import React, {useEffect} from "react";
import {Col, Form, Input, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {InstitutionRow} from "@services/dto/institution";
import {CustomUpload, normFile} from "@components/CustomUpload";
import {TreeSelectInstitution} from "@components/TreeSelectInstitution";
import {SelectPersonCharge} from "@components/SelectPersonCharge";

export interface PropsType {
  row: InstitutionRow
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    const institutionRow = {...props.row}
    form.setFieldsValue(institutionRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      initialValues={{status: 1, isVirtualUser: 0}}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="部门名称"
            name="name"
            rules={[{required: true, message: '请输入部门名称!'}]}>
            <Input placeholder="请输入部门名称"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="部门代码"
            name="code"
            rules={[{required: true, message: '请输入部门代码!'}]}>
            <Input placeholder="请输入部门代码"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="负责人"
            name="userId"
            rules={[{required: true, message: '请选择负责人!'}]}>
            <SelectPersonCharge placeholder={"请选择负责人"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="上级部门"
            name="pid"
            rules={[{required: false, message: '请选择上级部门!'}]}>
            <TreeSelectInstitution placeholder={"请选择上级部门"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="附件"
            name="file"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            extra={"支持文件(pdf,doc,docx),10M以内"}
            rules={[{required: false, message: '请上传附件!'}]}>
            <CustomUpload updateSize={20}
              maxCount={1}
              uploadType={"application"}
              accept={"application/*"}
              listType={"picture"}
              multiple={false}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
