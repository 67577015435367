import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {SealApprovalReq, SealApprovalRow} from "@services/dto/sealApproval";

export class SealApprovalService {
  static async getSealApproval(req: SealApprovalReq): Promise<Page<SealApprovalRow>> {
    return await apiV1.get("/sealApproval/search", {params: req})
  }

  static async addSealApproval(row: SealApprovalRow): Promise<ResponseEntity<SealApprovalRow>> {
    return await apiV1.post("/sealApproval/save", {...row})
  }

  static async updateSealApproval(row: SealApprovalRow): Promise<ResponseEntity<SealApprovalRow>> {
    return await apiV1.put("/sealApproval/update", {...row})
  }

  static async deleteSealApproval(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/sealApproval/delete", {data: ids})
  }

  static async getOne(id: string): Promise<ResponseEntity<SealApprovalRow>> {
    return await apiV1.get(`/sealApproval/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<SealApprovalRow>> {
    return await apiV1.get(`/sealApproval/getBatch/${ids.join(',')}`)
  }
}
