import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {Button, message, Space, Table} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {
  LARGER_MODAL_WIDTH,
  SMALL_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x,
} from "@/config";
import {ContractListReq, ContractStatisticsRow, ContractStatisticsSummaryTotal} from "@services/dto/contractList";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import {useLocation} from "react-router-dom";
import {HeaderActionCom} from "@modules/ContractList/Components/HeaderActionCom";
import {TableOptions} from "src/components/TableOptions";
import {UnorderedListOutlined} from "@ant-design/icons";
import {InvoiceRegister} from "@modules/InvoiceRegister";
import {FundApproval} from "@modules/FundApproval";
import {InvoiceRegisterRow} from "@services/dto/invoiceRegister";
import {FundApprovalRow} from "@services/dto/fundApproval";
import {ContractSettlementRow} from "@services/dto/contractSettlement";
import {BankStatementRow} from "@services/dto/bankStatement";
import {ContractSettlement} from "@modules/ContractSettlement";
import {BankStatement} from "@modules/BankStatement";
import {CustomDraggableModal} from "@components/CustomDraggableModal";
import {accAdd} from "@common/utils";
import {ContractListService} from "@services/ContractListService";

const wideShowColumn: string[] = [
  'clName',
  'clCode',
  'clType',
  'clAmount-summation',
  'csEndType',
  'csEndAmount-summation',
  'amountRealized-summation',
  'realizeBalance-summation',
  'responsibilityProject',
  'status',
  "action",
]

const narrowShowColumn: string[] = [
  'clName',
  'clCode',
  'clType',
  'clAmount-summation',
  'csEndType',
  'csEndAmount-summation',
  'amountRealized-summation',
  'realizeBalance-summation',
  'responsibilityProject',
  'status',
  "action",
]

export const ContractStatistics = observer(() => {
  const {contractListStore, tableColumnsStore, screenResolutionStore} = allStores
  const [dataSource, setDataSource] = useState<ContractStatisticsRow[]>([])
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [scrollHeight, setScrollHeight] = useState<number>(SMALL_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<ContractStatisticsRow>>([])
  const [contractSettlementOpen, setContractSettlementOpen] = useState<boolean>(false)
  const [contractSettlementDataSource, setContractSettlementDataSource] = useState<ContractSettlementRow[]>([])
  const [bankStatementOpen, setBankStatementOpen] = useState<boolean>(false)
  const [bankStatementDataSource, setBankStatementDataSource] = useState<BankStatementRow[]>([])
  const [invoiceRegisterOpen, setInvoiceRegisterOpen] = useState<boolean>(false)
  const [invoiceRegisterDataSource, setInvoiceRegisterDataSource] = useState<InvoiceRegisterRow[]>([])
  const [fundApprovalOpen, setFundApprovalOpen] = useState<boolean>(false)
  const [fundApprovalDataSource, setFundApprovalDataSource] = useState<FundApprovalRow[]>([])
  const location = useLocation()
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [summaryTotalObj, setSummaryTotalObj] = useState<ContractStatisticsSummaryTotal>({
    clAmount: 0,
    csEndAmount: 0,
    amountRealized: 0,
    realizeBalance: 0,
  })
  const [searchReq, setSearchReq] = useState<ContractListReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
  })

  const columns: ColumnsType<ContractStatisticsRow> = [
    {
      title: '合同名称',
      dataIndex: 'clName',
      key: 'clName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '合同编号',
      dataIndex: 'clCode',
      key: 'clCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '合同类别',
      dataIndex: 'clType',
      key: 'clType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '合同约定金额',
      dataIndex: 'clAmount',
      key: 'clAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '结算类别',
      dataIndex: 'csEndType',
      key: 'csEndType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '结算金额',
      dataIndex: 'csEndAmount',
      key: 'csEndAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '实现金额',
      dataIndex: 'amountRealized',
      key: 'amountRealized-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '结算余额',
      dataIndex: 'realizeBalance',
      key: 'realizeBalance-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <TableOptions
          items={[
            <Button
              className={'text-align-left'}
              type={'text'}
              block
              onClick={() => handleContractSettlementClick(record)}>
              <UnorderedListOutlined/>
              合同结算
            </Button>,
            <Button
              className={'text-align-left'}
              type={'text'}
              block
              onClick={() => handleInvoiceRegisterClick(record)}>
              <UnorderedListOutlined/>
              发票登记
            </Button>,
            <Button
              className={'text-align-left'}
              type={'text'}
              block
              onClick={() => handleFundApprovalClick(record)}>
              <UnorderedListOutlined/>
              资金审批
            </Button>,
            <Button
              className={'text-align-left'}
              type={'text'}
              block
              onClick={() => handleBankStatementClick(record)}>
              <UnorderedListOutlined/>
              资金出纳
            </Button>,
          ]}
          onClick={() => handleContractSettlementClick(record)}>
          查看关联合同结算
        </TableOptions>
      )
    }
  ];

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      setShowColumn(narrowShowColumn)
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    refreshData(searchReq)
  }, [])

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
    }
    refreshData(req)
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (contractListStore.contractStatisticsDatasource.data) {
      setDataSource(contractListStore.contractStatisticsDatasource.data.items)
      setTotal(contractListStore.contractStatisticsDatasource.data.total)
    }
  }, [contractListStore.contractStatisticsDatasource])

  useEffect(() => {
    setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))))
  }, [showColumn])

  /**
   * @description 表格合计
   */
  useEffect(() => {
    const objInitValue = {
      clAmount: 0,
      csEndAmount: 0,
      amountRealized: 0,
      realizeBalance: 0,
    }
    let obj = summaryTotalObj
    const arr = contractListStore.contractStatisticsDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        clAmount: accAdd(acc.clAmount, cur.clAmount),
        csEndAmount: accAdd(acc.csEndAmount, cur.csEndAmount),
        amountRealized: accAdd(acc.amountRealized, cur.amountRealized),
        realizeBalance: accAdd(acc.realizeBalance, cur.realizeBalance),
      }), objInitValue);
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [contractListStore.contractListDatasource, selectedRowKeys])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(parent?.clientHeight - headAction?.clientHeight - thead?.clientHeight - paginationHeight - othersHeight)
  }, [screenResolutionStore.screenResolution.height, searchReq.pageNum, searchReq.perPage])

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const refreshData = (req: ContractListReq) => {
    setSearchReq(req)
    contractListStore.statistics(req)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(location.pathname, value)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  /**
   * 点击关联合同结算
   * @param record
   */
  const handleContractSettlementClick = async (record: ContractStatisticsRow) => {
    try {
      const row = await ContractListService.getByBusinessId(record.id)
      setContractSettlementDataSource(row.contractSettlements)
      setContractSettlementOpen(true)
    } catch (e) {
      message.warning('查询失败')
    }
  }

  /**
   * 点击关联发票登记
   * @param record
   */
  const handleInvoiceRegisterClick = async (record: ContractStatisticsRow) => {
    try {
      const row = await ContractListService.getByBusinessId(record.id)
      setInvoiceRegisterDataSource(row.invoiceRegistrations)
      setInvoiceRegisterOpen(true)
    } catch (e) {
      message.warning('查询失败')
    }
  }

  /**
   * 点击关联资金审批
   * @param record
   */
  const handleFundApprovalClick = async (record: ContractStatisticsRow) => {
    try {
      const row = await ContractListService.getByBusinessId(record.id)
      setFundApprovalDataSource(row.fundingApprovals)
      setFundApprovalOpen(true)
    } catch (e) {
      message.warning('查询失败')
    }
  }

  /**
   * 点击关联资金出纳
   * @param record
   */
  const handleBankStatementClick = async (record: ContractStatisticsRow) => {
    try {
      const row = await ContractListService.getByBusinessId(record.id)
      setBankStatementDataSource(row.bankStatements)
      setBankStatementOpen(true)
    } catch (e) {
      message.warning('查询失败')
    }
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        // isHiddenOptions={true}
        handleShowColumnReset={handleShowColumnReset}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={columns}
        refreshData={(req) => refreshData({...searchReq, ...req})}/>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={dataSource}
        columns={renderColumns}
        size={"small"}
        loading={contractListStore.contractStatisticsDatasource.loading}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                (showColumn as string[]).map((key, index) =>
                  key.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={summaryTotalObj[key.slice(0, key.indexOf('-'))]}></TableMoneyText> 元
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}
        bordered
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys, columnWidth: 50}}/>

      { /*关联合同结算*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">关联合同结算</div>}
        open={contractSettlementOpen}
        width={LARGER_MODAL_WIDTH}
        onCancel={() => setContractSettlementOpen(false)}
        destroyOnClose
        footer={null}>
        <ContractSettlement
          dataSource={contractSettlementDataSource}
          isModalSelect
          isHiddenOptions
          locationPathname={'/contractSettlement'}/>
      </CustomDraggableModal>

      { /*关联资金出纳*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">关联资金出纳</div>}
        open={bankStatementOpen}
        width={LARGER_MODAL_WIDTH}
        onCancel={() => setBankStatementOpen(false)}
        destroyOnClose
        footer={null}>
        <BankStatement
          dataSource={bankStatementDataSource}
          isModalSelect
          isHiddenOptions
          locationPathname={'/bankStatement'}/>
      </CustomDraggableModal>

      { /*关联发票登记*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">关联发票登记</div>}
        open={invoiceRegisterOpen}
        width={LARGER_MODAL_WIDTH}
        onCancel={() => setInvoiceRegisterOpen(false)}
        destroyOnClose
        footer={null}>
        <InvoiceRegister
          dataSource={invoiceRegisterDataSource}
          isModalSelect
          locationPathname={'/invoiceRegister'}/>
      </CustomDraggableModal>

      { /*关联资金审批*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">关联资金审批</div>}
        open={fundApprovalOpen}
        width={LARGER_MODAL_WIDTH}
        onCancel={() => setFundApprovalOpen(false)}
        destroyOnClose
        footer={null}>
        <FundApproval
          dataSource={fundApprovalDataSource}
          isModalSelect
          locationPathname={'/fundApproval'}/>
      </CustomDraggableModal>
    </div>
  )
})
