import React, {FC, useEffect, useState} from "react";
import {Button, Table, Space, Card} from 'antd'
import {RecruitmentRow, SearchRecruitmentReq} from "@services/dto/recruitment";
import {Page, StateFullData} from "@services/dto/common";
import {useNavigate} from "react-router-dom";
import {RecruitmentService} from "@services/RecruitmentService";
import {showErr} from "@common/utils";
import {QRcodeModal} from "@components/QRcodeModal";
import {ColumnsType} from "antd/es/table";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import IconFont from "@components/IconFont";
import {BASE64_URL} from "@/config";
import {allStores} from "@/stores";
import {SecurityService} from "@services/SecurityService";

export interface PropsType {
  curRecruitment: StateFullData<Page<RecruitmentRow>>;
  searchReq: SearchRecruitmentReq;
  deletePublicity: (row: RecruitmentRow) => void,
  handlePageChange: (page: number, pageSize: number) => void
  selectedRowKeys: React.Key[];
  selectedRowKeysChange: (selectedRowKeys: React.Key[], selectedRows: RecruitmentRow[]) => void
  handleOffline: (row: RecruitmentRow, status: string) => void
}

export const Publicity: FC<PropsType> = (props) => {
  const {securityStore} = allStores
  const [dataSource, setDataSource] = useState<RecruitmentRow[]>([])
  const navigate = useNavigate()
  const [total, setTotal] = useState<number>(0)
  const [qRcodeModal, setQRcodeModal] = useState({
    title: "",
    visible: false,
    width: 500,
    value: "",
    size: 430,
  })

  const columns: ColumnsType<RecruitmentRow> = [
    {
      title: '招聘名称',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => { editPublicity(record) }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <div>{ new Date(text).toLocaleDateString() }</div>
        </Space>
      )
    },
    {
      title: '招聘经理',
      dataIndex: 'recruiters',
      key: 'recruiters',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          { record.recruiters.map(item => item.recruiterName).join("、") }
        </Space>
      )
    },
    {
      title: '项目详细地址',
      dataIndex: 'address',
      key: 'address',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          { getAddress(record) }
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record, index) => (
        <Space>
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined/>}
            onClick={() => { editPublicity(record) }}>修改</Button>
          {
            record.status === 'online' ?
              <Button 
                shape="round"
                className={"delete-btn"}
                size={"middle"}
                icon={<IconFont type={"hr-mokuai-shang"} className={"icon-fontsize"}/>}
                onClick={() => { handleOffline(record, "offline") }}>下线</Button>:
              <Button 
                shape="round" 
                className={"delete-btn"} 
                size={"middle"} 
                icon={<DeleteOutlined/>} 
                onClick={() => { deletePublicity(record) }}>删除</Button>
          }
          {
            record.status === 'online' && <Button shape="round" className={"view-btn"} size={"middle"} icon={<IconFont type={"hr-31erweima"}/>}
              onClick={() => { generateCode(record) }}>二维码</Button>
          }
        </Space>
      )
    }
  ];

  const getAddress = (record: RecruitmentRow): string => {
    if (Array.isArray(record.region)) {
      if (record.workAddress.includes(record.region.join(""))) {
        return record.workAddress
      }
      return record.region.join("") + record.workAddress
    }
    return record.region + record.workAddress
  }

  useEffect(() => {
    if (props.curRecruitment.data) {
      setTotal(props.curRecruitment.data.total);
      setDataSource(props.curRecruitment.data.items);
    }
  }, [props.curRecruitment])

  const editPublicity = (obj: RecruitmentRow) => {
    navigate(`/publicityAction/${obj._id}`)
  }

  const deletePublicity = (row: RecruitmentRow) => {
    props.deletePublicity(row)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    props.handlePageChange(page, pageSize)
  }

  const handleOffline = (row: RecruitmentRow, status: string) => {
    props.handleOffline(row, status)
  }

  const generateCode = (item: RecruitmentRow) => {
    SecurityService.setUserSortId(securityStore.getLoggedUser()?._id!).then(rsp => {
      RecruitmentService.programQrCode({page: 'pages/recruitment-detail/index', scene: `rid=${item.sortId}&uid=${rsp.data.sortId}`}).then((res) => {
        const code = {...qRcodeModal}
        code.visible = true
        code.title = item.title
        code.value = `${BASE64_URL}${res}`
        setQRcodeModal(code)
      }).catch(showErr);
    }).catch(showErr)
  }

  return (
    <Card>
      <Table
        rowKey={"_id"}
        dataSource={dataSource} 
        columns={columns} 
        size={"middle"}  
        loading={props.curRecruitment.loading}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: props.searchReq.perPage,
          current: props.searchReq.pageNum, 
          showSizeChanger: true, 
          showQuickJumper: true, 
          showTotal: total => `共${total}条`, onChange: handlePageChange
        }}
        rowSelection={{selectedRowKeys: props.selectedRowKeys, onChange: props.selectedRowKeysChange}}/>
      
      <QRcodeModal 
        title={qRcodeModal.title} 
        visible={qRcodeModal.visible} 
        width={qRcodeModal.width}
        value={qRcodeModal.value} 
        size={qRcodeModal.size}
        type={'image'}
        onCancel={() => setQRcodeModal({...qRcodeModal, visible: false})}/>
    </Card>
  )
}
