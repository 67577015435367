import React, {useEffect, useState} from "react";
import {Cascader} from "antd";
import {PersonCascader} from "@services/dto/talent";
import {DefaultOptionType} from "antd/es/cascader";
import {allStores} from "@/stores";
import {observer} from "mobx-react";

export interface PropsType {
  disabled?: boolean
  onChange?: (value: string[], selectedOptions: PersonCascader[]) => void
  person?: string[]
  placeholder?: string
  className?: string
}

export const CascaderPerson = observer((props: PropsType) => {
  const {talentStore} = allStores
  const [value, setValue] = useState<string[]>([])

  useEffect(() => {
    if (!talentStore.talentFatherSonData.data) {
      talentStore.getFatherAndSonStructure()
    }
  }, [])

  useEffect(() => {
    if (props.person) {
      setValue(props.person)
    }
  }, [props.person])

  const onChange = (value: string[], selectedOptions: PersonCascader[]) => {
    let values = value ? value.map(item => String(item)) : value
    setValue(values);
    if (props.onChange) {
      props.onChange(values, selectedOptions);
    }
  }

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
    )

  return (
    <>
      <Cascader
        value={value}
        disabled={props.disabled}
        size={"middle"}
        className={props.className}
        placeholder={props.placeholder || "请选择员工"}
        loading={talentStore.talentFatherSonData.loading}
        options={talentStore.talentFatherSonData.data}
        showSearch={{ filter }}
        onChange={onChange}/>
    </>
  )
})
