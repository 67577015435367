import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {BudgetManagementService} from "@services/BudgetManagementService";
import {BudgetManagementReq, BudgetManagementRow} from "@services/dto/budgetManagement";

export class BudgetManagementStore {
  budgetManagementDatasource: StateFullData<Page<BudgetManagementRow>> = {}
  budgetManagementSelectDatasource: StateFullData<Page<BudgetManagementRow>> = {}
  budgetManagementRow?: BudgetManagementRow
  budgetManagementOption?: string
  budgetManagementDetail: StateFullData<ResponseEntity<BudgetManagementRow>> = {}
  budgetManagementDetails: StateFullData<ResponsesEntity<BudgetManagementRow>> = {}
  budgetManagementSearchReq: BudgetManagementReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateBudgetManagementRow(row: BudgetManagementRow) {
    this.budgetManagementRow = {...row}
  }

  updateBudgetManagementOption(option: string) {
    this.budgetManagementOption = option
  }

  updateBudgetManagementSearchReq(req: BudgetManagementReq) {
    this.budgetManagementSearchReq = {...this.budgetManagementSearchReq, ...req}
  }

  getBudgetManagement(req: BudgetManagementReq) {
    this.budgetManagementDatasource = {...this.budgetManagementDatasource, loading: true}
    this.budgetManagementSearchReq = {...this.budgetManagementSearchReq, ...req}
    BudgetManagementService.getBudgetManagement(req).then(rsp => {
      runInAction(() => this.budgetManagementDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.budgetManagementDatasource = {
        ...this.budgetManagementDatasource,
        loading: false,
        err
      })
    })
  }

  getSelectBudgetManagement(req: BudgetManagementReq) {
    this.budgetManagementSelectDatasource = {...this.budgetManagementSelectDatasource, loading: true}
    BudgetManagementService.getBudgetManagement(req).then(rsp => {
      runInAction(() => this.budgetManagementSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.budgetManagementSelectDatasource = {
        ...this.budgetManagementSelectDatasource,
        loading: false,
        err
      })
    })
  }

  getOne(id: string) {
    this.budgetManagementDetail = {...this.budgetManagementDetail, loading: true}
    BudgetManagementService.getDetailById(id).then(rsp => {
      runInAction(() => this.budgetManagementDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.budgetManagementDetail = {...this.budgetManagementDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.budgetManagementDetails = {...this.budgetManagementDetails, loading: true}
    BudgetManagementService.getBatch(ids).then(rsp => {
      runInAction(() => this.budgetManagementDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.budgetManagementDetails = {...this.budgetManagementDetails, loading: false, err})
    })
  }

  async getPrintList(record: BudgetManagementRow): Promise<LabelValue[]> {

    const row = this.budgetManagementDatasource.data?.items.find(o => o.id === record.id)

    return [
      {label: '制单日期', value: row.bmDate as string},
      {label: '制单编号', value: row.bmCode},
      {label: '预算类别', value: row.bmType},
      {label: '摘要', value: row.bmSummary},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const budgetManagementStore = new BudgetManagementStore();
