import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {MaterialsOutBoundReq, MaterialsOutBoundRow} from "@services/dto/materialsOutBound";

export class MaterialsOutBoundService {
  static async getMaterialsOutBound(req: MaterialsOutBoundReq): Promise<Page<MaterialsOutBoundRow>> {
    return await apiV1.get("/materialDelivery/search", {params: req})
  }

  static async addMaterialsOutBound(row: MaterialsOutBoundRow): Promise<ResponseEntity<MaterialsOutBoundRow>> {
    return await apiV1.post("/materialDelivery/save", {...row})
  }

  static async updateMaterialsOutBound(row: MaterialsOutBoundRow): Promise<ResponseEntity<MaterialsOutBoundRow>> {
    return await apiV1.put("/materialDelivery/update", {...row})
  }

  static async deleteMaterialsOutBound(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/materialDelivery/delete", {data: ids})
  }
}
