import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {EmolumentPlanReq, EmolumentPlanRow} from "@services/dto/emolumentPlan";

export class EmolumentPlanService {
  static async getEmolumentPlan(req: EmolumentPlanReq): Promise<Page<EmolumentPlanRow>> {
    return await apiV1.get("/employeeFeeCompensationPlan/search", {params: req})
  }

  static async batchRefresh(): Promise<ResponseEntity<string>> {
    return await apiV1.get("/employeeFeeCompensationPlan/batch/refresh")
  }

  static async addEmolumentPlan(row: EmolumentPlanRow): Promise<ResponseEntity<EmolumentPlanRow>> {
    return await apiV1.post("/employeeFeeCompensationPlan/save", {...row})
  }

  static async batchUpdateEmolumentPlan(rows: EmolumentPlanRow[]): Promise<ResponseEntity<string>> {
    return await apiV1.put("/employeeFeeCompensationPlan/batchUpdate", [...rows])
  }

  static async updateEmolumentPlan(row: EmolumentPlanRow): Promise<ResponseEntity<EmolumentPlanRow>> {
    return await apiV1.put("/employeeFeeCompensationPlan/update", {...row})
  }

  static async deleteEmolumentPlan(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/employeeFeeCompensationPlan/delete", {data: ids})
  }

  static async getDetailById(id: string): Promise<ResponseEntity<EmolumentPlanRow>> {
    return await apiV1.get(`/employeeFeeCompensationPlan/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<EmolumentPlanRow>> {
    return await apiV1.get(`/employeeFeeCompensationPlan/getBatch/${ids.join(',')}`)
  }
}
