import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {HeaderAction} from "@components/HeaderAction";
import {ColumnsType} from "antd/es/table";
import {IncomeExpenditureStatisticsReq,} from "@services/dto/incomeExpenditureStatistics";
import {Button, DatePicker, Select} from "antd";
import dayjs from "dayjs";
import {ProjectRow} from "@services/dto/project";
import {allStores} from "@/stores";
import {BulbOutlined} from "@ant-design/icons";

export interface PropsType {
  handleDeleteBatch?: () => void,
  handleShowColumnReset?: () => void,
  handleAdd?: () => void,
  handleIncomeExpenditureStatisticsRowView?: () => void,
  incomeExpenditureStatisticsRowViewTitle?: string,
  handleTableColumnsChange?: (value: string[]) => void,
  showColumn?: string[],
  tableColumns?: ColumnsType,
  refreshData?: (req: IncomeExpenditureStatisticsReq) => void,
  items?: React.ReactNode[]
  selectedRowKeys?: React.Key[], // 所选行
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const {headerSearchStore, securityStore} = allStores
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [userProjects, setUserProjects] = useState<ProjectRow[] | undefined>([])

  useEffect(() => {
    setUserProjects(securityStore.getLoggedUserProjects())
  }, [])

  const handleProjectChange = (value: string) => {
    setResponsibilityProject(value)
    headerSearchStore.updateResponsibilityProject(value)
  }

  useEffect(() => {
    setResponsibilityProject(headerSearchStore.headerSearchReq.responsibilityProject)
  }, [headerSearchStore.headerSearchReq])

  const [searchReq, setSearchReq] = useState<IncomeExpenditureStatisticsReq>({
    pageNum: 1,
    perPage: 10,
  })

  const refreshData = (req: IncomeExpenditureStatisticsReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.start = dateStrings[0]
    req.end = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <HeaderAction
        handleDeleteBatch={props.handleDeleteBatch}
        handleAdd={props.handleAdd}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        handleShowColumnReset={props.handleShowColumnReset}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        items={props.items}
        selectedRowKeys={props.selectedRowKeys}
        isShowKeyword={true}
        collapsedChildren={[
          <DatePicker.RangePicker
            onChange={handleDateChange}
            placeholder={['开始日期', '结束日期']}
            className={"width-100-percentage"}/>,
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => ((option as ProjectRow).name ?? '').includes(input)}
            placeholder={"请选择责任部门"}
            className={"width-100-percentage"}
            allowClear
            fieldNames={{value: "dictValue", label: "projectName"}}
            options={[
              {projectName: 'N/A', dictValue: 'N/A'},
              {projectName: '容缺', dictValue: '容缺'},
              ...userProjects ? userProjects : []
            ]}
            value={responsibilityProject}
            onChange={handleProjectChange}/>,
          <Button
            type={'primary'}
            danger={props.incomeExpenditureStatisticsRowViewTitle !== '余额相符'}
            onClick={() => {
              props.handleIncomeExpenditureStatisticsRowView && props.handleIncomeExpenditureStatisticsRowView()
            }}>
            <BulbOutlined/>
            { props.incomeExpenditureStatisticsRowViewTitle || '余额对账' }
          </Button>,
        ]}/>
    </>
  )
}))
