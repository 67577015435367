import React, {FC} from "react";
import {observer} from "mobx-react";
import {Button, Card, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {AdvanceRow} from "@services/dto/advance";
import dayjs from "dayjs";
import {DATE_MONTH_FMT} from "@/config";
import {getAdvanceStatusLabel} from "@common/utils";

export interface PropsType {
  className?: string
  loading: boolean
  total: number
  perPage: number
  pageNum: number
  selectedRowKeys: React.Key[]
  dataSource: AdvanceRow[]
  handlePageChange: (page: number, pageSize: number) => void
  handleChange: (selectedRowKeys: React.Key[], selectedRows: AdvanceRow[]) => void
  handleEdit: (record: AdvanceRow) => void
  handleView: (record: AdvanceRow) => void
  handleDeleteBatch: (record: AdvanceRow) => void
}

export const ContentTable:FC<PropsType> = observer((props: PropsType) => {
  const columns: ColumnsType<AdvanceRow> = [
    {
      title: '姓名',
      dataIndex: ['userRef', 'name'],
      align: "center",
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => { props.handleView(record) }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '时间',
      dataIndex: "date",
      align: "center",
      render: (text, record, index) => (
        <Space>
          <div>{ dayjs(text).format(DATE_MONTH_FMT) }</div>
        </Space>
      )
    },
    {
      title: '项目',
      dataIndex: "projectName",
      align: "center"
    },
    {
      title: '队部',
      dataIndex: "professionalLeagues",
      align: "center"
    },
    {
      title: '职位',
      dataIndex: "professional",
      align: "center"
    },
    {
      title: '金额',
      dataIndex: "money",
      align: "center"
    },
    {
      title: '代领人',
      dataIndex: "leadingPeopleIRecipients",
      align: "center"
    },
    {
      title: '代领人银行卡',
      dataIndex: "bankCardNumber",
      align: "center"
    },
    {
      title: '预支情况',
      dataIndex: "status",
      align: "center",
      render: (text, record, index) => (
        <Space>
          <div>{ getAdvanceStatusLabel(text) }</div>
        </Space>
      )
    },
    {
      title: '操作',
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined/>}
            onClick={() => { handleEdit(record) }} disabled={record.status === 2}>修改</Button>
          <Button shape="round" className={"delete-btn"} size={"middle"}
            icon={<DeleteOutlined/>} onClick={() => { handleDeleteBatch(record) }} disabled={record.status === 2}>删除</Button>
        </Space>
      ),
    },
  ];

  const handlePageChange = (page: number, pageSize: number) => {
    props.handlePageChange(page, pageSize)
  }

  const handleChange = (selectedRowKeys: React.Key[], selectedRows: AdvanceRow[]) => {
    props.handleChange(selectedRowKeys, selectedRows);
  }

  const handleEdit = (record: AdvanceRow) => {
    props.handleEdit(record)
  }

  const handleDeleteBatch = (record: AdvanceRow) => {
    props.handleDeleteBatch(record)
  }
  
  const getCheckboxProps = (record: AdvanceRow) => ({
    disabled: record.status === 2
  })
  
  return (
    <>
      <Card className={props.className}>
        <Table
          rowKey={'id'}
          dataSource={props.dataSource}
          columns={columns}
          size={"middle"}
          loading={props.loading}
          pagination={{
            total: props.total,
            position: ['bottomCenter'],
            pageSize: props.perPage,
            current: props.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`, onChange: handlePageChange
          }}
          rowSelection={{selectedRowKeys: props.selectedRowKeys, onChange: handleChange, getCheckboxProps: getCheckboxProps}}/>
      </Card>
    </>
  )
})