import React, {FC, useEffect, useLayoutEffect, useState} from "react";
import {Button, Drawer, message, Modal, Space, Table} from 'antd'
import {ColumnsType} from "antd/es/table";
import {FormInstance} from "antd/es";
import {OrganizationalRow, PageParam, Projects} from "@services/dto/organizational";
import {BasicInfo} from './Components/BasicInfo'
import {allStores} from "@/stores";
import {observer} from "mobx-react";
import {logger, parseURLParams, safeCompute, showErr} from "@common/utils";
import {
  DEFAULT_MODAL_WIDTH,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  EDIT_TEXT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x
} from "@/config";
import {OrganizationalService} from "@services/OrganizationalService";
import "./index.less"
import {TableOptions} from "src/components/TableOptions";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {useLocation} from "react-router-dom";
import {HeaderActionCom} from "@modules/Organizational/Components/HeaderActionCom";

const blackColumnList = [
  'action',
]

const wideShowColumn: string[] = [
  'name',
  'phone',
  'workNumber',
  'rolesMId',
  'projects',
  'cooperationUnits',
  'action',
]

const narrowShowColumn: string[] = [
  'name',
  'phone',
  'workNumber',
  // 'rolesMId',
  'projects',
  'cooperationUnits',
  'action',
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: OrganizationalRow[] // 数据源
  setFundApprovalSelectedRowKeys?: (key: React.Key[]) => void // 所选行
}

export const Organizational: FC = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const location = useLocation()
  const {
    organizationalStore,
    headerSearchStore,
    tableColumnsStore,
    screenResolutionStore
  } = allStores;
  const basicInfo = React.createRef<FormInstance>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isView, setIsView] = useState<boolean>(false)
  const [organizationalObj, setOrganizationalObj] = useState<OrganizationalRow>()
  const [dataSource, setDataSource] = useState<OrganizationalRow[]>([]);
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<OrganizationalRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [total, setTotal] = useState<number>(0)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [organizationalReq, setOrganizationalReq] = useState<PageParam>({
    perPage: 10,
    pageNum: 1,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...organizationalStore.organizationalReq
  })

  const columns: ColumnsType<OrganizationalRow> = [
    {
      title: '用户账号',
      dataIndex: 'workNumber',
      key: 'workNumber',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '用户姓名',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '手机号码',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '用户角色',
      dataIndex: 'rolesMId',
      key: 'rolesMId',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (_, record) => (
        <Space>
          <TableEllipsisText value={record.rolesM?.map(o => o?.name).join(',')}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'projects',
      key: 'projects',
      width: TABLE_CELL_WIDTH_1_5x,
      align: 'center',
      render: (text: Projects[]) => (
        <Space>
          <TableEllipsisText value={text.map(o => o.name).join("、")}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnits',
      key: 'cooperationUnits',
      width: TABLE_CELL_WIDTH_1_5x,
      align: 'center',
      render: (text: Projects[]) => (
        <Space>
          <TableEllipsisText value={text.map(o => o.name).join("、")}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableOptions
          handleEdit={() => {
            handleEdit(record)
          }}
          handleView={() => {
            handleView(record)
          }}
          handleDelete={() => {
            handleDeleteBatch(record)
          }}
          onClick={() => handleEdit(record)}>
          { EDIT_TEXT }
        </TableOptions>
      )
    }
  ];

  /**
   * @description 初始化
   */
  useEffect(() => {

    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(organizationalReq)
    }
  }, [])

  /**
   * @description 全局查询
   */
  useEffect(() => {
    const req = {...organizationalReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setOrganizationalReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (organizationalStore.organizationalDatasource.data) {
      setDataSource(organizationalStore.organizationalDatasource.data.items || [])
      setTotal(organizationalStore.organizationalDatasource.data.total || 0)
    }
  }, [organizationalStore.organizationalDatasource.data])

  /**
   * @description 表单数据变化
   */
  useEffect(() => {
    if (organizationalStore.organizationalRow?.organizationalId) {
      setOrganizationalObj(organizationalStore.organizationalRow)
      setIsView(false)
      setIsModalVisible(true);
      organizationalStore.updateOrganizationalRow({
        ...organizationalStore.organizationalRow,
        organizationalId: undefined,
      })
    }
  }, [organizationalStore.organizationalRow])

  useEffect(() => {
    if (organizationalStore.organizationalOption) {
      const [option, paramsStr] = organizationalStore.organizationalOption.split("?")
      if (option === 'add') {
        handleAdd()
      } else if (paramsStr) {
        const params = parseURLParams(paramsStr)
        if (option === 'edit') {
          OrganizationalService.getOne(params.id).then(rsp => {
            if (rsp) {
              handleEdit(rsp)
            }
          })
        }
      }
      organizationalStore.updateFundApprovalOption(undefined)
    }
  }, [organizationalStore.organizationalOption])

  /**
   * 列配置变化
   */
  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => !blackColumnList.includes(o.key as string)).filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, organizationalStore.collapsed, organizationalReq.pageNum, organizationalReq.perPage])

  const refreshData = (organizationalRequest: PageParam) => {
    setOrganizationalReq(organizationalRequest)
    organizationalStore.getOrganizational(organizationalRequest)
  }

  const handleDeleteBatch = (record?: OrganizationalRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        OrganizationalService.deleteBatchOrganizationalByIds(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(organizationalReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handleEdit = (record: OrganizationalRow) => {
    setOrganizationalObj(record)
    setIsModalVisible(true);
    setIsView(false)
  }

  const handleAdd = () => {
    setOrganizationalObj(undefined)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleView = (record: OrganizationalRow) => {
    setOrganizationalObj(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleOk = () => {
    const organizationalRow = {...organizationalObj!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(organizationalRow, values)
      if (organizationalRow.projects) {
        organizationalRow.projects = organizationalRow.projects.map(item => ({
          id: item.value!,
          name: item.label!
        }));
      }
      if (organizationalRow.cooperationUnits) {
        organizationalRow.cooperationUnits = organizationalRow.cooperationUnits.map(item => ({
          id: item.value!,
          name: item.label!
        }));
      }
      organizationalRow.status = 1
      setConfirmLoading(true)
      if (organizationalRow.id) {
        OrganizationalService.updateOrganizationalById(organizationalRow.id, organizationalRow).then(() => {
          setUpdateCount(updateCount + 1)
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        OrganizationalService.addOrganizational(organizationalRow).then(() => {
          setUpdateCount(updateCount + 1)
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setOrganizationalObj(undefined)
  };

  const actionThen = () => {
    message.success("操作成功！").then()
    setIsModalVisible(false);
    setOrganizationalObj(undefined)
    refreshData(organizationalReq)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let organizationalRequest = {...organizationalReq};
    organizationalRequest.perPage = pageSize
    organizationalRequest.pageNum = page
    setOrganizationalReq(organizationalRequest)
    refreshData(organizationalRequest)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    organizationalStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  return (
    <div className="organizational">

      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        isHiddenOptions={props.isHiddenOptions}
        updateCount={updateCount}
        collapsed={organizationalStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={organizationalReq}
        selectedRowKeys={selectedRowKeys}
        handleShowColumnReset={handleShowColumnReset}
        handleDeleteBatch={handleDeleteBatch}
        handleAdd={handleAdd}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={props.isModalSelect ? columns.filter(o => !blackColumnList.includes(o.key as string)) : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...organizationalReq, ...req})}></HeaderActionCom>

      <Table
        className={"margin-top-xs"}
        bordered
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        size={"middle"}
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        loading={props.dataSource ? false : organizationalStore.organizationalDatasource.loading || confirmLoading}
        pagination={!props.dataSource && {
          total: total,
          position: ['bottomCenter'],
          pageSize: organizationalReq.perPage,
          current: organizationalReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`, onChange: handlePageChange
        }}
        rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys}}/>

      <Drawer
        title={<div className="text-align-center">系统用户</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        destroyOnClose
        onClose={handleCancel}>
        <BasicInfo row={{...organizationalObj!}} isView={isView} ref={basicInfo}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button
              type="primary"
              onClick={handleOk}
              loading={confirmLoading}
              className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>
    </div>
  )
}))
