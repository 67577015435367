import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {TaskSupervisionReq, TaskSupervisionRow} from "@services/dto/taskSupervision";
import {TaskSupervisionService} from "@services/TaskSupervisionService";
import {BLACK_LIST} from "@/config";
import {FilingService} from "@services/FilingService";

export class TaskSupervisionStore {
  taskSupervisionDatasource: StateFullData<Page<TaskSupervisionRow>> = {}
  taskSupervisionSelectDatasource: StateFullData<Page<TaskSupervisionRow>> = {}
  taskSupervisionRow?: TaskSupervisionRow
  taskSupervisionDetail: StateFullData<ResponseEntity<TaskSupervisionRow>> = {}
  taskSupervisionDetails: StateFullData<ResponsesEntity<TaskSupervisionRow>> = {}
  taskSupervisionReq: TaskSupervisionReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateTaskSupervisionRow(row: TaskSupervisionRow) {
    this.taskSupervisionRow = {...row}
  }


  getTreeTaskSupervision(req: TaskSupervisionReq) {
    this.taskSupervisionDatasource = {...this.taskSupervisionDatasource, loading: true}
    this.taskSupervisionReq = {...this.taskSupervisionReq, ...req}
    TaskSupervisionService.getTreeTaskSupervision(req).then(rsp => {
      runInAction(() => this.taskSupervisionDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.taskSupervisionDatasource = {...this.taskSupervisionDatasource, loading: false, err})
    })
  }

  getSelectTaskSupervision(req: TaskSupervisionReq) {
    this.taskSupervisionSelectDatasource = {...this.taskSupervisionSelectDatasource, loading: true}
    TaskSupervisionService.getTaskSupervision(req).then(rsp => {
      runInAction(() => this.taskSupervisionSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.taskSupervisionSelectDatasource = {...this.taskSupervisionSelectDatasource, loading: false, err})
    })
  }

  getDetailById(id: string) {
    this.taskSupervisionDetail = {...this.taskSupervisionDetail, loading: true}
    TaskSupervisionService.getOne(id).then(rsp => {
      runInAction(() => this.taskSupervisionDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.taskSupervisionDetail = {...this.taskSupervisionDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.taskSupervisionDetails = {...this.taskSupervisionDetails, loading: true}
    TaskSupervisionService.getBatch(ids).then(rsp => {
      runInAction(() => this.taskSupervisionDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.taskSupervisionDetails = {...this.taskSupervisionDetails, loading: false, err})
    })
  }

  async getPrintList(record: TaskSupervisionRow): Promise<LabelValue[]> {

    const row = this.taskSupervisionDatasource.data?.items.find(o => o.id === record.id)

    // 存档批号
    let efaFileArchiveRow = undefined
    if (row.tsFile && !BLACK_LIST.includes(row.tsFile)) {
      const efaFileArchiveRsp = await FilingService.getBatch([row.tsFile])
      if (efaFileArchiveRsp && efaFileArchiveRsp.code !== 200) {
        return
      }
      efaFileArchiveRow = efaFileArchiveRsp.data[0]
    }

    return [
      {label: '制单日期', value: String(row.tsDate || '')},
      {label: '制单编号', value: row.tsCode},
      {label: '费用说明', value: row.tsDesc},
      {label: '存档批号', value: efaFileArchiveRow?.faBatchNumber},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const taskSupervisionStore = new TaskSupervisionStore();
