import React, {useEffect} from "react";
import {Col, Form, Input, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {DemoPageRow} from "@services/dto/demoPage";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {allStores} from "@/stores";
import {ProjectRow} from "@services/dto/project";
import {CustomUpload, normFile} from "@components/CustomUpload";
import {OrganizationalRow} from "@services/dto/organizational";

export interface PropsType {
  row: DemoPageRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores

  useEffect(() => {
    form.resetFields()
    const demoPageRow = {...props.row}
    filingStore.setFilingFaCode(demoPageRow.code) // 将制单编号给到存档批号
    if (demoPageRow.id) {

    }
    form.setFieldsValue(demoPageRow);
  }, [props.row])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    const row = options.find(o => value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  /**
   * 责任人修改 填充名字
   */
  const handlePersonResponsibleChange = (value: string, selectedOptions: OrganizationalRow[]) => {
    const row = selectedOptions.find(o => value === o.id)
    form.setFieldsValue({personResponsibleName: row.name})
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      disabled={props.isView}
      initialValues={{ }}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="责任人"
            name="personResponsible"
            rules={[{required: true, message: '请选择责任人!'}]}>
            <SelectPersonCharge
              onChange={handlePersonResponsibleChange}
              className={"width-100-percentage"}
              placeholder={"请选择负责人"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: true, message: '请选择责任部门!'}]}>
            <SelectProject
              placeholder={"请选择责任部门"}
              onChange={handleProjectChange}
              allowClear
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: true, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              allowClear
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="存档批号"
            name="fileList"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            extra={"支持文件(pdf,doc,docx),10M以内"}
            rules={[{required: false, message: '请选择存档批号!'}]}>
            <CustomUpload
              updateSize={10}
              maxCount={5}
              uploadType={"application"}
              accept={"application/*"}
              listType={"picture"}
              multiple={false}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
