import {makeAutoObservable, runInAction} from "mobx";
import {Page, StateFullData} from "@services/dto/common";
import {
  IncomeExpenditureStatisticsReq,
  IncomeExpenditureStatisticsRow
} from "@services/dto/incomeExpenditureStatistics";
import {IncomeExpenditureStatisticsService} from "@services/IncomeExpenditureStatisticsService";

export class IncomeExpenditureStatisticsStore {
  incomeExpenditureStatisticsDatasource: StateFullData<Page<IncomeExpenditureStatisticsRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getIncomeExpenditureStatistics(req: IncomeExpenditureStatisticsReq) {
    this.incomeExpenditureStatisticsDatasource = {...this.incomeExpenditureStatisticsDatasource, loading: true}
    IncomeExpenditureStatisticsService.getIncomeExpenditureStatistics(req).then(rsp => {
      runInAction(() => this.incomeExpenditureStatisticsDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.incomeExpenditureStatisticsDatasource = {...this.incomeExpenditureStatisticsDatasource, loading: false, err})
    })
  }
}

export const incomeExpenditureStatisticsStore = new IncomeExpenditureStatisticsStore();
