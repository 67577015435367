import {List} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {WorkerType} from "@services/dto/workerType";

export class WorkerTypeService {
  static getWorkerTypes(): Promise<List<WorkerType>> {
    return apiV1.get('/workerTypes');
  }
}

