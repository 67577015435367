import {Page, StateFullData} from "@services/dto/common";
import {InstitutionRow, PageParam} from "@services/dto/institution";
import {makeAutoObservable, runInAction} from "mobx";
import {InstitutionService} from "@services/InstitutionService";

export class InstitutionStore {
  institutionDatasource: StateFullData<Page<InstitutionRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getInstitutions(req: PageParam) {
    this.institutionDatasource = {...this.institutionDatasource, loading: true}
    InstitutionService.getInstitutions(req).then(rsp => {
      runInAction(() => this.institutionDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.institutionDatasource = {...this.institutionDatasource, loading: false, err})
    })
  }
}

export const institutionStore = new InstitutionStore();
