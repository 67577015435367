import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {FormInstance, message, Modal, Space, Table, Tag} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {accAdd, logger, safeCompute, showErr} from "@common/utils";
import {BasicInfo} from "@modules/TaxAccounting/Components/BasicInfo";
import {TaxAccountingReq, TaxAccountingRow, TaxAccountingRowSummaryTotal} from "@services/dto/taxAccounting";
import {TaxAccountingService} from "@services/TaxAccountingService";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import {useLocation, useNavigate} from "react-router-dom";
import {HeaderActionCom} from "@modules/TaxAccounting/Components/HeaderActionCom";
import {BasicInfo as ProcessRecordsBasicInfo} from "@modules/ProcessRecords/Components/BasicInfo";
import {InvoiceRegister} from "@modules/InvoiceRegister";
import {InvoiceRegisterRow} from "@services/dto/invoiceRegister";
import {parameterType, WindowPrint} from "@modules/WindowPrint";
import {LabelValue, ResponseEntity} from "@services/dto/common";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FieldTimeOutlined,
  PlusOutlined,
  PrinterOutlined,
  RetweetOutlined
} from "@ant-design/icons";
import {CustomDraggableModal} from "@components/CustomDraggableModal";
import {ProjectService} from "@services/ProjectService";
import {fundApprovalStore} from "@modules/FundApproval/Stores/FundApprovalStore";
import dayjs from "dayjs";
import {TableFundApprovals} from "@components/TableFundApprovals";
import {FundApprovalRow} from "@services/dto/fundApproval";
import {FundApproval} from "@modules/FundApproval";
import {TableFilingText} from "@components/TableFilingText";
import {getBatchTableInvoiceRegistrations, TableInvoiceRegistrations} from "@components/TableInvoiceRegistrations";
import {getBatchTableTableBankAccount, TableBankAccount} from "@components/TableBankAccount";
import {
  APPROVE_FORM_TRANS_STATE,
  BLACK_LIST,
  DATE_FMT,
  FORM_ALTERNATE_TEXT,
  FORM_CLOSE_TEXT,
  FORM_COMMIT_TEXT,
  FORM_DISABLED_STATE,
  FORM_HANDOVER_TEXT,
  FORM_INITIATE_STATE,
  FORM_PERSON_RESPONSIBLE_WHITELIST,
  FORM_RETURN_TEXT,
  FORM_REVOCATION_TEXT,
  LARGE_MODAL_WIDTH,
  LARGE_MODAL_WIDTH_OTHER,
  LARGER_MODAL_WIDTH,
  READY,
  SMALL_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x,
  TABLE_CELL_WIDTH_3x
} from "@/config";
import {TableAuthOptions} from "@components/CustomAuthOptions";
import {ProcessRecordsRow} from "@services/dto/processRecords";
import {ProcessRecordsService} from "@services/ProcessRecordsService";
import {ProcessRecords} from "@modules/ProcessRecords";
import {CustomForm} from "@components/CustomForm";

const wideShowColumn: string[] = [
  'taDate',
  'taCode',
  // 'taSegment',
  // 'taEnterprise',
  'taCategory',
  // 'taxCategory',
  'taxMethod',
  // 'taDesc',
  // 'invoiceRegistrationOutId',
  // 'invoiceRegistrationInId',
  // 'taOutIrAmount-summation',
  // 'taInIrAmount-summation',
  // 'taOutTaxIrAmount-summation',
  // 'taInTaxIrAmount-summation',
  // 'taVatPayable-summation',
  // 'additionalTaxAmount-summation',
  // 'personDue-summation',
  // 'bsAccountPayId',
  // 'bsAccountInId',
  // 'efpEmployeeFeeApplicationId',
  // 'fundingApprovalList',
  // 'personResponsibleName',
  // 'responsibilityProject',
  // 'cooperationUnit',
  // 'fileArchive',
  'amountRealizedDate',
  'amountRealized-summation',
  'balance-summation',
  'taStatus',
  'statusCommon',
  'action',
]

const narrowShowColumn: string[] = [
  'taDate',
  'taCode',
  // 'taSegment',
  // 'taEnterprise',
  'taCategory',
  // 'taxCategory',
  'taxMethod',
  // 'taDesc',
  // 'invoiceRegistrationOutId',
  // 'invoiceRegistrationInId',
  // 'taOutIrAmount-summation',
  // 'taInIrAmount-summation',
  // 'taOutTaxIrAmount-summation',
  // 'taInTaxIrAmount-summation',
  // 'taVatPayable-summation',
  // 'additionalTaxAmount-summation',
  // 'personDue-summation',
  // 'bsAccountPayId',
  // 'bsAccountInId',
  // 'efpEmployeeFeeApplicationId',
  // 'fundingApprovalList',
  // 'personResponsibleName',
  // 'responsibilityProject',
  // 'cooperationUnit',
  // 'fileArchive',
  // 'amountRealizedDate',
  // 'amountRealized-summation',
  // 'balance-summation',
  'taStatus',
  'statusCommon',
  'action',
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: TaxAccountingRow[] // 数据源
}

export const TableName = 'taxAccounting'

export const TaxAccounting = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const location = useLocation()
  const navigate = useNavigate()
  const {
    processStore,
    securityStore,
    taxAccountingStore,
    screenResolutionStore,
    tableColumnsStore} = allStores
  const basicInfo = React.createRef<FormInstance>();
  const processRecordsBasicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<TaxAccountingRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [taxAccountingRow, setTaxAccountingRow] = useState<TaxAccountingRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [scrollHeight, setScrollHeight] = useState<number>(SMALL_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<TaxAccountingRow>>([])
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [invoiceRegisterOpen, setInvoiceRegisterOpen] = useState<boolean>(false)
  const [invoiceRegisterDataSource, setInvoiceRegisterDataSource] = useState<InvoiceRegisterRow[]>([])
  const [printTitle, setPrintTitle] = useState<string>('')
  const [isPrintTable, setIsPrintTable] = useState<boolean>(false)
  const [printDataSource, setPrintDataSource] = useState<TaxAccountingRow[]>([])
  const [isBatchUpdate, setIsBatchUpdate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentList, setContentList] = useState<LabelValue[]>([]);
  const [fundApprovalOpen, setFundApprovalOpen] = useState<boolean>(false)
  const [fundApprovalDataSource, setFundApprovalDataSource] = useState<FundApprovalRow[]>([])
  const [processRecordsIsOpen, setProcessRecordsIsOpen] = useState<boolean>(false)
  const [processRecordsRow, setProcessRecordsRow] = useState<ProcessRecordsRow>()
  const [processRecordsOpen, setProcessRecordsOpen] = useState<boolean>(false)
  const [processRecordsDataSource, setProcessRecordsDataSource] = useState<ProcessRecordsRow[]>([])
  const [submitRow, setSubmitRow] = useState<TaxAccountingRow>()
  const [submitterId, setSubmitterId] = useState<string>()
  const [latestProcess, setLatestProcess] = useState<string>()
  const [latestProcessBatch, setLatestProcessBatch] = useState<string[]>()
  const [summaryTotalObj, setSummaryTotalObj] = useState<TaxAccountingRowSummaryTotal>({
    taOutIrAmount: 0,
    taInIrAmount: 0,
    taOutTaxIrAmount: 0,
    taInTaxIrAmount: 0,
    taVatPayable: 0,
    personDue: 0,
    additionalTaxAmount: 0,
    amountRealized: 0,
    balance: 0,
  })
  const [searchReq, setSearchReq] = useState<TaxAccountingReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...taxAccountingStore.taxAccountingReq
  })

  /**
   * @description 打印参数
   */
  const parameter: parameterType = {
    width: 21,
    height: 29.7,
    horizontal: 1.91,
    vertical: 2.54
  }

  const columns: ColumnsType<TaxAccountingRow> = [
    {
      title: '制单日期',
      dataIndex: 'taDate',
      key: 'taDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'taCode',
      key: 'taCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '核算部门',
      dataIndex: 'taSegment',
      key: 'taSegment',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '核算企业',
      dataIndex: 'taEnterprise',
      key: 'taEnterprise',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '纳税人级别',
      dataIndex: 'taCategory',
      key: 'taCategory',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '税费类别',
      dataIndex: 'taxCategory',
      key: 'taxCategory',
      width: TABLE_CELL_WIDTH_1_5x,
      align: 'center',
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '计税方法',
      dataIndex: 'taxMethod',
      key: 'taxMethod',
      width: TABLE_CELL_WIDTH_1_5x,
      align: 'center',
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '税费摘要',
      dataIndex: 'taDesc',
      key: 'taDesc',
      width: TABLE_CELL_WIDTH_3x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联销项发票',
      dataIndex: 'invoiceRegistrationOutId',
      key: 'invoiceRegistrationOutId',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableInvoiceRegistrations
            isId
            text={text}
            promiseValue={getBatchTableInvoiceRegistrations(text ? text.split(',') : [])}
            setDataSource={setInvoiceRegisterDataSource}
            onClick={() => setInvoiceRegisterOpen(true)}></TableInvoiceRegistrations>
        </Space>
      )
    },
    {
      title: '关联进项发票',
      dataIndex: 'invoiceRegistrationInId',
      key: 'invoiceRegistrationInId',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableInvoiceRegistrations
            isId
            text={text}
            promiseValue={getBatchTableInvoiceRegistrations(text ? text.split(',') : [])}
            setDataSource={setInvoiceRegisterDataSource}
            onClick={() => setInvoiceRegisterOpen(true)}></TableInvoiceRegistrations>
        </Space>
      )
    },
    {
      title: '销项价税合计',
      dataIndex: 'taOutIrAmount',
      key: 'taOutIrAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '进项价税合计',
      dataIndex: 'taInIrAmount',
      key: 'taInIrAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '销项税额合计',
      dataIndex: 'taOutTaxIrAmount',
      key: 'taOutTaxIrAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '进项税额合计',
      dataIndex: 'taInTaxIrAmount',
      key: 'taInTaxIrAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '应缴增值税额',
      dataIndex: 'taVatPayable',
      key: 'taVatPayable-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '核算金额(附加项)',
      dataIndex: 'additionalTaxAmount',
      key: 'additionalTaxAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (_, record) => (
        <Space>
          <TableMoneyText
            value={accAdd(accAdd(accAdd(accAdd(accAdd(record.taxAccountingMiddle.cityDue || 0, record.taxAccountingMiddle.educationDue || 0), record.taxAccountingMiddle.spaceDue || 0), record.taxAccountingMiddle.printDue || 0), record.taxAccountingMiddle.unionDue || 0), record.taxAccountingMiddle.hydraulicDue || 0).toFixed(2)}/>
        </Space>
      )
    },
    {
      title: '核算金额',
      dataIndex: 'personDue',
      key: 'personDue-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (_, record) => (
        <Space>
          <TableMoneyText
            value={record.taxAccountingMiddle.personDue}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '付款账户',
      dataIndex: 'bsAccountPayId',
      key: 'bsAccountPayId',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableBankAccount
            isId
            promiseValue={getBatchTableTableBankAccount([text])}></TableBankAccount>
        </Space>
      )
    },
    {
      title: '收款账户',
      dataIndex: 'bsAccountInId',
      key: 'bsAccountInId',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableBankAccount
            isId
            promiseValue={getBatchTableTableBankAccount([text])}></TableBankAccount>
        </Space>
      )
    },
    {
      title: '关联税费计划',
      dataIndex: 'employeeFeeTaxScheduleCode',
      key: 'employeeFeeTaxScheduleCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联资金审批',
      dataIndex: 'fundingApprovalList',
      key: 'fundingApprovalList',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableFundApprovals
            value={(text as FundApprovalRow[])}
            onClick={() => {
              setFundApprovalDataSource(text)
              setFundApprovalOpen(true)
            }}></TableFundApprovals>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'fileArchive',
      key: 'fileArchive',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableFilingText id={record.fileArchive} value={record.fileArchiveCode}></TableFilingText>
        </Space>
      )
    },
    {
      title: '实现日期',
      dataIndex: 'amountRealizedDate',
      key: 'amountRealizedDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '实现金额',
      dataIndex: 'amountRealized',
      key: 'amountRealized-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '余额',
      dataIndex: 'balance',
      key: 'balance-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '办理人员',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '主办人员',
      dataIndex: 'organizersUserName',
      key: 'organizersUserName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'taStatus',
      key: 'taStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
    },
    {
      title: '校验',
      dataIndex: 'statusCommon',
      key: 'statusCommon',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <Tag color={text === '完成' ? "success" : 'error'}>{ text }</Tag>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableAuthOptions
          mainBtn={['handleEdit', 'handleView']}
          items={[
            {
              key: 'handleView',
              icon: <EllipsisOutlined/>,
              onClick: () => handleView(record)
            },
            {
              key: 'handleEdit',
              icon: <EditOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.taStatus),
              onClick: async () => await handleEdit(record)
            },
            {
              key: 'handlePrint',
              icon: <PrinterOutlined/>,
              onClick: () => handlePrint(record)
            },
            {
              key: 'handleDeleteBatch',
              icon: <DeleteOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.taStatus),
              onClick: () => handleDeleteBatch(record)
            },
          ]}></TableAuthOptions>
      )
    }
  ];

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (taxAccountingStore.taxAccountingDatasource.data) {
      setDataSource(taxAccountingStore.taxAccountingDatasource.data.items || [])
      setTotal(taxAccountingStore.taxAccountingDatasource.data.total || 0)
    }
  }, [taxAccountingStore.taxAccountingDatasource.data])

  /**
   * @description 表单数据变化
   */
  useEffect(() => {
    if (taxAccountingStore.taxAccountingRow?.taxAccountingId) {
      handleAdd(taxAccountingStore.taxAccountingRow)
      taxAccountingStore.updateTaxAccountingRow({
        ...taxAccountingStore.taxAccountingRow,
        taxAccountingId: undefined,
      })
    }
  }, [taxAccountingStore.taxAccountingRow])

  useEffect(() => {
    const objInitValue = {
      taOutIrAmount: 0,
      taInIrAmount: 0,
      taOutTaxIrAmount: 0,
      taInTaxIrAmount: 0,
      taVatPayable: 0,
      personDue: 0,
      additionalTaxAmount: 0,
      amountRealized: 0,
      balance: 0,
    }
    let obj = summaryTotalObj
    const arr = taxAccountingStore.taxAccountingDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        taOutIrAmount: accAdd(acc.taOutIrAmount, cur.taOutIrAmount),
        taInIrAmount: accAdd(acc.taInIrAmount, cur.taInIrAmount),
        taOutTaxIrAmount: accAdd(acc.taOutTaxIrAmount, cur.taOutTaxIrAmount),
        taInTaxIrAmount: accAdd(acc.taInTaxIrAmount, cur.taInTaxIrAmount),
        taVatPayable: accAdd(acc.taVatPayable, cur.taVatPayable),
        personDue: accAdd(acc.personDue, safeCompute(cur.taxAccountingMiddle?.personDue)),
        additionalTaxAmount: accAdd(acc.additionalTaxAmount, accAdd(accAdd(accAdd(accAdd(accAdd(safeCompute(cur.taxAccountingMiddle?.cityDue), safeCompute(cur.taxAccountingMiddle?.educationDue)), safeCompute(cur.taxAccountingMiddle?.spaceDue || 0)), safeCompute(cur.taxAccountingMiddle?.printDue || 0)), safeCompute(cur.taxAccountingMiddle?.unionDue || 0)), safeCompute(cur.taxAccountingMiddle?.hydraulicDue || 0))),
        amountRealized: accAdd(acc.amountRealized, safeCompute(cur.amountRealized)),
        balance: accAdd(acc.balance, safeCompute(cur.balance)),
      }), objInitValue);
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [taxAccountingStore.taxAccountingDatasource, selectedRowKeys])

  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => o.key !== 'action').filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, taxAccountingStore.collapsed, searchReq.pageNum, searchReq.perPage])

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: TaxAccountingReq) => {
    setSearchReq(req)
    taxAccountingStore.getTaxAccounting(req)
  }

  const handleFundApprovalClick = async (record: TaxAccountingRow) => {

    // 获取详情
    const {code: detailCode, data: row, message: detailMessage} = await TaxAccountingService.getOne(record.id)
    if (detailCode !== 200) {
      message.warning(detailMessage).then()
    }

    // 注意责任部门 code
    let code = ''
    if (!row.responsibilityProject) {
      message.warning("请检查责任部门！").then()
      return
    }
    let names: string[] = typeof row.responsibilityProject === 'object' ? row.responsibilityProject : row.responsibilityProject.split(',')
    names = names
      .filter(o => o)
      .filter((v, i, self) => self.indexOf(v) === i) // 去重
      .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
    if (names.length) {
      const responsibilityProject = await ProjectService.getBatchByName(names)
      if (typeof responsibilityProject === 'object' && responsibilityProject.length !== names.length) {
        message.warning("请检查责任部门！").then()
        return
      }
      code = responsibilityProject
        .map(o => o.code)
        .filter(o => o)
        .filter((v, i, self) => self.indexOf(v) === i)
        .join(',')
    }

    // 构造资金审批
    fundApprovalStore.updateFundApprovalRow({
      id: undefined,
      code,
      fundApprovalId: row.id, // 合同结算发起资金审批
      bankStatementIds: undefined,
      bankStatementCodes: undefined,
      faDate: dayjs(row.taDate), // 制单日期
      faCode: undefined, // 制单编号
      faType: undefined, // 款项类别
      faSummary: row.taDesc, // 款项摘要
      faAmount: 0, // 金额
      faPayDate: undefined, // 付款日期
      faStatus: undefined, // 办理进度
      faApplicationForm: row.id, // 关联申请id
      faApplicationFormType: '税费核算', // 关联申请类型
      fundingApprovalTables: [{type: '税费核算', businessId: row.id}],
      contractListIds: [],// 关联合同 商务合同
      contractLists: [],// 关联合同
      lnvoiceRegistration: [], // 关联发票登记
      lnvoiceRegistrationIds: [],
      personResponsibleName: row.personResponsibleName, // 办理人员
      personResponsible: row.personResponsible, // 办理人员
      organizersUserId: undefined,
      organizersUserName: undefined,
      responsibilityProject: row.responsibilityProject, // 责任部门
      cooperationUnit: ['N/A'], // 协作单位
      faFile: undefined, // 存档批号
      faFileList: undefined, // 上传文件
      faPaymentAccount: row.baNick2,
      faPaymentAccountId: row.bsAccountPayId,
      baName1: row.baName2,
      baAccount1: row.baAccount2,
      baBlank1: row.baBlank2,
      faAccountsReceivable: row.baNick1,
      faAccountsReceivableId: row.bsAccountInId,
      baName2: row.baName1,
      baAccount2: row.baBlank1,
      baBlank2: row.baAccount1,
    })
    navigate("/fundApproval")
  }

  /**
   * 打印单条数据
   * @param record
   */
  const handlePrint = async (record: TaxAccountingRow) => {
    const list = await taxAccountingStore.getPrintList(record)
    if (list) {
      setContentList(list)
      setPrintTitle('税费核算单')
      setIsPrintTable(false)
      setIsModalOpen(true)
    } else {
      message.warning('请检查')
    }
  }

  /**
   * @description 打印表格
   */
  const handlePrintTable = () => {
    // 数据处理
    setPrintDataSource(taxAccountingStore.taxAccountingDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id)))
    setIsPrintTable(true)
    setPrintTitle('税费核算表格')
    setIsModalOpen(true)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    taxAccountingStore.setCollapsed(collapsed)
  }

  const batchUpdate = (row: TaxAccountingRow) => {
    // let flag = false
    const rows = taxAccountingStore.taxAccountingDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    for (let selectedRow of rows) {
      // if (selectedRow.taStatus === ONFILE) {
      //   flag = true
      //   continue
      // }
      if (typeof row.taDate === 'object' && row.taDate) {
        selectedRow.taDate = row.taDate.format(DATE_FMT);
      }
      if (row.taCode) {
        selectedRow.taCode = row.taCode
      }
      if (typeof row.taEnterprise === 'object' && row.taEnterprise) {
        selectedRow.taEnterprise = row.taEnterprise.join(',')
      }
      if (row.taCategory) {
        selectedRow.taCategory = row.taCategory
      }
      if (row.taxCategory) {
        selectedRow.taxCategory = row.taxCategory
      }
      if (row.taxMethod) {
        selectedRow.taxMethod = row.taxMethod
      }
      if (row.taDesc) {
        selectedRow.taDesc = row.taDesc
      }
      if (typeof row.invoiceRegistrationOutId === 'object' && row.invoiceRegistrationOutId) {
        selectedRow.invoiceRegistrationOutId = row.invoiceRegistrationOutId.join(',')
        selectedRow.taOutIrAmount = row.taOutIrAmount || 0
        selectedRow.taOutTaxIrAmount = row.taOutTaxIrAmount || 0
        selectedRow.taVatPayable = row.taVatPayable || 0
      }
      if (typeof row.invoiceRegistrationInId === 'object' && row.invoiceRegistrationInId) {
        selectedRow.invoiceRegistrationInId = row.invoiceRegistrationInId.join(',')
        selectedRow.taInIrAmount = row.taInIrAmount || 0
        selectedRow.taInTaxIrAmount = row.taInTaxIrAmount || 0
        selectedRow.taVatPayable = row.taVatPayable || 0
      }
      if (row.responsibilityProject) {
        selectedRow.responsibilityProject = row.responsibilityProject;
        selectedRow.code = row.code;
      }
      if (row.additionalTaxAmount || row.additionalTaxAmount === 0) {
        selectedRow.additionalTaxAmount = row.additionalTaxAmount;
      }
      if (row.bsAccountPayId) {
        selectedRow.bsAccountPayId = row.bsAccountPayId;
        selectedRow.baNick1 = row.baNick1;
        selectedRow.baName1 = row.baName1;
        selectedRow.baAccount1 = row.baAccount1;
        selectedRow.baBlank1 = row.baBlank1;
      }
      if (row.bsAccountInId) {
        selectedRow.bsAccountPayId = row.bsAccountPayId;
        selectedRow.baNick2 = row.baNick2;
        selectedRow.baName2 = row.baName2;
        selectedRow.baAccount2 = row.baAccount2;
        selectedRow.baBlank2 = row.baBlank2;
      }
      if (row.employeeFeeTaxScheduleId) {
        selectedRow.employeeFeeTaxScheduleId = row.employeeFeeTaxScheduleId;
        selectedRow.employeeFeeTaxScheduleCode = row.employeeFeeTaxScheduleCode;
        selectedRow.responsibilityProject = row.responsibilityProject;
        selectedRow.cooperationUnit = row.cooperationUnit;
        selectedRow.code = row.code;
      }
      if (typeof row.fundApprovalIds === 'object' && row.fundApprovalIds) {
        selectedRow.fundApprovalIds = row.fundApprovalIds
      }
      if (typeof row.taxAccountingMiddle === 'object' && row.taxAccountingMiddle) {
        selectedRow.taxAccountingMiddle = row.taxAccountingMiddle
      }
      if (row.responsibilityProject) {
        selectedRow.responsibilityProject = row.responsibilityProject
      }
      if (typeof row.cooperationUnit === 'object' && row.cooperationUnit) {
        selectedRow.cooperationUnit = row.cooperationUnit.join(',');
      }
    }
    // if (flag) {
    //   message.warning(`办理进度为${ONFILE}的数据不可编辑！`).then()
    // }
    TaxAccountingService.batchUpdateTaxAccounting(rows).then((rsp) => {
      actionThen(rsp);
      setUpdateCount(updateCount + 1)
    }).catch(showErr).finally(() => setConfirmLoading(false));
  }

  // 新增
  // 资金审批发起
  // 资金审批批量发起
  const handleAdd = (row?: TaxAccountingRow) => {
    const user = securityStore.getLoggedUser()
    const initRow = {
      taStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      taDate: dayjs(),
    } as TaxAccountingRow
    Object.assign(row || {}, initRow)
    setTaxAccountingRow(row || initRow)
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  const handleView = (record: TaxAccountingRow) => {
    setTaxAccountingRow(record)
    setIsView(true)
    setIsBatchUpdate(false)
    setIsModalVisible(true);
  }

  const handleEdit = async (record: TaxAccountingRow) => {

    try {
      const rsp = await ProcessRecordsService.getProcessRecords({
        businessId: record.id,
        pageNum: 1,
        perPage: 999,
        type: TableName,
      })
      const process = rsp.items.length ? rsp.items[0].process : ''
      const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
      const con = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
      // 如果本轮有撤回 （id为空） 否则 （发起最新一轮的提交的人的id）
      setSubmitterId(con ? undefined : userId)
      setLatestProcess(process) // 发起最新一轮的标志

      setTaxAccountingRow(record)
      setIsView(false)
      setIsBatchUpdate(false)
      setIsModalVisible(true);
    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleEditBatch = async () => {

    try {

      if (selectedRowKeys.length === 0) {
        message.warning("请选择要操作的数据！").then()
        return
      }

      // 要操作的数据
      const rows = taxAccountingStore.taxAccountingDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))

      // 办理流程辅助字段：办理进度
      let taStatus: string

      // 判断是否符合批量操作条件
      const con = rows.every((o, i, arr) => {
        const firstRow = arr[0]
        taStatus = firstRow.taStatus
        if (o.personResponsible !== firstRow.personResponsible) {
          message.warning("办理人员不一致！").then()
          return false
        } else if (o.taStatus !== firstRow.taStatus) {
          message.warning("办理进度不一致！").then()
          return false
        } else return true
      })

      if (con) {

        let latestProcessList: string[] = []
        let submitterIdList: string[] = []
        for (let i = 0; i < rows.length; i++) {
          const item = rows[i]
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: item.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const process = rsp.items.length ? rsp.items[0].process : ''
          const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
          const userIdCon = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
          latestProcessList.push(process)
          submitterIdList.push(userIdCon ? undefined : userId)
        }

        // 如果提交人全部一致才赋值给参数:【提交人】
        setSubmitterId(new Set(submitterIdList).size === 1 ? submitterIdList[0] : undefined)
        setLatestProcessBatch(latestProcessList)
        setIsView(false)
        setIsBatchUpdate(true)
        setTaxAccountingRow({taStatus} as TaxAccountingRow)
        setIsModalVisible(true)

      }

    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleDeleteBatch = (record?: TaxAccountingRow) => {
    const keys = [...selectedRowKeys]
    if (!record && keys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = [];
    if (record) {
      ids.push(record.id)
    } else {
      ids = keys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        TaxAccountingService.deleteTaxAccounting(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(taxAccountingStore.taxAccountingReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const insertOrUpdate = async (row: TaxAccountingRow) => {
    if (typeof row.taDate === 'object' && row.taDate) {
      row.taDate = row.taDate.format(DATE_FMT);
    }
    if (typeof row.taEnterprise === 'object' && row.taEnterprise) {
      row.taEnterprise = row.taEnterprise.join(",")
    }
    if (row.invoiceRegistrationOutId && typeof row.invoiceRegistrationOutId === 'object') {
      row.invoiceRegistrationOutId = row.invoiceRegistrationOutId.join(',')
    }
    if (row.invoiceRegistrationOut && typeof row.invoiceRegistrationOut === 'object') {
      row.invoiceRegistrationOut = row.invoiceRegistrationOut.join(',')
    }
    if (row.invoiceRegistrationInId && typeof row.invoiceRegistrationInId === 'object') {
      row.invoiceRegistrationInId = row.invoiceRegistrationInId.join(',')
    }
    if (row.invoiceRegistrationIn && typeof row.invoiceRegistrationIn === 'object') {
      row.invoiceRegistrationIn = row.invoiceRegistrationIn.join(',')
    }
    if (typeof row.cooperationUnit === 'object') {
      if (!row.id) {
        row.cooperationUnit = []
      }
      row.cooperationUnit = row.cooperationUnit
        .filter((v, i, self) => self.indexOf(v) === i) // 去重
        .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
        .join(',');
    }
    if (row.id) {
      const rsp = await TaxAccountingService.updateTaxAccounting(row)
      actionThen(rsp)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    } else {
      const rsp = await TaxAccountingService.addTaxAccounting(row)
      actionThen(rsp)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    }
  }

  const actionThen = (rsp: ResponseEntity<any>) => {
    if (rsp.code === 200) {
      message.success("操作成功！").then()
    } else {
      message.warning(rsp.message).then()
    }
    setIsModalVisible(false);
    setTaxAccountingRow(undefined)
    refreshData(taxAccountingStore.taxAccountingReq)
    setSelectedRowKeys([])
  }

  const handleOk = (open: boolean) => {
    const row = {...taxAccountingRow!}
    basicInfo.current!.validateFields().then(async (values) => {
      setConfirmLoading(true)
      Object.assign(row, values)

      if (open) {

        // 将row和按钮先存起来
        setSubmitRow(row)

        // 弹窗选择处理人
        setProcessRecordsIsOpen(true)
        setProcessRecordsRow(undefined)

      } else {

        const user = securityStore.getLoggedUser()

        if (isBatchUpdate) {
          batchUpdate(row)
        } else {
          // 默认处理人员是自己
          if (!row.id) {
            row.personResponsible = user._id
            row.personResponsibleName = user.name
          }

          try {
            await insertOrUpdate(row)
          } catch (error) {
            setProcessRecordsIsOpen(false)
            setProcessRecordsRow(undefined)
            setConfirmLoading(false)
            setIsModalVisible(false);
            message.warning(error.message)
          }
        }

      }

    }).catch(logger.warn)
  }

  const handleSubmit = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)

      const user = securityStore.getLoggedUser()
      const btnText = row.status

      // 当类型是撤回时 默认办理人员为提交人(自己)
      if (btnText === FORM_REVOCATION_TEXT) {
        row.personResponsible = user._id || submitterId
        row.personResponsibleName = user.name
      }

      // 主表
      const subRow = {...submitRow}
      subRow.personResponsible = row.personResponsible
      subRow.personResponsibleName = row.personResponsibleName
      subRow.taStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.taStatus
      // 主表在撤回、退回、变更时->更新局部数据
      if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在转交、提交时->更新局部数据
      if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在提交时->更新局部数据
      if (btnText === FORM_COMMIT_TEXT) {
        subRow.taDate = new Date().toLocaleDateString().replace(/\//g, '-')
      }
      // 主表在结办时->更新局部数据
      if (btnText === FORM_CLOSE_TEXT) {

      }

      // 办理记录表
      row.type = TableName
      row.businessId = subRow.id // 有可能会没值
      row.status = btnText
      row.userId = user._id
      row.userName = user.name
      row.personResponsible = undefined
      row.personResponsibleName = undefined
      if (btnText === FORM_COMMIT_TEXT) {
        const date = new Date()
        row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      } else {
        row.process = latestProcess
      }
      if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
        row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
      } else {
        row.conca = `${user.name}${btnText}了。`
      }

      // 判断是否 先新增主表 后新增记录和通知表
      if (subRow.id) {

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.taCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "税费核算",
          wnDesc: `您参与办理的税费核算（${subRow.taCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      } else {

        let subRsp: ResponseEntity<TaxAccountingRow>
        try {
          subRsp = await insertOrUpdate(subRow)
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }

        if (subRsp.data) {
          // 重新赋值再提交
          row.businessId = subRsp.data.id
          await ProcessRecordsService.addProcessRecords(row)
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: subRsp.data.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const notificationScopeUserId = rsp.items
            .map(o => o.userId)
            .concat(row.person)
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(id => id !== user._id) // 过滤出不是自己的数据
            .join(',') || ''
          const notificationScopeUserName = rsp.items
            .map(o => o.userName)
            .concat((row.personName || '').split(','))
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(name => name !== user.name) // 过滤出不是自己的数据
            .join(',') || ''
          await processStore.newAssignmentNotice({
            businessId: subRsp.data.id,
            businessCode: subRsp.data.taCode,
            cooperationUnit: (subRsp.data.cooperationUnit as string).split(',')[0] || 'N/A',
            notificationScopeUserId,
            notificationScopeUserName,
            personResponsible: subRsp.data.personResponsible,
            personResponsibleName: subRsp.data.personResponsibleName,
            responsibilityProject: subRsp.data.responsibilityProject,
            type: "税费核算",
            wnDesc: `您参与办理的税费核算（${subRsp.data.taCode}），${row.conca} 请知悉！`,
            wnStatus: "未读"
          })
        } else {
          message.warning('主表保存成功，办理记录表和任务通知表提交失败')
        }

      }
      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  const handleSubmitBatch = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {

      const rows = taxAccountingStore.taxAccountingDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
      for (let i = 0; i < rows.length; i++) {

        Object.assign(row, values)

        const user = securityStore.getLoggedUser()
        const btnText = row.status

        // 当类型是撤回时 默认办理人员为提交人(自己)
        if (btnText === FORM_REVOCATION_TEXT) {
          row.personResponsible = user._id || submitterId
          row.personResponsibleName = user.name
        }

        // 主表
        const subRow = {...rows[i]}
        subRow.personResponsible = row.personResponsible
        subRow.personResponsibleName = row.personResponsibleName
        subRow.taStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.taStatus
        // 主表在撤回、退回、变更时->更新局部数据
        if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在转交、提交时->更新局部数据
        if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在提交时->更新局部数据
        if (btnText === FORM_COMMIT_TEXT) {
          subRow.taDate = new Date().toLocaleDateString().replace(/\//g, '-')
        }
        // 主表在结办时->更新局部数据
        if (btnText === FORM_CLOSE_TEXT) {

        }

        // 办理记录表
        row.type = TableName
        row.businessId = subRow.id // 有可能会没值
        row.status = btnText
        row.userId = user._id
        row.userName = user.name
        row.personResponsible = undefined
        row.personResponsibleName = undefined
        if (btnText === FORM_COMMIT_TEXT) {
          const date = new Date()
          row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
        } else {
          row.process = latestProcessBatch[i]
        }
        if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
          row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
        } else {
          row.conca = `${user.name}${btnText}了。`
        }

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.taCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "税费核算",
          wnDesc: `您参与办理的税费核算（${subRow.taCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      }

      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  /**
   * 操作记录按钮
   */
  const handleProcessRecordsClick = async (record: TaxAccountingRow) => {
    const rsp = await ProcessRecordsService.getProcessRecords({
      businessId: record.id,
      pageNum: 1,
      perPage: 999,
      type: TableName
    })
    if (!rsp) {
      message.warning('查无数据')
      return
    }
    setProcessRecordsDataSource(rsp.items)
    setProcessRecordsOpen(true)
  }

  const handleCopyClick = (record: TaxAccountingRow) => {
    const user = securityStore.getLoggedUser()
    const initRow = {
      ...record,
      id: '',
      fileArchive: '',
      taStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      taDate: dayjs(),
    }
    setTaxAccountingRow(initRow)
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        updateCount={updateCount}
        collapsed={taxAccountingStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        handleShowColumnReset={handleShowColumnReset}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={props.isModalSelect ? columns.filter(o => o.key !== 'action') : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}
        items={[
          {
            key: 'handleAdd',
            icon: <PlusOutlined/>,
            onClick: () => handleAdd()
          },
          {
            key: 'handleDeleteBatch',
            icon: <DeleteOutlined/>,
            onClick: () => handleDeleteBatch()
          },
          {
            key: 'handleEditBatch',
            icon: <EditOutlined/>,
            onClick: () => handleEditBatch()
          },
          {
            key: 'handlePrintTable',
            icon: <PrinterOutlined/>,
            onClick: () => handlePrintTable()
          },
        ]}></HeaderActionCom>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        size={"middle"}
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        loading={taxAccountingStore.taxAccountingDatasource.loading || confirmLoading}
        bordered
        pagination={!props.isModalSelect && {
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                (showColumn as string[]).map((key, index) =>
                  key.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={summaryTotalObj[key.slice(0, key.indexOf('-'))]}></TableMoneyText> 元
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys)
          },
          columnWidth: 50,
        }}/>

      { /*关联发票登记*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">关联发票登记</div>}
        open={invoiceRegisterOpen}
        width={LARGER_MODAL_WIDTH}
        onCancel={() => setInvoiceRegisterOpen(false)}
        destroyOnClose
        footer={null}>
        <InvoiceRegister
          dataSource={invoiceRegisterDataSource}
          isModalSelect
          locationPathname={'/invoiceRegister'}/>
      </CustomDraggableModal>

      { /*关联资金审批*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">关联资金审批</div>}
        open={fundApprovalOpen}
        width={LARGER_MODAL_WIDTH}
        onCancel={() => setFundApprovalOpen(false)}
        destroyOnClose
        footer={null}>
        <FundApproval
          dataSource={fundApprovalDataSource}
          isModalSelect
          locationPathname={'/fundApproval'}/>
      </CustomDraggableModal>

      { /*操作记录表*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsOpen}
        width={LARGE_MODAL_WIDTH_OTHER}
        onCancel={() => setProcessRecordsOpen(false)}
        destroyOnClose
        footer={null}>
        <ProcessRecords
          isHiddenOptions
          dataSource={processRecordsDataSource}
          isModalSelect
          locationPathname={'/processRecords'}/>
      </CustomDraggableModal>

      { /*操作记录表单*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsIsOpen}
        width={LARGE_MODAL_WIDTH}
        destroyOnClose
        onCancel={() => {
          setProcessRecordsIsOpen(false)
          setConfirmLoading(false)
        }}
        onOk={isBatchUpdate ? handleSubmitBatch : handleSubmit}>
        <ProcessRecordsBasicInfo
          tableName={TableName}
          businessId={taxAccountingRow?.id}
          submitterId={submitterId}
          userId={taxAccountingRow?.personResponsible}
          status={taxAccountingRow?.taStatus}
          row={processRecordsRow}
          ref={processRecordsBasicInfo}/>
      </CustomDraggableModal>

      <CustomForm
        title={'税费核算'}
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={isModalVisible}
        submitterId={submitterId}
        confirmLoading={confirmLoading}
        userId={taxAccountingRow?.personResponsible}
        status={taxAccountingRow?.taStatus}
        isView={isView}
        extraDisabled={!taxAccountingRow?.id}
        extraOnClick={() => handlePrint(taxAccountingRow)}
        item={[
          {
            key: 'handleFundApprovalClick',
            icon: <RetweetOutlined/>,
            hidden: !FORM_INITIATE_STATE.includes(taxAccountingRow?.taStatus),
            onClick: () => handleFundApprovalClick(taxAccountingRow)
          },
          {
            key: 'handleCopyClick',
            icon: <FieldTimeOutlined/>,
            disabled: !taxAccountingRow?.id,
            onClick: () => handleCopyClick(taxAccountingRow)
          },
          {
            key: 'handleProcessRecordsClick',
            icon: <FieldTimeOutlined/>,
            disabled: !taxAccountingRow?.id,
            onClick: () => handleProcessRecordsClick(taxAccountingRow)
          },
        ]}>
        <BasicInfo row={{...taxAccountingRow!}} ref={basicInfo} isView={isView} isBatchUpdate={isBatchUpdate}/>
      </CustomForm>

      { /*打印*/ }
      <WindowPrint
        isHiddenTime
        title={printTitle}
        contentList={contentList}
        isModalOpen={isModalOpen}
        isPrintTable={isPrintTable}
        tableColumns={columns}
        dataSource={printDataSource}
        narrowShowColumn={narrowShowColumn}
        parameter={parameter}
        handleCancel={() => {
          setIsModalOpen(false)
          setSelectedRowKeys([])
        }}/>

    </div>
  )
}))
