import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {EmolumentPlanReq, EmolumentPlanRow} from "@services/dto/emolumentPlan";
import {EmolumentPlanService} from "@services/EmolumentPlanService";
import {FilingService} from "@services/FilingService";
import {formatMoney} from "@common/utils";
import {OrganizationalService} from "@services/OrganizationalService";
import {BLACK_LIST} from "@/config";

export class EmolumentPlanStore {
  emolumentPlanDatasource: StateFullData<Page<EmolumentPlanRow>> = {}
  emolumentPlanSelectDatasource: StateFullData<Page<EmolumentPlanRow>> = {}
  emolumentPlanRow?: EmolumentPlanRow
  emolumentPlanOption?: string
  emolumentPlanDetail: StateFullData<ResponseEntity<EmolumentPlanRow>> = {}
  emolumentPlanDetails: StateFullData<ResponsesEntity<EmolumentPlanRow>> = {}
  emolumentPlanReq: EmolumentPlanReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateEmolumentPlanRow(row: EmolumentPlanRow) {
    this.emolumentPlanRow = {...row}
  }

  updateEmolumentPlanOption(option: string) {
    this.emolumentPlanOption = option
  }

  getEmolumentPlan(req: EmolumentPlanReq) {
    this.emolumentPlanDatasource = {...this.emolumentPlanDatasource, loading: true}
    this.emolumentPlanReq = {...this.emolumentPlanReq, ...req}
    EmolumentPlanService.getEmolumentPlan(req).then(rsp => {
      runInAction(() => this.emolumentPlanDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.emolumentPlanDatasource = {...this.emolumentPlanDatasource, loading: false, err})
    })
  }

  getSelectEmolumentPlan(req: EmolumentPlanReq) {
    this.emolumentPlanSelectDatasource = {...this.emolumentPlanSelectDatasource, loading: true}
    EmolumentPlanService.getEmolumentPlan(req).then(rsp => {
      runInAction(() => this.emolumentPlanSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.emolumentPlanSelectDatasource = {...this.emolumentPlanSelectDatasource, loading: false, err})
    })
  }

  getDetailById(id: string) {
    this.emolumentPlanDetail = {...this.emolumentPlanDetail, loading: true}
    EmolumentPlanService.getDetailById(id).then(rsp => {
      runInAction(() => this.emolumentPlanDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.emolumentPlanDetail = {...this.emolumentPlanDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.emolumentPlanDetails = {...this.emolumentPlanDetails, loading: true}
    EmolumentPlanService.getBatch(ids).then(rsp => {
      runInAction(() => this.emolumentPlanDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.emolumentPlanDetails = {...this.emolumentPlanDetails, loading: false, err})
    })
  }

  async getPrintList(record: EmolumentPlanRow): Promise<LabelValue[]> {

    const row = this.emolumentPlanDatasource.data?.items.find(o => o.id === record.id)

    let personResponsible = ''
    if (!BLACK_LIST.includes(row.personResponsible)) {
      const [organizationalRow] = await OrganizationalService.getBatch([row.personResponsible])
      personResponsible = organizationalRow.name
    }

    // 存档批号
    let efaFileArchiveRow = undefined
    if (row.efaFileArchive && !BLACK_LIST.includes(row.efaFileArchive)) {
      const efaFileArchiveRsp = await FilingService.getBatch([row.efaFileArchive])
      if (efaFileArchiveRsp && efaFileArchiveRsp.code !== 200) {
        return
      }
      efaFileArchiveRow = efaFileArchiveRsp.data[0]
    }

    return [
      {label: '制单日期', value: String(row.efaAccountingObject)},
      {label: '制单编号', value: row.efaApplyCode},
      {label: '薪酬类别', value: row.efaPayType},
      {label: '薪酬说明', value: row.efaTitle},
      {label: '关联立项', value: row.efaRelatedProjectApproval},
      {label: '计划金额', value: formatMoney(row.efaApplyAmount) + '元'},
      {label: '责任人', value: personResponsible},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '存档批号', value: efaFileArchiveRow?.faBatchNumber},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const emolumentPlanStore = new EmolumentPlanStore();
