import {makeAutoObservable, runInAction} from 'mobx';
import {LoginRsp} from "@services/dto/security";
import {getData, removeData, setData} from "@common/utils";
import {LOGIN_USER_KEY} from "@/config";
import _ from 'lodash';

export const getLoginRspFromCache = async () =>
  getData<LoginRsp>(LOGIN_USER_KEY);

export const logout = () => securityStore.logout();

export class SecurityStore {
  loginRsp?: LoginRsp = undefined;

  constructor() {
    makeAutoObservable(this);
    getLoginRspFromCache().then(cached => {
      if (!_.isEmpty(cached)) {
        runInAction(() => {
          this.loginRsp = cached;
        });
      }
    });
  }

  async onLoginFinished(rsp: LoginRsp) {
    await setData(LOGIN_USER_KEY, rsp);
    runInAction(() => (this.loginRsp = rsp));
  }

  async logout() {
    await removeData(LOGIN_USER_KEY);
    // 清理更多的数据
    runInAction(() => (this.loginRsp = undefined));
  }

  getLoggedUser() {
    return this.loginRsp?.user;
  }

  getLoggedUserCooperationUnits() {
    return this.loginRsp?.userCooperationUnits?.map(item=> {
      item.dictValue = item.cooperationUnitName
      return item
    });
  }

  getLoggedUserProjects() {
    return this.loginRsp?.userProjects?.map(item=> {
      item.dictValue = item.projectName
      return item
    });
  }

  getPermissions(){
    return this.loginRsp?.permissions
  }

}

export const securityStore = new SecurityStore();
