import {allStores} from "@/stores";
import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select} from "antd";
import {observer} from "mobx-react";
import {useParams} from "react-router-dom";
import {ProjectRow} from "@services/dto/project";
import {SelectProject} from "@components/SelectProject";
import {SelectDict} from "@components/SelectDict";

export interface PropsType {
    handleProjectChange:() => void
}

export const PersonalProfile = observer((props: PropsType) => {
  const {workerTypeStore} = allStores;
  const cardDict = ["身份证"]
  const params = useParams()
  const [project, setProject] = useState<ProjectRow>()

  useEffect(() => {
    workerTypeStore.getWorkerTypes()
  }, [])

  const handleChange = (value: string, options: ProjectRow[]) => {
    let project = options.find(item => item.id === value)
    props.handleProjectChange()
    setProject(project)
  }

  return (
    <Row gutter={16}>
      <Form.Item hidden name="_id">
        <Input/>
      </Form.Item>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="姓名"
          name="name"
          rules={[{required: false, message: '请输入姓名!'}]}>
          <Input placeholder="请输入姓名"/>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="性别"
          name="gender"
          rules={[{required: false, message: '请选择性别!'}]}>
          <Radio.Group>
            <Radio value={1}>女</Radio>
            <Radio value={0}>男</Radio>
          </Radio.Group>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="民族"
          name="nationality"
          rules={[{required: false, message: '请输入民族!'}]}>
          <Input placeholder="请输入民族"/>
        </Form.Item>
      </Col>

      <Col xxl={8} xs={12}>
        <Form.Item
          label="项目"
          name="projectId"
          rules={[{required: false, message: '请选择项目',}]}>
          <SelectProject allowClear disabled={!!params.id} mode={params.id ? "multiple" : undefined} onChange={handleChange}/>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="人员类别"
          name="personnelCategory"
          rules={[{required: false, message: '请选择人员类别!'}]}>
          <SelectDict disabled={!!params.id} allowClear code={"dict_personnel_category"} placeholder={"请选择人员类别"}/>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="队部"
          name="professionalLeagues"
          rules={[{required: false, message: '请选择队部',}]}>
          <Select placeholder="请选择队部" disabled={!!params.id}>
            { project?.professionalLeagues.map((item) => (
              <Select.Option key={item.name}>{ item.name }</Select.Option>)) }
          </Select>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="工种"
          name="workerTypeName"
          rules={[{required: false, message: '请选择工种',}]}>
          <Select placeholder="请选择工种" disabled={!!params.id}>
            { workerTypeStore.workerTypes.items.map((item) => (
              <Select.Option key={item.name}>{ item.name }</Select.Option>)) }
          </Select>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="职位"
          name="professional"
          rules={[{required: false, message: '请选择职位!'}]}>
          <SelectDict disabled={!!params.id} allowClear code={"dict_professional"} placeholder={"请选择职位"}/>
        </Form.Item>
      </Col>

      <Col xxl={8} xs={12}>
        <Form.Item
          label="证件类型"
          name="chinaUidType"
          rules={[{required: false, message: '请选择证件类型!'}]}>
          <Select allowClear placeholder="请选择证件类型">
            {
              cardDict.map(item => {
                return (
                  <Select.Option key={item} value={item}>{ item }</Select.Option>
                )
              })
            }
          </Select>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="身份证"
          name="chinaUid"
          rules={[{required: false, message: '请输入身份证!', pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/}]}>
          <Input placeholder="请输入身份证"/>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="银行卡号"
          name="bankCardNumber"
          rules={[{required: false, message: '请输入银行卡号!', pattern: /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/}]}>
          <Input placeholder="请输入银行卡号"/>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="开户行信息"
          name="bankInfo"
          rules={[{required: false, message: '请输入开户行信息!'}]}>
          <Input placeholder="请输入开户行信息"/>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="手机号码"
          name="phone"
          rules={[{required: false, message: '请输入手机号码!', pattern: /0?(13|14|15|17|18|19)[0-9]{9}$/}]}>
          <Input placeholder="请输入手机号码"/>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="户籍城市"
          name="cityHoldRegistration"
          rules={[{required: false, message: '请输入户籍城市!'}]}>
          <Input placeholder="请输入户籍城市"/>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="籍贯"
          name="nativePlace"
          rules={[{required: false, message: '请输入籍贯!'}]}>
          <Input placeholder="请输入籍贯"/>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="年龄"
          name="age"
          rules={[{required: false, message: '请输入年龄!'}]}>
          <InputNumber min={16} max={100} precision={0} placeholder="请输入年龄" className={'width-100-percentage'}/>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="工作年限"
          name="workingYear"
          rules={[{required: false, message: '请输入工作年限!'}]}>
          <InputNumber min={0} max={100} precision={0} placeholder="请输入工作年限" className={'width-100-percentage'}/>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="政治面貌"
          name="politicalStatus"
          rules={[{required: false, message: '请输入政治面貌!'}]}>
          <Input placeholder="请输入政治面貌"/>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="最高学历"
          name="highestRecord"
          rules={[{required: false, message: '请选择最高学历!'}]}>
          <SelectDict allowClear code={"dict_highest_record"} placeholder={"请选择最高学历"}/>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="毕业学校"
          name="graduatedSchool"
          rules={[{required: false, message: '请输入毕业学校!'}]}>
          <Input placeholder="请输入毕业学校"/>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="出生日期"
          name="dateOfBirth"
          rules={[{required: false, message: '请选择出生日期!'}]}>
          <DatePicker placeholder={"请选择出生日期"} className={'width-100-percentage'}/>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="职业等级证"
          name="skillLevel"
          rules={[{required: false, message: '请选择职业等级证!'}]}>
          <SelectDict allowClear code={"dict_skill_level"} placeholder={"请选择职业等级证"}/>
        </Form.Item>
      </Col>
      <Col xxl={8} xs={12}>
        <Form.Item
          label="所属事业部"
          name="belongToGroup"
          rules={[{required: false, message: '请选择所属事业部!'}]}>
          <SelectDict allowClear code={"dict_belong_to_group"} placeholder={"请选择所属事业部"}/>
        </Form.Item>
      </Col>
      <Col xxl={16} xs={24}>
        <Form.Item
          label="详细地址"
          name="address"
          labelCol={{span: 4}}
          wrapperCol={{span: 20}}
          rules={[{required: false, message: '请输入详细地址!'}]}>
          <Input.TextArea placeholder="请输入详细地址" autoSize={{minRows: 3, maxRows: 9}}/>
        </Form.Item>
      </Col>
    </Row>
  )
})
