import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {PayrollReq, PayrollRow} from "@services/dto/Payroll";

export class PayrollService {
  static async getPayroll(req: PayrollReq): Promise<Page<PayrollRow>> {
    return await apiV1.get("/payroll/search", {params: req})
  }

  static async addPayroll(row: PayrollRow): Promise<ResponseEntity<PayrollRow>> {
    return await apiV1.post("/payroll/save", {...row})
  }

  static async updatePayroll(row: PayrollRow): Promise<ResponseEntity<PayrollRow>> {
    return await apiV1.put("/payroll/update", {...row})
  }

  static async batchUpdatePayroll(rows: PayrollRow[]): Promise<ResponseEntity<string>> {
    return await apiV1.put("/payroll/updateBatch", [...rows])
  }

  static async deletePayroll(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/payroll/delete", {data: ids})
  }

  static async getOne(id: string): Promise<ResponseEntity<PayrollRow>> {
    return await apiV1.get(`/payroll/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponseEntity<PayrollRow[]>> {
    return await apiV1.get(`/payroll/getBatch/${ids.join(',')}`)
  }
}
