import "./index.less"
import React, {useEffect, useState} from "react";
import AMapLoader from '@amap/amap-jsapi-loader'
import {showErr} from "@common/utils";
import {Any} from "@common/types";
import {MAP_WEB_KEY} from "@/config";
import {Col, Input, message, Row} from "antd";
import {ProvinceCityDistrictService} from "@services/ProvinceCityDistrictService";

export interface PropsType {
  centerChange: (lng: number, lat: number) => void
  center?: number[]
}

export const MapContainer = (props: PropsType) => {
  const [AMap, setAMap] = useState<Any>()

  useEffect(() => {
    initAMap()
  }, [])

  const initAMap = () => {
    AMapLoader.load({
      key: MAP_WEB_KEY,                     // 申请好的Web端开发者Key，首次调用 load 时必填
      version:"2.0",              // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins:['AMap.Geolocation', 'AMap.Autocomplete', 'AMap.Geocoder'],               // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    }).then((AMap)=>{
      setAMap(AMap)
      initMap(AMap, props.center)
    }).catch(showErr)
  }

  const initMap = (AMap: Any, center: number[] | undefined) => {
    const map = new AMap.Map("container",{ //设置地图容器id
      viewMode:"2D",         //是否为3D地图模式
      zoom: 16, // 级别
      center: center,
      resizeEnable: true, // 是否监控地图容器尺寸变化，默认值为false
      expandZoomRange: true, // 是否支持可以扩展最大缩放级别,和zooms属性配合使用
      features: ['bg', 'road', 'building', 'point'], // 设置地图显示要素
      zooms: [2, 30],
      isHotspot: false
    })

    map.on('click', (e: Any) => {
      map.clearMap() // 清除地图上所有的覆盖物
      addMarker(AMap, map, e.lnglat.lng, e.lnglat.lat)
      props.centerChange(e.lnglat.lng, e.lnglat.lat)
    });
  }

  const addMarker = (AMap: Any, map: Any, lng: number, lat:number) => {
    const marker = new AMap.Marker({
      position: new AMap.LngLat(lng, lat),
      offset: new AMap.Pixel(-10, -30),
      content: '<img class="map-img" src="//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png"/>'
    });
    map.add(marker);
  }

  const handleSearch = (value: string) => {
    if (value) {
      ProvinceCityDistrictService.getGeo(value).then(rsp => {
        initMap(AMap, rsp.data.geocodes[0].location.split(","))
      }).catch(() => {
        message.warning("地址输入有误，请输入正确的地址进行搜寻！")
      });
    }
  }

  return <>
    <Row>
      <Col span={8} className={"margin-bottom-xs"} offset={16}>
        <Input.Search onSearch={handleSearch} placeholder={"请输入地点定位"} allowClear/>
      </Col>
      <Col span={24}>
        <div id="container" className="map"></div>
      </Col>
    </Row>

  </>
}