import {observer} from "mobx-react";
import {allStores} from "@/stores";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Drawer, FormInstance, Input, message, Modal, Row, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import {logger, showErr} from "@common/utils";
import {DATE_FMT, DEFAULT_MODAL_WIDTH} from "@/config";
import {ContractLedgerRow, PageParam} from "@services/dto/contractLedger";
import {ContractLedgerService} from "@services/ContractLedgerService";
import {BasicInfo} from "@modules/ContractLedger/Components/BasicInfo";

export const ContractLedger = observer(() => {
  const { contractLedgerStore } = allStores
  const basicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<ContractLedgerRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [contractLedgerRow, setContractLedgerRow] = useState<ContractLedgerRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [searchReq, setSearchReq] = useState<PageParam>({
    pageNum: 1,
    perPage: 10,
  })

  const columns: ColumnsType<ContractLedgerRow> = [
    {
      title: '合同编号',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => { handleView(record) }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '合同名称',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '合同当事人',
      dataIndex: 'user',
      key: 'user',
      align: 'center'
    },
    {
      title: '合同金额',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center'
    },
    {
      title: '合同结算金额',
      dataIndex: 'settlementAmount',
      key: 'settlementAmount',
      align: 'center'
    },
    {
      title: '合同实现金额',
      dataIndex: 'toRealizeTheAmount',
      key: 'toRealizeTheAmount',
      align: 'center'
    },
    {
      title: '合同签订日期',
      dataIndex: 'startTime',
      key: 'startTime',
      align: 'center'
    },
    {
      title: '合同关闭日期',
      dataIndex: 'endTime',
      key: 'endTime',
      align: 'center'
    },
    {
      title: '合同经办人',
      dataIndex: 'agentUser',
      key: 'agentUser',
      align: 'center'
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record, index) => (
        <Space>
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined />}
            onClick={() => { handleEdit(record) }}>修改</Button>
          <Button shape="round" className={"delete-btn"} size={"middle"}
            icon={<DeleteOutlined />} onClick={() => { handleDeleteBatch(record) }}>删除</Button>
        </Space>
      )
    }
  ];

  useEffect(() => {
    refreshData(searchReq)
  }, [])

  useEffect(() => {
    setDataSource(contractLedgerStore.contractLedgerDatasource.data?.items!)
    setTotal(contractLedgerStore.contractLedgerDatasource.data?.total!)
  }, [contractLedgerStore.contractLedgerDatasource])

  const handleAdd = () => {
    setContractLedgerRow(undefined)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handleView = (record: ContractLedgerRow) => {
    setContractLedgerRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = (record: ContractLedgerRow) => {
    setContractLedgerRow(record)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleDeleteBatch = (record?: ContractLedgerRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        ContractLedgerService.deleteContractLedger(ids).then(() => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(searchReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = { ...searchReq };
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: PageParam) => {
    contractLedgerStore.getContractLedger(req)
  }

  const actionThen = () => {
    message.success("操作成功！")
    setIsModalVisible(false);
    setContractLedgerRow(undefined)
    refreshData(searchReq)
  }

  const handleOk = () => {
    const row = { ...contractLedgerRow! }
    basicInfo.current!.validateFields().then(values => {
      Object.assign(row, values)
      row.startTime = values.time[0].format(DATE_FMT)
      row.endTime = values.time[1].format(DATE_FMT)
      setConfirmLoading(true)
      if (row.id) {
        ContractLedgerService.updateContractLedger(row).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        ContractLedgerService.addContractLedger(row).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  }

  return (
    <div className={"institution"}>
      <Row gutter={12}>
        <Col span={6}>
          <Input
            value={searchReq.name}
            onChange={(e) => setSearchReq({...searchReq, name: e.target.value})}
            onPressEnter={() => refreshData({...searchReq, pageNum: 1})}
            allowClear
            placeholder="请输入合同名称"
            suffix={<SearchOutlined onClick={() => refreshData({...searchReq, pageNum: 1})} />}
          />
        </Col>
        <Col span={6}>
          <Input
            value={searchReq.code}
            onChange={(e) => setSearchReq({...searchReq, code: e.target.value})}
            onPressEnter={() => refreshData({...searchReq, pageNum: 1})}
            allowClear
            placeholder="请输入合同编号"
            suffix={<SearchOutlined onClick={() => refreshData({...searchReq, pageNum: 1})} />}
          />
        </Col>
        <Col span={12} className={"text-align-right"}>
          <Button icon={<DeleteOutlined />} size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={() => handleDeleteBatch(undefined)}>删除</Button>
          <Button icon={<PlusOutlined />} type={"primary"} size={"middle"} shape={"round"}
            onClick={handleAdd}>新增</Button>
        </Col>
      </Row>

      <Card className={"margin-top-sm"}>
        <Table
          rowKey={"id"}
          dataSource={dataSource}
          columns={columns}
          size={"middle"}
          loading={contractLedgerStore.contractLedgerDatasource.loading || confirmLoading}
          pagination={{
            total: total,
            position: ['bottomCenter'],
            pageSize: searchReq.perPage,
            current: searchReq.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`,
            onChange: handlePageChange
          }}
          rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys }} />
      </Card>

      <Drawer
        title={<div className="text-align-center">合同台账</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        onClose={handleCancel}>
        <BasicInfo row={{ ...contractLedgerRow! }} ref={basicInfo} isView={isView} />

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button type="primary" onClick={handleOk} loading={confirmLoading} className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>
    </div>
  )
})
