import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Button, Col, Form, Input, message, Radio, Row, UploadFile} from "antd";
import {ProgressSteps} from "@modules/HR/Components/Hire";
import {allStores} from "@/stores";
import {FileRow, TodoRow, UpdateWorkerRecruitmentRow} from "@services/dto/workerRecruitment";
import {getUploadIds, showErr} from "@common/utils";
import {DATE_SECONDS_FMT, WorkerRecruitmentStatus} from "@/config";
import {ToolService} from "@services/ToolService";
import {WorkerRecruitmentService} from "@services/WorkerRecruitmentService";
import dayjs from "dayjs";

export const Exit:FC = observer(() => {
  const {wrStore} = allStores;
  const {wr: stateWr} = wrStore
  const wr = stateWr.data!;
  const [form] = Form.useForm();
  const [quit, setQuit] = useState<string>("exited")
  const [disable, setDisable] = useState<boolean>(false)

  useEffect(() => {
    setDisable(false)
    if (wr.remark) {
      form.resetFields();
      setQuit("abnormalexited")
      form.setFieldsValue(wr);
      setDisable(true)
    }
    if (wr.exitTodoChecked.length > 0) {
      setQuit("exited")
      setDisable(true)
    }
  }, [wr])

  const onNext = async (row: TodoRow, index: number, lastStep: boolean, fileList?: UploadFile[]) => {
    const checked = [...wr.exitTodoChecked || []]
    if (fileList) {
      const files: FileRow[] = []
      fileList.forEach(item => {
        let url = item.response ? item.response.url : item.url;
        files.push({
          name: "",
          fileUrl: url,
          createdAt: dayjs().format(DATE_SECONDS_FMT)
        })
      })
      let ids = getUploadIds(fileList)
      if (ids) {
        try {
          await ToolService.submittedSuccess(ids, row.id);
        } catch (e) {
          showErr(e)
          return
        }
      }
      checked[index] = {
        ...checked[index],
        typeId: row.title,
        files
      };
    } else {
      checked[index] = {
        ...checked[index],
        typeId: row.title,
        type: row.type,
        isToDo: true,
      };
    }

    const wrObj: UpdateWorkerRecruitmentRow = {
      exitTodoChecked: checked.map(item => ({
        typeId: item.typeId,
        isToDo: item.isToDo,
        type: item.type,
        files: item.files
      }))
    }
    if (lastStep && !checked.some(i => !i.isToDo)) {
      wrObj.status = WorkerRecruitmentStatus.exited
    }
    WorkerRecruitmentService.updateWorkerRecruitment(wr._id, wrObj).then(rsp => {
      message.success(row.title + "完成")
      wrStore.updateExitTodo(checked)
    }).catch(showErr)
  }

  const onFinish = (values: any) => {
    WorkerRecruitmentService.exitWorkerRecruitment(wr._id, values.remark).then(rsp => {
      message.success("离职完成");
      wrStore.updateRemark(values.remark)
    }).catch(showErr)
  }

  return(
    <>
      <Radio.Group value={quit} onChange={(e) => setQuit(e.target.value)} buttonStyle="solid" disabled={disable}>
        <Radio.Button value="exited">正常离职</Radio.Button>
        <Radio.Button value="abnormalexited">非正常离职</Radio.Button>
      </Radio.Group>
      {
        quit === "exited" ?
          <ProgressSteps rows={wr.exitTodo} finalStepLabel={'完成离职'} checked={wr.exitTodoChecked} onNext={onNext}/> :
          <Form
            name="basic"
            form={form}
            labelCol={{span: 4}}
            wrapperCol={{span: 16}}
            onFinish={onFinish}
            autoComplete="off">
            <Row>
              <Form.Item hidden name="_id">
                <Input/>
              </Form.Item>
              <Col span={24} className={"margin-top-xs"}>
                <Form.Item
                  label="离职原因"
                  name="remark"
                  rules={[{required: true, message: '请输入离职原因!'}]}>
                  <Input.TextArea placeholder="请输入离职原因" autoSize={{minRows: 3, maxRows: 9}} disabled={disable}/>
                </Form.Item>
              </Col>
              {
                !disable &&
                      <Col span={16}>
                        <Form.Item className={"text-align-center"}>
                          <Button type="primary" htmlType="submit">
                            提交
                          </Button>
                        </Form.Item>
                      </Col>
              }
            </Row>
          </Form>
      }
    </>
  )
})