import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {FinalSettlementReq, FinalSettlementRow} from "@services/dto/finalSettlement";

export class FinalSettlementService {
  static async getFinalSettlement(req: FinalSettlementReq): Promise<Page<FinalSettlementRow>> {
    return await apiV1.get("/finalSettlement/search", {params: req})
  }

  static async addFinalSettlement(row: FinalSettlementRow): Promise<ResponseEntity<FinalSettlementRow>> {
    return await apiV1.post("/finalSettlement/save", {...row})
  }

  static async updateFinalSettlement(row: FinalSettlementRow): Promise<ResponseEntity<FinalSettlementRow>> {
    return await apiV1.put("/finalSettlement/update", {...row})
  }

  static async batchUpdateFinalSettlement(rows: FinalSettlementRow[]): Promise<ResponseEntity<FinalSettlementRow>> {
    return await apiV1.put("/finalSettlement/updateBatch", [...rows])
  }

  static async deleteFinalSettlement(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/finalSettlement/delete", {data: ids})
  }

  static async getOne(id: string): Promise<ResponseEntity<FinalSettlementRow>> {
    return await apiV1.get(`/finalSettlement/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponseEntity<FinalSettlementRow[]>> {
    return await apiV1.get(`/finalSettlement/getBatch/${ids.join(',')}`)
  }
}
