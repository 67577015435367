import {apiV1} from "@services/apiHelper";
import {SuppliesRow, PageParam, Recipient} from "@services/dto/supplies";
import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";

export class SuppliesService {
  static async addSupplies(entity: SuppliesRow): Promise<SuppliesRow> {
    return await apiV1.post('/supplies', {...entity});
  }

  static async getSupplies(req: PageParam): Promise<Page<SuppliesRow>> {
    return await apiV1.get('/supplies/search', {params: req});
  }

  static async updateSupplies(id: string, entity: SuppliesRow): Promise<SuppliesRow> {
    return await apiV1.put(`/supplies/${id}`, {...entity});
  }

  static async deleteSupplies(id: string): Promise<SuppliesRow> {
    return await apiV1.delete(`/supplies/${id}`);
  }

  static async getSuppliesById(id: string): Promise<SuppliesRow> {
    return await apiV1.get(`/supplies/${id}`);
  }

  static async getWorkerRecruitmentsUser(projectId: string): Promise<ResponsesEntity<Recipient>> {
    return await apiV1.get("/workerRecruitments/user", {params: {projectId}});
  }

  static async downloadTemplate(): Promise<Blob> {
    return await apiV1.get("/supplies/template")
  }

  static async exportTemplate(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.post("/supplies/template/export", {ids})
  }
}
