import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Select} from "antd";
import {CLOSE, END, ONFILE, PROCESS, READY} from "@/config";

export interface PropsType {
  disabled?: boolean
  onChange?: (v: string) => void
  placeholder?: string
  value?: string
  className?: string
  mode?: 'multiple' | 'tags'
  allowClear?: boolean
}

export const SelectProcessStatus = observer((props: PropsType) => {
  const [value, setValue] = useState<string[] | string>()

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const onChange = (newValue: string) => {
    setValue(newValue);
    if (props.onChange) {
      props.onChange(newValue)
    }
  }

  return (
    <>
      <Select
        allowClear={props.allowClear}
        value={value}
        disabled={props.disabled}
        className={props.className}
        onChange={onChange}
        mode={props.mode}
        placeholder={props.placeholder || "请选择办理进度"}
        options={[
          {label: '空白', value: '空白'},
          {label: 'N/A', value: 'N/A'},
          {label: READY, value: READY},
          {label: PROCESS, value: PROCESS},
          {label: CLOSE, value: CLOSE},
          {label: ONFILE, value: ONFILE},
          {label: END, value: END},
        ]}
      />

    </>
  )
})
