import {ResponsesEntity, StateFullData} from "@services/dto/common";
import {FormStatusReq, FormStatusRow} from "@services/dto/formStatus";
import {makeAutoObservable, runInAction} from "mobx";
import {FormStatusService} from "@services/FormStatusService";

class FormStatusStore {
  formStatusDatasource: StateFullData<ResponsesEntity<FormStatusRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getFormStatus(req: FormStatusReq) {
    this.formStatusDatasource = {...this.formStatusDatasource, loading: true}
    FormStatusService.getFormStatus(req).then(response => {
      runInAction(() => this.formStatusDatasource = {data: response, loading: false})
    }).catch(err => {
      runInAction(() => this.formStatusDatasource = {...this.formStatusDatasource, loading: false, err})
    })
  }
}

export const formStatusStore = new FormStatusStore()
