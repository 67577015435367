import React, {FC, useEffect, useLayoutEffect, useState} from "react";
import {Button, Drawer, message, Modal, Space, Table, TableColumnsType} from 'antd'
import {ColumnsType} from "antd/es/table";
import {FormInstance} from "antd/es";
import {BasicInfo} from './Components/BasicInfo'
import {allStores} from "@/stores";
import {observer} from "mobx-react";
import {logger, safeCompute, showErr} from "@common/utils";
import {
  DEFAULT_MODAL_WIDTH,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  EDIT_TEXT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x
} from "@/config";
import "./index.less"
import {TableOptions} from "src/components/TableOptions";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {useLocation} from "react-router-dom";
import {HeaderActionCom} from "@modules/MenuPermissions/Components/HeaderActionCom";
import {MenuPermissionsReq, MenuPermissionsRow} from "@services/dto/menuPermissions";
import {MenuPermissionsService} from "@services/MenuPermissionsService";
import {DeleteOutlined, EllipsisOutlined, PlusOutlined} from "@ant-design/icons";
import {ResponseEntity} from "@services/dto/common";

const blackColumnList = [
  'action',
]

const wideShowColumn: string[] = [
  'name',
  'title',
  'type',
  'show',
  'action',
]

const narrowShowColumn: string[] = [
  'name',
  'title',
  'type',
  'show',
  'action',
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: MenuPermissionsRow[] // 数据源
  setFundApprovalSelectedRowKeys?: (key: React.Key[]) => void // 所选行
}

export const MenuPermissions: FC = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const location = useLocation()
  const {
    menuPermissionsStore,
    headerSearchStore,
    tableColumnsStore,
    screenResolutionStore
  } = allStores;
  const basicInfo = React.createRef<FormInstance>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isView, setIsView] = useState<boolean>(false)
  const [menuPermissionsRow, setMenuPermissionsObj] = useState<MenuPermissionsRow>()
  const [dataSource, setDataSource] = useState<MenuPermissionsRow[]>([]);
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<MenuPermissionsRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [total, setTotal] = useState<number>(0)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [menuPermissionsReq, setMenuPermissionsReq] = useState<MenuPermissionsReq>({
    perPage: 10,
    pageNum: 1,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...menuPermissionsStore.menuPermissionsReq
  })

  const columns: TableColumnsType<MenuPermissionsRow> = [
    {
      title: '名称',
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '节点名称',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    /*{
      title: '菜单图标',
      dataIndex: 'icon',
      key: 'icon',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },*/
    /*
    {
      title: '路由地址',
      dataIndex: 'path',
      key: 'path',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '组件地址',
      dataIndex: 'viewPath',
      key: 'viewPath',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },*/
    {
      title: '菜单类型',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space style={{color: text === 0 ? '#44000A' : text === 1 ? '#108ee9' : '#87d068'}}>
          <TableEllipsisText value={text === 0 ? '目录' : text === 1 ? '菜单' : '按钮'}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '权限标识',
      dataIndex: 'perms',
      key: 'perms',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    /*{
      title: '后台存活',
      dataIndex: 'keepAlive',
      key: 'keepAlive',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },*/
    {
      title: '显示办理进度',
      dataIndex: 'show',
      key: 'show',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text ? '显示' : '隐藏'}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '排序',
      dataIndex: 'orderNum',
      key: 'orderNum',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text.toString()}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableOptions
          items={[
            <Button
              block
              className={'text-align-left'}
              type={"text"}
              disabled={record.type === 2}
              onClick={() => handleChildAdd(record)}>
              <PlusOutlined/>
              添加
            </Button>,
            <Button
              block
              className={'text-align-left'}
              type={"text"}
              onClick={() => handleView(record)}>
              <EllipsisOutlined/>
              详情
            </Button>,
            <Button
              block
              className={'text-align-left'}
              type={"text"}
              danger
              onClick={() => handleDelete(record)}>
              <DeleteOutlined/>
              删除
            </Button>,
          ]}
          onClick={() => handleEdit(record)}>
          { EDIT_TEXT }
        </TableOptions>
      )
    }
  ];

  /**
   * @description 初始化
   */
  useEffect(() => {

    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(menuPermissionsReq)
    }
  }, [])

  /**
   * @description 全局查询
   */
  useEffect(() => {
    const req = {...menuPermissionsReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setMenuPermissionsReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (menuPermissionsStore.menuPermissionsDatasource.data) {
      setDataSource(transFormData(menuPermissionsStore.menuPermissionsDatasource.data.data || []))
      setTotal(menuPermissionsStore.menuPermissionsDatasource.data.data.length || 0)
    }
  }, [menuPermissionsStore.menuPermissionsDatasource.data])

  /**
   * 列配置变化
   */
  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => !blackColumnList.includes(o.key as string)).filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, menuPermissionsReq, menuPermissionsStore.collapsed])

  const transFormData = (row: MenuPermissionsRow[]) => {
    const resRow = JSON.parse(JSON.stringify(row))
    for (let i = 0; i < resRow.length; i++) {
      const item = resRow[i]
      if (item.child.length) {
        item.child = transFormData(item.child)
      } else {
        delete item.child
      }
    }
    return resRow
  }

  const refreshData = (req: MenuPermissionsReq) => {
    setMenuPermissionsReq(req)
    menuPermissionsStore.getMenuPermissions(req)
  }

  const handleDelete = (record: MenuPermissionsRow) => {
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        MenuPermissionsService.deleteMenuPermissionsById(record.id).then((rsp) => {
          if (rsp.code === 200) {
            message.success("删除成功！").then()
          } else {
            message.success(rsp.message).then()
          }
          setConfirmLoading(false)
          refreshData(menuPermissionsReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handleEdit = (record: MenuPermissionsRow) => {
    setMenuPermissionsObj(record)
    setIsModalVisible(true);
    setIsView(false)
  }

  const handleAdd = () => {
    setMenuPermissionsObj(undefined)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleChildAdd = (record: MenuPermissionsRow) => {
    setMenuPermissionsObj({parentId: record.id} as MenuPermissionsRow)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleView = (record: MenuPermissionsRow) => {
    setMenuPermissionsObj(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleOk = () => {
    const row = {...menuPermissionsRow!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(row, values)

      setConfirmLoading(true)
      if (row.id) {
        MenuPermissionsService.updateMenuPermissionsById(row.id, row).then(rsp => {
          setUpdateCount(updateCount + 1)
          actionThen(rsp)
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        MenuPermissionsService.addMenuPermissions(row).then(rsp => {
          setUpdateCount(updateCount + 1)
          actionThen(rsp)
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setMenuPermissionsObj(undefined)
  };

  const actionThen = (rsp:ResponseEntity<MenuPermissionsRow>) => {
    if (rsp.code === 200) {
      message.success("操作成功！").then()
    } else {
      message.warning(rsp.message).then()
    }
    setIsModalVisible(false);
    setMenuPermissionsObj(undefined)
    refreshData(menuPermissionsReq)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let menuPermissionsRequest = {...menuPermissionsReq};
    menuPermissionsRequest.perPage = pageSize
    menuPermissionsRequest.pageNum = page
    setMenuPermissionsReq(menuPermissionsRequest)
    refreshData(menuPermissionsRequest)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    menuPermissionsStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  return (
    <div className="menuPermissions">

      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        isHiddenOptions={props.isHiddenOptions}
        updateCount={updateCount}
        collapsed={menuPermissionsStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={menuPermissionsReq}
        handleShowColumnReset={handleShowColumnReset}
        // handleDeleteBatch={handleDeleteBatch}
        // handleAdd={handleAdd}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        items={[
          <Button
            block
            className={'text-align-left'}
            type={"text"}
            onClick={() => handleAdd()}>
            <PlusOutlined/>
            添加
          </Button>,
        ]}
        tableColumns={props.isModalSelect ? columns.filter(o => !blackColumnList.includes(o.key as string)) : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...menuPermissionsReq, ...req})}></HeaderActionCom>

      <Table
        className={"margin-top-xs"}
        bordered
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        size={"middle"}
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        loading={props.dataSource ? false : menuPermissionsStore.menuPermissionsDatasource.loading || confirmLoading}
        pagination={!props.dataSource && {
          total: total,
          position: ['bottomCenter'],
          pageSize: total,
          current: 1,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        expandable={{
          childrenColumnName: 'child',
        }}/>

      <Drawer
        title={<div className="text-align-center">菜单权限</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        destroyOnClose
        onClose={handleCancel}>

        <BasicInfo row={{...menuPermissionsRow!}} isView={isView} ref={basicInfo}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button
              type="primary"
              onClick={handleOk}
              loading={confirmLoading}
              className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>
    </div>
  )
}))
