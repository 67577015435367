import React, {useEffect} from "react";
import {BasicInfo as ConstListBasicInfo} from "@modules/ContractList/Components/BasicInfo";
import {BasicInfo as ContractSettlementBasicInfo} from "@modules/ContractSettlement/Components/BasicInfo";
import {BasicInfo as FundApprovalBasicInfo} from "@modules/FundApproval/Components/BasicInfo";
import {BasicInfo as BankStatementBasicInfo} from "@modules/BankStatement/Components/BasicInfo";
import {BasicInfo as CostApplicationBasicInfo} from "@modules/CostApplication/Components/BasicInfo";
import {BasicInfo as CostExpendBasicInfo} from "@modules/CostExpend/Components/BasicInfo";
import {BasicInfo as StaffLoanBasicInfo} from "@modules/StaffLoan/Components/BasicInfo";
import {BasicInfo as CostReimbursementBasicInfo} from "@modules/CostReimbursement/Components/BasicInfo";
import {BasicInfo as FinalSettlementBasicInfo} from "@modules/FinalSettlement/Components/BasicInfo";
import {BasicInfo as InvoiceRegisterBasicInfo} from "@modules/InvoiceRegister/Components/BasicInfo";
import {BasicInfo as PayrollBasicInfo} from "@modules/Payroll/Components/BasicInfo";
import {BasicInfo as TaxAccountingBasicInfo} from "@modules/TaxAccounting/Components/BasicInfo";
import {BasicInfo as WorkScheduleBasicInfo} from "@modules/WorkSchedule/Components/BasicInfo";
import {allStores} from "@/stores";
import {observer} from "mobx-react";
import {FormInstance} from "antd";

export interface PropsType {
  type: string
  code: string
}

export const OtherBasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const {contractListStore,
    contractSettlementStore,
    fundApprovalStore,
    bankStatementStore,
    costApplicationStore,
    costExpendStore,
    staffLoanStore,
    costReimbursementStore,
    finalSettlementStore,
    invoiceRegisterStore,
    payrollStore,
    workScheduleStore,
    taxAccountingStore} = allStores

  useEffect(() => {
    if (props.type) {
      refreshData(props.code)
    }
  }, [props.type])

  const refreshData = (code: string) => {
    if (props.type.includes("合同审批")) {
      contractListStore.getContractList({pageNum: 1, perPage: 10, clCode: code})
    } else if (props.type.includes("合同结算")) {
      contractSettlementStore.getContractSettlement({pageNum: 1, perPage: 10, csCode: code})
    } else if (props.type.includes("资金审批")) {
      fundApprovalStore.getFundApproval({pageNum: 1, perPage: 10, faCode: code})
    } else if (props.type.includes("资金出纳")) {
      bankStatementStore.getBankStatement({pageNum: 1, perPage: 10, bsCode: code})
    } else if (props.type.includes("费用申请")) {
      costApplicationStore.getCostApplication({pageNum: 1, perPage: 10, efaApplyCode: code})
    } else if (props.type.includes("费用支出")) {
      costExpendStore.getCostExpend({pageNum: 1, perPage: 10, efaApplyCode: code})
    } else if (props.type.includes("员工借款")) {
      staffLoanStore.getStaffLoanRow({pageNum: 1, perPage: 10, elReqCode: code})
    } else if (props.type.includes("费用报销")) {
      costReimbursementStore.getCostReimbursement({pageNum: 1, perPage: 10, erApprovalCode: code})
    } else if (props.type.includes("税费缴纳")) {
      finalSettlementStore.getFinalSettlement({pageNum: 1, perPage: 10, fsCode: code})
    } else if (props.type.includes("发票登记")) {
      invoiceRegisterStore.getInvoiceRegister({pageNum: 1, perPage: 10, irInvoiceCode2: code})
    } else if (props.type.includes("工资发放")) {
      payrollStore.getPayroll({pageNum: 1, perPage: 10, payrollNumber: code})
    } else if (props.type.includes("税费核算")) {
      taxAccountingStore.getTaxAccounting({pageNum: 1, perPage: 10, taCode: code})
    } else if (props.type.includes("工作日程")) {
      workScheduleStore.getWorkSchedule({pageNum: 1, perPage: 10, wsCode: code})
    }
  }

  return (
    <>
      {
        props.type?.includes("合同审批") && <ConstListBasicInfo row={{...contractListStore.contractListDatasource.data?.items[0]!}} ref={ref} isView/>
      }
      {
        props.type?.includes("合同结算") && <ContractSettlementBasicInfo row={{...contractSettlementStore.contractSettlementDatasource.data?.items[0]!}} ref={ref} isView/>
      }
      {
        props.type?.includes("资金审批") && <FundApprovalBasicInfo row={{...fundApprovalStore.fundApprovalDatasource.data?.items[0]!}} ref={ref} isView/>
      }
      {
        props.type?.includes("资金出纳") && <BankStatementBasicInfo row={{...bankStatementStore.bankStatementDatasource.data?.items[0]!}} ref={ref} isView/>
      }
      {
        props.type?.includes("费用申请") && <CostApplicationBasicInfo row={{...costApplicationStore.costApplicationDatasource.data?.items[0]!}} ref={ref} isView/>
      }
      {
        props.type?.includes("费用支出") && <CostExpendBasicInfo row={{...costExpendStore.costExpendDatasource.data?.items[0]!}} ref={ref} isView/>
      }
      {
        props.type?.includes("员工借款") && <StaffLoanBasicInfo row={{...staffLoanStore.staffLoanDatasource.data?.items[0]!}} ref={ref} isView/>
      }
      {
        props.type?.includes("费用报销") && <CostReimbursementBasicInfo row={{...costReimbursementStore.costReimbursementDatasource.data?.items[0]!}} ref={ref} isView/>
      }
      {
        props.type?.includes("税费缴纳") && <FinalSettlementBasicInfo row={{...finalSettlementStore.finalSettlementDatasource.data?.items[0]!}} ref={ref} isView/>
      }
      {
        props.type?.includes("发票登记") && <InvoiceRegisterBasicInfo row={{...invoiceRegisterStore.invoiceRegisterDatasource.data?.items[0]!}} ref={ref} isView/>
      }
      {
        props.type?.includes("工资发放") && <PayrollBasicInfo row={{...payrollStore.payrollDatasource.data?.items[0]!}} ref={ref} isView/>
      }
      {
        props.type?.includes("税费核算") && <TaxAccountingBasicInfo row={{...taxAccountingStore.taxAccountingDatasource.data?.items[0]!}} ref={ref} isView/>
      }
      {
        props.type?.includes("工作日程") && <WorkScheduleBasicInfo row={{...workScheduleStore.workScheduleDatasource.data?.items[0]!}} ref={ref} isView/>
      }
    </>
  )
}))
