import {SubsidyService} from "@services/SubsidyService";
import {Page, StateFullData} from "@services/dto/common";
import {PageParam, SubsidyRow} from "@services/dto/subsidy";
import {makeAutoObservable, runInAction} from "mobx";

export class SubsidyStore {
  subsidyDatasource: StateFullData<Page<SubsidyRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getSubsidies(req: PageParam) {
    this.subsidyDatasource = {...this.subsidyDatasource, loading: true}
    SubsidyService.getSubsidies(req).then(rsp => {
      runInAction(() => this.subsidyDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.subsidyDatasource = {...this.subsidyDatasource, loading: false, err})
    })
  }
}

export const subsidyStore = new SubsidyStore()
