import React, {useState} from "react";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {HeaderAction} from "@components/HeaderAction";
import {ColumnsType} from "antd/es/table";
import {ProjectReq} from "@services/dto/project";
import {SelectProject} from "@components/SelectProject";
import {SelectDict} from "@components/SelectDict";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {DatePicker} from "antd";
import dayjs from "dayjs";
import {SelectFiling} from "@components/SelectFiling";

export interface PropsType {
  updateCount?: number, // 更新渲染标志
  collapsed?: boolean, // 是否展开
  handleShowColumnReset?: () => void, // 重置列操作
  setCollapsed?: (v: boolean) => void, // 展开事件
  searchReq?: ProjectReq, // 请求参数
  selectedRowKeys?: React.Key[], // 所选行
  handleDeleteBatch?: () => void,
  handleAdd?: () => void,
  handleTableColumnsChange?: (value: string[]) => void,
  showColumn?: string[],
  tableColumns?: ColumnsType,
  refreshData?: (req: ProjectReq) => void,
  size?: number
  isHiddenOptions?: boolean
  locationPathname?: string
  items?: React.ReactNode[]
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<ProjectReq>({
    pageNum: 1,
    perPage: 10,
  })

  const refreshData = (req: ProjectReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handlePidChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.pid = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleSuperiorTypeChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.superiorType = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleTypeChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.type = value
    setSearchReq(req)
    refreshData(req)
  }

  const handlePrincipalChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.principal = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleDateOfEstablishmentChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, [start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.dateOfEstablishment1 = start
    req.dateOfEstablishment2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleDissolutionDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, [start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.dissolutionDate1 = start
    req.dissolutionDate2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleStatusChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.status = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleFileChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.file = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined

    req.type = undefined
    req.pid = undefined
    req.dateOfEstablishment1 = undefined
    req.dateOfEstablishment2 = undefined
    req.dissolutionDate1 = undefined
    req.dissolutionDate2 = undefined
    req.superiorType = undefined
    req.file = undefined
    req.principal = undefined
    req.principalName = undefined
    req.status = undefined

    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <HeaderAction
        size={props.size}
        isHiddenOptions={props.isHiddenOptions}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        selectedRowKeys={props.selectedRowKeys}
        handleDeleteBatch={props.handleDeleteBatch}
        handleAdd={props.handleAdd}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <SelectDict
            onChange={handleTypeChange}
            className={'width-100-percentage'}
            value={searchReq?.type}
            updateCount={props.updateCount}
            allowClear
            code={'dict_level'}
            placeholder={'请选择部门类别'}/>,
          <SelectProject
            value={searchReq?.pid}
            onChange={handlePidChange}
            className={'width-100-percentage'}
            placeholder={"请选择上级部门"}
            allowClear/>,
          <DatePicker.RangePicker
            value={searchReq?.dateOfEstablishment1 ? [dayjs(searchReq?.dateOfEstablishment1), dayjs(searchReq?.dateOfEstablishment2)] : [undefined, undefined]}
            placeholder={['成立开始日期', '成立结束日期']}
            onChange={handleDateOfEstablishmentChange}
            className={"width-100-percentage"}/>,
          <DatePicker.RangePicker
            value={searchReq?.dissolutionDate1 ? [dayjs(searchReq?.dissolutionDate1), dayjs(searchReq?.dissolutionDate2)] : [undefined, undefined]}
            placeholder={['解散开始日期', '解散结束日期']}
            onChange={handleDissolutionDateChange}
            className={"width-100-percentage"}/>,
          <SelectDict
            onChange={handleSuperiorTypeChange}
            className={'width-100-percentage'}
            value={searchReq?.superiorType}
            updateCount={props.updateCount}
            allowClear
            code={'dict_level'}
            placeholder={'请选择部门级别'}/>,
          <SelectFiling
            placeholder={"请选择存档批号"}
            allowClear
            isFaBatchNumberGroupBy
            fieldNames={{label: 'faBatchNumber', value: 'faBatchNumber'}}
            onChange={handleFileChange}
            value={searchReq?.file}
            className={'width-100-percentage'}
            isHiddenOptions/>,
          <SelectPersonCharge
            value={searchReq?.principal}
            onChange={handlePrincipalChange}
            className={"width-100-percentage"}
            placeholder={"请选择负责人"}
            fieldNames={{value: 'name', label: 'name'}}
            allowClear/>,
          <SelectDict
            value={searchReq?.status}
            onChange={handleStatusChange}
            className={'width-100-percentage'}
            updateCount={props.updateCount}
            allowClear
            placeholder={'请选择办理进度'}
            code={'common_type3'}
          />,
        ] : []}/>
    </>
  )
}))
