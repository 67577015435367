import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {Space, Table} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {DEFAULT_TABLE_SCROLL_HEIGHT, TABLE_CELL_WIDTH_1_5x, TABLE_CELL_WIDTH_1x, TABLE_CELL_WIDTH_2x,} from "@/config";
import {JobAccountReq, JobAccountRow, JobAccountRowSummaryTotal} from "@services/dto/jobAccount";
import {TableMoneyText} from "@components/TableMoneyText";
import {safeCompute} from "@common/utils";
import {HeaderActionCom} from "@modules/JobAccount/Components/HeaderActionCom";
import {useLocation} from "react-router-dom";

const wideShowColumn: string[] = [
  'tsPlanComp',
  'tsLevel',
  'five-summation',
  'four-summation',
  'three-summation',
  'two-summation',
  'one-summation',
  'total-summation',
  'organizersUserName',
  'personResponsibleName',
  'responsibilityProject',
]

const narrowShowColumn: string[] = [
  'tsPlanComp',
  'tsLevel',
  'five-summation',
  'four-summation',
  'three-summation',
  'two-summation',
  'one-summation',
  'total-summation',
  'organizersUserName',
  'personResponsibleName',
  'responsibilityProject',
]

export const JobAccount = observer(() => {
  const {
    jobAccountStore,
    screenResolutionStore,
    tableColumnsStore
  } = allStores
  const location = useLocation()
  const [total, setTotal] = useState<number>(0)
  const [dataSource, setDataSource] = useState<JobAccountRow[]>([])
  const [renderColumns, setRenderColumns] = useState<ColumnsType<JobAccountRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchReq, setSearchReq] = useState<JobAccountReq>({
    pageNum: 1,
    perPage: 10,
    condition: '任务状态统计表-主办人员',
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
  })
  const [summaryTotalObj, setSummaryTotalObj] = useState<JobAccountRowSummaryTotal>({
    one: 0, // 已完成滞后
    two: 0, // 已完成按期
    three: 0, // 已完成提前
    four: 0, // 未完成拖期
    five: 0, // 未完成正常
    total: 0, // 未完成正常
  })

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  const columns: ColumnsType<JobAccountRow> = [
    {
      title: '统计月份',
      dataIndex: 'tsPlanComp',
      key: 'tsPlanComp',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '任务级别',
      dataIndex: 'tsLevel',
      key: 'tsLevel',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText
            value={safeCompute(text) === 1 ? '一级' : (safeCompute(text) === 2 ? '二级' : (safeCompute(text) === 3) ? '三级' : '/')}/>
        </Space>
      )
    },
    {
      title: '未完成-正常',
      dataIndex: 'five',
      key: 'five-summation',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text, record) => (
        <Space>
          <div>
            { safeCompute(text) || 0 }
            &nbsp;(<TableMoneyText isPercentage value={
              (safeCompute(text) || 0) / ((safeCompute(record.one) || 0) +
                  (safeCompute(record.two) || 0) +
                  (safeCompute(record.three) || 0) +
                  (safeCompute(record.four) || 0) +
                  (safeCompute(record.five) || 0)) || 0
            }></TableMoneyText>)
          </div>
        </Space>
      )
    },
    {
      title: '未完成-拖期',
      dataIndex: 'four',
      key: 'four-summation',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text, record) => (
        <Space>
          <div>
            { safeCompute(text) || 0 }
            &nbsp;(<TableMoneyText isPercentage value={
              (safeCompute(text) || 0) / ((safeCompute(record.one) || 0) +
                  (safeCompute(record.two) || 0) +
                  (safeCompute(record.three) || 0) +
                  (safeCompute(record.four) || 0) +
                  (safeCompute(record.five) || 0)) || 0
            }></TableMoneyText>)
          </div>
        </Space>
      )
    },
    {
      title: '已完成-提前',
      dataIndex: 'three',
      key: 'three-summation',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text, record) => (
        <Space>
          <div>
            { safeCompute(text) || 0 }
            &nbsp;(<TableMoneyText isPercentage value={
              (safeCompute(text) || 0) / ((safeCompute(record.one) || 0) +
                  (safeCompute(record.two) || 0) +
                  (safeCompute(record.three) || 0) +
                  (safeCompute(record.four) || 0) +
                  (safeCompute(record.five) || 0)) || 0
            }></TableMoneyText>)
          </div>
        </Space>
      )
    },
    {
      title: '已完成-按期',
      dataIndex: 'two',
      key: 'two-summation',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text, record) => (
        <Space>
          <div>
            { safeCompute(text) || 0 }
            &nbsp;(<TableMoneyText isPercentage value={
              (safeCompute(text) || 0) / ((safeCompute(record.one) || 0) +
                  (safeCompute(record.two) || 0) +
                  (safeCompute(record.three) || 0) +
                  (safeCompute(record.four) || 0) +
                  (safeCompute(record.five) || 0)) || 0
            }></TableMoneyText>)
          </div>
        </Space>
      )
    },
    {
      title: '已完成-滞后',
      dataIndex: 'one',
      key: 'one-summation',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text, record) => (
        <Space>
          <div>
            { safeCompute(text) || 0 }
            &nbsp;(<TableMoneyText isPercentage value={
              (safeCompute(text) || 0) / ((safeCompute(record.one) || 0) +
                  (safeCompute(record.two) || 0) +
                  (safeCompute(record.three) || 0) +
                  (safeCompute(record.four) || 0) +
                  (safeCompute(record.five) || 0)) || 0
            }></TableMoneyText>)
          </div>
        </Space>
      )
    },
    {
      title: '合计',
      dataIndex: 'total',
      key: 'total-summation',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text, record) => (
        <Space>
          <div>
            { (safeCompute(record.one) || 0) +
                (safeCompute(record.two) || 0) +
                (safeCompute(record.three) || 0) +
                (safeCompute(record.four) || 0) +
                (safeCompute(record.five) || 0) }
            &nbsp;(<TableMoneyText isPercentage value={1}></TableMoneyText>)
          </div>
        </Space>
      )
    },
    {
      title: '主办人员',
      dataIndex: 'organizersUserName',
      key: 'organizersUserName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理人员',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
  ];

  useEffect(() => {
    refreshData(searchReq)
  }, [searchReq])

  useEffect(() => {
    const objInitValue = {
      one: 0,//已完成滞后
      two: 0,//已完成按期
      three: 0,//已完成提前
      four: 0,//未完成拖期
      five: 0,//未完成正常
      total: 0,
    }
    const arr = jobAccountStore.jobAccountDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      arr.forEach(item => {
        objInitValue.one += safeCompute(item.one) || 0
        objInitValue.two += safeCompute(item.two) || 0
        objInitValue.three += safeCompute(item.three) || 0
        objInitValue.four += safeCompute(item.four) || 0
        objInitValue.five += safeCompute(item.five) || 0
        objInitValue.total += (safeCompute(item.one) || 0) +
            (safeCompute(item.two) || 0) +
            (safeCompute(item.three) || 0) +
            (safeCompute(item.four) || 0) +
            (safeCompute(item.five) || 0)
      })
      setSummaryTotalObj(objInitValue)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [jobAccountStore.jobAccountDatasource, selectedRowKeys])

  useEffect(() => {
    if (jobAccountStore.jobAccountDatasource.data) {
      setDataSource(jobAccountStore.jobAccountDatasource.data.items || [])
      setTotal(jobAccountStore.jobAccountDatasource.data.total || 0)
    }
  }, [jobAccountStore.jobAccountDatasource.data])

  /**
   * 列配置变化
   */
  useEffect(() => {
    if (total) {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn, total])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, jobAccountStore.collapsed, searchReq.pageNum, searchReq.perPage])

  const refreshData = (req: JobAccountReq) => {
    if (req.condition === '任务状态统计表-主办人员') {
      setShowColumn([...showColumn.filter(o => o !== 'personResponsibleName'), 'organizersUserName'])
    } else {
      setShowColumn([...showColumn.filter(o => o !== 'organizersUserName'), 'personResponsibleName'])
    }
    jobAccountStore.getJobAccount(req)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSelectedRowKeys([])
    setSearchReq(req)
    refreshData(req)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(location.pathname, value)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    jobAccountStore.setCollapsed(collapsed)
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        locationPathname={location.pathname}
        collapsed={jobAccountStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        handleShowColumnReset={handleShowColumnReset}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        isShowColumn
        tableColumns={columns}
        refreshData={(req) => refreshData({...searchReq, ...req})}
        items={[]}/>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={dataSource}
        columns={renderColumns}
        size={"middle"}
        loading={jobAccountStore.jobAccountDatasource.loading}
        bordered
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys)
          },
          columnWidth: 50,
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                columns.map((item, index) =>
                  String(item.key)?.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                        合计：<span>
                      { summaryTotalObj[String(item.key).slice(0, String(item.key).indexOf('-'))] }
                    &nbsp;(<TableMoneyText isPercentage value={
                        (
                          (summaryTotalObj[String(item.key).slice(0, String(item.key).indexOf('-'))]) / total
                        ) || 0
                      }></TableMoneyText>)
                    </span>
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </div>
  )
})
