import {makeAutoObservable, runInAction} from "mobx";
import {Page, ResponseEntity, StateFullData} from "@services/dto/common";
import {PersonnelChangeRow, PersonnelChangeSearchReq} from "@services/dto/personnelChange";
import {PersonnelChangeService} from "@services/PersonnelChangeService";

export class PersonnelChangeStore {
  personnelChangeDatasource: StateFullData<ResponseEntity<Page<PersonnelChangeRow>>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  searchPersonChange(req: PersonnelChangeSearchReq) {
    this.personnelChangeDatasource = {...this.personnelChangeDatasource, loading: true}
    PersonnelChangeService.searchPersonnelChange(req).then(rsp => {
      runInAction(() => this.personnelChangeDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.personnelChangeDatasource = {...this.personnelChangeDatasource, loading: false, err})
    })
  }
}

export const personnelChangeStore = new PersonnelChangeStore();