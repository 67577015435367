import React, {useEffect, useState} from "react";
import {Divider, Pagination, Select, Space} from "antd";
import {Any} from "@common/types";
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {BudgetManagementReq, BudgetManagementRow} from "@services/dto/budgetManagement";

export interface PropsType {
  onChange?: (value: Any | null) => void
  value?: Any | null
  mode?: 'multiple' | 'tags'
  labelInValue?: boolean
  disabled?: boolean
  className?: string
  allowClear?: boolean
  placeholder?: string
  fieldNames?: { value: string, label: string }
}

export const SelectBudgetManagement = observer((props: PropsType) => {
  const [value, setValue] = useState<string[] | null>()
  const {budgetManagementStore} = allStores
  const [datasource, setDatasource] = useState<BudgetManagementRow[]>([])
  const [details, setDetails] = useState<BudgetManagementRow[]>([])
  const [total, setTotal] = useState(0)
  const [searchReq, setSearchReq] = useState<BudgetManagementReq>({
    pageNum: 1,
    perPage: 10
  })

  /**
   *  每个组件默认写法 只需修改 'bmCode'
   */
  const defaultItems: any[] = [
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: 'N/A',
      [props.fieldNames ? props.fieldNames.label : 'bmCode']: 'N/A'
    },
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: '空白',
      [props.fieldNames ? props.fieldNames.label : 'bmCode']: '空白'
    },
  ]

  useEffect(() => {
    refreshData(searchReq)
  }, [])

  useEffect(() => {
    if (budgetManagementStore.budgetManagementSelectDatasource.data) {
      setDatasource(budgetManagementStore.budgetManagementSelectDatasource.data.items || []);
      setTotal(budgetManagementStore.budgetManagementSelectDatasource.data.total || 0)
    }
  }, [budgetManagementStore.budgetManagementSelectDatasource])

  useEffect(() => {
    if (budgetManagementStore.budgetManagementDetails.data && budgetManagementStore.budgetManagementSelectDatasource.data) {
      const items = [...details]
      budgetManagementStore.budgetManagementDetails.data.data.forEach(item => {
        const obj = budgetManagementStore.budgetManagementSelectDatasource.data.items.find(obj => obj.id === item.id)
        if (!obj) {
          items.push(item)
        }
      })
      setDetails(items);
    }
  }, [budgetManagementStore.budgetManagementDetails, budgetManagementStore.budgetManagementSelectDatasource])

  useEffect(() => {
    setValue(props.value)
    if (props.value) {
      budgetManagementStore.getBatch([...props.value])
    }
  }, [props.value])

  const refreshData = (req: BudgetManagementReq) => {
    budgetManagementStore.getSelectBudgetManagement(req)
  }

  const onChange = (value: Any | null) => {
    setValue(value)
    if (props.onChange) {
      props.onChange(value);
    }
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    const req = {...searchReq}
    req.pageNum = page
    req.perPage = pageSize
    setSearchReq(req)
    refreshData(req)
  }

  const handleSearch = (inputValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = inputValue
    setSearchReq(req)
    refreshData(req)
  }

  return (
    <Select
      allowClear={props.allowClear}
      placeholder={props.placeholder || "请选择项目"}
      className={`${props.className} width-100-percentage`}
      dropdownRender={(menu) => (
        <>
          { menu }
          <Divider className={"divider-margin"}/>
          <Space>
            <Pagination
              total={total}
              pageSize={searchReq.perPage}
              size={"small"}
              onChange={handlePaginationChange}/>
          </Space>
        </>
      )}
      value={value}
      onChange={onChange}
      showSearch
      autoClearSearchValue
      disabled={props.disabled}
      mode={props.mode}
      loading={budgetManagementStore.budgetManagementSelectDatasource.loading}
      labelInValue={props.labelInValue}
      filterOption={false}
      onSearch={handleSearch}
      onDropdownVisibleChange={(open) => open || handleSearch("")}
      fieldNames={props.fieldNames || {value: "id", label: "bmCode"}}
      options={[
        ...defaultItems,
        ...datasource,
        ...details
      ]}/>
  )
})
