import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {TaskSupervisionReq} from "@services/dto/taskSupervision";
import {DatePicker, Select} from "antd";
import dayjs from "dayjs";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectTaskSupervision} from "@components/SelectTaskSupervision";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {Any} from "@common/types";
import {SelectFiling} from "@components/SelectFiling";
import {SelectWorkSchedule} from "@components/SelectWorkSchedule";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {SelectDict} from "@components/SelectDict";
import {CustomAuthHeaderAction, PropsType as ParentPropsType} from "@components/CustomAuthHeaderAction";

export interface PropsType extends ParentPropsType {
  refreshData?: (req: TaskSupervisionReq) => void,
  updateCount?: number, // 更新渲染标志
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<TaskSupervisionReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: TaskSupervisionReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleTsDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.tsDate1 = dateStrings[0]
    req.tsDate2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleTsPlanCompChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.tsPlanComp1 = dateStrings[0]
    req.tsPlanComp2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleTsStartCompChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.tsStartComp1 = dateStrings[0]
    req.tsStartComp2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleTsStartDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.tsStartDate1 = dateStrings[0]
    req.tsStartDate2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleTsEndDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.tsEndDate1 = dateStrings[0]
    req.tsEndDate2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonResponsibleChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleStatusNoticeChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.statusNotice = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleWnUserIdsChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.wnUserIds = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleTsLevelChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.tsLevel = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleTsTaskPidChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.tsTaskPid = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleWorkScheduleIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.workScheduleId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleTsTaskChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.tsTask = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleTsStatusChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.tsStatus = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleTsFileChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.tsFile = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined

    req.tsLevel = undefined
    req.tsTask = undefined
    req.tsTaskPid = undefined
    req.workScheduleId = undefined
    req.tsDate1 = undefined
    req.tsDate2 = undefined
    req.tsStartComp1 = undefined
    req.tsStartComp2 = undefined
    req.tsPlanComp1 = undefined
    req.tsPlanComp2 = undefined
    req.tsStartDate1 = undefined
    req.tsStartDate2 = undefined
    req.tsEndDate1 = undefined
    req.tsEndDate2 = undefined
    req.checkPersonResponsible = undefined
    req.personResponsible = undefined
    req.personResponsibleName = undefined
    req.organizersUserId = undefined // 主办人员
    req.organizersUserName = undefined // 主办人员名
    req.tsStatus = undefined
    req.tsFile = undefined

    setSearchReq(req)
    refreshData(req)
  }

  const handleOrganizersUserIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.organizersUserId = value
    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <CustomAuthHeaderAction
        size={props.size}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <SelectDict
            onChange={handleTsLevelChange}
            defaultItems={[]}
            fieldNames={{value: 'value', label: 'key'}}
            value={searchReq?.tsLevel}
            className={"width-100-percentage"}
            placeholder={"请选择任务级别"}
            code={'dict_task_supervision_leve'}
            allowClear/>,
          <DatePicker.RangePicker
            allowClear
            value={searchReq?.tsDate1 ? [dayjs(searchReq?.tsDate1), dayjs(searchReq?.tsDate2)] : [undefined, undefined]}
            onChange={handleTsDateChange}
            placeholder={['制单开始日期', '制单结束日期']}
            className={"width-100-percentage"}/>,
          <SelectTaskSupervision
            onChange={handleTsTaskPidChange}
            className={"width-100-percentage"}
            placeholder={"请选择上级任务"}
            value={searchReq.tsTaskPid}
            allowClear/>,
          <SelectTaskSupervision
            onChange={handleTsTaskChange}
            className={"width-100-percentage"}
            placeholder={"请选择前置任务"}
            value={searchReq.tsTask}
            allowClear/>,
          <SelectWorkSchedule
            onChange={handleWorkScheduleIdChange}
            className={"width-100-percentage"}
            value={searchReq.workScheduleId}
            allowClear/>,
          <DatePicker.RangePicker
            allowClear
            onChange={handleTsStartCompChange}
            value={searchReq?.tsStartComp1 ? [dayjs(searchReq?.tsStartComp1), dayjs(searchReq?.tsStartComp2)] : [undefined, undefined]}
            placeholder={['计划开始日期', '计划开始日期']}
            className={"width-100-percentage"}/>,
          <DatePicker.RangePicker
            allowClear
            onChange={handleTsPlanCompChange}
            value={searchReq?.tsPlanComp1 ? [dayjs(searchReq?.tsPlanComp1), dayjs(searchReq?.tsPlanComp2)] : [undefined, undefined]}
            placeholder={['计划完成日期', '计划完成日期']}
            className={"width-100-percentage"}/>,
          <DatePicker.RangePicker
            onChange={handleTsStartDateChange}
            allowClear
            value={searchReq?.tsStartDate1 ? [dayjs(searchReq?.tsStartDate2), dayjs(searchReq?.tsStartDate2)] : [undefined, undefined]}
            placeholder={['实际开始日期', '实际开始日期']}
            className={"width-100-percentage"}/>,
          <DatePicker.RangePicker
            onChange={handleTsEndDateChange}
            allowClear
            value={searchReq?.tsEndDate1 ? [dayjs(searchReq?.tsEndDate2), dayjs(searchReq?.tsEndDate2)] : [undefined, undefined]}
            placeholder={['实际完成日期', '实际完成日期']}
            className={"width-100-percentage"}/>,
          <Select
            className={"width-100-percentage"}
            allowClear
            placeholder={"请选择任务状态"}
            value={searchReq.statusNotice}
            onChange={handleStatusNoticeChange}
            options={[
              {label:'未完成-正常', value:'正常'},
              {label:'未完成-拖期', value:'拖期'},
              {label:'已完成-提前', value:'提前'},
              {label:'已完成-按期', value:'按期'},
              {label:'已完成-滞后', value:'滞后'},
            ]}/>,
          <SelectPersonCharge
            onChange={handlePersonResponsibleChange}
            className={"width-100-percentage"}
            placeholder={"请选择办理人员"}
            value={searchReq.personResponsible}
            allowClear/>,
          <SelectPersonCharge
            onChange={handleOrganizersUserIdChange}
            className={"width-100-percentage"}
            placeholder={"请选择主办人员"}
            value={searchReq?.organizersUserId}
            allowClear/>,
          <SelectPersonCharge
            onChange={handleWnUserIdsChange}
            className={"width-100-percentage"}
            placeholder={"请选择参与人员"}
            value={searchReq.wnUserIds}
            allowClear/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
          <SelectCooperativeUnit
            onChange={handleCooperationUnitChange}
            value={searchReq?.cooperationUnit}
            allowClear
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectProcessStatus
            onChange={handleTsStatusChange}
            allowClear
            value={searchReq?.tsStatus}
            placeholder={'请选择办理进度'}
            className={"width-100-percentage"}/>,
          <SelectFiling
            placeholder={"请选择存档批号"}
            allowClear
            onChange={handleTsFileChange}
            value={searchReq?.tsFile}
            className={'width-100-percentage'}
            isHiddenOptions/>,
        ] : []}/>
    </>
  )
}))
