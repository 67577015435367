import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {CostApplicationReq, CostApplicationRow} from "@services/dto/costApplication";

export class CostApplicationService {
  static async getCostApplication(req: CostApplicationReq): Promise<Page<CostApplicationRow>> {
    return await apiV1.get("/employeeFeeApplication/search", {params: req})
  }

  static async batchRefresh(): Promise<ResponseEntity<string>> {
    return await apiV1.get("/employeeFeeApplication/batch/refresh")
  }

  static async addCostApplication(row: CostApplicationRow): Promise<ResponseEntity<CostApplicationRow>> {
    return await apiV1.post("/employeeFeeApplication/save", {...row})
  }

  static async updateCostApplication(row: CostApplicationRow): Promise<ResponseEntity<CostApplicationRow>> {
    return await apiV1.put("/employeeFeeApplication/update", {...row})
  }

  static async deleteCostApplication(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/employeeFeeApplication/delete", {data: ids})
  }

  static async getDetailById(id: string): Promise<ResponseEntity<CostApplicationRow>> {
    return await apiV1.get(`/employeeFeeApplication/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<CostApplicationRow>> {
    return await apiV1.get(`/employeeFeeApplication/getBatch/${ids.join(',')}`)
  }
}
