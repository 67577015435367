import {makeAutoObservable, runInAction} from "mobx";
import {Page, StateFullData} from "@services/dto/common";
import {SettleApplyReq, SettleApplyRow} from "@services/dto/settleApply";
import {SettleApplyService} from "@services/SettleApplyService";

export class SettleApplyStore {
  settleApplyDatasource: StateFullData<Page<SettleApplyRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getSettleApply(req: SettleApplyReq) {
    this.settleApplyDatasource = {...this.settleApplyDatasource, loading: true}
    SettleApplyService.getSettleApply(req).then(rsp => {
      runInAction(() => this.settleApplyDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.settleApplyDatasource = {...this.settleApplyDatasource, loading: false, err})
    })
  }
}

export const settleApplyStore = new SettleApplyStore();
