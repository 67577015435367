import {AdCodes, Children, ProvinceCityDistrict} from "@services/dto/provinceCityDistrict";
import {apiV1} from "@services/apiHelper";
import axios from "axios";
import {MAP_WEB_SERVICE_KEY} from "@/config";
import {Any} from "@common/types";

export class ProvinceCityDistrictService {
  private static PROVINCECITYDISTRICT_KEY = 'PROVINCE_CITY_DISTRICT'

  private static instance: ProvinceCityDistrictService;

  private static provinceCityDistrict: ProvinceCityDistrict[] = []

  constructor() {
    if (typeof ProvinceCityDistrictService.instance === 'object') {
      return ProvinceCityDistrictService.instance
    }
    ProvinceCityDistrictService.instance = this
    return this
  }

  static async getAdCodes(): Promise<AdCodes[]> {
    return await apiV1.get('/ad-codes');
  }

  static async initProvinceCityDistrict() {
    const result = await this.getAdCodes()
    let districts: ProvinceCityDistrict[] = []

    result.forEach(item => {
      let district: ProvinceCityDistrict = item.root
      if (item.children && item.children.length > 0) {
        district.children = []
        this.getRootChildren(item.children, district.children);
      }
      districts.push(district)
    })

    localStorage.setItem(this.PROVINCECITYDISTRICT_KEY, JSON.stringify(districts))
    return JSON.stringify(districts)
  }

  static getRootChildren(children: Children[], districts: ProvinceCityDistrict[]) {
    children.forEach(item => {
      let district: ProvinceCityDistrict = item.root ? item.root : item
      if (item.children && item.children.length > 0) {
        district.children = []
        this.getRootChildren(item.children, district.children)
      }
      districts.push(district);
    })
  }

  static async getProvinceCityDistrict() {
    if (this.provinceCityDistrict.length > 0) {
      return this.provinceCityDistrict
    }
    let provinceCityDistrict = localStorage.getItem(this.PROVINCECITYDISTRICT_KEY)
    if (!provinceCityDistrict) {
      provinceCityDistrict = await this.initProvinceCityDistrict()
    }
    this.provinceCityDistrict = JSON.parse(provinceCityDistrict)
    return this.provinceCityDistrict
  }

  static async getGeo(address: string): Promise<Any> {
    return await axios.get(`https://restapi.amap.com/v3/geocode/geo`, {
      params: {
        key: MAP_WEB_SERVICE_KEY,
        address: address
      }
    })
  }
}
