import { observer } from "mobx-react";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Drawer, FormInstance, message, Modal, Row, Select, Space, Table} from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {DATE_FMT, DEFAULT_MODAL_WIDTH} from "@/config";
import {PageParam, SubsidyRow} from "@services/dto/subsidy";
import {allStores} from "@/stores";
import {showErr} from "@common/utils";
import {SubsidyService} from "@services/SubsidyService";
import {SubsidyBasicInfo} from "@modules/Salary/Components/SubsidyBasicInfo";
import {CascaderPerson} from "@components/CascaderPerson";
import {MonthDatePicker} from "@modules/Salary/Components/MonthDatePicker";
import dayjs from "dayjs";

export const Subsidy = observer(() => {
  const {subsidyStore, salaryTypeStore} = allStores
  const [messageApi, contextHolder] = message.useMessage();
  const basicInfo = React.createRef<FormInstance>();
  const [total, setTotal] = useState<number>(0)
  const [dataSource, setDataSource] = useState<SubsidyRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [subsidyReq, setSubsidyReq] = useState<PageParam>({ perPage: 10, pageNum: 1, time: dayjs().format(DATE_FMT) })
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [subsidyRow, setSubsidyRow] = useState<SubsidyRow>()
  const [isView, setIsView] = useState<boolean>(false);

  const columns: ColumnsType<SubsidyRow> = [
    {
      title: '工号',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => { handleView(record) }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '补贴类型',
      dataIndex: 'typeText',
      key: 'typeText',
      align: 'center'
    },
    {
      title: '补贴标准',
      dataIndex: 'subsidyStandards',
      key: 'subsidyStandards',
      align: 'center'
    },
    {
      title: '补贴金额',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center'
    },
    {
      title: '补贴时间',
      dataIndex: 'time',
      key: 'time',
      align: 'center'
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record, index) => (
        <Space>
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined />}
            onClick={() => { handleEdit(record) }}>修改</Button>
          <Button shape="round" className={"delete-btn"} size={"middle"}
            icon={<DeleteOutlined />} onClick={() => { handleDeleteBatch(record) }}>删除</Button>
        </Space>
      )
    }
  ];

  useEffect(() => {
    refreshData(subsidyReq)
    salaryTypeStore.getSalaryTypes()
  }, [])

  useEffect(() => {
    setDataSource(subsidyStore.subsidyDatasource.data?.items || [])
    setTotal(subsidyStore.subsidyDatasource.data?.total || 0)
  }, [subsidyStore.subsidyDatasource])

  const refreshData = (req: PageParam) => {
    subsidyStore.getSubsidies(req)
  }

  const handleView = (record: SubsidyRow) => {
    setSubsidyRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleAdd = () => {
    setSubsidyRow(undefined)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handleEdit = (record: SubsidyRow) => {
    setSubsidyRow(record)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handleDeleteBatch = (record?: SubsidyRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids:string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        SubsidyService.deleteSubsidies(ids).then(() => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(subsidyReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...subsidyReq};
    req.perPage = pageSize
    req.pageNum = page
    setSubsidyReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleOk = () => {
    const subsidyParams = {...subsidyRow!}
    basicInfo.current?.validateFields().then(values => {
      Object.assign(subsidyParams, values)
      subsidyParams.time = values.time.format(DATE_FMT)
      subsidyParams.userId = values.userCode[values.userCode.length - 1]
      subsidyParams.userCode = values.userCode.join(",")
      setConfirmLoading(true)
      if (subsidyParams.id) {
        SubsidyService.updateSubsidies(subsidyParams).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false))
      } else {
        SubsidyService.addSubsidies(subsidyParams).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false))
      }
      setIsModalVisible(false)
    })
  }

  const actionThen = () => {
    messageApi.success("操作成功！")
    setIsModalVisible(false);
    setSubsidyRow(undefined)
    refreshData(subsidyReq)
  }

  const handleDateChange = (date: dayjs.Dayjs, dateString: string) => {
    const req = {...subsidyReq}
    req.pageNum = 1
    req.time = date.format(DATE_FMT)
    setSubsidyReq(req)
    refreshData({...req, userCode: undefined})
  }

  return (
    <div className={"institution"}>
      { contextHolder }
      <Row gutter={12}>
        <Col span={8} className={"show"}>
          <Select
            value={subsidyReq.type}
            showSearch
            onChange={(e) => {
              setSubsidyReq({...subsidyReq, type: e})
              refreshData({...subsidyReq, type: e})
            }}
            allowClear
            className={"width-100-percentage"}
            placeholder={"请选择补贴类型"}
            optionFilterProp="children"
            fieldNames={{value: 'type', label: 'name'}}
            filterOption={(inputValue, option) => String(option?.name).toLowerCase().includes(inputValue.toLowerCase())}
            options={salaryTypeStore.salaryTypes.items}
          />
          <CascaderPerson
            person={subsidyReq.userCode}
            onChange={(value) => {
              setSubsidyReq({...subsidyReq, userCode: value, userId: value ? value[value.length -1] : ''})
              refreshData({...subsidyReq, userCode: undefined, userId: value ? value[value.length -1] : ''})
            }}
            className={"margin-left-xs width-100-percentage"}
            placeholder={"请选择员工"}/>
        </Col>
        <Col span={8}>
          <MonthDatePicker handleDateChange={handleDateChange}/>
        </Col>
        <Col span={8} className={"text-align-right"}>
          <Button icon={<DeleteOutlined />} size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={() => handleDeleteBatch(undefined)}>删除</Button>
          <Button icon={<PlusOutlined />} type={"primary"} size={"middle"} shape={"round"}
            onClick={handleAdd}>新增</Button>
        </Col>
      </Row>

      <Card className={"margin-top-sm"}>
        <Table
          rowKey={"id"}
          dataSource={dataSource}
          columns={columns}
          size={"middle"}
          loading={subsidyStore.subsidyDatasource.loading || confirmLoading}
          pagination={{
            total: total,
            position: ['bottomCenter'],
            pageSize: subsidyReq.perPage,
            current: subsidyReq.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`, onChange: handlePageChange
          }}
          rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys }} />
      </Card>

      <Drawer
        title={<div className="text-align-center">补贴津贴</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        onClose={handleCancel}>
        <SubsidyBasicInfo row={{...subsidyRow!}} ref={basicInfo} isView={isView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          <Button type="primary" onClick={handleOk} loading={confirmLoading} className={"margin-left-xs"}>确定</Button>
        </div>
      </Drawer>
    </div>
  )
})
