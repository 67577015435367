import {ResponsesEntity, StateFullData} from "@services/dto/common";
import {makeAutoObservable, runInAction} from "mobx";
import {MenuPermissionsReq, MenuPermissionsRow} from "@services/dto/menuPermissions";
import {MenuPermissionsService} from "@services/MenuPermissionsService";

export class MenuPermissionsStore {
  menuPermissionsDatasource: StateFullData<ResponsesEntity<MenuPermissionsRow>> = {}
  menuPermissionsSelectDatasource: StateFullData<ResponsesEntity<MenuPermissionsRow>> = {}
  menuPermissionsReq: MenuPermissionsReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  getMenuPermissions(req: MenuPermissionsReq) {
    this.menuPermissionsDatasource = {...this.menuPermissionsDatasource, loading: true}
    this.menuPermissionsReq = {...this.menuPermissionsReq, ...req}
    MenuPermissionsService.getMenuPermissions(req).then(rsp => {
      runInAction(() => {
        this.menuPermissionsDatasource = {data: rsp, loading: false}
      })
    }).catch(e => {
      runInAction(() => this.menuPermissionsDatasource = {...this.menuPermissionsDatasource, loading: false, err: e})
    })
  }

  getSelectMenuPermissions(req: MenuPermissionsReq) {
    this.menuPermissionsSelectDatasource = {...this.menuPermissionsSelectDatasource, loading: true}
    MenuPermissionsService.getMenuPermissions(req).then(rsp => {
      runInAction(() => {
        this.menuPermissionsSelectDatasource = {data: rsp, loading: false}
      })
    }).catch(e => {
      runInAction(() => this.menuPermissionsSelectDatasource = {...this.menuPermissionsSelectDatasource, loading: false, err: e})
    })
  }
}

export const menuPermissionsStore = new MenuPermissionsStore();
