import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {DatePicker, Select, Space, Table} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {accAdd, safeCompute} from "@common/utils";
import {DEFAULT_TABLE_SCROLL_HEIGHT, TABLE_CELL_WIDTH_1_5x, TABLE_CELL_WIDTH_2x,} from "@/config";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import {HeaderAction} from "@components/HeaderAction";
import dayjs from "dayjs";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {useLocation} from "react-router-dom";
import {
  InvoiceRegistrationReq,
  InvoiceRegistrationRow,
  InvoiceRegistrationRowSummaryTotal
} from "@services/dto/invoiceRegistration";
import {TaxFeeStatisticsReq, TaxFeeStatisticsRow} from "@services/dto/taxFeeStatistics";

const wideShowColumn: string[] = [
  'company',
  'responsibilityProject',
  'inAccumulationInAccumulation',
  'saleAccumulation',
  'inAccumulationDifference',
  'inAccumulationTax',
  'saleAccumulationTax',
  'due',
]

const narrowShowColumn: string[] = [
  'company',
  'responsibilityProject',
  'inAccumulationTax',
  'saleAccumulationTax',
  'due-summation',
]

export const TaxFeeStatistics = observer(() => {
  const location = useLocation()
  const {invoiceRegistrationStore, taxFeeStatisticsStore, tableColumnsStore, screenResolutionStore} = allStores
  const [dataSource, setDataSource] = useState<InvoiceRegistrationRow[] | TaxFeeStatisticsRow[]>([])
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [summaryTotalObj, setSummaryTotalObj] = useState<InvoiceRegistrationRowSummaryTotal>()
  const [searchReq, setSearchReq] = useState<InvoiceRegistrationReq | TaxFeeStatisticsReq>({
    pageNum: 1,
    perPage: 10,
    taxCategory: '增值税统计表',
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...invoiceRegistrationStore.invoiceRegistrationReq
  })
  const invoiceRegistrationColumns: ColumnsType<InvoiceRegistrationRow> = [
    {
      title: '企业',
      dataIndex: 'company',
      key: 'company',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '进项价税合计',
      dataIndex: 'inAccumulationInAccumulation',
      key: 'inAccumulationInAccumulation-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '销项价税合计',
      dataIndex: 'saleAccumulation',
      key: 'saleAccumulation-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '进销价税差额',
      dataIndex: 'inAccumulationDifference',
      key: 'inAccumulationDifference-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '进项税额合计',
      dataIndex: 'inAccumulationTax',
      key: 'inAccumulationTax-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '销项税额合计',
      dataIndex: 'saleAccumulationTax',
      key: 'saleAccumulationTax-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '应缴增值税额',
      dataIndex: 'due',
      key: 'due-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
  ]
  const [columns, setColumns] = useState<ColumnsType<InvoiceRegistrationRow> | ColumnsType<TaxFeeStatisticsRow>>(invoiceRegistrationColumns)
  const taxFeeStatisticsColumns: ColumnsType<TaxFeeStatisticsRow> = [
    {
      title: '企业',
      dataIndex: 'company',
      key: 'company',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '税费类别',
      dataIndex: 'feeCategory',
      key: 'feeCategory',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '税费缴纳合计',
      dataIndex: 'taxTotalPayment',
      key: 'taxTotalPayment',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
  ];

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }
    // 刷新数据源
    refreshData(searchReq)
  }, [])

  useEffect(() => {
    if (invoiceRegistrationStore.invoiceRegistrationDatasource.data) {
      setDataSource(invoiceRegistrationStore.invoiceRegistrationDatasource.data.items || []);
      setTotal(invoiceRegistrationStore.invoiceRegistrationDatasource.data.total || 0)
    }
  }, [invoiceRegistrationStore.invoiceRegistrationDatasource])

  useEffect(() => {
    if (taxFeeStatisticsStore.taxFeeStatisticsDatasource.data) {
      setDataSource(taxFeeStatisticsStore.taxFeeStatisticsDatasource.data.items || []);
      setTotal(taxFeeStatisticsStore.taxFeeStatisticsDatasource.data.total || 0)
    }
  }, [taxFeeStatisticsStore.taxFeeStatisticsDatasource])

  useEffect(() => {
    const objInitValue = {
      inAccumulationInAccumulation: 0,
      saleAccumulation: 0,
      inAccumulationDifference: 0,
      inAccumulationTax: 0,
      saleAccumulationTax: 0,
      due: 0,
    }
    let obj = summaryTotalObj
    const arr = invoiceRegistrationStore.invoiceRegistrationDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        inAccumulationInAccumulation: accAdd(acc.inAccumulationInAccumulation, safeCompute((cur?.inAccumulationInAccumulation))),
        saleAccumulation: accAdd(acc.saleAccumulation, safeCompute(cur?.saleAccumulation)),
        inAccumulationDifference: accAdd(acc.inAccumulationDifference, safeCompute(cur?.inAccumulationDifference)),
        inAccumulationTax: accAdd(acc.inAccumulationTax, safeCompute(cur?.inAccumulationTax)),
        saleAccumulationTax: accAdd(acc.saleAccumulationTax, safeCompute(cur?.saleAccumulationTax)),
        due: accAdd(acc.due, cur.due),
      }), objInitValue);
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [invoiceRegistrationStore.invoiceRegistrationDatasource, selectedRowKeys])

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const refreshData = (req: InvoiceRegistrationReq | TaxFeeStatisticsReq) => {
    setDataSource([])
    if (req.taxCategory === '增值税统计表') {
      setColumns(invoiceRegistrationColumns)
      invoiceRegistrationStore.getInvoiceRegistrationStatistics(req)
    } else {
      setColumns(taxFeeStatisticsColumns)
      taxFeeStatisticsStore.getTaxFeeStatistics(req)
    }
  }

  const handleTimeChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.irDate1 = dateStrings[0]
    req.irDate2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleCompanyChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.company = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleChange = (value: string) => {
    const req = {...searchReq};
    req.pageNum = 1
    req.taxCategory = value
    setSearchReq(req)
    refreshData(req)
  }

  return (
    <div className={"institution"}>
      <HeaderAction
        handleDeleteBatch={() => {
        }}
        handleKeywordSearch={(keyword) => {
          setSearchReq({...searchReq, keyword})
          refreshData({...searchReq, keyword, pageNum: 1})
        }}
        collapsedChildren={[
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            value={searchReq.taxCategory}
            onChange={handleChange}
            placeholder={'请选择税费统计表'}
            className={'width-100-percentage'}
            options={[
              {value: '增值税统计表', label: '增值税统计表'},
              {value: '附加税统计表', label: '附加税统计表'},
              {value: '企业所得税统计表', label: '企业所得税统计表'},
              {value: '个人所得税统计表', label: '个人所得税统计表'},
              {value: '社会保险费统计表', label: '社会保险费统计表'},
              {value: '住房公积金统计表', label: '住房公积金统计表'},
              {value: '项目管理费统计表', label: '项目管理费统计表'},
              {value: '其他税费统计表', label: '其他税费统计表'},
            ]}/>,
          <DatePicker.RangePicker onChange={handleTimeChange} className={"width-100-percentage"}/>,
          <SelectCooperativeUnit
            allowClear
            placeholder="请选择企业"
            cooperativeUnitCategory={"内部协作单位"}
            onChange={handleCompanyChange}
            fieldNames={{value: 'cuName', label: 'cuName'}}/>
        ]}></HeaderAction>

      <Table
        className={'margin-top-xss'}
        rowKey={'id'}
        dataSource={dataSource as any[]}
        columns={columns as any[]}
        size={"middle"}
        loading={invoiceRegistrationStore.invoiceRegistrationDatasource.loading}
        bordered
        scroll={{y: DEFAULT_TABLE_SCROLL_HEIGHT}}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                (showColumn as string[]).map((key, index) =>
                  key.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={summaryTotalObj[key.slice(0, key.indexOf('-'))]}></TableMoneyText> 元
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}
        rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys, columnWidth: 50}}/>
    </div>
  )
})
