import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {CostReimbursementReq, CostReimbursementRow} from "@services/dto/costReimbursement";
import {CostReimbursementService} from "@services/CostReimbursementService";
import {CostExpendRow} from "@services/dto/costExpend";
import {CostExpendService} from "@services/CostExpendService";
import {convertCurrency, formatMoney} from "@common/utils";

export class CostReimbursementStore {
  costReimbursementDatasource: StateFullData<Page<CostReimbursementRow>> = {}
  costReimbursementSelectDatasource: StateFullData<Page<CostReimbursementRow>> = {}
  costReimbursementRow?: CostReimbursementRow
  costReimbursementOption?: string
  costReimbursementDetail: StateFullData<ResponseEntity<CostReimbursementRow>> = {}
  costReimbursementDetails: StateFullData<ResponsesEntity<CostReimbursementRow>> = {}
  costReimbursementReq: CostReimbursementReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateCostReimbursementRow(row: CostReimbursementRow) {
    this.costReimbursementRow = {...row}
  }

  updateCostReimbursementOption(option: string) {
    this.costReimbursementOption = option
  }

  getCostReimbursement(req: CostReimbursementReq) {
    this.costReimbursementDatasource = {...this.costReimbursementDatasource, loading: true}
    this.costReimbursementReq = {...this.costReimbursementReq, ...req}
    CostReimbursementService.getCostReimbursement(req).then(rsp => {
      runInAction(() => this.costReimbursementDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.costReimbursementDatasource = {...this.costReimbursementDatasource, loading: false, err})
    })
  }

  getSelectCostReimbursement(req: CostReimbursementReq) {
    this.costReimbursementSelectDatasource = {...this.costReimbursementSelectDatasource, loading: true}
    CostReimbursementService.getCostReimbursement(req).then(rsp => {
      runInAction(() => this.costReimbursementSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.costReimbursementSelectDatasource = {...this.costReimbursementSelectDatasource, loading: false, err})
    })
  }

  getOne(id: string) {
    this.costReimbursementDetail = {...this.costReimbursementDetail, loading: true}
    CostReimbursementService.getOne(id).then(rsp => {
      runInAction(() => this.costReimbursementDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.costReimbursementDetail = {...this.costReimbursementDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.costReimbursementDetails = {...this.costReimbursementDetails, loading: true}
    CostReimbursementService.getBatch(ids).then(rsp => {
      runInAction(() => this.costReimbursementDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.costReimbursementDetails = {...this.costReimbursementDetails, loading: false, err})
    })
  }

  async getPrintList(record: CostReimbursementRow): Promise<LabelValue[]> {

    const row = this.costReimbursementDatasource.data?.items.find(o => o.id === record.id)

    // 关联费用支出
    let costExpend: CostExpendRow[] = []
    if (row.employeeFeePayIds.length) {
      const employeeFeePayRsp = await CostExpendService.getBatch(row.employeeFeePayIds)
      if (employeeFeePayRsp.code === 200) {
        costExpend = employeeFeePayRsp.data
      }
    }

    return [
      {label: '制单日期', value: String(row.erDateOfPreparation)},
      {label: '制单编号', value: row.erApprovalCode},
      {label: '费用摘要', value: row.erExpenseSummary},
      {label: '关联费用支出', value: costExpend.map(o => o.efaApplyCode).join(',')},
      {label: '付款账户', value: row.efpPaymentAccountName},
      {label: '收款账户', value: row.efpAccountingObjectName},
      {label: '申请金额(元)', value: `${formatMoney(row.erReimbursementAmount)}元（${convertCurrency(row.erReimbursementAmount)}）`},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const costReimbursementStore = new CostReimbursementStore();
