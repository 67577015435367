import {LoginReq, LoginRsp, PasswordUpdateReq, User} from "@services/dto/security";
import {apiV1} from "@services/apiHelper";
import {ResponseEntity} from "@services/dto/common";

export class SecurityService {
  static async login(req: LoginReq): Promise<LoginRsp> {
    return await apiV1.post('/login', {...req})
  }

  static async updateUser(id: string, user: User): Promise<User> {
    return await apiV1.put(`/users/${id}`, {...user});
  }

  static async updatePassword(req: PasswordUpdateReq): Promise<User> {
    return await apiV1.post('/changePwd', {...req});
  }

  static async getUserSortId(sortId: string): Promise<ResponseEntity<User>> {
    return await apiV1.get(`/getUserSortId/${sortId}`)
  }

  static async setUserSortId(userId: string): Promise<ResponseEntity<User>> {
    return await apiV1.get(`/setUserSortId/${userId}`)
  }
}
