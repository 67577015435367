import React, {FC, useEffect, useState} from "react";
import './index.less'
import {Button, ConfigProvider, Popover, Space} from "antd";
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {
  DashOutlined,
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  PlusOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import {ADD_TEXT, DEL_TEXT, EDIT_TEXT, ONFILE, VIEW_TEXT} from "@/config";

export interface PropsType {
  isQuery?: boolean, // 所选行
  selectedRowKeys?: React.Key[], // 所选行
  handleAdd?: () => void
  handleEdit?: () => void
  handleSearchReqReset?: () => void // 重置请求参数
  handleDeleteBatch?: () => void
  handleView?: () => void
  onClick?: () => void
  trigger?: ['click', 'hover', 'contextMenu']
  size?: 'large' | 'middle' | 'small'
  type?: "link" | "text" | "default" | "primary" | "dashed" | undefined
  children?: React.ReactNode
  isHiddenOptions?: boolean
  items?: React.ReactNode[]
  status?: string  // 办理进度为完成时 del跟edit按钮不展示
}

type btnItemType = { key: string, node: React.ReactNode }

export const HeaderOptions: FC<PropsType> = observer((props: PropsType) => {

  const {securityStore} = allStores
  const [items, setItems] = useState<React.ReactNode[]>([])
  const [permissionsList, setPermissionsList] = useState<string[]>([])

  // 表格的默认按钮列表键值
  const tableItemKeys: string[] = [
    'update',
    'view',
    'delete',
  ]

  // 查询的默认按钮列表键值
  const queryItemKeys: string[] = [
    'add',
    'delete',
  ]

  // 默认按钮列表
  const defaultItems: btnItemType[] = [
    {
      key: 'add',
      node: <Button
        className={'text-align-left'}
        type={'text'}
        style={{display: props.handleAdd ? "unset" : 'none'}}
        block
        onClick={() => {
          props.handleAdd && props.handleAdd()
        }}>
        <PlusOutlined/>
        { ADD_TEXT }
      </Button>,
    },
    {
      key: 'update',
      node: <Button
        className={'text-align-left'}
        type={'text'}
        block
        style={{display: props.handleEdit ? props.status === ONFILE ? "none" : "unset" : 'none'}}
        onClick={() => {
          props.handleEdit && props.handleEdit()
        }}>
        <EditOutlined/>
        { EDIT_TEXT }
      </Button>
    },
    {
      key: 'view',
      node: <Button
        className={'text-align-left'}
        type={'text'}
        style={{display: props.handleView ? "unset" : 'none'}}
        block
        onClick={() => {
          props.handleView && props.handleView()
        }}>
        <EllipsisOutlined/>
        { VIEW_TEXT }
      </Button>
    },
    {
      key: 'delete',
      node: <Button
        className={'text-align-left'}
        type={'text'}
        block
        style={{display: props.handleDeleteBatch ? props.status === ONFILE? "none" :  "unset" : 'none'}}
        danger
        onClick={() => {
          props.handleDeleteBatch && props.handleDeleteBatch()
        }}>
        <DeleteOutlined/>
        { DEL_TEXT }
      </Button>
    },
  ]

  /**
   * @description 初始化
   */
  useEffect(() => {
    setItemFun()
  }, [])

  /**
   * @description 行数据变化
   */
  useEffect(() => {
    setItemFun()
  }, [props.selectedRowKeys]);

  /**
   * @description 设置按钮列表
   */
  const setItemFun = () => {
    let keys: string[] = []
    const permissions: string[] = securityStore.getPermissions() || []
    keys = props.isQuery ? queryItemKeys : tableItemKeys
    if (permissions.join('') !== 'all') {
      keys = keys.filter(v => permissions.includes(v))
    }
    const renderItems: React.ReactNode[] = defaultItems.filter(o => keys.includes(o.key)).map(o => o.node)
    setPermissionsList(permissions)
    setItems([
      ...renderItems,
      ...(props.items || [])
    ])
  }

  return (
    <Space.Compact>

      { /*展开/收起*/ }
      {
        ['展开', '收起'].includes(props.children as string) && <Button
          type={props.type || "default"}
          size={props.size || "small"}
          onClick={props.onClick}>
          { props.children }
        </Button>
      }

      { /*操作 / 其他按钮*/ }
      {
        [EDIT_TEXT].includes(props.children as string) && (
          <Button
            type={props.type || "default"}
            size={props.size || "small"}
            onClick={permissionsList.some(v => v === 'all' || v === 'edit') ? props.onClick : props.handleView}>
            { (permissionsList.some(v => v === 'all' || v === 'edit') ? props.children : VIEW_TEXT) }
          </Button>
        )
      }

      {
        !['展开', '收起', EDIT_TEXT].includes(props.children as string) && props.children &&  (
          <Button
            type={props.type || "default"}
            size={props.size || "small"}
            onClick={props.onClick}>
            { props.children }
          </Button>
        )
      }

      { /*重置按钮*/ }
      {
        props.handleSearchReqReset && <Button
          type={props.type || "default"}
          style={{padding: `0 ${(props.size || "small") === 'small' ? 5 : 10}px`}}
          size={props.size || "small"}
          onClick={props.handleSearchReqReset}>
          <RedoOutlined/>
        </Button>
      }

      { /*...按钮 hover效果*/ }
      {
        !props.isHiddenOptions && <ConfigProvider
          theme={{
            components: {
              Popover: {
                titleMinWidth: 130,
                algorithm: true, // 启用算法
              },
            },
          }}
        >
          <Space>
            <Popover
              title={''}
              placement="bottomRight"
              arrow={false}
              trigger={props.trigger || ['hover']}
              content={
                <>
                  { /*按钮列表*/ }
                  <div className={'width-100-percentage content-box'} style={{width: 'min-content'}}>
                    {
                      items.map((item: React.ReactNode, index) => (<div
                        key={index}>
                        { item }
                      </div>))
                    }
                  </div>
                </>
              }>
              <Button
                type={props.type || "default"}
                style={{padding: `0 ${(props.size || "small") === 'small' ? 5 : 10}px`}}
                size={props.size || "small"}>
                <DashOutlined/>
              </Button>
            </Popover>
          </Space>
        </ConfigProvider>
      }
    </Space.Compact>
  )
})
