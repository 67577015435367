import React, {useEffect, useState} from "react";
import {Divider, Pagination, Select, Space} from "antd";
import {Any} from "@common/types";
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {DictItem, StatisticsItemsReq, StatisticsItemsRow} from "@services/dto/dict";
import {DictService} from "@services/DictService";
import {showErr} from "@common/utils";
import {BLACK_LIST} from "@/config";
import {
  DatasourceType,
  defaultFieldNames,
  FieldNameType,
  SearchReqType,
  typeDefaultItems
} from "@services/dto/applicationForm";

export interface PropsType {
  value?: [string, string]
  onSearch?: ([value, tableValue]: [value: string, tableValue: string]) => void
  labelInValue?: boolean
  disabled?: boolean
  className?: string
  allowClear?: boolean
  placeholder?: string
  statisticsItemsReq?: StatisticsItemsReq
  isOption?: boolean
  showKey?: string[]
  defaultItems?: { id: string, value: string }[]
}

export const CustomApplicationFormType = observer((props: PropsType) => {
  const [datasource, setDatasource] = useState<DatasourceType[]>([])
  const [total, setTotal] = useState(0)
  const [items, setItems] = useState<DatasourceType[]>([])
  const [value, setValue] = useState<string>()
  const [tableValue, setTableValue] = useState<string>()
  const [fieldNames, setFieldNames] = useState<FieldNameType>(defaultFieldNames)
  const [loading, setLoading] = useState<boolean>(false)
  const [dict, setDict] = useState<DictItem[]>([])
  const [statisticsItems, setStatisticsItems] = useState<StatisticsItemsRow[]>([])
  const [defaultItems, setDefaultItems] = useState<Any[]>([
    {[fieldNames.value]: 'N/A', [fieldNames.label]: 'N/A'},
    {[fieldNames.value]: '空白', [fieldNames.label]: '空白'},
  ])
  const [searchReq, setSearchReq] = useState<SearchReqType>({
    pageNum: 1,
    perPage: 10
  })
  const {
    wrStore,
    costApplicationStore,
    costExpendStore,
    projectCollaborationStore,
    payrollStore,
    bankStatementStore,
    contractSettlementStore,
    staffLoanStore,
    costReimbursementStore,
    taxAccountingStore,
    finalSettlementStore,
    taskSupervisionStore,
    workScheduleStore,
  } = allStores

  /**
   * 初始化
   */
  useEffect(() => {
    if (props.statisticsItemsReq) {
      // 统计查询
      getStatisticsItems()
    } else {
      // 查询字典
      getByKey()
    }
    refreshData(searchReq)
  }, [props.showKey])

  /**
   * 值发生改变
   */
  useEffect(() => {
    if (props.value) {
      setValue(props.value[0])
      setTableValue(props.value[1])
    }
  }, [props.value]);

  /**
   * 办理进度数据变化
   */
  useEffect(() => {
    switch (value) {
    case '员工档案':
      if (wrStore.workerRecruitmentSelectDatasource.data) {
        setDatasource(wrStore.workerRecruitmentSelectDatasource.data.items || [])
        setTotal(wrStore.workerRecruitmentSelectDatasource.data.total || 0)
        if (wrStore.workerRecruitmentDetails.data?.length) {
          setItems(wrStore.workerRecruitmentDetails.data
            ?.filter(item => !(wrStore.workerRecruitmentSelectDatasource.data.items || [])
              .some(o => o._id === item._id)))
        }
      }
      break
    case '工资发放':
      if (payrollStore.payrollSelectDatasource.data) {
        setDatasource(payrollStore.payrollSelectDatasource.data.items || [])
        setTotal(payrollStore.payrollSelectDatasource.data.total || 0)
        if (payrollStore.payrollDetails.data?.data.length) {
          setItems(payrollStore.payrollDetails.data?.data
            .filter(item => !(payrollStore.payrollSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '费用申请':
      if (costApplicationStore.costApplicationSelectDatasource.data) {
        setDatasource(costApplicationStore.costApplicationSelectDatasource.data.items || [])
        setTotal(costApplicationStore.costApplicationSelectDatasource.data.total || 0)
        if (costApplicationStore.costApplicationDetails.data?.data.length) {
          setItems(costApplicationStore.costApplicationDetails.data?.data
            .filter(item => !(costApplicationStore.costApplicationSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '社保清缴':
      break
    case '员工借款':
      if (staffLoanStore.staffLoanSelectDatasource.data) {
        setDatasource(staffLoanStore.staffLoanSelectDatasource.data.items || [])
        setTotal(staffLoanStore.staffLoanSelectDatasource.data.total || 0)
        if (staffLoanStore.staffLoanDetails.data?.data.length) {
          setItems(staffLoanStore.staffLoanDetails.data?.data
            .filter(item => !(staffLoanStore.staffLoanSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '费用报销':
      if (costReimbursementStore.costReimbursementSelectDatasource.data) {
        setDatasource(costReimbursementStore.costReimbursementSelectDatasource.data.items || [])
        setTotal(costReimbursementStore.costReimbursementSelectDatasource.data.total || 0)
        if (costReimbursementStore.costReimbursementDetails.data?.data.length) {
          setItems(costReimbursementStore.costReimbursementDetails.data?.data
            .filter(item => !(costReimbursementStore.costReimbursementSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '费用支出':
      if (costExpendStore.costExpendSelectDatasource.data) {
        setDatasource(costExpendStore.costExpendSelectDatasource.data.items || [])
        setTotal(costExpendStore.costExpendSelectDatasource.data.total || 0)
        if (costExpendStore.costExpendDetails.data?.data.length) {
          setItems(costExpendStore.costExpendDetails.data?.data
            .filter(item => !(costExpendStore.costExpendSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '合同结算':
      if (contractSettlementStore.contractSettlementSelectDatasource.data) {
        setDatasource(contractSettlementStore.contractSettlementSelectDatasource.data.items || [])
        setTotal(contractSettlementStore.contractSettlementSelectDatasource.data.total || 0)
        if (contractSettlementStore.contractSettlementDetails.data?.data.length) {
          setItems(contractSettlementStore.contractSettlementDetails.data?.data
            .filter(item => !(contractSettlementStore.contractSettlementSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '税费核算':
      if (taxAccountingStore.taxAccountingSelectDatasource.data) {
        setDatasource(taxAccountingStore.taxAccountingSelectDatasource.data.items || [])
        setTotal(taxAccountingStore.taxAccountingSelectDatasource.data.total || 0)
        if (taxAccountingStore.taxAccountingDetails.data?.data.length) {
          setItems(taxAccountingStore.taxAccountingDetails.data?.data
            .filter(item => !(taxAccountingStore.taxAccountingSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '税费缴纳':
      if (finalSettlementStore.finalSettlementSelectDatasource.data) {
        setDatasource(finalSettlementStore.finalSettlementSelectDatasource.data.items || [])
        setTotal(finalSettlementStore.finalSettlementSelectDatasource.data.total || 0)
        if (finalSettlementStore.finalSettlementDetails.data?.data.length) {
          setItems(finalSettlementStore.finalSettlementDetails.data?.data
            .filter(item => !(finalSettlementStore.finalSettlementSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '资金出纳':
      if (bankStatementStore.bankStatementSelectDatasource.data) {
        setDatasource(bankStatementStore.bankStatementSelectDatasource.data.items || [])
        setTotal(bankStatementStore.bankStatementSelectDatasource.data.total || 0)
        if (bankStatementStore.bankStatementDetails.data?.data.length) {
          setItems(bankStatementStore.bankStatementDetails.data?.data
            .filter(item => !(bankStatementStore.bankStatementSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '项目协作':
      if (projectCollaborationStore.projectCollaborationSelectDatasource.data) {
        setDatasource(projectCollaborationStore.projectCollaborationSelectDatasource.data.items || [])
        setTotal(projectCollaborationStore.projectCollaborationSelectDatasource.data.total || 0)
        if (projectCollaborationStore.projectCollaborationDetails.data?.data.length) {
          setItems(projectCollaborationStore.projectCollaborationDetails.data?.data
            .filter(item => !(projectCollaborationStore.projectCollaborationSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '工作任务':
      if (taskSupervisionStore.taskSupervisionSelectDatasource.data) {
        setDatasource(taskSupervisionStore.taskSupervisionSelectDatasource.data.items || [])
        setTotal(taskSupervisionStore.taskSupervisionSelectDatasource.data.total || 0)
        if (taskSupervisionStore.taskSupervisionDetails.data?.data.length) {
          setItems(taskSupervisionStore.taskSupervisionDetails.data?.data
            .filter(item => !(taskSupervisionStore.taskSupervisionSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '工作日程':
      if (workScheduleStore.workScheduleSelectDatasource.data) {
        setDatasource(workScheduleStore.workScheduleSelectDatasource.data.items || [])
        setTotal(workScheduleStore.workScheduleSelectDatasource.data.total || 0)
        if (workScheduleStore.workScheduleDetails.data?.data.length) {
          setItems(workScheduleStore.workScheduleDetails.data?.data
            .filter(item => !(workScheduleStore.workScheduleSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break

    default:
      break
    }
  }, [
    wrStore.workerRecruitmentDetails, wrStore.workerRecruitmentSelectDatasource.data,
    costApplicationStore.costApplicationDetails, costApplicationStore.costApplicationSelectDatasource.data,
    payrollStore.payrollDetails, payrollStore.payrollSelectDatasource.data,
    finalSettlementStore.finalSettlementDetails, finalSettlementStore.finalSettlementSelectDatasource.data,
    taxAccountingStore.taxAccountingDetails, taxAccountingStore.taxAccountingSelectDatasource.data,
    costReimbursementStore.costReimbursementDetails, costReimbursementStore.costReimbursementSelectDatasource.data,
    staffLoanStore.staffLoanDetails, staffLoanStore.staffLoanSelectDatasource.data,
    contractSettlementStore.contractSettlementDetails, contractSettlementStore.contractSettlementSelectDatasource.data,
    bankStatementStore.bankStatementDetails, bankStatementStore.bankStatementSelectDatasource.data,
    costExpendStore.costExpendSelectDatasource.data, costExpendStore.costExpendDetails,
    projectCollaborationStore.projectCollaborationDetails, projectCollaborationStore.projectCollaborationSelectDatasource.data,
    taskSupervisionStore.taskSupervisionSelectDatasource.data, taskSupervisionStore.taskSupervisionDetails,
    workScheduleStore.workScheduleSelectDatasource.data, workScheduleStore.workScheduleDetails
  ])

  /**
   * left值改变
   */
  useEffect(() => {
    const req = {...searchReq}
    req.pageNum = 1
    setSearchReq(req)
    refreshData(req)
    switch (value) {
    case '员工档案':
      setFieldNames({value: "_id", label: "name"})
      break
    case '工资发放':
      setFieldNames({value: "id", label: "payrollNumber"})
      break
    case '费用申请':
      setFieldNames({value: "id", label: "efaApplyCode"})
      break
    case '社保清缴':
      break
    case '员工借款':
      setFieldNames({value: "id", label: "elReqCode"})
      break
    case '费用报销':
      setFieldNames({value: "id", label: "erApprovalCode"})
      break
    case '费用支出':
      setFieldNames({value: "id", label: "efaApplyCode"})
      break
    case '合同结算':
      setFieldNames({value: "id", label: "csCode"})
      break
    case '税费核算':
      setFieldNames({value: "id", label: "taCode"})
      break
    case '税费缴纳':
      setFieldNames({value: "id", label: "fsCode"})
      break
    case '资金出纳':
      setFieldNames({value: "id", label: "bsCode"})
      break
    case '项目协作':
      setFieldNames({value: "id", label: "cuName"})
      break
    case '工作任务':
      setFieldNames({value: "id", label: "tsCode"})
      break
    case '工作日程':
      setFieldNames({value: "id", label: "wsCode"})
      break

    default:
      break
    }
  }, [value]);

  /**
   * right值改变
   */
  useEffect(() => {
    switch (value) {
    case '员工档案':
      if (typeof tableValue === 'string' && !BLACK_LIST.includes(tableValue)) {
        wrStore.getBatch([tableValue])
      }
      break
    case '工资发放':
      if (typeof tableValue === 'string' && !BLACK_LIST.includes(tableValue)) {
        payrollStore.getBatch([tableValue])
      }
      break
    case '费用申请':
      if (typeof tableValue === 'string' && !BLACK_LIST.includes(tableValue)) {
        costApplicationStore.getBatch([tableValue])
      }
      break
    case '社保清缴':
      break
    case '员工借款':
      if (typeof tableValue === 'string' && !BLACK_LIST.includes(tableValue)) {
        staffLoanStore.getBatch([tableValue])
      }
      break
    case '费用报销':
      if (typeof tableValue === 'string' && !BLACK_LIST.includes(tableValue)) {
        costReimbursementStore.getBatch([tableValue])
      }
      break
    case '费用支出':
      if (typeof tableValue === 'string' && !BLACK_LIST.includes(tableValue)) {
        costExpendStore.getBatch([tableValue])
      }
      break
    case '合同结算':
      if (typeof tableValue === 'string' && !BLACK_LIST.includes(tableValue)) {
        contractSettlementStore.getBatch([tableValue])
      }
      break
    case '税费核算':
      if (typeof tableValue === 'string' && !BLACK_LIST.includes(tableValue)) {
        taxAccountingStore.getBatch([tableValue])
      }
      break
    case '税费缴纳':
      if (typeof tableValue === 'string' && !BLACK_LIST.includes(tableValue)) {
        finalSettlementStore.getBatch([tableValue])
      }
      break
    case '资金出纳':
      if (typeof tableValue === 'string' && !BLACK_LIST.includes(tableValue)) {
        bankStatementStore.getBatch([tableValue])
      }
      break
    case '项目协作':
      if (typeof tableValue === 'string' && !BLACK_LIST.includes(tableValue)) {
        projectCollaborationStore.getBatch([tableValue])
      }
      break
    case '工作任务':
      if (typeof tableValue === 'string' && !BLACK_LIST.includes(tableValue)) {
        taskSupervisionStore.getBatch([tableValue])
      }
      break
    case '工作日程':
      if (typeof tableValue === 'string' && !BLACK_LIST.includes(tableValue)) {
        workScheduleStore.getBatch([tableValue])
      }
      break

    default:
      break
    }
  }, [tableValue])

  useEffect(() => {
    setDefaultItems([
      {[fieldNames.value]: 'N/A', [fieldNames.label]: 'N/A'},
      {[fieldNames.value]: '空白', [fieldNames.label]: '空白'},
    ])
  }, [fieldNames])

  const refreshData = (req: SearchReqType) => {
    switch (value) {
    case '工资发放':
      payrollStore.getSelectPayroll(req)
      break
    case '员工档案':
      wrStore.getSelectWorkerRecruitments({...req, status: "hired"})
      break
    case '费用申请':
      costApplicationStore.getSelectCostApplication(req)
      break
    case '社保清缴':
      break
    case '员工借款':
      staffLoanStore.getSelectStaffLoanRow(req)
      break
    case '费用报销':
      costReimbursementStore.getSelectCostReimbursement(req)
      break
    case '费用支出':
      costExpendStore.getSelectCostExpend(req)
      break
    case '合同结算':
      contractSettlementStore.getSelectContractSettlement(req)
      break
    case '税费核算':
      taxAccountingStore.getSelectTaxAccounting(req)
      break
    case '税费缴纳':
      finalSettlementStore.getSelectFinalSettlement(req)
      break
    case '资金出纳':
      bankStatementStore.getSelectBankStatement(req)
      break
    case '项目协作':
      projectCollaborationStore.getSelectProjectCollaboration(req)
      break
    case '工作任务':
      taskSupervisionStore.getSelectTaskSupervision(req)
      break
    case '工作日程':
      workScheduleStore.getSelectWorkSchedule(req)
      break

    default:
      setDatasource([])
      setTotal(0)
      break
    }
  }

  const handleChange = (v: string, _: DictItem | DictItem[]) => {
    setValue(v)
    if (props.onSearch) {
      props.onSearch([v, tableValue])
    }
  }

  const handleTableValueChange = (v: string, _: Any[]) => {
    setTableValue(v)
    if (props.onSearch) {
      props.onSearch([value, v])
    }
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    const req = {...searchReq}
    req.pageNum = page
    req.perPage = pageSize
    setSearchReq(req)
    refreshData(req)
  }

  const handleSearch = (inputValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = inputValue
    setSearchReq(req)
    refreshData(req)
    return true
  }

  const getStatisticsItems = () => {
    setLoading(true)
    DictService.getStatisticsItems(props.statisticsItemsReq).then(rsp => {
      const row = JSON.parse(JSON.stringify(rsp.data || [])).map((item: StatisticsItemsRow) => {
        item.value = `${item.value}(${item.coun})`
        return item
      })
      setStatisticsItems(row);
    }).catch(showErr).finally(() => setLoading(false))
  }

  const getByKey = () => {
    setLoading(true)
    DictService.getByKey('fund_approval_related_form_type').then(rsp => {
      setDict(rsp.value.filter(o => props.showKey ? props.showKey.includes(o.value) : o.id).map(o=>({id:o.id,value:o.value})) || []);
    }).catch(showErr).finally(() => setLoading(false))
  }

  const leftNode = <Select
    allowClear={props.allowClear}
    value={value}
    onChange={handleChange}
    placeholder={props.placeholder || '关联申请表单'}
    showSearch
    style={props.isOption ? {width: 'auto'} : {}}
    filterOption={(input: string, option) =>
      String(option?.value).toLowerCase().includes(input.toLowerCase())}
    labelInValue={props.labelInValue}
    disabled={props.disabled}
    fieldNames={props.statisticsItemsReq ? {value: 'id', label: 'value'} : {value: 'value', label: 'value'}}
    options={props.statisticsItemsReq ? [...statisticsItems] : [...(props.defaultItems || typeDefaultItems), ...dict]}
    loading={loading}>
  </Select>

  const rightNode = <Select
    style={{width: '100%'}}
    allowClear={props.allowClear}
    placeholder={value ? `请选择${value}` : "请选择表数据"}
    dropdownRender={(menu) => (
      <>
        { menu }
        <Divider className={"divider-margin"}/>
        <Space>
          <Pagination
            total={total}
            pageSize={searchReq.perPage}
            size={"small"}
            onChange={handlePaginationChange}/>
        </Space>
      </>
    )}
    value={tableValue}
    onChange={handleTableValueChange}
    showSearch
    onClear={() => handleSearch('')}
    disabled={props.disabled}
    filterOption={false}
    onSearch={handleSearch}
    labelInValue={props.labelInValue}
    fieldNames={fieldNames}
    options={[
      ...defaultItems,
      ...datasource,
      ...items,
    ]}/>

  return (
    <>
      <Space.Compact
        className={props.className || 'width-100-percentage'}>
        { leftNode }
        { rightNode }
      </Space.Compact>
    </>
  )
})
