import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Avatar, Card, Col, Form, Input, Row, UploadFile} from "antd";
import {UploadOutlined} from "@ant-design/icons";
import {CustomUpload, normFile} from "@components/CustomUpload";
import {UploadChangeParam} from "antd/es/upload/interface";
import {PersonalProfile} from "@modules/Archives/Components/PersonalProfile";
import {WorkExperience} from "@modules/Archives/Components/WorkExperience";
import {FormInstance} from "antd/es";
import {allStores} from "@/stores";
import {getUploadStringToArray} from "@common/utils";
import dayjs from "dayjs";
import "./BasicInfo.less"
import {DATE_FMT} from "@/config";

export const BasicInfo = observer(React.forwardRef<FormInstance>((props, ref) => {
  const {wrStore} = allStores;
  const {wr: stateWr} = wrStore
  const wr = stateWr.data!;

  const [fileList, setFileList] = useState<UploadFile[]>([])

  const handleChange = (info: UploadChangeParam) => {
    setFileList([...info.fileList])
  }

  const [form] = Form.useForm()
  useEffect(() => {
    form.resetFields();
    let user = undefined
    if (wr) {
      user = {...wr.user}
      if (user.dateOfBirth) {
        user.dateOfBirth = dayjs(user.dateOfBirth, DATE_FMT);
      }
      if (typeof user.profilePictureUrl === 'string') {
        user.profilePictureUrl = getUploadStringToArray(user.profilePictureUrl)
        setFileList(user.profilePictureUrl)
      }
    } else {
      setFileList([])
    }
    form.setFieldsValue({...user,
      professionalLeagues: wr?.professionalLeaguesName,
      workerTypeName: wr?.workerTypeName,
      projectId: wr?.projects.map(item => item.id)})
  }, [wr])
  
  const handleProjectChange = () => {
    form.resetFields(['professionalLeagues', 'workerTypeName'])
  }

  return (
    <Form
      name="basic"
      form={form}
      ref={ref}
      labelCol={{span: 8}}
      wrapperCol={{span: 16}}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Col xl={6} lg={8} md={12} xs={24}>
          <Card className={"left-card text-align-center"}>
            <Form.Item
              name="profilePictureUrl"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              wrapperCol={{span: 24}}
              extra={"支持文件(jpg,jpeg,png),2M以内"}
              rules={[{required: false, message: '请上传证件照!'}]}>
              <CustomUpload 
                updateSize={2}
                maxCount={1}
                uploadType={"image"}
                accept={"image/*"}
                listType={"picture-card"}
                multiple={false}
                status={"1"}
                className={fileList.length > 0 ? "custom-upload upload-circle" : "upload-circle"}
                onChange={handleChange}>
                <Avatar
                  size={{xs: 80, sm: 80, md: 80, lg: 80, xl: 80, xxl: 80}}
                  icon={<UploadOutlined />}
                />
              </CustomUpload>
            </Form.Item>
            <Form.Item
              label="个人介绍"
              name="profile"
              labelCol={{span: 24}}
              wrapperCol={{span: 24}}
              rules={[{required: false, message: '请输入个人介绍!'}]}>
              <Input.TextArea placeholder="请输入个人介绍" autoSize={{minRows: 24, maxRows: 24}}/>
            </Form.Item>
          </Card>
        </Col>
        <Col xl={18} lg={16} xs={24}>
          <Card title={<div className={"text-align-center"}>个人基本资料</div>}>
            <PersonalProfile handleProjectChange={handleProjectChange}/>
          </Card>
          <Card title={<div className={"text-align-center"}>工作经历</div>} className={"margin-top-sm"}>
            <WorkExperience/>
          </Card>
        </Col>
      </Row>
    </Form>
  )
}))