import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {PayrollReq} from "@services/dto/Payroll";
import {DatePicker} from "antd";
import dayjs from "dayjs";
import {SelectDict} from "@components/SelectDict";
import {SelectFundApproval} from "@components/SelectFundApproval";
import {CustomInputNumber} from "@components/CustomInputNumber";
import {SelectBankAccount} from "@components/SelectBankAccount";
import {SelectProject} from "@components/SelectProject";
import {Any} from "@common/types";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {SelectEmolumentPlan} from "@components/SelectEmolumentPlan";
import {CustomAuthHeaderAction, PropsType as ParentPropsType} from "@components/CustomAuthHeaderAction";
import {SelectProcessStatus} from "@components/SelectProcessStatus";

export interface PropsType extends ParentPropsType {
  updateCount?: number, // 更新渲染标志
  refreshData?: (req: PayrollReq) => void,
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<PayrollReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: PayrollReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonResponsibleNameChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsibleName = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }

  const handlePayrollSalaryChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.payrollSalary1 = start
    req.payrollSalary2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handlePayrollDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.payrollDate1 = dateStrings[0]
    req.payrollDate2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handlePayrollCategoryChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.payrollCategory = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleEmployeeFeeCompensationPlanCodeChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.employeeFeeCompensationPlanCode = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleFundApprovalIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.fundApprovalId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleStatusChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.status = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCreditedAccountIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.creditedAccountId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handlePaymentAccountIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.paymentAccountId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleOrganizersUserIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.organizersUserId = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined

    req.payrollNumber = undefined
    req.employeeFeeCompensationPlanCode = undefined
    req.payrollCategory = undefined
    req.personResponsible = undefined
    req.personResponsibleName = undefined
    req.organizersUserId = undefined // 主办人员
    req.organizersUserName = undefined // 主办人员名
    req.payrollDate1 = undefined
    req.payrollDate2 = undefined
    req.payrollSalary1 = undefined
    req.payrollSalary2 = undefined
    req.fundApprovalId = undefined
    req.paymentAccountId = undefined
    req.creditedAccountId = undefined
    req.status = undefined

    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <CustomAuthHeaderAction
        size={props.size}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <DatePicker.RangePicker
            allowClear
            onChange={handlePayrollDateChange}
            value={searchReq?.payrollDate1 ? [dayjs(searchReq?.payrollDate1), dayjs(searchReq?.payrollDate2)] : [undefined, undefined]}
            placeholder={['制单开始日期', '制单结束日期']}
            className={"width-100-percentage"}/>,
          <SelectDict
            className={'width-100-percentage'}
            allowClear
            value={searchReq?.payrollCategory}
            onChange={handlePayrollCategoryChange}
            code={'dict_personnel_category'}
            placeholder={'请输入结算类别'}/>,
          <CustomInputNumber
            value={[searchReq?.payrollSalary1, searchReq?.payrollSalary2]}
            placeholder={['申请金额最小', '申请金额最大']}
            className={'width-100-percentage'}
            onSearch={handlePayrollSalaryChange}/>,
          <SelectBankAccount
            updateCount={props.updateCount}
            value={searchReq?.paymentAccountId}
            onChange={handlePaymentAccountIdChange}
            allowClear
            className={'width-100-percentage'}
            placeholder={'请输入付款账户'}/>,
          <SelectBankAccount
            updateCount={props.updateCount}
            value={searchReq?.creditedAccountId}
            onChange={handleCreditedAccountIdChange}
            allowClear
            className={'width-100-percentage'}
            placeholder={'请输入收款账户'}/>,
          <SelectEmolumentPlan
            allowClear
            updateCount={props.updateCount}
            placeholder={"请选择薪酬计划"}
            value={searchReq?.employeeFeeCompensationPlanCode}
            fieldNames={{value: 'efaApplyCode', label: 'efaApplyCode'}}
            onChange={handleEmployeeFeeCompensationPlanCodeChange}
            className={'width-100-percentage'}/>,
          <SelectFundApproval
            allowClear
            updateCount={props.updateCount}
            placeholder={"请选择资金审批"}
            value={searchReq?.fundApprovalId}
            onChange={handleFundApprovalIdChange}
            className={'width-100-percentage'}/>,
          <SelectPersonCharge
            updateCount={props.updateCount}
            onChange={handlePersonResponsibleNameChange}
            className={"width-100-percentage"}
            fieldNames={{value: 'name', label: 'name'}}
            value={searchReq?.personResponsibleName}
            placeholder={"请选择办理人员"}
            allowClear/>,
          <SelectPersonCharge
            onChange={handleOrganizersUserIdChange}
            className={"width-100-percentage"}
            placeholder={"请选择主办人员"}
            value={searchReq?.organizersUserId}
            allowClear/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
          <SelectCooperativeUnit
            value={searchReq?.cooperationUnit}
            onChange={handleCooperationUnitChange}
            allowClear
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectProcessStatus
            onChange={handleStatusChange}
            allowClear
            value={searchReq?.status}
            placeholder={'请选择办理进度'}
            className={"width-100-percentage"}/>,
        ] : []}/>
    </>
  )
}))
