import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {ConferenceRecordReq, ConferenceRecordRow} from "@services/dto/conferenceRecord";

export class ConferenceRecordService {
  static async getConferenceRecord(req: ConferenceRecordReq): Promise<Page<ConferenceRecordRow>> {
    return await apiV1.get("/meetingMinutes/search", {params: req})
  }

  static async addConferenceRecord(row: ConferenceRecordRow): Promise<ResponseEntity<ConferenceRecordRow>> {
    return await apiV1.post("/meetingMinutes/save", {...row})
  }

  static async updateConferenceRecord(row: ConferenceRecordRow): Promise<ResponseEntity<ConferenceRecordRow>> {
    return await apiV1.put("/meetingMinutes/update", {...row})
  }

  static async deleteConferenceRecord(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/meetingMinutes/delete", {data: ids})
  }

  static async getOne(id: string): Promise<ResponseEntity<ConferenceRecordRow>> {
    return await apiV1.get(`/meetingMinutes/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<ConferenceRecordRow>> {
    return await apiV1.get(`/meetingMinutes/getBatch/${ids.join(',')}`)
  }
}
