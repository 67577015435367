import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Button, DatePicker, Drawer, FormInstance, message, Modal, Space, Table} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {accAdd, logger, safeCompute, showErr} from "@common/utils";
import {DEFAULT_MODAL_WIDTH, DEFAULT_TABLE_SCROLL_HEIGHT, TABLE_CELL_WIDTH_2x,} from "@/config";
import {BasicInfo} from "@modules/InvoiceRegistration/Components/BasicInfo";
import {InvoiceRegistrationService} from "@services/InvoiceRegistrationService";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import {HeaderAction} from "@components/HeaderAction";
import dayjs from "dayjs";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {useLocation} from "react-router-dom";
import {
  InvoiceRegistrationReq,
  InvoiceRegistrationRow,
  InvoiceRegistrationRowSummaryTotal
} from "@services/dto/invoiceRegistration";

const wideShowColumn: string[] = [
  'company',
  'responsibilityProject',
  'inAccumulationInAccumulation',
  'saleAccumulation',
  'inAccumulationDifference',
  'inAccumulationTax',
  'saleAccumulationTax',
  'due',
]

const narrowShowColumn: string[] = [
  'company',
  'responsibilityProject',
  // 'inAccumulationInAccumulation',
  // 'saleAccumulation',
  // 'inAccumulationDifference',
  'inAccumulationTax',
  'saleAccumulationTax',
  'due-summation',
]

export const InvoiceRegistration = observer(() => {
  const location = useLocation()
  const {invoiceRegistrationStore, tableColumnsStore, screenResolutionStore} = allStores
  const basicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<InvoiceRegistrationRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [invoiceRegistrationRow, setInvoiceRegistrationRow] = useState<InvoiceRegistrationRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [summaryTotalObj, setSummaryTotalObj] = useState<InvoiceRegistrationRowSummaryTotal>()
  const [searchReq, setSearchReq] = useState<InvoiceRegistrationReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...invoiceRegistrationStore.invoiceRegistrationReq
  })

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  const columns: ColumnsType<InvoiceRegistrationRow> = [
    {
      title: '企业',
      dataIndex: 'company',
      key: 'company',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '进项价税合计',
      dataIndex: 'inAccumulationInAccumulation',
      key: 'inAccumulationInAccumulation-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '销项价税合计',
      dataIndex: 'saleAccumulation',
      key: 'saleAccumulation-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '进销价税差额',
      dataIndex: 'inAccumulationDifference',
      key: 'inAccumulationDifference-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '进项税额合计',
      dataIndex: 'inAccumulationTax',
      key: 'inAccumulationTax-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '销项税额合计',
      dataIndex: 'saleAccumulationTax',
      key: 'saleAccumulationTax-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '应缴增值税额',
      dataIndex: 'due',
      key: 'due-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
  ];

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    refreshData(searchReq)
  }, [])

  useEffect(() => {
    if (invoiceRegistrationStore.invoiceRegistrationDatasource.data) {
      setDataSource(invoiceRegistrationStore.invoiceRegistrationDatasource.data.items || []);
      setTotal(invoiceRegistrationStore.invoiceRegistrationDatasource.data.total || 0)
    }
  }, [invoiceRegistrationStore.invoiceRegistrationDatasource])

  useEffect(() => {
    const objInitValue = {
      inAccumulationInAccumulation: 0,
      saleAccumulation: 0,
      inAccumulationDifference: 0,
      inAccumulationTax: 0,
      saleAccumulationTax: 0,
      due: 0,
    }
    let obj = summaryTotalObj
    const arr = invoiceRegistrationStore.invoiceRegistrationDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        inAccumulationInAccumulation: accAdd(acc.inAccumulationInAccumulation, safeCompute((cur?.inAccumulationInAccumulation))),
        saleAccumulation: accAdd(acc.saleAccumulation, safeCompute(cur?.saleAccumulation)),
        inAccumulationDifference: accAdd(acc.inAccumulationDifference, safeCompute(cur?.inAccumulationDifference)),
        inAccumulationTax: accAdd(acc.inAccumulationTax, safeCompute(cur?.inAccumulationTax)),
        saleAccumulationTax: accAdd(acc.saleAccumulationTax, safeCompute(cur?.saleAccumulationTax)),
        due: accAdd(acc.due, cur.due),
      }), objInitValue);
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [invoiceRegistrationStore.invoiceRegistrationDatasource, selectedRowKeys])

  const handleAdd = () => {
    setInvoiceRegistrationRow(undefined)
    setIsView(false)
    setIsModalVisible(true)
  }

  // const handleView = (record: InvoiceRegistrationRow) => {
  //   setInvoiceRegistrationRow(record)
  //   setIsView(true)
  //   setIsModalVisible(true);
  // }

  // const handleEdit = (record: InvoiceRegistrationRow) => {
  //   setInvoiceRegistrationRow(record)
  //   setIsView(false)
  //   setIsModalVisible(true);
  // }

  const handleDeleteBatch = (record?: InvoiceRegistrationRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        InvoiceRegistrationService.deleteInvoiceRegistration(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(searchReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: InvoiceRegistrationReq) => {
    invoiceRegistrationStore.getInvoiceRegistrationStatistics(req)
  }

  const handleOk = () => {
    const row = {...invoiceRegistrationRow!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(row, values)
      setConfirmLoading(true)

      if (row.id) {
        InvoiceRegistrationService.updateInvoiceRegistration(row).then(() => {
          actionThen();
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        InvoiceRegistrationService.addInvoiceRegistration(row).then(() => {
          actionThen();
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  }

  const actionThen = () => {
    message.success("操作成功！").then()
    setIsModalVisible(false);
    setInvoiceRegistrationRow(undefined)
    refreshData(searchReq)
  }

  const handleTimeChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.irDate1 = dateStrings[0]
    req.irDate2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleCompanyChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.company = value
    setSearchReq(req)
    refreshData(req)
  }

  return (
    <div className={"institution"}>
      <HeaderAction
        handleDeleteBatch={handleDeleteBatch}
        handleAdd={handleAdd}
        isShowKeyword
        collapsedChildren={[
          <DatePicker.RangePicker onChange={handleTimeChange} className={"width-100-percentage"}/>,
          <SelectCooperativeUnit
            allowClear
            placeholder="请选择企业"
            cooperativeUnitCategory={"内部协作单位"}
            onChange={handleCompanyChange}
            fieldNames={{value: 'cuName', label: 'cuName'}}/>
        ]}></HeaderAction>

      <Table
        className={'margin-top-xss'}
        rowKey={'id'}
        dataSource={dataSource}
        columns={columns}
        size={"middle"}
        loading={invoiceRegistrationStore.invoiceRegistrationDatasource.loading || confirmLoading}
        bordered
        scroll={{y: DEFAULT_TABLE_SCROLL_HEIGHT}}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                (showColumn as string[]).map((key, index) =>
                  key.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={summaryTotalObj[key.slice(0, key.indexOf('-'))]}></TableMoneyText> 元
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}
        rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys, columnWidth: 50}}/>

      <Drawer
        title={<div className="text-align-center">税费核算</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        onClose={handleCancel}>
        <BasicInfo row={{...invoiceRegistrationRow!}} ref={basicInfo} isView={isView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button
              type="primary" onClick={handleOk} loading={confirmLoading}
              className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>
    </div>
  )
})
