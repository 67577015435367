import {makeAutoObservable, runInAction} from "mobx";
import {Page, StateFullData} from "@services/dto/common";
import {RecruitmentRow, SearchRecruitmentReq} from "@services/dto/recruitment";
import {RecruitmentService} from "@services/RecruitmentService";

export class RecruitmentStore {

  curRecruitment: StateFullData<Page<RecruitmentRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  loadRecruitments(req: SearchRecruitmentReq) {
    this.curRecruitment = {...this.curRecruitment, loading: true}
    RecruitmentService.searchRecruitments(req).then(rsp => {
      runInAction(() => {this.curRecruitment = {data: rsp, loading: false}})
    }).catch(e => {
      runInAction(() => this.curRecruitment = {...this.curRecruitment, loading: false, err: e})
    })
  }
}


export const recruitmentStore = new RecruitmentStore();
