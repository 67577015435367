import {observer} from "mobx-react";
import {HeaderAction} from "@modules/RewardPunishment/Components/HeaderAction";
import {ContentTable} from "@modules/RewardPunishment/Components/ContentTable";
import {allStores} from "@/stores";
import {
  CreateRewardPunishmentReq,
  RewardPunishmentReq,
  RewardPunishmentRow
} from "@services/dto/rewardPunishment";
import React, {useEffect, useState} from "react";
import {DATE_FMT, DEFAULT_MODAL_WIDTH} from "@/config";
import {Button, Drawer, message, Modal} from "antd";
import {FormInstance} from "antd/es";
import {BasicInfo} from "@modules/RewardPunishment/Components/BasicInfo";
import {logger, showErr} from "@common/utils";
import {RewardPunishmentService} from "@services/RewardPunishmentService";

export const RewardPunishment = observer(() => {
  const {rewardPunishmentStore} = allStores
  const {rewardPunishments: datasource} = rewardPunishmentStore
  const [rewardPunishmentReq, setRewardPunishmentReq] = useState<RewardPunishmentReq>({
    pageNum: 1,
    perPage: 10,
    status: "1"
  })
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [visible, setVisible] = useState<boolean>(false)
  const [row, setRow] = useState<RewardPunishmentRow>()
  const basicInfo = React.createRef<FormInstance>();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)

  useEffect(() => {
    refreshData(rewardPunishmentReq)
  }, [])
  
  const refreshData = (req: RewardPunishmentReq) => {
    rewardPunishmentStore.getRewardPunishment(req)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    const req = {...rewardPunishmentReq}
    req.pageNum = page
    req.perPage = pageSize
    setRewardPunishmentReq(req)
    refreshData(req)
  }
  
  const handleActiveKeyChange = (value: string) => {
    const req = {...rewardPunishmentReq}
    req.pageNum = 1
    req.status = value
    setRewardPunishmentReq(req)
    refreshData(req)
  }
  const handleProjectIdChange = (projectId: string) => {
    const req = {...rewardPunishmentReq}
    req.pageNum = 1
    req.projectId = projectId
    setRewardPunishmentReq(req)
    refreshData(req)
  }
  const handleTimeChange = (dateStrings: string[]) => {
    const req = {...rewardPunishmentReq}
    req.pageNum = 1
    req.startTime = ""
    req.endTime = ""
    if (dateStrings.length > 0) {
      req.startTime = dateStrings[0]
      req.endTime = dateStrings[1]
    }
    setRewardPunishmentReq(req)
    refreshData(req)
  }
  const handleDeleteBatch = (record?: RewardPunishmentRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        RewardPunishmentService.deleteBatchRewardPunishment(ids).then(rsp => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(rewardPunishmentReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }
  const handleAdd = () => {
    setRow(undefined)
    setVisible(true)
  }
  const handleEdit = (record: RewardPunishmentRow) => {
    setRow(record)
    setVisible(true)
  }
  const handleCancel = () => {
    setVisible(false)
  }
  const handleOk = () => {
    basicInfo.current?.validateFields().then(value => {
      setConfirmLoading(true)
      if (value.rewardTime) {
        value.rewardTime = value.rewardTime.format(DATE_FMT)
      }
      const createRewardPunishmentReq: CreateRewardPunishmentReq = {
        rewardTime: value.rewardTime,
        status: value.status,
        amount: value.amount,
        projectId: value.person[0],
        professionalLeagues: value.person[1],
        professional: value.person[2],
        userId: value.person[3],
        remark: value.remark
      }
      if (row?.id) {
        RewardPunishmentService.updateRewardPunishment(row.id, createRewardPunishmentReq).then(rsp => {
          message.success("修改成功！")
          handleCancel()
          refreshData(rewardPunishmentReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      } else {
        RewardPunishmentService.createRewardPunishment(createRewardPunishmentReq).then(rsp => {
          message.success("新增成功！")
          handleCancel()
          refreshData(rewardPunishmentReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      }
    }).catch(logger.error)
  }
  return (
    <>
      <HeaderAction
        handleActiveKeyChange={handleActiveKeyChange}
        handleProjectIdChange={handleProjectIdChange}
        handleTimeChange={handleTimeChange}
        handleDeleteBatch={handleDeleteBatch}
        handleAdd={handleAdd}/>
      
      <ContentTable
        className={"margin-top-sm"}
        loading={datasource.loading || confirmLoading}
        total={datasource.data?.total!}
        perPage={rewardPunishmentReq.perPage}
        pageNum={rewardPunishmentReq.pageNum}
        selectedRowKeys={selectedRowKeys}
        dataSource={datasource.data?.items!}
        handlePageChange={handlePageChange}
        handleChange={setSelectedRowKeys}
        handleEdit={handleEdit}
        handleDeleteBatch={handleDeleteBatch}/>

      <Drawer
        title={<div className="text-align-center">奖惩信息</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={visible}
        onClose={handleCancel}>
        <BasicInfo row={{...row!}} ref={basicInfo}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          <Button type="primary" onClick={handleOk} loading={confirmLoading} className={"margin-left-xs"}>确定</Button>
        </div>
      </Drawer>
    </>
  )
})