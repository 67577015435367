import {observer} from "mobx-react";
import {ColumnsType} from "antd/es/table";
import {CompanyRow} from "@services/dto/company";
import {Button, Card, Space, Table} from "antd";
import {EditOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {getUploadStringToArray} from "@common/utils";
import {allStores} from "@/stores";

export const CompanyPages = observer(() => {
  const {securityStore, companyStore} = allStores;
  const [dataSource, setDataSource] = useState<CompanyRow[]>([]);

  useEffect(() => {
    const user = securityStore.getLoggedUser()
    if (user?.companyId) {
      companyStore.getCompanyById(user?.companyId);
    }
  }, [])

  useEffect(() => {
    const companyDatasource = []
    if (companyStore.companyRowData.data) {
      companyDatasource.push(companyStore.companyRowData.data.data);
    }
    setDataSource(companyDatasource)
  }, [companyStore.companyRowData])

  const columns: ColumnsType<CompanyRow> = [
    {
      title: '企业名称',
      dataIndex: 'companyName',
      key: 'companyName',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => { handleEdit(record) }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '信息发布',
      dataIndex: 'infoRelease',
      key: 'infoRelease',
      align: 'center',
    },
    {
      title: '待办事项',
      dataIndex: 'todoList',
      key: 'todoList',
      align: 'center',
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record: CompanyRow, index) => (
        <Space>
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined/>}
            onClick={() => handleEdit(record)}>修改</Button>
        </Space>
      )
    }
  ];

  const handleEdit = (record: CompanyRow) => {
    if (typeof record.businessLicense === 'string') {
      record.businessLicense = getUploadStringToArray(record.businessLicense)
    }
  }

  return (
    <>
      <Card className={"margin-top-sm"}>
        <Table
          rowKey={"id"}
          dataSource={dataSource}
          columns={columns}
          size={"middle"}
          loading={companyStore.companyDataSource.loading}
          pagination={{
            total: dataSource.length,
            pageSize: 1,
            position: ['bottomCenter'],
            current: 10,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`
          }}/>
      </Card>
    </>
  )
})
