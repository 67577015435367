import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";
import "./index.less"
import {Button, Card, Col, Drawer, Input, message, Modal, Row, Space, Table} from 'antd';
import {SearchOutlined, PlusOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons"
import {ColumnsType} from "antd/es/table";
import {CompanyReq, CompanyRow, UpdateCompanyReq} from "@services/dto/company";
import {allStores} from "@/stores";
import {DEFAULT_MODAL_WIDTH} from "@/config";
import {FormInstance} from "antd/es";
import {BasicInfo} from "@modules/Company/Components/BasicInfo";
import {getUploadArrayToString, getUploadIds, getUploadStringToArray, showErr} from "@common/utils";
import {CompanyService} from "@services/CompanyService";
import {ResponseEntity} from "@services/dto/common";
import {ToolService} from "@services/ToolService";
import {PreviewImage} from "@components/PreviewImage";

export const Company: FC = observer(() => {
  const {companyStore} = allStores;
  const [dataSource, setDataSource] = useState<CompanyRow[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("")
  const [companyReq, setCompanyReq] = useState<CompanyReq>({pageNum: 1, perPage: 10})

  const basicInfo = React.createRef<FormInstance>();
  const [companyInfo, setCompanyInfo] = useState<CompanyRow>()
  const [visible, setVisible] = useState<boolean>(false)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const [imageUrl, setImageUrl] = useState<string>("")
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    refreshData(companyReq)
  }, [])

  useEffect(() => {
    setDataSource(companyStore.companyDataSource.data?.items!)
    setTotal(companyStore.companyDataSource.data?.total!)
  }, [companyStore.companyDataSource])

  const refreshData = (req: CompanyReq) => {
    companyStore.searchCompany(req)
  }

  const onSearch = () => {
    const req = {...companyReq};
    req.pageNum = 1
    req.keyword = searchValue
    setCompanyReq(req)
    refreshData(req)
  }

  const preview = (text: string) => {
    setImageUrl(text)
    setIsPreview(true)
  }

  const columns: ColumnsType<CompanyRow> = [
    {
      title: '公司名称',
      dataIndex: 'companyName',
      key: 'companyName',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => { handleEdit(record) }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '联系人',
      dataIndex: 'contacts',
      key: 'contacts',
      align: 'center',
    },
    {
      title: '手机号码',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
    },
    {
      title: '企业管理账号',
      dataIndex: 'personInCharges',
      key: 'personInCharges',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <div>{ record.personInCharges.map(item => item.companyUsername).join(",") }</div>
        </Space>
      )
    },
    {
      title: '详细地址',
      dataIndex: 'address',
      key: 'address',
      align: 'center',
    },
    {
      title: '营业执照',
      dataIndex: 'businessLicense',
      key: 'businessLicense',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <PreviewImage visible={isPreview} src={imageUrl} setVisible={setIsPreview} onClick={() => preview(text)}/>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record: CompanyRow, index) => (
        <Space>
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined/>}
            onClick={() => handleEdit(record)}>修改</Button>
          <Button shape="round" className={"delete-btn"} size={"middle"}
            icon={<DeleteOutlined/>} onClick={() => handleDeleteBatch(record)}>删除</Button>
        </Space>
      )
    }
  ];

  const handleAdd = () => {
    setCompanyInfo(undefined);
    setVisible(true);
  }

  const handleEdit = (record: CompanyRow) => {
    if (typeof record.businessLicense === 'string') {
      record.businessLicense = getUploadStringToArray(record.businessLicense)
    }
    setCompanyInfo(record);
    setVisible(true);
  }
  
  const handleDeleteBatch = (record?: CompanyRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids:string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        CompanyService.deleteBatchCompanyByIds(ids).then((rsp) => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(companyReq)
        }).catch(showErr).finally(() => setConfirmLoading(false));
      },
    });
  }

  const handleOk = () => {
    const companyRow = {...companyInfo!}
    basicInfo.current?.validateFields().then(values => {
      Object.assign(companyRow, values)
      let isRepeat = false
      companyRow.personInCharges.forEach(item => {
        item.isEnable = item.isEnable ? 1 : 0
        if (companyRow.personInCharges.filter(obj => item.companyUsername === obj.companyUsername).length > 1) {
          isRepeat = true
        }
      })
      if (isRepeat) {
        message.warning("管理员账号不能重复！")
        return
      }
      companyRow.personInCharges = companyRow.personInCharges.map((item) => ({
        id: item.id,
        companyUsername: item.companyUsername,
        password: item.password,
        isEnable: item.isEnable,
        phone: item.phone,
        name: item.name
      }))
      setConfirmLoading(true);
      let ids = ""
      if (typeof companyRow.businessLicense === 'object') {
        ids = getUploadIds(companyRow.businessLicense)
        companyRow.businessLicense = getUploadArrayToString(companyRow.businessLicense)
      }
      if (companyRow.id) {
        const updateCompany: UpdateCompanyReq = {...companyRow}
        CompanyService.updateCompanyById(companyRow.id, updateCompany).then(rsp => {
          actionThen(rsp, ids);
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        CompanyService.createCompany(companyRow).then(rsp => {
          actionThen(rsp, ids);
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    })
  }

  const actionThen = async (rsp: ResponseEntity<CompanyRow>, ids: string) => {
    // 图片上传跟表单绑定
    try {
      await ToolService.submittedSuccess(ids, rsp.data.id)
      message.success("操作成功！")
    } catch (e) {
      showErr(e)
    } finally {
      setVisible(false);
      setCompanyInfo(undefined)
      refreshData(companyReq)
    }
  }

  const handleCancel = () => {
    setVisible(false);
    setCompanyInfo(undefined);
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let companyRequest = {...companyReq};
    companyRequest.perPage = pageSize
    companyRequest.pageNum = page
    setCompanyReq(companyRequest)
    refreshData(companyRequest)
  }

  return (
    <div className={"company"}>
      <Row>
        <Col span={8}>
          <Input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={onSearch}
            allowClear
            placeholder="请输入公司名称"
            suffix={<SearchOutlined onClick={onSearch}/>}
          />
        </Col>
        <Col span={16} className={"text-align-right"}>
          <Button icon={<DeleteOutlined/>} size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={() => handleDeleteBatch(undefined)}>删除</Button>

          <Button icon={<PlusOutlined/>} type={"primary"} size={"middle"} shape={"round"}
            onClick={handleAdd}>新增</Button>
        </Col>
      </Row>
      <Card className={"margin-top-sm"}>
        <Table 
          rowKey={"id"} 
          dataSource={dataSource} 
          columns={columns} 
          size={"middle"}  
          loading={companyStore.companyDataSource.loading || confirmLoading}
          pagination={{
            total: total, pageSize: 
            companyReq.perPage,
            position: ['bottomCenter'],
            current: companyReq.pageNum, 
            showSizeChanger: true, 
            showQuickJumper: true,
            showTotal: total => `共${total}条`, 
            onChange: handlePageChange
          }}
          rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys}}/>
      </Card>

      <Drawer
        title={<div className="text-align-center">公司录入</div>} 
        width={DEFAULT_MODAL_WIDTH}
        open={visible}
        onClose={handleCancel}>
        <BasicInfo row={{...companyInfo!}} ref={basicInfo}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          <Button type="primary" onClick={handleOk} loading={confirmLoading} className={"margin-left-xs"}>确定</Button>
        </div>
      </Drawer>
    </div>
  )
})