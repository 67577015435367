import React, {useEffect, useState} from 'react'
import './OnBoardingUsers.less'
import {Button, Col, Input, message, Modal, Row, Select, Space, Table, Tabs, Upload} from "antd";
import {UpdateWorkerRecruitmentRow, WorkerRecruitmentReq, WorkerRecruitmentRow} from "@services/dto/workerRecruitment";
import type {ColumnsType} from 'antd/es/table';
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {RecruitmentRow} from "@services/dto/recruitment";
import {Page, StateFullData} from "@services/dto/common";
import {WorkerRecruitmentService} from "@services/WorkerRecruitmentService";
import {showErr, useUploadProps} from "@common/utils";
import {BASE_API_PATH, VIEW_TEXT, WorkerRecruitmentStatus} from "@/config";
import {SearchOutlined} from "@ant-design/icons";
import {previewFile} from "@components/CustomUpload";
import {UploadChangeParam} from "antd/es/upload/interface";

export const OnBoardingUsers = observer(() => {
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState(WorkerRecruitmentStatus.hired)
  const columns: ColumnsType<WorkerRecruitmentRow> = [
    {
      title: '姓名',
      dataIndex: ['user', 'name'],
      align: "center"
    },
    {
      title: '电话',
      dataIndex: ['user', 'phone'],
      align: "center"
    },
    {
      title: '评分',
      dataIndex: ['user', 'systemScore'],
      align: "center"
    },
    {
      title: '籍贯',
      dataIndex: ['user', 'nativePlace'],
      align: "center"
    },
    {
      title: '办理进度',
      dataIndex: ['status'],
      align: "center",
      render: (_, record) => (
        <Space>
          { statusText(record) }
        </Space>
      )
    },
    {
      title: '操作',
      align: "center",
      render: (_, record) => (
        <Space size="middle" key={record._id}>
          { buttons(record) }
          <Button type="link"
            onClick={() => navigate(`/onBoardingUsers/${record._id}/users/${record.user._id}`)}>{ VIEW_TEXT }</Button>
        </Space>
      ),
    },
  ];
  const statusText = (record: WorkerRecruitmentRow) => {
    switch (record.status) {
    case WorkerRecruitmentStatus.recruiterConfirmed:
      return <>
        <div>待审核</div>
      </>
    case WorkerRecruitmentStatus.pmConfirmed:
      return <>
        <div>待入职</div>
      </>
    case WorkerRecruitmentStatus.hired:
      return <>
        <div>已入职</div>
      </>
    case WorkerRecruitmentStatus.exited:
      return <>
        <div>已离职</div>
      </>
    default:
      return <>
        <div>{ record.status }</div>
      </>
    }
  }

  const buttons = (record: WorkerRecruitmentRow) => {
    const gotoDetails = ()=>navigate(`/onBoardingUsers/${record._id}/users/${record.user._id}?status=${record.status}`)
    switch (record.status) {
    case WorkerRecruitmentStatus.recruiterConfirmed:
      return <>
        <Button type="link" onClick={() => handleUpdateStatus(record, WorkerRecruitmentStatus.pmConfirmed)}>通过</Button>
        <Button type="link" onClick={() => handleDelete(record)}>删除</Button>
      </>
    case WorkerRecruitmentStatus.pmConfirmed:
      return <>
        <Button type="link" onClick={() => handleUpdateStatus(record, WorkerRecruitmentStatus.recruiterConfirmed)}>驳回</Button>
        <Button type="link" onClick={() => gotoDetails()}>入职</Button></>
    case WorkerRecruitmentStatus.hired:
      return <><Button type="link" onClick={() => gotoDetails()}>离职</Button></>
    case WorkerRecruitmentStatus.exited:
      break;
    }
  }

  const handleDelete = (record: WorkerRecruitmentRow) => {
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        WorkerRecruitmentService.deleteWorkerRecruitment(record._id).then(() => {
          message.success("删除成功！")
          refreshData(workerRecruitmentReq)
        }).catch(showErr)
      },
    })
  }

  const handleUpdateStatus = (record: WorkerRecruitmentRow, status: string) => {
    const wrObj: UpdateWorkerRecruitmentRow = {status: status}
    WorkerRecruitmentService.updateWorkerRecruitment(record._id, wrObj).then(() => {
      message.success("操作完成！")
      refreshData(workerRecruitmentReq)
    }).catch(showErr)
  }

  const {recruitmentStore, wrStore, securityStore} = allStores;
  const [curRecruitment, setCurRecruitment] = useState<StateFullData<Page<RecruitmentRow>>>({})
  const [userKeyword, setUserKeyword] = useState<string>('')
  const [selectRecruitmentId, setSelectRecruitmentId] = useState<string>()
  const [total, setTotal] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(10)
  const [workerRecruitmentReq, setWorkerRecruitmentReq] = useState<WorkerRecruitmentReq>({
    pageNum: 1,
    perPage: pageSize,
    status: activeKey,
  })
  useEffect(() => {
    recruitmentStore.loadRecruitments({status: "online", companyId : securityStore.getLoggedUser()?.companyId})
  }, [])

  useEffect(() => {
    setCurRecruitment(recruitmentStore.curRecruitment)
  }, [recruitmentStore.curRecruitment])

  useEffect(() => {
    handlePressEnter()
  }, [activeKey, selectRecruitmentId])


  const [dataSource, setDataSource] = useState<WorkerRecruitmentRow[]>([])
  useEffect(() => {
    setDataSource(wrStore.workerRecruitmentDataSource.data?.items!)
    setTotal(wrStore.workerRecruitmentDataSource.data?.total!)
  }, [wrStore.workerRecruitmentDataSource])

  const handlePageChange = (page: number, pageSize: number) => {
    let workerRecruitmentReqParams = {...workerRecruitmentReq}
    workerRecruitmentReqParams.perPage = pageSize
    workerRecruitmentReqParams.pageNum = page
    setPageSize(pageSize)
    setWorkerRecruitmentReq(workerRecruitmentReqParams)
    refreshData(workerRecruitmentReqParams)
  }

  const refreshData = (workerRecruitmentReq: WorkerRecruitmentReq) => {
    wrStore.getWorkerRecruitments(workerRecruitmentReq)
  }

  const handlePressEnter = () => {
    let workerRecruitmentReqParams = {...workerRecruitmentReq}
    workerRecruitmentReqParams.pageNum = 1
    workerRecruitmentReqParams.projectId = selectRecruitmentId
    workerRecruitmentReqParams.status = activeKey
    workerRecruitmentReqParams.keyword = userKeyword;
    setWorkerRecruitmentReq(workerRecruitmentReqParams)
    refreshData(workerRecruitmentReqParams);
  }

  const downloadTemplate = () => {
    window.open(BASE_API_PATH + "/filepath/花名册.xlsx")
  }

  const uploadChange = (info: UploadChangeParam) => {
    if (info.file.status === 'done') {
      if (info.file.response.data) {
        message.success("文件个别导入失败, 失败列表");
        previewFile(info.file.response.data)
      } else {
        message.success(`${info.file.name} 文件导入成功`);
      }
      refreshData(workerRecruitmentReq)
    }
  }

  return <div className="on-board-container">
    <div className='top-container'>
      <Row gutter={16}>
        <Col xl={4} md={6} xs={12}>
          <Select value={selectRecruitmentId} allowClear onChange={setSelectRecruitmentId} className={"flex2 width-100-percentage"} loading={curRecruitment.loading}
            placeholder="请先选择招聘项目">
            {
              curRecruitment.data?.items.map(item => {
                return (
                  <Select.Option key={item._id} value={item.projectId}>{ item.projectName }</Select.Option>
                )
              })
            }
          </Select>
        </Col>
        <Col xl={6} md={8} xs={12}>
          <Input allowClear className={"flex1"} placeholder="按姓名或者手机号搜索" value={userKeyword}
            onChange={(e) => setUserKeyword(e.target.value)} onPressEnter={handlePressEnter}
            suffix={<SearchOutlined onClick={handlePressEnter}/>}/>
        </Col>
        <Col xl={14} md={10} xs={24}>
          <Button size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={downloadTemplate}>花名册模板下载</Button>
          <Upload {...useUploadProps("/v1/system/user/import", uploadChange)}>
            <Button size={"middle"} shape={"round"}>导入花名册</Button>
          </Upload>
        </Col>
      </Row>
      <Tabs activeKey={activeKey} onChange={(activeKey) => setActiveKey(activeKey)}>
        <Tabs.TabPane tab="已入职" key={WorkerRecruitmentStatus.hired}/>
        <Tabs.TabPane tab="待入职" key={WorkerRecruitmentStatus.pmConfirmed}/>
        <Tabs.TabPane tab="待审核" key={WorkerRecruitmentStatus.recruiterConfirmed}/>
        <Tabs.TabPane tab="已离职" key={WorkerRecruitmentStatus.exited}/>
      </Tabs>
    </div>
    <div className="table-container margin-top-sm">
      <Table columns={columns} dataSource={dataSource} rowKey={'_id'} loading={wrStore.workerRecruitmentDataSource.loading}
        pagination={{
          current: workerRecruitmentReq.pageNum, total: total, pageSize: pageSize, showSizeChanger: true, showQuickJumper: true,
          showTotal: total => `共${total}条`, onChange: handlePageChange
        }}/>
    </div>
  </div>
})
