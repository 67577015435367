import React, {FC} from "react";
import {observer} from "mobx-react";
import {Card, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {MonthSalarySumRow} from "@services/dto/monthSalary";

export interface PropsType {
    className?: string
    loading: boolean
    total: number
    perPage: number
    pageNum: number
    selectedRowKeys: React.Key[]
    handlePageChange: (page: number, pageSize: number) => void
    handleChange: (selectedRowKeys: React.Key[], selectedRows: MonthSalarySumRow[]) => void
    dataSource: MonthSalarySumRow[]
}

export const MonthSalaryTable:FC<PropsType> = observer((props: PropsType) => {
  const handlePageChange = (page: number, pageSize: number) => {
    props.handlePageChange(page, pageSize)
  }

  const handleChange = (selectedRowKeys: React.Key[], selectedRows: MonthSalarySumRow[]) => {
    props.handleChange(selectedRowKeys, selectedRows);
  }

  const columns: ColumnsType<MonthSalarySumRow> = [
    {
      title: '项目名称',
      dataIndex: "projectName",
      align: "center",
    },
    {
      title: '时间',
      dataIndex: "time",
      align: "center",
    },
    {
      title: '计薪人数',
      dataIndex: "payrollNum",
      align: "center",
    },
    {
      title: '新入职',
      dataIndex: "newEntry",
      align: "center",
    },
    {
      title: '离职',
      dataIndex: "exitedNum",
      align: "center",
    },
    {
      title: '调薪',
      dataIndex: "changeASalary",
      align: "center",
    },
    {
      title: '应发工资',
      dataIndex: "wagespayable",
      align: "center",
    },
    {
      title: '个税',
      dataIndex: "sui",
      align: "center",
    },
    {
      title: '实发工资',
      dataIndex: "netSalary",
      align: "center",
    }
  ];
    
  return (
    <>
      <Card className={props.className}>
        <Table
          rowKey={(record) => record.time + record.projectId}
          dataSource={props.dataSource}
          columns={columns}
          size={"middle"}
          loading={props.loading}
          pagination={{
            total: props.total,
            position: ['bottomCenter'],
            pageSize: props.perPage,
            current: props.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`, onChange: handlePageChange
          }}
          rowSelection={{selectedRowKeys: props.selectedRowKeys, onChange: handleChange}}/>
      </Card>
    </>
  )
})