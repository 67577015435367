import React, {useEffect, useState} from "react";
import {Col, Form, Input, InputNumber, Radio, Row, Select} from "antd";
import {FormInstance} from "antd/es";
import {Recipient, SuppliesRow} from "@services/dto/supplies";
import {observer} from "mobx-react";
import {showErr} from "@common/utils";
import {SuppliesService} from "@services/SuppliesService";
import {ProjectRow} from "@services/dto/project";
import {ProjectService} from "@services/ProjectService";
import {SelectDict} from "@components/SelectDict";

export interface PropsType {
  row: SuppliesRow
  isView: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [projects, setProjects] = useState<ProjectRow[]>([])
  const [row, setRow] = useState<ProjectRow>()
  const [recipients, setRecipients] = useState<Recipient[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const [form] = Form.useForm()
  useEffect(() => {
    form.resetFields()
    const suppliesRow = {...props.row}
    if (props.row.id) {
      suppliesRow.user = {value: suppliesRow.userId, label: suppliesRow.userName};
      suppliesRow.project = {value: suppliesRow.projectId, label: suppliesRow.projectName}
      suppliesRow.professionalLeagues = {
        ...suppliesRow.professionalLeagues,
        value: suppliesRow.professionalLeagues.id,
        label: suppliesRow.professionalLeagues.name
      }
    }
    form.setFieldsValue(suppliesRow)
  }, [props.row])

  useEffect(() => {
    ProjectService.getProject().then(rsp => {
      setProjects(rsp.data)
    }).catch(showErr)
  }, [])

  const getRecipient = (projectId: string) => {
    setLoading(true)

    SuppliesService.getWorkerRecruitmentsUser(projectId).then(rsp => {
      setRecipients(rsp.data)
    }).catch(showErr).finally(() => setLoading(false))
  }

  useEffect(() => {
    if (props.row) {
      const obj = projects.find(item => item.id === props.row.projectId);
      if (obj) {
        setRow(obj)
        getRecipient(props.row.projectId)
      }
    }
  }, [projects])

  const handleChange = (value: { value: string, label: string }) => {
    const obj = projects.find(item => item.id === value.value)
    form.resetFields(['professionalLeagues', "user"])
    setRow(obj)
    getRecipient(value.value)
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="项目"
            name="project"
            rules={[{required: true, message: '请选择项目',}]}>
            <Select
              placeholder="请选择项目" 
              labelInValue 
              onChange={handleChange} 
              showSearch
              filterOption={(inputValue, option) => String(option?.name).includes(inputValue)}
              fieldNames={{value: "id", label: "name"}}
              options={projects}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="物资编号"
            name="code"
            rules={[{required: true, message: '请输入物资编号!'}]}>
            <Input placeholder="请输入物资编号"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="物资名称"
            name="name"
            rules={[{required: true, message: '请输入物资名称!'}]}>
            <Input placeholder="请输入物资名称"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="数量"
            name="num"
            rules={[{required: true, message: '请输入数量!'}]}>
            <InputNumber placeholder="请输入数量" precision={0} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="单位"
            name="unit"
            rules={[{required: true, message: '请选择单位',}]}>
            <SelectDict allowClear code={"dict_unit"} placeholder={"请选择单位"} className={'width-100-percentage'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="物资规格"
            name="specifications"
            rules={[{required: true, message: '请输入物资规格!'}]}>
            <Input placeholder="请输入物资规格"/>
          </Form.Item>
        </Col>
        {
          row &&
                  <Col span={24}>
                    <Form.Item
                      label="领用班"
                      name="professionalLeagues"
                      rules={[{required: true, message: '请选择领用班',}]}>
                      <Select placeholder="请选择领用班" labelInValue >
                        { row.name }
                      </Select>
                    </Form.Item>
                  </Col>
        }
        {
          row && <Col span={24}>
            <Form.Item
              label="领用人"
              name="user"
              rules={[{required: true, message: '请选择领用人'}]}>
              <Select placeholder="请选择领用人" labelInValue loading={loading}>
                { recipients.map((item) => (
                  <Select.Option key={item.id} value={item.id}>{ item.name }</Select.Option>)) }
              </Select>
            </Form.Item>
          </Col>
        }

        <Col span={24}>
          <Form.Item
            label="原因"
            name="original"
            rules={[{required: true, message: '请输入原因',}]}>
            <Input.TextArea placeholder="请输入原因" autoSize={{minRows: 3, maxRows: 5}}/>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="status"
            rules={[{required: true, message: '请选择办理进度',}]}>
            <Radio.Group>
              <Radio value={0}>已归还</Radio>
              <Radio value={1}>已出库</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="备注"
            name="remark"
            rules={[{required: false, message: '请输入备注',}]}>
            <Input.TextArea placeholder="请输入备注" autoSize={{minRows: 3, maxRows: 5}}/>
          </Form.Item>
        </Col>

      </Row>
    </Form>
  )
}))
