import React, {useEffect, useState} from "react";
import {DatePicker} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {SelectInvoiceRegister} from "@components/SelectInvoiceRegister";
import dayjs from "dayjs";
import {Any} from "@common/types";
import {ContractSettlementReq} from "@services/dto/contractSettlement";
import {SelectContractList} from "@components/SelectContractList";
import {CustomInputNumber} from "@components/CustomInputNumber";
import {SelectDict} from "@components/SelectDict";
import {SelectBankAccount} from "@components/SelectBankAccount";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectFiling} from "@components/SelectFiling";
import {SelectFundApproval} from "@components/SelectFundApproval";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {CustomAuthHeaderAction, PropsType as ParentPropsType} from "@components/CustomAuthHeaderAction";

export interface PropsType extends ParentPropsType {
  updateCount?: number, // 更新渲染标志
  refreshData?: (req: ContractSettlementReq) => void,
  setCollapsed?: (v: boolean) => void, // 展开事件
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<ContractSettlementReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: ContractSettlementReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }


  const handleCsDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.csDate1 = dateStrings[0]
    req.csDate2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleContractListChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.csContract = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleFundingApprovalIdChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.fundingApprovalId = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleInvoiceRegisterChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.lnvoiceRegistrationId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCsEndTypeChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.csEndType = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCsEndOutAccountIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.csEndOutAccountId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handlecsEndInAccountIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.csEndInAccountId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleSearchMoneyRange = (value: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.minCsEndAmount = value[0]
    req.maxCsEndAmount = value[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonResponsibleChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCsFileChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.csFile = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCsStatusChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.csStatus = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleOrganizersUserIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.organizersUserId = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    // req.cooperationUnit = undefined
    // req.responsibilityProject = undefined

    req.csStatus = undefined
    req.personResponsible = undefined
    req.personResponsibleName = undefined
    req.organizersUserId = undefined // 主办人员
    req.organizersUserName = undefined // 主办人员名
    req.csFile = undefined
    req.csEndOutAccount = undefined
    req.csEndInAccount = undefined
    req.csEndType = undefined
    req.csContract = undefined
    req.lnvoiceRegistrationId = undefined
    req.csDate1 = undefined
    req.csDate2 = undefined
    req.minCsEndAmount = undefined
    req.maxCsEndAmount = undefined
    req.fundingApprovalId = undefined

    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <CustomAuthHeaderAction
        locationPathname={props.locationPathname}
        size={props.size}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        items={props.items}
        isShowKeyword={!props.refreshData}
        collapsedChildren={props.refreshData ? [
          <DatePicker.RangePicker
            allowClear
            onChange={handleCsDateChange}
            value={searchReq?.csDate1 ? [dayjs(searchReq?.csDate1), dayjs(searchReq?.csDate2)] : [undefined, undefined]}
            placeholder={['制单开始日期', '制单结束日期']}
            className={"width-100-percentage"}/>,
          <SelectDict
            className={'width-100-percentage'}
            allowClear
            value={searchReq?.csEndType}
            onChange={handleCsEndTypeChange}
            code={'payment_typee'}
            placeholder={'请输入结算类别'}/>,
          <CustomInputNumber
            onSearch={handleSearchMoneyRange}
            value={[searchReq?.minCsEndAmount, searchReq?.maxCsEndAmount]}
            className={'width-100-percentage'}
            placeholder={['结算最小金额', '结算最大金额']}/>,
          <SelectBankAccount
            className={'width-100-percentage'}
            allowClear
            value={searchReq?.csEndOutAccountId}
            onChange={handleCsEndOutAccountIdChange}
            placeholder={'请选择付款账户'}/>,
          <SelectBankAccount
            className={'width-100-percentage'}
            allowClear
            value={searchReq?.csEndInAccountId}
            onChange={handlecsEndInAccountIdChange}
            placeholder={'请选择收款账户'}/>,
          <SelectContractList
            allowClear
            value={searchReq?.csContract}
            onChange={handleContractListChange}
            placeholder={'请选择关联商务合同'}
            fieldNames={{value: 'id', label: 'clCode'}}/>,
          <SelectFundApproval
            className={'width-100-percentage'}
            value={searchReq?.fundingApprovalId}
            onChange={handleFundingApprovalIdChange}
            placeholder={'请选择关联资金审批'}
            allowClear/>,
          <SelectInvoiceRegister
            fieldNames={{label:'irInvoiceCode2', value:'id'}}
            onChange={handleInvoiceRegisterChange}
            allowClear
            value={searchReq?.lnvoiceRegistrationId}
            isHiddenOption={true}
            className={"width-100-percentage"}/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
          <SelectCooperativeUnit
            value={searchReq?.cooperationUnit}
            onChange={handleCooperationUnitChange}
            allowClear
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectFiling
            placeholder={"请选择存档批号"}
            allowClear
            isFaBatchNumberGroupBy
            fieldNames={{label: 'faBatchNumber', value: 'faBatchNumber'}}
            value={searchReq?.csFile}
            onChange={handleCsFileChange}
            className={'width-100-percentage'}
            isHiddenOptions/>,
          <SelectPersonCharge
            onChange={handlePersonResponsibleChange}
            className={"width-100-percentage"}
            value={searchReq?.personResponsible}
            placeholder={"请选择办理人员"}
            allowClear/>,
          <SelectPersonCharge
            onChange={handleOrganizersUserIdChange}
            className={"width-100-percentage"}
            placeholder={"请选择主办人员"}
            value={searchReq?.organizersUserId}
            allowClear/>,
          <SelectProcessStatus
            onChange={handleCsStatusChange}
            allowClear
            value={searchReq?.csStatus}
            placeholder={'请选择办理进度'}
            className={"width-100-percentage"}/>,
        ] : []}/>
    </>
  )
}))
