import React, {useEffect, useState} from 'react'
import {CalendarMonth} from "@components/CalendarMonth";
import dayjs, {Dayjs} from "dayjs";
import {DATE_FMT, DATE_MONTH_FMT} from "@/config";
import {InfoBody, InfoBodyRow} from "@modules/HR/Components/BasicInfo";
import {Modal, Typography, Button, Spin, InputNumber, Select, Input} from "antd";
import {Calculated, CreateWorkRecordReq, UpdateWorkRecordReq, WorkRecord} from "@services/dto/workTime";
import {allStores} from "@/stores";
import {observer} from "mobx-react";
import {WorkTimeService} from "@services/WorkTimeService";
import {showErr} from "@common/utils";
import './WorkTime.less'
import {
  CreateRewardPunishmentReq,
  RewardPunishmentRow
} from "@services/dto/rewardPunishment";
import {RewardPunishmentService} from "@services/RewardPunishmentService";

export const WorkTime = observer(() => {
  const {workTimeStore, wrStore, rewardPunishmentStore} = allStores
  const {wr: stateWr} = wrStore
  const wr = stateWr.data!;
  const {workTimes} = workTimeStore
  const [id, setId] = useState<string>("")
  const [workRecords, setWorkRecords] = useState<WorkRecord[]>([]);
  const [calculated, setCalculated] = useState<Calculated[]>([]);
  const [totalOfSalary, setTotalOfSalary] = useState<number>(0);
  const [nowYearMonth, setNowYearMonth] = useState<dayjs.Dayjs>(dayjs())
  const [totalDaysInMonth, setTotalDaysInMonth] = useState<number>(dayjs().daysInMonth());
  const [key, setKey] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [workTime, setWorkTime] = useState<number>(0);
  const noRecords = workRecords.length === 0;
  const [spinning, setSpinning] = useState<boolean>(false)
  // 奖惩相关信息
  const [rewardPunishments, setRewardPunishments] = useState<RewardPunishmentRow[]>([])
  const [status, setStatus] = useState<number>()
  const [remark, setRemark] = useState<string>()
  const [amount, setAmount] = useState<number>()

  useEffect(() => {
    refreshData()
  }, [wr])

  useEffect(() => {
    setDaysInMonth()
  }, [workTimeStore.workTimes])

  useEffect(() => {
    refreshData()
  }, [nowYearMonth])

  useEffect(() => {
    setRewardPunishments(rewardPunishmentStore.rewardPunishments.data?.items!)
  }, [rewardPunishmentStore.rewardPunishments])

  const refreshData = (reCalculate?: boolean) => {
    workTimeStore.getWorkerTimes({
      userId: wr.user._id,
      recruitmentId: wr.recruitment._id,
      year: nowYearMonth.year(),
      month: nowYearMonth.month() + 1,
      reCalculate
    })
    rewardPunishmentStore.getRewardPunishment({
      userId: wr.user._id,
      year: nowYearMonth.year(),
      month: nowYearMonth.month() + 1,
      pageNum: 1,
      perPage: 35
    })
  }

  const setDaysInMonth = () => {
    if (!workTimes.data) {
      return;
    }
    let works: WorkRecord[] = []
    let calculated: Calculated[] = []
    workTimes.data.items.forEach(item => {
      setId(item._id)
      works = works.concat(item.workRecords);
      calculated = calculated.concat(item.calculated)
      setTotalOfSalary(item.totalOfSalary)
    });
    setCalculated(calculated)
    setWorkRecords(works)
  }

  const renderDay = (date: Dayjs) => {
    const key = date.format(DATE_FMT);
    const record = workRecords.find(i => i.workDate === key)
    if (!record) {
      return <>
        { getRewardPunishmentJsx(key) }
        <div className="record no-record">无数据</div>
      </>
    }
    if (record.spendTime <= 0) {
      return <>
        { getRewardPunishmentJsx(key) }
        <div className="record not-work">未出勤</div>
      </>
    }
    return <>
      { getRewardPunishmentJsx(key) }
      <div className="record work">工作{ Math.min(record.spendTime, 8).toFixed(1) }时</div>
      { record.spendTime > 8 && <div className="record work">加班{ (record.spendTime - 8).toFixed(1) }时</div> }
    </>;
  }

  const getRewardPunishmentJsx = (key: string) => {
    if (!rewardPunishments || rewardPunishments.length <= 0) {
      return <></>
    }
    const item = rewardPunishments.find(i => i && i.rewardTime === key);
    if (!item) {
      return <></>
    }
    switch (item.status) {
    case 1:
      return <div className={"record work"}>奖励{ (item.amount || 0) + "元" }</div>
    case 2:
      return <div className={"record not-work"}>处罚{ (item.amount || 0) + "元" }</div>
    case 3:
      return <div className={"record not-work"}>其他扣款{ (item.amount || 0) + "元" }</div>
    case 4:
      return <div className={"record work"}>其他加款{ (item.amount || 0) + "元" }</div>
    default:
      return <></>
    }
  }

  const handleOk = () => {
    const records = [...workRecords || []]
    const record = records.find(i => i.workDate === key)
    if (record) {
      record.spendTime = workTime;
      const row:UpdateWorkRecordReq = {
        workRecords: convertWorkRecords(records)
      }
      updateWorkerTimes(row)
    } else {
      records.push({
        workDate: key,
        spendTime: workTime,
        clocIns: []
      })
      const row: CreateWorkRecordReq = {
        year: nowYearMonth.year(),
        month: nowYearMonth.month() + 1,
        userId: wr.user._id,
        recruitmentId: wr.recruitment._id,
        workRecords: convertWorkRecords(records)
      }
      addWorkerTimes(row)
    }

    const obj = rewardPunishments.find(i => i.rewardTime === key)
    const reward: CreateRewardPunishmentReq = {
      userId: wr.user._id,
      professionalLeagues: wr.professionalLeaguesName!,
      professional: wr.user.professional,
      projectId: wr.projectId,
      rewardTime: key,
      status,
      remark,
      amount
    }
    if (obj) {
      RewardPunishmentService.updateRewardPunishment(obj.id, reward).catch(showErr)
    } else {
      RewardPunishmentService.createRewardPunishment(reward).catch(showErr)
    }
  };

  const convertWorkRecords = (records: WorkRecord[]) => {
    return records.map(item => ({
      workDate: item.workDate,
      spendTime: item.spendTime,
      clocIns: item.clocIns
    }))
  }

  const updateWorkerTimes = (row: UpdateWorkRecordReq) => {
    setSpinning(true)
    WorkTimeService.updateWorkerTimes(id, row).then(rsp => {
      refreshData()
      setIsModalVisible(false);
    }).catch(showErr).finally(() => {
      setSpinning(false)
    });
  }

  const addWorkerTimes = (row: CreateWorkRecordReq) => {
    setSpinning(true)
    WorkTimeService.addWorkerTimes(row).then(rsp => {
      refreshData()
      setIsModalVisible(false);
    }).catch(showErr).finally(() => {
      setSpinning(false)
    });
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDayChange = (date: Dayjs) => {
    const key = date.format(DATE_FMT);
    const record = workRecords.find(i => i.workDate === key)
    if (record) {
      setWorkTime(record.spendTime);
    } else {
      setWorkTime(0)
    }
    const item = rewardPunishments.find(i => i.rewardTime === key)
    if (item) {
      setAmount(item.amount);
      setStatus(item.status)
      setRemark(item.remark)
    } else {
      setAmount(undefined);
      setStatus(undefined)
      setRemark(undefined)
    }

    setKey(key)
    setIsModalVisible(true)
  }
  
  const handleDateChange = (dateString: string) => {
    if (!dateString) {
      return;
    }
    setNowYearMonth(dayjs(dateString))
    setTotalDaysInMonth(dayjs(dateString).daysInMonth())
  }

  // 出勤的天数
  const totalOfWorkDays = workRecords.filter(i => i.spendTime > 0).length;

  // 有效工作天数
  const validWorkTime = workRecords.reduce(function (s, c) {
    return s + c.spendTime;
  }, 0);

  // 加班的天数
  const moreWorkDays = workRecords.filter(i => i.spendTime > 8).length;


  const WORK_INFO_FIELDS: InfoBodyRow[] = [
    {title: '当月总天数', value: totalDaysInMonth},
    {title: '出勤天数', value: workRecords.filter(i => i.spendTime > 0).length},
    {title: '缺勤天数', value: totalDaysInMonth - totalOfWorkDays},
    {title: '加班天数', value: moreWorkDays},
    {title: '工作时长', value: validWorkTime},
  ];

  const SALARY_INFO_FIELDS: InfoBodyRow[] = [];
  for (let i = 0; i < calculated.length; i++) {
    const item = calculated[i];
    SALARY_INFO_FIELDS.push({
      title: item.name,
      value: `${item.amount.toFixed(2)}元`
    })
  }
  if (totalOfSalary) {
    SALARY_INFO_FIELDS.unshift({title: '总计', value: `${totalOfSalary.toFixed(2)}元`})
  }

  const generateAttendanceData = () => {
    const works: WorkRecord[] = []
    for (let i = 1; i <= totalDaysInMonth; i++) {
      const workDate = dayjs(nowYearMonth.toISOString()).set("dates", i).format(DATE_FMT)
      works.push({workDate: workDate, spendTime: 8, clocIns: []})
    }
    const row: CreateWorkRecordReq = {
      year: nowYearMonth.year(),
      month: nowYearMonth.month() + 1,
      userId: wr.user._id,
      recruitmentId: wr.recruitment._id,
      workRecords: works
    }
    addWorkerTimes(row)
  }
  
  const handleStatusChange = (value: number) => {
    setStatus(value)
    if (!value) {
      setRemark(undefined)
      setAmount(undefined)
    }
  }

  return <Spin spinning={spinning || workTimes.loading}>
    <div className="work-time-container">
      <div className="work-time-calendar">
        <CalendarMonth date={dayjs(nowYearMonth.format(DATE_MONTH_FMT))}
          renderDay={(date) => <div className="day-cell">{ renderDay(date) }</div>}
          popupDay={handleDayChange} handleDateChange={handleDateChange}/>
      </div>

      <div className="work-info-root">
        {
          noRecords && <div className="show">
            <Button type="primary" size="small" onClick={generateAttendanceData}>生成默认出勤数据</Button>
          </div>
        }

        { !noRecords && <>
          <Typography.Title level={4}>
                    出勤信息
          </Typography.Title>
          <InfoBody rows={WORK_INFO_FIELDS}/>

          <Typography.Title level={4}>
                    工资信息
            <Button loading={workTimeStore.workTimes.loading} disabled={workTimeStore.workTimes.loading}
              onClick={() => refreshData(true)} type={"primary"} size="small">刷新</Button>
          </Typography.Title>

          <InfoBody singleRow rows={SALARY_INFO_FIELDS}/>
        </>
        }
      </div>

      <Modal title={key} open={isModalVisible} onOk={handleOk} onCancel={handleCancel} maskClosable={false} confirmLoading={spinning}>
        <InputNumber 
          placeholder={"请输入工作时长"} 
          addonAfter={"时"} 
          precision={1} 
          value={workTime} 
          onChange={(e) => setWorkTime(e || 0)}
          className={"width-100-percentage"}/>

        <Select 
          placeholder="请选择奖惩类型" 
          allowClear 
          value={status}
          onChange={handleStatusChange}
          className={"width-100-percentage margin-top-xs"}>
          <Select.Option value={1}>奖励</Select.Option>
          <Select.Option value={2}>处罚</Select.Option>
          <Select.Option value={3}>其他扣款</Select.Option>
          <Select.Option value={4}>其他加款</Select.Option>
        </Select>

        {
          status && <InputNumber
            value={amount}
            onChange={(e) => setAmount(e || 0)}
            placeholder={"请输入奖惩金额"}
            precision={1}
            className={"width-100-percentage margin-top-xs"}/>
        }

        {
          status && <Input
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            placeholder={"请输入原因"}
            className={"margin-top-xs"}/>
        }
      </Modal>
    </div>
  </Spin>
})
