import {makeAutoObservable, runInAction} from "mobx";
import {Page, StateFullData} from "@services/dto/common";
import {TaxFeeStatisticsReq, TaxFeeStatisticsRow} from "@services/dto/taxFeeStatistics";
import {TaxFeeStatisticsService} from "@services/TaxFeeStatisticsService";

export class TaxFeeStatisticsStore {
  taxFeeStatisticsDatasource: StateFullData<Page<TaxFeeStatisticsRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getTaxFeeStatistics(req: TaxFeeStatisticsReq) {
    this.taxFeeStatisticsDatasource = {...this.taxFeeStatisticsDatasource, loading: true}
    TaxFeeStatisticsService.getTaxFeeStatistics(req).then(rsp => {
      runInAction(() => this.taxFeeStatisticsDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.taxFeeStatisticsDatasource = {...this.taxFeeStatisticsDatasource, loading: false, err})
    })
  }
}

export const taxFeeStatisticsStore = new TaxFeeStatisticsStore();
