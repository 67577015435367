import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {FileDistributionReq, FileDistributionRow} from "@/services/dto/fileDistribution";
import {FileDistributionService} from "@services/FileDistributionService";

export class FileDistributionStore {
  fileDistributionDatasource: StateFullData<Page<FileDistributionRow>> = {}
  fileDistributionSelectDatasource: StateFullData<Page<FileDistributionRow>> = {}
  fileDistributionRow?: FileDistributionRow
  fileDistributionOption?: string
  fileDistributionDetail: StateFullData<ResponseEntity<FileDistributionRow>> = {}
  fileDistributionDetails: StateFullData<ResponsesEntity<FileDistributionRow>> = {}
  fileDistributionSearchReq: FileDistributionReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateFileDistributionRow(row: FileDistributionRow) {
    this.fileDistributionRow = {...row}
  }

  updateFileDistributionOption(option: string) {
    this.fileDistributionOption = option
  }

  updateFileDistributionSearchReq(req: FileDistributionReq) {
    this.fileDistributionSearchReq = {...this.fileDistributionSearchReq, ...req}
  }

  getFileDistribution(req: FileDistributionReq) {
    this.fileDistributionDatasource = {...this.fileDistributionDatasource, loading: true}
    this.fileDistributionSearchReq = {...this.fileDistributionSearchReq, ...req}
    FileDistributionService.getFileDistribution(req).then(rsp => {
      runInAction(() => this.fileDistributionDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.fileDistributionDatasource = {
        ...this.fileDistributionDatasource,
        loading: false,
        err
      })
    })
  }

  getSelectFileDistribution(req: FileDistributionReq) {
    this.fileDistributionSelectDatasource = {...this.fileDistributionSelectDatasource, loading: true}
    FileDistributionService.getFileDistribution(req).then(rsp => {
      runInAction(() => this.fileDistributionSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.fileDistributionSelectDatasource = {
        ...this.fileDistributionSelectDatasource,
        loading: false,
        err
      })
    })
  }

  getOne(id: string) {
    this.fileDistributionDetail = {...this.fileDistributionDetail, loading: true}
    FileDistributionService.getOne(id).then(rsp => {
      runInAction(() => this.fileDistributionDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.fileDistributionDetail = {...this.fileDistributionDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.fileDistributionDetails = {...this.fileDistributionDetails, loading: true}
    FileDistributionService.getBatch(ids).then(rsp => {
      runInAction(() => this.fileDistributionDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.fileDistributionDetails = {...this.fileDistributionDetails, loading: false, err})
    })
  }

  async getPrintList(record: FileDistributionRow): Promise<LabelValue[]> {

    const row = this.fileDistributionDatasource.data?.items.find(o => o.id === record.id)

    return [
      {label: '制单日期', value: row.fdDate as string},
      {label: '制单编号', value: row.fdCode},
      {label: '文件名称', value: row.fdName},
      {label: '文件版本', value: row.fdVersion},
      {label: '摘要', value: row.fdDesc},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const fileDistributionStore = new FileDistributionStore();
