import './index.less'
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {Divider, Pagination, Select, Space} from "antd";
import React, {useEffect, useState} from "react";
import {Any} from "@common/types";
import {BLACK_LIST} from "@/config";
import {RoleManageReq, RoleManageRow} from "@services/dto/roleManage";

export interface PropsType {
  onChange?: (value: string | string[], options: RoleManageRow | RoleManageRow[]) => void
  value?: Any | null
  mode?: 'multiple' | 'tags'
  labelInValue?: boolean
  disabled?: boolean
  className?: string
  allowClear?: boolean
  placeholder?: string
  fieldNames?: { value: string, label: string }
}

export const SelectRoleManage = observer((props: PropsType) => {
  const [value, setValue] = useState<Any | null>()
  const {roleManageStore} = allStores
  const [dataSource, setDataSource] = useState<RoleManageRow[]>([])
  const [total, setTotal] = useState<number>(0)
  const [items, setItems] = useState<RoleManageRow[]>([])
  const [searchReq, setSearchReq] = useState<RoleManageReq>({
    pageNum: 1,
    perPage: 10,
  })

  /**
   *  每个组件默认写法 只需修改 'name'
   */
  const defaultItems: any[] = [
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: 'N/A',
      [props.fieldNames ? props.fieldNames.label : 'name']: 'N/A'
    },
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: '空白',
      [props.fieldNames ? props.fieldNames.label : 'name']: '空白'
    },
  ]

  /**
   * 初始化
   */
  useEffect(() => {
    refreshData(searchReq)
  }, [])

  /**
   * 办理进度数据变化
   */
  useEffect(() => {
    if (roleManageStore.roleManageSelectDatasource.data) {
      setDataSource(roleManageStore.roleManageSelectDatasource.data.items || [])
      setTotal(roleManageStore.roleManageSelectDatasource.data.total || 0)
      if (roleManageStore.roleManageSelectDatasource.data.items?.length) {
        setItems(roleManageStore.roleManageDetails.data?.data
          .filter(item => !(roleManageStore.roleManageSelectDatasource.data.items || [])
            .some(o => o.id === item.id)) || [])
      }
    }
  }, [roleManageStore.roleManageDetails, roleManageStore.roleManageSelectDatasource.data])

  /**
   * 值发生改变
   */
  useEffect(() => {
    setValue(props.value)
    if (typeof props.value === 'string' && !BLACK_LIST.includes(props.value)) {
      roleManageStore.getBatch([props.value])
    } else if (typeof props.value === 'object') {
      const ids = props.value.filter((v: string) => !BLACK_LIST.includes(v))
      if (ids.length) {
        roleManageStore.getBatch(ids)
      }
    }
  }, [props.value])

  const refreshData = (req: RoleManageReq) => {
    roleManageStore.getSelectRoleManage(req)
  }

  const onChange = (value: Any | null) => {
    setValue(value)
    if (props.onChange) {
      props.onChange(value, [
        ...dataSource,
        ...items,
      ]);
    }
  }

  const handleSearch = (inputValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = inputValue
    setSearchReq(req)
    refreshData(req)
    return true
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    const req = {...searchReq}
    req.pageNum = page
    req.perPage = pageSize
    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      {
        <Select
          allowClear={props.allowClear}
          placeholder={props.placeholder || "请选择角色"}
          className={`${props.className} width-100-percentage`}
          dropdownRender={(menu) => (
            <>
              { menu }
              <Divider className={"divider-margin"}/>
              <Space>
                <Pagination
                  total={total}
                  pageSize={searchReq.perPage}
                  size={"small"}
                  onChange={handlePaginationChange}/>
              </Space>
            </>
          )}
          value={value}
          onChange={onChange}
          showSearch
          onClear={() => handleSearch('')}
          filterOption={false}
          onSearch={handleSearch}
          disabled={props.disabled}
          mode={props.mode}
          loading={roleManageStore.roleManageSelectDatasource.loading}
          labelInValue={props.labelInValue}
          fieldNames={{value: "id", label: "name"}}
          options={[
            ...defaultItems,
            ...dataSource,
            ...items,
          ]}/>
      }
    </>
  )
})
