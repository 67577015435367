import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, StateFullData} from "@services/dto/common";
import {TaxAccountingReq, TaxAccountingRow} from "@services/dto/taxAccounting";
import {TaxAccountingService} from "@services/TaxAccountingService";
import {accAdd, formatMoney} from "@common/utils";

export class TaxAccountingStore {
  taxAccountingDatasource: StateFullData<Page<TaxAccountingRow>> = {}
  taxAccountingSelectDatasource: StateFullData<Page<TaxAccountingRow>> = {}
  taxAccountingRow?: TaxAccountingRow
  taxAccountingDetail: StateFullData<ResponseEntity<TaxAccountingRow>> = {}
  taxAccountingDetails: StateFullData<ResponseEntity<TaxAccountingRow[]>> = {}
  taxAccountingReq: TaxAccountingReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateTaxAccountingRow(row: TaxAccountingRow) {
    this.taxAccountingRow = {...row}
  }

  getTaxAccounting(req: TaxAccountingReq) {
    this.taxAccountingDatasource = {...this.taxAccountingDatasource, loading: true}
    this.taxAccountingReq = {...this.taxAccountingReq, ...req}
    TaxAccountingService.getTaxAccounting(req).then(rsp => {
      runInAction(() => this.taxAccountingDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.taxAccountingDatasource = {...this.taxAccountingDatasource, loading: false, err})
    })
  }

  getSelectTaxAccounting(req: TaxAccountingReq) {
    this.taxAccountingSelectDatasource = {...this.taxAccountingSelectDatasource, loading: true}
    TaxAccountingService.getTaxAccounting(req).then(rsp => {
      runInAction(() => this.taxAccountingSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.taxAccountingSelectDatasource = {...this.taxAccountingSelectDatasource, loading: false, err})
    })
  }

  getOne(id: string) {
    this.taxAccountingDetail = {...this.taxAccountingDetail, loading: true}
    TaxAccountingService.getOne(id).then(rsp => {
      runInAction(() => this.taxAccountingDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.taxAccountingDetail = {...this.taxAccountingDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.taxAccountingDetails = {...this.taxAccountingDetails, loading: true}
    TaxAccountingService.getBatch(ids).then(rsp => {
      runInAction(() => this.taxAccountingDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.taxAccountingDetails = {...this.taxAccountingDetails, loading: false, err})
    })
  }

  async getPrintList(record: TaxAccountingRow): Promise<LabelValue[]> {

    const row = this.taxAccountingDatasource.data?.items.find(o => o.id === record.id)

    const additionalTaxAmount = accAdd(accAdd(accAdd(accAdd(accAdd(row.taxAccountingMiddle.cityDue || 0, row.taxAccountingMiddle.educationDue || 0), row.taxAccountingMiddle.spaceDue || 0), row.taxAccountingMiddle.printDue || 0), row.taxAccountingMiddle.unionDue || 0), row.taxAccountingMiddle.hydraulicDue || 0).toFixed(2)

    return [
      {label: '制单日期', value: row.taDate as string},
      {label: '制单编号', value: row.taCode},
      {label: '核算部门', value: row.taSegment},
      {label: '核算企业', value: row.taEnterprise as string},
      {label: '税费摘要', value: row.taDesc},
      {label: '关联销项发票', value: row.invoiceRegistrationOut as string},
      {label: '关联进项发票', value: row.invoiceRegistrationOut as string},
      {label: '销项价税合计', value: formatMoney(row.taOutIrAmount) + '元'},
      {label: '进项价税合计', value: formatMoney(row.taInIrAmount) + '元'},
      {label: '销项税额合计', value: formatMoney(row.taOutTaxIrAmount) + '元'},
      {label: '进项税额合计', value: formatMoney(row.taInTaxIrAmount) + '元'},
      {label: '应缴增值税额', value: formatMoney(row.taVatPayable) + '元'},
      {
        label: '核算金额',
        value: (row.taxCategory === '增值税及附加' ? additionalTaxAmount : formatMoney(row.taxAccountingMiddle.personDue)) + '元'
      },
      {label: '付款账户', value: row.baNick1},
      {label: '收款账户', value: row.baNick2},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const taxAccountingStore = new TaxAccountingStore();
