import React, { useEffect } from "react";
import { Col, Form, Input, Row } from "antd";
import { FormInstance } from "antd/es";
import { observer } from "mobx-react";
import { WorkPostRow } from "@services/dto/workPost";

export interface PropsType {
  row: WorkPostRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    const workPostRow = { ...props.row }
    form.setFieldsValue(workPostRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ status: 1, isVirtualUser: 0 }}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="岗位名称"
            name="name"
            rules={[{ required: true, message: '请输入岗位名称!' }]}>
            <Input placeholder="请输入岗位名称" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="岗位代码"
            name="code"
            rules={[{ required: true, message: '请输入岗位代码!' }]}>
            <Input placeholder="请输入岗位代码" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="岗位职责"
            name="requirements2"
            rules={[{ required: true, message: '请输入岗位职责!' }]}>
            <Input.TextArea placeholder="请输入岗位职责" autoSize={{ minRows: 3, maxRows: 9 }} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="任职要求"
            name="requirements"
            rules={[{ required: true, message: '请输入任职要求!' }]}>
            <Input.TextArea placeholder="请输入任职要求" autoSize={{ minRows: 3, maxRows: 9 }} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
