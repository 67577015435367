import { makeAutoObservable, runInAction } from "mobx";
import { Page, StateFullData } from "@services/dto/common";
import { ContractRow, ContractSearchReq } from "@services/dto/contract";
import { ContractService } from "@services/ContractService";

export class ContractStore {
  contractDatasource: StateFullData<Page<ContractRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  searchContract(req: ContractSearchReq) {
    this.contractDatasource = { ...this.contractDatasource, loading: true }
    ContractService.searchContract(req).then(rsp => {
      runInAction(() => this.contractDatasource = { data: rsp, loading: false })
    }).catch(err => {
      runInAction(() => this.contractDatasource = { ...this.contractDatasource, loading: false, err })
    })
  }
}
export const contractStore = new ContractStore();
