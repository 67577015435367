import {
  BonusRow,
  SearchBonusRowReq
} from "@services/dto/bonus";
import { apiV1 } from "@services/apiHelper";
import { Page, ResponseEntity } from "@services/dto/common";

export class BonusService {
  static async search(req: SearchBonusRowReq): Promise<Page<BonusRow>> {
    return apiV1.get("/bonus", { params: req })
  }
  static async add(req: BonusRow): Promise<ResponseEntity<BonusRow>> {
    return apiV1.post("/bonus", { ...req })
  }
  static async update(req: BonusRow): Promise<ResponseEntity<BonusRow>> {
    return apiV1.put("/bonus", { ...req })
  }
  static async delete(ids: string[]): Promise<Page<BonusRow>> {
    return await apiV1.delete("/bonus", { data: ids })
  }
}
