import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {WorkScheduleReq, WorkScheduleRow} from "@services/dto/workSchedule";
import {WorkScheduleService} from "@services/WorkScheduleService";
import {BLACK_LIST} from "@/config";
import {FilingService} from "@services/FilingService";

export class WorkScheduleStore {
  workScheduleDatasource: StateFullData<Page<WorkScheduleRow>> = {}
  workScheduleSelectDatasource: StateFullData<Page<WorkScheduleRow>> = {}
  workScheduleRow?: WorkScheduleRow
  workScheduleOption?: string
  workScheduleDetail: StateFullData<ResponseEntity<WorkScheduleRow>> = {}
  workScheduleDetails: StateFullData<ResponsesEntity<WorkScheduleRow>> = {}
  workScheduleReq: WorkScheduleReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateWorkScheduleRow(row: WorkScheduleRow) {
    this.workScheduleRow = {...row}
  }

  updateWorkScheduleOption(option: string) {
    this.workScheduleOption = option
  }


  getWorkSchedule(req: WorkScheduleReq) {
    this.workScheduleDatasource = {...this.workScheduleDatasource, loading: true}
    this.workScheduleReq = {...this.workScheduleReq, ...req}
    WorkScheduleService.getWorkSchedule(req).then(rsp => {
      runInAction(() => this.workScheduleDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.workScheduleDatasource = {...this.workScheduleDatasource, loading: false, err})
    })
  }

  getSelectWorkSchedule(req: WorkScheduleReq) {
    this.workScheduleSelectDatasource = {...this.workScheduleSelectDatasource, loading: true}
    this.workScheduleReq = {...this.workScheduleReq, ...req}
    WorkScheduleService.getWorkSchedule(req).then(rsp => {
      runInAction(() => this.workScheduleSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.workScheduleSelectDatasource = {...this.workScheduleSelectDatasource, loading: false, err})
    })
  }

  getDetailById(id: string) {
    this.workScheduleDetail = {...this.workScheduleDetail, loading: true}
    WorkScheduleService.getOne(id).then(rsp => {
      runInAction(() => this.workScheduleDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.workScheduleDetail = {...this.workScheduleDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.workScheduleDetails = {...this.workScheduleDetails, loading: true}
    WorkScheduleService.getBatch(ids).then(rsp => {
      runInAction(() => this.workScheduleDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.workScheduleDetails = {...this.workScheduleDetails, loading: false, err})
    })
  }

  async getPrintList(record: WorkScheduleRow): Promise<LabelValue[]> {

    const row = this.workScheduleDatasource.data?.items.find(o => o.id === record.id)

    // 存档批号
    let efaFileArchiveRow = undefined
    if (row.wsFile && !BLACK_LIST.includes(row.wsFile)) {
      const efaFileArchiveRsp = await FilingService.getBatch([row.wsFile])
      if (efaFileArchiveRsp && efaFileArchiveRsp.code !== 200) {
        return
      }
      efaFileArchiveRow = efaFileArchiveRsp.data[0]
    }

    const wsDay = {
      0: '开始日期当天',
      1: '开始日期前1天',
      3: '开始日期前3天',
      7: '开始日期前7天',
      15: '开始日期前15天',
      30: '开始日期前30天',
    }[row.wsDay]

    return [
      {label: '制单日期', value: String(row.wsDate || '')},
      {label: '制单编号', value: row.wsCode},
      {label: '工作摘要', value: row.wsSummary},
      {label: '开始日期', value: String(row.wsWorkPlanStartDate || '')},
      {label: '完成日期', value: String(row.wsWorkPlanCompletionDate || '')},
      {label: '计划工期', value: row.wsWorkPlanDuration},
      {label: '通知日期', value: wsDay},
      {label: '循环周期', value: row.wsCyclePeriod},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '协作单位', value: row.cooperationUnit},
      {label: '存档批号', value: efaFileArchiveRow?.faBatchNumber},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const workScheduleStore = new WorkScheduleStore();
