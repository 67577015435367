import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {Col, DatePicker, Form, FormInstance, Input, InputNumber, Row} from "antd";
import {InsuranceRow} from "@services/dto/Insurance";
import {CascaderPerson} from "@components/CascaderPerson";
import dayjs from "dayjs";
import {PersonCascader} from "@services/dto/talent";

export interface PropsType {
  row: InsuranceRow
  isView?: boolean
}

export const InsuranceBasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  const handlePersonChange = (value: string[], selectedOptions: PersonCascader[]) => {
    return value
  }

  useEffect(() => {
    form.resetFields()
    const insuranceRow = { ...props.row }
    if (insuranceRow.id) {
      if (typeof insuranceRow.userCode === 'string') {
        insuranceRow.userCode = insuranceRow.userCode.split(",");
      }
      insuranceRow.time = dayjs(insuranceRow.time)
    }
    form.setFieldsValue(insuranceRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ status: 1, isVirtualUser: 0 }}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="员工"
            name="userCode"
            valuePropName={"person"}
            getValueFromEvent={handlePersonChange}
            rules={[{ required: !props.row.id, message: '请选择员工!' }]}>
            <CascaderPerson placeholder={"请选择员工"} disabled={!!props.row.id}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="缴纳月份"
            name="time"
            rules={[{ required: true, message: '请选择缴纳月份!' }]}>
            <DatePicker.MonthPicker className={"width-100-percentage"} disabledDate={(cur) => cur.isAfter(dayjs())} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="社保基数(个人)"
            name="socialSecurityBaseUser"
            rules={[{ required: true, message: '请输入社保基数(个人)!' }]}>
            <InputNumber placeholder="请输入社保基数(个人)" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="社保基数(企业)"
            name="socialSecurityBaseCompany"
            rules={[{ required: true, message: '请输入社保基数(企业)!' }]}>
            <InputNumber placeholder="请输入社保基数(企业)" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="公积金基数(个人)"
            name="providentFundBaseUser"
            rules={[{ required: true, message: '请输入公积金基数(个人)!' }]}>
            <InputNumber placeholder="请输入公积金基数(个人)" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="公积金基数(企业)"
            name="providentFundBaseCompany"
            rules={[{ required: true, message: '请输入公积金基数(企业)!' }]}>
            <InputNumber placeholder="请输入公积金基数(企业)" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="养老保险(个人)"
            name="endowmentInsuranceUser"
            rules={[{ required: true, message: '请输入养老保险(个人)!' }]}>
            <InputNumber placeholder="请输入养老保险(个人)" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="养老保险(企业)"
            name="endowmentInsuranceCompany"
            rules={[{ required: true, message: '请输入养老保险(企业)!' }]}>
            <InputNumber placeholder="请输入养老保险(企业)" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="基本医疗保险(个人)"
            name="basicMedicalInsuranceUser"
            rules={[{ required: true, message: '请输入基本医疗保险(个人)!' }]}>
            <InputNumber placeholder="请输入基本医疗保险(个人)" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="基本医疗保险(企业)"
            name="basicMedicalInsuranceCompany"
            rules={[{ required: true, message: '请输入基本医疗保险(企业)!' }]}>
            <InputNumber placeholder="请输入基本医疗保险(企业)" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="失业保险(个人)"
            name="unemploymentInsuranceUser"
            rules={[{ required: true, message: '请输入失业保险(个人)!' }]}>
            <InputNumber placeholder="请输入失业保险(个人)" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="基本医疗保险(企业)"
            name="unemploymentInsuranceCompany"
            rules={[{ required: true, message: '请输入失业保险(企业)!' }]}>
            <InputNumber placeholder="请输入失业保险(企业)" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="工伤保险(个人)"
            name="employmentInjuryInsuranceUser"
            rules={[{ required: true, message: '请输入工伤保险(个人)!' }]}>
            <InputNumber placeholder="请输入工伤保险(个人)" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="工伤保险(企业)"
            name="employmentInjuryInsuranceCompany"
            rules={[{ required: true, message: '请输入工伤保险(企业)!' }]}>
            <InputNumber placeholder="请输入工伤保险(企业)" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="大病互助(个人)"
            name="mutualAidForMajorIllnessesUser"
            rules={[{ required: true, message: '请输入大病互助(个人)!' }]}>
            <InputNumber placeholder="请输入大病互助(个人)" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="大病互助(企业)"
            name="mutualAidForMajorIllnessesCompany"
            rules={[{ required: true, message: '请输入大病互助(企业)!' }]}>
            <InputNumber placeholder="请输入大病互助(企业)" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="住房公积金(个人)"
            name="housingFundUser"
            rules={[{ required: true, message: '请输入住房公积金(个人)!' }]}>
            <InputNumber placeholder="请输入住房公积金(个人)" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="住房公积金(企业)"
            name="housingFundCompany"
            rules={[{ required: true, message: '请输入住房公积金(企业)!' }]}>
            <InputNumber placeholder="请输入住房公积金(企业)" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
