import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {Col, DatePicker, Row, Select, Space, Table} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {
  TransactionStatisticsReq,
  TransactionStatisticsRow,
  TransactionStatisticsSum
} from "@services/dto/transactionStatistics";
import {SMALL_TABLE_SCROLL_HEIGHT, TABLE_CELL_WIDTH_1x, TABLE_CELL_WIDTH_2x} from "@/config";
import dayjs from "dayjs";
import {TableMoneyText} from "@components/TableMoneyText";
import {SelectBankAccount} from "@components/SelectBankAccount";
import {SelectDict} from "@components/SelectDict";
import {Key} from "antd/es/table/interface";
import {accAdd, safeCompute} from "@common/utils";
import {TableEllipsisText} from "@components/TableEllipsisText";

export const TransactionStatistics = observer(() => {
  const {transactionStatisticsStore, headerSearchStore, screenResolutionStore} = allStores
  const [dataSource, setDataSource] = useState<TransactionStatisticsRow[]>([])
  const [scrollHeight, setScrollHeight] = useState<number>(SMALL_TABLE_SCROLL_HEIGHT)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [transactionStatisticsSum, setTransactionStatisticsSum] = useState<TransactionStatisticsSum>({
    paidAmountTotal: 0,
    incomeAmountTotal: 0,
    balanceAmountTotal: 0
  });
  const [searchReq, setSearchReq] = useState<TransactionStatisticsReq>({
    pageNum: 1,
    perPage: 10,
    paymentCategory: '往来款-员工还款/借款'
  })

  const protogenesisColumns: ColumnsType<TransactionStatisticsRow> = [
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      ),
    },
    {
      title: '资金账户',
      dataIndex: 'bankAccount',
      key: 'bankAccount',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      ),
    },
    {
      title: '往来款-员工借款金额',
      dataIndex: 'paidAmount',
      key: 'paidAmount',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      ),
    },
    {
      title: '往来款-员工还款金额',
      dataIndex: 'incomeAmount',
      key: 'incomeAmount',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      ),
    },
    {
      title: '余额',
      dataIndex: 'balanceAmount',
      key: 'balanceAmount',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      ),
    },
    {
      title: '对方账户',
      dataIndex: 'currentAccount',
      key: 'currentAccount',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      ),
    },
    /*{
      title: '责任人',
      dataIndex: 'personResponsible',
      key: 'personResponsible',
      align: 'center',
      width: DEFAULT_TABLE_CELL_WIDTH,
    },*/
  ]

  const [columns, setColumns] = useState<ColumnsType<TransactionStatisticsRow>>(protogenesisColumns)

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
    }
    refreshData(req)
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    let newColumns = [...protogenesisColumns]
    switch (searchReq.paymentCategory) {
    case "往来款-员工还款/借款":
      newColumns[2].title = '往来款-员工借款金额'
      newColumns[3].title = '往来款-员工还款金额'
      newColumns[4].title = '余额'
      break
    case "往来款-其他收入/支出":
      newColumns[2].title = '往来款-其他支出金额'
      newColumns[3].title = '往来款-其他收入金额'
      newColumns[4].title = '余额'
      break
    case "往来款-内部往来":
      newColumns[2].title = '往来款-内部往来-支出金额'
      newColumns[3].title = '往来款-内部往来-收入金额'
      newColumns[4].title = '余额'
      break
    case "往来款-应收账款":
      newColumns[2].title = '资金审批金额'
      newColumns[3].title = '资金实现金额'
      newColumns[4].title = '应收账款余额'
      break
    case "往来款-应付账款":
      newColumns[2].title = '资金审批金额'
      newColumns[3].title = '资金实现金额'
      newColumns[4].title = '应付账款余额'
      break
    case "应收账款/应付账款":
      newColumns = [
        {
          title: '资金申请编号',
          dataIndex: 'applicationNumber',
          key: 'applicationNumber',
          align: 'center',
          width: TABLE_CELL_WIDTH_1x,
          render: (text) => (
            <Space>
              <TableEllipsisText value={text}></TableEllipsisText>
            </Space>
          ),
        },
        {
          title: '资金申请类型',
          dataIndex: 'applicationType',
          key: 'applicationType',
          align: 'center',
          width: TABLE_CELL_WIDTH_1x,
          render: (text) => (
            <Space>
              <TableEllipsisText value={text}></TableEllipsisText>
            </Space>
          ),
        },
        {
          title: '摘要',
          dataIndex: 'digest',
          key: 'digest',
          align: 'center',
          width: TABLE_CELL_WIDTH_2x,
          render: (text) => (
            <Space>
              <TableEllipsisText value={text}></TableEllipsisText>
            </Space>
          ),
        },
        {
          title: '应收金额',
          dataIndex: 'incomeAmount',
          key: 'incomeAmount',
          align: 'center',
          width: TABLE_CELL_WIDTH_1x,
          render: (text) => (
            <Space>
              {
                text === 0 ? '-' : <TableMoneyText value={text}></TableMoneyText>
              }
            </Space>
          ),
        },
        {
          title: '应付金额',
          dataIndex: 'paidAmount',
          key: 'paidAmount',
          align: 'center',
          width: TABLE_CELL_WIDTH_1x,
          render: (text) => (
            <Space>
              {
                text === 0 ? '-' : <TableMoneyText value={text}></TableMoneyText>
              }
            </Space>
          ),
        },
        {
          title: '付款账户',
          dataIndex: 'bankAccount',
          key: 'bankAccount',
          align: 'center',
          width: TABLE_CELL_WIDTH_1x,
          render: (text) => (
            <Space>
              <TableEllipsisText value={text}></TableEllipsisText>
            </Space>
          ),
        },
        {
          title: '收款账户',
          dataIndex: 'currentAccount',
          key: 'currentAccount',
          align: 'center',
          width: TABLE_CELL_WIDTH_1x,
          render: (text) => (
            <Space>
              <TableEllipsisText value={text}></TableEllipsisText>
            </Space>
          ),
        },
        {
          title: '责任人',
          dataIndex: 'personResponsible',
          key: 'personResponsible',
          align: 'center',
          width: TABLE_CELL_WIDTH_1x,
          render: (text) => (
            <Space>
              <TableEllipsisText value={text}></TableEllipsisText>
            </Space>
          ),
        },
        {
          title: '责任部门',
          dataIndex: 'responsibilityProject',
          key: 'responsibilityProject',
          align: 'center',
          width: TABLE_CELL_WIDTH_1x,
          render: (text) => (
            <Space>
              <TableEllipsisText value={text}></TableEllipsisText>
            </Space>
          ),
        },
        {
          title: '协作单位',
          dataIndex: 'cooperationUnit',
          key: 'cooperationUnit',
          align: 'center',
          width: TABLE_CELL_WIDTH_1x,
          render: (text) => (
            <Space>
              <TableEllipsisText value={text}></TableEllipsisText>
            </Space>
          ),
        },
      ]
      break
    }
    setColumns(newColumns)
  }, [searchReq.paymentCategory])

  useEffect(() => {
    if (transactionStatisticsStore.transactionStatisticsDatasource.data) {
      setDataSource(transactionStatisticsStore.transactionStatisticsDatasource.data.items || []);
      setTotal(transactionStatisticsStore.transactionStatisticsDatasource.data.total || 0)
    }
  }, [transactionStatisticsStore.transactionStatisticsDatasource])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, searchReq.pageNum, searchReq.perPage])

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const refreshData = (req: TransactionStatisticsReq) => {
    transactionStatisticsStore.getTransactionStatistics(req)
  }

  const handleChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.paymentCategory = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.startDate = dateStrings[0]
    req.endDate = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleBankAccountChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.bankAccount = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCurrentAccountChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.currentAccount = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleBaType = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.baType = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleRowSelectionChange = (selectedRowKeys: Key[], selectedRows: TransactionStatisticsRow[]) => {
    setSelectedRowKeys(selectedRowKeys)
    let transactionStatisticsSum = {paidAmountTotal: 0, incomeAmountTotal: 0, balanceAmountTotal: 0}
    if (selectedRows.length > 0) {
      setTransactionStatisticsSum(selectedRows.reduce((acc, cur) => ({
        paidAmountTotal: accAdd(acc.paidAmountTotal, cur.paidAmount),
        incomeAmountTotal: accAdd(acc.incomeAmountTotal, cur.incomeAmount),
        balanceAmountTotal: accAdd(acc.balanceAmountTotal, cur.balanceAmount),
      }), transactionStatisticsSum))
    } else {
      setTransactionStatisticsSum(transactionStatisticsSum)
    }
  }

  return (
    <div className={"institution"}>
      <Row gutter={12} className={'header-action-row'}>
        <Col xl={4} md={6} xs={8}>
          <DatePicker.RangePicker
            onChange={handleDateChange}
            allowClear={false}
            placeholder={['请选择开始日期', '请选择结束日期']}
            className={"width-100-percentage"}/>
        </Col>
        <Col xl={4} md={6} xs={8}>
          <SelectBankAccount
            value={searchReq.bankAccount}
            allowClear
            isEnableFocusEvent
            searchReq={{
              pageNum: 1,
              perPage: 10,
              baType: '内部协作账户,员工公务账户',
            }}
            placeholder={"请选择资金账户"}
            onChange={handleBankAccountChange}/>
        </Col>
        <Col xl={4} md={6} xs={8}>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            value={searchReq.paymentCategory}
            onChange={handleChange}
            placeholder={'请选择款项类别'}
            className={'width-100-percentage'}
            options={[
              {value: '往来款-员工还款/借款', label: '往来款-员工还款/借款'},
              {value: '往来款-其他收入/支出', label: '往来款-其他收入/支出'},
              {value: '往来款-内部往来', label: '往来款-内部往来'},
              {value: '往来款-应收账款', label: '往来款-应收账款'},
              {value: '往来款-应付账款', label: '往来款-应付账款'},
              {value: '应收账款/应付账款', label: '应收账款/应付账款'}
            ]}/>
        </Col>
        <Col xl={4} md={6} xs={8}>
          <SelectBankAccount
            className={'width-100-percentage'}
            placeholder={'请选择往来账户'}
            isEnableFocusEvent
            allowClear
            onChange={handleCurrentAccountChange}/>
        </Col>
        <Col xl={4} md={6} xs={8}>
          <SelectDict
            className={'width-100-percentage'}
            code={'amount_type'}
            allowClear
            onChange={handleBaType}
            placeholder={'请选择账户类型'}/>
        </Col>
      </Row>

      <Table
        rowKey={(record) => {
          return record.id + record.incomeAmount + record.paidAmount + record.bankAccount + record.currentAccount + record.responsibilityProject
        }}
        className={"margin-top-xss"}
        dataSource={dataSource}
        columns={columns}
        size={"small"}
        bordered
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        loading={transactionStatisticsStore.transactionStatisticsDatasource.loading}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2} className='text-align-center'>
                已选：{ selectedRowKeys.length }
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} colSpan={1}/>
              {
                searchReq.paymentCategory === '应收账款/应付账款' ?
                  <>
                    <Table.Summary.Cell index={3} colSpan={2} className='text-align-right'>
                      合计：<TableMoneyText value={transactionStatisticsSum.incomeAmountTotal}></TableMoneyText>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1} className='text-align-right'>
                      合计：<TableMoneyText value={transactionStatisticsSum.paidAmountTotal}></TableMoneyText>
                    </Table.Summary.Cell>
                  </> :
                  <>
                    <Table.Summary.Cell index={3} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={transactionStatisticsSum.paidAmountTotal}></TableMoneyText>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={transactionStatisticsSum.incomeAmountTotal}></TableMoneyText>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={transactionStatisticsSum.balanceAmountTotal}></TableMoneyText>
                    </Table.Summary.Cell>
                  </>
              }
              <Table.Summary.Cell index={6} colSpan={1}/>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        rowSelection={{selectedRowKeys, onChange: handleRowSelectionChange}}/>
    </div>
  )
})
