import React, { useEffect } from "react";
import {Col, DatePicker, Form, Input, Row} from "antd";
import { FormInstance } from "antd/es";
import { observer } from "mobx-react";
import dayjs from "dayjs";
import {MaterialsOutBoundRow} from "@services/dto/materialsOutBound";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {SelectFiling} from "@components/SelectFiling";

export interface PropsType {
  row: MaterialsOutBoundRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    const outInBoundRow = { ...props.row }
    if (outInBoundRow.id) {
      if (typeof outInBoundRow.mdDate === 'string') {
        outInBoundRow.mdDate = dayjs(outInBoundRow.mdDate)
      }if (typeof outInBoundRow.mdOutDate === 'string') {
        outInBoundRow.mdOutDate = dayjs(outInBoundRow.mdOutDate)
      }
    }
    form.setFieldsValue(outInBoundRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ status: 1, isVirtualUser: 0 }}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="mdDate"
            rules={[{ required: false, message: '请输入制单日期!' }]}>
            <DatePicker placeholder={"请输入制单日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="mdCode"
            rules={[{ required: false, message: '请输入制单编号!' }]}>
            <Input placeholder="请输入制单编号" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="类型"
            name="mdType"
            rules={[{ required: false, message: '请输入类型!' }]}>
            <Input placeholder="请输入类型" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="仓库"
            name="mdStorehouse"
            rules={[{ required: false, message: '请输入仓库!' }]}>
            <Input placeholder="请输入仓库" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联物资清单"
            name="materialListId"
            rules={[{ required: false, message: '请输入关联物资清单!' }]}>
            <Input placeholder="请输入关联物资清单" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="出库日期"
            name="mdOutDate"
            rules={[{ required: false, message: '请输入出库日期!' }]}>
            <DatePicker placeholder={"请输入出库日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="mdStatus"
            rules={[{ required: false, message: '请输入办理进度!' }]}>
            <Input placeholder="请输入办理进度" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任人"
            name="personResponsible"
            rules={[{ required: true, message: '请选择责任人!' }]}>
            <SelectPersonCharge className={"width-100-percentage"} placeholder={"请选择负责人"} allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{ required: true, message: '请选择责任部门!' }]}>
            <SelectProject placeholder={"请选择责任部门"} allowClear fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{ required: true, message: '请输入协作单位!' }]}>
            <SelectCooperativeUnit allowClear fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="存档批号"
            name="mdFile"
            rules={[{ required: false, message: '请选择存档批号!' }]}>
            <SelectFiling placeholder={"请选择存档批号"} allowClear />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
