import './index.less'
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {Divider, Pagination, Select, Space} from "antd";
import React, {useEffect, useState} from "react";
import {Any} from "@common/types";
import {CostReimbursementReq, CostReimbursementRow} from "@services/dto/costReimbursement";
import {StatisticsItemsReq, StatisticsItemsRow} from "@services/dto/dict";
import {DictService} from "@services/DictService";
import {showErr} from "@common/utils";
import {BLACK_LIST} from "@/config";

export interface PropsType {
  onChange?: (value: Any | null, options: CostReimbursementRow[]) => void
  value?: Any | null
  mode?: 'multiple' | 'tags'
  labelInValue?: boolean
  disabled?: boolean
  className?: string
  allowClear?: boolean
  placeholder?: string
  fieldNames?: { value: string, label: string }
  statisticsItemsReq?: StatisticsItemsReq
}

export const SelectCostReimbursement = observer((props: PropsType) => {
  const [value, setValue] = useState<Any | null>()
  const {costReimbursementStore} = allStores
  const [dataSource, setDataSource] = useState<CostReimbursementRow[]>([])
  const [total, setTotal] = useState<number>(0)
  const [items, setItems] = useState<CostReimbursementRow[]>([])
  const [statisticsItems, setStatisticsItems] = useState<StatisticsItemsRow[] | CostReimbursementRow[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [searchReq, setSearchReq] = useState<CostReimbursementReq>({
    pageNum: 1,
    perPage: 10,
  })

  /**
   *  每个组件默认写法 只需修改 'erApprovalCode'
   */
  const defaultItems: any[] = [
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: 'N/A',
      [props.fieldNames ? props.fieldNames.label : 'erApprovalCode']: 'N/A'
    },
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: '空白',
      [props.fieldNames ? props.fieldNames.label : 'erApprovalCode']: '空白'
    },
  ]

  /**
   * 初始化
   */
  useEffect(() => {
    if (props.statisticsItemsReq) {
      setLoading(true)
      DictService.getStatisticsItems(props.statisticsItemsReq).then(rsp => {
        const row = JSON.parse(JSON.stringify(rsp.data || [])).map((item: StatisticsItemsRow | CostReimbursementRow) => {
          (item as CostReimbursementRow).erApprovalCode = `${(item as CostReimbursementRow).erApprovalCode || (item as StatisticsItemsRow).value}(${(item as StatisticsItemsRow).coun})`
          return item
        })
        setStatisticsItems(row);
      }).catch(showErr).finally(() => {
        setLoading(false)
      })
    } else {
      refreshData(searchReq)
    }
  }, [])

  /**
   * 办理进度数据变化
   */
  useEffect(() => {
    if (costReimbursementStore.costReimbursementSelectDatasource.data) {
      setDataSource(costReimbursementStore.costReimbursementSelectDatasource.data.items || [])
      setTotal(costReimbursementStore.costReimbursementSelectDatasource.data.total || 0)
      if (costReimbursementStore.costReimbursementDetails.data?.data.length) {
        setItems(costReimbursementStore.costReimbursementDetails.data?.data
          .filter(item => !(costReimbursementStore.costReimbursementSelectDatasource.data.items || [])
            .some(o => o.id === item.id)))
      }
    }
  }, [costReimbursementStore.costReimbursementDetails, costReimbursementStore.costReimbursementSelectDatasource.data])

  /**
   * 值发生改变
   */
  useEffect(() => {
    setValue(props.value)
    if (typeof props.value === 'string' && !BLACK_LIST.includes(props.value)) {
      costReimbursementStore.getBatch([props.value])
    } else if (typeof props.value === 'object') {
      const ids = props.value.filter((v: string) => !BLACK_LIST.includes(v))
      if (ids.length) {
        costReimbursementStore.getBatch(ids)
      }
    }
  }, [props.value])

  const refreshData = (req: CostReimbursementReq) => {
    costReimbursementStore.getSelectCostReimbursement(req)
  }

  const onChange = (value: Any | null) => {
    setValue(value)
    if (props.onChange) {
      props.onChange(value, [
        ...dataSource,
        ...items,
      ]);
    }
  }

  const handleSearch = (inputValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = inputValue
    setSearchReq(req)
    refreshData(req)
    return true
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    const req = {...searchReq}
    req.pageNum = page
    req.perPage = pageSize
    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      {
        props.statisticsItemsReq ? (
          <Select
            allowClear={props.allowClear}
            placeholder={props.placeholder || "请选择费用报销"}
            className={`${props.className} width-100-percentage`}
            value={value}
            onChange={onChange}
            showSearch
            optionFilterProp="children"
            filterOption={(input: string, option) =>
              String(option?.erApprovalCode).toLowerCase().includes(input.toLowerCase())}
            disabled={props.disabled}
            mode={props.mode}
            loading={loading}
            labelInValue={props.labelInValue}
            fieldNames={{value: "id", label: "erApprovalCode"}}
            options={statisticsItems as CostReimbursementRow[]}/>
        ) : (
          <Select
            allowClear={props.allowClear}
            placeholder={props.placeholder || "请选择费用报销"}
            className={`${props.className} width-100-percentage`}
            dropdownRender={(menu) => (
              <>
                { menu }
                <Divider className={"divider-margin"}/>
                <Space>
                  <Pagination
                    total={total}
                    pageSize={searchReq.perPage}
                    size={"small"}
                    onChange={handlePaginationChange}/>
                </Space>
              </>
            )}
            value={value}
            onChange={onChange}
            showSearch
            onClear={() => handleSearch('')}
            filterOption={false}
            onSearch={handleSearch}
            disabled={props.disabled}
            mode={props.mode}
            loading={costReimbursementStore.costReimbursementDatasource.loading}
            labelInValue={props.labelInValue}
            fieldNames={{value: "id", label: "erApprovalCode"}}
            options={[
              ...defaultItems,
              ...dataSource,
              ...items,
            ]}/>
        )
      }
    </>
  )
})
