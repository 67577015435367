import {observer} from "mobx-react";
import {Hire} from "@modules/HR/Components/Hire";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import {allStores} from "@/stores";
import {Button, Card, Spin} from "antd";
import {LeftOutlined} from "@ant-design/icons";

export const OnInductionDetail = observer(() => {
  const params = useParams()
  const navigate = useNavigate();
  const {wrStore} = allStores;

  useEffect(() => {
    if (params.onBoardingUserId) {
      wrStore.refreshWorkerRecruitment(params.onBoardingUserId!)
    }
  }, [])

  const backPage = () => {
    navigate(-1)
  }

  return (
    <>
      <Spin spinning={wrStore.wr.loading}>
        <Card
          title={wrStore.wr.data && <Button>{ wrStore.wr.data.user.name }--入职信息</Button>}
          extra={<Button icon={<LeftOutlined />} type={"primary"} onClick={backPage} className={"margin-right-xs"}>返回上一页</Button>}>
          {
            wrStore.wr.data && <Hire/>
          }
        </Card>
      </Spin>
    </>
  )
})