import {observer} from "mobx-react";
import {Col, Form, FormInstance, InputNumber, Row, Select} from "antd";
import React, {useEffect} from "react";
import {allStores} from "@/stores";
import {Salary} from "@services/dto/workerRecruitment";

export interface PropsType {
    row: Salary
}

export const SalaryInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const {salaryTypeStore} = allStores;
  const [form] = Form.useForm()

  useEffect(() => {
    salaryTypeStore.getSalaryTypes()
  }, [])

  useEffect(() => {
    form.resetFields()
    const salary = {...props.row}
    if (salary.typeName) {
      salary.salaryType = {
        value: salary.typeName,
        label: salary.name
      }  
    }
    form.setFieldsValue(salary);
  }, [props.row])
    
  return <>
    <Form
      name="basic"
      form={form}
      ref={ref}
      labelCol={{span: 4}}
      wrapperCol={{span: 20}}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Form.Item
            label="工资类型"
            name="salaryType"
            rules={[{required: true, message: '请选择工资类型!'}]}>
            <Select placeholder="请选择工资类型" labelInValue>
              {
                salaryTypeStore.salaryTypes.items.map(item => (
                  <Select.Option key={item._id} value={item.type}>{ item.name }</Select.Option>)
                )
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="金额"
            name="amount"
            rules={[{required: true, message: '请输入金额!'}]}>
            <InputNumber placeholder={'请输入金额'} className={"width-100-percentage"} min={0} precision={1}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  </>
}))