import "./MonthDatePicker.less"
import {Button, DatePicker} from "antd";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import React, {useState} from "react";
import {handleDateAddSubtract} from "@common/utils";

export interface PropsType {
  handleDateChange: (date: dayjs.Dayjs, dateString: string) => void
}

export const MonthDatePicker = (props: PropsType) => {
  const [dateValue, setDateValue] = useState<dayjs.Dayjs>(dayjs())

  const handleAddSubtract = (action: string) => {
    let now_calc = handleDateAddSubtract(action, dateValue);
    setDateValue(now_calc);
    props.handleDateChange(now_calc, now_calc.format("YYYY年MM月"))
  }

  const handleDateChange = (date: dayjs.Dayjs | null, dateString: string) => {
    if (date) {
      setDateValue(date);
      props.handleDateChange(date, dateString)
    }
  }

  return (
    <div className={"date-center"}>
      <Button icon={<LeftOutlined/>} onClick={() => handleAddSubtract('subtract')}></Button>
      <DatePicker
        bordered={false}
        allowClear={false}
        value={dateValue}
        disabledDate={(cur) => cur.isAfter(dayjs())}
        onChange={(date, dateString: string) => handleDateChange(date, dateString)}
        className={"date-style"}
        format={"YYYY年MM月"}
        picker={"month"}/>
      <Button disabled={dateValue.month() === dayjs().month() && dateValue.year() === dayjs().year()} icon={<RightOutlined/>} onClick={() => handleAddSubtract('add')}></Button>
    </div>
  )
}
