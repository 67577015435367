import {observer} from "mobx-react";
import {Button, Modal} from "antd";
import {LARGER_MODAL_WIDTH} from "@/config";
import React, {useRef} from "react";
import ReactToPrint from 'react-to-print';

export interface PropsType {
  isModalOpen: boolean
  handleCancel?: () => void
  parameter?: parameterType
  windowPrintTableNode?: React.ReactNode
}

export type parameterType = {
  width: number
  height: number
  horizontal: number
  vertical: number
}

export const WindowPrint = observer((props: PropsType) => {

  const componentRef = useRef(null);

  // 打印参数
  const parameter: parameterType = props.parameter || {
    width: 21,
    height: 29.7,
    horizontal: 1.91,
    vertical: 2.54
  }

  // 拟态框事件
  const handleCancel = () => {
    if (props.handleCancel) {
      props.handleCancel()
    }
  }

  return <Modal
    title={'收支统计表格'}
    open={props.isModalOpen}
    width={LARGER_MODAL_WIDTH}
    footer={[
      <Button
        key="back"
        onClick={handleCancel}>
        关闭
      </Button>,
      <ReactToPrint
        key={'print'}
        trigger={() => {
          // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
          // to the root node of the returned component as it will be overwritten.
          return <Button type={"primary"}>打印</Button>;
        }}
        content={() => componentRef.current}>
      </ReactToPrint>,
    ]}
    onCancel={handleCancel}>

    { /*打印盒子*/ }
    <div
      className={'print-box'}
      ref={el => componentRef.current = el}>
      { props.windowPrintTableNode }
    </div>

    <style lang='less'>
      { `
        @media print {
          @page {
            size: landscape A4;
            margin: ${parameter.horizontal}cm ${parameter.vertical}cm; /* 设置边距 */
          }
        }
      ` }
    </style>
  </Modal>
})
