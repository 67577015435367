import React, {useState} from "react";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {HeaderAction} from "@components/HeaderAction";
import {ColumnsType} from "antd/es/table";
import {FundApprovalReq} from "@services/dto/fundApproval";

export interface PropsType {
  collapsed?: boolean, // 是否展开
  handleShowColumnReset?: () => void, // 重置列操作
  setCollapsed?: (v: boolean) => void, // 展开事件
  searchReq?: FundApprovalReq, // 请求参数
  selectedRowKeys?: React.Key[], // 所选行
  handleDeleteBatch?: () => void,
  handleAdd?: () => void,
  handleTableColumnsChange?: (value: string[]) => void,
  showColumn?: string[],
  tableColumns?: ColumnsType,
  refreshData?: (req: FundApprovalReq) => void,
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<FundApprovalReq>({
    pageNum: 1,
    perPage: 10,
  })

  const refreshData = (req: FundApprovalReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined


    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <HeaderAction
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={handleSearchReqReset}
        selectedRowKeys={props.selectedRowKeys}
        handleDeleteBatch={props.handleDeleteBatch}
        handleAdd={props.handleAdd}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        collapsedChildren={[]}/>
    </>
  )
}))
