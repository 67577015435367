import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {SealApprovalReq, SealApprovalRow} from "@services/dto/sealApproval";
import {SealApprovalService} from "@services/SealApprovalService";

export class SealApprovalStore {
  sealApprovalDatasource: StateFullData<Page<SealApprovalRow>> = {}
  sealApprovalSelectDatasource: StateFullData<Page<SealApprovalRow>> = {}
  sealApprovalRow?: SealApprovalRow
  sealApprovalOption?: string
  sealApprovalDetail: StateFullData<ResponseEntity<SealApprovalRow>> = {}
  sealApprovalDetails: StateFullData<ResponsesEntity<SealApprovalRow>> = {}
  sealApprovalSearchReq: SealApprovalReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateSealApprovalRow(row: SealApprovalRow) {
    this.sealApprovalRow = {...row}
  }

  updateSealApprovalOption(option: string) {
    this.sealApprovalOption = option
  }

  updateSealApprovalSearchReq(req: SealApprovalReq) {
    this.sealApprovalSearchReq = {...this.sealApprovalSearchReq, ...req}
  }

  getSealApproval(req: SealApprovalReq) {
    this.sealApprovalDatasource = {...this.sealApprovalDatasource, loading: true}
    this.sealApprovalSearchReq = {...this.sealApprovalSearchReq, ...req}
    SealApprovalService.getSealApproval(req).then(rsp => {
      runInAction(() => this.sealApprovalDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.sealApprovalDatasource = {...this.sealApprovalDatasource, loading: false, err})
    })
  }

  getSelectSealApproval(req: SealApprovalReq) {
    this.sealApprovalSelectDatasource = {...this.sealApprovalSelectDatasource, loading: true}
    SealApprovalService.getSealApproval(req).then(rsp => {
      runInAction(() => this.sealApprovalSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.sealApprovalSelectDatasource = {...this.sealApprovalSelectDatasource, loading: false, err})
    })
  }

  getOne(id: string) {
    this.sealApprovalDetail = {...this.sealApprovalDetail, loading: true}
    SealApprovalService.getOne(id).then(rsp => {
      runInAction(() => this.sealApprovalDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.sealApprovalDetail = {...this.sealApprovalDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.sealApprovalDetails = {...this.sealApprovalDetails, loading: true}
    SealApprovalService.getBatch(ids).then(rsp => {
      runInAction(() => this.sealApprovalDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.sealApprovalDetails = {...this.sealApprovalDetails, loading: false, err})
    })
  }

  async getPrintList(record: SealApprovalRow): Promise<LabelValue[]> {

    const row = this.sealApprovalDatasource.data?.items.find(o => o.id === record.id)

    return [
      {label: '制单日期', value: row.saDate as string},
      {label: '制单编号', value: row.saCode},
      {label: '印章名称', value: row.saName},
      {label: '启用日期', value: row.saStartDate as string},
      {label: '停用日期', value: row.saStopDate as string},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const sealApprovalStore = new SealApprovalStore();
