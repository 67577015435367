import React, {FC, useEffect, useLayoutEffect, useState} from "react";
import {Button, Drawer, message, Modal, Space, Table} from 'antd'
import {ColumnsType} from "antd/es/table";
import {FormInstance} from "antd/es";
import {RoleManageReq, RoleManageRow} from "@services/dto/roleManage";
import {BasicInfo} from './Components/BasicInfo'
import {allStores} from "@/stores";
import {observer} from "mobx-react";
import {logger, parseURLParams, safeCompute, showErr} from "@common/utils";
import {
  DEFAULT_MODAL_WIDTH,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  EDIT_TEXT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x
} from "@/config";
import {RoleManageService} from "@services/RoleManageService";
import "./index.less"
import {TableOptions} from "src/components/TableOptions";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {useLocation} from "react-router-dom";
import {HeaderActionCom} from "@modules/RoleManage/Components/HeaderActionCom";

const blackColumnList = [
  'action',
]

const wideShowColumn: string[] = [
  'name',
  'remark',
  'action',
]

const narrowShowColumn: string[] = [
  'name',
  'remark',
  'action',
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: RoleManageRow[] // 数据源
  setFundApprovalSelectedRowKeys?: (key: React.Key[]) => void // 所选行
}

export const RoleManage: FC = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const location = useLocation()
  const {
    roleManageStore,
    tableColumnsStore,
    screenResolutionStore
  } = allStores;
  const basicInfo = React.createRef<FormInstance>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isView, setIsView] = useState<boolean>(false)
  const [roleManageObj, setRoleManageObj] = useState<RoleManageRow>()
  const [dataSource, setDataSource] = useState<RoleManageRow[]>([]);
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<RoleManageRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [total, setTotal] = useState<number>(0)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [roleManageReq, setRoleManageReq] = useState<RoleManageReq>({
    perPage: 10,
    pageNum: 1,
    ...roleManageStore.roleManageReq
  })

  const columns: ColumnsType<RoleManageRow> = [
    {
      title: '角色名',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableOptions
          handleEdit={() => {
            handleEdit(record)
          }}
          handleView={() => {
            handleView(record)
          }}
          handleDelete={() => {
            handleDeleteBatch(record)
          }}
          onClick={() => handleEdit(record)}>
          { EDIT_TEXT }
        </TableOptions>
      )
    }
  ];

  /**
   * @description 初始化
   */
  useEffect(() => {

    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(roleManageReq)
    }
  }, [])

  useEffect(() => {
    if (roleManageStore.roleManageDatasource.data) {
      setDataSource(roleManageStore.roleManageDatasource.data.items || [])
      setTotal(roleManageStore.roleManageDatasource.data.total || 0)
    }
  }, [roleManageStore.roleManageDatasource.data])

  /**
   * @description 表单数据变化
   */
  useEffect(() => {

  }, [roleManageStore.roleManageRow])

  useEffect(() => {
    if (roleManageStore.roleManageOption) {
      const [option, paramsStr] = roleManageStore.roleManageOption.split("?")
      if (option === 'add') {
        handleAdd()
      } else if (paramsStr) {
        const params = parseURLParams(paramsStr)
        if (option === 'edit') {
          RoleManageService.getOne(params.id).then(rsp => {
            if (rsp) {
              handleEdit(rsp)
            }
          })
        }
      }
      roleManageStore.updateFundApprovalOption(undefined)
    }
  }, [roleManageStore.roleManageOption])

  /**
   * 列配置变化
   */
  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => !blackColumnList.includes(o.key as string)).filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, roleManageStore.collapsed, roleManageReq.pageNum, roleManageReq.perPage])

  const refreshData = (roleManageRequest: RoleManageReq) => {
    setRoleManageReq(roleManageRequest)
    roleManageStore.getRoleManage(roleManageRequest)
  }

  const handleDeleteBatch = (record?: RoleManageRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        RoleManageService.deleteBatchRoleManageByIds(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(roleManageReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handleEdit = (record: RoleManageRow) => {
    setRoleManageObj(record)
    setIsModalVisible(true);
    setIsView(false)
  }

  const handleAdd = () => {
    setRoleManageObj(undefined)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleView = (record: RoleManageRow) => {
    setRoleManageObj(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleOk = () => {
    const roleManageRow = {...roleManageObj!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(roleManageRow, values)

      setConfirmLoading(true)
      if (roleManageRow.id) {
        RoleManageService.updateRoleManageById(roleManageRow.id, roleManageRow).then(() => {
          setUpdateCount(updateCount + 1)
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        RoleManageService.addRoleManage(roleManageRow).then(() => {
          setUpdateCount(updateCount + 1)
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setRoleManageObj(undefined)
  };

  const actionThen = () => {
    message.success("操作成功！").then()
    setIsModalVisible(false);
    setRoleManageObj(undefined)
    refreshData(roleManageReq)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let roleManageRequest = {...roleManageReq};
    roleManageRequest.perPage = pageSize
    roleManageRequest.pageNum = page
    setRoleManageReq(roleManageRequest)
    refreshData(roleManageRequest)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    roleManageStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  return (
    <div className="roleManage">

      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        isHiddenOptions={props.isHiddenOptions}
        updateCount={updateCount}
        collapsed={roleManageStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={roleManageReq}
        selectedRowKeys={selectedRowKeys}
        handleShowColumnReset={handleShowColumnReset}
        handleDeleteBatch={handleDeleteBatch}
        handleAdd={handleAdd}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={props.isModalSelect ? columns.filter(o => !blackColumnList.includes(o.key as string)) : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...roleManageReq, ...req})}></HeaderActionCom>

      <Table
        className={"margin-top-xs"}
        bordered
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        size={"middle"}
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        loading={props.dataSource ? false : roleManageStore.roleManageDatasource.loading || confirmLoading}
        pagination={!props.dataSource && {
          total: total,
          position: ['bottomCenter'],
          pageSize: roleManageReq.perPage,
          current: roleManageReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`, onChange: handlePageChange
        }}
        rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys, columnWidth: 50}}/>

      <Drawer
        title={<div className="text-align-center">角色管理</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        destroyOnClose
        onClose={handleCancel}>
        <BasicInfo row={{...roleManageObj!}} isView={isView} ref={basicInfo}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button
              type="primary"
              onClick={handleOk}
              loading={confirmLoading}
              className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>
    </div>
  )
}))
