import {makeAutoObservable, runInAction} from "mobx";
import {Page, StateFullData} from "@services/dto/common";
import {CommercialContractReq, CommercialContractRow} from "@services/dto/commercialContract";
import {CommercialContractService} from "@services/CommercialContractService";

export class CommercialContractStore {
  commercialContractDatasource: StateFullData<Page<CommercialContractRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getCommercialContract(req: CommercialContractReq) {
    this.commercialContractDatasource = {...this.commercialContractDatasource, loading: true}
    CommercialContractService.getCommercialContract(req).then(rsp => {
      runInAction(() => this.commercialContractDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.commercialContractDatasource = {...this.commercialContractDatasource, loading: false, err})
    })
  }
}

export const commercialContractStore = new CommercialContractStore();
