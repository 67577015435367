import {observer} from "mobx-react";
import {Select} from "antd";
import React, {useEffect} from "react";
import {allStores} from "@/stores";
import {Any} from "@common/types";
import {WorkerType} from "@services/dto/workerType";

export interface PropsType {
    placeholder: string
    className?: string
    fieldNames?: {value: string, label: string}
    value?: Any
    mode?: 'multiple' | 'tags'
    allowClear?: boolean
    onChange?: (value: Any, options: WorkerType | WorkerType[]) => void
}

export const SelectWorkerType = observer((props: PropsType) => {
  const {workerTypeStore} = allStores;

  useEffect(() => {
    workerTypeStore.getWorkerTypes()
  }, [])
    
  return (
    <Select
      allowClear={props.allowClear}
      placeholder={props.placeholder}
      value={props.value}
      mode={props.mode}
      options={workerTypeStore.workerTypes.items}
      fieldNames={props.fieldNames || {value: '_id', label: 'name'}}
      filterOption={(inputValue, option) => String(option?.name).includes(inputValue)}
      onChange={props.onChange}
      className={props.className}/>
  )
})