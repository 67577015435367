import React, {useEffect, useState} from "react";
import {Button, Col, DatePicker, Divider, Form, Input, InputNumber, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import dayjs from "dayjs";
import {ContractListRow} from "@services/dto/contractList";
import {SelectFiling} from "@components/SelectFiling";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {allStores} from "@/stores";
import {ProjectRow} from "@services/dto/project";
import {SelectContractList} from "@components/SelectContractList";
import {Any} from "@common/types";
import {PlusOutlined} from "@ant-design/icons";
import {ContractSettlementRow} from "@services/dto/contractSettlement";
import {BankStatementRow} from "@services/dto/bankStatement";
import {FundApprovalRow} from "@services/dto/fundApproval";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {FORM_DISABLED_STATE} from "@/config";
import {SelectDict} from "@components/SelectDict";
import {SelectProjectStructure} from "@components/SelectProjectStructure";

export interface PropsType {
  row: ContractListRow
  isView?: boolean
  isBatchUpdate?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores
  const [personResponsible, setPersonResponsible] = useState<string>()
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()

  useEffect(() => {
    form.resetFields()
    const contractListRow = {...props.row}
    filingStore.setFilingFaCode(contractListRow.clCode)
    if (typeof contractListRow.clDate === 'string' && contractListRow.clDate !== '') {
      contractListRow.clDate = dayjs(contractListRow.clDate)
    }
    if (typeof contractListRow.clEndDate === 'string' && contractListRow.clEndDate !== '') {
      contractListRow.clEndDate = dayjs(contractListRow.clEndDate)
    }
    if (typeof contractListRow.cooperationUnit === 'string') {
      contractListRow.cooperationUnit = contractListRow.cooperationUnit !== '' ? contractListRow.cooperationUnit.split(',') : []
    }
    if (typeof contractListRow.clDocDate === 'string' && contractListRow.clDocDate !== '') {
      contractListRow.clDocDate = dayjs(contractListRow.clDocDate)
    }
    if (contractListRow.contractSettlements) {
      contractListRow.contractSettlements = (contractListRow.contractSettlements as ContractSettlementRow[])?.map(o => o.id)
    }
    if (contractListRow.fundApprovals) {
      contractListRow.fundApprovals = (contractListRow.fundApprovals as FundApprovalRow[])?.map(o => o.id)
    }
    if (contractListRow.bankStatements) {
      contractListRow.bankStatements = (contractListRow.bankStatements as BankStatementRow[])?.map(o => o.id)
    }
    setPersonResponsible(contractListRow.personResponsible)
    setResponsibilityProject(contractListRow.responsibilityProject)
    setCooperationUnit(contractListRow.cooperationUnit)
    form.setFieldsValue(contractListRow);
  }, [props.row.id])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    setResponsibilityProject(value)
    const row = options.find(o => value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  /**
   * 改变上级合同自动填充上级合同名称
   * @param value
   * @param options
   */
  const handleParentIdChange = (value: Any | null, options: ContractListRow[]) => {
    const row = options.find(o => value === o.clName)
    form.setFieldsValue({parentName: row?.parentName})
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      initialValues={{
        cooperationUnit: ['N/A'],
        contractParties: []
      }}
      disabled={props.isView || FORM_DISABLED_STATE.includes(props.row.clStatus)}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="parentName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="organizersUserName">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="clDocDate"
            rules={[{required: false, message: '请输入制单日期!'}]}>
            <DatePicker
              disabled
              placeholder={"请输入制单日期"}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="clCode"
            rules={[{required: false, message: '请输入制单编号!'}]}>
            <Input disabled placeholder="请输入制单编号"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同名称"
            name="clName"
            rules={[{required: false, message: '请输入合同名称!'}]}>
            <Input placeholder="请输入合同名称"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同类别"
            name="clType"
            rules={[{required: false, message: '请选择合同类别!'}]}>
            <SelectDict code={'cl_type'} placeholder={'请选择合同类别'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同摘要"
            name="clDesc"
            rules={[{required: false, message: '请输入合同摘要!'}]}>
            <Input.TextArea placeholder="请输入合同摘要" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联上级合同"
            name="parentId"
            rules={[{required: false, message: '请选择关联上级合同!'}]}>
            <SelectContractList
              onChange={handleParentIdChange}
              className={'width-100-percentage'}
              placeholder={"请选择关联上级合同"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联项目审批"
            name="clProject"
            rules={[{required: false, message: '请选择关联项目审批!'}]}>
            <SelectProjectStructure placeholder={"请选择关联项目审批"} allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.List name={"contractParties"}>
            { (fields, {add, remove}) => (
              <>
                <Divider style={{margin: '0 0 10px 0'}}/>
                { fields.map((field, index) => (
                  <Row key={field.key}>
                    <Col span={24}>
                      <Form.Item
                        {...field}
                        hidden
                        name="id">
                        <Input/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...field}
                        hidden
                        name="contractListId">
                        <Input/>
                      </Form.Item>
                    </Col>
                    <Col span={24} className={"margin-bottom-xs"}>
                      <Row>
                        <Col span={9}>
                          <Form.Item
                            {...field}
                            labelCol={{span: 16}}
                            wrapperCol={{span: 8}}
                            label="合同当事人"
                            name={[field.name, 'contractPrivity']}
                            rules={[{required: !props.isBatchUpdate, message: '请输入合同方!'}]}>
                            <Input placeholder={"请输入合同方"}/>
                          </Form.Item>
                        </Col>
                        <Col span={1} className={'d-flex df-jcc df-aic'}>:</Col>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            labelCol={{span: 0}}
                            wrapperCol={{span: 24}}
                            label="合同当事人"
                            style={{boxSizing:'border-box'}}
                            name={[field.name, 'contractParties']}
                            rules={[{required: !props.isBatchUpdate, message: '请选择合同当事人!'}]}>
                            <SelectCooperativeUnit
                              allowClear
                              isShowAction
                              isSimpleAction
                              handleButtonClick={() => remove(index)}
                              personResponsible={personResponsible}
                              onChange={(value) => setPersonResponsible(value)}
                              responsibilityProject={responsibilityProject}
                              modalTitle={"合同当事人"}
                              placeholder={"请选择合同当事人"}
                              fieldNames={{value: 'cuName', label: 'cuName'}}/>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )) }
                <Col span={18} offset={3} className={"margin-bottom-xs"}>
                  <Form.Item wrapperCol={{span: 20, offset: 3}} className={"text-align-center"}>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                      新增合同当事人
                    </Button>
                  </Form.Item>
                </Col>
                <Divider className={'margin-none'}/>
              </>
            ) }
          </Form.List>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同约定金额"
            name="clAmount"
            rules={[{required: false, message: '请输入合同约定金额!'}]}>
            <InputNumber
              min={0}
              precision={2}
              placeholder="请输入合同约定金额"
              addonAfter={'元'}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        { /*<Col span={24}>
          <Form.Item
            label="合同结算金额"
            name="clEndAmount"
            rules={[{ required: false, message: '请输入合同结算金额!' }]}>
            <InputNumber min={0} precision={2} placeholder="请输入金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同实现金额"
            name="clEndAmountnow"
            rules={[{ required: false, message: '请输入合同实现金额!' }]}>
            <InputNumber min={0} precision={2} placeholder="请输入金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>*/ }
        <Col span={24}>
          <Form.Item
            label="合同签订日期"
            name="clDate"
            rules={[{required: false, message: '请输入合同签订日期!'}]}>
            <DatePicker
              allowClear
              placeholder={"请选择合同签订日期"}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同关闭日期"
            name="clEndDate"
            rules={[{required: false, message: '请输入合同关闭日期!'}]}>
            <DatePicker
              allowClear
              placeholder={"请选择合同关闭日期"}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: !props.isBatchUpdate, message: '请选择责任部门!'}]}>
            <SelectProject
              placeholder={"请选择责任部门"}
              onChange={handleProjectChange}
              allowClear
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              allowClear
              mode={'multiple'}
              disabled
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        {
          props.row.id && <Col span={24}>
            <Form.Item
              label="存档批号"
              name="clFile"
              rules={[{required: false, message: '请选择存档批号!'}]}>
              <SelectFiling
                responsibilityProject={responsibilityProject}
                isHideClose
                isHidePlus
                cooperationUnit={cooperationUnit}
                filingMode={"multiple"}
                inputDisabled
                placeholder={"请选择存档批号"}
                allowClear/>
            </Form.Item>
          </Col>
        }
        <Col span={24}>
          <Form.Item
            label="主办人员"
            name="organizersUserId"
            rules={[{required: false, message: '请选择主办人员!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择主办人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理人员"
            name="personResponsible"
            rules={[{required: false, message: '请选择办理人员!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择办理人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="参与人员"
            name="wnUserNames"
            rules={[{required: false, message: '请输入参与人员!'}]}>
            <Input placeholder="请输入参与人员" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="clStatus"
            rules={[{required: false, message: '请输入办理进度!'}]}>
            <SelectProcessStatus
              allowClear
              placeholder={"请选择办理进度"}
              disabled/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
