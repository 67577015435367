import {PageParam, RuleRegulationRow} from "@services/dto/ruleRegulation";
import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";

export class RuleRegulationService {
  static async getRulesAndRegulations(req: PageParam): Promise<Page<RuleRegulationRow>> {
    return await apiV1.get("/rulesAndRegulations", {params: req})
  }

  static async addRulesAndRegulations(row: RuleRegulationRow): Promise<Page<RuleRegulationRow>> {
    return await apiV1.post("/rulesAndRegulations", {...row})
  }

  static async updateRulesAndRegulations(row: RuleRegulationRow): Promise<Page<RuleRegulationRow>> {
    return await apiV1.put("/rulesAndRegulations", {...row})
  }

  static async deleteRulesAndRegulations(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/rulesAndRegulations", {data: ids})
  }
}
