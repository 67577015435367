import {makeAutoObservable, runInAction} from 'mobx'
import {ProvinceCityDistrict} from "@services/dto/provinceCityDistrict";
import {ProvinceCityDistrictService} from "@services/ProvinceCityDistrictService";

export class ProvinceCityDistrictStore {
  provinceCityDistrict: ProvinceCityDistrict[] = []

  constructor() {
    makeAutoObservable(this)
  }

  getAllProvinceCityDistrict() {
    ProvinceCityDistrictService.getProvinceCityDistrict().then(res => {
      runInAction(() => this.provinceCityDistrict = res)
    })
  }
}

export const provinceCityDistrictStore = new ProvinceCityDistrictStore();
