import {observer} from "mobx-react";
import {ColumnsType} from "antd/es/table";
import {BankStatementReq, BankStatementRow} from "@services/dto/bankStatement";
import {FormInstance, Space, Table, Tag, Typography} from "antd";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import React, {useEffect, useState} from "react";
import {HeaderActionCom} from "@modules/BankStatement/Components/HeaderActionCom";
import {allStores} from "@/stores";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {previewFile} from "@components/CustomUpload";
import {
  LARGER_MODAL_WIDTH,
  SMALL_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import {CustomDraggableModal} from "@components/CustomDraggableModal";

export interface PropsType {
  open: boolean
  onChange: (open: boolean) => void
  handleOk: (keys: React.Key[]) => void
}

const narrowShowColumn: string[] = [
  "bsDate",
  "bsName",
  "bsSummary",
  "bsInAmount",
  "bsOutAmount",
  "bsAmount",
  // "bsOtherAccountName",
  // "bsAccount",
  "bsBalance",
  // "bsStatus",
  "statusCommon",
  "action",
]

export const BankStatementTableModal = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {
  const {bankStatementStore, tableColumnsStore} = allStores
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [dataSource, setDataSource] = useState<BankStatementRow[]>([])
  const [total, setTotal] = useState<number>(0)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<BankStatementRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(narrowShowColumn)
  const [searchReq, setSearchReq] = useState<BankStatementReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...bankStatementStore.bankStatementReq,
    isBind: '否'
  })

  /**
   * @description 初始化
   */
  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem('/bankStatement') || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    }
    refreshData(searchReq)
  }, [])

  /**
   * @description 全局查询
   */
  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (bankStatementStore.bankStatementDatasource.data) {
      setDataSource(bankStatementStore.bankStatementDatasource.data.items || [])
      setTotal(bankStatementStore.bankStatementDatasource.data.total || 0)
    }
  }, [bankStatementStore.bankStatementDatasource.data])

  /**
   * 列配置变化
   */
  useEffect(() => {
    setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))))
  }, [showColumn])

  /**
   * 弹窗改变
   */
  useEffect(() => {
    setIsOpen(props.open)
  }, [props.open]);

  const columns: ColumnsType<BankStatementRow> = [
    {
      title: '出纳日期',
      dataIndex: 'bsDate',
      key: 'bsDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
    },
    {
      title: '出纳编号',
      dataIndex: 'bsCode',
      key: 'bsCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
    },
    {
      title: '出纳账户',
      dataIndex: 'bsName',
      key: 'bsName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '出纳账户名称',
      dataIndex: 'baName1',
      key: 'baName1',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '出纳账户账号',
      dataIndex: 'baAccount1',
      key: 'baAccount1',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '出纳账户银行',
      dataIndex: 'baBlank1',
      key: 'baBlank1',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '转入金额',
      dataIndex: 'bsInAmount',
      key: 'bsInAmount',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '转出金额',
      dataIndex: 'bsOutAmount',
      key: 'bsOutAmount',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '余额',
      dataIndex: 'bsAmount',
      key: 'bsAmount',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '对方账户',
      dataIndex: 'bsOtherAccountName',
      key: 'bsOtherAccountName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '对方账户名称',
      dataIndex: 'baName2',
      key: 'baName2',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '对方账户账号',
      dataIndex: 'bsAccount',
      key: 'bsAccount',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '对方账户银行',
      dataIndex: 'baBlank2',
      key: 'baBlank2',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联资金审批',
      dataIndex: 'fundingApprovalCode',
      key: 'fundingApprovalCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={
            <Typography.Text
              type="success"
              className={'c-pointer'}
              onClick={() => {
                // handleFundingApprovalCodeClick(record)
              }}>{ text }</Typography.Text>
          }></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'bsFile',
      key: 'bsFile',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text, record) => (
        <Space>
          {
            text && <Typography.Text
              type="success"
              className={'c-pointer'}
              onClick={() => {
                handlePreview(record)
              }}>查看</Typography.Text>
          }
        </Space>
      )
    },
    {
      title: '余额对账',
      dataIndex: 'bsBalance',
      key: 'bsBalance',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <Tag color={text === '余额相符' ? "success" : 'error'}>{ text }</Tag>
        </Space>
      )
    },
    {
      title: '经办记录',
      dataIndex: 'personResponsible',
      key: 'personResponsible',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'bsStatus',
      key: 'bsStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '校验',
      dataIndex: 'statusCommon',
      key: 'statusCommon',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          {
            <Tag color={text === '完成' ? "success" : 'error'}>{ text }</Tag>
          }
        </Space>
      )
    },
  ];

  const handleOk = () => {
    props.handleOk(selectedRowKeys)
  }

  const handleCancel = () => {
    props.onChange(false)
  }

  const refreshData = (req: BankStatementReq) => {
    setSearchReq(req)
    bankStatementStore.getBankStatement(req)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem('/bankStatement', value)
  }

  /**
   * 文件预览
   * @param record
   */
  const handlePreview = (record: BankStatementRow) => {
    previewFile(record.bsFile as string)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSelectedRowKeys([])
    setSearchReq(req)
    refreshData(req)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    bankStatementStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    setShowColumn(narrowShowColumn)
  }

  return (
    <CustomDraggableModal
      title={<div className="text-align-center">关联资金出纳</div>}
      open={isOpen}
      destroyOnClose
      width={LARGER_MODAL_WIDTH}
      onOk={handleOk}
      onCancel={handleCancel}>

      <HeaderActionCom
        locationPathname={'/bankStatement'}
        size={6}
        collapsed={bankStatementStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        handleShowColumnReset={handleShowColumnReset}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={columns}
        refreshData={(req) => refreshData({...searchReq, ...req})}/>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={dataSource}
        columns={renderColumns}
        size={"middle"}
        loading={bankStatementStore.bankStatementDatasource.loading}
        bordered
        scroll={{y: SMALL_TABLE_SCROLL_HEIGHT, scrollToFirstRowOnChange: true}}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys, columnWidth: 50}}/>
    </CustomDraggableModal>

  )
}))
