import React, {useState} from "react";
import {Button, Col, DatePicker, Row} from "antd";
import IconFont from "@components/IconFont";
import dayjs from "dayjs";
import {SelectProject} from "@components/SelectProject";

export interface PropsType {
    handleTimeChange: (dateStrings: string[]) => void
    handleProjectIdChange: (projectId: string) => void
    exportMonthSalarySum: () => void
}

type RangeValue = [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;

export const SumHeaderAction = (props: PropsType) => {
  const [timeValue, setTimeValue] = useState<RangeValue>(null);

  const handleTimeChange = (dates: RangeValue, dateStrings: [string, string]) => {
    setTimeValue(dates)
    props.handleTimeChange(dateStrings)
  }

  const handleProjectIdChange = (value: string) => {
    props.handleProjectIdChange(value)
  }

  const exportMonthSalarySum = () => {
    props.exportMonthSalarySum()
  }

  return (
    <>
      <Row gutter={16}>
        <Col xl={5} md={6} xs={12}>
          <SelectProject allowClear onChange={handleProjectIdChange}/>
        </Col>
        <Col xl={7} md={10} xs={12}>
          <DatePicker.RangePicker picker={"month"} value={timeValue} onChange={handleTimeChange}
            className={"width-100-percentage"}/>
        </Col>
        <Col xl={12} md={8} xs={24} className={"text-align-right"}>
          <Button 
            icon={<IconFont type={"hr-shangchuandaochu"} className={"icon-fontsize"}/>} 
            type={"primary"}
            size={"middle"} 
            shape={"round"}
            onClick={exportMonthSalarySum}>导出月工资汇总表</Button>
        </Col>
      </Row>
    </>
  )
}