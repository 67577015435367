import {AdvanceParams, AdvanceReq, AdvanceRow, CheckAdvanceReq} from "@services/dto/advance";
import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";

export class AdvanceService {
  static async getAdvancePageList(params: AdvanceParams): Promise<ResponseEntity<Page<AdvanceRow>>> {
    return await apiV1.get("/advancePayments/search", {params: params})
  }

  static async addAdvance(req: AdvanceReq): Promise<ResponseEntity<AdvanceRow>> {
    return await apiV1.post("/advancePayments", {...req})
  }

  static async updateAdvance(id: string, req: AdvanceReq): Promise<ResponseEntity<AdvanceRow>> {
    return await apiV1.put(`/advancePayments/${id}`, {...req})
  }

  static async getAdvanceById(id: string): Promise<ResponseEntity<AdvanceRow>> {
    return await apiV1.get(`/advancePayments/${id}`)
  }

  static async deleteAdvanceById(id: string): Promise<ResponseEntity<AdvanceRow>> {
    return await apiV1.delete(`/advancePayments/${id}`)
  }

  static async deleteBatchAdvanceByIds(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/advancePayments/deleteBatch", {data: {ids}})
  }

  static async checkAdvancePayments(req: CheckAdvanceReq): Promise<ResponseEntity<number>> {
    return await apiV1.get("/advancePayments/check", {params: req})
  }
}