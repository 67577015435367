import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {HeaderAction, PropsType as ParentPropsType} from "@components/HeaderAction";
import {ColumnsType} from "antd/es/table";
import {DatePicker} from "antd";
import dayjs from "dayjs";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {SelectProject} from "@components/SelectProject";
import {Any} from "@common/types";
import {SelectNotificationStatus} from "@components/SelectNotificationStatus";
import {ConferenceRecordReq} from "@services/dto/conferenceRecord";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {CustomApplicationFormType} from "@components/CustomApplicationFormType";

export interface PropsType extends ParentPropsType {
  selectedRowKeys?: React.Key[], // 所选行
  handleDeleteBatch?: () => void,
  handleAdd?: () => void,
  handleKeywordSearch?: () => void,
  handleKeywordChange?: (value: string) => void,
  handleTableColumnsChange?: (value: string[]) => void,
  showColumn?: string[],
  tableColumns?: ColumnsType,
  refreshData?: (req: ConferenceRecordReq) => void,
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<ConferenceRecordReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: ConferenceRecordReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleWnStatusChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.mmStatus = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleMmDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.mmDate1 = dateStrings[0]
    req.mmDate2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleMmMDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.mmMdate1 = dateStrings[0]
    req.mmMdate2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleMmUserIdsChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.mmUserIds = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleApplicationFormTypeChange = ([value, tableValue]: [value: string, tableValue: string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.tableType = value
    req.meetingMinutesIds = tableValue
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonResponsibleChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined
    req.personResponsibleName = undefined
    req.mmDate1 = undefined
    req.mmDate2 = undefined
    req.mmMdate1 = undefined
    req.mmMdate2 = undefined
    req.mmStatus = undefined
    req.mmUserIds = undefined
    req.mmUserNames = undefined

    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <HeaderAction
        size={props.size}
        isHiddenOptions={props.isHiddenOptions}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        selectedRowKeys={props.selectedRowKeys}
        handleDeleteBatch={props.handleDeleteBatch}
        handleAdd={props.handleAdd}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={[
          <DatePicker.RangePicker
            allowClear
            value={searchReq?.mmDate1 ? [dayjs(searchReq?.mmDate1), dayjs(searchReq?.mmDate2)] : [undefined, undefined]}
            onChange={handleMmDateChange}
            placeholder={['制单开始日期', '制单结束日期']}
            className={"width-100-percentage"}/>,
          <DatePicker.RangePicker
            allowClear
            value={searchReq?.mmMdate1 ? [dayjs(searchReq?.mmMdate1), dayjs(searchReq?.mmMdate2)] : [undefined, undefined]}
            onChange={handleMmMDateChange}
            placeholder={['会议开始日期', '会议结束日期']}
            className={"width-100-percentage"}/>,
          <SelectPersonCharge
            updateCount={props.updateCount}
            onChange={handleMmUserIdsChange}
            className={"width-100-percentage"}
            value={searchReq?.mmUserIds}
            placeholder={"请选择与会人员"}
            allowClear/>,
          <CustomApplicationFormType
            className={'width-100-percentage'}
            allowClear
            placeholder={'申请表单'}
            isOption
            value={[searchReq?.tableType, searchReq?.meetingMinutesIds]}
            onSearch={handleApplicationFormTypeChange}/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
          <SelectCooperativeUnit
            onChange={handleCooperationUnitChange}
            value={searchReq?.cooperationUnit}
            allowClear
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectPersonCharge
            updateCount={props.updateCount}
            onChange={handlePersonResponsibleChange}
            className={"width-100-percentage"}
            value={searchReq?.personResponsible}
            placeholder={"请选择办理人员"}
            allowClear/>,
          <SelectNotificationStatus
            onChange={handleWnStatusChange}
            allowClear
            value={searchReq?.mmStatus}
            placeholder={'请选择办理进度'}
            className={"width-100-percentage"}/>,
        ]}/>
    </>
  )
}))
