import {observer} from "mobx-react";
import {HeaderAction} from "@modules/Advance/Components/HeaderAction";
import React, {useEffect, useState} from "react";
import {AdvanceParams, AdvanceReq, AdvanceRow} from "@services/dto/advance";
import {ContentTable} from "@modules/Advance/Components/ContentTable";
import {allStores} from "@/stores";
import {Button, Drawer, message, Modal} from "antd";
import {showErr} from "@common/utils";
import {AdvanceService} from "@services/AdvanceService";
import {DATE_FMT, DEFAULT_MODAL_WIDTH} from "@/config";
import {BasicInfo} from "@modules/Advance/Components/BasicInfo";
import {FormInstance} from "antd/es";

export const Advance = observer(() => {
  const {advanceStore} = allStores
  const {advanceDatasource} = advanceStore
  const [row, setRow] = useState<AdvanceRow>()
  const basicInfo = React.createRef<FormInstance>()
  const [visible, setVisible] = useState<boolean>(false)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [params, setParams] = useState<AdvanceParams>({
    pageNum: 1,
    perPage: 10
  })
  const [isView, setIsView] = useState<boolean>(false)

  useEffect(() => {
    refreshData(params)
  }, [])

  const refreshData = (params: AdvanceParams) => {
    advanceStore.getAdvancePageList(params)
  }

  const handleProjectIdChange = (projectId: string) => {
    const req = {...params}
    req.pageNum = 1
    req.projectId = projectId
    setParams(req)
    refreshData(req)
  };

  const handleSearch = (searchValue: string) => {
    const req = {...params}
    req.pageNum = 1
    req.keyword = searchValue
    setParams(req)
    refreshData(req)
  }

  const handleDeleteBatch = (record?: AdvanceRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids:string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        AdvanceService.deleteBatchAdvanceByIds(ids).then(() => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(params)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handleAdd = () => {
    setIsView(false)
    setRow(undefined)
    setVisible(true)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    const req = {...params}
    req.pageNum = page
    req.perPage = pageSize
    setParams(req)
    refreshData(params)
  }

  const handleEdit = (record: AdvanceRow) => {
    setIsView(false)
    setRow(record)
    setVisible(true)
  }

  const handleView = (record: AdvanceRow) => {
    setIsView(true)
    setRow(record)
    setVisible(true)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const handleOk = () => {
    basicInfo.current?.validateFields().then(value => {
      setConfirmLoading(true)
      if (value.date) {
        value.year = value.date.year()
        value.month = value.date.month() + 1
        value.day = value.date.date()
        value.date = value.date.format(DATE_FMT)
      }
      const advanceReq: AdvanceReq = {
        projectName: value.projectName,
        projectId: value.person[0],
        professionalLeagues: value.person[1],
        professional: value.person[2],
        uid: value.person[3],
        money: value.money,
        bankCardNumber: value.bankCardNumber,
        leadingPeopleIRecipients: value.leadingPeopleIRecipients,
        date: value.date,
        year: value.year,
        month: value.month,
        day: value.day,
        remark: value.remark
      };
      if (row?.id) {
        AdvanceService.updateAdvance(row.id, advanceReq).then(rsp => {
          message.success("修改成功！")
          handleCancel()
          refreshData(params)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      } else {
        AdvanceService.addAdvance(advanceReq).then(rsp => {
          message.success("新增成功！")
          handleCancel()
          refreshData(params)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      }
      setVisible(false);
    })
  }

  return (
    <>
      <HeaderAction
        handleProjectIdChange={handleProjectIdChange}
        handleDeleteBatch={handleDeleteBatch}
        handleSearch={handleSearch}
        handleAdd={handleAdd}/>

      <ContentTable
        className={"margin-top-sm"}
        loading={advanceDatasource.loading || confirmLoading}
        total={advanceDatasource.data?.data.total || 0}
        perPage={params.perPage}
        pageNum={params.pageNum}
        selectedRowKeys={selectedRowKeys}
        dataSource={advanceDatasource.data?.data.items || []}
        handlePageChange={handlePageChange}
        handleChange={setSelectedRowKeys}
        handleEdit={handleEdit}
        handleView={handleView}
        handleDeleteBatch={handleDeleteBatch}/>

      <Drawer
        title={<div className="text-align-center">工资预支</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={visible}
        onClose={handleCancel}>

        <BasicInfo row={{...row!}} ref={basicInfo} isView={isView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button type="primary" onClick={handleOk} loading={confirmLoading} className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>
    </>
  )
})