import React, {useEffect} from "react";
import {Col, Form, InputNumber, Row} from "antd";
import {FormInstance} from "antd/es";
import {PropsType} from "@modules/Compensation/Components/MonthPersonForm";

export const MonthAdminForm = React.forwardRef<FormInstance, PropsType>((props, ref) => {
  const [form] = Form.useForm()
  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(props.row)
  }, [props.row])

  return (
    <>
      <Form
        name="basic"
        ref={ref}
        form={form}
        labelCol={{span: 8}}
        wrapperCol={{span: 14}}
        autoComplete="off">
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="日工资"
              name="dailyWages"
              rules={[{required: true, message: '请输入日工资!'}]}>
              <InputNumber placeholder="请输入日工资" precision={2} className={"width-100-percentage"}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Col span={24}>
              <Form.Item
                label="总工日合计"
                name="attendanceDaysSum"
                rules={[{required: true, message: '请输入总工日合计!'}]}>
                <InputNumber placeholder="请输入总工日合计" precision={3} className={"width-100-percentage"}/>
              </Form.Item>
            </Col>
            <Form.Item
              label="加班小时合计"
              name="totalOvertimeHours"
              rules={[{required: true, message: '请输入加班小时合计!'}]}>
              <InputNumber placeholder="请输入加班小时合计" precision={2} className={"width-100-percentage"}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="加班工资"
              name="overtimePay"
              rules={[{required: true, message: '请输入加班工资!'}]}>
              <InputNumber placeholder="请输入加班工资" precision={2} className={"width-100-percentage"}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="餐补总额"
              name="totalamountoffoodsubsidies"
              rules={[{required: true, message: '请输入餐补总额!'}]}>
              <InputNumber placeholder="请输入餐补总额" precision={2} className={"width-100-percentage"}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="车费补贴"
              name="theFareSubsidies"
              rules={[{required: true, message: '请输入车费补贴!'}]}>
              <InputNumber placeholder="请输入车费补贴" precision={2} className={"width-100-percentage"}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="报销车费体检"
              name="forTheFare"
              extra={"军核考试考勤"}
              rules={[{required: true, message: '请输入报销车费体检(军核考试考勤)!'}]}>
              <InputNumber placeholder="请输入报销车费体检(军核考试考勤)" precision={2} className={"width-100-percentage"}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="扣款"
              name="deductions"
              rules={[{required: true, message: '请输入扣款!'}]}>
              <InputNumber placeholder="请输入扣款" precision={2} className={"width-100-percentage"}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="管理费"
              name="buckleFee"
              rules={[{required: true, message: '请输入管理费!'}]}>
              <InputNumber placeholder="请输入管理费" precision={2} className={"width-100-percentage"}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="劳保"
              name="deductionLaborInsurance"
              rules={[{required: true, message: '请输入所扣劳保!'}]}>
              <InputNumber placeholder="请输入所扣劳保" precision={2} className={"width-100-percentage"}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="上月多发"
              name="lastMonthMultiple"
              rules={[{required: true, message: '请输入上月多发!'}]}>
              <InputNumber placeholder="请输入上月多发" precision={2} className={"width-100-percentage"}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="扣除本月社保个人承担部分"
              name="peopleTakePart"
              rules={[{required: true, message: '请输入本月社保个人承担部分!'}]}>
              <InputNumber placeholder="请输入本月社保个人承担部分" precision={2} className={"width-100-percentage"}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="预支工资"
              name="buckleAdvancePayments"
              rules={[{required: true, message: '请输入预支工资!'}]}>
              <InputNumber placeholder="请输入预支工资" precision={2} className={"width-100-percentage"}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="水电代扣"
              name="withholdingHydropower"
              rules={[{required: true, message: '请输入水电代扣!'}]}>
              <InputNumber placeholder="请输入水电代扣" precision={2} className={"width-100-percentage"}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="代扣他行卡"
              name="withholdinghislinecard"
              rules={[{required: true, message: '请输入代扣他行卡!'}]}>
              <InputNumber placeholder="请输入代扣他行卡" precision={2} className={"width-100-percentage"}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="项目部代发"
              name="projectDepartmentUndertakesToBuckle"
              rules={[{required: true, message: '请输入项目部代发!'}]}>
              <InputNumber placeholder="请输入项目部代发" precision={2} className={"width-100-percentage"}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="总部发放"
              name="undertakesToBuckleHeadquarters"
              rules={[{required: true, message: '请输入总部发放!'}]}>
              <InputNumber placeholder="请输入总部发放" precision={2} className={"width-100-percentage"}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="应交税额"
              name="sui"
              rules={[{required: true, message: '请输入应交税额!'}]}>
              <InputNumber placeholder="请输入应交税额" precision={2} className={"width-100-percentage"}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="合计应发工资"
              name="totalShouldBePay"
              rules={[{required: true, message: '请输入合计应发工资!'}]}>
              <InputNumber addonAfter={'元'} placeholder="请输入合计应发工资" precision={2} className={"width-100-percentage"}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="合计实发工资"
              name="totalSalary"
              rules={[{required: true, message: '请输入合计实发工资!'}]}>
              <InputNumber addonAfter={'元'} placeholder="请输入合计实发工资" precision={2} className={"width-100-percentage"}/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
})
