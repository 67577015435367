import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {DocumentApprovalReq, DocumentApprovalRow} from "@/services/dto/documentApproval";
import {DocumentApprovalService} from "@services/DocumentApprovalService";

export class DocumentApprovalStore {
  documentApprovalDatasource: StateFullData<Page<DocumentApprovalRow>> = {}
  documentApprovalSelectDatasource: StateFullData<Page<DocumentApprovalRow>> = {}
  documentApprovalRow?: DocumentApprovalRow
  documentApprovalOption?: string
  documentApprovalDetail: StateFullData<ResponseEntity<DocumentApprovalRow>> = {}
  documentApprovalDetails: StateFullData<ResponsesEntity<DocumentApprovalRow>> = {}
  documentApprovalSearchReq: DocumentApprovalReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateDocumentApprovalRow(row: DocumentApprovalRow) {
    this.documentApprovalRow = {...row}
  }

  updateDocumentApprovalOption(option: string) {
    this.documentApprovalOption = option
  }

  updateDocumentApprovalSearchReq(req: DocumentApprovalReq) {
    this.documentApprovalSearchReq = {...this.documentApprovalSearchReq, ...req}
  }

  getDocumentApproval(req: DocumentApprovalReq) {
    this.documentApprovalDatasource = {...this.documentApprovalDatasource, loading: true}
    this.documentApprovalSearchReq = {...this.documentApprovalSearchReq, ...req}
    DocumentApprovalService.getDocumentApproval(req).then(rsp => {
      runInAction(() => this.documentApprovalDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.documentApprovalDatasource = {...this.documentApprovalDatasource, loading: false, err})
    })
  }

  getSelectDocumentApproval(req: DocumentApprovalReq) {
    this.documentApprovalSelectDatasource = {...this.documentApprovalSelectDatasource, loading: true}
    DocumentApprovalService.getDocumentApproval(req).then(rsp => {
      runInAction(() => this.documentApprovalSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.documentApprovalSelectDatasource = {...this.documentApprovalSelectDatasource, loading: false, err})
    })
  }

  getOne(id: string) {
    this.documentApprovalDetail = {...this.documentApprovalDetail, loading: true}
    DocumentApprovalService.getOne(id).then(rsp => {
      runInAction(() => this.documentApprovalDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.documentApprovalDetail = {...this.documentApprovalDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.documentApprovalDetails = {...this.documentApprovalDetails, loading: true}
    DocumentApprovalService.getBatch(ids).then(rsp => {
      runInAction(() => this.documentApprovalDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.documentApprovalDetails = {...this.documentApprovalDetails, loading: false, err})
    })
  }

  async getPrintList(record: DocumentApprovalRow): Promise<LabelValue[]> {

    const row = this.documentApprovalDatasource.data?.items.find(o => o.id === record.id)

    return [
      {label: '制单日期', value: row.daDate as string},
      {label: '制单编号', value: row.daCode},
      {label: '文件名称', value: row.daName},
      {label: '生效日期', value: row.daEffectiveDate as string},
      {label: '失效日期', value: row.daExpiryDate as string},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const documentApprovalStore = new DocumentApprovalStore();
