import React, {FC, useEffect, useState} from "react";
import {PublicityBasic} from '../Components/PublicityBasic'
import {Recruitment} from "../Components/Recruitment"
import {useNavigate, useParams} from 'react-router-dom'
import './PublicityAction.less'
import {Button} from "antd";
import {FormInstance} from "antd/es";
import {RecruitmentReq, WorkerTypes} from "@services/dto/recruitment";
import {RecruitmentService} from "@services/RecruitmentService";
import {logger, showErr} from "@common/utils";
import {DATE_FMT} from "@/config";

export const PublicityAction: FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const publicityBasic = React.createRef<FormInstance>();
  const [recruitmentReq, setRecruitmentReq] = useState<RecruitmentReq>()

  // 招聘岗位
  const handleAddRecruitment = (workerType: WorkerTypes, isEdit: boolean, index: number) => {
    const recruitmentReqTypes = {...recruitmentReq!}
    const workerTypes = [...recruitmentReqTypes.workerTypes || []];
    if (isEdit) {
      const i = workerTypes.findIndex(item => item.typeId === workerType.typeId)
      if (i >= 0 && i !== index) {
        const type = {...workerTypes[i]}
        type.numberOfRequire += workerType.numberOfRequire
        workerTypes[i] = type
        workerTypes.splice(index, 1);
      } else {
        workerTypes[index] = workerType;
      }
    } else {
      const i = workerTypes.findIndex(item => item.typeId === workerType.typeId)
      if (i >= 0) {
        workerType.numberOfRequire += workerTypes[i].numberOfRequire
        workerTypes[i] = workerType
      } else {
        workerTypes.push(workerType);
      }
    }
    recruitmentReqTypes.workerTypes = workerTypes
    initRecruitmentReq(recruitmentReqTypes)
  }
  const handleRemoveRecruitment = (typeIds: string[]) => {
    let recruitmentReqTypes = {...recruitmentReq!}
    const workerTypes = [...recruitmentReqTypes.workerTypes || []];
    recruitmentReqTypes.workerTypes = workerTypes.filter(item => !typeIds.includes(item.typeId))
    initRecruitmentReq(recruitmentReqTypes)
  }

  useEffect(() => {
    if (params._id) {
      RecruitmentService.getRecruitmentById(params._id).then(rsp => {
        const recruitmentObj = {...recruitmentReq!}
        Object.assign(recruitmentObj, rsp)
        if (typeof rsp.region === "string") {
          recruitmentObj.region = rsp.region.split(",")
        }
        setRecruitmentReq(recruitmentObj);
      }).catch(showErr)
    }
  }, [])

  const backPage = () => {
    navigate('/recruitments')
  }

  const handleSave = (status: string) => {
    publicityBasic.current!.validateFields().then(value => {
      const recruitmentObj = {...recruitmentReq!}
      Object.assign(recruitmentObj, value)
      recruitmentObj.region = value.region.join(",")
      if (status !== 'draft' || !value.status) {
        recruitmentObj.status = status;
      }
      recruitmentObj.phone = value.phone.join(",")
      recruitmentObj.releaseTime = value.releaseTime.format(DATE_FMT)
      recruitmentObj.recruiters = recruitmentObj.recruiters.map(item => ({
        ...item,
        recruiterId: item.value!,
        recruiterName: item.label!
      }))
      setLoading(true)
      if (params._id) {
        RecruitmentService.updateRecruitment(params._id, recruitmentObj).then(rsp => {
          navigate('/recruitments')
        }).catch(showErr).finally(() => {setLoading(false)});
      } else {
        RecruitmentService.addRecruitment(recruitmentObj).then(rsp => {
          navigate('/recruitments')
        }).catch(showErr).finally(() => {setLoading(false)});
      }
    }).catch(info => {
      logger.log('Validate Failed:', info);
    });
  }

  const initRecruitmentReq = (recruitmentObj: RecruitmentReq) => {
    const values = publicityBasic.current?.getFieldsValue()
    Object.assign(recruitmentObj, values)
    setRecruitmentReq(recruitmentObj)
  }

  const handleReset = () => {
    publicityBasic.current!.resetFields()
    const recruitmentObj = {...recruitmentReq!}
    recruitmentObj.workerTypes = []
    initRecruitmentReq(recruitmentObj);
  }

  return (
    <div className={"publicity-action"}>
      <div className={"publicity-content"}>
        <PublicityBasic ref={publicityBasic} recruitmentReq={{...recruitmentReq!}}/>

        <Recruitment dataSource={[...recruitmentReq?.workerTypes || []]} handleAddRecruitment={handleAddRecruitment}
          handleRemoveRecruitment={handleRemoveRecruitment} className={"margin-top-sm"}/>
      </div>
      <div className="button-bottom margin-top-xs">
        <Button className="action-button cu-btn-green" shape="round" onClick={backPage}>返回上一页</Button>
        <Button className="action-button cu-btn-blue" shape="round" onClick={handleReset}>重置</Button>
        <Button className="action-button cu-btn-red" shape="round"
          onClick={() => handleSave('draft')} loading={loading}>保存</Button>
        {
          recruitmentReq && recruitmentReq.status === 'online' ?
            <Button className="action-button" type="primary" shape="round"
              onClick={() => handleSave('offline')} loading={loading}>下线</Button> :
            <Button className="action-button" type="primary" shape="round"
              onClick={() => handleSave('online')} loading={loading}>发布</Button>
        }
      </div>
    </div>
  )
}
