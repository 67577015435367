import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from "react-router-dom"
import {Col, Row, Card, Image, Button, Pagination, Modal, Form, Input, Upload, Spin} from "antd";
import './Document.less'
import {UploadOutlined} from "@ant-design/icons";
import {allStores} from "@/stores";
import {observer} from "mobx-react";
import {DocumentReq, DocumentRow} from "@services/dto/document";
import {DocumentService} from "@services/DocumentService";
import {showErr} from "@common/utils";
import {BASE_API_PATH} from "@/config";
import docImg from "@assets/images/doc.png"
import excelImg from "@assets/images/excel.png"

export const Document = observer(() => {
  const [isPopup, setIsPopup] = useState<string>('')
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false);
  const {documentStore} = allStores
  const [documentRow, setDocumentRow] = useState<DocumentRow[]>([])
  const [total, setTotal] = useState<number>(0)
  const [pageSize, setPageSize] = useState<number>(12)
  const params = useParams()
  const [spinning, setSpinning] = useState<boolean>(false)

  useEffect(() => {
    refreshData({perPage: pageSize, pageNum: 1})
  }, [])

  const refreshData = (req: DocumentReq) => {
    documentStore.getDocumentTpls(req)
  }

  useEffect(() => {
    setDocumentRow(documentStore.documents.data?.items!)
    setTotal(documentStore.documents.data?.total!)
  }, [documentStore.documents])

  const handlePageChange = (page: number, pageSize: number) => {
    setPageSize(pageSize)
    refreshData({perPage: pageSize, pageNum: page})
  }

  const handleMouseMove = (i: string) => {
    setIsPopup(i)
  }

  const handleMouseLeave = () => {
    setIsPopup('')
  }

  const handleClick = (obj: DocumentRow) => {
    setSpinning(true)
    DocumentService.generateDocumentTpl(obj._id, params.userId!).then(rsp => {
      const url = window.URL.createObjectURL(rsp)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', obj.uri.substring(obj.uri.lastIndexOf("/") + 1))
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }).catch(showErr).finally(() => {
      setSpinning(false)
    })
  }

  const backPage = () => {
    navigate("/onBoardingUsers")
  }

  const handleOk = () => {
    form.validateFields().then(values => {
      setIsModalVisible(false);
    })
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const getCoverImg = (item: DocumentRow): string => {
    const fileName = item.uri.substring(item.uri.lastIndexOf("/") + 1)
    if (fileName.includes(".doc") || fileName.includes(".docx")) {
      return docImg
    } else if (fileName.includes(".xls") || fileName.includes(".xlsx")) {
      return excelImg
    }
    return BASE_API_PATH + item.cover;
  }

  return (
    <Spin spinning={spinning}>
      <div className="header-action">
        <Button className="action-button cu-btn-gray" shape="round" onClick={backPage}>返回上一页</Button>
        { /*<Button className="action-button" type="primary" shape="round" onClick={() => setIsModalVisible(true)}>添加文档</Button>*/ }
      </div>
      <Row gutter={16}>
        {
          documentRow?.map(item => {
            return (
              <Col xxl={4} xl={6} key={item._id}>
                <Card
                  className={"document-card"}
                  bodyStyle={{padding: 0}}
                  cover={
                    <Image
                      preview={false}
                      height={230}
                      width={"100%"}
                      src={getCoverImg(item)}
                      onMouseMove={(e) => handleMouseMove(item._id)}
                      onMouseLeave={(e) => handleMouseLeave()}
                    />
                  }
                  actions={[
                    <div className="title">{ item.name }</div>
                  ]}>
                  {
                    isPopup === item._id && <Card.Meta className="meta-bottom"
                      title={<Button type="primary"
                        className={'width-100-percentage'}
                        size="large"
                        onMouseMove={(e) => handleMouseMove(item._id)}
                        onMouseLeave={(e) => handleMouseLeave()}
                        onClick={() => handleClick(item)}>生成并下载</Button>}/>
                  }
                </Card>
              </Col>
            )
          })
        }
      </Row>
      <div className="pagination-container">
        <Pagination
          total={total}
          pageSizeOptions={[12, 24, 36, 48, 60]}
          showSizeChanger
          showQuickJumper
          onChange={handlePageChange}
          pageSize={pageSize}
          showTotal={total => `共 ${total} 条`}
        />
      </div>

      <Modal title="文档" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} maskClosable={false}>
        <Form
          name="basic"
          form={form}
          labelCol={{span: 6}}
          wrapperCol={{span: 16}}
          autoComplete="off">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="文档名称"
                name="name"
                rules={[{required: true, message: '请输入文档名称!'}]}>
                <Input placeholder="请输入文档名称"/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="文档类型"
                name="type"
                rules={[{required: true, message: '请输入文档类型!'}]}>
                <Input placeholder="请输入文档类型"/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="文档描述"
                name="describe"
                rules={[{required: true, message: '请输入文档描述!'}]}>
                <Input placeholder="请输入文档描述"/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="templateFile"
                label="文档模板上传"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{required: true, message: '请上传文档模板!'}]}
              >
                <Upload name="logo" listType="picture">
                  <Button icon={<UploadOutlined/>}>模板上传</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Spin>
  )
})
