import React, {FC, useState} from "react";
import {observer} from "mobx-react";
import {Button, Col, Input, Radio, Row} from "antd";
import {WorkerRecruitmentStatus} from "@/config";
import IconFont from "@components/IconFont";
import {RollbackOutlined, SearchOutlined} from "@ant-design/icons";
import {RadioChangeEvent} from "antd/es/radio/interface";
import {SelectProject} from "@components/SelectProject";

export interface PropsType {
  disabled: boolean
  handleActiveKeyChange: (value: string) => void
  handleProjectIdChange: (value: string) => void
  handlePressEnter: (value: string) => void
  handleBatchRecall: () => void
}

export const HeaderAction:FC<PropsType> = observer((props: PropsType) => {
  const [activeKey, setActiveKey] = useState(WorkerRecruitmentStatus.hired)
  const [searchValue, setSearchValue] = useState<string>("")
    
  const handleActiveKeyChange = (e: RadioChangeEvent) => {
    setActiveKey(e.target.value)
    props.handleActiveKeyChange(e.target.value)
  }

  const handleProjectIdChange = (value: string) => {
    props.handleProjectIdChange(value)
  }

  const handlePressEnter = () => {
    props.handlePressEnter(searchValue)
  }
    
  return (
    <>
      <Row gutter={16}>
        <Col xl={5} lg={6} md={9} xs={12}>
          <Radio.Group value={activeKey} onChange={handleActiveKeyChange} buttonStyle="solid"
            size={"middle"}
            disabled={props.disabled}>
            <Radio.Button value={WorkerRecruitmentStatus.hired}><IconFont type={"hr-caogaoxiang1"}
              className={"icon-fontsize"}/>已入职</Radio.Button>
            <Radio.Button value={WorkerRecruitmentStatus.pmConfirmed}><IconFont type={"hr-mokuai-shang"}
              className={"icon-fontsize icon-rotate"}/>待入职</Radio.Button>
          </Radio.Group>
        </Col>
        <Col xl={{span: 5}} lg={4} md={6} xs={12}>
          <SelectProject allowClear onChange={handleProjectIdChange}/>
        </Col>
        <Col xl={7} lg={8} md={9} xs={12}>
          <Input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={handlePressEnter}
            allowClear
            placeholder="请输入姓名/电话/工种/籍贯"
            className={"width-100-percentage"}
            suffix={<SearchOutlined onClick={handlePressEnter}/>}
          />
        </Col>
        <Col xl={7} lg={6} md={24} xs={12} className={"text-align-right"}>
          {
            activeKey === WorkerRecruitmentStatus.pmConfirmed &&
                      <Button icon={<RollbackOutlined/>} size={"middle"} shape={"round"}
                        onClick={() => props.handleBatchRecall()}>驳回</Button>
          }
        </Col>
      </Row>
    </>
  )
})