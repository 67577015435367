import {
  QRCodeReq,
  RecruitmentReq,
  RecruitmentRow,
  SearchRecruitmentReq,
  UpdateRecruiters
} from "@services/dto/recruitment";
import {Page} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {Any} from "@common/types";

export class RecruitmentService {
  static async searchRecruitments(req: SearchRecruitmentReq): Promise<Page<RecruitmentRow>> {
    return await apiV1.get('/recruitments', {params: req});
  }

  static async addRecruitment(obj: RecruitmentReq): Promise<RecruitmentRow> {
    return await apiV1.post('/recruitments', obj);
  }

  static async updateRecruitment(id: string, obj: RecruitmentReq): Promise<RecruitmentRow> {
    return await apiV1.put(`/recruitments/${id}`, obj);
  }

  static async updateRecruiters(id: string, obj: UpdateRecruiters): Promise<RecruitmentRow> {
    return await apiV1.put(`/recruitments/${id}`, obj);
  }

  static async deleteRecruitment(id: string): Promise<void> {
    return await apiV1.delete(`/recruitments/${id}`);
  }

  static async deleteBatchRecruitment(ids: string[]): Promise<void> {
    return await apiV1.delete("/recruitments/deleteBatch", {data: {ids}});
  }

  static async getRecruitmentById(id: string): Promise<RecruitmentRow> {
    return await apiV1.get(`/recruitments/${id}`);
  }

  static async getFrom(recruitmentId: string): Promise<string> {
    return await apiV1.get(`/open/user/getFrom/${recruitmentId}`)
  }

  static async programQrCode(req: QRCodeReq): Promise<Any> {
    return await apiV1.get('/programQrCode', {params: req})
  }
}
