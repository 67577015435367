import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import dayjs from "dayjs";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {allStores} from "@/stores";
import {SelectNotificationStatus} from "@components/SelectNotificationStatus";
import {SelectApplicationFormType} from "@components/SelectApplicationFormType";
import {ConferenceRecordRow} from "@services/dto/conferenceRecord";
import {OrganizationalRow} from "@services/dto/organizational";
import {SelectFiling} from "@components/SelectFiling";
import {Any} from "@common/types";

export interface PropsType {
  row: ConferenceRecordRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores
  const [meetingMinutesIds, setMeetingMinutesIds] = useState<string[]>()
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()

  useEffect(() => {
    form.resetFields()
    const conferenceRecordRow = {...props.row}
    filingStore.setFilingFaCode(conferenceRecordRow.mmFile)
    if (conferenceRecordRow.meetingMinutesFroms?.length) {
      setMeetingMinutesIds(conferenceRecordRow.meetingMinutesFroms.map(o => o.businessId))
    } else {
      setMeetingMinutesIds([])
    }
    if (conferenceRecordRow.id) {
      if (typeof conferenceRecordRow.mmDate === 'string' && conferenceRecordRow.mmDate !== '') {
        conferenceRecordRow.mmDate = dayjs(conferenceRecordRow.mmDate)
      }
      if (typeof conferenceRecordRow.mmMdate === 'string' && conferenceRecordRow.mmMdate !== '') {
        conferenceRecordRow.mmMdate = dayjs(conferenceRecordRow.mmMdate)
      }
      if (typeof conferenceRecordRow.mmUserIds === 'string' && conferenceRecordRow.mmUserIds) {
        conferenceRecordRow.mmUserIds = conferenceRecordRow.mmUserIds.split(",")
      } else {
        conferenceRecordRow.mmUserIds = []
      }
      if (typeof conferenceRecordRow.mmUserNames === 'string' && conferenceRecordRow.mmUserNames) {
        conferenceRecordRow.mmUserNames = conferenceRecordRow.mmUserNames.split(",")
      } else {
        conferenceRecordRow.mmUserNames = []
      }
      if (typeof conferenceRecordRow.cooperationUnit === 'string' && conferenceRecordRow.cooperationUnit) {
        conferenceRecordRow.cooperationUnit = conferenceRecordRow.cooperationUnit.split(",")
      } else {
        conferenceRecordRow.cooperationUnit = []
      }
      setResponsibilityProject(conferenceRecordRow.responsibilityProject);
      setCooperationUnit(conferenceRecordRow.cooperationUnit)
    }
    form.setFieldsValue(conferenceRecordRow);
  }, [props.row])

  const handlePersonResponsibleChange = (valueArr: string[], selectedOptions: OrganizationalRow[]) => {
    if (typeof valueArr !== 'object') {
      return
    }
    const rows: string[] = selectedOptions.filter(o => valueArr.includes(o.id)).map(o => o.name)
    form.setFieldsValue({mmUserNames: rows})
  }

  const handleFaApplicationFormTypeChange = () => {
    setMeetingMinutesIds(undefined)
    form.resetFields(['meetingMinutesIds'])
  }

  const handleTableValueChange = async (v: string[], options: Any[]) => {
    if (options && options.length > 0) {
      const cooperationUnit = typeof options[0].cooperationUnit === 'string' ? options[0].cooperationUnit?.split(",") : options[0].cooperationUnit
      setResponsibilityProject(options[0].personResponsible)
      setCooperationUnit(cooperationUnit)
      form.setFieldsValue({
        responsibilityProject: options[0].responsibilityProject,
        cooperationUnit: cooperationUnit,
        meetingMinutesIds: v
      });
    } else {
      setResponsibilityProject(undefined)
      setCooperationUnit([])
      form.setFieldsValue({
        responsibilityProject: undefined,
        cooperationUnit: undefined,
        meetingMinutesIds: undefined
      });
    }
    setMeetingMinutesIds(v)
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="mmFileCode">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="mmUserNames">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="meetingMinutesIds">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="mmDate"
            rules={[{required: false, message: '请输入制单日期!'}]}>
            <DatePicker
              disabled
              placeholder={"请选择制单日期"}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="mmCode"
            rules={[{required: false, message: '请输入制单编号!'}]}>
            <Input
              disabled
              placeholder="请输入制单编号"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="会议主题"
            name="mmTitle"
            rules={[{required: false, message: '请输入会议主题!'}]}>
            <Input.TextArea
              placeholder="请输入会议主题"
              autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="会议日期"
            name="mmMdate"
            rules={[{required: false, message: '请输入会议日期!'}]}>
            <DatePicker
              placeholder={"请输入会议日期"}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="会议地点"
            name="mmAddress"
            rules={[{required: false, message: '请输入会议地点!'}]}>
            <Input placeholder="请输入会议地点"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="与会人员"
            name="mmUserIds"
            rules={[{required: false, message: '请选择与会人员!'}]}>
            <SelectPersonCharge
              onChange={handlePersonResponsibleChange}
              mode={"multiple"}
              className={"width-100-percentage"}
              placeholder={"请选择与会人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联表单"
            name="tableType"
            rules={[{required: false, message: '请输入关联表单!'}]}>
            <SelectApplicationFormType
              className={'width-100-percentage'}
              allowClear
              isOption
              showKey={['工资发放', '员工借款', '费用报销', '合同结算', '税费核算', '税费缴纳', '资金出纳', '工作任务']}
              mode={'multiple'}
              placeholder={'请选择关联申请表单'}
              tableValue={meetingMinutesIds}
              onChange={handleFaApplicationFormTypeChange}
              handleTableValueChange={handleTableValueChange}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: false, message: '请选择责任部门!'}]}>
            <SelectProject
              disabled
              placeholder={"请选择责任部门"}
              allowClear
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              disabled
              allowClear
              mode={"multiple"}
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="存档批号"
            name="mmFile"
            rules={[{required: false, message: '请选择存档批号!'}]}>
            <SelectFiling
              responsibilityProject={responsibilityProject}
              isHideClose
              isHidePlus
              cooperationUnit={cooperationUnit}
              filingMode={"multiple"}
              inputDisabled
              placeholder={"请选择存档批号"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理人员"
            name="personResponsible"
            rules={[{required: false, message: '请选择办理人员!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              disabled
              placeholder={"请选择办理人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="mmStatus"
            rules={[{required: false, message: '请输入办理进度!'}]}>
            <SelectNotificationStatus
              allowClear
              className={"width-100-percentage"}
              placeholder={'请选择办理进度'}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
