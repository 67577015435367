import {makeAutoObservable, runInAction} from "mobx";
import {Page, ResponseEntity, StateFullData} from "@services/dto/common";
import {CooperativeUnitService} from "@services/CooperativeUnitService";
import {CooperativeUnitReq, CooperativeUnitRow} from "@services/dto/cooperativeUnit";

export class CooperativeUnitStore {
  cooperativeUnitDatasource: StateFullData<Page<CooperativeUnitRow>> = {}
  cooperativeUnitDetail: StateFullData<ResponseEntity<CooperativeUnitRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getCooperativeUnit(req: CooperativeUnitReq) {
    this.cooperativeUnitDatasource = {...this.cooperativeUnitDatasource, loading: true}
    CooperativeUnitService.getCooperativeUnit(req).then(rsp => {
      runInAction(() => this.cooperativeUnitDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.cooperativeUnitDatasource = {...this.cooperativeUnitDatasource, loading: false, err})
    })
  }

  getDetailById(id: string) {
    this.cooperativeUnitDetail = {...this.cooperativeUnitDetail, loading: true}
    CooperativeUnitService.getDetailById(id).then(rsp => {
      runInAction(() => this.cooperativeUnitDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.cooperativeUnitDetail = {...this.cooperativeUnitDetail, loading: false, err})
    })
  }
}

export const cooperativeUnitStore = new CooperativeUnitStore();
