import {observer} from "mobx-react";
import {allStores} from "@/stores";
import React, {useEffect, useState} from "react";
import {Divider, Pagination, Select, Space} from "antd";
import {OrganizationalRow, PageParam} from "@services/dto/organizational";
import {StatisticsItemsReq, StatisticsItemsRow} from "@services/dto/dict";
import {DictService} from "@services/DictService";
import {showErr} from "@common/utils";
import {BLACK_LIST} from "@/config";

export interface PropsType {
  disabled?: boolean
  onChange?: (value: string | string[], selectedOptions: OrganizationalRow[]) => void
  onDetailsChange?: (value: string | string[], selectedOptions: OrganizationalRow[]) => void
  placeholder?: string
  value?: string[] | string
  className?: string
  mode?: 'multiple' | 'tags'
  allowClear?: boolean
  fieldNames?: { value: string, label: string }
  statisticsItemsReq?: StatisticsItemsReq
  updateCount?: number
  isHiddenDefaultItems?: boolean
}

export const SelectPersonCharge = observer((props: PropsType) => {
  const {organizationalStore} = allStores;
  const [value, setValue] = useState<string[] | string>()
  const [datasource, setDatasource] = useState<OrganizationalRow[]>([])
  const [total, setTotal] = useState(0)
  const [items, setItems] = useState<OrganizationalRow[]>([])
  const [statisticsItems, setStatisticsItems] = useState<StatisticsItemsRow[] | OrganizationalRow[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [searchReq, setSearchReq] = useState<PageParam>({
    pageNum: 1,
    perPage: 10
  })

  /**
   *  每个组件默认写法 只需修改 'name'
   */
  const defaultItems: any[] = [
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: '空白',
      [props.fieldNames ? props.fieldNames.label : 'name']: '空白'
    },
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: 'N/A',
      [props.fieldNames ? props.fieldNames.label : 'name']: 'N/A'
    },
  ]

  /**
   * 初始化
   */
  useEffect(() => {
    if (props.statisticsItemsReq) {
      getStatisticsItems()
    } else {
      refreshData(searchReq)
    }
  }, [])

  /**
   * 更新统计
   */
  useEffect(() => {
    if (props.updateCount && props.statisticsItemsReq && props.value) {
      getStatisticsItems()
    }
  }, [props.updateCount]);

  /**
   * 办理进度数据变化
   */
  useEffect(() => {
    if (organizationalStore.organizationalSelectDatasource.data) {
      setDatasource(organizationalStore.organizationalSelectDatasource.data.items || [])
      setTotal(organizationalStore.organizationalSelectDatasource.data.total || 0)
    }
  }, [organizationalStore.organizationalSelectDatasource.data])

  useEffect(() => {
    if (organizationalStore.organizationalSelectDatasource.data && organizationalStore.organizationalDetails.data?.length) {
      const items = organizationalStore.organizationalDetails.data
        ?.filter(item => !(organizationalStore.organizationalSelectDatasource.data.items || [])
          .some(o => o.id === item._id))
        .map(item => {
          item.id = item._id
          return item
        })
      const datasource = organizationalStore.organizationalSelectDatasource.data.items || []
      setItems(items)
      if (props.onDetailsChange) {
        props.onDetailsChange(value, [
          ...datasource,
          ...items
        ])
      }
    }
  }, [organizationalStore.organizationalDetails, organizationalStore.organizationalSelectDatasource.data])

  useEffect(() => {
    setValue(props.value)
    if (typeof props.value === 'string' && !BLACK_LIST.includes(props.value)) {
      organizationalStore.getBatch([props.value])
    } else if (typeof props.value === 'object') {
      const ids = props.value.filter((v: string) => !BLACK_LIST.includes(v))
      if (ids.length) {
        organizationalStore.getBatch(ids)
      }
    }
  }, [props.value])

  const getStatisticsItems = () => {
    DictService.getStatisticsItems(props.statisticsItemsReq).then(rsp => {
      const row = JSON.parse(JSON.stringify(rsp.data || [])).map((item: StatisticsItemsRow | OrganizationalRow) => {
        (item as OrganizationalRow).name = `${(item as StatisticsItemsRow).value}(${(item as StatisticsItemsRow).coun})`
        return item
      })
      setStatisticsItems(row);
    }).catch(showErr).finally(() => {
      setLoading(false)
    })
  }

  const onChange = (newValue: string) => {
    setValue(newValue);
    if (props.onChange) {
      props.onChange(newValue, [
        ...datasource,
        ...items,
      ]);
    }
  }

  const refreshData = (req: PageParam) => {
    organizationalStore.getSelectOrganizational(req)
  }

  const handleSearch = (inputValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = inputValue
    setSearchReq(req)
    refreshData(req)
    return true
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    const req = {...searchReq}
    req.pageNum = page
    req.perPage = pageSize
    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      {
        props.statisticsItemsReq ? (
          <Select
            allowClear={props.allowClear}
            showSearch
            value={value}
            disabled={props.disabled}
            className={props.className}
            onChange={onChange}
            loading={loading}
            placeholder={props.placeholder || "请选择"}
            optionFilterProp="children"
            filterOption={(input: string, option) =>
              String(option?.name).toLowerCase().includes(input.toLowerCase())}
            fieldNames={props.fieldNames || {value: 'id', label: 'name'}}
            options={statisticsItems as OrganizationalRow[]}
          />
        ) : (
          <Select
            allowClear={props.allowClear}
            showSearch
            value={value}
            disabled={props.disabled}
            className={props.className}
            dropdownRender={(menu) => (
              <>
                { menu }
                <Divider className={"divider-margin"}/>
                <Space>
                  <Pagination
                    total={total}
                    pageSize={searchReq.perPage}
                    size={"small"}
                    onChange={handlePaginationChange}/>
                </Space>
              </>
            )}
            onChange={onChange}
            onClear={() => handleSearch('')}
            filterOption={false}
            onSearch={handleSearch}
            mode={props.mode}
            loading={organizationalStore.organizationalDatasource.loading}
            placeholder={props.placeholder || "请选择"}
            optionFilterProp="children"
            fieldNames={props.fieldNames || {value: 'id', label: 'name'}}
            options={[
              ...(props.isHiddenDefaultItems ? [] : defaultItems),
              ...datasource,
              ...items,
            ]}
          />
        )
      }
    </>
  )
})
