import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {CommercialContractReq, CommercialContractRow} from "@services/dto/commercialContract";

export class CommercialContractService {
  static async getCommercialContract(req: CommercialContractReq): Promise<Page<CommercialContractRow>> {
    return await apiV1.get("/", {params: req})
  }

  static async addCommercialContract(row: CommercialContractRow): Promise<ResponseEntity<CommercialContractRow>> {
    return await apiV1.post("/", {...row})
  }

  static async updateCommercialContract(row: CommercialContractRow): Promise<ResponseEntity<CommercialContractRow>> {
    return await apiV1.put("/", {...row})
  }

  static async deleteCommercialContract(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/", {data: ids})
  }
}
