import React from "react";
import {observer} from "mobx-react";
import {Button, message, Upload} from "antd";
import {useUploadProps} from "@common/utils";
import {UploadChangeParam} from "antd/es/upload/interface";
import {previewFile} from "@components/CustomUpload";
import {LoginOutlined} from "@ant-design/icons";

export interface PropsType {
  callback?: () => void
  url: string
  title?: string
}

export const CustomImport = observer((props: PropsType) => {

  const uploadChange = (info: UploadChangeParam) => {
    if (info.file.status === 'done') {
      if (info.file.response.data) {
        message.success("文件个别导入失败, 失败列表").then();
        previewFile(info.file.response.data)
      } else {
        message.success(`${info.file.name} 文件导入成功`).then();
      }
      if (props.callback) {
        props.callback()
      }
    }
  }

  return <>
    <Upload {...useUploadProps(props.url, uploadChange)} style={{display: 'block', width: '100%'}}>
      <Button
        block
        className={'text-align-left'}
        type={"text"}>
        <LoginOutlined/>
        { props.title || '一键导入' }
      </Button>
    </Upload>
  </>
})
