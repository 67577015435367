import './index.less'
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {
  Button,
  Col,
  Divider,
  Dropdown,
  FormInstance,
  MenuProps,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Space
} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {Any} from "@common/types";
import {InvoiceRegisterReq, InvoiceRegisterRow} from "@services/dto/invoiceRegister";
import {CloseOutlined, EditOutlined, FolderViewOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {StatisticsItemsReq, StatisticsItemsRow} from "@services/dto/dict";
import {DictService} from "@services/DictService";
import {getUploadArrayToString, getUploadIds, logger, showErr} from "@common/utils";
import {previewFile} from "@components/CustomUpload";
import {LabeledValue} from "antd/es/select";
import {InvoiceRegisterService} from "@services/InvoiceRegisterService";
import {BLACK_LIST, DATE_FMT, DEFAULT_MODAL_WIDTH, LARGER_MODAL_WIDTH, READY} from "@/config";
import {InvoiceRegister} from "@modules/InvoiceRegister";
import {SelectRowKeyService} from "@modules/InvoiceRegister/Context/ModalSelectContext";
import {BasicInfo} from "@modules/InvoiceRegister/Components/BasicInfo";
import {BankAccountService} from "@services/BankAccountService";
import {ResponseEntity} from "@services/dto/common";
import {ToolService} from "@services/ToolService";
import dayjs from "dayjs";
import {CustomDraggableModal} from "@components/CustomDraggableModal";

export interface PropsType {
  modalSelect?: (selectRowKeys: React.Key[]) => void
  onChange?: (value: Any | null, options: InvoiceRegisterRow[]) => void
  value?: Any | null
  mode?: 'multiple' | 'tags'
  labelInValue?: boolean
  disabled?: boolean
  className?: string
  allowClear?: boolean
  placeholder?: string
  isHiddenOption?: boolean
  isShowAction?: boolean
  isSimpleAction?: boolean
  fieldNames?: { value: string, label: string }
  statisticsItemsReq?: StatisticsItemsReq
  updateCount?: number
  onDeselect?: (value: InvoiceRegisterRow) => void
  onSelect?: (value: string | number | LabeledValue, option: InvoiceRegisterRow) => void
  isModalSelect?: boolean
  modalTitle?: string
  taCodeIsNull?: string
  notInIds?: string
  inIds?: string
  outOrInIsNull?: string
  selectDisabled?: boolean
  irPurchaser?: string  //购买方
  irSelle?: string  //销售方
  handleButtonClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  addSelectInvoiceRegister?: (invoiceRegistration?: InvoiceRegisterRow) => void
  isView?: boolean
  irType?: string // 发票类型
}

export const SelectInvoiceRegister = observer((props: PropsType) => {
  const invoiceRegisterRef = useRef<SelectRowKeyService>()
  const {invoiceRegisterStore, securityStore} = allStores
  const [value, setValue] = useState<Any | null>()
  const [dataSource, setDataSource] = useState<InvoiceRegisterRow[]>([])
  const [total, setTotal] = useState<number>(0)
  const [items, setItems] = useState<InvoiceRegisterRow[]>([])
  const [statisticsItems, setStatisticsItems] = useState<StatisticsItemsRow[] | InvoiceRegisterRow[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalFormOpen, setIsModalFormOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [isView, setIsView] = useState<boolean>(false)
  const [invoiceRegisterRow, setInvoiceRegisterRow] = useState<InvoiceRegisterRow>()
  const basicInfo = React.createRef<FormInstance>();
  const [searchReq, setSearchReq] = useState<InvoiceRegisterReq>({
    pageNum: 1,
    perPage: 10,
  })

  /**
   *  每个组件默认写法 只需修改 'irInvoiceCode'
   */
  const defaultItems: any[] = [
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: '',
      [props.fieldNames ? props.fieldNames.label : 'irInvoiceCode']: '空白'
    },
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: 'N/A',
      [props.fieldNames ? props.fieldNames.label : 'irInvoiceCode']: 'N/A'
    },
  ]

  /**
   * 初始化
   */
  useEffect(() => {
    if (props.statisticsItemsReq) {
      getStatisticsItems()
    } else {
      refreshData(searchReq)
    }
  }, [])

  /**
   * 更新统计
   */
  useEffect(() => {
    if (props.updateCount && props.statisticsItemsReq && props.value) {
      getStatisticsItems()
    }
  }, [props.updateCount]);

  /**
   * 办理进度数据变化
   */
  useEffect(() => {
    if (invoiceRegisterStore.invoiceRegisterSelectDatasource.data) {
      setDataSource(invoiceRegisterStore.invoiceRegisterSelectDatasource.data.items || [])
      setTotal(invoiceRegisterStore.invoiceRegisterSelectDatasource.data.total || 0)
      if (invoiceRegisterStore.invoiceRegisterDetails.data?.data.length) {
        setItems(invoiceRegisterStore.invoiceRegisterDetails.data?.data
          .filter(item => !(invoiceRegisterStore.invoiceRegisterSelectDatasource.data.items || [])
            .some(o => o.id === item.id)))
      }
    }
  }, [invoiceRegisterStore.invoiceRegisterDetails, invoiceRegisterStore.invoiceRegisterSelectDatasource])

  /**
   * 值发生改变
   */
  useEffect(() => {
    setValue(props.value)
    if (typeof props.value === 'string' && !BLACK_LIST.includes(props.value)) {
      invoiceRegisterStore.getBatchDetailById([props.value])
    } else if (typeof props.value === 'object') {
      const ids = props.value.filter((v: string) => !BLACK_LIST.includes(v))
      if (ids.length) {
        invoiceRegisterStore.getBatchDetailById(ids)
      }
    }
  }, [props.value])

  const getStatisticsItems = () => {
    setLoading(true)
    DictService.getStatisticsItems(props.statisticsItemsReq).then(rsp => {
      const row = JSON.parse(JSON.stringify(rsp.data || [])).map((item: StatisticsItemsRow | InvoiceRegisterRow) => {
        (item as InvoiceRegisterRow).irInvoiceCode = `${(item as InvoiceRegisterRow).irInvoiceCode}(${(item as StatisticsItemsRow).coun})`
        return item
      })
      setStatisticsItems(row);
    }).catch(showErr).finally(() => {
      setLoading(false)
    })
  }

  const refreshData = (req: InvoiceRegisterReq) => {
    invoiceRegisterStore.getSelectInvoiceRegister(req)
  }

  const onChange = (value: any | null) => {
    setValue(value)
    if (props.onChange) {
      props.onChange(value, [
        ...dataSource,
        ...items,
      ]);
    }
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    const req = {...searchReq}
    req.pageNum = page
    req.perPage = pageSize
    setSearchReq(req)
    refreshData(req)
  }

  const handleSearch = (inputValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = inputValue
    setSearchReq(req)
    refreshData(req)
    return true
  }

  /**
   * 注意事项：
   * 1. 下拉选择发票登记可能多选
   * 2. 一条发票登记记录的irFileList也是多选
   * 3. 值可能为空项
   */
  const handleViewFile = () => {
    const data = [...items, ...dataSource].filter(o => typeof value === 'string' ? o.id === value : value.includes(o.id)).map(o => o.irFileList)
    if (data.length) {
      const urls = data
        .join(';')
        .split(';')
        .filter(o => o) // ! 这一步是去除空的项
        .join(';')
      previewFile(urls);
    } else {
      message.warning('没有文件')
    }
  }

  /**
   * FIXME：目前编写的代码只对String类型的值作出了解析
   * @param value
   */
  const onDeselect = (value: string | number | LabeledValue) => {
    if (typeof value === 'string') {
      InvoiceRegisterService.getDetailById(value).then(rsp => {
        if (props.onDeselect) {
          props.onDeselect(rsp.data);
        }
      })
    }
  }

  const onSelect = (value: string | number | LabeledValue, option: InvoiceRegisterRow) => {
    if (props.onSelect) {
      props.onSelect(value, option);
    }
  }

  const handleModalSelect = () => {
    if (invoiceRegisterRef.current) {
      invoiceRegisterRef.current.setSelectedRowKeys(props.value || []);
    }
    setIsModalOpen(true)
  }

  const handleOk = () => {
    let selectRowKeys = []
    if (invoiceRegisterRef.current) {
      selectRowKeys = invoiceRegisterRef.current.getSelectedRowKeys();
    }
    if (props.modalSelect) {
      props.modalSelect(selectRowKeys)
    }
    setIsModalOpen(false);
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const selectNode = props.statisticsItemsReq ? (
    <Select
      allowClear={props.allowClear}
      placeholder={props.placeholder || "请选择发票登记"}
      className={`${props.className} width-100-percentage`}
      value={value}
      onChange={onChange}
      showSearch
      onDeselect={onDeselect}
      onSelect={onSelect}
      optionFilterProp="children"
      filterOption={(input: string, option) =>
        String(option?.irInvoiceCode).toLowerCase().includes(input.toLowerCase())}
      autoClearSearchValue
      disabled={props.disabled}
      mode={props.mode}
      loading={loading}
      labelInValue={props.labelInValue}
      fieldNames={props.fieldNames || {value: "id", label: "irInvoiceCode"}}
      options={statisticsItems as InvoiceRegisterRow[]}/>
  ) : (
    <Select
      allowClear={props.allowClear}
      onDeselect={onDeselect}
      onSelect={onSelect}
      placeholder={props.placeholder || "请选择发票登记"}
      className={`${props.className} width-100-percentage`}
      dropdownRender={(menu) => (
        <>
          { menu }
          <Divider className={"divider-margin"}/>
          <Space>
            <Pagination
              total={total}
              pageSize={searchReq.perPage}
              size={"small"}
              onChange={handlePaginationChange}/>
          </Space>
        </>
      )}
      value={value}
      onChange={onChange}
      showSearch
      autoClearSearchValue
      disabled={props.disabled}
      mode={props.mode}
      onClear={() => handleSearch('')}
      loading={invoiceRegisterStore.invoiceRegisterDatasource.loading}
      labelInValue={props.labelInValue}
      filterOption={false}
      onSearch={handleSearch}
      fieldNames={props.fieldNames || {value: "id", label: "irInvoiceCode"}}
      options={[
        ...defaultItems,
        ...dataSource,
        ...items,
      ]}/>
  )

  const handleEdit = () => {
    const record = [
      ...dataSource,
      ...items,
    ].find(item => item.id === value)
    setInvoiceRegisterRow(record)
    setIsModalFormOpen(true);
    setIsView(false)
  }

  const handlePlus = () => {
    const user = securityStore.getLoggedUser()
    setInvoiceRegisterRow({
      irStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      irDate: dayjs(),
    } as InvoiceRegisterRow)
    setIsModalFormOpen(true);
    setIsView(false)
  }

  const handleClose = () => {
    const record = [
      ...dataSource,
      ...items,
    ].find(item => item.id === value)
    if (!record) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        BankAccountService.deleteBankAccount([record.id]).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(searchReq)
          onChange(null)
          if (props.addSelectInvoiceRegister) {
            props.addSelectInvoiceRegister()
          }
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handleView = () => {
    const record = [
      ...dataSource,
      ...items,
    ].find(item => item.id === value)
    setInvoiceRegisterRow(record)
    setIsModalFormOpen(true);
    setIsView(true)
  }

  const menuProps: MenuProps = {
    items: [
      {
        label: '',
        key: 'edit',
        icon: <EditOutlined/>,
        style: {display: (!value || props.disabled) ? 'none' : '', color: '#ffec3d'},
        onClick: () => handleEdit()
      },
      {
        label: '',
        key: 'plus',
        icon: <PlusOutlined/>,
        style: {color: '#73d13d'},
        onClick: () => handlePlus()
      },
      {
        label: '',
        key: 'close',
        style: {display: (!value || props.disabled) ? 'none' : '', color: '#ff4d4f'},
        icon: <CloseOutlined/>,
        onClick: () => handleClose()
      },
      {
        label: '',
        key: 'view',
        style: {display: !value ? 'none' : '', color: '#4096ff'},
        icon: <FolderViewOutlined/>,
        onClick: () => handleView()
      },
    ]
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.handleButtonClick) {
      props.handleButtonClick(e)
    }
  };

  const handleFormCancel = () => {
    setIsModalFormOpen(false)
  }

  const handleFormOk = () => {
    const row = {...invoiceRegisterRow!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(row, values)
      if (values.irDate) {
        row.irDate = values.irDate.format(DATE_FMT)
      }
      let ids = ""
      if (typeof row.irFileList === 'object') {
        ids = getUploadIds(row.irFileList)
        row.irFileList = getUploadArrayToString(row.irFileList)
      }
      if (values.contractListIds && typeof values.contractListIds === 'string') {
        row.contractListIds = values.contractListIds.split(',');
      }
      if (typeof values.cooperationUnit === 'object') {
        row.cooperationUnit = values.cooperationUnit.join(',')
      }
      if (typeof values.irContractNoId === 'object') {
        row.irContractNoId = values.irContractNoId.join(',')
      }
      if (values.inputAccountingId) {
        row.inputAccountingId = values.inputAccountingId.join(",")
      }
      setConfirmLoading(true);
      if (row.id) {
        InvoiceRegisterService.updateInvoiceRegister(row).then(rsp => {
          actionThen(ids, rsp).catch();
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        InvoiceRegisterService.addInvoiceRegister(row).then(rsp => {
          if (props.addSelectInvoiceRegister) {
            props.addSelectInvoiceRegister(rsp.data)
          }
          actionThen(ids, rsp).catch();
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  }

  const actionThen = async (ids: string, rsp: ResponseEntity<InvoiceRegisterRow>) => {
    // 图片上传跟表单绑定
    try {
      await ToolService.submittedSuccess(ids, rsp.data.id)
      message.success("操作成功！")
      setIsModalFormOpen(false)
    } catch (e) {
      showErr(e)
    } finally {
      refreshData(searchReq)
    }
  }

  return (
    <>
      <Row>
        <Col span={!props.isHiddenOption || props.isModalSelect || props.isShowAction ? 18 : 24}>
          { selectNode }
        </Col>
        {
          !props.isHiddenOption && <Col span={6} className={'col-button'}>
            <Button type={'text'} disabled={!value} onClick={handleViewFile}>
              <FolderViewOutlined/>
              查看
            </Button>
          </Col>
        }
        {
          props.isModalSelect && <Col span={6} className={'col-button'}>
            <Button type={'text'} disabled={!props.isModalSelect || props.selectDisabled} onClick={handleModalSelect}>
              <PlusOutlined/>
              选择
            </Button>
          </Col>
        }
        {
          props.isShowAction && <Col span={6}>
            <div
              className={`d-flex df-aic df-jcsa height-100-percentage ${props.isSimpleAction ? 'margin-left-xs' : ''}`}>
              {
                props.isSimpleAction ?
                  <>
                    <Dropdown.Button size={"small"} menu={menuProps} onClick={handleButtonClick}>
                      <MinusCircleOutlined/>
                    </Dropdown.Button>
                  </>
                  :
                  <>
                    <Button
                      size={'small'} type={'link'} className={'btn-edit'}
                      disabled={!value || (props.disabled && props.isView)}
                      onClick={handleEdit}><EditOutlined/></Button>
                    <Button
                      size={'small'} type={'link'} className={'btn-plus'}
                      onClick={handlePlus}><PlusOutlined/></Button>
                    <Button
                      size={'small'} type={'link'} className={'btn-close'}
                      disabled={!value || (props.disabled && props.isView)}
                      onClick={handleClose}><CloseOutlined/></Button>
                    <Button
                      size={'small'} type={'link'} className={'btn-view'} disabled={!value}
                      onClick={handleView}><FolderViewOutlined/></Button>
                  </>
              }
            </div>
          </Col>
        }
      </Row>

      { /*表单组件*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">发票登记</div>}
        open={isModalFormOpen}
        width={DEFAULT_MODAL_WIDTH}
        onOk={handleFormOk}
        confirmLoading={confirmLoading}
        onCancel={handleFormCancel}
        footer={<>
          <Button onClick={handleFormCancel}>取消</Button>
          {
            !isView && <Button onClick={handleFormOk} type={'primary'}>确定</Button>
          }
        </>}>
        <BasicInfo row={{...invoiceRegisterRow!}} ref={basicInfo} isView={isView}/>
      </CustomDraggableModal>

      { /*表格组件*/ }
      <Modal
        title={<div className="text-align-center">{ props.modalTitle }</div>}
        open={isModalOpen}
        width={LARGER_MODAL_WIDTH}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={<>
          <Button onClick={handleCancel}>取消</Button>
          <Button onClick={handleOk} type={'primary'}>确定</Button>
        </>}>

        <InvoiceRegister
          isModalSelect
          locationPathname={'/invoiceRegister'}
          ref={invoiceRegisterRef}
          rowKeys={props.value}
          notInIds={props.notInIds}
          irPurchaser={props.irPurchaser}
          irSelle={props.irSelle}
          irType={props.irType}
          outOrInIsNull={props.outOrInIsNull}
          inIds={props.inIds}/>
      </Modal>
    </>
  )
})
