import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {FormInstance, message, Modal, Space, Table, Typography} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {accAdd, logger, parseURLParams, safeCompute, showErr} from "@common/utils";
import {HeaderActionCom} from "@modules/FundApproval/Components/HeaderActionCom";
import {FundApprovalReq, FundApprovalRow, FundApprovalSummaryTotal} from "@services/dto/fundApproval";
import {FundApprovalService} from "@services/FundApprovalService";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import {LabelValue, ResponseEntity} from "@services/dto/common";
import {WindowPrint} from "@modules/WindowPrint";
import {useLocation, useNavigate} from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FieldTimeOutlined,
  MergeCellsOutlined,
  PlusOutlined,
  PrinterOutlined,
  RetweetOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import {BankStatementTableModal} from "@modules/FundApproval/Components/BankStatementTableModal";
import {BankStatementService} from "@services/BankStatementService";
import {BankAccountService} from "@services/BankAccountService";
import {BasicInfo as ProcessRecordsBasicInfo} from "@modules/ProcessRecords/Components/BasicInfo";
import {BankStatementRow} from "@services/dto/bankStatement";
import {ProjectService} from "@services/ProjectService";
import {BankStatementModal} from "@modules/FundApproval/Components/BankStatementModal";
import {MergeFundApprovalModal} from "@modules/FundApproval/Components/MergeFundApprovalModal";
import {BasicInfo} from "@modules/FundApproval/Components/BasicInfo";
import {getApplicationFrom, TableApplicationFormText} from "@components/TableApplicationFormText";
import {TableFilingText} from "@components/TableFilingText";
import {TaxAccountingRow} from "@services/dto/taxAccounting";
import {TaxAccountingService} from "@services/TaxAccountingService";
import {
  APPROVE_FORM_TRANS_STATE,
  BLACK_LIST,
  DATE_FMT,
  DATE_SECONDS_FMT,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  FORM_ALTERNATE_TEXT,
  FORM_CLOSE_TEXT,
  FORM_COMMIT_TEXT,
  FORM_DISABLED_STATE,
  FORM_HANDOVER_TEXT,
  FORM_INITIATE_STATE,
  FORM_PERSON_RESPONSIBLE_WHITELIST,
  FORM_RETURN_TEXT,
  FORM_REVOCATION_TEXT,
  LARGE_MODAL_WIDTH,
  LARGE_MODAL_WIDTH_OTHER,
  READY,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import {CustomDraggableModal} from "@components/CustomDraggableModal";
import {ProcessRecordsRow} from "@services/dto/processRecords";
import {TableAuthOptions} from "@components/CustomAuthOptions";
import {ProcessRecordsService} from "@services/ProcessRecordsService";
import {ProcessRecords} from "@modules/ProcessRecords";
import {CustomForm} from "@components/CustomForm";
import {dataConversionContractSettlement, insertOrUpdateContractSettlement} from "@modules/ContractSettlement";
import {CostReimbursementService} from "@services/CostReimbursementService";
import {PayrollService} from "@services/PayrollService";
import {FinalSettlementService} from "@services/FinalSettlementService";
import {staffLoanService} from "@services/StaffLoanService";
import {BaseEntity} from "@services/dto/BaseEntity";

const blackColumnList = [
  'faApplicationFormType',
  'faApplicationForm',
  'bankStatementCodes',
  'action',
]

const wideShowColumn: string[] = [
  // 'sort',
  'faDate',
  'faCode',
  'faType',
  // 'faSummary',
  'faAmount-summation',
  // 'faPaymentAccountId',
  // 'faAccountsReceivableId',
  // 'faApplicationFormType2',
  // 'faApplicationForm2',
  // 'bankStatementCodes',
  // 'personResponsibleName',
  // 'responsibilityProject',
  // 'cooperationUnit',
  'faFile',
  'faPayDate',
  'bsAmountRealized-summation',
  'bsAmount-summation',
  'faStatus',
  'action',
]

const narrowShowColumn: string[] = [
  // 'sort',
  'faDate',
  'faCode',
  'faType',
  // 'faSummary',
  // 'faAmount-summation',
  // 'faPaymentAccountId',
  // 'faAccountsReceivableId',
  // 'faApplicationFormType2',
  // 'faApplicationForm2',
  // 'bankStatementCodes',
  // 'personResponsibleName',
  // 'responsibilityProject',
  // 'cooperationUnit',
  'faFile',
  'faPayDate',
  // 'bsAmountRealized-summation',
  // 'bsAmount-summation',
  'faStatus',
  'action',
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: FundApprovalRow[] // 数据源
  setFundApprovalSelectedRowKeys?: (key: React.Key[]) => void // 所选行
}

export const TableName = 'fundingApproval'

export const FundApproval = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    processStore,
    securityStore,
    taxAccountingStore,
    contractSettlementStore,
    bankStatementStore,
    fundApprovalStore,
    screenResolutionStore,
    tableColumnsStore
  } = allStores
  const basicInfo = React.createRef<FormInstance>();
  const processRecordsBasicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<FundApprovalRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBatchUpdate, setIsBatchUpdate] = useState(false);
  const [fundApprovalRow, setFundApprovalRow] = useState<FundApprovalRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [printTitle, setPrintTitle] = useState<string>('')
  const [isPrintTable, setIsPrintTable] = useState<boolean>(false)
  const [printDataSource, setPrintDataSource] = useState<FundApprovalRow[]>([])
  const [contentList, setContentList] = useState<LabelValue[]>([]);
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<FundApprovalRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [BankStatementTableModalOpen, setBankStatementTableModalOpen] = useState<boolean>(false)
  const [FundingApprovalId, setFundingApprovalId] = useState<string>('')
  const [BankStatementModalOpen, setBankStatementModalOpen] = useState<boolean>(false)
  const [bankStatementModalDataSource, setBankStatementModalDataSource] = useState<BankStatementRow[]>([])
  const [MergeFundApprovalModalOpen, setMergeFundApprovalModalOpen] = useState<boolean>(false)
  const [mergeFundApprovalModalDataSource, setMergeFundApprovalModalDataSource] = useState<FundApprovalRow[]>([])
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [bankStatementModalFundApprovalId, setBankStatementModalFundApprovalId] = useState<string>('')
  const [processRecordsIsOpen, setProcessRecordsIsOpen] = useState<boolean>(false)
  const [processRecordsRow, setProcessRecordsRow] = useState<ProcessRecordsRow>()
  const [processRecordsOpen, setProcessRecordsOpen] = useState<boolean>(false)
  const [processRecordsDataSource, setProcessRecordsDataSource] = useState<ProcessRecordsRow[]>([])
  const [submitRow, setSubmitRow] = useState<FundApprovalRow>()
  const [submitterId, setSubmitterId] = useState<string>()
  const [latestProcess, setLatestProcess] = useState<string>()
  const [latestProcessBatch, setLatestProcessBatch] = useState<string[]>()
  const [summaryTotalObj, setSummaryTotalObj] = useState<FundApprovalSummaryTotal>({
    faAmount: 0,
    bsAmountRealized: 0,
    bsAmount: 0
  })
  const [searchReq, setSearchReq] = useState<FundApprovalReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...fundApprovalStore.fundApprovalReq
  })

  const columns: ColumnsType<FundApprovalRow> = [
    {
      title: '排序',
      dataIndex: 'sort',
      key: 'sort',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单日期',
      dataIndex: 'faDate',
      key: 'faDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'faCode',
      key: 'faCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '款项类别',
      dataIndex: 'faType',
      key: 'faType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '款项摘要',
      dataIndex: 'faSummary',
      key: 'faSummary',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '申请金额(元)',
      dataIndex: 'faAmount',
      key: 'faAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '付款账户',
      dataIndex: 'faPaymentAccount',
      key: 'faPaymentAccount',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '收款账户',
      dataIndex: 'faAccountsReceivable',
      key: 'faAccountsReceivable',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联计划编号',
      dataIndex: 'planCode',
      key: 'planCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText
            value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联申请类型',
      dataIndex: 'faApplicationFormType',
      key: 'faApplicationFormType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (_, record) => (
        <Space>
          <TableEllipsisText
            value={record.fundingApprovalTables?.length ? record.fundingApprovalTables[0].type : ''}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联申请编号',
      dataIndex: 'faApplicationForm',
      key: 'faApplicationForm',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableApplicationFormText
            type={record.fundingApprovalTables?.length ? record.fundingApprovalTables[0].type : ''}
            value={record.fundingApprovalTables?.map(o => o.businessId)}/>
        </Space>
      )
    },
    {
      title: '关联资金出纳',
      dataIndex: 'bankStatementCodes',
      key: 'bankStatementCodes',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text, record) => (
        <Space>
          <TableEllipsisText copyValue={text} value={
            <Typography.Text
              type="success"
              className={'c-pointer'}
              onClick={() => {
                handleBankStatementCodeClick(record).then()
              }}>{ text }</Typography.Text>
          }></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'faFileCode',
      key: 'faFileCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableFilingText id={record.faFile} value={record.faFileCode}></TableFilingText>
        </Space>
      )
    },
    {
      title: '实现日期',
      dataIndex: 'faPayDate',
      key: 'faPayDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text?.slice(0, 10)}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '实现金额(元)',
      dataIndex: 'bsAmountRealized',
      key: 'bsAmountRealized-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '余额',
      dataIndex: 'bsAmount',
      key: 'bsAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '办理人员',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '主办人员',
      dataIndex: 'organizersUserName',
      key: 'organizersUserName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'faStatus',
      key: 'faStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableAuthOptions
          mainBtn={['handleEdit', 'handleView']}
          items={[
            {
              key: 'handleView',
              icon: <EllipsisOutlined/>,
              onClick: () => handleView(record)
            },
            {
              key: 'handleEdit',
              icon: <EditOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.faStatus),
              onClick: async () => await handleEdit(record)
            },
            {
              key: 'handlePrint',
              icon: <PrinterOutlined/>,
              onClick: () => handlePrint(record)
            },
            {
              key: 'handleDeleteBatch',
              icon: <DeleteOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.faStatus),
              onClick: () => handleDeleteBatch(record)
            },
          ]}/>
      )
    }
  ];

  /**
   * @description 初始化
   */
  useEffect(() => {

    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  /**
   * @description 全局查询
   */
  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  /**
   * @description 表格数据变化
   */
  useEffect(() => {
    if (fundApprovalStore.fundApprovalDatasource.data) {
      setDataSource(fundApprovalStore.fundApprovalDatasource.data.items || []);
      setTotal(fundApprovalStore.fundApprovalDatasource.data.total || 0)
    }
  }, [fundApprovalStore.fundApprovalDatasource.data])

  /**
   * @description 表单数据变化
   */
  useEffect(() => {
    if (fundApprovalStore.fundApprovalRow?.fundApprovalId) {
      handleAdd(fundApprovalStore.fundApprovalRow)
      fundApprovalStore.updateFundApprovalRow({
        ...fundApprovalStore.fundApprovalRow,
        fundApprovalId: undefined,
      })
    }
  }, [fundApprovalStore.fundApprovalRow])

  useEffect(() => {
    if (fundApprovalStore.fundApprovalOption) {
      const [option, paramsStr] = fundApprovalStore.fundApprovalOption.split("?")
      if (option === 'add') {
        handleAdd()
      } else if (paramsStr) {
        const params = parseURLParams(paramsStr)
        if (option === 'edit') {
          FundApprovalService.getOne(params.id).then(rsp => {
            if (rsp.code === 200) {
              handleEdit(rsp.data).then()
            }
          })
        }
      }
      fundApprovalStore.updateFundApprovalOption(undefined)
    }
  }, [fundApprovalStore.fundApprovalOption])

  /**
   * @description 表格合计
   */
  useEffect(() => {
    const objInitValue = {
      faAmount: 0,
      bsAmountRealized: 0,
      bsAmount: 0
    }
    let obj = summaryTotalObj
    const datasource = props.isModalSelect ? props.dataSource : fundApprovalStore.fundApprovalDatasource.data?.items
    const arr = datasource?.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        faAmount: accAdd(acc.faAmount, cur.faAmount),
        bsAmountRealized: accAdd(acc.bsAmountRealized, cur.bsAmountRealized),
        bsAmount: accAdd(acc.bsAmount, cur.bsAmount),
      }), objInitValue);
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [fundApprovalStore.fundApprovalDatasource, selectedRowKeys])

  /**
   * 列配置变化
   */
  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => !blackColumnList.includes(o.key as string)).filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, fundApprovalStore.collapsed, searchReq.pageNum, searchReq.perPage])

  useEffect(() => {
    if (props.setFundApprovalSelectedRowKeys) {
      props.setFundApprovalSelectedRowKeys(selectedRowKeys)
    }
  }, [selectedRowKeys]);

  /**
   * 批量出纳
   */
  const handleBulkTellerClick = (record: FundApprovalRow) => {
    setFundingApprovalId(record.id)
    setBankStatementTableModalOpen(true)
  }

  /**
   * 资金出纳编号详情 请求出纳数据并打开弹窗
   * @param record
   */
  const handleBankStatementCodeClick = async (record: FundApprovalRow) => {

    // 需要解绑的资金审批id
    setBankStatementModalFundApprovalId(record.id)

    // 转换资金出纳id
    let ids: string[] = (typeof record.bankStatementIds === 'string' && record.bankStatementIds !== '') ? record.bankStatementIds.split(',') : []
    // 去重并且过滤黑名单
    ids = ids.filter((v, i, self) => self.indexOf(v) === i)
      .filter(o => !BLACK_LIST.includes(o)) || []
    if (!ids.length) {
      message.warning("请检查审批编号！").then()
      return
    }

    // 请求资金出纳的详情
    const bsRsp = await BankStatementService.getBatch(ids)
    const dataSource: BankStatementRow[] = bsRsp.data
    if (bsRsp.code !== 200 || !dataSource) {
      message.warning("操作失败！").then()
      return
    }
    setBankStatementModalDataSource(dataSource)
    setBankStatementModalOpen(true)

  }

  /**
   * 关联资金审批时 自动填充
   * 1. 责任人id
   * 2. 责任人名字
   * 3. 责任部门
   * 4. 协作单位
   * 5. 存档批号
   * 这些任何时候都禁止填写
   * @param valueArr
   * @param options
   */
  const handleFundingApprovalIdChange = (valueArr: string[] | string, options: FundApprovalRow[]) => {
    if (typeof valueArr === 'object') {
      const BLACK_LIST = ['N/A', '容缺']
      if (!BLACK_LIST.every(v => !valueArr.includes(v))) {
        const res = BLACK_LIST.find(v => valueArr.includes(v))
        return {
          fundingApprovalId: [res],
          fundingApprovalCode: [res],
          responsibilityProject: [res],
        }
      }
      const rows = options.filter(item => valueArr.includes(item.id))
      return {
        fundingApprovalCode: handleParameterConversion(rows, 'faCode'),
        responsibilityProject: handleParameterConversion(rows, 'responsibilityProject'),
      }
    }
  }

  /**
   * 数据转换
   * @param rows
   * @param key
   */
  const handleParameterConversion = (rows: FundApprovalRow[], key: string): string[] => {
    return rows?.map(o => o[key])
      .join(',')
      .split(',')
      .filter((v, i, self) => self.indexOf(v) === i)
      .filter(o => !BLACK_LIST.includes(o)) || []
  }

  /**
   * 根据所选资金出纳 资金出纳解绑资金审批 资金审批同步数据后刷新数据
   * 资金审批表格点击关联资金出纳编号弹窗和资金审批绑定的资金出纳数据，解绑功能，更新资金出纳的关联资金审批，后端同步资金审批，刷新数据
   * @param rows
   */
  const handleBankStatementModalOk = (rows: BankStatementRow[]) => {
    // logger.log(rows, bankStatementModalFundApprovalId)

    setConfirmLoading(true)

    Modal.confirm({
      title: "是否批量操作-资金出纳解绑资金审批？",
      okText: "确定",
      cancelText: "取消",
      onCancel: () => setConfirmLoading(false),
      onOk: async () => {

        // 响应体列表
        let resList = []

        for (let i = 0; i < rows.length; i++) {

          message.loading(`请稍等！${i + 1}/${rows.length}`).then()

          const bsRecord = rows[i]

          // 操作数据 转换成数组
          bsRecord.fundingApprovalId = typeof bsRecord.fundingApprovalId === 'string' && bsRecord.fundingApprovalId !== '' ? bsRecord.fundingApprovalId.split(',') : []
          bsRecord.fundingApprovalCode = typeof bsRecord.fundingApprovalCode === 'string' && bsRecord.fundingApprovalCode !== '' ? bsRecord.fundingApprovalCode.split(',') : []
          bsRecord.responsibilityProject = typeof bsRecord.responsibilityProject === 'string' && bsRecord.responsibilityProject !== '' ? bsRecord.responsibilityProject.split(',') : []
          bsRecord.cooperationUnit = typeof bsRecord.cooperationUnit === 'string' && bsRecord.cooperationUnit !== '' ? bsRecord.cooperationUnit.split(',') : []

          // 过滤掉需要解绑的审批id
          bsRecord.fundingApprovalId = bsRecord.fundingApprovalId.filter(value => value !== bankStatementModalFundApprovalId)

          // 批量请求资金审批详情
          let faDetails: FundApprovalRow[] = []
          if (bsRecord.fundingApprovalId.length) {
            const faRsp = await FundApprovalService.getBatch(bsRecord.fundingApprovalId)
            faDetails = faRsp.data
            if (faRsp.code !== 200) {
              resList.push('操作失败-批量请求资金审批详情！')
              continue
            }
          }

          const {
            fundingApprovalCode,
            responsibilityProject,
          } = handleFundingApprovalIdChange(bsRecord.fundingApprovalId, faDetails)

          bsRecord.fundingApprovalCode = fundingApprovalCode
          bsRecord.responsibilityProject = responsibilityProject

          // 转换为字符串
          bsRecord.fundingApprovalId = bsRecord.fundingApprovalId.join(',')
          bsRecord.fundingApprovalCode = bsRecord.fundingApprovalCode.join(',')
          bsRecord.responsibilityProject = bsRecord.responsibilityProject.join(',')

          if (typeof bsRecord.fundingApprovalId === 'object' || typeof bsRecord.fundingApprovalCode === 'object' || typeof bsRecord.cooperationUnit === 'object') {
            resList.push('数据转换失败-转字符串！')
            continue
          }

          // 请求更新接口-更新资金出纳

          const rsp = await BankStatementService.updateBankStatement(bsRecord)
          if (rsp.code !== 200) {
            resList.push('操作失败-更新资金出纳！')
            continue
          }

          resList.push(rsp.message)
        }

        // 全部一起提示
        Modal.info({
          title: `温馨提示-批量操作-资金出纳解绑资金审批`,
          content: <>{ resList.map(o => <div>{ typeof o === 'string' ? o : o.message }</div>) }</>,
          okText: "确定",
          cancelText: "取消",
          onOk() {
            setSelectedRowKeys([])
            refreshData(searchReq)
            setConfirmLoading(false)
            setBankStatementModalOpen(false)
          },
        })
      }
    })

  }

  const handleBankStatementModalCancel = () => {
    setBankStatementModalOpen(false)
  }

  /**
   * 合并资金审批 拟态宽ok事件
   * @param key
   * @param type
   */
  const handleMergeFundApprovalModalOk = (key: React.Key, type: number) => {
    const ids: string[] = selectedRowKeys as string[]
    const mainBody: string = key as string
    setConfirmLoading(true)
    FundApprovalService.mergeFundApproval(ids, mainBody, type).then(rsp => {
      if (rsp.code === 200) {
        message.success("合并成功！").then()
        setConfirmLoading(false)
        const record = rsp.data
        handleView(record)
        setMergeFundApprovalModalOpen(false)
      } else {
        message.warning(rsp.message).then()
      }
    }).catch(showErr).finally(() => {
      refreshData(fundApprovalStore.fundApprovalReq)
      setConfirmLoading(false)
      setSelectedRowKeys([])
    })
  }

  /**
   * 合并资金审批 拟态宽cancel事件
   */
  const handleMergeFundApprovalModalCancel = () => {
    setMergeFundApprovalModalOpen(false)
    setSelectedRowKeys([])
  }

  const handleTaxAccountingClick = async (record: FundApprovalRow) => {

    // 获取详情
    const {code: detailCode, data: row, message: detailMessage} = await FundApprovalService.getOne(record.id)
    if (detailCode !== 200) {
      message.warning(detailMessage).then()
    }

    const dict = {
      '税费-税金及附加': "增值税及附加",
      '税费-个人所得税': "个人所得税",
      '税费-企业所得税': "企业所得税",
      '管理费-职工薪酬': "其他",
    }

    if (!dict[row.faType]) {
      message.warning("请检查款项类别！").then()
      return
    }

    // 责任部门获取code
    if (!row.responsibilityProject) {
      message.warning("请检查责任部门！").then()
      return
    }
    const names: string[] = typeof row.responsibilityProject === 'object' ? row.responsibilityProject : row.responsibilityProject.split(',')
    const responsibilityProject = await ProjectService.getBatchByName(names)
    if (typeof responsibilityProject === 'object' && responsibilityProject.length !== names.length) {
      message.warning("请检查责任部门！").then()
      return
    }
    const code = responsibilityProject
      .map(o => o.code)
      .filter(o => o)
      .filter((v, i, self) => self.indexOf(v) === i)
      .join(',')

    taxAccountingStore.updateTaxAccountingRow({
      taxMethod: undefined,
      taCategory: undefined,
      amountRealizedDate: undefined,
      isTaApplicationForm: undefined,
      taxCategory: dict[row.faType],
      code,
      taxAccountingId: row.id,

      bsAccountIn: undefined,
      baNick1: row.faAccountsReceivable,
      bsAccountInId: row.faAccountsReceivableId,
      baName1: row.baName2,
      baBlank1: row.baAccount2,
      baAccount1: row.baBlank2,

      bsAccountPay: undefined,
      baNick2: row.faPaymentAccount,
      bsAccountPayId: row.faPaymentAccountId,
      baName2: row.baName1,
      baAccount2: row.baAccount1,
      baBlank2: row.baBlank1,
      cooperationUnit: ['N/A'],
      fileArchive: undefined,

      // 关联资金审批
      fundApprovalIds: [row.id],
      fundingApprovalList: [row],

      id: undefined,
      invoiceRegistrationIn: undefined,
      invoiceRegistrationInId: undefined,
      invoiceRegistrationIns: undefined,
      invoiceRegistrationOut: undefined,
      invoiceRegistrationOutId: undefined,
      invoiceRegistrationOuts: undefined,
      personResponsible: row.personResponsible,
      personResponsibleName: row.personResponsibleName,
      organizersUserId: undefined,
      organizersUserName: undefined,
      responsibilityProject: row.responsibilityProject,
      status1: undefined,
      status2: undefined,
      taCode: undefined,
      taDate: dayjs(new Date()), // 制单日期 = 资金审批.制单日期
      taDesc: row.faSummary,
      taEnterprise: undefined,
      taInIrAmount: 0,
      taInTaxIrAmount: 0,
      taOutIrAmount: 0,
      taOutTaxIrAmount: 0,
      taSegment: row.responsibilityProject,
      taStatus: undefined,
      taVatPayable: 0,

      additionalTaxAmount: row.faAmount,
      taxAccountingMiddle: {
        personDue: row.faAmount,
        enterpriseDue: row.faAmount,
        managerDue: row.faAmount,
      },
      employeeFeeTaxScheduleId: undefined
    })
    navigate("/taxAccounting")
  }

  // 单笔 资金审批 发起 合同结算
  const handleContractSettlementClick = async (record: FundApprovalRow) => {

    // 获取详情
    const {code: detailCode, data: row, message: detailMessage} = await FundApprovalService.getOne(record.id)
    if (detailCode !== 200) {
      message.warning(detailMessage).then()
    }

    // 责任部门获取code
    if (!row.responsibilityProject) {
      message.warning("请检查责任部门！").then()
      return
    }
    const names: string[] = typeof row.responsibilityProject === 'object' ? row.responsibilityProject : row.responsibilityProject.split(',')
    const responsibilityProject = await ProjectService.getBatchByName(names)
    if (typeof responsibilityProject === 'object' && responsibilityProject.length !== names.length) {
      message.warning("请检查责任部门！").then()
      return
    }
    const code = responsibilityProject
      .map(o => o.code)
      .filter(o => o)
      .filter((v, i, self) => self.indexOf(v) === i)
      .join(',')

    contractSettlementStore.updateContractSettlementRow({
      contractSettlementId: row.id,
      applicationType: undefined,
      code,
      contractListIds: undefined,
      contractLists: undefined,
      cooperationUnit: ['N/A'],
      csBook: undefined,
      csCode: undefined,
      csContract: undefined,
      csContractCode: undefined,
      csDate: dayjs(new Date()), // 制单日期 = 资金审批.制单日期
      csEndAmount: row.faAmount, // 结算金额 = 资金审批.金额

      csEndInAccount: row.faAccountsReceivable, // 结算收款账户 = 资金审批.收款账户
      csEndInAccountId: row.faAccountsReceivableId,
      csEndInAccountTitle: row.baName2,
      csEndInBankAccount: row.baAccount2,
      csEndInCustomer: undefined,
      csEndInDepositBank: row.baBlank2,

      csEndOutAccount: row.faPaymentAccount, // 结算付款账户 = 资金审批.付款账户
      csEndOutAccountId: row.faPaymentAccountId,
      csEndOutAccountTitle: row.baName1,
      csEndOutBankAccount: row.baAccount1,
      csEndOutCustomer: undefined,
      csEndOutDepositBank: row.baBlank1,

      csEndType: undefined,
      csFile: undefined,
      csStatus: undefined,
      csSummany: row.faSummary, // 结算摘要 = 资金审批.款项摘要
      executorId: undefined,
      fundingApprovalIds: [row.id],
      fundingApprovals: [row],
      id: undefined,
      invoiceRegistrationIds: undefined,
      invoiceRegistrations: undefined,
      oldCsEndInAccount: undefined,
      oldCsEndOutAccount: undefined,
      outboundInbound: undefined,
      personResponsible: row.personResponsible, // 责任人 = 资金审批.责任人
      personResponsibleName: row.personResponsibleName,
      organizersUserId: undefined,
      organizersUserName: undefined,
      responsibilityProject: row.responsibilityProject, // 责任部门 = 资金审批.责任部门
      type: undefined
    })
    navigate("/contractSettlement")
  }

  /**
   * @description 发起资金出纳
   * 收款账户--内部协作账户为转入金额
   * 收款账户--外部协作账户为转出金额
   *
   * 付款账号--内部协作账户为转出金额
   * 付款账号--外部协作账户为转入金额
   * @param record
   */
  const handleBankStatementClick = async (record: FundApprovalRow) => {

    // 获取详情
    const {code: detailCode, data: row, message: detailMessage} = await FundApprovalService.getOne(record.id)
    if (detailCode !== 200) {
      message.warning(detailMessage).then()
    }

    // 获取账户
    if (!row.faPaymentAccountId) {
      message.warning("请检查付款账户！").then()
      return
    }
    const paymentAccountRsp = await BankAccountService.getOne(row.faPaymentAccountId)
    const paymentAccount = paymentAccountRsp.data
    const baTypeWhiteList = ['内部协作账户', '员工公务账户', '库存现金账户']
    if (paymentAccountRsp.code !== 200 || !paymentAccount) {
      message.warning("请检查付款账户！").then()
      return
    }

    // 责任部门获取code
    if (!row.responsibilityProject) {
      message.warning("请检查责任部门！").then()
      return
    }
    const names: string[] = typeof row.responsibilityProject === 'object' ? row.responsibilityProject : row.responsibilityProject.split(',')
    const responsibilityProject = await ProjectService.getBatchByName(names)
    if (typeof responsibilityProject === 'object' && responsibilityProject.length !== names.length) {
      message.warning("请检查责任部门！").then()
      return
    }
    const code = responsibilityProject
      .map(o => o.code)
      .filter(o => o)
      .filter((v, i, self) => self.indexOf(v) === i)
      .join(',')

    bankStatementStore.updateBankStatementRow({
      bsDateM: undefined,
      bsType: undefined,
      id: undefined,
      bsCode: undefined, // 出纳编号
      fundingApprovalId: row.id, // 资金审批
      fundingApprovalCode: row.faCode, // 资金审批单号
      bsDate: dayjs(new Date()), // 出纳日期
      bsSummary: row.faSummary, // 款项摘要
      bsInAmount: baTypeWhiteList.includes(paymentAccount.baType) ? undefined : row.faAmount, // 转入金额
      bsOutAmount: baTypeWhiteList.includes(paymentAccount.baType) ? row.faAmount : undefined, // 转出金额
      bsAmount: undefined, // 余额

      bsName: baTypeWhiteList.includes(paymentAccount.baType) ? row.faPaymentAccount : row.faAccountsReceivable, // 出纳账户
      bankAccountId: baTypeWhiteList.includes(paymentAccount.baType) ? row.faPaymentAccountId : row.faAccountsReceivableId, // 账户id
      baName1: baTypeWhiteList.includes(paymentAccount.baType) ? row.baName1 : row.baName2, // 账户id
      baAccount1: baTypeWhiteList.includes(paymentAccount.baType) ? row.baAccount1 : row.baAccount2, // 账户id
      baBlank1: baTypeWhiteList.includes(paymentAccount.baType) ? row.baBlank1 : row.baBlank2, // 账户id

      bsOtherAccountName: baTypeWhiteList.includes(paymentAccount.baType) ? row.faAccountsReceivable : row.faPaymentAccount, // 对方户
      bsOtherAccountId: baTypeWhiteList.includes(paymentAccount.baType) ? row.faAccountsReceivableId : row.faPaymentAccountId, // 对方户
      baName2: baTypeWhiteList.includes(paymentAccount.baType) ? row.baName2 : row.baName1, // 对方户
      bsAccount: baTypeWhiteList.includes(paymentAccount.baType) ? row.baAccount2 : row.baAccount1, // 对方户
      baBlank2: baTypeWhiteList.includes(paymentAccount.baType) ? row.baBlank2 : row.baBlank1, // 对方户

      personResponsible: row.personResponsible, // 责任人
      organizersUserId: undefined,
      organizersUserName: undefined,
      responsibilityProject: typeof row.responsibilityProject === 'string' ? row.responsibilityProject.split(',') : row.responsibilityProject, // 责任部门
      cooperationUnit: ['N/A'], // 协作单位
      bsFile: undefined, // 存档批号
      bsStatus: undefined, // 办理进度
      bsBalance: undefined, // 余额对账
      code
    })
    navigate("/bankStatement")
  }

  /**
   * 打印单条数据
   * @param record
   */
  const handlePrint = async (record: FundApprovalRow) => {
    const list = await fundApprovalStore.getPrintList(record)
    if (list) {
      setContentList(list)
      setPrintTitle('资金审批单')
      setIsPrintTable(false)
      setIsModalOpen(true)
    } else {
      message.warning('请检查')
    }
  }

  /**
   * @description 打印表格
   */
  const handlePrintTable = async () => {
    if (!selectedRowKeys.length) {
      message.warning('请至少选择一条数据')
    }
    // 数据处理
    const rows = fundApprovalStore.fundApprovalDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    for (let i = 0; i < rows.length; i++) {
      rows[i].faApplicationFormType = rows[i].fundingApprovalTables?.length ? rows[i].fundingApprovalTables[0].type : ''
      rows[i].faApplicationForm = await getApplicationFrom(rows[i].faApplicationFormType, rows[i].fundingApprovalTables?.map(o => o.businessId))
    }
    setPrintDataSource(rows)
    setIsPrintTable(true)
    setPrintTitle('资金审批表格')
    setIsModalOpen(true)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSelectedRowKeys([])
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: FundApprovalReq) => {
    setSearchReq(req)
    fundApprovalStore.getFundApproval(req)
  }

  const handleBankStatementTableModalOk = (keys: React.Key[]) => {
    BankStatementService.bindD({
      FundingApprovalId: FundingApprovalId,
      BankStatementIds: keys as string[]
    }).then(rsp => {
      if (rsp.code === 200) {
        message.success(rsp.message).then();
      } else {
        message.warning(rsp.message).then();
      }
    }).finally(() => {
      refreshData(fundApprovalStore.fundApprovalReq)
      setBankStatementTableModalOpen(false)
    })
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    fundApprovalStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  /**
   *合并资金审批
   */
  const handleMerge = () => {
    // logger.log(selectedRowKeys)
    if (!selectedRowKeys.length) {
      message.warning("请选择要合并的数据！").then()
      return
    }
    setMergeFundApprovalModalDataSource(fundApprovalStore.fundApprovalDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id)))
    setMergeFundApprovalModalOpen(true)
  }

  /**
   * 批量发起 资金出纳
   */
  const handleAddBankStatementBatch = () => {
    if (!selectedRowKeys.length) {
      message.warning("请检勾选资金审批").then()
      return
    }
    setConfirmLoading(true)

    Modal.confirm({
      title: "是否批量发起出纳？",
      okText: "确定",
      cancelText: "取消",
      onCancel: () => setConfirmLoading(false),
      onOk: async () => {

        // 响应体列表
        let resList = []

        const user = securityStore.getLoggedUser()

        // 账户类型白名单
        const baTypeWhiteList = ['内部协作账户', '员工公务账户', '库存现金账户']

        // 批量发起出纳
        for (let i = 0; i < selectedRowKeys.length; i++) {
          message.loading(`请稍等！${i + 1}/${selectedRowKeys.length}`).then()
          const record = fundApprovalStore.fundApprovalDatasource.data?.items.find(o => o.id === selectedRowKeys[i])
          const BLACK_LIST: string[] = ['', 'N/A', '空白', '容缺']
          if (BLACK_LIST.includes(record.faPaymentAccountId)) {
            resList.push('请检查付款账户')
            continue
          }
          const paymentAccountRsp = await BankAccountService.getOne(record.faPaymentAccountId)
          const paymentAccount = paymentAccountRsp.data
          if (paymentAccountRsp.code !== 200 || !paymentAccount) {
            resList.push('请检查付款账户')
          }
          const responsibilityProjectDetails = await ProjectService.getBatchByName([record.responsibilityProject])
          const res = await handleBankStatementOk({
            bsDateM: undefined,
            bsType: undefined,
            id: undefined,
            bsCode: undefined, // 出纳编号
            fundingApprovalId: record.id, // 资金审批
            fundingApprovalCode: record.faCode, // 资金审批单号
            bsDate: dayjs(new Date()), // 出纳日期
            bsSummary: record.faSummary, // 款项摘要
            bsInAmount: baTypeWhiteList.includes(paymentAccount.baType) ? undefined : record.faAmount, // 转入金额
            bsOutAmount: baTypeWhiteList.includes(paymentAccount.baType) ? record.faAmount : undefined, // 转出金额
            bsAmount: undefined, // 余额

            bsName: baTypeWhiteList.includes(paymentAccount.baType) ? record.faPaymentAccount : record.faAccountsReceivable, // 出纳账户
            bankAccountId: baTypeWhiteList.includes(paymentAccount.baType) ? record.faPaymentAccountId : record.faAccountsReceivableId, // 账户id
            baName1: baTypeWhiteList.includes(paymentAccount.baType) ? record.baName1 : record.baName2, // 账户id
            baAccount1: baTypeWhiteList.includes(paymentAccount.baType) ? record.baAccount1 : record.baAccount2, // 账户id
            baBlank1: baTypeWhiteList.includes(paymentAccount.baType) ? record.baBlank1 : record.baBlank2, // 账户id

            bsOtherAccountName: baTypeWhiteList.includes(paymentAccount.baType) ? record.faAccountsReceivable : record.faPaymentAccount, // 对方户
            bsOtherAccountId: baTypeWhiteList.includes(paymentAccount.baType) ? record.faAccountsReceivableId : record.faPaymentAccountId, // 对方户
            baName2: baTypeWhiteList.includes(paymentAccount.baType) ? record.baName2 : record.baName1, // 对方户
            bsAccount: baTypeWhiteList.includes(paymentAccount.baType) ? record.baAccount2 : record.baAccount1, // 对方户
            baBlank2: baTypeWhiteList.includes(paymentAccount.baType) ? record.baBlank2 : record.baBlank1, // 对方户

            personResponsible: user._id,
            personResponsibleName: user.name,
            organizersUserId: undefined,
            organizersUserName: undefined,
            responsibilityProject: record.responsibilityProject, // 责任部门
            cooperationUnit: ['N/A'], // 协作单位
            bsFile: undefined, // 存档批号
            bsStatus: READY, // 办理进度
            bsBalance: undefined, // 余额对账
            code: responsibilityProjectDetails.length ? responsibilityProjectDetails[0].code : '' // 责任部门编号
          })
          resList.push(res)
        }

        // 全部一起提示
        Modal.info({
          title: `温馨提示-批量发起资金出纳`,
          content: <>{ resList.map(o => <div>{ typeof o === 'string' ? o : o.message }</div>) }</>,
          okText: "确定",
          cancelText: "取消",
          onOk() {
            refreshData(fundApprovalStore.fundApprovalReq)
            setConfirmLoading(false)
            setSelectedRowKeys([])
          },
        })

      },
    })

  }

  // 生成和转换协作单位
  const TaxAccountingGenerateCooperationUnit = (row: FundApprovalRow) => {
    if (typeof row.cooperationUnit === 'object') {
      if (!row.id) {
        row.cooperationUnit = []
        if (row.faPaymentAccountId && !BLACK_LIST.includes(row.faPaymentAccountId)) {
          row.cooperationUnit.push(row.baName1)
        }
        if (row.faAccountsReceivableId && !BLACK_LIST.includes(row.faAccountsReceivableId)) {
          row.cooperationUnit.push(row.baName2)
        }
      }
      row.cooperationUnit = row.cooperationUnit
        .filter((v, i, self) => self.indexOf(v) === i) // 去重
        .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
        .join(',');
    }
    return row.cooperationUnit
  }

  /**
   * 批量发起 税费核算
   */
  const handleAddTaxAccountingBatch = () => {
    if (!selectedRowKeys.length) {
      message.warning("请检勾选资金审批").then()
      return
    }
    setConfirmLoading(true)

    Modal.confirm({
      title: "是否批量发起税费核算？",
      okText: "确定",
      cancelText: "取消",
      onCancel: () => setConfirmLoading(false),
      onOk: async () => {

        // 响应体列表
        let resList = []

        const user = securityStore.getLoggedUser()

        // 批量发起出纳
        for (let i = 0; i < selectedRowKeys.length; i++) {

          // 提示
          message.loading(`请稍等！${i + 1}/${selectedRowKeys.length}`).then()

          const record = fundApprovalStore.fundApprovalDatasource.data?.items.find(o => o.id === selectedRowKeys[i])

          const dict = {
            '税费-税金及附加': "增值税及附加",
            '税费-个人所得税': "个人所得税",
            '税费-企业所得税': "企业所得税",
            '管理费-职工薪酬': "其他",
          }

          if (!dict[record.faType]) {
            resList.push('请检查款项类别')
            continue
          }

          const names: string[] = typeof record.responsibilityProject === 'object' ? record.responsibilityProject : record.responsibilityProject.split(',')
          const responsibilityProject = await ProjectService.getBatchByName(names)
          if (typeof responsibilityProject === 'object' && responsibilityProject.length !== names.length) {
            resList.push('请检查责任部门')
            continue
          }
          const code = responsibilityProject
            .map(o => o.code)
            .filter(o => o)
            .filter((v, i, self) => self.indexOf(v) === i)
            .join(',')

          const cooperationUnit = TaxAccountingGenerateCooperationUnit(record)

          const res = await handleTaxAccountingOk({
            taxMethod: undefined,
            taCategory: undefined,
            amountRealizedDate: undefined,
            isTaApplicationForm: undefined,
            taxCategory: dict[record.faType],
            code,
            taxAccountingId: record.id,

            bsAccountIn: undefined,
            baNick1: record.faAccountsReceivable,
            bsAccountInId: record.faAccountsReceivableId,
            baName1: record.baName2,
            baBlank1: record.baAccount2,
            baAccount1: record.baBlank2,

            bsAccountPay: undefined,
            baNick2: record.faPaymentAccount,
            bsAccountPayId: record.faPaymentAccountId,
            baName2: record.baName1,
            baAccount2: record.baAccount1,
            baBlank2: record.baBlank1,

            cooperationUnit: cooperationUnit,
            fileArchive: undefined,

            // 关联资金审批
            fundApprovalIds: [record.id],
            fundingApprovalList: [record],

            id: undefined,
            invoiceRegistrationIn: undefined,
            invoiceRegistrationInId: undefined,
            invoiceRegistrationIns: undefined,
            invoiceRegistrationOut: undefined,
            invoiceRegistrationOutId: undefined,
            invoiceRegistrationOuts: undefined,
            personResponsible: user._id,
            personResponsibleName: user.name,
            organizersUserId: undefined,
            organizersUserName: undefined,
            responsibilityProject: record.responsibilityProject,
            status1: undefined,
            status2: undefined,
            taCode: undefined,
            taDate: dayjs(new Date()), // 制单日期 = 资金审批.制单日期
            taDesc: record.faSummary,
            taEnterprise: undefined,
            taInIrAmount: 0,
            taInTaxIrAmount: 0,
            taOutIrAmount: 0,
            taOutTaxIrAmount: 0,
            taSegment: record.responsibilityProject,
            taStatus: READY,
            taVatPayable: 0,

            additionalTaxAmount: record.faAmount,
            taxAccountingMiddle: {
              personDue: record.faAmount,
              enterpriseDue: record.faAmount,
              managerDue: record.faAmount,
            },

            employeeFeeTaxScheduleId: undefined
          })
          resList.push(res)
        }

        // 全部一起提示
        Modal.info({
          title: `温馨提示-批量发起税费核算`,
          content: <>{ resList.map(o => <div>{ typeof o === 'string' ? o : o.message }</div>) }</>,
          okText: "确定",
          cancelText: "取消",
          onOk() {
            refreshData(fundApprovalStore.fundApprovalReq)
            setConfirmLoading(false)
            setSelectedRowKeys([])
          },
        })

      },
    })

  }

  /**
   * 批量发起 合同结算
   */
  const handleAddContractSettlementBatch = () => {
    if (!selectedRowKeys.length) {
      message.warning("请检勾选资金审批").then()
      return
    }

    // 批量发起前判断 是否符合条件
    const rows = fundApprovalStore.fundApprovalDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    const con = rows.every((o, _, arr) => {
      const firstRow = arr[0]
      if (o.personResponsible !== firstRow.personResponsible) {
        message.warning("办理人员不一致！").then()
        return false
      } else if (o.faStatus !== firstRow.faStatus) {
        message.warning("办理进度不一致！").then()
        return false
      } else return true
    })

    if (!con) return

    setConfirmLoading(true)
    Modal.confirm({
      title: "是否批量发起合同结算？",
      okText: "确定",
      cancelText: "取消",
      onCancel: () => setConfirmLoading(false),
      onOk: async () => {

        try {

          // 响应体列表
          let resList = []

          const user = securityStore.getLoggedUser()

          // 批量发起合同结算
          for (let i = 0; i < rows.length; i++) {

            // 提示
            message.loading(`请稍等！${i + 1}/${rows.length}`).then()

            const record = rows[i]

            const res = await insertOrUpdateContractSettlement(await dataConversionContractSettlement({
              contractSettlementId: record.id,
              applicationType: undefined,
              code: undefined,
              contractListIds: undefined,
              contractLists: undefined,
              cooperationUnit: ['N/A'],
              csBook: undefined,
              csCode: undefined,
              csContract: undefined,
              csContractCode: undefined,
              csDate: dayjs(new Date()), // 制单日期 = 资金审批.制单日期
              csEndAmount: record.faAmount, // 结算金额 = 资金审批.金额

              csEndInAccount: record.faAccountsReceivable, // 结算收款账户 = 资金审批.收款账户
              csEndInAccountId: record.faAccountsReceivableId,
              csEndInAccountTitle: record.baName2,
              csEndInBankAccount: record.baAccount2,
              csEndInCustomer: undefined,
              csEndInDepositBank: record.baBlank2,

              csEndOutAccount: record.faPaymentAccount, // 结算付款账户 = 资金审批.付款账户
              csEndOutAccountId: record.faPaymentAccountId,
              csEndOutAccountTitle: record.baName1,
              csEndOutBankAccount: record.baAccount1,
              csEndOutCustomer: undefined,
              csEndOutDepositBank: record.baBlank1,

              csEndType: undefined,
              csFile: undefined,
              csStatus: READY, // 默认准备中
              csSummany: record.faSummary, // 结算摘要 = 资金审批.款项摘要
              executorId: undefined,
              fundingApprovalIds: [record.id],
              fundingApprovals: [record],
              id: undefined,
              invoiceRegistrationIds: undefined,
              invoiceRegistrations: undefined,
              oldCsEndInAccount: undefined,
              oldCsEndOutAccount: undefined,
              outboundInbound: undefined,
              personResponsible: user._id,
              personResponsibleName: user.name,
              organizersUserId: undefined,
              organizersUserName: undefined,
              responsibilityProject: record.responsibilityProject, // 责任部门 = 资金审批.责任部门
              type: undefined
            }))
            resList.push(res)
          }

          // 全部一起提示
          Modal.info({
            title: `温馨提示-批量发起税费核算`,
            content: <>{ resList.map(o => <div>{ typeof o === 'string' ? o : o.message }</div>) }</>,
            okText: "确定",
            cancelText: "取消",
            onOk() {
              refreshData(fundApprovalStore.fundApprovalReq)
              setConfirmLoading(false)
              setSelectedRowKeys([])
            },
          })

        } catch (e) {

          logger.log(e)
          message.warning('失败')

        }

      },
    })
  }

  /**
   * 模拟 资金出纳页面 的 新增
   * @param row
   */
  const handleBankStatementOk = (row: BankStatementRow): string | Promise<ResponseEntity<BankStatementRow>> => {
    if (!row.code || row.code === '未知') {
      return '请检查责任部门'
    }
    if (typeof row.bsDate === 'object') {
      row.bsDate = row.bsDate.format(DATE_SECONDS_FMT)
    }
    if (typeof row.fundingApprovalCode === 'object' && row.fundingApprovalCode) {
      row.fundingApprovalCode = row.fundingApprovalCode.join(',');
    }
    if (typeof row.fundingApprovalId === 'object' && row.fundingApprovalId) {
      row.fundingApprovalId = row.fundingApprovalId.join(',');
    }
    if (typeof row.responsibilityProject === 'object' && row.responsibilityProject) {
      row.responsibilityProject = row.responsibilityProject.join(',');
    }
    if (typeof row.cooperationUnit === 'object' && row.cooperationUnit) {
      row.cooperationUnit = row.cooperationUnit.join(',');
    }
    // let ids = ""
    // if (typeof row.bsFile === 'object') {
    //   ids = getUploadIds(row.bsFile)
    //   row.bsFile = getUploadArrayToString(row.bsFile)
    // }

    return BankStatementService.addBankStatement(row)
  }

  /**
   * 模拟 税费核算页面 的 新增
   * @param row
   */
  const handleTaxAccountingOk = (row: TaxAccountingRow): string | Promise<ResponseEntity<TaxAccountingRow>> => {
    if (typeof row.taDate === 'object') {
      row.taDate = row.taDate.format(DATE_FMT)
    }
    if (typeof row.cooperationUnit === 'object') {
      row.cooperationUnit = row.cooperationUnit.join(",")
    }
    if (row.invoiceRegistrationOutId && typeof row.invoiceRegistrationOutId === 'object') {
      row.invoiceRegistrationOutId = row.invoiceRegistrationOutId.join(',')
    }
    if (row.invoiceRegistrationOut && typeof row.invoiceRegistrationOut === 'object') {
      row.invoiceRegistrationOut = row.invoiceRegistrationOut.join(',')
    }
    if (row.invoiceRegistrationInId && typeof row.invoiceRegistrationInId === 'object') {
      row.invoiceRegistrationInId = row.invoiceRegistrationInId.join(',')
    }
    if (row.invoiceRegistrationIn && typeof row.invoiceRegistrationIn === 'object') {
      row.invoiceRegistrationIn = row.invoiceRegistrationIn.join(',')
    }
    return TaxAccountingService.addTaxAccounting(row)
  }

  const batchUpdate = (row: FundApprovalRow) => {
    // let flag = false
    const rows = fundApprovalStore.fundApprovalDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    for (let selectedRow of rows) {
      // if (selectedRow.faStatus === ONFILE) {
      //   flag = true
      //   continue
      // }
      if (typeof row.faDate === 'object' && row.faDate) {
        selectedRow.faDate = row.faDate.format(DATE_SECONDS_FMT);
      }
      if (typeof row.faPayDate === 'object' && row.faPayDate) {
        selectedRow.faPayDate = row.faPayDate.format(DATE_SECONDS_FMT);
      }
      if (row.faType) {
        selectedRow.faType = row.faType;
      }
      if (row.faSummary) {
        selectedRow.faSummary = row.faSummary;
      }
      if (row.faAmount || row.faAmount === 0) {
        selectedRow.faAmount = row.faAmount;
      }
      if (row.faPaymentAccountId) {
        selectedRow.faPaymentAccountId = row.faPaymentAccountId
        selectedRow.faPaymentAccount = row.faPaymentAccount
        selectedRow.baName1 = row.baName1
        selectedRow.baAccount1 = row.baAccount1
        selectedRow.baBlank1 = row.baBlank1
      }
      if (row.faAccountsReceivableId) {
        selectedRow.faAccountsReceivableId = row.faAccountsReceivableId
        selectedRow.faAccountsReceivable = row.faAccountsReceivable
        selectedRow.baName2 = row.baName2
        selectedRow.baAccount2 = row.baAccount2
        selectedRow.baBlank2 = row.baBlank2
      }
      if (typeof row.faApplicationForm === 'object' && row.faApplicationForm && row.faApplicationFormType) {
        selectedRow.faApplicationFormType = row.faApplicationFormType
        selectedRow.faApplicationForm = row.faApplicationForm.join(',')
      }
      if (row.personResponsible) {
        selectedRow.personResponsible = row.personResponsible
        selectedRow.personResponsibleName = row.personResponsibleName
      }
      if (row.responsibilityProject) {
        selectedRow.code = row.code
        selectedRow.responsibilityProject = row.responsibilityProject
      }
      if (row.faStatus) {
        selectedRow.faStatus = row.faStatus
      }
      if (typeof row.cooperationUnit === 'object' && row.cooperationUnit) {
        selectedRow.cooperationUnit = []
        if (row.faPaymentAccountId && !BLACK_LIST.includes(row.faPaymentAccountId)) {
          selectedRow.cooperationUnit.push(row.baName1)
        }
        if (row.faAccountsReceivableId && !BLACK_LIST.includes(row.faAccountsReceivableId)) {
          selectedRow.cooperationUnit.push(row.baName2)
        }
        selectedRow.cooperationUnit = selectedRow.cooperationUnit
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
          .join(',');
      }
    }
    // if (flag) {
    //   message.warning(`办理进度为${ONFILE}的数据不可编辑！`).then()
    // }
    FundApprovalService.batchUpdateFundApproval(rows).then((rsp) => {
      actionThen(rsp);
      setUpdateCount(updateCount + 1)
    }).catch(showErr).finally(() => setConfirmLoading(false));
  }

  // 新增
  // 资金出纳发起
  // 费用报销发起
  // 合同结算发起
  // 工资发放发起
  // 税费核算发起
  // 资金出纳发起
  // 税费缴纳发起
  // 员工借款发起
  const handleAdd = (row?: FundApprovalRow) => {
    const user = securityStore.getLoggedUser()
    const initRow = {
      faStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      faDate: dayjs(),
    } as FundApprovalRow
    Object.assign(row || {}, initRow)
    setFundApprovalRow(row || initRow)
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  const handleView = (record: FundApprovalRow) => {
    setFundApprovalRow(record)
    setIsView(true)
    setIsBatchUpdate(false)
    setIsModalVisible(true);
  }

  const handleEdit = async (record: FundApprovalRow) => {

    try {
      const rsp = await ProcessRecordsService.getProcessRecords({
        businessId: record.id,
        pageNum: 1,
        perPage: 999,
        type: TableName,
      })
      const process = rsp.items.length ? rsp.items[0].process : ''
      const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
      const con = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
      // 如果本轮有撤回 （id为空） 否则 （发起最新一轮的提交的人的id）
      setSubmitterId(con ? undefined : userId)
      setLatestProcess(process) // 发起最新一轮的标志

      setFundApprovalRow(record)
      setIsView(false)
      setIsBatchUpdate(false)
      setIsModalVisible(true);
    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleEditBatch = async () => {

    try {

      if (selectedRowKeys.length === 0) {
        message.warning("请选择要操作的数据！").then()
        return
      }

      // 要操作的数据
      const rows = fundApprovalStore.fundApprovalDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))

      // 办理流程辅助字段：办理进度
      let faStatus: string

      // 判断是否符合批量操作条件
      const con = rows.every((o, _, arr) => {
        const firstRow = arr[0]
        faStatus = firstRow.faStatus
        if (o.personResponsible !== firstRow.personResponsible) {
          message.warning("办理人员不一致！").then()
          return false
        } else if (o.faStatus !== firstRow.faStatus) {
          message.warning("办理进度不一致！").then()
          return false
        } else return true
      })

      if (con) {

        let latestProcessList: string[] = []
        let submitterIdList: string[] = []
        for (let i = 0; i < rows.length; i++) {
          const item = rows[i]
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: item.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const process = rsp.items.length ? rsp.items[0].process : ''
          const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
          const userIdCon = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
          latestProcessList.push(process)
          submitterIdList.push(userIdCon ? undefined : userId)
        }

        // 如果提交人全部一致才赋值给参数:【提交人】
        setSubmitterId(new Set(submitterIdList).size === 1 ? submitterIdList[0] : undefined)
        setLatestProcessBatch(latestProcessList)
        setIsView(false)
        setIsBatchUpdate(true)
        setFundApprovalRow({faStatus} as FundApprovalRow)
        setIsModalVisible(true)

      }

    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleDeleteBatch = (record?: FundApprovalRow) => {
    const keys = [...selectedRowKeys]
    if (!record && keys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = [];
    if (record) {
      ids.push(record.id)
    } else {
      ids = keys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        FundApprovalService.deleteFundApproval(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(fundApprovalStore.fundApprovalReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const insertOrUpdate = async (row: FundApprovalRow) => {
    if (typeof row.faDate === 'object' && row.faDate) {
      row.faDate = row.faDate.format(DATE_SECONDS_FMT);
    }
    if (typeof row.faPayDate === 'object' && row.faPayDate) {
      row.faPayDate = row.faPayDate.format(DATE_SECONDS_FMT);
    }
    if (typeof row.faApplicationForm === 'object' && row.faApplicationForm) {
      row.faApplicationForm = row.faApplicationForm.join(',');
    }
    if (typeof row.bankStatementIds === 'object' && row.bankStatementIds) {
      row.bankStatementIds = row.bankStatementIds.join(',');
    }
    if (typeof row.bankStatementCodes === 'object' && row.bankStatementCodes) {
      row.bankStatementCodes = row.bankStatementCodes.join(',');
    }
    if (typeof row.cooperationUnit === 'object') {
      row.cooperationUnit = []
      if (row.faPaymentAccountId && !BLACK_LIST.includes(row.faPaymentAccountId)) {
        row.cooperationUnit.push(row.baName1)
      }
      if (row.faAccountsReceivableId && !BLACK_LIST.includes(row.faAccountsReceivableId)) {
        row.cooperationUnit.push(row.baName2)
      }
      row.cooperationUnit = row.cooperationUnit
        .filter((v, i, self) => self.indexOf(v) === i) // 去重
        .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
        .join(',');
    }
    if (row.id) {
      const rsp = await FundApprovalService.updateFundApproval(row)
      actionThen(rsp)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    } else {
      const rsp = await FundApprovalService.addFundApproval(row)
      actionThen(rsp)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    }
  }

  const actionThen = (rsp: ResponseEntity<any>) => {
    if (rsp.code === 200) {
      message.success("操作成功！").then()
    } else {
      message.warning(rsp.message).then()
    }
    setIsModalVisible(false);
    setFundApprovalRow(undefined)
    refreshData(fundApprovalStore.fundApprovalReq)
    setSelectedRowKeys([])
  }

  const handleOk = (open: boolean) => {
    const row = {...fundApprovalRow!}
    basicInfo.current!.validateFields().then(async (values) => {
      setConfirmLoading(true)
      Object.assign(row, values)

      if (open) {

        // 将row和按钮先存起来
        setSubmitRow(row)

        // 弹窗选择处理人
        setProcessRecordsIsOpen(true)
        setProcessRecordsRow(undefined)

      } else {

        const user = securityStore.getLoggedUser()

        if (isBatchUpdate) {
          batchUpdate(row)
        } else {
          // 默认处理人员是自己
          if (!row.id) {
            row.personResponsible = user._id
            row.personResponsibleName = user.name
          }

          try {
            await insertOrUpdate(row)
          } catch (error) {
            setProcessRecordsIsOpen(false)
            setProcessRecordsRow(undefined)
            setConfirmLoading(false)
            setIsModalVisible(false);
            message.warning(error.message)
          }
        }

      }

    }).catch(logger.warn)
  }

  const handleSubmit = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)

      const user = securityStore.getLoggedUser()
      const btnText = row.status

      // 当类型是撤回时 默认办理人员为提交人(自己)
      if (btnText === FORM_REVOCATION_TEXT) {
        row.personResponsible = user._id || submitterId
        row.personResponsibleName = user.name
      }

      // 主表
      const subRow = {...submitRow}
      subRow.personResponsible = row.personResponsible
      subRow.personResponsibleName = row.personResponsibleName
      subRow.faStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.faStatus
      // 主表在撤回、退回、变更时->更新局部数据
      if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在转交、提交时->更新局部数据
      if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在提交时->更新局部数据
      if (btnText === FORM_COMMIT_TEXT) {
        subRow.faDate = new Date().toLocaleDateString().replace(/\//g, '-')
      }
      // 主表在结办时->更新局部数据
      if (btnText === FORM_CLOSE_TEXT) {

      }

      // 办理记录表
      row.type = TableName
      row.businessId = subRow.id // 有可能会没值
      row.status = btnText
      row.userId = user._id
      row.userName = user.name
      row.personResponsible = undefined
      row.personResponsibleName = undefined
      if (btnText === FORM_COMMIT_TEXT) {
        const date = new Date()
        row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      } else {
        row.process = latestProcess
      }
      if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
        row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
      } else {
        row.conca = `${user.name}${btnText}了。`
      }

      // 判断是否 先新增主表 后新增记录和通知表
      if (subRow.id) {

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.faCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "资金审批",
          wnDesc: `您参与办理的资金审批（${subRow.faCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      } else {

        let subRsp: ResponseEntity<FundApprovalRow>
        try {
          subRsp = await insertOrUpdate(subRow)
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }

        if (subRsp.data) {
          // 重新赋值再提交
          row.businessId = subRsp.data.id
          await ProcessRecordsService.addProcessRecords(row)
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: subRsp.data.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const notificationScopeUserId = rsp.items
            .map(o => o.userId)
            .concat(row.person)
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(id => id !== user._id) // 过滤出不是自己的数据
            .join(',') || ''
          const notificationScopeUserName = rsp.items
            .map(o => o.userName)
            .concat((row.personName || '').split(','))
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(name => name !== user.name) // 过滤出不是自己的数据
            .join(',') || ''
          await processStore.newAssignmentNotice({
            businessId: subRsp.data.id,
            businessCode: subRsp.data.faCode,
            cooperationUnit: (subRsp.data.cooperationUnit as string).split(',')[0] || 'N/A',
            notificationScopeUserId,
            notificationScopeUserName,
            personResponsible: subRsp.data.personResponsible,
            personResponsibleName: subRsp.data.personResponsibleName,
            responsibilityProject: subRsp.data.responsibilityProject,
            type: "资金审批",
            wnDesc: `您参与办理的资金审批（${subRsp.data.faCode}），${row.conca} 请知悉！`,
            wnStatus: "未读"
          })
        } else {
          message.warning('主表保存成功，办理记录表和任务通知表提交失败')
        }

      }
      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  const handleSubmitBatch = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)
      const rows = fundApprovalStore.fundApprovalDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
      debugger
      for (let i = 0; i < rows.length; i++) {

        const user = securityStore.getLoggedUser()
        const btnText = row.status

        // 当类型是撤回时 默认办理人员为提交人(自己)
        if (btnText === FORM_REVOCATION_TEXT) {
          row.personResponsible = user._id || submitterId
          row.personResponsibleName = user.name
        }

        // 主表
        rows[i].faApplicationFormType = rows[i].fundingApprovalTables[0].type
        rows[i].faApplicationForm = rows[i].fundingApprovalTables.map(o => o.businessId).join(",")
        const subRow = {...rows[i]}
        subRow.personResponsible = row.personResponsible
        subRow.personResponsibleName = row.personResponsibleName
        subRow.faStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.faStatus
        // 主表在撤回、退回、变更时->更新局部数据
        if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在转交、提交时->更新局部数据
        if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在提交时->更新局部数据
        if (btnText === FORM_COMMIT_TEXT) {
          subRow.faDate = new Date().toLocaleDateString().replace(/\//g, '-')
        }
        // 主表在结办时->更新局部数据
        if (btnText === FORM_CLOSE_TEXT) {

        }

        // 办理记录表
        row.type = TableName
        row.businessId = subRow.id // 有可能会没值
        row.status = btnText
        row.userId = user._id
        row.userName = user.name
        row.personResponsible = undefined
        row.personResponsibleName = undefined
        if (btnText === FORM_COMMIT_TEXT) {
          const date = new Date()
          row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
        } else {
          row.process = latestProcessBatch[i]
        }
        if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
          row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
        } else {
          row.conca = `${user.name}${btnText}了。`
        }

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.faCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "资金审批",
          wnDesc: `您参与办理的资金审批（${subRow.faCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      }

      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  /**
   * 操作记录按钮
   */
  const handleProcessRecordsClick = async (record: FundApprovalRow) => {
    const rsp = await ProcessRecordsService.getProcessRecords({
      businessId: record.id,
      pageNum: 1,
      perPage: 999,
      type: TableName
    })
    if (!rsp) {
      message.warning('查无数据')
      return
    }
    setProcessRecordsDataSource(rsp.items)
    setProcessRecordsOpen(true)
  }

  const handleCopyClick = (record: FundApprovalRow) => {
    const user = securityStore.getLoggedUser()
    const initRow = {
      ...record,
      id: '',
      faFile: '',
      bankStatementIds: '',
      faStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      faDate: dayjs(),
    }
    setFundApprovalRow(initRow)
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  const handleAddCostReimbursementBatch = () => {

    if (!selectedRowKeys.length) {
      message.warning("请检勾选资金审批").then()
      return
    }

    // 批量发起前判断 是否符合条件
    const rows = fundApprovalStore.fundApprovalDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    const con = rows.every((o, _, arr) => {
      const firstRow = arr[0]
      if (o.personResponsible !== firstRow.personResponsible) {
        message.warning("办理人员不一致！").then()
        return false
      } else if (o.faStatus !== firstRow.faStatus) {
        message.warning("办理进度不一致！").then()
        return false
      } else return true
    })

    if (!con) return

    setConfirmLoading(true)
    Modal.confirm({
      title: "是否批量发起费用报销？",
      okText: "确定",
      cancelText: "取消",
      onCancel: () => setConfirmLoading(false),
      onOk: async () => {

        try {

          // 响应体列表
          let resList = []

          const user = securityStore.getLoggedUser()

          // 批量发起合同结算
          for (let i = 0; i < rows.length; i++) {

            // 提示
            message.loading(`请稍等！${i + 1}/${rows.length}`).then()

            const record = rows[i]

            const res = await CostReimbursementService.addCostReimbursement({
              id: undefined,
              code: undefined,
              responsibilityProject: record.responsibilityProject,  // 责任部门
              cooperationUnit: 'N/A',  // 协作单位
              efpType: record.faType,  // 类别
              erDateOfPreparation: dayjs(new Date()).format(DATE_FMT),  // 制单日期
              erApprovalCode: undefined,  // 制单编号
              erExpenseSummary: record.faSummary,  // 费用摘要
              erReimbursementAmount: record.faAmount,  // 申请金额
              efpEmployeeFeeApplicationIds: undefined,  // 关联-费用申请
              employeeFeePayIds: undefined, // 关联-费用支出
              fundApprovalId: record.id, // 关联-资金审批
              efpAccountingObjectName: record.faAccountsReceivable, // 收款账户
              efpAccountingObject: record.faAccountsReceivableId, // 收款账户
              efpName1: record.baName2, //户名
              efpAccount1: record.baAccount2, //账号
              efpBlank1: record.baBlank2, //银行
              efpPaymentAccount: record.faPaymentAccountId,  // 付款账户
              efpPaymentAccountName: record.faPaymentAccount,  // 付款账户
              efpName2: record.baName1,  // 户名
              efpAccount2: record.baAccount1,  // 账号
              efpBlank2: record.baBlank1,  // 银行
              personResponsible: user._id,  // 办理人员
              personResponsibleName: user.name,  // 办理人员名
              organizersUserId: undefined, // 主办人员
              organizersUserName: undefined, // 主办人员名
              efaFileArchive: undefined,  // 存档批号
              erEstatus: READY,  // 状态
            })
            resList.push(res)
          }

          // 全部一起提示
          Modal.info({
            title: `温馨提示-批量发起费用报销`,
            content: <>{ resList.map(o => <div>{ typeof o === 'string' ? o : o.message }</div>) }</>,
            okText: "确定",
            cancelText: "取消",
            onOk() {
              refreshData(fundApprovalStore.fundApprovalReq)
              setConfirmLoading(false)
              setSelectedRowKeys([])
            },
          })

        } catch (e) {

          logger.log(e)
          message.warning('失败')

        }

      },
    })
  }

  const handleAddPayrollBatch = () => {

    if (!selectedRowKeys.length) {
      message.warning("请检勾选资金审批").then()
      return
    }

    // 批量发起前判断 是否符合条件
    const rows = fundApprovalStore.fundApprovalDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    const con = rows.every((o, _, arr) => {
      const firstRow = arr[0]
      if (o.personResponsible !== firstRow.personResponsible) {
        message.warning("办理人员不一致！").then()
        return false
      } else if (o.faStatus !== firstRow.faStatus) {
        message.warning("办理进度不一致！").then()
        return false
      } else return true
    })

    if (!con) return

    setConfirmLoading(true)
    Modal.confirm({
      title: "是否批量发起工资发放？",
      okText: "确定",
      cancelText: "取消",
      onCancel: () => setConfirmLoading(false),
      onOk: async () => {

        try {

          // 响应体列表
          let resList = []

          const user = securityStore.getLoggedUser()

          // 批量发起合同结算
          for (let i = 0; i < rows.length; i++) {

            // 提示
            message.loading(`请稍等！${i + 1}/${rows.length}`).then()

            const record = rows[i]

            const res = await PayrollService.addPayroll({
              id: undefined,
              payrollDate: dayjs(new Date()).format(DATE_FMT), // 制单日期
              payrollNumber: undefined, // 制单编号
              payrollCategory: undefined, // 工资类别
              payrollDesc: record.faSummary, // 工资摘要
              payrollSalary: record.faAmount, // 金额
              paymentAccountId: record.faPaymentAccountId, // 付款账户
              paymentAccountNick: record.faPaymentAccount,
              paymentAccountTitle: record.baName1,
              paymentAccount: record.baAccount1,
              paymentAccountBank: record.baBlank1,
              creditedAccountId: record.faAccountsReceivableId, // 收款账户
              creditedAccountNick: record.faAccountsReceivable,
              creditedAccountTitle: record.baName2,
              creditedAccount: record.baAccount2,
              creditedAccountBank: record.baBlank2,
              payrollSummaryId: undefined, // 关联薪资总表
              payrollSummaryCode: undefined, // 关联薪资总表-编号
              personnelContractId: undefined, // 关联人事合同
              businessContractId: undefined, // 关联商务合同
              employeeFeeCompensationPlanId: undefined, // 薪酬计划表
              employeeFeeCompensationPlanCode: undefined, // 薪酬计划表
              fundApprovalId: record.id, // 关联资金审批
              payrollFile: undefined, // 存档批号
              status: READY, // 状态
              personResponsible: user._id, // 办理人员
              personResponsibleName: user.name,
              organizersUserId: undefined, // 主办人员
              organizersUserName: undefined, // 主办人员名
              responsibilityProject: record.responsibilityProject, // 责任项目
              cooperationUnit: 'N/A' // 协作单位
            })
            resList.push(res)
          }

          // 全部一起提示
          Modal.info({
            title: `温馨提示-批量发起工资发放`,
            content: <>{ resList.map(o => <div>{ typeof o === 'string' ? o : o.message }</div>) }</>,
            okText: "确定",
            cancelText: "取消",
            onOk() {
              refreshData(fundApprovalStore.fundApprovalReq)
              setConfirmLoading(false)
              setSelectedRowKeys([])
            },
          })

        } catch (e) {

          logger.log(e)
          message.warning('失败')

        }

      },
    })
  }

  const handleAddStaffLoanBatch = () => {
    if (!selectedRowKeys.length) {
      message.warning("请检勾选资金审批").then()
      return
    }

    // 批量发起前判断 是否符合条件
    const rows = fundApprovalStore.fundApprovalDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    const con = rows.every((o, _, arr) => {
      const firstRow = arr[0]
      if (o.personResponsible !== firstRow.personResponsible) {
        message.warning("办理人员不一致！").then()
        return false
      } else if (o.faStatus !== firstRow.faStatus) {
        message.warning("办理进度不一致！").then()
        return false
      } else if (!(!o.faApplicationFormType || o.faApplicationFormType === "员工借款")) {
        message.warning(`【${o.faCode}】不可发起员借款`).then()
        return false
      } else return true
    })

    if (!con) return

    setConfirmLoading(true)
    Modal.confirm({
      title: "是否批量发起员工借款？",
      okText: "确定",
      cancelText: "取消",
      onCancel: () => setConfirmLoading(false),
      onOk: async () => {

        try {

          // 响应体列表
          let resList = []

          const user = securityStore.getLoggedUser()

          // 批量发起员工借款
          for (let i = 0; i < rows.length; i++) {

            // 提示
            message.loading(`请稍等！${i + 1}/${rows.length}`).then()

            const record = rows[i]

            const res = await staffLoanService.addStaffLoan({
              code: undefined,
              elDate: record.faDate,  // 制单日期
              elReqCode: undefined,  // 制单编号
              elType: undefined,  // 借款类别
              elDesc: record.faSummary,  // 借款说明
              elAmount: record.faAmount,  // 借款金额

              baNick1: record.faAccountsReceivable, // 收款账户简称
              elPaymentNumber: record.faAccountsReceivableId, // 收款账户
              baName1: record.baName2, //户名
              baAccount1: record.baAccount2, //账号
              baBlank1: record.baBlank2, //银行

              baNick2: record.faPaymentAccount,  // 付款账户简称
              elReceiptNumber: record.faPaymentAccountId,  // 付款账户
              baName2: record.baName1,  // 户名
              baAccount2: record.baAccount1,  // 账号
              baBlank2: record.baBlank1,  // 银行

              employeeFeeApplicationId: undefined, // 费用申请
              employeeFeeApplicationCode: undefined, // 费用申请-编号

              // 关联资金审批
              fundingApprovals: undefined,
              fundingApprovalIds: undefined,
              fundingApprovalId: record.id,

              elPlannedReturnDate: undefined,  // 计划归还日期
              elActualReturnDate: undefined,  // 实际归还日期
              personResponsible: user._id, // 办理人员
              personResponsibleName: user.name, // 办理人员-名字
              organizersUserId: undefined, // 主办人员
              organizersUserName: undefined, // 主办人员名
              responsibilityProject: record.responsibilityProject, // 责任部门
              responsibleDepartment: undefined,  // 责任部门
              companyId: undefined,  // 责任企业

              elFile: undefined,  // 存档批号
              elFileCode: undefined,  // 存档批号-编号
              elFileList: undefined,  // 存档批号
              elStatus: READY,  // 状态
              cooperationUnit: 'N/A', // 协作单位

              borrowAmount: undefined, // 借款实现金额
              repaymentAmount: undefined, // 还款实现金额
              balance: undefined, // 余额

              isTaApplicationForm: undefined, //1查询未绑定 0查询已绑定
            } as BaseEntity)
            resList.push(res)
          }

          // 全部一起提示
          Modal.info({
            title: `温馨提示-批量发起员工借款`,
            content: <>{ resList.map(o => <div>{ typeof o === 'string' ? o : o.message }</div>) }</>,
            okText: "确定",
            cancelText: "取消",
            onOk() {
              refreshData(fundApprovalStore.fundApprovalReq)
              setConfirmLoading(false)
              setSelectedRowKeys([])
            },
          })

        } catch (e) {

          logger.log(e)
          message.warning('失败')

        }

      },
    })
  }

  const handleAddFinalSettlementBatch = () => {

    if (!selectedRowKeys.length) {
      message.warning("请检勾选资金审批").then()
      return
    }

    // 批量发起前判断 是否符合条件
    const rows = fundApprovalStore.fundApprovalDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    const con = rows.every((o, _, arr) => {
      const firstRow = arr[0]
      if (o.personResponsible !== firstRow.personResponsible) {
        message.warning("办理人员不一致！").then()
        return false
      } else if (o.faStatus !== firstRow.faStatus) {
        message.warning("办理进度不一致！").then()
        return false
      } else return true
    })

    if (!con) return

    setConfirmLoading(true)
    Modal.confirm({
      title: "是否批量发起税费缴纳？",
      okText: "确定",
      cancelText: "取消",
      onCancel: () => setConfirmLoading(false),
      onOk: async () => {

        try {

          // 响应体列表
          let resList = []

          const user = securityStore.getLoggedUser()

          // 批量发起合同结算
          for (let i = 0; i < rows.length; i++) {

            // 提示
            message.loading(`请稍等！${i + 1}/${rows.length}`).then()

            const record = rows[i]

            const res = await FinalSettlementService.addFinalSettlement({
              id: undefined,
              code: undefined,
              fsDate: dayjs(new Date()).format(DATE_FMT), // 制单日期
              fsCode: undefined, // 制单编号
              fsType: undefined, // 税费类别
              fsDesc: record.faSummary, // 税费摘要
              fsAmount: record.faAmount, // 申请金额
              fsAccountPay: record.faPaymentAccount, // 付款账户
              fsAccountPayId: record.faPaymentAccountId, // 付款账户
              baName1: record.baName1,
              baAccount1: record.baAccount1,
              baBlank1: record.baBlank1,
              fsAccountIn: record.faAccountsReceivable, // 收款账户
              fsAccountInId: record.faAccountsReceivableId, // 收款账户
              baName2: record.baName2,
              baAccount2: record.baAccount2,
              baBlank2: record.baBlank2,
              fsStatus: READY, // 状态
              fsFile: undefined, // 存档批号
              responsibilityProject: record.responsibilityProject, // 责任部门
              cooperationUnit: 'N/A', // 协作单位
              personResponsible: user._id, // 办理人员
              personResponsibleName: user.name, // 办理人员-名字
              organizersUserId: undefined, // 主办人员
              organizersUserName: undefined, // 主办人员名
              fundApprovalId: record.id, // 资金审批
              employeeFeeTaxScheduleId: undefined // 关联税费计划
            })
            resList.push(res)
          }

          // 全部一起提示
          Modal.info({
            title: `温馨提示-批量发起税费缴纳`,
            content: <>{ resList.map(o => <div>{ typeof o === 'string' ? o : o.message }</div>) }</>,
            okText: "确定",
            cancelText: "取消",
            onOk() {
              refreshData(fundApprovalStore.fundApprovalReq)
              setConfirmLoading(false)
              setSelectedRowKeys([])
            },
          })

        } catch (e) {

          logger.log(e)
          message.warning('失败')

        }

      },
    })
  }

  return (
    <>
      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        updateCount={updateCount}
        collapsed={fundApprovalStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        handleShowColumnReset={handleShowColumnReset}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={props.isModalSelect ? columns.filter(o => !blackColumnList.includes(o.key as string)) : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}
        setConfirmLoading={setConfirmLoading}
        items={[
          {
            key: 'handleAdd',
            icon: <PlusOutlined/>,
            onClick: () => handleAdd()
          },
          {
            key: 'handleDeleteBatch',
            icon: <DeleteOutlined/>,
            onClick: () => handleDeleteBatch()
          },
          {
            key: 'handleEditBatch',
            icon: <EditOutlined/>,
            onClick: () => handleEditBatch()
          },
          {
            key: 'handlePrintTable',
            icon: <PrinterOutlined/>,
            onClick: () => handlePrintTable()
          },
          {
            key: 'handleAddTaxAccountingBatch',
            icon: <RetweetOutlined/>,
            onClick: () => handleAddTaxAccountingBatch()
          },
          {
            key: 'handleAddBankStatementBatch',
            icon: <RetweetOutlined/>,
            onClick: () => handleAddBankStatementBatch()
          },
          {
            key: 'handleAddContractSettlementBatch',
            icon: <RetweetOutlined/>,
            onClick: () => handleAddContractSettlementBatch()
          },
          {
            key: 'handleAddCostReimbursementBatch',
            icon: <RetweetOutlined/>,
            onClick: () => handleAddCostReimbursementBatch()
          },
          {
            key: 'handleAddPayrollBatch',
            icon: <RetweetOutlined/>,
            onClick: () => handleAddPayrollBatch()
          },
          {
            key: 'handleAddFinalSettlementBatch',
            icon: <RetweetOutlined/>,
            onClick: () => handleAddFinalSettlementBatch()
          },
          {
            key: 'handleAddStaffLoanBatch',
            icon: <RetweetOutlined/>,
            onClick: () => handleAddStaffLoanBatch()
          },
          {
            key: 'handleMerge',
            icon: <MergeCellsOutlined/>,
            onClick: () => handleMerge()
          },
        ]}/>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        size={"middle"}
        loading={props.dataSource ? false : fundApprovalStore.fundApprovalDatasource.loading || confirmLoading}
        bordered
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        pagination={!props.dataSource && {
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                (showColumn as string[]).map((key, index) =>
                  key.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={summaryTotalObj[key.slice(0, key.indexOf('-'))]}></TableMoneyText> 元
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys)
          },
          columnWidth: 50,
        }}/>

      { /*操作记录表*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsOpen}
        width={LARGE_MODAL_WIDTH_OTHER}
        onCancel={() => setProcessRecordsOpen(false)}
        destroyOnClose
        footer={null}>
        <ProcessRecords
          isHiddenOptions
          dataSource={processRecordsDataSource}
          isModalSelect
          locationPathname={'/processRecords'}/>
      </CustomDraggableModal>

      { /*操作记录表单*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsIsOpen}
        width={LARGE_MODAL_WIDTH}
        destroyOnClose
        onCancel={() => {
          setProcessRecordsIsOpen(false)
          setConfirmLoading(false)
        }}
        onOk={isBatchUpdate ? handleSubmitBatch : handleSubmit}>
        <ProcessRecordsBasicInfo
          tableName={TableName}
          businessId={fundApprovalRow?.id}
          submitterId={submitterId}
          userId={fundApprovalRow?.personResponsible}
          status={fundApprovalRow?.faStatus}
          row={processRecordsRow}
          ref={processRecordsBasicInfo}/>
      </CustomDraggableModal>

      { /*单笔资金审批关联资金出纳*/ }
      <BankStatementTableModal
        open={BankStatementTableModalOpen}
        handleOk={handleBankStatementTableModalOk}
        onChange={v => setBankStatementTableModalOpen(v)}
      ></BankStatementTableModal>

      { /*与资金审批绑定的资金出纳  有解绑功能*/ }
      <BankStatementModal
        bankStatementModalFundApprovalId={bankStatementModalFundApprovalId}
        dataSource={bankStatementModalDataSource}
        open={BankStatementModalOpen}
        handleOk={handleBankStatementModalOk}
        handleCancel={handleBankStatementModalCancel}
      ></BankStatementModal>

      { /*合并资金审批 两种方案*/ }
      <MergeFundApprovalModal
        dataSource={mergeFundApprovalModalDataSource}
        open={MergeFundApprovalModalOpen}
        handleOk={handleMergeFundApprovalModalOk}
        handleCancel={handleMergeFundApprovalModalCancel}
      ></MergeFundApprovalModal>

      <CustomForm
        title={'资金审批'}
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={isModalVisible}
        submitterId={submitterId}
        confirmLoading={confirmLoading}
        userId={fundApprovalRow?.personResponsible}
        status={fundApprovalRow?.faStatus}
        extraDisabled={!fundApprovalRow?.id}
        isView={isView}
        extraOnClick={() => handlePrint(fundApprovalRow)}
        item={[
          {
            key: 'handleBankStatementClick',
            icon: <RetweetOutlined/>,
            hidden: !FORM_INITIATE_STATE.includes(fundApprovalRow?.faStatus),
            onClick: () => handleBankStatementClick(fundApprovalRow)
          },
          {
            key: 'handleContractSettlementClick',
            icon: <RetweetOutlined/>,
            hidden: !FORM_INITIATE_STATE.includes(fundApprovalRow?.faStatus),
            onClick: () => handleContractSettlementClick(fundApprovalRow)
          },
          {
            key: 'handleTaxAccountingClick',
            icon: <RetweetOutlined/>,
            hidden: !FORM_INITIATE_STATE.includes(fundApprovalRow?.faStatus),
            onClick: () => handleTaxAccountingClick(fundApprovalRow)
          },
          {
            key: 'handleCopyClick',
            icon: <FieldTimeOutlined/>,
            disabled: !fundApprovalRow?.id,
            onClick: () => handleCopyClick(fundApprovalRow)
          },
          {
            key: 'handleBulkTellerClick',
            icon: <FieldTimeOutlined/>,
            disabled: !fundApprovalRow?.id,
            onClick: () => handleBulkTellerClick(fundApprovalRow)
          },
          {
            key: 'handleProcessRecordsClick',
            icon: <FieldTimeOutlined/>,
            disabled: !fundApprovalRow?.id,
            onClick: () => handleProcessRecordsClick(fundApprovalRow)
          },
        ]}>
        <BasicInfo row={{...fundApprovalRow!}} ref={basicInfo} isView={isView} isBatchUpdate={isBatchUpdate}/>
      </CustomForm>

      <WindowPrint
        isSummary
        isHiddenTime
        title={printTitle}
        contentList={contentList}
        isModalOpen={isModalOpen}
        isPrintTable={isPrintTable}
        tableColumns={columns}
        dataSource={printDataSource}
        narrowShowColumn={narrowShowColumn}
        handleCancel={() => {
          setIsModalOpen(false)
          setSelectedRowKeys([])
        }}/>
    </>
  )
}))
