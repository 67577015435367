import React, {useEffect, useState} from 'react'
import './UserDetails.less'
import {Spin, Tabs} from "antd";
import {BasicInfo} from "@modules/HR/Components/BasicInfo";
import {Hire} from "@modules/HR/Components/Hire";
import {WorkTime} from "@modules/HR/Components/WorkTime";
import {Exit} from "@modules/HR/Components/Exit";
import {Document} from "@modules/HR/Components/Document";
import {useParams} from "react-router-dom";
import {allStores} from "@/stores";
import {observer} from "mobx-react";
import {WorkerRecruitmentStatus} from "@/config";

export const UserDetails = observer(() => {
  // 这个默认值会随着入职办理进度变化
  const getDefaultStatus = ()=>{
    let defaultStatus = 'basic';
    const searchParams = new URLSearchParams(window.location.search)
    const status = searchParams.get('status')
    if (status === WorkerRecruitmentStatus.pmConfirmed) {
      return 'hire'
    } else if (status === WorkerRecruitmentStatus.hired) {
      return 'exit'
    }
    return defaultStatus;
  }

  const [tabKey, setTabKey] = useState(getDefaultStatus())
  const {wrStore} = allStores;

  const params = useParams()
  useEffect(() => {
    wrStore.refreshWorkerRecruitment(params.onBoardingUserId!)
  }, [])


  return <div className="user-details-container">
    <div className='top-container margin-bottom-sm'>
      <Tabs
        activeKey={tabKey}
        onChange={setTabKey}
        items={[
          {
            label: "基本资料",
            key: "basic"
          },
          {
            label: "入职",
            key: "hire"
          },
          {
            label: "考勤",
            key: "workTime"
          },
          {
            label: "离职",
            key: "exit"
          },
          {
            label: "相关文档",
            key: "document"
          }
        ]}>
      </Tabs>
    </div>
    { wrStore.wr.loading && <Spin/> }
    { wrStore.wr.data && <>
      <div className={`content ${tabKey !== 'basic' ? 'hide' : ''}`}><BasicInfo/></div>
      <div className={`content ${tabKey !== 'hire' ? 'hide' : ''}`}><Hire/></div>
      <div className={`content ${tabKey !== 'workTime' ? 'hide' : ''}`}><WorkTime/></div>
      <div className={`content ${tabKey !== 'exit' ? 'hide' : ''}`}><Exit/></div>
      <div className={`content ${tabKey !== 'document' ? 'hide' : ''}`}><Document/></div>
    </>
    }
  </div>
})
