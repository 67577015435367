import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {DemoPageReq, DemoPageRow} from "@services/dto/demoPage";

export class DemoPageService {
  static async getDemoPage(req: DemoPageReq): Promise<Page<DemoPageRow>> {
    return await apiV1.get("/demoPage/search", {params: req})
  }

  static async addDemoPage(row: DemoPageRow): Promise<ResponseEntity<DemoPageRow>> {
    return await apiV1.post("/demoPage/save", {...row})
  }

  static async updateDemoPage(row: DemoPageRow): Promise<ResponseEntity<DemoPageRow>> {
    return await apiV1.put("/demoPage/update", {...row})
  }

  static async deleteDemoPage(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/demoPage/delete", {data: ids})
  }

  static async getOne(id: string): Promise<ResponseEntity<DemoPageRow[]>> {
    return await apiV1.get(`/demoPage/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponseEntity<DemoPageRow[]>> {
    return await apiV1.get(`/demoPage/getBatch/${ids.join(',')}`)
  }
}
