import {makeAutoObservable, runInAction} from "mobx";
import {Page, StateFullData} from "@services/dto/common";
import {SocialSecurityPaymentReq, SocialSecurityPaymentRow} from "@services/dto/socialSecurityPayment";
import {SocialSecurityPaymentService} from "@services/SocialSecurityPaymentService";

export class SocialSecurityPaymentStore {
  socialSecurityPaymentDatasource: StateFullData<Page<SocialSecurityPaymentRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getSocialSecurityPayment(req: SocialSecurityPaymentReq) {
    this.socialSecurityPaymentDatasource = {...this.socialSecurityPaymentDatasource, loading: true}
    SocialSecurityPaymentService.getSocialSecurityPayment(req).then(rsp => {
      runInAction(() => this.socialSecurityPaymentDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.socialSecurityPaymentDatasource = {...this.socialSecurityPaymentDatasource, loading: false, err})
    })
  }
}

export const socialSecurityPaymentStore = new SocialSecurityPaymentStore();
