import {apiV1} from "@services/apiHelper";
import {CompanyReq, CompanyRow, CreateCompanyReq, UpdateCompanyReq} from "@services/dto/company";
import {Page, ResponseEntity} from "@services/dto/common";

export class CompanyService {
  static async searchCompany(req: CompanyReq): Promise<Page<CompanyRow>> {
    return await apiV1.get("/company/search", {params: req})
  }

  static async createCompany(req: CreateCompanyReq): Promise<ResponseEntity<CompanyRow>> {
    return await apiV1.post("/company", {...req})
  }

  static async getCompany(): Promise<CompanyRow> {
    return await apiV1.get("/company")
  }

  static async updateCompany(req: UpdateCompanyReq): Promise<CompanyRow> {
    return await apiV1.put("/company", {...req})
  }

  static async getCompanyById(id: string): Promise<ResponseEntity<CompanyRow>> {
    return await apiV1.get(`/company/${id}`)
  }

  static async updateCompanyById(id: string, req: UpdateCompanyReq): Promise<ResponseEntity<CompanyRow>> {
    return await apiV1.put(`/company/${id}`, {...req})
  }

  static async deleteCompanyById(id: string): Promise<void> {
    return await apiV1.delete(`/company/${id}`)
  }

  static async deleteBatchCompanyByIds(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/company/deleteBatch", {data: {ids}})
  }
}