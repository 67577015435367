import {InsuranceRow, PageParam} from "@services/dto/Insurance";
import {Page, StateFullData} from "@services/dto/common";
import {makeAutoObservable, runInAction} from "mobx";
import {InsuranceService} from "@services/InsuranceService";

export class InsuranceStore {
  insuranceDatasource: StateFullData<Page<InsuranceRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getSocialInsurance(req: PageParam) {
    this.insuranceDatasource = {...this.insuranceDatasource, loading: true}
    InsuranceService.getSocialInsurance(req).then(rsp => {
      runInAction(() => this.insuranceDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.insuranceDatasource = {...this.insuranceDatasource, loading: false, err})
    })
  }
}

export const insuranceStore = new InsuranceStore()
