import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {Divider, Pagination, TreeSelect, TreeSelectProps} from "antd";
import React, {useEffect, useState} from "react";
import {InstitutionRow, PageParam} from "@services/dto/institution";
import {InstitutionService} from "@services/InstitutionService";

export interface PropsType {
  disabled?: boolean
  onChange?: (value: string, selectedOptions: InstitutionRow[]) => void
  placeholder?: string
  value?: string
}

export const TreeSelectInstitution = observer((props: PropsType) => {
  const {institutionStore} = allStores
  const [value, setValue] = useState<string>()
  const [datasource, setDatasource] = useState<InstitutionRow[]>([])
  const [institutionReq, setInstitutionReq] = useState<PageParam>({perPage: 10, pageNum: 1, pid: '0'})
  const [total, setTotal] = useState<number>(0)

  useEffect(() => {
    if (!institutionStore.institutionDatasource.data) {
      refreshData(institutionReq);
    }
  }, [])

  const refreshData = (req: PageParam) => {
    institutionStore.getInstitutions(req)
  }

  useEffect(() => {
    if (institutionStore.institutionDatasource.data) {
      setDatasource(institutionStore.institutionDatasource.data.items)
      setTotal(institutionStore.institutionDatasource.data.total)
    }
  }, [institutionStore.institutionDatasource])

  useEffect(() => {
    if (props.value) {
      setValue(props.value)
    }
  }, [props.value])

  const onLoadData: TreeSelectProps['loadData'] = ({id}) =>
    new Promise((resolve) => {
      InstitutionService.getInstitutions({pageNum: 1, perPage: 999, pid: id}).then(rsp => {
        const data = [...datasource]
        const obj = data.find(item => item.id === id)
        if(obj) {
          obj.children = rsp.items
        }
        setDatasource(data)
        resolve(undefined);
      })
    });

  const onChange = (newValue: string) => {
    setValue(newValue);
    if (props.onChange) {
      props.onChange(newValue, datasource);
    }
  };

  const handlePageChange = (page: number, pageSize: number) => {
    const req = {...institutionReq}
    req.pageNum = page
    req.perPage = pageSize
    setInstitutionReq(req)
    refreshData(req)
  }

  return (
    <TreeSelect
      style={{ width: '100%' }}
      value={value}
      disabled={props.disabled}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder={props.placeholder || "请选择"}
      onChange={onChange}
      loadData={onLoadData}
      fieldNames={{label: "name", value: "id", children: "children"}}
      treeData={datasource}
      dropdownRender={(menu) => (
        <>
          { menu }
          <Divider style={{ margin: '8px 0' }} />
          <Pagination
            total={total}
            size={"small"}
            onChange={handlePageChange}
            pageSize={institutionReq.perPage}
          />
        </>
      )}
    />
  )
})
