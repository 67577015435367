import {InstitutionRow, PageParam} from "@services/dto/institution";
import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";

export class InstitutionService {
  static async getInstitutions(req: PageParam): Promise<Page<InstitutionRow>> {
    return await apiV1.get("/institutions", {params: req})
  }

  static async addInstitutions(row: InstitutionRow): Promise<ResponseEntity<InstitutionRow>> {
    return await apiV1.post("/institutions", {...row})
  }

  static async updateInstitutions(row: InstitutionRow): Promise<ResponseEntity<InstitutionRow>> {
    return await apiV1.put("/institutions", {...row})
  }

  static async deleteInstitutions(ids: string[]): Promise<ResponseEntity<InstitutionRow>> {
    return await apiV1.delete("/institutions", {data: ids})
  }
}
