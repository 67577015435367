import {Col, Form, Input, Row} from "antd";
import React, {useEffect} from "react";
import {FormInstance} from "antd/es";

export const UpdatePassword = React.forwardRef<FormInstance>((props, ref) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
  }, [])

  return (
    <>
      <Form
        name="basic"
        form={form}
        ref={ref}
        autoComplete="off">
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="请输入原密码"
              name="oldPassWord"
              rules={[{required: true, message: '请输入原密码!'}]}>
              <Input.Password placeholder="请输入原密码"/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="请输入新密码"
              name="firstPassWord"
              rules={[{required: true, message: '密码由8-16位数字、大小写字母跟特殊字符组成!', pattern: /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,16}/}]}>
              <Input.Password placeholder="请输入新密码"/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="请确认新密码"
              name="secondPassWord"
              rules={[{required: true, message: '密码由8-16位数字、大小写字母跟特殊字符组成!', pattern: /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,16}/}]}>
              <Input.Password placeholder="请确认新密码"/>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
})