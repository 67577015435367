import {observer} from "mobx-react";
import React from "react";
import {Statistic} from "antd";

export interface PropsType {
  value: number | any
  className?: string
  isPercentage?: boolean
  style?: React.CSSProperties | undefined
}

export const TableMoneyText = observer((props: PropsType) => {
  const defaultStyle: React.CSSProperties = {
    fontSize: 14,
    color: props.value <= 0 ? '#B84A5B' : '#262626'
  }

  return <>
    <Statistic
      valueStyle={{
        ...defaultStyle,
        ...props.style
      }}
      className={`d-line-block ${props.className}`}
      suffix={props.isPercentage ? "%" : ""}
      title=""
      precision={props.isPercentage ? 0 : 2}
      value={props.isPercentage ? props.value * 100 : props.value}/>
  </>
})
