import React, {useEffect, useState} from "react";
import {DatePicker} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import dayjs from "dayjs";
import {Any} from "@common/types";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {CustomAuthHeaderAction, PropsType as ParentPropsType} from "@components/CustomAuthHeaderAction";
import {DocumentApprovalReq} from "@services/dto/documentApproval";

export interface PropsType extends ParentPropsType {
  updateCount?: number, // 更新渲染标志
  refreshData?: (req: DocumentApprovalReq) => void,
  collapsed?: boolean, // 是否展开
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<DocumentApprovalReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: DocumentApprovalReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleEfpPayTimeChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.startDate = dateStrings[0]
    req.endDate = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonResponsibleNameChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfpStatusChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.daStatus = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleOrganizersUserIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.organizersUserId = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined

    req.startDate = undefined
    req.endDate = undefined
    req.personResponsible = undefined
    req.personResponsibleName = undefined
    req.organizersUserId = undefined // 主办人员
    req.organizersUserName = undefined // 主办人员名
    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <CustomAuthHeaderAction
        size={props.size}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <DatePicker.RangePicker
            value={searchReq?.startDate ? [dayjs(searchReq?.startDate), dayjs(searchReq?.endDate)] : [undefined, undefined]}
            placeholder={['制单开始日期', '制单结束日期']}
            onChange={handleEfpPayTimeChange}
            allowClear
            className={"width-100-percentage"}/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
          <SelectCooperativeUnit
            onChange={handleCooperationUnitChange}
            value={searchReq?.cooperationUnit}
            allowClear
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectPersonCharge
            updateCount={props.updateCount}
            onChange={handlePersonResponsibleNameChange}
            className={"width-100-percentage"}
            value={searchReq?.personResponsible}
            placeholder={"请选择办理人员"}
            allowClear/>,
          <SelectPersonCharge
            onChange={handleOrganizersUserIdChange}
            className={"width-100-percentage"}
            placeholder={"请选择主办人员"}
            value={searchReq?.organizersUserId}
            allowClear/>,
          <SelectProcessStatus
            onChange={handleEfpStatusChange}
            allowClear
            value={searchReq?.daStatus}
            placeholder={'请选择办理进度'}
            className={"width-100-percentage"}/>,
        ] : []}/>
    </>
  )
}))
