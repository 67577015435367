import React, { useEffect } from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import { FormInstance } from "antd/es";
import { observer } from "mobx-react";
import {InvoiceRegistrationRow} from "@services/dto/invoiceRegistration";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {allStores} from "@/stores";
import {SelectFiling} from "@components/SelectFiling";
import {SelectDict} from "@components/SelectDict";
import {ProjectRow} from "@services/dto/project";
import {ONFILE} from "@/config";

export interface PropsType {
  row: InvoiceRegistrationRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores

  useEffect(() => {
    form.resetFields()
    const InvoiceRegistrationRow = { ...props.row }
    filingStore.setFilingFaCode(InvoiceRegistrationRow.code)
    if (InvoiceRegistrationRow.id) {

    }
    form.setFieldsValue(InvoiceRegistrationRow);
  }, [props.row])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    const row = options.find(o=> value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 14 }}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item hidden name="code">
          <Input />
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="fsDate"
            rules={[{ required: false, message: '请输入制单日期!' }]}>
            <DatePicker placeholder={"请输入制单日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        {
          props.isView && <Col span={24}>
            <Form.Item
              label="制单编号"
              name="fsCode"
              rules={[{ required: false, message: '请输入制单编号!' }]}>
              <Input placeholder="请输入制单编号" />
            </Form.Item>
          </Col>
        }
        <Col span={24}>
          <Form.Item
            label="税费类别"
            name="fsType"
            rules={[{ required: false, message: '请输入税费类别!' }]}>
            <SelectDict code={'tax_accounting_type '} placeholder={'请选择税费类别'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="税费摘要"
            name="fsDesc"
            rules={[{ required: false, message: '请输入费用摘要!' }]}>
            <Input.TextArea placeholder="请输入费用摘要" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="金额"
            name="fsAmount"
            rules={[{ required: false, message: '请输入金额!' }]}>
            <InputNumber min={0} precision={2} placeholder="请输入金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户"
            name="fsAccountPay"
            rules={[{ required: false, message: '请输入付款账户!' }]}>
            <Input placeholder="请输入付款账户" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户"
            name="fsAccountIn"
            rules={[{ required: false, message: '请输入收款账户!' }]}>
            <Input placeholder="请输入收款账户" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="irStatus"
            rules={[{ required: false, message: '请输入办理进度!' }]}>
            <SelectDict
              code={'common_type3'}
              disabled={props.row.irStatus === ONFILE}
              placeholder={'请选择办理进度'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任人"
            name="personResponsible"
            rules={[{ required: true, message: '请选择责任人!' }]}>
            <SelectPersonCharge className={"width-100-percentage"} placeholder={"请选择负责人"} allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{ required: true, message: '请选择责任部门!' }]}>
            <SelectProject placeholder={"请选择责任部门"} onChange={handleProjectChange} allowClear fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{ required: true, message: '请输入协作单位!' }]}>
            <SelectCooperativeUnit allowClear fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="存档批号"
            name="fsFile"
            rules={[{ required: false, message: '请选择存档批号!' }]}>
            <SelectFiling placeholder={"请选择存档批号"} allowClear />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
