import {InsuranceRow, PageParam} from "@services/dto/Insurance";
import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";

export class InsuranceService {
  static async getSocialInsurance(req: PageParam): Promise<Page<InsuranceRow>> {
    return await apiV1.get("/socialInsurance", {params: req})
  }

  static async addSocialInsurance(row: InsuranceRow): Promise<ResponseEntity<InsuranceRow>> {
    return await apiV1.post("/socialInsurance", {...row})
  }

  static async updateSocialInsurance(row: InsuranceRow): Promise<ResponseEntity<InsuranceRow>> {
    return await apiV1.put("/socialInsurance", {...row})
  }

  static async deleteSocialInsurance(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/socialInsurance", {data: ids})
  }
}
