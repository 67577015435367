import React, {FC} from "react";
import {observer} from "mobx-react";
import {Button, Card, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {DeleteOutlined} from "@ant-design/icons";
import {PersonnelChangeRow} from "@services/dto/personnelChange";

export interface PropsType {
  className?: string
  loading: boolean
  total: number
  perPage: number
  pageNum: number
  selectedRowKeys: React.Key[]
  dataSource: PersonnelChangeRow[]
  handlePageChange: (page: number, pageSize: number) => void
  handleChange: (selectedRowKeys: React.Key[], selectedRows: PersonnelChangeRow[]) => void
  handleView: (record: PersonnelChangeRow) => void
  handleDeleteBatch: (record: PersonnelChangeRow) => void
}

export const ContentTable:FC<PropsType> = observer((props: PropsType) => {
  const columns: ColumnsType<PersonnelChangeRow> = [
    {
      title: '姓名',
      dataIndex: ['user', 'name'],
      align: "center",
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => { props.handleView(record) }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '时间',
      dataIndex: ['releaseTime'],
      align: "center"
    },
    {
      title: '原项目',
      dataIndex: ['oldCompanyProject', 'name'],
      align: "center"
    },
    {
      title: '原队部',
      dataIndex: "oldProfessionalLeaguesName",
      align: "center"
    },
    {
      title: '原职位',
      dataIndex: ['oldProfessional'],
      align: "center"
    },
    {
      title: '现项目',
      dataIndex: ['newCompanyProject', 'name'],
      align: "center"
    },
    {
      title: '现队部',
      dataIndex: "newProfessionalLeaguesName",
      align: "center"
    },
    {
      title: '现职位',
      dataIndex: ['newProfessional'],
      align: "center"
    },
    {
      title: '操作',
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button shape="round" className={"delete-btn"} size={"middle"}
            icon={<DeleteOutlined/>} onClick={() => { handleDeleteBatch(record) }}>删除</Button>
        </Space>
      ),
    },
  ];

  const handlePageChange = (page: number, pageSize: number) => {
    props.handlePageChange(page, pageSize)
  }

  const handleChange = (selectedRowKeys: React.Key[], selectedRows: PersonnelChangeRow[]) => {
    props.handleChange(selectedRowKeys, selectedRows);
  }

  const handleDeleteBatch = (record: PersonnelChangeRow) => {
    props.handleDeleteBatch(record)
  }
  
  return (
    <>
      <Card className={props.className}>
        <Table
          rowKey={'id'}
          dataSource={props.dataSource}
          columns={columns}
          size={"middle"}
          loading={props.loading}
          pagination={{
            total: props.total,
            position: ['bottomCenter'],
            pageSize: props.perPage,
            current: props.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`, onChange: handlePageChange
          }}
          rowSelection={{selectedRowKeys: props.selectedRowKeys, onChange: handleChange}}/>
      </Card>
    </>
  )
})