import {Page, StateFullData} from "@services/dto/common";
import {PersonCascader, TalentReq, TalentRow} from "@services/dto/talent";
import {makeAutoObservable, runInAction} from "mobx";
import {TalentService} from "@services/TalentService";

export class TalentStore {
  talentDatasource: StateFullData<Page<TalentRow>> = {}
  talentFatherSonData: StateFullData<PersonCascader[]> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getTalent(req: TalentReq) {
    this.talentDatasource = {...this.talentDatasource, loading: true}
    TalentService.getTalent(req).then(rsp => {
      runInAction(() => {this.talentDatasource = {data: rsp, loading: false}})
    }).catch(e => {
      runInAction(() => this.talentDatasource = {...this.talentDatasource, err: e, loading: false})
    })
  }

  getFatherAndSonStructure() {
    this.talentFatherSonData = {...this.talentFatherSonData, loading: true}
    TalentService.getFatherAndSonStructure().then(rsp => {
      runInAction(() => this.talentFatherSonData = {data: rsp.data, loading: false})
    }).catch(err => {
      runInAction(() => this.talentFatherSonData = {...this.talentFatherSonData, loading: false, err})
    })
  }
}

export const talentStore = new TalentStore();
