import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {HeaderAction} from "@components/HeaderAction";
import {ColumnsType} from "antd/es/table";
import {AccountSalesReq} from "@services/dto/accountSales";
import {CustomInputNumber} from "@components/CustomInputNumber";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {Any} from "@common/types";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {Select} from "antd";
import {SelectProject} from "@components/SelectProject";

export interface PropsType {
  updateCount?: number, // 更新渲染标志
  collapsed?: boolean, // 是否展开
  handleShowColumnReset?: () => void, // 重置列操作
  setCollapsed?: (v: boolean) => void, // 展开事件
  searchReq?: AccountSalesReq, // 请求参数
  selectedRowKeys?: React.Key[], // 所选行
  handleDeleteBatch?: () => void,
  handleAdd?: () => void,
  handleTableColumnsChange?: (value: string[]) => void,
  showColumn?: string[],
  tableColumns?: ColumnsType,
  refreshData?: (req: AccountSalesReq) => void,
  size?: number
  isHiddenOptions?: boolean
  locationPathname?: string
  items?: React.ReactNode[]
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<AccountSalesReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: AccountSalesReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleSlNumChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.slNum1 = start
    req.slNum2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleSlPriceChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.slPrice1 = start
    req.slPrice2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleSlAmountChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.slAmount1 = start
    req.slAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleSlTaxRateChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.slTaxRate1 = start
    req.slTaxRate2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleSlTaxAmountChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.slTaxAmount1 = start
    req.slTaxAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleSlPurchaserChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.slPurchaser = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleSlSellerChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.slSeller = value
    setSearchReq(req)
    refreshData(req)
  }

  const handlePerSonChargeChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleStatusCommonChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.statusCommon = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined

    req.slNum1 = undefined
    req.personResponsible = undefined
    req.slNum2 = undefined
    req.slPrice1 = undefined
    req.slPrice2 = undefined
    req.slAmount1 = undefined
    req.slAmount2 = undefined
    req.slTaxRate1 = undefined
    req.slTaxRate2 = undefined
    req.slTaxAmount1 = undefined
    req.slTaxAmount2 = undefined
    req.slPurchaser = undefined
    req.slSeller = undefined
    req.statusCommon = undefined

    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <HeaderAction
        size={props.size}
        isHiddenOptions={props.isHiddenOptions}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        selectedRowKeys={props.selectedRowKeys}
        handleDeleteBatch={props.handleDeleteBatch}
        handleAdd={props.handleAdd}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <CustomInputNumber
            value={[searchReq?.slNum1, searchReq?.slNum2]}
            onSearch={handleSlNumChange}
            className={'width-100-percentage'}
            placeholder={['数量最小值', '数量最大值']}/>,
          <CustomInputNumber
            value={[searchReq?.slPrice1, searchReq?.slPrice2]}
            onSearch={handleSlPriceChange}
            className={'width-100-percentage'}
            placeholder={['单价最小值', '单价最大值']}/>,
          <CustomInputNumber
            value={[searchReq?.slAmount1, searchReq?.slAmount2]}
            onSearch={handleSlAmountChange}
            className={'width-100-percentage'}
            placeholder={['金额最小值', '金额最大值']}/>,
          <CustomInputNumber
            value={[searchReq?.slTaxRate1, searchReq?.slTaxRate2]}
            onSearch={handleSlTaxRateChange}
            className={'width-100-percentage'}
            placeholder={['税率最小值', '税率最大值']}/>,
          <CustomInputNumber
            value={[searchReq?.slTaxAmount1, searchReq?.slTaxAmount2]}
            onSearch={handleSlTaxAmountChange}
            className={'width-100-percentage'}
            placeholder={['税额最小值', '税额最大值']}/>,
          <SelectCooperativeUnit
            allowClear
            placeholder="请选择购买方"
            value={searchReq?.slPurchaser}
            onChange={handleSlPurchaserChange}
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectCooperativeUnit
            allowClear
            placeholder="请选择销售方"
            value={searchReq?.slSeller}
            onChange={handleSlSellerChange}
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
          <SelectCooperativeUnit
            onChange={handleCooperationUnitChange}
            value={searchReq?.cooperationUnit}
            allowClear
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectPersonCharge
            onChange={handlePerSonChargeChange}
            className={"width-100-percentage"}
            placeholder={"请选择经办记录"}
            value={searchReq?.personResponsible}
            allowClear/>,
          <Select
            onChange={handleStatusCommonChange}
            allowClear
            value={searchReq?.statusCommon}
            options={[
              {label: '完成', value: '完成'},
              {label: '容缺', value: '容缺'},
            ]}
            placeholder={'请选择校验办理进度'}
            className={"width-100-percentage"}/>,
        ] : []}/>
    </>
  )
}))
