import {makeAutoObservable, runInAction} from "mobx";
import {Page, StateFullData} from "@services/dto/common";
import {EmployeeFeePayService} from "@services/EmployeeFeePayService";
import {EmployeeFeePayReq, EmployeeFeePayRow} from "@services/dto/employeeFeePay";

export class EmployeeFeePayStore {
  employeeFeePayDatasource: StateFullData<Page<EmployeeFeePayRow>> = {}
  employeeFeePaySearchReq: EmployeeFeePayReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  getEmployeeFeePay(req: EmployeeFeePayReq) {
    this.employeeFeePayDatasource = {...this.employeeFeePayDatasource, loading: true}
    this.employeeFeePaySearchReq = {...this.employeeFeePaySearchReq, ...req}
    EmployeeFeePayService.getEmployeeFeePay(req).then(rsp => {
      runInAction(() => this.employeeFeePayDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.employeeFeePayDatasource = {...this.employeeFeePayDatasource, loading: false, err})
    })
  }
}

export const employeeFeePayStore = new EmployeeFeePayStore();
