import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {Button, FormInstance, message, Modal, Space, Table, Typography} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {accAdd, logger, parseURLParams, safeCompute, showErr} from "@common/utils";
import {BasicInfo} from "@modules/ContractSettlement/Components/BasicInfo";
import {ContractSettlementService} from "@services/ContractSettlementService";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import {LabelValue, ResponseEntity} from "@services/dto/common";
import {WindowPrint} from "@modules/WindowPrint";
import {HeaderActionCom} from "@modules/ContractSettlement/Components/HeaderActionCom";
import {useLocation, useNavigate} from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FieldTimeOutlined,
  MergeCellsOutlined,
  PlusOutlined,
  PrinterOutlined,
  RetweetOutlined
} from "@ant-design/icons";
import {InvoiceRegisterService} from "@services/InvoiceRegisterService";
import {InvoiceRegisterRow} from "@services/dto/invoiceRegister";
import {InvoiceRegister} from "@modules/InvoiceRegister";
import {ContractListRow} from "@services/dto/contractList";
import {ContractList} from "@modules/ContractList";
import {FundApprovalRow} from "@services/dto/fundApproval";
import {FundApproval} from "@modules/FundApproval";
import {ProjectService} from "@services/ProjectService";
import {TableContractLists} from "@components/TableContractLists";
import {TableFundApprovals} from "@components/TableFundApprovals";
import {TableInvoiceRegistrations} from "@components/TableInvoiceRegistrations";
import {FundApprovalService} from "@services/FundApprovalService";
import {TableFilingText} from "@components/TableFilingText";
import {BasicInfo as ProcessRecordsBasicInfo} from "@modules/ProcessRecords/Components/BasicInfo";
import {CustomDraggableModal} from "@components/CustomDraggableModal";
import dayjs from "dayjs";
import {
  ContractSettlementReq,
  ContractSettlementRow,
  ContractSettlementSummaryTotal
} from "@services/dto/contractSettlement";
import {
  APPROVE_FORM_TRANS_STATE,
  BLACK_LIST,
  DATE_FMT,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  FORM_ALTERNATE_TEXT,
  FORM_CLOSE_TEXT,
  FORM_COMMIT_TEXT,
  FORM_DISABLED_STATE,
  FORM_HANDOVER_TEXT,
  FORM_INITIATE_STATE,
  FORM_PERSON_RESPONSIBLE_WHITELIST,
  FORM_RETURN_TEXT,
  FORM_REVOCATION_TEXT,
  LARGE_MODAL_WIDTH,
  LARGE_MODAL_WIDTH_OTHER,
  LARGER_MODAL_WIDTH,
  READY,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import {ProcessRecordsRow} from "@services/dto/processRecords";
import {ProcessRecordsService} from "@services/ProcessRecordsService";
import {CustomForm} from "@components/CustomForm";
import {ProcessRecords} from "@modules/ProcessRecords";
import {TableAuthOptions} from "@components/CustomAuthOptions";
import {MergeContractSettlementModal} from "@modules/ContractSettlement/Components/MergeContractSettlementModal";

const wideShowColumn: string[] = [
  'csDate',
  'csCode',
  'csEndType',
  'csSummany',
  'csEndAmount-summation',
  // 'csEndOutAccountId',
  // 'csEndInAccountId',
  // 'contractLists',
  // 'fundingApprovals',
  // 'csBook',
  // 'outboundInbound',
  // 'invoiceRegistrations',
  // 'personResponsible',
  // 'responsibilityProject',
  // 'cooperationUnit',
  // 'csFile',
  'amountRealizedDate',
  'amountRealized-summation',
  'realizeBalance-summation',
  'csStatus',
  'action',
]

const narrowShowColumn: string[] = [
  'csDate',
  'csCode',
  'csEndType',
  'csSummany',
  'csEndAmount-summation',
  // 'csEndOutAccountId',
  // 'csEndInAccountId',
  // 'contractLists',
  // 'fundingApprovals',
  // 'csBook',
  // 'outboundInbound',
  // 'invoiceRegistrations',
  // 'personResponsible',
  // 'responsibilityProject',
  // 'cooperationUnit',
  // 'csFile',
  // 'amountRealizedDate',
  // 'amountRealized-summation',
  // 'realizeBalance-summation',
  'csStatus',
  'action',
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: ContractSettlementRow[] // 数据源
}

export const TableName = 'contractSettlement'

/**
 * 提交前 => 转换数据
 * @param row
 */
export const dataConversionContractSettlement = async (row: ContractSettlementRow): Promise<ContractSettlementRow> => {

  /**
   * 数据转换
   * 1. 保证数据类型正确
   * 2. 保证正确清空值
   */

  if (row.csDate && typeof row.csDate === 'object') {
    row.csDate = row.csDate.format(DATE_FMT);
  } else if (!row.csDate) {
    row.csDate = ''
  }
  if (!row.csEndType) {
    row.csEndType = ""
  }
  if (!row.csSummany) {
    row.csSummany = ""
  }
  if (!row.csEndAmount) {
    row.csEndAmount = 0
  }
  if (!row.csEndOutAccountId) {
    row.csEndOutAccountId = ""
    row.csEndOutAccount = ""
    row.csEndOutAccountTitle = ""
    row.csEndOutBankAccount = ""
    row.csEndOutDepositBank = ""
  }
  if (!row.csEndInAccountId) {
    row.csEndInAccountId = ""
    row.csEndInAccount = ""
    row.csEndInAccountTitle = ""
    row.csEndInBankAccount = ""
    row.csEndInDepositBank = ""
  }
  if (row.contractListIds && typeof row.contractListIds === 'string') {
    row.contractListIds = row.contractListIds.split(',')
  } else {
    if (row.contractLists) {
      row.contractListIds = row.contractLists.map(o => o.id)
    } else {
      row.contractListIds = []
    }
  }
  if (row.invoiceRegistrations && typeof row.invoiceRegistrations === 'object') {
    row.invoiceRegistrationIds = (row.invoiceRegistrations as InvoiceRegisterRow[])?.map(o => o.id)
    const irTaxRateSum: number[] = row.invoiceRegistrations.map((item) => {
      return item.irTaxRateSum
    })
    if (irTaxRateSum.length) {
      row.invoiceRegistrationMoney = irTaxRateSum.reduce((acc, cur) => {
        return accAdd(acc, cur)
      })
    }
  }

  /**
   * 以下字段 带逻辑 必须有值
   */

  // 协作单位
  row.cooperationUnit = []
  if (row.csEndOutAccountId && !BLACK_LIST.includes(row.csEndOutAccountTitle)) {
    row.cooperationUnit.push(row.csEndOutAccountTitle)
  }
  if (row.csEndInAccountId && !BLACK_LIST.includes(row.csEndInAccountTitle)) {
    row.cooperationUnit.push(row.csEndInAccountTitle)
  }
  row.cooperationUnit = row.cooperationUnit
    .filter((v, i, self) => self.indexOf(v) === i) // 去重
    .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
    .join(',');

  // 责任部门编号
  if (row.responsibilityProject) {
    const names: string[] = typeof row.responsibilityProject === 'object' ? row.responsibilityProject : row.responsibilityProject.split(',')
    const responsibilityProject = await ProjectService.getBatchByName(names)
    row.code = responsibilityProject
      .map(o => o.code)
      .filter(o => o)
      .filter((v, i, self) => self.indexOf(v) === i)
      .join(',')
  } else {
    row.responsibilityProject = ''
  }

  return row
}

/**
 * 提交数据
 * @param row
 */
export const insertOrUpdateContractSettlement = async (row: ContractSettlementRow) => {
  if (row.id) {
    return await ContractSettlementService.updateContractSettlement(row)
  } else {
    return await ContractSettlementService.addContractSettlement(row)
  }
}

export const ContractSettlement = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    processStore,
    securityStore,
    contractSettlementStore,
    headerSearchStore,
    fundApprovalStore,
    tableColumnsStore,
    screenResolutionStore
  } = allStores
  const basicInfo = React.createRef<FormInstance>();
  const processRecordsBasicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<ContractSettlementRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [contractSettlementRow, setContractSettlementRow] = useState<ContractSettlementRow>()
  const [isPrintTable, setIsPrintTable] = useState<boolean>(false)
  const [printDataSource, setPrintDataSource] = useState<ContractSettlementRow[]>([])
  const [isView, setIsView] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentList, setContentList] = useState<LabelValue[]>([]);
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<ContractSettlementRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [invoiceRegisterOpen, setInvoiceRegisterOpen] = useState<boolean>(false)
  const [invoiceRegisterDataSource, setInvoiceRegisterDataSource] = useState<InvoiceRegisterRow[]>([])
  const [contractListOpen, setContractListOpen] = useState<boolean>(false)
  const [contractListDataSource, setContractListDataSource] = useState<ContractListRow[]>([])
  const [contractSettlementId, setContractSettlementId] = useState<string>('');
  const [fundApprovalSelectedRowKeys, setFundApprovalSelectedRowKeys] = useState<React.Key[]>([]);
  const [fundApprovalOpen, setFundApprovalOpen] = useState<boolean>(false)
  const [mergeContractSettlementOpen, setMergeContractSettlementOpen] = useState<boolean>(false)
  const [mergeContractSettlementDataSource, setMergeContractSettlementDataSource] = useState<ContractSettlementRow[]>([])
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [fundApprovalDataSource, setFundApprovalDataSource] = useState<FundApprovalRow[]>([])
  const [processRecordsIsOpen, setProcessRecordsIsOpen] = useState<boolean>(false)
  const [processRecordsRow, setProcessRecordsRow] = useState<ProcessRecordsRow>()
  const [processRecordsOpen, setProcessRecordsOpen] = useState<boolean>(false)
  const [processRecordsDataSource, setProcessRecordsDataSource] = useState<ProcessRecordsRow[]>([])
  const [submitRow, setSubmitRow] = useState<ContractSettlementRow>()
  const [submitterId, setSubmitterId] = useState<string>()
  const [latestProcess, setLatestProcess] = useState<string>()
  const [latestProcessBatch, setLatestProcessBatch] = useState<string[]>()
  const [isBatchUpdate, setIsBatchUpdate] = useState(false);
  const [summaryTotalObj, setSummaryTotalObj] = useState<ContractSettlementSummaryTotal>({
    csEndAmount: 0,
    amountRealized: 0,
    realizeBalance: 0
  })
  const [searchReq, setSearchReq] = useState<ContractSettlementReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...contractSettlementStore.contractSettlementReq
  })

  const columns: ColumnsType<ContractSettlementRow> = [
    {
      title: '制单日期',
      dataIndex: 'csDate',
      key: 'csDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'csCode',
      key: 'csCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '结算类别',
      dataIndex: 'csEndType',
      key: 'csEndType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '结算摘要',
      dataIndex: 'csSummany',
      key: 'csSummany',
      width: TABLE_CELL_WIDTH_2x,
      // align: 'center',
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '结算金额(元)',
      dataIndex: 'csEndAmount',
      key: 'csEndAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '付款账户',
      dataIndex: 'csEndOutAccount',
      key: 'csEndOutAccount',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '收款账户',
      dataIndex: 'csEndInAccount',
      key: 'csEndInAccount',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联商务合同',
      dataIndex: 'contractLists',
      key: 'contractLists',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableContractLists
            value={text as ContractListRow[]}
            onClick={() => {
              setContractListDataSource(text)
              setContractListOpen(true)
            }}></TableContractLists>
        </Space>
      )
    },
    {
      title: '关联资金审批',
      dataIndex: 'fundingApprovals',
      key: 'fundingApprovals',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text, record) => (
        <Space>
          <TableFundApprovals
            value={text as FundApprovalRow[]}
            onClick={() => {
              setContractSettlementId(record.id)
              setFundApprovalDataSource(text)
              setFundApprovalOpen(true)
            }}></TableFundApprovals>
        </Space>
      )
    },
    {
      title: '关联验收证书',
      dataIndex: 'csBook',
      key: 'csBook',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text} showValue={
            <Typography.Text type="success" className={'c-pointer'}>{ text }</Typography.Text>
          }></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联出库入库',
      dataIndex: 'outboundInbound',
      key: 'outboundInbound',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text} showValue={
            <Typography.Text type="success" className={'c-pointer'}>{ text }</Typography.Text>
          }></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联发票登记',
      dataIndex: 'invoiceRegistrations',
      key: 'invoiceRegistrations',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableInvoiceRegistrations
            value={text as InvoiceRegisterRow[]}
            onClick={() => {
              setInvoiceRegisterDataSource(text)
              setInvoiceRegisterOpen(true)
            }}></TableInvoiceRegistrations>
        </Space>
      )
    },
    {
      title: '关联发票金额(元)',
      dataIndex: 'invoiceRegistrationMoney',
      key: 'invoiceRegistrationMoney',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text, record) => (
        <Space>
          {
            record.invoiceRegistrations && record.invoiceRegistrations.length > 0
              ? <TableMoneyText value={record.invoiceRegistrations.map((item) => {
                return item.irTaxRateSum
              })?.reduce((acc, cur) => {
                return accAdd(acc, cur)
              })}></TableMoneyText>
              : <TableMoneyText value={0}/>
          }

        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'csFile',
      key: 'csFile',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableFilingText id={record.csFile} value={record.csFileCode}></TableFilingText>
        </Space>
      )
    },
    {
      title: '实现日期',
      dataIndex: 'amountRealizedDate',
      key: 'amountRealizedDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '实现金额(元)',
      dataIndex: 'amountRealized',
      key: 'amountRealized-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '余额',
      dataIndex: 'realizeBalance',
      key: 'realizeBalance-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <Space>
            <TableMoneyText value={text}></TableMoneyText>
          </Space>
        </Space>
      )
    },
    {
      title: '办理人员',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '主办人员',
      dataIndex: 'organizersUserName',
      key: 'organizersUserName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'csStatus',
      key: 'csStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableAuthOptions
          mainBtn={['handleEdit', 'handleView']}
          items={[
            {
              key: 'handleView',
              icon: <EllipsisOutlined/>,
              onClick: () => handleView(record)
            },
            {
              key: 'handleEdit',
              icon: <EditOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.csStatus),
              onClick: async () => await handleEdit(record)
            },
            {
              key: 'handlePrint',
              icon: <PrinterOutlined/>,
              onClick: () => handlePrint(record)
            },
            {
              key: 'handleDeleteBatch',
              icon: <DeleteOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.csStatus),
              onClick: () => handleDeleteBatch(record)
            },
          ]}></TableAuthOptions>
      )
    }
  ];

  /**
   * @description 初始化
   */
  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      setShowColumn(narrowShowColumn)
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  /**
   * @description 全局查询
   */
  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (contractSettlementStore.contractSettlementRow?.contractSettlementId) {
      handleAdd(contractSettlementStore.contractSettlementRow)
      contractSettlementStore.updateContractSettlementRow({
        ...contractSettlementStore.contractSettlementRow,
        contractSettlementId: undefined,
      })
    }
  }, [contractSettlementStore.contractSettlementRow])

  /**
   * @description 表格数据变化
   */
  useEffect(() => {
    if (contractSettlementStore.contractSettlementDatasource.data) {
      setDataSource(contractSettlementStore.contractSettlementDatasource.data.items || [])
      setTotal(contractSettlementStore.contractSettlementDatasource.data.total || 0)
    }
  }, [contractSettlementStore.contractSettlementDatasource.data])

  /**
   * @description 表格合计
   */
  useEffect(() => {
    const objInitValue = {
      csEndAmount: 0,
      amountRealized: 0,
      realizeBalance: 0
    }
    let obj = summaryTotalObj
    const datasource = props.dataSource ? props.dataSource : contractSettlementStore.contractSettlementDatasource.data?.items
    const arr = datasource?.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        csEndAmount: accAdd(acc.csEndAmount, cur.csEndAmount),
        amountRealized: accAdd(acc.amountRealized, cur.amountRealized),
        realizeBalance: accAdd(acc.realizeBalance, cur.realizeBalance),
      }), objInitValue);
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [contractSettlementStore.contractSettlementDatasource, selectedRowKeys])

  /**
   * 列配置变化
   */
  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => o.key !== 'action').filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, contractSettlementStore.collapsed, searchReq.pageNum, searchReq.perPage])

  useEffect(() => {
    if (contractSettlementStore.contractSettlementOption) {
      const [option, paramsStr] = contractSettlementStore.contractSettlementOption.split("?")
      if (option === 'add') {
        handleAdd()
        contractSettlementStore.updateContractSettlementOption(undefined)
      } else if (paramsStr) {
        const params = parseURLParams(paramsStr)
        if (option === 'edit') {
          ContractSettlementService.getOne(params.id).then(rsp => {
            if (rsp.code === 200) {
              handleEdit(rsp.data).then()
            }
          })
        }
      }
    }
  }, [contractSettlementStore.contractSettlementOption])

  const handleFundApprovalClick = async (record: ContractSettlementRow) => {

    // 获取详情
    // const {code: detailCode, data: row, message: detailMessage} = await ContractSettlementService.getOne(record.id)
    // if (detailCode !== 200) {
    //   message.warning(detailMessage).then()
    // }
    const row = contractSettlementStore.contractSettlementDatasource.data.items.find(o => o.id === record.id)

    // 注意发票登记
    let lnvoiceRegistration = null
    if (typeof row.invoiceRegistrations === 'object' && row.invoiceRegistrations.length) {
      let lnvoiceRegistrationIds = (row.invoiceRegistrations as InvoiceRegisterRow[]).map(o => o.id)
      lnvoiceRegistrationIds = lnvoiceRegistrationIds
        .filter(o => o)
        .filter((v, i, self) => self.indexOf(v) === i) // 去重
        .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
      if (lnvoiceRegistrationIds.length) {
        const lnvoiceRegistrationRsp = await InvoiceRegisterService.getBatch(lnvoiceRegistrationIds)
        lnvoiceRegistration = lnvoiceRegistrationRsp.data
        if (lnvoiceRegistrationRsp.code !== 200 || !lnvoiceRegistration) {
          message.warning("请求发票登记失败！").then()
          return
        }
      }
    }

    // 注意责任部门 code
    let code = ''
    if (!row.responsibilityProject) {
      message.warning("请检查责任部门！").then()
      return
    }
    let names: string[] = typeof row.responsibilityProject === 'object' ? row.responsibilityProject : row.responsibilityProject.split(',')
    names = names
      .filter(o => o)
      .filter((v, i, self) => self.indexOf(v) === i) // 去重
      .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
    if (names.length) {
      const responsibilityProject = await ProjectService.getBatchByName(names)
      if (typeof responsibilityProject === 'object' && responsibilityProject.length !== names.length) {
        message.warning("请检查责任部门！").then()
        return
      }
      code = responsibilityProject
        .map(o => o.code)
        .filter(o => o)
        .filter((v, i, self) => self.indexOf(v) === i)
        .join(',')
    }

    // 构造资金审批
    fundApprovalStore.updateFundApprovalRow({
      id: undefined,
      code,
      fundApprovalId: row.id, // 合同结算发起资金审批
      bankStatementIds: undefined,
      bankStatementCodes: undefined,
      faDate: dayjs(new Date()), // 制单日期
      faCode: undefined, // 制单编号
      faType: undefined, // 款项类别
      faSummary: row.csSummany, // 款项摘要
      faAmount: row.csEndAmount, // 金额
      faPayDate: undefined, // 付款日期
      faStatus: undefined, // 办理进度
      faApplicationForm: row.id, // 关联申请id
      faApplicationFormType: '合同结算', // 关联申请类型
      fundingApprovalTables: [{type: '合同结算', businessId: row.id}],
      contractListIds: (row.contractLists as ContractListRow[])?.map(o => o.id),// 关联合同 商务合同
      contractLists: row.contractLists,// 关联合同
      lnvoiceRegistration: lnvoiceRegistration && lnvoiceRegistration, // 关联发票登记
      lnvoiceRegistrationIds: lnvoiceRegistration && (lnvoiceRegistration as InvoiceRegisterRow[]).map(o => o.id),
      personResponsibleName: row.personResponsibleName, // 办理人员
      personResponsible: row.personResponsible, // 办理人员
      organizersUserId: undefined,
      organizersUserName: undefined,
      responsibilityProject: row.responsibilityProject, // 责任部门
      cooperationUnit: [], // 协作单位
      faFile: undefined, // 存档批号
      faFileList: undefined, // 上传文件
      faPaymentAccount: row.csEndOutAccount,
      faPaymentAccountId: row.csEndOutAccountId,
      baName1: row.csEndOutAccountTitle,
      baAccount1: row.csEndOutBankAccount,
      baBlank1: row.csEndOutDepositBank,
      faAccountsReceivable: row.csEndInAccount,
      faAccountsReceivableId: row.csEndInAccountId,
      baName2: row.csEndInAccountTitle,
      baAccount2: row.csEndInBankAccount,
      baBlank2: row.csEndInDepositBank,
    })
    navigate("/fundApproval")
  }

  const handlePrint = async (record: ContractSettlementRow) => {
    const list = await contractSettlementStore.getPrintList(record)
    if (list) {
      setContentList(list)
      setIsModalOpen(true)
    } else {
      message.warning('请检查')
    }
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: ContractSettlementReq) => {
    setSearchReq(req)
    contractSettlementStore.getContractSettlement(req)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    contractSettlementStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const handleFundApprovalCancel = () => {
    setFundApprovalOpen(false)
    setFundApprovalSelectedRowKeys([])
    setContractSettlementId('')
  }

  const handleUnbind = () => {
    // logger.log(fundApprovalSelectedRowKeys)
    if (!fundApprovalSelectedRowKeys.length) {
      message.warning('至少选择一条数据').then()
      return
    }


    Modal.confirm({
      title: "是否批量操作-合同结算解绑资金审批？",
      okText: "确定",
      cancelText: "取消",
      onCancel: () => setConfirmLoading(false),
      onOk: async () => {

        // 响应体列表
        let resList = []
        setConfirmLoading(true)

        for (let i = 0; i < fundApprovalSelectedRowKeys.length; i++) {

          // 提示
          message.loading(`请稍等！${i + 1}/${fundApprovalSelectedRowKeys.length}`).then()

          // 请求数据
          const fundingId = String(fundApprovalSelectedRowKeys[i])
          const rsp = await FundApprovalService.unbundle(fundingId, contractSettlementId, '合同结算')

          // 更新列表
          if (rsp.code !== 200) {
            resList.push('操作失败-网络Error！')
            continue
          }
          resList.push(rsp)
        }

        // 全部一起提示
        Modal.info({
          title: `温馨提示-批量操作-合同结算解绑资金审批`,
          content: <>{ resList.map(o => <div>{ typeof o === 'string' ? o : o.message || '操作成功' }</div>) }</>,
          okText: "确定",
          cancelText: "取消",
          onOk() {
            setFundApprovalSelectedRowKeys([])
            setContractSettlementId('')
            refreshData(searchReq)
            setConfirmLoading(false)
            setFundApprovalOpen(false)
          },
        })

      }
    })

  }

  // 合同审批 发起
  // 资金审批 发起
  const handleAdd = (row?: ContractSettlementRow) => {
    const user = securityStore.getLoggedUser()
    const initRow = {
      csStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      csDate: dayjs(),
    } as ContractSettlementRow
    Object.assign(row || {}, initRow)
    setContractSettlementRow(row || initRow)
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  const handleView = (record: ContractSettlementRow) => {
    setContractSettlementRow(record)
    setIsView(true)
    setIsBatchUpdate(false)
    setIsModalVisible(true);
  }

  const handleEdit = async (record: ContractSettlementRow) => {

    try {
      const rsp = await ProcessRecordsService.getProcessRecords({
        businessId: record.id,
        pageNum: 1,
        perPage: 999,
        type: TableName,
      })
      const process = rsp.items.length ? rsp.items[0].process : ''
      const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
      const con = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
      // 如果本轮有撤回 （id为空） 否则 （发起最新一轮的提交的人的id）
      setSubmitterId(con ? undefined : userId)
      setLatestProcess(process) // 发起最新一轮的标志

      setContractSettlementRow(record)
      setIsView(false)
      setIsBatchUpdate(false)
      setIsModalVisible(true);
    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }
  }

  const handleDeleteBatch = (record?: ContractSettlementRow) => {
    const keys = [...selectedRowKeys]
    if (!record && keys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = keys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        ContractSettlementService.deleteContractSettlement(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(contractSettlementStore.contractSettlementReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const actionThen = (rsp: ResponseEntity<ContractSettlementRow>) => {
    if (rsp.code === 200) {
      message.success("操作成功！").then()
    } else {
      message.warning(rsp.message).then()
    }
    setIsModalVisible(false);
    setContractSettlementRow(undefined)
    refreshData(contractSettlementStore.contractSettlementReq)
    setSelectedRowKeys([])
  }

  const handleOk = (open: boolean) => {
    const row = {...contractSettlementRow!}
    basicInfo.current!.validateFields().then(async (values) => {
      setConfirmLoading(true)
      Object.assign(row, values)

      if (open) {

        // 将row和按钮先存起来
        setSubmitRow(row)

        // 弹窗选择处理人
        setProcessRecordsIsOpen(true)
        setProcessRecordsRow(undefined)

      } else {

        const user = securityStore.getLoggedUser()

        if (isBatchUpdate) {
          batchUpdate(row)
        } else {

          // 默认处理人员是自己
          if (!row.id) {
            row.personResponsible = user._id;
            row.personResponsibleName = user.name;
          }

          try {
            const rowRes = await dataConversionContractSettlement(row)
            await actionThen(await insertOrUpdateContractSettlement(rowRes))
          } catch (error) {
            setProcessRecordsIsOpen(false)
            setProcessRecordsRow(undefined)
            setConfirmLoading(false)
            setIsModalVisible(false);
            message.warning(error.message)
          } finally {
            setConfirmLoading(false)
            setUpdateCount(updateCount + 1)
          }

        }
      }

    }).catch(logger.warn)
  }

  const batchUpdate = (row: ContractSettlementRow) => {
    // let flag = false
    const rows = contractSettlementStore.contractSettlementDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    for (let selectedRow of rows) {
      if (row.csDate && typeof row.csDate === 'object') {
        selectedRow.csDate = row.csDate.format(DATE_FMT);
      }
      if (row.csEndType) {
        selectedRow.csEndType = row.csEndType
      }
      if (row.csSummany) {
        selectedRow.csSummany = row.csSummany
      }
      if (row.csEndAmount) {
        selectedRow.csEndAmount = row.csEndAmount
      }
      if (row.csEndOutAccountId) {
        selectedRow.csEndOutAccountId = row.csEndOutAccountId
        selectedRow.csEndOutAccount = row.csEndOutAccount
        selectedRow.csEndOutAccountTitle = row.csEndOutAccountTitle
        selectedRow.csEndOutBankAccount = row.csEndOutBankAccount
        selectedRow.csEndOutDepositBank = row.csEndOutDepositBank
      }
      if (row.csEndInAccountId) {
        selectedRow.csEndInAccountId = row.csEndInAccountId
        selectedRow.csEndInAccount = row.csEndInAccount
        selectedRow.csEndInAccountTitle = row.csEndInAccountTitle
        selectedRow.csEndInBankAccount = row.csEndInBankAccount
        selectedRow.csEndInDepositBank = row.csEndInDepositBank
      }
      if (row.contractListIds && typeof row.contractListIds === 'string') {
        selectedRow.contractListIds = row.contractListIds.split(',')
      } else {
        if (selectedRow.contractLists && selectedRow.contractLists.length > 0) {
          selectedRow.contractListIds = selectedRow.contractLists.map(item => item.id)
        }
      }
      if (row.invoiceRegistrations && typeof row.invoiceRegistrations === 'object') {
        selectedRow.invoiceRegistrationIds = (row.invoiceRegistrations as InvoiceRegisterRow[])?.map(o => o.id)
        const irTaxRateSum: number[] = row.invoiceRegistrations.map((item) => {
          return item.irTaxRateSum
        })
        if (irTaxRateSum.length) {
          selectedRow.invoiceRegistrationMoney = irTaxRateSum.reduce((acc, cur) => {
            return accAdd(acc, cur)
          })
        }
      }

      /**
       * 以下字段 带逻辑 必须有值
       */

      // 协作单位
      if (typeof row.cooperationUnit === 'object' && row.cooperationUnit) {
        selectedRow.cooperationUnit = []
        if (row.csEndOutAccountId && !BLACK_LIST.includes(row.csEndOutAccountTitle)) {
          selectedRow.cooperationUnit.push(row.csEndOutAccountTitle)
        }
        if (row.csEndInAccountId && !BLACK_LIST.includes(row.csEndInAccountTitle)) {
          selectedRow.cooperationUnit.push(row.csEndInAccountTitle)
        }
        selectedRow.cooperationUnit = row.cooperationUnit
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
          .join(',');
      }
      // 责任部门编号
      if (row.responsibilityProject) {
        selectedRow.code = row.code
        selectedRow.responsibilityProject = row.responsibilityProject
      }
    }
    ContractSettlementService.updateBatchContractSettlement(rows).then((rsp) => {
      actionThen(rsp);
      setUpdateCount(updateCount + 1)
    }).catch(showErr).finally(() => setConfirmLoading(false));
  }

  const handleSubmit = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)

      const user = securityStore.getLoggedUser()
      const btnText = row.status

      // 当类型是撤回时 默认办理人员为提交人(自己)
      if (btnText === FORM_REVOCATION_TEXT) {
        row.personResponsible = user._id || submitterId
        row.personResponsibleName = user.name
      }

      // 主表
      const subRow = {...submitRow}
      subRow.personResponsible = row.personResponsible
      subRow.personResponsibleName = row.personResponsibleName
      subRow.csStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.csStatus
      // 主表在撤回、退回、变更时->更新局部数据
      if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在转交、提交时->更新局部数据
      if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在提交时->更新局部数据
      if (btnText === FORM_COMMIT_TEXT) {
        subRow.csDate = new Date().toLocaleDateString().replace(/\//g, '-')
      }
      // 主表在结办时->更新局部数据
      if (btnText === FORM_CLOSE_TEXT) {

      }

      // 办理记录表
      row.type = TableName
      row.businessId = subRow.id // 有可能会没值
      row.status = btnText
      row.userId = user._id
      row.userName = user.name
      row.personResponsible = undefined
      row.personResponsibleName = undefined
      if (btnText === FORM_COMMIT_TEXT) {
        const date = new Date()
        row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      } else {
        row.process = latestProcess
      }
      if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
        row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
      } else {
        row.conca = `${user.name}${btnText}了。`
      }

      // 判断是否 先新增主表 后新增记录和通知表
      if (subRow.id) {

        let firstOperationSucceeded = false;
        try {
          const rowRes = await dataConversionContractSettlement(subRow)
          await actionThen(await insertOrUpdateContractSettlement(rowRes))
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        } finally {
          setConfirmLoading(false)
          setUpdateCount(updateCount + 1)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.csCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "合同审批",
          wnDesc: `您参与办理的合同审批（${subRow.csCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      } else {

        let subRsp: ResponseEntity<ContractSettlementRow>
        try {
          const rowRes = await dataConversionContractSettlement(subRow)
          subRsp = await insertOrUpdateContractSettlement(rowRes)
          await actionThen(subRsp)
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        } finally {
          setConfirmLoading(false)
          setUpdateCount(updateCount + 1)
        }

        if (subRsp.data) {
          // 重新赋值再提交
          row.businessId = subRsp.data.id
          await ProcessRecordsService.addProcessRecords(row)
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: subRsp.data.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const notificationScopeUserId = rsp.items
            .map(o => o.userId)
            .concat(row.person)
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(id => id !== user._id) // 过滤出不是自己的数据
            .join(',') || ''
          const notificationScopeUserName = rsp.items
            .map(o => o.userName)
            .concat((row.personName || '').split(','))
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(name => name !== user.name) // 过滤出不是自己的数据
            .join(',') || ''
          await processStore.newAssignmentNotice({
            businessId: subRsp.data.id,
            businessCode: subRsp.data.csCode,
            cooperationUnit: (subRsp.data.cooperationUnit as string).split(',')[0] || 'N/A',
            notificationScopeUserId,
            notificationScopeUserName,
            personResponsible: subRsp.data.personResponsible,
            personResponsibleName: subRsp.data.personResponsibleName,
            responsibilityProject: subRsp.data.responsibilityProject,
            type: "合同审批",
            wnDesc: `您参与办理的合同审批（${subRsp.data.csCode}），${row.conca} 请知悉！`,
            wnStatus: "未读"
          })
        } else {
          message.warning('主表保存成功，办理记录表和任务通知表提交失败')
        }

      }
      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  /**
   * 操作记录按钮
   */
  const handleProcessRecordsClick = async (record: ContractSettlementRow) => {
    const rsp = await ProcessRecordsService.getProcessRecords({
      businessId: record.id,
      pageNum: 1,
      perPage: 999,
      type: TableName
    })
    if (!rsp) {
      message.warning('查无数据')
      return
    }
    setProcessRecordsDataSource(rsp.items)
    setProcessRecordsOpen(true)
  }

  /**
   * @description 打印表格
   */
  const handlePrintTable = async () => {
    if (!selectedRowKeys.length) {
      message.warning('请至少选择一条数据')
    }
    // 数据处理
    const rows = contractSettlementStore.contractSettlementDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    for (let i = 0; i < rows.length; i++) {

    }
    setPrintDataSource(rows)
    setIsPrintTable(true)
    setIsModalOpen(true)
  }

  /**
   * 合并 合同结算
   */
  const handleMarge = () => {
    if (!selectedRowKeys.length) {
      message.warning("请选择要合并的数据！").then()
      return
    }

    // 获取数据
    const rows = contractSettlementStore.contractSettlementDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))

    // 判断是否符合批量操作条件
    const con = rows.every((o, _, arr) => {
      const firstRow = arr[0]
      if (o.personResponsible !== firstRow.personResponsible) {
        message.warning("办理人员不一致！").then()
        return false
      } else if (o.csStatus !== firstRow.csStatus) {
        message.warning("办理进度不一致！").then()
        return false
      } else if (o.csEndOutAccount !== firstRow.csEndOutAccount) {
        message.warning("付款账户不一致！").then()
        return false
      } else if (o.csEndInAccount !== firstRow.csEndInAccount) {
        message.warning("收款账户不一致！").then()
        return false
      } else if (!o.contractLists.every(o => firstRow.contractLists.some(v => o.id === v.id))) {
        message.warning("商务合同不一致！").then()
        return false
      } else return true
    })

    if (con) {
      setMergeContractSettlementDataSource(rows)
      setMergeContractSettlementOpen(true)
    }
  }

  /**
   * 合并 合同结算 确认
   */
  const handleMergeContractSettlementOk = async (mergeTo: string, mergeObjects: string[]) => {
    try {
      setConfirmLoading(true)
      const {code} = await ContractSettlementService.merge(mergeTo, mergeObjects)
      if (code === 200) {
        message.success('合并成功')
      } else {
        message.warning('合并失败')
      }
    } catch (e) {
      message.warning('合并失败')
    } finally {
      setMergeContractSettlementOpen(false)
      refreshData(contractSettlementStore.contractSettlementReq)
      setSelectedRowKeys([])
      setConfirmLoading(false)
    }
  }

  /**
   * 合并 合同结算 取消
   */
  const handleMergeContractSettlementCancel = () => {
    setMergeContractSettlementOpen(false)
    setSelectedRowKeys([])
  }

  const handleCopyClick = (record: ContractSettlementRow) => {
    const user = securityStore.getLoggedUser()
    const initRow = {
      ...record,
      id: '',
      csFile: '',
      fundingApprovalIds: [],
      csStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      csDate: dayjs(),
    }
    setContractSettlementRow(initRow)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handleEditBatch = async () => {

    try {

      if (selectedRowKeys.length === 0) {
        message.warning("请选择要操作的数据！").then()
        return
      }

      // 要操作的数据
      const rows = contractSettlementStore.contractSettlementDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))

      // 办理流程辅助字段：办理进度
      let csStatus: string

      // 判断是否符合批量操作条件
      const con = rows.every((o, _, arr) => {
        const firstRow = arr[0]
        csStatus = firstRow.csStatus
        if (o.personResponsible !== firstRow.personResponsible) {
          message.warning("办理人员不一致！").then()
          return false
        } else if (o.csStatus !== firstRow.csStatus) {
          message.warning("办理进度不一致！").then()
          return false
        } else return true
      })

      if (con) {

        let latestProcessList: string[] = []
        let submitterIdList: string[] = []
        for (let i = 0; i < rows.length; i++) {
          const item = rows[i]
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: item.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const process = rsp.items.length ? rsp.items[0].process : ''
          const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
          const userIdCon = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
          latestProcessList.push(process)
          submitterIdList.push(userIdCon ? undefined : userId)
        }

        // 如果提交人全部一致才赋值给参数:【提交人】
        setSubmitterId(new Set(submitterIdList).size === 1 ? submitterIdList[0] : undefined)
        setLatestProcessBatch(latestProcessList)
        setIsView(false)
        setIsBatchUpdate(true)
        setContractSettlementRow({csStatus} as ContractSettlementRow)
        setIsModalVisible(true)

      }

    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleSubmitBatch = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {

      const rows = contractSettlementStore.contractSettlementDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
      for (let i = 0; i < rows.length; i++) {

        Object.assign(row, values)

        const user = securityStore.getLoggedUser()
        const btnText = row.status

        // 当类型是撤回时 默认办理人员为提交人(自己)
        if (btnText === FORM_REVOCATION_TEXT) {
          row.personResponsible = user._id || submitterId
          row.personResponsibleName = user.name
        }

        // 主表
        const subRow = {...rows[i]}
        subRow.personResponsible = row.personResponsible
        subRow.personResponsibleName = row.personResponsibleName
        subRow.csStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.csStatus
        // 主表在撤回、退回、变更时->更新局部数据
        if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在转交、提交时->更新局部数据
        if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在提交时->更新局部数据
        if (btnText === FORM_COMMIT_TEXT) {
          subRow.csDate = new Date().toLocaleDateString().replace(/\//g, '-')
        }
        // 主表在结办时->更新局部数据
        if (btnText === FORM_CLOSE_TEXT) {

        }

        // 办理记录表
        row.type = TableName
        row.businessId = subRow.id // 有可能会没值
        row.status = btnText
        row.userId = user._id
        row.userName = user.name
        row.personResponsible = undefined
        row.personResponsibleName = undefined
        if (btnText === FORM_COMMIT_TEXT) {
          const date = new Date()
          row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
        } else {
          row.process = latestProcessBatch[i]
        }
        if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
          row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
        } else {
          row.conca = `${user.name}${btnText}了。`
        }

        let firstOperationSucceeded = false;
        try {
          const rowRes = await dataConversionContractSettlement(subRow)
          await actionThen(await insertOrUpdateContractSettlement(rowRes))
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        } finally {
          setConfirmLoading(false)
          setUpdateCount(updateCount + 1)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.csCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "合同审批",
          wnDesc: `您参与办理的合同审批（${subRow.csCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      }

      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        updateCount={updateCount}
        collapsed={contractSettlementStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        handleShowColumnReset={handleShowColumnReset}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={props.isModalSelect ? columns.filter(o => o.key !== 'action') : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}
        items={[
          {
            key: 'handleAdd',
            icon: <PlusOutlined/>,
            onClick: () => handleAdd()
          },
          {
            key: 'handleDeleteBatch',
            icon: <DeleteOutlined/>,
            onClick: () => handleDeleteBatch()
          },
          {
            key: 'handlePrintTable',
            icon: <PrinterOutlined/>,
            onClick: () => handlePrintTable()
          },
          {
            key: 'handleEditBatch',
            icon: <EditOutlined/>,
            onClick: () => handleEditBatch()
          },
          {
            key: 'handleMarge',
            icon: <MergeCellsOutlined/>,
            onClick: () => handleMarge()
          },
        ]}></HeaderActionCom>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        size={"middle"}
        loading={props.dataSource ? false : contractSettlementStore.contractSettlementDatasource.loading || confirmLoading}
        bordered
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        pagination={!props.dataSource && {
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                (showColumn as string[]).map((key, index) =>
                  key.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={summaryTotalObj[key.slice(0, key.indexOf('-'))]}></TableMoneyText> 元
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys)
          },
          columnWidth: 50,
        }}/>

      { /*操作记录表*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsOpen}
        width={LARGE_MODAL_WIDTH_OTHER}
        onCancel={() => setProcessRecordsOpen(false)}
        destroyOnClose
        footer={null}>
        <ProcessRecords
          isHiddenOptions
          dataSource={processRecordsDataSource}
          isModalSelect
          locationPathname={'/processRecords'}/>
      </CustomDraggableModal>

      { /*操作记录表单*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsIsOpen}
        width={LARGE_MODAL_WIDTH}
        destroyOnClose
        onCancel={() => {
          setProcessRecordsIsOpen(false)
          setConfirmLoading(false)
        }}
        onOk={isBatchUpdate ? handleSubmitBatch : handleSubmit}>
        <ProcessRecordsBasicInfo
          tableName={TableName}
          businessId={contractSettlementRow?.id}
          submitterId={submitterId}
          userId={contractSettlementRow?.personResponsible}
          status={contractSettlementRow?.csStatus}
          row={processRecordsRow}
          ref={processRecordsBasicInfo}/>
      </CustomDraggableModal>

      <CustomForm
        title={'合同结算'}
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={isModalVisible}
        submitterId={submitterId}
        confirmLoading={confirmLoading}
        userId={contractSettlementRow?.personResponsible}
        status={contractSettlementRow?.csStatus}
        extraDisabled={!contractSettlementRow?.id}
        isView={isView}
        extraOnClick={() => handlePrint(contractSettlementRow)}
        item={[
          {
            key: 'handleFundApprovalClick',
            icon: <RetweetOutlined/>,
            hidden: !FORM_INITIATE_STATE.includes(contractSettlementRow?.csStatus),
            onClick: () => handleFundApprovalClick(contractSettlementRow)
          },
          {
            key: 'handleCopyClick',
            icon: <FieldTimeOutlined/>,
            disabled: !contractSettlementRow?.id,
            onClick: () => handleCopyClick(contractSettlementRow)
          },
          {
            key: 'handleProcessRecordsClick',
            icon: <FieldTimeOutlined/>,
            disabled: !contractSettlementRow?.id,
            onClick: () => handleProcessRecordsClick(contractSettlementRow)
          },
          {
            key: 'handlePrintTable',
            icon: <PrinterOutlined/>,
            onClick: () => handlePrintTable()
          },
        ]}>
        <BasicInfo row={{...contractSettlementRow!}} ref={basicInfo} isView={isView} isBatchUpdate={isBatchUpdate}/>
      </CustomForm>

      { /*关联发票登记*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">关联发票登记</div>}
        open={invoiceRegisterOpen}
        width={LARGER_MODAL_WIDTH}
        onCancel={() => setInvoiceRegisterOpen(false)}
        destroyOnClose
        footer={null}>
        <InvoiceRegister
          dataSource={invoiceRegisterDataSource}
          isModalSelect
          locationPathname={'/invoiceRegister'}/>
      </CustomDraggableModal>

      { /*关联合同审批*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">关联合同审批</div>}
        open={contractListOpen}
        width={LARGER_MODAL_WIDTH}
        onCancel={() => setContractListOpen(false)}
        destroyOnClose
        footer={null}>
        <ContractList
          dataSource={contractListDataSource}
          isModalSelect
          locationPathname={'/contractList'}/>
      </CustomDraggableModal>

      { /*关联资金审批*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">关联资金审批</div>}
        open={fundApprovalOpen}
        width={LARGER_MODAL_WIDTH}
        onCancel={() => setFundApprovalOpen(false)}
        destroyOnClose
        footer={[
          <Button
            key="Cancel"
            onClick={handleFundApprovalCancel}>
            取消
          </Button>,
          <Button
            key="Unbind"
            type="primary"
            onClick={handleUnbind}>
            解绑
          </Button>,
        ]}>
        <FundApproval
          setFundApprovalSelectedRowKeys={setFundApprovalSelectedRowKeys}
          dataSource={fundApprovalDataSource}
          isModalSelect
          locationPathname={'/fundApproval'}/>
      </CustomDraggableModal>

      { /*合并 合同结算*/ }
      <MergeContractSettlementModal
        dataSource={mergeContractSettlementDataSource}
        open={mergeContractSettlementOpen}
        handleOk={handleMergeContractSettlementOk}
        handleCancel={handleMergeContractSettlementCancel}></MergeContractSettlementModal>

      <WindowPrint
        isHiddenTime
        isPrintTable={isPrintTable}
        tableColumns={columns}
        dataSource={printDataSource}
        narrowShowColumn={narrowShowColumn}
        title={"合同结算"}
        contentList={contentList}
        isModalOpen={isModalOpen}
        handleCancel={() => setIsModalOpen(false)}/>
    </div>
  )
}))
