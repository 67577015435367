import {PageParam, ContractLedgerRow} from "@services/dto/contractLedger";
import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";

export class ContractLedgerService {
  static async getContractLedger(req: PageParam): Promise<Page<ContractLedgerRow>> {
    return await apiV1.get("/theContractParameter", {params: req})
  }

  static async addContractLedger(row: ContractLedgerRow): Promise<ResponseEntity<ContractLedgerRow>> {
    return await apiV1.post("/theContractParameter", {...row})
  }

  static async updateContractLedger(row: ContractLedgerRow): Promise<ResponseEntity<ContractLedgerRow>> {
    return await apiV1.put("/theContractParameter", {...row})
  }

  static async deleteContractLedger(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/theContractParameter", {data: ids})
  }
}
