import React, {FC, useEffect, useLayoutEffect, useState} from "react";
import {Button, Drawer, message, Modal, Space, Table, TableColumnsType} from 'antd'
import {ColumnsType} from "antd/es/table";
import {FormInstance} from "antd/es";
import {BasicInfo} from './Components/BasicInfo'
import {allStores} from "@/stores";
import {observer} from "mobx-react";
import {logger, safeCompute, showErr} from "@common/utils";
import {DEFAULT_MODAL_WIDTH, DEFAULT_TABLE_SCROLL_HEIGHT, TABLE_CELL_WIDTH_1_5x, TABLE_CELL_WIDTH_1x} from "@/config";
import "./index.less"
import {TableEllipsisText} from "@components/TableEllipsisText";
import {useLocation} from "react-router-dom";
import {HeaderActionCom} from "@modules/DictManagement/Components/HeaderActionCom";
import {DictManagementChildRow, DictManagementReq, DictManagementRow} from "@services/dto/dictManagement";
import {DictManagementService} from "@services/DictManagementService";
import {DeleteOutlined, EditOutlined, EllipsisOutlined, PlusOutlined} from "@ant-design/icons";
import {ResponseEntity} from "@services/dto/common";
import {TableAuthOptions} from "@components/CustomAuthOptions";

const blackColumnList = [
  'action',
]

const wideShowColumn: string[] = [
  'alias',
  'key',
  'remark',
  'action',
]

const narrowShowColumn: string[] = [
  'alias',
  'key',
  'remark',
  'action',
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: DictManagementRow[] // 数据源
  setFundApprovalSelectedRowKeys?: (key: React.Key[]) => void // 所选行
}

export const DictManagement: FC = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const location = useLocation()
  const {
    dictManagementStore,
    headerSearchStore,
    tableColumnsStore,
    screenResolutionStore
  } = allStores;
  const basicInfo = React.createRef<FormInstance>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isView, setIsView] = useState<boolean>(false)
  const [dictManagementRow, setDictManagementRow] = useState<DictManagementRow>()
  const [dataSource, setDataSource] = useState<DictManagementRow[]>([]);
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<DictManagementRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [total, setTotal] = useState<number>(0)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [dictManagementReq, setDictManagementReq] = useState<DictManagementReq>({
    perPage: 10,
    pageNum: 1,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...dictManagementStore.dictManagementReq
  })

  const columns: TableColumnsType<DictManagementRow> = [
    {
      title: '别名',
      dataIndex: 'alias',
      key: 'alias',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '标识',
      dataIndex: 'key',
      key: 'key',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableAuthOptions
          mainBtn={['handleEdit', 'handleView']}
          items={[
            {
              key: 'handleView',
              icon: <EllipsisOutlined/>,
              onClick: () => handleView(record)
            },
            {
              key: 'handleEdit',
              icon: <EditOutlined/>,
              onClick: () => handleEdit(record)
            },
            {
              key: 'handleDelete',
              icon: <DeleteOutlined/>,
              onClick: () => handleDelete(record)
            },
          ]}></TableAuthOptions>
      )
    }
  ];

  /**
   * @description 初始化
   */
  useEffect(() => {

    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(dictManagementReq)
    }
  }, [])

  /**
   * @description 全局查询
   */
  useEffect(() => {
    const req = {...dictManagementReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setDictManagementReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (dictManagementStore.dictManagementDatasource.data) {
      setDataSource(dictManagementStore.dictManagementDatasource.data?.items || [])
      setTotal(dictManagementStore.dictManagementDatasource.data?.items.length || 0)
    }
  }, [dictManagementStore.dictManagementDatasource.data])

  /**
   * 列配置变化
   */
  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => !blackColumnList.includes(o.key as string)).filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, dictManagementReq, dictManagementStore.collapsed])

  const refreshData = (req: DictManagementReq) => {
    setDictManagementReq(req)
    dictManagementStore.getDictManagement(req)
  }

  const handleDelete = (record: DictManagementRow) => {
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        DictManagementService.deleteDictManagementById(record.id).then((rsp) => {
          if (rsp.code === 200) {
            message.success("删除成功！").then()
          } else {
            message.success(rsp.message).then()
          }
          setConfirmLoading(false)
          refreshData(dictManagementReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handleEdit = (record: DictManagementRow) => {
    setDictManagementRow(record)
    setIsModalVisible(true);
    setIsView(false)
  }

  const handleAdd = () => {
    setDictManagementRow(undefined)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleView = (record: DictManagementRow) => {
    setDictManagementRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleOk = () => {
    const row = {...dictManagementRow!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(row, values)

      setConfirmLoading(true)
      if (row.id) {
        DictManagementService.updateDictManagementById(row.id, row).then(rsp => {
          setUpdateCount(updateCount + 1)
          actionThen(rsp)
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        DictManagementService.addDictManagement(row).then(rsp => {
          setUpdateCount(updateCount + 1)
          actionThen(rsp)
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setDictManagementRow(undefined)
  };

  const actionThen = (rsp: ResponseEntity<any>) => {
    if (rsp) {
      message.success("操作成功！").then()
    } else {
      message.warning('操作失败').then()
    }
    setIsModalVisible(false);
    setDictManagementRow(undefined)
    refreshData(dictManagementReq)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let dictManagementRequest = {...dictManagementReq};
    dictManagementRequest.perPage = pageSize
    dictManagementRequest.pageNum = page
    setDictManagementReq(dictManagementRequest)
    refreshData(dictManagementRequest)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    dictManagementStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  const expandedRowRender = (record: DictManagementRow) => {
    const columns: TableColumnsType<DictManagementChildRow> = [
      {
        title: '键标题',
        dataIndex: 'label',
        key: 'label',
        align: 'center',
        width: TABLE_CELL_WIDTH_1x,
        render: (text) => (
          <Space>
            <TableEllipsisText value={text}></TableEllipsisText>
          </Space>
        )
      },
      {
        title: '键值',
        dataIndex: 'value',
        key: 'value',
        align: 'center',
        width: TABLE_CELL_WIDTH_1x,
        render: (text) => (
          <Space>
            <TableEllipsisText value={text}></TableEllipsisText>
          </Space>
        )
      },
    ];

    return <Table bordered rowKey={r=>r.value} columns={columns} dataSource={record.value} pagination={false}/>;
  };

  return (
    <div className="dictManagement">

      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        updateCount={updateCount}
        collapsed={dictManagementStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={dictManagementReq}
        handleShowColumnReset={handleShowColumnReset}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        items={[
          {
            key: 'handleAdd',
            icon: <PlusOutlined/>,
            onClick: () => handleAdd()
          },
        ]}
        tableColumns={props.isModalSelect ? columns.filter(o => !blackColumnList.includes(o.key as string)) : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...dictManagementReq, ...req})}></HeaderActionCom>

      <Table
        className={"margin-top-xs"}
        bordered
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        size={"middle"}
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        loading={props.dataSource ? false : dictManagementStore.dictManagementDatasource.loading || confirmLoading}
        pagination={!props.dataSource && {
          total: total,
          position: ['bottomCenter'],
          pageSize: total,
          current: 1,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        expandable={{
          expandedRowRender,
          columnWidth: 50,
        }}/>

      <Drawer
        title={<div className="text-align-center">字典管理</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        destroyOnClose
        onClose={handleCancel}>

        <BasicInfo row={{...dictManagementRow!}} isView={isView} ref={basicInfo}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button
              type="primary"
              onClick={handleOk}
              loading={confirmLoading}
              className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>
    </div>
  )
}))
