import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {FormInstance, message, Modal, Space, Table} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {accAdd, logger, parseURLParams, safeCompute, showErr} from "@common/utils";
import {BasicInfo} from "@modules/ContractList/Components/BasicInfo";
import {ContractListReq, ContractListRow, ContractListSummaryTotal} from "@services/dto/contractList";
import {ContractListService} from "@services/ContractListService";
import {TableMoneyText} from "@components/TableMoneyText";
import {LabelValue, ResponseEntity} from "@services/dto/common";
import {WindowPrint} from "@modules/WindowPrint";
import {useLocation, useNavigate} from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FieldTimeOutlined,
  PlusOutlined,
  PrinterOutlined,
  RetweetOutlined
} from "@ant-design/icons";
import {TableFilingText} from "@components/TableFilingText";
import {BasicInfo as ProcessRecordsBasicInfo} from "@modules/ProcessRecords/Components/BasicInfo";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {ProjectService} from "@services/ProjectService";
import dayjs from "dayjs";
import {TableContractLists} from "@components/TableContractLists";
import {
  APPROVE_FORM_TRANS_STATE,
  BLACK_LIST,
  DATE_FMT,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  FORM_ALTERNATE_TEXT,
  FORM_CLOSE_TEXT,
  FORM_COMMIT_TEXT,
  FORM_DISABLED_STATE,
  FORM_HANDOVER_TEXT,
  FORM_INITIATE_STATE,
  FORM_PERSON_RESPONSIBLE_WHITELIST,
  FORM_RETURN_TEXT,
  FORM_REVOCATION_TEXT,
  LARGE_MODAL_WIDTH,
  LARGE_MODAL_WIDTH_OTHER,
  LARGER_MODAL_WIDTH,
  ONFILE,
  READY,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import {CustomDraggableModal} from "@components/CustomDraggableModal";
import {ProcessRecordsRow} from "@services/dto/processRecords";
import {ProcessRecordsService} from "@services/ProcessRecordsService";
import {ProcessRecords} from "@modules/ProcessRecords";
import {CustomForm} from "@components/CustomForm";
import {TableAuthOptions} from "@components/CustomAuthOptions";
import {HeaderActionCom} from "@modules/ContractList/Components/HeaderActionCom";

const wideShowColumn: string[] = [
  'clDocDate',
  'clCode',
  'clName',
  'clDesc',
  // 'parentId',
  // 'contractParties',
  'clAmount-summation',
  // 'clDate',
  // 'clEndDate',
  // 'personResponsibleName',
  'responsibilityProject',
  'cooperationUnit',
  'clFile',
  'clStatus',
  'action',
]

const narrowShowColumn: string[] = [
  'clDocDate',
  'clCode',
  'clName',
  'clDesc',
  // 'parentId',
  // 'contractParties',
  'clAmount-summation',
  // 'clDate',
  // 'clEndDate',
  // 'personResponsibleName',
  // 'responsibilityProject',
  // 'cooperationUnit',
  // 'clFile',
  'clStatus',
  'action',
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: ContractListRow[] // 数据源
}

export const TableName = 'contractList'

export const ContractList = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const navigate = useNavigate()
  const {
    processStore,
    securityStore,
    contractListStore,
    contractSettlementStore,
    headerSearchStore,
    tableColumnsStore,
    screenResolutionStore
  } = allStores
  const basicInfo = React.createRef<FormInstance>();
  const processRecordsBasicInfo = React.createRef<FormInstance>();
  const location = useLocation()
  const [dataSource, setDataSource] = useState<ContractListRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [contractListRow, setContractListRow] = useState<ContractListRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentList, setContentList] = useState<LabelValue[]>([]);
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [isBatchUpdate, setIsBatchUpdate] = useState(false);
  const [renderColumns, setRenderColumns] = useState<ColumnsType<ContractListRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [contractListOpen, setContractListOpen] = useState<boolean>(false)
  const [contractListDataSource, setContractListDataSource] = useState<ContractListRow[]>([])
  const [processRecordsIsOpen, setProcessRecordsIsOpen] = useState<boolean>(false)
  const [processRecordsRow, setProcessRecordsRow] = useState<ProcessRecordsRow>()
  const [processRecordsOpen, setProcessRecordsOpen] = useState<boolean>(false)
  const [processRecordsDataSource, setProcessRecordsDataSource] = useState<ProcessRecordsRow[]>([])
  const [submitRow, setSubmitRow] = useState<ContractListRow>()
  const [submitterId, setSubmitterId] = useState<string>()
  const [latestProcess, setLatestProcess] = useState<string>()
  const [latestProcessBatch, setLatestProcessBatch] = useState<string[]>()
  const [summaryTotalObj, setSummaryTotalObj] = useState<ContractListSummaryTotal>({
    clAmount: 0,
    clEndAmount: 0,
    clEndAmountnow: 0,
  })
  const [searchReq, setSearchReq] = useState<ContractListReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...contractListStore.contractListReq
  })

  const columns: ColumnsType<ContractListRow> = [
    {
      title: '制单日期',
      dataIndex: 'clDocDate',
      key: 'clDocDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '合同编号',
      dataIndex: 'clCode',
      key: 'clCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '合同名称',
      dataIndex: 'clName',
      key: 'clName',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '合同类别',
      dataIndex: 'clType',
      key: 'clType',
      width: TABLE_CELL_WIDTH_2x,
      align: 'center',
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '合同摘要',
      dataIndex: 'clDesc',
      key: 'clDesc',
      align: 'left',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联上级合同',
      dataIndex: 'parent',
      key: 'parent',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (_, record) => (
        <Space>
          <TableContractLists
            textId={record.parentId}
            code={record.parentName}
            setDataSource={setContractListDataSource}
            onClick={() => setContractListOpen(true)}
            isId></TableContractLists>
        </Space>
      )
    },
    {
      title: '合同当事人',
      dataIndex: 'contractParties',
      key: 'contractParties',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableEllipsisText
            value={(record.contractParties && record.contractParties.length > 0) ?
              record.contractParties.map(item => item.contractPrivity + '：' + item.contractParties).join("；") : ""}/>
        </Space>
      )
    },
    {
      title: '合同约定金额(元)',
      dataIndex: 'clAmount',
      key: 'clAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '合同签订日期',
      dataIndex: 'clDate',
      key: 'clDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '合同关闭日期',
      dataIndex: 'clEndDate',
      key: 'clEndDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '主办人员',
      dataIndex: 'organizersUserName',
      key: 'organizersUserName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理人员',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '参与人员',
      dataIndex: 'wnUserNames',
      key: 'wnUserNames',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'clFile',
      key: 'clFile',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableFilingText id={record.clFile} value={record.clFileCode}></TableFilingText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'clStatus',
      key: 'clStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableAuthOptions
          mainBtn={['handleEdit', 'handleView']}
          items={[
            {
              key: 'handleView',
              icon: <EllipsisOutlined/>,
              onClick: () => handleView(record)
            },
            {
              key: 'handleEdit',
              icon: <EditOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.clStatus),
              onClick: async () => await handleEdit(record)
            },
            {
              key: 'handlePrint',
              icon: <PrinterOutlined/>,
              onClick: () => handlePrint(record)
            },
            {
              key: 'handleDeleteBatch',
              icon: <DeleteOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.clStatus),
              onClick: () => handleDeleteBatch(record)
            },
          ]}></TableAuthOptions>
      )
    }
  ];

  /**
   * @description 初始化
   */
  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      setShowColumn(narrowShowColumn)
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  /**
   * @description 全局查询
   */
  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  /**
   * @description 表格数据变化
   */
  useEffect(() => {
    if (contractListStore.contractListDatasource.data) {
      setDataSource(contractListStore.contractListDatasource.data.items || [])
      setTotal(contractListStore.contractListDatasource.data.total || 0)
    }
  }, [contractListStore.contractListDatasource.data])

  /**
   * @description 表格合计
   */
  useEffect(() => {
    const objInitValue = {
      clAmount: 0,
      clEndAmount: 0,
      clEndAmountnow: 0,
    }
    let obj = summaryTotalObj
    const arr = contractListStore.contractListDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        clAmount: accAdd(acc.clAmount, cur.clAmount),
        clEndAmount: accAdd(acc.clEndAmount, cur.clEndAmount),
        clEndAmountnow: accAdd(acc.clEndAmountnow, cur.clEndAmountnow),
      }), objInitValue);
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [contractListStore.contractListDatasource, selectedRowKeys])

  /**
   * 列配置变化
   */
  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => o.key !== 'action').filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, contractListStore.collapsed, searchReq.pageNum, searchReq.perPage])

  useEffect(() => {
    if (contractListStore.contractListOption) {
      const [option, paramsStr] = contractListStore.contractListOption.split("?")
      if (option === 'add') {
        handleAdd()
      } else if (paramsStr) {
        const params = parseURLParams(paramsStr)
        if (option === 'edit') {
          ContractListService.getOne(params.id).then(rsp => {
            if (rsp.code === 200) {
              handleEdit(rsp.data).then()
            }
          })
        }
      }
      contractListStore.updateContractListOption(undefined)
    }
  }, [contractListStore.contractListOption])

  const handlePrint = async (record: ContractListRow) => {
    const list = await contractListStore.getPrintList(record)
    if (list) {
      setContentList(list)
      setIsModalOpen(true)
    }
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setContractListRow(undefined)
    setIsModalVisible(false)
  }

  const refreshData = (req: ContractListReq) => {
    setSearchReq(req)
    contractListStore.getContractList(req)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    contractListStore.setCollapsed(collapsed)
  }

  const handleContractSettlementClick = async (record: ContractListRow) => {

    // 获取详情
    const {code: detailCode, data: row, message: detailMessage} = await ContractListService.getOne(record.id)
    if (detailCode !== 200) {
      message.warning(detailMessage).then()
    }

    // 注意责任部门 code
    if (!row.responsibilityProject) {
      message.warning("请检查责任部门！").then()
      return
    }
    const names: string[] = typeof row.responsibilityProject === 'object' ? row.responsibilityProject : row.responsibilityProject.split(',')
    const responsibilityProject = await ProjectService.getBatchByName(names)
    if (typeof responsibilityProject === 'object' && responsibilityProject.length !== names.length) {
      message.warning("请检查责任部门！").then()
      return
    }
    const code = responsibilityProject
      .map(o => o.code)
      .filter(o => o)
      .filter((v, i, self) => self.indexOf(v) === i)
      .join(',')

    // 构造合同结算
    contractSettlementStore.updateContractSettlementRow({
      applicationType: undefined,
      code,
      contractListIds: row.id,
      contractLists: [row],
      cooperationUnit: undefined,
      csBook: undefined,
      csCode: undefined,
      csContract: undefined,
      csContractCode: undefined,
      csDate: dayjs(new Date()),
      csEndAmount: 0,
      csEndInAccount: undefined,
      csEndInAccountId: undefined,
      csEndInAccountTitle: undefined,
      csEndInBankAccount: undefined,
      csEndInCustomer: undefined,
      csEndInDepositBank: undefined,
      csEndOutAccount: undefined,
      csEndOutAccountId: undefined,
      csEndOutAccountTitle: undefined,
      csEndOutBankAccount: undefined,
      csEndOutCustomer: undefined,
      csEndOutDepositBank: undefined,
      csEndType: undefined,
      csFile: undefined,
      csStatus: undefined,
      csSummany: row.clDesc,
      executorId: undefined,
      contractSettlementId: row.id, // ！起合同结算判断条件
      fundingApprovalIds: [],
      fundingApprovals: undefined,
      id: undefined,
      index: 0,
      invoiceRegistrationIds: undefined,
      invoiceRegistrations: undefined,
      oldCsEndInAccount: undefined,
      oldCsEndOutAccount: undefined,
      outboundInbound: undefined,
      personResponsible: row.personResponsible,
      personResponsibleName: row.personResponsibleName,
      organizersUserId: undefined,
      organizersUserName: undefined,
      processes: undefined,
      responsibilityProject: row.responsibilityProject,
      type: undefined

    })
    navigate("/contractSettlement")
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const batchUpdate = (row: ContractListRow) => {
    let flag = false
    const rows = contractListStore.contractListDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    for (let selectedRow of rows) {
      if (selectedRow.clStatus === ONFILE) {
        flag = true
        continue
      }
      if (row.clDocDate && typeof row.clDocDate === 'object') {
        selectedRow.clDocDate = row.clDocDate.format(DATE_FMT);
      }
      if (row.clDate && typeof row.clDate === 'object') {
        selectedRow.clDate = row.clDate.format(DATE_FMT);
      }
      if (row.clEndDate && typeof row.clEndDate === 'object') {
        selectedRow.clEndDate = row.clEndDate.format(DATE_FMT);
      }
      if (row.clCode) {
        selectedRow.clCode = row.clCode;
      }
      if (row.clName) {
        selectedRow.clName = row.clName;
      }
      if (row.clType) {
        selectedRow.clType = row.clType;
      }
      if (row.clDesc) {
        selectedRow.clDesc = row.clDesc;
      }
      if (row.parentId) {
        selectedRow.parentId = row.parentId;
        selectedRow.parentName = row.parentName;
      }
      if (row.clAmount || row.clAmount === 0) {
        selectedRow.clAmount = row.clAmount;
      }
      if (row.responsibilityProject) {
        selectedRow.responsibilityProject = row.responsibilityProject;
        selectedRow.code = row.code;
      }
      if (typeof row.cooperationUnit === 'object' && row.cooperationUnit.length) {
        selectedRow.cooperationUnit = row.cooperationUnit.join(',');
      }
    }
    if (flag) {
      message.warning(`办理进度为${ONFILE}的数据不可编辑！`).then()
    }
    ContractListService.batchUpdateContractList(rows).then((rsp) => {
      actionThen(rsp);
      setUpdateCount(updateCount + 1)
    }).catch(showErr).finally(() => setConfirmLoading(false));
  }

  const handleAdd = () => {
    const user = securityStore.getLoggedUser()
    setContractListRow({
      clStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      clDocDate: dayjs(),
      contractParties: [
        {
          id: '',
          contractListId: '',
          contractPrivity: undefined,
          contractParties: undefined
        },
        {
          id: '',
          contractListId: '',
          contractPrivity: undefined,
          contractParties: undefined
        }
      ]
    } as ContractListRow)
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  const handleView = (record: ContractListRow) => {
    setContractListRow(record)
    setIsView(true)
    setIsBatchUpdate(false)
    setIsModalVisible(true);
  }

  const handleEdit = async (record: ContractListRow) => {

    try {
      const rsp = await ProcessRecordsService.getProcessRecords({
        businessId: record.id,
        pageNum: 1,
        perPage: 999,
        type: TableName,
      })
      const process = rsp.items.length ? rsp.items[0].process : ''
      const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
      const con = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
      // 如果本轮有撤回 （id为空） 否则 （发起最新一轮的提交的人的id）
      setSubmitterId(con ? undefined : userId)
      setLatestProcess(process) // 发起最新一轮的标志

      setContractListRow(record)
      setIsView(false)
      setIsBatchUpdate(false)
      setIsModalVisible(true);
    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleEditBatch = async () => {

    try {

      if (selectedRowKeys.length === 0) {
        message.warning("请选择要操作的数据！").then()
        return
      }

      // 要操作的数据
      const rows = contractListStore.contractListDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))

      // 办理流程辅助字段：办理进度
      let clStatus: string

      // 判断是否符合批量操作条件
      const con = rows.every((o, i, arr) => {
        const firstRow = arr[0]
        clStatus = firstRow.clStatus
        if (o.personResponsible !== firstRow.personResponsible) {
          message.warning("办理人员不一致！").then()
          return false
        } else if (o.clStatus !== firstRow.clStatus) {
          message.warning("办理进度不一致！").then()
          return false
        } else return true
      })

      if (con) {

        let latestProcessList: string[] = []
        let submitterIdList: string[] = []
        for (let i = 0; i < rows.length; i++) {
          const item = rows[i]
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: item.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const process = rsp.items.length ? rsp.items[0].process : ''
          const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
          const userIdCon = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
          latestProcessList.push(process)
          submitterIdList.push(userIdCon ? undefined : userId)
        }

        // 如果提交人全部一致才赋值给参数:【提交人】
        setSubmitterId(new Set(submitterIdList).size === 1 ? submitterIdList[0] : undefined)
        setLatestProcessBatch(latestProcessList)
        setIsView(false)
        setIsBatchUpdate(true)
        setContractListRow({clStatus} as ContractListRow)
        setIsModalVisible(true)

      }

    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleDeleteBatch = (record?: ContractListRow) => {
    const keys = [...selectedRowKeys]
    if (!record && keys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = [];
    if (record) {
      ids.push(record.id)
    } else {
      ids = keys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        ContractListService.deleteContractList(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(contractListStore.contractListReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const insertOrUpdate = async (row: ContractListRow) => {
    if (typeof row.clDate === 'object' && row.clDate) {
      row.clDate = row.clDate.format(DATE_FMT);
    }
    if (typeof row.clDocDate === 'object' && row.clDocDate) {
      row.clDocDate = row.clDocDate.format(DATE_FMT);
    }
    if (typeof row.clEndDate === 'object' && row.clEndDate) {
      row.clEndDate = row.clEndDate.format(DATE_FMT);
    }
    if (typeof row.cooperationUnit === 'object') {
      row.cooperationUnit = []
      for (let i = 0; i < row.contractParties.length; i++) {
        const item = row.contractParties[i]
        row.cooperationUnit.push(item.contractParties)
      }
      row.cooperationUnit = row.cooperationUnit
        .filter((v, i, self) => self.indexOf(v) === i) // 去重
        .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
        .join(',');
    }
    row.contractSettlements = undefined
    row.bankStatements = undefined
    if (row.id) {
      const rsp = await ContractListService.updateContractList(row)
      actionThen(rsp)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    } else {
      const rsp = await ContractListService.addContractList(row)
      actionThen(rsp)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    }
  }

  const actionThen = (rsp: ResponseEntity<any>) => {
    if (rsp.code === 200) {
      message.success("操作成功！").then()
    } else {
      message.warning(rsp.message).then()
    }
    setIsModalVisible(false);
    setContractListRow(undefined)
    refreshData(contractListStore.contractListReq)
    setSelectedRowKeys([])
  }

  const handleOk = (open: boolean) => {
    const row = {...contractListRow!}
    basicInfo.current!.validateFields().then(async (values) => {
      setConfirmLoading(true)
      Object.assign(row, values)

      if (open) {

        // 将row和按钮先存起来
        setSubmitRow(row)

        // 弹窗选择处理人
        setProcessRecordsIsOpen(true)
        setProcessRecordsRow(undefined)

      } else {

        const user = securityStore.getLoggedUser()

        if (isBatchUpdate) {
          batchUpdate(row)
        } else {
          // 默认处理人员是自己
          if (!row.id) {
            row.personResponsible = user._id
            row.personResponsibleName = user.name
          }

          try {
            await insertOrUpdate(row)
          } catch (error) {
            setProcessRecordsIsOpen(false)
            setProcessRecordsRow(undefined)
            setConfirmLoading(false)
            setIsModalVisible(false);
            message.warning(error.message)
          }
        }

      }

    }).catch(logger.warn)
  }

  const handleSubmit = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)

      const user = securityStore.getLoggedUser()
      const btnText = row.status

      // 当类型是撤回时 默认办理人员为提交人(自己)
      if (btnText === FORM_REVOCATION_TEXT) {
        row.personResponsible = user._id || submitterId
        row.personResponsibleName = user.name
      }

      // 主表
      const subRow = {...submitRow}
      subRow.personResponsible = row.personResponsible
      subRow.personResponsibleName = row.personResponsibleName
      subRow.clStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.clStatus
      // 主表在撤回、退回、变更时->更新局部数据
      if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在转交、提交时->更新局部数据
      if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在提交时->更新局部数据
      if (btnText === FORM_COMMIT_TEXT) {
        subRow.clDocDate = new Date().toLocaleDateString().replace(/\//g, '-')
      }
      // 主表在结办时->更新局部数据
      if (btnText === FORM_CLOSE_TEXT) {

      }

      // 办理记录表
      row.type = TableName
      row.businessId = subRow.id // 有可能会没值
      row.status = btnText
      row.userId = user._id
      row.userName = user.name
      row.personResponsible = undefined
      row.personResponsibleName = undefined
      if (btnText === FORM_COMMIT_TEXT) {
        const date = new Date()
        row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      } else {
        row.process = latestProcess
      }
      if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
        row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
      } else {
        row.conca = `${user.name}${btnText}了。`
      }

      // 判断是否 先新增主表 后新增记录和通知表
      if (subRow.id) {

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.clCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "合同审批",
          wnDesc: `您参与办理的合同审批（${subRow.clCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      } else {

        let subRsp: ResponseEntity<ContractListRow>
        try {
          subRsp = await insertOrUpdate(subRow)
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }

        if (subRsp.data) {
          // 重新赋值再提交
          row.businessId = subRsp.data.id
          await ProcessRecordsService.addProcessRecords(row)
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: subRsp.data.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const notificationScopeUserId = rsp.items
            .map(o => o.userId)
            .concat(row.person)
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(id => id !== user._id) // 过滤出不是自己的数据
            .join(',') || ''
          const notificationScopeUserName = rsp.items
            .map(o => o.userName)
            .concat((row.personName || '').split(','))
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(name => name !== user.name) // 过滤出不是自己的数据
            .join(',') || ''
          await processStore.newAssignmentNotice({
            businessId: subRsp.data.id,
            businessCode: subRsp.data.clCode,
            cooperationUnit: (subRsp.data.cooperationUnit as string).split(',')[0] || 'N/A',
            notificationScopeUserId,
            notificationScopeUserName,
            personResponsible: subRsp.data.personResponsible,
            personResponsibleName: subRsp.data.personResponsibleName,
            responsibilityProject: subRsp.data.responsibilityProject,
            type: "合同审批",
            wnDesc: `您参与办理的合同审批（${subRsp.data.clCode}），${row.conca} 请知悉！`,
            wnStatus: "未读"
          })
        } else {
          message.warning('主表保存成功，办理记录表和任务通知表提交失败')
        }

      }
      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  const handleSubmitBatch = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {

      const rows = contractListStore.contractListDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
      for (let i = 0; i < rows.length; i++) {

        Object.assign(row, values)

        const user = securityStore.getLoggedUser()
        const btnText = row.status

        // 当类型是撤回时 默认办理人员为提交人(自己)
        if (btnText === FORM_REVOCATION_TEXT) {
          row.personResponsible = user._id || submitterId
          row.personResponsibleName = user.name
        }

        // 主表
        const subRow = {...rows[i]}
        subRow.personResponsible = row.personResponsible
        subRow.personResponsibleName = row.personResponsibleName
        subRow.clStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.clStatus
        // 主表在撤回、退回、变更时->更新局部数据
        if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在转交、提交时->更新局部数据
        if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在提交时->更新局部数据
        if (btnText === FORM_COMMIT_TEXT) {
          subRow.clDocDate = new Date().toLocaleDateString().replace(/\//g, '-')
        }
        // 主表在结办时->更新局部数据
        if (btnText === FORM_CLOSE_TEXT) {

        }

        // 办理记录表
        row.type = TableName
        row.businessId = subRow.id // 有可能会没值
        row.status = btnText
        row.userId = user._id
        row.userName = user.name
        row.personResponsible = undefined
        row.personResponsibleName = undefined
        if (btnText === FORM_COMMIT_TEXT) {
          const date = new Date()
          row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
        } else {
          row.process = latestProcessBatch[i]
        }
        if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
          row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
        } else {
          row.conca = `${user.name}${btnText}了。`
        }

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.clCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "合同审批",
          wnDesc: `您参与办理的合同审批（${subRow.clCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      }

      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  /**
   * 操作记录按钮
   */
  const handleProcessRecordsClick = async (record: ContractListRow) => {
    const rsp = await ProcessRecordsService.getProcessRecords({
      businessId: record.id,
      pageNum: 1,
      perPage: 999,
      type: TableName
    })
    if (!rsp) {
      message.warning('查无数据')
      return
    }
    setProcessRecordsDataSource(rsp.items)
    setProcessRecordsOpen(true)
  }

  const handleCopyClick = (record: ContractListRow) => {
    const user = securityStore.getLoggedUser()
    setContractListRow({
      ...record,
      id: '',
      clFile: '',
      clStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      clDocDate: dayjs(),
      contractParties: [
        {
          id: '',
          contractListId: '',
          contractPrivity: undefined,
          contractParties: undefined
        },
        {
          id: '',
          contractListId: '',
          contractPrivity: undefined,
          contractParties: undefined
        }
      ]
    })
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        updateCount={updateCount}
        collapsed={contractListStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        handleShowColumnReset={handleShowColumnReset}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={props.isModalSelect ? columns.filter(o => o.key !== 'action') : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}
        setConfirmLoading={setConfirmLoading}
        isContractList
        items={[
          {
            key: 'handleAdd',
            icon: <PlusOutlined/>,
            onClick: () => handleAdd()
          },
          {
            key: 'handleDeleteBatch',
            icon: <DeleteOutlined/>,
            onClick: () => handleDeleteBatch()
          },
          {
            key: 'handleEditBatch',
            icon: <EditOutlined/>,
            onClick: () => handleEditBatch()
          },
        ]}></HeaderActionCom>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        size={"middle"}
        loading={props.dataSource ? false : contractListStore.contractListDatasource.loading || confirmLoading}
        pagination={!props.dataSource && {
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        bordered
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys)
          },
          columnWidth: 50
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                (showColumn as string[]).map((key, index) =>
                  key.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={summaryTotalObj[key.slice(0, key.indexOf('-'))]}></TableMoneyText> 元
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}/>

      { /*操作记录表*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsOpen}
        width={LARGE_MODAL_WIDTH_OTHER}
        onCancel={() => setProcessRecordsOpen(false)}
        destroyOnClose
        footer={null}>
        <ProcessRecords
          isHiddenOptions
          dataSource={processRecordsDataSource}
          isModalSelect
          locationPathname={'/processRecords'}/>
      </CustomDraggableModal>

      { /*操作记录表单*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsIsOpen}
        width={LARGE_MODAL_WIDTH}
        destroyOnClose
        onCancel={() => {
          setProcessRecordsIsOpen(false)
          setConfirmLoading(false)
        }}
        onOk={isBatchUpdate ? handleSubmitBatch : handleSubmit}>
        <ProcessRecordsBasicInfo
          tableName={TableName}
          businessId={contractListRow?.id}
          submitterId={submitterId}
          userId={contractListRow?.personResponsible}
          status={contractListRow?.clStatus}
          row={processRecordsRow}
          ref={processRecordsBasicInfo}/>
      </CustomDraggableModal>

      { /*关联合同审批*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">关联合同审批</div>}
        open={contractListOpen}
        width={LARGER_MODAL_WIDTH}
        onCancel={() => setContractListOpen(false)}
        destroyOnClose
        footer={null}>
        <ContractList
          dataSource={contractListDataSource}
          isModalSelect
          locationPathname={'/contractList'}/>
      </CustomDraggableModal>

      <CustomForm
        title={'合同审批'}
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={isModalVisible}
        submitterId={submitterId}
        confirmLoading={confirmLoading}
        userId={contractListRow?.personResponsible}
        status={contractListRow?.clStatus}
        extraDisabled={!contractListRow?.id}
        isView={isView}
        extraOnClick={() => handlePrint(contractListRow)}
        item={[
          {
            key: 'handleContractSettlementClick',
            icon: <RetweetOutlined/>,
            hidden: !FORM_INITIATE_STATE.includes(contractListRow?.clStatus),
            onClick: () => handleContractSettlementClick(contractListRow)
          },
          {
            key: 'handleCopyClick',
            icon: <FieldTimeOutlined/>,
            disabled: !contractListRow?.id,
            onClick: () => handleCopyClick(contractListRow)
          },
          {
            key: 'handleProcessRecordsClick',
            icon: <FieldTimeOutlined/>,
            disabled: !contractListRow?.id,
            onClick: () => handleProcessRecordsClick(contractListRow)
          },
        ]}>
        <BasicInfo row={{...contractListRow!}} ref={basicInfo} isView={isView} isBatchUpdate={isBatchUpdate}/>
      </CustomForm>

      <WindowPrint
        isHiddenTime
        title={"合同审批单"}
        contentList={contentList}
        isModalOpen={isModalOpen}
        handleCancel={() => setIsModalOpen(false)}/>
    </div>
  )
}))
