import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import {Spin, Tooltip} from "antd";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {TooltipPlacement} from "antd/es/tooltip";
import {PayrollService} from "@services/PayrollService";
import {staffLoanService} from "@services/StaffLoanService";
import {CostReimbursementService} from "@services/CostReimbursementService";
import {ContractSettlementService} from "@services/ContractSettlementService";
import {TaxAccountingService} from "@services/TaxAccountingService";
import {FinalSettlementService} from "@services/FinalSettlementService";
import {BankStatementService} from "@services/BankStatementService";
import {BLACK_LIST} from "@/config";
import {WorkerRecruitmentService} from "@services/WorkerRecruitmentService";
import {CostApplicationService} from "@services/CostApplicationService";
import {CostExpendService} from "@services/CostExpendService";
import {ProjectCollaborationService} from "@services/ProjectCollaborationService";
import {TaskSupervisionService} from "@services/TaskSupervisionService";
import {WorkScheduleService} from "@services/WorkScheduleService";

export interface PropsType {
  type: string
  value: string | string[]
  placement?: TooltipPlacement
}

export const getApplicationFrom = async (type: string, value: string | string[]) => {
  let ids = []
  if (typeof value === 'string') {
    ids = value
      .split(',')
      .filter((v, i, self) => self.indexOf(v) === i)
      .filter(o => !BLACK_LIST.includes(o))
  } else if (typeof value === 'object') {
    ids = value
      .filter((v, i, self) => self.indexOf(v) === i)
      .filter(o => !BLACK_LIST.includes(o))
  }
  if (!ids.length) {
    return String(value)
  }

  let res = String(value)

  switch (type) {
  case '员工档案':
    const workerRecruitmentRsp = await WorkerRecruitmentService.getBatch(ids)
    if (workerRecruitmentRsp.length) {
      res = workerRecruitmentRsp.map(o => o?.name).join(',')
    }
    break
  case '工资发放':
    const payrollRsp = await PayrollService.getBatch(ids)
    if (payrollRsp.code === 200 && payrollRsp.data.length) {
      res = payrollRsp.data.map(o => o.payrollNumber).join(',')
    }
    break
  case '费用申请':
    const employeeFeeApplicationRsp = await CostApplicationService.getBatch(ids)
    if (employeeFeeApplicationRsp.code === 200 && employeeFeeApplicationRsp.data.length) {
      res = employeeFeeApplicationRsp.data.map(o => o.efaApplyCode).join(',')
    }
    break
  case '社保清缴':
    break
  case '员工借款':
    const staffLoanRsp = await staffLoanService.getBatch(ids)
    if (staffLoanRsp.code === 200 && staffLoanRsp.data.length) {
      res = staffLoanRsp.data.map(o => o.elReqCode).join(',')
    }
    break
  case '费用报销':
    const costReimbursementRsp = await CostReimbursementService.getBatch(ids)
    if (costReimbursementRsp.code === 200 && costReimbursementRsp.data.length) {
      res = costReimbursementRsp.data.map(o => o.erApprovalCode).join(',')
    }
    break
  case '费用支出':
    const employeeFeePayRsp = await CostExpendService.getBatch(ids)
    if (employeeFeePayRsp.code === 200 && employeeFeePayRsp.data.length) {
      res = employeeFeePayRsp.data.map(o => o.efaApplyCode).join(',')
    }
    break
  case '合同结算':
    const contractSettlementRsp = await ContractSettlementService.getBatch(ids)
    if (contractSettlementRsp.code === 200 && contractSettlementRsp.data.length) {
      res = contractSettlementRsp.data.map(o => o.csCode).join(',')
    }
    break
  case '税费核算':
    const taxAccountingRsp = await TaxAccountingService.getBatch(ids)
    if (taxAccountingRsp.code === 200 && taxAccountingRsp.data.length) {
      res = taxAccountingRsp.data.map(o => o.taCode).join(',')
    }
    break
  case '税费缴纳':
    const finalSettlementRsp = await FinalSettlementService.getBatch(ids)
    if (finalSettlementRsp.code === 200 && finalSettlementRsp.data.length) {
      res = finalSettlementRsp.data.map(o => o.fsCode).join(',')
    }
    break
  case '资金出纳':
    const bankStatementRsp = await BankStatementService.getBatch(ids)
    if (bankStatementRsp.code === 200 && bankStatementRsp.data.length) {
      res = bankStatementRsp.data.map(o => o.bsCode).join(',')
    }
    break
  case '项目协作':
    const cooperationUnitRsp = await ProjectCollaborationService.getBatch(ids)
    if (cooperationUnitRsp.code === 200 && cooperationUnitRsp.data.length) {
      res = cooperationUnitRsp.data.map(o => o.cuName).join(',')
    }
    break
  case '工作任务':
    const taskSupervisionRsp = await TaskSupervisionService.getBatch(ids)
    if (taskSupervisionRsp.code === 200 && taskSupervisionRsp.data.length) {
      res = taskSupervisionRsp.data.map(o => o.tsCode).join(',')
    }
    break
  case '工作日程':
    const workScheduleRsp = await WorkScheduleService.getBatch(ids)
    if (workScheduleRsp.code === 200 && workScheduleRsp.data.length) {
      res = workScheduleRsp.data.map(o => o.wsCode).join(',')
    }
    break

  default:
    break
  }

  return res
}

export const TableApplicationFormText = observer((props: PropsType) => {

  const [loading, setLoading] = useState<boolean>(true)
  const [value, setValue] = useState<string>('')
  const [tip, setTip] = useState<string>('')

  useEffect(() => {
    getApplicationFrom(props.type, props.value).then(res=>{
      setValue(res)
      setLoading(false)
    })
  }, [props.value, props.type]);

  return <>
    <CopyToClipboard
      onCopy={() => tip ? setTip(tip + '.') : setTip('复制成功')}
      text={value}>
      <Tooltip
        placement={props.placement}
        title={
          <>
            <div>{ tip || '一键复制' }</div>
            <div>{ value }</div>
          </>
        }>
        <Spin size="small" spinning={loading}>
          <div
            onMouseOut={() => setTimeout(() => setTip(''), 600)}
            className={'box c-pointer'}>
            { value }
          </div>
        </Spin>

      </Tooltip>
    </CopyToClipboard>

  </>
})
