import {observer} from "mobx-react";
import {HeaderAction} from "@modules/PunchClock/Components/HeaderAction";
import {Button, Card, Drawer, FormInstance, message, Space, Table} from "antd";
import React, {useEffect, useState} from "react";
import {ColumnsType} from "antd/es/table";
import {
  PunchClockRow,
  ClockInReq,
  GeoLocationReq,
  PunchClockReq,
  SearchPunchClockReq, GeoLocationRow
} from "@services/dto/punchClock";
import {EditOutlined} from "@ant-design/icons";
import {DEFAULT_MODAL_WIDTH} from "@/config";
import {BasicInfo} from "@modules/PunchClock/Components/BasicInfo";
import {PunchClockService} from "@services/PunchClockService";
import {showErr} from "@common/utils";
import {allStores} from "@/stores";
import dayjs from "dayjs";

export const PunchClock = observer(() => {
  const { punchClockStore } = allStores
  const [total, setTotal] = useState<number>(0)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [punchClockReq, setPunchClockReq] = useState<SearchPunchClockReq>({
    perPage: 10,
    pageNum: 1,
  })
  const [dataSource, setDataSource] = useState<PunchClockRow[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const basicInfo = React.createRef<FormInstance>();
  const [punchClockRow, setPunchClockRow] = useState<PunchClockRow>()

  const columns: ColumnsType<PunchClockRow> = [
    {
      title: '项目名称',
      dataIndex: ['project', 'name'],
      key: 'projectName',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => { handleEdit(record) }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <div>{ dayjs(text).format("YYYY-MM-DD") }</div>
        </Space>
      )
    },
    {
      title: '打卡地点',
      dataIndex: 'geoLocationList',
      key: 'geoLocationList',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          {
            text.map((item: GeoLocationRow, i: number) => {
              return (
                <div key={item.id}>{ (i + 1) + '、' + item.addressName }</div>
              )
            })
          }
        </Space>
      )
    },
    {
      title: '上班打卡时间',
      dataIndex: 'clockIns',
      key: 'clockIns',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          {
            text[0].goToWorkTimeHour + " : " + text[0].goToWorkTimeMinutes
          }
        </Space>
      )
    },
    {
      title: '下班打卡时间',
      dataIndex: 'clockIns',
      key: 'clockIns',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          {
            text[text.length - 1].timeFromWorkHour + " : " + text[text.length - 1].timeFromWorkMinutes
          }
        </Space>
      )
    },
    {
      title: '设置人员',
      dataIndex: ['createUser', 'name'],
      key: '',
      align: 'center'
    },
    {
      title: '操作',
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button 
            shape="round" 
            className={"edit-btn"} 
            size={"middle"} 
            icon={<EditOutlined/>}
            onClick={() => { handleEdit(record) }}>修改</Button>
        </Space>
      ),
    }
  ];

  useEffect(() => {
    refreshData(punchClockReq)
  }, [])

  const refreshData = (req: SearchPunchClockReq) => {
    punchClockStore.searchPunchClock(req)
  }

  useEffect(() => {
    setDataSource(punchClockStore.punchClockDatasource.data?.data.items!)
    setTotal(punchClockStore.punchClockDatasource.data?.data.total!)
  }, [punchClockStore.punchClockDatasource])

  const handleEdit = (record: PunchClockRow) => {
    setPunchClockRow(record)
    setIsModalVisible(true)
  }
    
  const handleProjectChange = (projectId: string) => {
    const req = {...punchClockReq}
    req.companyProjectId = projectId
    refreshData(req)
    setPunchClockReq(req)
  }

  const handleAdd = () => {
    setPunchClockRow(undefined)
    setIsModalVisible(true)
  }

  const handleBatchDelete = () => {
    if (selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    setConfirmLoading(true)
    const ids:string[] = selectedRowKeys.map(item => String(item))
    PunchClockService.deleteBatchPunchClock(ids).then(rsp => {
      message.success("删除成功")
      refreshData(punchClockReq)
    }).catch(showErr).finally(() => setConfirmLoading(false))
  }
  
  const handlePageChange = (page: number, pageSize: number) => {
    const req = {...punchClockReq}
    req.pageNum = page
    req.perPage = pageSize
    refreshData(req)
    setPunchClockReq(req)
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  }
  
  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleOk = () => {
    basicInfo.current?.validateFields().then(value => {
      const req:PunchClockReq = {
        companyProjectId: value.companyProjectId,
        clockFrequency: value.clockIns.length,
        clockIns: value.clockIns.map((item: ClockInReq) => ({
          goToWorkTimeHour: item.goToWorkTime?.format("HH"),
          goToWorkTimeMinutes: item.goToWorkTime?.format("mm"),
          clockOutInAdvance: item.clockOutInAdvance,
          timeFromWorkHour: item.goOffWorkTime?.format("HH"),
          timeFromWorkMinutes: item.goOffWorkTime?.format("mm"),
          earlyfromwork: item.earlyfromwork,
          goToWorkIsNextDay: item.goToWorkIsNextDay,
          timeFromIsNextDay: item.timeFromIsNextDay
        })),
        geoLocationList: value.geoLocationList.map((item: GeoLocationReq) => ({
          id: item.id,
          clockInScope: item.clockInScope,
          loc: {
            x: item.location?.split(",")[0],
            y: item.location?.split(",")[1]
          },
          addressName: item.addressName
        }))
      }
      setConfirmLoading(true)
      if (value.id) {
        PunchClockService.updatePunchClock(value.id, req).then(rsp => {
          message.success("修改成功")
          setIsModalVisible(false)
          refreshData(punchClockReq)
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        PunchClockService.savePunchClock(req).then(rsp => {
          message.success("新增成功")
          setIsModalVisible(false)
          refreshData(punchClockReq)
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    })
  }

  return <>
    <HeaderAction 
      handleAdd={handleAdd}
      handleBatchDelete={handleBatchDelete}
      handleProjectChange={handleProjectChange} />
    
    <Card className={"margin-top-sm"}>
      <Table
        rowKey={"id"}
        dataSource={dataSource}
        columns={columns}
        size={"middle"}
        loading={punchClockStore.punchClockDatasource.loading}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: punchClockReq.perPage,
          current: punchClockReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        rowSelection={{selectedRowKeys, onChange: onSelectChange}}/>
    </Card>

    <Drawer
      title={<div className="text-align-center">打卡设置</div>}
      width={DEFAULT_MODAL_WIDTH}
      open={isModalVisible}
      onClose={handleCancel}>

      <BasicInfo ref={basicInfo} row={{...punchClockRow!}}/>

      <div className={"drawer-bottom"}>
        <Button onClick={handleCancel}>取消</Button>
        <Button type="primary" onClick={handleOk} loading={confirmLoading} className={"margin-left-xs"}>确定</Button>
      </div>
    </Drawer>
  </>
})