import {observer} from "mobx-react";
import {allStores} from "@/stores";
import React, {useEffect, useState} from "react";
import {Button, FormInstance, message, Modal, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {Salary} from "@services/dto/workerRecruitment";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {DEFAULT_MODAL_WIDTH} from "@/config";
import {SalaryInfo} from "@modules/Archives/Components/SalaryInfo";
import {WorkerRecruitmentService} from "@services/WorkerRecruitmentService";
import {showErr} from "@common/utils";
import _ from "lodash";

export interface PropsType {
  newSalary: number
}

export const SalarySet = observer((props: PropsType) => {
  const {wrStore} = allStores;
  const {wr: stateWr} = wrStore
  const wr = stateWr.data!;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [salaryObj, setSalaryObj] = useState<Salary>()
  const salaryInfo = React.createRef<FormInstance>();

  const columns: ColumnsType<Salary> = [
    {
      title: '工资类型',
      align: 'center',
      dataIndex: ['name'],
    },
    {
      title: '金额',
      align: 'center',
      dataIndex: ['amount'],
    },
    {
      title: '操作',
      align: "center",
      render: (_, record, index) => (
        <Space>
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined/>}
            onClick={() => {
              handleEdit(record)
            }}>修改</Button>
          <Button shape="round" className={"delete-btn"} size={"middle"}
            icon={<DeleteOutlined/>} onClick={() => {
              handleDelete(record)
            }}>删除</Button>
        </Space>
      )
    }
  ];

  useEffect(() => {
    if (props.newSalary) {
      setSalaryObj(undefined)
      setIsModalOpen(true)
    }
  }, [props.newSalary])

  const handleEdit = (record: Salary) => {
    setSalaryObj(record)
    setIsModalOpen(true)
  }

  const handleDelete = (record: Salary) => {
    Modal.confirm({
      title: `是否删除？`,
      okText: "确定",
      cancelText: "取消",
      onOk() {
        const newArr = [...wr.salaries]
        const i = newArr.findIndex(item => item.typeName === record.typeName)
        if (_.isNil(i)) {
          return
        }
        newArr.splice(i, 1)
        callUpdateApi(newArr)
      },
    })
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleOk = () => {
    salaryInfo.current?.validateFields().then(values => {
      const newArr = [...wr?.salaries || []]
      const obj:Salary = {
        name: values.salaryType.label,
        typeName: values.salaryType.value,
        amount: values.amount
      }
      if (salaryObj) {
        const i = newArr.findIndex(s => s.typeName === salaryObj.typeName)
        newArr[i] = obj
      } else {
        newArr.push(obj)
      }
      if (newArr.filter(s => s.typeName === values.salaryType.value).length > 1) {
        message.warning("已有该工资类型，不能重复添加")
        return;
      }
      callUpdateApi(newArr)
    })
  }

  const callUpdateApi = (newArr: Salary[]) => {
    setConfirmLoading(true)
    wrStore.updateSalaries(newArr)
    const req = {salaries: newArr.map(i => _.omit(i, '_id'))}
    WorkerRecruitmentService.updateWorkerRecruitment(wr._id, req).then(rsp => {
      message.success('操作成功')
      setIsModalOpen(false)
    }).catch(showErr).finally(() => setConfirmLoading(false))
  }

  return <>
    <Table
      rowKey={'typeName'}
      columns={columns}
      dataSource={wr?.salaries || []}
      size={"middle"}
      pagination={false}/>

    <Modal
      title={<div className="text-align-center">工资信息</div>}
      width={DEFAULT_MODAL_WIDTH}
      open={isModalOpen}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      onOk={handleOk}>
      <SalaryInfo row={{...salaryObj!}} ref={salaryInfo}/>
    </Modal>
  </>
})