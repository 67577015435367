import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {CostApplicationReq, CostApplicationRow} from "@services/dto/costApplication";
import {CostApplicationService} from "@services/CostApplicationService";
import {BLACK_LIST} from "@/config";
import {convertCurrency, formatMoney} from "@common/utils";
import {FilingService} from "@services/FilingService";

export class CostApplicationStore {
  costApplicationDatasource: StateFullData<Page<CostApplicationRow>> = {}
  costApplicationSelectDatasource: StateFullData<Page<CostApplicationRow>> = {}
  costApplicationRow?: CostApplicationRow
  costApplicationOption?: string
  costApplicationDetail: StateFullData<ResponseEntity<CostApplicationRow>> = {}
  costApplicationDetails: StateFullData<ResponsesEntity<CostApplicationRow>> = {}
  costApplicationReq: CostApplicationReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateCostApplicationRow(row: CostApplicationRow) {
    this.costApplicationRow = {...row}
  }

  updateCostApplicationOption(option: string) {
    this.costApplicationOption = option
  }

  getCostApplication(req: CostApplicationReq) {
    this.costApplicationDatasource = {...this.costApplicationDatasource, loading: true}
    this.costApplicationReq = {...this.costApplicationReq, ...req}
    CostApplicationService.getCostApplication(req).then(rsp => {
      runInAction(() => this.costApplicationDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.costApplicationDatasource = {...this.costApplicationDatasource, loading: false, err})
    })
  }

  getSelectCostApplication(req: CostApplicationReq) {
    this.costApplicationSelectDatasource = {...this.costApplicationSelectDatasource, loading: true}
    CostApplicationService.getCostApplication(req).then(rsp => {
      runInAction(() => this.costApplicationSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.costApplicationSelectDatasource = {
        ...this.costApplicationSelectDatasource,
        loading: false,
        err
      })
    })
  }

  getDetailById(id: string) {
    this.costApplicationDetail = {...this.costApplicationDetail, loading: true}
    CostApplicationService.getDetailById(id).then(rsp => {
      runInAction(() => this.costApplicationDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.costApplicationDetail = {...this.costApplicationDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.costApplicationDetails = {...this.costApplicationDetails, loading: true}
    CostApplicationService.getBatch(ids).then(rsp => {
      runInAction(() => this.costApplicationDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.costApplicationDetails = {...this.costApplicationDetails, loading: false, err})
    })
  }

  async getPrintList(record: CostApplicationRow): Promise<LabelValue[]> {

    const row = this.costApplicationDatasource.data?.items.find(o => o.id === record.id)

    // 存档批号
    let efaFileArchiveRow = undefined
    if (row.efaFileArchive && !BLACK_LIST.includes(row.efaFileArchive)) {
      const efaFileArchiveRsp = await FilingService.getBatch([row.efaFileArchive])
      if (efaFileArchiveRsp && efaFileArchiveRsp.code !== 200) {
        return
      }
      efaFileArchiveRow = efaFileArchiveRsp.data[0]
    }

    return [
      {label: '制单日期', value: String(row.efaAccountingObject)},
      {label: '制单编号', value: row.efaApplyCode},
      {label: '费用类别', value: row.efaPayType},
      {label: '费用说明', value: row.efaTitle},
      {label: '关联立项', value: row.efaRelatedProjectApproval},
      {label: '申请金额(元)', value: `${formatMoney(row.efaApplyAmount)}元（${convertCurrency(row.efaApplyAmount)}）`},
      {label: '存档批号', value: efaFileArchiveRow?.faBatchNumber},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const costApplicationStore = new CostApplicationStore();
