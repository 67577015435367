import {makeAutoObservable, runInAction} from "mobx";
import {Page, StateFullData} from "@services/dto/common";
import {ConstructionLogService} from "@services/ConstructionLogService";
import {ConstructionLogRow, PageParam} from "@services/dto/constructionLog";

export class ConstructionLogStore {
  constructionLogDatasource: StateFullData<Page<ConstructionLogRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getConstructionLog(req: PageParam) {
    this.constructionLogDatasource = {...this.constructionLogDatasource, loading: true}
    ConstructionLogService.getConstructionLog(req).then(rsp => {
      runInAction(() => this.constructionLogDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.constructionLogDatasource = {...this.constructionLogDatasource, loading: false, err})
    })
  }
}

export const constructionLogStore = new ConstructionLogStore();
