import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {FilingService} from "@services/FilingService";
import {TooltipPlacement} from "antd/es/tooltip";
import {message, Spin, Tooltip} from "antd";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {FolderOpenOutlined} from "@ant-design/icons";
import {previewFile} from "@components/CustomUpload";
import {FilingRow} from "@services/dto/filing";
import {isChineseStr} from "@common/utils";
import {BLACK_LIST} from "@/config";

export interface PropsType {
  id?: string
  value: string
  placement?: TooltipPlacement
}

export const getBatchTableTableFiling = async (values: string[]): Promise<FilingRow[]> => {
  const ids = (values as string[])
    .filter((v, i, self) => self.indexOf(v) === i) // 去重
    .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
    .filter(value => !isChineseStr(value)) // 去不是id的
  if (ids.length) {
    const rsp = await FilingService.getBatch(ids)
    if (rsp.code === 200) {
      return rsp.data
    } else {
      return []
    }
  } else {
    return []
  }
}

export const TableFilingText = observer((props: PropsType) => {

  const [loading, setLoading] = useState<boolean>(false)
  const [tip, setTip] = useState<string>('')
  const [value, setValue] = useState<string>('/')
  const [url, setUrl] = useState<string>('')
  const [isUrl, setIsUrl] = useState<boolean>(false)

  useEffect(() => {
    if (props.value) {
      setValue(props.value)
    }
  }, [props.value])

  // 文件预览
  const handlePreview = () => {
    if (!props.id) {
      message.warning('该存档没有电子版!').then()
      return
    }
    if (isUrl) {
      if (url) {
        previewFile(url)
      } else {
        message.warning('该存档没有电子版!').then()
        return
      }
    } else {
      setLoading(true)
      FilingService.getDetailById(props.id).then(rsp => {
        if (rsp.code === 200) {
          if (rsp.data.efaAccountingObject) {
            previewFile(url)
            setUrl(rsp.data.efaAccountingObject as string)
          } else {
            message.warning('该存档没有电子版!').then()
          }
        }
        setIsUrl(true)
        setLoading(false)
      })
    }
  }

  return <>
    <Spin size="small" spinning={loading}>
      <div className={'box'}>
        <Tooltip
          className={'margin-right-xs'}
          placement={props.placement}
          title={
            <>
              <div>查看预览</div>
              <div>{ url }</div>
            </>
          }>
          <FolderOpenOutlined
            onClick={handlePreview}
            style={{color: url ? '#007AAE' : '#808080'}}
            className={'c-pointer'}/>
        </Tooltip>
        <CopyToClipboard
          onCopy={() => tip ? setTip(tip + '.') : setTip('复制成功')}
          text={value}>
          <Tooltip
            placement={props.placement}
            title={
              <>
                <div>{ tip || '一键复制' }</div>
                <div>{ value }</div>
              </>
            }>
            <span
              onMouseOut={() => setTimeout(() => setTip(''), 600)}
              className={'c-pointer'}>
              { value }
            </span>
          </Tooltip>
        </CopyToClipboard>
      </div>
    </Spin>
  </>
})
