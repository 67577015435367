import React, {useEffect} from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row, Select} from "antd";
import {FormInstance} from "antd/es";
import {RewardPunishmentRow} from "@services/dto/rewardPunishment";
import dayjs from "dayjs";
import {CascaderPerson} from "@components/CascaderPerson";
import {PersonCascader} from "@services/dto/talent";

export interface PropsType {
    row: RewardPunishmentRow
}

export const BasicInfo = React.forwardRef<FormInstance, PropsType>((props, ref) => {
  const [form] = Form.useForm()
  useEffect(() => {
    form.resetFields()
    if (props.row.id) {
      if (props.row.rewardTime) {
        props.row.rewardTime = dayjs(props.row.rewardTime, 'YYYY-MM-DD');
      }
      props.row.person = [props.row.projectId, props.row.professionalLeagues, props.row.professional, props.row.userId]
    }
    form.setFieldsValue(props.row);
  }, [props.row])

  const handlePersonChange = (value: string[], selectedOptions: PersonCascader[]) => {
    return value
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Form.Item
            label="员工"
            name="person"
            valuePropName={"person"}
            getValueFromEvent={handlePersonChange}
            rules={[{ required: true, message: '请选择员工!' }]}>
            <CascaderPerson disabled={!!props.row.id}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="奖惩日期"
            name="rewardTime"
            rules={[{required: true, message: '请选择奖惩日期!'}]}>
            <DatePicker placeholder={"请选择奖惩日期"} className={'width-100-percentage'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="奖惩类型"
            name="status"
            rules={[{ required: true, message: '请选择奖励类型!' }]}>
            <Select
              placeholder="请选择奖励类型"
              allowClear
              className={"width-100-percentage"}>
              <Select.Option value={1}>奖励</Select.Option>
              <Select.Option value={2}>处罚</Select.Option>
              <Select.Option value={3}>其他扣款</Select.Option>
              <Select.Option value={4}>其他加款</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="奖惩金额"
            name="amount"
            rules={[{ required: true, message: '请输入奖惩金额!' }]}>
            <InputNumber
              placeholder={"请输入奖惩金额"}
              precision={1}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="原因"
            name="remark"
            rules={[{ required: false, message: '请输入原因!' }]}>
            <Input.TextArea placeholder="请输入原因" autoSize={{ minRows: 3, maxRows: 5 }}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
})