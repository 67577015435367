import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {PageParam, ProjectLogRow} from "@services/dto/projectLog";

export class ProjectLogService {
  static async getProjectLog(req: PageParam): Promise<Page<ProjectLogRow>> {
    return await apiV1.get("/projectLog", {params: req})
  }

  static async addProjectLog(row: ProjectLogRow): Promise<ResponseEntity<ProjectLogRow>> {
    return await apiV1.post("/projectLog", {...row})
  }

  static async updateProjectLog(row: ProjectLogRow): Promise<ResponseEntity<ProjectLogRow>> {
    return await apiV1.put("/projectLog", {...row})
  }

  static async deleteProjectLog(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/projectLog", {data: ids})
  }
}
