import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {FormInstance, message, Modal, Space, Table} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {accAdd, logger, parseURLParams, safeCompute, showErr} from "@common/utils";
import {BasicInfo} from "@modules/CostReimbursement/Components/BasicInfo";
import {CostReimbursementService} from "@services/CostReimbursementService";
import {useLocation, useNavigate} from "react-router-dom";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {LabelValue, ResponseEntity} from "@services/dto/common";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import {WindowPrint} from "@modules/WindowPrint";
import {HeaderActionCom} from "@modules/CostReimbursement/Components/HeaderActionCom";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FieldTimeOutlined,
  PlusOutlined,
  PrinterOutlined,
  RetweetOutlined
} from "@ant-design/icons";
import {ProjectService} from "@services/ProjectService";
import dayjs from "dayjs";
import {TableFilingText} from "@components/TableFilingText";
import {
  CostReimbursementReq,
  CostReimbursementRow,
  CostReimbursementSummaryTotal
} from "@services/dto/costReimbursement";
import {BasicInfo as ProcessRecordsBasicInfo} from "@modules/ProcessRecords/Components/BasicInfo";
import {
  APPROVE_FORM_TRANS_STATE,
  BLACK_LIST,
  DATE_FMT,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  FORM_ALTERNATE_TEXT,
  FORM_CLOSE_TEXT,
  FORM_COMMIT_TEXT,
  FORM_DISABLED_STATE,
  FORM_HANDOVER_TEXT,
  FORM_INITIATE_STATE,
  FORM_PERSON_RESPONSIBLE_WHITELIST,
  FORM_RETURN_TEXT,
  FORM_REVOCATION_TEXT,
  LARGE_MODAL_WIDTH,
  LARGE_MODAL_WIDTH_OTHER,
  LARGER_MODAL_WIDTH,
  ONFILE,
  READY,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import {getBatchTableTableCostExpend, TableCostExpend} from "@components/TableCostExpend";
import {CustomDraggableModal} from "@components/CustomDraggableModal";
import {CostExpend} from "@modules/CostExpend";
import {CostExpendRow} from "@services/dto/costExpend";
import {TableCostApplication} from "@components/TableCostApplication";
import {CostApplicationRow} from "@services/dto/costApplication";
import {CostApplication} from "@modules/CostApplication";
import {FundApproval} from "@modules/FundApproval";
import {FundApprovalRow} from "@services/dto/fundApproval";
import {getBatchTableFundApprovals, TableFundApprovals} from "@components/TableFundApprovals";
import {TableAuthOptions} from "@components/CustomAuthOptions";
import {ProcessRecordsRow} from "@services/dto/processRecords";
import {CustomForm} from "@components/CustomForm";
import {ProcessRecords} from "@modules/ProcessRecords";
import {ProcessRecordsService} from "@services/ProcessRecordsService";

const wideShowColumn: string[] = [
  "erDateOfPreparation",
  "erApprovalCode",
  // "erExpenseSummary",
  "erReimbursementAmount-summation",
  // "efpEmployeeFeeApplicationIds",
  // "employeeFeePayIds",
  // "fundApprovalId",
  "efpAccountingObjectName",
  // "personResponsible",
  // "responsibilityProject",
  // "cooperationUnit",
  // "efaFileArchive",
  "erReimbursementPay-summation",
  "erReimbursementBalance-summation",
  "erEstatus",
  "action",
]

const narrowShowColumn: string[] = [
  "erDateOfPreparation",
  "erApprovalCode",
  // "erExpenseSummary",
  // "erReimbursementAmount-summation",
  // "efpEmployeeFeeApplicationIds",
  // "employeeFeePayIds",
  // "fundApprovalId",
  "efpAccountingObjectName",
  // "personResponsible",
  // "responsibilityProject",
  // "cooperationUnit",
  // "efaFileArchive",
  // "erReimbursementPay-summation",
  // "erReimbursementBalance-summation",
  "erEstatus",
  "action",
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: CostReimbursementRow[] // 数据源
}

export const TableName = 'contractList'

export const CostReimbursement = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const {
    processStore,
    securityStore,
    costReimbursementStore,
    fundApprovalStore,
    screenResolutionStore,
    tableColumnsStore
  } = allStores
  const navigate = useNavigate();
  const basicInfo = React.createRef<FormInstance>();
  const processRecordsBasicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<CostReimbursementRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [isBatchUpdate, setIsBatchUpdate] = useState(false);
  const [costReimbursementRow, setCostReimbursementRow] = useState<CostReimbursementRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentList, setContentList] = useState<LabelValue[]>([]);
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<CostReimbursementRow>>([])
  const location = useLocation()
  const [costApplicationOpen, setCostApplicationOpen] = useState<boolean>(false)
  const [costApplicationDataSource, setCostApplicationDataSource] = useState<CostApplicationRow[]>([])
  const [costExpendOpen, setCostExpendOpen] = useState<boolean>(false)
  const [costExpendDataSource, setCostExpendDataSource] = useState<CostExpendRow[]>([])
  const [fundApprovalOpen, setFundApprovalOpen] = useState<boolean>(false)
  const [fundApprovalDataSource, setFundApprovalDataSource] = useState<FundApprovalRow[]>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [processRecordsIsOpen, setProcessRecordsIsOpen] = useState<boolean>(false)
  const [processRecordsRow, setProcessRecordsRow] = useState<ProcessRecordsRow>()
  const [processRecordsOpen, setProcessRecordsOpen] = useState<boolean>(false)
  const [processRecordsDataSource, setProcessRecordsDataSource] = useState<ProcessRecordsRow[]>([])
  const [submitRow, setSubmitRow] = useState<CostReimbursementRow>()
  const [submitterId, setSubmitterId] = useState<string>()
  const [latestProcess, setLatestProcess] = useState<string>()
  const [latestProcessBatch, setLatestProcessBatch] = useState<string[]>()
  const [summaryTotalObj, setSummaryTotalObj] = useState<CostReimbursementSummaryTotal>({
    erReimbursementAmount: 0,
    erReimbursementPay: 0,
    erReimbursementBalance: 0,
  })
  const [searchReq, setSearchReq] = useState<CostReimbursementReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...costReimbursementStore.costReimbursementReq
  })

  const columns: ColumnsType<CostReimbursementRow> = [
    {
      title: '制单日期',
      dataIndex: 'erDateOfPreparation',
      key: 'erDateOfPreparation',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'erApprovalCode',
      key: 'erApprovalCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '类别',
      dataIndex: 'efpType',
      key: 'efpType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '费用摘要',
      dataIndex: 'erExpenseSummary',
      key: 'erExpenseSummary',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '申请金额(元)',
      dataIndex: 'erReimbursementAmount',
      key: 'erReimbursementAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '关联费用申请',
      dataIndex: 'efpEmployeeFeeApplicationIds',
      key: 'efpEmployeeFeeApplicationIds',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableCostApplication
            value={text}
            onClick={() => {
              setCostApplicationDataSource(text)
              setCostApplicationOpen(true)
            }}></TableCostApplication>
        </Space>
      )
    },
    {
      title: '关联费用支出',
      dataIndex: 'employeeFeePayIds',
      key: 'employeeFeePayIds',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableCostExpend
            isId
            text={text}
            promiseValue={getBatchTableTableCostExpend(text)}
            setDataSource={setCostExpendDataSource}
            onClick={() => setCostExpendOpen(true)}></TableCostExpend>
        </Space>
      )
    },
    {
      title: '关联发票登记',
      dataIndex: 'invoiceRegistrationCodes',
      key: 'invoiceRegistrationCodes',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联发票金额(元)',
      dataIndex: 'invoiceRegistrationMoney',
      key: 'invoiceRegistrationMoney',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}/>
        </Space>
      )
    },
    {
      title: '关联资金审批',
      dataIndex: 'fundApprovalId',
      key: 'fundApprovalId',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableFundApprovals
            isId
            text={text}
            promiseValue={getBatchTableFundApprovals(text.split(','))}
            setDataSource={setFundApprovalDataSource}
            onClick={() => setFundApprovalOpen(true)}></TableFundApprovals>
        </Space>
      )
    },
    {
      title: '付款账户',
      dataIndex: 'efpPaymentAccountName',
      key: 'efpPaymentAccountName',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '收款账户',
      dataIndex: 'efpAccountingObjectName',
      key: 'efpAccountingObjectName',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'efaFileArchive',
      key: 'efaFileArchive',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableFilingText id={record.efaFileArchive} value={record.efaFileArchiveCode}></TableFilingText>
        </Space>
      )
    },
    {
      title: '实现金额(元)',
      dataIndex: 'erReimbursementPay',
      key: 'erReimbursementPay-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '余额',
      dataIndex: 'erReimbursementBalance',
      key: 'erReimbursementBalance-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '办理人员',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '主办人员',
      dataIndex: 'organizersUserName',
      key: 'organizersUserName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'erEstatus',
      key: 'erEstatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableAuthOptions
          mainBtn={['handleEdit', 'handleView']}
          items={[
            {
              key: 'handleView',
              icon: <EllipsisOutlined/>,
              onClick: () => handleView(record)
            },
            {
              key: 'handleEdit',
              icon: <EditOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.erEstatus),
              onClick: async () => await handleEdit(record)
            },
            {
              key: 'handlePrint',
              icon: <PrinterOutlined/>,
              onClick: () => handlePrint(record)
            },
            {
              key: 'handleDeleteBatch',
              icon: <DeleteOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.erEstatus),
              onClick: () => handleDeleteBatch(record)
            },
          ]}></TableAuthOptions>
      )
    }
  ];

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
    }
    refreshData(req)
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (costReimbursementStore.costReimbursementDatasource.data) {
      setDataSource(costReimbursementStore.costReimbursementDatasource.data?.items || [])
      setTotal(costReimbursementStore.costReimbursementDatasource.data?.total || 0)
    }
  }, [costReimbursementStore.costReimbursementDatasource.data])

  useEffect(() => {
    if (costReimbursementStore.costReimbursementRow?.costReimbursementId) {
      handleAdd(costReimbursementStore.costReimbursementRow)
      costReimbursementStore.updateCostReimbursementRow({
        ...costReimbursementStore.costReimbursementRow,
        costReimbursementId: undefined,
      })
    }
  }, [costReimbursementStore.costReimbursementRow])

  useEffect(() => {
    if (costReimbursementStore.costReimbursementOption) {
      const [option, paramsStr] = costReimbursementStore.costReimbursementOption.split("?")
      if (option === 'add') {
        handleAdd()
      } else if (paramsStr) {
        const params = parseURLParams(paramsStr)
        if (option === 'edit') {
          CostReimbursementService.getOne(params.id).then(rsp => {
            if (rsp.code === 200) {
              handleEdit(rsp.data).then()
            } else {
              message.warning(rsp.message).then()
            }
          })
        }
      }
      costReimbursementStore.updateCostReimbursementOption(undefined)
    }
  }, [costReimbursementStore.costReimbursementOption])

  useEffect(() => {
    const objInitValue = {
      erReimbursementAmount: 0,
      erReimbursementPay: 0,
      erReimbursementBalance: 0,
    }
    let obj = summaryTotalObj
    const arr = costReimbursementStore.costReimbursementDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        erReimbursementAmount: accAdd(acc.erReimbursementAmount, cur.erReimbursementAmount),
        erReimbursementPay: accAdd(acc.erReimbursementPay, cur.erReimbursementPay),
        erReimbursementBalance: accAdd(acc.erReimbursementBalance, cur.erReimbursementBalance),
      }), objInitValue);
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [costReimbursementStore.costReimbursementDatasource, selectedRowKeys])

  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => o.key !== 'action').filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, costReimbursementStore.collapsed, searchReq.pageNum, searchReq.perPage])

  const handlePrint = async (record: CostReimbursementRow) => {
    const list = await costReimbursementStore.getPrintList(record)
    if (list) {
      setContentList(list)
      setIsModalOpen(true)
    } else {
      message.warning('请检查')
    }
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: CostReimbursementReq) => {
    setSearchReq(req)
    costReimbursementStore.getCostReimbursement(req)
  }

  const handleFundApprovalClick = async (record: CostReimbursementRow) => {

    // 获取详情
    const {
      code: detailCode,
      data: row,
      message: detailMessage
    } = await CostReimbursementService.getDetailById(record.id)
    if (detailCode !== 200 || !row) {
      message.warning(detailMessage || '查无费用报销数据').then()
    }


    // 请检查协作单位
    if (BLACK_LIST.includes(row.cooperationUnit as string)) {
      message.warning("请检查协作单位！").then()
      return
    }

    // 注意责任部门 code
    if (BLACK_LIST.includes(row.responsibilityProject)) {
      message.warning("请检查责任部门！").then()
      return
    }
    const [responsibilityProject] = await ProjectService.getBatchByName([row.responsibilityProject])
    if (!responsibilityProject) {
      message.warning("查无责任部门数据！").then()
      return
    }

    // 判断费用申请与费用报销是否正常
    if (!row.efpEmployeeFeeApplicationIds.length) {
      message.warning("请检查费用申请！").then()
      return
    }
    const costApplicationRow = row.efpEmployeeFeeApplicationIds[0]

    let errMessage = []
    let verification = true
    if (row.responsibilityProject !== costApplicationRow.responsibilityProject) {
      errMessage.push(`责任部门【${row.responsibilityProject}】与关联费用申请的第一条的责任部门【${costApplicationRow.responsibilityProject}】不符`)
      verification = false
    }
    if (!verification) {
      // 全部一起提示
      Modal.info({
        title: `温馨提示-发起资金审批`,
        content: <>{ errMessage.map((item, index) =>
          <div>{ index + 1 }、{ typeof item === 'string' ? item : item.message }</div>) }</>,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          refreshData(costReimbursementStore.costReimbursementReq)
        },
      })
      return
    }

    // 构造资金审批
    fundApprovalStore.updateFundApprovalRow({
      id: undefined,
      code: responsibilityProject?.code,
      fundApprovalId: row.id, // 合同结算发起资金审批
      bankStatementIds: undefined,
      bankStatementCodes: undefined,
      faDate: dayjs(new Date()), // 制单日期
      faCode: undefined, // 制单编号
      faType: undefined, // 款项类别
      faSummary: row.erExpenseSummary, // 款项摘要
      faAmount: row.erReimbursementPay, // 金额
      faPayDate: undefined, // 付款日期
      faStatus: undefined, // 办理进度
      faApplicationForm: row.id, // 关联申请id
      faApplicationFormType: '费用报销', // 关联申请类型
      fundingApprovalTables: [{type: '费用报销', businessId: row.id}],
      contractListIds: [],// 关联合同 商务合同
      contractLists: [],// 关联合同
      lnvoiceRegistration: [], // 关联发票登记
      lnvoiceRegistrationIds: [],
      personResponsibleName: row.personResponsibleName, // 办理人员
      personResponsible: row.personResponsible, // 办理人员
      organizersUserId: undefined,
      organizersUserName: undefined,
      responsibilityProject: row.responsibilityProject, // 责任部门
      cooperationUnit: ['N/A'], // 协作单位
      faFile: undefined, // 存档批号
      faFileList: undefined, // 上传文件
      faPaymentAccount: undefined,
      faPaymentAccountId: undefined,
      baName1: undefined,
      baAccount1: undefined,
      baBlank1: undefined,
      faAccountsReceivable: row.efpAccountingObjectName,
      faAccountsReceivableId: row.efpAccountingObject,
      baName2: row.efpName1,
      baAccount2: row.efpAccount1,
      baBlank2: row.efpBlank1,
    })
    navigate("/fundApproval")
  }

  const batchUpdate = (row: CostReimbursementRow) => {
    let flag = false
    const rows = costReimbursementStore.costReimbursementDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    for (let selectedRow of rows) {
      if (selectedRow.erEstatus === ONFILE) {
        flag = true
        continue
      }
      if (row.erDateOfPreparation && typeof row.erDateOfPreparation === 'object') {
        selectedRow.erDateOfPreparation = row.erDateOfPreparation.format(DATE_FMT);
      }
      if (row.erExpenseSummary) {
        selectedRow.erExpenseSummary = row.erExpenseSummary;
      }
      if (row.erReimbursementAmount || row.erReimbursementAmount === 0) {
        selectedRow.erReimbursementAmount = row.erReimbursementAmount;
      }
      if (row.employeeFeePayIds) {
        selectedRow.employeeFeePayIds = row.employeeFeePayIds;
      }
      if (row.efpPaymentAccount) {
        selectedRow.efpPaymentAccount = row.efpPaymentAccount;
        selectedRow.efpPaymentAccountName = row.efpPaymentAccountName;
        selectedRow.efpName2 = row.efpName2;
        selectedRow.efpAccount2 = row.efpAccount2;
        selectedRow.efpBlank2 = row.efpBlank2;
      }
      if (row.efpAccountingObject) {
        selectedRow.efpAccountingObject = row.efpAccountingObject;
        selectedRow.efpAccountingObjectName = row.efpAccountingObjectName;
        selectedRow.efpName1 = row.efpName1;
        selectedRow.efpAccount1 = row.efpAccount1;
        selectedRow.efpBlank1 = row.efpBlank1;
      }
      if (row.personResponsible) {
        selectedRow.personResponsible = row.personResponsible
        selectedRow.personResponsibleName = row.personResponsibleName
      }
      if (row.responsibilityProject) {
        selectedRow.code = row.code
        selectedRow.responsibilityProject = row.responsibilityProject
      }
      if (row.cooperationUnit) {
        selectedRow.cooperationUnit = row.cooperationUnit
      }
      if (row.erReimbursementPay || row.erReimbursementPay === 0) {
        selectedRow.erReimbursementPay = row.erReimbursementPay;
      }
      if (row.erReimbursementBalance || row.erReimbursementBalance === 0) {
        selectedRow.erReimbursementBalance = row.erReimbursementBalance;
      }
      if (row.erEstatus) {
        selectedRow.erEstatus = row.erEstatus
      }
    }
    if (flag) {
      message.warning(`办理进度为${ONFILE}的数据不可编辑！`).then()
    }
    CostReimbursementService.batchUpdateCostReimbursement(rows).then(rsp => {
      actionThen(rsp);
      setUpdateCount(updateCount + 1)
    }).catch(showErr).finally(() => setConfirmLoading(false));
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    costReimbursementStore.setCollapsed(collapsed)
  }

  // 新增
  // 费用支出一键报销 发起费用报销
  const handleAdd = (row?: CostReimbursementRow) => {
    const user = securityStore.getLoggedUser()
    const initRow = {
      erEstatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      erDateOfPreparation: dayjs(),
    } as CostReimbursementRow
    Object.assign(row || {}, initRow)
    setCostReimbursementRow(row || initRow)
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  const handleView = (record: CostReimbursementRow) => {
    setCostReimbursementRow(record)
    setIsView(true)
    setIsBatchUpdate(false)
    setIsModalVisible(true);
  }

  const handleEdit = async (record: CostReimbursementRow) => {

    try {
      const rsp = await ProcessRecordsService.getProcessRecords({
        businessId: record.id,
        pageNum: 1,
        perPage: 999,
        type: TableName,
      })
      const process = rsp.items.length ? rsp.items[0].process : ''
      const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
      const con = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
      // 如果本轮有撤回 （id为空） 否则 （发起最新一轮的提交的人的id）
      setSubmitterId(con ? undefined : userId)
      setLatestProcess(process) // 发起最新一轮的标志

      setCostReimbursementRow(record)
      setIsView(false)
      setIsBatchUpdate(false)
      setIsModalVisible(true);
    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleEditBatch = async () => {

    try {

      if (selectedRowKeys.length === 0) {
        message.warning("请选择要操作的数据！").then()
        return
      }

      // 要操作的数据
      const rows = costReimbursementStore.costReimbursementDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))

      // 办理流程辅助字段：办理进度
      let erEstatus: string

      // 判断是否符合批量操作条件
      const con = rows.every((o, i, arr) => {
        const firstRow = arr[0]
        erEstatus = firstRow.erEstatus
        if (o.cooperationUnit !== firstRow.cooperationUnit) {
          message.warning("协作单位不一致！").then()
          return false
        }
        if (o.personResponsible !== firstRow.personResponsible) {
          message.warning("办理人员不一致！").then()
          return false
        } else if (o.erEstatus !== firstRow.erEstatus) {
          message.warning("办理进度不一致！").then()
          return false
        } else return true
      })

      if (con) {

        let latestProcessList: string[] = []
        let submitterIdList: string[] = []
        for (let i = 0; i < rows.length; i++) {
          const item = rows[i]
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: item.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const process = rsp.items.length ? rsp.items[0].process : ''
          const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
          const userIdCon = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
          latestProcessList.push(process)
          submitterIdList.push(userIdCon ? undefined : userId)
        }

        // 如果提交人全部一致才赋值给参数:【提交人】
        setSubmitterId(new Set(submitterIdList).size === 1 ? submitterIdList[0] : undefined)
        setLatestProcessBatch(latestProcessList)
        setIsView(false)
        setIsBatchUpdate(true)
        setCostReimbursementRow({erEstatus} as CostReimbursementRow)
        setIsModalVisible(true)

      }

    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleDeleteBatch = (record?: CostReimbursementRow) => {
    const keys = [...selectedRowKeys]
    if (!record && keys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = keys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        CostReimbursementService.deleteCostReimbursement(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(costReimbursementStore.costReimbursementReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const insertOrUpdate = async (row: CostReimbursementRow) => {
    if (typeof row.erDateOfPreparation === 'object' && row.erDateOfPreparation) {
      row.erDateOfPreparation = row.erDateOfPreparation.format(DATE_FMT);
    }
    if (typeof row.invoiceRegistrationIds === 'object' && row.invoiceRegistrationIds) {
      row.invoiceRegistrationIds = row.invoiceRegistrationIds.join(',')
    }
    if (typeof row.cooperationUnit === 'object') {
      row.cooperationUnit = []
      if (row.efpAccountingObject && !BLACK_LIST.includes(row.efpAccountingObject)) {
        row.cooperationUnit.push(row.efpName1)
      }
      if (row.efpPaymentAccount && !BLACK_LIST.includes(row.efpPaymentAccount)) {
        row.cooperationUnit.push(row.efpName2)
      }
      row.cooperationUnit = row.cooperationUnit
        .filter((v, i, self) => self.indexOf(v) === i) // 去重
        .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
        .join(',');
    }
    if (row.id) {
      const rsp = await CostReimbursementService.updateCostReimbursement(row)
      actionThen(rsp)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    } else {
      const rsp = await CostReimbursementService.addCostReimbursement(row)
      actionThen(rsp)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    }
  }

  const actionThen = (rsp: ResponseEntity<any>) => {
    if (rsp.code === 200) {
      message.success("操作成功！").then()
    } else {
      message.warning(rsp.message).then()
    }
    setIsModalVisible(false)
    setIsBatchUpdate(false)
    setCostReimbursementRow(undefined)
    refreshData(costReimbursementStore.costReimbursementReq)
    setSelectedRowKeys([])
  }

  const handleOk = (open: boolean) => {
    const row = {...costReimbursementRow!}
    basicInfo.current!.validateFields().then(async (values) => {
      setConfirmLoading(true)
      Object.assign(row, values)

      if (open) {

        // 将row和按钮先存起来
        setSubmitRow(row)

        // 弹窗选择处理人
        setProcessRecordsIsOpen(true)
        setProcessRecordsRow(undefined)

      } else {

        const user = securityStore.getLoggedUser()

        if (isBatchUpdate) {
          batchUpdate(row)
        } else {
          // 默认处理人员是自己
          if (!row.id) {
            row.personResponsible = user._id
            row.personResponsibleName = user.name
          }

          try {
            await insertOrUpdate(row)
          } catch (error) {
            setProcessRecordsIsOpen(false)
            setProcessRecordsRow(undefined)
            setConfirmLoading(false)
            setIsModalVisible(false);
            message.warning(error.message)
          }
        }

      }

    }).catch(logger.warn)
  }

  const handleSubmit = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)

      const user = securityStore.getLoggedUser()
      const btnText = row.status

      // 当类型是撤回时 默认办理人员为提交人(自己)
      if (btnText === FORM_REVOCATION_TEXT) {
        row.personResponsible = user._id || submitterId
        row.personResponsibleName = user.name
      }

      // 主表
      const subRow = {...submitRow}
      subRow.personResponsible = row.personResponsible
      subRow.personResponsibleName = row.personResponsibleName
      subRow.erEstatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.erEstatus
      // 主表在撤回、退回、变更时->更新局部数据
      if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在转交、提交时->更新局部数据
      if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在提交时->更新局部数据
      if (btnText === FORM_COMMIT_TEXT) {
        subRow.erDateOfPreparation = new Date().toLocaleDateString().replace(/\//g, '-')
      }
      // 主表在结办时->更新局部数据
      if (btnText === FORM_CLOSE_TEXT) {

      }

      // 办理记录表
      row.type = TableName
      row.businessId = subRow.id // 有可能会没值
      row.status = btnText
      row.userId = user._id
      row.userName = user.name
      row.personResponsible = undefined
      row.personResponsibleName = undefined
      if (btnText === FORM_COMMIT_TEXT) {
        const date = new Date()
        row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      } else {
        row.process = latestProcess
      }
      if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
        row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
      } else {
        row.conca = `${user.name}${btnText}了。`
      }

      // 判断是否 先新增主表 后新增记录和通知表
      if (subRow.id) {

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.erApprovalCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "费用报销",
          wnDesc: `您参与办理的费用报销（${subRow.erApprovalCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      } else {

        let subRsp: ResponseEntity<CostReimbursementRow>
        try {
          subRsp = await insertOrUpdate(subRow)
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }

        if (subRsp.data) {
          // 重新赋值再提交
          row.businessId = subRsp.data.id
          await ProcessRecordsService.addProcessRecords(row)
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: subRsp.data.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const notificationScopeUserId = rsp.items
            .map(o => o.userId)
            .concat(row.person)
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(id => id !== user._id) // 过滤出不是自己的数据
            .join(',') || ''
          const notificationScopeUserName = rsp.items
            .map(o => o.userName)
            .concat((row.personName || '').split(','))
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(name => name !== user.name) // 过滤出不是自己的数据
            .join(',') || ''
          await processStore.newAssignmentNotice({
            businessId: subRsp.data.id,
            businessCode: subRsp.data.erApprovalCode,
            cooperationUnit: (subRsp.data.cooperationUnit as string).split(',')[0] || 'N/A',
            notificationScopeUserId,
            notificationScopeUserName,
            personResponsible: subRsp.data.personResponsible,
            personResponsibleName: subRsp.data.personResponsibleName,
            responsibilityProject: subRsp.data.responsibilityProject,
            type: "费用报销",
            wnDesc: `您参与办理的费用报销（${subRsp.data.erApprovalCode}），${row.conca} 请知悉！`,
            wnStatus: "未读"
          })
        } else {
          message.warning('主表保存成功，办理记录表和任务通知表提交失败')
        }

      }
      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  const handleSubmitBatch = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {

      const rows = costReimbursementStore.costReimbursementDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
      for (let i = 0; i < rows.length; i++) {

        Object.assign(row, values)

        const user = securityStore.getLoggedUser()
        const btnText = row.status

        // 当类型是撤回时 默认办理人员为提交人(自己)
        if (btnText === FORM_REVOCATION_TEXT) {
          row.personResponsible = user._id || submitterId
          row.personResponsibleName = user.name
        }

        // 主表
        const subRow = {...rows[i]}
        subRow.personResponsible = row.personResponsible
        subRow.personResponsibleName = row.personResponsibleName
        subRow.erEstatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.erEstatus
        // 主表在撤回、退回、变更时->更新局部数据
        if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在转交、提交时->更新局部数据
        if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在提交时->更新局部数据
        if (btnText === FORM_COMMIT_TEXT) {
          subRow.erDateOfPreparation = new Date().toLocaleDateString().replace(/\//g, '-')
        }
        // 主表在结办时->更新局部数据
        if (btnText === FORM_CLOSE_TEXT) {

        }

        // 办理记录表
        row.type = TableName
        row.businessId = subRow.id // 有可能会没值
        row.status = btnText
        row.userId = user._id
        row.userName = user.name
        row.personResponsible = undefined
        row.personResponsibleName = undefined
        if (btnText === FORM_COMMIT_TEXT) {
          const date = new Date()
          row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
        } else {
          row.process = latestProcessBatch[i]
        }
        if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
          row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
        } else {
          row.conca = `${user.name}${btnText}了。`
        }

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.erApprovalCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "费用报销",
          wnDesc: `您参与办理的费用报销（${subRow.erApprovalCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      }

      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  /**
   * 操作记录按钮
   */
  const handleProcessRecordsClick = async (record: CostReimbursementRow) => {
    const rsp = await ProcessRecordsService.getProcessRecords({
      businessId: record.id,
      pageNum: 1,
      perPage: 999,
      type: TableName
    })
    if (!rsp) {
      message.warning('查无数据')
      return
    }
    setProcessRecordsDataSource(rsp.items)
    setProcessRecordsOpen(true)
  }

  const handleCopyClick = (record: CostReimbursementRow) => {
    const user = securityStore.getLoggedUser()
    const initRow = {
      ...record,
      id: '',
      efaFileArchive: '',
      erEstatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      erDateOfPreparation: dayjs(),
    }
    setCostReimbursementRow(initRow)
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        updateCount={updateCount}
        collapsed={costReimbursementStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        handleShowColumnReset={handleShowColumnReset}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}
        items={[
          {
            key: 'handleAdd',
            icon: <PlusOutlined/>,
            onClick: () => handleAdd()
          },
          {
            key: 'handleDeleteBatch',
            icon: <DeleteOutlined/>,
            onClick: () => handleDeleteBatch()
          },
          {
            key: 'handleEditBatch',
            icon: <EditOutlined/>,
            onClick: () => handleEditBatch()
          },
        ]}></HeaderActionCom>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        size={"middle"}
        loading={props.dataSource ? false : costReimbursementStore.costReimbursementDatasource.loading || confirmLoading}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        bordered
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                (showColumn as string[]).map((key, index) =>
                  key.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={summaryTotalObj[key.slice(0, key.indexOf('-'))]}></TableMoneyText> 元
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys)
          },
          columnWidth: 50,
        }}/>

      { /*操作记录表*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsOpen}
        width={LARGE_MODAL_WIDTH_OTHER}
        onCancel={() => setProcessRecordsOpen(false)}
        destroyOnClose
        footer={null}>
        <ProcessRecords
          isHiddenOptions
          dataSource={processRecordsDataSource}
          isModalSelect
          locationPathname={'/processRecords'}/>
      </CustomDraggableModal>

      { /*操作记录表单*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsIsOpen}
        width={LARGE_MODAL_WIDTH}
        destroyOnClose
        onCancel={() => {
          setProcessRecordsIsOpen(false)
          setConfirmLoading(false)
        }}
        onOk={isBatchUpdate ? handleSubmitBatch : handleSubmit}>
        <ProcessRecordsBasicInfo
          tableName={TableName}
          businessId={costReimbursementRow?.id}
          submitterId={submitterId}
          userId={costReimbursementRow?.personResponsible}
          status={costReimbursementRow?.erEstatus}
          row={processRecordsRow}
          ref={processRecordsBasicInfo}/>
      </CustomDraggableModal>

      <CustomForm
        title={'费用报销'}
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={isModalVisible}
        submitterId={submitterId}
        confirmLoading={confirmLoading}
        userId={costReimbursementRow?.personResponsible}
        status={costReimbursementRow?.erEstatus}
        isView={isView}
        extraDisabled={!costReimbursementRow?.id}
        extraOnClick={() => handlePrint(costReimbursementRow)}
        item={[
          {
            key: 'handleFundApprovalClick',
            icon: <RetweetOutlined/>,
            hidden: !FORM_INITIATE_STATE.includes(costReimbursementRow?.erEstatus),
            onClick: () => handleFundApprovalClick(costReimbursementRow)
          },
          {
            key: 'handleCopyClick',
            icon: <FieldTimeOutlined/>,
            disabled: !costReimbursementRow?.id,
            onClick: () => handleCopyClick(costReimbursementRow)
          },
          {
            key: 'handleProcessRecordsClick',
            icon: <FieldTimeOutlined/>,
            disabled: !costReimbursementRow?.id,
            onClick: () => handleProcessRecordsClick(costReimbursementRow)
          },
        ]}>
        <BasicInfo row={{...costReimbursementRow!}} ref={basicInfo} isView={isView} isBatchUpdate={isBatchUpdate}/>
      </CustomForm>

      { /*关联费用申请*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">关联费用申请</div>}
        open={costApplicationOpen}
        width={LARGER_MODAL_WIDTH}
        onCancel={() => setCostApplicationOpen(false)}
        destroyOnClose
        footer={null}>
        <CostApplication
          dataSource={costApplicationDataSource}
          isModalSelect
          locationPathname={'/costApplication'}/>
      </CustomDraggableModal>

      { /*关联费用支出*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">关联费用支出</div>}
        open={costExpendOpen}
        width={LARGER_MODAL_WIDTH}
        onCancel={() => setCostExpendOpen(false)}
        destroyOnClose
        footer={null}>
        <CostExpend
          dataSource={costExpendDataSource}
          isModalSelect
          locationPathname={'/costExpend'}/>
      </CustomDraggableModal>

      { /*关联资金审批*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">关联资金审批</div>}
        open={fundApprovalOpen}
        width={LARGER_MODAL_WIDTH}
        onCancel={() => setFundApprovalOpen(false)}
        destroyOnClose
        footer={null}>
        <FundApproval
          dataSource={fundApprovalDataSource}
          isModalSelect
          locationPathname={'/fundApproval'}/>
      </CustomDraggableModal>

      <WindowPrint
        isHiddenTime
        title={"费用报销"}
        contentList={contentList}
        isModalOpen={isModalOpen}
        handleCancel={() => setIsModalOpen(false)}/>
    </div>
  )
}))
