import {Avatar, Col, Form, Input, InputNumber, Radio, Row, UploadFile} from "antd";
import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/es";
import {CustomUpload, normFile} from "@components/CustomUpload";
import {UploadOutlined} from "@ant-design/icons";
import {UploadChangeParam} from "antd/es/upload/interface";
import {getUploadStringToArray} from "@common/utils";
import {User} from "@services/dto/security";

export interface PropsType {
  user: User
}

export const PersonInfo = React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState<UploadFile[]>([])

  useEffect(() => {
    form.resetFields()
  }, [])

  useEffect(() => {
    const user = {...props.user}
    if (typeof user?.avatarUrl === 'string') {
      user.avatarUrl = getUploadStringToArray(user.avatarUrl)
      setFileList(user.avatarUrl)
    }
    form.setFieldsValue(user)
  }, [props.user])

  const handleChange = (info: UploadChangeParam) => {
    setFileList([...info.fileList])
  }

  return (
    <>
      <Form
        name="basic"
        form={form}
        ref={ref}
        labelCol={{span: 6}}
        wrapperCol={{span: 16}}
        autoComplete="off">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="姓名"
              name="name"
              rules={[{required: true, message: '请输入姓名!'}]}>
              <Input placeholder="请输入姓名"/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="性别"
              name="gender"
              rules={[{required: true, message: '请选择性别!'}]}>
              <Radio.Group>
                <Radio value={1}>女</Radio>
                <Radio value={0}>男</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="年龄"
              name="age"
              rules={[{required: false, message: '请输入年龄!'}]}>
              <InputNumber min={16} max={100} precision={0} placeholder="请输入年龄" className={'width-100-percentage'}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="手机号码"
              name="phone"
              rules={[{required: false, message: '请输入手机号码!', pattern: /0?(13|14|15|17|18|19)[0-9]{9}$/}]}>
              <Input placeholder="请输入手机号码"/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              labelCol={{span: 3}}
              label="头像"
              name="avatarUrl"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              wrapperCol={{span: 24}}
              extra={"支持文件(jpg,jpeg,png),2M以内"}
              rules={[{required: false, message: '请上传头像!'}]}>
              <CustomUpload
                updateSize={2}
                maxCount={1}
                uploadType={"image"}
                accept={"image/*"}
                listType={"picture-card"}
                multiple={false}
                className={fileList.length > 0 ? "custom-upload upload-circle" : "upload-circle"}
                onChange={handleChange}>
                <Avatar
                  size={{xs: 80, sm: 80, md: 80, lg: 80, xl: 80, xxl: 80}}
                  icon={<UploadOutlined />}
                />
              </CustomUpload>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
})