import "./index.less"
import React, {FC} from "react";
import {Button, Image, Modal} from "antd";
import {QRCodeCanvas} from "qrcode.react";

export interface PropsType {
    title: string
    visible: boolean
    width: string | number
    value: string
    size: number
    onCancel: () => void
    type?: 'image'
}

export const QRcodeModal: FC<PropsType> = (props) => {
  const downloadQrcode = () =>{
    let link = document.createElement('a')
    switch (props.type) {
    case 'image':
      link.href = props.value
      break
    default:
      let canvasImg = document.getElementsByTagName('canvas')[0];
      link.href = canvasImg.toDataURL("image/png")
    }
    link.style.display = 'none'
    link.setAttribute('download', props.title + '.png')
    link.click()
  }

  return (
    <Modal title={<div className={"text-align-center"}>{ props.title }</div>} open={props.visible}
      width={props.width} footer={null} closable={false}>
      <div className={"qrcode-container"}>
        <div className={"qrcode-content"}>
          {
            !props.type && <QRCodeCanvas value={props.value} size={props.size} id={"qrcode"}></QRCodeCanvas>
          }
          {
            props.type === 'image' && <Image preview={false} width={props.size} src={props.value}/>
          }
        </div>
        <div className={"qrcode-action"}>
          <Button className={"cancel-button"} onClick={props.onCancel}>取消</Button>
          <Button className={"download-button"} type={"primary"} onClick={downloadQrcode}>下载</Button>
        </div>
      </div>
    </Modal>
  )
}