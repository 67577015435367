import './index.less'
import dayjs, {Dayjs} from "dayjs";
import {Any} from "@common/types";
import {Button, DatePicker} from "antd";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {DATE_MONTH_FMT} from "@/config";
import {handleDateAddSubtract} from "@common/utils";

dayjs.extend(require('dayjs/plugin/isoWeek'))

export interface CalendarMonthProps {
  date: Dayjs
  renderDay: (day: Dayjs) => Any,
  popupDay: (day: Dayjs) => Any,
  dayTextStyle?: Any,
  activeDayStyle?: Any,
  inactiveDayStyle?: Any
  handleDateChange: (dateString: string) => void
}

export interface DayCellParams {
  dayKey: string
  day: Dayjs
  isoWeekday: number,
  isBlank?: boolean
  isToday?: boolean
}

const DATE_FMT_DAY = 'YYYY-MM-DD';
const IOS_WEEK_LABEL = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']

export const getIsoWeekDay = (d: Dayjs) => (d as Any).isoWeekday()

export const getDays = (date: Dayjs): DayCellParams[] => {
  let currDayInMonth = dayjs(date).startOf('month');
  let endOfMonth = dayjs(date).endOf('month');
  let allElements: DayCellParams[] = [];
  const processByWeek = () => {
    const currentMonth = currDayInMonth.month();
    // these change when the while loop detects the first or last day of month
    let isFirstWeek = false;
    let isLastWeek = false;

    let daysToRender: DayCellParams[] = [];

    // Days rendered in a week must be at least 1, and we want to stop on the next sunday
    // Also, we want to make sure that we don't overshoot to the next month
    while (
      (daysToRender.length === 0 || getIsoWeekDay(currDayInMonth) % 7 !== 0) &&
      currDayInMonth.month() <= currentMonth
    ) {
      const isToday = currDayInMonth.diff(dayjs().startOf('day'), 'days') === 0;

      daysToRender.push({
        day: currDayInMonth.clone(),
        dayKey: currDayInMonth.format(DATE_FMT_DAY), isToday,
        isoWeekday: getIsoWeekDay(currDayInMonth)
      });

      // sets if the current day is the first or last week
      if (currDayInMonth.diff(dayjs(currDayInMonth).startOf('month'), 'days') === 0) {
        isFirstWeek = true;
      } else if (currDayInMonth.diff(dayjs(currDayInMonth).endOf('month'), 'days') === 0) {
        isLastWeek = true;
      }
      currDayInMonth = currDayInMonth.add(1, 'days');
    }

    if (isFirstWeek) {
      // if first week, we want to pad blank elements at the start
      for (let i = daysToRender.length; i < 7; i++) {
        const newDate = daysToRender[0].day.add(-1, "days")
        daysToRender.unshift({
          day: newDate,
          isoWeekday: getIsoWeekDay(newDate),
          dayKey: newDate.format(DATE_FMT_DAY),
          isBlank: true
        });
      }
    } else if (isLastWeek) {
      //if last week, we want to pad blank elements at the end
      for (let i = daysToRender.length; i < 7; i++) {
        const newDate = daysToRender[0].day.add(1, "days")
        daysToRender.push({
          day: newDate,
          isoWeekday: getIsoWeekDay(newDate),
          dayKey: newDate.format(DATE_FMT_DAY),
          isBlank: true
        });
      }
    }
    return daysToRender;
  };

  while (currDayInMonth.isBefore(endOfMonth)) {
    allElements = [...allElements, ...processByWeek()]
  }
  return allElements;
}

export const CalendarMonth = (props: CalendarMonthProps) => {
  const {date} = props;
  const daysElementArray = getDays(date);
  const [dateValue, setDateValue] = useState<dayjs.Dayjs>(dayjs(date.format(DATE_MONTH_FMT)))

  const handleAddSubtract = (action: string) => {
    let now_calc = handleDateAddSubtract(action, dateValue);
    setDateValue(now_calc);
    props.handleDateChange(now_calc.format(DATE_MONTH_FMT))
  }

  const handleDateChange = (date: dayjs.Dayjs | null, dateString: string) => {
    if (date) {
      setDateValue(date);
      props.handleDateChange(date.format(DATE_MONTH_FMT))
    }
  }

  const renderCell = (cell: DayCellParams, index: number) => {
    return <div key={index} className={`cell-container cell-${cell.isoWeekday}`}>
      <div className="cell-content" onClick={(e) => {
        e.preventDefault()
        props.popupDay(cell.day)
      }}>
        { cell.isBlank && <div className="blank"></div> }
        { !cell.isBlank && <div className="not-blank">
          { props.renderDay(cell.day) }
          <div className="day">{ cell.day.format('D') }</div>
        </div> }
      </div>
    </div>
  }
  return <div className="calendar-container">
    <div className="top-tool-bar">
      <Button icon={<LeftOutlined/>} onClick={() => handleAddSubtract('subtract')}></Button>
      <DatePicker
        bordered={false}
        allowClear={false}
        value={dateValue}
        disabledDate={(cur) => cur.isAfter(dayjs())}
        onChange={(date, dateString: string) => handleDateChange(date, dateString)}
        className={"date-style"}
        format={"YYYY年MM月"}
        picker={"month"}/>
      <Button disabled={dateValue.month() === dayjs().month() && dateValue.year() === dayjs().year()} icon={<RightOutlined/>} onClick={() => handleAddSubtract('add')}></Button>
    </div>
    <div className="calendar-content">
      { IOS_WEEK_LABEL.map((l, i) => (<div className={`cell-container cell-${i} week-label`} key={i}>{ l }</div>)) }
      { daysElementArray.map(renderCell) }
    </div>
  </div>
}
