import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {FilingReq, FilingRow} from "@services/dto/filing";
import {FilingService} from "@services/FilingService";
import {OrganizationalRow} from "@services/dto/organizational";
import {OrganizationalService} from "@services/OrganizationalService";

export class FilingStore {
  filingDatasource: StateFullData<Page<FilingRow>> = {}
  filingSelectDatasource: StateFullData<Page<FilingRow>> = {}
  filingRow?: FilingRow
  filingOption?: string
  filingDetail: StateFullData<ResponseEntity<FilingRow>> = {}
  filingDetails: StateFullData<ResponsesEntity<FilingRow>> = {}
  filingReq: FilingReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false
  filingFaCode: string = ''

  constructor() {
    makeAutoObservable(this)
  }

  setFilingFaCode(faCode: string) {
    this.filingFaCode = faCode
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateFilingRow(row: FilingRow) {
    this.filingRow = {...row}
  }

  updateFilingOption(option: string) {
    this.filingOption = option
  }

  getFiling(req: FilingReq) {
    this.filingDatasource = {...this.filingDatasource, loading: true}
    this.filingReq = {...this.filingReq, ...req}
    FilingService.getFiling(req).then(rsp => {
      runInAction(() => this.filingDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.filingDatasource = {...this.filingDatasource, loading: false, err})
    })
  }

  getSelectFiling(req: FilingReq) {
    this.filingSelectDatasource = {...this.filingSelectDatasource, loading: true}
    this.filingReq = {...this.filingReq, ...req}
    FilingService.getFiling(req).then(rsp => {
      runInAction(() => this.filingSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.filingSelectDatasource = {...this.filingSelectDatasource, loading: false, err})
    })
  }

  getGroupByFaBatchNumber(req: FilingReq) {
    this.filingSelectDatasource = {...this.filingSelectDatasource, loading: true}
    this.filingReq = {...this.filingReq, ...req}
    FilingService.getGroupByFaBatchNumber(req).then(rsp => {
      runInAction(() => this.filingSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.filingSelectDatasource = {...this.filingSelectDatasource, loading: false, err})
    })
  }

  getDetailById(id: string) {
    this.filingDetail = {...this.filingDetail, loading: true}
    FilingService.getDetailById(id).then(rsp => {
      runInAction(() => this.filingDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.filingDetail = {...this.filingDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.filingDetails = {...this.filingDetails, loading: true}
    FilingService.getBatch(ids).then(rsp => {
      runInAction(() => this.filingDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.filingDetails = {...this.filingDetails, loading: false, err})
    })
  }

  async getPrintList(record: FilingRow): Promise<LabelValue[]> {

    const row = this.filingDatasource.data?.items.find(o => o.id === record.id)

    let faSubmitterRsp: OrganizationalRow
    let faRecipientRsp: OrganizationalRow
    // 交件人和收件人
    if (row.faSubmitter && row.faRecipient) {
      const [faSubmitterRes, faRecipientRes] = await OrganizationalService.getBatch([row.faSubmitter, row.faRecipient])
      faSubmitterRsp = faSubmitterRes
      faRecipientRsp = faRecipientRes
    } else if (row.faSubmitter) {
      const [faSubmitterRes] = await OrganizationalService.getBatch([row.faSubmitter])
      faSubmitterRsp = faSubmitterRes
    } else if (row.faRecipient) {
      const [faRecipientRes] = await OrganizationalService.getBatch([row.faRecipient])
      faRecipientRsp = faRecipientRes
    }

    return [
      // {label: '制单日期', value: String(row.faTime)},
      {label: '存档批号', value: row.faBatchNumber},
      {label: '文件名称', value: row.faName},
      {label: '文件编号', value: row.faCode},
      {label: '文件类别', value: row.faType},
      {label: '存档日期', value: row.faSaveDate as string},
      {label: '存档盒号', value: row.faSaveBox},
      {label: '存档地点', value: row.faSaveAddress},
      {label: '交件人', value: faSubmitterRsp?.name || row.faSubmitter},
      {label: '收件人', value: faRecipientRsp?.name || row.faRecipient},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '状态', value: row.status},
      {label: '意见', value: '', size: 'large'}
    ]
  }
}

export const filingStore = new FilingStore();
