import {DictRow, StatisticsItemsReq, StatisticsItemsRow} from "./dto/dict";
import {apiV1} from "@services/apiHelper";
import {ResponseEntity} from "@services/dto/common";

export class DictService {
  static async getByKey(key: string): Promise<DictRow> {
    return await apiV1.get(`/dict/getByKey/${key}`);
  }

  static async getStatisticsItems(req: StatisticsItemsReq): Promise<ResponseEntity<StatisticsItemsRow[]>> {
    return await apiV1.get(`/commonaa/getGroupField?table=${req.table}&field=${req.field}&remotelyTable=${req.remotelyTable || ''}&remotelyField=${req.remotelyField || ''}&showField=${req.showField || ''}`);
  }

  static async customScript(data: any): Promise<any> {
    return await apiV1.post(`/commonaa/zdy`, data);
  }
}
