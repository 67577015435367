import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {ConstructionLogRow, PageParam} from "@services/dto/constructionLog";

export class ConstructionLogService {
  static async getConstructionLog(req: PageParam): Promise<Page<ConstructionLogRow>> {
    return await apiV1.get("/constructionLog/search", {params: req})
  }

  static async addConstructionLog(row: ConstructionLogRow): Promise<ResponseEntity<ConstructionLogRow>> {
    return await apiV1.post("/constructionLog/save", {...row})
  }

  static async updateConstructionLog(row: ConstructionLogRow): Promise<ResponseEntity<ConstructionLogRow>> {
    return await apiV1.put("/constructionLog/update", {...row})
  }

  static async deleteConstructionLog(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/constructionLog/delete", {data: ids})
  }
}
