import {Page, ResponseEntity, StateFullData} from "@services/dto/common";
import {makeAutoObservable, runInAction} from "mobx";
import {RoleManageReq, RoleManageRow} from "@services/dto/roleManage";
import {RoleManageService} from "@services/RoleManageService";

export class RoleManageStore {
  roleManageDatasource: StateFullData<Page<RoleManageRow>> = {}
  roleManageSelectDatasource: StateFullData<Page<RoleManageRow>> = {}
  roleManageRow?: RoleManageRow
  roleManageOption?: string
  roleManageDetail: StateFullData<RoleManageRow> = {}
  roleManageDetails: StateFullData<ResponseEntity<RoleManageRow[]>> = {}
  roleManageReq: RoleManageReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }
  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateRoleManageRow(row: RoleManageRow) {
    this.roleManageRow = {...row}
  }

  updateFundApprovalOption(option: string) {
    this.roleManageOption = option
  }

  getRoleManage(req: RoleManageReq) {
    this.roleManageDatasource = {...this.roleManageDatasource, loading: true}
    this.roleManageReq = {...this.roleManageReq, ...req}
    RoleManageService.getRoleManage(req).then(rsp => {
      runInAction(() => this.roleManageDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.roleManageDatasource = {...this.roleManageDatasource, loading: false, err})
    })
  }

  getSelectRoleManage(req: RoleManageReq) {
    this.roleManageSelectDatasource = {...this.roleManageSelectDatasource, loading: true}
    this.roleManageReq = {...this.roleManageReq, ...req}
    RoleManageService.getRoleManage(req).then(rsp => {
      runInAction(() => {
        this.roleManageSelectDatasource = {data: rsp, loading: false}
      })
    }).catch(e => {
      runInAction(() => this.roleManageSelectDatasource = {...this.roleManageSelectDatasource, loading: false, err: e})
    })
  }

  getOne(id: string) {
    this.roleManageDetail = {...this.roleManageDetail, loading: true}
    RoleManageService.getOne(id).then(rsp => {
      runInAction(() => this.roleManageDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.roleManageDetail = {...this.roleManageDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.roleManageDetails = {...this.roleManageDetails, loading: true}
    RoleManageService.getBatch(ids).then(rsp => {
      runInAction(() => this.roleManageDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.roleManageDetails = {...this.roleManageDetails, loading: false, err})
    })
  }
}

export const roleManageStore = new RoleManageStore();
