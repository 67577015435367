import {apiV1} from "@services/apiHelper";
import {PageParam, ProjectAndTeam, ProjectPersonnel, ProjectPersonnelReq, ProjectRow} from "@services/dto/project";
import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";

export class ProjectService {
  static async addCompanyProject(entity: ProjectRow): Promise<ProjectRow> {
    return await apiV1.post('/companyProject', {...entity});
  }

  static async deleteCompanyProject(id: string): Promise<void> {
    return await apiV1.delete(`/companyProject/${id}`);
  }

  static async deleteBatchCompanyProject(ids: string[]): Promise<void> {
    return await apiV1.delete("/companyProject/deleteBatch", {data: {ids}});
  }

  static async updateCompanyProject(id: string, talent: ProjectRow): Promise<ProjectRow> {
    return await apiV1.put(`/companyProject/${id}`, {...talent});
  }

  static async getCompanyProject(req: PageParam): Promise<Page<ProjectRow>> {
    return await apiV1.get('/companyProject/search', {params: req});
  }

  static async getOne(id: string): Promise<ProjectRow> {
    return await apiV1.get(`/companyProject/${id}`);
  }

  static async getBatch(ids: string[]): Promise<ProjectRow[]> {
    return await apiV1.get(`/companyProject/getBatch/${ids.join(',')}`)
  }

  static async getBatchByName(names: string[]): Promise<ProjectRow[]> {
    return await apiV1.get(`/companyProject/getBatchName/${names.join(',')}`)
  }

  static async getProject(): Promise<ResponsesEntity<ProjectRow>> {
    return await apiV1.get("/companyProject/getProject")
  }

  static async getProjectAndPersonnel(): Promise<ResponsesEntity<ProjectAndTeam>> {
    return await apiV1.get("/companyProject/getProjectAndPersonnel")
  }

  static async getProjectPersonnel(req: ProjectPersonnelReq): Promise<ResponseEntity<Page<ProjectPersonnel>>> {
    return await apiV1.post("/companyProject/getProjectPersonnel", {...req})
  }
}
