import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {TaxPlanReq, TaxPlanRow} from "@services/dto/taxPlan";

export class TaxPlanService {
  static async getTaxPlan(req: TaxPlanReq): Promise<Page<TaxPlanRow>> {
    return await apiV1.get("/employeeFeeTaxSchedule/search", {params: req})
  }

  static async batchRefresh(): Promise<ResponseEntity<string>> {
    return await apiV1.get("/employeeFeeTaxSchedule/batch/refresh")
  }

  static async addTaxPlan(row: TaxPlanRow): Promise<ResponseEntity<TaxPlanRow>> {
    return await apiV1.post("/employeeFeeTaxSchedule/save", {...row})
  }

  static async updateTaxPlan(row: TaxPlanRow): Promise<ResponseEntity<TaxPlanRow>> {
    return await apiV1.put("/employeeFeeTaxSchedule/update", {...row})
  }

  static async deleteTaxPlan(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/employeeFeeTaxSchedule/delete", {data: ids})
  }

  static async batchUpdateTaxPlan(selectedRows: TaxPlanRow[]): Promise<ResponseEntity<string>> {
    return await apiV1.put("/employeeFeeTaxSchedule/updateBatch", [...selectedRows])
  }

  static async getDetailById(id: string): Promise<ResponseEntity<TaxPlanRow>> {
    return await apiV1.get(`/employeeFeeTaxSchedule/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<TaxPlanRow>> {
    return await apiV1.get(`/employeeFeeTaxSchedule/getBatch/${ids.join(',')}`)
  }
}
