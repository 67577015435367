import {makeAutoObservable, runInAction} from "mobx";
import {Page, StateFullData} from "@services/dto/common";
import {InvoiceRegistrationReq, InvoiceRegistrationRow} from "@services/dto/invoiceRegistration";
import {InvoiceRegistrationService} from "@services/InvoiceRegistrationService";

export class InvoiceRegistrationStore {
  invoiceRegistrationDatasource: StateFullData<Page<InvoiceRegistrationRow>> = {}
  invoiceRegistrationReq: InvoiceRegistrationReq = {
    pageNum: 1,
    perPage: 10,
  }

  constructor() {
    makeAutoObservable(this)
  }

  getInvoiceRegistrationStatistics(req: InvoiceRegistrationReq) {
    this.invoiceRegistrationDatasource = {...this.invoiceRegistrationDatasource, loading: true}
    this.invoiceRegistrationReq = {...this.invoiceRegistrationReq, ...req}
    InvoiceRegistrationService.getInvoiceRegistrationStatistics(req).then(rsp => {
      runInAction(() => this.invoiceRegistrationDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.invoiceRegistrationDatasource = {...this.invoiceRegistrationDatasource, loading: false, err})
    })
  }
}

export const invoiceRegistrationStore = new InvoiceRegistrationStore();
