import {makeAutoObservable, runInAction} from "mobx";
import {Page, ResponseEntity, StateFullData} from "@services/dto/common";
import {DemoPageReq, DemoPageRow} from "@services/dto/demoPage";
import {DemoPageService} from "@services/DemoPageService";

export class DemoStore {
  demoPageDatasource: StateFullData<Page<DemoPageRow>> = {}
  demoPageSelectDatasource: StateFullData<Page<DemoPageRow>> = {}
  demoPageRow?: DemoPageRow
  demoPageDetail: StateFullData<ResponseEntity<DemoPageRow[]>> = {}
  demoPageReq: DemoPageReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateDemoPageRow(row: DemoPageRow) {
    this.demoPageRow = {...row}
  }

  getDemoPage(req: DemoPageReq) {
    this.demoPageDatasource = {...this.demoPageDatasource, loading: true}
    this.demoPageReq = {...this.demoPageReq, ...req}
    DemoPageService.getDemoPage(req).then(rsp => {
      runInAction(() => this.demoPageDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.demoPageDatasource = {...this.demoPageDatasource, loading: false, err})
    })
  }

  getSelectDemoPage(req: DemoPageReq) {
    this.demoPageSelectDatasource = {...this.demoPageSelectDatasource, loading: true}
    this.demoPageReq = {...this.demoPageReq, ...req}
    DemoPageService.getDemoPage(req).then(rsp => {
      runInAction(() => this.demoPageSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.demoPageSelectDatasource = {...this.demoPageSelectDatasource, loading: false, err})
    })
  }

  getOne(id: string) {
    this.demoPageDetail = {...this.demoPageDetail, loading: true}
    DemoPageService.getOne(id).then(rsp => {
      runInAction(() => this.demoPageDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.demoPageDetail = {...this.demoPageDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.demoPageDetail = {...this.demoPageDetail, loading: true}
    DemoPageService.getBatch(ids).then(rsp => {
      runInAction(() => this.demoPageDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.demoPageDetail = {...this.demoPageDetail, loading: false, err})
    })
  }
}

export const demoPageStore = new DemoStore();
