import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {Button, Drawer, FormInstance, message, Modal, Space, Table} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {accAdd, logger, parseURLParams, safeCompute, showErr} from "@common/utils";
import {BasicInfo} from "@modules/BankAccount/Components/BasicInfo";
import {BankAccountReq, BankAccountRow, BankStatementStatisticsAmount} from "@services/dto/bankAccount";
import {BankAccountService} from "@services/BankAccountService";
import {TableOptions} from "src/components/TableOptions";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import {useLocation} from "react-router-dom";
import {HeaderActionCom} from "@modules/BankAccount/Components/HeaderActionCom";
import {
  DATE_FMT,
  DEFAULT_MODAL_WIDTH,
  EDIT_TEXT,
  SMALL_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";

const wideShowColumn: string[] = [
  "baNick",
  "baName",
  "baAccount",
  "baBlank",
  "baType",
  // "balance-summation",
  // 'accumulatedIncome-summation',
  // 'accumulatedOutlay-summation',
  "baApplicationFormType",
  'enableDate',
  'disableDate',
  "action",
]

const narrowShowColumn: string[] = [
  "baNick",
  "baName",
  // "baAccount",
  "baBlank",
  "baType",
  // "balance-summation",
  // 'accumulatedIncome-summation',
  // 'accumulatedOutlay-summation',
  'enableDate',
  'disableDate',
  // "baApplicationFormType",
  "action",
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: BankAccountRow[] // 数据源
}

export const BankAccount = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const location = useLocation()
  const {bankAccountStore, screenResolutionStore, headerSearchStore, tableColumnsStore} = allStores
  const basicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<BankAccountRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [bankAccountRow, setBankAccountRow] = useState<BankAccountRow>()
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [isView, setIsView] = useState<boolean>(false)
  const [scrollHeight, setScrollHeight] = useState<number>(SMALL_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<BankAccountRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [summaryTotalObj, setSummaryTotalObj] = useState<BankStatementStatisticsAmount>({
    balance: 0,
    accumulatedIncome: 0,
    accumulatedOutlay: 0,
  })
  const [searchReq, setSearchReq] = useState<BankAccountReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...bankAccountStore.bankAccountReq
  })

  const columns: ColumnsType<BankAccountRow> = [
    {
      title: '账户简称',
      dataIndex: 'baNick',
      key: 'baNick',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '账户名称',
      dataIndex: 'baName',
      key: 'baName',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '银行账号',
      dataIndex: 'baAccount',
      key: 'baAccount',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '开户银行',
      dataIndex: 'baBlank',
      key: 'baBlank',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '转入金额',
      dataIndex: ['bankStatementStatisticsAmount', 'accumulatedIncome'],
      key: 'accumulatedIncome-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '转出金额',
      dataIndex: ['bankStatementStatisticsAmount', 'accumulatedOutlay'],
      key: 'accumulatedOutlay-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '账户余额',
      dataIndex: ['bankStatementStatisticsAmount', 'balance'],
      key: 'balance-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '账户类型',
      dataIndex: 'baType',
      key: 'baType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联表单',
      dataIndex: 'baApplicationFormType',
      key: 'baApplicationFormType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (_, record) => (
        <Space>
          <TableEllipsisText
            value={record.baType === '外部协作账户' ? (record.cooperationUnitCode || '/') : (record.userId ? `${record.userName}` : '/')}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableOptions
          handleDelete={() => {
            handleDeleteBatch(record)
          }}
          handleEdit={() => {
            handleEdit(record)
          }}
          handleView={() => {
            handleView(record)
          }}
          onClick={() => {
            handleEdit(record)
          }}>
          { EDIT_TEXT }
        </TableOptions>
      )
    }
  ];

  /**
   * @description 初始化
   */
  useEffect(() => {

    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (bankAccountStore.bankAccountDatasource.data) {
      setDataSource(bankAccountStore.bankAccountDatasource.data.items || [])
      setTotal(bankAccountStore.bankAccountDatasource.data.total || 0)
    }
  }, [bankAccountStore.bankAccountDatasource.data])

  /**
   * @description 表单数据变化
   */
  useEffect(() => {
    if (bankAccountStore.bankAccountRow?.bankAccountId) {
      setBankAccountRow(bankAccountStore.bankAccountRow)
      setIsView(false)
      setIsModalVisible(true);
      bankAccountStore.updateBankAccountRow({...bankAccountStore.bankAccountRow, bankAccountId: ''})
    }
  }, [bankAccountStore.bankAccountRow])

  useEffect(() => {
    if (bankAccountStore.bankAccountOption) {
      const [option, paramsStr] = bankAccountStore.bankAccountOption.split("?")
      if (option === 'add') {
        // handleAdd()
      } else if (paramsStr) {
        const params = parseURLParams(paramsStr)
        if (option === 'edit') {
          BankAccountService.getOne(params.id).then(rsp => {
            if (rsp.code === 200) {
              handleEdit(rsp.data)
            }
          })
        }
      }
      bankAccountStore.updateBankAccountOption(undefined)
    }
  }, [bankAccountStore.bankAccountOption])

  useEffect(() => {
    const objInitValue = {
      balance: 0,
      accumulatedIncome: 0,
      accumulatedOutlay: 0,
    }
    let obj = summaryTotalObj
    const arr = bankAccountStore.bankAccountDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        accumulatedIncome: accAdd(acc.accumulatedIncome, safeCompute(cur.bankStatementStatisticsAmount?.accumulatedIncome)),
        accumulatedOutlay: accAdd(acc.accumulatedOutlay, safeCompute(cur.bankStatementStatisticsAmount?.accumulatedOutlay)),
        balance: accAdd(acc.balance, safeCompute(cur.bankStatementStatisticsAmount?.balance)),
      }), objInitValue);
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [bankAccountStore.bankAccountDatasource, selectedRowKeys])

  /**
   * 列配置变化
   */
  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => o.key !== 'action').filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, bankAccountStore.collapsed, searchReq.pageNum, searchReq.perPage])

  const handleAdd = () => {
    setBankAccountRow(undefined)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handleView = (record: BankAccountRow) => {
    setBankAccountRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = (record: BankAccountRow) => {
    setBankAccountRow(record)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleDeleteBatch = (record?: BankAccountRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        BankAccountService.deleteBankAccount(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(bankAccountStore.bankAccountReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: BankAccountReq) => {
    setSearchReq(req)
    bankAccountStore.getBankAccount(req)
  }

  const handleOk = () => {
    const row = {...bankAccountRow!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(row, values)
      if (values.responsibilityProject) {
        row.responsibilityProject = values.responsibilityProject.join(",");
      } else {
        row.responsibilityProject = ''
      }
      if (values.enableDisableDate && values.enableDisableDate.length > 1) {
        row.enableDate = values.enableDisableDate[0].format(DATE_FMT)
        row.disableDate = values.enableDisableDate[1].format(DATE_FMT)
      } else {
        row.enableDate = ''
        row.disableDate = ''
      }
      if (values.bankAccountCompanyProjects && values.bankAccountCompanyProjects.length > 0) {
        row.bankAccountCompanyProjects = values.bankAccountCompanyProjects.map((item) => ({
          ...item,
          bankAccountId: row.id,
          startOn: item.startOn.format(DATE_FMT),
          stopOff: item.stopOff?.format(DATE_FMT)
        }))
      } else {
        row.bankAccountCompanyProjects = []
      }
      setConfirmLoading(true);
      if (row.id) {
        BankAccountService.updateBankAccount(row).then(() => {
          setUpdateCount(updateCount + 1)
          actionThen();
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        BankAccountService.addBankAccount(row).then(() => {
          setUpdateCount(updateCount + 1)
          actionThen();
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  }

  const actionThen = () => {
    message.success("操作成功！").then()
    setIsModalVisible(false);
    setBankAccountRow(undefined)
    refreshData(bankAccountStore.bankAccountReq)
    setSelectedRowKeys([])
  }

  const handleRowSelection = (selectedRowKeys: React.Key[]) => {
    if (!props.isModalSelect) {
      setSelectedRowKeys(selectedRowKeys)
    }
  }

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (!showColumn.length) return
    // 检验存储中的列表是否符合规范
    showColumn = showColumn.filter(o => columns.some(column => column.key === o))
    // 无论有无存储都触发change，保存当前显示的字段
    setShowColumn(showColumn)
  }, []);

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    bankAccountStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        isHiddenOptions={props.isHiddenOptions}
        updateCount={updateCount}
        collapsed={bankAccountStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        selectedRowKeys={selectedRowKeys}
        handleShowColumnReset={handleShowColumnReset}
        handleDeleteBatch={handleDeleteBatch}
        handleAdd={handleAdd}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={props.isModalSelect ? columns.filter(o => o.key !== 'action') : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}></HeaderActionCom>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        size={"middle"}
        loading={props.dataSource ? false : bankAccountStore.bankAccountDatasource.loading || confirmLoading}
        bordered
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        pagination={!props.dataSource && {
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                (showColumn as string[]).map((key, index) =>
                  key.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={summaryTotalObj[key.slice(0, key.indexOf('-'))]}></TableMoneyText> 元
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}
        rowSelection={{selectedRowKeys, onChange: handleRowSelection, columnWidth: 50}}/>

      <Drawer
        title={<div className="text-align-center">资金账户</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        destroyOnClose
        onClose={handleCancel}>
        <BasicInfo row={{...bankAccountRow!}} ref={basicInfo} isView={isView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button
              type="primary" onClick={handleOk} loading={confirmLoading}
              className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>
    </div>
  )
}))
