import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {Button, Drawer, FormInstance, Space, Table} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {accAdd, safeCompute} from "@common/utils";
import {BasicInfo} from "@modules/EmployeeFeePay/Components/BasicInfo";
import {TableOptions} from "src/components/TableOptions";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {useLocation} from "react-router-dom";
import {HeaderActionCom} from "@modules/EmployeeFeePay/Components/HeaderActionCom";
import {TablePersonResponsible} from "@components/TablePersonResponsible";
import {EmployeeFeePayReq, EmployeeFeePayRow, EmployeeFeePaySummaryTotal} from "@services/dto/employeeFeePay";
import {TableMoneyText} from "@components/TableMoneyText";
import {
  DEFAULT_MODAL_WIDTH,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x,
  VIEW_TEXT
} from "@/config";

const wideShowColumn: string[] = [
  "efpPayType",
  "efpAmount-summation",
  "efpApprovedAmount-summation",
  "baNick1",
  "personResponsible",
  "responsibilityProject",
  "action",
]

const narrowShowColumn: string[] = [
  "efpPayType",
  "efpAmount-summation",
  "efpApprovedAmount-summation",
  // "baNick1",
  // "personResponsible",
  "responsibilityProject",
  "action",
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: EmployeeFeePayRow[] // 数据源
}

export const EmployeeFeePay = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const location = useLocation()
  const {employeeFeePayStore, screenResolutionStore, securityStore, tableColumnsStore} = allStores
  const basicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<EmployeeFeePayRow[]>([])
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [employeeFeePayRow, setEmployeeFeePayRow] = useState<EmployeeFeePayRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<EmployeeFeePayRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [summaryTotalObj, setSummaryTotalObj] = useState<EmployeeFeePaySummaryTotal>({
    efpAmount: 0,
    efpApprovedAmount: 0,
  })
  const [searchReq, setSearchReq] = useState<EmployeeFeePayReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...employeeFeePayStore.employeeFeePaySearchReq
  })

  const columns: ColumnsType<EmployeeFeePayRow> = [
    {
      title: '付款月份',
      dataIndex: 'efpPayDate',
      key: 'efpPayDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '费用类别',
      dataIndex: 'efpPayType',
      key: 'efpPayType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '金额',
      dataIndex: 'efpAmount',
      key: 'efpAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '核定金额',
      dataIndex: 'efpApprovedAmount',
      key: 'efpApprovedAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '付款方',
      dataIndex: 'baNick1',
      key: 'baNick1',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任人',
      dataIndex: 'personResponsible',
      key: 'personResponsible',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TablePersonResponsible value={text}></TablePersonResponsible>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableOptions
          handleView={() => handleView(record)}
          onClick={() => handleView(record)}
          items={[]}>
          { VIEW_TEXT }
        </TableOptions>
      )
    }
  ];

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    refreshData(searchReq)
  }, [])

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (employeeFeePayStore.employeeFeePayDatasource.data) {
      setDataSource(employeeFeePayStore.employeeFeePayDatasource.data?.items || [])
      setTotal(employeeFeePayStore.employeeFeePayDatasource.data?.total || 0)
    }
  }, [employeeFeePayStore.employeeFeePayDatasource.data])

  /**
   * @description 表格合计
   */
  useEffect(() => {
    const objInitValue = {
      efpAmount: 0,
      efpApprovedAmount: 0,
    }
    let obj = summaryTotalObj
    const arr = employeeFeePayStore.employeeFeePayDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        efpAmount: accAdd(acc.efpAmount, cur.efpAmount),
        efpApprovedAmount: accAdd(acc.efpApprovedAmount, cur.efpApprovedAmount),
      }), objInitValue);
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [employeeFeePayStore.employeeFeePayDatasource, selectedRowKeys])

  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => o.key !== 'action').filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, employeeFeePayStore.collapsed, searchReq.pageNum, searchReq.perPage])

  const handleView = (record: EmployeeFeePayRow) => {
    setEmployeeFeePayRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: EmployeeFeePayReq) => {
    setSearchReq(req)
    employeeFeePayStore.getEmployeeFeePay(req)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    employeeFeePayStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        isHiddenOptions={props.isHiddenOptions}
        collapsed={employeeFeePayStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        handleShowColumnReset={handleShowColumnReset}
        tableColumns={props.isModalSelect ? columns.filter(o => o.key !== 'action') : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}
        selectedRowKeys={selectedRowKeys}
        items={['add', 'all'].some(o => (securityStore.getPermissions() || []).includes(o)) ? [

        ] : [

        ]}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}></HeaderActionCom>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        bordered
        size={"middle"}
        loading={props.dataSource ? false : employeeFeePayStore.employeeFeePayDatasource.loading}
        pagination={!props.dataSource && {
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                (showColumn as string[]).map((key, index) =>
                  key.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={summaryTotalObj[key.slice(0, key.indexOf('-'))]}></TableMoneyText> 元
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        rowSelection={{
          selectedRowKeys,
          onChange: setSelectedRowKeys,
          columnWidth: 50,
        }}/>

      <Drawer
        title={<div className="text-align-center">费用统计</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        destroyOnClose
        onClose={handleCancel}>
        <BasicInfo row={{...employeeFeePayRow!}} ref={basicInfo} isView={isView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
        </div>
      </Drawer>
    </div>
  )
}))
