import React, {useEffect, useState} from "react";
import {DatePicker} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {SelectBankAccount} from "@components/SelectBankAccount";
import dayjs from "dayjs";
import {Any} from "@common/types";
import {CostReimbursementReq} from "@services/dto/costReimbursement";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {CustomInputNumber} from "@components/CustomInputNumber";
import {SelectCostApplication} from "@components/SelectCostApplication";
import {SelectCostExpend} from "@components/SelectCostExpend";
import {SelectFundApproval} from "@components/SelectFundApproval";
import {SelectDict} from "@components/SelectDict";
import {CustomAuthHeaderAction, PropsType as ParentPropsType} from "@components/CustomAuthHeaderAction";

export interface PropsType extends ParentPropsType {
  updateCount?: number, // 更新渲染标志
  refreshData?: (req: CostReimbursementReq) => void,
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<CostReimbursementReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: CostReimbursementReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleErDateOfPreparationChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.erDateOfPreparation1 = dateStrings[0]
    req.erDateOfPreparation2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleErReimbursementAmountChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.erReimbursementAmount1 = start
    req.erReimbursementAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleErReimbursementPayChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.erReimbursementPay1 = start
    req.erReimbursementPay2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleErEstatusChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.erEstatus = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleErReimbursementBalanceChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.erReimbursementBalance1 = start
    req.erReimbursementBalance2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfpPaymentAccountChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efpPaymentAccount = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfpAccountingObjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efpAccountingObject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleEmployeeFeeApplicationIdChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.employeeFeeApplicationId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleEmployeeFeePayIdChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.employeeFeePayId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleFundingApprovalIdChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.fundApprovalId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfpTypeChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efpType = value
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonResponsibleNameChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleOrganizersUserIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.organizersUserId = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined
    req.isTaApplicationForm = undefined

    req.erDateOfPreparation1 = undefined
    req.erDateOfPreparation2 = undefined
    req.erReimbursementAmount1 = undefined
    req.erReimbursementAmount2 = undefined
    req.employeeFeeApplicationId = undefined
    req.employeeFeePayId = undefined
    req.fundApprovalId = undefined
    req.efpPaymentAccount = undefined
    req.efpAccountingObject = undefined
    req.personResponsible = undefined
    req.personResponsibleName = undefined
    req.organizersUserId = undefined // 主办人员
    req.organizersUserName = undefined // 主办人员名
    req.erDate1 = undefined
    req.erDate2 = undefined
    req.erReimbursementPay1 = undefined
    req.erReimbursementPay2 = undefined
    req.efpType = undefined
    req.erReimbursementBalance1 = undefined
    req.erReimbursementBalance2 = undefined
    req.erEstatus = undefined

    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <CustomAuthHeaderAction
        size={props.size}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={[
          <DatePicker.RangePicker
            value={searchReq?.erDateOfPreparation1 ? [dayjs(searchReq?.erDateOfPreparation1), dayjs(searchReq?.erDateOfPreparation2)] : [undefined, undefined]}
            placeholder={['制单开始日期', '制单结束日期']}
            allowClear
            onChange={handleErDateOfPreparationChange}
            className={"width-100-percentage"}/>,
          <SelectDict
            updateCount={props.updateCount}
            code={'payment_typee'}
            onChange={handleEfpTypeChange}
            value={searchReq?.efpType}
            placeholder={'请选择类别'}
            allowClear
            className={"width-100-percentage"}/>,
          <CustomInputNumber
            value={[searchReq?.erReimbursementAmount1, searchReq?.erReimbursementAmount2]}
            placeholder={['申请金额最小', '申请金额最大']}
            className={'width-100-percentage'}
            onSearch={handleErReimbursementAmountChange}/>,
          <SelectCostApplication
            onChange={handleEmployeeFeeApplicationIdChange}
            value={searchReq?.employeeFeeApplicationId}
            allowClear
            className={"width-100-percentage"}/>,
          <SelectCostExpend
            onChange={handleEmployeeFeePayIdChange}
            value={searchReq?.employeeFeePayId}
            allowClear
            className={"width-100-percentage"}/>,
          <SelectFundApproval
            onChange={handleFundingApprovalIdChange}
            value={searchReq?.fundApprovalId}
            updateCount={props.updateCount}
            allowClear
            className={"width-100-percentage"}/>,
          <SelectBankAccount
            value={searchReq?.efpPaymentAccount}
            updateCount={props.updateCount}
            onChange={handleEfpPaymentAccountChange}
            allowClear
            placeholder={'请选择付款账户'}/>,
          <SelectBankAccount
            value={searchReq?.efpAccountingObject}
            updateCount={props.updateCount}
            onChange={handleEfpAccountingObjectChange}
            allowClear
            placeholder={'请选择收款账户'}/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
          <SelectCooperativeUnit
            allowClear
            value={searchReq?.cooperationUnit}
            onChange={handleCooperationUnitChange}
            placeholder={"请选择协作单位"}
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <CustomInputNumber
            value={[searchReq?.erReimbursementPay1, searchReq?.erReimbursementPay2]}
            placeholder={['实现金额最小', '实现金额最大']}
            className={'width-100-percentage'}
            onSearch={handleErReimbursementPayChange}/>,
          <CustomInputNumber
            value={[searchReq?.erReimbursementBalance1, searchReq?.erReimbursementBalance2]}
            placeholder={['余额最小', '余额最大']}
            className={'width-100-percentage'}
            onSearch={handleErReimbursementBalanceChange}/>,
          <SelectPersonCharge
            updateCount={props.updateCount}
            value={searchReq?.personResponsible}
            onChange={handlePersonResponsibleNameChange}
            className={"width-100-percentage"}
            placeholder={"请选择办理人员"}
            allowClear/>,
          <SelectPersonCharge
            onChange={handleOrganizersUserIdChange}
            className={"width-100-percentage"}
            placeholder={"请选择主办人员"}
            value={searchReq?.organizersUserId}
            allowClear/>,
          <SelectDict
            onChange={handleErEstatusChange}
            value={searchReq?.erEstatus}
            code={'common_type3'}
            placeholder={'请选择办理进度'}
            allowClear
            className={"width-100-percentage"}/>,
        ]}/>
    </>
  )
}))
