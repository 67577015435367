import {makeAutoObservable, runInAction} from "mobx";
import {RewardPunishmentReq, RewardPunishmentRow} from "@services/dto/rewardPunishment";
import {Page, StateFullData} from "@services/dto/common";
import {RewardPunishmentService} from "@services/RewardPunishmentService";

export class RewardPunishmentStore {
  rewardPunishments: StateFullData<Page<RewardPunishmentRow>> = {};
    
  constructor() {
    makeAutoObservable(this)
  }

  getRewardPunishment(req: RewardPunishmentReq) {
    this.rewardPunishments = {...this.rewardPunishments, loading: true}
    RewardPunishmentService.getRewardPunishment(req).then(rsp => {
      runInAction(() => this.rewardPunishments = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.rewardPunishments = {...this.rewardPunishments, loading: false, err})
    })
  }
}

export const rewardPunishmentStore = new RewardPunishmentStore();