
import { makeAutoObservable, runInAction } from "mobx";
import { Page, StateFullData } from "@services/dto/common";
import { DeductionRow, SearchDeductionRowReq } from "@/services/dto/deduction";
import { DeductionService as Service } from "@/services/DeductionService";

export class DeductionStore {
  datasource: StateFullData<Page<DeductionRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  search(req: SearchDeductionRowReq) {
    this.datasource = { ...this.datasource, loading: true }
    Service.search(req).then(rsp => {
      runInAction(() => this.datasource = { data: rsp, loading: false })
    }).catch(err => {
      runInAction(() => this.datasource = { ...this.datasource, loading: false, err })
    })
  }
}
export const deductionStore = new DeductionStore();