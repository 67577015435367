import React, {FC} from "react";
import {observer} from "mobx-react";
import {Button, Card, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {RewardPunishmentRow} from "@services/dto/rewardPunishment";

export interface PropsType {
  className?: string
  loading: boolean
  total: number
  perPage: number
  pageNum: number
  selectedRowKeys: React.Key[]
  dataSource: RewardPunishmentRow[]
  handlePageChange: (page: number, pageSize: number) => void
  handleChange: (selectedRowKeys: React.Key[], selectedRows: RewardPunishmentRow[]) => void
  handleEdit: (record: RewardPunishmentRow) => void
  handleDeleteBatch: (record: RewardPunishmentRow) => void
}

export const ContentTable:FC<PropsType> = observer((props: PropsType) => {
  const columns: ColumnsType<RewardPunishmentRow> = [
    {
      title: '姓名',
      dataIndex: "userName",
      align: "center",
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => { handleEdit(record) }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '时间',
      dataIndex: 'rewardTime',
      align: "center"
    },
    {
      title: '队部',
      dataIndex: "professionalLeagues",
      align: "center"
    },
    {
      title: '工种',
      dataIndex: "workerTypeName",
      align: "center"
    },
    {
      title: '职位',
      dataIndex: "professional",
      align: "center"
    },
    {
      title: '奖惩金额',
      dataIndex: "amount",
      align: "center"
    },
    {
      title: '原因',
      dataIndex: 'remark',
      align: "center"
    },
    {
      title: '操作',
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined/>}
            onClick={() => { handleEdit(record) }}>修改</Button>
          <Button shape="round" className={"delete-btn"} size={"middle"}
            icon={<DeleteOutlined/>} onClick={() => { handleDeleteBatch(record) }}>删除</Button>
        </Space>
      ),
    },
  ];

  const handlePageChange = (page: number, pageSize: number) => {
    props.handlePageChange(page, pageSize)
  }

  const handleChange = (selectedRowKeys: React.Key[], selectedRows: RewardPunishmentRow[]) => {
    props.handleChange(selectedRowKeys, selectedRows);
  }

  const handleEdit = (record: RewardPunishmentRow) => {
    props.handleEdit(record)
  }

  const handleDeleteBatch = (record: RewardPunishmentRow) => {
    props.handleDeleteBatch(record)
  }
  
  return (
    <>
      <Card className={props.className}>
        <Table
          rowKey={'id'}
          dataSource={props.dataSource}
          columns={columns}
          size={"middle"}
          loading={props.loading}
          pagination={{
            total: props.total,
            position: ['bottomCenter'],
            pageSize: props.perPage,
            current: props.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`, onChange: handlePageChange
          }}
          rowSelection={{selectedRowKeys: props.selectedRowKeys, onChange: handleChange}}/>
      </Card>
    </>
  )
})