import {PageParam, RuleRegulationRow} from "@services/dto/ruleRegulation";
import {Page, StateFullData} from "@services/dto/common";
import {RuleRegulationService} from "@services/RuleRegulationService";
import {makeAutoObservable, runInAction} from "mobx";

export class RuleRegulationStore {
  ruleRegulationDatasource: StateFullData<Page<RuleRegulationRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getRulesAndRegulations(req: PageParam) {
    this.ruleRegulationDatasource = {...this.ruleRegulationDatasource, loading: true}
    RuleRegulationService.getRulesAndRegulations(req).then(rsp => {
      runInAction(() => this.ruleRegulationDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.ruleRegulationDatasource = {...this.ruleRegulationDatasource, loading: false, err})
    })
  }
}

export const ruleRegulationStore = new RuleRegulationStore();
