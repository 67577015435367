import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {ContractSettlementReq, ContractSettlementRow} from "@services/dto/contractSettlement";
import {ContractSettlementService} from "@services/ContractSettlementService";
import {ContractListRow} from "@services/dto/contractList";
import {BLACK_LIST} from "@/config";
import {convertCurrency, formatMoney} from "@common/utils";
import {FilingService} from "@services/FilingService";
import {InvoiceRegisterRow} from "@services/dto/invoiceRegister";

export class ContractSettlementStore {
  contractSettlementDatasource: StateFullData<Page<ContractSettlementRow>> = {}
  contractSettlementSelectDatasource: StateFullData<Page<ContractSettlementRow>> = {}
  contractSettlementRow?: ContractSettlementRow
  contractSettlementOption?: string
  contractSettlementDetail: StateFullData<ResponseEntity<ContractSettlementRow>> = {}
  contractSettlementDetails: StateFullData<ResponsesEntity<ContractSettlementRow>> = {}
  contractSettlementReq: ContractSettlementReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateContractSettlementRow(row: ContractSettlementRow) {
    this.contractSettlementRow = {...row}
  }

  updateContractSettlementOption(option: string) {
    this.contractSettlementOption = option
  }

  getContractSettlement(req: ContractSettlementReq) {
    this.contractSettlementDatasource = {...this.contractSettlementDatasource, loading: true}
    this.contractSettlementReq = {...this.contractSettlementReq, ...req}
    ContractSettlementService.getContractSettlement(req).then(rsp => {
      runInAction(() => this.contractSettlementDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.contractSettlementDatasource = {...this.contractSettlementDatasource, loading: false, err})
    })
  }

  getSelectContractSettlement(req: ContractSettlementReq) {
    this.contractSettlementSelectDatasource = {...this.contractSettlementSelectDatasource, loading: true}
    ContractSettlementService.getContractSettlement(req).then(rsp => {
      runInAction(() => this.contractSettlementSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.contractSettlementSelectDatasource = {
        ...this.contractSettlementSelectDatasource,
        loading: false,
        err
      })
    })
  }

  getOne(id: string) {
    this.contractSettlementDetail = {...this.contractSettlementDetail, loading: true}
    ContractSettlementService.getOne(id).then(rsp => {
      runInAction(() => this.contractSettlementDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.contractSettlementDetail = {...this.contractSettlementDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.contractSettlementDetails = {...this.contractSettlementDetails, loading: true}
    ContractSettlementService.getBatch(ids).then(rsp => {
      runInAction(() => this.contractSettlementDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.contractSettlementDetails = {...this.contractSettlementDetails, loading: false, err})
    })
  }

  async getPrintList(record: ContractSettlementRow): Promise<LabelValue[]> {

    const row = this.contractSettlementDatasource.data?.items.find(o => o.id === record.id)

    // 存档批号
    let csFileRow = undefined
    if (row.csFile && !BLACK_LIST.includes(row.csFile)) {
      const csFileRsp = await FilingService.getBatch([row.csFile])
      if (csFileRsp && csFileRsp.code === 200 && csFileRsp.data) {
        csFileRow = csFileRsp.data[0]
      }
    }

    return [
      {label: '制单日期', value: String(row.csDate)},
      {label: '制单编号', value: row.csCode},
      {label: '结算类别', value: row.csEndType},
      {label: '结算摘要', value: row.csSummany},
      {label: '结算金额(元)', value: `${formatMoney(row.csEndAmount)}元（${convertCurrency(row.csEndAmount)}）`},
      {label: '付款账户', value: row.csEndOutAccount},
      {label: '收款账户', value: row.csEndInAccount},
      {label: '关联合同', value: (row.contractLists as ContractListRow[])?.map(o => `${o.clName}（${o.clCode}）`).join(',')},
      {label: '验收证书', value: row.csBook},
      {label: '出库入库', value: row.outboundInbound},
      {
        label: '关联发票',
        value: (row.invoiceRegistrations as InvoiceRegisterRow[]).map(o => o.irInvoiceCode2).join(',')
      },
      {label: '责任部门', value: row.responsibilityProject},
      {label: '存档批号', value: csFileRow?.faBatchNumber},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},    ]
  }
}

export const contractSettlementStore = new ContractSettlementStore();
