import {Page, StateFullData} from "@services/dto/common";
import {JobAccountReq, JobAccountRow} from "@services/dto/jobAccount";
import {makeAutoObservable, runInAction} from "mobx";
import {JobAccountService} from "@services/JobAccountService";

class JobAccountStore {
  jobAccountDatasource: StateFullData<Page<JobAccountRow>> = {}
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  getJobAccount(req: JobAccountReq) {
    this.jobAccountDatasource = {...this.jobAccountDatasource, loading: true}
    JobAccountService.getJobAccount(req).then(rsp => {
      runInAction(() => this.jobAccountDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.jobAccountDatasource = {...this.jobAccountDatasource, loading: false, err})
    })
  }
}

export const jobAccountStore = new JobAccountStore()
