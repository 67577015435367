import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {Button, Drawer, FormInstance, message, Modal, Space, Table} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {logger, parseURLParams, safeCompute, showErr} from "@common/utils";
import {
  DEFAULT_MODAL_WIDTH,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import {ProjectCollaborationReq, ProjectCollaborationRow} from "@services/dto/projectCollaboration";
import {BasicInfo} from "@modules/ProjectCollaboration/Components/BasicInfo";
import {ProjectCollaborationService} from "@services/ProjectCollaborationService";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableOptions} from "src/components/TableOptions";
import {useLocation} from "react-router-dom";
import {HeaderActionCom} from "@modules/ProjectCollaboration/Components/HeaderActionCom";
import {TablePersonResponsible} from "@components/TablePersonResponsible";

const blackColumnList = [
  'action',
]

const wideShowColumn: string[] = [
  // 'cuName',
  'cuNick',
  'cooperationUnitCode',
  // 'cooperativeUnitCategory',
  // 'unifiedSocialCreditIdentifier',
  'address',
  'phone',
  'cooperationContent',
  'bankAccounts',
  'action',
]

const narrowShowColumn: string[] = [
  // 'cuName',
  'cuNick',
  'cooperationUnitCode',
  // 'cooperativeUnitCategory',
  // 'unifiedSocialCreditIdentifier',
  'address',
  'phone',
  'cooperationContent',
  // 'bankAccounts',
  'action',
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: ProjectCollaborationRow[] // 数据源
}

export const ProjectCollaboration = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const location = useLocation()
  const {projectCollaborationStore, headerSearchStore, screenResolutionStore, tableColumnsStore} = allStores
  const basicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<ProjectCollaborationRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<ProjectCollaborationRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [projectCollaborationRow, setProjectCollaborationRow] = useState<ProjectCollaborationRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [searchReq, setSearchReq] = useState<ProjectCollaborationReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...projectCollaborationStore.projectCollaborationReq
  })

  const columns: ColumnsType<ProjectCollaborationRow> = [
    {
      title: '制单日期',
      dataIndex: 'cuDate',
      key: 'cuDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'cooperationUnitCode',
      key: 'cooperationUnitCode',
      align: 'center',
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}/>
        </Space>
      )
    },
    {
      title: '类别',
      dataIndex: 'cooperativeUnitCategory',
      key: 'cooperativeUnitCategory',
      align: 'center',
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}/>
        </Space>
      )
    },
    {
      title: '名称',
      dataIndex: 'cuName',
      key: 'cuName',
      align: 'center',
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}/>
        </Space>
      )
    },
    {
      title: '简称',
      dataIndex: 'cuNick',
      key: 'cuNick',
      align: 'center',
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}/>
        </Space>
      )
    },
    {
      title: '信用代码',
      dataIndex: 'unifiedSocialCreditIdentifier',
      key: 'unifiedSocialCreditIdentifier',
      align: 'center',
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}/>
        </Space>
      )
    },
    {
      title: '联系地址',
      dataIndex: 'address',
      key: 'address',
      align: 'center',
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}/>
        </Space>
      )
    },
    {
      title: '联系电话',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}/>
        </Space>
      )
    },
    {
      title: '联系人',
      dataIndex: 'cooperationContent',
      key: 'cooperationContent',
      align: 'center',
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}/>
        </Space>
      )
    },
    {
      title: '银行账户',
      dataIndex: 'bankAccounts',
      key: 'bankAccounts',
      align: 'center',
      render: (text) => (
        <Space>
          <TableEllipsisText value={text?.map(item => item.baName).join(',')}/>
        </Space>
      )
    },
    {
      title: '责任人',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TablePersonResponsible value={text}></TablePersonResponsible>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'cuStatus',
      key: 'cuStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableOptions
          handleDelete={() => {
            handleDeleteBatch(record)
          }}
          handleEdit={() => {
            handleEdit(record)
          }}
          handleView={() => {
            handleView(record)
          }}
          onClick={() => {
            handleEdit(record)
          }}>
          修改
        </TableOptions>
      )
    }
  ];


  /**
   * @description 初始化
   */
  useEffect(() => {

    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  /**
   * @description 全局查询
   */
  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (projectCollaborationStore.projectCollaborationDatasource.data) {
      setDataSource(projectCollaborationStore.projectCollaborationDatasource.data.items || [])
      setTotal(projectCollaborationStore.projectCollaborationDatasource.data.total || 0)
    }
  }, [projectCollaborationStore.projectCollaborationDatasource.data])

  /**
   * @description 表单数据变化
   */
  useEffect(() => {
    if (projectCollaborationStore.projectCollaborationRow?.projectCollaborationId) {
      setProjectCollaborationRow(projectCollaborationStore.projectCollaborationRow)
      setIsView(false)
      setIsModalVisible(true);
      projectCollaborationStore.updateProjectCollaborationRow({
        ...projectCollaborationStore.projectCollaborationRow,
        projectCollaborationId: undefined,
      })
    }
  }, [projectCollaborationStore.projectCollaborationRow])

  useEffect(() => {
    if (projectCollaborationStore.projectCollaborationOption) {
      const [option, paramsStr] = projectCollaborationStore.projectCollaborationOption.split("?")
      if (option === 'add') {
        handleAdd()
      } else if (paramsStr) {
        const params = parseURLParams(paramsStr)
        if (option === 'edit') {
          ProjectCollaborationService.getDetailById(params.id).then(rsp => {
            if (rsp.code === 200) {
              handleEdit(rsp.data)
            }
          })
        }
      }
      projectCollaborationStore.updateProjectCollaborationOption(undefined)
    }
  }, [projectCollaborationStore.projectCollaborationOption])

  /**
   * 列配置变化
   */
  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => !blackColumnList.includes(o.key as string)).filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, projectCollaborationStore.collapsed, searchReq.pageNum, searchReq.perPage])

  const handleAdd = () => {
    setProjectCollaborationRow(undefined)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handleView = (record: ProjectCollaborationRow) => {
    setProjectCollaborationRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = (record: ProjectCollaborationRow) => {
    setProjectCollaborationRow(record)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleDeleteBatch = (record?: ProjectCollaborationRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        ProjectCollaborationService.deleteProjectCollaboration(ids).then(() => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(searchReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: ProjectCollaborationReq) => {
    projectCollaborationStore.getProjectCollaboration(req)
    setSearchReq(req)
  }

  const handleOk = () => {
    const row = {...projectCollaborationRow!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(row, values)
      setConfirmLoading(true)
      if (row.id) {
        ProjectCollaborationService.updateProjectCollaboration(row).then(() => {
          actionThen();
          setUpdateCount(updateCount + 1)
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        ProjectCollaborationService.addProjectCollaboration(row).then(() => {
          actionThen();
          setUpdateCount(updateCount + 1)
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  }

  const actionThen = () => {
    message.success("操作成功！")
    setIsModalVisible(false);
    setProjectCollaborationRow(undefined)
    refreshData(searchReq)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    projectCollaborationStore.setCollapsed(collapsed)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        isHiddenOptions={props.isHiddenOptions}
        updateCount={updateCount}
        collapsed={projectCollaborationStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        selectedRowKeys={selectedRowKeys}
        handleShowColumnReset={handleShowColumnReset}
        handleDeleteBatch={handleDeleteBatch}
        handleAdd={handleAdd}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={props.isModalSelect ? columns.filter(o => !blackColumnList.includes(o.key as string)) : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}/>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        size={"middle"}
        bordered
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        loading={props.dataSource ? false : projectCollaborationStore.projectCollaborationDatasource.loading || confirmLoading}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys}}/>

      <Drawer
        title={<div className="text-align-center">项目协作</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        destroyOnClose
        onClose={handleCancel}>

        <BasicInfo row={{...projectCollaborationRow!}} ref={basicInfo} isView={isView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button
              type="primary" onClick={handleOk} className={"margin-left-xs"}
              loading={confirmLoading}>确定</Button>
          }
        </div>
      </Drawer>
    </div>
  )
}))
