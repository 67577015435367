import React, {useEffect} from "react";
import {Button, Col, Form, Input, Row, Space} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {DictManagementRow} from "@services/dto/dictManagement";
import {CloseOutlined} from "@ant-design/icons";

export interface PropsType {
  row: DictManagementRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  useEffect(() => {
    form.resetFields()
    const organizationalRow = {...props.row}
    if (props.row.id) {

    }
    form.setFieldsValue(organizationalRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      disabled={props.isView}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      initialValues={{status: 1}}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="别名"
            name="alias"
            rules={[{required: true, message: '请输入别名!'}]}>
            <Input placeholder="请输入别名"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="标识"
            name="key"
            rules={[{required: true, message: '请输入标识!'}]}>
            <Input placeholder="请输入标识"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="备注"
            name="remark"
            rules={[{required: false, message: '请输入备注!'}]}>
            <Input.TextArea placeholder="请输入备注" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="字典">
            <Form.List name="value">
              { (fields, {add, remove}) => (
                <div style={{display: 'flex', rowGap: 8, flexDirection: 'column'}}>
                  { fields.map((field) => (
                    <Space.Compact
                      key={field.key}>
                      <Form.Item label="" name={[field.name, 'label']}>
                        <Input placeholder={'键标题'}/>
                      </Form.Item>
                      <Form.Item label="" name={[field.name, 'value']}>
                        <Input placeholder={'键值'}/>
                      </Form.Item>
                      <Button style={{width: "max-content"}} onClick={() => remove(field.name)}>
                        <CloseOutlined/>
                      </Button>
                    </Space.Compact>
                  )) }

                  <Button type="dashed" onClick={() => add()} block>
                    + Add Item
                  </Button>
                </div>
              ) }
            </Form.List>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
