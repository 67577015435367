import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {FormInstance, message, Modal, Space, Table,} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {accAdd, logger, safeCompute, showErr} from "@common/utils";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FieldTimeOutlined,
  PlusOutlined,
  PrinterOutlined,
  RetweetOutlined
} from "@ant-design/icons";
import {useLocation, useNavigate} from "react-router-dom";
import {LabelValue, ResponseEntity} from "@services/dto/common";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import {WindowPrint} from "@modules/WindowPrint";
import {
  APPROVE_FORM_TRANS_STATE,
  BLACK_LIST,
  DATE_FMT,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  FORM_ALTERNATE_TEXT,
  FORM_CLOSE_TEXT,
  FORM_COMMIT_TEXT,
  FORM_DISABLED_STATE,
  FORM_HANDOVER_TEXT,
  FORM_INITIATE_STATE,
  FORM_PERSON_RESPONSIBLE_WHITELIST,
  FORM_RETURN_TEXT,
  FORM_REVOCATION_TEXT,
  LARGE_MODAL_WIDTH,
  LARGE_MODAL_WIDTH_OTHER,
  ONFILE,
  READY,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import {TableFilingText} from "@components/TableFilingText";
import {TaxPlanReq, TaxPlanRow, TaxPlanSummaryTotal} from "@services/dto/taxPlan";
import {BasicInfo} from "@modules/TaxPlan/Components/BasicInfo";
import {TaxPlanService} from "@services/TaxPlanService";
import {BasicInfo as ProcessRecordsBasicInfo} from "@modules/ProcessRecords/Components/BasicInfo";
import {HeaderActionCom} from "@modules/TaxPlan/Components/HeaderActionCom";
import {ProjectService} from "@services/ProjectService";
import dayjs from "dayjs";
import {ProcessRecordsService} from "@services/ProcessRecordsService";
import {ProcessRecordsRow} from "@services/dto/processRecords";
import {CustomDraggableModal} from "@components/CustomDraggableModal";
import {ProcessRecords} from "@modules/ProcessRecords";
import {CustomForm} from "@components/CustomForm";
import {TableAuthOptions} from "@components/CustomAuthOptions";

const wideShowColumn: string[] = [
  "efaAccountingObject",
  "efaApplyCode",
  "efaPayType",
  // "efaTitle",
  // "efaRelatedProjectApproval",
  "efaApplyAmount-summation",
  "efaAmountPayout-summation",
  "efaBalance-summation",
  // "personResponsibleName",
  // "responsibilityProject",
  // "cooperationUnit",
  "efaStatus",
  "action",
]

const narrowShowColumn: string[] = [
  "efaAccountingObject",
  "efaApplyCode",
  "efaPayType",
  // "efaTitle",
  // "efaRelatedProjectApproval",
  // "efaApplyAmount-summation",
  // "efaAmountPayout-summation",
  // "efaBalance-summation",
  "personResponsibleName",
  // "responsibilityProject",
  // "cooperationUnit",
  "efaStatus",
  "action",
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: TaxPlanRow[] // 数据源
}

export const TableName = 'employeeFeeTaxSchedule'

export const TaxPlan = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    processStore,
    securityStore,
    finalSettlementStore,
    taxAccountingStore,
    taxPlanStore,
    headerSearchStore,
    screenResolutionStore,
    tableColumnsStore,
    costExpendStore,
  } = allStores
  const basicInfo = React.createRef<FormInstance>();
  const processRecordsBasicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<TaxPlanRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [taxPlanRow, setTaxPlanRow] = useState<TaxPlanRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentList, setContentList] = useState<LabelValue[]>([]);
  const [isBatchUpdate, setIsBatchUpdate] = useState(false);
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<TaxPlanRow>>([])
  const [processRecordsIsOpen, setProcessRecordsIsOpen] = useState<boolean>(false)
  const [processRecordsRow, setProcessRecordsRow] = useState<ProcessRecordsRow>()
  const [processRecordsOpen, setProcessRecordsOpen] = useState<boolean>(false)
  const [processRecordsDataSource, setProcessRecordsDataSource] = useState<ProcessRecordsRow[]>([])
  const [submitRow, setSubmitRow] = useState<TaxPlanRow>()
  const [submitterId, setSubmitterId] = useState<string>()
  const [latestProcess, setLatestProcess] = useState<string>()
  const [latestProcessBatch, setLatestProcessBatch] = useState<string[]>()
  const [summaryTotalObj, setSummaryTotalObj] = useState<TaxPlanSummaryTotal>({
    efaApplyAmount: 0,
    efaAmountPayout: 0,
    efaBalance: 0,
  })
  const [searchReq, setSearchReq] = useState<TaxPlanReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...taxPlanStore.taxPlanReq
  })

  const columns: ColumnsType<TaxPlanRow> = [
    {
      title: '制单日期',
      dataIndex: 'efaAccountingObject',
      key: 'efaAccountingObject',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'efaApplyCode',
      key: 'efaApplyCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '税费类别',
      dataIndex: 'efaPayType',
      key: 'efaPayType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '税费说明',
      dataIndex: 'efaTitle',
      key: 'efaTitle',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联立项',
      dataIndex: 'efaRelatedProjectApproval',
      key: 'efaRelatedProjectApproval',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '计划金额',
      dataIndex: 'efaApplyAmount',
      key: 'efaApplyAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '实现金额',
      dataIndex: 'efaAmountPayout',
      key: 'efaAmountPayout-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text, record) => (
        <Space onClick={() => handleCostExpend(record)}>
          <TableMoneyText
            className={'c-pointer'}
            value={text}
            style={{color: record.efaApplyAmount < text ? '#B84A5B' : '#6BD089'}}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '余额',
      dataIndex: 'efaBalance',
      key: 'efaBalance-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'efaFileArchiveCode',
      key: 'efaFileArchiveCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableFilingText id={record.efaFileArchive} value={record.efaFileArchiveCode}></TableFilingText>
        </Space>
      )
    },
    {
      title: '办理人员',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '主办人员',
      dataIndex: 'organizersUserName',
      key: 'organizersUserName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'efaStatus',
      key: 'efaStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableAuthOptions
          mainBtn={['handleEdit', 'handleView']}
          items={[
            {
              key: 'handleView',
              icon: <EllipsisOutlined/>,
              onClick: () => handleView(record)
            },
            {
              key: 'handleEdit',
              icon: <EditOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.efaStatus),
              onClick: async () => await handleEdit(record)
            },
            {
              key: 'handlePrint',
              icon: <PrinterOutlined/>,
              onClick: () => handlePrint(record)
            },
            {
              key: 'handleDeleteBatch',
              icon: <DeleteOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.efaStatus),
              onClick: () => handleDeleteBatch(record)
            },
          ]}></TableAuthOptions>
      )
    }
  ];

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (taxPlanStore.taxPlanDatasource.data) {
      setDataSource(taxPlanStore.taxPlanDatasource.data?.items || [])
      setTotal(taxPlanStore.taxPlanDatasource.data?.total || 0)
    }
  }, [taxPlanStore.taxPlanDatasource.data])

  useEffect(() => {

  }, [taxPlanStore.taxPlanRow])

  useEffect(() => {
    const objInitValue = {
      efaApplyAmount: 0,
      efaAmountPayout: 0,
      efaBalance: 0,
    }
    let obj = summaryTotalObj
    const arr = taxPlanStore.taxPlanDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        efaApplyAmount: accAdd(acc.efaApplyAmount, cur.efaApplyAmount),
        efaAmountPayout: accAdd(acc.efaAmountPayout, cur.efaAmountPayout),
        efaBalance: accAdd(acc.efaBalance, cur.efaBalance),
      }), objInitValue);
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [taxPlanStore.taxPlanDatasource, selectedRowKeys])

  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => o.key !== 'action').filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, taxPlanStore.collapsed, searchReq.pageNum, searchReq.perPage])

  useEffect(() => {
    if (taxPlanStore.taxPlanOption) {
      const [option] = taxPlanStore.taxPlanOption.split("?")
      if (option === 'add') {
        handleAdd()
        taxPlanStore.updateTaxPlanOption(undefined)
      }
    }
  }, [taxPlanStore.taxPlanOption])

  const handlePrint = async (record: TaxPlanRow) => {
    const list = await taxPlanStore.getPrintList(record)
    if (list) {
      setContentList(list)
      setIsModalOpen(true)
    } else {
      message.warning('请检查')
    }
  }

  const handleFinalSettlementClick = async (record: TaxPlanRow) => {
    // 获取详情
    const row = taxPlanStore.taxPlanDatasource.data.items.find(o => o.id === record.id)

    // 责任部门获取code
    if (!row.responsibilityProject) {
      message.warning("请检查责任部门！").then()
      return
    }
    const names: string[] = typeof row.responsibilityProject === 'object' ? row.responsibilityProject : row.responsibilityProject.split(',')
    const responsibilityProject = await ProjectService.getBatchByName(names)
    if (typeof responsibilityProject === 'object' && responsibilityProject.length !== names.length) {
      message.warning("请检查责任部门！").then()
      return
    }
    const code = responsibilityProject
      .map(o => o.code)
      .filter(o => o)
      .filter((v, i, self) => self.indexOf(v) === i)
      .join(',')

    finalSettlementStore.updateFinalSettlementRow({
      baAccount1: undefined,
      baAccount2: undefined,
      baBlank1: undefined,
      baBlank2: undefined,
      baName1: undefined,
      baName2: undefined,
      code,
      companyId: undefined,
      cooperationUnit: undefined,
      employeeFeeTaxScheduleId: row.id,
      employeeFeeTaxScheduleCode: row.efaApplyCode,
      fsAccountIn: undefined,
      fsAccountInId: undefined,
      fsAccountPay: undefined,
      fsAccountPayId: undefined,
      fsAmount: row.efaApplyAmount,
      fsCode: undefined,
      fsDate: dayjs(new Date()), // 制单日期
      fsDesc: row.efaTitle,
      fsFile: undefined,
      fsStatus: undefined,
      fsType: undefined,
      fundingApprovals: undefined,
      id: undefined,
      personResponsible: row.personResponsible,
      personResponsibleName: row.personResponsibleName,
      organizersUserId: undefined,
      organizersUserName: undefined,
      responsibilityProject: row.responsibilityProject,
      statusCommon: undefined,
      finalSettlementId: row.id
    })
    navigate("/finalSettlement")
  }

  const handleTaxAccountingClick = async (record: TaxPlanRow) => {

    // 获取详情
    const row = taxPlanStore.taxPlanDatasource.data.items.find(o => o.id === record.id)

    // 责任部门获取code
    if (!row.responsibilityProject) {
      message.warning("请检查责任部门！").then()
      return
    }
    const names: string[] = typeof row.responsibilityProject === 'object' ? row.responsibilityProject : row.responsibilityProject.split(',')
    const responsibilityProject = await ProjectService.getBatchByName(names)
    if (typeof responsibilityProject === 'object' && responsibilityProject.length !== names.length) {
      message.warning("请检查责任部门！").then()
      return
    }
    const code = responsibilityProject
      .map(o => o.code)
      .filter(o => o)
      .filter((v, i, self) => self.indexOf(v) === i)
      .join(',')

    taxAccountingStore.updateTaxAccountingRow({
      taxMethod: undefined,
      taCategory: undefined,
      amountRealizedDate: undefined,
      isTaApplicationForm: undefined,
      taxCategory: undefined,
      code,
      taxAccountingId: row.id,

      bsAccountIn: undefined,
      baNick1: undefined,
      bsAccountInId: undefined,
      baName1: undefined,
      baBlank1: undefined,
      baAccount1: undefined,

      bsAccountPay: undefined,
      baNick2: undefined,
      bsAccountPayId: undefined,
      baName2: undefined,
      baAccount2: undefined,
      baBlank2: undefined,
      cooperationUnit: undefined,
      fileArchive: undefined,

      // 关联资金审批
      fundApprovalIds: undefined,
      fundingApprovalList: undefined,

      id: undefined,
      invoiceRegistrationIn: undefined,
      invoiceRegistrationInId: undefined,
      invoiceRegistrationIns: undefined,
      invoiceRegistrationOut: undefined,
      invoiceRegistrationOutId: undefined,
      invoiceRegistrationOuts: undefined,
      personResponsible: row.personResponsible,
      personResponsibleName: row.personResponsibleName,
      organizersUserId: undefined,
      organizersUserName: undefined,
      responsibilityProject: row.responsibilityProject,
      status1: undefined,
      status2: undefined,
      taCode: undefined,
      taDate: dayjs(new Date()), // 制单日期
      taDesc: row.efaTitle,
      taEnterprise: undefined,
      taInIrAmount: 0,
      taInTaxIrAmount: 0,
      taOutIrAmount: 0,
      taOutTaxIrAmount: 0,
      taSegment: row.responsibilityProject,
      taStatus: undefined,
      taVatPayable: 0,

      additionalTaxAmount: row.efaApplyAmount,
      taxAccountingMiddle: {
        personDue: 0,
        enterpriseDue: 0,
        managerDue: 0,
      },

      employeeFeeTaxScheduleId: row.id,
      employeeFeeTaxScheduleCode: row.efaApplyCode
    })
    navigate("/taxAccounting")
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: TaxPlanReq) => {
    setSearchReq(req)
    taxPlanStore.getTaxPlan(req)
  }

  const handleCostExpend = (record: TaxPlanRow) => {
    costExpendStore.updateCostExpendSearchReq({
      pageNum: 1,
      perPage: 10,
      efpEmployeeFeeApplicationId: record.id,
    })
    navigate("/costExpend")
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    taxPlanStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const batchUpdate = (row: TaxPlanRow) => {
    let flag = false
    const rows = taxPlanStore.taxPlanDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    for (let selectedRow of rows) {
      if (selectedRow.efaStatus === ONFILE) {
        flag = true
        continue
      }
      if (row.responsibilityProject) {
        selectedRow.responsibilityProject = row.responsibilityProject;
        selectedRow.code = row.code;
      }
      if (typeof row.cooperationUnit === 'object' && row.cooperationUnit.length) {
        selectedRow.cooperationUnit = row.cooperationUnit.join(',');
      }
    }
    if (flag) {
      message.warning(`办理进度为${ONFILE}的数据不可编辑！`).then()
    }
    TaxPlanService.batchUpdateTaxPlan(rows).then((rsp) => {
      actionThen(rsp);
      setUpdateCount(updateCount + 1)
    }).catch(showErr).finally(() => setConfirmLoading(false));
  }

  const handleAdd = () => {
    const user = securityStore.getLoggedUser()
    setTaxPlanRow({
      efaStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      efaAccountingObject: dayjs(),
    } as TaxPlanRow)
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  const handleView = (record: TaxPlanRow) => {
    setTaxPlanRow(record)
    setIsView(true)
    setIsBatchUpdate(false)
    setIsModalVisible(true);
  }

  const handleEdit = async (record: TaxPlanRow) => {

    try {
      const rsp = await ProcessRecordsService.getProcessRecords({
        businessId: record.id,
        pageNum: 1,
        perPage: 999,
        type: TableName,
      })
      const process = rsp.items.length ? rsp.items[0].process : ''
      const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
      const con = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
      // 如果本轮有撤回 （id为空） 否则 （发起最新一轮的提交的人的id）
      setSubmitterId(con ? undefined : userId)
      setLatestProcess(process) // 发起最新一轮的标志

      setTaxPlanRow(record)
      setIsView(false)
      setIsBatchUpdate(false)
      setIsModalVisible(true);
    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleEditBatch = async () => {

    try {

      if (selectedRowKeys.length === 0) {
        message.warning("请选择要操作的数据！").then()
        return
      }

      // 要操作的数据
      const rows = taxPlanStore.taxPlanDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))

      // 办理流程辅助字段：办理进度
      let efaStatus: string

      // 判断是否符合批量操作条件
      const con = rows.every((o, _, arr) => {
        const firstRow = arr[0]
        efaStatus = firstRow.efaStatus
        if (o.personResponsible !== firstRow.personResponsible) {
          message.warning("办理人员不一致！").then()
          return false
        } else if (o.efaStatus !== firstRow.efaStatus) {
          message.warning("办理进度不一致！").then()
          return false
        } else return true
      })

      if (con) {

        let latestProcessList: string[] = []
        let submitterIdList: string[] = []
        for (let i = 0; i < rows.length; i++) {
          const item = rows[i]
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: item.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const process = rsp.items.length ? rsp.items[0].process : ''
          const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
          const userIdCon = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
          latestProcessList.push(process)
          submitterIdList.push(userIdCon ? undefined : userId)
        }

        // 如果提交人全部一致才赋值给参数:【提交人】
        setSubmitterId(new Set(submitterIdList).size === 1 ? submitterIdList[0] : undefined)
        setLatestProcessBatch(latestProcessList)
        setIsView(false)
        setIsBatchUpdate(true)
        setTaxPlanRow({efaStatus} as TaxPlanRow)
        setIsModalVisible(true)

      }

    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleDeleteBatch = (record?: TaxPlanRow) => {
    const keys = [...selectedRowKeys]
    if (!record && keys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = [];
    if (record) {
      ids.push(record.id)
    } else {
      ids = keys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        TaxPlanService.deleteTaxPlan(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(taxPlanStore.taxPlanReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const insertOrUpdate = async (row: TaxPlanRow) => {
    if (typeof row.efaAccountingObject === 'object' && row.efaAccountingObject) {
      row.efaAccountingObject = row.efaAccountingObject.format(DATE_FMT);
    }
    if (typeof row.cooperationUnit === 'object' && row.cooperationUnit) {
      row.cooperationUnit = row.cooperationUnit
        .filter((v, i, self) => self.indexOf(v) === i) // 去重
        .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
        .join(',');
    }
    if (row.id) {
      const rsp = await TaxPlanService.updateTaxPlan(row)
      actionThen(rsp)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    } else {
      const rsp = await TaxPlanService.addTaxPlan(row)
      actionThen(rsp)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    }
  }

  const actionThen = (rsp: ResponseEntity<any>) => {
    if (rsp.code === 200) {
      message.success("操作成功！").then()
    } else {
      message.warning(rsp.message).then()
    }
    setIsModalVisible(false);
    setTaxPlanRow(undefined)
    refreshData(taxPlanStore.taxPlanReq)
    setSelectedRowKeys([])
  }

  const handleOk = (open: boolean) => {
    const row = {...taxPlanRow!}
    basicInfo.current!.validateFields().then(async (values) => {
      setConfirmLoading(true)
      Object.assign(row, values)

      if (open) {

        // 将row和按钮先存起来
        setSubmitRow(row)

        // 弹窗选择处理人
        setProcessRecordsIsOpen(true)
        setProcessRecordsRow(undefined)

      } else {

        const user = securityStore.getLoggedUser()

        if (isBatchUpdate) {
          batchUpdate(row)
        } else {
          // 默认处理人员是自己
          if (!row.id) {
            row.personResponsible = user._id
            row.personResponsibleName = user.name
          }

          try {
            await insertOrUpdate(row)
          } catch (error) {
            setProcessRecordsIsOpen(false)
            setProcessRecordsRow(undefined)
            setConfirmLoading(false)
            setIsModalVisible(false);
            message.warning(error.message)
          }
        }

      }

    }).catch(logger.warn)
  }

  const handleSubmit = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)

      const user = securityStore.getLoggedUser()
      const btnText = row.status

      // 当类型是撤回时 默认办理人员为提交人(自己)
      if (btnText === FORM_REVOCATION_TEXT) {
        row.personResponsible = user._id || submitterId
        row.personResponsibleName = user.name
      }

      // 主表
      const subRow = {...submitRow}
      subRow.personResponsible = row.personResponsible
      subRow.personResponsibleName = row.personResponsibleName
      subRow.efaStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.efaStatus
      // 主表在撤回、退回、变更时->更新局部数据
      if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在转交、提交时->更新局部数据
      if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在提交时->更新局部数据
      if (btnText === FORM_COMMIT_TEXT) {
        subRow.efaAccountingObject = new Date().toLocaleDateString().replace(/\//g, '-')
      }
      // 主表在结办时->更新局部数据
      if (btnText === FORM_CLOSE_TEXT) {

      }

      // 办理记录表
      row.type = TableName
      row.businessId = subRow.id // 有可能会没值
      row.status = btnText
      row.userId = user._id
      row.userName = user.name
      row.personResponsible = undefined
      row.personResponsibleName = undefined
      if (btnText === FORM_COMMIT_TEXT) {
        const date = new Date()
        row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      } else {
        row.process = latestProcess
      }
      if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
        row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
      } else {
        row.conca = `${user.name}${btnText}了。`
      }

      // 判断是否 先新增主表 后新增记录和通知表
      if (subRow.id) {

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.efaApplyCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "税费计划",
          wnDesc: `您参与办理的税费计划（${subRow.efaApplyCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      } else {

        let subRsp: ResponseEntity<TaxPlanRow>
        try {
          subRsp = await insertOrUpdate(subRow)
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }

        if (subRsp.data) {
          // 重新赋值再提交
          row.businessId = subRsp.data.id
          await ProcessRecordsService.addProcessRecords(row)
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: subRsp.data.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const notificationScopeUserId = rsp.items
            .map(o => o.userId)
            .concat(row.person)
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(id => id !== user._id) // 过滤出不是自己的数据
            .join(',') || ''
          const notificationScopeUserName = rsp.items
            .map(o => o.userName)
            .concat((row.personName || '').split(','))
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(name => name !== user.name) // 过滤出不是自己的数据
            .join(',') || ''
          await processStore.newAssignmentNotice({
            businessId: subRsp.data.id,
            businessCode: subRsp.data.efaApplyCode,
            cooperationUnit: (subRsp.data.cooperationUnit as string).split(',')[0] || 'N/A',
            notificationScopeUserId,
            notificationScopeUserName,
            personResponsible: subRsp.data.personResponsible,
            personResponsibleName: subRsp.data.personResponsibleName,
            responsibilityProject: subRsp.data.responsibilityProject,
            type: "税费计划",
            wnDesc: `您参与办理的税费计划（${subRsp.data.efaApplyCode}），${row.conca} 请知悉！`,
            wnStatus: "未读"
          })
        } else {
          message.warning('主表保存成功，办理记录表和任务通知表提交失败')
        }

      }
      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  const handleSubmitBatch = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {

      const rows = taxPlanStore.taxPlanDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
      for (let i = 0; i < rows.length; i++) {

        Object.assign(row, values)

        const user = securityStore.getLoggedUser()
        const btnText = row.status

        // 当类型是撤回时 默认办理人员为提交人(自己)
        if (btnText === FORM_REVOCATION_TEXT) {
          row.personResponsible = user._id || submitterId
          row.personResponsibleName = user.name
        }

        // 主表
        const subRow = {...rows[i]}
        subRow.personResponsible = row.personResponsible
        subRow.personResponsibleName = row.personResponsibleName
        subRow.efaStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.efaStatus
        // 主表在撤回、退回、变更时->更新局部数据
        if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在转交、提交时->更新局部数据
        if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
          subRow.organizersUserId = subRow.personResponsible
          subRow.organizersUserName = subRow.personResponsibleName
        }
        // 主表在提交时->更新局部数据
        if (btnText === FORM_COMMIT_TEXT) {
          subRow.efaAccountingObject = new Date().toLocaleDateString().replace(/\//g, '-')
        }
        // 主表在结办时->更新局部数据
        if (btnText === FORM_CLOSE_TEXT) {

        }

        // 办理记录表
        row.type = TableName
        row.businessId = subRow.id // 有可能会没值
        row.status = btnText
        row.userId = user._id
        row.userName = user.name
        row.personResponsible = undefined
        row.personResponsibleName = undefined
        if (btnText === FORM_COMMIT_TEXT) {
          const date = new Date()
          row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
        } else {
          row.process = latestProcessBatch[i]
        }
        if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
          row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
        } else {
          row.conca = `${user.name}${btnText}了。`
        }

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.efaApplyCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "税费计划",
          wnDesc: `您参与办理的税费计划（${subRow.efaApplyCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      }

      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  /**
   * 操作记录按钮
   */
  const handleProcessRecordsClick = async (record: TaxPlanRow) => {
    const rsp = await ProcessRecordsService.getProcessRecords({
      businessId: record.id,
      pageNum: 1,
      perPage: 999,
      type: TableName
    })
    if (!rsp) {
      message.warning('查无数据')
      return
    }
    setProcessRecordsDataSource(rsp.items)
    setProcessRecordsOpen(true)
  }

  const handleCopyClick = (record: TaxPlanRow) => {
    const user = securityStore.getLoggedUser()
    setTaxPlanRow({
      ...record,
      id: '',
      efaFileArchive: '',
      efaStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      efaAccountingObject: dayjs(),
    })
    setIsView(false)
    setIsBatchUpdate(false)
    setIsModalVisible(true)
  }

  return (
    <div className={"institution"}>

      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        updateCount={updateCount}
        collapsed={taxPlanStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        handleShowColumnReset={handleShowColumnReset}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={props.isModalSelect ? columns.filter(o => o.key !== 'action') : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}
        setConfirmLoading={setConfirmLoading}
        items={[
          {
            key: 'handleAdd',
            icon: <PlusOutlined/>,
            onClick: () => handleAdd()
          },
          {
            key: 'handleDeleteBatch',
            icon: <DeleteOutlined/>,
            onClick: () => handleDeleteBatch()
          },
          {
            key: 'handleEditBatch',
            icon: <EditOutlined/>,
            onClick: () => handleEditBatch()
          },
        ]}></HeaderActionCom>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        size={"middle"}
        loading={props.dataSource ? false : taxPlanStore.taxPlanDatasource.loading || confirmLoading}
        bordered
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys)
          },
          columnWidth: 50,
        }}
        pagination={!props.dataSource && {
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                (showColumn as string[]).map((key, index) =>
                  key.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={summaryTotalObj[key.slice(0, key.indexOf('-'))]}></TableMoneyText> 元
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}/>

      { /*操作记录表*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsOpen}
        width={LARGE_MODAL_WIDTH_OTHER}
        onCancel={() => setProcessRecordsOpen(false)}
        destroyOnClose
        footer={null}>
        <ProcessRecords
          isHiddenOptions
          dataSource={processRecordsDataSource}
          isModalSelect
          locationPathname={'/processRecords'}/>
      </CustomDraggableModal>

      { /*操作记录表单*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsIsOpen}
        width={LARGE_MODAL_WIDTH}
        destroyOnClose
        onCancel={() => {
          setProcessRecordsIsOpen(false)
          setConfirmLoading(false)
        }}
        onOk={isBatchUpdate ? handleSubmitBatch : handleSubmit}>
        <ProcessRecordsBasicInfo
          tableName={TableName}
          businessId={taxPlanRow?.id}
          submitterId={submitterId}
          userId={taxPlanRow?.personResponsible}
          status={taxPlanRow?.efaStatus}
          row={processRecordsRow}
          ref={processRecordsBasicInfo}/>
      </CustomDraggableModal>

      <CustomForm
        title={'税费计划'}
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={isModalVisible}
        submitterId={submitterId}
        confirmLoading={confirmLoading}
        userId={taxPlanRow?.personResponsible}
        status={taxPlanRow?.efaStatus}
        extraDisabled={!taxPlanRow?.id}
        isView={isView}
        extraOnClick={() => handlePrint(taxPlanRow)}
        item={[
          {
            key: 'handleFinalSettlementClick',
            icon: <RetweetOutlined/>,
            hidden: !FORM_INITIATE_STATE.includes(taxPlanRow?.efaStatus),
            onClick: () => handleFinalSettlementClick(taxPlanRow)
          },
          {
            key: 'handleTaxAccountingClick',
            icon: <RetweetOutlined/>,
            hidden: !FORM_INITIATE_STATE.includes(taxPlanRow?.efaStatus),
            onClick: () => handleTaxAccountingClick(taxPlanRow)
          },
          {
            key: 'handleCopyClick',
            icon: <FieldTimeOutlined/>,
            disabled: !taxPlanRow?.id,
            onClick: () => handleCopyClick(taxPlanRow)
          },
          {
            key: 'handleProcessRecordsClick',
            icon: <FieldTimeOutlined/>,
            disabled: !taxPlanRow?.id,
            onClick: () => handleProcessRecordsClick(taxPlanRow)
          },
        ]}>
        <BasicInfo row={{...taxPlanRow!}} ref={basicInfo} isView={isView} isBatchUpdate={isBatchUpdate}/>
      </CustomForm>

      <WindowPrint
        isHiddenTime
        title={"税费计划"}
        contentList={contentList}
        isModalOpen={isModalOpen}
        handleCancel={() => setIsModalOpen(false)}/>
    </div>
  )
}))
