
import { makeAutoObservable, runInAction } from "mobx";
import { Page, StateFullData } from "@services/dto/common";
import { BonusRow, SearchBonusRowReq } from "@services/dto/bonus";
import { BonusService as Service } from "@/services/BonusService";

export class BonusStore {
  datasource: StateFullData<Page<BonusRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  search(req: SearchBonusRowReq) {
    this.datasource = { ...this.datasource, loading: true }
    Service.search(req).then(rsp => {
      runInAction(() => this.datasource = { data: rsp, loading: false })
    }).catch(err => {
      runInAction(() => this.datasource = { ...this.datasource, loading: false, err })
    })
  }
}
export const bonusStore = new BonusStore();
