import {makeAutoObservable, runInAction} from "mobx";
import {Page, StateFullData} from "@services/dto/common";
import {AttendanceReq, AttendanceRow, WorkTimeReq, WorkTimeRow} from "@services/dto/workTime";
import {WorkTimeService} from "@services/WorkTimeService";

export class WorkTimeStore {
  workTimes: StateFullData<Page<WorkTimeRow>> = {};
  attendances: StateFullData<Page<AttendanceRow>> = {};

  constructor() {
    makeAutoObservable(this)
  }

  getWorkerTimes(req: WorkTimeReq) {
    this.workTimes = {...this.workTimes, loading: true}
    WorkTimeService.getWorkerTimes(req).then(rsp => {
      runInAction(() => this.workTimes = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.workTimes = {...this.workTimes, err, loading: false})
    })
  }

  getWorkerTimeAttendances(req: AttendanceReq) {
    this.attendances = {...this.attendances, loading: true}
    WorkTimeService.getWorkerTimeAttendances(req).then(rsp => {
      runInAction(() => this.attendances = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.attendances = {...this.attendances, err, loading: false})
    })
  }
}

export const workTimeStore = new WorkTimeStore()
