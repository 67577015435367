import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Button, Drawer, FormInstance, message, Modal, Space, Table, Typography} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {accAdd, getUploadArrayToString, getUploadIds, logger, showErr} from "@common/utils";
import {BasicInfo} from "@modules/DemoPage/Components/BasicInfo";
import {HeaderActionCom} from "@modules/DemoPage/Components/HeaderActionCom";
import {DemoPageReq, DemoPageRow, DemoPageSummaryTotal} from "@services/dto/demoPage";
import {DemoPageService} from "@services/DemoPageService";
import {TableOptions} from "src/components/TableOptions";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {TableMoneyText} from "@components/TableMoneyText";
import {LabelValue, ResponseEntity} from "@services/dto/common";
import {useLocation} from "react-router-dom";
import {ToolService} from "@services/ToolService";
import {WorkerRecruitmentService} from "@services/WorkerRecruitmentService";
import {User} from "@services/dto/security";
import {previewFile} from "@components/CustomUpload";
import {
  DEFAULT_MODAL_WIDTH,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  SMALL_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1x
} from "@/config";
import {PrinterOutlined} from "@ant-design/icons";
import {WindowPrint} from "@modules/WindowPrint";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {CustomDraggableModal} from "@components/CustomDraggableModal";

const wideShowColumn: string[] = [
  "id",
  "code",
  "responsibilityProject",
  "cooperationUnit",
  "statusCommon",
  "fileList",
  "total",
  "personResponsible",
  "personResponsibleName",
  "action",
]

const narrowShowColumn: string[] = [
  "id",
  "code",
  // "responsibilityProject",
  "cooperationUnit",
  // "statusCommon",
  // "fileList",
  "total",
  "personResponsible",
  "personResponsibleName",
  "action",
]

export const DemoPage = observer(() => {
  const {demoPageStore, screenResolutionStore, tableColumnsStore} = allStores
  const basicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<DemoPageRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [demoPageRow, setDemoPageRow] = useState<DemoPageRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [summaryTotalObj, setSummaryTotalObj] = useState<DemoPageSummaryTotal>()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentList, setContentList] = useState<LabelValue[]>([]);
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<DemoPageRow>>([])
  const location = useLocation()
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [searchReq, setSearchReq] = useState<DemoPageReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
  })

  const columns: ColumnsType<DemoPageRow> = [
    {
      title: 'id',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '校验',
      dataIndex: 'statusCommon',
      key: 'statusCommon',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '文件',
      dataIndex: 'fileList',
      key: 'fileList',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text, record) => (
        <Space>
          {
            text && <Typography.Text
              type="success"
              className={'c-pointer'}
              onClick={() => {
                handlePreview(record)
              }}>查看</Typography.Text>
          }
        </Space>
      )
    },
    {
      title: '金额',
      dataIndex: 'total',
      key: 'total',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任人id',
      dataIndex: 'personResponsible',
      key: 'personResponsible',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任人名字',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableOptions
          items={[
            <Button
              className={'text-align-left'}
              type={'text'}
              block
              onClick={() => {
                handlePrint(record).then()
              }}>
              <PrinterOutlined/>
              打印
            </Button>,
          ]}
          handleEdit={() => {
            handleEdit(record)
          }}
          handleView={() => {
            handleView(record)
          }}
          handleDelete={() => {
            handleDeleteBatch(record)
          }}
          onClick={() => {
            handleEdit(record)
          }}>
          修改
        </TableOptions>
      )
    }
  ];

  /**
   * @description 初始化
   */
  useEffect(() => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setScrollHeight(SMALL_TABLE_SCROLL_HEIGHT)

      // 读取存储
      let showColumn: string[] = tableColumnsStore.getItem(location.pathname) || []
      if (showColumn.length) {
        // 检验存储中的列表是否符合规范
        showColumn = showColumn.filter(o => columns.some(column => column.key === o))
        // 无论有无存储都触发change，保存当前显示的字段
        setShowColumn(showColumn)
      } else {
        setShowColumn(narrowShowColumn)
      }
    }
    refreshData(searchReq)
  }, [])

  /**
   * @description 全局查询
   */
  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  /**
   * @description 表格数据变化
   */
  useEffect(() => {
    if (demoPageStore.demoPageDatasource.data) {
      setDataSource(demoPageStore.demoPageDatasource.data.items || []);
      setTotal(demoPageStore.demoPageDatasource.data.total || 0)
    }
  }, [demoPageStore.demoPageDatasource.data])

  /**
   * @description 表单数据变化
   */
  useEffect(() => {

  }, [demoPageStore.demoPageRow])

  /**
   * @description 表格合计
   */
  useEffect(() => {
    const objInitValue = {total: 0}
    let obj = summaryTotalObj
    const arr = demoPageStore.demoPageDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        total: accAdd(acc.total, cur.total),
      }), objInitValue);
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [demoPageStore.demoPageDatasource, selectedRowKeys])

  /**
   * 列配置变化
   */
  useEffect(() => {
    setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))))
  }, [showColumn])

  /**
   * 文件预览
   * @param record
   */
  const handlePreview = (record: DemoPageRow) => {
    if (typeof record.fileList === 'string') {
      previewFile(record.fileList)
    }
  }

  const handleAdd = () => {
    setDemoPageRow(undefined)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handlePrint = async (record: DemoPageRow) => {
    let user: User | undefined = undefined
    if (record.personResponsible && record.personResponsible !== 'N/A') {
      setConfirmLoading(true)
      user = await WorkerRecruitmentService.getUserById(record.personResponsible).finally(() => {
        setConfirmLoading(false)
      })
    }
    logger.log(user)
    setContentList([
      {label: '制单日期', value: new Date().toDateString()},
      {label: '制单日期', value: new Date().toDateString()},
      {label: '制单日期', value: new Date().toDateString()},
      {label: '制单日期', value: new Date().toDateString()},
    ])
    setIsModalOpen(true)
  }

  const handleView = (record: DemoPageRow) => {
    setDemoPageRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = (record: DemoPageRow) => {
    setDemoPageRow(record)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleDeleteBatch = (record?: DemoPageRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        DemoPageService.deleteDemoPage(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(searchReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: DemoPageReq) => {
    setSearchReq(req)
    demoPageStore.getDemoPage(req)
  }

  const handleOk = () => {
    const row = {...demoPageRow!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(row, values)

      let ids = ""
      if (typeof row.fileList === 'object') {
        ids = getUploadIds(row.fileList)
        row.fileList = getUploadArrayToString(row.fileList)
      }

      setConfirmLoading(true)
      if (row.id) {
        DemoPageService.updateDemoPage(row).then(rsp => {
          actionThen(ids, rsp).catch(() => false);
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        DemoPageService.addDemoPage(row).then(rsp => {
          actionThen(ids, rsp).catch(() => false);
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  }

  const actionThen = async (ids: string, rsp: ResponseEntity<DemoPageRow>) => {
    // 图片上传跟表单绑定
    try {
      await ToolService.submittedSuccess(ids, rsp.data.id)
      message.success("操作成功！")
    } catch (e) {
      showErr(e)
    } finally {
      setIsModalVisible(false);
      setDemoPageRow(undefined)
      refreshData(searchReq)
    }
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(location.pathname, value)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    demoPageStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const [open, setOpen] = useState<boolean>(false)

  return (
    <div className={"institution"}>
      <HeaderActionCom
        collapsed={demoPageStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        selectedRowKeys={selectedRowKeys}
        handleShowColumnReset={handleShowColumnReset}
        handleDeleteBatch={handleDeleteBatch}
        handleAdd={handleAdd}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={columns}
        refreshData={(req) => refreshData({...searchReq, ...req})}></HeaderActionCom>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={dataSource}
        columns={renderColumns}
        size={"middle"}
        loading={demoPageStore.demoPageDatasource.loading || confirmLoading}
        bordered
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2} className='text-align-center'>
                已选：{ selectedRowKeys.length }
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} colSpan={2}/>
              <Table.Summary.Cell index={5} colSpan={1} className='text-align-right'>
                合计：<TableMoneyText value={summaryTotalObj?.total}></TableMoneyText>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6} colSpan={6}/>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys, columnWidth: 50}}/>

      <Button onClick={() => setOpen(true)}>open</Button>
      <CustomDraggableModal
        title={<div className="text-align-center">hello-title</div>}
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        destroyOnClose>
        hello
      </CustomDraggableModal>

      <Drawer
        title={<div className="text-align-center">资金审批</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        onClose={handleCancel}>
        <BasicInfo row={{...demoPageRow!}} ref={basicInfo} isView={isView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button
              type="primary"
              onClick={handleOk}
              loading={confirmLoading}
              className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>

      <WindowPrint
        title={"模板页"}
        contentList={contentList}
        isModalOpen={isModalOpen}
        handleCancel={() => setIsModalOpen(false)}/>
    </div>
  )
})
