import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {TaxAccountingReq, TaxAccountingRow} from "@services/dto/taxAccounting";

export class TaxAccountingService {
  static async getTaxAccounting(req: TaxAccountingReq): Promise<Page<TaxAccountingRow>> {
    return await apiV1.get("/taxAccounting/search", {params: req})
  }

  static async addTaxAccounting(row: TaxAccountingRow): Promise<ResponseEntity<TaxAccountingRow>> {
    return await apiV1.post("/taxAccounting/save", {...row})
  }

  static async updateTaxAccounting(row: TaxAccountingRow): Promise<ResponseEntity<TaxAccountingRow>> {
    return await apiV1.put("/taxAccounting/update", {...row})
  }

  static async deleteTaxAccounting(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/taxAccounting/delete", {data: ids})
  }

  static async batchUpdateTaxAccounting(selectedRows: TaxAccountingRow[]): Promise<ResponseEntity<string>> {
    return await apiV1.put("/taxAccounting/updateBatch", [...selectedRows])
  }

  static async getOne(id: string): Promise<ResponseEntity<TaxAccountingRow>> {
    return await apiV1.get(`/taxAccounting/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponseEntity<TaxAccountingRow[]>> {
    return await apiV1.get(`/taxAccounting/getBatch/${ids.join(',')}`)
  }
}
