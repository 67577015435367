import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import dayjs from "dayjs";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {allStores} from "@/stores";
import {ProjectRow} from "@services/dto/project";
import {SelectFiling} from "@components/SelectFiling";
import {FORM_DISABLED_STATE} from "@/config";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {SealApplicationRow} from "@services/dto/sealApplication";
import {SelectApplicationFormType} from "@components/SelectApplicationFormType";
import {Any} from "@common/types";

export interface PropsType {
  row: SealApplicationRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores
  const [formIds, setFormIds] = useState<string[]>()
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()

  useEffect(() => {
    form.resetFields()
    const sealApplicationRow = {...props.row}
    filingStore.setFilingFaCode(sealApplicationRow.saCode)
    if (sealApplicationRow.formIdStr) {
      setFormIds(sealApplicationRow.formIdStr.split(","))
      sealApplicationRow.formIds = sealApplicationRow.formIdStr.split(",")
    } else {
      setFormIds([])
    }
    if (typeof sealApplicationRow.saDate === 'string' && sealApplicationRow.saDate !== '') {
      sealApplicationRow.saDate = dayjs(sealApplicationRow.saDate)
    }
    if (typeof sealApplicationRow.cooperationUnit === 'string') {
      sealApplicationRow.cooperationUnit = sealApplicationRow.cooperationUnit !== '' ? sealApplicationRow.cooperationUnit.split(',') : []
    }
    setResponsibilityProject(sealApplicationRow.responsibilityProject);
    setCooperationUnit(sealApplicationRow.cooperationUnit)
    form.setFieldsValue(sealApplicationRow);
  }, [props.row.id])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    setResponsibilityProject(value)
    const row = options.find(o => value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  const handleFaApplicationFormTypeChange = () => {
    setFormIds(undefined)
    form.resetFields(['formIds'])
  }

  const handleTableValueChange = async (v: string[], options: Any[]) => {
    if (options && options.length > 0) {
      const cooperationUnit = typeof options[0].cooperationUnit === 'string' ? options[0].cooperationUnit?.split(",") : options[0].cooperationUnit
      setResponsibilityProject(options[0].personResponsible)
      setCooperationUnit(cooperationUnit)
      form.setFieldsValue({
        responsibilityProject: options[0].responsibilityProject,
        cooperationUnit: cooperationUnit,
        formIds: v
      });
    } else {
      setResponsibilityProject(undefined)
      setCooperationUnit([])
      form.setFieldsValue({
        responsibilityProject: undefined,
        cooperationUnit: undefined,
        formIds: undefined
      });
    }
    setFormIds(v)
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      initialValues={{
        cooperationUnit: ['N/A'],
      }}
      disabled={props.isView || FORM_DISABLED_STATE.includes(props.row.saStatus)}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="organizersUserName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="formIds">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="saDate"
            rules={[{required: false, message: '请选择制单日期!'}]}>
            <DatePicker disabled placeholder={"请选择制单日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="saCode"
            rules={[{required: false, message: '请输入制单编号!'}]}>
            <Input disabled placeholder="请输入制单编号"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="摘要"
            name="saDesc"
            rules={[{required: false, message: '请输入印章名称!'}]}>
            <Input.TextArea placeholder="请输入印章名称" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="印章名称"
            name="saName"
            rules={[{required: false, message: '请输入印章名称!'}]}>
            <Input placeholder="请输入印章名称"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联表单"
            name="formType"
            rules={[{required: false, message: '请输入关联表单!'}]}>
            <SelectApplicationFormType
              className={'width-100-percentage'}
              allowClear
              isOption
              showKey={['工资发放', '员工借款', '费用报销', '合同结算', '税费核算', '税费缴纳', '资金出纳', '工作任务']}
              mode={'multiple'}
              placeholder={'请选择关联申请表单'}
              tableValue={formIds}
              onChange={handleFaApplicationFormTypeChange}
              handleTableValueChange={handleTableValueChange}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: false, message: '请选择责任部门!'}]}>
            <SelectProject
              disabled
              placeholder={"请选择责任部门"} allowClear onChange={handleProjectChange}
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              allowClear
              mode={'tags'}
              disabled
              onChange={(value) => setCooperationUnit(value)}
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="存档批号"
            name="saFileArchive"
            rules={[{required: false, message: '请选择存档批号!'}]}>
            <SelectFiling
              isHidePlus
              isHideClose
              responsibilityProject={responsibilityProject}
              cooperationUnit={cooperationUnit}
              placeholder={"请选择存档批号"}
              inputDisabled
              fieldNames={{value: "id", label: "faBatchNumber"}}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="经办记录"
            name="personResponsible"
            rules={[{required: false, message: '请选择经办记录!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择经办记录"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="主办人员"
            name="organizersUserId"
            rules={[{required: false, message: '请选择主办人员!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择主办人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="saStatus"
            rules={[{required: false, message: '请选择办理进度',}]}>
            <SelectProcessStatus
              allowClear
              placeholder={"请选择办理进度"}
              disabled/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
