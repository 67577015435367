import {makeAutoObservable, runInAction} from "mobx";
import {Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {ConferenceRecordReq, ConferenceRecordRow} from "@services/dto/conferenceRecord";
import {ConferenceRecordService} from "@services/ConferenceRecordService";

export class ConferenceRecordStore {
  conferenceRecordDatasource: StateFullData<Page<ConferenceRecordRow>> = {}
  conferenceRecordSelectDatasource: StateFullData<Page<ConferenceRecordRow>> = {}
  conferenceRecordRow?: ConferenceRecordRow
  conferenceRecordDetail: StateFullData<ResponseEntity<ConferenceRecordRow>> = {}
  conferenceRecordDetails: StateFullData<ResponsesEntity<ConferenceRecordRow>> = {}
  conferenceRecordReq: ConferenceRecordReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateConferenceRecordRow(row: ConferenceRecordRow) {
    this.conferenceRecordRow = {...row}
  }

  getConferenceRecord(req: ConferenceRecordReq) {
    this.conferenceRecordDatasource = {...this.conferenceRecordDatasource, loading: true}
    this.conferenceRecordReq = {...this.conferenceRecordReq, ...req}
    ConferenceRecordService.getConferenceRecord(req).then(rsp => {
      runInAction(() => this.conferenceRecordDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.conferenceRecordDatasource = {...this.conferenceRecordDatasource, loading: false, err})
    })
  }

  getSelectConferenceRecord(req: ConferenceRecordReq) {
    this.conferenceRecordSelectDatasource = {...this.conferenceRecordSelectDatasource, loading: true}
    ConferenceRecordService.getConferenceRecord(req).then(rsp => {
      runInAction(() => this.conferenceRecordSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.conferenceRecordSelectDatasource = {...this.conferenceRecordSelectDatasource, loading: false, err})
    })
  }

  getDetailById(id: string) {
    this.conferenceRecordDetail = {...this.conferenceRecordDetail, loading: true}
    ConferenceRecordService.getOne(id).then(rsp => {
      runInAction(() => this.conferenceRecordDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.conferenceRecordDetail = {...this.conferenceRecordDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.conferenceRecordDetails = {...this.conferenceRecordDetails, loading: true}
    ConferenceRecordService.getBatch(ids).then(rsp => {
      runInAction(() => this.conferenceRecordDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.conferenceRecordDetails = {...this.conferenceRecordDetails, loading: false, err})
    })
  }
}

export const conferenceRecordStore = new ConferenceRecordStore();
