import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {Button, Drawer, FormInstance, message, Modal, Space, Table, Tag} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {accAdd, logger, safeCompute, showErr} from "@common/utils";
import {BasicInfo} from "@modules/AccountSales/Components/BasicInfo";
import {AccountSalesReq, AccountSalesRow, AccountSalesRowSummaryTotal} from "@services/dto/accountSales";
import {AccountSalesService} from "@services/AccountSalesService";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {TableOptions} from "src/components/TableOptions";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import {useLocation} from "react-router-dom";
import {HeaderActionCom} from "@modules/AccountSales/Components/HeaderActionCom";
import {
  DEFAULT_MODAL_WIDTH,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x,
} from "@/config";

const wideShowColumn: string[] = [
  "slCode",
  // "slCoding",
  // "slName",
  "slType",
  // "slUnit",
  "slNum-summation",
  "slPrice-summation",
  "slAmount-summation",
  // "slTaxRate",
  "slTaxAmount-summation",
  "slPurchaser",
  "slSeller",
  // "personResponsibleName",
  // "responsibilityProject",
  // "cooperationUnit",
  // "statusCommon",
  "action",
]

const narrowShowColumn: string[] = [
  "slCode",
  // "slCoding",
  "slName",
  "slType",
  // "slUnit",
  // "slNum-summation",
  // "slPrice-summation",
  // "slAmount-summation",
  // "slTaxRate",
  // "slTaxAmount-summation",
  "slPurchaser",
  "slSeller",
  // "personResponsibleName",
  // "responsibilityProject",
  // "cooperationUnit",
  // "statusCommon",
  "action",
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: AccountSalesRow[] // 数据源
}

export const AccountSales = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const location = useLocation()
  const {accountSalesStore, screenResolutionStore, tableColumnsStore} = allStores
  const basicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<AccountSalesRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [accountSalesRow, setAccountSalesRow] = useState<AccountSalesRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<AccountSalesRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [summaryTotalObj, setSummaryTotalObj] = useState<AccountSalesRowSummaryTotal>({
    slNum: 0,
    slPrice: 0,
    slAmount: 0,
    slTaxAmount: 0
  })
  const [searchReq, setSearchReq] = useState<AccountSalesReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...accountSalesStore.accountSalesReq
  })

  const columns: ColumnsType<AccountSalesRow> = [
    {
      title: '发票代码-号码',
      dataIndex: 'slCode',
      key: 'slCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '商品编码',
      dataIndex: 'slCoding',
      key: 'slCoding',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '货物名称',
      dataIndex: 'slName',
      key: 'slName',
      // align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '规格型号',
      dataIndex: 'slType',
      key: 'slType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
    },
    {
      title: '单位',
      dataIndex: 'slUnit',
      key: 'slUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '数量',
      dataIndex: 'slNum',
      key: 'slNum-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '单价(不含税)',
      dataIndex: 'slPrice',
      key: 'slPrice-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '金额(不含税)',
      dataIndex: 'slAmount',
      key: 'slAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '税率',
      dataIndex: 'slTaxRate',
      key: 'slTaxRate',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text} isPercentage={true}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '税额',
      dataIndex: 'slTaxAmount',
      key: 'slTaxAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '购买方',
      dataIndex: 'slPurchaser',
      key: 'slPurchaser',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '销售方',
      dataIndex: 'slSeller',
      key: 'slSeller',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '经办记录',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '校验',
      dataIndex: 'statusCommon',
      key: 'statusCommon',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <Tag color={text === '完成' ? "success" : 'error'}>{ text }</Tag>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableOptions
          handleDelete={() => handleDeleteBatch(record)}
          handleEdit={() => handleEdit(record)}
          handleView={() => handleView(record)}
          onClick={() => handleEdit(record)}>
          修改
        </TableOptions>
      )
    }
  ];

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (accountSalesStore.accountSalesDatasource.data) {
      setDataSource(accountSalesStore.accountSalesDatasource.data.items || [])
      setTotal(accountSalesStore.accountSalesDatasource.data.total || 0)
    }
  }, [accountSalesStore.accountSalesDatasource.data])

  useEffect(() => {
    const objInitValue = {
      slNum: 0,
      slPrice: 0,
      slAmount: 0,
      slTaxAmount: 0
    }
    let obj = summaryTotalObj
    const arr = accountSalesStore.accountSalesDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        slNum: accAdd(acc.slNum, cur.slNum),
        slPrice: accAdd(acc.slPrice, cur.slPrice),
        slAmount: accAdd(acc.slAmount, cur.slAmount),
        slTaxAmount: accAdd(acc.slTaxAmount, cur.slTaxAmount),
      }), objInitValue);
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [accountSalesStore.accountSalesDatasource, selectedRowKeys])

  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => o.key !== 'action').filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, accountSalesStore.collapsed, searchReq.pageNum, searchReq.perPage])

  const handleAdd = () => {
    setAccountSalesRow(undefined)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handleView = (record: AccountSalesRow) => {
    setAccountSalesRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = (record: AccountSalesRow) => {
    setAccountSalesRow(record)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleDeleteBatch = (record?: AccountSalesRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        AccountSalesService.deleteAccountSales(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(accountSalesStore.accountSalesReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: AccountSalesReq) => {
    setSearchReq(req)
    accountSalesStore.getAccountSales(req)
  }

  const handleOk = () => {
    const row = {...accountSalesRow!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(row, values)
      if (typeof row.cooperationUnit === 'object') {
        row.cooperationUnit = values.cooperationUnit.join(",")
      }
      setConfirmLoading(true)
      if (row.id) {
        AccountSalesService.updateAccountSales(row).then(() => {
          actionThen();
          setUpdateCount(updateCount + 1)
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        AccountSalesService.addAccountSales(row).then(() => {
          actionThen();
          setUpdateCount(updateCount + 1)
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  }

  const actionThen = () => {
    message.success("操作成功！").then()
    setIsModalVisible(false);
    setAccountSalesRow(undefined)
    refreshData(accountSalesStore.accountSalesReq)
  }

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (!showColumn.length) return
    // 检验存储中的列表是否符合规范
    showColumn = showColumn.filter(o => columns.some(column => column.key === o))
    // 无论有无存储都触发change，保存当前显示的字段
    setShowColumn(showColumn)
  }, []);

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    accountSalesStore.setCollapsed(collapsed)
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        isHiddenOptions={props.isHiddenOptions}
        updateCount={updateCount}
        collapsed={accountSalesStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        selectedRowKeys={selectedRowKeys}
        handleShowColumnReset={handleShowColumnReset}
        handleDeleteBatch={handleDeleteBatch}
        handleAdd={handleAdd}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={props.isModalSelect ? columns.filter(o => o.key !== 'action') : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}></HeaderActionCom>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        size={"middle"}
        loading={props.dataSource ? false : accountSalesStore.accountSalesDatasource.loading || confirmLoading}
        bordered
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        pagination={!props.dataSource && {
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                (showColumn as string[]).map((key, index) =>
                  key.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={summaryTotalObj[key.slice(0, key.indexOf('-'))]}></TableMoneyText> 元
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}
        rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys, columnWidth: 50}}/>

      <Drawer
        title={<div className="text-align-center">销货清单</div>}
        width={DEFAULT_MODAL_WIDTH}
        destroyOnClose
        open={isModalVisible}
        onClose={handleCancel}>
        <BasicInfo row={{...accountSalesRow!}} ref={basicInfo} isView={isView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button
              type="primary"
              onClick={handleOk}
              loading={confirmLoading}
              className={"margin-left-xs"}>
              确定
            </Button>
          }
        </div>
      </Drawer>
    </div>
  )
}))
