import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {WorkPostReq, WorkPostRow} from "@services/dto/workPost";
import {WorkPostService} from "@/services/WorkPostService";

export class WorkPostStore {
  workPostDatasource: StateFullData<Page<WorkPostRow>> = {}
  workPostSelectDatasource: StateFullData<Page<WorkPostRow>> = {}
  workPostRow: WorkPostRow
  workPostOption?: string
  workPostDetail: StateFullData<ResponseEntity<WorkPostRow>> = {}
  workPostDetails: StateFullData<ResponsesEntity<WorkPostRow>> = {}
  workPostReq: WorkPostReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateWorkPostRow(row: WorkPostRow) {
    this.workPostRow = {...row}
  }

  updateWorkPostOption(option: string) {
    this.workPostOption = option
  }

  getWorkPost(req: WorkPostReq) {
    this.workPostDatasource = { ...this.workPostDatasource, loading: true }
    this.workPostReq = {...this.workPostReq, ...req}
    WorkPostService.searchWorkPost(req).then(rsp => {
      runInAction(() => this.workPostDatasource = { data: rsp, loading: false })
    }).catch(err => {
      runInAction(() => this.workPostDatasource = { ...this.workPostDatasource, loading: false, err })
    })
  }

  getSelectWorkPost(req: WorkPostReq) {
    this.workPostDatasource = { ...this.workPostDatasource, loading: true }
    WorkPostService.searchWorkPost(req).then(rsp => {
      runInAction(() => this.workPostDatasource = { data: rsp, loading: false })
    }).catch(err => {
      runInAction(() => this.workPostDatasource = { ...this.workPostDatasource, loading: false, err })
    })
  }

  getOne(id: string) {
    this.workPostDetail = {...this.workPostDetail, loading: true}
    WorkPostService.getOne(id).then(rsp => {
      runInAction(() => this.workPostDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.workPostDetail = {...this.workPostDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.workPostDetails = {...this.workPostDetails, loading: true}
    WorkPostService.getBatch(ids).then(rsp => {
      runInAction(() => this.workPostDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.workPostDetails = {...this.workPostDetails, loading: false, err})
    })
  }

  async getPrintList(record: WorkPostRow): Promise<LabelValue[]> {

    const row = this.workPostDatasource.data?.items.find(o => o.id === record.id)

    return [
      {label: '岗位名称', value: row.name },
      {label: '岗位代码', value: row.code},
      {label: '岗位职责', value: row.requirements},
      {label: '任职要求', value: row.requirements2},
    ]
  }
}
export const workPostStore = new WorkPostStore();
