import {observer} from "mobx-react";
import {allStores} from "@/stores";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Drawer, FormInstance, message, Modal, Row, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {logger, showErr} from "@common/utils";
import {DATE_FMT, DEFAULT_MODAL_WIDTH} from "@/config";
import {MaterialsOutBoundReq, MaterialsOutBoundRow} from "@services/dto/materialsOutBound";
import {MaterialsOutBoundService} from "@services/MaterialsOutBoundService";
import {BasicInfo} from "@modules/MaterialsOutBound/Components/BasicInfo";

export const MaterialsOutBound = observer(() => {
  const { materialsOutBoundStore } = allStores
  const basicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<MaterialsOutBoundRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [materialsOutBoundRow, setMaterialsOutBoundRow] = useState<MaterialsOutBoundRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [searchReq, setSearchReq] = useState<MaterialsOutBoundReq>({
    pageNum: 1,
    perPage: 10,
  })

  const columns: ColumnsType<MaterialsOutBoundRow> = [
    {
      title: '制单日期',
      dataIndex: 'mdDate',
      key: 'mdDate',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => {
            handleView(record)
          }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'mdCode',
      key: 'mdCode',
      align: 'center'
    },
    {
      title: '类型',
      dataIndex: 'mdType',
      key: 'mdType',
      align: 'center'
    },
    {
      title: '仓库',
      dataIndex: 'mdStorehouse',
      key: 'mdStorehouse',
      align: 'center'
    },
    {
      title: '关联物资清单',
      dataIndex: 'materialListId',
      key: 'materialListId',
      align: 'center'
    },
    {
      title: '出库日期',
      dataIndex: 'mdOutDate',
      key: 'mdOutDate',
      align: 'center'
    },
    {
      title: '办理进度',
      dataIndex: 'mdStatus',
      key: 'mdStatus',
      align: 'center'
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record, index) => (
        <Space>
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined />}
            onClick={() => { handleEdit(record) }}>修改</Button>
          <Button shape="round" className={"delete-btn"} size={"middle"}
            icon={<DeleteOutlined />} onClick={() => { handleDeleteBatch(record) }}>删除</Button>
        </Space>
      )
    }
  ];

  useEffect(() => {
    refreshData(searchReq)
  }, [])

  useEffect(() => {
    setDataSource(materialsOutBoundStore.materialsOutBoundDatasource.data?.items!)
    setTotal(materialsOutBoundStore.materialsOutBoundDatasource.data?.total!)
  }, [materialsOutBoundStore.materialsOutBoundDatasource])

  const handleAdd = () => {
    setMaterialsOutBoundRow(undefined)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handleView = (record: MaterialsOutBoundRow) => {
    setMaterialsOutBoundRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = (record: MaterialsOutBoundRow) => {
    setMaterialsOutBoundRow(record)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleDeleteBatch = (record?: MaterialsOutBoundRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        MaterialsOutBoundService.deleteMaterialsOutBound(ids).then(() => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(searchReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = { ...searchReq };
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: MaterialsOutBoundReq) => {
    materialsOutBoundStore.getMaterialsOutBound(req)
  }

  const actionThen = () => {
    message.success("操作成功！")
    setIsModalVisible(false);
    setMaterialsOutBoundRow(undefined)
    refreshData(searchReq)
  }

  const handleOk = () => {
    const row = { ...materialsOutBoundRow! }
    basicInfo.current!.validateFields().then(values => {
      Object.assign(row, values)
      if (values.mdDate) {
        values.mdDate = values.mdDate.format(DATE_FMT);
      }
      if (values.mdOutDate) {
        values.mdOutDate = values.mdOutDate.format(DATE_FMT);
      }
      setConfirmLoading(true)
      if (row.id) {
        MaterialsOutBoundService.updateMaterialsOutBound(row).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        MaterialsOutBoundService.addMaterialsOutBound(row).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  }

  return (
    <div className={"institution"}>
      <Row gutter={12}>
        <Col span={6}>

        </Col>
        <Col span={6}>

        </Col>
        <Col span={12} className={"text-align-right"}>
          <Button icon={<DeleteOutlined />} size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={() => handleDeleteBatch(undefined)}>删除</Button>
          <Button icon={<PlusOutlined />} type={"primary"} size={"middle"} shape={"round"}
            onClick={handleAdd}>新增</Button>
        </Col>
      </Row>

      <Card className={"margin-top-sm"}>
        <Table
          rowKey={"id"}
          dataSource={dataSource}
          columns={columns}
          size={"middle"}
          loading={materialsOutBoundStore.materialsOutBoundDatasource.loading || confirmLoading}
          pagination={{
            total: total,
            position: ['bottomCenter'],
            pageSize: searchReq.perPage,
            current: searchReq.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`,
            onChange: handlePageChange
          }}
          rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys }} />
      </Card>

      <Drawer
        title={<div className="text-align-center">物质出库</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        onClose={handleCancel}>
        <BasicInfo row={{ ...materialsOutBoundRow! }} ref={basicInfo} isView={isView} />

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          <Button type="primary" onClick={handleOk} loading={confirmLoading} className={"margin-left-xs"}>确定</Button>
        </div>
      </Drawer>
    </div>
  )
})
