import './index.less'
import {observer} from "mobx-react";
import {Spin, Tooltip} from "antd";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useEffect, useState} from "react";
import {TooltipPlacement} from "antd/es/tooltip";
import {OrganizationalService} from "@services/OrganizationalService";

export interface PropsType {
  value: string
  placement?: TooltipPlacement
}

export const TablePersonResponsible = observer((props: PropsType) => {

  const [loading, setLoading] = useState<boolean>(true)
  const [tip, setTip] = useState<string>('')
  const [value, setValue] = useState<string>('')

  useEffect(() => {
    if (props.value) {
      OrganizationalService.getOne(props.value).then(rsp => {
        if (rsp) {
          setValue(rsp.name)
          setLoading(false)
        } else {
          setValue(`【未绑定】${props.value}`)
          setLoading(false)
        }
      })
    } else {
      setValue(props.value)
      setLoading(false)
    }
  }, [props.value]);

  return <>
    <CopyToClipboard
      onCopy={() => tip ? setTip(tip + '.') : setTip('复制成功')}
      text={value}>
      <Tooltip
        placement={props.placement}
        title={
          <>
            <div>{ tip || '一键复制' }</div>
            <div>{ value || '/' }</div>
          </>
        }>
        <Spin size="small" spinning={loading}>
          <div
            onMouseOut={() => setTimeout(() => setTip(''), 600)}
            className={'box c-pointer'}>
            { value || '/' }
          </div>
        </Spin>
      </Tooltip>
    </CopyToClipboard>
  </>
})
