import {makeAutoObservable, runInAction} from "mobx";
import {Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {ProcessRecordsReq, ProcessRecordsRow} from "@services/dto/processRecords";
import {ProcessRecordsService} from "@services/ProcessRecordsService";

export class ProcessRecordsStore {
  processRecordsDatasource: StateFullData<Page<ProcessRecordsRow>> = {}
  processRecordsSelectDatasource: StateFullData<Page<ProcessRecordsRow>> = {}
  processRecordsRow?: ProcessRecordsRow
  processRecordsOption?: string
  processRecordsDetail: StateFullData<ResponseEntity<ProcessRecordsRow>> = {}
  processRecordsDetails: StateFullData<ResponsesEntity<ProcessRecordsRow>> = {}
  processRecordsReq: ProcessRecordsReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateProcessRecordsRow(row: ProcessRecordsRow) {
    this.processRecordsRow = {...row}
  }

  updateProcessRecordsOption(option: string) {
    this.processRecordsOption = option
  }

  getProcessRecords(req: ProcessRecordsReq) {
    this.processRecordsDatasource = {...this.processRecordsDatasource, loading: true}
    this.processRecordsReq = {...this.processRecordsReq, ...req}
    ProcessRecordsService.getProcessRecords(req).then(rsp => {
      runInAction(() => this.processRecordsDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.processRecordsDatasource = {...this.processRecordsDatasource, loading: false, err})
    })
  }

  getSelectProcessRecords(req: ProcessRecordsReq) {
    this.processRecordsSelectDatasource = {...this.processRecordsSelectDatasource, loading: true}
    ProcessRecordsService.getProcessRecords(req).then(rsp => {
      runInAction(() => this.processRecordsSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.processRecordsSelectDatasource = {
        ...this.processRecordsSelectDatasource,
        loading: false,
        err
      })
    })
  }

  getDetailById(id: string) {
    this.processRecordsDetail = {...this.processRecordsDetail, loading: true}
    ProcessRecordsService.getOne(id).then(rsp => {
      runInAction(() => this.processRecordsDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.processRecordsDetail = {...this.processRecordsDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.processRecordsDetails = {...this.processRecordsDetails, loading: true}
    ProcessRecordsService.getBatch(ids).then(rsp => {
      runInAction(() => this.processRecordsDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.processRecordsDetails = {...this.processRecordsDetails, loading: false, err})
    })
  }

}

export const processRecordsStore = new ProcessRecordsStore();
