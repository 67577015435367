import {makeAutoObservable, runInAction} from "mobx";
import {Salary, TodoChecked, WorkerRecruitmentReq, WorkerRecruitmentRow} from "@services/dto/workerRecruitment";
import {Page, StateFullData} from "@services/dto/common";
import {WorkerRecruitmentService} from "@services/WorkerRecruitmentService";
import {User} from "@services/dto/security";

export class WRStore {
  wr: StateFullData<WorkerRecruitmentRow> = {};
  workerRecruitmentDataSource: StateFullData<Page<WorkerRecruitmentRow>> = {}
  workerRecruitmentSelectDatasource: StateFullData<Page<WorkerRecruitmentRow>> = {}
  workerRecruitmentRow?: WorkerRecruitmentRow
  workerRecruitmentOption?: string
  workerRecruitmentDetail: StateFullData<WorkerRecruitmentRow> = {}
  workerRecruitmentDetails: StateFullData<WorkerRecruitmentRow[]> = {}
  workerRecruitmentReq: WorkerRecruitmentReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateHireTodo(checked: TodoChecked[]) {
    if (!this.wr.data) return;
    this.wr = {...this.wr, data: {...this.wr.data, hireTodoChecked: checked}}
  }

  updateExitTodo(checked: TodoChecked[]) {
    if (!this.wr.data) return;
    this.wr = {...this.wr, data: {...this.wr.data, exitTodoChecked: checked}}
  }

  updateSalaries(salaries: Salary[]) {
    if (!this.wr.data) return;
    this.wr = {...this.wr, data: {...this.wr.data, salaries}}
  }

  updateUser(user: User) {
    if (!this.wr.data) return;
    this.wr = {...this.wr, data: {...this.wr.data, user}}
  }

  updateRemark(remark?: string) {
    if (!this.wr.data) return;
    this.wr = {...this.wr, data: {...this.wr.data, remark}}
  }

  updateWR(data?: WorkerRecruitmentRow) {
    this.wr = {...this.wr, data: data}
  }

  refreshWorkerRecruitment(onBoardingUserId: string) {
    this.wr = {...this.wr, loading: true}
    WorkerRecruitmentService.getWorkerRecruitmentById(onBoardingUserId).then(rsp => {
      runInAction(() => this.wr = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.wr = {...this.wr, err: err, loading: false})
    })
  }

  getWorkerRecruitments(req: WorkerRecruitmentReq) {
    this.workerRecruitmentDataSource = {...this.workerRecruitmentDataSource, loading: true}
    this.workerRecruitmentReq = {...this.workerRecruitmentReq, ...req}
    WorkerRecruitmentService.getWorkerRecruitments(req).then(rsp => {
      runInAction(() => this.workerRecruitmentDataSource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.workerRecruitmentDataSource = {...this.workerRecruitmentDataSource, err: err, loading: false})
    })
  }

  postWorkerRecruitments(req: WorkerRecruitmentReq) {
    this.workerRecruitmentDataSource = {...this.workerRecruitmentDataSource, loading: true}
    this.workerRecruitmentReq = {...this.workerRecruitmentReq, ...req}
    WorkerRecruitmentService.postWorkerRecruitments(req).then(rsp => {
      runInAction(() => this.workerRecruitmentDataSource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.workerRecruitmentDataSource = {...this.workerRecruitmentDataSource, err: err, loading: false})
    })
  }

  getSelectWorkerRecruitments(req: WorkerRecruitmentReq) {
    this.workerRecruitmentSelectDatasource = {...this.workerRecruitmentSelectDatasource, loading: true}
    WorkerRecruitmentService.getWorkerRecruitments(req).then(rsp => {
      runInAction(() => this.workerRecruitmentSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.workerRecruitmentSelectDatasource = {...this.workerRecruitmentSelectDatasource, err: err, loading: false})
    })
  }

  postSelectWorkerRecruitments(req: WorkerRecruitmentReq) {
    this.workerRecruitmentSelectDatasource = {...this.workerRecruitmentSelectDatasource, loading: true}
    WorkerRecruitmentService.postWorkerRecruitments(req).then(rsp => {
      runInAction(() => this.workerRecruitmentSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.workerRecruitmentSelectDatasource = {...this.workerRecruitmentSelectDatasource, err: err, loading: false})
    })
  }

  getBatch(ids: string[]) {
    this.workerRecruitmentDetails = {...this.workerRecruitmentDetails, loading: true}
    WorkerRecruitmentService.getBatch(ids).then((rsp: any) => {
      runInAction(() => this.workerRecruitmentDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.workerRecruitmentDetails = {...this.workerRecruitmentDetails, loading: false, err})
    })
  }
}

export const wrStore = new WRStore();
