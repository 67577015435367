import './index.less'
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {Divider, Pagination, Select, Space} from "antd";
import React, {useEffect, useState} from "react";
import {Any} from "@common/types";
import {CostExpendReq, CostExpendRow} from "@services/dto/costExpend";
import {BLACK_LIST} from "@/config";

export interface PropsType {
  onChange?: (value: Any | null, options: CostExpendRow[]) => void
  value?: Any | null
  mode?: 'multiple' | 'tags'
  labelInValue?: boolean
  disabled?: boolean
  className?: string
  allowClear?: boolean
  placeholder?: string
  fieldNames?: { value: string, label: string }
}

export const SelectCostExpend = observer((props: PropsType) => {
  const [value, setValue] = useState<Any | null>()
  const {costExpendStore} = allStores
  const [dataSource, setDataSource] = useState<CostExpendRow[]>([])
  const [total, setTotal] = useState<number>(0)
  const [items, setItems] = useState<CostExpendRow[]>([])
  const [searchReq, setSearchReq] = useState<CostExpendReq>({
    pageNum: 1,
    perPage: 10,
  })

  /**
   *  每个组件默认写法 只需修改 'efaApplyCode'
   */
  const defaultItems: any[] = [
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: 'N/A',
      [props.fieldNames ? props.fieldNames.label : 'efaApplyCode']: 'N/A'
    },
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: '空白',
      [props.fieldNames ? props.fieldNames.label : 'efaApplyCode']: '空白'
    },
  ]

  /**
   * 初始化
   */
  useEffect(() => {
    refreshData(searchReq)
  }, [])

  /**
   * 办理进度数据变化
   */
  useEffect(() => {
    if (costExpendStore.costExpendSelectDatasource.data) {
      setDataSource(costExpendStore.costExpendSelectDatasource.data.items || [])
      setTotal(costExpendStore.costExpendSelectDatasource.data.total || 0)
      if (costExpendStore.costExpendSelectDatasource.data.items?.length) {
        setItems(costExpendStore.costExpendDetails.data?.data
          .filter(item => !(costExpendStore.costExpendSelectDatasource.data.items || [])
            .some(o => o.id === item.id)) || [])
      }
    }
  }, [costExpendStore.costExpendDetails, costExpendStore.costExpendSelectDatasource.data])

  /**
   * 值发生改变
   */
  useEffect(() => {
    setValue(props.value)
    if (typeof props.value === 'string' && !BLACK_LIST.includes(props.value)) {
      costExpendStore.getBatch([props.value])
    } else if (typeof props.value === 'object') {
      const ids = props.value.filter((v: string) => !BLACK_LIST.includes(v))
      if (ids.length) {
        costExpendStore.getBatch(ids)
      }
    }
  }, [props.value])

  const refreshData = (req: CostExpendReq) => {
    costExpendStore.getSelectCostExpend(req)
  }

  const onChange = (value: Any | null) => {
    setValue(value)
    if (props.onChange) {
      props.onChange(value, [
        ...dataSource,
        ...items,
      ]);
    }
  }

  const handleSearch = (inputValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = inputValue
    setSearchReq(req)
    refreshData(req)
    return true
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    const req = {...searchReq}
    req.pageNum = page
    req.perPage = pageSize
    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      {
        <Select
          allowClear={props.allowClear}
          placeholder={props.placeholder || "请选择费用支出"}
          className={`${props.className} width-100-percentage`}
          dropdownRender={(menu) => (
            <>
              { menu }
              <Divider className={"divider-margin"}/>
              <Space>
                <Pagination
                  total={total}
                  pageSize={searchReq.perPage}
                  size={"small"}
                  onChange={handlePaginationChange}/>
              </Space>
            </>
          )}
          value={value}
          onChange={onChange}
          showSearch
          onClear={() => handleSearch('')}
          filterOption={false}
          onSearch={handleSearch}
          disabled={props.disabled}
          mode={props.mode}
          loading={costExpendStore.costExpendSelectDatasource.loading}
          labelInValue={props.labelInValue}
          fieldNames={{value: "id", label: "efaApplyCode"}}
          options={[
            ...defaultItems,
            ...dataSource,
            ...items,
          ]}/>
      }
    </>
  )
})
