import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {CostExpendRow} from "@services/dto/costExpend";
import dayjs from "dayjs";
import {SelectDict} from "@components/SelectDict";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCostApplication} from "@components/SelectCostApplication";
import {SelectCostReimbursement} from "@components/SelectCostReimbursement";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {allStores} from "@/stores";
import {ProjectRow} from "@services/dto/project";
import {SelectInvoiceRegister} from "@components/SelectInvoiceRegister";
import {InvoiceRegisterRow} from "@services/dto/invoiceRegister";
import {SelectFiling} from "@components/SelectFiling";
import {CostApplicationRow} from "@services/dto/costApplication";
import {BLACK_LIST, FORM_DISABLED_STATE} from "@/config";
import {SelectArchives} from "@components/SelectArchives";
import {SelectProcessStatus} from "@components/SelectProcessStatus";

export interface PropsType {
  row: CostExpendRow
  isView?: boolean
  isBatchUpdate?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()

  useEffect(() => {
    form.resetFields()
    const costApplicationRow = {...props.row}
    filingStore.setFilingFaCode(costApplicationRow.efaApplyCode)
    if (typeof costApplicationRow.efpPayTime === 'string' && costApplicationRow.efpPayTime !== '') {
      costApplicationRow.efpPayTime = dayjs(costApplicationRow.efpPayTime)
    }
    if (!costApplicationRow.efaMaterialWarehousing) {
      costApplicationRow.efaMaterialWarehousing = 'N/A'
    }
    if (typeof costApplicationRow.efpPayDate === 'string' && costApplicationRow.efpPayDate !== '') {
      costApplicationRow.efpPayDate = dayjs(costApplicationRow.efpPayDate);
    }
    if (!costApplicationRow.efaInvoiceRegistration) {
      costApplicationRow.efaInvoiceRegistration = 'N/A'
    }
    if (typeof costApplicationRow.cooperationUnit === 'string') {
      costApplicationRow.cooperationUnit = costApplicationRow.cooperationUnit !== '' ? costApplicationRow.cooperationUnit.split(',') : []
    }
    setResponsibilityProject(costApplicationRow.responsibilityProject);
    setCooperationUnit(costApplicationRow.cooperationUnit)
    form.setFieldsValue(costApplicationRow);
  }, [props.row.id])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    setResponsibilityProject(value)
    const row = options.find(o => value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  const addSelectInvoiceRegister = (invoiceRegister?: InvoiceRegisterRow) => {
    form.setFieldsValue({
      efaInvoiceRegistration: invoiceRegister?.id,
      efaInvoiceRegistrationCode: invoiceRegister?.irInvoiceCode2,
      efaInvoiceRegistrationMoney: invoiceRegister?.irAmount,
    })
  }

  // 关联费用申请
  const handleCostApplicationChange = (value: string, options: CostApplicationRow[]) => {
    const row = options.find(o => !BLACK_LIST.includes(value) && value === String(o.id))
    if (row) {
      const cooperationUnit = typeof row.cooperationUnit === 'string' ? row.cooperationUnit?.split(",") : row.cooperationUnit
      form.setFieldsValue({
        responsibilityProject: row.responsibilityProject,
        code: row.code,
        cooperationUnit: cooperationUnit
      })
      setResponsibilityProject(row.responsibilityProject)
      setCooperationUnit(row.cooperationUnit)
    } else {
      form.setFieldsValue({
        responsibilityProject: undefined,
        code: undefined,
        cooperationUnit: undefined
      })
      setResponsibilityProject(undefined)
      setCooperationUnit(undefined)
    }
  }

  const handleEfpAmount = (value: number) => {
    const efpApprovedAmount = form.getFieldValue("efpApprovedAmount")
    reasonAmountSetEfpAuditOpinion(value, efpApprovedAmount)
  }

  const handleEfpApprovedAmount = (value: number) => {
    const efpAmount = form.getFieldValue("efpAmount")
    reasonAmountSetEfpAuditOpinion(efpAmount, value)
  }

  const handleEfpAuditOpinion = (value: string) => {
    const efpAmount = form.getFieldValue("efpAmount")
    const efpApprovedAmount = form.getFieldValue("efpApprovedAmount")
    reasonAmountSetEfpAuditOpinion(efpAmount, efpApprovedAmount, value)
  }

  const reasonAmountSetEfpAuditOpinion = (efpAmount: number, efpApprovedAmount: number, efpAuditOpinion?: string) => {
    if (efpAmount && efpApprovedAmount) {
      if (efpAmount === efpApprovedAmount) {
        form.setFieldsValue({
          efpAuditOpinion: efpAuditOpinion ? efpAuditOpinion : '核准',
        });
      } else if (efpApprovedAmount > efpAmount) {
        form.setFieldsValue({
          efpAuditOpinion: efpAuditOpinion ? efpAuditOpinion : '核增',
        });
      } else {
        form.setFieldsValue({
          efpAuditOpinion: efpAuditOpinion ? efpAuditOpinion : '核减',
        });
      }
    } else {
      form.setFieldsValue({
        efpAuditOpinion: efpAuditOpinion,
      });
    }
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      initialValues={{
        cooperationUnit: ['N/A'],
      }}
      disabled={props.isView || FORM_DISABLED_STATE.includes(props.row.efpStatus)}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="baNick1">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="baNick2">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="efaInvoiceRegistrationCode">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="organizersUserName">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="efpPayTime"
            rules={[{required: false, message: '请选择制单日期!'}]}>
            <DatePicker disabled placeholder={"请选择制单日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="efaApplyCode"
            rules={[{required: false, message: '请输入制单编号!'}]}>
            <Input disabled placeholder="请输入制单编号"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="费用类别"
            name="efpPayType"
            rules={[{required: false, message: '请选择费用类别!'}]}>
            <SelectDict defaultItems={[]} code={'payment_typee'} placeholder={'请选择费用类别'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="费用摘要"
            name="efpPayDesc"
            rules={[{required: false, message: '请输入费用摘要!'}]}>
            <Input.TextArea placeholder="请输入费用摘要" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="支出金额"
            name="efpAmount"
            rules={[{required: false, message: '请输入支出金额!'}]}>
            <InputNumber
              min={0}
              precision={2}
              addonAfter={'元'}
              onChange={handleEfpAmount}
              placeholder="请输入支出金额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款日期"
            name="efpPayDate"
            rules={[{required: false, message: '请选择付款日期!'}]}>
            <DatePicker
              placeholder={"请选择付款日期"}
              showTime
              allowClear
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款方"
            name="efpPaymentAccount"
            rules={[{required: false, message: '请输入付款方!'}]}>
            <SelectArchives
              onChange={(v, options) => form.setFieldsValue({baNick1: options.find(o => o._id === v)?.user?.name})}
              placeholder={'请选择付款方'}
              className={'width-100-percentage'}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款方"
            name="baNick2"
            rules={[{required: false, message: '请输入收款方!'}]}>
            <Input.TextArea placeholder="请输入收款方" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联费用申请"
            name="efpEmployeeFeeApplicationId"
            rules={[{required: !props.isBatchUpdate, message: '请选择关联费用申请!'}]}>
            <SelectCostApplication
              onChange={handleCostApplicationChange}
              placeholder={'请选择关联费用申请'}
              className={'width-100-percentage'}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联发票登记"
            name="efaInvoiceRegistration"
            rules={[{required: false, message: '请输入关联发票登记!'}]}>
            <SelectInvoiceRegister
              placeholder={'请选择关联发票登记'}
              isShowAction
              isHiddenOption
              fieldNames={{label: 'irInvoiceCode2', value: 'id'}}
              isView={props.isView}
              addSelectInvoiceRegister={addSelectInvoiceRegister}
              className={'width-100-percentage'}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联发票金额"
            name="efaInvoiceRegistrationMoney"
            rules={[{required: false, message: '请输入关联发票金额!'}]}>
            <InputNumber
              precision={2}
              disabled
              addonAfter={'元'}
              placeholder="请输入关联发票金额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联物资入库"
            name="efaMaterialWarehousing"
            rules={[{required: false, message: '请输入关联物资入库!'}]}>
            <Input disabled placeholder="请输入关联物资入库"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联费用报销"
            name="efaEmployeeReimbursementIds"
            rules={[{required: false, message: '请选择关联费用报销!'}]}>
            <SelectCostReimbursement
              disabled
              mode={"tags"}
              className={'width-100-percentage'}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: false, message: '请选择责任部门!'}]}>
            <SelectProject
              disabled
              placeholder={"请选择责任部门"} allowClear onChange={handleProjectChange}
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              allowClear
              mode={'tags'}
              disabled
              onChange={(value) => setCooperationUnit(value)}
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="存档批号"
            name="efpFileArchive"
            rules={[{required: false, message: '请选择存档批号!'}]}>
            <SelectFiling
              isHidePlus
              isHideClose
              responsibilityProject={responsibilityProject}
              cooperationUnit={cooperationUnit}
              placeholder={"请选择存档批号"}
              inputDisabled
              fieldNames={{value: "id", label: "faBatchNumber"}}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="审核意见"
            name="efpAuditOpinion"
            rules={[{required: false, message: '请选择审核意见',}]}>
            <SelectDict
              className={'width-100-percentage'}
              allowClear
              onChange={handleEfpAuditOpinion}
              code={'dict_efp_audit_opinion'}
              placeholder={'请选择审核意见'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="核定金额"
            name="efpApprovedAmount"
            rules={[{required: false, message: '请输入核定金额!'}]}>
            <InputNumber
              min={0}
              precision={2}
              addonAfter={'元'}
              onChange={handleEfpApprovedAmount}
              placeholder="请输入核定金额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="经办记录"
            name="personResponsible"
            rules={[{required: false, message: '请选择经办记录!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择经办记录"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="主办人员"
            name="organizersUserId"
            rules={[{required: false, message: '请选择主办人员!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择主办人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="efpStatus"
            rules={[{required: false, message: '请选择办理进度',}]}>
            <SelectProcessStatus
              allowClear
              placeholder={"请选择办理进度"}
              disabled/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
