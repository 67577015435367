import React, {FC, useState} from "react";
import {Alert, Button, Col, Form, Image, Input, message, Row} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom'
import {allStores} from "@/stores";
import {getPathByRoles, showErr} from "@common/utils";
import {LoginReq} from "@services/dto/security";
import {SecurityService} from "@services/SecurityService";
import {COPY_RIGHT, VERSION_NUMBER} from "@/config";
import loginIcon from "@assets/images/login-icon.png"
import '../Styles/Login.less'
import {RoleManageService} from "@services/RoleManageService";

export const Login: FC = () => {
  const [loading, setLoading] = useState(false)
  const [loginErr, setLoginErr] = useState("")
  let navigate = useNavigate()

  const {securityStore} = allStores;

  const onFinish = (values: LoginReq) => {
    // 显示loading
    setLoading(true)
    // 这里调用 login service
    SecurityService.login(values).then((res) => {
      securityStore.onLoginFinished({
        user: res.user,
        token: res.token,
        permissions: res.permissions,
        userCooperationUnits: res.userCooperationUnits,
        userProjects: res.userProjects
      }).then(() => {
        RoleManageService.getRoleAndMenu().then(rsp => {
          if (rsp.code === 200) {
            localStorage.setItem('role-and-menus', JSON.stringify(rsp.data));
            navigate(getPathByRoles(res.user))
          } else {
            message.warning(rsp.message).then()
          }
        })
      }).catch(showErr)
    }).catch((e) => {
      setLoginErr(e.error.message)
    }).finally(() => {
      setTimeout(() => {
        setLoading(false)
      }, 3000)
    })
  };

  const handleChange = () => {
    setLoginErr("")
  };

  return (
    <>
      <div className="login-container">
        <Row>
          <Col span={13} className={"left-container"}>
            <Image width={200} src={loginIcon} preview={false} className={"image-style"}/>
          </Col>
          <Col span={11} className={"right-container"}>
            <div className="login-content">
              <Row>
                <Col span={24}>
                  <div className={"login-title"}>欢迎登录</div>
                </Col>
                <Col span={24}>
                  <div className={"system-title"}>数字化集成管理信息系统</div>
                </Col>
              </Row>
              {
                loginErr &&
                <Row>
                  <Col xl={10} offset={7}>
                    <Form.Item labelCol={{span: 0}} wrapperCol={{span: 24}}>
                      <Alert message={loginErr} type="error" showIcon/>
                    </Form.Item>
                  </Col>
                </Row>
              }
              <Form
                name="basic"
                wrapperCol={{span: 24}}
                onFinish={onFinish}
                autoComplete="off">
                <Row>
                  <Col span={10} offset={7}>
                    <Form.Item
                      name="account"
                      rules={[{required: true, message: '请输入你的用户名!'}]}>
                      <Input
                        size={"large"} placeholder="请输入用户名" prefix={<UserOutlined/>} allowClear
                        onChange={handleChange}/>
                    </Form.Item>
                  </Col>
                  <Col span={10} offset={7}>
                    <Form.Item
                      name="password"
                      rules={[{required: true, message: '请输入你的密码!'}]}>
                      <Input.Password
                        size={"large"} placeholder="请输入密码" prefix={<LockOutlined/>} allowClear
                        onChange={handleChange}/>
                    </Form.Item>
                  </Col>
                  <Col span={10} offset={7}>
                    <Form.Item>
                      <Button
                        size={'large'} type="primary" htmlType="submit" className="login-button"
                        loading={loading}>
                        登录
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>

            <div className={"footer-container"}>
              <div className={"version-info"}>版本号：{ VERSION_NUMBER }</div>
              <div className={"company-info"}>{ COPY_RIGHT }</div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}
