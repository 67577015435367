import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Button, Checkbox, Col, Divider, Input, message, Modal, Popover, Radio, Row} from "antd";
import {
  CheckSquareOutlined,
  ClearOutlined,
  RedoOutlined,
  SaveOutlined,
  SearchOutlined,
  UnorderedListOutlined
} from "@ant-design/icons";
import {ColumnsType} from "antd/es/table";
import {allStores} from "@/stores";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {useLocation} from "react-router-dom";
import {useModalSelect} from "@modules/InvoiceRegister/Context/ModalSelectContext";
import {arraysContainSameElements} from "@common/utils";
import {HeaderOptions} from "@components/HeaderOptions";

export interface PropsType {
  collapsed?: boolean, // 是否展开
  setCollapsed?: (v: boolean) => void, // 展开事件
  searchReq?: any, // 请求参数
  handleSearchReqReset?: () => void // 重置请求参数
  isTable?: boolean, // 是否启用气泡布局
  selectedRowKeys?: React.Key[], // 所选行
  handleDeleteBatch?: () => void // 删除事件
  handleKeywordSearch?: (value: string) => void // 关键词搜索事件
  handleKeywordClear?: () => void // 关键词清空事件
  handleAdd?: () => void // 添加新纪录事件
  handleEdit?: () => void // 编辑纪录事件
  handleTableColumnsChange?: (value: string[]) => void // 字段列改变事件
  collapsedChildren?: React.ReactNode[], // 收起下的布局
  tableColumns?: ColumnsType, // 全部字段
  showColumn?: string[], // 需要显示的字段 - 只读作用于初始化值
  handleShowColumnReset?: () => void, // 重置列操作
  isShowKeyword?: boolean
  isHiddenOptions?: boolean
  items?: React.ReactNode[]
  size?: number // 一个控件占多少
  columnWidth?: number // 字体长度
  columnLen?: number // 字体长度
  locationPathname?: string // 路由
  updateCount?: number, // 更新渲染标志
}

export const HeaderAction: FC<PropsType> = observer((props: PropsType) => {

  const location = useLocation()
  const {screenResolutionStore} = allStores
  const [columnsPopoverShow, setColumnsPopoverShow] = useState<boolean>(false)
  const [size, setSize] = useState<number>(4)
  const [optionSize, setOptionSize] = useState<number>(4)
  const [maxQueryCount, setMaxQueryCount] = useState<number>(0)
  const [isShowCollapsedButton, setIsShowCollapsedButton] = useState<boolean>(false)
  const [showColumn, setShowColumn] = useState<string[]>([])
  const [keyword, setKeyword] = useState<string>('')
  const [scheme, setScheme] = useState<number>(-1)
  const [schemeList, setSchemeList] = useState<string[][]>([])
  const [isToggle, setIsToggle] = useState<boolean>(false)

  const isModalSelect = useModalSelect()

  /**
   * 默认办理进度
   * 1. 获取查询控件个数
   * 2. 通过 屏幕高度 判断 size
   * 3. 通过 单行最大尺寸 判断 显示 【展开/收起】 或 【新增】
   */
  useEffect(() => {
    // 查询控件个数
    const len: number = props.collapsedChildren?.length || 0
    // 一个控件所占的尺寸
    const size: number = props.size ? props.size : (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) ? 6 : 4
    // 静态控件所占的个数
    const staticCount: number = props.isShowKeyword ? 0 : 1
    // 操作控件所占的个数
    const optionCount: number = 1
    // 所有控件累加的总尺寸
    const totalSize = (staticCount + optionCount + len) * size
    // 单行最宽的尺寸
    const maxSingleRowSize: number = 24
    // 第一行最多放下的查询控件的个数
    const maxQueryCount: number = maxSingleRowSize / size - (staticCount + optionCount)
    // (查询控件个数)与(第一行最多放下的查询控件的个数)的差值
    const differenceQueryCount: number = maxQueryCount - len < 0 ? 0 : maxQueryCount - len
    // 需要补充的给(操作控件)的尺寸
    const differenceQuerySize: number = differenceQueryCount * size
    // 操作控件的尺寸 = 自身尺寸 + 需要补充的给(操作控件)的尺寸
    const optionSize: number = size + differenceQuerySize
    // 本地缓存的方案
    const list: string[][] = JSON.parse(localStorage.getItem(props.locationPathname || location.pathname)) || []
    // 关键词的值
    const keyword = props.searchReq?.keyword || undefined
    setMaxQueryCount(maxQueryCount)
    setSize(size)
    setOptionSize(optionSize)
    setIsShowCollapsedButton(totalSize > maxSingleRowSize)
    setSchemeList(list)
    setKeyword(keyword)
  }, [])

  /**
   * 注意事项
   * 1. 侦听 screenResolutionStore.screenResolution.height 和 collapsed
   * 2. 求出 size
   * 3. 求出 optionSize
   */
  useEffect(() => {
    // 查询控件个数
    const len: number = props.collapsedChildren?.length || 0
    // 一个控件所占的尺寸
    const _size: number = props.size ? props.size : (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) ? 6 : 4
    // 静态控件所占的个数
    const staticCount: number = props.isShowKeyword ? 0 : 1
    // 操作控件所占的个数
    const optionCount: number = 1
    // 所有控件累加的总尺寸
    const totalSize: number = (staticCount + optionCount + len) * _size
    // 单行最宽的尺寸
    const maxSingleRowSize: number = 24
    // 第一行最多放下的查询控件的个数
    const _maxQueryCount: number = maxSingleRowSize / _size - (staticCount + optionCount)
    // 是否显示展开/收起按钮
    const _isShowCollapsedButton = totalSize > maxSingleRowSize

    /**
     * collapsed 展开/收起
     * 1. 值为true 展开
     * 2. 值为false 展开
     */
    if (props.collapsed) {
      // 除操作控件外的所有控件累加的总尺寸
      const exceptOptionTotalSize: number = (staticCount + len) * _size
      // (除操作控件外的所有控件累加的总尺寸)与(单行最宽的尺寸)的余数
      const remainderExceptOptionTotalSize: number = exceptOptionTotalSize % maxSingleRowSize
      // 操作控件的尺寸 = 单行最宽的尺寸 - (除操作控件外的所有控件累加的总尺寸)与(单行最宽的尺寸)的余数
      const _optionSize = 24 - remainderExceptOptionTotalSize
      if (_size !== size) setSize(_size)
      if (_optionSize !== optionSize) setOptionSize(_optionSize)
      setMaxQueryCount(_maxQueryCount)
      setIsShowCollapsedButton(_isShowCollapsedButton)
    } else {
      // (查询控件个数)与(第一行最多放下的查询控件的个数)的差值
      const differenceQueryCount: number = _maxQueryCount - len < 0 ? 0 : _maxQueryCount - len
      // 需要补充的给(操作控件)的尺寸
      const differenceQuerySize: number = differenceQueryCount * _size
      // 操作控件的尺寸 = 自身尺寸 + 需要补充的给(操作控件)的尺寸
      const _optionSize: number = _size + differenceQuerySize
      if (_size !== size) setSize(_size)
      if (_optionSize !== optionSize) setOptionSize(_optionSize)
      setMaxQueryCount(_maxQueryCount)
      setIsShowCollapsedButton(_isShowCollapsedButton)
    }
  }, [screenResolutionStore.screenResolution, props.collapsed])

  useEffect(() => {
    if (props.showColumn) {
      setShowColumn(props.showColumn)
      setIsToggle(arraysContainSameElements(props.showColumn as string[], props.tableColumns.map(o=>o.key as string)))
    }
  }, [props.showColumn]);

  useEffect(() => {
    setKeyword(props.searchReq?.keyword || undefined)
  }, [props.searchReq]);

  const handleCollapsed = () => {
    props.setCollapsed && props.setCollapsed(!props.collapsed)
  }

  const handleReset = () => {
    setScheme(-1)
    if (props.handleShowColumnReset) {
      props.handleShowColumnReset()
    }
  }

  const handleToggle = () => {
    let columns: string[]
    if(isToggle){
      columns = []
    }else{
      columns = props.tableColumns?.map(o => o.key as string)
    }
    if(props.handleTableColumnsChange){
      props.handleTableColumnsChange(columns)
    }
  }

  const handleSave = () => {
    const con = schemeList.some(valArr => valArr.sort().join('') === showColumn.sort().join(''))
    if (con) {
      message.warning("方案已经存在！").then()
    } else {
      Modal.info({
        title: `温馨提示`,
        content: <>
          <div>{ `是否新增-方案${schemeList.length + 1}` }</div>
          <div>右键点击方案即可删除</div>
        </>,
        okText: "确定",
        cancelText: "取消",
        onOk() {
          setSchemeList([
            ...schemeList,
            showColumn
          ])
          localStorage.setItem(props.locationPathname || location.pathname, JSON.stringify([
            ...schemeList,
            showColumn
          ]))
        },
      })
    }
  }

  const handleCheckboxChange = (e: CheckboxChangeEvent, key: string) => {
    let _showColumn = showColumn
    if (e.target.checked) {
      _showColumn = props.tableColumns.filter(o => [..._showColumn, key].includes(String(o.key))).map(o => o.key as string)
    } else {
      _showColumn = _showColumn.filter(_key => _key !== key)
    }
    setShowColumn(_showColumn)
    if (props.handleTableColumnsChange) {
      props.handleTableColumnsChange(_showColumn)
    }
  }

  const handleRadioChange = (index: number) => {
    setScheme(index)
    if (index !== -1) {
      setShowColumn(schemeList[index])
      if (props.handleTableColumnsChange) {
        props.handleTableColumnsChange(schemeList[index])
      }
    }
  }

  const handleRadioDel = (index: number) => {
    Modal.confirm({
      title: `是否删除方案${index + 1}？`,
      okText: "确定",
      cancelText: "取消",
      onOk() {
        if (scheme === index) {
          handleReset()
        }
        const _schemeList = schemeList.filter((_, i) => i !== index)
        setSchemeList(_schemeList)
        localStorage.setItem(props.locationPathname || location.pathname, JSON.stringify(_schemeList))
      },
    })
  }

  const handleKeywordSearch = () => {
    if (props.handleKeywordSearch) {
      props.handleKeywordSearch(keyword)
    }
  }

  return (
    <Row gutter={[8, 5]} className={'header-action-row'}>

      {
        !props.isShowKeyword && <Col
          xl={size}
          md={size}
          xs={(size) * 2}>
          <Input
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
            onPressEnter={handleKeywordSearch}
            allowClear={{clearIcon: <ClearOutlined onClick={props.handleKeywordClear}/>}}
            placeholder="请搜索关键词"
            suffix={<SearchOutlined onClick={handleKeywordSearch}/>}
          />
        </Col>
      }

      {
        props.collapsedChildren?.map((node, index) => (
          <Col
            xl={size}
            md={size}
            className={!props.collapsed && index > maxQueryCount - 1 && 'd-none'}
            xs={(size) * 2}
            key={index}>
            { node }
          </Col>
        ))
      }

      <Col
        xl={optionSize}
        md={optionSize}
        xs={size * 2}
        className={"text-align-right"}>
        <Popover
          title={
            <>
              <div className={'d-flex df-jcsb df-aic padding-left-xs padding-right-xs'}>
                <span>选择需要显示/隐藏的字段</span>
                <div>
                  <CheckSquareOutlined style={{color: isToggle&&'#1890ff'}} className={'margin-right-xs'} onClick={() => handleToggle()}/>
                  {
                    scheme === -1 &&
                    <SaveOutlined style={{color: '#1890ff'}} className={'margin-right-xs'} onClick={() => handleSave()}/>
                  }
                  {
                    props.handleShowColumnReset &&
                    <RedoOutlined style={{color: '#1890ff'}} onClick={() => handleReset()}/>
                  }
                </div>
              </div>
            </>
          }
          trigger="click"
          open={columnsPopoverShow}
          placement="bottomRight"
          onOpenChange={(value) => {
            setColumnsPopoverShow(value)
          }}
          content={
            <>
              <Row style={{width: `${(props.columnWidth || 10) * 2}em`}} className={'width-100-percentage content-box'}>
                {
                  schemeList.map((_, index) => <Col
                    span={props.columnLen || 12}
                    key={index}>
                    <Button
                      onClick={() => handleRadioChange(index)}
                      type={"text"}
                      block
                      className={'text-align-left'}
                      onContextMenu={(e) => {
                        e.preventDefault()
                        handleRadioDel(index)
                      }}
                    >
                      <Radio checked={scheme === index}>
                        方案{ index + 1 }
                      </Radio>
                    </Button>
                  </Col>)
                }
                <Col span={props.columnLen || 12}>
                  <Button type={"text"} block className={'text-align-left'} onClick={() => {
                    handleRadioChange(-1)
                  }}>
                    <Radio checked={scheme === -1}>
                      自定义
                    </Radio>
                  </Button>
                </Col>
                <Divider dashed className={'margin-bottom-xs margin-top-xs'}/>
                {
                  props.tableColumns?.map(item => (
                    <Col span={props.columnLen || 12} key={item.key}>
                      <Button type={'text'} block className={'text-align-left'}>
                        <Checkbox
                          value={item.key}
                          disabled={scheme !== -1}
                          checked={showColumn.includes(String(item.key))}
                          onChange={(e) => {
                            handleCheckboxChange(e, String(item.key))
                          }}>
                          { String(item.title) }
                        </Checkbox>
                      </Button>
                    </Col>
                  ))
                }
              </Row>
            </>
          }
        >
          <Button
            type={'default'}
            className={'margin-right-xs'}
            onClick={() => {
              setColumnsPopoverShow(!columnsPopoverShow)
            }}>
            <UnorderedListOutlined/>
          </Button>
        </Popover>
        {
          isModalSelect ? <>
            <Button onClick={handleCollapsed} type={"primary"}>
              { props.collapsed ? '收起' : '展开' }
            </Button>
          </> :
            <HeaderOptions
              isHiddenOptions={props.isHiddenOptions}
              handleSearchReqReset={props.handleSearchReqReset}
              isQuery={true}
              selectedRowKeys={props.selectedRowKeys}
              type={'primary'}
              size={'middle'}
              handleDeleteBatch={props.handleDeleteBatch}
              handleAdd={props.handleAdd}
              handleEdit={props.handleEdit}
              items={props.items || []}
              onClick={isShowCollapsedButton && handleCollapsed}>
              { isShowCollapsedButton && (props.collapsed ? '收起' : '展开')  }
            </HeaderOptions>
        }
      </Col>

    </Row>
  )
})
