import React from "react";
import {MonthSalary} from "@modules/Compensation/Components/MonthSalary";

export const MonthPersonSalary = () => {

  return (
    <>
      <MonthSalary personnelCategory={"生产人员"} type={0}/>
    </>
  )
}