import './TabsView.less'
import {observer} from "mobx-react";
import {Tabs, TabsProps} from "antd";
import {useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";

type TargetKey = React.MouseEvent | React.KeyboardEvent | string;

export interface PropsType {
  tabsItems?: TabsProps['items']
  handleChange?: (key: string) => void
  add?: () => void
  remove?: (targetKey: TargetKey) => void
}

export const TabsView = observer((props: PropsType) => {
  const location = useLocation()
  const [activeKey, setActiveKey] = useState<string>()

  useEffect(() => {
    setActiveKey(location.pathname)
  }, [location.pathname])

  const handleChange = (key: string) => {
    if (props.handleChange) {
      props.handleChange(key);
    }
  }

  const onEdit = (targetKey: TargetKey, action: 'add' | 'remove') => {
    if (action === 'add') {
      if (props.add) {
        props.add();
      }
    } else {
      if (props.remove) {
        props.remove(targetKey);
      }
    }
  };

  return <Tabs
    size={'small'}
    hideAdd
    onChange={handleChange}
    activeKey={activeKey}
    type="editable-card"
    onEdit={onEdit}
    items={props.tabsItems}
  />
})
