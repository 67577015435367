import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import dayjs from "dayjs";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {allStores} from "@/stores";
import {ProjectRow} from "@services/dto/project";
import {SelectFiling} from "@components/SelectFiling";
import {FORM_DISABLED_STATE} from "@/config";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {FileDistributionRow} from "@services/dto/fileDistribution";

export interface PropsType {
  row: FileDistributionRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()

  useEffect(() => {
    form.resetFields()
    const fileDistributionRow = {...props.row}
    filingStore.setFilingFaCode(fileDistributionRow.fdCode)
    if (typeof fileDistributionRow.fdDate === 'string' && fileDistributionRow.fdDate !== '') {
      fileDistributionRow.fdDate = dayjs(fileDistributionRow.fdDate)
    }
    if (typeof fileDistributionRow.cooperationUnit === 'string') {
      fileDistributionRow.cooperationUnit = fileDistributionRow.cooperationUnit !== '' ? fileDistributionRow.cooperationUnit.split(',') : []
    }
    setResponsibilityProject(fileDistributionRow.responsibilityProject);
    setCooperationUnit(fileDistributionRow.cooperationUnit)
    form.setFieldsValue(fileDistributionRow);
  }, [props.row.id])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    setResponsibilityProject(value)
    const row = options.find(o => value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      initialValues={{
        cooperationUnit: ['N/A'],
      }}
      disabled={props.isView || FORM_DISABLED_STATE.includes(props.row.fdStatus)}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="organizersUserName">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="fdDate"
            rules={[{required: false, message: '请选择制单日期!'}]}>
            <DatePicker disabled placeholder={"请选择制单日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="fdCode"
            rules={[{required: false, message: '请输入制单编号!'}]}>
            <Input disabled placeholder="请输入制单编号"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="文件名称"
            name="fdName"
            rules={[{required: false, message: '请输入文件名称!'}]}>
            <Input placeholder="请输入文件名称"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="文件版本"
            name="fdVersion"
            rules={[{required: false, message: '请输入文件版本!'}]}>
            <Input placeholder="请输入文件版本"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="摘要"
            name="fdDesc"
            rules={[{required: false, message: '请输入摘要!'}]}>
            <Input.TextArea placeholder="请输入摘要" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: false, message: '请选择责任部门!'}]}>
            <SelectProject
              placeholder={"请选择责任部门"} allowClear onChange={handleProjectChange}
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              allowClear
              mode={'tags'}
              onChange={(value) => setCooperationUnit(value)}
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="存档批号"
            name="fdFile"
            rules={[{required: false, message: '请选择存档批号!'}]}>
            <SelectFiling
              isHidePlus
              isHideClose
              responsibilityProject={responsibilityProject}
              cooperationUnit={cooperationUnit}
              placeholder={"请选择存档批号"}
              inputDisabled
              fieldNames={{value: "id", label: "faBatchNumber"}}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="经办记录"
            name="personResponsible"
            rules={[{required: false, message: '请选择经办记录!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择经办记录"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="主办人员"
            name="organizersUserId"
            rules={[{required: false, message: '请选择主办人员!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择主办人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="fdStatus"
            rules={[{required: false, message: '请选择办理进度',}]}>
            <SelectProcessStatus
              allowClear
              placeholder={"请选择办理进度"}
              disabled/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
