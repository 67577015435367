import React, {useEffect, useState} from "react";
import {Checkbox, Col, Divider, Pagination, Row, Select, Space} from "antd";
import {Any} from "@common/types";
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {StatisticsItemsReq, StatisticsItemsRow} from "@services/dto/dict";
import {DictService} from "@services/DictService";
import {showErr} from "@common/utils";
import {WorkerRecruitmentReq, WorkerRecruitmentRow} from "@services/dto/workerRecruitment";
import {BLACK_LIST} from "@/config";

export interface PropsType {
  onChange?: (value: string[] | string, options: WorkerRecruitmentRow[]) => void
  value?: string[] | string
  mode?: 'multiple' | 'tags'
  labelInValue?: boolean
  disabled?: boolean
  className?: string
  allowClear?: boolean
  placeholder?: string
  fieldNames?: { value: string, label: string }
  isHiddenOption?: boolean
  isCustom?: boolean
  handleIsCustomChange?: (value: boolean) => void
  searchReq?: WorkerRecruitmentReq
  statisticsItemsReq?: StatisticsItemsReq
  updateCount?: number
  isOnlyUnbound?: boolean // 是否只查未绑定
}

export const SelectArchives = observer((props: PropsType) => {
  const [value, setValue] = useState<Any | null>()
  const {wrStore} = allStores
  const [datasource, setDatasource] = useState<WorkerRecruitmentRow[]>([])
  const [total, setTotal] = useState(0)
  const [items, setItems] = useState<WorkerRecruitmentRow[]>([])
  const [statisticsItems, setStatisticsItems] = useState<StatisticsItemsRow[] | WorkerRecruitmentRow[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [searchReq, setSearchReq] = useState<WorkerRecruitmentReq>({
    pageNum: 1,
    perPage: 10,
    status: "hired"
  })

  /**
   *  每个组件默认写法 只需修改 'name'
   */
  const defaultItems: any[] = [
    {
      [props.fieldNames ? props.fieldNames.value : '_id']: 'N/A',
      [props.fieldNames ? props.fieldNames.label : 'name']: 'N/A'
    },
    {
      [props.fieldNames ? props.fieldNames.value : '_id']: '空白',
      [props.fieldNames ? props.fieldNames.label : 'name']: '空白'
    },
  ]

  /**
   * 初始化
   */
  useEffect(() => {
    if (props.statisticsItemsReq) {
      getStatisticsItems()
    } else {
      refreshData(searchReq)
    }
  }, [])

  /**
   * 更新统计
   */
  useEffect(() => {
    if (props.updateCount && props.statisticsItemsReq && props.value) {
      getStatisticsItems()
    }
  }, [props.updateCount]);

  /**
   * 办理进度数据变化
   */
  useEffect(() => {
    if (wrStore.workerRecruitmentSelectDatasource.data) {
      const res = wrStore.workerRecruitmentSelectDatasource.data.items || []
      res.forEach(item=>{
        item.name = item.user?.name
      })
      setDatasource(res)
      setTotal(wrStore.workerRecruitmentSelectDatasource.data.total || 0)
      if (wrStore.workerRecruitmentDetails?.data?.length) {
        setItems(wrStore.workerRecruitmentDetails?.data
          .filter(item => !(wrStore.workerRecruitmentSelectDatasource.data.items || [])
            .some(o => o.name === item.name)))
      }
    }
  }, [wrStore.workerRecruitmentDetails, wrStore.workerRecruitmentSelectDatasource.data])

  /**
   * 值发生改变
   */
  useEffect(() => {
    setValue(props.value)
    if (typeof props.value === 'string' && !BLACK_LIST.includes(props.value)) {
      wrStore.getBatch([props.value])
    } else if (typeof props.value === 'object') {
      const ids = props.value.filter((v: string) => !BLACK_LIST.includes(v))
      if (ids.length) {
        wrStore.getBatch(ids)
      }
    }
  }, [props.value])

  /**
   * 自定义查询
   */
  useEffect(() => {
    if (props.isCustom) {
      refreshData(searchReq)
    }
  }, [props.isCustom, props.searchReq])

  const getStatisticsItems = () => {
    setLoading(true)
    DictService.getStatisticsItems(props.statisticsItemsReq).then(rsp => {
      const row = JSON.parse(JSON.stringify(rsp.data || [])).map((item: StatisticsItemsRow | WorkerRecruitmentRow) => {
        (item as WorkerRecruitmentRow).name = `${(item as WorkerRecruitmentRow).name || (item as StatisticsItemsRow).value}(${(item as StatisticsItemsRow).coun})`
        return item
      })
      setStatisticsItems(row);
    }).catch(showErr).finally(() => {
      setLoading(false)
    })
  }

  const refreshData = (req: WorkerRecruitmentReq) => {
    if (props.isCustom) {
      wrStore.postSelectWorkerRecruitments({...req, ...props.searchReq})
    } else {
      wrStore.postSelectWorkerRecruitments(req)
    }
  }

  const onChange = (value: Any | null) => {
    setValue(value)
    if (props.onChange) {
      props.onChange(value, [
        ...datasource,
        ...items,
      ]);
    }
  }

  const handleSearch = (inputValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = inputValue
    setSearchReq(req)
    refreshData(req)
    return true
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    const req = {...searchReq}
    req.pageNum = page
    req.perPage = pageSize
    setSearchReq(req)
    refreshData(req)
  }

  const handleIsCustomChange = (value: boolean) => {
    if (props.handleIsCustomChange) {
      props.handleIsCustomChange(value)
    }
  }

  return (
    <>
      <Row>
        <Col span={props.isHiddenOption ? 16 : 24}>
          {
            props.statisticsItemsReq ? (
              <Select
                allowClear={props.allowClear}
                placeholder={props.placeholder || "请选择资金审批"}
                className={`${props.className} width-100-percentage`}
                value={value}
                onChange={onChange}
                showSearch
                optionFilterProp="children"
                filterOption={(input: string, option) =>
                  String(option?.name).toLowerCase().includes(input.toLowerCase())}
                disabled={props.disabled}
                mode={props.mode}
                loading={loading}
                labelInValue={props.labelInValue}
                fieldNames={props.fieldNames || {value: "_id", label: "name"}}
                options={statisticsItems as WorkerRecruitmentRow[]}/>
            ) : (
              <Select
                allowClear={props.allowClear}
                placeholder={props.placeholder || "请选择资金审批"}
                className={`${props.className} width-100-percentage`}
                dropdownRender={(menu) => (
                  <>
                    { menu }
                    <Divider className={"divider-margin"}/>
                    <Space>
                      <Pagination
                        total={total}
                        pageSize={searchReq.perPage}
                        size={"small"}
                        onChange={handlePaginationChange}/>
                    </Space>
                  </>
                )}
                value={value}
                onChange={onChange}
                showSearch
                onClear={() => handleSearch('')}
                disabled={props.disabled}
                mode={props.mode}
                filterOption={false}
                onSearch={handleSearch}
                loading={wrStore.workerRecruitmentSelectDatasource.loading}
                labelInValue={props.labelInValue}
                fieldNames={props.fieldNames || {value: "_id", label: "name"}}
                options={[
                  ...defaultItems,
                  ...datasource,
                  ...items,
                ]}/>
            )
          }
        </Col>
        <Col span={props.isHiddenOption ? 8 : 0} className={props.isHiddenOption ? "d-flex df-jcc df-aic" : ""}>
          <Checkbox checked={props.isCustom} onChange={(e) => handleIsCustomChange(e.target.checked)}>日期查询</Checkbox>
        </Col>
      </Row>
    </>
  )
})
