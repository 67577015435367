import React, { useEffect } from "react";
import { Col, DatePicker, Form, InputNumber, Row } from "antd";
import { FormInstance } from "antd/es";
import { observer } from "mobx-react";
import { BasicSalaryRow } from "@services/dto/basicSalary";
import { CascaderPerson } from "@/components/CascaderPerson";
import { PersonCascader } from "@/services/dto/talent";
import { SelectDict } from "@/components/SelectDict";
import { CustomUpload, normFile } from "@/components/CustomUpload";
import { getUploadStringToArray } from "@/common/utils";
import dayjs from "dayjs";

export interface PropsType {
  row: BasicSalaryRow
}

export const BasicSalaryBasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  const handlePersonChange = (value: string[], selectedOptions: PersonCascader[]) => {
    return value
  }

  useEffect(() => {
    form.resetFields()
    const row = { ...props.row }
    if (row.id) {
      row.effectiveDate = [dayjs(row.startTime), dayjs(row.endTime)]
      if (typeof row.userCode === 'string') {
        row.userCode = row.userCode.split(",")
      }
      if (typeof row.file === 'string') {
        row.file = getUploadStringToArray(row.file);
      }
      if (row.confirmTheDate) {
        row.confirmTheDate = dayjs(row.confirmTheDate);
      }
    }
    form.setFieldsValue(row);
  }, [props.row])


  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ status: 1, isVirtualUser: 0 }}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Form.Item
            label="员工"
            name="userCode"
            valuePropName={"person"}
            getValueFromEvent={handlePersonChange}
            rules={[{ required: !props.row.id, message: '请选择员工!' }]}>
            <CascaderPerson disabled={!!props.row.id} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="工资类型"
            name="wageTypes"
            rules={[{ required: true, message: '请选择工资类型', }]}>
            <SelectDict allowClear code={"dict_wage_types"} placeholder={"请选择工资类型"} className={'width-100-percentage'} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="工资标准"
            name="oldWage"
            rules={[{ required: true, message: '请输入工资标准!' }]}>
            <InputNumber placeholder="请输入工资标准" precision={0} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="有效日期"
            name="effectiveDate"
            rules={[{ required: true, message: '请选择确认日期!' }]}>
            <DatePicker.RangePicker className={'width-100-percentage'} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="确认日期"
            name="confirmTheDate"
            rules={[{ required: true, message: '请选择确认日期!' }]}>
            <DatePicker placeholder={"请选择确认日期"} className={'width-100-percentage'} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="附件"
            name="file"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            extra={"支持文件(pdf,doc,docx),10M以内,最大上传1个文件"}
            rules={[{ required: false, message: '请上传附件!' }]}>
            <CustomUpload updateSize={20}
              maxCount={1}
              uploadType={"application"}
              accept={"application/*"}
              listType={"picture"}
              multiple={false} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
