import {makeAutoObservable} from "mobx";

export interface HeaderSearchReq {
    //责任人
    personResponsible?: string
    //责任项目
    responsibilityProject?: string
    //责任部门
    cooperationUnit?: string
    // 责任企业
    companyId?: string
}

export class HeaderSearchStore {
  headerSearchReq: HeaderSearchReq = {}

  constructor() {
    makeAutoObservable(this)
  }

  updatePersonResponsible(personResponsible: string) {
    this.headerSearchReq = {...this.headerSearchReq, personResponsible}
  }

  updateResponsibilityProject(responsibilityProject: string) {
    this.headerSearchReq = {...this.headerSearchReq, responsibilityProject}
  }

  updateCooperationUnit(cooperationUnit: string) {
    this.headerSearchReq = {...this.headerSearchReq, cooperationUnit}
  }

  updateCompanyId(companyId: string) {
    this.headerSearchReq = {...this.headerSearchReq, companyId}
  }
}

export const headerSearchStore = new HeaderSearchStore()
