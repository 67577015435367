import {makeAutoObservable, runInAction} from "mobx";
import {Page, StateFullData} from "@services/dto/common";
import {TransactionStatisticsService} from "@services/TransactionStatisticsService";
import {TransactionStatisticsReq, TransactionStatisticsRow} from "@services/dto/transactionStatistics";

export class TransactionStatisticsStore {
  transactionStatisticsDatasource: StateFullData<Page<TransactionStatisticsRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getTransactionStatistics(req: TransactionStatisticsReq) {
    this.transactionStatisticsDatasource = {...this.transactionStatisticsDatasource, loading: true}
    TransactionStatisticsService.getTransactionStatistics(req).then(rsp => {
      runInAction(() => this.transactionStatisticsDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.transactionStatisticsDatasource = {...this.transactionStatisticsDatasource, loading: false, err})
    })
  }
}

export const transactionStatisticsStore = new TransactionStatisticsStore();
