import {Page, StateFullData} from "@services/dto/common";
import {SuppliesRow, PageParam} from "@services/dto/supplies";
import {makeAutoObservable, runInAction} from "mobx";
import {SuppliesService} from "@services/SuppliesService";

export class SuppliesStore {
  suppliesDatasource: StateFullData<Page<SuppliesRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getSupplies(req: PageParam) {
    this.suppliesDatasource = {...this.suppliesDatasource, loading: true}
    SuppliesService.getSupplies(req).then(rsp => {
      runInAction(() => {
        this.suppliesDatasource = {data: rsp, loading: false}})
    }).catch(e => {
      runInAction(() => this.suppliesDatasource = {...this.suppliesDatasource, loading: false, err: e})
    })
  }
}

export const suppliesStore = new SuppliesStore();
