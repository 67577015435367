import {observer} from "mobx-react";
import {ColumnsType} from "antd/es/table";
import {
  LARGER_MODAL_WIDTH,
  SMALL_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import './BankStatementModal.less'
import type {DescriptionsProps} from 'antd';
import {Button, FormInstance, message, Space, Table, Tag, Typography} from "antd";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import React, {useEffect, useState} from "react";
import {HeaderActionCom} from "@modules/BankStatement/Components/HeaderActionCom";
import {allStores} from "@/stores";
import {useLocation} from "react-router-dom";
import {BankStatementRow, BankStatementSummaryTotal} from "@services/dto/bankStatement";
import {accAdd} from "@common/utils";
import {CustomDraggableModal} from "@components/CustomDraggableModal";
import {TableFilingText} from "@components/TableFilingText";

export interface PropsType {
  dataSource?: BankStatementRow[]
  open?: boolean
  items?: DescriptionsProps['items']
  handleOk?: (rows: BankStatementRow[]) => void
  handleCancel?: () => void
  bankStatementModalFundApprovalId?: string
}

const narrowShowColumn: string[] = [
  "bsDate",
  "bsName",
  "bsSummary",
  "bsInAmount",
  "bsOutAmount",
  "bsAmount",
  // "bsOtherAccountName",
  // "bsAccount",
  "bsBalance",
  "bsStatus",
  "statusCommon",
  "action",
]

export const BankStatementModal = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const location = useLocation()
  const {bankStatementStore, tableColumnsStore} = allStores
  const [showColumn, setShowColumn] = useState<string[]>(narrowShowColumn)
  const [dataSource, setDataSource] = useState<BankStatementRow[]>([])
  const [renderColumns, setRenderColumns] = useState<ColumnsType<BankStatementRow>>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [summaryTotalObj, setSummaryTotalObj] = useState<BankStatementSummaryTotal>()
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    setDataSource(props.dataSource)
  }, [props.dataSource])

  useEffect(() => {
    setSelectedRowKeys([])
    setIsOpen(props.open)
  }, [props.open]);

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem('/bankStatement') || []
    if (!showColumn.length) return
    // 检验存储中的列表是否符合规范
    showColumn = showColumn.filter(o => columns.some(column => column.key === o))
    // 无论有无存储都触发change，保存当前显示的字段
    setShowColumn(showColumn)
  }, []);

  /**
   * 列配置变化
   */
  useEffect(() => {
    setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))))
  }, [showColumn])

  /**
   * @description 表格合计
   */
  useEffect(() => {
    const objInitValue = {bsInAmount: 0, bsOutAmount: 0, bsAmount: 0,}
    let obj = summaryTotalObj
    const arr = dataSource.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        bsInAmount: accAdd(acc.bsInAmount, cur.bsInAmount),
        bsOutAmount: accAdd(acc.bsOutAmount, cur.bsOutAmount),
        bsAmount: accAdd(acc.bsAmount, cur.bsAmount),
      }), objInitValue);
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [bankStatementStore.bankStatementDatasource, selectedRowKeys])

  const columns: ColumnsType<BankStatementRow> = [
    {
      title: '排序',
      dataIndex: 'sort',
      key: 'sort',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '出纳日期',
      dataIndex: 'bsDate',
      key: 'bsDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text?.slice(0, 10)}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '出纳编号',
      dataIndex: 'bsCode',
      key: 'bsCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '出纳交易类型',
      dataIndex: 'bsType',
      key: 'bsType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '款项摘要',
      dataIndex: 'bsSummary',
      key: 'bsSummary',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '出纳账户',
      dataIndex: 'bsName',
      key: 'bsName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '出纳账户类型',
      dataIndex: 'baType1',
      key: 'baType1',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '转入金额',
      dataIndex: 'bsInAmount',
      key: 'bsInAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '转出金额',
      dataIndex: 'bsOutAmount',
      key: 'bsOutAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '余额',
      dataIndex: 'bsAmount',
      key: 'bsAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '对方账户',
      dataIndex: 'bsOtherAccountName',
      key: 'bsOtherAccountName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联资金审批',
      dataIndex: 'fundingApprovalCode',
      key: 'fundingApprovalCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText copyValue={text} value={
            <Typography.Text
              type="success"
              className={'c-pointer'}>{ text }</Typography.Text>
          }></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '资金审批办理进度',
      dataIndex: 'fundingApprovalCodeStatus',
      key: 'fundingApprovalCodeStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (_, record) => (
        <Space>
          {
            record.fundingApprovalId.includes(',') ? <Tag color={'error'}>错误</Tag> : <Tag color={'blue'}>正常</Tag>
          }
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'bsFile',
      key: 'bsFile',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableFilingText id={record.bsFile} value={record.bsFileCode}></TableFilingText>
        </Space>
      )
    },
    {
      title: '经办记录',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'bsStatus',
      key: 'bsStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
  ];

  /**
   * 解绑按钮事件 是否反选
   * @param isReverse
   */
  const handleOk = (isReverse: boolean) => {
    const rows = dataSource.filter(o => isReverse ? (!selectedRowKeys.includes(o.id)) : (selectedRowKeys.includes(o.id)))
    if (!rows.length) {
      message.warning("请检查要解绑的数据！").then()
      return
    }
    props.handleOk && props.handleOk(rows)
  }

  /**
   * 取消按钮事件
   */
  const handleCancel = () => {
    props.handleCancel && props.handleCancel()
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    bankStatementStore.setCollapsed(collapsed)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(location.pathname, value)
  }

  const handleShowColumnReset = () => {
    setShowColumn(narrowShowColumn)
  }

  return (
    <CustomDraggableModal
      title={<div className="text-align-center">查看关联资金出纳</div>}
      open={isOpen}
      okText={'解绑'}
      footer={[
        <Button
          key="back"
          onClick={handleCancel}>
          取消
        </Button>,
        <Button
          key="1"
          type="primary"
          onClick={() => handleOk(false)}>
          解绑
        </Button>,
        <Button
          key="2"
          type="primary"
          onClick={() => handleOk(true)}
        >
          解绑反选
        </Button>
      ]}
      width={LARGER_MODAL_WIDTH}
      onCancel={handleCancel}>
      { /*{
        props.items && <Descriptions
          title=""
          items={props.items}/>
      }*/ }
      {
        props.items && <>
          <div className={'desc-box'}>
            {
              props.items.map(item => <div
                key={item.key}
                className={'desc-item'}>
                <span className={'label'}>{ item.label }</span>
                <span>:</span>
                <span className={'children'}>{ item.children }</span>
              </div>)
            }
          </div>
        </>
      }

      <HeaderActionCom
        locationPathname={'/bankStatement'}
        handleShowColumnReset={handleShowColumnReset}
        collapsed={bankStatementStore.collapsed}
        setCollapsed={handleCollapsedChange}
        size={6}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={columns}/>

      <Table
        className={"margin-top-xss"}
        rowKey={"id"}
        dataSource={dataSource}
        columns={renderColumns}
        size={"small"}
        bordered
        scroll={{y: SMALL_TABLE_SCROLL_HEIGHT, scrollToFirstRowOnChange: true}}
        rowSelection={{selectedRowKeys: selectedRowKeys, onChange: setSelectedRowKeys, columnWidth: 50}}
        pagination={false}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                (showColumn as string[]).map((key, index) =>
                  key.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={summaryTotalObj[key.slice(0, key.indexOf('-'))]}></TableMoneyText> 元
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </CustomDraggableModal>

  )
}))
