import {makeAutoObservable, runInAction} from "mobx";
import {Page, ResponsesEntity, StateFullData} from "@services/dto/common";
import {ProfessionSearchReq, ProjectAndTeam, ProjectPersonnel, ProjectPersonnelReq} from "@services/dto/project";
import {ProjectService} from "@services/ProjectService";

export class ProfessionStore {
  projectPersonnel: StateFullData<Page<ProjectPersonnel>> = {}

  projectAndTeam: StateFullData<ResponsesEntity<ProjectAndTeam>> = {}

  professionSearchReq: StateFullData<ProfessionSearchReq> = {}

  constructor() {
    makeAutoObservable(this)
  }

  updateProfessionSearchReq(req: ProfessionSearchReq) {
    this.professionSearchReq.data = {...req}
  }

  getProjectAndPersonnel() {
    this.projectAndTeam = {...this.projectAndTeam, loading: true}
    ProjectService.getProjectAndPersonnel().then(rsp => {
      runInAction(() => this.projectAndTeam = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.projectAndTeam = {...this.projectAndTeam, loading: false, err})
    })
  }

  getProjectPersonnel(req: ProjectPersonnelReq) {
    this.projectPersonnel = {...this.projectPersonnel, loading: true}
    ProjectService.getProjectPersonnel(req).then(rsp => {
      runInAction(() => this.projectPersonnel = {data: rsp.data, loading: false})
    }).catch(err => {
      runInAction(() => this.projectPersonnel = {...this.projectPersonnel, loading: false, err})
    })
  }
}

export const professionStore = new ProfessionStore();