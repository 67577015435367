import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Form, Input, message, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {getUploadArrayToString, getUploadStringToArray, showErr} from "@common/utils";
import {CustomUpload, normFile} from "@components/CustomUpload";
import {PlusOutlined} from "@ant-design/icons";
import {User} from "@services/dto/security";
import {allStores} from "@/stores";
import {Any} from "@common/types";
import {SecurityService} from "@services/SecurityService";
import {SelectDict} from "@components/SelectDict";

export interface PropsType {
    row: User
}

export const ExtraInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const {wrStore} = allStores;
  const [form] = Form.useForm()
  useEffect(() => {
    form.resetFields()
    const userRow = {...props.row}
    if (props.row) {
      if (typeof userRow.profilePictureUrl === 'string') {
        userRow.profilePictureUrl = getUploadStringToArray(userRow.profilePictureUrl)
      }
    }
    form.setFieldsValue(userRow);
  }, [props.row])
  
  const onFinish = (values: User) => {
    const user = {...props.row}
    Object.assign(user, values)
    wrStore.updateUser(user)
    callUpdateApi(user)
  }

  const updateApiWorker = useRef<Any>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const callUpdateApi = (user: User) => {
    clearTimeout(updateApiWorker.current)
    setLoading(true)
    // 延迟1秒，防止用户重复点击
    updateApiWorker.current = setTimeout(() => {
      if (typeof user.profilePictureUrl === 'object') {
        user.profilePictureUrl = getUploadArrayToString(user.profilePictureUrl);
      }
      SecurityService.updateUser(user._id, user).then(rsp => {
        message.success("修改成功")
      }).catch(showErr).finally(() => {setLoading(false)})
    }, 1000)
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 8}}
      wrapperCol={{span: 16}}
      onFinish={onFinish}
      autoComplete="off">
      <Row>
        <Form.Item hidden name="_id">
          <Input/>
        </Form.Item>
        <Col xl={8} md={12} xs={20}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="人员类别"
                name="personnelCategory"
                rules={[{required: false, message: '请选择人员类别!'}]}>
                <SelectDict allowClear code={"dict_personnel_category"} placeholder={"请选择人员类别"}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="职位"
                name="professional"
                rules={[{required: false, message: '请选择职位!'}]}>
                <SelectDict allowClear code={"dict_professional"} placeholder={"请选择职位"}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="银行卡姓名"
                name="bankName"
                rules={[{required: false, message: '请输入银行卡姓名!'}]}>
                <Input placeholder="请输入银行卡姓名"/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="银行卡号"
                name="bankCardNumber"
                rules={[{required: false, message: '请输入银行卡号!', pattern: /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/}]}>
                <Input placeholder="请输入银行卡号"/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="开户行信息"
                name="bankInfo"
                rules={[{required: false, message: '请输入开户行信息!'}]}>
                <Input placeholder="请输入开户行信息"/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="证件照"
                name="profilePictureUrl"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                extra={"支持文件(jpg,jpeg,png),2M以内"}
                rules={[{required: false, message: '请上传证件照!'}]}>
                <CustomUpload updateSize={2}
                  maxCount={1}
                  uploadType={"image"}
                  accept={"image/*"}
                  listType={"picture-card"}
                  multiple={false}>
                  <div>
                    <PlusOutlined/>
                  </div>
                </CustomUpload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item className={"text-align-center"}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  修改
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}))
