import {
  OvertimeSalaryRow,
  SearchOvertimeSalaryRowReq
} from "@services/dto/overtimeSalary";
import { apiV1 } from "@services/apiHelper";
import { Page } from "@services/dto/common";

export class OvertimeSalaryService {
  static async search(req: SearchOvertimeSalaryRowReq): Promise<Page<OvertimeSalaryRow>> {
    return apiV1.get("/overtimePay", { params: req })
  }
  static async add(req: OvertimeSalaryRow): Promise<Page<OvertimeSalaryRow>> {
    return apiV1.post("/overtimePay", { ...req })
  }
  static async update(req: OvertimeSalaryRow): Promise<Page<OvertimeSalaryRow>> {
    return apiV1.put("/overtimePay", { ...req })
  }
  static async delete(ids: string[]): Promise<Page<OvertimeSalaryRow>> {
    return await apiV1.delete("/overtimePay", { data: ids })
  }
}
