import {observer} from "mobx-react";
import {allStores} from "@/stores";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Drawer, FormInstance, message, Modal, Row, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {logger, showErr} from "@common/utils";
import {DATE_FMT, DEFAULT_MODAL_WIDTH} from "@/config";
import {ConstructionLogRow, PageParam} from "@services/dto/constructionLog";
import {ConstructionLogService} from "@services/ConstructionLogService";
import {BasicInfo} from "@modules/ConstructionLog/Components/BasicInfo";
import {SelectProject} from "@components/SelectProject";

export const ConstructionLog = observer(() => {
  const {constructionLogStore} = allStores
  const basicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<ConstructionLogRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [constructionLogRow, setConstructionLogRow] = useState<ConstructionLogRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [searchReq, setSearchReq] = useState<PageParam>({
    pageNum: 1,
    perPage: 10,
  })

  const columns: ColumnsType<ConstructionLogRow> = [
    {
      title: '项目名称',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => {
            handleView(record)
          }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '日期',
      dataIndex: 'time',
      key: 'time',
      align: 'center',
    },
    {
      title: '天气情况',
      dataIndex: 'weather',
      key: 'weather',
      align: 'center'
    },
    {
      title: '施工情况记录',
      dataIndex: 'situationRecord',
      key: 'situationRecord',
      align: 'center'
    },
    {
      title: '安全工作记录',
      dataIndex: 'workRecords',
      key: 'workRecords',
      align: 'center'
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
      align: 'center'
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record, index) => (
        <Space>
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined/>}
            onClick={() => {
              handleEdit(record)
            }}>修改</Button>
          <Button shape="round" className={"delete-btn"} size={"middle"}
            icon={<DeleteOutlined/>} onClick={() => {
              handleDeleteBatch(record)
            }}>删除</Button>
        </Space>
      )
    }
  ];

  useEffect(() => {
    refreshData(searchReq)
  }, [])

  useEffect(() => {
    setDataSource(constructionLogStore.constructionLogDatasource.data?.items!)
    setTotal(constructionLogStore.constructionLogDatasource.data?.total!)
  }, [constructionLogStore.constructionLogDatasource])

  const handleAdd = () => {
    setConstructionLogRow(undefined)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handleView = (record: ConstructionLogRow) => {
    setConstructionLogRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = (record: ConstructionLogRow) => {
    setConstructionLogRow(record)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleDeleteBatch = (record?: ConstructionLogRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        ConstructionLogService.deleteConstructionLog(ids).then(() => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(searchReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: PageParam) => {
    constructionLogStore.getConstructionLog(req)
  }

  const actionThen = () => {
    message.success("操作成功！")
    setIsModalVisible(false);
    setConstructionLogRow(undefined)
    refreshData(searchReq)
  }

  const handleOk = () => {
    const row = {...constructionLogRow!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(row, values)
      row.time = values.time.format(DATE_FMT)
      setConfirmLoading(true)
      if (row.id) {
        ConstructionLogService.updateConstructionLog(row).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        ConstructionLogService.addConstructionLog(row).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  }

  return (
    <div className={"institution"}>
      <Row gutter={12}>
        <Col span={6}>
          <SelectProject
            value={searchReq.projectId}
            allowClear
            onChange={(value: string) => {
              setSearchReq({...searchReq, pageNum: 1, projectId: value})
              refreshData({...searchReq, pageNum: 1, projectId: value})
            }}/>
        </Col>
        <Col span={18} className={"text-align-right"}>
          <Button icon={<DeleteOutlined/>} size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={() => handleDeleteBatch(undefined)}>删除</Button>
          <Button icon={<PlusOutlined/>} type={"primary"} size={"middle"} shape={"round"}
            onClick={handleAdd}>新增</Button>
        </Col>
      </Row>

      <Card className={"margin-top-sm"}>
        <Table
          rowKey={"id"}
          dataSource={dataSource}
          columns={columns}
          size={"middle"}
          loading={constructionLogStore.constructionLogDatasource.loading || confirmLoading}
          pagination={{
            total: total,
            position: ['bottomCenter'],
            pageSize: searchReq.perPage,
            current: searchReq.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`,
            onChange: handlePageChange
          }}
          rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys}}/>
      </Card>

      <Drawer
        title={<div className="text-align-center">施工日志</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        onClose={handleCancel}>
        <BasicInfo row={{...constructionLogRow!}} ref={basicInfo} isView={isView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button type="primary" onClick={handleOk} loading={confirmLoading}
              className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>
    </div>
  )
})
