import React, {useEffect, useState} from "react";
import {DatePicker} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {SelectDict} from "@components/SelectDict";
import {SelectInvoiceRegister} from "@components/SelectInvoiceRegister";
import dayjs from "dayjs";
import {Any} from "@common/types";
import {CostExpendReq} from "@services/dto/costExpend";
import {SelectCostApplication} from "@components/SelectCostApplication";
import {SelectCostReimbursement} from "@components/SelectCostReimbursement";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {CustomInputNumber} from "@components/CustomInputNumber";
import {SelectBankAccount} from "@components/SelectBankAccount";
import {SelectArchives} from "@components/SelectArchives";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {CustomAuthHeaderAction, PropsType as ParentPropsType} from "@components/CustomAuthHeaderAction";

export interface PropsType extends ParentPropsType {
  updateCount?: number, // 更新渲染标志
  refreshData?: (req: CostExpendReq) => void,
  collapsed?: boolean, // 是否展开
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<CostExpendReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: CostExpendReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleElTypeChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efpPayType = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfpAmountChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efpAmount1 = start
    req.efpAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfpPayTimeChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efpPayTime1 = dateStrings[0]
    req.efpPayTime2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfpPayDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efpPayDate1 = dateStrings[0]
    req.efpPayDate2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleApplicationIdChange = (searchValue: string) => {
    const req: CostExpendReq = {...searchReq}
    req.pageNum = 1
    req.efpEmployeeFeeApplicationId = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleBaNick1Change = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.baNick1 = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleBaNick2Change = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.baNick2 = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleReimbursementIdChange = (searchValue: string) => {
    const req: CostExpendReq = {...searchReq}
    req.pageNum = 1
    req.efaEmployeeReimbursementId = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonResponsibleNameChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfpAuditOpinionChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efpAuditOpinion = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfpStatusChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efpStatus = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleInvoiceRegisterChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efaInvoiceRegistration = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleOrganizersUserIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.organizersUserId = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined

    req.efpPayTime1 = undefined
    req.efpPayTime2 = undefined
    req.efpPayType = undefined
    req.efpAmount1 = undefined
    req.efpAmount2 = undefined
    req.efpPayDate1 = undefined
    req.efpPayDate2 = undefined
    req.efpPaymentAccount = undefined
    req.baNick1 = undefined
    req.efpAccountsReceivable = undefined
    req.baNick2 = undefined
    req.efpEmployeeFeeApplicationId = undefined
    req.efaInvoiceRegistration = undefined
    req.efaMaterialWarehousing = undefined
    req.efaEmployeeReimbursementId = undefined
    req.personResponsible = undefined
    req.personResponsibleName = undefined
    req.organizersUserId = undefined // 主办人员
    req.organizersUserName = undefined // 主办人员名
    req.efpFileArchive = undefined
    req.efpAuditOpinion = undefined
    req.efpApprovedAmount1 = undefined
    req.efpApprovedAmount2 = undefined
    req.efpStatus = undefined

    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <CustomAuthHeaderAction
        size={props.size}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <DatePicker.RangePicker
            value={searchReq?.efpPayTime1 ? [dayjs(searchReq?.efpPayTime1), dayjs(searchReq?.efpPayTime2)] : [undefined, undefined]}
            placeholder={['制单开始日期', '制单结束日期']}
            onChange={handleEfpPayTimeChange}
            allowClear
            className={"width-100-percentage"}/>,
          <SelectDict
            updateCount={props.updateCount}
            code={'payment_typee'}
            value={searchReq?.efpPayType}
            allowClear
            onChange={handleElTypeChange}
            placeholder={'请输入费用类别'}
            className={"width-100-percentage"}/>,
          <CustomInputNumber
            value={[searchReq?.efpAmount1, searchReq?.efpAmount2]}
            placeholder={['支出金额最小', '支出金额最大']}
            className={'width-100-percentage'}
            onSearch={handleEfpAmountChange}/>,
          <SelectArchives
            updateCount={props.updateCount}
            onChange={handleBaNick1Change}
            value={searchReq?.baNick1}
            fieldNames={{label: 'name', value: 'name'}}
            allowClear
            className={'width-100-percentage'}
            placeholder={'请输入付款方'}/>,
          <SelectBankAccount
            onChange={handleBaNick2Change}
            value={searchReq?.baNick2}
            updateCount={props.updateCount}
            statisticsItemsReq={{
              table: 'employeeFeePay',
              field: 'baNick2',
            }}
            allowClear
            className={'width-100-percentage'}
            placeholder={'请输入收款方'}/>,
          <DatePicker.RangePicker
            value={searchReq?.efpPayDate1 ? [dayjs(searchReq?.efpPayDate1), dayjs(searchReq?.efpPayDate2)] : [undefined, undefined]}
            placeholder={['付款开始日期', '付款结束日期']}
            onChange={handleEfpPayDateChange}
            allowClear
            className={"width-100-percentage"}/>,
          <SelectCostApplication
            onChange={handleApplicationIdChange}
            value={searchReq?.efpEmployeeFeeApplicationId}
            allowClear
            className={"width-100-percentage"}/>,
          <SelectInvoiceRegister
            updateCount={props.updateCount}
            value={searchReq?.efaInvoiceRegistration}
            onChange={handleInvoiceRegisterChange}
            fieldNames={{label: 'irInvoiceCode2', value: 'id'}}
            allowClear
            isHiddenOption={true}
            className={"width-100-percentage"}/>,
          <SelectCostReimbursement
            value={searchReq?.efaEmployeeReimbursementId}
            onChange={handleReimbursementIdChange}
            allowClear
            className={"width-100-percentage"}/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
          <SelectCooperativeUnit
            onChange={handleCooperationUnitChange}
            value={searchReq?.cooperationUnit}
            allowClear
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectDict
            updateCount={props.updateCount}
            code={'dict_efp_audit_opinion'}
            onChange={handleEfpAuditOpinionChange}
            value={searchReq?.efpAuditOpinion}
            placeholder={'请选择审核意见'}
            allowClear
            className={"width-100-percentage"}/>,
          <SelectPersonCharge
            updateCount={props.updateCount}
            onChange={handlePersonResponsibleNameChange}
            className={"width-100-percentage"}
            value={searchReq?.personResponsible}
            placeholder={"请选择办理人员"}
            allowClear/>,
          <SelectPersonCharge
            onChange={handleOrganizersUserIdChange}
            className={"width-100-percentage"}
            placeholder={"请选择主办人员"}
            value={searchReq?.organizersUserId}
            allowClear/>,
          <SelectProcessStatus
            onChange={handleEfpStatusChange}
            allowClear
            value={searchReq?.efpStatus}
            placeholder={'请选择办理进度'}
            className={"width-100-percentage"}/>,
        ] : []}/>
    </>
  )
}))
