import React, { useEffect } from "react";
import { Col, DatePicker, Form, Input, InputNumber, Row } from "antd";
import { FormInstance } from "antd/es";
import { observer } from "mobx-react";
import { DeductionRow } from "@services/dto/deduction";
import { CascaderPerson } from "@/components/CascaderPerson";
import dayjs from "dayjs";
import { DATE_FMT } from "@/config";

export interface PropsType {
  row: DeductionRow
}

export const DeductionBasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    const row = { ...props.row }
    if (row.id) {
      if (typeof row.userCode === 'string') {
        row.userCode = row.userCode.split(",")
      }
      row.time = dayjs(row.time)
    }
    form.setFieldsValue(row);
  }, [props.row])


  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ status: 1, isVirtualUser: 0 }}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Form.Item
            label="员工"
            name="userCode"
            valuePropName={"person"}
            rules={[{ required: !props.row.id, message: '请选择员工!' }]}>
            <CascaderPerson disabled={!!props.row.id} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="扣减类型"
            name="type"
            rules={[{ required: true, message: '请输入扣减类型!' }]}>
            <Input placeholder="请输入扣减类型" className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="扣减金额"
            name="amount"
            rules={[{ required: true, message: '请输入金额!' }]}>
            <InputNumber placeholder="请输入金额" precision={0} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="扣减时间"
            name="time"
            rules={[{ required: true, message: '扣减时间!' }]}>
            <DatePicker placeholder={"扣减时间"} className={'width-100-percentage'} format={DATE_FMT} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="备注"
            name="remark"
            rules={[{ required: false, message: '请输入备注!' }]}>
            <Input.TextArea placeholder="请输入备注" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
