import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {FundApprovalReq, FundApprovalRow} from "@services/dto/fundApproval";
import {FundApprovalService} from "@services/FundApprovalService";
import {BLACK_LIST} from "@/config";
import {convertCurrency, formatMoney} from "@common/utils";
import {BankAccountService} from "@services/BankAccountService";
import {getApplicationFrom} from "@components/TableApplicationFormText";
import {FilingService} from "@services/FilingService";

export class FundApprovalStore  {
  fundApprovalDatasource: StateFullData<Page<FundApprovalRow>> = {}
  fundApprovalSelectDatasource: StateFullData<Page<FundApprovalRow>> = {}
  fundApprovalRow?: FundApprovalRow
  fundApprovalOption?: string
  fundApprovalDetail: StateFullData<ResponseEntity<FundApprovalRow>> = {}
  fundApprovalDetails: StateFullData<ResponsesEntity<FundApprovalRow>> = {}
  fundApprovalReq: FundApprovalReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateFundApprovalRow(row: FundApprovalRow) {
    this.fundApprovalRow = {...row}
  }

  updateFundApprovalOption(option: string) {
    this.fundApprovalOption = option
  }

  getFundApproval(req: FundApprovalReq) {
    this.fundApprovalDatasource = {...this.fundApprovalDatasource, loading: true}
    this.fundApprovalReq = {...this.fundApprovalReq, ...req}
    FundApprovalService.getFundApproval(req).then(rsp => {
      runInAction(() => this.fundApprovalDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.fundApprovalDatasource = {...this.fundApprovalDatasource, loading: false, err})
    })
  }

  getSelectFundApproval(req: FundApprovalReq) {
    this.fundApprovalSelectDatasource = {...this.fundApprovalSelectDatasource, loading: true}
    FundApprovalService.getFundApproval(req).then(rsp => {
      runInAction(() => this.fundApprovalSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.fundApprovalSelectDatasource = {...this.fundApprovalSelectDatasource, loading: false, err})
    })
  }

  getOne(id: string) {
    this.fundApprovalDetail = {...this.fundApprovalDetail, loading: true}
    FundApprovalService.getOne(id).then(rsp => {
      runInAction(() => this.fundApprovalDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.fundApprovalDetail = {...this.fundApprovalDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.fundApprovalDetails = {...this.fundApprovalDetails, loading: true}
    FundApprovalService.getBatch(ids).then(rsp => {
      runInAction(() => this.fundApprovalDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.fundApprovalDetails = {...this.fundApprovalDetails, loading: false, err})
    })
  }

  async getPrintList(record: FundApprovalRow): Promise<LabelValue[]> {

    const row = this.fundApprovalDatasource.data?.items.find(o => o.id === record.id)

    let faPaymentAccount = undefined
    let faAccountsReceivable = undefined
    if (row.faPaymentAccountId !== 'N/A' && row.faAccountsReceivableId !== 'N/A') {
      let accountRsp = await BankAccountService.getBatch([row.faPaymentAccountId, row.faAccountsReceivableId])
      if (accountRsp.code !== 200 || accountRsp.data.length !== 2) {
        return
      }
      faPaymentAccount = accountRsp.data[0]
      faAccountsReceivable = accountRsp.data[1]
    } else if (row.faPaymentAccountId !== 'N/A') {
      const {
        data: faPaymentAccountData,
        code: faPaymentAccountCode,
      } = await BankAccountService.getOne(row.faPaymentAccountId)
      if (faPaymentAccountCode === 200) {
        faPaymentAccount = faPaymentAccountData
      } else {
        return
      }
    } else if (row.faAccountsReceivableId !== 'N/A') {
      const {
        data: faAccountsReceivableData,
        code: faAccountsReceivableCode,
      } = await BankAccountService.getOne(row.faAccountsReceivableId)
      if (faAccountsReceivableCode === 200) {
        faAccountsReceivable = faAccountsReceivableData
      } else {
        return
      }
    }

    // 通过导出的方法获取对应表格的值
    const faApplicationForm = await getApplicationFrom(row.faApplicationFormType, row.faApplicationForm)

    // 存档批号
    let faFileRow = undefined
    if (row.faFile && !BLACK_LIST.includes(row.faFile)) {
      const faFileRsp = await FilingService.getBatch([row.faFile])
      if (faFileRsp && faFileRsp.code !== 200) {
        return
      }
      faFileRow = faFileRsp.data[0]
    }

    return [
      {label: '制单日期', value: row.faDate as string},
      {label: '制单编号', value: row.faCode},
      {label: '款项类别', value: row.faType},
      {label: '款项摘要', value: row.faSummary},
      {label: '申请金额(元)', value: `${formatMoney(row.faAmount)}元（${convertCurrency(row.faAmount)}）`},
      {label: '付款账户名称', value: faPaymentAccount?.baName},
      {label: '付款账户银行账号', value: faPaymentAccount?.baAccount},
      {label: '付款账户开户银行', value: faPaymentAccount?.baBlank},
      {label: '收款账户名称', value: faAccountsReceivable?.baName},
      {label: '收款账户银行账号', value: faAccountsReceivable?.baAccount},
      {label: '收款账户开户银行', value: faAccountsReceivable?.baBlank},
      {
        label: '关联申请',
        value: (row.faApplicationFormType && faApplicationForm) && `【${row.faApplicationFormType}】${faApplicationForm}`
      },
      {label: '责任部门', value: row.responsibilityProject},
      {label: '存档批号', value: faFileRow?.faBatchNumber},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const fundApprovalStore = new FundApprovalStore();
