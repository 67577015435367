import React, {useEffect} from "react";
import {Button, Col, Divider, Form, Input, message, Row, Switch} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {CompanyRow} from "@services/dto/company";
import {CustomUpload, normFile} from "@components/CustomUpload";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

export interface PropsType {
  row: CompanyRow
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  useEffect(() => {
    const companyRow = {...props.row}
    if (!companyRow.id) {
      companyRow.personInCharges = [{
        id: "",
        companyUsername: "",
        password: "",
        isEnable: 0,
        phone: "",
        name: ""
      }]
    }
    form.resetFields();
    form.setFieldsValue(companyRow)
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 4, offset: 2}}
      wrapperCol={{span: 16}}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="_id">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="公司名称"
            name="companyName"
            rules={[{required: false, message: '请输入公司名称!'}]}>
            <Input placeholder="请输入公司名称"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="公司官网"
            name="url"
            rules={[{required: false, message: '请输入公司官网!'}]}>
            <Input placeholder="请输入公司官网"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="联系人"
            name="contacts"
            rules={[{required: false, message: '请输入联系人!'}]}>
            <Input placeholder="请输入联系人"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="手机号码"
            name="phone"
            rules={[{required: false, message: '请输入手机号码!', pattern: /0?(13|14|15|17|18|19)[0-9]{9}$/}]}>
            <Input placeholder="请输入手机号码"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="详细地址"
            name="address"
            rules={[{required: false, message: '请输入详细地址!'}]}>
            <Input.TextArea placeholder="请输入详细地址" autoSize={{ minRows: 3, maxRows: 5 }}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="营业执照"
            name="businessLicense"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            extra={"支持文件(jpg,jpeg,png),2M以内"}
            rules={[{required: false, message: '请上传营业执照!'}]}>
            <CustomUpload updateSize={2}
              maxCount={1}
              uploadType={"image"}
              accept={"image/*"}
              listType={"picture"}
              multiple={false}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.List name={"personInCharges"}>
            { (fields, { add, remove }) => (
              <>
                { fields.map((field, index) => (
                  <Row key={field.key}>
                    <Divider className={"divider-padding"}/>
                    <Col span={24}>
                      <Form.Item
                        {...field}
                        hidden
                        name="id">
                        <Input/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...field}
                        labelCol={{span: 4, offset: 2}}
                        wrapperCol={{span: 16}}
                        label="姓名"
                        name={[field.name, 'name']}
                        rules={[{required: false, message: '请输入姓名!'}]}>
                        <Input placeholder="请输入姓名"/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...field}
                        labelCol={{span: 4, offset: 2}}
                        wrapperCol={{span: 16}}
                        label="手机号码"
                        name={[field.name, 'phone']}
                        rules={[{required: false, message: '请输入手机号码!', pattern: /0?(13|14|15|17|18|19)[0-9]{9}$/}]}>
                        <Input placeholder="请输入手机号码"/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...field}
                        labelCol={{span: 4, offset: 2}}
                        wrapperCol={{span: 16}}
                        label="是否启用"
                        valuePropName={"checked"}
                        name={[field.name, 'isEnable']}
                        rules={[{required: false, message: '请选择是否启用!'}]}>
                        <Switch checkedChildren="启用" unCheckedChildren="禁用" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...field}
                        labelCol={{span: 4, offset: 2}}
                        wrapperCol={{span: 16}}
                        label="管理账号"
                        name={[field.name, 'companyUsername']}
                        rules={[{required: true, message: '账号由字母数字下划线组成!', pattern: /^[a-zA-Z0-9_]+$/}]}>
                        <Input placeholder="请输入管理账号"/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...field}
                        labelCol={{span: 4, offset: 2}}
                        wrapperCol={{span: 16}}
                        label="管理密码"
                        name={[field.name, 'password']}
                        rules={[{required: !props.row.personInCharges || !props.row.personInCharges[index], message: '密码由8-16位数字、大小写字母跟特殊字符组成!', pattern: /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,16}/}]}>
                        <Row align={"middle"}>
                          <Col span={22}>
                            <Input.Password placeholder="请输入管理密码"/>
                          </Col>
                          <Col span={2} className={"text-align-center"}>
                            <MinusCircleOutlined onClick={() => {
                              if (fields.length === 1) {
                                message.warning("至少保留一个管理员账号")
                                return
                              }
                              remove(field.name);
                            }} />
                          </Col>
                        </Row>
                      </Form.Item>
                    </Col>
                  </Row>
                )) }
                <Col span={24}>
                  <Form.Item wrapperCol={{span: 20, offset: 3}} className={"text-align-center"}>
                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                      新增企业管理员用户
                    </Button>
                  </Form.Item>
                </Col>
              </>
            ) }
          </Form.List>
        </Col>
      </Row>
    </Form>
  )
}))
