import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import {ProjectService} from "@services/ProjectService";
import {BLACK_LIST} from "@/config";

export interface PropsType {
    projectId: string
}

export const TableProjectText = observer((props: PropsType) => {

  const [projectName, setProjectName] = useState<string>()

  useEffect(() => {
    if (props.projectId) {
      if (!BLACK_LIST.includes(props.projectId)){
        ProjectService.getOne(props.projectId).then(response => {
          setProjectName(response.name)
        })
      } else {
        setProjectName('/')
      }
    } else {
      setProjectName('/')
    }
  }, [props.projectId])

  return <div>
    {
      projectName || props.projectId
    }
  </div>
})
