import React, {useEffect, useState} from "react";
import {Divider, Pagination, Select, Space} from "antd";
import {Any} from "@common/types";
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {TaskSupervisionReq, TaskSupervisionRow} from "@services/dto/taskSupervision";
import {StatisticsItemsReq, StatisticsItemsRow} from "@services/dto/dict";
import {showErr} from "@common/utils";
import {DictService} from "@services/DictService";
import {BLACK_LIST} from "@/config";

export interface PropsType {
  onChange?: (value: Any | null, options: TaskSupervisionRow[]) => void
  value?: Any | null
  mode?: 'multiple' | 'tags'
  labelInValue?: boolean
  disabled?: boolean
  className?: string
  allowClear?: boolean
  placeholder?: string
  fieldNames?: { value: string, label: string }
  statisticsItemsReq?: StatisticsItemsReq
  tsLevel?: string
  defaultItems?: { id: string, value: string }[]
}

export const SelectTaskSupervision = observer((props: PropsType) => {
  const [value, setValue] = useState<Any | null>()
  const {taskSupervisionStore} = allStores
  const [datasource, setDatasource] = useState<TaskSupervisionRow[]>([])
  const [total, setTotal] = useState(0)
  const [items, setItems] = useState<TaskSupervisionRow[]>([])
  const [statisticsItems, setStatisticsItems] = useState<StatisticsItemsRow[] | TaskSupervisionRow[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [searchReq, setSearchReq] = useState<TaskSupervisionReq>({
    pageNum: 1,
    perPage: 10,
    tsLevel: props.tsLevel
  })

  /**
   *  每个组件默认写法 只需修改 'tsCode'
   */
  const defaultItems: any[] = [
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: '',
      [props.fieldNames ? props.fieldNames.label : 'tsCode']: '空白'
    },
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: 'N/A',
      [props.fieldNames ? props.fieldNames.label : 'tsCode']: 'N/A'
    },
  ]

  /**
   * 初始化
   */
  useEffect(() => {
    refreshData(searchReq)
    if (props.statisticsItemsReq) {
      setLoading(true)
      DictService.getStatisticsItems(props.statisticsItemsReq).then(rsp => {
        const row = JSON.parse(JSON.stringify(rsp.data || [])).map((item: StatisticsItemsRow | TaskSupervisionRow) => {
          (item as TaskSupervisionRow).tsCode = `${(item as TaskSupervisionRow).tsCode || (item as StatisticsItemsRow).value}(${(item as StatisticsItemsRow).coun})`
          return item
        })
        setStatisticsItems(row);
      }).catch(showErr).finally(() => {
        setLoading(false)
      })
    }
  }, [])

  /**
   * 办理进度数据变化
   */
  useEffect(() => {
    if (taskSupervisionStore.taskSupervisionSelectDatasource.data) {
      setDatasource(taskSupervisionStore.taskSupervisionSelectDatasource.data.items || [])
      setTotal(taskSupervisionStore.taskSupervisionSelectDatasource.data.total || 0)
      if (taskSupervisionStore.taskSupervisionDetails.data?.data.length) {
        setItems(taskSupervisionStore.taskSupervisionDetails.data?.data
          .filter(item => !(taskSupervisionStore.taskSupervisionSelectDatasource.data.items || [])
            .some(o => o.id === item.id)))
      }
    }
  }, [taskSupervisionStore.taskSupervisionDetails, taskSupervisionStore.taskSupervisionSelectDatasource.data])

  /**
   * 值发生改变
   */
  useEffect(() => {
    setValue(props.value)
    if (typeof props.value === 'string' && !BLACK_LIST.includes(props.value)) {
      taskSupervisionStore.getBatch([props.value])
    } else if (typeof props.value === 'object') {
      const ids = props.value.filter((v: string) => !BLACK_LIST.includes(v))
      if (ids.length) {
        taskSupervisionStore.getBatch(ids)
      }
    }
  }, [props.value])

  const refreshData = (req: TaskSupervisionReq) => {
    taskSupervisionStore.getSelectTaskSupervision(req)
  }

  const onChange = (value: Any | null) => {
    setValue(value)
    if (props.onChange) {
      props.onChange(value, [
        ...datasource,
        ...items,
      ]);
    }
  }

  const handleSearch = (inputValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = inputValue
    setSearchReq(req)
    refreshData(req)
    return true
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    const req = {...searchReq}
    req.pageNum = page
    req.perPage = pageSize
    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      {
        props.statisticsItemsReq ? (
          <Select
            allowClear={props.allowClear}
            placeholder={props.placeholder || "请选择工作任务"}
            className={`${props.className} width-100-percentage`}
            dropdownRender={(menu) => (
              <>
                { menu }
                <Divider className={"divider-margin"}/>
                <Space>
                  <Pagination
                    total={total}
                    pageSize={searchReq.perPage}
                    size={"small"}
                    onChange={handlePaginationChange}/>
                </Space>
              </>
            )}
            value={value}
            onChange={onChange}
            showSearch
            optionFilterProp="children"
            filterOption={(input: string, option) =>
              String(option?.tsCode).toLowerCase().includes(input.toLowerCase())}
            disabled={props.disabled}
            mode={props.mode}
            loading={loading}
            labelInValue={props.labelInValue}
            fieldNames={props.fieldNames || {value: "id", label: "tsCode"}}
            options={statisticsItems as TaskSupervisionRow[]}>
          </Select>
        ) : (
          <Select
            allowClear={props.allowClear}
            placeholder={props.placeholder || "请选择工作任务"}
            className={`${props.className} width-100-percentage`}
            dropdownRender={(menu) => (
              <>
                { menu }
                <Divider className={"divider-margin"}/>
                <Space>
                  <Pagination
                    total={total}
                    pageSize={searchReq.perPage}
                    size={"small"}
                    onChange={handlePaginationChange}/>
                </Space>
              </>
            )}
            value={value}
            onChange={onChange}
            showSearch
            onClear={() => handleSearch('')}
            disabled={props.disabled}
            mode={props.mode}
            filterOption={false}
            onSearch={handleSearch}
            loading={taskSupervisionStore.taskSupervisionDatasource.loading}
            labelInValue={props.labelInValue}
            fieldNames={props.fieldNames || {value: "id", label: "tsCode"}}
            options={[
              ...(props.defaultItems || defaultItems),
              ...datasource,
              ...items,
            ]}>
          </Select>
        )
      }
    </>
  )
})
