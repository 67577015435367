import {apiV1} from "@services/apiHelper";
import {
  GeoLocationRow,
  PunchClockRow,
  GeoLocationReq,
  PunchClockReq,
  SearchGeoLocationReq,
  SearchPunchClockReq,
} from "@services/dto/punchClock";
import {Page, ResponseEntity} from "@services/dto/common";

export class PunchClockService {
  static async searchPunchClock(req: SearchPunchClockReq): Promise<ResponseEntity<Page<PunchClockRow>>> {
    return await apiV1.get('/attendanceRules/search', {params: req})
  }

  static async savePunchClock(req: PunchClockReq): Promise<ResponseEntity<PunchClockRow>> {
    return await apiV1.post('/attendanceRules/save', req)
  }

  static async updatePunchClock(id: string, req: PunchClockReq): Promise<ResponseEntity<PunchClockRow>> {
    return await apiV1.put(`/attendanceRules/put/${id}`, req)
  }

  static async deleteBatchPunchClock(ids: string[]): Promise<string> {
    return await apiV1.delete("/attendanceRules/deleteBatch", {data: {ids}})
  }

  static async searchGeoLocation(req: SearchGeoLocationReq): Promise<ResponseEntity<Page<GeoLocationRow>>> {
    return await apiV1.get('/geoLocation/search', {params: req})
  }

  static async saveGeoLocation(req: GeoLocationReq): Promise<ResponseEntity<GeoLocationRow>> {
    return await apiV1.post('/geoLocation/save', req)
  }

  static async updateGeoLocation(id: string, req: GeoLocationReq): Promise<ResponseEntity<GeoLocationRow>> {
    return await apiV1.put(`/geoLocation/put/${id}`, req)
  }
}