import React, {FC, useEffect, useState} from "react";
import {Button, Spin, Row, Col, Radio, Input, Modal, message} from 'antd'
import {DeleteOutlined, PlusOutlined, SearchOutlined} from '@ant-design/icons';
import {Publicity} from '@modules/Recruitment/Components/Publicity'
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {useNavigate} from "react-router-dom";
import {RecruitmentRow, SearchRecruitmentReq} from "@services/dto/recruitment";
import {Page, StateFullData} from "@services/dto/common";
import IconFont from "@components/IconFont";
import {RecruitmentService} from "@services/RecruitmentService";
import './index.less'
import {showErr} from "@common/utils";

export const Recruitment: FC = observer(() => {
  const {recruitmentStore, securityStore} = allStores;
  const navigate = useNavigate()
  const [tabKey, setTabKey] = useState('online')
  const [curRecruitment, setCurRecruitment] = useState<StateFullData<Page<RecruitmentRow>>>({})
  const [searchReq, setSearchReq] = useState<SearchRecruitmentReq>({pageNum: 1, perPage: 10, status: tabKey})
  const [searchValue, setSearchValue] = useState<string>("")
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  useEffect(() => {
    setCurRecruitment(recruitmentStore.curRecruitment)
  }, [recruitmentStore.curRecruitment])

  useEffect(() => {
    const req = {...searchReq}
    req.pageNum = 1
    refreshData(req)
  }, [tabKey])

  const addPublicity = () => {
    navigate('/publicityAction')
  }

  const refreshData = (searchReq: SearchRecruitmentReq) => {
    const req = {...searchReq}
    req.companyId = securityStore.getLoggedUser()?.companyId
    req.status = tabKey
    setSearchReq(req)
    recruitmentStore.loadRecruitments(req)
  }

  const onSearch = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = searchValue
    refreshData(req)
  }
  
  const handlePageChange = (page: number, pageSize: number) => {
    const req = {...searchReq}
    req.pageNum = page
    req.perPage = pageSize
    refreshData(req)
  }
  
  const handleDeleteBatch = (record?: RecruitmentRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids:string[] = []
    if (record) {
      ids.push(record._id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        RecruitmentService.deleteBatchRecruitment(ids).then(() => {
          message.success("删除成功！")
          refreshData(searchReq)
        }).catch(showErr)
      },
    })
  }
  
  const handleOffline = (record: RecruitmentRow, status: string) => {
    const row = {...record, status}
    Modal.confirm({
      title: "是否下线？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        RecruitmentService.updateRecruitment(record._id, row).then(rsp => {
          message.success("下线成功！")
          refreshData(searchReq)
        }).catch(showErr)
      },
    })
  }

  return (
    <>
      <div className="container">
        <Row className={"margin-bottom-sm"}>
          <Col xl={7} md={9} xs={24}>
            <Radio.Group value={tabKey} onChange={(e) => setTabKey(e.target.value)} buttonStyle="solid">
              <Radio.Button value="online"><IconFont type={"hr-mokuai-shang"} className={"icon-fontsize icon-rotate"}/>已上线</Radio.Button>
              <Radio.Button value="offline"><IconFont type={"hr-mokuai-shang"} className={"icon-fontsize"}/>已下线</Radio.Button>
              <Radio.Button value="draft"><IconFont type={"hr-caogaoxiang1"} className={"icon-fontsize"}/>草稿</Radio.Button>
            </Radio.Group>
          </Col>
          <Col xl={8} md={9} xs={24}>
            <Input
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onPressEnter={onSearch}
              allowClear
              placeholder="请输入招聘经理"
              suffix={<SearchOutlined onClick={onSearch}/>}
            />
          </Col>
          <Col xl={9} md={6} xs={24} className={"text-align-right"}>
            {
              tabKey !== 'online' && <Button icon={<DeleteOutlined/>} size={"middle"} shape={"round"} className={"margin-right-xs"}
                onClick={() => handleDeleteBatch(undefined)}>删除</Button>
            }
            <Button icon={<PlusOutlined/>} type={"primary"} size={"middle"} shape={"round"}
              onClick={addPublicity}>新增</Button>
          </Col>
        </Row>
        <Spin spinning={curRecruitment.loading} wrapperClassName="content-container">
          <Publicity 
            curRecruitment={curRecruitment} 
            deletePublicity={handleDeleteBatch} 
            handlePageChange={handlePageChange} 
            searchReq={searchReq}
            selectedRowKeys={selectedRowKeys}
            selectedRowKeysChange={setSelectedRowKeys} 
            handleOffline={handleOffline}/>
        </Spin>
      </div>
    </>
  )
});

