
import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {BudgetManagementReq, BudgetManagementRow} from "@services/dto/budgetManagement";

export class BudgetManagementService {
  static async getBudgetManagement(req: BudgetManagementReq): Promise<Page<BudgetManagementRow>> {
    return await apiV1.get("/budgetManagement/search", {params: req})
  }

  static async addBudgetManagement(row: BudgetManagementRow): Promise<ResponseEntity<BudgetManagementRow>> {
    return await apiV1.post("/budgetManagement/save", {...row})
  }

  static async updateBudgetManagement(row: BudgetManagementRow): Promise<ResponseEntity<BudgetManagementRow>> {
    return await apiV1.put("/budgetManagement/update", {...row})
  }

  static async deleteBudgetManagement(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/budgetManagement/delete", {data: ids})
  }

  static async getBatch(ids: string[]): Promise<ResponseEntity<BudgetManagementRow[]>> {
    return await apiV1.get(`/budgetManagement/getBatch/${ids.join(',')}`);
  }

  static async getDetailById(id: string): Promise<ResponseEntity<BudgetManagementRow>> {
    return await apiV1.get(`/budgetManagement/getOne/${id}`)
  }
}
