import axios from 'axios';
import {get} from 'lodash';
import {Any} from '@common/types';
import {ACCESS_PATH, BASE_API_PATH, STATUS_401} from '@/config';
import {logger} from '@common/utils';
import {getLoginRspFromCache, logout} from "@modules/Security/Stores/SecurityStore";

/**
 * get cost time
 * @param config
 */
function getCostTime(config: Any) {
  const startedAt = ((config as Any) || {meta: {requestStartedAt: 0}}).meta
    .requestStartedAt;
  if (!startedAt) {
    return 0;
  }
  return new Date().getTime() - startedAt;
}

/**
 * create axios instance
 * @param prefix the api prefix
 * @param host the host name
 */
export function createAxiosInstance(prefix: string, host?: string) {
  /**
     * new http/s request instance
     */
  const apiInstance = axios.create({
    baseURL: (host || BASE_API_PATH) + prefix,
  });

  /**
     * inject token
     */
  apiInstance.interceptors.request.use(
    async (config: Any) => {
      config.headers = config.headers || {};
      config.headers['Content-Type'] = 'application/json';
      config.meta = config.meta || {};
      config.meta.requestStartedAt = new Date().getTime();

      const loginRsp = await getLoginRspFromCache();

      if (loginRsp?.token) {
        config.headers.Authorization = `${loginRsp.token.jwtToken}`;
        config.headers.RealmName = ACCESS_PATH
      }

      return config;
    },
    () => {
      return Promise.reject('error.request_failed');
    },
  );

  const getReqUrl = (context: Any) => {
    const reqUrl =
            get(context, 'request._url', '') ||
            get(context, 'request.responseURL', '');
    return reqUrl || '';
  };

  /**
     * interceptor errors
     */
  apiInstance.interceptors.response.use(
    async rsp => {
      const uri = getReqUrl(rsp);
      logger.debug(`[OK]${uri},time=${getCostTime(rsp.config)}ms`);
      logger.debug(JSON.stringify(rsp.data).substring(0, 1024));
      return rsp.data;
    },
    async error => {
      let errMsg = error.message || 'error.request_failed';
      const reqUrl = getReqUrl(error);
      logger.debug(
        `[FAILED]${reqUrl},time=${getCostTime(error.config)}ms`
      );
      let errorJson: Any = {};
      if (error.response && error.response.data) {
        errorJson = error.response.data;
        logger.log(`reqUrl = ${reqUrl}, err=${JSON.stringify(errorJson)}`);
        const statusCode = get(error, 'response.status', '') + '';
        const isNeedRedirectToLanding = statusCode === STATUS_401;
        errMsg = get(errorJson, 'message');
        errMsg = errMsg || '网络错误，请稍后再试';

        if (isNeedRedirectToLanding) {
          logout().then(() => {
            window.location.href = '/'
          });
        }
      }
      return Promise.reject({message: errMsg, error: errorJson});
    },
  );

  return apiInstance;
}

export const apiV1 = createAxiosInstance('/v1');
