import React, { useEffect } from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import { FormInstance } from "antd/es";
import { observer } from "mobx-react";
import { OvertimeSalaryRow } from "@services/dto/overtimeSalary";
import { CascaderPerson } from "@/components/CascaderPerson";
import { PersonCascader } from "@/services/dto/talent";
import dayjs from "dayjs";

export interface PropsType {
  row: OvertimeSalaryRow
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  const handlePersonChange = (value: string[], selectedOptions: PersonCascader[]) => {
    return value
  }

  useEffect(() => {
    form.resetFields()
    const row = { ...props.row }
    if (row.id) {
      if (typeof row.userCode === 'string') {
        row.userCode = row.userCode.split(",")
      }
      if (typeof row.overTime === 'string') {
        row.overTime = dayjs(row.overTime)
      }
    }
    form.setFieldsValue(row);
  }, [props.row])


  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ status: 1, isVirtualUser: 0 }}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Form.Item
            label="员工"
            name="userCode"
            valuePropName={"person"}
            getValueFromEvent={handlePersonChange}
            rules={[{ required: !props.row.id, message: '请选择员工!' }]}>
            <CascaderPerson disabled={!!props.row.id} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="加班日期"
            name="overTime"
            rules={[{ required: true, message: '加班日期!' }]}>
            <DatePicker placeholder={"加班日期"} className={'width-100-percentage'} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="加班类型"
            name="type"
            rules={[{ required: true, message: '请输入加班类型!' }]}>
            <Input placeholder="请输入加班类型" className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="加班工时"
            name="time"
            rules={[{ required: true, message: '请输入加班工时!' }]}>
            <InputNumber placeholder="请输入加班工时" precision={0} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="加班工资"
            name="overtimeSalary"
            rules={[{ required: true, message: '请输入加班工资!' }]}>
            <InputNumber placeholder="请输入加班工资" precision={0} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="备注"
            name="remark"
            rules={[{ required: false, message: '请输入备注!' }]}>
            <Input.TextArea placeholder="请输入备注" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
