import {makeAutoObservable, runInAction} from "mobx";
import {Page, StateFullData} from "@services/dto/common";
import {RevenueExpenditureService} from "@services/RevenueExpenditureService";
import {PageParam, RevenueExpenditureRow} from "@services/dto/revenueExpenditure";

export class RevenueExpenditureStore {
  revenueExpenditureDatasource: StateFullData<Page<RevenueExpenditureRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getRevenueExpenditure(req: PageParam) {
    this.revenueExpenditureDatasource = {...this.revenueExpenditureDatasource, loading: true}
    RevenueExpenditureService.getRevenueExpenditure(req).then(rsp => {
      runInAction(() => this.revenueExpenditureDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.revenueExpenditureDatasource = {...this.revenueExpenditureDatasource, loading: false, err})
    })
  }
}

export const revenueExpenditureStore = new RevenueExpenditureStore();
