import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {SealApplicationService} from "@services/SealApplicationService";
import {SealApplicationReq, SealApplicationRow} from "@services/dto/sealApplication";

export class SealApplicationStore {
  sealApplicationDatasource: StateFullData<Page<SealApplicationRow>> = {}
  sealApplicationSelectDatasource: StateFullData<Page<SealApplicationRow>> = {}
  sealApplicationRow?: SealApplicationRow
  sealApplicationOption?: string
  sealApplicationDetail: StateFullData<ResponseEntity<SealApplicationRow>> = {}
  sealApplicationDetails: StateFullData<ResponsesEntity<SealApplicationRow>> = {}
  sealApplicationSearchReq: SealApplicationReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateSealApplicationRow(row: SealApplicationRow) {
    this.sealApplicationRow = {...row}
  }

  updateSealApplicationOption(option: string) {
    this.sealApplicationOption = option
  }

  updateSealApplicationSearchReq(req: SealApplicationReq) {
    this.sealApplicationSearchReq = {...this.sealApplicationSearchReq, ...req}
  }

  getSealApplication(req: SealApplicationReq) {
    this.sealApplicationDatasource = {...this.sealApplicationDatasource, loading: true}
    this.sealApplicationSearchReq = {...this.sealApplicationSearchReq, ...req}
    SealApplicationService.getSealApplication(req).then(rsp => {
      runInAction(() => this.sealApplicationDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.sealApplicationDatasource = {...this.sealApplicationDatasource, loading: false, err})
    })
  }

  getSelectSealApplication(req: SealApplicationReq) {
    this.sealApplicationSelectDatasource = {...this.sealApplicationSelectDatasource, loading: true}
    SealApplicationService.getSealApplication(req).then(rsp => {
      runInAction(() => this.sealApplicationSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.sealApplicationSelectDatasource = {...this.sealApplicationSelectDatasource, loading: false, err})
    })
  }

  getOne(id: string) {
    this.sealApplicationDetail = {...this.sealApplicationDetail, loading: true}
    SealApplicationService.getOne(id).then(rsp => {
      runInAction(() => this.sealApplicationDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.sealApplicationDetail = {...this.sealApplicationDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.sealApplicationDetails = {...this.sealApplicationDetails, loading: true}
    SealApplicationService.getBatch(ids).then(rsp => {
      runInAction(() => this.sealApplicationDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.sealApplicationDetails = {...this.sealApplicationDetails, loading: false, err})
    })
  }

  async getPrintList(record: SealApplicationRow): Promise<LabelValue[]> {

    const row = this.sealApplicationDatasource.data?.items.find(o => o.id === record.id)

    return [
      {label: '制单日期', value: row.saDate as string},
      {label: '制单编号', value: row.saCode},
      {label: '印章名称', value: row.saName},
      {label: '摘要', value: row.saDesc},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const sealApplicationStore = new SealApplicationStore();
