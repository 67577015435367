import React, {createContext, useContext} from "react";

export const ModalSelectContext = createContext(null)

export const useModalSelect = () => {
  return useContext(ModalSelectContext)
}

export interface SelectRowKeyService {
  getSelectedRowKeys()
  setSelectedRowKeys(rowKeys: React.Key[])
}
