import React, {FC} from "react";
import {observer} from "mobx-react";
import {ProgressSteps} from "@modules/HR/Components/Hire";
import {FileRow, TodoRow, UpdateWorkerRecruitmentRow} from "@services/dto/workerRecruitment";
import {message, UploadFile} from "antd";
import {getUploadIds, showErr} from "@common/utils";
import {ToolService} from "@services/ToolService";
import {DATE_SECONDS_FMT, WorkerRecruitmentStatus} from "@/config";
import {WorkerRecruitmentService} from "@services/WorkerRecruitmentService";
import {allStores} from "@/stores";
import dayjs from "dayjs";

export const Hire:FC = observer(() => {
  const {wrStore} = allStores;
  const {wr: stateWr} = wrStore
  const wr = stateWr.data!;

  const onNext = async (row: TodoRow, index: number, lastStep: boolean, fileList?: UploadFile[]) => {
    const checked = [...wr.hireTodoChecked || []]
    if (fileList) {
      const files: FileRow[] = []
      fileList.forEach(item => {
        let url = item.response ? item.response.url : item.url;
        files.push({
          name: "",
          fileUrl: url,
          createdAt: dayjs().format(DATE_SECONDS_FMT)
        })
      })
      let ids = getUploadIds(fileList)
      if (ids) {
        try {
          await ToolService.submittedSuccess(ids, row.id);
        } catch (e) {
          showErr(e)
          return
        }
      }
      checked[index] = {
        ...checked[index],
        typeId: row.id,
        files
      };
    } else {
      checked[index] = {
        ...checked[index],
        typeId: row.id,
        isToDo: true,
        type: row.type
      };
    }
    const wrObj: UpdateWorkerRecruitmentRow = {
      hireTodoChecked: checked.map(item => ({
        typeId: item.typeId,
        isToDo: item.isToDo,
        type: item.type,
        files: item.files
      }))
    }
    if (lastStep && !checked.some(i => !i.isToDo)) {
      wrObj.status = WorkerRecruitmentStatus.hired
    }
    WorkerRecruitmentService.updateWorkerRecruitment(wr._id, wrObj).then(rsp => {
      message.success(row.title + "完成")
      wrStore.updateHireTodo(checked)
    }).catch(showErr)
  }
    
  return (
    <>
      <ProgressSteps rows={wr.hireTodo}
        finalStepLabel={'完成入职'}
        checked={wr.hireTodoChecked}
        onNext={onNext}/>
    </>
  )
})