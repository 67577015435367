import {WorkPostReq, WorkPostRow} from "@services/dto/workPost";
import {apiV1} from "@services/apiHelper";
import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";

export class WorkPostService {
  static async searchWorkPost(req: WorkPostReq): Promise<Page<WorkPostRow>> {
    return apiV1.get("/jobs", { params: req })
  }
  static async addWorkPost(req: WorkPostRow): Promise<ResponseEntity<WorkPostRow>> {
    return apiV1.post("/jobs", { ...req })
  }
  static async updateWorkPost(req: WorkPostRow): Promise<ResponseEntity<WorkPostRow>> {
    return apiV1.put("/jobs", { ...req })
  }
  static async deleteWorkPost(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/jobs", { data: ids })
  }
  static async getOne(ids: string): Promise<ResponseEntity<WorkPostRow>> {
    return await apiV1.delete("/jobs", { data: ids })
  }
  static async getBatch(ids: string[]): Promise<ResponsesEntity<WorkPostRow>> {
    return await apiV1.delete("/jobs", { data: ids })
  }
}
