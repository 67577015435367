import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {ProcessReq, ProcessRow} from "@services/dto/process";

export class ProcessService {
  static async getProcess(req: ProcessReq): Promise<Page<ProcessRow>> {
    return await apiV1.get("/workNotice/search", {params: req})
  }

  static async addProcess(row: ProcessRow): Promise<ResponseEntity<ProcessRow>> {
    return await apiV1.post("/workNotice/save", {...row})
  }

  static async updateProcess(row: ProcessRow): Promise<ResponseEntity<ProcessRow>> {
    return await apiV1.put("/workNotice/update", {...row})
  }

  static async deleteProcess(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/workNotice/delete", {data: ids})
  }

  static async getOne(id: string): Promise<ResponseEntity<ProcessRow>> {
    return await apiV1.get(`/workNotice/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<ProcessRow>> {
    return await apiV1.get(`/workNotice/getBatch/${ids.join(',')}`)
  }
}
