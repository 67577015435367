import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {SumHeaderAction} from "@modules/Compensation/Components/SumHeaderAction";
import {MonthSalaryTable} from "@modules/Compensation/Components/MonthSalaryTable";
import {MonthSalaryReq, MonthSalarySumRow} from "@services/dto/monthSalary";
import {allStores} from "@/stores";
import {message} from "antd";
import {MonthSalaryService} from "@services/MonthSalaryService";
import {previewFile} from "@components/CustomUpload";
import dayjs from "dayjs";
import {showErr} from "@common/utils";

export const MonthSalarySum:FC = observer(() => {
  const {monthSalaryStore} = allStores
  const {monthSalarySumRowData: datasource} = monthSalaryStore
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<MonthSalarySumRow[]>([]);
  const [monthSalaryReq, setMonthSalaryReq] = useState<MonthSalaryReq>({
    pageNum: 1,
    perPage: 10
  })

  useEffect(() => {
    refreshData(monthSalaryReq)
  }, [])

  const refreshData = (req: MonthSalaryReq) => {
    monthSalaryStore.getMonthStatisticsSalary(req)
  };

  const handleTimeChange = (dateStrings: string[]) => {
    const req = {...monthSalaryReq}
    req.pageNum = 1
    req.startYear = ""
    req.startMonth = ""
    req.endYear = ""
    req.endMonth = ""
    if (dateStrings.length > 0) {
      if (dateStrings[0] && dateStrings[1]) {
        req.startYear = dayjs(dateStrings[0]).year();
        req.startMonth = dayjs(dateStrings[0]).month() + 1
        req.endYear = dayjs(dateStrings[1]).year()
        req.endMonth = dayjs(dateStrings[1]).month() + 1
      }
    }
    setMonthSalaryReq(req)
    refreshData(req)
  }

  const handleProjectIdChange = (projectId: string) => {
    const req = {...monthSalaryReq}
    req.pageNum = 1
    req.projectId = projectId
    setMonthSalaryReq(req)
    refreshData(req)
  }

  const exportMonthSalarySum = () => {
    if (selectedRows.length === 0) {
      message.warning("请选择要导出的数据！")
      return
    }
    setConfirmLoading(true)
    MonthSalaryService.exportMonthStatisticsSalary(selectedRows).then(rsp => {
      previewFile(rsp.data)
    }).catch(showErr).finally(() => setConfirmLoading(false))
  }

  const handlePageChange = (page: number, pageSize: number) => {
    const req = {...monthSalaryReq}
    req.pageNum = page
    req.perPage = pageSize
    setMonthSalaryReq(req)
    refreshData(req)
  }
  
  const handleChange = (selectedRowKeys: React.Key[], selectedRows: MonthSalarySumRow[]) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRows)
  }

  return (
    <>
      <SumHeaderAction 
        handleTimeChange={handleTimeChange} 
        handleProjectIdChange={handleProjectIdChange} 
        exportMonthSalarySum={exportMonthSalarySum}/>

      <MonthSalaryTable
        className={"margin-top-sm"}
        loading={datasource.loading || confirmLoading}
        total={datasource.data?.data.total!}
        perPage={monthSalaryReq.perPage}
        pageNum={monthSalaryReq.pageNum}
        selectedRowKeys={selectedRowKeys}
        handlePageChange={handlePageChange}
        handleChange={handleChange}
        dataSource={datasource.data?.data.items!}/>
    </>
  )
})