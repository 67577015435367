import {makeAutoObservable, runInAction} from "mobx";
import {Page, StateFullData} from "@services/dto/common";
import {MaterialsOutBoundReq, MaterialsOutBoundRow} from "@services/dto/materialsOutBound";
import {MaterialsOutBoundService} from "@services/MaterialsOutBoundService";

export class MaterialsOutBoundStore {
  materialsOutBoundDatasource: StateFullData<Page<MaterialsOutBoundRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getMaterialsOutBound(req: MaterialsOutBoundReq) {
    this.materialsOutBoundDatasource = {...this.materialsOutBoundDatasource, loading: true}
    MaterialsOutBoundService.getMaterialsOutBound(req).then(rsp => {
      runInAction(() => this.materialsOutBoundDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.materialsOutBoundDatasource = {...this.materialsOutBoundDatasource, loading: false, err})
    })
  }
}

export const materialsOutBoundStore = new MaterialsOutBoundStore();
