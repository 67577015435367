import {makeAutoObservable} from "mobx";

export class ScreenResolutionStore {
  screenResolution:{width:number, height:number} = { width: window.innerWidth, height: window.innerHeight }

  constructor() {
    makeAutoObservable(this)
  }

  updateScreenResolution(screenResolution:{width:number, height:number}) {
    this.screenResolution = screenResolution
  }
}

export const screenResolutionStore = new ScreenResolutionStore()
