import React, {FC, useState} from "react";
import {observer} from "mobx-react";
import {Button, Col, DatePicker, Row} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import {SelectProject} from "@components/SelectProject";

export interface PropsType {
  handleTimeChange: (dateStrings: string[]) => void
  handleProjectIdChange: (projectId: string) => void
  handleDeleteBatch: () => void
  handleAdd: () => void
}

type RangeValue = [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;

export const HeaderAction:FC<PropsType> = observer((props: PropsType) => {
  const [timeValue, setTimeValue] = useState<RangeValue>(null);

  const handleTimeChange = (dates: RangeValue, dateStrings: [string, string]) => {
    setTimeValue(dates)
    props.handleTimeChange(dateStrings)
  }

  const handleProjectIdChange = (value: string) => {
    props.handleProjectIdChange(value)
  }
  
  const handleDeleteBatch = () => {
    props.handleDeleteBatch()
  }
  
  const handleAdd = () => {
    props.handleAdd()
  }

  return (
    <>
      <Row gutter={16}>
        <Col xl={5} md={6} xs={12}>
          <SelectProject allowClear onChange={handleProjectIdChange}/>
        </Col>
        <Col xl={7} md={9} xs={12}>
          <DatePicker.RangePicker value={timeValue} onChange={handleTimeChange} className={"width-100-percentage"}/>
        </Col>
        <Col xl={12} md={9} xs={24} className={"text-align-right"}>
          <Button icon={<DeleteOutlined/>} size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={() => handleDeleteBatch()}>删除</Button>

          <Button icon={<PlusOutlined/>} type={"primary"} size={"middle"} shape={"round"}
            onClick={handleAdd}>新增</Button>
        </Col>
      </Row>
    </>
  )
})