import React, { useEffect } from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import { FormInstance } from "antd/es";
import { observer } from "mobx-react";
import dayjs from "dayjs";
import {SettleApplyRow} from "@services/dto/settleApply";
import {SelectFiling} from "@components/SelectFiling";

export interface PropsType {
  row: SettleApplyRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    const settleApplyRow = { ...props.row }
    if (settleApplyRow.id) {
      settleApplyRow.startDate = dayjs(settleApplyRow.startDate)
    }
    form.setFieldsValue(settleApplyRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ status: 1, isVirtualUser: 0 }}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="核算对象"
            name="checkObject"
            rules={[{ required: true, message: '请输入核算对象!' }]}>
            <Input placeholder="请输入核算对象" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="存档批号"
            name="filing"
            rules={[{ required: true, message: '请选择存档批号!' }]}>
            <SelectFiling placeholder={"请选择存档批号"} allowClear />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="发起日期"
            name="startDate"
            rules={[{ required: true, message: '请选择发起日期!' }]}>
            <DatePicker placeholder={"请选择发起日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="申请单号"
            name="applyNumber"
            rules={[{ required: true, message: '请输入申请单号!' }]}>
            <Input placeholder="请输入申请单号" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="申请单标题"
            name="applyTitle"
            rules={[{ required: true, message: '请输入申请单标题!' }]}>
            <Input placeholder="请输入申请单标题" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="申请金额"
            name="applyAmount"
            rules={[{ required: true, message: '请输入申请金额!' }]}>
            <InputNumber placeholder="请输入申请金额" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="实现金额"
            name="realizeAmount"
            rules={[{ required: true, message: '请输入实现金额!' }]}>
            <InputNumber placeholder="请输入实现金额" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="余额"
            name="balance"
            rules={[{ required: true, message: '请输入余额!' }]}>
            <InputNumber placeholder="请输入余额" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联合同编号"
            name="contractNumber"
            rules={[{ required: true, message: '请输入关联合同编号!' }]}>
            <Input placeholder="请输入关联合同编号" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="备注"
            name="remark"
            rules={[{ required: true, message: '请输入备注!' }]}>
            <Input.TextArea placeholder="请输入备注" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
