import {Page, StateFullData} from "@services/dto/common";
import {OrganizationalRow, PageParam} from "@services/dto/organizational";
import {makeAutoObservable, runInAction} from "mobx";
import {OrganizationalService} from "@services/OrganizationalService";

export class OrganizationalStore {
  organizationalDatasource: StateFullData<Page<OrganizationalRow>> = {}
  organizationalSelectDatasource: StateFullData<Page<OrganizationalRow>> = {}
  organizationalRow?: OrganizationalRow
  organizationalOption?: string
  organizationalDetail: StateFullData<OrganizationalRow> = {}
  organizationalDetails: StateFullData<OrganizationalRow[]> = {}
  organizationalReq: PageParam = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateOrganizationalRow(row: OrganizationalRow) {
    this.organizationalRow = {...row}
  }

  updateFundApprovalOption(option: string) {
    this.organizationalOption = option
  }

  getOrganizational(req: PageParam) {
    this.organizationalDatasource = {...this.organizationalDatasource, loading: true}
    this.organizationalReq = {...this.organizationalReq, ...req}
    OrganizationalService.getOrganizational(req).then(rsp => {
      runInAction(() => {
        this.organizationalDatasource = {data: rsp, loading: false}
      })
    }).catch(e => {
      runInAction(() => this.organizationalDatasource = {...this.organizationalDatasource, loading: false, err: e})
    })
  }

  getSelectOrganizational(req: PageParam) {
    this.organizationalSelectDatasource = {...this.organizationalSelectDatasource, loading: true}
    OrganizationalService.getOrganizational(req).then(rsp => {
      runInAction(() => {
        this.organizationalSelectDatasource = {data: rsp, loading: false}
      })
    }).catch(e => {
      runInAction(() => this.organizationalSelectDatasource = {...this.organizationalSelectDatasource, loading: false, err: e})
    })
  }

  getOne(id: string) {
    this.organizationalDetail = {...this.organizationalDetail, loading: true}
    OrganizationalService.getOne(id).then(rsp => {
      runInAction(() => this.organizationalDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.organizationalDetail = {...this.organizationalDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.organizationalDetails = {...this.organizationalDetails, loading: true}
    OrganizationalService.getBatch(ids).then(rsp => {
      runInAction(() => this.organizationalDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.organizationalDetails = {...this.organizationalDetails, loading: false, err})
    })
  }
}

export const organizationalStore = new OrganizationalStore();
