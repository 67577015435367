import {observer} from "mobx-react";
import {ColumnsType} from "antd/es/table";
import {FundApprovalReq, FundApprovalRow} from "@services/dto/fundApproval";
import {
  LARGER_MODAL_WIDTH,
  SMALL_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import {FormInstance, Space, Table, Tag, Typography} from "antd";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import React, {useEffect, useState} from "react";
import {HeaderActionCom} from "@modules/FundApproval/Components/HeaderActionCom";
import {allStores} from "@/stores";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {previewFile} from "@components/CustomUpload";
import {CustomDraggableModal} from "@components/CustomDraggableModal";

export interface PropsType {
  open: boolean
  loading: boolean
  onChange: (open: boolean) => void
  handleOk: (keys: React.Key[]) => void
}

const narrowShowColumn: string[] = [
  "faDate",
  "faCode",
  "faType",
  "faSummary",
  "faAmount",
  // "faPaymentAccount",
  // "faAccountsReceivable",
  // "faPayDate",
  // "faStatus",
  "statusCommon",
  "action",
]

export const FundApprovalTableModal = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {
  const {fundApprovalStore, tableColumnsStore} = allStores
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [dataSource, setDataSource] = useState<FundApprovalRow[]>([])
  const [total, setTotal] = useState<number>(0)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<FundApprovalRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(narrowShowColumn)
  const [searchReq, setSearchReq] = useState<FundApprovalReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...fundApprovalStore.fundApprovalReq
  })

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem('/fundApproval') || []
    if (!showColumn.length) return
    // 检验存储中的列表是否符合规范
    showColumn = showColumn.filter(o => columns.some(column => column.key === o))
    // 无论有无存储都触发change，保存当前显示的字段
    setShowColumn(showColumn)

    refreshData(searchReq)
  }, []);

  useEffect(() => {
    setIsOpen(props.open)
  }, [props.open]);

  /**
   * @description 表格数据变化
   */
  useEffect(() => {
    if (fundApprovalStore.fundApprovalDatasource.data) {
      setDataSource(fundApprovalStore.fundApprovalDatasource.data.items || []);
      setTotal(fundApprovalStore.fundApprovalDatasource.data.total || 0)
    }
  }, [fundApprovalStore.fundApprovalDatasource.data])

  /**
   * @description 全局查询
   */
  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  /**
   * 列配置变化
   */
  useEffect(() => {
    setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))))
  }, [showColumn])

  /**
   * 文件预览
   * @param record
   */
  const handlePreview = (record: FundApprovalRow) => {
    if (typeof record.faFileList === 'string') {
      previewFile(record.faFileList)
    }
  }

  const columns: ColumnsType<FundApprovalRow> = [
    {
      title: '制单日期',
      dataIndex: 'faDate',
      key: 'faDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'faCode',
      key: 'faCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '款项类别',
      dataIndex: 'faType',
      key: 'faType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
    },
    {
      title: '款项摘要',
      dataIndex: 'faSummary',
      key: 'faSummary',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '金额',
      dataIndex: 'faAmount',
      key: 'faAmount',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '付款账户',
      dataIndex: 'faPaymentAccount',
      key: 'faPaymentAccount',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    // {
    //   title: '付款账户简称',
    //   dataIndex: 'faPaymentAccount',
    //   key: 'faPaymentAccount',
    //   align: 'center',
    //   width: TABLE_CELL_WIDTH_2x,
    //   render: (text) => (
    //     <Space>
    //       <TableEllipsisText value={text}></TableEllipsisText>
    //     </Space>
    //   )
    // },
    {
      title: '付款账户名称',
      dataIndex: 'baName1',
      key: 'baName1',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '付款账户账号',
      dataIndex: 'baAccount1',
      key: 'baAccount1',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '付款账户银行',
      dataIndex: 'baBlank1',
      key: 'baBlank1',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '收款账户',
      dataIndex: 'faAccountsReceivable',
      key: 'faAccountsReceivable',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    // {
    //   title: '收款账户简称',
    //   dataIndex: 'faAccountsReceivable',
    //   key: 'faAccountsReceivable',
    //   align: 'center',
    //   width: TABLE_CELL_WIDTH_2x,
    //   render: (text) => (
    //     <Space>
    //       <TableEllipsisText value={text}></TableEllipsisText>
    //     </Space>
    //   )
    // },
    {
      title: '收款账户名称',
      dataIndex: 'baName2',
      key: 'baName2',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '收款账户账号',
      dataIndex: 'baAccount2',
      key: 'baAccount2',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '收款账户银行',
      dataIndex: 'baBlank2',
      key: 'baBlank2',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联申请',
      dataIndex: 'faApplicationForm',
      key: 'faApplicationForm',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联合同',
      dataIndex: 'faContractCode',
      key: 'faContractCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联发票',
      dataIndex: 'lnvoiceRegistrationId',
      key: 'lnvoiceRegistrationId',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'faFileList',
      key: 'faFileList',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text, record) => (
        <Space>
          {
            text && <Typography.Text
              type="success"
              className={'c-pointer'}
              onClick={() => {
                handlePreview(record)
              }}>查看</Typography.Text>
          }
        </Space>
      )
    },
    {
      title: '实现日期',
      dataIndex: 'faPayDate',
      key: 'faPayDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x
    },
    {
      title: '经办记录',
      dataIndex: 'personResponsible',
      key: 'personResponsible',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'faStatus',
      key: 'faStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x
    },
    {
      title: '校验',
      dataIndex: 'statusCommon',
      key: 'statusCommon',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <Tag color={text === '完成' ? "success" : 'error'}>{ text }</Tag>
        </Space>
      )
    },
  ];

  const handleOk = () => {
    // if (selectedRowKeys.length !== 1) {
    //   message.warning("仅可选择一条资金审批数据！").then()
    //   return
    // }
    props.handleOk(selectedRowKeys)
  }

  const handleCancel = () => {
    props.onChange(false)
  }

  const refreshData = (req: FundApprovalReq) => {
    setSearchReq(req)
    fundApprovalStore.getFundApproval(req)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem('/fundApproval', value)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSelectedRowKeys([])
    setSearchReq(req)
    refreshData(req)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    fundApprovalStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    setShowColumn(narrowShowColumn)
  }

  return (
    <CustomDraggableModal
      title={<div className="text-align-center">关联资金审批</div>}
      open={isOpen}
      width={LARGER_MODAL_WIDTH}
      onOk={handleOk}
      onCancel={handleCancel}>

      <HeaderActionCom
        locationPathname={'/fundApproval'}
        collapsed={fundApprovalStore.collapsed}
        setCollapsed={handleCollapsedChange}
        handleShowColumnReset={handleShowColumnReset}
        searchReq={searchReq}
        size={6}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={columns}
        refreshData={(req) => refreshData({...searchReq, ...req})}/>
      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={dataSource}
        columns={renderColumns}
        size={"middle"}
        loading={fundApprovalStore.fundApprovalDatasource.loading || props.loading}
        bordered
        scroll={{y: SMALL_TABLE_SCROLL_HEIGHT, scrollToFirstRowOnChange: true}}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys, columnWidth: 50}}/>
    </CustomDraggableModal>

  )
}))
