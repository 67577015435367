import React, { useEffect } from "react";
import {Col, Form, Input, Row} from "antd";
import { FormInstance } from "antd/es";
import { observer } from "mobx-react";
import {CooperativeUnitRow} from "@services/dto/cooperativeUnit";

export interface PropsType {
  row: CooperativeUnitRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    const cooperativeUnitRow = { ...props.row }
    form.setFieldsValue(cooperativeUnitRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="单位名称"
            name="cuName"
            rules={[{ required: true, message: '请输入单位名称!' }]}>
            <Input placeholder="请输入单位名称" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
