import {apiV1} from "@services/apiHelper";
import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {DictManagementReq, DictManagementRow} from "@services/dto/dictManagement";

export class DictManagementService {
  static async addDictManagement(entity: DictManagementRow): Promise<ResponseEntity<DictManagementRow>> {
    return await apiV1.post('/dict', {...entity});
  }

  static async deleteDictManagementById(id: string): Promise<ResponsesEntity<string>> {
    return await apiV1.delete(`/dict/${id}`);
  }

  static async updateDictManagementById(id: string, talent: DictManagementRow): Promise<ResponseEntity<DictManagementRow>> {
    return await apiV1.put(`/dict/${id}`, {...talent});
  }

  static async getDictManagement(req: DictManagementReq): Promise<Page<DictManagementRow>> {
    return await apiV1.get('/dict', {params: req});
  }

  static async getByKey(key: string): Promise<DictManagementRow> {
    return await apiV1.get(`/dict/getByKey/${key}`);
  }
}
