import {apiV1} from "@services/apiHelper";
import {OrganizationalRow, PageParam} from "@services/dto/organizational";
import {Page, ResponseEntity} from "@services/dto/common";

export class OrganizationalService {
  static async addOrganizational(entity: OrganizationalRow): Promise<OrganizationalRow> {
    return await apiV1.post('/user/save', {...entity});
  }

  static async deleteOrganizationalById(id: string): Promise<void> {
    return await apiV1.delete(`/user/del/${id}`);
  }

  static async updateOrganizationalById(id: string, talent: OrganizationalRow): Promise<OrganizationalRow> {
    return await apiV1.put(`/user/put/${id}`, {...talent});
  }

  static async getOrganizational(req: PageParam): Promise<Page<OrganizationalRow>> {
    return await apiV1.get('/user/get', {params: req});
  }

  static async getOne(id: string): Promise<OrganizationalRow> {
    return await apiV1.get(`/user/get/${id}`);
  }

  static async getBatch(ids: string[]): Promise<OrganizationalRow[]> {
    return await apiV1.get(`/user/getBatch/${ids.join(',')}`);
  }

  static async deleteBatchOrganizationalByIds(ids: string[]): Promise<void> {
    return await apiV1.delete("/user/delBatch", {data: {ids}})
  }

  static async getDetailById(id: string): Promise<ResponseEntity<OrganizationalRow>> {
    return await apiV1.get(`/user/getOne/${id}`)
  }
}
