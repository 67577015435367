import {makeAutoObservable} from "mobx";

type columnsItem = {
  pathname: string
  columns: string[]
}

/**
 * @desc 模拟localStorage
 */
export class TableColumnsStore {

  columnsList: columnsItem[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setItem(pathname: string, columns: string[]) {
    let columnsList = this.columnsList
    columnsList = columnsList.filter(o => o.pathname !== pathname)
    this.columnsList = [...columnsList, {pathname, columns}]
  }

  getItem(pathname: string): string[] | undefined{
    return this.columnsList.find(o=>o.pathname===pathname)?.columns
  }
}

export const tableColumnsStore = new TableColumnsStore()
