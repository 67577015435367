import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {HeaderAction, PropsType as ParentPropsType} from "@components/HeaderAction";
import {ColumnsType} from "antd/es/table";
import {WorkerRecruitmentReq} from "@services/dto/workerRecruitment";
import {SelectProject} from "@components/SelectProject";
import {Select} from "antd";
import {ProfessionalLeagues, ProjectRow} from "@services/dto/project";
import {SelectWorkerType} from "@components/SelectWorkerType";
import {SelectDict} from "@components/SelectDict";

export interface PropsType extends ParentPropsType {
  updateCount?: number, // 更新渲染标志
  collapsed?: boolean, // 是否展开
  handleShowColumnReset?: () => void, // 重置列操作
  setCollapsed?: (v: boolean) => void, // 展开事件
  searchReq?: WorkerRecruitmentReq, // 请求参数
  selectedRowKeys?: React.Key[], // 所选行
  handleDeleteBatch?: () => void,
  handleAdd?: () => void,
  handleTableColumnsChange?: (value: string[]) => void,
  showColumn?: string[],
  tableColumns?: ColumnsType,
  refreshData?: (req: WorkerRecruitmentReq) => void,
  size?: number
  isHiddenOptions?: boolean
  locationPathname?: string
  items?: React.ReactNode[]
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [professionalLeagues, setProfessionalLeagues] = useState<ProfessionalLeagues[]>([])
  const [searchReq, setSearchReq] = useState<WorkerRecruitmentReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: WorkerRecruitmentReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleWorkerGroupNameChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.workerGroupName = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleProfessionalChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.professional = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleProjectIdChange = (searchValue: string[], options:ProjectRow[]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.projectIds = searchValue
    setSearchReq(req)
    refreshData(req)

    let team: ProfessionalLeagues[] = []
    options.forEach(item => {
      if (searchValue.includes(item.id)) {
        team = team.concat(item.professionalLeagues)
      }
    })
    setProfessionalLeagues(team)
  }

  const handleProfessionalLeaguesNamesChange = (searchValue: string[]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.professionalLeaguesNames = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined


    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <HeaderAction
        size={props.size}
        isHiddenOptions={props.isHiddenOptions}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        selectedRowKeys={props.selectedRowKeys}
        handleDeleteBatch={props.handleDeleteBatch}
        handleAdd={props.handleAdd}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <SelectProject
            updateCount={props.updateCount}
            allowClear
            mode={"multiple"}
            onChange={handleProjectIdChange}
            value={searchReq?.projectId}/>,
          <SelectWorkerType
            className={'width-100-percentage'}
            allowClear
            onChange={handleWorkerGroupNameChange}
            value={searchReq?.workerGroupName}
            placeholder={'请选择工种'}/>,
          <SelectDict
            allowClear
            value={searchReq?.professional}
            code={"dict_professional"}
            placeholder={"请选择职位"}
            onChange={handleProfessionalChange}
            className={"width-100-percentage"}/>,
          <Select
            allowClear
            placeholder="请选择队部"
            className={"width-100-percentage "}
            value={searchReq?.professionalLeaguesNames}
            onChange={handleProfessionalLeaguesNamesChange}
            mode={"multiple"}
            fieldNames={{value: "name", label: "name"}}
            options={professionalLeagues}/>
        ] : []}/>
    </>
  )
}))
