import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import { FormInstance } from "antd/es";
import { observer } from "mobx-react";
import {SocialSecurityPaymentRow} from "@services/dto/socialSecurityPayment";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {allStores} from "@/stores";
import dayjs from "dayjs";
import {SelectFiling} from "@components/SelectFiling";
import {SelectDict} from "@components/SelectDict";
import {ProjectRow} from "@services/dto/project";
import {SelectBankAccount} from "@components/SelectBankAccount";
import {BankAccountRow} from "@services/dto/bankAccount";
import {ONFILE} from "@/config";

export interface PropsType {
  row: SocialSecurityPaymentRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()

  useEffect(() => {
    form.resetFields()
    const socialSecurityPaymentRow = { ...props.row }
    filingStore.setFilingFaCode(socialSecurityPaymentRow.sspCode)
    if (socialSecurityPaymentRow.id) {
      if (typeof socialSecurityPaymentRow.sspDate === 'string' && socialSecurityPaymentRow.sspDate !== '') {
        socialSecurityPaymentRow.sspDate = dayjs(socialSecurityPaymentRow.sspDate);
      }
      setResponsibilityProject(socialSecurityPaymentRow.responsibilityProject)
      setCooperationUnit(socialSecurityPaymentRow.cooperationUnit)
    }
    form.setFieldsValue(socialSecurityPaymentRow);
  }, [props.row])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    setResponsibilityProject(value)
    const row = options.find(o=> value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  const handleSspPaymentAccountChange = (value: string, options: BankAccountRow[]) => {
    let option = options.find(item => item.id === value)
    form.setFieldsValue({sspPaymentAccountTitle: option?.baName})
    form.setFieldsValue({sspPaymentBankAccount: option?.baAccount})
    form.setFieldsValue({sspPaymentDepositBank: option?.baBlank})
  }

  const handleSspReceiptAccountChange = (value: string, options: BankAccountRow[]) => {
    let option = options.find(item => item.id === value)
    form.setFieldsValue({sspReceiptAccountTitle: option?.baName})
    form.setFieldsValue({sspReceiptBankAccount: option?.baAccount})
    form.setFieldsValue({sspReceiptDepositBank: option?.baBlank})
  }


  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      initialValues={{fsStatus:'通过'}}
      wrapperCol={{ span: 14 }}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Form.Item hidden name="code">
          <Input />
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="sspDate"
            rules={[{ required: false, message: '请输入制单日期!' }]}>
            <DatePicker placeholder={"请输入制单日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="sspCode"
            rules={[{ required: false, message: '请输入制单编号!' }]}>
            <Input placeholder="请输入制单编号" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="款项类别"
            name="sspCategory"
            rules={[{ required: false, message: '请输入款项类别!' }]}>
            <SelectDict code={'tax_accounting_type '} placeholder={'请输入款项类别'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="款项摘要"
            name="sspDesc"
            rules={[{ required: false, message: '请输入款项摘要!' }]}>
            <Input.TextArea placeholder="请输入款项摘要" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="金额"
            name="sspMoney"
            rules={[{ required: false, message: '请输入金额!' }]}>
            <InputNumber min={0} precision={2} placeholder="请输入金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户"
            name="sspPaymentAccount"
            rules={[{ required: false, message: '请选择付款账户!' }]}>
            <SelectBankAccount
              className={'width-100-percentage'}
              allowClear
              isShowAction
              onChange={handleSspPaymentAccountChange}
              placeholder={'请选择付款账户'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户名称"
            name="sspPaymentAccountTitle"
            rules={[{ required: false, message: '请输入付款账户名称!' }]}>
            <Input placeholder="请输入付款账户名称" disabled />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款银行账号"
            name="sspPaymentBankAccount"
            rules={[{ required: false, message: '请输入付款银行账号!' }]}>
            <Input placeholder="请输入付款银行账号" disabled />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款开户银行"
            name="sspPaymentDepositBank"
            rules={[{ required: false, message: '请输入付款开户银行!' }]}>
            <Input placeholder="请输入付款开户银行" disabled />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户"
            name="sspReceiptAccount"
            rules={[{ required: false, message: '请选择收款账户!' }]}>
            <SelectBankAccount
              className={'width-100-percentage'}
              allowClear
              isShowAction
              onChange={handleSspReceiptAccountChange}
              placeholder={'请选择收款账户'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户名称"
            name="sspReceiptAccountTitle"
            rules={[{ required: false, message: '请输入收款账户名称!' }]}>
            <Input placeholder="请输入收款账户名称" disabled />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款银行账号"
            name="sspReceiptBankAccount"
            rules={[{ required: false, message: '请输入收款银行账号!' }]}>
            <Input placeholder="请输入收款银行账号" disabled />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款开户银行"
            name="sspReceiptDepositBank"
            rules={[{ required: false, message: '请输入收款开户银行!' }]}>
            <Input placeholder="请输入收款开户银行" disabled />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任人"
            name="personResponsible"
            rules={[{ required: true, message: '请选择责任人!' }]}>
            <SelectPersonCharge className={"width-100-percentage"} placeholder={"请选择负责人"} allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{ required: true, message: '请选择责任部门!' }]}>
            <SelectProject placeholder={"请选择责任部门"} onChange={handleProjectChange} allowClear fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{ required: true, message: '请输入协作单位!' }]}>
            <SelectCooperativeUnit allowClear onChange={(value) => setCooperationUnit(value)} fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        {
          props.row.id && <Col span={24}>
            <Form.Item
              label="存档批号"
              name="fsFile"
              rules={[{ required: false, message: '请选择存档批号!' }]}>
              <SelectFiling
                responsibilityProject={responsibilityProject}
                cooperationUnit={cooperationUnit}
                placeholder={"请选择存档批号"}
                inputDisabled
                fieldNames={{value: "id", label: "faBatchNumber"}}
                allowClear/>
            </Form.Item>
          </Col>
        }
        { /* TODO 实现日期、实现金额、余额 三个字段不可输入，以后通过关联资金审批自动带出，表格列表也要进行展示这三个字段 */ }
        <Col span={24}>
          <Form.Item
            label="实现日期"
            name="ambitionDate"
            rules={[{ required: false, message: '请输入实现日期!' }]}>
            <Input disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="实现金额"
            name="realCashBalance"
            rules={[{ required: false, message: '请输入实现金额!' }]}>
            <Input disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="余额"
            name="balance"
            rules={[{ required: false, message: '请输入余额!' }]}>
            <Input disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="sspStatus"
            rules={[{ required: false, message: '请输入办理进度!' }]}>
            <SelectDict
              code={'common_type3'}
              disabled={props.row.sspStatus === ONFILE}
              placeholder={'请选择办理进度'}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
