import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {MonthHeaderAction} from "@modules/Compensation/Components/MonthHeaderAction";
import {DEFAULT_MODAL_WIDTH} from "@/config";
import {Button, Drawer, message} from "antd";
import {FormInstance} from "antd/es";
import {MonthAdminTable} from "@modules/Compensation/Components/MonthAdminTable";
import {MonthAdminForm} from "@modules/Compensation/Components/MonthAdminForm";
import {allStores} from "@/stores";
import {MonthSalaryRow, MonthSalaryReq, UpdateMonthSalary, MonthSalaryExportReq} from "@services/dto/monthSalary";
import {MonthSalaryService} from "@services/MonthSalaryService";
import {previewFile} from "@components/CustomUpload";
import {logger, showErr} from "@common/utils";
import dayjs from "dayjs";
import {MonthPersonTable} from "@modules/Compensation/Components/MonthPersonTable";
import {MonthPersonForm} from "@modules/Compensation/Components/MonthPersonForm";

export interface PropsType {
    personnelCategory: string
    type: number
}

export const MonthSalary:FC<PropsType> = observer((props: PropsType) => {
  const {monthSalaryStore} = allStores
  const {monthSalaryData: datasource} = monthSalaryStore
  const [row, setRow] = useState<MonthSalaryRow>()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [visible, setVisible] = useState<boolean>(false)
  const monthForm = React.createRef<FormInstance>()
  const [monthSalaryReq, setMonthSalaryReq] = useState<MonthSalaryReq>({
    pageNum: 1,
    perPage: 10,
    year: dayjs().year(),
    month: dayjs().month() + 1,
    personnelCategory: props.personnelCategory
  })

  useEffect(() => {
    refreshData(monthSalaryReq)
  }, [])

  const refreshData = (req: MonthSalaryReq) => {
    monthSalaryStore.getMonthPersonSalary(req)
  };

  const handleProjectChange = (projectId: string) => {
    const req = {...monthSalaryReq}
    req.pageNum = 1
    req.projectId = projectId
    setSelectedRowKeys([])
    setMonthSalaryReq(req)
    refreshData(req)
  };

  const handleSearch = (searchValue: string) => {
    const req = {...monthSalaryReq}
    req.pageNum = 1
    req.keyword = searchValue
    setMonthSalaryReq(req)
    refreshData(req)
  };

  const handleDateChange = (year: number, month: number) => {
    const req = {...monthSalaryReq}
    req.pageNum = 1
    req.year = year
    req.month = month
    setSelectedRowKeys([])
    setMonthSalaryReq(req)
    refreshData(req)
  };

  const exportSalary = () => {
    if (!monthSalaryReq.projectId) {
      message.warning("请先选择项目进行导出！")
      return
    }
    setConfirmLoading(true)
    const ids = selectedRowKeys.map(item => String(item));
    const exportReq:MonthSalaryExportReq = {
      ids,
      projectId: monthSalaryReq.projectId,
      year: monthSalaryReq.year!,
      month: monthSalaryReq.month!
    }
    MonthSalaryService.exportMonthSalary(props.type, exportReq).then(rsp => {
      previewFile(rsp.data)
    }).catch(showErr).finally(() => setConfirmLoading(false))
  }

  const handleBatchDelete = () => {
    if (selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    const ids:string[] = selectedRowKeys.map(item => String(item))
    setConfirmLoading(true)
    MonthSalaryService.deleteBatchMonthSalary(ids).then(rsp => {
      message.success("删除成功")
      refreshData(monthSalaryReq)
    }).catch(showErr).finally(() => setConfirmLoading(false))
  };

  const handlePageChange = (page: number, pageSize: number) => {
    const req = {...monthSalaryReq}
    req.pageNum = page
    req.perPage = pageSize
    setMonthSalaryReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const handleOk = () => {
    monthForm.current?.validateFields().then(value => {
      const updateMonthSalary: UpdateMonthSalary = {
        totalShouldBePay: value.totalShouldBePay,
        totalSalary: value.totalSalary,
        sui: value.sui,
        overtimePay: value.overtimePay,
        peopleTakePart: value.peopleTakePart,
        dailyWages: value.dailyWages,
        attendanceDays: value.attendanceDays,
        attendanceDaysSum: value.attendanceDaysSum,
        totalOvertimeHours: value.totalOvertimeHours,
        lastMonthWithout: value.lastMonthWithout,
        lastMonthMultiple: value.lastMonthMultiple,
        forTheFare: value.forTheFare,
        physicalExamination: value.physicalExamination,
        fullTrainingInMarch: value.fullTrainingInMarch,
        employeesBedding: value.employeesBedding,
        deductionLaborInsurance: value.deductionLaborInsurance,
        buckleAdvancePayments: value.buckleAdvancePayments,
        projectDepartmentUndertakesToBuckle: value.projectDepartmentUndertakesToBuckle,
        undertakesToBuckleHeadquarters: value.undertakesToBuckleHeadquarters,
        theFareSubsidies: value.theFareSubsidies,
        deductions: value.deductions,
        buckleFee: value.buckleFee,
        withholdingHydropower: value.withholdingHydropower,
        withholdinghislinecard: value.withholdinghislinecard,
        totalamountoffoodsubsidies: value.totalamountoffoodsubsidies,
        otherBenefits: value.otherBenefits
      }
      setConfirmLoading(true)
      MonthSalaryService.updateMonthSalary(row?.id!, updateMonthSalary).then(rsp => {
        message.success("修改成功")
        handleCancel()
        refreshData(monthSalaryReq)
      }).catch(showErr).finally(() => setConfirmLoading(false))
    }).catch(logger.error)
  }

  const handleEdit = (record: MonthSalaryRow) => {
    setRow(record)
    setVisible(true)
  }

  const handleGenerate = (year: number, month: number) => {
    setConfirmLoading(true)
    MonthSalaryService.generateMonthSalary(props.type, year, month).then(rsp => {
      message.success("生成中...")
      refreshData(monthSalaryReq)
      setConfirmLoading(false)
    }).catch((e) => {
      showErr(e)
      setConfirmLoading(false)
    })
  }
  
  const handleSave = (datasource: MonthSalaryRow[], row: MonthSalaryRow) => {
    const updateMonthSalary: UpdateMonthSalary = row
    setConfirmLoading(true)
    MonthSalaryService.updateMonthSalary(row?.id!, updateMonthSalary).then(rsp => {
      message.success("修改成功")
      const index = datasource.findIndex(item => item.id === rsp.data.id)
      datasource.splice(index, 1, rsp.data)
      monthSalaryStore.updateMonthSalaryData(datasource)
    }).catch(showErr).finally(() => setConfirmLoading(false))
  }

  return (
    <>
      <MonthHeaderAction
        handleProjectChange={handleProjectChange}
        handleSearch={handleSearch}
        refreshData={() => refreshData(monthSalaryReq)}
        handleBatchDelete={handleBatchDelete}
        handleDateChange={handleDateChange}
        exportSalary={exportSalary}
        type={props.type}
        loading={datasource.loading || confirmLoading}
        handleGenerate={handleGenerate}/>
      {
        props.type === 1 &&
            <>
              <MonthAdminTable
                className={"margin-top-sm"}
                loading={datasource.loading || confirmLoading}
                total={datasource.data?.data.total!}
                perPage={monthSalaryReq.perPage}
                pageNum={monthSalaryReq.pageNum}
                selectedRowKeys={selectedRowKeys}
                handlePageChange={handlePageChange}
                handleChange={setSelectedRowKeys}
                dataSource={datasource.data?.data.items!}
                handleEdit={handleEdit}
                handleSave={handleSave}/>
            </>
      }
      {
        props.type === 0 &&
          <>
            <MonthPersonTable
              className={"margin-top-sm"}
              loading={datasource.loading || confirmLoading}
              total={datasource.data?.data.total!}
              perPage={monthSalaryReq.perPage}
              pageNum={monthSalaryReq.pageNum}
              selectedRowKeys={selectedRowKeys}
              handlePageChange={handlePageChange}
              handleChange={setSelectedRowKeys}
              handleEdit={handleEdit}
              dataSource={datasource.data?.data.items!}
              handleSave={handleSave}/>
          </>
      }
      <Drawer
        title={<div className="text-align-center">{ row?.user.name }</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={visible}
        onClose={handleCancel}>
        {
          props.type === 1 && <MonthAdminForm row={{...row!}} ref={monthForm}/>
        }
        {
          props.type === 0 && <MonthPersonForm row={{...row!}} ref={monthForm}/>
        }
        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          <Button type="primary" onClick={handleOk} loading={confirmLoading} className={"margin-left-xs"}>确定</Button>
        </div>
      </Drawer>
    </>
  )
})