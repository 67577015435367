import React, { useEffect } from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import { FormInstance } from "antd/es";
import { observer } from "mobx-react";
import {RevenueExpenditureRow} from "@services/dto/revenueExpenditure";
import {SelectProject} from "@components/SelectProject";
import {SelectContractLedger} from "@components/SelectContractLedger";
import dayjs from "dayjs";

export interface PropsType {
  row: RevenueExpenditureRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    const revenueExpenditureRow = { ...props.row }
    if (revenueExpenditureRow.id) {
      revenueExpenditureRow.time = dayjs(revenueExpenditureRow.time)
    }
    form.setFieldsValue(revenueExpenditureRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ status: 1, isVirtualUser: 0 }}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="日期"
            name="time"
            rules={[{ required: true, message: '请选择日期!' }]}>
            <DatePicker placeholder={"请选择日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="款项编号"
            name="code"
            rules={[{ required: true, message: '请输入款项编号!' }]}>
            <Input placeholder="请输入款项编号" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="款项类别"
            name="type"
            rules={[{ required: true, message: '请输入款项类别!' }]}>
            <Input placeholder="请输入款项类别" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="款项摘要"
            name="abstracte"
            rules={[{ required: true, message: '请输入款项摘要!' }]}>
            <Input placeholder="请输入款项摘要" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="金额"
            name="amount"
            rules={[{ required: true, message: '请输入金额!' }]}>
            <InputNumber placeholder="请输入金额" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款方"
            name="apayer"
            rules={[{ required: true, message: '请输入付款方!' }]}>
            <Input placeholder="请输入付款方" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款方"
            name="payee"
            rules={[{ required: true, message: '请输入收款方!' }]}>
            <Input placeholder="请输入收款方" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联合同"
            name="theContractParameterId"
            rules={[{ required: true, message: '请选择关联合同!' }]}>
            <SelectContractLedger placeholder={"请选择关联合同"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联项目"
            name="projectId"
            rules={[{ required: true, message: '请选择关联项目!' }]}>
            <SelectProject placeholder={"请选择关联项目"} allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="备注"
            name="remark"
            rules={[{ required: false, message: '请输入备注!' }]}>
            <Input.TextArea placeholder="请输入备注" autoSize={{ minRows: 3, maxRows: 5 }}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
