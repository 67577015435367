import {
  CreatePersonnelChange,
  PersonnelChangeRow,
  PersonnelChangeSearchReq,
  UpdatePersonnelChange
} from "@services/dto/personnelChange";
import {apiV1} from "@services/apiHelper";
import {Page, ResponseEntity} from "@services/dto/common";

export class PersonnelChangeService {
  static async searchPersonnelChange(req: PersonnelChangeSearchReq): Promise<ResponseEntity<Page<PersonnelChangeRow>>> {
    return apiV1.get("/personnelChanges/search", {params: req})
  }

  static async createPersonnelChange(create: CreatePersonnelChange): Promise<PersonnelChangeRow> {
    return apiV1.post("/personnelChanges", {...create})
  }

  static async updatePersonnelChange(id: string, update: UpdatePersonnelChange): Promise<PersonnelChangeRow> {
    return apiV1.put(`/personnelChanges/${id}`, {...update})
  }

  static async getPersonnelChange(id: string): Promise<PersonnelChangeRow> {
    return apiV1.get(`/personnelChanges/${id}`)
  }

  static async deletePersonnelChange(id: string[]): Promise<void> {
    return apiV1.delete("/personnelChanges", {params: id})
  }

  static async deleteBatchPersonnelChange(ids: string[]): Promise<void> {
    return apiV1.delete("/personnelChanges/deleteBatch", {data: {ids}})
  }
}