import {makeAutoObservable, runInAction} from "mobx";
import {Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {AccountSalesReq, AccountSalesRow} from "@services/dto/accountSales";
import {AccountSalesService} from "@services/AccountSalesService";

export class AccountSalesStore {
  accountSalesDatasource: StateFullData<Page<AccountSalesRow>> = {}
  accountSalesSelectDatasource: StateFullData<Page<AccountSalesRow>> = {}
  accountSalesRow?: AccountSalesRow
  accountSalesDetail: StateFullData<ResponseEntity<AccountSalesRow>> = {}
  accountSalesDetails: StateFullData<ResponsesEntity<AccountSalesRow>> = {}
  accountSalesReq: AccountSalesReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateAccountSalesRow(row: AccountSalesRow) {
    this.accountSalesRow = {...row}
  }

  getAccountSales(req: AccountSalesReq) {
    this.accountSalesDatasource = {...this.accountSalesDatasource, loading: true}
    this.accountSalesReq = {...this.accountSalesReq, ...req}
    AccountSalesService.getAccountSales(req).then(rsp => {
      runInAction(() => this.accountSalesDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.accountSalesDatasource = {...this.accountSalesDatasource, loading: false, err})
    })
  }

  getSelectAccountSales(req: AccountSalesReq) {
    this.accountSalesSelectDatasource = {...this.accountSalesSelectDatasource, loading: true}
    this.accountSalesReq = {...this.accountSalesReq, ...req}
    AccountSalesService.getAccountSales(req).then(rsp => {
      runInAction(() => this.accountSalesSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.accountSalesSelectDatasource = {...this.accountSalesSelectDatasource, loading: false, err})
    })
  }

  getDetailById(id: string) {
    this.accountSalesDetail = {...this.accountSalesDetail, loading: true}
    AccountSalesService.getDetailById(id).then(rsp => {
      runInAction(() => this.accountSalesDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.accountSalesDetail = {...this.accountSalesDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.accountSalesDetails = {...this.accountSalesDetails, loading: true}
    AccountSalesService.getBatch(ids).then(rsp => {
      runInAction(() => this.accountSalesDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.accountSalesDetails = {...this.accountSalesDetails, loading: false, err})
    })
  }
}

export const accountSalesStore = new AccountSalesStore();
