import {apiV1} from "@services/apiHelper";
import {RoleAndMenuType, RoleManageReq, RoleManageRow} from "@services/dto/roleManage";
import {Page, ResponseEntity} from "@services/dto/common";

export class RoleManageService {
  static async getRoleAndMenu(): Promise<ResponseEntity<RoleAndMenuType>> {
    return await apiV1.get('/roleM/getRoleAndMenu');
  }

  static async addRoleManage(entity: RoleManageRow): Promise<RoleManageRow> {
    return await apiV1.post('/roleM/save', {...entity});
  }

  static async deleteRoleManageById(id: string): Promise<void> {
    return await apiV1.delete(`/roleM/del/${id}`);
  }

  static async updateRoleManageById(id: string, talent: RoleManageRow): Promise<RoleManageRow> {
    return await apiV1.put(`/roleM/update`, {...talent});
  }

  static async getRoleManage(req: RoleManageReq): Promise<Page<RoleManageRow>> {
    return await apiV1.get('/roleM/search', {params: req});
  }

  static async getOne(id: string): Promise<RoleManageRow> {
    return await apiV1.get(`/roleM/get/${id}`);
  }

  static async getBatch(ids: string[]): Promise<ResponseEntity<RoleManageRow[]>> {
    return await apiV1.get(`/roleM/getBatch`, {params: {roles: ids.join(',')}});
  }

  static async deleteBatchRoleManageByIds(ids: string[]): Promise<void> {
    return await apiV1.delete("/roleM/delBatch", {data: {ids}})
  }

  static async getDetailById(id: string): Promise<ResponseEntity<RoleManageRow>> {
    return await apiV1.get(`/roleM/getOne/${id}`)
  }
}
