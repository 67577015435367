import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {ProcessRecordsReq, ProcessRecordsRow} from "@services/dto/processRecords";

export class ProcessRecordsService {
  static async getProcessRecords(req: ProcessRecordsReq): Promise<Page<ProcessRecordsRow>> {
    return await apiV1.get("/processRecords/search", {params: req})
  }

  static async addProcessRecords(row: ProcessRecordsRow): Promise<ResponseEntity<ProcessRecordsRow>> {
    return await apiV1.post("/processRecords/save", {...row})
  }

  static async updateProcessRecords(row: ProcessRecordsRow): Promise<ResponseEntity<ProcessRecordsRow>> {
    return await apiV1.put("/processRecords/update", {...row})
  }

  static async deleteProcessRecords(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/processRecords/delete", {data: ids})
  }

  static async mergeProcessRecords(ids: string[], mainBody: string, type: number): Promise<ResponseEntity<ProcessRecordsRow>> {
    return await apiV1.get(`/processRecords/merge/${ids.join(',')}?mainBodyId=${mainBody}&type=${type}`)
  }

  static async getOne(id: string): Promise<ResponseEntity<ProcessRecordsRow>> {
    return await apiV1.get(`/processRecords/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<ProcessRecordsRow>> {
    return await apiV1.get(`/processRecords/getBatch/${ids.join(',')}`)
  }
}
