import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Col, Input, Row, Space, Table} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TABLE_CELL_WIDTH_1_5x, TABLE_CELL_WIDTH_1x,} from "@/config";
import {FormStatusReq, FormStatusRow, FormStatusRowSummaryTotal} from "@services/dto/formStatus";
import {SearchOutlined} from "@ant-design/icons";
import {TableMoneyText} from "@components/TableMoneyText";

export const FormStatus = observer(() => {
  const {formStatusStore} = allStores
  const [dataSource, setDataSource] = useState<FormStatusRow[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchReq, setSearchReq] = useState<FormStatusReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
  })
  const [summaryTotalObj, setSummaryTotalObj] = useState<FormStatusRowSummaryTotal>({
    inPreparation: 0,
    inProcess: 0,
    closedAccount: 0,
    terminated: 0,
    onFile: 0,
    other: 0,
    total: 0,
  })

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  const columns: ColumnsType<FormStatusRow> = [
    {
      title: '表单名称',
      dataIndex: 'table',
      key: 'table',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '准备中',
      dataIndex: 'inPreparation',
      key: 'inPreparation-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text, record) => (
        <Space>
          <div>
            { record.countMap.find(item => item.field === '准备中')?.count || 0 }
            &nbsp;(<TableMoneyText isPercentage value={
              (
                (record.countMap.find(item => item.field === '准备中')?.count || 0) / (record.countMap.reduce((acc, cur) => {
                  // 使用展开运算符保留字段信息，并累加 count 值
                  return {
                    ...acc,
                    count: acc.count + cur.count
                  };
                }, { field: '', count: 0 }).count)
              ) || 0
            }></TableMoneyText>)
          </div>
        </Space>
      )
    },
    {
      title: '办理中',
      dataIndex: 'inProcess',
      key: 'inProcess-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text, record) => (
        <Space>
          <div>
            { record.countMap.find(item => item.field === '办理中')?.count || 0 }
            &nbsp;(<TableMoneyText isPercentage value={
              (
                (record.countMap.find(item => item.field === '办理中')?.count || 0) / (record.countMap.reduce((acc, cur) => {
                  // 使用展开运算符保留字段信息，并累加 count 值
                  return {
                    ...acc,
                    count: acc.count + cur.count
                  };
                }, { field: '', count: 0 }).count)
              ) || 0
            }></TableMoneyText>)
          </div>
        </Space>
      )
    },
    {
      title: '已存档',
      dataIndex: 'onFile',
      key: 'onFile-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text, record) => (
        <Space>
          <div>
            { record.countMap.find(item => item.field === '已存档')?.count || 0 }
            &nbsp;(<TableMoneyText isPercentage value={
              (
                (record.countMap.find(item => item.field === '已存档')?.count || 0) / (record.countMap.reduce((acc, cur) => {
                  // 使用展开运算符保留字段信息，并累加 count 值
                  return {
                    ...acc,
                    count: acc.count + cur.count
                  };
                }, { field: '', count: 0 }).count)
              ) || 0
            }></TableMoneyText>)
          </div>
        </Space>
      )
    },
    {
      title: '已办结',
      dataIndex: 'closedAccount',
      key: 'closedAccount-summation',
      width: TABLE_CELL_WIDTH_1x,
      align: 'right',
      render: (text, record) => (
        <Space>
          <div>
            { record.countMap.find(item => item.field === '已办结')?.count || 0 }
            &nbsp;(<TableMoneyText isPercentage value={
              (
                (record.countMap.find(item => item.field === '已办结')?.count || 0) / (record.countMap.reduce((acc, cur) => {
                  // 使用展开运算符保留字段信息，并累加 count 值
                  return {
                    ...acc,
                    count: acc.count + cur.count
                  };
                }, { field: '', count: 0 }).count)
              ) || 0
            }></TableMoneyText>)
          </div>
        </Space>
      )
    },
    {
      title: '已终止',
      dataIndex: 'terminated',
      key: 'terminated-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text, record) => (
        <Space>
          <div>
            { record.countMap.find(item => item.field === '已终止')?.count || 0 }
            &nbsp;(<TableMoneyText isPercentage value={
              (
                (record.countMap.find(item => item.field === '已终止')?.count || 0) / (record.countMap.reduce((acc, cur) => {
                  // 使用展开运算符保留字段信息，并累加 count 值
                  return {
                    ...acc,
                    count: acc.count + cur.count
                  };
                }, { field: '', count: 0 }).count)
              ) || 0
            }></TableMoneyText>)
          </div>
        </Space>
      )
    },
    {
      title: '其他',
      dataIndex: 'other',
      key: 'other-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text, record) => (
        <Space>
          <div>{
            record.countMap.reduce((acc, cur) => {
              // 使用展开运算符保留字段信息，并累加 count 值
              return {
                ...acc,
                count: acc.count + cur.count
              };
            }, { field: '', count: 0 }).count - (record.countMap.find(item => item.field === '准备中')?.count || 0)
              - (record.countMap.find(item => item.field === '办理中')?.count || 0)
              - (record.countMap.find(item => item.field === '已存档')?.count || 0)
              - (record.countMap.find(item => item.field === '已办结')?.count || 0)
              - (record.countMap.find(item => item.field === '已终止')?.count || 0)
          }
            &nbsp;(<TableMoneyText isPercentage value={
            (
              (
                record.countMap.reduce((acc, cur) => {
                  // 使用展开运算符保留字段信息，并累加 count 值
                  return {
                    ...acc,
                    count: acc.count + cur.count
                  };
                }, { field: '', count: 0 }).count - (record.countMap.find(item => item.field === '准备中')?.count || 0)
                  - (record.countMap.find(item => item.field === '办理中')?.count || 0)
                  - (record.countMap.find(item => item.field === '已存档')?.count || 0)
                  - (record.countMap.find(item => item.field === '已办结')?.count || 0)
                  - (record.countMap.find(item => item.field === '已终止')?.count || 0)
              ) / (record.countMap.reduce((acc, cur) => {
                // 使用展开运算符保留字段信息，并累加 count 值
                return {
                  ...acc,
                  count: acc.count + cur.count
                };
              }, { field: '', count: 0 }).count)
            ) || 0
          }></TableMoneyText>)
          </div>
        </Space>
      )
    },
    {
      title: '合计',
      dataIndex: 'total',
      key: 'total-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text, record) => (
        <Space>
          <div>{
            record.countMap.reduce((acc, cur) => {
              // 使用展开运算符保留字段信息，并累加 count 值
              return {
                ...acc,
                count: acc.count + cur.count
              };
            }, { field: '', count: 0 }).count
          }
          (100 %)
          </div>
        </Space>
      )
    }
  ];

  useEffect(() => {
    refreshData(searchReq)
  }, [searchReq])

  useEffect(() => {
    const objInitValue = {
      inPreparation: 0,
      inProcess: 0,
      closedAccount: 0,
      terminated: 0,
      onFile: 0,
      other: 0,
      total: 0,
    }
    const arr = formStatusStore.formStatusDatasource.data?.data.filter(o => selectedRowKeys.includes(o.table))
    if (arr?.length) {
      arr.forEach(item => {
        objInitValue.inPreparation += item.countMap.find(item => item.field === '准备中')?.count || 0
        objInitValue.inProcess += item.countMap.find(item => item.field === '办理中')?.count || 0
        objInitValue.closedAccount += item.countMap.find(item => item.field === '已办结')?.count || 0
        objInitValue.terminated += item.countMap.find(item => item.field === '已终止')?.count || 0
        objInitValue.onFile += item.countMap.find(item => item.field === '已存档')?.count || 0
        objInitValue.other += (
          item.countMap.reduce((acc, cur) => {
            // 使用展开运算符保留字段信息，并累加 count 值
            return {
              ...acc,
              count: acc.count + cur.count
            };
          }, { field: '', count: 0 }).count - (item.countMap.find(item => item.field === '准备中')?.count || 0)
            - (item.countMap.find(item => item.field === '办理中')?.count || 0)
            - (item.countMap.find(item => item.field === '已存档')?.count || 0)
            - (item.countMap.find(item => item.field === '已办结')?.count || 0)
            - (item.countMap.find(item => item.field === '已终止')?.count || 0)
        )
        objInitValue.total += item.countMap.reduce((acc, cur) => {
          // 使用展开运算符保留字段信息，并累加 count 值
          return {
            ...acc,
            count: acc.count + cur.count
          };
        }, { field: '', count: 0 }).count
      })
      setSummaryTotalObj(objInitValue)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [formStatusStore.formStatusDatasource, selectedRowKeys])

  useEffect(() => {
    if (formStatusStore.formStatusDatasource.data) {
      setDataSource(formStatusStore.formStatusDatasource.data.data || [])
    }
  }, [formStatusStore.formStatusDatasource.data])

  const refreshData = (req: FormStatusReq) => {
    formStatusStore.getFormStatus(req)
  }

  return (
    <div className={"institution"}>
      <Row gutter={12} className={'header-action-row'}>
        <Col xl={4} md={6} xs={8}>
          <Input
            value={searchReq.keyword}
            onChange={(e) => setSearchReq({...searchReq, keyword: e.target.value})}
            onPressEnter={() => refreshData({...searchReq, pageNum: 1})}
            allowClear
            placeholder="请输入关键字"
            suffix={<SearchOutlined onClick={() => refreshData({...searchReq, pageNum: 1})} />}
          />
        </Col>
      </Row>

      <Table
        className={'margin-top-xss'}
        rowKey={"table"}
        dataSource={dataSource}
        columns={columns}
        size={"middle"}
        loading={formStatusStore.formStatusDatasource.loading}
        bordered
        scroll={{scrollToFirstRowOnChange: true}}
        pagination={false}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys)
          },
          columnWidth: 50,
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                columns.map((item, index) =>
                  String(item.key)?.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                        合计：<span>
                      { summaryTotalObj[String(item.key).slice(0, String(item.key).indexOf('-'))] }
                    &nbsp;(<TableMoneyText isPercentage value={
                        (
                          (summaryTotalObj[String(item.key).slice(0, String(item.key).indexOf('-'))]) / (summaryTotalObj.total)
                        ) || 0
                      }></TableMoneyText>)
                    </span>
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </div>
  )
})
