import {observer} from "mobx-react";
import {allStores} from "@/stores";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Drawer, FormInstance, message, Modal, Row, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {getUploadArrayToString, getUploadIds, logger, showErr} from "@common/utils";
import {DATE_FMT, DEFAULT_MODAL_WIDTH} from "@/config";
import {SelectProject} from "@components/SelectProject";
import {PageParam, ProjectLogRow} from "@services/dto/projectLog";
import {ProjectLogService} from "@services/ProjectLogService";
import {BasicInfo} from "@modules/ProjectLog/Components/BasicInfo";
import {previewFile} from "@components/CustomUpload";
import {ResponseEntity} from "@services/dto/common";
import {ToolService} from "@services/ToolService";

export const ProjectLog = observer(() => {
  const {projectLogStore} = allStores
  const basicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<ProjectLogRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [projectLogRow, setProjectLogRow] = useState<ProjectLogRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [searchReq, setSearchReq] = useState<PageParam>({
    pageNum: 1,
    perPage: 10,
  })

  const columns: ColumnsType<ProjectLogRow> = [
    {
      title: '项目名称',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => {
            handleView(record)
          }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '日期',
      dataIndex: 'time',
      key: 'time',
      align: 'center',
    },
    {
      title: '今日工作完成情况',
      dataIndex: 'workCompleted',
      key: 'workCompleted',
      align: 'center'
    },
    {
      title: '明日计划完成工作',
      dataIndex: 'tomorrowPlan',
      key: 'tomorrowPlan',
      align: 'center'
    },
    {
      title: '需协调工作',
      dataIndex: 'needToCoordinateWork',
      key: 'needToCoordinateWork',
      align: 'center'
    },
    {
      title: '待办事项',
      dataIndex: 'todo',
      key: 'todo',
      align: 'center'
    },
    {
      title: '附件',
      dataIndex: 'url',
      key: 'url',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          { text ? <Button type={"link"} onClick={() => {
            previewFile(text)
          }}>查看</Button> : '未上传' }
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record, index) => (
        <Space>
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined/>}
            onClick={() => {
              handleEdit(record)
            }}>修改</Button>
          <Button shape="round" className={"delete-btn"} size={"middle"}
            icon={<DeleteOutlined/>} onClick={() => {
              handleDeleteBatch(record)
            }}>删除</Button>
        </Space>
      )
    }
  ];

  useEffect(() => {
    refreshData(searchReq)
  }, [])

  useEffect(() => {
    setDataSource(projectLogStore.projectLogDatasource.data?.items!)
    setTotal(projectLogStore.projectLogDatasource.data?.total!)
  }, [projectLogStore.projectLogDatasource])

  const handleAdd = () => {
    setProjectLogRow(undefined)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handleView = (record: ProjectLogRow) => {
    setProjectLogRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = (record: ProjectLogRow) => {
    setProjectLogRow(record)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleDeleteBatch = (record?: ProjectLogRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        ProjectLogService.deleteProjectLog(ids).then(() => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(searchReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: PageParam) => {
    projectLogStore.getProjectLog(req)
  }

  const handleOk = () => {
    const row = {...projectLogRow!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(row, values)
      row.time = values.time.format(DATE_FMT)
      let ids = ""
      if (typeof values.url === 'object') {
        ids = getUploadIds(values.url)
        row.url = getUploadArrayToString(values.url)
      } else {
        row.url = ""
      }
      setConfirmLoading(true)
      if (row.id) {
        ProjectLogService.updateProjectLog(row).then(rsp => {
          actionThen(rsp, ids);
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        ProjectLogService.addProjectLog(row).then(rsp => {
          actionThen(rsp, ids);
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  }

  const actionThen = async (rsp: ResponseEntity<ProjectLogRow>, ids: string) => {
    // 图片上传跟表单绑定
    try {
      await ToolService.submittedSuccess(ids, rsp.data.id)
      message.success("操作成功！")
    } catch (e) {
      showErr(e)
    } finally {
      setIsModalVisible(false);
      setProjectLogRow(undefined)
      refreshData(searchReq)
    }
  }

  return (
    <div className={"institution"}>
      <Row gutter={12}>
        <Col span={6}>
          <SelectProject
            value={searchReq.projectId}
            allowClear
            onChange={(value: string) => {
              setSearchReq({...searchReq, pageNum: 1, projectId: value})
              refreshData({...searchReq, pageNum: 1, projectId: value})
            }}/>
        </Col>
        <Col span={18} className={"text-align-right"}>
          <Button icon={<DeleteOutlined/>} size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={() => handleDeleteBatch(undefined)}>删除</Button>
          <Button icon={<PlusOutlined/>} type={"primary"} size={"middle"} shape={"round"}
            onClick={handleAdd}>新增</Button>
        </Col>
      </Row>

      <Card className={"margin-top-sm"}>
        <Table
          rowKey={"id"}
          dataSource={dataSource}
          columns={columns}
          size={"middle"}
          loading={projectLogStore.projectLogDatasource.loading || confirmLoading}
          pagination={{
            total: total,
            position: ['bottomCenter'],
            pageSize: searchReq.perPage,
            current: searchReq.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`,
            onChange: handlePageChange
          }}
          rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys}}/>
      </Card>

      <Drawer
        title={<div className="text-align-center">项目日志</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        onClose={handleCancel}>
        <BasicInfo row={{...projectLogRow!}} ref={basicInfo} isView={isView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button type="primary" onClick={handleOk} loading={confirmLoading}
              className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>
    </div>
  )
})
