import {makeAutoObservable, runInAction} from "mobx";
import {List} from "@services/dto/common";
import {WorkerTypeService} from "@services/WorkerTypeService";
import {WorkerType} from "@services/dto/workerType";

export class WorkerTypeStore {
  workerTypes: List<WorkerType> = {
    items: [],
    total: 0
  }

  constructor() {
    makeAutoObservable(this)
  }

  getWorkerTypes() {
    this.workerTypes = {...this.workerTypes}
    WorkerTypeService.getWorkerTypes().then((res) => {
      runInAction(() => {
        this.workerTypes = {...res}
      })
    }).catch(err => {
      runInAction(() => {
        this.workerTypes = {...this.workerTypes, err: err}
      })
    })
  }
}

export const workerTypeStore = new WorkerTypeStore();
