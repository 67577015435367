import React, {useEffect, useState} from 'react'
import {ColumnsType} from "antd/es/table";
import {Button, message, Modal, Space, Table, Typography} from "antd";
import {UserProfileRecord} from "@services/dto/userProfile";
import dayjs from "dayjs";
import {DATE_FMT} from "@/config";
import './BasicInfo.less'
import {getGenderLabel, showErr} from "@common/utils";
import {observer} from "mobx-react";
import {useParams} from "react-router-dom";
import {WorkerRecruitmentService} from "@services/WorkerRecruitmentService";
import {UserProfileService} from "@services/UserProfileService";
import {User} from "@services/dto/security";

export interface InfoBodyRow {
  title: string
  value: string | number
}

export interface InfoBodyProps {
  rows: InfoBodyRow[]
  singleRow?: boolean
}

export const InfoBody = (props: InfoBodyProps) => {
  return <div className={`info-root ${props.singleRow ? 'single-row':''}`}>
    { props.rows.map((i, index) => (<div className='info-item' key={index}>
      <div className='info-title'>{ i.title }</div>
      <div className='info-value'>{ i.value }</div>
    </div>)) }
  </div>
}

export const BasicInfo = observer(() => {
  const [user, setUser] = useState<User>()
  const [rows, setRows] = useState<UserProfileRecord[]>([])
  const params = useParams();

  useEffect(() => {
    loadUser()
    loadUserProfile()
  }, [])

  const loadUser = () => {
    WorkerRecruitmentService.getUserById(params.userId!).then(rsp => {
      setUser(rsp)
    }).catch(showErr)
  }

  const loadUserProfile = () => {
    UserProfileService.getUserProfileByUserId(params.userId!).then(rsp => {
      setRows(rsp.records)
    }).catch(showErr)
  }

  const columns: ColumnsType<UserProfileRecord> = [
    {
      title: '时间',
      render: (_, i) => `${dayjs(i.startDate).format(DATE_FMT)}至${dayjs(i.endDate).format(DATE_FMT)}`
    },
    {
      title: '项目名称',
      dataIndex: ['title'],
    },
    {
      title: '工种',
      render: (_, record) => record.workerTypes.map(i => i.typeName).join(',')
    },
    {
      title: '评分',
      dataIndex: ['systemScore'],
    },
    {
      title: '操作',
      render: (_, record) => (
        <Space size="middle" key={record._id}>
          <Button type="link" onClick={() => handleDeleteProfile(record)}>删除</Button>
        </Space>
      ),
    },
  ];

  const handleDeleteProfile = (record: UserProfileRecord) => {
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        UserProfileService.deleteUserProfileByUserIdById(params.userId!, record._id).then(() => {
          message.success("删除成功！")
          loadUserProfile()
        }).catch(showErr)
      },
    })
  }

  const INFO_FIELDS: InfoBodyRow[] = [
    {title: '姓名', value: user?.name!},
    {title: '身份证', value: user?.chinaUid!},
    {title: '手机号码', value: user?.phone!},
    {title: '性别', value: getGenderLabel(user?.gender)},
    {title: '籍贯', value: user?.nativePlace!},
    {title: '工种', value: (user?.workerTypes || []).map(i => i.typeName).join(',')},
    {title: '年龄', value: user?.age!},
    {title: '系统评分', value: user?.systemScore!},
  ];
  return <div>
    <Typography.Title level={4}>
      基本信息
    </Typography.Title>
    <InfoBody rows={INFO_FIELDS}/>
    <Typography.Title className={"mt16"} level={4}>
      详细经历
    </Typography.Title>
    <Table columns={columns} dataSource={rows} rowKey={'_id'}/>
  </div>
})
