import {makeAutoObservable, runInAction} from "mobx";
import {Page, ResponseEntity, StateFullData} from "@services/dto/common";
import {CompanyPeopleReq, CompanyPeopleRow} from "@services/dto/companyPeople";
import {CompanyPeopleService} from "@services/CompanyPeopleService";

export class CompanyPeoplePeopleStore {
  companyPeopleDataSource: StateFullData<Page<CompanyPeopleRow>> = {};
  companyPeopleRowData: StateFullData<ResponseEntity<CompanyPeopleRow>> = {}
    
  constructor() {
    makeAutoObservable(this)
  }

  searchCompanyPeople(req: CompanyPeopleReq) {
    this.companyPeopleDataSource = {...this.companyPeopleDataSource, loading: true};
    CompanyPeopleService.searchCompanyPeople(req).then(rsp => {
      runInAction(() => {this.companyPeopleDataSource = {data: rsp, loading: false}})
    }).catch(e => {
      runInAction(() => this.companyPeopleDataSource = {...this.companyPeopleDataSource, loading: false, err: e})
    })
  }

  getCompanyPeopleById(id: string) {
    this.companyPeopleRowData = {...this.companyPeopleRowData, loading: true}
    CompanyPeopleService.getCompanyPeopleById(id).then(rsp => {
      runInAction(() => {this.companyPeopleRowData = {data: rsp, loading: false}})
    }).catch(err => {
      runInAction(() => this.companyPeopleRowData = {...this.companyPeopleRowData, loading: false, err})
    })
  }
}

export const companyPeopleStore = new CompanyPeoplePeopleStore();