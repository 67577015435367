import {Page, StateFullData} from "@services/dto/common";
import {makeAutoObservable, runInAction} from "mobx";
import {DictManagementReq, DictManagementRow} from "@services/dto/dictManagement";
import {DictManagementService} from "@services/DictManagementService";

export class DictManagementStore {
  dictManagementDatasource: StateFullData<Page<DictManagementRow>> = {}
  dictManagementSelectDatasource: StateFullData<Page<DictManagementRow>> = {}
  dictManagementReq: DictManagementReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  getDictManagement(req: DictManagementReq) {
    this.dictManagementDatasource = {...this.dictManagementDatasource, loading: true}
    this.dictManagementReq = {...this.dictManagementReq, ...req}
    DictManagementService.getDictManagement(req).then(rsp => {
      runInAction(() => {
        this.dictManagementDatasource = {data: rsp, loading: false}
      })
    }).catch(e => {
      runInAction(() => this.dictManagementDatasource = {...this.dictManagementDatasource, loading: false, err: e})
    })
  }

  getSelectDictManagement(req: DictManagementReq) {
    this.dictManagementSelectDatasource = {...this.dictManagementSelectDatasource, loading: true}
    DictManagementService.getDictManagement(req).then(rsp => {
      runInAction(() => {
        this.dictManagementSelectDatasource = {data: rsp, loading: false}
      })
    }).catch(e => {
      runInAction(() => this.dictManagementSelectDatasource = {...this.dictManagementSelectDatasource, loading: false, err: e})
    })
  }
}

export const dictManagementStore = new DictManagementStore();
