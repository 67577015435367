import React, {useEffect} from "react";
import {Col, DatePicker, Form, Input, Row} from "antd";
import {FormInstance} from "antd/es";
import {ProjectRow} from "@services/dto/project";
import {observer} from "mobx-react";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectFiling} from "@components/SelectFiling";
import {SelectProject} from "@components/SelectProject";
import {SelectDict} from "@components/SelectDict";
import dayjs from "dayjs";
import {ONFILE} from "@/config";

export interface PropsType {
  row: ProjectRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  useEffect(() => {
    form.resetFields()
    const projectRow = {...props.row}
    if (props.row.id) {
      if (typeof projectRow.dateOfEstablishment === 'string' && projectRow.dateOfEstablishment !== '') {
        projectRow.dateOfEstablishment = dayjs(projectRow.dateOfEstablishment)
      }
      if (typeof projectRow.dissolutionDate === 'string' && projectRow.dissolutionDate !== '') {
        projectRow.dissolutionDate = dayjs(projectRow.dissolutionDate)
      }
    }
    form.setFieldsValue(projectRow);
  }, [props.row.id])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="部门名称"
            name="name"
            rules={[{required: false, message: '部门名称!'}]}>
            <Input placeholder="部门名称"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="部门代码"
            name="code"
            rules={[{required: false, message: '请输入部门代码!'}]}>
            <Input placeholder="请输入部门代码"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="部门类别"
            name="type"
            rules={[{required: false, message: '请输入部门类别!'}]}>
            <Input placeholder="请输入部门类别"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="上级部门"
            name="pid"
            rules={[{ required: false, message: '请选择上级部门!' }]}>
            <SelectProject
              className={'width-100-percentage'}
              placeholder={"请选择上级部门"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="成立日期"
            name="dateOfEstablishment"
            rules={[{required: false, message: '请输入成立日期!'}]}>
            <DatePicker placeholder={"请输入成立日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="解散日期"
            name="dissolutionDate"
            rules={[{required: false, message: '请输入解散日期!'}]}>
            <DatePicker placeholder={"请输入解散日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="部门级别"
            name="superiorType"
            rules={[{required: false, message: '请输入上级类别!'}]}>
            <SelectDict
              className={'width-100-percentage'}
              allowClear
              code={'dict_level'}
              placeholder={'请选择上级类别'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="存档批号"
            name="file"
            rules={[{ required: false, message: '请选择存档批号!' }]}>
            <SelectFiling placeholder={"请选择存档批号"} allowClear />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="经办记录"
            name="principal"
            rules={[{ required: false, message: '请选择经办记录!' }]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              placeholder={"请选择经办记录"}
              fieldNames={{value: 'name', label: 'name'}}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="status"
            rules={[{required: false, message: '请输入办理进度!'}]}>
            <SelectDict
              className={'width-100-percentage'}
              allowClear
              placeholder={'请选择办理进度'}
              code={'common_type3'}
              disabled={props.row.status === ONFILE}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
