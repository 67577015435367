import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {PageParam, RevenueExpenditureRow} from "@services/dto/revenueExpenditure";

export class RevenueExpenditureService {
  static async getRevenueExpenditure(req: PageParam): Promise<Page<RevenueExpenditureRow>> {
    return await apiV1.get("/balanceParameter", {params: req})
  }

  static async addRevenueExpenditure(row: RevenueExpenditureRow): Promise<ResponseEntity<RevenueExpenditureRow>> {
    return await apiV1.post("/balanceParameter", {...row})
  }

  static async updateRevenueExpenditure(row: RevenueExpenditureRow): Promise<ResponseEntity<RevenueExpenditureRow>> {
    return await apiV1.put("/balanceParameter", {...row})
  }

  static async deleteRevenueExpenditure(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/balanceParameter", {data: ids})
  }
}
