import React, {useEffect, useState} from "react";
import {Button, DatePicker, Input, message, Select, Space} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {SelectBankAccount} from "@components/SelectBankAccount";
import dayjs from "dayjs";
import {Any} from "@common/types";
import {BankStatementReq} from "@services/dto/bankStatement";
import {SelectFundApproval} from "@components/SelectFundApproval";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectFiling} from "@components/SelectFiling";
import {SelectDict} from "@components/SelectDict";
import {CustomInputNumber} from "@components/CustomInputNumber";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {BankStatementService} from "@services/BankStatementService";
import {BLACK_LIST} from "@/config";
import {CustomAuthHeaderAction, PropsType as ParentPropsType} from "@components/CustomAuthHeaderAction";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {CustomApplicationFormType} from "@components/CustomApplicationFormType";
import {ClearOutlined, SearchOutlined} from "@ant-design/icons";

export interface PropsType extends ParentPropsType {
  setConfirmLoading?: (loading: boolean) => void
  updateCount?: number, // 更新渲染标志
  refreshData?: (req: BankStatementReq) => void,
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [baAccountId, setBaAccountId] = useState<string>('')
  const [planCode, setPlanCode] = useState<string>()
  const [searchReq, setSearchReq] = useState<BankStatementReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: BankStatementReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleFundingApprovalCodeChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.fundingApprovalCode = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleBsDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.bsDate1 = dateStrings[0]
    req.bsDate2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleBsStatusChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.bsStatus = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  // const handleBsTypeChange = (searchValue: string) => {
  //   const req = {...searchReq}
  //   req.pageNum = 1
  //   req.bsType = searchValue
  //   setSearchReq(req)
  //   refreshData(req)
  // }

  const handleFaPaymentAccountChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.bankAccountId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleBaType1Change = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.baType1 = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleBsOtherAccountIdChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.bsOtherAccountId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonResponsibleChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleFilingChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.bsFile = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleBsBalanceChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.bsBalance = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleProjectChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleBsInAmountChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.bsInAmount1 = start
    req.bsInAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleBsOutAmountChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.bsOutAmount1 = start
    req.bsOutAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleBsAmountChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.bsAmount1 = start
    req.bsAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleOrganizersUserIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.organizersUserId = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    if (props.setConfirmLoading) {
      props.setConfirmLoading(true);
    }
    BankStatementService.batchDateRefresh().then(() => {
      const req = {...searchReq}

      req.pageNum = 1
      req.perPage = 10
      req.keyword = undefined
      req.cooperationUnit = undefined
      req.responsibilityProject = undefined

      req.bsStatus = undefined
      req.bsDate1 = undefined
      req.bsDate2 = undefined
      req.bsAmount1 = undefined
      req.bsAmount2 = undefined
      req.bsInAmount1 = undefined
      req.bsInAmount2 = undefined
      req.bsOutAmount1 = undefined
      req.bsOutAmount2 = undefined
      req.fundingApprovalId = undefined
      req.fundingApprovalCode = undefined
      req.bsFile = undefined
      req.bankAccountId = undefined
      req.baType1 = undefined
      req.bsOtherAccountId = undefined
      req.personResponsible = undefined
      req.personResponsibleName = undefined
      req.organizersUserId = undefined // 主办人员
      req.organizersUserName = undefined // 主办人员名
      req.bsBalance = undefined
      req.statusCommon = undefined
      req.xt = undefined
      req.bsType = undefined

      setSearchReq(req)
      refreshData(req)
    }).finally(() => {
      if (props.setConfirmLoading) {
        props.setConfirmLoading(false);
      }
    })
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleRecalculate = async () => {
    BankStatementService.recalculate(baAccountId).then(rsp => {
      if (rsp.code === 200) {
        message.success(rsp.message)
      }else{
        message.warning(rsp.message)
      }
      refreshData(searchReq)
    }).catch(err=>{
      message.warning(err.message || '计算错误')
    })
  }

  const handleApplicationFormTypeChange = ([value, tableValue]: [value: string, tableValue: string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.faApplicationFormType = value
    req.faApplicationForm = tableValue
    setSearchReq(req)
    refreshData(req)
  }

  const handlePlanTypeChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.planType = value
    setSearchReq(req)
    refreshData(req)
  }

  const handlePlanCodeClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.planCode = undefined
    setPlanCode(undefined)
    setSearchReq(req)
    refreshData(req)
  }

  const handlePlanCodeSearch = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.planCode = planCode
    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <CustomAuthHeaderAction
        locationPathname={props.locationPathname}
        size={props.size}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        collapsed={props.collapsed}
        searchReq={props.searchReq}
        setCollapsed={props.setCollapsed}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        isShowKeyword={!props.refreshData}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <DatePicker.RangePicker
            allowClear
            value={searchReq?.bsDate1 ? [dayjs(searchReq?.bsDate1), dayjs(searchReq?.bsDate2)] : [undefined, undefined]}
            placeholder={['出纳开始日期', '出纳结束日期']}
            onChange={handleBsDateChange}
            className={"width-100-percentage"}/>,
          // <SelectDict
          //   updateCount={props.updateCount}
          //   code={'dict_bs_type'}
          //   onChange={handleBsTypeChange}
          //   allowClear
          //   value={searchReq?.bsType}
          //   placeholder={'出纳交易类型'}
          //   className={"width-100-percentage"}/>,
          <SelectBankAccount
            isEnableFocusEvent
            searchReq={{
              pageNum: 1,
              perPage: 10,
              baType:'内部协作账户,员工公务账户'
            }}
            updateCount={props.updateCount}
            onChange={handleFaPaymentAccountChange}
            allowClear
            value={searchReq?.bankAccountId}
            className={'width-100-percentage'}
            placeholder={'请选择出纳账户'}/>,
          <SelectDict
            updateCount={props.updateCount}
            onChange={handleBaType1Change}
            allowClear
            value={searchReq?.baType1}
            className={"width-100-percentage"}
            code={'amount_type'}
            placeholder={'出纳账户类型'}/>,
          <CustomApplicationFormType
            className={'width-100-percentage'}
            allowClear
            placeholder={'申请表单'}
            isOption
            value={[searchReq?.faApplicationFormType, searchReq?.faApplicationForm]}
            onSearch={handleApplicationFormTypeChange}/>,
          <Select
            className={'width-100-percentage'}
            allowClear
            onChange={handlePlanTypeChange}
            value={searchReq?.planType}
            placeholder={'关联计划表单类型'}
            options={[
              {label:'薪酬计划', value:'薪酬计划'},
              {label:'合同审批', value:'合同审批'},
              {label:'费用申请', value:'费用申请'},
              {label:'税费计划', value:'税费计划'},
            ]}
          ></Select>,
          <Input
            onChange={(e) => setPlanCode(e.target.value)}
            value={planCode}
            onPressEnter={handlePlanCodeSearch}
            allowClear={{clearIcon: <ClearOutlined onClick={handlePlanCodeClear}/>}}
            placeholder="请搜索计划表单编号"
            suffix={<SearchOutlined onClick={handlePlanCodeSearch}/>}
          />,
          <CustomInputNumber
            className={'width-100-percentage'}
            value={[searchReq?.bsInAmount1, searchReq?.bsInAmount2]}
            onSearch={handleBsInAmountChange}
            placeholder={['转入金额最小', '转入金额最大']}/>,
          <CustomInputNumber
            className={'width-100-percentage'}
            value={[searchReq?.bsOutAmount1, searchReq?.bsOutAmount2]}
            onSearch={handleBsOutAmountChange}
            placeholder={['转出金额最小', '转出金额最大']}/>,
          <CustomInputNumber
            className={'width-100-percentage'}
            value={[searchReq?.bsAmount1, searchReq?.bsAmount2]}
            onSearch={handleBsAmountChange}
            placeholder={['余额最小', '余额最大']}/>,
          <SelectBankAccount
            isEnableFocusEvent
            updateCount={props.updateCount}
            onChange={handleBsOtherAccountIdChange}
            allowClear
            searchReq={{
              pageNum: 1,
              perPage: 10,
              baType: undefined
            }}
            value={searchReq?.bsOtherAccountId}
            className={'width-100-percentage'}
            placeholder={'请选择对方账户'}/>,
          <SelectFundApproval
            updateCount={props.updateCount}
            allowClear
            className={'width-100-percentage'}
            value={searchReq?.fundingApprovalCode}
            onChange={handleFundingApprovalCodeChange}
            fieldNames={{value: 'faCode', label: 'faCode'}}/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleProjectChange}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
          <SelectCooperativeUnit
            onChange={handleCooperationUnitChange}
            value={searchReq?.cooperationUnit}
            allowClear
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectFiling
            updateCount={props.updateCount}
            placeholder={"请选择存档批号"}
            allowClear
            isFaBatchNumberGroupBy
            fieldNames={{label: 'faBatchNumber', value: 'faBatchNumber'}}
            value={searchReq?.bsFile}
            isHiddenOptions={true}
            onChange={handleFilingChange}/>,
          <Select
            onChange={handleBsBalanceChange}
            allowClear
            options={[
              {label: '余额相符', value: '余额相符'},
              {label: '余额不符', value: '余额不符'},
            ]}
            value={searchReq?.bsBalance}
            placeholder={'请选择余额对账'}
            className={"width-100-percentage"}/>,
          <SelectPersonCharge
            updateCount={props.updateCount}
            onChange={handlePersonResponsibleChange}
            className={"width-100-percentage"}
            placeholder={"请选择办理人员"}
            value={searchReq?.personResponsible}
            allowClear/>,
          <SelectPersonCharge
            onChange={handleOrganizersUserIdChange}
            className={"width-100-percentage"}
            placeholder={"请选择主办人员"}
            value={searchReq?.organizersUserId}
            allowClear/>,
          <SelectProcessStatus
            onChange={handleBsStatusChange}
            allowClear
            value={searchReq?.bsStatus}
            placeholder={'请选择办理进度'}
            className={"width-100-percentage"}/>,
          <Space.Compact
            className={'width-100-percentage'} >
            <SelectBankAccount
              style={{flex: 1}}
              isEnableFocusEvent
              updateCount={props.updateCount}
              onChange={v => !BLACK_LIST.includes(v) && setBaAccountId(v)}
              allowClear
              placeholder={'重新计算的账户'}/>
            <Button
              style={{width:'auto'}}
              disabled={!baAccountId}
              onClick={() => handleRecalculate()}>
              重新计算
            </Button>
          </Space.Compact>,
        ] : []}></CustomAuthHeaderAction>
    </>
  )
}))
