import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {allStores} from "@/stores";
import {WorkerRecruitmentReq, WorkerRecruitmentRow} from "@services/dto/workerRecruitment";
import {DEFAULT_MODAL_WIDTH, WorkerRecruitmentStatus} from "@/config";
import {Drawer, message, Modal} from "antd";
import {Exit} from "@modules/Induction/Components/Exit";
import {HeaderAction} from "@modules/Quit/Components/HeaderAction";
import {ContentTable} from "@modules/Quit/Components/ContentTable";
import {WorkerRecruitmentService} from "@services/WorkerRecruitmentService";
import {showErr} from "@common/utils";
import {ExitInfo} from "@modules/Quit/Components/ExitInfo";
import {FormInstance} from "antd/es";

export const Quit = observer(() => {
  const {wrStore} = allStores;

  const {workerRecruitmentDataSource: datasource} = wrStore
  const [workerRecruitmentReq, setWorkerRecruitmentReq] = useState<WorkerRecruitmentReq>({
    pageNum: 1,
    perPage: 10,
    status: WorkerRecruitmentStatus.exited
  })
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const exitInfo = React.createRef<FormInstance>();

  const handleView = (record: WorkerRecruitmentRow) => {
    wrStore.updateWR(record)
    setVisible(true)
  }

  const handleDeleteBatch = (record?: WorkerRecruitmentRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record._id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        WorkerRecruitmentService.deleteBatchWorkerRecruitment(ids).then(rsp => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(workerRecruitmentReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  useEffect(() => {
    refreshData(workerRecruitmentReq)
  }, [])

  useEffect(() => {
    if (wrStore.wr.data && modalVisible) {
      setVisible(true)
      setModalVisible(false)
    }
  }, [wrStore.wr])

  const refreshData = (recruitmentReq: WorkerRecruitmentReq) => {
    const req = {...recruitmentReq}
    setWorkerRecruitmentReq(req)
    wrStore.getWorkerRecruitments(req)
  }

  const handleActiveKeyChange = (value: string) => {
    let req = {...workerRecruitmentReq}
    req.pageNum = 1
    req.status = value
    refreshData(req);
  }

  const handleProjectIdChange = (value: string) => {
    let req = {...workerRecruitmentReq}
    req.pageNum = 1
    req.projectId = value
    refreshData(req);
  }

  const handlePressEnter = (value: string) => {
    let req = {...workerRecruitmentReq}
    req.pageNum = 1
    req.keyword = value
    refreshData(req);
  }

  const handlePageChange = (page: number, pageSize: number) => {
    const req = {...workerRecruitmentReq}
    req.pageNum = page
    req.perPage = pageSize
    refreshData(req)
    setWorkerRecruitmentReq(req)
  }

  const handleOnClose = () => {
    setVisible(false)
    refreshData(workerRecruitmentReq)
  }
  
  const handleAdd = () => {
    setModalVisible(true)
  }

  const handleOnCancel = () => {
    setModalVisible(false)
  }
  
  const handleExit = () => {
    exitInfo.current?.validateFields().then(value => {
      wrStore.refreshWorkerRecruitment(value.wid)
    })
  }

  return (
    <>
      <HeaderAction
        disabled={wrStore.workerRecruitmentDataSource.loading || confirmLoading}
        handleDeleteBatch={handleDeleteBatch}
        handleActiveKeyChange={handleActiveKeyChange}
        handleProjectIdChange={handleProjectIdChange}
        handlePressEnter={handlePressEnter}
        handleAdd={handleAdd}/>

      <ContentTable
        className={"margin-top-sm"}
        loading={datasource.loading || confirmLoading}
        total={datasource.data?.total!}
        selectedRowKeys={selectedRowKeys}
        perPage={workerRecruitmentReq.perPage}
        pageNum={workerRecruitmentReq.pageNum}
        dataSource={datasource.data?.items!}
        handleBatchDelete={handleDeleteBatch}
        handleView={handleView}
        handlePageChange={handlePageChange}
        handleChange={setSelectedRowKeys}/>

      <Modal
        title={<div className="text-align-center">离职人员选择</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={modalVisible}
        onCancel={handleOnCancel}
        destroyOnClose
        confirmLoading={wrStore.wr.loading}
        onOk={handleExit}>
        <ExitInfo ref={exitInfo}/>
      </Modal>

      <Drawer
        title={<div className="text-align-center">待办事项</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={visible}
        onClose={handleOnClose}>
        <div className={`content`}><Exit/></div>
      </Drawer>
    </>
  )
})