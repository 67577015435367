import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {ContractListReq, ContractListRow, ContractStatisticsRow} from "@services/dto/contractList";
import {ContractListService} from "@services/ContractListService";
import {BLACK_LIST} from "@/config";
import {convertCurrency, formatMoney} from "@common/utils";

export class ContractListStore {
  contractListDatasource: StateFullData<Page<ContractListRow>> = {}
  contractListSelectDatasource: StateFullData<Page<ContractListRow>> = {}
  contractStatisticsDatasource: StateFullData<Page<ContractStatisticsRow>> = {}
  contractListRow?: ContractListRow
  contractListOption?: string
  contractListDetail: StateFullData<ResponseEntity<ContractListRow>> = {}
  contractListDetails: StateFullData<ResponsesEntity<ContractListRow>> = {}
  contractListReq: ContractListReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateContractListRow(row: ContractListRow) {
    this.contractListRow = {...row}
  }

  updateContractListOption(option: string) {
    this.contractListOption = option
  }

  getContractList(req: ContractListReq) {
    this.contractListDatasource = {...this.contractListDatasource, loading: true}
    this.contractListReq = {...this.contractListReq, ...req}
    ContractListService.getContractList(req).then(rsp => {
      runInAction(() => this.contractListDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.contractListDatasource = {...this.contractListDatasource, loading: false, err})
    })
  }

  getSelectContractList(req: ContractListReq) {
    this.contractListSelectDatasource = {...this.contractListSelectDatasource, loading: true}
    ContractListService.getContractList(req).then(rsp => {
      runInAction(() => this.contractListSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.contractListSelectDatasource = {...this.contractListSelectDatasource, loading: false, err})
    })
  }

  getOne(id: string) {
    this.contractListDetail = {...this.contractListDetail, loading: true}
    ContractListService.getOne(id).then(rsp => {
      runInAction(() => this.contractListDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.contractListDetail = {...this.contractListDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.contractListDetails = {...this.contractListDetails, loading: true}
    ContractListService.getBatch(ids).then(rsp => {
      runInAction(() => this.contractListDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.contractListDetails = {...this.contractListDetails, loading: false, err})
    })
  }

  statistics(req: ContractListReq) {
    this.contractStatisticsDatasource = {...this.contractStatisticsDatasource, loading: true}
    ContractListService.statistics(req).then(rsp => {
      runInAction(() => this.contractStatisticsDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.contractStatisticsDatasource = {...this.contractStatisticsDatasource, loading: false, err})
    })
  }

  async getPrintList(record: ContractListRow): Promise<LabelValue[]> {

    const row = this.contractListDatasource.data?.items.find(o => o.id === record.id)

    // 上级合同
    let parent = ''
    if (!BLACK_LIST.includes(row.parentId)) {
      const parentRsp = await ContractListService.getBatch([row.parentId])
      if (parentRsp && parentRsp.code === 200 && parentRsp.data.length === 1) {
        const parentRow = parentRsp.data[0]
        parent = `${parentRow.clName}（${parentRow.clCode}）`
      }
    }

    return [
      {label: '制单日期', value: String(row.clDocDate)},
      {label: '制单编号', value: row.clCode},
      {label: '合同名称', value: row.clName},
      {label: '合同摘要', value: row.clDesc},
      {label: '上级合同', value: parent},
      {
        label: '合同当事人',
        value: (row.contractParties && row.contractParties.length > 0) ? row.contractParties.map(item => item.contractPrivity + '：' + item.contractParties).join("；") : ""
      },
      {label: '合同约定金额(元)', value: `${formatMoney(row.clAmount)}元（${convertCurrency(row.clAmount)}）`},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '存档批号', value: row.clFileCode},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const contractListStore = new ContractListStore();
