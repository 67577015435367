import React, {useEffect} from "react";
import {Col, Form, Input, InputNumber, Row, Select, Radio, DatePicker} from "antd";
import {FormInstance} from "antd/es";
import {TalentRow} from "@services/dto/talent";
import {allStores} from "@/stores";
import {observer} from "mobx-react";
import {SelectDict} from "@components/SelectDict";

export interface PropsType {
  row: TalentRow;
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const {workerTypeStore} = allStores;
  useEffect(() => {
    workerTypeStore.getWorkerTypes()
  }, [])

  const [form] = Form.useForm()
  useEffect(() => {
    form.resetFields()
    form.setFieldsValue(props.row)
  }, [props.row])

  const cardDict = ["身份证"]

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 8}}
      wrapperCol={{span: 16}}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="_id">
          <Input/>
        </Form.Item>
        <Col span={12}>
          <Form.Item
            label="姓名"
            name="name"
            rules={[{required: true, message: '请输入姓名!'}]}>
            <Input placeholder="请输入姓名"/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="手机号码"
            name="phone"
            rules={[{required: true, message: '请输入手机号码!', pattern: /0?(13|14|15|17|18|19)[0-9]{9}$/}]}>
            <Input placeholder="请输入手机号码"/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="民族"
            name="nationality"
            rules={[{required: true, message: '请输入民族!'}]}>
            <Input placeholder="请输入民族"/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="户籍城市"
            name="cityHoldRegistration"
            rules={[{required: true, message: '请输入户籍城市!'}]}>
            <Input placeholder="请输入户籍城市"/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="工种"
            name="workerTypes"
            rules={[{required: true, message: '请选择工种!'}]}>
            <Select allowClear placeholder="请选择工种" labelInValue mode="multiple">
              {
                workerTypeStore.workerTypes.items.map(item => {
                  return (
                    <Select.Option key={item._id} value={item._id}>{ item.name }</Select.Option>
                  )
                })
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="籍贯"
            name="nativePlace"
            rules={[{required: true, message: '请输入籍贯!'}]}>
            <Input placeholder="请输入籍贯"/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="年龄"
            name="age"
            rules={[{required: true, message: '请输入年龄!'}]}>
            <InputNumber min={16} max={100} precision={0} placeholder="请输入年龄" className={'width-100-percentage'}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="性别"
            name="gender"
            rules={[{required: true, message: '请选择性别!'}]}>
            <Radio.Group>
              <Radio value={1}>女</Radio>
              <Radio value={0}>男</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="证件类型"
            name="chinaUidType"
            rules={[{required: true, message: '请选择证件类型!'}]}>
            <Select allowClear placeholder="请选择证件类型">
              {
                cardDict.map(item => {
                  return (
                    <Select.Option key={item} value={item}>{ item }</Select.Option>
                  )
                })
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="身份证"
            name="chinaUid"
            rules={[{required: true, message: '请输入身份证!', pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/}]}>
            <Input placeholder="请输入身份证"/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="工作年限"
            name="workingYear"
            rules={[{required: false, message: '请输入工作年限!'}]}>
            <InputNumber min={0} max={100} precision={0} placeholder="请输入工作年限" className={'width-100-percentage'}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="政治面貌"
            name="politicalStatus"
            rules={[{required: false, message: '请输入政治面貌!'}]}>
            <Input placeholder="请输入政治面貌"/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="最高学历"
            name="highestRecord"
            rules={[{required: false, message: '请选择最高学历!'}]}>
            <SelectDict allowClear code={"dict_highest_record"} placeholder={"请选择最高学历"}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="毕业学校"
            name="graduatedSchool"
            rules={[{required: false, message: '请输入毕业学校!'}]}>
            <Input placeholder="请输入毕业学校"/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="出生日期"
            name="dateOfBirth"
            rules={[{required: false, message: '请选择出生日期!'}]}>
            <DatePicker placeholder={"请选择出生日期"} className={'width-100-percentage'}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="职业等级证"
            name="skillLevel"
            rules={[{required: false, message: '请选择职业等级证!'}]}>
            <SelectDict allowClear code={"dict_skill_level"} placeholder={"请选择职业等级证"}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="所属事业部"
            name="belongToGroup"
            rules={[{required: false, message: '请选择所属事业部!'}]}>
            <SelectDict code={"dict_belong_to_group"} placeholder={"请选择所属事业部"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="详细地址"
            name="address"
            labelCol={{span: 4}}
            wrapperCol={{span: 20}}
            rules={[{required: false, message: '请输入详细地址!'}]}>
            <Input placeholder="请输入详细地址"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="个人介绍"
            name="profile"
            labelCol={{span: 4}}
            wrapperCol={{span: 20}}
            rules={[{required: false, message: '请输入个人介绍!'}]}>
            <Input.TextArea placeholder="请输入个人介绍" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
