import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row, Space} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import dayjs from "dayjs";
import {ContractSettlementRow} from "@services/dto/contractSettlement";
import {SelectFiling} from "@components/SelectFiling";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {SelectDict} from "@components/SelectDict";
import {allStores} from "@/stores";
import {ProjectRow} from "@services/dto/project";
import {SelectContractList} from "@components/SelectContractList";
import {SelectInvoiceRegister} from "@components/SelectInvoiceRegister";
import {SelectBankAccount} from "@components/SelectBankAccount";
import {BankAccountRow} from "@services/dto/bankAccount";
import {ContractListRow} from "@services/dto/contractList";
import {InvoiceRegisterRow} from "@services/dto/invoiceRegister";
import {SelectFundApproval} from "@components/SelectFundApproval";
import {FundApprovalRow} from "@services/dto/fundApproval";
import {accAdd} from "@common/utils";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {FORM_DISABLED_STATE} from "@/config";
import {ProjectService} from "@services/ProjectService";

export interface PropsType {
  row: ContractSettlementRow
  isView?: boolean
  isBatchUpdate?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()

  useEffect(() => {
    form.resetFields()
    const contractSettlementRow = {...props.row}
    filingStore.setFilingFaCode(contractSettlementRow.csCode)
    contractSettlementRow.oldCsEndInAccount = contractSettlementRow.csEndInAccount
    contractSettlementRow.oldCsEndOutAccount = contractSettlementRow.csEndOutAccount
    if (typeof contractSettlementRow.csDate === 'string' && contractSettlementRow.csDate !== '') {
      contractSettlementRow.csDate = dayjs(contractSettlementRow.csDate)
    }
    if (!contractSettlementRow.csBook) {
      contractSettlementRow.csBook = 'N/A'
    }
    if (!contractSettlementRow.outboundInbound) {
      contractSettlementRow.outboundInbound = 'N/A'
    }
    if (typeof contractSettlementRow.cooperationUnit === 'string') {
      contractSettlementRow.cooperationUnit = contractSettlementRow.cooperationUnit !== '' ? contractSettlementRow.cooperationUnit.split(',') : [];
    }
    if (contractSettlementRow.invoiceRegistrations && contractSettlementRow.invoiceRegistrations.length > 0) {
      contractSettlementRow.invoiceRegistrationIds = (contractSettlementRow.invoiceRegistrations as InvoiceRegisterRow[])?.map(o => o.id)
      contractSettlementRow.invoiceRegistrationMoney = contractSettlementRow.invoiceRegistrations.map((item) => {
        return item.irTaxRateSum
      }).reduce((acc, cur) => {
        return accAdd(acc, cur)
      })
    } else {
      contractSettlementRow.invoiceRegistrationIds = ['N/A']
      contractSettlementRow.invoiceRegistrationMoney = 0
    }
    if (typeof contractSettlementRow.contractLists === 'object' && contractSettlementRow.contractLists) {
      contractSettlementRow.contractListIds = (contractSettlementRow.contractLists as ContractListRow[])?.map(o => o.id).join(',')
    }
    if (contractSettlementRow.fundingApprovals) {
      contractSettlementRow.fundingApprovalIds = (contractSettlementRow.fundingApprovals as FundApprovalRow[])?.map(o => o.id)
    }
    setResponsibilityProject(contractSettlementRow.responsibilityProject)
    setCooperationUnit(contractSettlementRow.cooperationUnit)
    form.setFieldsValue(contractSettlementRow);
  }, [props.row.id])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    setResponsibilityProject(value)
    const row = options.find(o => value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  const handleCsEndOutAccountChange = (value: string, options: BankAccountRow[]) => {
    let option = options.find(item => item.id === value)
    form.setFieldsValue({csEndOutAccount: option?.baNick})
    form.setFieldsValue({csEndOutAccountTitle: option?.baName})
    form.setFieldsValue({csEndOutBankAccount: option?.baAccount})
    form.setFieldsValue({csEndOutDepositBank: option?.baBlank})
  }

  const handleCsEndInAccountChange = (value: string, options: BankAccountRow[]) => {
    let option = options.find(item => item.id === value)
    form.setFieldsValue({csEndInAccount: option?.baNick})
    form.setFieldsValue({csEndInAccountTitle: option?.baName})
    form.setFieldsValue({csEndInBankAccount: option?.baAccount})
    form.setFieldsValue({csEndInDepositBank: option?.baBlank})
  }

  const handleContractListChange = async (value: string, options: ContractListRow[]) => {
    const contractList = options.find(item => item.id === value)
    let responsibilityProject: ProjectRow
    if (contractList?.responsibilityProject) {
      [responsibilityProject] = await ProjectService.getBatchByName([contractList?.responsibilityProject])
    }
    form.setFieldsValue({
      responsibilityProject: contractList?.responsibilityProject,
      code: responsibilityProject?.code
    })
    setResponsibilityProject(contractList?.responsibilityProject)
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      disabled={props.isView || FORM_DISABLED_STATE.includes(props.row.csStatus)}
      initialValues={{
        cooperationUnit: ['N/A']
      }}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="csEndOutAccount">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="csEndInAccount">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="organizersUserName">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="csDate"
            rules={[{required: false, message: '请输入制单日期!'}]}>
            <DatePicker disabled placeholder={"请选择制单日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="csCode"
            rules={[{required: false, message: '请输入制单编号!'}]}>
            <Input placeholder="请输入制单编号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="结算类别"
            name="csEndType"
            rules={[{required: false, message: '请输入结算类别!'}]}>
            <SelectDict
              code={'payment_typee'}
              placeholder={'请输入结算类别'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="结算摘要"
            name="csSummany"
            rules={[{required: false, message: '请输入结算摘要!'}]}>
            <Input.TextArea placeholder="请输入结算摘要" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="结算金额"
            name="csEndAmount"
            rules={[{required: false, message: '请输入结算金额!'}]}>
            <InputNumber addonAfter={'元'} min={0} precision={2} placeholder="请输入结算金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="原付款账户"
            name="oldCsEndOutAccount"
            rules={[{required: false, message: '请输入原付款账户!'}]}>
            <Input.TextArea placeholder="请输入原付款账户" disabled autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户"
            name="csEndOutAccountId"
            rules={[{required: false, message: '请选择付款账户!'}]}>
            <SelectBankAccount
              className={'width-100-percentage'}
              allowClear
              isShowAction
              bankAccountTypeOptions={[{id: '内部协作账户', value: '内部协作账户'}, {id: '外部协作账户', value: '外部协作账户'}]}
              onChange={handleCsEndOutAccountChange}
              placeholder={'请选择付款账户'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户名称"
            name="csEndOutAccountTitle"
            rules={[{required: false, message: '请输入付款账户名称!'}]}>
            <Input placeholder="请输入付款账户名称" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款银行账号"
            name="csEndOutBankAccount"
            rules={[{required: false, message: '请输入付款银行账号!'}]}>
            <Input placeholder="请输入付款银行账号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款开户银行"
            name="csEndOutDepositBank"
            rules={[{required: false, message: '请输入付款开户银行!'}]}>
            <Input placeholder="请输入付款开户银行" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="原收款账户"
            name="oldCsEndInAccount"
            rules={[{required: false, message: '请输入原收款账户!'}]}>
            <Input.TextArea placeholder="请输入原收款账户" disabled autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户"
            name="csEndInAccountId"
            rules={[{required: false, message: '请选择收款账户!'}]}>
            <SelectBankAccount
              className={'width-100-percentage'}
              allowClear
              isShowAction
              bankAccountTypeOptions={[{id: '内部协作账户', value: '内部协作账户'}, {id: '外部协作账户', value: '外部协作账户'}]}
              onChange={handleCsEndInAccountChange}
              placeholder={'请选择收款账户'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户名称"
            name="csEndInAccountTitle"
            rules={[{required: false, message: '请输入收款账户名称!'}]}>
            <Input placeholder="请输入收款账户名称" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款银行账号"
            name="csEndInBankAccount"
            rules={[{required: false, message: '请输入收款银行账号!'}]}>
            <Input placeholder="请输入收款银行账号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款开户银行"
            name="csEndInDepositBank"
            rules={[{required: false, message: '请输入收款开户银行!'}]}>
            <Input placeholder="请输入收款开户银行" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联商务合同"
            name="contractListIds"
            rules={[{required: !props.isBatchUpdate, message: '请选择关联商务合同!'}]}>
            <SelectContractList
              className={'width-100-percentage'}
              optionRender={(option) => (
                <Space>
                  <span role="img" aria-label={option.data.clCode}>
                    { option.data.clName }
                  </span>
                  ({ option.data.clCode })
                </Space>
              )}
              onChange={handleContractListChange}
              placeholder={"请选择关联商务合同"}
              allowClear/>
          </Form.Item>
        </Col>
        { /*只是可以看 无法修改*/ }
        <Col span={24}>
          <Form.Item
            label="关联资金审批"
            name="fundingApprovalIds"
            rules={[{required: false, message: '请输入结算资金审批!'}]}>
            <SelectFundApproval
              disabled
              mode={'multiple'}
              className={'width-100-percentage'}
              placeholder={"请选择资金审批"}
              allowClear/>
          </Form.Item>
        </Col>
        { /*todo 有可能是文件*/ }
        <Col span={24}>
          <Form.Item
            label="关联验收证书"
            name="csBook"
            rules={[{required: false, message: '请输入关联验收证书!'}]}>
            <Input disabled placeholder="请输入关联验收证书"/>
          </Form.Item>
        </Col>
        { /*todo 不明*/ }
        <Col span={24}>
          <Form.Item
            label="关联出库入库"
            name="outboundInbound"
            rules={[{required: false, message: '请输入关联出库入库!'}]}>
            <Input disabled placeholder="请输入关联出库入库"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联发票登记"
            name="invoiceRegistrationIds"
            rules={[{required: false, message: '请输入发票登记!'}]}>
            <SelectInvoiceRegister
              allowClear
              fieldNames={{label: 'irInvoiceCode2', value: 'id'}}
              disabled
              mode={'tags'}
              placeholder={'请选择发票登记'}
              className={'width-100-percentage'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联发票金额"
            name="invoiceRegistrationMoney"
            rules={[{required: false, message: '请输入发票金额!'}]}>
            <InputNumber
              precision={2}
              disabled
              addonAfter={'元'}
              placeholder="请输入发票金额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: false, message: '请选择责任部门!'}]}>
            <SelectProject
              placeholder={"请选择责任部门"}
              allowClear
              disabled
              onChange={handleProjectChange}
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              disabled
              allowClear
              mode={'multiple'}
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        {
          props.row.id && <Col span={24}>
            <Form.Item
              label="存档批号"
              name="csFile"
              rules={[{required: false, message: '请选择存档批号!'}]}>
              <SelectFiling
                filingMode={"multiple"}
                isHidePlus
                isHideClose
                responsibilityProject={responsibilityProject}
                cooperationUnit={cooperationUnit}
                placeholder={"请选择存档批号"}
                inputDisabled
                fieldNames={{value: "id", label: "faBatchNumber"}}
                allowClear/>
            </Form.Item>
          </Col>
        }
        <Col span={24}>
          <Form.Item
            label="实现日期"
            name="amountRealizedDate"
            rules={[{required: false, message: '请输入实现日期!'}]}>
            <Input disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="实现金额"
            name="amountRealized"
            rules={[{required: false, message: '请输入实现金额!'}]}>
            <InputNumber
              precision={2}
              disabled
              addonAfter={'元'}
              placeholder="请输入实现金额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="余额"
            name="realizeBalance"
            rules={[{required: false, message: '请输入余额!'}]}>
            <InputNumber
              precision={2}
              disabled
              addonAfter={'元'}
              placeholder="请输入余额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理人员"
            name="personResponsible"
            rules={[{required: false, message: '请选择办理人员!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择办理人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="主办人员"
            name="organizersUserId"
            rules={[{required: false, message: '请选择主办人员!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择主办人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="csStatus"
            rules={[{required: false, message: '请输入办理进度!'}]}>
            <SelectProcessStatus
              allowClear
              placeholder={"请选择办理进度"}
              disabled/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
