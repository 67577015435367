import React, {useEffect, useState} from "react";
import {Divider, Pagination, Select, Space} from "antd";
import {Any} from "@common/types";
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {StatisticsItemsReq, StatisticsItemsRow} from "@services/dto/dict";
import {showErr} from "@common/utils";
import {DictService} from "@services/DictService";
import {FlattenOptionData} from "rc-select/lib/interface";
import {BaseOptionType} from "rc-select/lib/Select";
import {BLACK_LIST} from "@/config";
import {WorkScheduleReq, WorkScheduleRow} from "@services/dto/workSchedule";

export interface PropsType {
  onChange?: (value: Any | null, options: WorkScheduleRow[]) => void
  value?: Any | null
  mode?: 'multiple' | 'tags'
  labelInValue?: boolean
  disabled?: boolean
  className?: string
  allowClear?: boolean
  placeholder?: string
  fieldNames?: {
    value: string,
    label: string
  }
  statisticsItemsReq?: StatisticsItemsReq
  updateCount?: number
  optionRender?: (oriOption: FlattenOptionData<BaseOptionType>, info: {
    index: number;
  }) => React.ReactNode;
}

export const SelectWorkSchedule = observer((props: PropsType) => {
  const [value, setValue] = useState<Any | null>()
  const {workScheduleStore} = allStores
  const [datasource, setDatasource] = useState<WorkScheduleRow[]>([])
  const [total, setTotal] = useState(0)
  const [items, setItems] = useState<WorkScheduleRow[]>([])
  const [statisticsItems, setStatisticsItems] = useState<StatisticsItemsRow[] | WorkScheduleRow[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [searchReq, setSearchReq] = useState<WorkScheduleReq>({
    pageNum: 1,
    perPage: 10
  })

  /**
   *  每个组件默认写法 只需修改 'wsCode'
   */
  const defaultItems: any[] = [
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: '空白',
      [props.fieldNames ? props.fieldNames.label : 'wsCode']: '空白'
    },
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: '',
      [props.fieldNames ? props.fieldNames.label : 'wsCode']: 'N/A'
    },
  ]

  /**
   * 初始化
   */
  useEffect(() => {
    refreshData(searchReq)
    if (props.statisticsItemsReq) {
      getStatisticsItems()
    }
  }, [])

  /**
   * 更新统计
   */
  useEffect(() => {
    if (props.updateCount && props.statisticsItemsReq && props.value) {
      getStatisticsItems()
    }
  }, [props.updateCount]);

  /**
   * 办理进度数据变化
   */
  useEffect(() => {
    if (workScheduleStore.workScheduleSelectDatasource.data) {
      setDatasource(workScheduleStore.workScheduleSelectDatasource.data.items || [])
      setTotal(workScheduleStore.workScheduleSelectDatasource.data.total || 0)
      if (workScheduleStore.workScheduleDetails.data?.data.length) {
        setItems(workScheduleStore.workScheduleDetails.data?.data
          .filter(item => !(workScheduleStore.workScheduleSelectDatasource.data.items || [])
            .some(o => o.id === item.id)))
      }
    }
  }, [workScheduleStore.workScheduleDetails, workScheduleStore.workScheduleSelectDatasource.data])

  /**
   * 值发生改变
   */
  useEffect(() => {
    setValue(props.value)
    if (typeof props.value === 'string' && !BLACK_LIST.includes(props.value)) {
      workScheduleStore.getBatch([props.value])
    } else if (typeof props.value === 'object') {
      const ids = props.value.filter((v: string) => !BLACK_LIST.includes(v))
      if (ids.length) {
        workScheduleStore.getBatch(ids)
      }
    }
  }, [props.value])

  const getStatisticsItems = () => {
    setLoading(true)
    DictService.getStatisticsItems(props.statisticsItemsReq).then(rsp => {
      const row = JSON.parse(JSON.stringify(rsp.data || [])).map((item: StatisticsItemsRow | WorkScheduleRow) => {
        (item as WorkScheduleRow).wsCode = `${(item as WorkScheduleRow).wsCode}(${(item as StatisticsItemsRow).coun})`
        return item
      })
      setStatisticsItems(row);
    }).catch(showErr).finally(() => {
      setLoading(false)
    })
  }

  const refreshData = (req: WorkScheduleReq) => {
    workScheduleStore.getSelectWorkSchedule(req)
  }

  const onChange = (value: Any | null) => {
    setValue(value)
    if (props.onChange) {
      props.onChange(value, [
        ...datasource,
        ...items,
      ]);
    }
  }

  const handleSearch = (inputValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = inputValue
    setSearchReq(req)
    refreshData(req)
    return true
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    const req = {...searchReq}
    req.pageNum = page
    req.perPage = pageSize
    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      {
        props.statisticsItemsReq ? (
          <Select
            allowClear={props.allowClear}
            placeholder={props.placeholder || "请选择工作日程"}
            className={`${props.className} width-100-percentage`}
            value={value}
            onChange={onChange}
            disabled={props.disabled}
            showSearch
            mode={props.mode}
            optionFilterProp="children"
            filterOption={(input: string, option) =>
              String(option?.wsCode).toLowerCase().includes(input.toLowerCase())}
            loading={loading}
            labelInValue={props.labelInValue}
            fieldNames={props.fieldNames || {value: "id", label: "wsCode"}}
            options={statisticsItems as WorkScheduleRow[]}>
          </Select>
        ) : (
          <Select
            allowClear={props.allowClear}
            placeholder={props.placeholder || "请选择工作日程"}
            className={`${props.className} width-100-percentage`}
            dropdownRender={(menu) => (
              <>
                { menu }
                <Divider className={"divider-margin"}/>
                <Space>
                  <Pagination
                    total={total}
                    pageSize={searchReq.perPage}
                    size={"small"}
                    onChange={handlePaginationChange}/>
                </Space>
              </>
            )}
            value={value}
            onChange={onChange}
            showSearch
            onClear={() => handleSearch('')}
            disabled={props.disabled}
            mode={props.mode}
            filterOption={false}
            onSearch={handleSearch}
            loading={workScheduleStore.workScheduleSelectDatasource.loading}
            labelInValue={props.labelInValue}
            fieldNames={props.fieldNames || {value: "id", label: "wsCode"}}
            optionRender={props.optionRender}
            options={[
              ...defaultItems,
              ...datasource,
              ...items,
            ]}>
          </Select>
        )
      }

    </>
  )
})
