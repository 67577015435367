import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {
  BankAccountReq,
  BankAccountRow,
  BankAccountStatisticsReq,
  BankAccountStatisticsRow
} from "@services/dto/bankAccount";

export class BankAccountService {
  static async getBankAccountStatistics(req: BankAccountStatisticsReq): Promise<Page<BankAccountStatisticsRow>> {
    return await apiV1.get("/bankAccount/statistics", {params: req})
  }

  static async getBankAccount(req: BankAccountReq): Promise<Page<BankAccountRow>> {
    return await apiV1.get("/bankAccount/search", {params: req})
  }

  static async addBankAccount(row: BankAccountRow): Promise<ResponseEntity<BankAccountRow>> {
    return await apiV1.post("/bankAccount/save", {...row})
  }

  static async updateBankAccount(row: BankAccountRow): Promise<ResponseEntity<BankAccountRow>> {
    return await apiV1.put("/bankAccount/update", {...row})
  }

  static async deleteBankAccount(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/bankAccount/delete", {data: ids})
  }

  static async getOne(id: string): Promise<ResponseEntity<BankAccountRow>> {
    return await apiV1.get(`/bankAccount/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<BankAccountRow>> {
    return await apiV1.get(`/bankAccount/getBatch/${ids.join(',')}`)
  }
}
