import React, {useEffect, useState} from "react";
import {DatePicker, DatePickerProps, Select} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {Any} from "@common/types";
import {SelectProject} from "@components/SelectProject";
import {CustomAuthHeaderAction, PropsType as ParentPropsType} from "@components/CustomAuthHeaderAction";
import {JobAccountReq} from "@services/dto/jobAccount";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectDict} from "@components/SelectDict";

export interface PropsType extends ParentPropsType {
  setConfirmLoading?: (loading: boolean) => void
  updateCount?: number, // 更新渲染标志
  refreshData?: (req: JobAccountReq) => void,
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<JobAccountReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: JobAccountReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonResponsibleChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleOrganizersUserIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.organizersUserId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleDateChange: DatePickerProps['onChange'] = (_, dateString: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.tsPlanComp = dateString
    setSearchReq(req)
    refreshData(req)
  }

  const handleTsLevelChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.tsLevel = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleConditionChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.condition = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined
    req.condition = undefined
    req.tsPlanComp = undefined
    req.tsLevel = undefined
    req.tsPlanComp1 = undefined
    req.tsPlanComp2 = undefined
    req.personResponsible = undefined
    req.personResponsibleName = undefined
    req.organizersUserId = undefined
    req.organizersUserName = undefined

    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <CustomAuthHeaderAction
        size={props.size}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        isShowColumn={props.isShowColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <DatePicker.MonthPicker
            allowClear
            onChange={handleDateChange}
            className={"width-100-percentage"}/>,
          <SelectDict
            onChange={handleTsLevelChange}
            defaultItems={[]}
            fieldNames={{value: 'value', label: 'key'}}
            value={searchReq?.tsLevel}
            className={"width-100-percentage"}
            placeholder={"请选择任务级别"}
            code={'dict_task_supervision_leve'}
            allowClear/>,
          <Select
            value={searchReq.condition}
            onChange={handleConditionChange}
            placeholder={'请选择条件'}
            className={'width-100-percentage'}
            options={[
              {value: '任务状态统计表-主办人员', label: '任务状态统计表-主办人员'},
              {value: '任务状态统计表-办理人员', label: '任务状态统计表-办理人员'}
            ]}/>,
          <>
            {
              searchReq.condition === '任务状态统计表-主办人员' ? <SelectPersonCharge
                updateCount={props.updateCount}
                onChange={handleOrganizersUserIdChange}
                className={"width-100-percentage"}
                value={searchReq?.organizersUserId}
                placeholder={"请选择主办人员"}
                allowClear/> : <SelectPersonCharge
                updateCount={props.updateCount}
                onChange={handlePersonResponsibleChange}
                className={"width-100-percentage"}
                value={searchReq?.personResponsible}
                placeholder={"请选择办理人员"}
                allowClear/>
            }
          </>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            statisticsItemsReq={{
              table: 'fundingApproval',
              field: 'responsibilityProject',
            }}
            allowClear
            fieldNames={{value: 'id', label: 'name'}}/>,
        ] : []}/>
    </>
  )
}))
