import React, {useEffect, useRef} from 'react'
import {Button, InputNumber, message, Select, Steps, Typography, UploadFile} from "antd";
import {InfoBody, InfoBodyRow} from "@modules/HR/Components/BasicInfo";
import {
  FileRow,
  Salary,
  TodoChecked,
  TodoRow,
  UpdateWorkerRecruitmentRow
} from "@services/dto/workerRecruitment";
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {DeleteOutlined, CheckCircleFilled} from "@ant-design/icons";
import _ from 'lodash';
import './Hire.less'
import {WorkerRecruitmentService} from "@services/WorkerRecruitmentService";
import {getUploadIds, logger, showErr} from "@common/utils";
import {Any} from "@common/types";
import {DATE_SECONDS_FMT, WorkerRecruitmentStatus} from "@/config";
import {DocumentService} from "@services/DocumentService";
import {ToolService} from "@services/ToolService";
import {ExtraInfo} from "@modules/HR/Components/ExtraInfo";
import {UploadCustom} from "@modules/HR/Components/UploadCustom";
import dayjs from "dayjs";
import {SelectDict} from "@components/SelectDict";

export interface StepRowProps {
  rows: TodoRow[]
  checked: TodoChecked[]
  finalStepLabel?: string
  onNext: (row: TodoRow, index: number, lastStep: boolean, fileList?: UploadFile[]) => void
}

export const ProgressSteps = observer((props: StepRowProps) => {
  const {rows, checked, onNext} = props;
  const {wrStore} = allStores;
  const {wr: stateWr} = wrStore
  const wr = stateWr.data!;

  let curStep = 0;

  for (let i = 0; i < rows?.length; i++) {
    if (checked.length <= i) {
      break;
    }
    if (checked[i].isToDo) {
      curStep = i + 1;
    }
  }

  const onPrint = (row: TodoRow) => {
    DocumentService.getDocumentTpls({type: row.type}).then(rsp => {
      if (!rsp.items || rsp.items.length === 0) {
        message.warning("没有对应的相关文档，请先添加相关文档")
      }
      const uri = rsp.items[0].uri
      const id = rsp.items[0]._id
      DocumentService.generateDocumentTpl(id, wr.user._id!).then(rsp => {
        const url = window.URL.createObjectURL(rsp)
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', uri.substring(uri.lastIndexOf("/") + 1))
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }).catch(showErr)
    })

  }

  const handleChange = (item: TodoRow, index: number, isLastStep: boolean, fileList: UploadFile[]) => {
    onNext(item, index, isLastStep, fileList)
  }

  const handleNext = (item: TodoRow, index: number, isLastStep: boolean) => {
    onNext(item, index, isLastStep)
  }

  const isCompleted = (i: number) => curStep > i
  const renderStepRow = (item: TodoRow, index: number) => {
    const isLastStep = index === rows.length - 1
    return <Steps.Step className={'step-container'} key={index}
      title={
        <span>{ item.title }
          { isCompleted(index) && <CheckCircleFilled className="checked-icon"/> }
        </span>
      } subTitle={
        <div className="step-body">
          <div className="desc">{ item.desc }</div>

          <div className="step-buttons">
            { item.action === 'print' && curStep === index &&
            <Button onClick={() => onPrint(item)} className="print-btn" type={"primary"}>生成并下载</Button> }
            {
              curStep === index && <Button className="submit-btn" onClick={() => handleNext(item, index, isLastStep)}
                type="primary">{ isLastStep ? (props.finalStepLabel || '完成') : '下一步' }</Button>
            }
            {
              item.action === 'select' && curStep >= index &&
                <SelectDict
                  value={checked[index]?.type || undefined}
                  code={'dict_tripartite_agreement'}
                  placeholder={'请选择存储位置'}
                  className={'margin-left-xs select-width'}
                  onChange={(value) => handleNext({...item, type: value}, index, isLastStep)}/>
            }
            {
              curStep >= index && <UploadCustom row={checked[index]} files={item.files} handleChange={(info) => handleChange(item, index, isLastStep, info)}/>
            }
          </div>
        </div>
      }/>
  }

  return <Steps progressDot current={curStep} direction="vertical">
    { rows?.map(renderStepRow) }
  </Steps>
})


export const HireBaseWorkInfo = () => {
  const {wrStore} = allStores;
  const {wr: stateWr} = wrStore
  const wr = stateWr.data!;
  const {recruitment} = wr;
  let title: string = ""
  if (recruitment && recruitment.title) {
    title = recruitment.title
  }
  let regionAddress = ""
  if (recruitment && recruitment.region && recruitment.workAddress) {
    if (typeof recruitment.region === 'string') {
      recruitment.region = recruitment.region.split(",").join("")
    }
    regionAddress = recruitment.region + recruitment.workAddress;
  }
  const WORK_INFO_FIELDS: InfoBodyRow[] = [
    {title: '招聘公示', value: title || "-"},
    {title: '入职工种', value: wr.workerTypeName || "-"},
    {title: '入职班组', value: wr.workerGroupName || "-"},
    {title: '工作地点', value: regionAddress || "-"},
    {title: '招聘经理', value: wr.recruiter?.name || '-'},
  ];

  return <>
    <Typography.Title level={4}>
      入职信息
    </Typography.Title>
    <InfoBody rows={WORK_INFO_FIELDS}/>
  </>
}

export const Hire = observer(() => {
  const {wrStore, salaryTypeStore} = allStores;
  const updateApiWorker = useRef<Any>(0)
  const {wr: stateWr} = wrStore
  const wr = stateWr.data!;

  useEffect(() => {
    salaryTypeStore.getSalaryTypes()
  }, [])

  const onNext = async (row: TodoRow, index: number, lastStep: boolean, fileList?: UploadFile[]) => {
    const checked = [...wr.hireTodoChecked || []]
    const files: FileRow[] = []
    let ids = ""
    if (fileList) {
      fileList.forEach(item => {
        let url = item.response ? item.response.url : item.url;
        files.push({
          name: "",
          fileUrl: url,
          createdAt: dayjs().format(DATE_SECONDS_FMT)
        })
      })
      ids = getUploadIds(fileList)
    }
    checked[index] = {
      typeId: row.id,
      isToDo: true,
      type: row.type,
      files
    };
    const wrObj: UpdateWorkerRecruitmentRow = {
      hireTodoChecked: checked.map(item => ({
        typeId: item.typeId,
        isToDo: item.isToDo,
        type: item.type,
        files: item.files
      }))
    }
    if (lastStep && !checked.some(i => !i.isToDo)) {
      wrObj.status = WorkerRecruitmentStatus.hired
    }
    if (ids) {
      try {
        await ToolService.submittedSuccess(ids, row.id);
      } catch (e) {
        showErr(e)
        return
      }
    }
    WorkerRecruitmentService.updateWorkerRecruitment(wr._id, wrObj).then(rsp => {
      message.success(row.title + "完成")
      wrStore.updateHireTodo(checked)
    }).catch(showErr)
  }

  const updateSalaries = (action: 'add' | 'remove' | 'edit', index?: number, item?: Salary) => {
    const newArr = [...wr.salaries]
    switch (action) {
    case "add": {
      if (salaryTypeStore.salaryTypes.items.length > 0 && wr.salaries.length < salaryTypeStore.salaryTypes.items.length) {
        let obj = salaryTypeStore.salaryTypes.items.filter(salary => !wr.salaries.find(s => s.typeName === salary.type))[0]
        newArr.push({typeName: obj.type, name: obj.name, amount: 0});
      } else {
        newArr.push({typeName: "", name: "", amount: 0});
      }
      break;
    }
    case "remove": {
      if (_.isNil(index)) {
        return;
      }
      newArr.splice(index, 1)
      break
    }
    case "edit": {
      if (!item?.amount && String(item?.amount) !== "0") {
        return;
      }
      if (_.isNil(index) || _.isNil(item)) {
        return;
      }
      newArr[index] = {...item};
      if (newArr.filter(s => s.typeName === item.typeName).length > 1) {
        message.warning("已有该工资类型，不能重复添加")
        return;
      }
    }
    }
    wrStore.updateSalaries(newArr)
    callUpdateApi({salaries: newArr.map(i => _.omit(i, '_id'))})
  }

  const callUpdateApi = (req: UpdateWorkerRecruitmentRow) => {
    clearTimeout(updateApiWorker.current)
    // 延迟500，并且防止重复提交产生数据错误
    updateApiWorker.current = setTimeout(
      () => {
        logger.info("请求api更新工资信息...")
        WorkerRecruitmentService.updateWorkerRecruitment(wr._id, req).then(rsp => {
          message.success('工资信息更新成功')
        }).catch(showErr)
      }, 500)
  }

  return <div className="hire-container">
    <HireBaseWorkInfo/>
    <Typography.Title className={"mt16"} level={4}>
      待办事项
    </Typography.Title>
    <ProgressSteps rows={wr.hireTodo}
      finalStepLabel={'完成入职'}
      checked={wr.hireTodoChecked}
      onNext={onNext} 
    />
    <Typography.Title className={"mt16"} level={4}>
      入职信息
    </Typography.Title>
    <ExtraInfo row={wr.user}/>
    
    <Typography.Title className={"mt16"} level={4}>
      工资设置
    </Typography.Title>

    { (wr.salaries || []).map((item, i) => <div className="salary-item" key={i}>
      <Select placeholder="请选择工资类型" labelInValue className={"select"} value={{value: item.typeName, label: item.name}}
        onChange={(e) => updateSalaries('edit', i, {...item, typeName: e.value, name: e.label})}>
        {
          salaryTypeStore.salaryTypes.items.map((item, index) => {
            return (
              <Select.Option key={index} value={item.type}>{ item.name }</Select.Option>
            )
          })
        }
      </Select>
      <InputNumber placeholder={'0.0'} className={"input"} value={item.amount} min={0}
        onChange={e => updateSalaries('edit', i, {...item, amount: e || 0})}/>
      <Button onClick={() => updateSalaries("remove", i)} className="action-button" shape="circle"
        icon={<DeleteOutlined/>}/>
    </div>)
    }
    <Button onClick={() => updateSalaries("add")} type={"primary"}>添加工资类型</Button>
  </div>
})
