import {apiV1} from "@services/apiHelper";
import {CompanyPeopleReq, CompanyPeopleRow, CreateCompanyPeopleReq, UpdateCompanyPeopleReq} from "@services/dto/companyPeople";
import {Page, ResponseEntity} from "@services/dto/common";

export class CompanyPeopleService {
  static async searchCompanyPeople(req: CompanyPeopleReq): Promise<Page<CompanyPeopleRow>> {
    return await apiV1.get("/company/queryAdmin", {params: req})
  }

  static async createCompanyPeople(req: CreateCompanyPeopleReq): Promise<ResponseEntity<CompanyPeopleRow>> {
    return await apiV1.post("/company/addAdmin", {...req})
  }

  static async getCompanyPeople(): Promise<CompanyPeopleRow> {
    return await apiV1.get("/companyPeople")
  }

  static async updateCompanyPeople(req: UpdateCompanyPeopleReq): Promise<CompanyPeopleRow> {
    return await apiV1.put("/companyPeople", {...req})
  }

  static async getCompanyPeopleById(id: string): Promise<ResponseEntity<CompanyPeopleRow>> {
    return await apiV1.get(`/companyPeople/${id}`)
  }

  static async updateCompanyPeopleById(id: string, req: UpdateCompanyPeopleReq): Promise<ResponseEntity<CompanyPeopleRow>> {
    return await apiV1.put(`/company/updateAdmin`, {...req})
  }

  static async deleteCompanyPeopleById(id: string): Promise<void> {
    return await apiV1.delete(`/companyPeople/${id}`)
  }

  static async deleteBatchCompanyPeopleByIds(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/company/deleteAdmin", {data: {ids}})
  }
}