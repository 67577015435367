import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {UserProfileRecord} from "@services/dto/userProfile";
import dayjs from "dayjs";
import {DATE_FMT} from "@/config";
import {UserProfileService} from "@services/UserProfileService";
import {showErr} from "@common/utils";
import {useParams} from "react-router-dom";

export const WorkExperience:FC = observer(() => {
  const [rows, setRows] = useState<UserProfileRecord[]>([])
  const params = useParams();
  
  useEffect(() => {
    loadUserProfile()
  }, [])

  const loadUserProfile = () => {
    UserProfileService.getUserProfileByUserId(params.userId!).then(rsp => {
      setRows(rsp.records)
    }).catch(showErr)
  }
    
  const columns: ColumnsType<UserProfileRecord> = [
    {
      title: '时间',
      align: 'center',
      render: (_, i) => `${dayjs(i.startDate).format(DATE_FMT)}至${dayjs(i.endDate).format(DATE_FMT)}`
    },
    {
      title: '项目名称',
      align: 'center',
      dataIndex: ['projectName'],
    },
    {
      title: '队部',
      align: 'center',
      dataIndex: ['professionalLeagues'],
    },
    {
      title: '工种',
      align: 'center',
      render: (_, record) => record.workerTypes.map(i => i.typeName).join(',')
    },
    {
      title: '职位',
      align: 'center',
      dataIndex: ['professional'],
    },
    {
      title: '离职情况',
      align: 'center',
      dataIndex: ['status'],
    }
  ];
    
  return (
    <>
      <Table 
        rowKey={'_id'} 
        columns={columns} 
        dataSource={rows} 
        size={"middle"}
        pagination={{
          position: ['bottomCenter'],
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`
        }}/>
    </>
  )
})