import React, {useEffect} from "react";
import {Col, Form, Input, Row} from "antd";
import {FormInstance} from "antd/es";
import {OrganizationalRow} from "@services/dto/organizational";
import {observer} from "mobx-react";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {SelectRoleManage} from "@components/SelectRoleManage";
import {RoleManageRow} from "@services/dto/roleManage";

export interface PropsType {
  row: OrganizationalRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  // const strokeColors = [green[6], green[6], green[6], green[6], green[6], green[6], yellow[5], yellow[5], yellow[5], yellow[5], yellow[5], yellow[5], red[5], red[5], red[5], red[5], red[5], red[5]]
  useEffect(() => {
    form.resetFields()
    const organizationalRow = {...props.row}
    if (props.row.id) {
      if (organizationalRow.projects) {
        organizationalRow.projects = organizationalRow.projects.map(item => ({
          ...item,
          label: item.name,
          value: item.id
        }));
      }
      if (organizationalRow.cooperationUnits) {
        organizationalRow.cooperationUnits = organizationalRow.cooperationUnits.map(item => ({
          ...item,
          label: item.name,
          value: item.id
        }));
      }
      if (organizationalRow.rolesM) {
        organizationalRow.rolesMId = organizationalRow.rolesM.map(o => o.id)
      }
    }
    form.setFieldsValue(organizationalRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      disabled={props.isView}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      initialValues={{status: 1, isVirtualUser: 0}}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="rolesM">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="用户账号"
            name="workNumber"
            rules={[{required: true, message: '请输入用户账号!'}]}>
            <Input placeholder="请输入用户账号"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="用户密码"
            name="password"
            // extra={<Progress showInfo={false} percent={33} steps={18} strokeColor={strokeColors} />}
            rules={[{
              required: !props.row.id,
              message: '密码由8-16位数字、大小写字母跟特殊字符组成!',
              pattern: /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,16}/
            }]}>
            <Input.Password placeholder="请输入用户密码"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="用户姓名"
            name="name"
            rules={[{required: true, message: '请输入用户姓名!'}]}>
            <Input placeholder="请输入用户姓名"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="手机号码"
            name="phone"
            rules={[{required: true, message: '请输入手机号码!', pattern: /0?(13|14|15|17|18|19)[0-9]{9}$/}]}>
            <Input placeholder="请输入手机号码"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="用户角色"
            name="rolesMId"
            rules={[{required: false, message: '请选择用户角色!'}]}>
            <SelectRoleManage
              allowClear
              placeholder={'请选择用户角色'}
              onChange={(values: string[], options: RoleManageRow[]) => form.setFieldsValue({rolesM: options?.filter(o=>values?.includes(o.id))})}
              mode={"multiple"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="projects"
            rules={[{required: false, message: '请选择责任部门!'}]}>
            <SelectProject allowClear mode={"multiple"} labelInValue/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnits"
            rules={[{required: false, message: '请选择责任部门!'}]}>
            <SelectCooperativeUnit allowClear mode={"multiple"} labelInValue/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
