import "./index.less"
import React, {FC, useEffect, useLayoutEffect, useState} from "react";
import {Button, Drawer, message, Modal, Space, Table} from 'antd'
import {ColumnsType} from "antd/es/table";
import {ProjectReq, ProjectRow} from "@services/dto/project";
import {FormInstance} from "antd/es";
import {BasicInfo} from './Components/BasicInfo'
import {allStores} from "@/stores";
import {observer} from "mobx-react";
import {logger, parseURLParams, safeCompute, showErr} from "@common/utils";
import {
  DATE_FMT,
  DEFAULT_MODAL_WIDTH,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import {ProjectService} from "@/services/ProjectService";
import {TableProjectText} from "@/components/TableProjectText";
import {TableOptions} from "src/components/TableOptions";
import {HeaderActionCom} from "@modules/Project/Components/HeaderActionCom";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {useLocation} from "react-router-dom";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableFilingText} from "@components/TableFilingText";

const blackColumnList = [
  'action',
]

const wideShowColumn: string[] = [
  'name',
  'code',
  'type',
  'pid',
  'dateOfEstablishment',
  'dissolutionDate',
  // 'superiorType',
  'faFileCode',
  // 'principal',
  'status',
  'action',
]

const narrowShowColumn: string[] = [
  'name',
  'code',
  'type',
  // 'pid',
  'dateOfEstablishment',
  // 'dissolutionDate',
  // 'superiorType',
  // 'faFileCode',
  'principal',
  'status',
  'action',
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: ProjectRow[] // 数据源
}

export const Project: FC = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const location = useLocation()
  const {projectStore, tableColumnsStore, screenResolutionStore} = allStores;
  const basicInfo = React.createRef<FormInstance>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [projectRow, setProjectRow] = useState<ProjectRow>()
  const [dataSource, setDataSource] = useState<ProjectRow[]>([]);
  const [total, setTotal] = useState<number>(0)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [isView, setIsView] = useState<boolean>(false)
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<ProjectRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [searchReq, setSearchReq] = useState<ProjectReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...projectStore.projectReq
  })

  const columns: ColumnsType<ProjectRow> = [
    {
      title: '部门名称',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '部门代码',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '部门类别',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '上级部门',
      dataIndex: 'pid',
      key: 'pid',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText
            value={<TableProjectText projectId={text}/>}/>
        </Space>
      )
    },
    {
      title: '成立日期',
      dataIndex: 'dateOfEstablishment',
      key: 'dateOfEstablishment',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '解散日期',
      dataIndex: 'dissolutionDate',
      key: 'dissolutionDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '部门级别',
      dataIndex: 'superiorType',
      key: 'superiorType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'faFileCode',
      key: 'faFileCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableFilingText id={record.file} value={record.fileCode}></TableFilingText>
        </Space>
      )
    },
    {
      title: '经办记录',
      dataIndex: 'principal',
      key: 'principal',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableOptions
          handleDelete={() => {
            handleDeleteBatch(record)
          }}
          handleEdit={() => {
            handleEdit(record)
          }}
          handleView={() => {
            handleView(record)
          }}
          onClick={() => {
            handleEdit(record)
          }}>
          修改
        </TableOptions>
      )
    }
  ];


  /**
   * @description 初始化
   */
  useEffect(() => {

    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  /**
   * @description 全局查询
   */
  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (projectStore.projectDatasource.data) {
      setDataSource(projectStore.projectDatasource.data.items || [])
      setTotal(projectStore.projectDatasource.data.total || 0)
    }
  }, [projectStore.projectDatasource.data])

  /**
   * @description 表单数据变化
   */
  useEffect(() => {
    if (projectStore.projectRow?.projectId) {
      setProjectRow(projectStore.projectRow)
      setIsView(false)
      setIsModalVisible(true);
      projectStore.updateProjectRow({
        ...projectStore.projectRow,
        projectId: undefined,
      })
    }
  }, [projectStore.projectRow])

  useEffect(() => {
    if (projectStore.projectOption) {
      const [option, paramsStr] = projectStore.projectOption.split("?")
      if (option === 'add') {
        handleAdd()
      } else if (paramsStr) {
        const params = parseURLParams(paramsStr)
        if (option === 'edit') {
          ProjectService.getOne(params.id).then(rsp => {
            if (rsp) {
              handleEdit(rsp)
            }
          })
        }
      }
      projectStore.updateProjectOption(undefined)
    }
  }, [projectStore.projectOption])

  /**
   * 列配置变化
   */
  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => !blackColumnList.includes(o.key as string)).filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, projectStore.collapsed, searchReq.pageNum, searchReq.perPage])

  const refreshData = (req: ProjectReq) => {
    setSearchReq(req)
    projectStore.getCompanyProject(req)
  }

  const handleDeleteBatch = (record?: ProjectRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        ProjectService.deleteBatchCompanyProject(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(searchReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handleView = (record: ProjectRow) => {
    setIsView(true)
    setProjectRow(record)
    setIsModalVisible(true);
  }

  const handleEdit = (record: ProjectRow) => {
    setIsView(false)
    setProjectRow(record)
    setIsModalVisible(true);
  }

  const handleAdd = () => {
    setIsView(false)
    setProjectRow(undefined)
    setIsModalVisible(true);
  };

  const handleOk = () => {
    const row = {...projectRow!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(row, values)
      if (values.faDate) {
        row.dateOfEstablishment = values.dateOfEstablishment.format(DATE_FMT);
      }
      if (values.faPayDate) {
        row.dissolutionDate = values.dissolutionDate.format(DATE_FMT);
      }
      setConfirmLoading(true)
      if (row.id) {
        ProjectService.updateCompanyProject(row.id, row).then(() => {
          setUpdateCount(updateCount + 1)
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        ProjectService.addCompanyProject(row).then(() => {
          setUpdateCount(updateCount + 1)
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setProjectRow(undefined)
  };

  const actionThen = () => {
    message.success("操作成功！").then()
    setIsModalVisible(false);
    setProjectRow(undefined)
    refreshData(searchReq)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(location.pathname) || []
    if (!showColumn.length) return
    // 检验存储中的列表是否符合规范
    showColumn = showColumn.filter(o => columns.some(column => column.key === o))
    // 无论有无存储都触发change，保存当前显示的字段
    setShowColumn(showColumn)
  }, []);

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    projectStore.setCollapsed(collapsed)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  return (
    <div className="project">

      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        isHiddenOptions={props.isHiddenOptions}
        updateCount={updateCount}
        collapsed={projectStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        selectedRowKeys={selectedRowKeys}
        handleShowColumnReset={handleShowColumnReset}
        handleDeleteBatch={handleDeleteBatch}
        handleAdd={handleAdd}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={props.isModalSelect ? columns.filter(o => !blackColumnList.includes(o.key as string)) : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}/>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        size={"middle"}
        loading={props.dataSource ? false : projectStore.projectDatasource.loading || confirmLoading}
        bordered
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`, onChange: handlePageChange
        }}
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys, columnWidth: 50}}/>

      <Drawer
        title={<div className="text-align-center">项目管理</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        destroyOnClose
        onClose={handleCancel}>

        <BasicInfo row={{...projectRow!}} ref={basicInfo} isView={isView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel} className={"margin-right-xs"}>取消</Button>
          {
            !isView && <Button
              type="primary" className={"margin-left-xs"} onClick={handleOk}
              loading={confirmLoading}>确定</Button>
          }
        </div>
      </Drawer>
    </div>
  )
}))
