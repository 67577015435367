import {Page, ResponseEntity} from "@services/dto/common";
import {
  MonthSalaryRow,
  MonthSalaryReq,
  UpdateMonthSalary,
  MonthSalarySumRow,
  MonthSalaryExportReq
} from "@services/dto/monthSalary";
import {apiV1} from "@services/apiHelper";

export class MonthSalaryService {
  static async getMonthPersonSalary(req: MonthSalaryReq): Promise<ResponseEntity<Page<MonthSalaryRow>>> {
    return await apiV1.get("/workerTimes/salary", {params: req})
  }

  static async exportMonthSalary(type: number, exportReq:MonthSalaryExportReq): Promise<ResponseEntity<string>> {
    return await apiV1.post(`/workerTimes/salary/export/${type}`, exportReq)
  }

  static async updateMonthSalary(id: string, row: UpdateMonthSalary): Promise<ResponseEntity<MonthSalaryRow>> {
    return await apiV1.put(`/workerTimes/salary/put/${id}`, row)
  }

  static async getMonthStatisticsSalary(req: MonthSalaryReq): Promise<ResponseEntity<Page<MonthSalarySumRow>>> {
    return await apiV1.get("/workerTimes/salary/Statistics", {params: req})
  }

  static async deleteBatchMonthSalary(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/workerTimes/salary/deleteBatch", {data: {ids}})
  }

  static async exportMonthStatisticsSalary(rows: MonthSalarySumRow[]): Promise<ResponseEntity<string>> {
    return await apiV1.post("/workerTimes/salary/Statistics/export", rows)
  }

  static async generateMonthSalary(type: number, year: number, month: number): Promise<ResponseEntity<string>> {
    return await apiV1.get(`/workerTimes/salary/generate/${type}`, {params: {year, month}})
  }
}