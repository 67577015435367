import React, {FC, useState, useEffect} from "react";
import {Button, Table, Space, message, Modal, Col, Input, Row, Card, Drawer} from 'antd'
import {ColumnsType} from "antd/es/table";
import {EditOutlined, RightCircleOutlined, SearchOutlined} from "@ant-design/icons";
import {TalentRow, TalentReq, WorkerTypeReq} from "@services/dto/talent";
import {FormInstance} from "antd/es";
import {BasicInfo} from './Components/BasicInfo'
import {TalentService} from "@services/TalentService";
import {allStores} from "@/stores";
import {observer} from "mobx-react";
import {
  getGenderLabel,
  getTalentJobStatusLabel,
  logger,
  showErr,
} from "@common/utils";
import {DEFAULT_MODAL_WIDTH, WorkerRecruitmentStatus} from "@/config";
import {AssignInfo} from "@modules/Talent/Components/AssignInfo";
import {WorkerRecruitmentService} from "@services/WorkerRecruitmentService";
import dayjs from "dayjs";

export const Talent: FC = observer(() => {
  const basicInfo = React.createRef<FormInstance>();
  const assignInfo = React.createRef<FormInstance>();
  const [talentObj, setTalentObj] = useState<TalentRow>()
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [dataSource, setDataSource] = useState<TalentRow[]>([]);
  const {talentStore, securityStore} = allStores;
  const [total, setTotal] = useState<number>(0)
  const [talentReq, setTalentReq] = useState<TalentReq>({perPage: 10, pageNum: 1, role: "", source: "0,1,2"})
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>("")
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isView, setIsView] = useState<boolean>(false)

  const columns: ColumnsType<TalentRow> = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => { handleView(record) }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '性别',
      dataIndex: 'gender',
      key: 'gender',
      align: 'center',
      render: (_, record: TalentRow, index) => (
        <Space>
          { getGenderLabel(record.gender) }
        </Space>
      )
    },
    {
      title: '籍贯',
      dataIndex: 'nativePlace',
      key: 'nativePlace',
      align: 'center'
    },
    {
      title: '工种',
      dataIndex: 'workerTypes',
      key: 'workerTypes',
      align: 'center',
      render: (_, record: TalentRow, index) => (
        <Space>
          { record.workerTypes.map(item => {
            if (typeof item === 'object') {
              return item.typeName
            }
            return ""
          }).join(",") }
        </Space>
      )
    },
    {
      title: '手机号码',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center'
    },
    {
      title: '详细地址',
      dataIndex: 'address',
      key: 'address',
      align: 'center'
    },
    {
      title: '办理进度',
      dataIndex: 'jobStatus',
      key: 'jobStatus',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          { getTalentJobStatusLabel(text) }
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record: TalentRow, index) => (
        <Space>
          <Button shape="round" className={"delete-btn"} size={"middle"}
            icon={<RightCircleOutlined/>} onClick={() => { handleAssign(record) }}>招聘</Button>
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined/>}
            onClick={() => { handleEdit(record) }}>修改</Button>
          <Button shape="round" className={"view-btn"} size={"middle"}
            icon={<EditOutlined/>} onClick={() => { handleView(record) }}>查看</Button>
        </Space>
      )
    }
  ];

  useEffect(() => {
    refreshData(talentReq)
  }, [])

  const refreshData = (talentRequest: TalentReq) => {
    talentStore.getTalent(talentRequest)
  }

  useEffect(() => {
    setDataSource(talentStore.talentDatasource.data?.items!)
    setTotal(talentStore.talentDatasource.data?.total!)
  }, [talentStore.talentDatasource])
  
  const handleView = (record: TalentRow) => {
    setIsView(true)
    transformData(record)
    setIsModalVisible(true);
  }

  const handleEdit = (record: TalentRow) => {
    setIsView(false)
    transformData(record)
    setIsModalVisible(true);
  }

  const handleAssign = (record: TalentRow) => {
    transformData(record)
    setShowAssignModal(true);
  }

  const transformData = (record: TalentRow) => {
    const talentRow = {...talentObj!}
    Object.assign(talentRow, record)
    if (talentRow.dateOfBirth) {
      talentRow.dateOfBirth = dayjs(talentRow.dateOfBirth, 'YYYY-MM-DD');
    }
    talentRow.workerTypes = record.workerTypes.map(item => ({
      ...item,
      value: item.typeId,
      label: item.typeName
    }));
    setTalentObj(talentRow)
  }

  const handleOk = () => {
    const talentRow = {...talentObj!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(talentRow, values)
      if (values.dateOfBirth) {
        talentRow.dateOfBirth = values.dateOfBirth.format('YYYY-MM-DD');
      }
      talentRow.workerTypes = values.workerTypes.map((item: WorkerTypeReq) => ({
        typeId: item.value,
        typeName: item.label
      }))
      talentRow.roles = ["normal"]
      talentRow.username = talentRow.phone
      setConfirmLoading(true)
      if (talentRow._id) {
        TalentService.updateTalent(talentRow._id, talentRow).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        TalentService.addTalent(talentRow).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  };
  const handleSaveAssign = ()=>{
    assignInfo.current!.validateFields().then(values => {
      setConfirmLoading(true)
      WorkerRecruitmentService.create({
        recruitment: values.recruitmentId,
        user: talentObj!._id,
        status: WorkerRecruitmentStatus.pmConfirmed,
        recruiter: securityStore.loginRsp?.user._id,
        workerGroupName: values.workerGroupName,
        workerTypeName: values.workerTypeName,
        professionalLeaguesName: values.professionalLeaguesName
      }).then(()=>{
        message.success("分配成功！")
        setTalentObj(undefined)
        setShowAssignModal(false);
        refreshData(talentReq)
      }).catch(showErr).finally(() => setConfirmLoading(false))
    }).catch(logger.warn)
  }

  const actionThen = () => {
    message.success("操作成功！")
    setIsModalVisible(false);
    setTalentObj(undefined)
    refreshData(talentReq)
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setShowAssignModal(false)
    setTalentObj(undefined)
  };

  const handleSearch = () => {
    let req = {...talentReq}
    req.keyword = searchValue
    setTalentReq(req)
    refreshData(req);
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let talentRequest = {...talentReq};
    talentRequest.perPage = pageSize
    talentRequest.pageNum = page
    setTalentReq(talentRequest)
    refreshData(talentRequest)
  }

  return (
    <div className="talent">
      <Row className={"margin-bottom-sm"}>
        <Col span={9}>
          <Input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={handleSearch}
            allowClear
            placeholder="请输入姓名/电话/工种/籍贯"
            suffix={<SearchOutlined onClick={handleSearch}/>}
          />
        </Col>
      </Row>

      <Card>
        <Table 
          rowKey={'_id'} 
          dataSource={dataSource} 
          columns={columns}
          size={"middle"}
          loading={talentStore.talentDatasource.loading || confirmLoading}
          pagination={{
            total: total,
            position: ['bottomCenter'],
            pageSize: talentReq.perPage, 
            current: talentReq.pageNum, 
            showSizeChanger: true, 
            showQuickJumper: true,
            showTotal: total => `共${total}条`, onChange: handlePageChange}} 
          rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys}}/>
      </Card>

      <Drawer
        title={<div className="text-align-center">人才库</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        onClose={handleCancel}>
        <BasicInfo row={talentObj!} ref={basicInfo} isView={isView}/>
        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button type="primary" onClick={handleOk} loading={confirmLoading} className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>

      <Modal
        title={<div className="text-align-center">招聘公示</div>}
        width={DEFAULT_MODAL_WIDTH} 
        open={showAssignModal}
        onCancel={handleCancel}
        onOk={handleSaveAssign}>
        <AssignInfo row={talentObj!} ref={assignInfo}/>
      </Modal>
    </div>
  )
})
