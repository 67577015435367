import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, StateFullData} from "@services/dto/common";
import {FinalSettlementReq, FinalSettlementRow} from "@services/dto/finalSettlement";
import {FinalSettlementService} from "@services/FinalSettlementService";
import {BLACK_LIST} from "@/config";
import {FilingService} from "@services/FilingService";
import {convertCurrency, formatMoney} from "@common/utils";

export class FinalSettlementStore {
  finalSettlementDatasource: StateFullData<Page<FinalSettlementRow>> = {}
  finalSettlementSelectDatasource: StateFullData<Page<FinalSettlementRow>> = {}
  finalSettlementRow?: FinalSettlementRow
  finalSettlementDetail: StateFullData<ResponseEntity<FinalSettlementRow>> = {}
  finalSettlementDetails: StateFullData<ResponseEntity<FinalSettlementRow[]>> = {}
  finalSettlementReq: FinalSettlementReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateFinalSettlementRow(row: FinalSettlementRow) {
    this.finalSettlementRow = {...row}
  }

  getFinalSettlement(req: FinalSettlementReq) {
    this.finalSettlementDatasource = {...this.finalSettlementDatasource, loading: true}
    this.finalSettlementReq = {...this.finalSettlementReq, ...req}
    FinalSettlementService.getFinalSettlement(req).then(rsp => {
      runInAction(() => this.finalSettlementDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.finalSettlementDatasource = {...this.finalSettlementDatasource, loading: false, err})
    })
  }

  getSelectFinalSettlement(req: FinalSettlementReq) {
    this.finalSettlementSelectDatasource = {...this.finalSettlementSelectDatasource, loading: true}
    FinalSettlementService.getFinalSettlement(req).then(rsp => {
      runInAction(() => this.finalSettlementSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.finalSettlementSelectDatasource = {...this.finalSettlementSelectDatasource, loading: false, err})
    })
  }

  getOne(id: string) {
    this.finalSettlementDetail = {...this.finalSettlementDetail, loading: true}
    FinalSettlementService.getOne(id).then(rsp => {
      runInAction(() => this.finalSettlementDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.finalSettlementDetail = {...this.finalSettlementDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.finalSettlementDetails = {...this.finalSettlementDetails, loading: true}
    FinalSettlementService.getBatch(ids).then(rsp => {
      runInAction(() => this.finalSettlementDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.finalSettlementDetails = {...this.finalSettlementDetails, loading: false, err})
    })
  }

  async getPrintList(record: FinalSettlementRow): Promise<LabelValue[]> {

    const row = this.finalSettlementDatasource.data?.items.find(o => o.id === record.id)

    // 存档批号
    let faFileRow = undefined
    if (row.fsFile && !BLACK_LIST.includes(row.fsFile)) {
      const fsFileRsp = await FilingService.getBatch([row.fsFile])
      if (fsFileRsp && fsFileRsp.code !== 200) {
        return
      }
      faFileRow = fsFileRsp.data[0]
    }

    return [
      {label: '制单日期', value: String(row.fsDate)},
      {label: '制单编号', value: row.fsCode},
      {label: '款项类别', value: row.fsType},
      {label: '款项摘要', value: row.fsDesc},
      {label: '申请金额(元)', value: `${formatMoney(row.fsAmount)}元（${convertCurrency(row.fsAmount)}）`},
      {label: '付款账户', value: row.fsAccountPay},
      {label: '收款账户', value: row.fsAccountIn},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '存档批号', value: faFileRow?.faBatchNumber},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const finalSettlementStore = new FinalSettlementStore();
