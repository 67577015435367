import React, {useEffect} from "react";
import {Col, Form, Input, Row} from "antd";
import {FormInstance} from "antd/es";
import {CascaderPerson} from "@components/CascaderPerson";
import {PersonCascader} from "@services/dto/talent";

export const ExitInfo = React.forwardRef<FormInstance>((props, ref) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
  }, [])

  const handlePersonChange = (value: string[], selectedOptions: PersonCascader[]) => {
    if (value && value.length > 0) {
      const option = selectedOptions.find(item => item.value === value[value.length - 1])
      form.setFieldValue("wid", option?.wid)
    } else {
      form.setFieldValue("wid", "")
    }
    return value;
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="wid">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="员工"
            name="person"
            getValueFromEvent={handlePersonChange}
            rules={[{ required: true, message: '请选择员工!' }]}>
            <CascaderPerson/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
})