import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {CostExpendReq, CostExpendRow} from "@services/dto/costExpend";

export class CostExpendService {
  static async getCostExpend(req: CostExpendReq): Promise<Page<CostExpendRow>> {
    return await apiV1.get("/employeeFeePay/search", {params: req})
  }

  static async addCostExpend(row: CostExpendRow): Promise<ResponseEntity<CostExpendRow>> {
    return await apiV1.post("/employeeFeePay/save", {...row})
  }

  static async updateCostExpend(row: CostExpendRow): Promise<ResponseEntity<CostExpendRow>> {
    return await apiV1.put("/employeeFeePay/update", {...row})
  }

  static async importCostExpend(row: CostExpendRow): Promise<ResponseEntity<CostExpendRow>> {
    return await apiV1.post("/employeeFeePay/import", {...row})
  }

  static async deleteCostExpend(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/employeeFeePay/delete", {data: ids})
  }

  static async reimbursementCostExpend(employeeFeeApplicationId: string, employeeFeePayIds: string): Promise<ResponseEntity<string>> {
    return await apiV1.get("/employeeFeePay/toReimburse?employeeFeeApplicationId=" + employeeFeeApplicationId + "&employeeFeePayIds=" + employeeFeePayIds)
  }

  static async getOne(id: string): Promise<ResponseEntity<CostExpendRow>> {
    return await apiV1.get(`/employeeFeePay/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<CostExpendRow>> {
    return await apiV1.get(`/employeeFeePay/getBatch/${ids.join(',')}`)
  }

  static async batchUpdateCostExpend(selectedRows: CostExpendRow[]): Promise<ResponseEntity<string>> {
    return await apiV1.put("/employeeFeePay/batchUpdate", [...selectedRows])
  }
}
