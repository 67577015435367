import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Button, Col, message, Radio, Row, Spin} from "antd";
import IconFont from "@components/IconFont";
import {allStores} from "@/stores";
import {useNavigate, useParams} from "react-router-dom";
import "@modules/Induction/index.less"
import {LeftOutlined, SaveOutlined, FileAddOutlined} from "@ant-design/icons";
import {BasicInfo} from "@modules/Archives/Components/BasicInfo";
import {EnrollmentFile} from "@modules/Archives/Components/EnrollmentFile";
import {FormInstance} from "antd/es";
import {ArchivesWorkerRecruitmentRow} from "@services/dto/workerRecruitment";
import {getUploadArrayToString, showErr} from "@common/utils";
import {WorkerRecruitmentService} from "@services/WorkerRecruitmentService";
import {SalarySet} from "@modules/Archives/Components/SalarySet";
import {DATE_FMT} from "@/config";

export const ArchiveUserAction: FC = observer(() => {
  const {wrStore} = allStores;
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState<string>("basic")
  const [loading, setLoading] = useState<boolean>(false)
  const basicInfo = React.createRef<FormInstance>();
  const [newSalary, setNewSalary] = useState<number>(0)

  const params = useParams()
  useEffect(() => {
    if (params.id) {
      wrStore.refreshWorkerRecruitment(params.id);
    } else {
      wrStore.updateWR(undefined)
    }
  }, [])

  const backPage = () => {
    navigate(-1)
  }
  
  const handleSave = () => {
    basicInfo.current?.validateFields().then(value => {
      setLoading(true)
      const user = {...wrStore.wr.data?.user!}
      Object.assign(user, value)
      if (typeof user.profilePictureUrl === 'object') {
        user.profilePictureUrl = getUploadArrayToString(user.profilePictureUrl)
      }
      if (value.dateOfBirth) {
        user.dateOfBirth = value.dateOfBirth.format(DATE_FMT);
      } else {
        user.dateOfBirth = ""
      }
      const wrObj: ArchivesWorkerRecruitmentRow = {
        wid: params.id,
        hireTodoChecked: [...wrStore.wr.data?.hireTodoChecked || []],
        user: user
      };
      if (params.id) {
        wrObj.status = "1"
      } else {
        wrObj.projectId = value.projectId
        wrObj.professionalLeagues = value.professionalLeagues
        wrObj.workerTypeName = value.workerTypeName
        wrObj.status = "0"
      }
      WorkerRecruitmentService.workerRecruitmentsHired(wrObj).then(rsp => {
        message.success("操作成功");
        navigate(-1)
      }).catch(showErr).finally(() => setLoading(false));
    }).catch(err => {
      message.warning("请先填写完整的基本资料，然后保存！")
    })
  }
  
  const onNewSalary = () => {
    if (!wrStore.wr.data || !wrStore.wr.data._id) {
      message.warning("请先保存基本信息！")
      return;
    }
    setNewSalary(newSalary + 1);
  }

  return (
    <Spin spinning={loading} tip={"保存中..."}>
      <Row>
        <Col span={16}>
          <Button icon={<LeftOutlined />} type={"primary"} onClick={backPage} className={"margin-right-xs"}>返回上一页</Button>
          <Radio.Group
            value={activeKey}
            onChange={(e) => setActiveKey(e.target.value)}
            buttonStyle="solid"
            size={"middle"}>
            <Radio.Button value={"basic"}>
              <IconFont type={"hr-mokuai-shang"} className={"icon-fontsize icon-rotate"}/>基本资料
            </Radio.Button>
            <Radio.Button value={"hire"}>
              <IconFont type={"hr-caogaoxiang1"} className={"icon-fontsize"}/>入职档案
            </Radio.Button>
            <Radio.Button value={"salary"}>
              <IconFont type={"hr-caogaoxiang1"} className={"icon-fontsize"}/>工资设置
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col span={8} className={"text-align-right"}>
          {
            activeKey === 'salary' && <Button icon={<FileAddOutlined />} type={"primary"} className={"margin-right-xs"} onClick={onNewSalary}>新增</Button>
          }
          <Button icon={<SaveOutlined />} type={"primary"} onClick={handleSave}>保存</Button>
        </Col>
      </Row>
      <div className={`margin-top-sm ${activeKey !== 'basic' ? 'hide' : ''}`}>
        <BasicInfo ref={basicInfo}/>
      </div>
      <div className={`margin-top-sm ${activeKey !== 'hire' ? 'hide' : ''}`}>
        <EnrollmentFile type={activeKey}/>
      </div>
      <div className={`margin-top-sm ${activeKey !== 'salary' ? 'hide' : ''}`}>
        <SalarySet newSalary={newSalary}/>
      </div>
    </Spin>
  )
})