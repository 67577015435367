import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {ContractSettlementReq, ContractSettlementRow} from "@services/dto/contractSettlement";

export class ContractSettlementService {
  static async getContractSettlement(req: ContractSettlementReq): Promise<Page<ContractSettlementRow>> {
    return await apiV1.get("/contractSettlement/search", {params: req})
  }

  static async addContractSettlement(row: ContractSettlementRow): Promise<ResponseEntity<ContractSettlementRow>> {
    return await apiV1.post("/contractSettlement/save", {...row})
  }

  static async updateContractSettlement(row: ContractSettlementRow): Promise<ResponseEntity<ContractSettlementRow>> {
    return await apiV1.put("/contractSettlement/update", {...row})
  }

  static async updateBatchContractSettlement(row: ContractSettlementRow[]): Promise<ResponseEntity<ContractSettlementRow>> {
    return await apiV1.put("/contractSettlement/updateBatch", [...row])
  }

  static async deleteContractSettlement(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/contractSettlement/delete", {data: ids})
  }

  static async getOne(id: string): Promise<ResponseEntity<ContractSettlementRow>> {
    return await apiV1.get(`/contractSettlement/getOne/${id}`)
  }

  static async merge(mergeTo: string, mergeObjects: string[]): Promise<ResponseEntity<ContractSettlementRow>> {
    const myFormData = new FormData()
    myFormData.append("mergeTo", mergeTo)
    // myFormData.append("mergeObjects", JSON.stringify(mergeObjects))
    for (let i = 0; i < mergeObjects.length; i++) {
      myFormData.append(`mergeObjects`, mergeObjects[i])
    }
    return await apiV1.post(`/contractSettlement/merge`, myFormData)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<ContractSettlementRow>> {
    return await apiV1.get(`/contractSettlement/getBatch/${ids.join(',')}`)
  }
}
