import './HeaderSearch.less'
import {observer} from "mobx-react";
import {Col, Row, Select} from "antd";
import React, {useEffect, useState} from "react";
import {allStores} from "@/stores";
import {CooperativeUnitRow} from "@services/dto/cooperativeUnit";
import {ProjectRow} from "@services/dto/project";

export const HeaderSearch = observer(() => {
  const {headerSearchStore, securityStore} = allStores
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string>()
  const [userCooperationUnits, setUserCooperationUnits] = useState<CooperativeUnitRow[] | undefined>([])
  const [userProjects, setUserProjects] = useState<ProjectRow[] | undefined>([])

  useEffect(() => {
    setUserCooperationUnits(securityStore.getLoggedUserCooperationUnits())
    setUserProjects(securityStore.getLoggedUserProjects())
  }, [])

  useEffect(() => {
    setCooperationUnit(headerSearchStore.headerSearchReq.cooperationUnit)
    setResponsibilityProject(headerSearchStore.headerSearchReq.responsibilityProject)
  }, [headerSearchStore.headerSearchReq])

  const handleProjectChange = (value: string) => {
    setResponsibilityProject(value)
    headerSearchStore.updateResponsibilityProject(value)
  }

  const handleCooperativeUnitChange = (value: string) => {
    setCooperationUnit(value)
    headerSearchStore.updateCooperationUnit(value)
  }

  return (
    <div className={"site-layout-center"}>
      <Row gutter={[6, 6]} className={'site-layout-center-row'}>
        <Col span={6} className={'site-layout-center-col'}>
          <Select
            placeholder={"请选择责任部门"}
            className={"width-100-percentage select-background-color"}
            allowClear
            fieldNames={{value: "dictValue", label: "projectName"}}
            options={[
              {projectName: 'N/A', dictValue: 'N/A'},
              {projectName: '空白', dictValue: '空白'},
              ...userProjects ? userProjects : []
            ]}
            showSearch
            filterOption={(input: string, option) => String(option?.projectName).includes(input)}
            value={responsibilityProject}
            onChange={handleProjectChange}/>
        </Col>
        <Col span={6} className={'site-layout-center-col'}>
          <Select
            placeholder={"请选择协作单位"}
            className={"width-100-percentage select-background-color"}
            allowClear
            fieldNames={{value: "dictValue", label: "cooperationUnitName"}}
            showSearch
            filterOption={(input: string, option) => String(option?.cooperationUnitName).includes(input)}
            options={[
              {cooperationUnitName: 'N/A', dictValue: 'N/A'},
              {cooperationUnitName: '空白', dictValue: '空白'},
              ...userCooperationUnits ? userCooperationUnits : []
            ]}
            value={cooperationUnit}
            onChange={handleCooperativeUnitChange}/>
        </Col>
      </Row>
    </div>
  )
})
