import React, {useEffect, useState} from "react";
import {Button, InputNumber, Space} from "antd";
import {observer} from "mobx-react";
import {RedoOutlined, SearchOutlined} from '@ant-design/icons';

export interface PropsType {
  className?: string,
  placeholder?: [string, string],
  onSearch?: (val: [string, string]) => void,
  value?: [string, string]
}

export const CustomInputNumber = observer((props: PropsType) => {

  const [start, setStart] = useState<string>('')
  const [end, setEnd] = useState<string>('')

  useEffect(() => {
    if (props.value) {
      setStart(props.value[0])
      setEnd(props.value[1])
    }
  }, [props.value]);

  const handleSearch = () => {
    if (props.onSearch) {
      props.onSearch([start, end])
    }
  }

  const handleReset = () => {
    setStart('')
    setEnd('')
    props.onSearch(['', ''])
  }

  const handleStartChange = (searchValue: string | null) => {
    setStart(searchValue)
    if (Number(end || '0') < Number(searchValue || '0')) {
      setStart(searchValue)
      setEnd(searchValue)
    }
  }

  return (
    <>
      <Space.Compact
        className={props.className || 'width-100-percentage'}>
        <InputNumber
          style={{width: 'auto'}}
          onChange={handleStartChange}
          min={"0"}
          value={start}
          precision={2}
          placeholder={props.placeholder ? props.placeholder[0] : "请输入最小数"}
        />
        <InputNumber
          style={{width: 'auto'}}
          onChange={(searchValue: string | null) => setEnd(searchValue)}
          min={"0"}
          value={end}
          precision={2}
          placeholder={props.placeholder ? props.placeholder[1] : "请输入最大数"}
        />
        <Button style={{width: "max-content"}} onClick={handleReset}>
          <RedoOutlined/>
        </Button>
        <Button style={{width: "max-content"}} onClick={handleSearch}>
          <SearchOutlined/>
        </Button>
      </Space.Compact>
    </>
  )
})
