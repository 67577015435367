import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {ProjectCollaborationReq, ProjectCollaborationRow} from "@services/dto/projectCollaboration";

export class ProjectCollaborationService {
  static async getProjectCollaboration(req: ProjectCollaborationReq): Promise<Page<ProjectCollaborationRow>> {
    return await apiV1.get("/cooperationUnit/search", {params: req})
  }

  static async addProjectCollaboration(row: ProjectCollaborationRow): Promise<ResponseEntity<ProjectCollaborationRow>> {
    return await apiV1.post("/cooperationUnit/save", {...row})
  }

  static async updateProjectCollaboration(row: ProjectCollaborationRow): Promise<ResponseEntity<ProjectCollaborationRow>> {
    return await apiV1.put("/cooperationUnit/update", {...row})
  }

  static async deleteProjectCollaboration(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/cooperationUnit/delete", {data: ids})
  }

  static async getDetailById(id: string): Promise<ResponseEntity<ProjectCollaborationRow>> {
    return await apiV1.get(`/cooperationUnit/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<ProjectCollaborationRow>> {
    return await apiV1.get(`/cooperationUnit/getBatch/${ids.join(',')}`)
  }
}
