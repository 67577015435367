import {makeAutoObservable, runInAction} from "mobx";
import {AdvanceParams, AdvanceRow} from "@services/dto/advance";
import {AdvanceService} from "@services/AdvanceService";
import {Page, ResponseEntity, StateFullData} from "@services/dto/common";

export class AdvanceStore {
  advanceDatasource: StateFullData<ResponseEntity<Page<AdvanceRow>>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getAdvancePageList(params: AdvanceParams) {
    this.advanceDatasource = {...this.advanceDatasource, loading: true}
    AdvanceService.getAdvancePageList(params).then(rsp => {
      runInAction(() => this.advanceDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.advanceDatasource = {...this.advanceDatasource, loading: false, err})
    })
  }
}

export const advanceStore = new AdvanceStore()