import {observer} from "mobx-react";
import {allStores} from "@/stores";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Drawer, FormInstance, message, Modal, Row, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {logger, showErr} from "@common/utils";
import {DEFAULT_MODAL_WIDTH} from "@/config";
import {MaterialListReq, MaterialListRow} from "@services/dto/materialList";
import {MaterialListService} from "@services/MaterialListService";
import {BasicInfo} from "@modules/MaterialList/Components/BasicInfo";

export const MaterialList = observer(() => {
  const { materialListStore } = allStores
  const basicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<MaterialListRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [materialListRow, setMaterialListRow] = useState<MaterialListRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [searchReq, setSearchReq] = useState<MaterialListReq>({
    pageNum: 1,
    perPage: 10,
  })

  const columns: ColumnsType<MaterialListRow> = [
    {
      title: '名称',
      dataIndex: 'mlName',
      key: 'mlName',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => {
            handleView(record)
          }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '规格型号',
      dataIndex: 'mlType',
      key: 'mlType',
      align: 'center'
    },
    {
      title: '单位',
      dataIndex: 'mlUnit',
      key: 'mlUnit',
      align: 'center'
    },
    {
      title: '数量',
      dataIndex: 'mlNum',
      key: 'mlNum',
      align: 'center'
    },
    {
      title: '单价',
      dataIndex: 'mlPrice',
      key: 'mlPrice',
      align: 'center'
    },
    {
      title: '合价',
      dataIndex: 'mlSumPrice',
      key: 'mlSumPrice',
      align: 'center'
    },
    {
      title: '购买方',
      dataIndex: 'mlPurchaser',
      key: 'mlPurchaser',
      align: 'center'
    },
    {
      title: '销售方',
      dataIndex: 'mlSeller',
      key: 'mlSeller',
      align: 'center'
    },
    {
      title: '办理进度',
      dataIndex: 'mlStatus',
      key: 'mlStatus',
      align: 'center'
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record, index) => (
        <Space>
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined />}
            onClick={() => { handleEdit(record) }}>修改</Button>
          <Button shape="round" className={"delete-btn"} size={"middle"}
            icon={<DeleteOutlined />} onClick={() => { handleDeleteBatch(record) }}>删除</Button>
        </Space>
      )
    }
  ];

  useEffect(() => {
    refreshData(searchReq)
  }, [])

  useEffect(() => {
    setDataSource(materialListStore.materialListDatasource.data?.items!)
    setTotal(materialListStore.materialListDatasource.data?.total!)
  }, [materialListStore.materialListDatasource])

  const handleAdd = () => {
    setMaterialListRow(undefined)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handleView = (record: MaterialListRow) => {
    setMaterialListRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = (record: MaterialListRow) => {
    setMaterialListRow(record)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleDeleteBatch = (record?: MaterialListRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        MaterialListService.deleteMaterialList(ids).then(() => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(searchReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = { ...searchReq };
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: MaterialListReq) => {
    materialListStore.getMaterialList(req)
  }

  const actionThen = () => {
    message.success("操作成功！")
    setIsModalVisible(false);
    setMaterialListRow(undefined)
    refreshData(searchReq)
  }

  const handleOk = () => {
    const row = { ...materialListRow! }
    basicInfo.current!.validateFields().then(values => {
      Object.assign(row, values)
      // if (values.mdDate) {
      //   values.mdDate = values.mdDate.format(DATE_FMT);
      // }
      // if (values.mdOutDate) {
      //   values.mdOutDate = values.mdOutDate.format(DATE_FMT);
      // }
      setConfirmLoading(true)
      if (row.id) {
        MaterialListService.updateMaterialList(row).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        MaterialListService.addMaterialList(row).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  }

  return (
    <div className={"institution"}>
      <Row gutter={12}>
        <Col span={6}>

        </Col>
        <Col span={6}>

        </Col>
        <Col span={12} className={"text-align-right"}>
          <Button icon={<DeleteOutlined />} size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={() => handleDeleteBatch(undefined)}>删除</Button>
          <Button icon={<PlusOutlined />} type={"primary"} size={"middle"} shape={"round"}
            onClick={handleAdd}>新增</Button>
        </Col>
      </Row>

      <Card className={"margin-top-sm"}>
        <Table
          rowKey={"id"}
          dataSource={dataSource}
          columns={columns}
          size={"middle"}
          loading={materialListStore.materialListDatasource.loading || confirmLoading}
          pagination={{
            total: total,
            position: ['bottomCenter'],
            pageSize: searchReq.perPage,
            current: searchReq.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`,
            onChange: handlePageChange
          }}
          rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys }} />
      </Card>

      <Drawer
        title={<div className="text-align-center">物质清单</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        onClose={handleCancel}>
        <BasicInfo row={{ ...materialListRow! }} ref={basicInfo} isView={isView} />

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button type="primary" onClick={handleOk} loading={confirmLoading} className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>
    </div>
  )
})
