import {observer} from "mobx-react";
import {ColumnsType} from "antd/es/table";
import {
  LARGER_MODAL_WIDTH,
  SMALL_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import './MergeContractSettlementModal.less'
import type {DescriptionsProps} from 'antd';
import {Button, FormInstance, message, Space, Table, Typography} from "antd";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import React, {useEffect, useState} from "react";
import {HeaderActionCom} from "@modules/FundApproval/Components/HeaderActionCom";
import {allStores} from "@/stores";
import {useLocation} from "react-router-dom";
import {CustomDraggableModal} from "@components/CustomDraggableModal";
import {ContractSettlementRow} from "@services/dto/contractSettlement";
import {accAdd} from "@common/utils";
import {TableFilingText} from "@components/TableFilingText";

export interface PropsType {
  dataSource?: ContractSettlementRow[]
  open?: boolean
  items?: DescriptionsProps['items']
  handleOk?: (mergeTo: string, mergeObjects: string[]) => void
  handleCancel?: () => void
}

const narrowShowColumn: string[] = [
  'csDate',
  'csCode',
  'csEndType',
  'csSummany',
  'csEndAmount-summation',
  // 'csEndOutAccountId',
  // 'csEndInAccountId',
  // 'contractLists',
  // 'fundingApprovals',
  // 'csBook',
  // 'outboundInbound',
  // 'invoiceRegistrations',
  // 'personResponsible',
  // 'responsibilityProject',
  // 'cooperationUnit',
  // 'csFile',
  // 'amountRealizedDate',
  // 'amountRealized-summation',
  // 'realizeBalance-summation',
  'csStatus',
  'action',
]

export const MergeContractSettlementModal = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const location = useLocation()
  const {bankStatementStore, tableColumnsStore} = allStores
  const [showColumn, setShowColumn] = useState<string[]>(narrowShowColumn)
  const [dataSource, setDataSource] = useState<ContractSettlementRow[]>([])
  const [renderColumns, setRenderColumns] = useState<ColumnsType<ContractSettlementRow>>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    setDataSource(props.dataSource)
  }, [props.dataSource])

  useEffect(() => {
    setIsOpen(props.open)
    setSelectedRowKeys([])
  }, [props.open]);

  /**
   * 列配置变化
   */
  useEffect(() => {
    setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))))
  }, [showColumn])

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem('/fundApproval') || []
    if (!showColumn.length) return
    // 检验存储中的列表是否符合规范
    showColumn = showColumn.filter(o => columns.some(column => column.key === o))
    // 无论有无存储都触发change，保存当前显示的字段
    setShowColumn(showColumn)
  }, []);

  const columns: ColumnsType<ContractSettlementRow> = [
    {
      title: '制单日期',
      dataIndex: 'csDate',
      key: 'csDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'csCode',
      key: 'csCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '结算类别',
      dataIndex: 'csEndType',
      key: 'csEndType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '结算摘要',
      dataIndex: 'csSummany',
      key: 'csSummany',
      width: TABLE_CELL_WIDTH_2x,
      // align: 'center',
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '结算金额(元)',
      dataIndex: 'csEndAmount',
      key: 'csEndAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '付款账户',
      dataIndex: 'csEndOutAccount',
      key: 'csEndOutAccount',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '收款账户',
      dataIndex: 'csEndInAccount',
      key: 'csEndInAccount',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联验收证书',
      dataIndex: 'csBook',
      key: 'csBook',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text} showValue={
            <Typography.Text type="success" className={'c-pointer'}>{ text }</Typography.Text>
          }></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联出库入库',
      dataIndex: 'outboundInbound',
      key: 'outboundInbound',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text} showValue={
            <Typography.Text type="success" className={'c-pointer'}>{ text }</Typography.Text>
          }></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联发票金额(元)',
      dataIndex: 'invoiceRegistrationMoney',
      key: 'invoiceRegistrationMoney',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text, record) => (
        <Space>
          {
            record.invoiceRegistrations && record.invoiceRegistrations.length > 0
              ? <TableMoneyText value={record.invoiceRegistrations.map((item) => {
                return item.irTaxRateSum
              })?.reduce((acc, cur) => {
                return accAdd(acc, cur)
              })}></TableMoneyText>
              : <TableMoneyText value={0}/>
          }

        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'csFile',
      key: 'csFile',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableFilingText id={record.csFile} value={record.csFileCode}></TableFilingText>
        </Space>
      )
    },
    {
      title: '实现日期',
      dataIndex: 'amountRealizedDate',
      key: 'amountRealizedDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '实现金额(元)',
      dataIndex: 'amountRealized',
      key: 'amountRealized-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '余额',
      dataIndex: 'realizeBalance',
      key: 'realizeBalance-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <Space>
            <TableMoneyText value={text}></TableMoneyText>
          </Space>
        </Space>
      )
    },
    {
      title: '办理人员',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '主办人员',
      dataIndex: 'organizersUserName',
      key: 'organizersUserName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'csStatus',
      key: 'csStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
  ];

  /**
   * 解绑按钮事件
   */
  const handleOk = () => {
    if (!selectedRowKeys.length) {
      message.warning("请选择要解绑的数据！").then()
      return
    }
    if (selectedRowKeys.length !== 1) {
      message.warning("请选择一条主体！").then()
      return
    }
    props.handleOk && props.handleOk(selectedRowKeys[0] as string, dataSource.filter(o=>o.id !== selectedRowKeys[0]).map((o=>o.id)))
  }

  /**
   * 取消按钮事件
   */
  const handleCancel = () => {
    props.handleCancel && props.handleCancel()
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    bankStatementStore.setCollapsed(collapsed)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(location.pathname, value)
  }

  const handleShowColumnReset = () => {
    setShowColumn(narrowShowColumn)
  }

  return (
    <CustomDraggableModal
      title={<div className="text-align-center">合并资金审批-操作配置</div>}
      open={isOpen}
      width={LARGER_MODAL_WIDTH}
      footer={[
        <Button
          key="back"
          onClick={handleCancel}>
          取消
        </Button>,
        <Button
          key="1"
          type="primary"
          onClick={() => handleOk()}>
          合并
        </Button>
      ]}
      onCancel={handleCancel}>
      { /*{
        props.items && <Descriptions
          title=""
          items={props.items}/>
      }*/ }
      {
        props.items && <>
          <div className={'desc-box'}>
            {
              props.items.map(item => <div
                key={item.key}
                className={'desc-item'}>
                <span className={'label'}>{ item.label }</span>
                <span>:</span>
                <span className={'children'}>{ item.children }</span>
              </div>)
            }
          </div>
        </>
      }

      <HeaderActionCom
        locationPathname={'/fundApproval'}
        handleShowColumnReset={handleShowColumnReset}
        collapsed={bankStatementStore.collapsed}
        setCollapsed={handleCollapsedChange}
        size={6}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={columns}></HeaderActionCom>

      <Table
        className={"margin-top-xss"}
        rowKey={"id"}
        dataSource={dataSource}
        columns={renderColumns}
        size={"small"}
        bordered
        scroll={{y: SMALL_TABLE_SCROLL_HEIGHT, scrollToFirstRowOnChange: true}}
        rowSelection={{selectedRowKeys: selectedRowKeys, onChange: setSelectedRowKeys, columnWidth: 50}}
        pagination={false}
      />
    </CustomDraggableModal>

  )
}))
