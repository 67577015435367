import {observer} from "mobx-react";
import {ColumnsType} from "antd/es/table";
import {Button, Card, Col, Drawer, FormInstance, Input, message, Modal, Row, Space, Table} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {InstitutionRow, PageParam} from "@services/dto/institution";
import {DEFAULT_MODAL_WIDTH} from "@/config";
import {BasicInfo} from "@modules/Institution/Components/BasicInfo";
import {allStores} from "@/stores";
import {getUploadArrayToString, getUploadIds, getUploadStringToArray, showErr} from "@common/utils";
import {InstitutionService} from "@services/InstitutionService";
import {ResponseEntity} from "@services/dto/common";
import {ToolService} from "@services/ToolService";
import {previewFile} from "@components/CustomUpload";

export const Institution = observer(() => {
  const {institutionStore} = allStores
  const basicInfo = React.createRef<FormInstance>();
  const [searchValue, setSearchValue] = useState<string>("")
  const [dataSource, setDataSource] = useState<InstitutionRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [institutionReq, setInstitutionReq] = useState<PageParam>({perPage: 10, pageNum: 1, pid: '0'})
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [institutionRow, setInstitutionRow] = useState<InstitutionRow>()

  const columns: ColumnsType<InstitutionRow> = [
    {
      title: '部门名称',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => { handleEdit(record) }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '部门代码',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
    },
    {
      title: '负责人',
      dataIndex: ['userRef','username'],
      key: 'userId',
      align: 'center'
    },
    {
      title: '上级部门',
      dataIndex: ['institutions', 'name'],
      key: 'pid',
      align: 'center'
    },
    {
      title: '附件',
      dataIndex: 'file',
      key: 'file',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          {
            text ? <Button onClick={() => previewFile(text)} type={"link"}>查看</Button> : '无'
          }
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record, index) => (
        <Space>
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined/>}
            onClick={() => { handleEdit(record) }}>修改</Button>
          <Button shape="round" className={"delete-btn"} size={"middle"}
            icon={<DeleteOutlined/>} onClick={() => { handleDeleteBatch(record) }}>删除</Button>
        </Space>
      )
    }
  ];

  useEffect(() => {
    refreshData(institutionReq)
  }, [])

  useEffect(() => {
    setDataSource(institutionStore.institutionDatasource.data?.items || [])
    setTotal(institutionStore.institutionDatasource.data?.total || 0)
  }, [institutionStore.institutionDatasource])

  const refreshData = (req: PageParam) => {
    institutionStore.getInstitutions(req)
  }

  const handleAdd = () => {
    setInstitutionRow(undefined)
    setIsModalVisible(true)
  }

  const handleEdit = (record: InstitutionRow) => {
    if (typeof record.file === 'string') {
      record.file = getUploadStringToArray(record.file)
    }
    setInstitutionRow(record)
    setIsModalVisible(true)
  }

  const handleDeleteBatch = (record?: InstitutionRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids:string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        InstitutionService.deleteInstitutions(ids).then((rsp) => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(institutionReq)
        }).catch(showErr).finally(() => setConfirmLoading(false));
      },
    });
  }

  const onSearch = () => {
    let req = {...institutionReq};
    req.pageNum = 1
    req.name = searchValue
    setInstitutionReq(req)
    refreshData(req)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...institutionReq};
    req.perPage = pageSize
    req.pageNum = page
    setInstitutionReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleOk = () => {
    const institutionObj = {...institutionRow!}
    basicInfo.current?.validateFields().then(values => {
      Object.assign(institutionObj, values)
      let ids = ""
      if (typeof institutionObj.file === 'object') {
        ids = getUploadIds(institutionObj.file)
        institutionObj.file = getUploadArrayToString(institutionObj.file)
      }
      institutionObj.institutions = null
      institutionObj.userRef = null
      setConfirmLoading(true)
      if (institutionObj.id) {
        InstitutionService.updateInstitutions(institutionObj).then(rsp => {
          actionThen(rsp, ids);
        }).catch(showErr).finally(() => setConfirmLoading(false))
      } else {
        InstitutionService.addInstitutions(institutionObj).then(rsp => {
          actionThen(rsp, ids);
        }).catch(showErr).finally(() => setConfirmLoading(false))
      }
      setIsModalVisible(false);
    })
  }

  const actionThen = async (rsp: ResponseEntity<InstitutionRow>, ids: string) => {
    // 图片上传跟表单绑定
    try {
      await ToolService.submittedSuccess(ids, rsp.data.id)
      message.success("操作成功！")
    } catch (e) {
      showErr(e)
    } finally {
      setIsModalVisible(false)
      setInstitutionRow(undefined)
      refreshData(institutionReq)
    }
  }

  const handleExpand = (record: InstitutionRow) => {
    InstitutionService.getInstitutions({pid: record.id, perPage: 999, pageNum: 1}).then(rsp => {
      const data = [...dataSource]
      const obj = data.find(item => item.id === record.id)
      if(obj) {
        obj.children = rsp.items
      }
      setDataSource(data)
    }).catch(showErr)
  }

  return (
    <div className={"institution"}>
      <Row>
        <Col span={8}>
          <Input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={onSearch}
            allowClear
            placeholder="请输入组织名称"
            suffix={<SearchOutlined onClick={onSearch}/>}
          />
        </Col>
        <Col span={16} className={"text-align-right"}>
          <Button icon={<DeleteOutlined/>} size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={() => handleDeleteBatch(undefined)}>删除</Button>
          <Button icon={<PlusOutlined/>} type={"primary"} size={"middle"} shape={"round"}
            onClick={handleAdd}>新增</Button>
        </Col>
      </Row>

      <Card className={"margin-top-sm"}>
        <Table
          rowKey={"id"}
          dataSource={dataSource}
          columns={columns}
          size={"middle"}
          loading={confirmLoading}
          expandable={{
            onExpand: (expanded, record) => handleExpand(record)
          }}
          pagination={{
            total: total,
            position: ['bottomCenter'],
            pageSize: institutionReq.perPage,
            current: institutionReq.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`, onChange: handlePageChange}}
          rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys}}/>
      </Card>

      <Drawer
        title={<div className="text-align-center">组织机构</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        onClose={handleCancel}>
        <BasicInfo row={{...institutionRow!}} ref={basicInfo}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          <Button type="primary" onClick={handleOk} loading={confirmLoading} className={"margin-left-xs"}>确定</Button>
        </div>
      </Drawer>
    </div>
  )
})
