import {List} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {WorkerGroup} from "@services/dto/workerGroup";

export class WorkerGroupService {
  static getWorkerGroups(): Promise<List<WorkerGroup>> {
    return apiV1.get('/workerGroups');
  }
}

