import { observer } from "mobx-react";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Drawer, FormInstance, Input, message, Modal, Row, Space, Table} from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import {DATE_FMT, DATE_MONTH_FMT, DEFAULT_MODAL_WIDTH} from "@/config";
import {InsuranceRow, PageParam} from "@services/dto/Insurance";
import {InsuranceBasicInfo} from "@modules/Salary/Components/InsuranceBasicInfo";
import {allStores} from "@/stores";
import {logger, showErr} from "@common/utils";
import {InsuranceService} from "@services/InsuranceService";
import {MonthDatePicker} from "@modules/Salary/Components/MonthDatePicker";
import dayjs from "dayjs";

export const Insurance = observer(() => {
  const {insuranceStore} = allStores;
  const [messageApi, contextHolder] = message.useMessage();
  const basicInfo = React.createRef<FormInstance>();
  const [total, setTotal] = useState<number>(0)
  const [dataSource, setDataSource] = useState<InsuranceRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [insuranceReq, setInsuranceReq] = useState<PageParam>({perPage: 10, pageNum: 1, time: dayjs().format(DATE_FMT)})
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [insuranceRow, setInsuranceRow] = useState<InsuranceRow>()
  const [isView, setIsView] = useState<boolean>(false);

  const columns: ColumnsType<InsuranceRow> = [
    {
      title: '工号',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => { handleView(record) }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '缴纳月份',
      dataIndex: 'time',
      key: 'time',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <div>{ dayjs(text).format(DATE_MONTH_FMT) }</div>
        </Space>
      )
    },
    {
      title: '社保基数(个人/企业)',
      dataIndex: 'socialSecurityBaseUser',
      key: 'socialSecurityBaseUser',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <div>{ record.socialSecurityBaseUser }/{ record.socialSecurityBaseCompany }</div>
        </Space>
      )
    },
    {
      title: '公积金基数(个人/企业)',
      dataIndex: 'providentFundBaseUser',
      key: 'providentFundBaseUser',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <div>{ record.providentFundBaseUser }/{ record.providentFundBaseCompany }</div>
        </Space>
      )
    },
    {
      title: '养老保险(个人/企业)',
      dataIndex: 'endowmentInsuranceUser',
      key: 'endowmentInsuranceUser',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <div>{ record.endowmentInsuranceUser }/{ record.endowmentInsuranceCompany }</div>
        </Space>
      )
    },
    {
      title: '基本医疗保险费(个人/企业)',
      dataIndex: 'basicMedicalInsuranceUser',
      key: 'basicMedicalInsuranceUser',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <div>{ record.basicMedicalInsuranceUser }/{ record.basicMedicalInsuranceCompany }</div>
        </Space>
      )
    },
    {
      title: '失业保险费(个人/企业)',
      dataIndex: 'unemploymentInsuranceUser',
      key: 'unemploymentInsuranceUser',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <div>{ record.unemploymentInsuranceUser }/{ record.unemploymentInsuranceCompany }</div>
        </Space>
      )
    },
    {
      title: '工伤保险(个人/企业)',
      dataIndex: 'employmentInjuryInsuranceUser',
      key: 'employmentInjuryInsuranceUser',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <div>{ record.employmentInjuryInsuranceUser }/{ record.employmentInjuryInsuranceCompany }</div>
        </Space>
      )
    },
    {
      title: '大病互助(个人/企业)',
      dataIndex: 'mutualAidForMajorIllnessesUser',
      key: 'mutualAidForMajorIllnessesUser',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <div>{ record.mutualAidForMajorIllnessesUser }/{ record.mutualAidForMajorIllnessesCompany }</div>
        </Space>
      )
    },
    {
      title: '住房公积金(个人/企业)',
      dataIndex: 'housingFundUser',
      key: 'housingFundUser',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <div>{ record.housingFundUser }/{ record.housingFundCompany }</div>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record, index) => (
        <Space>
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined />}
            onClick={() => { handleEdit(record) }}>修改</Button>
          <Button shape="round" className={"delete-btn"} size={"middle"}
            icon={<DeleteOutlined />} onClick={() => { handleDeleteBatch(record) }}>删除</Button>
        </Space>
      )
    }
  ];

  useEffect(() => {
    refreshData(insuranceReq)
  }, [])

  const refreshData = (req: PageParam) => {
    insuranceStore.getSocialInsurance(req)
  }

  useEffect(() => {
    setDataSource(insuranceStore.insuranceDatasource.data?.items || [])
    setTotal(insuranceStore.insuranceDatasource.data?.total!)
  }, [insuranceStore.insuranceDatasource])

  const handleView = (record: InsuranceRow) => {
    setInsuranceRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = (record: InsuranceRow) => {
    setInsuranceRow(record)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleAdd = () => {
    setInsuranceRow(undefined)
    setIsView(false)
    setIsModalVisible(true);
  };

  const handleDeleteBatch = (record?: InsuranceRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids:string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        InsuranceService.deleteSocialInsurance(ids).then(() => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(insuranceReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handleOk = () => {
    const insuranceParams = {...insuranceRow!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(insuranceParams, values)
      insuranceParams.time = values.time.format(DATE_FMT)
      insuranceParams.userId = values.userCode[values.userCode.length - 1]
      insuranceParams.userCode = values.userCode.join(",")
      setConfirmLoading(true)
      if (insuranceParams.id) {
        InsuranceService.updateSocialInsurance(insuranceParams).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false))
      } else {
        InsuranceService.addSocialInsurance(insuranceParams).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false))
      }
    }).catch(logger.warn)
  };

  const actionThen = () => {
    messageApi.success("操作成功！")
    setIsModalVisible(false);
    setInsuranceRow(undefined)
    refreshData(insuranceReq)
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setInsuranceRow(undefined)
  };

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...insuranceReq};
    req.perPage = pageSize
    req.pageNum = page
    setInsuranceReq(req)
    refreshData(req)
  }

  const onSearch = () => {
    const req = {...insuranceReq};
    req.pageNum = 1
    setInsuranceReq(req)
    refreshData(req)
  }

  const handleDateChange = (date: dayjs.Dayjs, dateString: string) => {
    const req = {...insuranceReq}
    req.pageNum = 1
    req.time = date.format(DATE_FMT)
    setInsuranceReq(req)
    refreshData({...req})
  }

  return (
    <div className={"institution"}>
      { contextHolder }
      <Row>
        <Col span={8}>
          <Input
            value={insuranceReq.code}
            onChange={(e) => setInsuranceReq({...insuranceReq, code: e.target.value})}
            onPressEnter={onSearch}
            allowClear
            placeholder="请输入工号"
            suffix={<SearchOutlined onClick={onSearch} />}
          />
        </Col>
        <Col span={8}>
          <MonthDatePicker handleDateChange={handleDateChange}/>
        </Col>
        <Col span={8} className={"text-align-right"}>
          <Button icon={<DeleteOutlined />} size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={() => handleDeleteBatch(undefined)}>删除</Button>

          <Button icon={<PlusOutlined />} type={"primary"} size={"middle"} shape={"round"}
            onClick={handleAdd}>新增</Button>
        </Col>
      </Row>

      <Card className={"margin-top-sm"}>
        <Table
          rowKey={"id"}
          dataSource={dataSource}
          columns={columns}
          size={"middle"}
          loading={insuranceStore.insuranceDatasource.loading || confirmLoading}
          pagination={{
            total: total,
            position: ['bottomCenter'],
            pageSize: insuranceReq.perPage,
            current: insuranceReq.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`, onChange: handlePageChange}}
          rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys}}/>
      </Card>

      <Drawer
        title={<div className="text-align-center">五险一金</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        onClose={handleCancel}>

        <InsuranceBasicInfo row={{...insuranceRow!}} ref={basicInfo} isView={isView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button type="primary" onClick={handleOk} loading={confirmLoading} className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>
    </div>
  )
})
