import {observer} from "mobx-react";
import {ColumnsType} from "antd/es/table";
import {FundApprovalRow, FundApprovalSummaryTotal} from "@services/dto/fundApproval";
import {
  LARGER_MODAL_WIDTH,
  SMALL_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import './FundApprovalModal.less'
import type {DescriptionsProps} from 'antd';
import {FormInstance, Space, Table, Typography} from "antd";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import React, {useEffect, useState} from "react";
import {HeaderActionCom} from "@modules/FundApproval/Components/HeaderActionCom";
import {allStores} from "@/stores";
import {CustomDraggableModal} from "@components/CustomDraggableModal";
import {TableFilingText} from "@components/TableFilingText";
import {TableApplicationFormText} from "@components/TableApplicationFormText";
import {accAdd} from "@common/utils";

export interface PropsType {
  dataSource: FundApprovalRow[]
  open: boolean
  setOpen: (v: boolean) => void
  items?: DescriptionsProps['items']
}

const narrowShowColumn: string[] = [
  "faDate",
  "faCode",
  "faType",
  "faSummary",
  "faAmount",
  // "faPaymentAccount",
  // "faAccountsReceivable",
  // "faPayDate",
  // "faStatus",
  "statusCommon",
  "action",
]


export const FundApprovalModal = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const {fundApprovalStore, tableColumnsStore} = allStores
  const [showColumn, setShowColumn] = useState<string[]>(narrowShowColumn)
  const [dataSource, setDataSource] = useState<FundApprovalRow[]>([])
  const [renderColumns, setRenderColumns] = useState<ColumnsType<FundApprovalRow>>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [summaryTotalObj, setSummaryTotalObj] = useState<FundApprovalSummaryTotal>({
    faAmount: 0,
    bsAmountRealized: 0,
    bsAmount: 0
  })

  useEffect(() => {
    setDataSource(props.dataSource)
  }, [props.dataSource])

  useEffect(() => {
    setIsOpen(props.open)
    setSelectedRowKeys([])
  }, [props.open]);

  /**
   * @description 表格合计
   */
  useEffect(() => {
    const objInitValue = {
      faAmount: 0,
      bsAmountRealized: 0,
      bsAmount: 0
    }
    let obj = summaryTotalObj
    const datasource = props.dataSource
    const arr = datasource?.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        faAmount: accAdd(acc.faAmount, cur.faAmount),
        bsAmountRealized: accAdd(acc.bsAmountRealized, cur.bsAmountRealized),
        bsAmount: accAdd(acc.bsAmount, cur.bsAmount),
      }), objInitValue);
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [selectedRowKeys])

  /**
   * 列配置变化
   */
  useEffect(() => {
    setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))))
  }, [showColumn])

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem('/fundApproval') || []
    if (!showColumn.length) return
    // 检验存储中的列表是否符合规范
    showColumn = showColumn.filter(o => columns.some(column => column.key === o))
    // 无论有无存储都触发change，保存当前显示的字段
    setShowColumn(showColumn)
  }, []);

  const columns: ColumnsType<FundApprovalRow> = [
    {
      title: '排序',
      dataIndex: 'sort',
      key: 'sort',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单日期',
      dataIndex: 'faDate',
      key: 'faDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'faCode',
      key: 'faCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '款项类别',
      dataIndex: 'faType',
      key: 'faType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '款项摘要',
      dataIndex: 'faSummary',
      key: 'faSummary',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '金额',
      dataIndex: 'faAmount',
      key: 'faAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '付款账户',
      dataIndex: 'faPaymentAccount',
      key: 'faPaymentAccount',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '收款账户',
      dataIndex: 'faAccountsReceivable',
      key: 'faAccountsReceivable',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联申请类型',
      dataIndex: 'faApplicationFormType',
      key: 'faApplicationFormType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (_, record) => (
        <Space>
          <TableEllipsisText
            value={record.fundingApprovalTables?.length ? record.fundingApprovalTables[0].type : ''}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联申请编号',
      dataIndex: 'faApplicationForm',
      key: 'faApplicationForm',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableApplicationFormText
            type={record.fundingApprovalTables?.length ? record.fundingApprovalTables[0].type : ''}
            value={record.fundingApprovalTables?.map(o => o.businessId)}/>
        </Space>
      )
    },
    {
      title: '关联资金出纳',
      dataIndex: 'bankStatementCodes',
      key: 'bankStatementCodes',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText copyValue={text} value={
            <Typography.Text
              type="success"
              className={'c-pointer'}>{ text }</Typography.Text>
          }></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'faFileCode',
      key: 'faFileCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableFilingText id={record.faFile} value={record.faFileCode}></TableFilingText>
        </Space>
      )
    },
    {
      title: '实现日期',
      dataIndex: 'faPayDate',
      key: 'faPayDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text?.slice(0, 10)}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '实现金额',
      dataIndex: 'bsAmountRealized',
      key: 'bsAmountRealized-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '余额',
      dataIndex: 'bsAmount',
      key: 'bsAmount-summation',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '经办记录',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'faStatus',
      key: 'faStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
  ];

  const handleOk = () => {
    props.setOpen(false)
  }

  const handleCancel = () => {
    props.setOpen(false)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    fundApprovalStore.setCollapsed(collapsed)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem('/fundApproval', value)
  }

  const handleShowColumnReset = () => {
    setShowColumn(narrowShowColumn)
  }

  return (
    <CustomDraggableModal
      title={<div className="text-align-center">查看关联资金审批</div>}
      open={isOpen}
      width={LARGER_MODAL_WIDTH}
      onOk={handleOk}
      onCancel={handleCancel}>
      { /*{
        props.items && <Descriptions
          title=""
          items={props.items}/>
      }*/ }
      {
        props.items && <>
          <div className={'desc-box'}>
            {
              props.items.map(item => <div
                key={item.key}
                className={'desc-item'}>
                <span className={'label'}>{ item.label }</span>
                <span>:</span>
                <span className={'children'}>{ item.children }</span>
              </div>)
            }
          </div>
        </>
      }

      <HeaderActionCom
        locationPathname={'/fundApproval'}
        handleShowColumnReset={handleShowColumnReset}
        collapsed={fundApprovalStore.collapsed}
        setCollapsed={handleCollapsedChange}
        size={6}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={columns}/>

      <Table
        className={"margin-top-xss"}
        rowKey={"id"}
        dataSource={dataSource}
        columns={renderColumns}
        size={"small"}
        bordered
        scroll={{y: SMALL_TABLE_SCROLL_HEIGHT, scrollToFirstRowOnChange: true}}
        rowSelection={{selectedRowKeys: selectedRowKeys, onChange: setSelectedRowKeys, columnWidth: 50}}
        pagination={false}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                (showColumn as string[]).map((key, index) =>
                  key.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                    合计：<TableMoneyText value={summaryTotalObj[key.slice(0, key.indexOf('-'))]}></TableMoneyText> 元
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </CustomDraggableModal>

  )
}))
