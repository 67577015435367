import {Page} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {DocumentReq, DocumentRow} from "@services/dto/document";

export class DocumentService {
  static async getDocumentTpls(req: DocumentReq): Promise<Page<DocumentRow>> {
    return await apiV1.get('/documentTpl', {params: req});
  }

  static async generateDocumentTpl(id: string, userId: string): Promise<Blob> {
    return await apiV1.post(`/documentTpl/${id}/generate`, {userId: userId}, {responseType: "blob"});
  }
}
