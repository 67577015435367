import {makeAutoObservable, runInAction} from "mobx";
import {Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {BankAccountService} from "@services/BankAccountService";
import {
  BankAccountReq,
  BankAccountRow,
  BankAccountStatisticsReq,
  BankAccountStatisticsRow
} from "@services/dto/bankAccount";

export class BankAccountStore {
  bankAccountDatasource: StateFullData<Page<BankAccountRow>> = {}
  bankAccountSelectDatasource: StateFullData<Page<BankAccountRow>> = {}
  bankAccountStatisticsDatasource: StateFullData<Page<BankAccountStatisticsRow>> = {}
  bankAccountDetail: StateFullData<ResponseEntity<BankAccountRow>> = {}
  bankAccountRow?: BankAccountRow
  bankAccountOption?: string
  bankAccountDetailsReq: string[] = []
  bankAccountDetails: StateFullData<ResponsesEntity<BankAccountRow>> = {}
  bankAccountReq: BankAccountReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateBankAccountRow(row: BankAccountRow) {
    this.bankAccountRow = {...row}
  }

  updateBankAccountDetailsReq(req: string[]) {
    this.bankAccountDetailsReq = [
      ...this.bankAccountDetailsReq,
      ...req
    ].filter((v, i, self) => self.indexOf(v) === i)
  }

  updateBankAccountOption(option: string) {
    this.bankAccountOption = option
  }

  getBankAccountStatistics(req: BankAccountStatisticsReq) {
    this.bankAccountStatisticsDatasource = {...this.bankAccountStatisticsDatasource, loading: true}
    BankAccountService.getBankAccountStatistics(req).then(rsp => {
      runInAction(() => this.bankAccountStatisticsDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.bankAccountStatisticsDatasource = {
        ...this.bankAccountStatisticsDatasource,
        loading: false,
        err
      })
    })
  }

  getBankAccount(req: BankAccountReq) {
    this.bankAccountDatasource = {...this.bankAccountDatasource, loading: true}
    this.bankAccountReq = {...this.bankAccountReq, ...req}
    BankAccountService.getBankAccount(req).then(rsp => {
      runInAction(() => this.bankAccountDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.bankAccountDatasource = {...this.bankAccountDatasource, loading: false, err})
    })
  }

  getSelectBankAccount(req: BankAccountReq) {
    this.bankAccountSelectDatasource = {...this.bankAccountSelectDatasource, loading: true}
    BankAccountService.getBankAccount(req).then(rsp => {
      runInAction(() => this.bankAccountSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.bankAccountSelectDatasource = {...this.bankAccountSelectDatasource, loading: false, err})
    })
  }

  getDetailById(id: string) {
    this.bankAccountDetail = {...this.bankAccountDetail, loading: true}
    BankAccountService.getOne(id).then(rsp => {
      runInAction(() => this.bankAccountDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.bankAccountDetail = {...this.bankAccountDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.updateBankAccountDetailsReq(ids)
    this.bankAccountDetails = {...this.bankAccountDetails, loading: true}
    BankAccountService.getBatch(this.bankAccountDetailsReq).then(rsp => {
      runInAction(() => this.bankAccountDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.bankAccountDetails = {...this.bankAccountDetails, loading: false, err})
    })
  }
}

export const bankAccountStore = new BankAccountStore();
