import {observer} from "mobx-react";
import {Button, Checkbox, Col, Divider, Modal, Popover, Row, Table} from "antd";
import dayjs from "dayjs";
import {DATE_MINUTE_FMT, DEFAULT_MODAL_WIDTH, LARGER_MODAL_WIDTH} from "@/config";
import React, {useEffect, useRef, useState} from "react";
import {LabelValue} from "@services/dto/common";
import ReactToPrint from 'react-to-print';
import {ColumnsType} from "antd/es/table";
import {UnorderedListOutlined} from "@ant-design/icons";
import {CheckboxChangeEvent} from "antd/es/checkbox";
import {FundApprovalRow} from "@services/dto/fundApproval";
import {TableMoneyText} from "@components/TableMoneyText";
import {accAdd, safeCompute} from "@common/utils";

export interface PropsType {
  title: string
  cooperativeUnit?: string
  createDate?: string
  printPerson?: string
  contentList?: LabelValue[]
  isPrintTable?: boolean
  isSummary?: boolean
  isModalOpen: boolean
  isHiddenTime?: boolean
  handleCancel?: () => void
  width?: number
  tableColumns?: ColumnsType
  dataSource?: any[]
  narrowShowColumn?: string[]
  parameter?: parameterType
}

export type parameterType = {
  width: number
  height: number
  horizontal: number
  vertical: number
}

export const WindowPrint = observer((props: PropsType) => {

  const componentRef = useRef(null);
  const [columnsPopoverShow, setColumnsPopoverShow] = useState<boolean>(false)
  const [showColumn, setShowColumn] = useState<string[]>([])
  const [renderColumns, setRenderColumns] = useState<ColumnsType<FundApprovalRow>>([])

  /**
   * @description 初始化
   */
  useEffect(() => {
    if (props.narrowShowColumn) {
      setShowColumn(props.narrowShowColumn)
    }
  }, []);

  /**
   * @description 列配置变化
   */
  useEffect(() => {
    const tableColumns: ColumnsType = props.tableColumns || []
    const columns = tableColumns
      .filter(o => showColumn.includes(String(o.key)))
      .map(item => {
        const temp = Object.assign({}, item)
        temp.render = v => v
        return temp
      })
    setRenderColumns(columns)
  }, [showColumn])

  /**
   * @description 打印参数
   */
  const parameter: parameterType = props.parameter || {
    width: 21,
    height: 29.7,
    horizontal: 1.91,
    vertical: 2.54
  }

  /**
   * @description 拟态框事件
   */
  const handleCancel = () => {
    if (props.handleCancel) {
      props.handleCancel()
    }
  }

  /**
   * 列配置事件
   * @param e
   * @param key
   */
  const handleCheckboxChange = (e: CheckboxChangeEvent, key: string) => {
    let _showColumn = showColumn
    if (e.target.checked) {
      _showColumn = props.tableColumns.filter(o => [..._showColumn, key].includes(String(o.key))).map(o => o.key as string)
    } else {
      _showColumn = _showColumn.filter(_key => _key !== key)
    }
    setShowColumn(_showColumn)
  }

  const getPaddingVertical = (size?: 'default' | 'large' | 'small'): number => {
    if (size === 'default') {
      return 18
    } else if (size === 'large') {
      return 35
    } else {
      return 5
    }
  }

  /**
   * @description 单条数据打印
   */
  const windowPrintNode = <div
    id={'window-print'}
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: 'center'
    }}>
    <div id={'print-content'} style={{width: '100%'}}>
      <h1 className={"title"} style={{textAlign: 'center', fontWeight: 'normal'}}>{ props.title }</h1>
      <div className={"header-container"} style={{display: 'flex', marginBottom: '5px'}}>
        {
          props.cooperativeUnit && <div
            className={"company-name"}>
            { props.cooperativeUnit }
          </div>
        }
        {
          props.createDate && <div
            className={"create-date"}
            style={{textIndent: '3rem'}}>
            创建时间：{ props.createDate }
          </div>
        }
      </div>
      {
        props.contentList && props.contentList.map((item, index) => {
          return (
            <div
              className={"content-container"}
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                borderTop: '1px solid #000',
                borderBottom: props.contentList.length === index + 1 ? '1px solid #000' : 'none'
              }}>
              <div className={"content-title"} style={{
                width: '30%',
                flexShrink: 0,
                padding: `${getPaddingVertical(item.size)}px 10px`,
                borderLeft: '1px solid #000',
                borderRight: '1px solid #000',
                display: 'flex',
                flex: 'row',
                alignItems: 'center'
              }}>{ item.label }</div>
              <div className={"content-value"} style={{
                width: '70%',
                padding: `5px 10px`,
                wordBreak: 'break-all',
                borderRight: '1px solid #000',
                display: 'flex',
                flex: 'row',
                alignItems: 'center'
              }}>{ item.value }</div>
            </div>
          )
        })
      }
      {
        !props.isHiddenTime && <div
          className={"footer-container"}
          style={{
            display: 'flex',
            marginTop: '10px'
          }}>
          <div
            className={"print-time"}>
            打印时间：{ dayjs().format(DATE_MINUTE_FMT) }</div>
          {
            props.printPerson &&
            <div className={"print-person"} style={{textIndent: '3rem'}}>打印人：{ props.printPerson }</div>
          }
        </div>
      }
    </div>
  </div>

  /**
   * @description 表格打印
   */
  const windowPrintTableNode = <Table
    rowKey={"id"}
    className={'width-100-percentage'}
    dataSource={props.dataSource}
    columns={renderColumns}
    size={"small"}
    bordered
    pagination={{
      pageSize: 999 * 999,
      hideOnSinglePage: true,
    }}
    summary={(props.isSummary && (showColumn as string[]).some(o => o.includes('-summation'))) ? () => (
      <Table.Summary fixed={"bottom"}>
        <Table.Summary.Row>
          {
            (showColumn as string[]).map((key, index) =>
              key.includes('-summation') ? <Table.Summary.Cell
                index={index} key={index} colSpan={1} className='text-align-right'>
                <TableMoneyText
                  value={props.dataSource.reduce((cur: number, item) => accAdd(cur, safeCompute(Number(item[key.slice(0, key.indexOf('-'))]))), 0)}></TableMoneyText>
              </Table.Summary.Cell> : <Table.Summary.Cell key={index} index={index} colSpan={1}/>)
          }
        </Table.Summary.Row>
      </Table.Summary>
    ) : undefined}/>

  /**
   * 控制列
   */
  const controlNode = <Popover
    title={
      <>
        <div className={'d-flex df-jcsb df-aic padding-left-xs padding-right-xs'}>
          <span>选择需要显示/隐藏的字段</span>
        </div>
      </>
    }
    trigger="click"
    open={columnsPopoverShow}
    placement="bottomRight"
    onOpenChange={(value) => {
      setColumnsPopoverShow(value)
    }}
    content={
      <>
        <Row style={{width: `${10 * 2}em`}}>
          <Divider dashed className={'margin-bottom-xs margin-top-xs'}/>
          {
            props.tableColumns?.map(item => (
              <Col span={12} key={item.key}>
                <Button type={'text'} block className={'text-align-left'}>
                  <Checkbox
                    value={item.key}
                    checked={showColumn.includes(String(item.key))}
                    onChange={(e) => {
                      handleCheckboxChange(e, String(item.key))
                    }}>
                    { String(item.title) }
                  </Checkbox>
                </Button>
              </Col>
            ))
          }
        </Row>
      </>
    }
  >
    <Button
      type={'default'}
      className={'margin-right-xs'}
      onClick={() => {
        setColumnsPopoverShow(!columnsPopoverShow)
      }}>
      <UnorderedListOutlined/>
    </Button>
  </Popover>

  return <Modal
    title={props.title}
    open={props.isModalOpen}
    width={props.width || props.isPrintTable ? LARGER_MODAL_WIDTH : DEFAULT_MODAL_WIDTH}
    footer={[
      <Button
        key="back"
        onClick={handleCancel}>
        关闭
      </Button>,
      <ReactToPrint
        key={'print'}
        trigger={() => {
          // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
          // to the root node of the returned component as it will be overwritten.
          return <Button type={"primary"}>打印</Button>;
        }}
        content={() => componentRef.current}>
      </ReactToPrint>,
    ]}
    onCancel={handleCancel}>
    { /*控制列*/ }
    { props.isPrintTable && controlNode }

    { /*打印盒子*/ }
    <div
      className={'print-box'}
      ref={el => componentRef.current = el}>
      { props.isPrintTable ? windowPrintTableNode : windowPrintNode }
    </div>

    <style lang='less'>
      { `
        @media print {
          @page {
            size: ${props.isPrintTable ? 'landscape' : ''} A4;
            margin: ${props.isPrintTable ? parameter.horizontal : parameter.vertical}cm ${props.isPrintTable ? parameter.vertical : parameter.horizontal}cm; /* 设置边距 */
          }
        }
      ` }
    </style>
  </Modal>
})

export interface WindowPrintImagePropsType {
  handleCancel?: () => void
  title: string
  cooperativeUnit?: string
  imgSrc?: string
  createDate?: string
  printPerson?: string
  isModalOpen: boolean
  isHiddenTime?: boolean
  width?: number
  tableColumns?: ColumnsType
  dataSource?: any[]
  narrowShowColumn?: string[]
}

export const WindowPrintImage = observer((props: WindowPrintImagePropsType) => {

  const componentRef = useRef(null);

  /**
   * @description 拟态框事件
   */
  const handleCancel = () => {
    if (props.handleCancel) {
      props.handleCancel()
    }
  }

  return <Modal
    title={props.title}
    open={props.isModalOpen}
    width={props.width || DEFAULT_MODAL_WIDTH}
    footer={[
      <Button
        key="back"
        onClick={handleCancel}>
        关闭
      </Button>,
      <ReactToPrint
        key={'print'}
        trigger={() => {
          // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
          // to the root node of the returned component as it will be overwritten.
          return <Button type={"primary"}>打印</Button>;
        }}
        content={() => componentRef.current}>
      </ReactToPrint>,
    ]}
    onCancel={handleCancel}>

    { /*打印盒子*/ }
    <div
      className={'print-box'}
      ref={el => componentRef.current = el}>
      <img className={'width-100-percentage show'} alt={props.imgSrc} src={props.imgSrc}/>
    </div>

    <style lang='less'>
      { `
        @media print {
          @page {
            size: A4;
          }
        }
      ` }
    </style>
  </Modal>
})
