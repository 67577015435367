import React, {useEffect} from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {SelectDict} from "@components/SelectDict";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {ProjectRow} from "@services/dto/project";
import {EmployeeFeePayRow} from "@services/dto/employeeFeePay";
import dayjs from "dayjs";

export interface PropsType {
  row: EmployeeFeePayRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    const EmployeeFeePayRow = {...props.row}
    if (EmployeeFeePayRow.id) {
      if (typeof EmployeeFeePayRow.efpPayDate === 'string' && EmployeeFeePayRow.efpPayDate !== '') {
        EmployeeFeePayRow.efpPayDate = dayjs(EmployeeFeePayRow.efpPayDate)
      }
    }
    form.setFieldsValue(EmployeeFeePayRow);
  }, [props.row])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    const row = options.find(o => value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      disabled={props.isView}
      initialValues={{efpStatus: '待完成'}}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="付款日期"
            name="efpPayDate"
            rules={[{required: false, message: '请选择付款日期!'}]}>
            <DatePicker
              placeholder={"请选择付款日期"}
              showTime
              allowClear
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="费用类别"
            name="efpPayType"
            rules={[{required: false, message: '请选择费用类别!'}]}>
            <SelectDict defaultItems={[]} code={'pay_type'} placeholder={'请选择费用类别'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="金额"
            name="efpAmount"
            rules={[{required: false, message: '请输入金额!'}]}>
            <InputNumber min={0} precision={2} placeholder="请输入金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="核定金额"
            name="efpApprovedAmount"
            rules={[{required: false, message: '请输入核定金额!'}]}>
            <InputNumber min={0} precision={2} placeholder="请输入核定金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任人"
            name="personResponsible"
            rules={[{required: true, message: '请选择责任人!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              placeholder={"请选择负责人"}
              onChange={(value, selectedOptions) => form.setFieldsValue({
                personResponsibleName: selectedOptions.find(item => item.id === value)?.name
              })}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: true, message: '请选择责任部门!'}]}>
            <SelectProject placeholder={"请选择责任部门"} allowClear onChange={handleProjectChange}
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
