import React, {FC} from "react";
import {observer} from "mobx-react";
import {Button, Card, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {WorkerRecruitmentRow} from "@services/dto/workerRecruitment";
import dayjs from "dayjs";
import {DATE_FMT, WorkerRecruitmentStatus} from "@/config";
import {getGenderLabel} from "@common/utils";
import {EditOutlined, RollbackOutlined} from "@ant-design/icons";

export interface PropsType {
  className?: string
  loading: boolean
  total: number
  selectedRowKeys: React.Key[]
  perPage: number
  pageNum: number
  dataSource: WorkerRecruitmentRow[]
  handleChange: (selectedRowKeys: React.Key[], selectedRows: WorkerRecruitmentRow[]) => void
  handlePageChange: (page: number, pageSize: number) => void
  handleInduction: (record: WorkerRecruitmentRow) => void
  handleBatchRecall: (record: WorkerRecruitmentRow) => void
  handleQuit: (record: WorkerRecruitmentRow) => void
  handleEdit: (record: WorkerRecruitmentRow) => void
}

export const ContentTable:FC<PropsType> = observer((props: PropsType) => {
  const columns: ColumnsType<WorkerRecruitmentRow> = [
    {
      title: '姓名',
      dataIndex: ['user', 'name'],
      align: "center",
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => { handleEdit(record) }}>{ record.user.name }</Button>
        </Space>
      )
    },
    {
      title: '入职时间',
      dataIndex: "inJobTime",
      align: "center",
      render: (text, record, index) => (
        <Space>
          { text ? dayjs(text).format(DATE_FMT) : "" }
        </Space>
      )
    },
    {
      title: '性别',
      dataIndex: ['user', 'gender'],
      align: "center",
      render: (text, record, index) => (
        <Space>
          { getGenderLabel(text) }
        </Space>
      )
    },
    {
      title: '籍贯',
      dataIndex: ['user', 'nativePlace'],
      align: "center"
    },
    {
      title: '电话',
      dataIndex: ['user', 'phone'],
      align: "center"
    },
    {
      title: '队部',
      dataIndex: "professionalLeaguesName",
      align: "center"
    },
    {
      title: '人员类别',
      dataIndex: ['user', 'personnelCategory'],
      align: "center"
    },
    {
      title: '工种',
      dataIndex: "workerTypeName",
      align: "center"
    },
    {
      title: '职位',
      dataIndex: ['user', 'professional'],
      align: "center"
    },
    {
      title: '招聘经理',
      dataIndex: ['recruiter', "name"],
      align: "center"
    },
    {
      title: '操作',
      align: "center",
      render: (_, record) => (
        <Space size="middle" key={record._id}>
          { buttons(record) }
        </Space>
      ),
    },
  ];

  const buttons = (record: WorkerRecruitmentRow) => {
    switch (record.status) {
    case WorkerRecruitmentStatus.pmConfirmed:
      return <>
        <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined/>}
          onClick={(e) => { handleInduction(record) }}>入职</Button>
        <Button shape="round" className={"delete-btn"} size={"middle"}
          icon={<RollbackOutlined/>} onClick={() => { handleBatchRecall(record) }}>驳回</Button>
      </>
    case WorkerRecruitmentStatus.hired:
      return <>
        <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined/>}
          onClick={() => { handleQuit(record) }}>离职</Button>
      </>
    default:
      return <></>
    }
  }
  
  const handleEdit = (record: WorkerRecruitmentRow) => {
    props.handleEdit(record)
  }
  
  const handleInduction = (record: WorkerRecruitmentRow) => {
    props.handleInduction(record)
  }

  const handleBatchRecall = (record: WorkerRecruitmentRow) => {
    props.handleBatchRecall(record)
  }

  const handleQuit = (record: WorkerRecruitmentRow) => {
    props.handleQuit(record)
  }
    
  const handlePageChange = (page: number, pageSize: number) => {
    props.handlePageChange(page, pageSize)
  }
  
  const handleChange = (selectedRowKeys: React.Key[], selectedRows: WorkerRecruitmentRow[]) => {
    props.handleChange(selectedRowKeys, selectedRows)
  }

  return (
    <>
      <Card className={props.className}>
        <Table
          rowKey={'_id'}
          dataSource={props.dataSource}
          columns={columns}
          size={"middle"}
          loading={props.loading}
          pagination={{
            total: props.total,
            position: ['bottomCenter'],
            pageSize: props.perPage,
            current: props.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`, onChange: handlePageChange
          }}
          rowSelection={{selectedRowKeys: props.selectedRowKeys, onChange: handleChange}}/>
      </Card>
    </>
  )
})