import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {BankStatementService} from "@services/BankStatementService";
import {BankStatementReq, BankStatementRow} from "@services/dto/bankStatement";
import {convertCurrency, formatMoney} from "@common/utils";
import {BankAccountService} from "@services/BankAccountService";
import {BLACK_LIST} from "@/config";
import {FilingService} from "@services/FilingService";

export class BankStatementStore {
  bankStatementDatasource: StateFullData<Page<BankStatementRow>> = {}
  bankStatementSelectDatasource: StateFullData<Page<BankStatementRow>> = {}
  bankStatementRow?: BankStatementRow
  bankStatementOption?: string
  bankStatementDetail: StateFullData<ResponseEntity<BankStatementRow>> = {}
  bankStatementDetails: StateFullData<ResponsesEntity<BankStatementRow>> = {}
  bankStatementReq: BankStatementReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateBankStatementRow(row: BankStatementRow) {
    this.bankStatementRow = {...row}
  }

  updateBankStatementOption(option: string) {
    this.bankStatementOption = option
  }

  getBankStatement(req: BankStatementReq) {
    this.bankStatementDatasource = {...this.bankStatementDatasource, loading: true}
    this.bankStatementReq = {...this.bankStatementReq, ...req}
    BankStatementService.getBankStatement(req).then(rsp => {
      runInAction(() => this.bankStatementDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.bankStatementDatasource = {...this.bankStatementDatasource, loading: false, err})
    })
  }

  getSelectBankStatement(req: BankStatementReq) {
    this.bankStatementSelectDatasource = {...this.bankStatementSelectDatasource, loading: true}
    this.bankStatementReq = {...this.bankStatementReq, ...req}
    BankStatementService.getBankStatement(req).then(rsp => {
      runInAction(() => this.bankStatementSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.bankStatementSelectDatasource = {
        ...this.bankStatementSelectDatasource,
        loading: false,
        err
      })
    })
  }

  getOne(id: string) {
    this.bankStatementDetail = {...this.bankStatementDetail, loading: true}
    BankStatementService.getOne(id).then(rsp => {
      runInAction(() => this.bankStatementDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.bankStatementDetail = {...this.bankStatementDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.bankStatementDetails = {...this.bankStatementDetails, loading: true}
    BankStatementService.getBatch(ids).then(rsp => {
      runInAction(() => this.bankStatementDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.bankStatementDetails = {...this.bankStatementDetails, loading: false, err})
    })
  }

  async getPrintList(record: BankStatementRow): Promise<LabelValue[]> {
    const row = this.bankStatementDatasource.data?.items.find(o => o.id === record.id)

    let faPaymentAccount = undefined
    let faAccountsReceivable = undefined
    if (row.bankAccountId !== 'N/A' && row.bsOtherAccountId !== 'N/A') {
      let accountRsp = await BankAccountService.getBatch([row.bankAccountId, row.bsOtherAccountId])
      if (accountRsp.code !== 200 || accountRsp.data.length !== 2) {
        return
      }
      faPaymentAccount = accountRsp.data[0]
      faAccountsReceivable = accountRsp.data[1]
    } else if (row.bankAccountId !== 'N/A') {
      const {
        data: faPaymentAccountData,
        code: faPaymentAccountCode,
      } = await BankAccountService.getOne(row.bankAccountId)
      if (faPaymentAccountCode === 200) {
        faPaymentAccount = faPaymentAccountData
      } else {
        return
      }
    } else if (row.bsOtherAccountId !== 'N/A') {
      const {
        data: faAccountsReceivableData,
        code: faAccountsReceivableCode,
      } = await BankAccountService.getOne(row.bsOtherAccountId)
      if (faAccountsReceivableCode === 200) {
        faAccountsReceivable = faAccountsReceivableData
      } else {
        return
      }
    }

    // 存档批号
    let bsFileRow = undefined
    if (row.bsFile && !BLACK_LIST.includes(row.bsFile)) {
      const bsFileRsp = await FilingService.getBatch([row.bsFile])
      if (bsFileRsp && bsFileRsp.code !== 200) {
        return
      }
      bsFileRow = bsFileRsp.data[0]
    }

    return [
      {label: '制单日期', value: row.bsDate as string},
      {label: '制单编号', value: row.bsCode},
      {label: '付款账户名称', value: faPaymentAccount?.baName},
      {label: '付款账户银行账号', value: faPaymentAccount?.baAccount},
      {label: '付款账户开户银行', value: faPaymentAccount?.baBlank},
      {label: '收款账户名称', value: faAccountsReceivable?.baName},
      {label: '收款账户银行账号', value: faAccountsReceivable?.baAccount},
      {label: '收款账户开户银行', value: faAccountsReceivable?.baBlank},
      {label: '转入金额(元)', value: `${formatMoney(row.bsInAmount)}元（${convertCurrency(row.bsInAmount)}）`},
      {label: '转出金额(元)', value: `${formatMoney(row.bsOutAmount)}元（${convertCurrency(row.bsOutAmount)}）`},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '存档批号', value: bsFileRow?.faBatchNumber},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const bankStatementStore = new BankStatementStore();
