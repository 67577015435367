import {observer} from "mobx-react";
import React, {useEffect} from "react";
import {Col, DatePicker, Form, FormInstance, Input, InputNumber, Row, Select} from "antd";
import {SubsidyRow} from "@services/dto/subsidy";
import {allStores} from "@/stores";
import {CascaderPerson} from "@components/CascaderPerson";
import dayjs from "dayjs";

export interface PropsType {
  row: SubsidyRow
  isView?: boolean
}

export const SubsidyBasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const {salaryTypeStore} = allStores
  const [form] = Form.useForm()

  useEffect(() => {
    salaryTypeStore.getSalaryTypes()
  }, [])

  useEffect(() => {
    form.resetFields()
    const subsidyRow = { ...props.row }
    if (subsidyRow.id) {
      if (typeof subsidyRow.userCode === 'string') {
        subsidyRow.userCode = subsidyRow.userCode.split(",")
      }
      subsidyRow.time = dayjs(subsidyRow.time)
    }
    form.setFieldsValue(subsidyRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ status: 1, isVirtualUser: 0 }}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="员工"
            name="userCode"
            valuePropName={"person"}
            rules={[{ required: !props.row.id, message: '请选择员工!' }]}>
            <CascaderPerson placeholder={"请选择员工"} disabled={!!props.row.id}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="补贴类型"
            name="type"
            rules={[{ required: true, message: '请选择补贴类型!' }]}>
            <Select
              showSearch
              placeholder={"请选择补贴类型"}
              optionFilterProp="children"
              fieldNames={{value: 'type', label: 'name'}}
              filterOption={(inputValue, option) => String(option?.name).toLowerCase().includes(inputValue.toLowerCase())}
              options={salaryTypeStore.salaryTypes.items}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="补贴时间"
            name="time"
            rules={[{ required: true, message: '请选择补贴时间!' }]}>
            <DatePicker className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="补贴标准"
            name="subsidyStandards"
            rules={[{ required: false, message: '请输入补贴标准!' }]}>
            <Input placeholder="请输入补贴标准"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="补贴金额"
            name="amount"
            rules={[{ required: true, message: '请输入补贴金额!' }]}>
            <InputNumber placeholder="请输入补贴金额" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="备注"
            name="remark"
            rules={[{ required: false, message: '请输入备注!' }]}>
            <Input.TextArea placeholder="请输入备注" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
