import React, {useEffect, useState} from "react";
import {Col, Form, Input, InputNumber, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {AccountSalesRow} from "@services/dto/accountSales";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {allStores} from "@/stores";
import {ProjectRow} from "@services/dto/project";
import {OrganizationalRow} from "@services/dto/organizational";

export interface PropsType {
  row: AccountSalesRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores
  const [mode, setMode] = useState<'multiple' | 'tags'>()

  useEffect(() => {
    form.resetFields()
    const accountSalesRow = {...props.row}
    filingStore.setFilingFaCode(accountSalesRow.codes)
    if (accountSalesRow.id) {
      if (typeof accountSalesRow.cooperationUnit === 'string') {
        const cooperationUnits = accountSalesRow.cooperationUnit.split(",")
        if (cooperationUnits.length > 1) {
          accountSalesRow.cooperationUnit = cooperationUnits
          setMode("multiple")
        }
      }
    }
    form.setFieldsValue(accountSalesRow);
  }, [props.row])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    const row = options.find(o => value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  /**
   * 责任人修改 填充名字
   */
  const handlePersonResponsibleChange = (value: string, selectedOptions: OrganizationalRow[]) => {
    const row = selectedOptions.find(o => value === o.id)
    form.setFieldsValue({personResponsibleName: row.name})
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="发票代码-号码"
            name="slCode"
            rules={[{required: false, message: '请输入发票代码-号码!'}]}>
            <Input placeholder="请输入发票代码-号码"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="商品编码"
            name="slCoding"
            rules={[{required: false, message: '请输入商品编码!'}]}>
            <Input placeholder="请输入商品编码"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="货物名称"
            name="slName"
            rules={[{required: false, message: '请输入货物名称!'}]}>
            <Input placeholder="请输入货物名称"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="规格型号"
            name="slType"
            rules={[{required: false, message: '请输入规格型号!'}]}>
            <Input placeholder="请输入规格型号"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="单位"
            name="slUnit"
            rules={[{required: false, message: '请输入单位!'}]}>
            <Input placeholder="请输入单位"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="数量"
            name="slNum"
            rules={[{required: false, message: '请输入数量!'}]}>
            <InputNumber min={0} precision={2} placeholder="请输入数量" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="单价(不含税)"
            name="slPrice"
            rules={[{required: false, message: '请输入单价(不含税)!'}]}>
            <InputNumber min={0} precision={2} placeholder="请输入单价(不含税)" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="金额(不含税)"
            name="slAmount"
            rules={[{required: false, message: '请输入金额!'}]}>
            <InputNumber min={0} precision={2} placeholder="请输入金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="税率"
            name="slTaxRate"
            rules={[{required: false, message: '请输入税率!'}]}>
            <InputNumber min={0} precision={2} placeholder="请输入税率" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="税额"
            name="slTaxAmount"
            rules={[{required: false, message: '请输入税额!'}]}>
            <InputNumber min={0} precision={2} placeholder="请输入税额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="购买方"
            name="slPurchaser"
            rules={[{required: false, message: '请输入购买方!'}]}>
            <SelectCooperativeUnit
              allowClear
              placeholder="请输入购买方"
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="销售方"
            name="slSeller"
            rules={[{required: false, message: '请输入销售方!'}]}>
            <SelectCooperativeUnit
              allowClear
              placeholder="请输入销售方"
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: true, message: '请选择责任部门!'}]}>
            <SelectProject
              placeholder={"请选择责任部门"} allowClear onChange={handleProjectChange}
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              disabled
              allowClear mode={mode} fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="经办记录"
            name="personResponsible"
            rules={[{required: true, message: '请选择经办记录!'}]}>
            <SelectPersonCharge
              onChange={handlePersonResponsibleChange}
              className={"width-100-percentage"}
              placeholder={"请选择经办记录"}
              fieldNames={{value: 'id', label: 'name'}}
              allowClear/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
