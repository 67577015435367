import {
  ContractRow, ContractSearchReq, addRow, updateRow,
} from "@services/dto/contract";
import { apiV1 } from "@services/apiHelper";
import { Page } from "@services/dto/common";

export class ContractService {
  static async searchContract(req: ContractSearchReq): Promise<Page<ContractRow>> {
    return apiV1.get("/personnelContract", { params: req })
  }
  static async addContract(req: addRow): Promise<Page<ContractRow>> {
    return apiV1.post("/personnelContract", { ...req })
  }
  static async updateContract(req: updateRow): Promise<Page<ContractRow>> {
    return apiV1.put("/personnelContract", { ...req })
  }
  static async deleteContract(ids: string[]): Promise<Page<ContractRow>> {
    return await apiV1.delete("/personnelContract", { data: ids })
  }
}
