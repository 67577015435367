import {makeAutoObservable, runInAction} from "mobx";
import {SalaryTypeService} from "@services/SalaryTypeService";
import {SalaryType} from "@services/dto/salaryType";
import {List} from "@services/dto/common";

export class SalaryTypeStore {
  salaryTypes:List<SalaryType> = {
    items: [],
    total: 0
  }

  constructor() {
    makeAutoObservable(this)
  }

  getSalaryTypes() {
    SalaryTypeService.getSalaryTypes().then(rsp => {
      runInAction(() => {this.salaryTypes = {...rsp}})
    }).catch(err => {
      runInAction(() => {this.salaryTypes = {...this.salaryTypes, err: err}})
    })
  }
}

export const salaryTypeStore = new SalaryTypeStore();
