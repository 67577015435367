import React, {FC} from "react";
import {observer} from "mobx-react";
import {Button, Card, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {WorkerRecruitmentRow} from "@services/dto/workerRecruitment";
import dayjs from "dayjs";
import {DATE_FMT} from "@/config";
import {getGenderLabel} from "@common/utils";
import {DeleteOutlined, SearchOutlined} from "@ant-design/icons";

export interface PropsType {
  className?: string
  loading: boolean
  total: number
  selectedRowKeys: React.Key[]
  perPage: number
  pageNum: number
  dataSource: WorkerRecruitmentRow[]
  handleChange: (selectedRowKeys: React.Key[], selectedRows: WorkerRecruitmentRow[]) => void
  handlePageChange: (page: number, pageSize: number) => void
  handleView: (record: WorkerRecruitmentRow) => void
  handleBatchDelete: (record: WorkerRecruitmentRow) => void
}

export const ContentTable:FC<PropsType> = observer((props: PropsType) => {
  const columns: ColumnsType<WorkerRecruitmentRow> = [
    {
      title: '姓名',
      dataIndex: ['user', 'name'],
      align: "center",
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => { handleView(record) }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '时间',
      dataIndex: "inJobTime",
      align: "center",
      render: (text, record, index) => (
        <Space>
          { text ? dayjs(text).format(DATE_FMT) : "" }
        </Space>
      )
    },
    {
      title: '性别',
      dataIndex: ['user', 'gender'],
      align: "center",
      render: (text, record, index) => (
        <Space>
          { getGenderLabel(text) }
        </Space>
      )
    },
    {
      title: '籍贯',
      dataIndex: ['user', 'nativePlace'],
      align: "center"
    },
    {
      title: '人员类别',
      dataIndex: ['user', 'personnelCategory'],
      align: "center"
    },
    {
      title: '队部',
      dataIndex: "professionalLeaguesName",
      align: "center"
    },
    {
      title: '工种',
      dataIndex: "workerTypeName",
      align: "center"
    },
    {
      title: '职位',
      dataIndex: ['user', 'professional'],
      align: "center"
    },
    {
      title: '备注',
      dataIndex: ['remark'],
      align: "center"
    },
    {
      title: '操作',
      align: "center",
      render: (_, record) => (
        <Space size="middle" key={record._id}>
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<SearchOutlined/>}
            onClick={() => { handleView(record) }}>查看</Button>
          <Button shape="round" className={"delete-btn"} size={"middle"}
            icon={<DeleteOutlined/>} onClick={() => { handleBatchDelete(record) }}>删除</Button>
        </Space>
      ),
    },
  ];
  
  const handleView = (record: WorkerRecruitmentRow) => {
    props.handleView(record)
  }

  const handleBatchDelete = (record: WorkerRecruitmentRow) => {
    props.handleBatchDelete(record)
  }
    
  const handlePageChange = (page: number, pageSize: number) => {
    props.handlePageChange(page, pageSize)
  }
  
  const handleChange = (selectedRowKeys: React.Key[], selectedRows: WorkerRecruitmentRow[]) => {
    props.handleChange(selectedRowKeys, selectedRows)
  }

  return (
    <>
      <Card className={props.className}>
        <Table
          rowKey={'_id'}
          dataSource={props.dataSource}
          columns={columns}
          size={"middle"}
          loading={props.loading}
          pagination={{
            total: props.total,
            position: ['bottomCenter'],
            pageSize: props.perPage,
            current: props.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`, onChange: handlePageChange
          }}
          rowSelection={{selectedRowKeys: props.selectedRowKeys, onChange: handleChange}}/>
      </Card>
    </>
  )
})