import {FC, ReactNode, useEffect, useState} from "react";
import {Button, Image, message, Modal, Space, Upload, UploadFile, UploadProps} from "antd";
import {PrinterOutlined, UploadOutlined} from "@ant-design/icons";
import {BASE_API_PATH} from "@/config";
import {HttpRequestHeader, RcFile, UploadChangeParam} from "antd/es/upload/interface";
import {getLoginRspFromCache} from "@modules/Security/Stores/SecurityStore";
import './index.less'
import {WindowPrintImage} from "@modules/WindowPrint";

export interface PropsType extends UploadProps {
  uploadType: "image" | "video" | "application" | "all"
  updateSize: number;
  children?: ReactNode
  status?: string // 0表单上传 1不是表单上传 不传默认表单上传 表单上传需要拿到保存成功后的表单id去请求submittedSuccess接口，每天自动删除文件上传成功表单未上传的文件；
  isLocalFileList?: boolean
}

declare type BeforeUploadValueType = void | boolean | string | Blob | File;

export const normFile = (e: UploadChangeParam) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e.fileList;
}

export const previewFile = (url: string) => {
  const urls = url.split(';')
  for (let urlString of urls) {
    const suffix = urlString.substring(urlString.lastIndexOf(".") + 1)
    if (suffix.toLowerCase() === "pdf") {
      if (urlString.includes("https") || urlString.includes("http")) {
        window.open(urlString, "_blank");
      } else {
        window.open("https://" + urlString, "_blank");
      }
    } else {
      if (suffix.toLowerCase() === 'doc' || suffix.toLowerCase() === 'docx' || suffix.toLowerCase() === 'xls' || suffix.toLowerCase() === 'xlsx') {
        window.open('https://view.officeapps.live.com/op/view.aspx?src=' + urlString, "_blank");
      } else {
        const link = document.createElement('a');
        link.href = urlString; // 设置下载链接为图片的URL
        link.download = suffix; // 设置下载的文件名，‌可以根据需要自定义文件名和扩展名
        document.body.appendChild(link); // 将链接添加到DOM中以便触发下载
        link.click(); // 模拟点击链接以开始下载
        document.body.removeChild(link); // 下载完成后移除链接元素，‌这一步是可选的，‌但为了保持页面的整洁，‌建议移除添加的元素
      }
    }
  }
}

export function useHeaders() {
  const [headers, setHeaders] = useState<HttpRequestHeader>({})
  useEffect(() => {
    const initHeaders = async () => {
      const obj = {...headers}
      const loginRsp = await getLoginRspFromCache();
      if (loginRsp?.token) {
        obj.Authorization = `${loginRsp.token.jwtToken}`;
      }
      setHeaders(obj)
    }
    initHeaders().then()
  }, [])
  return headers;
}

export const CustomUpload: FC<PropsType> = (props: PropsType) => {
  const action = (): string => {
    if (props.multiple) {
      return BASE_API_PATH + "/v1/oss/uploads";
    }
    return BASE_API_PATH + "/v1/oss/upload";
  }

  const [loading, setLoading] = useState<boolean>(false)
  const [isUpload, setIsUpload] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false);
  const [urls, setUrls] = useState<string[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [current, setCurrent] = useState<number>(0)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const beforeUpload = (file: RcFile, _: RcFile[]): BeforeUploadValueType | Promise<BeforeUploadValueType> => {
    const isOk = props.uploadType.includes('all') || file.type.includes(props.uploadType)
    if (!isOk) {
      message.warning("文件上传的类型不对").then()
    }
    const isLtM = file.size / 1024 / 1024 < props.updateSize;
    if (!isLtM) {
      message.warning('文件的大小必须是 ' + props.updateSize + 'MB以内!').then();
    }
    setIsUpload(isOk && isLtM)
    return isOk && isLtM;
  }

  const uploadProps: UploadProps = {
    name: "file",
    action: action(),
    multiple: props.multiple,
    listType: props.listType,
    accept: props.accept,
    fileList: props.isLocalFileList ? fileList : props.fileList || [],
    maxCount: props.maxCount,
    headers: useHeaders(),
    className: props.className,
    beforeUpload: beforeUpload,
    data: {status: props.status || "0"},
    onPreview: (file) => {
      // if (props.uploadType === 'image') {
      //   const urls = props.fileList?.map(item => item.thumbUrl ? item.thumbUrl : "")
      //   setUrls(urls ? urls : [])
      //   setVisible(true)
      // } else if (props.uploadType === 'application') {
      //   let url = file.response ? file.response.url : file.url;
      //   previewFile(url)
      // }

      let url = file.response ? file.response.url : file.url;
      let fileList = url.split(';')
      let imageUrls: string[] = []
      let wordUrls: string[] = []
      fileList.forEach((fileUrl: string) => {
        // 获取文件后缀名
        let fileExtension = fileUrl.split('.').pop();
        if (!fileExtension) return;
        // 判断文件类型
        if (['bmp', 'jpg', 'jpeg', 'png', 'gif'].includes(fileExtension.toLowerCase())) {
          imageUrls.push(fileUrl)
        } else {
          wordUrls.push(fileUrl)
        }

        // 有图片的话 就展示
        if (imageUrls.length) {
          setUrls(imageUrls ? imageUrls : [])
          setVisible(true)
        }

        // 有文档的话 就调用方法
        if (wordUrls.length) {
          previewFile(wordUrls.join(';'))
        }
      })


    },
    onChange: (info) => {
      if (props.isLocalFileList) {
        setFileList(info.fileList)
      }
      if (info.file.status === 'uploading') {
        setLoading(true);
      }
      if (info.file.status === 'done') {
        setLoading(false)
        if (props.isLocalFileList && !info.fileList.find(item => item.status === 'uploading')) {
          setFileList([])
        }
        message.success(`${info.file.name} 文件上传成功.`).then();
      } else if (info.file.status === 'error') {
        setLoading(false)
        if (props.isLocalFileList && !info.fileList.find(item => item.status === 'uploading')) {
          setFileList([])
        }
        message.error(`${info.file.name} 文件上传失败.`).then();
      }
      if (props.onChange) {
        if (!isUpload) {
          info.fileList = info.fileList.filter(item => item.status === 'done');
        }
        props.onChange(info);
      }
    }
  }

  const handlePrint = () => {

    setIsModalOpen(true)
  }

  const toolbarRender = (node: ReactNode) => {
    return <Space size={10}>
      { node }
      <div className={'ant-image-preview-operations'}>
        <div className={'ant-image-preview-operations-operation'} onClick={handlePrint}>
          <PrinterOutlined/>
        </div>
      </div>
    </Space>
  }

  return (
    <>
      <Upload {...uploadProps}>
        {
          props.children || <Button icon={<UploadOutlined/>} loading={loading}>上传</Button>
        }
      </Upload>
      <div className={"hide"}>
        <Image.PreviewGroup
          preview={{
            visible,
            toolbarRender,
            onVisibleChange: vis => setVisible(vis),
            onChange: v => setCurrent(v)
          }}>
          {
            urls.map((item, index) => {
              return (
                <Image key={index} src={item}/>
              )
            })
          }
        </Image.PreviewGroup>
      </div>

      <WindowPrintImage
        title={'打印'}
        imgSrc={urls[current]}
        isModalOpen={isModalOpen}
        handleCancel={() => setIsModalOpen(false)}
      ></WindowPrintImage>
    </>
  )
}
