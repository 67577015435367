import "./index.less"
import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Drawer, FormInstance, message, Modal} from "antd";
import {DEFAULT_MODAL_WIDTH, WorkerRecruitmentStatus} from "@/config";
import {showErr} from "@common/utils";
import {UpdateWorkerRecruitmentUser, WorkerRecruitmentReq, WorkerRecruitmentRow} from "@services/dto/workerRecruitment";
import {allStores} from "@/stores";
import {WorkerRecruitmentService} from "@services/WorkerRecruitmentService";
import {HeaderAction} from "@modules/Induction/Components/HeaderAction";
import {ContentTable} from "@modules/Induction/Components/ContentTable";
import {Hire} from "@modules/Induction/Components/Hire";
import {Exit} from "@modules/Induction/Components/Exit";
import {useNavigate} from "react-router-dom";
import {AssignPosition} from "@modules/Induction/Components/AssignPosition";

export const Induction: FC = observer(() => {
  const {wrStore} = allStores;

  const {workerRecruitmentDataSource: datasource} = wrStore
  const [workerRecruitmentReq, setWorkerRecruitmentReq] = useState<WorkerRecruitmentReq>({
    pageNum: 1,
    perPage: 10,
    status: WorkerRecruitmentStatus.hired,
    source: "0,1,2"
  })
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const navigate = useNavigate()
  const [showAssignModal, setShowAssignModal] = useState(false);
  const assignInfo = React.createRef<FormInstance>();

  const handleQuit = (record: WorkerRecruitmentRow) => {
    wrStore.updateWR(record)
    setVisible(true)
  }

  const handleInduction = (record: WorkerRecruitmentRow) => {
    wrStore.updateWR(record);
    setShowAssignModal(true)
  }

  const handleBatchRecall = (record?: WorkerRecruitmentRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要驳回的数据！")
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record._id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否驳回？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        WorkerRecruitmentService.departureRejectedEntry(ids, WorkerRecruitmentStatus.recruiterConfirmed).then(rsp => {
          message.success("驳回成功！")
          setConfirmLoading(false)
          refreshData(workerRecruitmentReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  useEffect(() => {
    refreshData(workerRecruitmentReq)
  }, [])

  const refreshData = (recruitmentReq: WorkerRecruitmentReq) => {
    const req = {...recruitmentReq}
    setWorkerRecruitmentReq(req)
    wrStore.getWorkerRecruitments(req)
  }

  const handleActiveKeyChange = (value: string) => {
    let req = {...workerRecruitmentReq}
    req.pageNum = 1
    req.status = value
    refreshData(req);
  }

  const handleProjectIdChange = (value: string) => {
    let req = {...workerRecruitmentReq}
    req.pageNum = 1
    req.projectId = value
    refreshData(req);
  }

  const handlePressEnter = (value: string) => {
    let req = {...workerRecruitmentReq}
    req.pageNum = 1
    req.keyword = value
    refreshData(req);
  }

  const handlePageChange = (page: number, pageSize: number) => {
    const req = {...workerRecruitmentReq}
    req.pageNum = page
    req.perPage = pageSize
    refreshData(req)
    setWorkerRecruitmentReq(req)
  }
  
  const handleOnClose = () => {
    setVisible(false)
    refreshData(workerRecruitmentReq)
  }

  const handleEdit = (record: WorkerRecruitmentRow) => {
    navigate(`/onInductionDetail/${record._id}`)
  }

  const handleCancel = () => {
    setShowAssignModal(false)
  }

  const handleSaveAssign = () => {
    assignInfo.current?.validateFields().then(value => {
      const recruitmentUser: UpdateWorkerRecruitmentUser = {
        projectId: value.projectId,
        professional: value.professional,
        professionalLeaguesName: value.professionalLeaguesName,
        workerTypeName: value.workerTypeName,
        personnelCategory: value.personnelCategory
      }
      setConfirmLoading(true)
      WorkerRecruitmentService.updateUser(wrStore.wr.data?._id!, recruitmentUser).then(rsp => {
        message.success("分配成功！")
        setShowAssignModal(false)
        setVisible(true)
      }).catch(showErr).finally(() => setConfirmLoading(false))
    })
  }

  return (
    <>
      <HeaderAction
        disabled={wrStore.workerRecruitmentDataSource.loading || confirmLoading}
        handleBatchRecall={handleBatchRecall}
        handleActiveKeyChange={handleActiveKeyChange}
        handleProjectIdChange={handleProjectIdChange}
        handlePressEnter={handlePressEnter}/>
      
      <ContentTable 
        className={"margin-top-sm"}
        loading={datasource.loading || confirmLoading}
        total={datasource.data?.total!}
        selectedRowKeys={selectedRowKeys}
        perPage={workerRecruitmentReq.perPage}
        pageNum={workerRecruitmentReq.pageNum}
        dataSource={datasource.data?.items!}
        handleBatchRecall={handleBatchRecall}
        handleInduction={handleInduction}
        handlePageChange={handlePageChange}
        handleQuit={handleQuit}
        handleChange={setSelectedRowKeys}
        handleEdit={handleEdit}/>

      <Drawer
        title={<div className="text-align-center">待办事项</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={visible}
        onClose={handleOnClose}>
        <div className={`content ${workerRecruitmentReq.status !== WorkerRecruitmentStatus.pmConfirmed ? 'hide' : ''}`}><Hire/></div>
        <div className={`content ${workerRecruitmentReq.status !== WorkerRecruitmentStatus.hired ? 'hide' : ''}`}><Exit/></div>
      </Drawer>

      <Modal
        title={<div className="text-align-center">请先分配队部、工种跟职位</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={showAssignModal}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        onOk={handleSaveAssign}>
        <AssignPosition ref={assignInfo}/>
      </Modal>
    </>
  )
})