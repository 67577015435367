import {
  AttendanceReq, AttendanceRow,
  CreateWorkRecordReq,
  UpdateWorkRecordReq,
  WorkTimeReq,
  WorkTimeRow
} from "@services/dto/workTime";
import {apiV1} from "@services/apiHelper";
import {Page} from "@services/dto/common";

export class WorkTimeService {
  static async getWorkerTimes(req: WorkTimeReq): Promise<Page<WorkTimeRow>> {
    return await apiV1.get("/workerTimes", {params: req})
  }

  static async updateWorkerTimes(id: string, req: UpdateWorkRecordReq): Promise<WorkTimeRow> {
    return await apiV1.put(`/workerTimes/${id}`, req)
  }

  static async addWorkerTimes(req: CreateWorkRecordReq): Promise<WorkTimeRow> {
    return await apiV1.post("/workerTimes", req)
  }

  static async getWorkerTimeAttendances(req: AttendanceReq): Promise<Page<AttendanceRow>> {
    return await apiV1.get("/workerTimes/attendance", {params: req})
  }

  static async deleteBatchWorkerTime(ids: string[]): Promise<string> {
    return await apiV1.delete("/workerTimes/deleteBatch", {data: {ids}})
  }
}
