import {makeAutoObservable, runInAction} from "mobx";
import {Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {NewAssignmentNotice, ProcessReq, ProcessRow} from "@services/dto/process";
import {ProcessService} from "@services/ProcessService";

export class ProcessStore {
  processDatasource: StateFullData<Page<ProcessRow>> = {}
  processSelectDatasource: StateFullData<Page<ProcessRow>> = {}
  processRow?: ProcessRow
  processDetail: StateFullData<ResponseEntity<ProcessRow>> = {}
  processDetails: StateFullData<ResponsesEntity<ProcessRow>> = {}
  processReq: ProcessReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateProcessRow(row: ProcessRow) {
    this.processRow = {...row}
  }

  async newAssignmentNotice(row: NewAssignmentNotice) {
    return await ProcessService.addProcess({
      id: undefined,
      code: undefined,
      wnCode: undefined,
      wnDate: undefined,
      cooperationUnit: row.cooperationUnit,
      notificationScopeUserId: row.notificationScopeUserId,
      notificationScopeUserName: row.notificationScopeUserName,
      personResponsible: row.personResponsible,
      personResponsibleName: row.personResponsibleName,
      responsibilityProject: row.responsibilityProject,
      businessId: row.businessId,
      businessCode: row.businessCode,
      type: row.type,
      wnDesc: row.wnDesc,
      wnStatus: row.wnStatus
    })
  }

  getProcess(req: ProcessReq) {
    this.processDatasource = {...this.processDatasource, loading: true}
    this.processReq = {...this.processReq, ...req}
    ProcessService.getProcess(req).then(rsp => {
      runInAction(() => this.processDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.processDatasource = {...this.processDatasource, loading: false, err})
    })
  }

  getSelectProcess(req: ProcessReq) {
    this.processSelectDatasource = {...this.processSelectDatasource, loading: true}
    ProcessService.getProcess(req).then(rsp => {
      runInAction(() => this.processSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.processSelectDatasource = {...this.processSelectDatasource, loading: false, err})
    })
  }

  getDetailById(id: string) {
    this.processDetail = {...this.processDetail, loading: true}
    ProcessService.getOne(id).then(rsp => {
      runInAction(() => this.processDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.processDetail = {...this.processDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.processDetails = {...this.processDetails, loading: true}
    ProcessService.getBatch(ids).then(rsp => {
      runInAction(() => this.processDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.processDetails = {...this.processDetails, loading: false, err})
    })
  }
}

export const processStore = new ProcessStore();
