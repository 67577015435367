import React, {useEffect, useState} from "react";
import {DatePicker, Input, Select} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {SelectDict} from "@components/SelectDict";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectBankAccount} from "@components/SelectBankAccount";
import dayjs from "dayjs";
import {Any} from "@common/types";
import {FundApprovalReq} from "@services/dto/fundApproval";
import {CustomInputNumber} from "@components/CustomInputNumber";
import {SelectProject} from "@components/SelectProject";
import {SelectBankStatement} from "@components/SelectBankStatement";
import {CustomApplicationFormType} from "@components/CustomApplicationFormType";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {SelectFiling} from "@components/SelectFiling";
import {CustomAuthHeaderAction, PropsType as ParentPropsType} from "@components/CustomAuthHeaderAction";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {ClearOutlined, SearchOutlined} from "@ant-design/icons";
import {FundApprovalService} from "@services/FundApprovalService";

export interface PropsType extends ParentPropsType {
  setConfirmLoading?: (loading: boolean) => void
  updateCount?: number, // 更新渲染标志
  refreshData?: (req: FundApprovalReq) => void,
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [planCode, setPlanCode] = useState<string>()
  const [searchReq, setSearchReq] = useState<FundApprovalReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: FundApprovalReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handlePlanCodeClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.planCode = undefined
    setPlanCode(undefined)
    setSearchReq(req)
    refreshData(req)
  }

  const handlePlanCodeSearch = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.planCode = planCode
    setSearchReq(req)
    refreshData(req)
  }

  const handleFaDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.faDate1 = dateStrings[0]
    req.faDate2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleFaPayDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.faPayDate1 = dateStrings[0]
    req.faPayDate2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleFaTypeChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.faType = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleFaStateChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.faStatus = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonResponsibleChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleFaFileChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.faBatchNumber = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleBankStatementIdsChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.bankStatementIds = value
    setSearchReq(req)
    refreshData(req)
  }

  const handlePlanTypeChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.planType = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleFaPaymentAccountChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.faPaymentAccountId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleFaAccountsReceivableChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.faAccountsReceivableId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleApplicationFormTypeChange = ([value, tableValue]: [value: string, tableValue: string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.faApplicationFormType = value
    req.faApplicationForm = tableValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleFaAmountChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.faAmount1 = start
    req.faAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleOrganizersUserIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.organizersUserId = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    if (props.setConfirmLoading) {
      props.setConfirmLoading(true);
    }
    FundApprovalService.batchDateRefresh().then(() => {
      const req = {...searchReq}
      req.pageNum = 1
      req.perPage = 10
      req.keyword = undefined
      req.cooperationUnit = undefined
      req.responsibilityProject = undefined

      req.faStatus = undefined
      req.faType = undefined
      req.faDate1 = undefined
      req.faDate2 = undefined
      req.faAmount1 = undefined
      req.faAmount2 = undefined
      req.lnvoiceRegistrationId = undefined
      req.faFile = undefined
      req.faFileList = undefined
      req.faPaymentAccountId = undefined
      req.faAccountsReceivableId = undefined
      req.faApplicationFormType = undefined
      req.faApplicationForm = undefined
      req.contractSettlementId = undefined
      req.personResponsible = undefined
      req.personResponsibleName = undefined
      req.organizersUserId = undefined // 主办人员
      req.organizersUserName = undefined // 主办人员名
      req.bsAmountRealized1 = undefined
      req.bsAmountRealized2 = undefined
      req.faPayDate1 = undefined
      req.faPayDate2 = undefined
      req.bankStatementIds = undefined
      req.bankStatementCodes = undefined
      req.bsDate = undefined
      req.statusCommon = undefined
      req.sortField = undefined
      req.planCode = undefined
      req.planType = undefined
      req.planId = undefined

      setSearchReq(req)
      refreshData(req)
    }).finally(() => {
      if (props.setConfirmLoading) {
        props.setConfirmLoading(false);
      }
    })
  }

  return (
    <>
      <CustomAuthHeaderAction
        size={props.size}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <DatePicker.RangePicker
            allowClear
            value={searchReq?.faDate1 ? [dayjs(searchReq?.faDate1), dayjs(searchReq?.faDate2)] : [undefined, undefined]}
            placeholder={['制单开始日期', '制单结束日期']}
            onChange={handleFaDateChange}
            className={"width-100-percentage"}/>,
          <SelectDict
            updateCount={props.updateCount}
            code={'payment_typee'}
            onChange={handleFaTypeChange}
            value={searchReq?.faType}
            placeholder={'请选择款项类别'}
            allowClear
            className={"width-100-percentage"}/>,
          <CustomInputNumber
            value={[searchReq?.faAmount1, searchReq?.faAmount2]}
            placeholder={['申请金额最小', '申请金额最大']}
            className={'width-100-percentage'}
            onSearch={handleFaAmountChange}/>,
          <SelectBankAccount
            updateCount={props.updateCount}
            value={searchReq?.faPaymentAccountId}
            onChange={handleFaPaymentAccountChange}
            allowClear
            className={'width-100-percentage'}
            placeholder={'请输入付款账户'}/>,
          <SelectBankAccount
            updateCount={props.updateCount}
            value={searchReq?.faAccountsReceivableId}
            onChange={handleFaAccountsReceivableChange}
            allowClear
            className={'width-100-percentage'}
            placeholder={'请输入收款账户'}/>,
          <CustomApplicationFormType
            className={'width-100-percentage'}
            allowClear
            placeholder={'申请表单'}
            isOption
            defaultItems={[]}
            value={[searchReq?.faApplicationFormType, searchReq?.faApplicationForm]}
            onSearch={handleApplicationFormTypeChange}/>,
          <Select
            className={'width-100-percentage'}
            allowClear
            onChange={handlePlanTypeChange}
            value={searchReq?.planType}
            placeholder={'关联计划表单类型'}
            options={[
              {label:'薪酬计划', value:'薪酬计划'},
              {label:'合同审批', value:'合同审批'},
              {label:'费用申请', value:'费用申请'},
              {label:'税费计划', value:'税费计划'},
            ]}
          ></Select>,
          <Input
            onChange={(e) => setPlanCode(e.target.value)}
            value={planCode}
            onPressEnter={handlePlanCodeSearch}
            allowClear={{clearIcon: <ClearOutlined onClick={handlePlanCodeClear}/>}}
            placeholder="请搜索计划表单编号"
            suffix={<SearchOutlined onClick={handlePlanCodeSearch}/>}
          />,
          <SelectBankStatement
            className={'width-100-percentage'}
            allowClear
            value={searchReq?.bankStatementCodes}
            onChange={handleBankStatementIdsChange}
            placeholder={"请选择资金出纳"}/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            statisticsItemsReq={{
              table: 'fundingApproval',
              field: 'responsibilityProject',
            }}
            allowClear
            fieldNames={{value: 'id', label: 'name'}}/>,
          <SelectCooperativeUnit
            onChange={handleCooperationUnitChange}
            value={searchReq?.cooperationUnit}
            allowClear
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectFiling
            placeholder={"请选择存档批号"}
            allowClear
            isFaBatchNumberGroupBy
            fieldNames={{label: 'faBatchNumber', value: 'faBatchNumber'}}
            onChange={handleFaFileChange}
            value={searchReq?.faBatchNumber}
            className={'width-100-percentage'}
            isHiddenOptions/>,
          <DatePicker.RangePicker
            allowClear
            value={searchReq?.faPayDate1 ? [dayjs(searchReq?.faPayDate1), dayjs(searchReq?.faPayDate2)] : [undefined, undefined]}
            placeholder={['实现开始日期', '实现结束日期']}
            onChange={handleFaPayDateChange}
            className={"width-100-percentage"}/>,
          <SelectPersonCharge
            updateCount={props.updateCount}
            onChange={handlePersonResponsibleChange}
            className={"width-100-percentage"}
            value={searchReq?.personResponsible}
            placeholder={"请选择办理人员"}
            allowClear/>,
          <SelectPersonCharge
            onChange={handleOrganizersUserIdChange}
            className={"width-100-percentage"}
            placeholder={"请选择主办人员"}
            value={searchReq?.organizersUserId}
            allowClear/>,
          <SelectProcessStatus
            onChange={handleFaStateChange}
            allowClear
            value={searchReq?.faStatus}
            placeholder={'请选择办理进度'}
            className={"width-100-percentage"}/>,
        ] : []}/>
    </>
  )
}))
