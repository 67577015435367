import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {FileDistributionReq, FileDistributionRow} from "@services/dto/fileDistribution";

export class FileDistributionService {
  static async getFileDistribution(req: FileDistributionReq): Promise<Page<FileDistributionRow>> {
    return await apiV1.get("/fileDistribution/search", {params: req})
  }

  static async addFileDistribution(row: FileDistributionRow): Promise<ResponseEntity<FileDistributionRow>> {
    return await apiV1.post("/fileDistribution/save", {...row})
  }

  static async updateFileDistribution(row: FileDistributionRow): Promise<ResponseEntity<FileDistributionRow>> {
    return await apiV1.put("/fileDistribution/update", {...row})
  }

  static async deleteFileDistribution(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/fileDistribution/delete", {data: ids})
  }

  static async getOne(id: string): Promise<ResponseEntity<FileDistributionRow>> {
    return await apiV1.get(`/fileDistribution/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<FileDistributionRow>> {
    return await apiV1.get(`/fileDistribution/getBatch/${ids.join(',')}`)
  }
}
