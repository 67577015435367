import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {HeaderAction} from "@components/HeaderAction";
import {ColumnsType} from "antd/es/table";
import {BankAccountReq} from "@services/dto/bankAccount";
import {SelectDict} from "@components/SelectDict";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {CustomInputNumber} from "@components/CustomInputNumber";
import {DatePicker, Space} from "antd";
import dayjs from "dayjs";
import {SelectProject} from "@components/SelectProject";
import {SelectArchives} from "@components/SelectArchives";

export interface PropsType {
  updateCount?: number, // 更新渲染标志
  collapsed?: boolean, // 是否展开
  handleShowColumnReset?: () => void, // 重置列操作
  setCollapsed?: (v: boolean) => void, // 展开事件
  searchReq?: BankAccountReq, // 请求参数
  selectedRowKeys?: React.Key[], // 所选行
  handleDeleteBatch?: () => void,
  handleAdd?: () => void,
  handleTableColumnsChange?: (value: string[]) => void,
  showColumn?: string[],
  tableColumns?: ColumnsType,
  refreshData?: (req: BankAccountReq) => void,
  size?: number
  isHiddenOptions?: boolean
  locationPathname?: string
  items?: React.ReactNode[]
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<BankAccountReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: BankAccountReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleBaTypeChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.baType = value
    req.userId = undefined
    req.cooperationUnitId = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleBaApplicationFormChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    if (req.baType === '外部协作账户') {
      req.userId = undefined
      req.cooperationUnitId = value
    } else {
      req.userId = value
      req.cooperationUnitId = undefined
    }
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleAccumulatedIncomeChange = ([start, end]: string[]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.accumulatedIncome1 = start
    req.accumulatedIncome2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleAccumulatedOutlayChange = ([start, end]: string[]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.accumulatedOutlay1 = start
    req.accumulatedOutlay2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleBalanceChange = ([start, end]: string[]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.balance1 = start
    req.balance2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleEnableDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, [start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.enableDate1 = start
    req.enableDate2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleDisableDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, [start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.disableDate1 = start
    req.disableDate2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleProjectChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.responsibilityProject = undefined
    req.cooperationUnit = undefined

    req.userId = undefined
    req.cooperationUnitId = undefined
    req.baType = undefined
    req.accumulatedIncome1 = undefined
    req.accumulatedIncome2 = undefined
    req.accumulatedOutlay1 = undefined
    req.accumulatedOutlay2 = undefined
    req.balance1 = undefined
    req.balance2 = undefined
    req.enableDate1 = undefined
    req.enableDate2 = undefined
    req.disableDate1 = undefined
    req.disableDate2 = undefined

    setSearchReq(req)
    refreshData(req)
  }


  return (
    <>
      <HeaderAction
        size={props.size}
        isHiddenOptions={props.isHiddenOptions}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        selectedRowKeys={props.selectedRowKeys}
        handleDeleteBatch={props.handleDeleteBatch}
        handleAdd={props.handleAdd}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <SelectDict
            className={'width-100-percentage'}
            code={'amount_type'}
            value={props.searchReq?.baType}
            allowClear
            onChange={handleBaTypeChange}
            statisticsItemsReq={{
              table: 'bankAccount',
              field: 'baType',
            }}
            placeholder={'请选择账户类型'}/>,
          ['外部协作账户', '内部协作账户'].includes(props.searchReq?.baType) ? <SelectCooperativeUnit
            allowClear
            value={props.searchReq?.cooperationUnitId}
            onChange={handleBaApplicationFormChange}
            optionRender={(option) => (
              <Space>
                <span role="img" aria-label={option.data.cuName}>
                  { option.data.cuName }
                </span>
                ({ option.data.cooperationUnitCode })
              </Space>
            )}
            disabled={!['外部协作账户', '内部协作账户', '员工工资账户', '员工公务账户'].includes(props.searchReq?.baType)}
            fieldNames={{value: "id", label: "cuName"}}
            placeholder={'请选择关联表单'}
            className={'width-100-percentage'}/> : <SelectArchives
            allowClear
            value={props.searchReq?.userId}
            disabled={!['外部协作账户', '内部协作账户', '员工工资账户', '员工公务账户'].includes(props.searchReq?.baType)}
            onChange={handleBaApplicationFormChange}
            placeholder={'请选择关联表单'}
            className={'width-100-percentage'}/>,
          <CustomInputNumber
            placeholder={['转入金额', '转入金额']}
            value={[searchReq?.accumulatedIncome1, searchReq?.accumulatedIncome2]}
            className={'width-100-percentage'}
            onSearch={handleAccumulatedIncomeChange}/>,
          <CustomInputNumber
            placeholder={['转出金额', '转出金额']}
            value={[searchReq?.accumulatedOutlay1, searchReq?.accumulatedOutlay2]}
            className={'width-100-percentage'}
            onSearch={handleAccumulatedOutlayChange}/>,
          <CustomInputNumber
            placeholder={['账户余额', '账户余额']}
            value={[searchReq?.balance1, searchReq?.balance2]}
            className={'width-100-percentage'}
            onSearch={handleBalanceChange}/>,
          <DatePicker.RangePicker
            placeholder={['启用日期', '启用日期']}
            onChange={handleEnableDateChange}
            value={searchReq?.enableDate1 ? [dayjs(searchReq?.enableDate1), dayjs(searchReq?.enableDate2)] : [undefined, undefined]}
            allowClear
            className={"width-100-percentage"}/>,
          <DatePicker.RangePicker
            placeholder={['停用日期', '停用日期']}
            onChange={handleDisableDateChange}
            value={searchReq?.disableDate1 ? [dayjs(searchReq?.disableDate1), dayjs(searchReq?.disableDate2)] : [undefined, undefined]}
            allowClear
            className={"width-100-percentage"}/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleProjectChange}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
        ] : []}/>
    </>
  )
}))
