import React, { useEffect } from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import { FormInstance } from "antd/es";
import { observer } from "mobx-react";
import {ContractLedgerRow} from "@services/dto/contractLedger";
import dayjs from "dayjs";

export interface PropsType {
  row: ContractLedgerRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    const contractLedgerRow = { ...props.row }
    if (contractLedgerRow.id) {
      contractLedgerRow.time = [dayjs(contractLedgerRow.startTime), dayjs(contractLedgerRow.endTime)]
    }
    form.setFieldsValue(contractLedgerRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ status: 1, isVirtualUser: 0 }}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="合同名称"
            name="name"
            rules={[{ required: true, message: '请输入合同名称!' }]}>
            <Input placeholder="请输入合同名称" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同编号"
            name="code"
            rules={[{ required: true, message: '请输入合同编号!' }]}>
            <Input placeholder="请输入合同编号" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同当事人"
            name="user"
            rules={[{ required: true, message: '请输入合同当事人!' }]}>
            <Input placeholder="请输入合同当事人" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同金额"
            name="amount"
            rules={[{ required: true, message: '请输入合同金额!' }]}>
            <InputNumber placeholder="请输入合同金额" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同结算金额"
            name="settlementAmount"
            rules={[{ required: true, message: '请输入合同结算金额!' }]}>
            <InputNumber placeholder="请输入合同结算金额" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同实现金额"
            name="toRealizeTheAmount"
            rules={[{ required: true, message: '请输入合同实现金额!' }]}>
            <InputNumber placeholder="请输入合同实现金额" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同日期"
            name="time"
            rules={[{ required: true, message: '请输入合同日期!' }]}>
            <DatePicker.RangePicker picker={"date"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同经办人"
            name="agentUser"
            rules={[{ required: true, message: '请输入合同经办人!' }]}>
            <Input placeholder="请输入合同经办人" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
