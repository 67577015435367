import './index.less'
import {observer} from "mobx-react";
import {ColumnsType} from "antd/es/table";
import {Button, Card, Col, Drawer, FormInstance, Input, message, Modal, Radio, Row, Space, Table} from "antd";
import {DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {DEFAULT_MODAL_WIDTH} from "@/config";
import {PageParam, RuleRegulationRow} from "@services/dto/ruleRegulation";
import {BasicInfo} from "@modules/RuleRegulation/Components/BasicInfo";
import {allStores} from "@/stores";
import {getRuleRegulationStatusLabel, logger, showErr} from "@common/utils";
import {RuleRegulationService} from "@services/RuleRegulationService";
import {RadioChangeEvent} from "antd/es/radio/interface";

export const RuleRegulation = observer(() => {
  const {ruleRegulationStore, securityStore} = allStores;
  const basicInfo = React.createRef<FormInstance>();
  const [total, setTotal] = useState<number>(0)
  const [dataSource, setDataSource] = useState<RuleRegulationRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [ruleRegulationReq, setRuleRegulationReq] = useState<PageParam>({perPage: 10, pageNum: 1, companyId: securityStore.getLoggedUser()?.companyId, status: ''})
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ruleRegulationRow, setRuleRegulationRow] = useState<RuleRegulationRow>()
  const [isView, setIsView] = useState<boolean>(false);

  const options = [
    { label: '有效', value: '0' },
    { label: '失效', value: '1' },
  ];

  const columns: ColumnsType<RuleRegulationRow> = [
    {
      title: '文件名称',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => { handleView(record) }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '文件编号',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
    },
    {
      title: '版本',
      dataIndex: 'version',
      key: 'version',
      align: 'center'
    },
    {
      title: '正文',
      dataIndex: 'theGroup',
      key: 'theGroup',
      align: 'center'
    },
    {
      title: '办理进度',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          { getRuleRegulationStatusLabel(text) }
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record, index) => (
        <Space>
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined/>}
            onClick={() => { handleEdit(record) }}>修改</Button>
          <Button shape="round" className={"delete-btn"} size={"middle"}
            icon={<DeleteOutlined/>} onClick={() => { handleDeleteBatch(record) }}>删除</Button>
        </Space>
      )
    }
  ];

  useEffect(() => {
    refreshData(ruleRegulationReq)
  }, [])

  const refreshData = (req: PageParam) => {
    ruleRegulationStore.getRulesAndRegulations(req)
  }

  useEffect(() => {
    setDataSource(ruleRegulationStore.ruleRegulationDatasource.data?.items!)
    setTotal(ruleRegulationStore.ruleRegulationDatasource.data?.total!)
  }, [ruleRegulationStore.ruleRegulationDatasource])

  const handleView = (record: RuleRegulationRow) => {
    setRuleRegulationRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = (record: RuleRegulationRow) => {
    setRuleRegulationRow(record)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleAdd = () => {
    setRuleRegulationRow(undefined)
    setIsView(false)
    setIsModalVisible(true);
  };

  const handleDeleteBatch = (record?: RuleRegulationRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids:string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        RuleRegulationService.deleteRulesAndRegulations(ids).then(() => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(ruleRegulationReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handleOk = () => {
    const ruleRegulationParams = {...ruleRegulationRow!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(ruleRegulationParams, values)
      // ruleRegulationParams.releaseTime = values.releaseTime.format(DATE_FMT)
      setConfirmLoading(true)
      if (ruleRegulationParams.id) {
        RuleRegulationService.updateRulesAndRegulations(ruleRegulationParams).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false))
      } else {
        RuleRegulationService.addRulesAndRegulations(ruleRegulationParams).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false))
      }
    }).catch(logger.warn)
  };

  const actionThen = () => {
    message.success("操作成功！")
    setIsModalVisible(false);
    setRuleRegulationRow(undefined)
    refreshData(ruleRegulationReq)
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setRuleRegulationRow(undefined)
  };

  const handlePageChange = (page: number, pageSize: number) => {
    let suppliesRequest = {...ruleRegulationReq};
    suppliesRequest.perPage = pageSize
    suppliesRequest.pageNum = page
    setRuleRegulationReq(suppliesRequest)
    refreshData(suppliesRequest)
  }

  const onSearch = () => {
    const req = {...ruleRegulationReq};
    req.pageNum = 1
    setRuleRegulationReq(req)
    refreshData(req)
  }

  const handleRadioChange = (e: RadioChangeEvent) => {
    const req = {...ruleRegulationReq}
    req.pageNum = 1
    req.status = e.target.value
    setRuleRegulationReq(req)
    refreshData(req)
  }

  return (
    <div className={"institution"}>
      <Row gutter={12}>
        <Col span={6}>
          <Input
            value={ruleRegulationReq.name}
            onChange={(e) => setRuleRegulationReq({...ruleRegulationReq, name: e.target.value})}
            onPressEnter={onSearch}
            allowClear
            placeholder="请输入名称"
            suffix={<SearchOutlined onClick={onSearch}/>}
          />
        </Col>
        <Col span={6}>
          <Input
            value={ruleRegulationReq.code}
            onChange={(e) => setRuleRegulationReq({...ruleRegulationReq, code: e.target.value})}
            onPressEnter={onSearch}
            allowClear
            placeholder="请输入编号"
            suffix={<SearchOutlined onClick={onSearch}/>}
          />
        </Col>
        <Col span={6} className={"search-radio"}>
          <Radio.Group
            options={options}
            onChange={handleRadioChange}
            value={ruleRegulationReq.status} />
        </Col>
        <Col span={6} className={"text-align-right"}>
          <Button icon={<DeleteOutlined/>} size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={() => handleDeleteBatch(undefined)}>删除</Button>

          <Button icon={<PlusOutlined/>} type={"primary"} size={"middle"} shape={"round"}
            onClick={handleAdd}>新增</Button>
        </Col>
      </Row>

      <Card className={"margin-top-sm"}>
        <Table
          rowKey={"id"}
          dataSource={dataSource}
          columns={columns}
          size={"middle"}
          loading={ruleRegulationStore.ruleRegulationDatasource.loading || confirmLoading}
          pagination={{
            total: total,
            position: ['bottomCenter'],
            pageSize: ruleRegulationReq.perPage,
            current: ruleRegulationReq.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`, onChange: handlePageChange}}
          rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys}}/>
      </Card>

      <Drawer
        title={<div className="text-align-center">规章制度</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        onClose={handleCancel}>
        <BasicInfo row={{...ruleRegulationRow!}} ref={basicInfo} isView={isView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button type="primary" onClick={handleOk} loading={confirmLoading} className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>
    </div>
  )
})
