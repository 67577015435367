import "./BasicInfo.less"
import React, {useEffect, useState} from "react";
import {PunchClockRow, ClockInReq} from "@services/dto/punchClock";
import {FormInstance} from "antd/es";
import {Col, Form, Input, InputNumber, Row, Select, Button, Typography, message, Modal} from "antd";
import {ProjectRow} from "@services/dto/project";
import {ProjectService} from "@services/ProjectService";
import {showErr} from "@common/utils";
import {MinusCircleOutlined, PlusOutlined, ClockCircleOutlined} from "@ant-design/icons";
import {TimePicker} from "antd/lib";
import dayjs, {Dayjs} from "dayjs";
import {JointContent} from "antd/es/message/interface";
import {DATE_FMT} from "@/config";
import {MapContainer} from "@components/MapContainer";
import IconFont from "@components/IconFont";
import {ProvinceCityDistrictService} from "@services/ProvinceCityDistrictService";

export interface PropsType {
    row: PunchClockRow
}

export const BasicInfo = React.forwardRef<FormInstance, PropsType>((props, ref) => {
  const [projects, setProjects] = useState<ProjectRow[]>([])
  const [clocks, setClocks] = useState<ClockInReq[]>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [center, setCenter] = useState<number[]>()
  const [index, setIndex] = useState<number>(-1)

  const [form] = Form.useForm()
  useEffect(() => {
    form.resetFields()
    const punchClockRow = {...props.row}
    if (props.row.id) {
      punchClockRow.clockIns = props.row.clockIns.map((item) => ({
        ...item,
        goToWorkTime: dayjs(dayjs().format(DATE_FMT) + item.goToWorkTimeHour + ":" + item.goToWorkTimeMinutes),
        goOffWorkTime: dayjs(dayjs().format(DATE_FMT) + item.timeFromWorkHour + ":" + item.timeFromWorkMinutes),
      }))
      punchClockRow.geoLocationList = props.row.geoLocationList.map((item) => ({
        id: item.id,
        attendanceRulesId: item.attendanceRulesId,
        clockInScope: item.clockInScope,
        loc: item.loc,
        location: item.loc.x + "," + item.loc.y,
        addressName: item.addressName
      }))
    }
    form.setFieldsValue(punchClockRow)
  }, [props.row])

  useEffect(() => {
    ProjectService.getProject().then(rsp => {
      setProjects(rsp.data)
    }).catch(showErr)
  }, [])

  const goToWorkChange = (time: Dayjs | null, index: number) => {
    const clockIns = form.getFieldValue("clockIns");
    const preClock = clockIns[index - 1]
    const nowClock = clockIns[index]
    if (preClock && preClock.goOffWorkTime) {
      if (preClock.timeFromIsNextDay) {
        clockIns[index].goToWorkIsNextDay = preClock.timeFromIsNextDay;
        if (time && !time?.isAfter(preClock.goOffWorkTime)) {
          resetGoToWorkTime(clockIns, index, "后一个次日打卡上班时间要晚于前一个次日打卡下班时间");
          return
        }
      } else if (time && time?.isBefore(preClock.goOffWorkTime)) {
        clockIns[index].goToWorkIsNextDay = time?.isBefore(preClock.goOffWorkTime);
      }
      if (clockIns[index].goToWorkIsNextDay) {
        if (nowClock.goOffWorkTime && !nowClock.timeFromIsNextDay) {
          resetGoToWorkTime(clockIns, index, "时间段选择有误，请删除当前下班时间后重新选择！");
          return;
        }
      }
    }
    if (nowClock.goOffWorkTime) {
      if (nowClock.timeFromIsNextDay) {
        if (time && !time?.isBefore(nowClock.goOffWorkTime)) {
          resetGoToWorkTime(clockIns, index, "次日打卡上班时间要早于次日打卡下班时间");
          return;
        }
      } else if (time && !time?.isBefore(nowClock.goOffWorkTime)) {
        resetGoToWorkTime(clockIns, index, "打卡上班时间要早于打卡下班时间")
        return;
      }
    }
    if (!time) {
      clockIns[index].goToWorkIsNextDay = false
    }
    setClocks(clockIns);
  }

  const resetGoToWorkTime = (clockIns: any, index: number, content: JointContent) => {
    clockIns[index].goToWorkTime = undefined
    clockIns[index].goToWorkIsNextDay = false
    form.resetFields(["clockIns"])
    form.setFieldValue("clockIns", clockIns)
    message.warning(content)
    setClocks(clockIns);
  }

  const goOffWorkChange = (time: Dayjs | null, index: number) => {
    const clockIns = form.getFieldValue("clockIns")
    const nowClock = clockIns[index]
    const afterClock = clockIns[index + 1]
    if (nowClock.goToWorkTime) {
      if (nowClock.goToWorkIsNextDay) {
        clockIns[index].timeFromIsNextDay = nowClock.goToWorkIsNextDay
        if (time?.isBefore(nowClock.goToWorkTime)) {
          resetGoOffWorkTime(clockIns, index, "次日打卡下班时间要晚于次日打卡上班时间");
          return
        }
      } else {
        clockIns[index].timeFromIsNextDay = time?.isBefore(clockIns[index].goToWorkTime);
      }
    }
    if (afterClock && afterClock.goToWorkTime) {
      if (time && !time?.isBefore(afterClock.goToWorkTime)) {
        resetGoOffWorkTime(clockIns, index, "打卡下班时间要早于下一次打卡上班时间")
        return
      }
      if (clockIns[index].timeFromIsNextDay) {
        if (afterClock.goToWorkTime && !afterClock.goToWorkIsNextDay) {
          resetGoToWorkTime(clockIns, index, "时间段选择有误，请删除下一次打卡上班时间后重新选择！");
          return;
        }
      }
    }
    if (!time) {
      clockIns[index].timeFromIsNextDay = false
    }
    form.setFieldValue("clockIns", clockIns)
    setClocks(clockIns);
  }

  const resetGoOffWorkTime = (clockIns: any, index: number, content: JointContent) => {
    clockIns[index].goOffWorkTime = undefined
    clockIns[index].timeFromIsNextDay = false
    form.resetFields(["clockIns"])
    form.setFieldValue("clockIns", clockIns)
    message.warning(content)
    setClocks(clockIns);
  }
  
  const onAddressBlur = async (index: number) => {
    const geoLocationList = form.getFieldValue("geoLocationList")
    if (geoLocationList[index] && geoLocationList[index].addressName) {
      ProvinceCityDistrictService.getGeo(geoLocationList[index].addressName).then(rsp => {
        geoLocationList[index].location = rsp.data.geocodes[0].location
        form.setFieldValue("geoLocationList", geoLocationList)
      }).catch(() => {
        message.warning("上班地点的地址格式有误，请根据提示输入正确的地址格式！")
      })
    } else {
      if (geoLocationList[index]) {
        geoLocationList[index].location = "";
      }
      form.resetFields(["geoLocationList"])
      form.setFieldValue("geoLocationList", geoLocationList)
    }
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }
  
  const handleOk = () => {
    setConfirmLoading(true)
    const geoLocationList = form.getFieldValue("geoLocationList")
    if (center && index > -1) {
      geoLocationList[index] = {
        ...geoLocationList[index],
        location: center.join(",")
      }
      form.resetFields(["geoLocationList"])
      form.setFieldValue("geoLocationList", geoLocationList)
    }
    setIsModalOpen(false)
    setConfirmLoading(false)
  }

  const handleClickAddress = (index: number) => {
    const geoLocationList = form.getFieldValue("geoLocationList")
    if (geoLocationList[index] && geoLocationList[index].location) {
      setCenter(geoLocationList[index].location.split(","))
    } else {
      setCenter(undefined)
    }
    setIndex(index)
    setIsModalOpen(true);
  }
  
  const handleCenter = (lng: number, lat: number) => {
    setCenter([lng, lat])
  }

  return <>
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 7}}
      wrapperCol={{span: 15}}
      autoComplete="off">
      <Row>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="项目"
            name="companyProjectId"
            rules={[{required: true, message: '请选择项目',}]}>
            <Select
              placeholder="请选择项目"
              showSearch
              filterOption={(inputValue, option) => String(option?.name).includes(inputValue)}
              fieldNames={{value: "id", label: "name"}}
              options={projects}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.List name="clockIns" rules={[
            {
              validator: async (_, clockIns) => {
                if (!clockIns || clockIns.length < 1) {
                  return Promise.reject(new Error('至少需要一个打卡记录'));
                } else if (clockIns.length > 10) {
                  return Promise.reject(new Error('至多拥有十个打卡记录'));
                }
              }
            }
          ]}>
            { (fields, {add, remove}, { errors }) => (
              <>
                { fields.map((field, index) => (
                  <Row key={field.key}>
                    <Col span={16} offset={4}>
                      <Typography.Title level={5}
                        className={"clock-title"}>第 { index + 1 } 次打卡</Typography.Title>
                    </Col>
                    {
                      index > 0 && <Col span={4} className={"text-align-center"}>
                        <MinusCircleOutlined onClick={() => {
                          remove(field.name);
                          setClocks(form.getFieldValue("clockIns"))
                        }}/>
                      </Col>
                    }
                    <Col span={24}>
                      <Form.Item hidden name="goToWorkIsNextDay">
                        <Input/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item hidden name="timeFromIsNextDay">
                        <Input/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...field}
                        label="上班时间"
                        name={[field.name, 'goToWorkTime']}
                        rules={[{required: true, message: '请选择上班时间!'}]}>
                        <TimePicker
                          format={"HH:mm"}
                          placeholder={"请选择上班时间"}
                          className={"width-100-percentage"}
                          showNow={false}
                          suffixIcon={(clocks[index] && clocks[index].goToWorkIsNextDay) ?
                            <div>次日</div> : <ClockCircleOutlined />}
                          onChange={(time) => goToWorkChange(time, index)}/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...field}
                        label="上班前打卡有效时长"
                        name={[field.name, 'clockOutInAdvance']}
                        rules={[{required: true, message: '请输入上班打卡有效时长!'}]}>
                        <InputNumber placeholder="请输入上班打卡有效时长" precision={0} addonAfter={"分"}
                          className={"width-100-percentage"}/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...field}
                        label="下班时间"
                        name={[field.name, 'goOffWorkTime']}
                        rules={[{required: true, message: '请选择下班时间!'}]}>
                        <TimePicker
                          format={"HH:mm"}
                          placeholder={"请选择下班时间"}
                          className={"width-100-percentage"}
                          showNow={false}
                          suffixIcon={(clocks[index] && clocks[index].timeFromIsNextDay) ?
                            <div>次日</div> : <ClockCircleOutlined />}
                          onChange={(time) => goOffWorkChange(time, index)}/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...field}
                        label="下班后打卡有效时长"
                        name={[field.name, 'earlyfromwork']}
                        rules={[{required: true, message: '请输入下班打卡有效时长!'}]}>
                        <InputNumber placeholder="请输入下班打卡有效时长" precision={0} addonAfter={"分"}
                          className={"width-100-percentage"}/>
                      </Form.Item>
                    </Col>
                  </Row>
                )) }
                <Col span={24}>
                  <Form.Item wrapperCol={{span: 20, offset: 3}} className={"text-align-center"}>
                    <Button 
                      type="dashed" 
                      block 
                      icon={<PlusOutlined/>} 
                      onClick={() => {
                        add()
                      }}>
                      打卡次数
                    </Button>
                    <Form.ErrorList errors={errors}/>
                  </Form.Item>
                </Col>
              </>
            ) }
          </Form.List>
        </Col>

        <Col span={24}>
          <Form.List name="geoLocationList" rules={[
            {
              validator: async (_, geoLocationList) => {
                if (!geoLocationList || geoLocationList.length < 1) {
                  return Promise.reject(new Error('至少需要一个打卡地点'));
                } else if (geoLocationList.length > 10) {
                  return Promise.reject(new Error('至多拥有十个打卡地点'));
                }
              }
            }
          ]}>
            { (fields, {add, remove}, { errors }) => (
              <>
                { fields.map((field, index) => (
                  <Row key={field.key}>
                    <Col span={16} offset={4}>
                      <Typography.Title level={5}
                        className={"clock-title"}>第 { index + 1 } 个打卡地点</Typography.Title>
                    </Col>
                    {
                      index > 0 && <Col span={4} className={"text-align-center"}>
                        <MinusCircleOutlined onClick={() => {
                          remove(field.name);
                        }}/>
                      </Col>
                    }
                    <Col span={24}>
                      <Form.Item hidden name="id">
                        <Input/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...field}
                        label="上班地点"
                        name={[field.name, 'addressName']}
                        extra={"请输入完整的地址：省+市+区+详细地址"}
                        rules={[{required: true, message: '请输入上班地点!'}]}>
                        <Input placeholder="请输入上班地点" onBlur={() => onAddressBlur(index)}/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        {...field}
                        label="打卡范围"
                        name={[field.name, 'clockInScope']}
                        rules={[{required: true, message: '请输入打卡范围!'}]}>
                        <InputNumber placeholder="请输入打卡范围" precision={0} addonAfter={"米"}
                          className={"width-100-percentage"}/>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Row gutter={10}>
                        <Col span={21}>
                          <Form.Item
                            {...field}
                            labelCol={{span: 8}}
                            wrapperCol={{span: 16}}
                            label="地点坐标"
                            name={[field.name, 'location']}
                            rules={[{required: true, message: '请选择地点坐标!'}]}>
                            <Input disabled placeholder={"请选择地点坐标"}/>
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Button type="primary" shape="circle" icon={<IconFont type={"hr-daohangdizhi"} />} onClick={() => handleClickAddress(index)}/>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )) }
                <Col span={24}>
                  <Form.Item wrapperCol={{span: 20, offset: 3}} className={"text-align-center"}>
                    <Button
                      type="dashed"
                      block
                      icon={<PlusOutlined/>}
                      onClick={() => {
                        add()
                      }}>
                        打卡地点
                    </Button>
                    <Form.ErrorList errors={errors}/>
                  </Form.Item>
                </Col>
              </>
            ) }
          </Form.List>
        </Col>
      </Row>
    </Form>

    <Modal
      title={<div className="text-align-center">地图选点</div>}
      width={900}
      open={isModalOpen}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      onOk={handleOk}>
      <MapContainer centerChange={handleCenter} center={center}/>
    </Modal>
  </>
})