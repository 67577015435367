import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {CostReimbursementReq, CostReimbursementRow} from "@services/dto/costReimbursement";

export class CostReimbursementService {
  static async getCostReimbursement(req: CostReimbursementReq): Promise<Page<CostReimbursementRow>> {
    return await apiV1.get("/employeeReimbursement/search", {params: req})
  }

  static async addCostReimbursement(row: CostReimbursementRow): Promise<ResponseEntity<CostReimbursementRow>> {
    return await apiV1.post("/employeeReimbursement/save", {...row})
  }

  static async updateCostReimbursement(row: CostReimbursementRow): Promise<ResponseEntity<CostReimbursementRow>> {
    return await apiV1.put("/employeeReimbursement/update", {...row})
  }

  static async deleteCostReimbursement(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/employeeReimbursement/delete", {data: ids})
  }

  static async getDetailById(id: string): Promise<ResponseEntity<CostReimbursementRow>> {
    return await apiV1.get(`/employeeReimbursement/getOne/${id}`)
  }

  static async getOne(id: string): Promise<ResponseEntity<CostReimbursementRow>> {
    return await apiV1.get(`/employeeReimbursement/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<CostReimbursementRow>> {
    return await apiV1.get(`/employeeReimbursement/getBatch/${ids.join(',')}`)
  }

  static async batchUpdateCostReimbursement(selectedRows: CostReimbursementRow[]): Promise<ResponseEntity<string>> {
    return await apiV1.put("/employeeReimbursement/batchUpdate", [...selectedRows])
  }
}
