import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/es";
import {Col, Form, Row} from "antd";
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {SelectDict} from "@components/SelectDict";
import {SelectProject} from "@components/SelectProject";
import {ProjectRow} from "@services/dto/project";
import {SelectWorkerType} from "@components/SelectWorkerType";

export const AssignPosition = observer(React.forwardRef<FormInstance>((props, ref) => {
  const {wrStore} = allStores;

  const [form] = Form.useForm()

  const [projectRow, setProjectRow] = useState<ProjectRow>()

  useEffect(() => {
    form.resetFields()
    const wrData = wrStore.wr.data
    if (wrData) {
      form.setFieldValue("projectId", wrData.projectId);
      form.setFieldValue("professionalLeaguesName", wrData.professionalLeaguesName || undefined);
      form.setFieldValue("workerTypeName", wrData.workerTypeName || undefined);
      form.setFieldValue("professional", wrData.professional || undefined);
      form.setFieldValue("personnelCategory", wrData.personnelCategory || undefined);
    }
  }, [wrStore.wr.data])

  const handleProjectChange = (value: string, options: ProjectRow[]) => {
    const row = options.find(item => item.id === value)
    setProjectRow(row)
    form.resetFields(['professionalLeaguesName'])
  }

  return <>
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 4}}
      wrapperCol={{span: 20}}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Form.Item
            label="项目"
            name="projectId"
            rules={[{required: true, message: '请选择项目',}]}>
            <SelectProject onChange={handleProjectChange}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="专业队"
            name="professionalLeaguesName"
            rules={[{required: true, message: '请选择专业队',}]}>
            { projectRow.name }
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="工种"
            name="workerTypeName"
            rules={[{required: true, message: '请选择工种'}]}>
            <SelectWorkerType
              placeholder={"请选择工种"}
              className={'width-100-percentage'}
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="职位"
            name="professional"
            rules={[{required: true, message: '请选择职位!'}]}>
            <SelectDict code={"dict_professional"} placeholder={"请选择职位"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="人员类别"
            name="personnelCategory"
            rules={[{required: true, message: '请选择人员类别!'}]}>
            <SelectDict code={"dict_personnel_category"} placeholder={"请选择人员类别"}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  </>
}))
