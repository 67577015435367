import {makeAutoObservable, runInAction} from "mobx";
import {Page, ResponseEntity, StateFullData} from "@services/dto/common";
import {MonthSalaryRow, MonthSalaryReq, MonthSalarySumRow} from "@services/dto/monthSalary";
import {MonthSalaryService} from "@services/MonthSalaryService";

class MonthSalaryStore {
  monthSalaryData: StateFullData<ResponseEntity<Page<MonthSalaryRow>>> = {}

  monthSalarySumRowData: StateFullData<ResponseEntity<Page<MonthSalarySumRow>>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  updateMonthSalaryData(datasource: MonthSalaryRow[]) {
    const datasources = {...this.monthSalaryData.data!}
    datasources.data.items = datasource
    this.monthSalaryData = {...this.monthSalaryData, data: datasources}
  }

  getMonthPersonSalary(req: MonthSalaryReq) {
    this.monthSalaryData = {...this.monthSalaryData, loading: true}
    MonthSalaryService.getMonthPersonSalary(req).then(rsp => {
      runInAction(() => this.monthSalaryData = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.monthSalaryData = {...this.monthSalaryData, loading: false, err})
    })
  }

  getMonthStatisticsSalary(req: MonthSalaryReq) {
    this.monthSalarySumRowData = {...this.monthSalarySumRowData, loading: true}
    MonthSalaryService.getMonthStatisticsSalary(req).then(rsp => {
      runInAction(() => this.monthSalarySumRowData = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.monthSalarySumRowData = {...this.monthSalarySumRowData, loading: false, err})
    })
  }
}

export const monthSalaryStore = new MonthSalaryStore()