import React, {FC, useState} from "react";
import {observer} from "mobx-react";
import {Button, Col, Input, Row} from "antd";
import {DeleteOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import {SelectProject} from "@components/SelectProject";

export interface PropsType {
  handleProjectIdChange: (projectId: string) => void
  handleDeleteBatch: () => void
  handleAdd: () => void
  handleSearch: (searchValue: string) => void
}

export const HeaderAction:FC<PropsType> = observer((props: PropsType) => {
  const [searchValue, setSearchValue] = useState<string>("")

  const handleProjectIdChange = (value: string) => {
    props.handleProjectIdChange(value)
  }
  
  const handleDeleteBatch = () => {
    props.handleDeleteBatch()
  }
  
  const handleAdd = () => {
    props.handleAdd()
  }

  const handleSearch = () => {
    props.handleSearch(searchValue)
  }

  return (
    <>
      <Row gutter={16}>
        <Col xl={5} md={6} xs={12}>
          <SelectProject allowClear onChange={handleProjectIdChange}/>
        </Col>
        <Col xl={7} md={9} xs={12}>
          <Input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={handleSearch}
            allowClear
            placeholder="请输入姓名"
            suffix={<SearchOutlined onClick={handleSearch}/>}
          />
        </Col>
        <Col xl={12} md={9} xs={24} className={"text-align-right"}>
          <Button icon={<DeleteOutlined/>} size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={() => handleDeleteBatch()}>删除</Button>

          <Button icon={<PlusOutlined/>} type={"primary"} size={"middle"} shape={"round"}
            onClick={handleAdd}>新增</Button>
        </Col>
      </Row>
    </>
  )
})