import React, {useEffect, useState} from "react";
import {
  Button,
  Col,
  Divider,
  Dropdown,
  FormInstance,
  MenuProps,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Space
} from "antd";
import {Any} from "@common/types";
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {BankAccountReq, BankAccountRow} from "@services/dto/bankAccount";
import {CloseOutlined, EditOutlined, FolderViewOutlined, MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {BLACK_LIST, DATE_FMT, DEFAULT_MODAL_WIDTH} from "@/config";
import {BasicInfo} from "@modules/BankAccount/Components/BasicInfo";
import {BankAccountService} from "@services/BankAccountService";
import {logger, showErr} from "@common/utils";
import {DictItem, StatisticsItemsReq, StatisticsItemsRow} from "@services/dto/dict";
import {DictService} from "@services/DictService";

export interface PropsType {
  onChange?: (value: Any | null, options: BankAccountRow[]) => void
  value?: Any | null
  mode?: 'multiple' | 'tags'
  labelInValue?: boolean
  disabled?: boolean
  className?: string
  allowClear?: boolean
  placeholder?: string
  fieldNames?: { value: string, label: string }
  isShowAction?: boolean
  isSimpleAction?: boolean
  statisticsItemsReq?: StatisticsItemsReq
  handleButtonClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  updateCount?: number
  searchReq?: BankAccountReq
  isEnableFocusEvent?: boolean // 是否启用聚焦事件： 聚焦时请求最新数据
  bankAccountTypeOptions?: DictItem[]
  style?: React.CSSProperties
}

export const SelectBankAccount = observer((props: PropsType) => {
  const [value, setValue] = useState<Any | null>()
  const {bankAccountStore} = allStores
  const [datasource, setDatasource] = useState<BankAccountRow[]>([])
  const [total, setTotal] = useState(0)
  const [items, setItems] = useState<BankAccountRow[]>([])
  const [statisticsItems, setStatisticsItems] = useState<StatisticsItemsRow[] | BankAccountRow[]>([])
  const basicInfo = React.createRef<FormInstance>();
  const [isView, setIsView] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bankAccountRow, setBankAccountRow] = useState<BankAccountRow>()
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [searchReq, setSearchReq] = useState<BankAccountReq>({
    pageNum: 1,
    perPage: 10
  })

  /**
   *  每个组件默认写法 只需修改 'baNick'
   */
  const defaultItems: any[] = [
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: 'N/A',
      [props.fieldNames ? props.fieldNames.label : 'baNick']: 'N/A'
    },
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: '空白',
      [props.fieldNames ? props.fieldNames.label : 'baNick']: '空白'
    },
  ]

  /**
   * 初始化
   */
  useEffect(() => {
    if (props.statisticsItemsReq) {
      getStatisticsItems()
    } else {
      refreshData(searchReq)
    }
  }, [])

  /**
   * 更新统计
   */
  useEffect(() => {
    if (props.updateCount && props.statisticsItemsReq && props.value) {
      getStatisticsItems()
    }
  }, [props.updateCount]);

  /**
   * 办理进度数据变化
   */
  useEffect(() => {
    if (bankAccountStore.bankAccountSelectDatasource.data) {
      setDatasource(bankAccountStore.bankAccountSelectDatasource.data.items || [])
      setTotal(bankAccountStore.bankAccountSelectDatasource.data.total || 0)
      if (bankAccountStore.bankAccountDetails.data?.data.length) {
        setItems(bankAccountStore.bankAccountDetails.data?.data
          .filter(item => !(bankAccountStore.bankAccountSelectDatasource.data.items || [])
            .some(o => o.id === item.id)))
      }
    }
  }, [bankAccountStore.bankAccountDetails, bankAccountStore.bankAccountSelectDatasource.data])

  /**
   * 值发生改变
   */
  useEffect(() => {
    setValue(props.value)
    if (typeof props.value === 'string' && !BLACK_LIST.includes(props.value)) {
      bankAccountStore.getBatch([props.value])
    } else if (typeof props.value === 'object') {
      const ids = props.value.filter((v: string) => !BLACK_LIST.includes(v))
      if (ids.length) {
        bankAccountStore.getBatch(ids)
      }
    }
  }, [props.value])

  const getStatisticsItems = () => {
    setLoading(true)
    DictService.getStatisticsItems(props.statisticsItemsReq).then(rsp => {
      const row = JSON.parse(JSON.stringify(rsp.data || [])).map((item: StatisticsItemsRow | BankAccountRow) => {
        (item as BankAccountRow).baNick = `${(item as BankAccountRow).baNick || (item as StatisticsItemsRow).value}(${(item as StatisticsItemsRow).coun})`
        return item
      })
      setStatisticsItems(row);
    }).catch(showErr).finally(() => {
      setLoading(false)
    })
  }

  const refreshData = (req: BankAccountReq) => {
    if (props.searchReq) {
      bankAccountStore.getSelectBankAccount({
        ...props.searchReq,
        ...req,
      })
    } else {
      bankAccountStore.getSelectBankAccount(req)
    }
  }

  const onChange = (value: Any | null) => {
    setValue(value)
    if (value) {
      const record = datasource.find(item => item.id === value)
      setBankAccountRow(record)
    } else {
      setBankAccountRow(undefined)
    }
    if (props.onChange) {
      props.onChange(value, [
        ...datasource,
        ...items,
      ]);
    }
  }

  const handleSearch = (inputValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = inputValue
    setSearchReq(req)
    refreshData(req)
    return true
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    const req = {...searchReq}
    req.pageNum = page
    req.perPage = pageSize
    setSearchReq(req)
    refreshData(req)
  }

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.handleButtonClick) {
      props.handleButtonClick(e)
    }
  };

  const menuProps: MenuProps = {
    items: [
      {
        label: '',
        key: 'edit',
        icon: <EditOutlined/>,
        style: {display: (!value || props.disabled) ? 'none' : '', color: '#ffec3d'},
        onClick: () => handleEdit()
      },
      {
        label: '',
        key: 'plus',
        icon: <PlusOutlined/>,
        style: {color: '#73d13d'},
        onClick: () => handlePlus()
      },
      {
        label: '',
        key: 'close',
        style: {display: (!value || props.disabled) ? 'none' : '', color: '#ff4d4f'},
        icon: <CloseOutlined/>,
        onClick: () => handleClose()
      },
      {
        label: '',
        key: 'view',
        style: {display: !value ? 'none' : '', color: '#4096ff'},
        icon: <FolderViewOutlined/>,
        onClick: () => handleView()
      },
    ]
  };

  const handleEdit = () => {
    const record = datasource.find(item => item.id === value)
    setBankAccountRow(record)
    setIsModalOpen(true);
    setIsView(false)
  }

  const handlePlus = () => {
    setBankAccountRow(undefined)
    setIsModalOpen(true);
    setIsView(false)
  }

  const handleClose = () => {
    const record = datasource.find(item => item.id === value)
    if (!record) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        BankAccountService.deleteBankAccount([record.id]).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(searchReq)
          onChange(null)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handleView = () => {
    const record = datasource.find(item => item.id === value)
    setBankAccountRow(record)
    setIsModalOpen(true);
    setIsView(true)
  }

  const handleOk = () => {
    const row = {...bankAccountRow!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(row, values)
      if (values.responsibilityProject) {
        row.responsibilityProject = values.responsibilityProject.join(",");
      } else {
        row.responsibilityProject = ''
      }
      if (values.enableDisableDate && values.enableDisableDate.length > 1) {
        row.enableDate = values.enableDisableDate[0].format(DATE_FMT)
        row.disableDate = values.enableDisableDate[1].format(DATE_FMT)
      } else {
        row.enableDate = ''
        row.disableDate = ''
      }
      setConfirmLoading(true)
      if (row.id) {
        BankAccountService.updateBankAccount(row).then(() => {
          actionThen();
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        BankAccountService.addBankAccount(row).then(() => {
          actionThen();
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  }

  const actionThen = () => {
    message.success("操作成功！").then()
    setIsModalOpen(false);
    setBankAccountRow(undefined)
    refreshData(searchReq)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const selectNode = props.statisticsItemsReq ? (
    <Select
      allowClear={props.allowClear}
      placeholder={props.placeholder || "请选择账户简称"}
      className={`${props.className} width-100-percentage`}
      value={value}
      onChange={onChange}
      showSearch
      optionFilterProp="children"
      filterOption={(input: string, option) =>
        String(option?.baNick).toLowerCase().includes(input.toLowerCase())}
      disabled={props.disabled}
      mode={props.mode}
      loading={loading}
      labelInValue={props.labelInValue}
      fieldNames={props.fieldNames || {value: "id", label: "baNick"}}
      options={statisticsItems as BankAccountRow[]}/>
  ) : (
    <Select
      allowClear={props.allowClear}
      placeholder={props.placeholder || "请选择账户简称"}
      className={`${props.className} width-100-percentage`}
      dropdownRender={(menu) => (
        <>
          { menu }
          <Divider className={"divider-margin"}/>
          <Space>
            <Pagination
              total={total}
              pageSize={searchReq.perPage}
              size={"small"}
              onChange={handlePaginationChange}/>
          </Space>
        </>
      )}
      value={value}
      onChange={onChange}
      showSearch
      onFocus={() => props.isEnableFocusEvent && refreshData(searchReq)}
      onClear={() => handleSearch('')}
      disabled={props.disabled}
      mode={props.mode}
      filterOption={false}
      onSearch={handleSearch}
      loading={bankAccountStore.bankAccountStatisticsDatasource.loading}
      labelInValue={props.labelInValue}
      fieldNames={props.fieldNames || {value: "id", label: "baNick"}}
      options={[
        ...defaultItems,
        ...datasource,
        ...items,
      ]}/>
  )

  return (
    <>
      <Row style={props.style}>
        <Col span={props.isShowAction ? 18 : 24}>
          { selectNode }
        </Col>
        <Col span={props.isShowAction ? 6 : 0}>
          <div
            className={`d-flex df-aic df-jcsa height-100-percentage ${props.isSimpleAction ? 'margin-left-xs' : ''}`}>
            {
              props.isSimpleAction ?
                <>
                  <Dropdown.Button size={"small"} menu={menuProps} onClick={handleButtonClick}>
                    <MinusCircleOutlined/>
                  </Dropdown.Button>
                </>
                :
                <>
                  <Button size={'small'} type={'link'} className={'btn-edit'} disabled={!value || props.disabled}
                    onClick={handleEdit}><EditOutlined/></Button>
                  <Button size={'small'} type={'link'} className={'btn-plus'}
                    onClick={handlePlus}><PlusOutlined/></Button>
                  <Button size={'small'} type={'link'} className={'btn-close'} disabled={!value || props.disabled}
                    onClick={handleClose}><CloseOutlined/></Button>
                  <Button size={'small'} type={'link'} className={'btn-view'} disabled={!value}
                    onClick={handleView}><FolderViewOutlined/></Button>
                </>
            }
          </div>
        </Col>
      </Row>

      <Modal
        title={<div className="text-align-center">资金账户</div>}
        open={isModalOpen}
        width={DEFAULT_MODAL_WIDTH}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={<>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button onClick={handleOk} type={'primary'}>确定</Button>
          }
        </>}>
        <BasicInfo bankAccountTypeOptions={props.bankAccountTypeOptions} row={{...bankAccountRow!}} ref={basicInfo} isView={isView}/>
      </Modal>
    </>
  )
})
