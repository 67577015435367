import React, {useEffect, useState} from "react";
import {Button, Col, DatePicker, Divider, Form, Input, InputNumber, message, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {BankAccountRow} from "@services/dto/bankAccount";
import {SelectDict} from "@components/SelectDict";
import {allStores} from "@/stores";
import {SelectProject} from "@components/SelectProject";
import dayjs from "dayjs";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {BLACK_LIST, ONFILE} from "@/config";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {formatMoney, safeCompute} from "@common/utils";
import {DictItem} from "@services/dto/dict";
import {SelectApplicationFormType} from "@components/SelectApplicationFormType";

export interface PropsType {
  row: BankAccountRow
  isView?: boolean
  bankAccountTypeOptions?: DictItem[]
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores
  const [baApplicationForm, setBaApplicationForm] = useState<string>()
  const [baApplicationFormDisabled, setBaApplicationFormDisabled] = useState<boolean>(true)
  const [baNameDisable, setBaNameDisable] = useState<boolean>(true)

  useEffect(() => {
    form.resetFields()
    const bankAccountRow = {...props.row}
    if (bankAccountRow.id) {
      if (typeof bankAccountRow.responsibilityProject === 'string') {
        bankAccountRow.responsibilityProject = bankAccountRow.responsibilityProject ? bankAccountRow.responsibilityProject.split(',') : []
      }
      if (bankAccountRow.enableDate && bankAccountRow.disableDate) {
        bankAccountRow.enableDisableDate = [dayjs(bankAccountRow.enableDate), dayjs(bankAccountRow.disableDate)]
      }
      if (bankAccountRow.bankAccountCompanyProjects) {
        bankAccountRow.bankAccountCompanyProjects = bankAccountRow.bankAccountCompanyProjects.map(item => ({
          ...item,
          startOn: item.startOn ? dayjs(item.startOn) : null,
          stopOff: item.stopOff ? dayjs(item.stopOff) : null
        }))
      } else {
        bankAccountRow.bankAccountCompanyProjects = []
      }
      switch (bankAccountRow.baType) {
      case '外部协作账户':
      case '内部协作账户':
        bankAccountRow.baApplicationFormType = '项目协作'
        setBaApplicationForm(bankAccountRow.cooperationUnitId)
        setBaApplicationFormDisabled(false)
        setBaNameDisable(!BLACK_LIST.includes(bankAccountRow.cooperationUnitId))
        break
      case '员工公务账户':
      case '员工工资账户':
        bankAccountRow.baApplicationFormType = '员工档案'
        setBaApplicationForm(bankAccountRow.userId)
        setBaApplicationFormDisabled(false)
        setBaNameDisable(!BLACK_LIST.includes(bankAccountRow.userId))
        break
      default:
        bankAccountRow.baApplicationFormType = undefined
        setBaApplicationFormDisabled(true)
        setBaNameDisable(false)
        break
      }
    }
    filingStore.setFilingFaCode(bankAccountRow.code);
    form.setFieldsValue(bankAccountRow);
  }, [props.row])

  const handleBaApplicationFormChange = (v: string, row: any) => {
    const baApplicationFormType = form.getFieldValue('baApplicationFormType')
    if (baApplicationFormType === '项目协作') {
      form.setFieldsValue({
        baName: row?.cuName,
        cooperationUnitId: v,
        cooperationUnit: row?.cuName,
        cooperationUnitCode: row?.cooperationUnitCode,
        userId: undefined,
        userName: undefined,
      })
    } else {
      form.setFieldsValue({
        baName: row?.user?.name,
        cooperationUnitId: undefined,
        cooperationUnit: undefined,
        cooperationUnitCode: undefined,
        userId: v,
        userName: row?.user?.name,
      })
    }
    setBaNameDisable(!BLACK_LIST.includes(v))
  }

  const handleBaTypeChange = (v: string) => {
    switch (v) {
    case '外部协作账户':
    case '内部协作账户':
      form.setFieldsValue({
        baApplicationFormType: '项目协作',
        cooperationUnitId: undefined,
        cooperationUnit: undefined,
        cooperationUnitCode: undefined,
        userId: undefined,
        userName: undefined,
      })
      setBaApplicationForm(undefined)
      setBaApplicationFormDisabled(false)
      setBaNameDisable(true)
      break
    case '员工公务账户':
    case '员工工资账户':
      form.setFieldsValue({
        baApplicationFormType: '员工档案',
        cooperationUnitId: undefined,
        cooperationUnit: undefined,
        cooperationUnitCode: undefined,
        userId: undefined,
        userName: undefined,
      })
      setBaApplicationForm(undefined)
      setBaApplicationFormDisabled(false)
      setBaNameDisable(true)
      break
    default:
      form.setFieldsValue({
        baApplicationFormType: undefined,
        cooperationUnitId: undefined,
        cooperationUnit: undefined,
        cooperationUnitCode: undefined,
        userId: undefined,
        userName: undefined,
      })
      setBaApplicationFormDisabled(true)
      setBaNameDisable(false)
      break
    }
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="cooperationUnitId">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="cooperationUnit">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="cooperationUnitCode">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="账户类型"
            name="baType"
            rules={[{required: false, message: '请选择账户类型!'}]}>
            <SelectDict
              defaultItems={[]}
              allowClear
              onChange={handleBaTypeChange}
              isCustomerOptions={props.bankAccountTypeOptions}
              code={'amount_type'}
              placeholder={'请选择账户类型'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联表单"
            name="baApplicationFormType"
            rules={[{required: false, message: '请输入关联表单!'}]}>
            <SelectApplicationFormType
              isOption
              placeholder={"请选择关联表单"}
              disabled={baApplicationFormDisabled}
              isDisallowedDistinctionType
              typeDisabled
              defaultItems={[]}
              tableValue={baApplicationForm}
              fieldNameList={{'项目协作': {label: 'cooperationUnitCode', value: 'id'}}}
              handleTableValueChange={handleBaApplicationFormChange}
              allowClear></SelectApplicationFormType>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="账户简称"
            name="baNick"
            rules={[{required: false, message: '请选择账户简称!'}]}>
            <Input placeholder="请选择账户简称"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="账户名称"
            name="baName"
            rules={[{required: false, message: '请输入账户名称!'}]}>
            <Input disabled={baNameDisable} placeholder="请输入账户名称"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="银行账号"
            name="baAccount"
            rules={[{required: false, message: '请输入银行账号!'}]}>
            <Input placeholder="请输入银行账号"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="开户银行"
            name="baBlank"
            rules={[{required: false, message: '请输入开户银行!'}]}>
            <Input placeholder="请输入开户银行"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="转入金额"
            name={['bankStatementStatisticsAmount', 'accumulatedIncome']}
            rules={[{required: false, message: '请输入转入金额!'}]}>
            <InputNumber
              formatter={v => v ? formatMoney(safeCompute(v)) : String(v)}
              disabled min={0} precision={2} placeholder="请输入转入金额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="转出金额"
            name={['bankStatementStatisticsAmount', 'accumulatedOutlay']}
            rules={[{required: false, message: '请输入转出金额!'}]}>
            <InputNumber
              formatter={v => v ? formatMoney(safeCompute(v)) : String(v)}
              disabled min={0} precision={2} placeholder="请输入转出金额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="账户余额"
            name={['bankStatementStatisticsAmount', 'balance']}
            rules={[{required: false, message: '请输入账户余额!'}]}>
            <InputNumber
              formatter={v => v ? formatMoney(safeCompute(v)) : String(v)}
              disabled min={0} precision={2} placeholder="请输入账户余额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>

        <Form.List name={"bankAccountCompanyProjects"}>
          { (fields, {add, remove}) => (
            <>
              <Divider style={{margin: '0'}}/>
              { fields.map((field, index) => (
                <Row key={field.key}>
                  <Col span={24}>
                    <Form.Item {...field} hidden name="id">
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item {...field} hidden name="bankAccountId">
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item {...field} hidden name="companyProjectName">
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col span={24} className={"margin-bottom-xs"}>
                    <Col span={24}>
                      <Row align={"middle"} gutter={12}>
                        <Col span={20}>
                          <Form.Item
                            label="责任部门"
                            name={[field.name, 'companyProjectId']}
                            labelCol={{span: 7}}
                            wrapperCol={{span: 17}}
                            rules={[{required: true, message: '请选择责任部门!'}]}>
                            <SelectProject
                              disabled={!!props.row.bankAccountCompanyProjects && !!props.row.bankAccountCompanyProjects[index]?.id}
                              placeholder={"请选择责任部门"}
                              onChange={(value, options) => {
                                const bankAccountCompanyProjects = form.getFieldValue("bankAccountCompanyProjects")
                                bankAccountCompanyProjects[index] = bankAccountCompanyProjects[index] ? bankAccountCompanyProjects[index] : {}
                                bankAccountCompanyProjects[index].companyProjectName = options.find(item => item.id === value)?.name
                                form.setFieldsValue({bankAccountCompanyProjects})
                              }}
                              allowClear/>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Button size={"small"} onClick={() => {
                            const bankAccountCompanyProjects = form.getFieldValue("bankAccountCompanyProjects")
                            if (bankAccountCompanyProjects[index]?.id) {
                              message.warning("责任部门不可删除").then()
                              return
                            }
                            remove(index);
                          }}>
                            <MinusCircleOutlined/>
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Col>
                  <Col span={24} className={"margin-bottom-xs"}>
                    <Form.Item
                      label="启用日期"
                      name={[field.name, 'startOn']}
                      rules={[{required: true, message: '请输入启用日期!'}]}>
                      <DatePicker
                        disabled={!!props.row.bankAccountCompanyProjects && !!props.row.bankAccountCompanyProjects[index]?.id}
                        placeholder={"请输入启用日期"}
                        onChange={() => {
                          const bankAccountCompanyProjects = form.getFieldValue("bankAccountCompanyProjects")
                          bankAccountCompanyProjects[index].stopOff = null
                          form.setFieldsValue({bankAccountCompanyProjects})
                        }}
                        className={"width-100-percentage"}/>
                    </Form.Item>
                  </Col>
                  <Col span={24} className={"margin-bottom-xs"}>
                    <Form.Item
                      label="转入金额"
                      name={[field.name, 'bankStatementStatisticsAmount', 'accumulatedIncome']}
                      rules={[{required: false, message: '请输入转入金额!'}]}>
                      <InputNumber
                        formatter={v => v ? formatMoney(safeCompute(v)) : String(v)}
                        disabled min={0} precision={2} placeholder="请输入转入金额"
                        className={"width-100-percentage"}/>
                    </Form.Item>
                  </Col>
                  <Col span={24} className={"margin-bottom-xs"}>
                    <Form.Item
                      label="转出金额"
                      name={[field.name, 'bankStatementStatisticsAmount', 'accumulatedOutlay']}
                      rules={[{required: false, message: '请输入转出金额!'}]}>
                      <InputNumber
                        formatter={v => v ? formatMoney(safeCompute(v)) : String(v)}
                        disabled min={0} precision={2} placeholder="请输入转出金额"
                        className={"width-100-percentage"}/>
                    </Form.Item>
                  </Col>
                  <Col span={24} className={"margin-bottom-xs"}>
                    <Form.Item
                      label="余额"
                      name={[field.name, 'bankStatementStatisticsAmount', 'balance']}
                      rules={[{required: false, message: '请输入余额!'}]}>
                      <InputNumber
                        formatter={v => v ? formatMoney(safeCompute(v)) : String(v)}
                        disabled min={0} precision={2} placeholder="请输入余额"
                        className={"width-100-percentage"}/>
                    </Form.Item>
                  </Col>
                  <Col span={24} className={"margin-bottom-xs"}>
                    <Form.Item
                      label="停用日期"
                      name={[field.name, 'stopOff']}
                      rules={[{required: false, message: '请输入停用日期!'}]}>
                      <DatePicker
                        disabledDate={(cur) => {
                          const bankAccountCompanyProjects = form.getFieldValue("bankAccountCompanyProjects")
                          return cur.isBefore(bankAccountCompanyProjects[index]?.startOn || dayjs())
                        }}
                        placeholder={"请输入停用日期"}
                        className={"width-100-percentage"}/>
                    </Form.Item>
                  </Col>
                  <Divider style={{margin: '0'}}/>
                </Row>
              )) }
              <Col span={18} offset={3}>
                <Form.Item wrapperCol={{span: 20, offset: 3}} className={"text-align-center"}>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                    新增责任部门
                  </Button>
                </Form.Item>
              </Col>
              <Divider style={{margin: '0'}}/>
            </>
          ) }
        </Form.List>
        <Col span={24}>
          <Form.Item
            label="经办记录"
            name="personResponsible"
            rules={[{required: false, message: '请选择经办记录!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              placeholder={"请选择经办记录"}
              onChange={(value, selectedOptions) => form.setFieldsValue({
                personResponsibleName: selectedOptions.find(item => item.id === value)?.name
              })}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="baStatus"
            rules={[{required: false, message: '请选择办理进度!'}]}>
            <SelectDict
              className={'width-100-percentage'}
              allowClear
              code={'common_type3'}
              disabled={props.row.baStatus === ONFILE}
              placeholder={'请选择办理进度'}/>
          </Form.Item>
        </Col>
        { /*<Col span={24}>
          <Form.Item
            label="启停日期"
            name="enableDisableDate"
            rules={[{required: false, message: '请选择启停日期!'}]}>
            <DatePicker.RangePicker
              allowClear
              placeholder={['启用日期', '结束日期']}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: false, message: '请选择责任部门!'}]}>
            <SelectProject
              mode={"multiple"} placeholder={"请选择责任部门"} allowClear
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>*/ }
      </Row>
    </Form>
  )
}))
