import React, {useEffect, useState} from "react";
import {Select, Space} from "antd";
import {observer} from "mobx-react";

export interface PropsType {
  options: { label: string, value: string }[]
  className?: string,
  placeholder?: [string, string],
  onChange?: (val: [string, string]) => void,
  value?: string
}

export const CustomTableSort = observer((props: PropsType) => {

  const [filed, setFiled] = useState<string>()
  const [sort, setSort] = useState<string>()

  useEffect(() => {
    if (props.value) {
      setFiled(props.value.split(' ')[0] || undefined)
      setSort(props.value.split(' ')[1] || undefined)
    } else {
      setFiled(undefined)
      setSort(undefined)
    }
  }, [props.value]);

  const handleFiledChange = (value: string) => {
    setFiled(value)
    if (props.onChange) {
      props.onChange([value, sort])
    }
  }

  const handleSortChange = (value: string) => {
    setSort(value)
    if (props.onChange) {
      props.onChange([filed, value])
    }
  }

  return (
    <>
      <Space.Compact
        className={props.className || 'width-100-percentage'}>
        <Select
          style={{width: '50%'}}
          allowClear
          value={filed}
          onChange={handleFiledChange}
          options={props.options}
          placeholder={(props.placeholder && props.placeholder[0]) || '请选择字段'}
          className={"width-100-percentage"}/>
        <Select
          style={{width: '50%'}}
          allowClear
          value={sort}
          onChange={handleSortChange}
          options={[
            {label: '升序', value: 'asc'},
            {label: '降序', value: 'desc'},
          ]}
          placeholder={(props.placeholder && props.placeholder[1]) || '请选择排序'}
          className={"width-100-percentage"}/>
      </Space.Compact>
    </>
  )
})
