import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {InvoiceRegistrationReq, InvoiceRegistrationRow} from "@services/dto/invoiceRegistration";

export class InvoiceRegistrationService {

  static async getInvoiceRegistrationStatistics(req: InvoiceRegistrationReq): Promise<Page<InvoiceRegistrationRow>> {
    return await apiV1.get("/invoiceRegistration/statistics", {params: req})
  }

  static async getInvoiceRegistration(req: InvoiceRegistrationReq): Promise<Page<InvoiceRegistrationRow>> {
    return await apiV1.get("/invoiceRegistration/search", {params: req})
  }

  static async addInvoiceRegistration(row: InvoiceRegistrationRow): Promise<ResponseEntity<InvoiceRegistrationRow>> {
    return await apiV1.post("/invoiceRegistration/save", {...row})
  }

  static async updateInvoiceRegistration(row: InvoiceRegistrationRow): Promise<ResponseEntity<InvoiceRegistrationRow>> {
    return await apiV1.put("/invoiceRegistration/update", {...row})
  }

  static async deleteInvoiceRegistration(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/invoiceRegistration/delete", {data: ids})
  }
}
