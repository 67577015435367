import {BankStatementReq, BankStatementRow} from "@services/dto/bankStatement";
import {ContractSettlementReq, ContractSettlementRow} from "@services/dto/contractSettlement";
import {StaffLoanReq, StaffLoanRow} from "@services/dto/staffLoan";
import {CostReimbursementReq, CostReimbursementRow} from "@services/dto/costReimbursement";
import {TaxAccountingReq, TaxAccountingRow} from "@services/dto/taxAccounting";
import {FinalSettlementReq, FinalSettlementRow} from "@services/dto/finalSettlement";
import {PayrollReq, PayrollRow} from "@services/dto/Payroll";
import {CostExpendReq, CostExpendRow} from "@services/dto/costExpend";
import {ProjectCollaborationReq, ProjectCollaborationRow} from "@services/dto/projectCollaboration";
import {CostApplicationReq, CostApplicationRow} from "@services/dto/costApplication";
import {WorkerRecruitmentReq, WorkerRecruitmentRow} from "@services/dto/workerRecruitment";
import {TaskSupervisionReq, TaskSupervisionRow} from "@services/dto/taskSupervision";
import {WorkScheduleReq, WorkScheduleRow} from "@services/dto/workSchedule";

/**
 * @description 每增加一张表 就使用 符号| 拼上一段
 * @example BankStatementRow | FundApprovalRow
 */
export type DatasourceType =
  BankStatementRow
  | ContractSettlementRow
  | StaffLoanRow
  | CostReimbursementRow
  | TaxAccountingRow
  | FinalSettlementRow
  | PayrollRow
  | CostExpendRow
  | ProjectCollaborationRow
  | CostApplicationRow
  | WorkerRecruitmentRow
  | TaskSupervisionRow
  | WorkScheduleRow

/**
 * @description 每增加一张表 就使用 符号| 拼上一段
 * @example BankStatementReq | FundApprovalReq
 */
export type SearchReqType =
  BankStatementReq
  | ContractSettlementReq
  | StaffLoanReq
  | CostReimbursementReq
  | TaxAccountingReq
  | FinalSettlementReq
  | PayrollReq
  | ProjectCollaborationReq
  | CostExpendReq
  | CostApplicationReq
  | WorkerRecruitmentReq
  | TaskSupervisionReq
  | WorkScheduleReq

export type FieldNameType = { value: string, label: string }

export type FieldNameListType = { [key: string]: FieldNameType }

export const defaultFieldNames: FieldNameType = {value: "id", label: "id"}

export const typeDefaultItems = [
  {id: 'N/A', value: 'N/A'},
  {id: '空白', value: '空白'},
]
