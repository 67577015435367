import {Any} from "@common/types";
import _ from 'lodash';
import {message, UploadFile, UploadProps} from "antd";
import {User} from "@services/dto/security";
import {UploadChangeParam} from "antd/es/upload/interface";
import {useState} from "react";
import {BASE_API_PATH} from "@/config";
import {useHeaders} from "@components/CustomUpload";
import dayjs from "dayjs";

export const logger = console;

export const getErrorStr = (e: Any): string => {
  return e.message || JSON.stringify(e)
}


/**
 * store date
 * @param key the key
 * @param obj the json object
 */
export const setData = (key: string, obj: Any) => {
  const rawStr = JSON.stringify(obj) || '';
  localStorage.setItem(key, rawStr);
  return Promise.resolve();
};

/**
 * remove date
 * @param key the key
 */
export const removeData = (key: string) => {
  localStorage.removeItem(key);
  return Promise.resolve();
};

/**
 * get data
 * @param key
 */
export function getData<T>(key: string): Promise<T | undefined> {
  const jsonStr = localStorage.getItem(key);
  if (!jsonStr) {
    return Promise.resolve(undefined);
  }
  return Promise.resolve(JSON.parse(jsonStr || '{}') as T);
}


export const showErr = (e: Any) => message.error(getErrorStr(e))

export const getGenderLabel = (gender?: number) => {
  if (_.isNil(gender)) {
    return "未知"
  }
  return gender === 0 ? '男' : '女'
}

export const getOrganizationStatusLabel = (status?: number) => {
  if (_.isNil(status)) {
    return "未知"
  }
  return status === 0 ? '离职' : '在职'
}

export const getSuppliesStatusLabel = (status?: number) => {
  if (_.isNil(status)) {
    return "未知"
  }
  return status === 0 ? '已归还' : '已出库'
}

export const getRuleRegulationStatusLabel = (status?: string) => {
  if (_.isNil(status)) {
    return "未知"
  }
  return status === '0' ? '有效' : '失效'
}

export const getProjectStatusLabel = (status?: number) => {
  if (_.isNil(status)) {
    return "未知"
  }
  return status === 0 ? '进行中' : status === 1 ? "已完成" : "未完成"
}

export const getTalentJobStatusLabel = (status?: string) => {
  switch (status) {
  case "exited":
    return "离职"
  case "hired":
    return "在职"
  case "unemployed":
    return "待业"
  default:
    return "未知"
  }
}

export const getAdvanceStatusLabel = (status?: number) => {
  if (_.isNil(status)) {
    return "代核查"
  }
  return status === 1 ? '预支中' : "已归还"
}

export const getUploadStringToArray = (url: string): UploadFile[] => {
  if (url) {
    return url.split(";").map(item => ({
      uid: item.substring(item.lastIndexOf("/") + 1),
      id: item.substring(item.lastIndexOf("/") + 1),
      name: item.substring(item.lastIndexOf("/") + 1),
      status: "done",
      url: item,
      thumbUrl: item,
    }));
  }
  return []
}

export const getUploadArrayToString = (UploadFiles: UploadFile[]): string => {
  return UploadFiles.map(item => {
    if (item.response) {
      if (item.response.length) {
        return item.response.map(res => {
          return res.url
        }).join(";")
      }
      return item.response.url;
    }
    return item.url
  }).join(";");
}

export const getUploadIds = (UploadFiles: UploadFile[]) => {
  return UploadFiles.map(item => {
    if (item.response) {
      if (item.response.length) {
        return item.response.map(res => {
          return res.id
        }).join(",")
      }
      return item.response.id;
    }
    return item.uid
  }).join(",")
}


export const getPathByRoles = (user: User) => {
  if (user.roles.includes("admin")) {
    return "/company"
  }
  if (user.roles.includes("company") || user.roles.includes("pm") || user.roles.includes("hr")) {
    return "/workbench"
  }
  return "/recruitments"
}

export function useUploadProps(url: string, callback: (info: UploadChangeParam) => void, data?: Record<string, unknown> | ((file: UploadFile) => Record<string, unknown> | Promise<Record<string, unknown>>)) {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const uploadProps: UploadProps = {
    name: "file",
    action: BASE_API_PATH + url,
    headers: useHeaders(),
    fileList: fileList,
    data: data,
    onChange: (info) => {
      if (info.file.status === 'done') {
        setFileList([])
        callback(info)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} 文件导入失败`);
        setFileList([])
      } else {
        setFileList([...info.fileList])
      }
    }
  }
  return uploadProps
}

export const handleDateAddSubtract = (action: string, dateValue: dayjs.Dayjs) => {
  const now = dayjs(dateValue.format("YYYY-MM"))
  let now_calc;
  switch (action) {
  case "add":
    now_calc = now.add(1, "month")
    break
  case "subtract":
    now_calc = now.add(-1, "month")
    break
  default:
    now_calc = now
    break
  }
  return now_calc
}

/**
 * 判断两个对象（或数组）的元素是否一致
 * @param obj1 对象1
 * @param obj2 对象2
 * @returns 是否一致
 */
export const compareObjects = (obj1: Record<string, any>, obj2: Record<string, any>): boolean => {
  if (obj1 === undefined || obj2 === undefined) {
    return false;
  }

  // 如果是数组先排序
  if(Array.isArray(obj1) && Array.isArray(obj2)) {
    obj1 = obj1.sort()
    obj2 = obj2.sort()
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

/**
 * js number类型新增计算失真
 * @param num1
 * @param num2
 */
export const accAdd = (num1: number, num2: number) => {
  const num1Digits = (num1.toString().split('.')[1] || '').length;
  const num2Digits = (num2.toString().split('.')[1] || '').length;
  const baseNum = Math.pow(10, Math.max(num1Digits, num2Digits));
  return (Math.round(num1 * baseNum) + Math.round(num2 * baseNum)) / baseNum;
}

/**
 * js方法，传入数字，输出字符串。各类金额后面加大写，如123456.78（壹拾贰万叁仟肆佰伍拾陆元柒角捌分）
 * @param money
 */
export //代码如下所示：
function convertCurrency(money: number) {
  //汉字的数字
  let cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  //基本单位
  let cnIntRadice = ['', '拾', '佰', '仟'];
  //对应整数部分扩展单位
  let cnIntUnits = ['', '万', '亿', '兆'];
  //对应小数部分单位
  let cnDecUnits = ['角', '分', '毫', '厘'];
  //整数金额时后面跟的字符
  let cnInteger = '整';
  //整型完以后的单位
  let cnIntLast = '元';
  //最大处理的数字
  let maxNum = 999999999999999.9999;
  //金额整数部分
  let integerNum: string = '';
  //金额小数部分
  let decimalNum: string = '';
  //输出的中文金额字符串
  let chineseStr = '';
  //分离金额后用的数组，预定义
  let parts: string[] = [];
  if (money >= maxNum) {
    //超出最大处理数字
    return '';
  }
  if (money === 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger;
    return chineseStr;
  }
  //转换为字符串
  const moneyStr = money.toString();
  if (moneyStr.indexOf('.') === -1) {
    integerNum = moneyStr;
    decimalNum = '';
  } else {
    parts = moneyStr.split('.');
    integerNum = parts[0];
    decimalNum = parts[1].substr(0, 4);
  }
  //获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    let zeroCount = 0;
    let IntLen = integerNum.length;
    for (let i = 0; i < IntLen; i++) {
      let n = integerNum.substr(i, 1);
      let p = IntLen - i - 1;
      let q = p / 4;
      let m = p % 4;
      if (n === '0') {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0];
        }
        //归零
        zeroCount = 0;
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
      }
      if (m === 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q];
      }
    }
    chineseStr += cnIntLast;
  }
  //小数部分
  if (decimalNum !== '') {
    let decLen = decimalNum.length;
    for (let i = 0; i < decLen; i++) {
      let n = decimalNum.substr(i, 1);
      if (n !== '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
      }
    }
  }
  if (chineseStr === '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger;
  } else if (decimalNum === '') {
    chineseStr += cnInteger;
  }
  return chineseStr;
}


/**
 * 返回安全数字
 * @param value
 */
export const safeCompute = (value: any) => {
  return isNaN(value) ? 0 : Number(value);
}

/**
 * 比较版本号
 * @param version1 最新版本
 * @param version2 自己的版本
 */
export const compareVersions = (version1: string, version2: string) => {
  // 将版本号字符串分割成数组
  const v1 = version1.split('.').map(Number);
  const v2 = version2.split('.').map(Number);

  // 逐个比较版本号的每个部分
  for (let i = 0; i < Math.max(v1.length, v2.length); i++) {
    const num1 = i < v1.length ? v1[i] : 0;
    const num2 = i < v2.length ? v2[i] : 0;

    if (num1 > num2) {
      return true;
    } else if (num1 < num2) {
      return false;
    }
  }

  // 如果所有部分都相等，则返回false，表示版本号相等
  return false;
}

/**
 * 解析 URL 参数并返回参数对象
 * @param urlParams 包含参数的字符串
 * @returns 参数对象
 */
export const parseURLParams = (urlParams: string): Record<string, string> => {
  const params: Record<string, string> = {};

  // 切割参数以等号分隔键值对
  const keyValuePairs = urlParams.split('&');

  // 遍历键值对，解析参数
  keyValuePairs.forEach(keyValue => {
    const [key, value] = keyValue.split('=');
    if (key && value) {
      params[key] = value;
    }
  });

  return params;
};

export const formatMoney = (num: number) => {
  // 将数字转换为字符串，并取得整数部分和小数部分
  let [integerPart, decimalPart] = (num || 0).toFixed(2).split(".");

  // 给整数部分添加千分符
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // 如果存在小数部分，则将小数部分添加到整数部分后面，并返回结果
  if (decimalPart) {
    return `${integerPart}.${decimalPart}`;
  } else {
    return integerPart;
  }
}

export const arraysContainSameElements = (arr1: string[], arr2: string[]) => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  return arr1.every(a => arr2.some(b => a === b));
}

export const isChineseStr = (str: string): boolean => {
  return /[\u4e00-\u9fa5]/.test(str);
}

export const accSubtract = (num1: number, num2: number) => {
  const num1Digits = (num1.toString().split('.')[1] || '').length;
  const num2Digits = (num2.toString().split('.')[1] || '').length;
  const baseNum = Math.pow(10, Math.max(num1Digits, num2Digits));
  return (Math.round(num1 * baseNum) - Math.round(num2 * baseNum)) / baseNum;
}

export const accMultiply = (num1: number, num2: number) => {
  const num1Digits = (num1.toString().split('.')[1] || '').length;
  const num2Digits = (num2.toString().split('.')[1] || '').length;
  const baseNum = Math.pow(10, Math.max(num1Digits, num2Digits));
  return (Math.round(num1 * baseNum) * Math.round(num2 * baseNum)) / baseNum / baseNum;
}

// export const
