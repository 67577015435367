import { observer } from "mobx-react";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Drawer, FormInstance, Input, message, Modal, Row, Space, Table} from "antd";
import { ColumnsType } from "antd/es/table";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined
} from "@ant-design/icons";
import {DATE_FMT, DEFAULT_MODAL_WIDTH} from "@/config";
import {SalaryRow, SearchSalaryReq} from "@services/dto/salary";
import { BasicInfo } from "@modules/Salary/Components/BasicInfo";
import {MonthDatePicker} from "@modules/Salary/Components/MonthDatePicker";
import dayjs from "dayjs";
import {allStores} from "@/stores";
import {showErr} from "@common/utils";
import {SalaryService} from "@services/SalaryService";

export const Salary = observer(() => {
  const {salaryStore} = allStores
  const basicInfo = React.createRef<FormInstance>();
  const [searchValue, setSearchValue] = useState<string>("")
  const [dataSource, setDataSource] = useState<SalaryRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [searchSalaryReq, setSearchSalaryReq] = useState<SearchSalaryReq>({ perPage: 10, pageNum: 1, time: dayjs().format(DATE_FMT) })
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [salaryRow, setSalaryRow] = useState<SalaryRow>()
  const [isView, setIsView] = useState(false)

  const columns: ColumnsType<SalaryRow> = [
    {
      title: '工号',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => { handleView(record) }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '证件类型',
      dataIndex: 'chinaUidType',
      key: 'chinaUidType',
      align: 'center'
    },
    {
      title: '证件号码',
      dataIndex: 'chinaUid',
      key: 'chinaUid',
      align: 'center'
    },
    {
      title: '基本工资',
      dataIndex: 'basicSalary',
      key: 'basicSalary',
      align: 'center'
    },
    {
      title: '加班工资',
      dataIndex: 'addBasicSalary',
      key: 'addBasicSalary',
      align: 'center'
    },
    {
      title: '补贴',
      dataIndex: 'subsidies',
      key: 'subsidies',
      align: 'center'
    },
    {
      title: '奖金',
      dataIndex: 'bonus',
      key: 'bonus',
      align: 'center'
    },
    {
      title: '扣减',
      dataIndex: 'deductions',
      key: 'deductions',
      align: 'center'
    },
    {
      title: '应发工资',
      dataIndex: 'shouldPay',
      key: 'shouldPay',
      align: 'center'
    },
    {
      title: '五险一金(个人)',
      dataIndex: 'socialInsuranceUser',
      key: 'socialInsuranceUser',
      align: 'center'
    },
    {
      title: '五险一金(企业)',
      dataIndex: 'socialInsuranceCompany',
      key: 'socialInsuranceCompany',
      align: 'center'
    },
    {
      title: '税前实发工资',
      dataIndex: 'realWages1',
      key: 'realWages1',
      align: 'center'
    },
    {
      title: '个人所得税',
      dataIndex: 'sui',
      key: 'sui',
      align: 'center'
    },
    {
      title: '实发工资',
      dataIndex: 'realWages2',
      key: 'realWages2',
      align: 'center'
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record, index) => (
        <Space>
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined />}
            onClick={() => { handleEdit(record) }}>修改</Button>
          <Button shape="round" className={"delete-btn"} size={"middle"}
            icon={<DeleteOutlined />} onClick={() => { handleDeleteBatch(record) }}>删除</Button>
        </Space>
      )
    }
  ];

  useEffect(() => {
    refreshData(searchSalaryReq)
  }, [])

  const refreshData = (req: SearchSalaryReq) => {
    salaryStore.search(req)
  }

  useEffect(() => {
    setDataSource(salaryStore.salaryDatasource.data?.items || [])
    setTotal(salaryStore.salaryDatasource.data?.total || 0)
  }, [salaryStore.salaryDatasource])

  const handleView = (record: SalaryRow) => {
    setSalaryRow(record)
    setIsView(true)
    setIsModalVisible(true)
  }

  const handleEdit = (record: SalaryRow) => {
    setSalaryRow(record)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handleDeleteBatch = (record?: SalaryRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids:string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        SalaryService.delete(ids).then(() => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(searchSalaryReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const onSearch = () => {
    const req = {...searchSalaryReq}
    req.pageNum = 1
    req.code = searchValue
    setSearchSalaryReq(req)
    refreshData(req)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchSalaryReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchSalaryReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsView(false)
    setIsModalVisible(false)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleDateChange = (date: dayjs.Dayjs, dateString: string) => {
    const req = {...searchSalaryReq}
    req.pageNum = 1
    req.time = date.format(DATE_FMT)
    setSearchSalaryReq(req)
    refreshData(req)
  }

  const handleGenerate = () => {
    const year = dayjs(searchSalaryReq.time).year()
    const month = dayjs(searchSalaryReq.time).month() + 1
    setConfirmLoading(true)
    SalaryService.calculate({year: year, month: month}).then(rsp => {
      message.success("工资生成成功！")
      refreshData(searchSalaryReq)
    }).catch(showErr).finally(() => setConfirmLoading(false))
  }

  return (
    <div className={"institution"}>
      <Row>
        <Col span={8}>
          <Input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={onSearch}
            allowClear
            placeholder="请输入工号"
            suffix={<SearchOutlined onClick={onSearch} />}
          />
        </Col>

        <Col span={8}>
          <MonthDatePicker handleDateChange={handleDateChange}/>
        </Col>

        <Col span={8} className={"text-align-right"}>
          <Button loading={confirmLoading} size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={handleGenerate} icon={<CheckCircleOutlined />}>生成</Button>
          <Button icon={<DeleteOutlined />} type={"primary"} size={"middle"} shape={"round"}
            onClick={() => handleDeleteBatch(undefined)}>删除</Button>
        </Col>
      </Row>

      <Card className={"margin-top-sm"}>
        <Table
          rowKey={"id"}
          dataSource={dataSource}
          columns={columns}
          size={"middle"}
          loading={confirmLoading}
          pagination={{
            total: total,
            position: ['bottomCenter'],
            pageSize: searchSalaryReq.perPage,
            current: searchSalaryReq.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`, onChange: handlePageChange
          }}
          rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys }} />
      </Card>

      <Drawer
        title={<div className="text-align-center">薪资总表</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        onClose={handleCancel}>

        <BasicInfo row={{ ...salaryRow! }} ref={basicInfo} isView={isView} />

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          <Button type="primary" onClick={handleOk} loading={confirmLoading} className={"margin-left-xs"}>确定</Button>
        </div>
      </Drawer>
    </div>
  )
})
