import React, { useEffect } from "react";
import {Col, Form, Input, InputNumber, Row, Select} from "antd";
import { FormInstance } from "antd/es";
import { observer } from "mobx-react";
import {SalaryRow} from "@services/dto/salary";
import {CascaderPerson} from "@components/CascaderPerson";

export interface PropsType {
  row: SalaryRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const cardDict = ["身份证"]

  useEffect(() => {
    form.resetFields()
    const salaryRow = { ...props.row }
    if (salaryRow.id) {
      if (typeof salaryRow.userCode === 'string') {
        salaryRow.userCode = salaryRow.userCode.split(",")
      }
    }
    form.setFieldsValue(salaryRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ status: 1, isVirtualUser: 0 }}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="员工"
            name="userCode"
            valuePropName={"person"}
            rules={[{ required: !props.row.id, message: '请选择员工!' }]}>
            <CascaderPerson placeholder={"请选择员工"} disabled={!!props.row.id}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="证件类型"
            name="chinaUidType"
            rules={[{required: true, message: '请选择证件类型!'}]}>
            <Select allowClear placeholder="请选择证件类型">
              {
                cardDict.map(item => {
                  return (
                    <Select.Option key={item} value={item}>{ item }</Select.Option>
                  )
                })
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="证件号码"
            name="chinaUid"
            rules={[{ required: true, message: '请输入证件号码!' }]}>
            <Input placeholder="请输入证件号码" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="基本工资"
            name="basicSalary"
            rules={[{ required: true, message: '请输入基本工资!' }]}>
            <InputNumber placeholder="请输入基本工资" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="加班工资"
            name="addBasicSalary"
            rules={[{ required: true, message: '请输入加班工资!' }]}>
            <InputNumber placeholder="请输入加班工资" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="补贴"
            name="subsidies"
            rules={[{ required: true, message: '请输入补贴!' }]}>
            <InputNumber placeholder="请输入补贴" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="奖金"
            name="bonus"
            rules={[{ required: true, message: '请输入奖金!' }]}>
            <InputNumber placeholder="请输入奖金" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="扣减"
            name="deductions"
            rules={[{ required: true, message: '请输入扣减!' }]}>
            <InputNumber placeholder="请输入扣减" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="应发工资"
            name="shouldPay"
            rules={[{ required: true, message: '请输入应发工资!' }]}>
            <InputNumber placeholder="请输入应发工资" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="五险一金(个人)"
            name="socialInsuranceUser"
            rules={[{ required: true, message: '请输入五险一金(个人)!' }]}>
            <InputNumber placeholder="请输入五险一金(个人)" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="五险一金(企业)"
            name="socialInsuranceCompany"
            rules={[{ required: true, message: '请输入五险一金(企业)!' }]}>
            <InputNumber placeholder="请输入五险一金(企业)" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="税前实发工资"
            name="realWages1"
            rules={[{ required: true, message: '请输入税前实发工资!' }]}>
            <InputNumber placeholder="请输入税前实发工资" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="个人所得税"
            name="sui"
            rules={[{ required: true, message: '请输入个人所得税!' }]}>
            <InputNumber placeholder="请输入个人所得税" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="税后实发工资"
            name="realWages2"
            rules={[{ required: true, message: '请输入税后实发工资!' }]}>
            <InputNumber placeholder="请输入税后实发工资" precision={2} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
