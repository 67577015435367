import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {Button, Drawer, FormInstance, message, Modal, Space, Table} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {getUploadArrayToString, getUploadIds, logger, showErr} from "@common/utils";
import {FilingReq, FilingRow} from "@services/dto/filing";
import {FilingService} from "@services/FilingService";
import {BasicInfo} from "@modules/Filing/Components/BasicInfo";
import {BasicInfoBatch} from "@modules/Filing/Components/BasicInfoBatch";
import {LabelValue, ResponseEntity} from "@services/dto/common";
import {ToolService} from "@services/ToolService";
import {CustomUpload, previewFile} from "@components/CustomUpload";
import {BasicInfo as ProcessRecordsBasicInfo} from "@modules/ProcessRecords/Components/BasicInfo";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {HeaderActionCom} from "@modules/Filing/Components/HeaderActionCom";
import {useLocation} from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FieldTimeOutlined,
  PrinterOutlined,
  UploadOutlined
} from "@ant-design/icons";
import {WindowPrint} from "@modules/WindowPrint";
import {
  APPROVE_FORM_TRANS_STATE,
  CLOSE,
  DATE_FMT,
  DEFAULT_MODAL_WIDTH,
  EDIT_TEXT,
  FORM_ALTERNATE_TEXT,
  FORM_CLOSE_TEXT,
  FORM_COMMIT_TEXT,
  FORM_HANDOVER_TEXT,
  FORM_PERSON_RESPONSIBLE_WHITELIST,
  FORM_RETURN_TEXT,
  FORM_REVOCATION_TEXT,
  LARGE_MODAL_WIDTH,
  LARGE_MODAL_WIDTH_OTHER,
  READY,
  SMALL_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x,
} from "@/config";
import {ProcessRecordsRow} from "@services/dto/processRecords";
import {TableAuthOptions} from "@components/CustomAuthOptions";
import {CustomDraggableModal} from "@components/CustomDraggableModal";
import {ProcessRecords} from "@modules/ProcessRecords";
import {ProcessRecordsService} from "@services/ProcessRecordsService";
import {CustomForm} from "@components/CustomForm";
import {OtherBasicInfo} from "@components/OtherBasicInfo";

const wideShowColumn: string[] = [
  // 'faTime',
  'faBatchNumber',
  'faName',
  'faCode',
  'faType',
  // 'remark',
  'faSaveDate',
  'faSaveBox',
  'faSaveAddress',
  'efaAccountingObject',
  'status',
  'action',
]

const narrowShowColumn: string[] = [
  // 'faTime',
  'faBatchNumber',
  'faName',
  // 'faCode',
  // 'faType',
  // 'remark',
  // 'faSaveDate',
  // 'faSaveBox',
  'faSaveAddress',
  'efaAccountingObject',
  'status',
  'action',
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: FilingRow[] // 数据源
}

export const TableName = 'fileArchive'

export const Filing = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const location = useLocation()
  const {
    processStore,
    filingStore,
    tableColumnsStore,
    securityStore,
    headerSearchStore,
    screenResolutionStore,
    contractListStore,
    contractSettlementStore,
    fundApprovalStore,
    bankStatementStore,
    costApplicationStore,
    costExpendStore,
    staffLoanStore,
    costReimbursementStore,
    finalSettlementStore,
    invoiceRegisterStore,
    payrollStore,
    taxAccountingStore,
  } = allStores
  const basicInfo = React.createRef<FormInstance>();
  const processRecordsBasicInfo = React.createRef<FormInstance>();
  const basicInfoBatch = React.createRef<FormInstance>();
  const basicInfoOther = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<FilingRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBatchModalVisible, setIsBatchModalVisible] = useState(false);
  const [filingRow, setFilingRow] = useState<FilingRow>()
  const [otherFilingRow, setOtherFilingRow] = useState<FilingRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isBatchView, setIsBatchView] = useState<boolean>(false)
  const [scrollHeight, setScrollHeight] = useState<number>(SMALL_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<FilingRow>>([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [printTitle, setPrintTitle] = useState<string>('文件存档单')
  const [contentList, setContentList] = useState<LabelValue[]>([]);
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [processRecordsIsOpen, setProcessRecordsIsOpen] = useState<boolean>(false)
  const [processRecordsRow, setProcessRecordsRow] = useState<ProcessRecordsRow>()
  const [processRecordsOpen, setProcessRecordsOpen] = useState<boolean>(false)
  const [processRecordsDataSource, setProcessRecordsDataSource] = useState<ProcessRecordsRow[]>([])
  const [submitRow, setSubmitRow] = useState<FilingRow>()
  const [submitterId, setSubmitterId] = useState<string>()
  const [latestProcess, setLatestProcess] = useState<string>()
  const [searchReq, setSearchReq] = useState<FilingReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...filingStore.filingReq
  })

  const columns: ColumnsType<FilingRow> = [
    {
      title: '文件编号',
      dataIndex: 'faCode',
      key: 'faCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text, record) => (
        <Space>
          <Button type={'link'} onClick={() => handleOtherView(record)}><TableEllipsisText
            value={text}></TableEllipsisText></Button>
        </Space>
      )
    },
    {
      title: '文件名称',
      dataIndex: 'faName',
      key: 'faName',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '文件类别',
      dataIndex: 'faType',
      key: 'faType',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '文件摘要',
      dataIndex: 'remark',
      key: 'remark',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'faBatchNumber',
      key: 'faBatchNumber',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档日期',
      dataIndex: 'faSaveDate',
      key: 'faSaveDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档盒号',
      dataIndex: 'faSaveBox',
      key: 'faSaveBox',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档地点',
      dataIndex: 'faSaveAddress',
      key: 'faSaveAddress',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    /*{
      title: '交件人',
      dataIndex: 'faSubmitter',
      key: 'faSubmitter',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TablePersonResponsible value={text}></TablePersonResponsible>
        </Space>
      )
    },
    {
      title: '收件人',
      dataIndex: 'faRecipient',
      key: 'faRecipient',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TablePersonResponsible value={text}></TablePersonResponsible>
        </Space>
      )
    },*/
    {
      title: '办理人员',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '主办人员',
      dataIndex: 'organizersUserName',
      key: 'organizersUserName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '电子文件',
      dataIndex: 'efaAccountingObject',
      key: 'efaAccountingObject',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          {
            text ? <Button onClick={() => previewFile(text)} type={"link"}>查看</Button> : '/'
          }
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableAuthOptions
          mainBtn={['handleEdit', 'handleView']}
          items={[
            {
              key: 'handleView',
              icon: <EllipsisOutlined/>,
              onClick: () => handleView(record)
            },
            {
              key: 'handleEdit',
              icon: <EditOutlined/>,
              disabled: record.status === CLOSE,
              onClick: async () => await handleEdit(record)
            },
            {
              key: 'handlePrint',
              icon: <PrinterOutlined/>,
              onClick: () => handlePrint(record)
            },
            {
              key: 'handleDeleteBatch',
              icon: <DeleteOutlined/>,
              disabled: record.status === CLOSE,
              onClick: () => handleDeleteBatch(record)
            },
          ]}></TableAuthOptions>
      )
    }
  ];

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (filingStore.filingDatasource.data) {
      setDataSource(filingStore.filingDatasource.data.items || [])
      setTotal(filingStore.filingDatasource.data.total || 0)
    }
  }, [filingStore.filingDatasource.data])

  useEffect(() => {

  }, [filingStore.filingRow])

  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => o.key !== 'action').filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(parent?.clientHeight - headAction?.clientHeight - thead?.clientHeight - paginationHeight - othersHeight)
  }, [screenResolutionStore.screenResolution.height, filingStore.collapsed, searchReq.pageNum, searchReq.perPage])

  useEffect(() => {
    if (filingStore.filingOption) {
      const [option] = filingStore.filingOption.split("?")
      if (option === 'add') {
        handleAdd()
        filingStore.updateFilingOption(undefined)
      }
    }
  }, [filingStore.filingOption])

  const handlePrint = async (record: FilingRow) => {
    const list = await filingStore.getPrintList(record)
    if (list) {
      setPrintTitle('文件存档单')
      setContentList(list)
      setIsModalOpen(true)
    } else {
      message.warning('请检查！')
    }
  }

  const handleOtherPrint = async () => {
    let list: LabelValue[]
    if (otherFilingRow.faType.includes('合同审批')) {
      list = await contractListStore.getPrintList(contractListStore.contractListDatasource.data.items[0])
    } else if (otherFilingRow.faType.includes('合同结算')) {
      list = await contractSettlementStore.getPrintList(contractSettlementStore.contractSettlementDatasource.data.items[0])
    } else if (otherFilingRow.faType.includes('资金审批')) {
      list = await fundApprovalStore.getPrintList(fundApprovalStore.fundApprovalDatasource.data.items[0])
    } else if (otherFilingRow.faType.includes('资金出纳')) {
      list = await bankStatementStore.getPrintList(bankStatementStore.bankStatementDatasource.data.items[0])
    } else if (otherFilingRow.faType.includes('费用申请')) {
      list = await costApplicationStore.getPrintList(costApplicationStore.costApplicationDatasource.data.items[0])
    } else if (otherFilingRow.faType.includes('费用支出')) {
      list = await costExpendStore.getPrintList(costExpendStore.costExpendDatasource.data.items[0])
    } else if (otherFilingRow.faType.includes('员工借款')) {
      list = await staffLoanStore.getPrintList(staffLoanStore.staffLoanDatasource.data.items[0])
    } else if (otherFilingRow.faType.includes('费用报销')) {
      list = await costReimbursementStore.getPrintList(costReimbursementStore.costReimbursementDatasource.data.items[0])
    } else if (otherFilingRow.faType.includes('税费缴纳')) {
      list = await finalSettlementStore.getPrintList(finalSettlementStore.finalSettlementDatasource.data.items[0])
    } else if (otherFilingRow.faType.includes('发票登记')) {
      list = await invoiceRegisterStore.getPrintList(invoiceRegisterStore.invoiceRegisterDatasource.data.items[0])
    } else if (otherFilingRow.faType.includes('工资发放')) {
      list = await payrollStore.getPrintList(payrollStore.payrollDatasource.data.items[0])
    } else if (otherFilingRow.faType.includes('税费核算')) {
      list = await taxAccountingStore.getPrintList(taxAccountingStore.taxAccountingDatasource.data.items[0])
    }
    setPrintTitle(otherFilingRow.faType)
    setContentList(list)
    setIsModalOpen(true)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const handleBatchCancel = () => {
    setIsBatchModalVisible(false)
  }

  const refreshData = (req: FilingReq) => {
    setSearchReq(req)
    filingStore.getFiling(req)
  }

  const handleBatchOk = () => {
    const row = {...filingRow!}
    basicInfoBatch.current!.validateFields().then(values => {
      Object.assign(row, values)
      if (values.faSaveDate) {
        row.faSaveDate = values.faSaveDate.format(DATE_FMT)
      }
      let rows: FilingRow[] = JSON.parse(JSON.stringify(dataSource.filter(o => selectedRowKeys.includes(o.id))))
      rows.forEach((item) => {
        item.faSaveDate = values.faSaveDate
        item.faSaveBox = values.faSaveBox
        item.faSaveAddress = values.faSaveAddress
        item.faSubmitter = values.faSubmitter
        item.faRecipient = values.faRecipient
      })
      setConfirmLoading(true)
      FilingService.batchUpdateFiling(rows).then(rsp => {
        message.success(rsp.message || "操作成功！").then()
        setIsBatchModalVisible(false);
        setFilingRow(undefined)
        refreshData(filingStore.filingReq)
        setUpdateCount(updateCount + 1)
      }).catch(showErr).finally(() => {
        setConfirmLoading(false)
        setSelectedRowKeys([])
      });
    }).catch(logger.warn)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(location.pathname, value)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    filingStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const handleEditBatch = () => {
    if (!selectedRowKeys.length) {
      message.warning('请至少勾选一个数据！').then()
      return
    }
    setFilingRow(null)
    setIsBatchView(false)
    setIsBatchModalVisible(true);
  }

  const handleOtherView = (record: FilingRow) => {
    setOtherFilingRow(record)
    setIsOpen(true)
  }

  const handleBatchUploadChange = async (info) => {
    const successFile = []
    const errorFile = []
    if (info.file.status === "done") {
      const response = await FilingService.getFiling({
        pageNum: 1,
        perPage: 1,
        faCode: info.file.name.split('.')[0]
      })
      if (response.items && response.items.length > 0) {
        const filing = {...response.items[0]}
        const url = getUploadArrayToString([info.file])
        if (filing.efaAccountingObject) {
          filing.efaAccountingObject += ';' + url;
        } else {
          filing.efaAccountingObject += url;
        }
        const res = await FilingService.updateFiling(filing)
        if (res.code === 200) {
          successFile.push(info.file.name)
        } else {
          errorFile.push(info.file.name)
        }
      } else {
        errorFile.push(info.file.name)
      }
    }
    if (successFile.length > 0) {
      message.success(`${successFile.join('、')} 已上传到指定文件编号.`).then();
    }
    if (errorFile.length > 0) {
      message.error(`${errorFile.join('、')} 上传指定文件编号失败.`).then();
    }
    const filingReq = filingStore.filingReq;
    refreshData({...searchReq, ...filingReq})
  }

  const handleAdd = () => {
    const user = securityStore.getLoggedUser()
    setFilingRow({
      status: READY,
      personResponsible: user._id,
      personResponsibleName: user.name
    } as FilingRow)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handleView = (record: FilingRow) => {
    setFilingRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = async (record: FilingRow) => {

    try {
      const rsp = await ProcessRecordsService.getProcessRecords({
        businessId: record.id,
        pageNum: 1,
        perPage: 999,
        type: TableName,
      })
      const process = rsp.items.length ? rsp.items[0].process : ''
      const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
      const con = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
      // 如果本轮有撤回 （id为空） 否则 （发起最新一轮的提交的人的id）
      setSubmitterId(con ? undefined : userId)
      setLatestProcess(process) // 发起最新一轮的标志
      setFilingRow(record)
      setIsView(false)
      setIsModalVisible(true);
    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }

  }

  const handleDeleteBatch = (record?: FilingRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        FilingService.deleteFiling(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(filingStore.filingReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const insertOrUpdate = async (row: FilingRow) => {
    if (typeof row.faSaveDate === 'object' && row.faSaveDate) {
      row.faSaveDate = row.faSaveDate.format(DATE_FMT)
    }
    if (typeof row.cooperationUnit === 'object' && row.cooperationUnit) {
      row.cooperationUnit = row.cooperationUnit.join(",")
    }
    let ids = ""
    if (typeof row.efaAccountingObject === 'object') {
      ids = getUploadIds(row.efaAccountingObject)
      row.efaAccountingObject = getUploadArrayToString(row.efaAccountingObject)
    }
    if (row.id) {
      const rsp = await FilingService.updateFiling(row)
      actionThen(ids, rsp).catch();
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    } else {
      const rsp = await FilingService.addFiling(row)
      actionThen(ids, rsp).catch();
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      return rsp
    }
  }

  const actionThen = async (ids: string, rsp: ResponseEntity<FilingRow>) => {
    // 图片上传跟表单绑定
    try {
      await ToolService.submittedSuccess(ids, rsp.data.id)
      if (rsp.code === 200) {
        message.success("操作成功！").then()
      } else {
        message.warning(rsp.message).then()
      }
    } catch (e) {
      showErr(e)
    } finally {
      setIsModalVisible(false);
      setFilingRow(undefined)
      refreshData(filingStore.filingReq)
      setSelectedRowKeys([])
    }
  }

  const handleOk = (open: boolean) => {
    const row = {...filingRow!}
    basicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)
      setConfirmLoading(true)

      if (open) {

        // 将row和按钮先存起来
        setSubmitRow(row)

        // 弹窗选择处理人
        setProcessRecordsIsOpen(true)
        setProcessRecordsRow(undefined)

      } else {

        const user = securityStore.getLoggedUser()

        // 默认处理人员是自己
        if (!row.id) {
          row.personResponsible = user._id
          row.personResponsibleName = user.name
        }

        try {
          await insertOrUpdate(row)
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }

      }

    }).catch(logger.warn)
  }

  const handleSubmit = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)

      const user = securityStore.getLoggedUser()
      const btnText = row.status

      // 当类型是撤回时 默认办理人员为提交人(自己)
      if (btnText === FORM_REVOCATION_TEXT) {
        row.personResponsible = user._id || submitterId
        row.personResponsibleName = user.name
      }

      // 主表
      const subRow = {...submitRow}
      subRow.personResponsible = row.personResponsible
      subRow.personResponsibleName = row.personResponsibleName
      subRow.status = APPROVE_FORM_TRANS_STATE[btnText] || subRow.status
      // 主表在撤回、退回、变更时->更新局部数据
      if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在转交、提交时->更新局部数据
      if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在提交时->更新局部数据
      if (btnText === FORM_COMMIT_TEXT) {
        // subRow.faTime = new Date().toLocaleDateString().replace(/\//g, '-')
      }
      // 主表在结办时->更新局部数据
      if (btnText === FORM_CLOSE_TEXT) {

      }

      // 办理记录表
      row.type = TableName
      row.businessId = subRow.id // 有可能会没值
      row.status = btnText
      row.userId = user._id
      row.userName = user.name
      row.personResponsible = undefined
      row.personResponsibleName = undefined
      if (btnText === FORM_COMMIT_TEXT) {
        const date = new Date()
        row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      } else {
        row.process = latestProcess
      }
      if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
        row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
      } else {
        row.conca = `${user.name}${btnText}了。`
      }

      // 判断是否 先新增主表 后新增记录和通知表
      if (subRow.id) {

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.faBatchNumber,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "文件存档",
          wnDesc: `您参与办理的文件存档（${subRow.faBatchNumber}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      } else {

        let subRsp: ResponseEntity<FilingRow>
        try {
          subRsp = await insertOrUpdate(subRow)
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }

        if (subRsp.data) {
          // 重新赋值再提交
          row.businessId = subRsp.data.id
          await ProcessRecordsService.addProcessRecords(row)
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: subRsp.data.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const notificationScopeUserId = rsp.items
            .map(o => o.userId)
            .concat(row.person)
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(id => id !== user._id) // 过滤出不是自己的数据
            .join(',') || ''
          const notificationScopeUserName = rsp.items
            .map(o => o.userName)
            .concat((row.personName || '').split(','))
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(name => name !== user.name) // 过滤出不是自己的数据
            .join(',') || ''
          await processStore.newAssignmentNotice({
            businessId: subRsp.data.id,
            businessCode: subRsp.data.faBatchNumber,
            cooperationUnit: (subRsp.data.cooperationUnit as string).split(',')[0] || 'N/A',
            notificationScopeUserId,
            notificationScopeUserName,
            personResponsible: subRsp.data.personResponsible,
            personResponsibleName: subRsp.data.personResponsibleName,
            responsibilityProject: subRsp.data.responsibilityProject,
            type: "文件存档",
            wnDesc: `您参与办理的文件存档（${subRsp.data.faBatchNumber}），${row.conca} 请知悉！`,
            wnStatus: "未读"
          })
        } else {
          message.warning('主表保存成功，办理记录表和任务通知表提交失败')
        }

      }
      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  /**
   * 操作记录按钮
   */
  const handleProcessRecordsClick = async (record: FilingRow) => {
    const rsp = await ProcessRecordsService.getProcessRecords({
      businessId: record.id,
      pageNum: 1,
      perPage: 999,
      type: TableName
    })
    if (!rsp) {
      message.warning('查无数据')
      return
    }
    setProcessRecordsDataSource(rsp.items)
    setProcessRecordsOpen(true)
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        isHiddenOptions={props.isHiddenOptions}
        updateCount={updateCount}
        collapsed={filingStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        selectedRowKeys={selectedRowKeys}
        handleShowColumnReset={handleShowColumnReset}
        handleDeleteBatch={handleDeleteBatch}
        handleAdd={handleAdd}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={props.isModalSelect ? columns.filter(o => o.key !== 'action') : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}
        items={['update', 'all'].some(o => (securityStore.getPermissions() || []).includes(o)) && [
          <Button
            block
            className={'text-align-left'}
            type={"text"}
            onClick={() => handleEditBatch()}>
            <EditOutlined/>
            批量{ EDIT_TEXT }
          </Button>,
          <CustomUpload
            updateSize={20}
            isLocalFileList
            uploadType={"all"}
            listType={"picture"}
            status={'1'}
            onChange={handleBatchUploadChange}
            multiple={true}>
            <Button type={'text'} icon={<UploadOutlined/>}>批量上传</Button>
          </CustomUpload>,
        ]}></HeaderActionCom>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={dataSource}
        columns={renderColumns}
        size={"small"}
        bordered
        loading={filingStore.filingDatasource.loading || confirmLoading}
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys, columnWidth: 50}}/>

      { /*操作记录表*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsOpen}
        width={LARGE_MODAL_WIDTH_OTHER}
        onCancel={() => setProcessRecordsOpen(false)}
        destroyOnClose
        footer={null}>
        <ProcessRecords
          isHiddenOptions
          dataSource={processRecordsDataSource}
          isModalSelect
          locationPathname={'/processRecords'}/>
      </CustomDraggableModal>

      { /*操作记录表单*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsIsOpen}
        width={LARGE_MODAL_WIDTH}
        destroyOnClose
        onCancel={() => {
          setProcessRecordsIsOpen(false)
          setConfirmLoading(false)
        }}
        onOk={handleSubmit}>
        <ProcessRecordsBasicInfo
          tableName={TableName}
          businessId={filingRow?.id}
          submitterId={submitterId}
          userId={filingRow?.personResponsible}
          status={filingRow?.status}
          row={processRecordsRow}
          ref={processRecordsBasicInfo}/>
      </CustomDraggableModal>

      <CustomForm
        title={'文件存档'}
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={isModalVisible}
        submitterId={submitterId}
        confirmLoading={confirmLoading}
        userId={filingRow?.personResponsible}
        status={filingRow?.status}
        extraDisabled={!filingRow?.id}
        extraOnClick={() => handlePrint(filingRow)}
        item={[
          // {
          //   key: 'handleCostExpendClick',
          //   icon: <RetweetOutlined/>,
          //   hidden: filingRow?.status !== CLOSE,
          //   onClick: () => handleCostExpendClick(filingRow)
          // },
          {
            key: 'handleProcessRecordsClick',
            icon: <FieldTimeOutlined/>,
            disabled: !filingRow?.id,
            onClick: () => handleProcessRecordsClick(filingRow)
          },
        ]}>
        <BasicInfo row={{...filingRow!}} ref={basicInfo} isView={isView}/>
      </CustomForm>

      <Drawer
        title={<div className="text-align-center">文件存档-批量{ EDIT_TEXT }</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isBatchModalVisible}
        destroyOnClose
        onClose={handleBatchCancel}>
        <BasicInfoBatch row={{...filingRow!}} ref={basicInfoBatch} isView={isBatchView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleBatchCancel}>取消</Button>
          {
            !isView && <Button
              type="primary" className={'margin-left-xs'} onClick={handleBatchOk}
              loading={confirmLoading}>确定</Button>
          }
        </div>
      </Drawer>

      <Drawer
        title={<div className="text-align-center">{ otherFilingRow?.faType }</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isOpen}
        onClose={() => setIsOpen(false)}>
        <OtherBasicInfo type={otherFilingRow?.faType} code={otherFilingRow?.faCode} ref={basicInfoOther}/>
        <div className={"drawer-bottom"}>
          <Button onClick={() => setIsOpen(false)}>取消</Button>
          <Button
            type="primary" className={'margin-left-xs'} onClick={handleOtherPrint}
            loading={confirmLoading}>打印</Button>
        </div>
      </Drawer>

      <WindowPrint
        isHiddenTime
        title={printTitle}
        contentList={contentList}
        isModalOpen={isModalOpen}
        handleCancel={() => setIsModalOpen(false)}/>
    </div>
  )
}))
