import React, { useEffect } from "react";
import { Col, DatePicker, Form, Input, InputNumber, Row } from "antd";
import { FormInstance } from "antd/es";
import { observer } from "mobx-react";
import { BonusRow } from "@services/dto/bonus";
import { CascaderPerson } from "@/components/CascaderPerson";
import { getUploadStringToArray } from "@/common/utils";
import dayjs from "dayjs";
import { DATE_FMT } from "@/config";
import { CustomUpload, normFile } from "@/components/CustomUpload";

export interface PropsType {
  row: BonusRow
}

export const BonusBasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    const row = { ...props.row }
    if (row.id) {
      if (typeof row.userCode === 'string') {
        row.userCode = row.userCode.split(",")
      }
      if (typeof row.file === 'string') {
        row.file = getUploadStringToArray(row.file);
      }
      row.time = dayjs(row.time)
    }
    form.setFieldsValue(row);
  }, [props.row])


  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ status: 1, isVirtualUser: 0 }}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Form.Item
            label="员工"
            name="userCode"
            valuePropName={"person"}
            rules={[{ required: !props.row.id, message: '请选择员工!' }]}>
            <CascaderPerson disabled={!!props.row.id} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="奖励类型"
            name="type"
            rules={[{ required: true, message: '请输入奖励类型!' }]}>
            <Input placeholder="请输入奖励类型" className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="金额"
            name="amount"
            rules={[{ required: true, message: '请输入金额!' }]}>
            <InputNumber placeholder="请输入金额" precision={0} className={"width-100-percentage"} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="奖励时间"
            name="time"
            rules={[{ required: true, message: '奖励时间!' }]}>
            <DatePicker placeholder={"奖励时间"} className={'width-100-percentage'} format={DATE_FMT} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="附件"
            name="file"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            extra={"支持文件(pdf,doc,docx),10M以内,最大上传1个文件"}
            rules={[{ required: false, message: '请上传附件!' }]}>
            <CustomUpload updateSize={20}
              maxCount={1}
              uploadType={"application"}
              accept={"application/*"}
              listType={"picture"}
              multiple={false} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="备注"
            name="remark"
            rules={[{ required: false, message: '请输入备注!' }]}>
            <Input.TextArea placeholder="请输入备注" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
