import {PageParam, SubsidyRow} from "@services/dto/subsidy";
import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";

export class SubsidyService {
  static async getSubsidies(req: PageParam): Promise<Page<SubsidyRow>> {
    return await apiV1.get("/subsidies", {params: req})
  }

  static async addSubsidies(row: SubsidyRow): Promise<ResponseEntity<SubsidyRow>> {
    return await apiV1.post("/subsidies", {...row})
  }

  static async updateSubsidies(row: SubsidyRow): Promise<ResponseEntity<SubsidyRow>> {
    return await apiV1.put("/subsidies", {...row})
  }

  static async deleteSubsidies(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/subsidies", {data: ids})
  }
}
