import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Drawer, FormInstance, Modal, Row, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { DEFAULT_MODAL_WIDTH, DATE_FMT } from "@/config";
import { addRow, ContractRow, ContractSearchReq, updateRow } from "@services/dto/contract";
import { ContractBasicInfo } from "@modules/HR/Components/ContractBasicInfo";
import { allStores } from "@/stores";
import { ContractService } from "@/services/ContractService";
import message from "antd/lib/message";
import { logger, showErr } from "@/common/utils";
import { CascaderPerson } from "@/components/CascaderPerson";
import { PersonCascader } from "@/services/dto/talent";

export const Contract = observer(() => {
  const { contractStore } = allStores
  const basicInfo = React.createRef<FormInstance>();
  const [person, setPerson] = useState<string[]>([])
  const [dataSource, setDataSource] = useState<ContractRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [contractRow, setContractRow] = useState<ContractRow>()
  const [searchReq, setSearchReq] = useState<ContractSearchReq>({
    pageNum: 1,
    perPage: 10,
    userId: ''
  })

  const columns: ColumnsType<ContractRow> = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: '工号',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
    },
    {
      title: '部门',
      dataIndex: 'department',
      key: 'department',
      align: 'center'
    },
    {
      title: '合同起始日',
      dataIndex: 'startTime',
      key: 'startTime',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <div>{ new Date(text).toLocaleDateString() }</div>
        </Space>
      )
    },
    {
      title: '合同到期日',
      dataIndex: 'endTime',
      key: 'endTime',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <div>{ new Date(text).toLocaleDateString() }</div>
        </Space>
      )
    },
    {
      title: '合同公司',
      dataIndex: 'companyName',
      key: 'companyName',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          { record.company.companyName }
        </Space>
      )
    },
    {
      title: '合同类型',
      dataIndex: 'type',
      key: 'type',
      align: 'center'
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record, index) => (
        <Space>
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined />}
            onClick={() => { handleEdit(record) }}>修改</Button>
          <Button shape="round" className={"delete-btn"} size={"middle"}
            icon={<DeleteOutlined />} onClick={() => { handleDeleteBatch(record) }}>删除</Button>
        </Space>
      )
    }
  ];

  useEffect(() => {
    refreshData(searchReq)
  }, [])

  useEffect(() => {
    setDataSource(contractStore.contractDatasource.data?.items!)
    setTotal(contractStore.contractDatasource.data?.total!)
  }, [contractStore.contractDatasource])

  const refreshData = (req: ContractSearchReq) => {
    contractStore.searchContract(req)
  }

  const handleAdd = () => {
    setContractRow(undefined)
    setIsModalVisible(true);
  }

  const handleEdit = (record: ContractRow) => {
    setContractRow(record)
    setIsModalVisible(true);
  }

  const handleDeleteBatch = (record: ContractRow | undefined) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        ContractService.deleteContract(ids).then(() => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(searchReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const onSearch = () => {
    const req = { ...searchReq };
    req.pageNum = 1
    if (person) {
      req.userId = person[3]
    } else {
      req.userId = ''
    }
    setSearchReq(req)
    refreshData(req)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = { ...searchReq };
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonChange = (value: string[], selectedOptions: PersonCascader[]) => {
    setPerson(value)
    return value
  }

  const actionThen = () => {
    message.success("操作成功！")
    setIsModalVisible(false);
    setContractRow(undefined)
    refreshData(searchReq)
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setContractRow(undefined)
  }

  const handleOk = () => {
    const row = { ...contractRow! }
    basicInfo.current!.validateFields().then(values => {
      if (values.startTime) {
        values.startTime = values.startTime.format(DATE_FMT);
      }
      if (values.endTime) {
        values.endTime = values.endTime.format(DATE_FMT);
      }
      if (values.person) {
        values.userId = values.person[3] || values.person[0];
      }
      Object.assign(row, values)
      setConfirmLoading(true)
      if (row.id) {
        const reqRow: updateRow = { ...row }
        ContractService.updateContract(reqRow).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        const reqRow: addRow = { ...row }
        ContractService.addContract(reqRow).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  }

  return (
    <div className={"institution"}>
      <Row>
        <Col span={8}>
          <CascaderPerson onChange={handlePersonChange}
          />
        </Col>
        <Col span={16} className={"text-align-right"}>
          <Button icon={<SearchOutlined />} size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={() => onSearch()}>查询</Button>
          <Button icon={<DeleteOutlined />} size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={() => handleDeleteBatch(undefined)}>删除</Button>
          <Button icon={<PlusOutlined />} type={"primary"} size={"middle"} shape={"round"}
            onClick={handleAdd}>新增</Button>
        </Col>
      </Row>

      <Card className={"margin-top-sm"}>
        <Table
          rowKey={"id"}
          dataSource={dataSource}
          columns={columns}
          size={"middle"}
          loading={confirmLoading}
          pagination={{
            total: total,
            position: ['bottomCenter'],
            pageSize: searchReq.perPage,
            current: searchReq.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`,
            onChange: handlePageChange
          }}
          rowSelection={{ selectedRowKeys, onChange: setSelectedRowKeys }} />
      </Card>

      <Drawer
        title={<div className="text-align-center">人事合同</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        onClose={handleCancel}>
        <ContractBasicInfo row={{ ...contractRow! }} ref={basicInfo} />

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          <Button type="primary" onClick={handleOk} loading={confirmLoading} className={"margin-left-xs"}>确定</Button>
        </div>
      </Drawer>
    </div>
  )
})
