import React, {useEffect, useState} from "react";
import {DatePicker, Select} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {CustomAuthHeaderAction, PropsType as ParentPropsType} from "@components/CustomAuthHeaderAction";
import {ContractListReq} from "@services/dto/contractList";
import dayjs from "dayjs";
import {SelectContractList} from "@components/SelectContractList";
import {CustomInputNumber} from "@components/CustomInputNumber";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectFiling} from "@components/SelectFiling";
import {SelectProject} from "@components/SelectProject";
import {Any} from "@common/types";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {ContractListService} from "@services/ContractListService";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {SelectDict} from "@components/SelectDict";

export interface PropsType extends ParentPropsType {
  setConfirmLoading?: (loading: boolean) => void
  refreshData?: (req: any) => void
  isContractList?: boolean
  updateCount?: number
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<ContractListReq>({
    pageNum: 1,
    perPage: 10,
    statisticsCategory: '收入结算'
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: ContractListReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleClDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, [start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.clDate1 = start
    req.clDate2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleClEndDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, [start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.clEndDate1 = start
    req.clEndDate2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleClDocDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, [start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.clDocDate1 = start
    req.clDocDate2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleParentIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.parentId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleClPartiesToContractChange = (values: string[]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.clPartiesToContract = values.join(',')
    setSearchReq(req)
    refreshData(req)
  }

  const handleClTypeChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.clType = value
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonResponsibleChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleOrganizersUserIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.organizersUserId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (values: string[]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = values.join(',')
    setSearchReq(req)
    refreshData(req)
  }

  const handleClFileChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.clFile = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleClStatusChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.clStatus = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleClAmountChange = ([start, end]: string[]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.clAmount1 = start
    req.clAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleCsEndTypeChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.csEndType = value
    setSearchReq(req)
    refreshData(req)
  }

  const contractListCollapsedChildren: React.ReactNode[] = [
    <DatePicker.RangePicker
      placeholder={['制单开始日期', '制单结束日期']}
      onChange={handleClDocDateChange}
      value={searchReq?.clDocDate1 ? [dayjs(searchReq?.clDocDate1), dayjs(searchReq?.clDocDate2)] : [undefined, undefined]}
      allowClear
      className={"width-100-percentage"}/>,
    <SelectContractList
      className={'width-100-percentage'}
      placeholder={"请选择上级合同"}
      value={searchReq?.parentId}
      onChange={handleParentIdChange}
      allowClear/>,
    <SelectCooperativeUnit
      defaultItems={[]}
      value={searchReq?.clPartiesToContract ? searchReq?.clPartiesToContract.split(',') : undefined}
      onChange={handleClPartiesToContractChange}
      mode={'multiple'}
      allowClear
      placeholder={'请选择合同当事人'}
      fieldNames={{value: 'cuName', label: 'cuName'}}/>,
    <SelectDict
      updateCount={props.updateCount}
      className={'width-100-percentage'}
      allowClear
      value={searchReq?.clType}
      onChange={handleClTypeChange}
      code={'cl_type'}
      placeholder={'请输入合同类别'}/>,
    <CustomInputNumber
      placeholder={['约定最小金额', '约定最大金额']}
      value={[searchReq?.clAmount1, searchReq?.clAmount2]}
      className={'width-100-percentage'}
      onSearch={handleClAmountChange}/>,
    <DatePicker.RangePicker
      placeholder={['签订开始日期', '签订结束日期']}
      value={searchReq?.clDate1 ? [dayjs(searchReq?.clDate1), dayjs(searchReq?.clDocDate2)] : [undefined, undefined]}
      onChange={handleClDateChange}
      allowClear
      className={"width-100-percentage"}/>,
    <DatePicker.RangePicker
      placeholder={['合同关闭日期', '合同关闭日期']}
      value={searchReq?.clEndDate1 ? [dayjs(searchReq?.clEndDate1), dayjs(searchReq?.clDocDate2)] : [undefined, undefined]}
      onChange={handleClEndDateChange}
      allowClear
      className={"width-100-percentage"}/>,
    <SelectProject
      placeholder={"请选择责任部门"}
      value={searchReq?.responsibilityProject}
      onChange={handleResponsibilityProjectChange}
      allowClear
      fieldNames={{value: 'name', label: 'name'}}/>,
    <SelectCooperativeUnit
      defaultItems={[]}
      value={searchReq?.cooperationUnit ? searchReq?.cooperationUnit.split(',') : undefined}
      onChange={handleCooperationUnitChange}
      mode={'multiple'}
      allowClear
      fieldNames={{value: 'cuName', label: 'cuName'}}/>,
    <SelectFiling
      placeholder={"请选择存档批号"}
      allowClear
      isFaBatchNumberGroupBy
      fieldNames={{label: 'faBatchNumber', value: 'faBatchNumber'}}
      onChange={handleClFileChange}
      value={searchReq?.clFile}
      className={'width-100-percentage'}
      isHiddenOptions/>,
    <SelectPersonCharge
      onChange={handlePersonResponsibleChange}
      className={"width-100-percentage"}
      placeholder={"请选择办理人员"}
      value={searchReq?.personResponsible}
      allowClear/>,
    <SelectPersonCharge
      onChange={handleOrganizersUserIdChange}
      className={"width-100-percentage"}
      placeholder={"请选择主办人员"}
      value={searchReq?.organizersUserId}
      allowClear/>,
    <SelectProcessStatus
      onChange={handleClStatusChange}
      allowClear
      value={searchReq?.clStatus}
      placeholder={'请选择办理进度'}
      className={"width-100-percentage"}/>,
  ]

  const contractStatisticsCollapsedChildren: React.ReactNode[] = [
    <SelectDict
      updateCount={props.updateCount}
      className={'width-100-percentage'}
      allowClear
      value={searchReq?.clType}
      onChange={handleClTypeChange}
      code={'cl_type'}
      placeholder={'请输入合同类别'}/>,
    <SelectDict
      onChange={handleCsEndTypeChange}
      code={'payment_typee'}
      allowClear
      value={searchReq?.csEndType}
      placeholder={'请输入结算类别'}
      className={"width-100-percentage"}/>
  ]

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    if (props.setConfirmLoading) {
      props.setConfirmLoading(true);
    }
    ContractListService.batchRefresh().then(() => {
      const req = {...searchReq}
      req.pageNum = 1
      req.perPage = 10
      req.keyword = undefined
      // req.cooperationUnit = undefined
      // req.responsibilityProject = undefined

      req.clDocDate1 = undefined // 合同约定金额
      req.clDocDate2 = undefined
      req.clAmount1 = undefined // 制单日期
      req.clAmount2 = undefined
      req.clDate1 = undefined // 合同签订日期
      req.clDate2 = undefined
      req.clEndDate1 = undefined // 合同关闭日期
      req.clEndDate2 = undefined
      req.personResponsible = undefined // 责任人
      req.personResponsibleName = undefined // 责任人
      req.organizersUserId = undefined // 主办人员
      req.organizersUserName = undefined // 主办人员名
      req.clType = undefined // 合同类别
      req.clFile = undefined // 合同类别
      req.parentId = undefined // 上级合同
      req.clStatus = undefined // 办理进度
      req.clPartiesToContract = undefined // 合同当事人

      setSearchReq(req)
      refreshData(req)
    }).finally(() => {
      if (props.setConfirmLoading) {
        props.setConfirmLoading(false);
      }
    })
  }

  return (
    <>
      <CustomAuthHeaderAction
        locationPathname={props.locationPathname}
        size={props.size}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.isContractList || props.refreshData ? handleSearchReqReset : undefined}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        items={props.items}
        isShowKeyword={!props.refreshData}
        collapsedChildren={props.refreshData ? (props.isContractList ? contractListCollapsedChildren : contractStatisticsCollapsedChildren) : []}/>
    </>
  )
}))
