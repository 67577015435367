import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom'
import {Image, Layout, Menu, MenuProps, TabsProps} from 'antd';
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import IconFont from "@components/IconFont";
import {SiteLayoutRight} from "@/layout/Components/SiteLayoutRight";
import './BasicLayout.less'
import {CompanyRow} from "@services/dto/company";
import {TabsView} from "@/layout/Components/TabsView";
import {Any} from "@common/types";
import {HeaderSearch} from "@/layout/Components/HeaderSearch";
import {IS_SHOW_ICON} from "@/config";
import logo from "@/assets/images/logo.png"
import {
  ClusterOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  FileWordOutlined,
  HomeOutlined,
  IdcardOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MoneyCollectOutlined,
  PayCircleOutlined,
} from '@ant-design/icons';
import {RoleAndMenuType} from "@services/dto/roleManage";
import {MenuPermissionsRow} from "@services/dto/menuPermissions";

const {Header, Sider, Content} = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const rootSubmenuKeys = [
  'work', 'project', 'worker', 'hr',
  'financial', 'marketing', 'purchase',
  'materials', 'statistical', 'systemSet'
];

export interface PropsType {
  children: Any
}

export const BasicLayout = observer((props: PropsType) => {
  const [collapsed, setCollapsed] = useState(false);
  const {securityStore, companyStore, tabsViewStore, screenResolutionStore} = allStores;
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname

  const [items, setItems] = useState<MenuItem[]>([])
  const [title, setTitle] = useState<String>("")
  const [current, setCurrent] = useState<string>(pathname);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [companyRowData, setCompanyRowData] = useState<CompanyRow>()
  const [isAdmin, setIsAdmin] = useState<boolean>(true)

  const allTabsItems: MenuItem[] = []
  const getItem = (
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem => {
    const res = {
      key,
      icon: IS_SHOW_ICON ? icon : null,
      children,
      label,
      type,
    }
    allTabsItems.push(res)
    return res;
  }

  const companyItems: MenuItem[] = [
    getItem('综合管理', 'project', <ClusterOutlined/>, [
      getItem('工作管理', 'goal', <ClusterOutlined/>, [
        getItem('工作目标', '/empty/goal', <IdcardOutlined/>),
        getItem('工作日程', '/workSchedule', <IdcardOutlined/>),
        getItem('工作任务', '/taskSupervision', <IdcardOutlined/>),
        getItem('工作通知', '/process', <IdcardOutlined/>),
        getItem('工作统计', '/jobAccount', <IconFont type={"hr-gongren"} className={"icon-fontsize"}/>),
      ]),
      getItem('组织管理', 'tissue', <ClusterOutlined/>, [
        getItem('部门管理', '/project', <IconFont type={"hr-xiangmu"} className={"icon-fontsize"}/>),
        getItem('岗位管理', '/workPost', <IdcardOutlined/>),
        getItem('流程管理', '/ruleRegulation', <FileWordOutlined/>),
        getItem('会议管理', '/conferenceRecord', <DashboardOutlined/>),
      ]),
      getItem('印章管理', '/sealManagement', <IdcardOutlined/>, [
        getItem('印章审批', '/sealApproval', <IdcardOutlined/>),
        getItem('用印申请', '/sealApplication', <IdcardOutlined/>),
      ]),
      getItem('文档管理', 'document', <ClusterOutlined/>, [
        getItem('文件审批', '/documentApproval', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
        getItem('文件分发', '/fileDistribution', <DashboardOutlined/>),
        getItem('文件存档', '/filing', <DatabaseOutlined/>),
        getItem('文档查询', '/empty/licenseBorrowing', <IdcardOutlined/>),
        getItem('文件处置', '/company/pages', <DashboardOutlined/>),
      ]),
      // getItem('事项待办', '/toDos', <SoundOutlined />),
      // getItem('企业列表', '/company', <HomeOutlined/>),
      // getItem('部门列表', '/institution', <ApartmentOutlined />),
    ]),
    getItem('项目管理', 'worker', <HomeOutlined/>, [
      getItem('项目审批', '/projectStructure', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      getItem('项目投资', '/empty/investment', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      getItem('项目融资', '/empty/financingManagement', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      getItem('项目协作', '/projectCollaboration', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      // getItem('项目协作', '/cooperativeUnit', <HomeOutlined/>),
      // getItem('项目日志', '/projectLog', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      // getItem('物资清单', '/supplies', <IconFont type={"hr-icon-import"}/>),
      // getItem('记工记账', '/empty/bookkeeping', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      // getItem('工效管理', '/empty/ergonomics', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
    ]),
    getItem('人事管理', 'hr', <HomeOutlined/>, [
      getItem('员工关系', 'staff', <HomeOutlined/>, [
        getItem('员工入职', '/induction', <IconFont type={"hr-ruzhi"} className={"menu-icon"}/>),
        getItem('员工转正', '/empty/become', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
        getItem('员工调岗', '/personnel', <IconFont type={"hr-hrrenshirenshiguanli380"} className={"icon-fontsize"}/>),
        getItem('员工离职', '/quit', <IconFont type={"hr-lizhirenyuan"} className={"menu-icon"}/>),
        getItem('员工定薪', '/empty/salaryStandard1', <IconFont type={"hr-kaoqin"} className={"icon-fontsize"}/>),
        getItem('员工调薪', '/empty/salaryStandard2', <IconFont type={"hr-kaoqin"} className={"icon-fontsize"}/>),
        getItem('员工合同', '/contract', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
        getItem('员工档案', '/archives', <IconFont type={"hr-record-full"} className={"icon-fontsize"}/>),
      ]),
      getItem('考勤假期', 'attendanceLeave', <IconFont type={"hr-kaoqinshenpi"} className={"icon-fontsize"}/>, [
        getItem('考勤记录', '/attendance', <IconFont type={"hr-kaoqin"} className={"icon-fontsize"}/>),
        getItem('假期记录', '/empty/vacation', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
        getItem('打卡设置', '/punchClock', <IconFont type={"hr-kaoqinshenpi"} className={"icon-fontsize"}/>),
      ]),
      getItem('薪酬绩效', 'compensation', <IconFont type={"hr-pay-center-salary-adjustment"} className={"icon-fontsize"}/>, [
        getItem('工资计划', '/emolumentPlan', <MoneyCollectOutlined/>),
        getItem('工资总表', '/salary', <IconFont type={"hr-kaoqin"} className={"icon-fontsize"}/>),
        getItem('基本工资', '/basicSalary', <IconFont type={"hr-kaoqin"} className={"icon-fontsize"}/>),
        getItem('加班工资', '/overtimeSalary', <IconFont type={"hr-kaoqin"} className={"icon-fontsize"}/>),
        getItem('绩效奖金', '/bonus', <IconFont type={"hr-kaoqin"} className={"icon-fontsize"}/>),
        getItem('补贴津贴', '/subsidy', <IconFont type={"hr-kaoqin"} className={"icon-fontsize"}/>),
        getItem('工资扣减', '/deduction', <IconFont type={"hr-kaoqin"} className={"icon-fontsize"}/>),
        getItem('五险一金', '/insurance', <IconFont type={"hr-kaoqin"} className={"icon-fontsize"}/>),
        getItem('工资发放', '/payroll', <IconFont type={"hr-kaoqin"} className={"icon-fontsize"}/>),
        // getItem('社保缴纳', '/socialSecurityPayment', <IconFont type={"hr-kaoqin"} className={"icon-fontsize"}/>),
        // getItem('员工薪资明细', '/monthPersonSalary', <IconFont type={"hr-kaoqin"} className={"icon-fontsize"}/>),
        // getItem('管理员薪资明细', '/monthAdminSalary', <IconFont type={"hr-kaoqin"} className={"icon-fontsize"}/>),
        // getItem('预支工资', '/advance', <IconFont type={"hr-gongziguanli"} className={"icon-fontsize"}/>),
        // getItem('奖惩记录', '/rewardPunishment', <IconFont type={"hr-jiangcheng"} className={"icon-fontsize"}/>),
      ]),
      getItem('人才招聘', 'recruitment', <IconFont type={"hr-zhaopin"} className={"icon-fontsize"}/>, [
        getItem('招聘公告', '/recruitments', <IconFont type={"hr-gongshi"} className={"icon-fontsize"}/>),
        getItem('人才社区', '/talent', <IconFont type={"hr-qiyerencaiku_hua"} className={"icon-fontsize"}/>),
      ]),
      getItem('培训认证', 'train', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>, [
        getItem('在线培训', '/empty/onlineTrain', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
        getItem('在线考试', '/empty/onlineExamination', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      ]),
      getItem('职业教育', '/empty/education', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
    ]),
    getItem('财务管理', 'financial', <IconFont type={"hr-zhaopin"} className={"icon-fontsize"}/>, [
      getItem('预算管理', 'budget', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>, [
        getItem('预算审批', '/budgetManagement', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
        getItem('预算统计', '/empty/projectBudget', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      ]),
      getItem('合同管理', 'contract', <IconFont type={"hr-zhaopin"} className={"icon-fontsize"}/>, [
        getItem('合同审批', '/contractList', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
        getItem('合同结算', '/contractSettlement', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
        getItem('合同统计', '/contractStatistics', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      ]),
      getItem('费用管理', 'costManage', <IconFont type={"hr-zhaopin"} className={"icon-fontsize"}/>, [
        getItem('费用申请', '/costApplication', <MoneyCollectOutlined/>),
        getItem('费用支出', '/costExpend', <MoneyCollectOutlined/>),
        getItem('费用报销', '/costReimbursement', <MoneyCollectOutlined/>),
        getItem('费用统计', '/employeeFeePay', <MoneyCollectOutlined/>),
      ]),
      getItem('税务管理', 'tax', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>, [
        getItem('税费计划', '/taxPlan', <MoneyCollectOutlined/>),
        getItem('税费核算', '/taxAccounting', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
        getItem('税费缴纳', '/finalSettlement', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
        getItem('增值税统计表', '/invoiceRegistration', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
        getItem('税费统计', '/taxFeeStatistics', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      ]),
      getItem('票据管理', 'bill', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>, [
        getItem('员工借款', '/staffLoan', <PayCircleOutlined/>),
        getItem('发票登记', '/invoiceRegister', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
        getItem('销货清单', '/accountSales', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      ]),
      getItem('资金管理', 'funds', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>, [
        getItem('资金审批', '/fundApproval', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
        getItem('资金出纳', '/bankStatement', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
        getItem('资金账户', '/bankAccount', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
        getItem('往来统计', '/transactionStatistics', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
        getItem('余额统计', '/bankAccountStatistics', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
        getItem('收支统计', '/incomeExpenditureStatistics', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      ]),

      // getItem('商务合同', '/commercialContract', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
    ]),
    getItem('物资管理', 'materials', <IconFont type={"hr-zhaopin"} className={"icon-fontsize"}/>, [
      getItem('物资计划', '/empty/materialsPlan', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      getItem('物资入库', '/empty/outInBound', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      getItem('物资出库', '/materialsOutBound', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      getItem('物资清单', '/materialList', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
    ]),
    getItem('营销管理', 'marketing', <IconFont type={"hr-zhaopin"} className={"icon-fontsize"}/>, [
      getItem('营销立项', '/empty/marketingProjectApproval', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      getItem('营销合同', '/empty/marketingContract', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      getItem('客户管理', '/empty/customerManagement', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      // getItem('合同台账', '/marketingContract', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      // getItem('收支台账', '/revenueExpenditure', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      // getItem('结算申请', '/marketingSettleApply', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
    ]),
    getItem('采购管理', 'purchase', <IconFont type={"hr-zhaopin"} className={"icon-fontsize"}/>, [
      getItem('采购立项', '/empty/procurementProjectApproval', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      getItem('采购合同', '/empty/procurementContract', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      getItem('供应商', '/empty/supplier', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      // getItem('合同台账', '/purchaseContract', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      // getItem('收支台账', '/revenueExpenditure', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      // getItem('结算申请', '/purchaseSettleApply', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
    ]),
    getItem('施工管理', 'work', <HomeOutlined/>, [
      getItem('造价管理', 'construction', <IconFont type={"hr-zhaopin"} className={"icon-fontsize"}/>, [
        getItem('施工日志', '/constructionLog', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      ]),
    ]),
    getItem('统计分析', 'statistical', <IconFont type={"hr-zhaopin"} className={"icon-fontsize"}/>, [
      getItem('人员异动', '/empty/turnover', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      getItem('工种人数', '/profession', <IconFont type={"hr-gongren"} className={"icon-fontsize"}/>),
      getItem('表单办理进度', '/formStatus', <IconFont type={"hr-gongren"} className={"icon-fontsize"}/>),
    ]),
    getItem('系统设置', 'systemSet', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>, [
      getItem('基本信息', '/empty/enterpriseInfo', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      getItem('用户管理', '/organizational', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      getItem('角色管理', '/roleManage', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      getItem('菜单管理', '/menuPermissions', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      getItem('表单管理', '/empty/expenseAccountingRules', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
      getItem('字典管理', '/dictManagement', <IconFont type={"hr-zuzhi"} className={"icon-fontsize"}/>),
    ]),
  ];

  /**
   * 初始化
   */
  useEffect(() => {
    const user = securityStore.getLoggedUser()
    const roleAndMenus: RoleAndMenuType = JSON.parse(localStorage.getItem('role-and-menus'))

    setIsAdmin(true)
    setTitle((roleAndMenus?.roleMS?.map(o => o.name) || []).join(','))
    if (localStorage.getItem('zhr')) {
      setItems(companyItems)
    } else {
      setItems(recursionGetAuthItems(roleAndMenus?.menus || []))
    }

    // 判断是否有公司id
    if (user?.companyId) {
      companyStore.getCompanyById(user?.companyId);
    }

    tabsViewStore.setAllTabsItems(allTabsItems as TabsProps['items'])

    updateScreenResolution();// 组件加载时获取初始的屏幕分辨率
    window.addEventListener('resize', updateScreenResolution);// 监听窗口大小的变化，并更新屏幕分辨率
    return () => {
      window.removeEventListener('resize', updateScreenResolution);// 组件卸载时移除事件监听器
    };
  }, []);

  /**
   * 路由变化
   */
  useEffect(() => {
    setCurrent(location.pathname)
  }, [location])

  /**
   *
   */
  useEffect(() => {
    setCompanyRowData(companyStore.companyRowData.data?.data)
  }, [companyStore.companyRowData.data?.data])

  /**
   * 定义一个函数来更新屏幕分辨率
   */
  const updateScreenResolution = () => {
    if (window.innerWidth !== screenResolutionStore.screenResolution.width || window.innerHeight !== screenResolutionStore.screenResolution.height) {
      screenResolutionStore.updateScreenResolution({width: window.innerWidth, height: window.innerHeight});
    }
  };

  /**
   * 菜单类别被点击
   * @param e
   */
  const handleMenuClick: MenuProps['onClick'] = (e) => {
    navigate(e.key)
  };

  /**
   * 菜单的onChange
   * @param keys
   */
  const handleMenuOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  }

  /**
   * 路由面包屑标签onChange
   * @param key
   */
  const handleTabsChange = (key: string) => {
    navigate(key)
  }

  /**
   * 路由面包屑标签 移除事件
   * @param targetKey
   */
  const handleTabsRemove = (targetKey: React.MouseEvent | React.KeyboardEvent | string) => {
    if (!tabsViewStore.tabsItems || tabsViewStore.tabsItems.length === 1) {
      return
    }
    const targetIndex = tabsViewStore.tabsItems.findIndex((pane) => pane.key === targetKey);
    const newPanes = tabsViewStore.tabsItems.filter((pane) => pane.key !== targetKey);
    if (newPanes.length) {
      const {key} = newPanes[targetIndex === newPanes.length ? targetIndex - 1 : targetIndex];
      handleTabsChange(key)
    }
    tabsViewStore.updateTabsItems(newPanes)
  };

  /**
   * 获取权限菜单列表
   */
  const recursionGetAuthItems = (items: MenuPermissionsRow[]): MenuItem[] => {
    let res:MenuItem[] = []
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      // 0是目录 1是菜单 2是按钮
      if ([0, 1].includes(item.type) && item.show) {
        let children: MenuItem[]
        if ([0].includes(item.type) && Array.isArray(item.child) && item.child.length) {
          children = recursionGetAuthItems(item.child)
        }
        res.push(getItem(item.title, item.perms, <MoneyCollectOutlined/>, children ? children : undefined))
      }
    }
    return res
  }

  return (
    <Layout>
      { /*左侧*/ }
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className={`logo ${collapsed ? 'collapsed' : ''}`}>
          <Image height={'78%'} src={logo} preview={false} className={'image-margin'}/>
        </div>
        <div className={`scroll-view ${collapsed ? 'collapsed' : ''}`}>
          <Menu
            inlineIndent={14}
            className={'menu'}
            mode="inline"
            selectedKeys={[current]}
            items={items}
            onClick={handleMenuClick}
            openKeys={openKeys}
            onOpenChange={handleMenuOpenChange}
          />
        </div>
      </Sider>

      { /*右侧*/ }
      <Layout className={`site-layout ${collapsed ? 'collapsed' : ''}`}>
        <Header className="site-layout-header">
          <div className="site-layout-left">
            <div className="site-layout-icon">
              { React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                className: 'trigger',
                onClick: () => setCollapsed(!collapsed),
              }) }
            </div>
            <div className="site-layout-title">{ title }</div>
          </div>
          { isAdmin && <HeaderSearch/> }
          <SiteLayoutRight companyRowData={{...companyRowData!}}/>
        </Header>
        <Content className="site-layout-content">
          <TabsView
            tabsItems={tabsViewStore.tabsItems}
            handleChange={handleTabsChange}
            remove={handleTabsRemove}/>
          <div className={'tabs-content'}>
            { props.children }
          </div>
        </Content>
      </Layout>
    </Layout>
  );
})

