import React, {useEffect} from "react";
import {Col, Form, Input, Row, Cascader, Select, Card, DatePicker} from "antd";
import {allStores} from "@/stores";
import {FormInstance} from "antd/es";
import {RecruitmentReq} from "@services/dto/recruitment";
import {observer} from "mobx-react";
import dayjs from "dayjs";
import {DATE_FMT} from "@/config";

interface PropsType {
  recruitmentReq: RecruitmentReq
}

export const PublicityBasic = observer(React.forwardRef<FormInstance, PropsType>((props, ref) => {
  const {provinceCityDistrictStore, talentStore} = allStores;
  const [form] = Form.useForm()

  useEffect(() => {
    const initRecruitmentReq = async () => {
      const recruitmentReq = {...props.recruitmentReq}
      if (props.recruitmentReq.recruiters) {
        recruitmentReq.recruiters = recruitmentReq.recruiters.map(item => ({
          ...item,
          label: item.recruiterName || item.label,
          value: item.recruiterId || item.value
        }))
        if (typeof recruitmentReq.phone === 'string') {
          recruitmentReq.phone = recruitmentReq.phone.split(',');
        }
        recruitmentReq.releaseTime = dayjs(recruitmentReq.releaseTime, DATE_FMT)
      }
      form.setFieldsValue(recruitmentReq);
    }
    initRecruitmentReq()
  }, [props])

  useEffect(() => {
    provinceCityDistrictStore.getAllProvinceCityDistrict()
    talentStore.getTalent({role: 'recruiter'})
  }, [])

  return (
    <Card title={<div className={"text-align-center"}>基本资料</div>}>
      <Form
        ref={ref}
        form={form}
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        autoComplete="off">
        <Row gutter={16}>
          <Form.Item hidden name="status">
            <Input/>
          </Form.Item>
          <Form.Item hidden name="projectName">
            <Input/>
          </Form.Item>
          <Col span={12}>
            <Form.Item
              label="招聘名称"
              name="title"
              rules={[{ required: true, message: '请输入招聘名称!' }]}>
              <Input placeholder="请输入招聘名称"/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="发布时间"
              name="releaseTime"
              rules={[{ required: true, message: '请选择发布时间!' }]}>
              <DatePicker placeholder={"请选择发布时间"} className={'width-100-percentage'}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="所在省市"
              name="region"
              rules={[{ required: true, message: '请选择所在省市区!' }]}>
              <Cascader options={provinceCityDistrictStore.provinceCityDistrict} fieldNames={{
                label: "name",
                value: "name",
                children:"children"
              }} placeholder="请选择所在省市区" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="详细地址"
              name="workAddress"
              rules={[{ required: true, message: '请输入详细地址!' }]}>
              <Input placeholder="请输入详细地址" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="招聘经理"
              name="recruiters"
              rules={[{ required: true, message: '请选择招聘经理!' }]}>
              <Select
                placeholder="请选择招聘经理"
                className={`width-100-percentage`}
                showSearch
                mode={'multiple'}
                loading={talentStore.talentDatasource.loading}
                labelInValue
                filterOption={(inputValue, option) => String(option?.name).includes(inputValue)}
                fieldNames={{value: "_id", label: "name"}}
                options={talentStore.talentDatasource.data?.items}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="手机号码"
              name="phone"
              labelCol={{span: 3}}
              wrapperCol={{span: 20}}
              rules={[{ required: true, message: '请输入手机号码!' }]}>
              <Select
                placeholder="请选择手机号码"
                className={`width-100-percentage`}
                showSearch
                mode={'multiple'}
                loading={talentStore.talentDatasource.loading}
                filterOption={(inputValue, option) => String(option?.name).includes(inputValue)}
                fieldNames={{value: "phone", label: "phone"}}
                options={talentStore.talentDatasource.data?.items}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="招聘内容"
              name="desc"
              labelCol={{span: 3}}
              wrapperCol={{span: 20}}
              rules={[{ required: true, message: '请输入招聘内容!' }]}>
              <Input.TextArea placeholder="请输入招聘内容" autoSize={{minRows: 2, maxRows: 9}} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}))
