import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Col, Form, Input, Row, Select} from "antd";
import {PersonnelChangeRow} from "@services/dto/personnelChange";
import {FormInstance} from "antd/es";
import {ProjectRow} from "@services/dto/project";
import {SelectProject} from "@components/SelectProject";
import {CascaderPerson} from "@components/CascaderPerson";
import {PersonCascader} from "@services/dto/talent";
import {SelectDict} from "@components/SelectDict";
import {allStores} from "@/stores";

export interface PropsType {
  row: PersonnelChangeRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props, ref) => {
  const {workerTypeStore} = allStores;
  const [project, setProject] = useState<ProjectRow>()
  const [form] = Form.useForm()
  useEffect(() => {
    form.resetFields()
    setProject(undefined)
    const personnelChangeRow = {...props.row}
    if (props.row.id) {
      personnelChangeRow.person = [props.row.newProjectId, props.row.newProfessionalLeaguesName, props.row.newProfessional, props.row.userId]
      setProject(personnelChangeRow.newCompanyProject)
    }
    form.setFieldsValue(personnelChangeRow);
  }, [props.row])

  const handleChange = (value: string, options: ProjectRow[]) => {
    let project = options.find(item => item.id === value)
    setProject(project)
    form.resetFields(["newProfessionalLeaguesName", "newProfessional", "newPersonnelCategory", "newWorkerTypeName"])
  }

  useEffect(() => {
    workerTypeStore.getWorkerTypes()
  }, [])

  const handlePersonChange = (value: string[], _: PersonCascader[]) => {
    return value
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 18}}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Form.Item
            label="员工"
            name="person"
            valuePropName={"person"}
            getValueFromEvent={handlePersonChange}
            rules={[{ required: true, message: '请选择员工!' }]}>
            <CascaderPerson disabled={!!props.row.id}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="新项目"
            name="newProjectId"
            rules={[{ required: true, message: '请选择新项目!' }]}>
            <SelectProject onChange={handleChange}/>
          </Form.Item>
        </Col>
        {
          project && <Col span={24}>
            <Form.Item
              label="新人员类别"
              name="newPersonnelCategory"
              rules={[{ required: true, message: '请选择新人员类别!' }]}>
              <SelectDict allowClear code={"dict_personnel_category"} placeholder={"请选择人员类别"}/>
            </Form.Item>
          </Col>
        }
        {
          project && <Col span={24}>
            <Form.Item
              label="新队部"
              name="newProfessionalLeaguesName"
              rules={[{ required: true, message: '请选择新队部!' }]}>
              { project.name }
            </Form.Item>
          </Col>
        }
        {
          project && <Col span={24}>
            <Form.Item
              label="新工种"
              name="newWorkerTypeName"
              rules={[{ required: true, message: '请选择新工种!' }]}>
              <Select placeholder="请选择工种">
                { workerTypeStore.workerTypes.items.map((item) => (
                  <Select.Option key={item.name}>{ item.name }</Select.Option>)) }
              </Select>
            </Form.Item>
          </Col>
        }
        {
          project && <Col span={24}>
            <Form.Item
              label="新职位"
              name="newProfessional"
              rules={[{ required: true, message: '请选择新职位!' }]}>
              <SelectDict allowClear code={"dict_professional"} placeholder={"请选择新职位"}/>
            </Form.Item>
          </Col>
        }
        <Col span={24}>
          <Form.Item
            label="备注"
            name="remark"
            rules={[{required: false, message: '请输入备注!'}]}>
            <Input.TextArea placeholder="请输入备注" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
