import React, {useEffect} from "react";
import {Col, Form, Input, Row, Select} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {MenuPermissionsRow} from "@services/dto/menuPermissions";

export interface PropsType {
  row: MenuPermissionsRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  useEffect(() => {
    form.resetFields()
    const organizationalRow = {...props.row}
    if (props.row.id) {

    }
    form.setFieldsValue(organizationalRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      disabled={props.isView}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      initialValues={{status: 1}}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="parentId">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="排序"
            name="orderNum"
            rules={[{required: false, message: '请输入排序!'}]}>
            <Input placeholder="请输入排序"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="名称"
            name="title"
            rules={[{required: false, message: '请输入名称!'}]}>
            <Input placeholder="请输入名称"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="节点名称"
            name="name"
            rules={[{required: true, message: '请输入节点名称!'}]}>
            <Input placeholder="请输入节点名称"/>
          </Form.Item>
        </Col>
        { /*<Col span={24}>
          <Form.Item
            label="菜单图标"
            name="icon"
            rules={[{required: false, message: '请输入菜单图标!'}]}>
            <Input placeholder="请输入菜单图标"/>
          </Form.Item>
        </Col>*/ }
        { /*
        <Col span={24}>
          <Form.Item
            label="路由地址"
            name="path"
            rules={[{required: false, message: '请输入路由地址!'}]}>
            <Input placeholder="请输入路由地址"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="组件地址"
            name="viewPath"
            rules={[{required: false, message: '请输入组件地址!'}]}>
            <Input placeholder="请输入组件地址"/>
          </Form.Item>
        </Col>*/ }
        <Col span={24}>
          <Form.Item
            label="菜单类型"
            name="type"
            rules={[{required: true, message: '请输入菜单类型!'}]}>
            <Select
              placeholder={'请选择菜单类型'}
              allowClear
              options={[
                {label: '目录', value: 0},
                {label: '菜单', value: 1},
                {label: '按钮', value: 2},
              ]}
            >
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="权限标识"
            name="perms"
            rules={[{required: true, message: '请输入权限标识!'}]}>
            <Input placeholder="请输入权限标识"/>
          </Form.Item>
        </Col>
        { /*<Col span={24}>
          <Form.Item
            label="后台存活"
            name="keepAlive"
            rules={[{required: false, message: '请输入后台存活!'}]}>
            <Select
              placeholder={'请选择后台存活'}
              allowClear
              options={[
                {label: '是', value: '1'},
                {label: '否', value: '1'},
              ]}
            >
            </Select>
          </Form.Item>
        </Col>*/ }
        <Col span={24}>
          <Form.Item
            label="显示办理进度"
            name="show"
            rules={[{required: false, message: '请输入显示办理进度!'}]}>
            <Select
              placeholder={'请选择显示办理进度'}
              allowClear
              options={[
                {label: '显示', value: true},
                {label: '隐藏', value: false},
              ]}
            >
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="备注"
            name="remark"
            rules={[{required: false, message: '请输入备注!'}]}>
            <Input.TextArea placeholder="请输入备注" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
