import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {FormInstance, message, Modal, Space, Table} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {logger, parseURLParams, safeCompute, showErr} from "@common/utils";
import {ProjectStructureService} from "@services/ProjectStructureService";
import {ProjectStructureReq, ProjectStructureRow, ProjectStructureSummaryTotal} from "@services/dto/projectStructure";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {LabelValue, ResponseEntity} from "@services/dto/common";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {BasicInfo as ProcessRecordsBasicInfo} from "@modules/ProcessRecords/Components/BasicInfo";
import {useLocation} from "react-router-dom";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FieldTimeOutlined,
  PlusOutlined,
  PrinterOutlined
} from "@ant-design/icons";
import {WindowPrint} from "@modules/WindowPrint";
import {CustomDraggableModal} from "@components/CustomDraggableModal";
import {
  APPROVE_FORM_TRANS_STATE,
  BLACK_LIST,
  DATE_FMT,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  FORM_ALTERNATE_TEXT,
  FORM_CLOSE_TEXT,
  FORM_COMMIT_TEXT,
  FORM_DISABLED_STATE,
  FORM_HANDOVER_TEXT,
  FORM_PERSON_RESPONSIBLE_WHITELIST,
  FORM_RETURN_TEXT,
  FORM_REVOCATION_TEXT,
  LARGE_MODAL_WIDTH,
  LARGE_MODAL_WIDTH_OTHER, READY,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import {ProcessRecordsRow} from "@services/dto/processRecords";
import {TableAuthOptions} from "@components/CustomAuthOptions";
import {ProcessRecordsService} from "@services/ProcessRecordsService";
import {CustomForm} from "@components/CustomForm";
import {ProcessRecords} from "@modules/ProcessRecords";
import {TableFilingText} from "@components/TableFilingText";
import dayjs from "dayjs";
import {BasicInfo} from "@modules/ProjectStructure/Components/BasicInfo";
import {HeaderActionCom} from "@modules/ProjectStructure/Components/HeaderActionCom";
import {TableMoneyText} from "@components/TableMoneyText";

const wideShowColumn: string[] = [
  'psDate',
  'psCode',
  'psName',
  'psDesc',
  'psFile',
  'psStatus',
  "action",
]

const narrowShowColumn: string[] = [
  'psDate',
  'psCode',
  'psName',
  'psDesc',
  'psFile',
  'psStatus',
  "action",
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: ProjectStructureRow[] // 数据源
}

export const TableName = 'projectStructure'

export const ProjectStructure = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const location = useLocation()
  const {
    processStore,
    securityStore,
    projectStructureStore,
    screenResolutionStore,
    tableColumnsStore
  } = allStores
  const basicInfo = React.createRef<FormInstance>();
  const processRecordsBasicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<ProjectStructureRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [projectStructureRow, setProjectStructureRow] = useState<ProjectStructureRow>()
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [isView, setIsView] = useState<boolean>(false)
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<ProjectStructureRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [printTitle, setPrintTitle] = useState<string>('')
  const [isPrintTable, setIsPrintTable] = useState<boolean>(false)
  const [printDataSource, setPrintDataSource] = useState<ProjectStructureRow[]>([])
  const [contentList, setContentList] = useState<LabelValue[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [processRecordsIsOpen, setProcessRecordsIsOpen] = useState<boolean>(false)
  const [processRecordsRow, setProcessRecordsRow] = useState<ProcessRecordsRow>()
  const [processRecordsOpen, setProcessRecordsOpen] = useState<boolean>(false)
  const [processRecordsDataSource, setProcessRecordsDataSource] = useState<ProcessRecordsRow[]>([])
  const [submitRow, setSubmitRow] = useState<ProjectStructureRow>()
  const [submitterId, setSubmitterId] = useState<string>()
  const [latestProcess, setLatestProcess] = useState<string>()
  const [searchReq, setSearchReq] = useState<ProjectStructureReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...projectStructureStore.projectStructureSearchReq
  })
  const [summaryTotalObj, setSummaryTotalObj] = useState<ProjectStructureSummaryTotal>({
    openIncome: 0,
    openExpend: 0,
    openBalance: 0,
    closeIncome: 0,
    closeExpend: 0,
    closeBalance: 0,
  })

  const columns: ColumnsType<ProjectStructureRow> = [
    {
      title: '制单日期',
      dataIndex: 'psDate',
      key: 'psDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'psCode',
      key: 'psCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '项目名称',
      dataIndex: 'psName',
      key: 'psName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '项目概述',
      dataIndex: 'psDesc',
      key: 'psDesc',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'psFile',
      key: 'psFile',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableFilingText id={record.psFile} value={record.psFileCode}></TableFilingText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理人员',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '主办人员',
      dataIndex: 'organizersUserName',
      key: 'organizersUserName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '参与人员',
      dataIndex: 'wnUserNames',
      key: 'wnUserNames',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'psStatus',
      key: 'psStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableAuthOptions
          mainBtn={['handleEdit', 'handleView']}
          items={[
            {
              key: 'handleView',
              icon: <EllipsisOutlined/>,
              onClick: () => handleView(record)
            },
            {
              key: 'handleEdit',
              icon: <EditOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.psStatus),
              onClick: async () => await handleEdit(record)
            },
            {
              key: 'handlePrint',
              icon: <PrinterOutlined/>,
              onClick: () => handlePrint(record)
            },
            {
              key: 'handleDeleteBatch',
              icon: <DeleteOutlined/>,
              disabled: FORM_DISABLED_STATE.includes(record.psStatus),
              onClick: () => handleDeleteBatch(record)
            },
          ]}></TableAuthOptions>
      )
    }
  ];

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (projectStructureStore.projectStructureDatasource.data) {
      setDataSource(projectStructureStore.projectStructureDatasource.data?.items || [])
      setTotal(projectStructureStore.projectStructureDatasource.data?.total || 0)
    }
  }, [projectStructureStore.projectStructureDatasource.data])

  /**
   * @description 表单数据变化
   */
  useEffect(() => {
    if (projectStructureStore.projectStructureRow?.id) {
      handleAdd(projectStructureStore.projectStructureRow)
      projectStructureStore.updateProjectStructureRow({
        ...projectStructureStore.projectStructureRow,
        id: undefined,
      })
    }
  }, [projectStructureStore.projectStructureRow])

  /**
   * @description 页面操作变化
   */
  useEffect(() => {
    if (projectStructureStore.projectStructureOption) {
      const [option, paramsStr] = projectStructureStore.projectStructureOption.split("?")
      if (option === 'add') {
        handleAdd()
      } else if (paramsStr) {
        const params = parseURLParams(paramsStr)
        if (option === 'edit') {
          ProjectStructureService.getDetailById(params.id).then(rsp => {
            if (rsp.code === 200) {
              handleEdit(rsp.data).then()
            }
          })
        }
      }
      projectStructureStore.updateProjectStructureOption(undefined)
    }
  }, [projectStructureStore.projectStructureOption])

  useEffect(() => {
    const objInitValue = {
      openIncome: 0,
      openExpend: 0,
      openBalance: 0,
      closeIncome: 0,
      closeExpend: 0,
      closeBalance: 0,
    }
    const arr = projectStructureStore.projectStructureDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      arr.forEach(item => {
        objInitValue.openIncome += safeCompute(item.openIncome) || 0
        objInitValue.openExpend += safeCompute(item.openExpend) || 0
        objInitValue.openBalance += safeCompute(item.openBalance) || 0
        objInitValue.closeIncome += safeCompute(item.closeIncome) || 0
        objInitValue.closeExpend += safeCompute(item.closeExpend) || 0
        objInitValue.closeBalance += safeCompute(item.closeBalance) || 0
      })
      setSummaryTotalObj(objInitValue)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [projectStructureStore.projectStructureDatasource, selectedRowKeys])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, projectStructureStore.collapsed, searchReq.pageNum, searchReq.perPage])

  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => o.key !== 'action').filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: ProjectStructureReq) => {
    setSearchReq(req)
    projectStructureStore.getProjectStructure(req)
  }

  /**
   * @description 打印表格
   */
  const handlePrintTable = () => {
    // 数据处理
    setPrintDataSource(projectStructureStore.projectStructureDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id)))
    setIsPrintTable(true)
    setPrintTitle('费用支出表格')
    setIsModalOpen(true)
  }

  /**
   * 打印单条数据
   * @param record
   */
  const handlePrint = async (record: ProjectStructureRow) => {
    const list = await projectStructureStore.getPrintList(record)
    if (list) {
      setContentList(list)
      setPrintTitle('项目审批单')
      setIsPrintTable(false)
      setIsModalOpen(true)
    } else {
      message.warning('请检查')
    }
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    projectStructureStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  // 新增
  const handleAdd = (row?: ProjectStructureRow) => {
    const user = securityStore.getLoggedUser()
    const initRow = {
      psStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      psDate: dayjs(),
      projectBudgetStructureMiddles: [
        {
          id: '',
          structureId: '',
          budgetCategory: undefined,
          budgetMoney: undefined
        }
      ],
      projectMilestoneStructureMiddles: [
        {
          id: '',
          structureId: '',
          milestone: undefined,
          planFinishDate: undefined,
          realityFinishDate: undefined,
        }
      ]
    } as ProjectStructureRow
    Object.assign(row || {}, initRow)
    setProjectStructureRow(initRow)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handleView = (record: ProjectStructureRow) => {
    setProjectStructureRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = async (record: ProjectStructureRow) => {
    try {
      const rsp = await ProcessRecordsService.getProcessRecords({
        businessId: record.id,
        pageNum: 1,
        perPage: 999,
        type: TableName,
      })
      const process = rsp.items.length ? rsp.items[0].process : ''
      const userId = rsp.items.find(o => o.process === process && o.status === FORM_COMMIT_TEXT)?.userId
      const con = rsp.items.some(o => o.process === process && o.status === FORM_REVOCATION_TEXT)
      // 如果本轮有撤回 （id为空） 否则 （发起最新一轮的提交的人的id）
      setSubmitterId(con ? undefined : userId)
      setLatestProcess(process) // 发起最新一轮的标志
      setProjectStructureRow(record)
      setIsView(false)
      setIsModalVisible(true);
    } catch (err) {
      message.warning("办理记录查询失败！").then()
      logger.log(err)
    }
  }

  const handleDeleteBatch = (record?: ProjectStructureRow) => {
    const keys = [...selectedRowKeys]
    if (!record && keys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = keys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        ProjectStructureService.deleteProjectStructure(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(projectStructureStore.projectStructureSearchReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const insertOrUpdate = async (row: ProjectStructureRow) => {
    if (row.psDate && typeof row.psDate === 'object') {
      row.psDate = row.psDate.format(DATE_FMT);
    }
    if (typeof row.cooperationUnit === 'object') {
      row.cooperationUnit = row.cooperationUnit
        .filter((v, i, self) => self.indexOf(v) === i) // 去重
        .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
        .join(',');
    }
    /*row.projectMilestoneStructureMiddles = row.projectMilestoneStructureMiddles.map(item => ({
      ...item,
      planFinishDate: item.planFinishDate && typeof item.planFinishDate === 'object' ? item.planFinishDate.format(DATE_FMT) : '',
      realityFinishDate: item.realityFinishDate && typeof item.realityFinishDate === 'object' ? item.realityFinishDate.format(DATE_FMT) : ''
    }))*/
    if (row.id) {
      const rsp = await ProjectStructureService.updateProjectStructure(row)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      actionThen(rsp)
      return rsp
    } else {
      const rsp = await ProjectStructureService.addProjectStructure(row)
      setUpdateCount(updateCount + 1)
      setConfirmLoading(false)
      actionThen(rsp)
      return rsp
    }
  }

  const actionThen = (rsp: ResponseEntity<any>) => {
    if (rsp.code === 200) {
      message.success("操作成功！").then()
    } else {
      message.warning(rsp.message).then()
    }
    setIsModalVisible(false)
    refreshData(projectStructureStore.projectStructureSearchReq)
  }

  const handleOk = (open: boolean) => {
    const row = {...projectStructureRow!}
    basicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)

      if (open) {

        // 将row和按钮先存起来
        setSubmitRow(row)

        // 弹窗选择处理人
        setProcessRecordsIsOpen(true)
        setProcessRecordsRow(undefined)

      } else {

        const user = securityStore.getLoggedUser()

        // 默认处理人员是自己
        if (!row.id) {
          row.personResponsible = user._id
          row.personResponsibleName = user.name
        }

        try {
          await insertOrUpdate(row)
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }

      }

    }).catch(logger.warn)
  }

  const handleSubmit = () => {
    const row = {...processRecordsRow!}
    processRecordsBasicInfo.current!.validateFields().then(async (values) => {
      Object.assign(row, values)

      const user = securityStore.getLoggedUser()
      const btnText = row.status

      // 当类型是撤回时 默认办理人员为提交人(自己)
      if (btnText === FORM_REVOCATION_TEXT) {
        row.personResponsible = user._id || submitterId
        row.personResponsibleName = user.name
      }

      // 主表
      const subRow = {...submitRow}
      subRow.personResponsible = row.personResponsible
      subRow.personResponsibleName = row.personResponsibleName
      subRow.psStatus = APPROVE_FORM_TRANS_STATE[btnText] || subRow.psStatus
      // 主表在撤回、退回、变更时->更新局部数据
      if ([FORM_REVOCATION_TEXT, FORM_RETURN_TEXT, FORM_ALTERNATE_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在转交、提交时->更新局部数据
      if ([FORM_HANDOVER_TEXT, FORM_COMMIT_TEXT].includes(btnText)) {
        subRow.organizersUserId = subRow.personResponsible
        subRow.organizersUserName = subRow.personResponsibleName
      }
      // 主表在提交时->更新局部数据
      if (btnText === FORM_COMMIT_TEXT) {
        subRow.psDate = new Date().toLocaleDateString().replace(/\//g, '-')
      }
      // 主表在结办时->更新局部数据
      if (btnText === FORM_CLOSE_TEXT) {

      }

      // 办理记录表
      row.type = TableName
      row.businessId = subRow.id // 有可能会没值
      row.status = btnText
      row.userId = user._id
      row.userName = user.name
      row.personResponsible = undefined
      row.personResponsibleName = undefined
      if (btnText === FORM_COMMIT_TEXT) {
        const date = new Date()
        row.process = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
      } else {
        row.process = latestProcess
      }
      if (FORM_PERSON_RESPONSIBLE_WHITELIST.includes(btnText)) {
        row.conca = `${user.name}${btnText}给${subRow.personResponsibleName}。`
      } else {
        row.conca = `${user.name}${btnText}了。`
      }

      // 判断是否 先新增主表 后新增记录和通知表
      if (subRow.id) {

        let firstOperationSucceeded = false;
        try {
          await insertOrUpdate(subRow);
          firstOperationSucceeded = true;
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }
        if (!firstOperationSucceeded) return
        await ProcessRecordsService.addProcessRecords(row)
        const rsp = await ProcessRecordsService.getProcessRecords({
          businessId: subRow.id,
          pageNum: 1,
          perPage: 999,
          type: TableName,
        })
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .concat(row.person)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== user._id) // 过滤出不是自己的数据
          .join(',') || ''
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .concat((row.personName || '').split(','))
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== user.name) // 过滤出不是自己的数据
          .join(',') || ''
        await processStore.newAssignmentNotice({
          businessId: subRow.id,
          businessCode: subRow.psCode,
          cooperationUnit: subRow.cooperationUnit[0] || 'N/A',
          notificationScopeUserId,
          notificationScopeUserName,
          personResponsible: subRow.personResponsible,
          personResponsibleName: subRow.personResponsibleName,
          responsibilityProject: subRow.responsibilityProject,
          type: "项目审批",
          wnDesc: `您参与办理的项目审批（${subRow.psCode}），${row.conca} 请知悉！`,
          wnStatus: "未读"
        })

      } else {

        let subRsp: ResponseEntity<ProjectStructureRow>
        try {
          subRsp = await insertOrUpdate(subRow)
        } catch (error) {
          setProcessRecordsIsOpen(false)
          setProcessRecordsRow(undefined)
          setConfirmLoading(false)
          setIsModalVisible(false);
          message.warning(error.message)
        }

        if (subRsp.data) {
          // 重新赋值再提交
          row.businessId = subRsp.data.id
          await ProcessRecordsService.addProcessRecords(row)
          const rsp = await ProcessRecordsService.getProcessRecords({
            businessId: subRsp.data.id,
            pageNum: 1,
            perPage: 999,
            type: TableName,
          })
          const notificationScopeUserId = rsp.items
            .map(o => o.userId)
            .concat(row.person)
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(id => id !== user._id) // 过滤出不是自己的数据
            .join(',') || ''
          const notificationScopeUserName = rsp.items
            .map(o => o.userName)
            .concat((row.personName || '').split(','))
            .filter((v, i, self) => self.indexOf(v) === i) // 去重
            .filter(name => name !== user.name) // 过滤出不是自己的数据
            .join(',') || ''
          await processStore.newAssignmentNotice({
            businessId: subRsp.data.id,
            businessCode: subRsp.data.psCode,
            cooperationUnit: (subRsp.data.cooperationUnit as string).split(',')[0] || 'N/A',
            notificationScopeUserId,
            notificationScopeUserName,
            personResponsible: subRsp.data.personResponsible,
            personResponsibleName: subRsp.data.personResponsibleName,
            responsibilityProject: subRsp.data.responsibilityProject,
            type: "项目审批",
            wnDesc: `您参与办理的项目审批（${subRsp.data.psCode}），${row.conca} 请知悉！`,
            wnStatus: "未读"
          })
        } else {
          message.warning('主表保存成功，办理记录表和任务通知表提交失败')
        }

      }
      setProcessRecordsIsOpen(false)
      setProcessRecordsRow(undefined)
    }).catch(logger.warn)
  }

  /**
   * 操作记录按钮
   */
  const handleProcessRecordsClick = async (record: ProjectStructureRow) => {
    const rsp = await ProcessRecordsService.getProcessRecords({
      businessId: record.id,
      pageNum: 1,
      perPage: 999,
      type: TableName
    })
    if (!rsp) {
      message.warning('查无数据')
      return
    }
    setProcessRecordsDataSource(rsp.items)
    setProcessRecordsOpen(true)
  }

  const handleCopyClick = (record: ProjectStructureRow) => {
    const user = securityStore.getLoggedUser()
    const initRow = {
      ...record,
      id: '',
      psFile: '',
      psStatus: READY,
      personResponsible: user._id,
      personResponsibleName: user.name,
      organizersUserId: user._id,
      organizersUserName: user.name,
      psDate: dayjs(),
    }
    setProjectStructureRow(initRow)
    setIsView(false)
    setIsModalVisible(true)
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        updateCount={updateCount}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        handleShowColumnReset={handleShowColumnReset}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={props.isModalSelect ? columns.filter(o => o.key !== 'action') : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}
        collapsed={projectStructureStore.collapsed}
        items={[
          {
            key: 'handleAdd',
            icon: <PlusOutlined/>,
            onClick: () => handleAdd()
          },
          {
            key: 'handleDeleteBatch',
            icon: <DeleteOutlined/>,
            onClick: () => handleDeleteBatch()
          },
          {
            key: 'handlePrintTable',
            icon: <PrinterOutlined/>,
            onClick: () => handlePrintTable()
          },
        ]}></HeaderActionCom>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        bordered
        size={"middle"}
        loading={props.dataSource ? false : projectStructureStore.projectStructureDatasource.loading || confirmLoading}
        pagination={!props.dataSource && {
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys)
          },
          columnWidth: 50,
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} key={0} colSpan={1} className='text-align-center'>
                { selectedRowKeys.length }
              </Table.Summary.Cell>
              {
                columns.map((item, index) =>
                  String(item.key)?.includes('-summation') ? <Table.Summary.Cell
                    index={index} key={index + 1} colSpan={1} className='text-align-right'>
                            合计：<span>
                      { summaryTotalObj[String(item.key).slice(0, String(item.key).indexOf('-'))] }
                            &nbsp;(<TableMoneyText isPercentage value={
                        (
                          (summaryTotalObj[String(item.key).slice(0, String(item.key).indexOf('-'))]) / total
                        ) || 0
                      }></TableMoneyText>)
                    </span>
                  </Table.Summary.Cell> : <Table.Summary.Cell key={index + 1} index={index} colSpan={1}/>)
              }
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />


      { /*操作记录表*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsOpen}
        width={LARGE_MODAL_WIDTH_OTHER}
        onCancel={() => setProcessRecordsOpen(false)}
        destroyOnClose
        footer={null}>
        <ProcessRecords
          isHiddenOptions
          dataSource={processRecordsDataSource}
          isModalSelect
          locationPathname={'/processRecords'}/>
      </CustomDraggableModal>

      { /*操作记录表单*/ }
      <CustomDraggableModal
        title={<div className="text-align-center">办理记录</div>}
        open={processRecordsIsOpen}
        width={LARGE_MODAL_WIDTH}
        destroyOnClose
        onCancel={() => {
          setProcessRecordsIsOpen(false)
          setConfirmLoading(false)
        }}
        onOk={handleSubmit}>
        <ProcessRecordsBasicInfo
          tableName={TableName}
          businessId={projectStructureRow?.id}
          submitterId={submitterId}
          userId={projectStructureRow?.personResponsible}
          status={projectStructureRow?.psStatus}
          row={processRecordsRow}
          ref={processRecordsBasicInfo}/>
      </CustomDraggableModal>

      <CustomForm
        title={'项目审批'}
        handleCancel={handleCancel}
        handleOk={handleOk}
        isModalVisible={isModalVisible}
        submitterId={submitterId}
        confirmLoading={confirmLoading}
        userId={projectStructureRow?.personResponsible}
        status={projectStructureRow?.psStatus}
        isView={isView}
        extraDisabled={!projectStructureRow?.id}
        extraOnClick={() => handlePrint(projectStructureRow)}
        item={[
          {
            key: 'handleCopyClick',
            icon: <FieldTimeOutlined/>,
            disabled: !projectStructureRow?.id,
            onClick: () => handleCopyClick(projectStructureRow)
          },
          {
            key: 'handleProcessRecordsClick',
            icon: <FieldTimeOutlined/>,
            disabled: !projectStructureRow?.id,
            onClick: () => handleProcessRecordsClick(projectStructureRow)
          },
        ]}>
        <BasicInfo row={{...projectStructureRow!}} ref={basicInfo} isView={isView}/>
      </CustomForm>

      <WindowPrint
        isHiddenTime
        isSummary
        title={printTitle}
        contentList={contentList}
        isModalOpen={isModalOpen}
        isPrintTable={isPrintTable}
        tableColumns={columns}
        dataSource={printDataSource}
        narrowShowColumn={narrowShowColumn}
        handleCancel={() => {
          setIsModalOpen(false)
          setSelectedRowKeys([])
        }}/>
    </div>
  )
}))
