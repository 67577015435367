import React, {useEffect, useState} from "react";
import type {MenuProps} from 'antd';
import {Button, Col, Divider, Dropdown, FormInstance, message, Modal, Pagination, Row, Select, Space} from "antd";
import {Any} from "@common/types";
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {CloseOutlined, EditOutlined, FolderViewOutlined, MinusCircleOutlined, PlusOutlined,} from "@ant-design/icons";
import {logger, showErr} from "@common/utils";
import {BLACK_LIST, DEFAULT_MODAL_WIDTH} from "@/config";
import {ProjectCollaborationReq, ProjectCollaborationRow} from "@services/dto/projectCollaboration";
import {BasicInfo} from "@modules/ProjectCollaboration/Components/BasicInfo";
import {ProjectCollaborationService} from "@services/ProjectCollaborationService";
import {DictService} from "@services/DictService";
import {StatisticsItemsReq, StatisticsItemsRow} from "@services/dto/dict";
import {FlattenOptionData} from "rc-select/lib/interface";
import {BaseOptionType} from "rc-select/lib/Select";

export interface PropsType {
  onChange?: (value: Any | null, options: ProjectCollaborationRow[]) => void
  value?: Any | null
  mode?: 'multiple' | 'tags' | undefined
  labelInValue?: boolean
  disabled?: boolean
  className?: string
  allowClear?: boolean
  placeholder?: string
  fieldNames?: { value: string, label: string }
  isShowAction?: boolean
  isSimpleAction?: boolean
  modalTitle?: string
  personResponsible?: string
  defaultItems?:  any[]
  responsibilityProject?: string
  handleButtonClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  statisticsItemsReq?: StatisticsItemsReq
  cooperativeUnitCategory?: string
  optionRender?: (oriOption: FlattenOptionData<BaseOptionType>, info: {
    index: number;
  }) => React.ReactNode;
}

export const SelectCooperativeUnit = observer((props: PropsType) => {
  const [value, setValue] = useState<Any | null>()
  const {projectCollaborationStore} = allStores
  const [datasource, setDatasource] = useState<ProjectCollaborationRow[]>([])
  const [total, setTotal] = useState(0)
  const basicInfo = React.createRef<FormInstance>();
  const [isView, setIsView] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectCollaborationRow, setProjectCollaborationRow] = useState<ProjectCollaborationRow>()
  const [items, setItems] = useState<ProjectCollaborationRow[]>([])
  const [statisticsItems, setStatisticsItems] = useState<StatisticsItemsRow[] | ProjectCollaborationRow[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [searchReq, setSearchReq] = useState<ProjectCollaborationReq>({
    pageNum: 1,
    perPage: 10,
    cooperativeUnitCategory: props.cooperativeUnitCategory
  })


  /**
   *  每个组件默认写法 只需修改 'cuName'
   */
  const defaultItems: any[] = [
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: 'N/A',
      [props.fieldNames ? props.fieldNames.label : 'cuName']: 'N/A'
    },
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: '空白',
      [props.fieldNames ? props.fieldNames.label : 'cuName']: '空白'
    },
  ]

  /**
   * 初始化
   */
  useEffect(() => {
    if (props.statisticsItemsReq) {
      setLoading(true)
      DictService.getStatisticsItems(props.statisticsItemsReq).then(rsp => {
        const row = JSON.parse(JSON.stringify(rsp.data || [])).map((item: StatisticsItemsRow | ProjectCollaborationRow) => {
          (item as ProjectCollaborationRow).cuName = `${(item as ProjectCollaborationRow).cuName || (item as StatisticsItemsRow).value}(${(item as StatisticsItemsRow).coun})`
          return item
        })
        setStatisticsItems(row);
      }).catch(showErr).finally(() => {
        setLoading(false)
      })
    }
  }, [])

  /**
   * 办理进度数据变化
   */
  useEffect(() => {
    if (projectCollaborationStore.projectCollaborationSelectDatasource.data) {
      setDatasource(projectCollaborationStore.projectCollaborationSelectDatasource.data.items || [])
      setTotal(projectCollaborationStore.projectCollaborationSelectDatasource.data.total || 0)
      if (projectCollaborationStore.projectCollaborationDetails.data?.data.length) {
        setItems(projectCollaborationStore.projectCollaborationDetails.data?.data
          .filter(item => !(projectCollaborationStore.projectCollaborationSelectDatasource.data.items || [])
            .some(o => o.id === item.id)))
      }
    }
  }, [projectCollaborationStore.projectCollaborationDetails, projectCollaborationStore.projectCollaborationSelectDatasource])

  /**
   * 值发生改变
   */
  useEffect(() => {
    setValue(props.value)
    if (typeof props.value === 'string' && !BLACK_LIST.includes(props.value)) {
      projectCollaborationStore.getBatch([props.value])
    } else if (typeof props.value === 'object') {
      const ids = props.value.filter((v: string) => !BLACK_LIST.includes(v))
      if (ids.length) {
        projectCollaborationStore.getBatch(ids)
      }
    }
  }, [props.value])


  const refreshData = (req: ProjectCollaborationReq) => {
    projectCollaborationStore.getSelectProjectCollaboration(req)
  }

  const onChange = (value: Any | null) => {
    setValue(value)
    if (props.onChange) {
      props.onChange(value, [
        ...datasource,
        ...items,
      ]);
    }
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    const req = {...searchReq}
    req.pageNum = page
    req.perPage = pageSize
    setSearchReq(req)
    refreshData(req)
  }

  const handleSearch = (inputValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = inputValue
    setSearchReq(req)
    refreshData(req)
    return true
  }

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (props.handleButtonClick) {
      props.handleButtonClick(e)
    }
  };

  const menuProps: MenuProps = {
    items: [
      {
        label: '',
        key: 'edit',
        icon: <EditOutlined/>,
        style: {display: (!value || props.disabled) ? 'none' : '', color: '#ffec3d'},
        onClick: () => handleEdit()
      },
      {
        label: '',
        key: 'plus',
        icon: <PlusOutlined/>,
        style: {color: '#73d13d'},
        onClick: () => handlePlus()
      },
      {
        label: '',
        key: 'close',
        style: {display: (!value || props.disabled) ? 'none' : '', color: '#ff4d4f'},
        icon: <CloseOutlined/>,
        onClick: () => handleClose()
      },
      {
        label: '',
        key: 'view',
        style: {display: !value ? 'none' : '', color: '#4096ff'},
        icon: <FolderViewOutlined/>,
        onClick: () => handleView()
      },
    ]
  };

  const handleEdit = () => {
    const record = datasource.find(item => item[props.fieldNames?.value || "id"] === value)
    setProjectCollaborationRow(record)
    setIsModalOpen(true);
    setIsView(false)
  }

  const handlePlus = () => {
    setProjectCollaborationRow({
      ...projectCollaborationRow,
      personResponsible: props.personResponsible,
      responsibilityProject: props.responsibilityProject
    })
    setIsModalOpen(true);
    setIsView(false)
  }

  const handleClose = () => {
    const record = datasource.find(item => item[props.fieldNames?.value || "id"] === value)
    if (!record) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        ProjectCollaborationService.deleteProjectCollaboration([record.id]).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(searchReq)
          onChange(null)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handleView = () => {
    const record = [...datasource, ...items].find(item => item[props.fieldNames?.value || "id"] === value)
    setProjectCollaborationRow(record)
    setIsModalOpen(true);
    setIsView(true)
  }

  const handleOk = () => {
    const row = {...projectCollaborationRow!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(row, values)
      setConfirmLoading(true)
      if (row.id) {
        ProjectCollaborationService.updateProjectCollaboration(row).then(() => {
          actionThen();
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        ProjectCollaborationService.addProjectCollaboration(row).then(() => {
          actionThen();
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  }

  const actionThen = () => {
    message.success("操作成功！").then()
    setIsModalOpen(false);
    setProjectCollaborationRow(undefined)
    refreshData(searchReq)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setProjectCollaborationRow(undefined)
  }

  const selectNode = props.statisticsItemsReq ? (
    <Select
      allowClear={props.allowClear}
      placeholder={props.placeholder || "请选择协作单位"}
      className={`${props.className} width-100-percentage`}
      value={value}
      onChange={onChange}
      showSearch
      optionFilterProp="children"
      filterOption={(input: string, option) =>
        String(option?.cuName).toLowerCase().includes(input.toLowerCase())}
      disabled={props.disabled}
      mode={props.mode}
      loading={loading}
      labelInValue={props.labelInValue}
      fieldNames={props.fieldNames || {value: "id", label: "cuName"}}
      optionRender={props.optionRender}
      options={statisticsItems as ProjectCollaborationRow[]}/>
  ) : (
    <Select
      allowClear={props.allowClear}
      placeholder={props.placeholder || "请选择协作单位"}
      className={`${props.className} width-100-percentage`}
      dropdownRender={(menu) => (
        <>
          { menu }
          <Divider className={"divider-margin"}/>
          <Space>
            <Pagination
              total={total}
              pageSize={searchReq.perPage}
              size={"small"}
              onChange={handlePaginationChange}/>
          </Space>
        </>
      )}
      value={value}
      onDropdownVisibleChange={(open) => !open || refreshData(searchReq)}
      onChange={onChange}
      onClear={() => handleSearch('')}
      filterOption={false}
      onSearch={handleSearch}
      showSearch
      optionRender={props.optionRender}
      disabled={props.disabled}
      mode={props.mode}
      loading={projectCollaborationStore.projectCollaborationDatasource.loading}
      labelInValue={props.labelInValue}
      fieldNames={props.fieldNames || {value: "id", label: "cuName"}}
      options={[
        ...(props.defaultItems || defaultItems),
        ...datasource,
        ...items,
      ]}/>
  )

  return (
    <>
      <Row>
        <Col span={props.isShowAction ? 18 : 24}>
          { selectNode }
        </Col>
        <Col span={props.isShowAction ? 6 : 0}>
          <div className={`d-flex df-aic df-jcc height-100-percentage`}>
            {
              props.isSimpleAction ?
                <>
                  <Dropdown.Button menu={menuProps} onClick={handleButtonClick}>
                    <MinusCircleOutlined/>
                  </Dropdown.Button>
                </>
                :
                <>
                  <Button
                    size={'small'} type={'link'} className={'btn-edit'} disabled={!value || props.disabled}
                    onClick={handleEdit}><EditOutlined/></Button>
                  <Button
                    size={'small'} type={'link'} className={'btn-plus'}
                    onClick={handlePlus}><PlusOutlined/></Button>
                  <Button
                    size={'small'} type={'link'} className={'btn-close'} disabled={!value || props.disabled}
                    onClick={handleClose}><CloseOutlined/></Button>
                  <Button
                    size={'small'} type={'link'} className={'btn-view'} disabled={!value}
                    onClick={handleView}><FolderViewOutlined/></Button>
                </>
            }
          </div>
        </Col>
      </Row>

      <Modal
        title={<div className="text-align-center">{ props.modalTitle }</div>}
        open={isModalOpen}
        width={DEFAULT_MODAL_WIDTH}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={<>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button onClick={handleOk} type={'primary'}>确定</Button>
          }
        </>}>
        <BasicInfo row={{...projectCollaborationRow!}} ref={basicInfo} isView={isView}/>
      </Modal>
    </>
  )
})
