import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {SelectDict} from "@components/SelectDict";
import {Any} from "@common/types";
import {InvoiceRegisterReq} from "@services/dto/invoiceRegister";
import {CustomInputNumber} from "@components/CustomInputNumber";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {DatePicker, Select} from "antd";
import dayjs from "dayjs";
import {SelectAccountSales} from "@components/SelectAccountSales";
import {SelectProject} from "@components/SelectProject";
import {CustomApplicationFormType} from "@components/CustomApplicationFormType";
import {SelectTaxAccounting} from "@components/SelectTaxAccounting";
import {CustomAuthHeaderAction, PropsType as ParentPropsType} from "@components/CustomAuthHeaderAction";
import {SelectProcessStatus} from "@components/SelectProcessStatus";

export interface PropsType extends ParentPropsType {
  updateCount?: number, // 更新渲染标志
  refreshData?: (req: InvoiceRegisterReq) => void,
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<InvoiceRegisterReq>(props.searchReq)

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: InvoiceRegisterReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleIrTypeChange = (value: Any) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.irType = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleIrAmountChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.irAmount1 = start
    req.irAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleIrTaxRateChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.irTaxRate1 = start
    req.irTaxRate2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleIrTaxAmountChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.irTaxAmount1 = start
    req.irTaxAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleIrTaxRateSumChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.irTaxRateSum1 = start
    req.irTaxRateSum2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleApplicationFormTypeChange = ([value, tableValue]: [value: string, tableValue: string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.irApplicationForm = value
    req.irContractNoId = tableValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleIrSellerChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.irSeller = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleIrPurchaserChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.irPurchaser = value
    setSearchReq(req)
    refreshData(req)
  }

  const handlePerSonChargeChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleIrStatusChange = (value: Any) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.irStatus = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleStatusCommonChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.statusCommon = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleIrDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, [start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.irDate1 = start
    req.irDate2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleSalesListIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.salesListId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleOutputAccountingIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.outputAccountingId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleInputAccountingIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.inputAccountingId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleOrganizersUserIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.organizersUserId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined

    req.irDate1 = undefined
    req.irDate2 = undefined
    req.irType = undefined
    req.salesListId = undefined
    req.irAmount1 = undefined
    req.irAmount2 = undefined
    req.irTaxRate1 = undefined
    req.irTaxRate2 = undefined
    req.irTaxAmount1 = undefined
    req.irTaxAmount2 = undefined
    req.irTaxRateSum1 = undefined
    req.irTaxRateSum2 = undefined
    req.irApplicationForm = undefined
    req.personResponsible = undefined
    req.personResponsibleName = undefined
    req.organizersUserId = undefined // 主办人员
    req.organizersUserName = undefined // 主办人员名
    req.irStatus = undefined
    req.irSeller = undefined
    req.irPurchaser = undefined
    req.statusCommon = undefined
    req.notInIds = undefined
    req.inIds = undefined
    req.outOrInIsNull = undefined
    req.outputAccountingId = undefined
    req.inputAccountingId = undefined
    req.irContractNoId = undefined

    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <CustomAuthHeaderAction
        size={props.size}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <DatePicker.RangePicker
            allowClear
            value={searchReq?.irDate1 ? [dayjs(searchReq?.irDate1), dayjs(searchReq?.irDate2)] : [undefined, undefined]}
            placeholder={['开票开始日期', '开票结束日期']}
            onChange={handleIrDateChange}
            className={"width-100-percentage"}/>,
          <SelectDict
            allowClear
            value={searchReq?.irType}
            className={'width-100-percentage'}
            code={'Invoice_registration_type'}
            onChange={handleIrTypeChange}
            placeholder={'请选择发票类型'}/>,
          <SelectAccountSales
            onChange={handleSalesListIdChange}
            value={searchReq?.salesListId}
            allowClear
            placeholder={'请选择销货清单'}
            className={'width-100-percentage'}/>,
          <CustomInputNumber
            value={[searchReq?.irAmount1, searchReq?.irAmount2]}
            onSearch={handleIrAmountChange}
            className={'width-100-percentage'}
            placeholder={['最小金额', '最大金额']}/>,
          <CustomInputNumber
            value={[searchReq?.irTaxRate1, searchReq?.irTaxRate2]}
            onSearch={handleIrTaxRateChange}
            className={'width-100-percentage'}
            placeholder={['最小税率', '最大税率']}/>,
          <CustomInputNumber
            value={[searchReq?.irTaxAmount1, searchReq?.irTaxAmount2]}
            onSearch={handleIrTaxAmountChange}
            className={'width-100-percentage'}
            placeholder={['最小税额', '最大税额']}/>,
          <CustomInputNumber
            onSearch={handleIrTaxRateSumChange}
            value={[searchReq?.irTaxRateSum1, searchReq?.irTaxRateSum2]}
            className={'width-100-percentage'}
            placeholder={['价税合计', '价税合计']}/>,
          <SelectCooperativeUnit
            allowClear
            value={searchReq?.irSeller}
            placeholder="请选择销售方"
            onChange={handleIrSellerChange}
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectCooperativeUnit
            allowClear
            placeholder="请选择购买方"
            value={searchReq?.irPurchaser}
            onChange={handleIrPurchaserChange}
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <CustomApplicationFormType
            className={'width-100-percentage'}
            allowClear
            placeholder={'申请表单'}
            showKey={['合同结算', '费用报销']}
            value={[searchReq?.irApplicationForm, searchReq?.irContractNoId]}
            onSearch={handleApplicationFormTypeChange}/>,
          <SelectTaxAccounting
            onChange={handleOutputAccountingIdChange}
            allowClear
            placeholder={'请选择销项核算'}
            value={searchReq?.outputAccountingId}
            className={'width-100-percentage'}/>,
          <SelectTaxAccounting
            onChange={handleInputAccountingIdChange}
            allowClear
            placeholder={'请选择进项核算'}
            value={searchReq?.inputAccountingId}
            className={'width-100-percentage'}/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
          <SelectCooperativeUnit
            onChange={handleCooperationUnitChange}
            value={searchReq?.cooperationUnit}
            allowClear
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectPersonCharge
            onChange={handlePerSonChargeChange}
            value={searchReq?.personResponsible}
            className={"width-100-percentage"}
            placeholder={"请选择办理人员"}
            allowClear/>,
          <SelectPersonCharge
            onChange={handleOrganizersUserIdChange}
            className={"width-100-percentage"}
            placeholder={"请选择主办人员"}
            value={searchReq?.organizersUserId}
            allowClear/>,
          <SelectProcessStatus
            onChange={handleIrStatusChange}
            allowClear
            value={searchReq?.irStatus}
            placeholder={'请选择办理进度'}
            className={"width-100-percentage"}/>,
          <Select
            onChange={handleStatusCommonChange}
            value={searchReq?.statusCommon}
            allowClear
            options={[
              {label: '完成', value: '完成'},
              {label: '容缺', value: '容缺'},
            ]}
            placeholder={'请选择校验办理进度'}
            className={"width-100-percentage"}/>,
        ] : []}/>
    </>
  )
}))
