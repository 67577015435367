import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {BankStatementReq, BankStatementRow} from "@services/dto/bankStatement";
import {FundApprovalRow} from "@services/dto/fundApproval";

export class BankStatementService {
  static async recalculate(id: string): Promise<ResponseEntity<string>> {
    return await apiV1.get("/bankStatement/refresh", {params: {bankAccountId: id}});
  }

  static async getBankStatement(req: BankStatementReq): Promise<Page<BankStatementRow>> {
    return await apiV1.get("/bankStatement/search", {params: req})
  }

  static async batchDateRefresh(): Promise<ResponseEntity<string>> {
    return await apiV1.get("/bankStatement/batch/date/refresh")
  }

  static async addBankStatement(row: BankStatementRow): Promise<ResponseEntity<BankStatementRow>> {
    return await apiV1.post("/bankStatement/save", {...row})
  }

  static async updateBankStatement(row: BankStatementRow): Promise<ResponseEntity<BankStatementRow>> {
    return await apiV1.put("/bankStatement/update", {...row})
  }

  static async deleteBankStatement(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/bankStatement/delete", {data: ids})
  }

  static async getOne(id: string): Promise<ResponseEntity<BankStatementRow>> {
    return await apiV1.get(`/bankStatement/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<BankStatementRow>> {
    return await apiV1.get(`/bankStatement/getBatch/${ids.join(',')}`)
  }

  static async batchUpdateBankStatement(selectedRows: BankStatementRow[]): Promise<ResponseEntity<string>> {
    return await apiV1.put("/bankStatement/updateBatch", [...selectedRows])
  }

  static async qualifyingData(bankStatementId: string): Promise<ResponseEntity<FundApprovalRow[]>> {
    return await apiV1.get(`/bankStatement/qualifyingData?bankStatementId=${bankStatementId}`)
  }

  static async bindD(req: { FundingApprovalId: string, BankStatementIds: string[] }): Promise<ResponseEntity<string>> {
    return await apiV1.post(`/bankStatement/bindD`, {...req})
  }
}
