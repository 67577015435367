import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, StateFullData} from "@services/dto/common";
import {StaffLoanReq, StaffLoanRow} from "@services/dto/staffLoan";
import {staffLoanService} from "@services/StaffLoanService";
import {BLACK_LIST} from "@/config";
import {FilingService} from "@services/FilingService";
import {convertCurrency, formatMoney} from "@common/utils";

export class StaffLoanStore  {
  staffLoanDatasource: StateFullData<Page<StaffLoanRow>> = {}
  staffLoanSelectDatasource: StateFullData<Page<StaffLoanRow>> = {}
  staffLoanRow?: StaffLoanRow
  staffLoanOption?: string
  staffLoanDetail: StateFullData<ResponseEntity<StaffLoanRow>> = {}
  staffLoanDetails: StateFullData<ResponseEntity<StaffLoanRow[]>> = {}
  staffLoanReq: StaffLoanReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateStaffLoanRow(row: StaffLoanRow) {
    this.staffLoanRow = {...row}
  }

  updateStaffLoanOption(option: string) {
    this.staffLoanOption = option
  }

  getStaffLoanRow(req: StaffLoanReq): void {
    this.staffLoanDatasource = {...this.staffLoanDatasource, loading: true}
    this.staffLoanReq = {...this.staffLoanReq, ...req}
    staffLoanService.getStaffLoan(req).then(rsp => {
      runInAction(() => this.staffLoanDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.staffLoanDatasource = {...this.staffLoanDatasource, loading: false, err})
    })
  }

  getSelectStaffLoanRow(req: StaffLoanReq): void {
    this.staffLoanSelectDatasource = {...this.staffLoanSelectDatasource, loading: true}
    staffLoanService.getStaffLoan(req).then(rsp => {
      runInAction(() => this.staffLoanSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.staffLoanSelectDatasource = {...this.staffLoanSelectDatasource, loading: false, err})
    })
  }

  getOne(id: string) {
    this.staffLoanDetail = {...this.staffLoanDetail, loading: true}
    staffLoanService.getOne(id).then(rsp => {
      runInAction(() => this.staffLoanDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.staffLoanDetail = {...this.staffLoanDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.staffLoanDetails = {...this.staffLoanDetails, loading: true}
    staffLoanService.getBatch(ids).then(rsp => {
      runInAction(() => this.staffLoanDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.staffLoanDetails = {...this.staffLoanDetails, loading: false, err})
    })
  }

  async getPrintList(record: StaffLoanRow): Promise<LabelValue[]> {

    const row = this.staffLoanDatasource.data?.items.find(o => o.id === record.id)

    // 存档批号
    let elFileRow = undefined
    if (row.elFile && !BLACK_LIST.includes(row.elFile)) {
      const elFileRsp = await FilingService.getBatch([row.elFile])
      if (elFileRsp && elFileRsp.code !== 200) {
        return
      }
      elFileRow = elFileRsp.data[0]
    }

    return [
      {label: '制单日期', value: String(row.elDate)},
      {label: '制单编号', value: row.elReqCode},
      // {label: '借款类别', value: row.elType},
      {label: '借款说明', value: row.elDesc},
      {label: '申请金额(元)', value: `${formatMoney(row.elAmount)}元（${convertCurrency(row.elAmount)}）`},
      {label: '收款账户', value: row.baNick1},
      {label: '付款账户', value: row.baNick2},
      {label: '借款日期', value: String(row.elPlannedReturnDate || '')},
      {label: '还款日期', value: String(row.elActualReturnDate || '')},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '存档批号', value: elFileRow?.faBatchNumber},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const staffLoanStore = new StaffLoanStore();
