import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {InvoiceRegisterReq, InvoiceRegisterRow} from "@services/dto/invoiceRegister";
import {InvoiceRegisterService} from "@services/InvoiceRegisterService";
import {convertCurrency, formatMoney} from "@common/utils";
import {getApplicationFrom} from "@components/TableApplicationFormText";

export class InvoiceRegisterStore {
  invoiceRegisterDatasource: StateFullData<Page<InvoiceRegisterRow>> = {}
  invoiceRegisterSelectDatasource: StateFullData<Page<InvoiceRegisterRow>> = {}
  invoiceRegisterRow?: InvoiceRegisterRow
  invoiceRegisterDetail: StateFullData<ResponseEntity<InvoiceRegisterRow>> = {}
  invoiceRegisterDetails: StateFullData<ResponsesEntity<InvoiceRegisterRow>> = {}
  invoiceRegisterReq: InvoiceRegisterReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateInvoiceRegisterRow(row: InvoiceRegisterRow) {
    this.invoiceRegisterRow = {...row}
  }

  getInvoiceRegister(req: InvoiceRegisterReq) {
    this.invoiceRegisterDatasource = {...this.invoiceRegisterDatasource, loading: true}
    this.invoiceRegisterReq = {...this.invoiceRegisterReq, ...req}
    InvoiceRegisterService.getInvoiceRegister(req).then(rsp => {
      runInAction(() => this.invoiceRegisterDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.invoiceRegisterDatasource = {...this.invoiceRegisterDatasource, loading: false, err})
    })
  }

  getSelectInvoiceRegister(req: InvoiceRegisterReq) {
    this.invoiceRegisterSelectDatasource = {...this.invoiceRegisterSelectDatasource, loading: true}
    this.invoiceRegisterReq = {...this.invoiceRegisterReq, ...req}
    InvoiceRegisterService.getInvoiceRegister(req).then(rsp => {
      runInAction(() => this.invoiceRegisterSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.invoiceRegisterSelectDatasource = {...this.invoiceRegisterSelectDatasource, loading: false, err})
    })
  }

  getDetailById(id: string) {
    this.invoiceRegisterDetail = {...this.invoiceRegisterDetail, loading: true}
    InvoiceRegisterService.getDetailById(id).then(rsp => {
      runInAction(() => this.invoiceRegisterDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.invoiceRegisterDetail = {...this.invoiceRegisterDetail, loading: false, err})
    })
  }

  getBatchDetailById(ids: string[]) {
    this.invoiceRegisterDetails = {...this.invoiceRegisterDetails, loading: true}
    InvoiceRegisterService.getBatch(ids).then(rsp => {
      runInAction(() => this.invoiceRegisterDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.invoiceRegisterDetails = {...this.invoiceRegisterDetails, loading: false, err})
    })
  }

  async getPrintList(record: InvoiceRegisterRow): Promise<LabelValue[]> {

    const row = this.invoiceRegisterDatasource.data?.items.find(o => o.id === record.id)

    // 关联申请表单
    // 通过导出的方法获取对应表格的值
    const irApplicationForm = await getApplicationFrom(row.irApplicationForm, row.irContractNoId)
    if (!row.irApplicationForm) {
      return
    }

    return [
      {label: '制单编号', value: row.irInvoiceCode2},
      {label: '开票日期', value: row.irDate as string},
      {label: '发票代码-号码', value: row.irInvoiceCode},
      {label: '发票类型', value: row.irType},
      {label: '开票内容', value: row.irContent},
      {label: '销货清单', value: row.salesListId},
      {label: '金额(元)', value: `${formatMoney(row.irAmount)}元（${convertCurrency(row.irAmount)}）`},
      {label: '税率', value: String(row.irTaxRate)},
      {label: '税额(元)', value: `${formatMoney(row.irTaxAmount)}元（${convertCurrency(row.irTaxAmount)}）`},
      {label: '价税合计(元)', value: `${formatMoney(row.irTaxRateSum)}元（${convertCurrency(row.irTaxRateSum)}）`},
      {label: '销售方', value: row.irSeller},
      {label: '购买方', value: row.irPurchaser},
      {
        label: '关联申请',
        value: (row.irApplicationForm && irApplicationForm) && `【${row.irApplicationForm}】${irApplicationForm}`
      },
      {label: '关联销项核算', value: row.outputAccountingCode},
      {label: '关联进项核算', value: row.inputAccountingCode},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '状态', value: row.irStatus},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const invoiceRegisterStore = new InvoiceRegisterStore();
