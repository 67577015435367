import React from "react";
import {Button, Col, Row} from "antd";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {SelectProject} from "@components/SelectProject";
import "./HeaderAction.less"

export interface PropsType {
    handleAdd: () => void
    handleBatchDelete: () => void
    handleProjectChange: (value: string) => void
}

export const HeaderAction = (props: PropsType) => {
  const handleProjectChange = (value: string) => {
    props.handleProjectChange(value)
  }

  const handleAdd = () => {
    props.handleAdd()
  }

  const handleBatchDelete = () => {
    props.handleBatchDelete()
  }

  return (
    <>
      <Row gutter={16}>
        <Col xl={{span: 8}} xs={12} className={"button-container"}>
          <SelectProject allowClear onChange={handleProjectChange}/>
        </Col>

        <Col xl={{span: 8, offset: 8}} xs={12} className={"button-container"}>
          <Button size={"middle"} shape={"round"} className={"margin-left-xs"}
            icon={<DeleteOutlined/>} onClick={handleBatchDelete}>删除</Button>

          <Button size={"middle"} shape={"round"} className={"margin-left-xs"}
            icon={<PlusOutlined />} type={"primary"} onClick={handleAdd}>新增</Button>
        </Col>
      </Row>
    </>
  )
}