import React, {useEffect, useState} from "react";
import {DatePicker} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {SelectDict} from "@components/SelectDict";
import dayjs from "dayjs";
import {Any} from "@common/types";
import {CustomInputNumber} from "@components/CustomInputNumber";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {TaxPlanReq} from "@services/dto/taxPlan";
import {TaxPlanService} from "@services/TaxPlanService";
import {CustomAuthHeaderAction, PropsType as ParentPropsType} from "@components/CustomAuthHeaderAction";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {SelectPersonCharge} from "@components/SelectPersonCharge";

export interface PropsType extends ParentPropsType {
  setConfirmLoading?: (loading: boolean) => void
  updateCount?: number, // 更新渲染标志
  refreshData?: (req: TaxPlanReq) => void,
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<TaxPlanReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: TaxPlanReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfaStatusChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efaStatus = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfaAccountingObjectChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efaAccountingObject1 = dateStrings[0]
    req.efaAccountingObject2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfaPayTypeChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efaPayType = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfaApplyAmountChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efaApplyAmount1 = start
    req.efaApplyAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfaAmountPayoutChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efaAmountPayout1 = start
    req.efaAmountPayout2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfaBalanceChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efaBalance1 = start
    req.efaBalance2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonResponsibleChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleOrganizersUserIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.organizersUserId = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    if (props.setConfirmLoading) {
      props.setConfirmLoading(true);
    }
    TaxPlanService.batchRefresh().then(() => {
      const req = {...searchReq}
      req.pageNum = 1
      req.perPage = 10
      req.keyword = undefined
      req.cooperationUnit = undefined
      req.responsibilityProject = undefined

      req.personResponsible = undefined
      req.personResponsibleName = undefined
      req.organizersUserId = undefined // 主办人员
      req.organizersUserName = undefined // 主办人员名
      req.efaPayType = undefined
      req.efaApplyCode = undefined
      req.efaAccountingObject1 = undefined
      req.efaAccountingObject2 = undefined
      req.efaApplyAmount1 = undefined
      req.efaApplyAmount2 = undefined
      req.efaAmountPayout1 = undefined
      req.efaAmountPayout2 = undefined
      req.efaStatus = undefined
      req.efaBalance1 = undefined
      req.efaBalance2 = undefined

      setSearchReq(req)
      refreshData(req)
    }).finally(() => {
      if (props.setConfirmLoading) {
        props.setConfirmLoading(false);
      }
    })
  }

  return (
    <>
      <CustomAuthHeaderAction
        size={props.size}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <DatePicker.RangePicker
            allowClear
            value={searchReq?.efaAccountingObject1 ? [dayjs(searchReq?.efaAccountingObject1), dayjs(searchReq?.efaAccountingObject2)] : [undefined, undefined]}
            placeholder={['制单开始日期', '制单结束日期']}
            onChange={handleEfaAccountingObjectChange}
            className={"width-100-percentage"}/>,
          <SelectDict
            updateCount={props.updateCount}
            code={'tax_plan_type'}
            onChange={handleEfaPayTypeChange}
            value={searchReq?.efaPayType}
            placeholder={'请选择税费类别'}
            allowClear
            className={"width-100-percentage"}/>,
          <CustomInputNumber
            value={[searchReq?.efaApplyAmount1, searchReq?.efaApplyAmount2]}
            placeholder={['税费金额最小', '税费金额最大']}
            className={'width-100-percentage'}
            onSearch={handleEfaApplyAmountChange}/>,
          <CustomInputNumber
            value={[searchReq?.efaAmountPayout1, searchReq?.efaAmountPayout2]}
            placeholder={['实现金额最小', '实现金额最大']}
            className={'width-100-percentage'}
            onSearch={handleEfaAmountPayoutChange}/>,
          <CustomInputNumber
            value={[searchReq?.efaBalance1, searchReq?.efaBalance2]}
            placeholder={['余额最小', '余额最大']}
            className={'width-100-percentage'}
            onSearch={handleEfaBalanceChange}/>,
          <SelectPersonCharge
            onChange={handlePersonResponsibleChange}
            className={"width-100-percentage"}
            placeholder={"请选择办理人员"}
            value={searchReq?.personResponsible}
            allowClear/>,
          <SelectPersonCharge
            onChange={handleOrganizersUserIdChange}
            className={"width-100-percentage"}
            placeholder={"请选择主办人员"}
            value={searchReq?.organizersUserId}
            allowClear/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
          <SelectCooperativeUnit
            onChange={handleCooperationUnitChange}
            value={searchReq?.cooperationUnit}
            allowClear
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectProcessStatus
            onChange={handleEfaStatusChange}
            allowClear
            value={searchReq?.efaStatus}
            placeholder={'请选择办理进度'}
            className={"width-100-percentage"}/>,
        ] : []}/>
    </>
  )
}))
