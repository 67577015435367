import React, {FC, ReactNode} from "react";
import {Button, Image} from "antd";

export interface PropsType {
    visible: boolean;
    src: string,
    setVisible: (value: boolean) => void
    onClick?: () => void
    children?: ReactNode
}

export const PreviewImage:FC<PropsType> = (props: PropsType) => {
  return (
    <>
      <div>
        {
          props.children || <Button type="link" onClick={props.onClick}>查看</Button>
        }
      </div>
      <div className={"hide"}>
        <Image height={0} width={0} src={props.src}
          preview={{visible: props.visible, src: props.src,
            onVisibleChange: value => {
              props.setVisible(value);
            },
          }}
        />
      </div>
    </>
  )
}