import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {FilingReq, FilingRow} from "@services/dto/filing";

export class FilingService {
  static async getFiling(req: FilingReq): Promise<Page<FilingRow>> {
    return await apiV1.get("/fileArchive/search", {params: req})
  }

  static async getGroupByFaBatchNumber(req: FilingReq): Promise<Page<FilingRow>> {
    return await apiV1.get("/fileArchive/getGroupByFaBatchNumber", {params: req})
  }

  static async addFiling(row: FilingRow): Promise<ResponseEntity<FilingRow>> {
    return await apiV1.post("/fileArchive/save", {...row})
  }

  static async updateFiling(row: FilingRow): Promise<ResponseEntity<FilingRow>> {
    return await apiV1.put("/fileArchive/update", {...row})
  }

  static async batchUpdateFiling(rows: FilingRow[]): Promise<ResponseEntity<FilingRow>> {
    return await apiV1.put("/fileArchive/batchUpdate", [...rows])
  }

  static async deleteFiling(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/fileArchive/delete", {data: ids})
  }

  static async getDetailById(id: string): Promise<ResponseEntity<FilingRow>> {
    return await apiV1.get(`/fileArchive/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<FilingRow>> {
    return await apiV1.get(`/fileArchive/getBatch/${ids.join(',')}`)
  }
}
