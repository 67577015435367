import {makeAutoObservable, runInAction} from "mobx";
import {Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {ProjectCollaborationService} from "@services/ProjectCollaborationService";
import {ProjectCollaborationReq, ProjectCollaborationRow} from "@services/dto/projectCollaboration";

export class ProjectCollaborationStore {
  projectCollaborationDatasource: StateFullData<Page<ProjectCollaborationRow>> = {}
  projectCollaborationSelectDatasource: StateFullData<Page<ProjectCollaborationRow>> = {}
  projectCollaborationRow?:ProjectCollaborationRow
  projectCollaborationOption?: string
  projectCollaborationDetail: StateFullData<ResponseEntity<ProjectCollaborationRow>> = {}
  projectCollaborationDetails: StateFullData<ResponsesEntity<ProjectCollaborationRow>> = {}
  projectCollaborationReq: ProjectCollaborationReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateProjectCollaborationRow(row: ProjectCollaborationRow) {
    this.projectCollaborationRow = {...row}
  }

  updateProjectCollaborationOption(option: string) {
    this.projectCollaborationOption = option
  }

  getProjectCollaboration(req: ProjectCollaborationReq) {
    this.projectCollaborationDatasource = {...this.projectCollaborationDatasource, loading: true}
    this.projectCollaborationReq = {...this.projectCollaborationReq, ...req}
    ProjectCollaborationService.getProjectCollaboration(req).then(rsp => {
      runInAction(() => this.projectCollaborationDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.projectCollaborationDatasource = {...this.projectCollaborationDatasource, loading: false, err})
    })
  }

  getSelectProjectCollaboration(req: ProjectCollaborationReq) {
    this.projectCollaborationSelectDatasource = {...this.projectCollaborationSelectDatasource, loading: true}
    this.projectCollaborationReq = {...this.projectCollaborationReq, ...req}
    ProjectCollaborationService.getProjectCollaboration(req).then(rsp => {
      runInAction(() => this.projectCollaborationSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.projectCollaborationSelectDatasource = {...this.projectCollaborationSelectDatasource, loading: false, err})
    })
  }

  getDetailById(id: string) {
    this.projectCollaborationDetail = {...this.projectCollaborationDetail, loading: true}
    ProjectCollaborationService.getDetailById(id).then(rsp => {
      runInAction(() => this.projectCollaborationDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.projectCollaborationDetail = {...this.projectCollaborationDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.projectCollaborationDetails = {...this.projectCollaborationDetails, loading: true}
    ProjectCollaborationService.getBatch(ids).then(rsp => {
      runInAction(() => this.projectCollaborationDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.projectCollaborationDetails = {...this.projectCollaborationDetails, loading: false, err})
    })
  }
}

export const projectCollaborationStore = new ProjectCollaborationStore();
