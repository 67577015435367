import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {HeaderAction} from "@components/HeaderAction";
import {ColumnsType} from "antd/es/table";
import {Any} from "@common/types";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {WorkPostReq} from "@services/dto/workPost";

export interface PropsType {
  updateCount?: number, // 更新渲染标志
  collapsed?: boolean, // 是否展开
  handleShowColumnReset?: () => void, // 重置列操作
  setCollapsed?: (v: boolean) => void, // 展开事件
  searchReq?: WorkPostReq, // 请求参数
  selectedRowKeys?: React.Key[], // 所选行
  handleDeleteBatch?: () => void,
  handleAdd?: () => void,
  handleTableColumnsChange?: (value: string[]) => void,
  showColumn?: string[],
  tableColumns?: ColumnsType,
  refreshData?: (req: WorkPostReq) => void,
  size?: number
  isHiddenOptions?: boolean
  locationPathname?: string
  items?: React.ReactNode[]
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<WorkPostReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: WorkPostReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }



  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined

    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <HeaderAction
        size={props.size}
        isHiddenOptions={props.isHiddenOptions}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        selectedRowKeys={props.selectedRowKeys}
        handleDeleteBatch={props.handleDeleteBatch}
        handleAdd={props.handleAdd}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            statisticsItemsReq={{
              table: 'fundingApproval',
              field: 'responsibilityProject',
            }}
            allowClear
            fieldNames={{value: 'id', label: 'name'}}/>,
          <SelectCooperativeUnit
            onChange={handleCooperationUnitChange}
            value={searchReq?.cooperationUnit}
            allowClear
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
        ] : []}/>
    </>
  )
}))
