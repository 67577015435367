import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import dayjs from "dayjs";
import {ProcessRow} from "@services/dto/process";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {allStores} from "@/stores";
import {SelectNotificationStatus} from "@components/SelectNotificationStatus";
import {SelectApplicationFormType} from "@components/SelectApplicationFormType";

export interface PropsType {
  row: ProcessRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores
  const [businessId, setBusinessId] = useState<string>()

  useEffect(() => {
    form.resetFields()
    const processRow = {...props.row}
    filingStore.setFilingFaCode(processRow.wnCode)
    if (processRow.id) {
      if (typeof processRow.wnDate === 'string' && processRow.wnDate !== '') {
        processRow.wnDate = dayjs(processRow.wnDate)
      }
      if (typeof processRow.notificationScopeUserId === 'string' && processRow.notificationScopeUserId) {
        processRow.notificationScopeUserId = processRow.notificationScopeUserId.split(',')
      }
      if (processRow.businessId) {
        setBusinessId(processRow.businessId)
      }
    }
    form.setFieldsValue(processRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="notificationScopeUserName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="businessId">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="wnDate"
            rules={[{required: false, message: '请输入制单日期!'}]}>
            <DatePicker
              disabled
              placeholder={"请选择制单日期"}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="wnCode"
            rules={[{required: false, message: '请输入制单编号!'}]}>
            <Input
              disabled
              placeholder="请输入制单编号"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="通知摘要"
            name="wnDesc"
            rules={[{required: false, message: '请输入通知摘要!'}]}>
            <Input.TextArea
              disabled
              placeholder="请输入通知摘要"
              autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联表单"
            name="type"
            rules={[{required: false, message: '请输入关联表单!'}]}>
            <SelectApplicationFormType
              isOption
              tableValue={businessId}
              placeholder={"请选择关联表单"}
              disabled
              allowClear></SelectApplicationFormType>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: false, message: '请选择责任部门!'}]}>
            <SelectProject
              disabled
              placeholder={"请选择责任部门"}
              allowClear
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              disabled
              allowClear
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="主送"
            name="personResponsible"
            rules={[{required: false, message: '请选择主送!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择主送"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="抄送"
            name="notificationScopeUserId"
            rules={[{required: false, message: '请选择抄送!'}]}>
            <SelectPersonCharge
              disabled
              mode={'multiple'}
              className={"width-100-percentage"}
              placeholder={"请选择抄送"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="wnStatus"
            rules={[{required: false, message: '请输入办理进度!'}]}>
            <SelectNotificationStatus
              allowClear
              disabled
              className={"width-100-percentage"}
              placeholder={'请选择办理进度'}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
