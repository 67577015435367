import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import {FormInstance} from "antd/es";
import {CascaderPerson} from "@components/CascaderPerson";
import {PersonCascader} from "@services/dto/talent";
import {AdvanceRow, CheckAdvanceReq} from "@services/dto/advance";
import dayjs from "dayjs";
import {AdvanceService} from "@services/AdvanceService";
import {showErr} from "@common/utils";
import {DATE_MONTH_FMT} from "@/config";

export interface PropsType {
  row: AdvanceRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props, ref) => {
  const [money, setMoney] = useState<number>()
  const [checkReq, setCheckReq] = useState<CheckAdvanceReq>({projectId: "", uid: "", year: 0, month: 0, date: ""})

  const [form] = Form.useForm()
  useEffect(() => {
    form.resetFields()
    const advanceRow = {...props.row}
    if (props.row.id) {
      if (advanceRow.date) {
        advanceRow.date = dayjs(advanceRow.date, DATE_MONTH_FMT);
      }
      advanceRow.person = [advanceRow.projectId, advanceRow.professionalLeagues, advanceRow.professional, advanceRow.uid]
    }
    form.setFieldsValue(advanceRow);
  }, [props.row])

  const handlePersonChange = (value: string[], selectedOptions: PersonCascader[]) => {
    if (value && value.length > 0) {
      const project = selectedOptions.find(item => item.value === value[0]);
      if (project) {
        assignmentProjectValue(value, project)
      } else {
        initProjectValue()
      }
    } else {
      initProjectValue()
    }
    return value
  }

  const assignmentProjectValue = (value: string[], project: PersonCascader) => {
    const req = {...checkReq, projectId: value[0], uid: value[3]}
    setCheckReq(req)
    form.setFieldValue("projectName", project.label);
    checkAdvancePayments(req)
  }
  
  const initProjectValue = () => {
    form.setFieldValue("projectName", "");
    const req = {...checkReq, projectId: "", uid: ""}
    setCheckReq(req)
    setMoney(0)
  }

  const handleDate = (value: dayjs.Dayjs | null, dateString: string) => {
    if (value) {
      const req = {...checkReq, year: value.year(), month: value.month() + 1, date: dateString}
      setCheckReq(req)
      checkAdvancePayments(req)
    } else {
      const req = {...checkReq, year: 0, month: 0, date: dateString}
      setCheckReq(req)
      setMoney(0)
    }
  }

  const checkAdvancePayments = (req: CheckAdvanceReq) => {
    if (req.date && req.uid && req.year && req.month && req.projectId) {
      AdvanceService.checkAdvancePayments(req).then(rsp => {
        setMoney(rsp.data)
      }).catch(err => {
        showErr(err)
        setMoney(0)
      });
    } else {
      setMoney(0)
    }
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 18}}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Form.Item hidden name="id">
            <Input/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item hidden name="projectName">
            <Input/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="员工"
            name="person"
            valuePropName={"person"}
            getValueFromEvent={handlePersonChange}
            rules={[{ required: true, message: '请选择员工!' }]}>
            <CascaderPerson disabled={!!props.row.id}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="预支月份"
            name="date"
            rules={[{ required: true, message: '请选择预支月份!' }]}>
            <DatePicker
              onChange={handleDate}
              disabledDate={(cur) => cur.isBefore(dayjs().subtract(1, "month"))}
              placeholder={"请选择预支月份"}
              picker={"month"}
              className={'width-100-percentage'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="预支金额"
            name="money"
            extra={money && `你可以预支的金额为：${money} 元`}
            rules={[{required: true, message: '请输入预支金额!'}]}>
            <InputNumber precision={2} placeholder={"请输入预支金额"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="代领人"
            name="leadingPeopleIRecipients"
            rules={[{required: false, message: '请输入代领人!'}]}>
            <Input placeholder="请输入代领人"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="代领人银行卡"
            name="bankCardNumber"
            rules={[{required: false, message: '请输入代领人银行卡!', pattern: /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/}]}>
            <Input placeholder="请输入代领人银行卡"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="备注"
            name="remark"
            rules={[{required: false, message: '请输入备注!'}]}>
            <Input.TextArea placeholder="请输入备注" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))