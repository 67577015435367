import {makeAutoObservable, runInAction} from "mobx";
import {Page, StateFullData} from "@services/dto/common";
import {ContractLedgerRow, PageParam} from "@services/dto/contractLedger";
import {ContractLedgerService} from "@services/ContractLedgerService";

export class ContractLedgerStore {
  contractLedgerDatasource: StateFullData<Page<ContractLedgerRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getContractLedger(req: PageParam) {
    this.contractLedgerDatasource = {...this.contractLedgerDatasource, loading: true}
    ContractLedgerService.getContractLedger(req).then(rsp => {
      runInAction(() => this.contractLedgerDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.contractLedgerDatasource = {...this.contractLedgerDatasource, loading: false, err})
    })
  }
}

export const contractLedgerStore = new ContractLedgerStore();
