import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {Button, Drawer, FormInstance, message, Modal, Space, Table} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {logger, safeCompute, showErr} from "@common/utils";
import {
  DATE_FMT,
  DEFAULT_MODAL_WIDTH,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  SMALL_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import {TableOptions} from "src/components/TableOptions";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {useLocation} from "react-router-dom";
import {ConferenceRecordReq, ConferenceRecordRow} from "@services/dto/conferenceRecord";
import {BasicInfo} from "@modules/ConferenceRecord/Components/BasicInfo";
import {HeaderActionCom} from "@modules/ConferenceRecord/Components/HeaderActionCom";
import {ConferenceRecordService} from "@services/ConferenceRecordService";

const blackColumnList = [
  'action',
]

const wideShowColumn: string[] = [
  'mmDate',
  'mmCode',
  'mmTitle',
  'mmMdate',
  'mmAddress',
  'mmUserNames',
  'responsibilityProject',
  'cooperationUnit',
  'mmStatus',
  'action',
]

const narrowShowColumn: string[] = [
  'mmDate',
  'mmCode',
  'mmTitle',
  'mmMdate',
  'mmAddress',
  'mmUserNames',
  'responsibilityProject',
  'cooperationUnit',
  'mmStatus',
  'action',
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: ConferenceRecordRow[] // 数据源
}

export const ConferenceRecord = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const {
    conferenceRecordStore,
    screenResolutionStore,
    tableColumnsStore,
  } = allStores
  const basicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<ConferenceRecordRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [conferenceRecordRow, setConferenceRecordRow] = useState<ConferenceRecordRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<ConferenceRecordRow>>([])
  const location = useLocation()
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [searchReq, setSearchReq] = useState<ConferenceRecordReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...conferenceRecordStore.conferenceRecordReq
  })

  const columns: ColumnsType<ConferenceRecordRow> = [
    {
      title: '制单日期',
      dataIndex: 'mmDate',
      key: 'mmDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'mmCode',
      key: 'mmCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '会议主题',
      dataIndex: 'mmTitle',
      key: 'mmTitle',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '会议日期',
      dataIndex: 'mmMdate',
      key: 'mmMdate',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '会议地点',
      dataIndex: 'mmAddress',
      key: 'mmAddress',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '与会人员',
      dataIndex: 'mmUserNames',
      key: 'mmUserNames',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'mmFileCode',
      key: 'mmFileCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理人员',
      dataIndex: 'personResponsibleName',
      key: 'personResponsibleName',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '主办人员',
      dataIndex: 'organizersUserName',
      key: 'organizersUserName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'mmStatus',
      key: 'mmStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableOptions
          handleDelete={() => {
            handleDeleteBatch(record)
          }}
          handleEdit={() => {
            handleEdit(record)
          }}
          handleView={() => {
            handleView(record)
          }}
          onClick={() => {
            handleEdit(record)
          }}>
            修改
        </TableOptions>
      )
    }
  ];

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  useEffect(() => {
    if (conferenceRecordStore.conferenceRecordDatasource.data) {
      setDataSource(conferenceRecordStore.conferenceRecordDatasource.data.items || [])
      setTotal(conferenceRecordStore.conferenceRecordDatasource.data.total || 0)
    }
  }, [conferenceRecordStore.conferenceRecordDatasource.data])

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setScrollHeight(SMALL_TABLE_SCROLL_HEIGHT)
    } else {
      setScrollHeight(DEFAULT_TABLE_SCROLL_HEIGHT)
    }
  }, [screenResolutionStore.screenResolution])

  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => o.key !== 'action').filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, conferenceRecordStore.collapsed, searchReq.pageNum, searchReq.perPage])

  const handleAdd = () => {
    setConferenceRecordRow(undefined)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handleView = (record: ConferenceRecordRow) => {
    setConferenceRecordRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = (record: ConferenceRecordRow) => {
    setConferenceRecordRow(record)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleDeleteBatch = (record?: ConferenceRecordRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        ConferenceRecordService.deleteConferenceRecord(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(searchReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: ConferenceRecordReq) => {
    setSearchReq(req)
    conferenceRecordStore.getConferenceRecord(req)
  }

  const handleOk = () => {
    const row = {...conferenceRecordRow!}
    basicInfo.current!.validateFields().then(values => {
      if (typeof values.mmDate === 'object' && values.mmDate) {
        values.mmDate = values.mmDate.format(DATE_FMT);
      }
      if (typeof values.mmMdate === 'object' && values.mmMdate) {
        values.mmMdate = values.mmMdate.format(DATE_FMT);
      }
      if (typeof values.mmUserIds === 'object' && values.mmUserIds) {
        values.mmUserIds = values.mmUserIds.join(",")
      }
      if (typeof values.mmUserNames === 'object' && values.mmUserNames) {
        values.mmUserNames = values.mmUserNames.join(",")
      }
      if (typeof values.cooperationUnit === 'object' && values.cooperationUnit) {
        values.cooperationUnit = values.cooperationUnit.join(",")
      } else {
        values.cooperationUnit = ''
      }
      if (typeof values.meetingMinutesIds === 'object' && values.meetingMinutesIds) {
        values.meetingMinutesFroms = values.meetingMinutesIds.map(item => ({
          id: '',
          meetingMinutesId: row.id,
          businessId: item,
          businessCode: ''
        }))
        values.meetingMinutesIds = values.meetingMinutesIds.join(",")
      } else {
        values.meetingMinutesFroms = []
        values.meetingMinutesIds = ''
      }
      Object.assign(row, values)
      setConfirmLoading(true)
      if (row.id) {
        ConferenceRecordService.updateConferenceRecord(row).then(() => {
          setUpdateCount(updateCount + 1)
          actionThen();
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        ConferenceRecordService.addConferenceRecord(row).then(() => {
          setUpdateCount(updateCount + 1)
          actionThen();
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  }

  const actionThen = () => {
    message.success("操作成功！").then()
    setIsModalVisible(false);
    setConferenceRecordRow(undefined)
    refreshData(searchReq)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(location.pathname, value)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    conferenceRecordStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        isHiddenOptions={props.isHiddenOptions}
        updateCount={updateCount}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        selectedRowKeys={selectedRowKeys}
        handleShowColumnReset={handleShowColumnReset}
        handleDeleteBatch={handleDeleteBatch}
        handleAdd={handleAdd}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        collapsed={conferenceRecordStore.collapsed}
        handleKeywordSearch={() => refreshData({...searchReq, pageNum: 1})}
        handleKeywordChange={(value: string) => setSearchReq({...searchReq, keyword: value})}
        tableColumns={props.isModalSelect ? columns.filter(o => !blackColumnList.includes(o.key as string)) : columns}
        items={[]}
        refreshData={(req) => refreshData({...searchReq, ...req})}></HeaderActionCom>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={dataSource}
        columns={renderColumns}
        size={"middle"}
        loading={conferenceRecordStore.conferenceRecordDatasource.loading || confirmLoading}
        bordered
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys, columnWidth: 50}}/>

      <Drawer
        title={<div className="text-align-center">会议管理</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        destroyOnClose
        onClose={handleCancel}>

        <BasicInfo row={{...conferenceRecordRow!}} ref={basicInfo} isView={isView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button
              type="primary"
              onClick={handleOk}
              loading={confirmLoading}
              className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>
    </div>
  )
}))
