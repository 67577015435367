import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {DescriptionsProps, Space, Table, Tag} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {DEFAULT_TABLE_SCROLL_HEIGHT, SMALL_TABLE_SCROLL_HEIGHT, TABLE_CELL_WIDTH_1x} from "@/config";
import {
  BalanceReconciliationRow,
  BalanceReconciliationSum,
  BankAccountStatisticsReq,
  BankAccountStatisticsRow
} from "@services/dto/bankAccount";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import {BalanceReconciliation} from "@modules/BankAccount/Components/BalanceReconciliation";
import {HeaderActionCom} from "@modules/BankAccount/Components/StatisticsHeaderActionCom";
import {useLocation} from "react-router-dom";
import {Key} from "antd/es/table/interface";
import {accAdd, safeCompute} from "@common/utils";

const wideShowColumn: string[] = [
  "spare",
  "baAccount",
  "responsibilityProject",
  "initialBalance",
  "currentIncomeAmount",
  "currentExpenditureAmount",
  "forTheCurrentPeriod",
  "endingBalance",
  "status",
]

const narrowShowColumn: string[] = [
  "spare",
  "baAccount",
  // "responsibilityProject",
  // "initialBalance",
  // "currentIncomeAmount",
  "currentExpenditureAmount",
  "forTheCurrentPeriod",
  "endingBalance",
  "status",
]

export const BankAccountStatistics = observer(() => {
  const {bankAccountStore, tableColumnsStore, screenResolutionStore} = allStores
  const [dataSource, setDataSource] = useState<BankAccountStatisticsRow[]>([])
  const [total, setTotal] = useState<number>(0)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [balanceReconciliationRow, setBalanceReconciliationRow] = useState<BalanceReconciliationRow[]>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchReq, setSearchReq] = useState<BankAccountStatisticsReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    bankAccountId: '',
  })
  const [balanceReconciliationSum, setBalanceReconciliationSum] = useState<BalanceReconciliationSum>({
    initialBalanceTotal: 0,
    currentIncomeAmountTotal: 0,
    currentExpenditureAmountTotal: 0,
    forTheCurrentPeriodTotal: 0,
    endingBalanceTotal: 0
  })

  const [scrollHeight, setScrollHeight] = useState<number>(SMALL_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<BankAccountStatisticsRow>>([])
  const location = useLocation()
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)

  const [items, setItems] = useState<DescriptionsProps['items']>([])

  const columns: ColumnsType<BankAccountStatisticsRow> = [
    {
      title: '月份',
      dataIndex: 'spare',
      key: 'spare',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '资金账户',
      dataIndex: 'baAccount',
      key: 'baAccount',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '期初余额',
      dataIndex: 'initialBalance',
      key: 'initialBalance',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '当期收入金额',
      dataIndex: 'currentIncomeAmount',
      key: 'currentIncomeAmount',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '当期支出金额',
      dataIndex: 'currentExpenditureAmount',
      key: 'currentExpenditureAmount',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '当期收支差额',
      dataIndex: 'forTheCurrentPeriod',
      key: 'forTheCurrentPeriod',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '期末余额',
      dataIndex: 'endingBalance',
      key: 'endingBalance',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '余额对账',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text, record) => (
        <Space className={'c-pointer'} onClick={() => {
          handleView(record)
        }}>
          <Tag color={text === '余额相符' ? "success" : 'error'}>{ text }</Tag>
        </Space>
      )
    },
  ];

  useEffect(() => {
    refreshData(searchReq)
  }, [])

  useEffect(() => {
    if (bankAccountStore.bankAccountStatisticsDatasource.data?.items) {
      setDataSource(bankAccountStore.bankAccountStatisticsDatasource.data?.items)
      setTotal(bankAccountStore.bankAccountStatisticsDatasource.data?.total)
    }
  }, [bankAccountStore.bankAccountStatisticsDatasource])

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setScrollHeight(SMALL_TABLE_SCROLL_HEIGHT)
    } else {
      setScrollHeight(DEFAULT_TABLE_SCROLL_HEIGHT)
    }
  }, [screenResolutionStore.screenResolution])

  useEffect(() => {
    setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))))
  }, [showColumn])

  const handleView = (record: BankAccountStatisticsRow) => {
    setItems([
      {
        key: '责任部门',
        label: '责任部门',
        children: record.responsibilityProject || '-',
      },
      {
        key: '银行账户',
        label: '银行账户',
        children: record.baAccount || '-',
      },
      {
        key: '统计区间',
        label: '统计区间',
        children: record.spare || '-',
      },
    ])
    setBalanceReconciliationRow(record.bankAccountStatisticsVoList)
    setIsModalVisible(true);
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const refreshData = (req: BankAccountStatisticsReq) => {
    setSearchReq(req)
    bankAccountStore.getBankAccountStatistics(req)
  }

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }
  }, []);

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, bankAccountStore.collapsed, searchReq.pageNum, searchReq.perPage])

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(location.pathname, value)
  }

  const handleRowSelectionChange = (selectedRowKeys: Key[], selectedRows: BankAccountStatisticsRow[]) => {
    setSelectedRowKeys(selectedRowKeys)
    let balanceReconciliationSum = {
      initialBalanceTotal: 0,
      currentIncomeAmountTotal: 0,
      currentExpenditureAmountTotal: 0,
      forTheCurrentPeriodTotal: 0,
      endingBalanceTotal: 0
    }
    if (selectedRows.length > 0) {
      setBalanceReconciliationSum(selectedRows.reduce((acc, cur) => ({
        initialBalanceTotal: accAdd(acc.initialBalanceTotal, cur.initialBalance),
        currentIncomeAmountTotal: accAdd(acc.currentIncomeAmountTotal, cur.currentIncomeAmount),
        currentExpenditureAmountTotal: accAdd(acc.currentExpenditureAmountTotal, cur.currentExpenditureAmount),
        forTheCurrentPeriodTotal: accAdd(acc.forTheCurrentPeriodTotal, cur.forTheCurrentPeriod),
        endingBalanceTotal: accAdd(acc.endingBalanceTotal, cur.endingBalance),
      }), balanceReconciliationSum))
    } else {
      setBalanceReconciliationSum(balanceReconciliationSum)
    }
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    bankAccountStore.setCollapsed(collapsed)
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        selectedRowKeys={selectedRowKeys}
        handleTableColumnsChange={handleTableColumnsChange}
        collapsed={bankAccountStore.collapsed}
        setCollapsed={handleCollapsedChange}
        showColumn={showColumn}
        tableColumns={columns}
        refreshData={(req) => refreshData({...searchReq, ...req})}/>

      <Table
        className={"margin-top-xs"}
        rowKey={"id"}
        dataSource={dataSource}
        columns={renderColumns}
        size={"small"}
        loading={bankAccountStore.bankAccountStatisticsDatasource.loading}
        bordered
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0} colSpan={2} className='text-align-center'>
                已选：{ selectedRowKeys.length }
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} colSpan={2}/>
              <Table.Summary.Cell index={4} colSpan={1} className='text-align-right'>
                合计：<TableMoneyText value={balanceReconciliationSum.initialBalanceTotal}></TableMoneyText>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5} colSpan={1} className='text-align-right'>
                合计：<TableMoneyText value={balanceReconciliationSum.currentIncomeAmountTotal}></TableMoneyText>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={6} colSpan={1} className='text-align-right'>
                合计：<TableMoneyText value={balanceReconciliationSum.currentExpenditureAmountTotal}></TableMoneyText>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={7} colSpan={1} className='text-align-right'>
                合计：<TableMoneyText value={balanceReconciliationSum.forTheCurrentPeriodTotal}></TableMoneyText>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={8} colSpan={1} className='text-align-right'>
                合计：<TableMoneyText value={balanceReconciliationSum.endingBalanceTotal}></TableMoneyText>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={9} colSpan={1}/>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        rowSelection={{selectedRowKeys, onChange: handleRowSelectionChange, columnWidth: 50}}/>

      { /*余额不符的弹窗页面*/ }
      <BalanceReconciliation
        dataSource={balanceReconciliationRow}
        items={items}
        open={isModalVisible}
        onChange={isModalVisible => setIsModalVisible(isModalVisible)}/>
    </div>
  )
})
