import {observer} from "mobx-react";
import dayjs, {Dayjs} from "dayjs";
import {DATE_FMT, DATE_MONTH_FMT} from "@/config";
import {CalendarMonth} from "@components/CalendarMonth";
import React, {useEffect, useState} from "react";
import {CreateWorkRecordReq, UpdateWorkRecordReq, WorkRecord} from "@services/dto/workTime";
import {Button, Card, Col, InputNumber, Modal, Row} from "antd";
import {allStores} from "@/stores";
import {useNavigate, useParams} from "react-router-dom";
import {showErr} from "@common/utils";
import {WorkTimeService} from "@services/WorkTimeService";
import {LeftOutlined} from "@ant-design/icons";
import "./AttendanceSheet.less"

export const AttendanceSheet = observer(() => {
  const {workTimeStore} = allStores
  const {workTimes} = workTimeStore
  const params = useParams()
  const navigator = useNavigate()
  const [id, setId] = useState<string>("")
  const [workRecords, setWorkRecords] = useState<WorkRecord[]>([]);
  const [nowYearMonth, setNowYearMonth] = useState<dayjs.Dayjs>(dayjs())
  const [workTime, setWorkTime] = useState<number>(0);
  const [key, setKey] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [spinning, setSpinning] = useState<boolean>(false)

  useEffect(() => {
    refreshData()
  }, [params.userId, nowYearMonth])

  const refreshData = (reCalculate?: boolean) => {
    workTimeStore.getWorkerTimes({
      userId: params.userId!,
      year: nowYearMonth.year(),
      month: nowYearMonth.month() + 1,
      reCalculate
    })
  }

  useEffect(() => {
    setDaysInMonth()
  }, [workTimeStore.workTimes])

  const setDaysInMonth = () => {
    if (!workTimes.data || workTimes.data.items.length === 0) {
      setWorkRecords([])
      setId("")
      return;
    }
    let works: WorkRecord[] = [];
    workTimes.data.items.forEach(item => {
      setId(item._id)
      works = works.concat(item.workRecords);
    });
    setWorkRecords(works)
  }

  const renderDay = (date: Dayjs) => {
    const key = date.format(DATE_FMT);
    const record = workRecords.find(i => i.workDate === key)
    if (!record) {
      return <>
        <div className="record no-record">无数据</div>
      </>
    }
    if (record.spendTime <= 0) {
      return <>
        <div className="record not-work">未出勤</div>
      </>
    }
    return <>
      <div className="record work">工作{ Math.min(record.spendTime, 8).toFixed(1) }时</div>
      { record.spendTime > 8 && <div className="record work">加班{ (record.spendTime - 8).toFixed(1) }时</div> }
    </>;
  }

  const handleDayChange = (date: Dayjs) => {
    const key = date.format(DATE_FMT);
    const record = workRecords.find(i => i.workDate === key)
    if (record) {
      setWorkTime(record.spendTime);
    } else {
      setWorkTime(0)
    }
    setKey(key)
    setIsModalVisible(true)
  }

  const handleOk = () => {
    const records = [...workRecords || []]
    if (id) {
      const record = records.find(i => i.workDate === key);
      if (record) {
        record.spendTime = workTime;
      } else {
        records.push({workDate: key, spendTime: workTime, clocIns: []})
      }
      const row:UpdateWorkRecordReq = {
        workRecords: convertWorkRecords(records)
      }
      updateWorkerTimes(row)
    } else {
      for (let i = 1; i <= nowYearMonth.daysInMonth(); i++) {
        const workDate = dayjs(nowYearMonth.toISOString()).set("dates", i).format(DATE_FMT)
        if (workDate === key) {
          records.push({workDate: workDate, spendTime: workTime, clocIns: []})
        } else {
          records.push({workDate: workDate, spendTime: 0, clocIns: []});
        }
      }
      const row: CreateWorkRecordReq = {
        year: nowYearMonth.year(),
        month: nowYearMonth.month() + 1,
        userId: params.userId!,
        workRecords: convertWorkRecords(records)
      }
      addWorkerTimes(row)
    }
  };

  const convertWorkRecords = (records: WorkRecord[]) => {
    return records.map(item => ({
      workDate: item.workDate,
      spendTime: item.spendTime,
      clocIns: item.clocIns
    }))
  }

  const updateWorkerTimes = (row: UpdateWorkRecordReq) => {
    setSpinning(true)
    WorkTimeService.updateWorkerTimes(id, row).then(rsp => {
      refreshData()
      setIsModalVisible(false);
    }).catch(showErr).finally(() => {
      setSpinning(false)
    });
  }

  const addWorkerTimes = (row: CreateWorkRecordReq) => {
    setSpinning(true)
    WorkTimeService.addWorkerTimes(row).then(rsp => {
      refreshData()
      setIsModalVisible(false);
    }).catch(showErr).finally(() => {
      setSpinning(false)
    });
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  
  const backPage = () => {
    navigator(-1)
  }
  
  const handleDateChange = (dateString: string) => {
    setNowYearMonth(dayjs(dateString))
  }

  return (
    <>
      <Row>
        <Col span={12}>
          <Button shape={"round"}>{ params.userName }--月考勤统计表</Button>
        </Col>
        <Col span={12} className={"text-align-right"}>
          <Button shape={"round"} icon={<LeftOutlined />} type={"primary"} onClick={backPage}>返回上一页</Button>
        </Col>
      </Row>
      <Card className={"margin-top-sm"}>
        <div className="work-time-calendar">
          <CalendarMonth date={dayjs(nowYearMonth.format(DATE_MONTH_FMT))}
            renderDay={(date) => <div className="day-cell">{ renderDay(date) }</div>}
            popupDay={handleDayChange} handleDateChange={handleDateChange}/>
        </div>

        <Modal title={key} open={isModalVisible} onOk={handleOk} onCancel={handleCancel} maskClosable={false} confirmLoading={spinning}>
          <InputNumber
            placeholder={"请输入工作时长"}
            addonAfter={"时"}
            precision={1}
            value={workTime}
            onChange={(e) => setWorkTime(e || 0)}
            className={"width-100-percentage"}/>
        </Modal>
      </Card>
    </>
  )
})