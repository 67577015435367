import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {WorkScheduleReq, WorkScheduleRow} from "@services/dto/workSchedule";

export class WorkScheduleService {
  static async getWorkSchedule(req: WorkScheduleReq): Promise<Page<WorkScheduleRow>> {
    return await apiV1.get("/workSchedule/search", {params: req})
  }

  static async addWorkSchedule(row: WorkScheduleRow): Promise<ResponseEntity<WorkScheduleRow>> {
    return await apiV1.post("/workSchedule/save", {...row})
  }

  static async updateWorkSchedule(row: WorkScheduleRow): Promise<ResponseEntity<WorkScheduleRow>> {
    return await apiV1.put("/workSchedule/update", {...row})
  }

  static async deleteWorkSchedule(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/workSchedule/delete", {data: ids})
  }

  static async getOne(id: string): Promise<ResponseEntity<WorkScheduleRow>> {
    return await apiV1.get(`/workSchedule/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<WorkScheduleRow>> {
    return await apiV1.get(`/workSchedule/getBatch/${ids.join(',')}`)
  }
}
