import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {MaterialListReq, MaterialListRow} from "@services/dto/materialList";

export class MaterialListService {
  static async getMaterialList(req: MaterialListReq): Promise<Page<MaterialListRow>> {
    return await apiV1.get("/materialList/search", {params: req})
  }

  static async addMaterialList(row: MaterialListRow): Promise<ResponseEntity<MaterialListRow>> {
    return await apiV1.post("/materialList/save", {...row})
  }

  static async updateMaterialList(row: MaterialListRow): Promise<ResponseEntity<MaterialListRow>> {
    return await apiV1.put("/materialList/update", {...row})
  }

  static async deleteMaterialList(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/materialList/delete", {data: ids})
  }
}
