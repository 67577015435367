import {observer} from "mobx-react";
import {Card, Empty, message, Space, Tag} from "antd";
import {useEffect, useState} from "react";
import './WorkNotice.less'
import {ProcessReq, ProcessRow} from "@services/dto/process";
import {allStores} from "@/stores";
import {useNavigate} from "react-router-dom";
import {ProcessService} from "@services/ProcessService";
import {DATE_FMT} from "@/config";

export const WorkNotice = observer(() => {

  const {
    securityStore,
    headerSearchStore,
    processStore,
    workScheduleStore,
    costExpendStore,
    costReimbursementStore,
    staffLoanStore,
    contractListStore,
    contractSettlementStore,
    costApplicationStore,
    fundApprovalStore,
    bankStatementStore,
  } = allStores

  const navigate = useNavigate()
  const tagsData = ['主送给我', '抄送给我'];
  const currentUserId = securityStore.getLoggedUser()._id
  const [selectedTag, setSelectedTag] = useState<string>(tagsData[0]);
  const [dataSource, setDataSource] = useState<ProcessRow[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [searchReq, setSearchReq] = useState<ProcessReq>({
    pageNum: 1,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...processStore.processReq,
    personResponsible: currentUserId,
    notificationScopeUserId: undefined,
    perPage: 999,
    wnStatus: '未读',
  })

  // 初始化 - 查询数据
  useEffect(() => {
    refreshData(searchReq);
  }, []);

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    if (processStore.processDatasource.data) {
      setDataSource(processStore.processDatasource.data.items || [])
    }
  }, [processStore.processDatasource.data])

  const refreshData = (req: ProcessReq) => {
    setSearchReq(req)
    processStore.getProcess(req)
  }

  const handleChange = (tag: string, checked: boolean) => {
    if (checked) {
      const req = {...searchReq}
      req.pageNum = 1
      if (tag === '主送给我') {
        req.personResponsible = currentUserId
        req.notificationScopeUserId = undefined
      } else if (tag === '抄送给我') {
        req.personResponsible = undefined
        req.notificationScopeUserId = currentUserId
      }
      setSearchReq(req)
      refreshData(req)
      setSelectedTag(tag)
    }
  }

  // 跳转 费用支出
  const handleCostExpend = async (record: ProcessRow) => {
    try {
      const row = processStore.processDatasource.data?.items.find(o => o.id === record.id)
      row.wnStatus = '已读'
      await ProcessService.updateProcess(row)
      costExpendStore.updateCostExpendOption(row.notificationScopeUserId.includes(currentUserId) ? 'view' : `edit?id=${record.businessId}`)
    } catch (e) {
      message.warning('操作失败')
    }
    navigate('/costExpend')
  }

  // 跳转 费用报销
  const handleCostReimbursement = async (record: ProcessRow) => {
    try {
      const row = processStore.processDatasource.data?.items.find(o => o.id === record.id)
      row.wnStatus = '已读'
      await ProcessService.updateProcess(row)
      costReimbursementStore.updateCostReimbursementOption(row.notificationScopeUserId.includes(currentUserId) ? 'view' : `edit?id=${record.businessId}`)
    } catch (e) {
      message.warning('操作失败')
    }
    navigate('/costReimbursement')
  }

  // 跳转 员工借款
  const handleStaffLoan = async (record: ProcessRow) => {
    try {
      const row = processStore.processDatasource.data?.items.find(o => o.id === record.id)
      row.wnStatus = '已读'
      await ProcessService.updateProcess(row)
      staffLoanStore.updateStaffLoanOption(row.notificationScopeUserId.includes(currentUserId) ? 'view' : `edit?id=${record.businessId}`)
    } catch (e) {
      message.warning('操作失败')
    }
    navigate('/staffLoan')
  }

  // 跳转 费用申请
  const handleCostApplication = async (record: ProcessRow) => {
    try {
      const row = processStore.processDatasource.data?.items.find(o => o.id === record.id)
      row.wnStatus = '已读'
      await ProcessService.updateProcess(row)
      costApplicationStore.updateCostApplicationOption(row.notificationScopeUserId.includes(currentUserId) ? 'view' : `edit?id=${record.businessId}`)
    } catch (e) {
      message.warning('操作失败')
    }
    navigate('/costApplication')
  }

  // 跳转 合同审批
  const handleContractList = async (record: ProcessRow) => {
    try {
      const row = processStore.processDatasource.data?.items.find(o => o.id === record.id)
      row.wnStatus = '已读'
      await ProcessService.updateProcess(row)
      contractListStore.updateContractListOption(row.notificationScopeUserId.includes(currentUserId) ? 'view' : `edit?id=${record.businessId}`)
    } catch (e) {
      message.warning('操作失败')
    }
    navigate('/contractList')
  }

  // 跳转 合同结算
  const handleContractSettlement = async (record: ProcessRow) => {
    try {
      const row = processStore.processDatasource.data?.items.find(o => o.id === record.id)
      row.wnStatus = '已读'
      await ProcessService.updateProcess(row)
      contractSettlementStore.updateContractSettlementOption(row.notificationScopeUserId.includes(currentUserId) ? 'view' : `edit?id=${record.businessId}`)
    } catch (e) {
      message.warning('操作失败')
    }
    navigate('/contractSettlement')
  }

  // 跳转 资金审批
  const handleFundApproval = async (record: ProcessRow) => {
    try {
      const row = processStore.processDatasource.data?.items.find(o => o.id === record.id)
      row.wnStatus = '已读'
      await ProcessService.updateProcess(row)
      fundApprovalStore.updateFundApprovalOption(row.notificationScopeUserId.includes(currentUserId) ? 'view' : `edit?id=${record.businessId}`)
    } catch (e) {
      message.warning('操作失败')
    }
    navigate('/fundApproval')
  }

  // 跳转 资金出纳
  const handleBankStatement = async (record: ProcessRow) => {
    try {
      const row = processStore.processDatasource.data?.items.find(o => o.id === record.id)
      row.wnStatus = '已读'
      await ProcessService.updateProcess(row)
      bankStatementStore.updateBankStatementOption(row.notificationScopeUserId.includes(currentUserId) ? 'view' : `edit?id=${record.businessId}`)
    } catch (e) {
      message.warning('操作失败')
    }
    navigate('/bankStatement')
  }

  // 跳转 工作日程
  const handleWorkSchedule = async (record: ProcessRow) => {
    try {
      const row = processStore.processDatasource.data?.items.find(o => o.id === record.id)
      row.wnStatus = '已读'
      await ProcessService.updateProcess(row)
      workScheduleStore.updateWorkScheduleOption(row.notificationScopeUserId.includes(currentUserId) ? 'view' : `edit?id=${record.businessId}`)
    } catch (e) {
      message.warning('操作失败')
    }
    navigate('/workSchedule')
  }

  const handleItemClick = async (record: ProcessRow) => {
    setLoading(true)
    switch (record.type) {
    case '费用支出':
      await handleCostExpend(record)
      break
    case '费用报销':
      await handleCostReimbursement(record)
      break
    case '员工借款':
      await handleStaffLoan(record)
      break
    case '费用申请':
      await handleCostApplication(record)
      break
    case '合同审批':
      await handleContractList(record)
      break
    case '合同结算':
      await handleContractSettlement(record)
      break
    case '资金审批':
      await handleFundApproval(record)
      break
    case '资金出纳':
      await handleBankStatement(record)
      break
    case '工作日程':
      await handleWorkSchedule(record)
      break
    default:
      message.warning(`${record.type}-待添加`)
      break
    }
    setLoading(false)
  }


  return <Card
    className={'width-100-percentage work-notice'}
    size={'small'}
    title="工作通知"
    loading={loading || processStore.processDatasource.loading}
    extra={<Space size={[0, 8]} wrap>
      { tagsData.map((tag) => (
        <Tag.CheckableTag
          key={tag}
          checked={selectedTag === tag}
          onChange={(checked) => handleChange(tag, checked)}>
          { tag }
        </Tag.CheckableTag>
      )) }
    </Space>}>
    <div className="scroll-box">
      <div className="scroll-content">
        { dataSource.map(record =>
          <div
            className={'item'} key={record.id}
            onClick={() => handleItemClick(record)}>
            { typeof record.wnDate === 'string' ? record.wnDate : record.wnDate.format(DATE_FMT) }&nbsp;&nbsp;
            { record.wnDesc }
          </div>)
        }
        { !dataSource.length && <div className={'width-100-percentage d-flex df-jcc padding-xs'}>
          <Empty/>
        </div> }
      </div>
    </div>
  </Card>
})
