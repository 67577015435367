import './index.less'
import {observer} from "mobx-react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import React, {useEffect, useState} from "react";
import {TooltipPlacement} from "antd/es/tooltip";
import {CopyOutlined} from '@ant-design/icons';
import {Spin, Tooltip, Typography} from "antd";
import {isChineseStr} from "@common/utils";
import {CostExpendRow} from "@services/dto/costExpend";
import {CostExpendService} from "@services/CostExpendService";
import {BLACK_LIST} from "@/config";

export interface PropsType {
  text?: any
  value?: CostExpendRow[]
  promiseValue?: Promise<CostExpendRow[]>
  placement?: TooltipPlacement
  onClick?: () => void
  setDataSource?: (dataSource: CostExpendRow[]) => void
  isId?: boolean
}

export const getBatchTableTableCostExpend = async (values: string[]): Promise<CostExpendRow[]> => {
  const ids = (values as string[])
    .filter((v, i, self) => self.indexOf(v) === i) // 去重
    .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
    .filter(value => !isChineseStr(value)) // 去不是id的
  if (ids.length) {
    const rsp = await CostExpendService.getBatch(ids)
    if (rsp.code === 200) {
      return rsp.data
    } else {
      return []
    }
  } else {
    return []
  }
}

export const TableCostExpend = observer((props: PropsType) => {

  const [loading, setLoading] = useState<boolean>(true)
  const [tip, setTip] = useState<string>('')
  const [value, setValue] = useState<string>('')

  useEffect(() => {
    if (props.isId) {
      props.promiseValue.then(rows => {
        if (rows.length) {
          setValue(rows.map(o => o.efaApplyCode).join(','))
        } else {
          setValue(typeof props.text === 'string' ? props.text : (props.text.length || ''))
        }
      }).finally(() => setLoading(false))
    } else {
      if (props.value.length) {
        setValue(props.value.map(o => o.efaApplyCode).join(','))
        setLoading(false)
      }
    }
  }, [props.value, props.promiseValue]);

  const handleClick = async () => {
    if (props.isId) {
      const rows = await props.promiseValue
      props.setDataSource && props.setDataSource(rows)
    }
    props.onClick && props.onClick()
  }

  return <>
    <Spin size="small" spinning={loading}>
      <div className={'box'}>
        <Tooltip
          className={'margin-right-xs'}
          placement={props.placement}
          title={
            <>
              <div>{ tip || '一键复制' }</div>
            </>
          }>
          <CopyToClipboard
            onCopy={() => tip ? setTip(tip + '.') : setTip('复制成功')}
            text={value}>
            { value ? <CopyOutlined
              onMouseOut={() => setTimeout(() => setTip(''), 600)}/> : <span>/</span> }
          </CopyToClipboard>
        </Tooltip>
        <Tooltip
          placement={props.placement}
          title={
            <>
              <div>{ value }</div>
            </>
          }>
          <Typography.Text
            onClick={handleClick}
            type="success"
            className={'c-pointer'}>
            { value }
          </Typography.Text>
        </Tooltip>
      </div>
    </Spin>
  </>
})
