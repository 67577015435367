import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {DocumentApprovalReq, DocumentApprovalRow} from "@services/dto/documentApproval";

export class DocumentApprovalService {
  static async getDocumentApproval(req: DocumentApprovalReq): Promise<Page<DocumentApprovalRow>> {
    return await apiV1.get("/documentApproval/search", {params: req})
  }

  static async addDocumentApproval(row: DocumentApprovalRow): Promise<ResponseEntity<DocumentApprovalRow>> {
    return await apiV1.post("/documentApproval/save", {...row})
  }

  static async updateDocumentApproval(row: DocumentApprovalRow): Promise<ResponseEntity<DocumentApprovalRow>> {
    return await apiV1.put("/documentApproval/update", {...row})
  }

  static async deleteDocumentApproval(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/documentApproval/delete", {data: ids})
  }

  static async getOne(id: string): Promise<ResponseEntity<DocumentApprovalRow>> {
    return await apiV1.get(`/documentApproval/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<DocumentApprovalRow>> {
    return await apiV1.get(`/documentApproval/getBatch/${ids.join(',')}`)
  }
}
