import {securityStore} from "@modules/Security/Stores/SecurityStore";
import {recruitmentStore} from "@modules/Recruitment/Stores/RecruitmentStore";
import {provinceCityDistrictStore} from "@store/provinceCityDistrictStore";
import {wrStore} from "@modules/HR/Stores/WRStore";
import {workerGroupStore} from "@modules/Recruitment/Stores/WorkerGroupStore";
import {workerTypeStore} from "@modules/Recruitment/Stores/WorkerTypeStore";
import {talentStore} from "@modules/Talent/Stores/TalentStore";
import {salaryTypeStore} from "@modules/HR/Stores/SalaryTypeStore";
import {workTimeStore} from "@modules/HR/Stores/WorkTimeStore";
import {documentStore} from "@modules/HR/Stores/DocumentStore";
import {contractStore} from "@modules/HR/Stores/ContractStore";
import {companyStore} from "@modules/Company/Stores/CompanyStore";
import {companyPeopleStore} from "@modules/CompanyPeople/Stores/CompanyPeopleStore";
import {projectStore} from "@modules/Project/Stores/ProjectStore";
import {organizationalStore} from "@modules/Organizational/Stores/OrganizationalStore";
import {menuPermissionsStore} from "@modules/MenuPermissions/Stores/MenuPermissionsStore";
import {dictManagementStore} from "@modules/DictManagement/Stores/DictManagementStore";
import {suppliesStore} from "@modules/Supplies/Stores/SuppliesStore";
import {rewardPunishmentStore} from "@modules/RewardPunishment/Stores/RewardPunishmentStore";
import {professionStore} from "@modules/Profession/Stores/ProfessionStore";
import {personnelChangeStore} from "@modules/PersonnelChange/Stores/PersonnelChangeStore";
import {monthSalaryStore} from "@modules/Compensation/Stores/MonthSalaryStore";
import {punchClockStore} from "@modules/PunchClock/Stores/PunchClockStore";
import {advanceStore} from "@modules/Advance/Stores/AdvanceStore"
import {ruleRegulationStore} from "@modules/RuleRegulation/Stores/RuleRegulationStore"
import {institutionStore} from "@modules/Institution/Stores/InstitutionStore"
import {basicSalaryStore} from "@modules/Salary/Stores/BasicSalaryStore";
import {overtimeSalaryStore} from "@modules/Salary/Stores/OvertimeSalaryStore";
import {workPostStore} from "@modules/WorkPost/Stores/WorkPostStore";
import {bonusStore} from "@modules/Salary/Stores/BonusStore";
import {deductionStore} from "@modules/Salary/Stores/DeductionStore";
import {insuranceStore} from "@modules/Salary/Stores/InsuranceStore";
import {subsidyStore} from "@modules/Salary/Stores/SubsidyStore";
import {salaryStore} from "@modules/Salary/Stores/SalaryStore";
import {contractLedgerStore} from "@modules/ContractLedger/Stores/ContractLedgerStore";
import {revenueExpenditureStore} from "@modules/RevenueExpenditure/Stores/RevenueExpenditureStore";
import {constructionLogStore} from "@modules/ConstructionLog/Stores/ConstructionLogStore";
import {projectLogStore} from "@modules/ProjectLog/Stores/ProjectLogStore";
import {staffLoanStore} from "@modules/StaffLoan/Stores/StaffLoanStore";
import {costApplicationStore} from "@modules/CostApplication/Stores/CostApplicationStore";
import {costExpendStore} from "@modules/CostExpend/Stores/CostExpendStore";
import {employeeFeePayStore} from "@modules/EmployeeFeePay/Stores/EmployeeFeePayStore";
import {costReimbursementStore} from "@modules/CostReimbursement/Stores/CostReimbursementStore";
import {filingStore} from "@modules/Filing/Stores/FilingStore";
import {invoiceRegisterStore} from "@modules/InvoiceRegister/Stores/InvoiceRegisterStore";
import {accountSalesStore} from "@modules/AccountSales/Stores/AccountSalesStore";
import {taxAccountingStore} from "@modules/TaxAccounting/Stores/TaxAccountingStore";
import {finalSettlementStore} from "@modules/FinalSettlement/Stores/FinalSettlementStore";
import {invoiceRegistrationStore} from "@modules/InvoiceRegistration/Stores/InvoiceRegistrationStore";
import {fundApprovalStore} from "@modules/FundApproval/Stores/FundApprovalStore";
import {contractListStore} from "@modules/ContractList/Stores/ContractListStore";
import {contractSettlementStore} from "@modules/ContractSettlement/Stores/ContractSettlementStore";
import {bankStatementStore} from "@modules/BankStatement/Stores/BankStatementStore";
import {bankAccountStore} from "@modules/BankAccount/Stores/BankAccountStore";
import {commercialContractStore} from "@modules/CommercialContract/Stores/CommercialContractStore";
import {settleApplyStore} from "@modules/SettleApply/Stores/SettleApplyStore";
import {materialsOutBoundStore} from "@modules/MaterialsOutBound/Stores/MaterialsOutBoundStore";
import {materialListStore} from "@modules/MaterialList/Stores/MaterialListStore";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {projectStructureStore} from "@modules/ProjectStructure/Stores/ProjectStructureStore";
import {budgetManagementStore} from "@modules/BudgetManagement/Stores/BudgetManagementStore";
import {projectCollaborationStore} from "@modules/ProjectCollaboration/Stores/ProjectCollaborationStore";
import {cooperativeUnitStore} from "@modules/CooperativeUnit/Stores/CooperativeUnitStore";
import {tabsViewStore} from "@/layout/Stores/TabsViewStore";
import {screenResolutionStore} from "@/layout/Stores/ScreenResolutionStore";
import {tableColumnsStore} from "@/layout/Stores/TableColumnsStore";
import {transactionStatisticsStore} from "@modules/TransactionStatistics/Stores/TransactionStatisticsStore";
import {
  incomeExpenditureStatisticsStore
} from "@modules/IncomeExpenditureStatistics/Stores/IncomeExpenditureStatisticsStore";
import {taskSupervisionStore} from "@modules/TaskSupervision/Stores/TaskSupervisionStore";
import {workScheduleStore} from "@modules/WorkSchedule/Stores/WorkScheduleStore";
import {processStore} from "@modules/Process/Stores/ProcessStore";
import {taxFeeStatisticsStore} from "@modules/TaxFeeStatistics/Stores/TaxFeeStatisticsStore";
import {documentApprovalStore} from "@modules/DocumentApproval/Stores/DocumentApprovalStore";
import {socialSecurityPaymentStore} from "@modules/SocialSecurityPayment/Stores/SocialSecurityPaymentStore";
import {demoPageStore} from "@modules/DemoPage/Stores/DemoStore";
import {payrollStore} from "@modules/Payroll/Stores/PayrollStore";
import {taxPlanStore} from "@modules/TaxPlan/Stores/TaxPlanStore";
import {emolumentPlanStore} from "@modules/EmolumentPlan/Stores/EmolumentPlanStore";
import {roleManageStore} from "@modules/RoleManage/Stores/RoleManageStore";
import {processRecordsStore} from "@modules/ProcessRecords/Stores/ProcessRecordsStore";
import {formStatusStore} from "@modules/FormStatus/Stores/FormStatusStore";
import {conferenceRecordStore} from "@modules/ConferenceRecord/Stores/ConferenceRecordStore";
import {jobAccountStore} from "@modules/JobAccount/Stores/JobAccountStore";
import {sealApprovalStore} from "@modules/SealApproval/Stores/SealApprovalStore";
import {sealApplicationStore} from "@modules/SealApplication/Stores/SealApplicationStore";
import {fileDistributionStore} from "@modules/FileDistribution/Stores/FileDistributionStore";

export const allStores = {
  processRecordsStore,
  securityStore,
  recruitmentStore,
  provinceCityDistrictStore,
  wrStore,
  workerGroupStore,
  workerTypeStore,
  talentStore,
  salaryTypeStore,
  workTimeStore,
  documentStore,
  contractStore,
  companyStore,
  companyPeopleStore,
  projectStore,
  organizationalStore,
  menuPermissionsStore,
  dictManagementStore,
  suppliesStore,
  rewardPunishmentStore,
  professionStore,
  personnelChangeStore,
  monthSalaryStore,
  punchClockStore,
  advanceStore,
  ruleRegulationStore,
  workPostStore,
  basicSalaryStore,
  overtimeSalaryStore,
  institutionStore,
  bonusStore,
  deductionStore,
  insuranceStore,
  subsidyStore,
  salaryStore,
  contractLedgerStore,
  revenueExpenditureStore,
  constructionLogStore,
  projectLogStore,
  staffLoanStore,
  costApplicationStore,
  costExpendStore,
  employeeFeePayStore,
  costReimbursementStore,
  filingStore,
  invoiceRegisterStore,
  accountSalesStore,
  taxAccountingStore,
  finalSettlementStore,
  invoiceRegistrationStore,
  fundApprovalStore,
  contractListStore,
  contractSettlementStore,
  bankStatementStore,
  bankAccountStore,
  commercialContractStore,
  settleApplyStore,
  materialsOutBoundStore,
  materialListStore,
  headerSearchStore,
  projectStructureStore,
  budgetManagementStore,
  projectCollaborationStore,
  cooperativeUnitStore,
  tabsViewStore,
  transactionStatisticsStore,
  incomeExpenditureStatisticsStore,
  screenResolutionStore,
  tableColumnsStore,
  taskSupervisionStore,
  workScheduleStore,
  processStore,
  taxFeeStatisticsStore,
  documentApprovalStore,
  socialSecurityPaymentStore,
  demoPageStore,
  payrollStore,
  taxPlanStore,
  emolumentPlanStore,
  roleManageStore,
  formStatusStore,
  jobAccountStore,
  conferenceRecordStore,
  sealApprovalStore,
  sealApplicationStore,
  fileDistributionStore
}
