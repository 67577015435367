import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {HeaderAction} from "@modules/PersonnelChange/Components/HeaderAction";
import {ContentTable} from "@modules/PersonnelChange/Components/ContentTable";
import {allStores} from "@/stores";
import {
  CreatePersonnelChange,
  PersonnelChangeRow,
  PersonnelChangeSearchReq,
  UpdatePersonnelChange
} from "@services/dto/personnelChange";
import {Button, Drawer, message, Modal} from "antd";
import {logger, showErr} from "@common/utils";
import {PersonnelChangeService} from "@services/PersonnelChangeService";
import {DATE_FMT, DEFAULT_MODAL_WIDTH} from "@/config";
import {BasicInfo} from "@modules/PersonnelChange/Components/BasicInfo";
import {FormInstance} from "antd/es";
import dayjs from "dayjs";

export const PersonnelChange:FC = observer(() => {
  const {personnelChangeStore} = allStores
  const {personnelChangeDatasource: datasource} = personnelChangeStore
  const [searchReq, setSearchReq] = useState<PersonnelChangeSearchReq>({
    pageNum: 1,
    perPage: 10,
    sorting: false
  })
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const [row, setRow] = useState<PersonnelChangeRow>()
  const basicInfo = React.createRef<FormInstance>();
  const [isView, setIsView] = useState<boolean>(false)

  useEffect(() => {
    refreshData(searchReq)
  }, [])
  
  const refreshData = (req: PersonnelChangeSearchReq) => {
    personnelChangeStore.searchPersonChange(req)
  }

  const handleTimeChange = (dateStrings: string[]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.startTime = ""
    req.endTime = ""
    if (dateStrings.length > 0) {
      req.startTime = dateStrings[0]
      req.endTime = dateStrings[1]
    }
    setSearchReq(req)
    refreshData(req)
  };
    
  const handleProjectIdChange = (projectId: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.projectId = projectId
    setSearchReq(req)
    refreshData(req)
  };
    
  const handleDeleteBatch = (record?: PersonnelChangeRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        PersonnelChangeService.deleteBatchPersonnelChange(ids).then(rsp => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(searchReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  };

  const handlePageChange = (page: number, pageSize: number) => {
    const req = {...searchReq}
    req.pageNum = page
    req.perPage = pageSize
    setSearchReq(req)
    refreshData(req)
  }

  const handleAdd = () => {
    setIsView(false)
    setRow(undefined)
    setVisible(true)
  }

  const handleView = (record: PersonnelChangeRow) => {
    setIsView(true)
    setRow(record)
    setVisible(true)
  }
  
  const handleOk = () => {
    basicInfo.current?.validateFields().then(value => {
      setConfirmLoading(true)
      if (row && row.id) {
        const updatePersonnel:UpdatePersonnelChange = {
          releaseTime: dayjs().format(DATE_FMT),
          userId: value.userId,
          newProjectId: value.newProjectId,
          newProfessionalLeaguesName: value.newProfessionalLeaguesName,
          newProfessional: value.newProfessional,
          remark: value.remark,
          newPersonnelCategory: value.newPersonnelCategory,
          newWorkerTypeName: value.newWorkerTypeName
        }
        PersonnelChangeService.updatePersonnelChange(row.id, updatePersonnel).then(rsp => {
          message.success("人事异动更改成功！");
          refreshData(searchReq)
          handleCancel()
        }).catch(showErr).finally(() => setConfirmLoading(false))
      } else {
        const createPersonnel:CreatePersonnelChange = {
          oldProjectId: value.person[0],
          oldProfessionalLeaguesName: value.person[1],
          oldProfessional: value.person[2],
          userId: value.person[3],

          releaseTime: dayjs().format(DATE_FMT),
          newProjectId: value.newProjectId,
          newProfessionalLeaguesName: value.newProfessionalLeaguesName,
          newProfessional: value.newProfessional,
          remark: value.remark,
          newPersonnelCategory: value.newPersonnelCategory,
          newWorkerTypeName: value.newWorkerTypeName
        };
        PersonnelChangeService.createPersonnelChange(createPersonnel).then(rsp => {
          message.success("人事异动新增成功！");
          handleCancel()
          refreshData(searchReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      }
    }).catch(logger.warn)
  }

  const handleCancel = () => {
    setVisible(false)
  }
    
  return (
    <>
      <HeaderAction
        handleTimeChange={handleTimeChange}
        handleProjectIdChange={handleProjectIdChange}
        handleDeleteBatch={handleDeleteBatch}
        handleAdd={handleAdd}/>

      <ContentTable
        className={"margin-top-sm"}
        loading={datasource.loading || confirmLoading}
        total={datasource.data?.data.total!}
        perPage={searchReq.perPage}
        pageNum={searchReq.pageNum}
        selectedRowKeys={selectedRowKeys}
        dataSource={datasource.data?.data.items!}
        handlePageChange={handlePageChange}
        handleChange={setSelectedRowKeys}
        handleView={handleView}
        handleDeleteBatch={handleDeleteBatch}/>

      <Drawer
        title={<div className="text-align-center">人事异动</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={visible}
        onClose={handleCancel}>
        <BasicInfo row={{...row!}} ref={basicInfo} isView={isView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button type="primary" onClick={handleOk} loading={confirmLoading} className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>
    </>
  )
})