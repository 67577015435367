import {Page, ResponseEntity, StateFullData} from "@services/dto/common";
import {PunchClockRow, SearchPunchClockReq} from "@services/dto/punchClock";
import {makeAutoObservable, runInAction} from "mobx";
import {PunchClockService} from "@services/PunchClockService";

export class PunchClockStore {
  punchClockDatasource: StateFullData<ResponseEntity<Page<PunchClockRow>>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  searchPunchClock(req: SearchPunchClockReq) {
    this.punchClockDatasource = {...this.punchClockDatasource, loading: true}
    PunchClockService.searchPunchClock(req).then(rsp => {
      runInAction(() => {
        this.punchClockDatasource = {data: rsp, loading: false}})
    }).catch(e => {
      runInAction(() => this.punchClockDatasource = {...this.punchClockDatasource, loading: false, err: e})
    })
  }
}

export const punchClockStore = new PunchClockStore()