import {Page, StateFullData} from "@services/dto/common";
import {DocumentReq, DocumentRow} from "@services/dto/document";
import {makeAutoObservable, runInAction} from "mobx";
import {DocumentService} from "@services/DocumentService";

export class DocumentStore {
  documents: StateFullData<Page<DocumentRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getDocumentTpls(req: DocumentReq) {
    this.documents = {...this.documents, loading: true}
    DocumentService.getDocumentTpls(req).then(rsp => {
      runInAction(() => {this.documents = {data: rsp, loading: false}})
    }).catch(err => {
      runInAction(() => this.documents = {...this.documents, err: err})
    })
  }
}

export const documentStore = new DocumentStore()
