import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {HeaderAction} from "@components/HeaderAction";
import {ColumnsType} from "antd/es/table";
import {FundApprovalReq} from "@services/dto/fundApproval";
import {BankAccountStatisticsReq} from "@services/dto/bankAccount";
import {DatePicker, DatePickerProps, Select} from "antd";
import {SelectBankAccount} from "@components/SelectBankAccount";
import {ProjectRow} from "@services/dto/project";
import {allStores} from "@/stores";

export interface PropsType {
  selectedRowKeys?: React.Key[], // 所选行
  handleDeleteBatch?: () => void,
  collapsed?: boolean, // 是否展开
  setCollapsed?: (v: boolean) => void, // 展开事件
  handleAdd?: () => void,
  handleTableColumnsChange?: (value: string[]) => void,
  showColumn?: string[],
  tableColumns?: ColumnsType,
  refreshData?: (req: FundApprovalReq) => void,
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const {securityStore} = allStores
  const [userProjects, setUserProjects] = useState<ProjectRow[] | undefined>([])
  const [searchReq, setSearchReq] = useState<BankAccountStatisticsReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    setUserProjects(securityStore.getLoggedUserProjects())
  }, [])

  const refreshData = (req: FundApprovalReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleDateChange: DatePickerProps['onChange'] = (_, dateString: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.start = dateString
    setSearchReq(req)
    refreshData(req)
  }

  const handleBankAccountChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.bankAccountId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleBsBalanceChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.bsBalance = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleBaTypeChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.baType = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleProjectChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  // const handleBsBalanceChange = (searchValue: string) => {
  //   const req = {...searchReq}
  //   req.pageNum = 1
  //   req.bsBalance = searchValue
  //   setSearchReq(req)
  //   refreshData(req)
  // }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <HeaderAction
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        selectedRowKeys={props.selectedRowKeys}
        handleDeleteBatch={props.handleDeleteBatch}
        handleAdd={props.handleAdd}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        isHiddenOptions={true}
        tableColumns={props.tableColumns}
        collapsedChildren={[
          <DatePicker.MonthPicker
            allowClear
            onChange={handleDateChange}
            className={"width-100-percentage"}/>,
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => ((option as ProjectRow).name ?? '').includes(input)}
            placeholder={"请选择责任部门"}
            className={"width-100-percentage"}
            allowClear
            fieldNames={{value: "dictValue", label: "projectName"}}
            options={[
              {projectName: 'N/A', dictValue: 'N/A'},
              {projectName: '容缺', dictValue: '容缺'},
              ...userProjects ? userProjects : []
            ]}
            onChange={handleProjectChange}/>,
          // <SelectDict
          //   className={'width-100-percentage'}
          //   code={'amount_type'}
          //   allowClear
          //   onChange={handleBaTypeChange}
          //   placeholder={'请选择账户类型'}/>,
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            onChange={handleBaTypeChange}
            placeholder={'请选择账户类型'}
            className={'width-100-percentage'}
            options={[
              {value: '内部协作账户', label: '内部协作账户'},
              {value: '员工公务账户', label: '员工公务账户'},
              {value: '库存现金账户', label: '库存现金账户'}
            ]}/>,
          <SelectBankAccount
            allowClear
            searchReq={{
              pageNum: 1,
              perPage: 10,
              baType: '内部协作账户,员工公务账户',
            }}
            placeholder={'请选择资金账户'}
            onChange={handleBankAccountChange}
            fieldNames={{value: 'id', label: 'baNick'}}></SelectBankAccount>,
          <Select
            onChange={handleBsBalanceChange}
            options={[
              {label: '余额相符', value: '余额相符'},
              {label: '余额不符', value: '余额不符'},
            ]}
            placeholder={'请选择余额对账'}
            className={"width-100-percentage"}/>,
        ]}></HeaderAction>
    </>
  )
}))
