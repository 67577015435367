import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {CostExpendService} from "@services/CostExpendService";
import {CostExpendReq, CostExpendRow} from "@services/dto/costExpend";
import {convertCurrency, formatMoney} from "@common/utils";

export class CostExpendStore {
  costExpendDatasource: StateFullData<Page<CostExpendRow>> = {}
  costExpendSelectDatasource: StateFullData<Page<CostExpendRow>> = {}
  costExpendRow?: CostExpendRow
  costExpendOption?: string
  costExpendDetail: StateFullData<ResponseEntity<CostExpendRow>> = {}
  costExpendDetails: StateFullData<ResponsesEntity<CostExpendRow>> = {}
  costExpendSearchReq: CostExpendReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateCostExpendRow(row: CostExpendRow) {
    this.costExpendRow = {...row}
  }

  updateCostExpendOption(option: string) {
    this.costExpendOption = option
  }

  updateCostExpendSearchReq(req: CostExpendReq) {
    this.costExpendSearchReq = {...this.costExpendSearchReq, ...req}
  }

  getCostExpend(req: CostExpendReq) {
    this.costExpendDatasource = {...this.costExpendDatasource, loading: true}
    this.costExpendSearchReq = {...this.costExpendSearchReq, ...req}
    CostExpendService.getCostExpend(req).then(rsp => {
      runInAction(() => this.costExpendDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.costExpendDatasource = {...this.costExpendDatasource, loading: false, err})
    })
  }

  getSelectCostExpend(req: CostExpendReq) {
    this.costExpendSelectDatasource = {...this.costExpendSelectDatasource, loading: true}
    CostExpendService.getCostExpend(req).then(rsp => {
      runInAction(() => this.costExpendSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.costExpendSelectDatasource = {...this.costExpendSelectDatasource, loading: false, err})
    })
  }

  getOne(id: string) {
    this.costExpendDetail = {...this.costExpendDetail, loading: true}
    CostExpendService.getOne(id).then(rsp => {
      runInAction(() => this.costExpendDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.costExpendDetail = {...this.costExpendDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.costExpendDetails = {...this.costExpendDetails, loading: true}
    CostExpendService.getBatch(ids).then(rsp => {
      runInAction(() => this.costExpendDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.costExpendDetails = {...this.costExpendDetails, loading: false, err})
    })
  }

  async getPrintList(record: CostExpendRow): Promise<LabelValue[]> {

    const row = this.costExpendDatasource.data?.items.find(o => o.id === record.id)


    return [
      {label: '制单日期', value: row.efpPayTime as string},
      {label: '制单编号', value: row.efaApplyCode},
      {label: '款项类别', value: row.efpPayType},
      {label: '款项摘要', value: row.efpPayDesc},
      {label: '支出金额(元)', value: `${formatMoney(row.efpAmount)}元（${convertCurrency(row.efpAmount)}）`},
      {label: '付款方', value: row.baNick1},
      {label: '收款方', value: row.baNick2},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const costExpendStore = new CostExpendStore();
