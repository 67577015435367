import {observer} from "mobx-react";
import {Card, Tree, TreeProps} from "antd";
import React, {useEffect, useState} from "react";
import {MenuPermissionsReq, MenuPermissionsRow} from "@services/dto/menuPermissions";
import {allStores} from "@/stores";
import {Key} from "antd/es/table/interface";

export interface PropsType {
  size?: 'default' | 'small'
  value?: string[]
  multiple?: boolean
  selectable?: boolean
  blockNode?: boolean
  checkStrictly?: boolean
  showLine?: boolean
  checkable?: boolean
  className?: string
  height?: number
  disabled?: boolean
  fieldNames?: { title: string, key: string, children: string }
  onChange?: (value: string[], option: MenuPermissionsRow[]) => void
}

type CheckStrictlyValueType = {
  checked: Key[];
  halfChecked: Key[];
}

export const SelectMenuPermissions = observer((props: PropsType) => {
  const {menuPermissionsStore} = allStores
  const [datasource, setDatasource] = useState<MenuPermissionsRow[]>([])
  const [value, setValue] = useState<React.Key[]>([])
  const [searchReq, setSearchReq] = useState<MenuPermissionsReq>({
    pageNum: 1,
    perPage: 10,
  })

  /**
   * 初始化
   */
  useEffect(() => {
    refreshData(searchReq)
  }, [])

  /**
   * 值发生改变
   */
  useEffect(() => {
    setValue(props.value as React.Key[])
  }, [props.value])

  /**
   * 办理进度数据变化
   */
  useEffect(() => {
    if (menuPermissionsStore.menuPermissionsSelectDatasource.data) {
      setDatasource(menuPermissionsStore.menuPermissionsSelectDatasource.data.data || [])
    }
  }, [menuPermissionsStore.menuPermissionsSelectDatasource.data])

  const refreshData = (req: MenuPermissionsReq) => {
    setSearchReq(req)
    menuPermissionsStore.getSelectMenuPermissions(req)
  }

  const recursiveFindParentPathById = (node: MenuPermissionsRow, path: string[], id: string): string[] => {
    if (node.id === id) {
      return path
    }

    if (node.child && node.child.length > 0) {
      for (let i = 0; i < node.child.length; i++) {
        const item = node.child[i]
        const res = recursiveFindParentPathById(item, [...path, item.id], id);
        if (res.length) {
          return res;
        }
      }
    }

    return []
  }

  const findParentPathById = (tree: MenuPermissionsRow[], id: string): string[] => {
    for (let i = 0; i < tree.length; i++) {
      const item = tree[i]
      const res = recursiveFindParentPathById(item, [item.id], id)
      if (res.length) {
        return res;
      }
    }

    return []
  }

  const recursiveFindChildPathByChildList = (rows: MenuPermissionsRow[]): string[] => {
    let res: string[] = []
    for (let i = 0; i < rows.length; i++) {
      const item = rows[i]
      res.push(item.id)
      res = [...res, ...recursiveFindChildPathByChildList(item.child)]
    }
    if (res.length) {
      return res;
    }

    return []
  }

  const findChildPathByNode = (node: MenuPermissionsRow): string[] => {
    return recursiveFindChildPathByChildList(node.child)
  }

  const findNodeById = (nodes: MenuPermissionsRow[], id: string) => {
    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].id === id) {
        return nodes[i];
      }
      if (nodes[i].child) {
        const result = findNodeById(nodes[i].child, id);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  const onCheck: TreeProps<MenuPermissionsRow>['onCheck'] = (checkedKeysValue: CheckStrictlyValueType, info) => {
    let value: string[]
    if (info.checked) {
      const path = findParentPathById(datasource, info.node.key as string)
      value = [...path, ...(checkedKeysValue.checked as string[])].filter((v, i, self) => self.indexOf(v) === i)
      setValue(value);
    } else {
      const path = findChildPathByNode(info.node)
      value = [...(checkedKeysValue.checked as string[])].filter(key => !path.includes(key))
      setValue(value);
    }
    if (props.onChange) {
      let nodeList: MenuPermissionsRow[] = []
      for (let i = 0; i < value.length; i++) {
        nodeList.push(findNodeById(datasource, value[i]))
      }
      props.onChange(value, nodeList)
    }
  };

  return <>
    <Card size={props.size}>
      <Tree
        className={props.className}
        disabled={props.disabled}
        blockNode={props.blockNode}
        height={props.height}
        multiple={props.multiple}
        selectable={props.selectable}
        checkStrictly={props.checkStrictly}
        showLine={props.showLine}
        checkable={props.checkable}
        checkedKeys={value}
        onCheck={onCheck}
        fieldNames={{title: 'title', key: 'id', children: 'child'}}
        treeData={datasource as any[]}
      />
    </Card>
  </>

})
