import {apiV1} from "@services/apiHelper";
import {ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {MenuPermissionsReq, MenuPermissionsRow} from "@services/dto/menuPermissions";

export class MenuPermissionsService {
  static async addMenuPermissions(entity: MenuPermissionsRow): Promise<ResponseEntity<MenuPermissionsRow>> {
    return await apiV1.post('/system/menu', {...entity});
  }

  static async deleteMenuPermissionsById(id: string): Promise<ResponsesEntity<string>> {
    return await apiV1.delete(`/system/menu/${id}`);
  }

  static async updateMenuPermissionsById(id: string, talent: MenuPermissionsRow): Promise<ResponseEntity<MenuPermissionsRow>> {
    return await apiV1.put(`/system/menu/${id}`, {...talent});
  }

  static async getMenuPermissions(req: MenuPermissionsReq): Promise<ResponsesEntity<MenuPermissionsRow>> {
    return await apiV1.get('/system/menu/all/1', {params: req});
  }

  static async getOne(id: string): Promise<MenuPermissionsRow> {
    return await apiV1.get(`/system/menu/get/${id}`);
  }

  static async getBatch(ids: string[]): Promise<MenuPermissionsRow[]> {
    return await apiV1.get(`/system/menu/getBatch/${ids.join(',')}`);
  }

  static async deleteBatchMenuPermissionsByIds(ids: string[]): Promise<void> {
    return await apiV1.delete("/system/menu/delBatch", {data: {ids}})
  }

  static async getDetailById(id: string): Promise<ResponseEntity<MenuPermissionsRow>> {
    return await apiV1.get(`/system/menu/getOne/${id}`)
  }
}
