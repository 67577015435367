import React, {FC, useState} from "react";
import {observer} from "mobx-react";
import {Button, Col, Input, Radio, Row} from "antd";
import IconFont from "@components/IconFont";
import {DeleteOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import {RadioChangeEvent} from "antd/es/radio/interface";
import {SelectProject} from "@components/SelectProject";

export interface PropsType {
  disabled: boolean
  handleActiveKeyChange: (value: string) => void
  handleProjectIdChange: (value: string) => void
  handlePressEnter: (value: string) => void
  handleDeleteBatch: () => void
  handleAdd: () => void
}

export const HeaderAction:FC<PropsType> = observer((props: PropsType) => {
  const [activeKey, setActiveKey] = useState("exited")
  const [searchValue, setSearchValue] = useState<string>("")
    
  const handleActiveKeyChange = (e: RadioChangeEvent) => {
    setActiveKey(e.target.value)
    props.handleActiveKeyChange(e.target.value)
  }

  const handleProjectIdChange = (value: string) => {
    props.handleProjectIdChange(value)
  }

  const handlePressEnter = () => {
    props.handlePressEnter(searchValue)
  }

  const handleDeleteBatch = () => {
    props.handleDeleteBatch()
  }

  const handleAdd = () => {
    props.handleAdd()
  }
    
  return (
    <>
      <Row gutter={16}>
        <Col xl={6} lg={9} md={12} xs={24}>
          <Radio.Group value={activeKey} onChange={handleActiveKeyChange} buttonStyle="solid"
            size={"middle"}
            disabled={props.disabled}>
            <Radio.Button value={"exited"}><IconFont type={"hr-lizhirenyuan"} className={"icon-fontsize"}/>正常离职</Radio.Button>
            <Radio.Button value={"abnormalexited"}><IconFont type={"hr-lizhirenyuan"} className={"icon-fontsize"}/>非正常离职</Radio.Button>
          </Radio.Group>
        </Col>
        <Col xl={5} lg={7} md={12} xs={12}>
          <SelectProject allowClear onChange={handleProjectIdChange}/>
        </Col>
        <Col xl={6} lg={8} md={12} xs={12}>
          <Input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={handlePressEnter}
            allowClear
            placeholder="请输入姓名/电话/工种/籍贯"
            className={"width-100-percentage"}
            suffix={<SearchOutlined onClick={handlePressEnter}/>}
          />
        </Col>
        <Col xl={7} lg={24} md={12} xs={24} className={"text-align-right"}>
          <Button icon={<DeleteOutlined/>} size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={() => handleDeleteBatch()}>删除</Button>

          <Button icon={<PlusOutlined/>} type={"primary"} size={"middle"} shape={"round"}
            onClick={handleAdd}>新增</Button>
        </Col>
      </Row>
    </>
  )
})