import './index.less'
import {observer} from "mobx-react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import React, {useEffect, useState} from "react";
import {TooltipPlacement} from "antd/es/tooltip";
import {CopyOutlined} from '@ant-design/icons';
import {Spin, Tooltip, Typography} from "antd";
import {ContractListRow} from "@services/dto/contractList";
import {ContractListService} from "@services/ContractListService";
import {BLACK_LIST} from "@/config";

export interface PropsType {
  value?: ContractListRow[]
  isId?: boolean
  textId?: string
  code?: string
  placement?: TooltipPlacement
  onClick?: () => void
  setDataSource?: (dataSource: ContractListRow[]) => void
}

export const getBatchTableContractLists = async (values: string[]): Promise<ContractListRow[]> => {
  const ids = (values as string[])
    .filter((v, i, self) => self.indexOf(v) === i) // 去重
    .filter(value => !(BLACK_LIST.includes(value))) // 去黑名单
  if (ids.length) {
    const rsp = await ContractListService.getBatch(ids)
    if (rsp.code === 200) {
      return rsp.data
    } else {
      return []
    }
  } else {
    return []
  }
}

export const TableContractLists = observer((props: PropsType) => {

  const [loading, setLoading] = useState<boolean>(true)
  const [tip, setTip] = useState<string>('')
  const [value, setValue] = useState<string>('')

  useEffect(() => {
    if (props.isId) {
      setValue(props.code)
      setLoading(false)
    } else {
      if (props.value.length) {
        setValue(props.value.map(o => o.clCode).join(','))
      }
      setLoading(false)
    }
  }, [props.value, props.isId]);

  const handleClick = async () => {
    if (props.isId) {
      const rows = await getBatchTableContractLists(props.textId.split(','))
      props.setDataSource && props.setDataSource(rows)
    }
    props.onClick && props.onClick()
  }

  return <>
    <Spin size="small" spinning={loading}>
      <div className={'box'}>
        <Tooltip
          className={'margin-right-xs'}
          placement={props.placement}
          title={
            <>
              <div>{ tip || '一键复制' }</div>
            </>
          }>
          <CopyToClipboard
            onCopy={() => tip ? setTip(tip + '.') : setTip('复制成功')}
            text={value}>
            { value ? <CopyOutlined
              onMouseOut={() => setTimeout(() => setTip(''), 600)}/> : <span>/</span> }
          </CopyToClipboard>
        </Tooltip>
        <Tooltip
          placement={props.placement}
          title={
            <>
              <div>{ value }</div>
            </>
          }>
          <Typography.Text
            onClick={handleClick}
            type="success"
            className={'c-pointer'}>
            { value }
          </Typography.Text>
        </Tooltip>
      </div>
    </Spin>
  </>
})
