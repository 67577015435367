import {observer} from "mobx-react";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {Button, Drawer, FormInstance, message, Modal, Space, Table,} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {logger, safeCompute, showErr} from "@common/utils";
import {BasicInfo} from "@modules/ProcessRecords/Components/BasicInfo";
import {ProcessRecordsService} from "@services/ProcessRecordsService";
import {useLocation} from "react-router-dom";
import {HeaderActionCom} from "@modules/ProcessRecords/Components/HeaderActionCom";
import {
  DEFAULT_MODAL_WIDTH,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import {ProcessRecordsReq, ProcessRecordsRow} from "@services/dto/processRecords";
import {TableOptions} from "@components/TableOptions";
import {TableEllipsisText} from "@components/TableEllipsisText";

const wideShowColumn: string[] = [
  "createdAt",
  "userName",
  // "status",
  "conca",
  "remark",
  "action",
]

const narrowShowColumn: string[] = [
  "createdAt",
  "userName",
  // "status",
  "conca",
  "remark",
  "action",
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: ProcessRecordsRow[] // 数据源
}

export const ProcessRecords = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {
  const location = useLocation();
  const {
    processRecordsStore,
    screenResolutionStore,
    tableColumnsStore,
  } = allStores
  const basicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<ProcessRecordsRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [processRecordsRow, setProcessRecordsRow] = useState<ProcessRecordsRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<ProcessRecordsRow>>([])
  const [searchReq, setSearchReq] = useState<ProcessRecordsReq>({
    pageNum: 1,
    perPage: 10,
    ...processRecordsStore.processRecordsReq
  })

  const columns: ColumnsType<ProcessRecordsRow> = [
    {
      title: '办理时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={`${new Date(text).toLocaleDateString()} ${new Date(text).toLocaleTimeString()}`}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理人员',
      dataIndex: 'userName',
      key: 'userName',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '类型',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '经办记录',
      dataIndex: 'conca',
      key: 'conca',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理意见',
      dataIndex: 'remark',
      key: 'remark',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableOptions
          handleEdit={() => handleEdit(record)}
          handleView={() => handleView(record)}
          handleDelete={() => handleDeleteBatch(record)}>
        </TableOptions>
      )
    }
  ];

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  useEffect(() => {
    if (processRecordsStore.processRecordsDatasource.data) {
      setDataSource(processRecordsStore.processRecordsDatasource.data?.items || [])
      setTotal(processRecordsStore.processRecordsDatasource.data?.total || 0)
    }
  }, [processRecordsStore.processRecordsDatasource.data])

  useEffect(() => {

  }, [processRecordsStore.processRecordsRow])

  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => o.key !== 'action').filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, processRecordsStore.collapsed, searchReq.pageNum, searchReq.perPage])

  useEffect(() => {
    if (processRecordsStore.processRecordsOption) {
      const [option] = processRecordsStore.processRecordsOption.split("?")
      if (option === 'add') {
        handleAdd()
        processRecordsStore.updateProcessRecordsOption(undefined)
      }
    }
  }, [processRecordsStore.processRecordsOption])


  const handleAdd = () => {
    setProcessRecordsRow(undefined)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handleView = (record?: ProcessRecordsRow) => {
    setProcessRecordsRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = (record?: ProcessRecordsRow) => {
    setProcessRecordsRow(record)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleDeleteBatch = (record?: ProcessRecordsRow) => {
    const keys = [...selectedRowKeys]
    if (!record && keys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = keys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        ProcessRecordsService.deleteProcessRecords(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(processRecordsStore.processRecordsReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: ProcessRecordsReq) => {
    setSearchReq(req)
    processRecordsStore.getProcessRecords(req)
  }

  const insertOrUpdate = (row: ProcessRecordsRow) => {
    if (row.id) {
      ProcessRecordsService.updateProcessRecords(row).then(() => {
        actionThen()
        setUpdateCount(updateCount + 1)
      }).catch(showErr).finally(() => setConfirmLoading(false));
    } else {
      ProcessRecordsService.addProcessRecords(row).then(() => {
        actionThen()
        setUpdateCount(updateCount + 1)
      }).catch(showErr).finally(() => setConfirmLoading(false));
    }
  }

  const handleOk = () => {
    const row = {...processRecordsRow!}
    basicInfo.current!.validateFields().then(values => {
      setConfirmLoading(true)
      Object.assign(row, values)
      insertOrUpdate(row)
    }).catch(logger.warn)
  }

  const actionThen = () => {
    message.success("操作成功！").then()
    setIsModalVisible(false)
    refreshData(processRecordsStore.processRecordsReq)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    processRecordsStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  return (
    <div className={"institution"}>

      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        isHiddenOptions={props.isHiddenOptions}
        updateCount={updateCount}
        collapsed={processRecordsStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        selectedRowKeys={selectedRowKeys}
        handleShowColumnReset={handleShowColumnReset}
        // handleDeleteBatch={handleDeleteBatch}
        // handleAdd={handleAdd}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={props.isModalSelect ? columns.filter(o => o.key !== 'action') : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}
        setConfirmLoading={setConfirmLoading}></HeaderActionCom>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        size={"middle"}
        loading={props.dataSource ? false : processRecordsStore.processRecordsDatasource.loading || confirmLoading}
        bordered
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys)
          },
          columnWidth: 50,
        }}
        pagination={!props.dataSource && {
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}/>

      <Drawer
        title={<div className="text-align-center">费用申请</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        destroyOnClose
        onClose={handleCancel}>

        <BasicInfo row={{...processRecordsRow!}} ref={basicInfo} isView={isView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button
              type="primary"
              onClick={handleOk}
              loading={confirmLoading}
              className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>
    </div>
  )
}))
