import React, {useState} from "react";
import {observer} from "mobx-react";
import {Modal} from "antd";
import Draggable from "react-draggable";

export interface PropsType {
  children?: React.ReactNode;
  open?: boolean;
  okText?: string;
  width?: number;
  zIndex?: number;
  destroyOnClose?: boolean;
  confirmLoading?: boolean;
  title?: React.ReactNode;
  footer?: React.ReactNode;
  onOk?: () => void;
  onCancel?: () => void;
}

export const CustomDraggableModal = observer((props: PropsType) => {

  const [disabled, setDisabled] = useState<boolean>(false)
  const [bounds, setBounds] = useState({left: 0, top: 0, bottom: 0, right: 0})

  const onStart = (event: any, uiData: any) => {
    // logger.log('onStart')
    const {clientWidth, clientHeight} = window.document.documentElement;
    const targetRect = uiData.node.getBoundingClientRect();
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const onMouseOver = () => {
    // logger.log('onMouseOver')
    setDisabled(false)
  }

  const onMouseOut = () => {
    // logger.log('onMouseOut')
    setDisabled(true)
  }

  return (

    <Modal
      title={<>
        <div onMouseOver={onMouseOver} onMouseOut={onMouseOut} className={'c-move'}>
          { props.title }
        </div>
      </>}
      okText={props.okText}
      width={props.width}
      footer={props.footer}
      confirmLoading={props.confirmLoading}
      open={props.open}
      destroyOnClose={props.destroyOnClose}
      onOk={props.onOk}
      onCancel={props.onCancel}
      zIndex={props.zIndex}
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          { modal }
        </Draggable>
      )}
    >
      { props.children }
    </Modal>
  )
})
