import {apiV1} from "@services/apiHelper";
import {UserProfileRow} from "@services/dto/userProfile";

export class UserProfileService {
  static async getUserProfileByUserId(userId: string): Promise<UserProfileRow> {
    return await apiV1.get(`/userProfiles/${userId}`)
  }

  static async deleteUserProfileByUserIdById(userId: string, id: string): Promise<void> {
    return await apiV1.delete(`/userProfiles/${userId}/${id}`)
  }
}
