import React, {FC, useEffect, useState} from "react";
import {Button, Card, message, Space, Table} from 'antd'
import {ColumnsType} from "antd/es/table";
import {allStores} from "@/stores";
import {observer} from "mobx-react";
import {AttendanceReq, AttendanceRow} from "@services/dto/workTime";
import {WorkTimeService} from "@services/WorkTimeService";
import {showErr} from "@common/utils";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {HeaderAction} from "@modules/Attendance/Components/HeaderAction";
import IconFont from "@components/IconFont";

export const Attendance: FC = observer(() => {
  const navigate = useNavigate();
  const {workTimeStore} = allStores;
  const [attendanceReq, setAttendanceReq] = useState<AttendanceReq>({
    perPage: 10,
    pageNum: 1,
    year: dayjs().year(),
    month: dayjs().month() + 1
  })
  const [dataSource, setDataSource] = useState<AttendanceRow[]>([]);
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const columns: ColumnsType<AttendanceRow> = [
    {
      title: '姓名',
      dataIndex: 'userName',
      key: 'userName',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => { handleView(record) }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '身份证号',
      dataIndex: 'chinaUid',
      key: 'chinaUid',
      align: 'center'
    },
    {
      title: '岗位',
      dataIndex: 'professional',
      key: 'professional',
      align: 'center'
    },
    {
      title: '总工日',
      dataIndex: 'totalAttendance',
      key: 'totalAttendance',
      align: 'center'
    },
    {
      title: '出勤日',
      dataIndex: 'attendance',
      key: 'attendance',
      align: 'center'
    },
    {
      title: '总工时',
      dataIndex: 'whenTheGeneral',
      key: 'whenTheGeneral',
      align: 'center',
    },
    {
      title: '操作',
      align: "center",
      render: (_, record) => (
        <Space size="middle">
          <Button 
            shape="round" 
            className={"edit-btn"} 
            size={"middle"} 
            icon={<IconFont type={"hr-chakan1"} className={"icon-fontsize"} />}
            onClick={() => { handleView(record) }}>查看</Button>
        </Space>
      ),
    }
  ]
  
  const handleView = (record: AttendanceRow) => {
    navigate(`/attendanceSheet/${record.userId}/${record.userName}`)
  }

  useEffect(() => {
    refreshData(attendanceReq)
  }, [])

  const refreshData = (req: AttendanceReq) => {
    workTimeStore.getWorkerTimeAttendances(req)
  }

  useEffect(() => {
    setDataSource(workTimeStore.attendances.data?.items!)
    setTotal(workTimeStore.attendances.data?.total!)
  }, [workTimeStore.attendances])

  const handlePageChange = (page: number, pageSize: number) => {
    const req = {...attendanceReq}
    req.pageNum = page
    req.perPage = pageSize
    setAttendanceReq(req)
    refreshData(req)
  }

  const handleSearch = (value: string) => {
    const req = {...attendanceReq}
    req.pageNum = 1
    req.keyword = value
    setAttendanceReq(req)
    refreshData(req)
  }

  const handleProjectChange = (value: string) => {
    const req = {...attendanceReq}
    req.pageNum = 1
    req.projectId = value
    setAttendanceReq(req)
    refreshData(req)
  }

  const handleDateChange = (year: number, month: number) => {
    const req = {...attendanceReq}
    req.pageNum = 1
    req.year = year
    req.month = month
    setAttendanceReq(req)
    refreshData(req)
  }

  const handleBatchDelete = () => {
    if (selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    const ids:string[] = selectedRowKeys.map(item => String(item))
    WorkTimeService.deleteBatchWorkerTime(ids).then(rsp => {
      if (!rsp) {
        message.success("删除成功")
        refreshData(attendanceReq)
      } else {
        message.error("删除失败")
      }
    }).catch(showErr)
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  }

  return (
    <div className="attendance">
      <HeaderAction
        handleProjectChange={handleProjectChange}
        handleSearch={handleSearch}
        refreshData={() => refreshData(attendanceReq)}
        handleBatchDelete={handleBatchDelete}
        handleDateChange={handleDateChange}/>

      <Card className={"margin-top-sm"}>
        <Table 
          rowKey={"id"} 
          dataSource={dataSource} 
          columns={columns} 
          size={"middle"}
          loading={workTimeStore.attendances.loading}
          pagination={{
            total: total,
            position: ['bottomCenter'],
            pageSize: attendanceReq.perPage,
            current: attendanceReq.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`,
            onChange: handlePageChange
          }}
          rowSelection={{selectedRowKeys, onChange: onSelectChange}}/>
      </Card>
    </div>
  )
})
