import {Any} from "@common/types";
import {DictItem, StatisticsItemsReq, StatisticsItemsRow} from "@services/dto/dict";
import {Select} from "antd";
import React, {useEffect, useState} from "react";
import {DictService} from "@services/DictService";
import {showErr} from "@common/utils";

export interface PropsType {
  code: string
  onChange?: (value: Any, options: DictItem | DictItem[]) => void
  placeholder: string
  value?: Any
  allowClear?: boolean
  labelInValue?: boolean
  mode?: 'multiple' | 'tags'
  className?: string
  disabled?: boolean
  statisticsItemsReq?: StatisticsItemsReq
  style?: React.CSSProperties
  updateCount?: number
  defaultItems?: { id: string, value: string }[]
  isCustomerOptions?: DictItem[]
  fieldNames?: { value: string, label: string }
}

export const SelectDict = (props: PropsType) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [dict, setDict] = useState<DictItem[]>([])
  const [statisticsItems, setStatisticsItems] = useState<StatisticsItemsRow[]>([])

  /**
   *  每个组件默认写法 只需修改 'clCode'
   */
  const defaultItems: { id: string, value: string }[] = [
    {id: 'N/A', value: 'N/A'},
    {id: '空白', value: '空白'},
  ]

  useEffect(() => {
    if (props.statisticsItemsReq) {
      // 统计查询
      getStatisticsItems()
    } else {
      // 查询字典
      getByKey()
    }
  }, [props.code])

  useEffect(() => {
    if (props.updateCount && props.statisticsItemsReq && props.value) {
      getStatisticsItems()
    }
  }, [props.updateCount]);

  const getStatisticsItems = () => {
    setLoading(true)
    DictService.getStatisticsItems(props.statisticsItemsReq).then(rsp => {
      const row = JSON.parse(JSON.stringify(rsp.data || [])).map((item: StatisticsItemsRow) => {
        item.value = `${item.value}(${item.coun})`
        return item
      })
      setStatisticsItems(row);
    }).catch(showErr).finally(() => setLoading(false))
  }

  const getByKey = () => {
    setLoading(true)
    DictService.getByKey(props.code).then(rsp => {
      setDict(rsp.value.map((o, i)=>({id:o.id,key:o?.key || o?.id || `index-${i}`,value:o.value})) || []);
    }).catch(showErr).finally(() => setLoading(false))
  }

  return (
    <Select
      allowClear={props.allowClear}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      mode={props.mode}
      showSearch
      style={props.style}
      filterOption={(input: string, option) =>
        String(option?.value).toLowerCase().includes(input.toLowerCase())}
      labelInValue={props.labelInValue}
      className={props.className}
      disabled={props.disabled}
      fieldNames={props.statisticsItemsReq ? {value: 'id', label: 'value'} : (props.fieldNames || {value: 'value', label: 'value'})}
      options={props.statisticsItemsReq ? [...statisticsItems] : [...(props.defaultItems || defaultItems), ...(props.isCustomerOptions || dict)]}
      loading={loading}>
    </Select>
  )
}
