import React, {useEffect, useLayoutEffect, useState} from "react";
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {WorkerRecruitmentReq, WorkerRecruitmentRow} from "@services/dto/workerRecruitment";
import {
  BASE_API_PATH,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  EDIT_TEXT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x,
  WorkerRecruitmentStatus
} from "@/config";
import {Button, FormInstance, message, Modal, Space, Table} from "antd";
import {WorkerRecruitmentService} from "@services/WorkerRecruitmentService";
import {safeCompute, showErr} from "@common/utils";
import {useLocation, useNavigate} from "react-router-dom";
import {previewFile} from "@components/CustomUpload";
import {ColumnsType} from "antd/es/table";
import {fundApprovalStore} from "@modules/FundApproval/Stores/FundApprovalStore";
import {HeaderActionCom} from "@modules/Archives/Components/HeaderActionCom";
import {TableOptions} from "@components/TableOptions";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {CloudDownloadOutlined, ExportOutlined} from "@ant-design/icons";
import {CustomImport} from "@components/CustomImport/CustomImport";

const blackColumnList = [
  'action',
]

const wideShowColumn: string[] = [
  'name',
  'sortCode',
  'chinaUid',
  'personnelCategory',
  'professionalLeaguesName',
  'workerTypeName',
  'professional',
  'projects',
  'action',
]

const narrowShowColumn: string[] = [
  'name',
  // 'sortCode',
  'chinaUid',
  'personnelCategory',
  'professionalLeaguesName',
  'workerTypeName',
  'professional',
  'projects',
  'action',
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: WorkerRecruitmentRow[] // 数据源
  setFundApprovalSelectedRowKeys?: (key: React.Key[]) => void // 所选行
}

export const Archives = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const navigate = useNavigate();
  const location = useLocation()
  const {
    tableColumnsStore,
    screenResolutionStore,
    wrStore,
    professionStore,
  } = allStores;
  const [dataSource, setDataSource] = useState<WorkerRecruitmentRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [renderColumns, setRenderColumns] = useState<ColumnsType<WorkerRecruitmentRow>>([])
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [searchReq, setSearchReq] = useState<WorkerRecruitmentReq>({
    pageNum: 1,
    perPage: 10,
    status: WorkerRecruitmentStatus.hired,
    ...professionStore.professionSearchReq
  })

  const columns: ColumnsType<WorkerRecruitmentRow> = [
    {
      title: '姓名',
      key: 'name',
      dataIndex: ['user', 'name'],
      align: "center",
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '远工编号',
      key: 'sortCode',
      dataIndex: ['user', 'sortCode'],
      align: "center",
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '身份证',
      key: 'chinaUid',
      dataIndex: ['user', 'chinaUid'],
      align: "center",
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '人员类别',
      key: 'personnelCategory',
      dataIndex: ['user', 'personnelCategory'],
      align: "center",
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '队部',
      key: 'professionalLeaguesName',
      dataIndex: "professionalLeaguesName",
      align: "center",
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '工种',
      key: 'workerTypeName',
      dataIndex: "workerTypeName",
      align: "center",
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '职位',
      key: 'professional',
      dataIndex: ['user', 'professional'],
      align: "center",
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '所属项目',
      key: 'projects',
      dataIndex: ['projects'],
      align: "center",
      width: TABLE_CELL_WIDTH_2x,
      render: (_, record) => (
        <Space>
          <TableEllipsisText value={
            record.projects.map(item => {
              return item.name
            }).join("、")
          }></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: "center",
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableOptions
          handleEdit={() => {
            handleEdit(record)
          }}
          handleDelete={() => {
            handleDeleteBatch(record)
          }}
          items={[
          ]}
          onClick={() => {
            handleEdit(record)
          }}>
          { EDIT_TEXT }
        </TableOptions>
      ),
    },
  ];

  /**
   * @description 初始化
   */
  useEffect(() => {

    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  /**
   * @description 表格数据变化
   */
  useEffect(() => {
    if (wrStore.workerRecruitmentDataSource.data) {
      setDataSource(wrStore.workerRecruitmentDataSource.data.items || []);
      setTotal(wrStore.workerRecruitmentDataSource.data.total || 0)
    }
  }, [wrStore.workerRecruitmentDataSource.data])

  /**
   * 列配置变化
   */
  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => !blackColumnList.includes(o.key as string)).filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, fundApprovalStore.collapsed, searchReq.pageNum, searchReq.perPage])

  const refreshData = (recruitmentReq: WorkerRecruitmentReq) => {
    const req = {...recruitmentReq}
    setSearchReq(req)
    wrStore.postWorkerRecruitments(req)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    const req = {...searchReq}
    req.pageNum = page
    req.perPage = pageSize
    refreshData(req)
    setSearchReq(req)
  }

  const handleDeleteBatch = (record?: WorkerRecruitmentRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record._id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        WorkerRecruitmentService.deleteBatchWorkerRecruitment(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(searchReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handleEdit = (record: WorkerRecruitmentRow) => {
    navigate(`/archiveUserAction/${record._id}/user/${record.user._id}`)
  }

  const handleAdd = () => {
    navigate("/archiveUserAction")
  }

  const handleExport = () => {
    let req = {...searchReq}
    req.pageNum = 1
    req.isExport = true
    WorkerRecruitmentService.exportWorkerRecruitments(req).then(rsp => {
      previewFile(rsp)
    }).catch(showErr)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    wrStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  const handleDownloadTemp = () => {
    window.open(BASE_API_PATH + "/filepath/花名册.xlsx")
  }

  return (
    <>

      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        isHiddenOptions={props.isHiddenOptions}
        collapsed={wrStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        selectedRowKeys={selectedRowKeys}
        handleShowColumnReset={handleShowColumnReset}
        handleDeleteBatch={handleDeleteBatch}
        handleAdd={handleAdd}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        items={[
          <Button
            block
            className={'text-align-left'}
            type={"text"}
            onClick={() => handleDownloadTemp()}>
            <CloudDownloadOutlined/>
            导入模板&nbsp;下载
          </Button>,
          <CustomImport
            url={'/v1/system/user/import'}
            callback={() => refreshData(wrStore.workerRecruitmentReq)}></CustomImport>,
          <Button
            block
            type={"text"}
            className={'text-align-left'}
            onClick={handleExport}>
            <ExportOutlined/>
            导出
          </Button>,
        ]}
        tableColumns={props.isModalSelect ? columns.filter(o => !blackColumnList.includes(o.key as string)) : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}></HeaderActionCom>

      <Table
        className={'margin-top-xss'}
        rowKey={"_id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        size={"middle"}
        loading={props.dataSource ? false : wrStore.workerRecruitmentDataSource.loading || confirmLoading}
        bordered
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        pagination={!props.dataSource && {
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: selectedRowKeys => setSelectedRowKeys(selectedRowKeys),
          columnWidth: 50,
        }}/>
    </>
  )
}))