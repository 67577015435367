import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {TaxAccountingMiddle, TaxAccountingRow} from "@services/dto/taxAccounting";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {allStores} from "@/stores";
import dayjs from "dayjs";
import {SelectFiling} from "@components/SelectFiling";
import {SelectDict} from "@components/SelectDict";
import {ProjectRow} from "@services/dto/project";
import {SelectBankAccount} from "@components/SelectBankAccount";
import {Any} from "@common/types";
import {BankAccountRow} from "@services/dto/bankAccount";
import {SelectInvoiceRegister} from "@components/SelectInvoiceRegister";
import {accAdd, accMultiply, accSubtract} from "@common/utils";
import {InvoiceRegisterService} from "@services/InvoiceRegisterService";
import {RadioChangeEvent} from "antd/es/radio/interface";
import {SelectFundApproval} from "@components/SelectFundApproval";
import {FundApprovalRow} from "@services/dto/fundApproval";
import {SelectTaxPlan} from "@components/SelectTaxPlan";
import {TaxPlanRow} from "@services/dto/taxPlan";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {FORM_DISABLED_STATE} from "@/config";
import {ProjectService} from "@services/ProjectService";

export interface PropsType {
  row: TaxAccountingRow
  isView?: boolean
  isBatchUpdate?: boolean
}

const taxBaseOptions = [
  {value: 'N/A', label: 'N/A'},
  {value: 'taOutIrAmount', label: '销项价税合计'},
  {value: 'taVatPayable', label: '应缴增值税额'}
]

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()
  const [taEnterprise, setTaEnterprise] = useState<string>()
  const [outIdNotInIds, setOutIdNotInIds] = useState<string>()
  const [inIdNotInIds, setInIdNotInIds] = useState<string>()
  const [taxCategory, setTaxCategory] = useState<string>()
  const [cardinals, setCardinals] = useState({
    cityCardinal: 'N/A',
    educationCardinal: 'N/A',
    spaceCardinal: 'N/A',
    printCardinal: 'N/A',
    unionCardinal: 'N/A',
    hydraulicCardinal: 'N/A'
  })

  useEffect(() => {
    form.resetFields()
    const taxAccountingRow = {...props.row}
    filingStore.setFilingFaCode(taxAccountingRow.taCode)
    setOutIdNotInIds(String(taxAccountingRow.invoiceRegistrationOutId))
    setInIdNotInIds(String(taxAccountingRow.invoiceRegistrationInId))

    if (taxAccountingRow.taxCategory) {
      handleTaxCategory(taxAccountingRow.taxCategory)
    }

    if (typeof taxAccountingRow.taDate === 'string' && taxAccountingRow.taDate !== '') {
      taxAccountingRow.taDate = dayjs(taxAccountingRow.taDate);
    }
    if (typeof taxAccountingRow.amountRealizedDate === 'string' && taxAccountingRow.amountRealizedDate !== '') {
      taxAccountingRow.amountRealizedDate = dayjs(taxAccountingRow.amountRealizedDate);
    }
    if (typeof taxAccountingRow.invoiceRegistrationOutId === 'string') {
      taxAccountingRow.invoiceRegistrationOutId = taxAccountingRow.invoiceRegistrationOutId !== '' ? taxAccountingRow.invoiceRegistrationOutId.split(',') : []
    }
    if (typeof taxAccountingRow.invoiceRegistrationInId === 'string') {
      taxAccountingRow.invoiceRegistrationInId = taxAccountingRow.invoiceRegistrationInId !== '' ? taxAccountingRow.invoiceRegistrationInId.split(',') : []
    }
    if (typeof taxAccountingRow.fundingApprovalList === 'object') {
      taxAccountingRow.fundApprovalIds = (taxAccountingRow.fundingApprovalList as FundApprovalRow[])?.map(o => o.id)
    }
    if (typeof taxAccountingRow.cooperationUnit === 'string') {
      taxAccountingRow.cooperationUnit = taxAccountingRow.cooperationUnit !== '' ? taxAccountingRow.cooperationUnit.split(',') : []
    }
    setTaxCategory(taxAccountingRow.taxCategory)
    setTaEnterprise(taxAccountingRow.taEnterprise as string)
    setResponsibilityProject(taxAccountingRow.responsibilityProject)
    setCooperationUnit(taxAccountingRow.cooperationUnit)
    form.setFieldsValue(taxAccountingRow);
    setAdditionalTaxAmount(taxAccountingRow.taxAccountingMiddle)
    restCardinal()
  }, [props.row.id])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    setResponsibilityProject(value)
    const row = options.find(o => value === o.name)
    form.setFieldsValue({code: row?.code})
  }
  // 关联销项发票
  const handleOutIdSelectModal = async (selectRowKeys: string[]) => {
    let taOutIrAmount = 0;
    let taOutTaxIrAmount = 0;
    if (selectRowKeys.length !== 0) {
      const outIdResult = await InvoiceRegisterService.getBatch(selectRowKeys);
      for (let i = 0; i < outIdResult.data.length; i++) {
        taOutIrAmount = accAdd(taOutIrAmount, outIdResult.data[i].irAmount || 0)
        taOutTaxIrAmount = accAdd(taOutTaxIrAmount, outIdResult.data[i].irTaxAmount || 0)
      }
    }
    const taVatPayable = accSubtract(taOutTaxIrAmount, (form.getFieldValue("taInTaxIrAmount") || 0))
    setOutIdNotInIds(selectRowKeys.join(","))
    form.setFieldsValue({
      invoiceRegistrationOutId: selectRowKeys,
      taOutIrAmount,
      taOutTaxIrAmount,
      taVatPayable
    })
    handleChangeTaxAccountingMiddle()
    setAdditionalTaxAmount()
  }
  // 关联进项发票
  const handleInIdSelectModal = async (selectRowKeys: string[]) => {
    let taInIrAmount = 0;
    let taInTaxIrAmount = 0;
    if (selectRowKeys.length !== 0) {
      const inIdResult = await InvoiceRegisterService.getBatch(selectRowKeys);
      for (let i = 0; i < inIdResult.data.length; i++) {
        taInIrAmount = accAdd(taInIrAmount, inIdResult.data[i].irAmount || 0)
        taInTaxIrAmount = accAdd(taInTaxIrAmount, inIdResult.data[i].irTaxAmount || 0)
      }
    }
    if (form.getFieldValue("taxMethod") === '简易计税法') {
      taInTaxIrAmount = 0
    }
    const taVatPayable = accSubtract((form.getFieldValue("taOutTaxIrAmount") || 0), (form.getFieldValue("taCategory") === '小规模纳税人' ? 0 : taInTaxIrAmount));
    setInIdNotInIds(selectRowKeys.join(","))
    form.setFieldsValue({
      invoiceRegistrationInId: selectRowKeys,
      taInIrAmount,
      taInTaxIrAmount,
      taVatPayable
    })
    handleChangeTaxAccountingMiddle()
    setAdditionalTaxAmount()
  }

  const handleChangeTaxAccountingMiddle = () => {
    // taOutIrAmount  销项价税合计  taVatPayable 应缴增值税额
    const formObject = form.getFieldsValue()
    const tam = formObject.taxAccountingMiddle
    if (tam) {
      if (tam.cityCardinal === 'taOutIrAmount') {
        form.setFieldValue(['taxAccountingMiddle', 'cityDue'], accMultiply(formObject.taOutIrAmount || 0, accMultiply(tam.cityTax || 0, 0.01)))
      } else if (tam.cityCardinal === 'taVatPayable') {
        form.setFieldValue(['taxAccountingMiddle', 'cityDue'], accMultiply(formObject.taVatPayable || 0, accMultiply(tam.cityTax || 0, 0.01)))
      }
      if (tam.educationCardinal === 'taOutIrAmount') {
        form.setFieldValue(['taxAccountingMiddle', 'educationDue'], accMultiply(formObject.taOutIrAmount || 0, accMultiply(tam.educationTax || 0, 0.01)))
      } else if (tam.educationCardinal === 'taVatPayable') {
        form.setFieldValue(['taxAccountingMiddle', 'educationDue'], accMultiply(formObject.taVatPayable || 0, accMultiply(tam.educationTax || 0, 0.01)))
      }
      if (tam.spaceCardinal === 'taOutIrAmount') {
        form.setFieldValue(['taxAccountingMiddle', 'spaceDue'], accMultiply(formObject.taOutIrAmount || 0, accMultiply(tam.spaceTax || 0, 0.01)))
      } else if (tam.spaceCardinal === 'taVatPayable') {
        form.setFieldValue(['taxAccountingMiddle', 'spaceDue'], accMultiply(formObject.taVatPayable || 0, accMultiply(tam.spaceTax || 0, 0.01)))
      }
      if (tam.printCardinal === 'taOutIrAmount') {
        form.setFieldValue(['taxAccountingMiddle', 'printDue'], accMultiply(formObject.taOutIrAmount || 0, accMultiply(tam.printTax || 0, 0.01)))
      } else if (tam.printCardinal === 'taVatPayable') {
        form.setFieldValue(['taxAccountingMiddle', 'printDue'], accMultiply(formObject.taVatPayable || 0, accMultiply(tam.printTax || 0, 0.01)))
      }
      if (tam.unionCardinal === 'taOutIrAmount') {
        form.setFieldValue(['taxAccountingMiddle', 'unionDue'], accMultiply(formObject.taOutIrAmount || 0, accMultiply(tam.unionTax || 0, 0.01)))
      } else if (tam.unionCardinal === 'taVatPayable') {
        form.setFieldValue(['taxAccountingMiddle', 'unionDue'], accMultiply(formObject.taVatPayable || 0, accMultiply(tam.unionTax || 0, 0.01)))
      }
      if (tam.hydraulicCardinal === 'taOutIrAmount') {
        form.setFieldValue(['taxAccountingMiddle', 'hydraulicDue'], accMultiply(formObject.taOutIrAmount || 0, accMultiply(tam.hydraulicTax || 0, 0.01)))
      } else if (tam.hydraulicCardinal === 'taVatPayable') {
        form.setFieldValue(['taxAccountingMiddle', 'hydraulicDue'], accMultiply(formObject.taVatPayable || 0, accMultiply(tam.hydraulicTax || 0, 0.01)))
      }
    }
  }

  /**
   * @desc 账户组件修改默认填充简称字段
   * @param value
   * @param options
   */
  const handleBsAccountPayIdChange = (value: Any | null, options: BankAccountRow[]) => {
    const row = options.find(o => value === o.id)
    form.setFieldsValue({baNick1: row?.baNick || ""})
    form.setFieldsValue({baName1: row?.baName || ""})
    form.setFieldsValue({baAccount1: row?.baAccount || ""})
    form.setFieldsValue({baBlank1: row?.baBlank || ""})
  }

  /**
   * @desc 账户组件修改默认填充简称字段
   * @param value
   * @param options
   */
  const handleBsAccountInChange = (value: Any | null, options: BankAccountRow[]) => {
    const row = options.find(o => value === o.id)
    form.setFieldsValue({baNick2: row?.baNick || ""})
    form.setFieldsValue({baName2: row?.baName || ""})
    form.setFieldsValue({baAccount2: row?.baAccount || ""})
    form.setFieldsValue({baBlank2: row?.baBlank || ""})
  }

  const handleTaEnterprise = (value: string) => {
    form.resetFields([
      "invoiceRegistrationOutId",
      "invoiceRegistrationInId",
      "taOutIrAmount",
      "taInIrAmount",
      "taOutTaxIrAmount",
      "taInTaxIrAmount",
      "taVatPayable",
      "additionalTaxAmount"
    ])
    setTaEnterprise(value)
  }

  const handleCardinalChange = (value: string, field1: string, field2: string) => {
    if (value !== 'N/A') {
      const fieldValue = form.getFieldValue(`${value}`);
      const field1Value = form.getFieldValue(['taxAccountingMiddle', field1]);
      form.setFieldValue(['taxAccountingMiddle', field2], accMultiply((fieldValue || 0), accMultiply((field1Value || 0), 0.01)));
    } else {
      form.setFieldValue(['taxAccountingMiddle', field1], 0);
      form.setFieldValue(['taxAccountingMiddle', field2], 0);
    }
    setAdditionalTaxAmount()
  }

  const handleTaxChange = (value: number, field1: string, field2: string) => {
    const field1Value = form.getFieldValue(['taxAccountingMiddle', field1]);
    if (field1Value !== 'N/A') {
      const fieldValue = form.getFieldValue(`${field1Value}`);
      form.setFieldValue(['taxAccountingMiddle', field2], accMultiply((fieldValue || 0), accMultiply((value || 0), 0.01)));
    } else {
      form.setFieldValue(['taxAccountingMiddle', field2], 0);
    }
    if (field2 !== 'enterpriseDue' && field2 !== 'managerDue') {
      setAdditionalTaxAmount();
    }
  }

  const setAdditionalTaxAmount = (taxAccountingMiddle?: TaxAccountingMiddle) => {
    const formObject = form.getFieldsValue()
    const tam = taxAccountingMiddle || formObject.taxAccountingMiddle
    let additionalTaxAmount = 0
    if (tam) {
      additionalTaxAmount = accAdd(accAdd(accAdd(accAdd(accAdd(tam.cityDue || 0, tam.educationDue || 0), tam.spaceDue || 0), tam.printDue || 0), tam.unionDue || 0), tam.hydraulicDue || 0);
    }
    form.setFieldsValue({additionalTaxAmount})
  }

  const handleTaCategoryChange = (e: RadioChangeEvent) => {
    if (e.target.value === '小规模纳税人') {
      form.setFieldsValue({
        taVatPayable: form.getFieldValue("taOutTaxIrAmount") || 0
      })
    } else {
      form.setFieldsValue({
        taVatPayable: accSubtract((form.getFieldValue("taOutTaxIrAmount") || 0), (form.getFieldValue("taInTaxIrAmount") || 0))
      })
    }
    handleChangeTaxAccountingMiddle()
    setAdditionalTaxAmount()
  }

  const restCardinal = () => {
    const formObject = form.getFieldsValue()
    const tam = formObject.taxAccountingMiddle
    if (tam) {
      setCardinals({
        cityCardinal: tam.cityCardinal,
        educationCardinal: tam.educationCardinal,
        spaceCardinal: tam.spaceCardinal,
        printCardinal: tam.printCardinal,
        unionCardinal: tam.unionCardinal,
        hydraulicCardinal: tam.hydraulicCardinal
      })
    }
  };

  const handleTaxCategory = (value: string) => {
    setTaxCategory(value)
    // const formObject = form.getFieldsValue()
    // if (['增值税及附加', '企业所得税', '项目管理费'].includes(value)) {
    //   form.resetFields([
    //     "invoiceRegistrationOutId",
    //     "invoiceRegistrationInId",
    //     "taOutIrAmount",
    //     "taInIrAmount",
    //     "taOutTaxIrAmount",
    //     "taInTaxIrAmount",
    //     "taVatPayable",
    //     "taxMethod",
    //     "additionalTaxAmount"
    //   ])
    // }
    // let taxAccountingMiddle = formObject.taxAccountingMiddle
    // if (taxAccountingMiddle) {
    //   taxAccountingMiddle = {
    //     cityCardinal: undefined,
    //     cityTax: undefined,
    //     cityDue: undefined,
    //     educationCardinal: undefined,
    //     educationTax: undefined,
    //     educationDue: undefined,
    //     spaceCardinal: undefined,
    //     spaceTax: undefined,
    //     spaceDue: undefined,
    //     printCardinal: undefined,
    //     printTax: undefined,
    //     printDue: undefined,
    //     unionCardinal: undefined,
    //     unionTax: undefined,
    //     unionDue: undefined,
    //     hydraulicCardinal: undefined,
    //     hydraulicTax: undefined,
    //     hydraulicDue: undefined,
    //     personDue: undefined,
    //     enterpriseCardinal: undefined,
    //     enterpriseTax: undefined,
    //     enterpriseDue: undefined,
    //     managerCardinal: undefined,
    //     managerTax: undefined,
    //     managerDue: undefined
    //   }
    // }
    // form.setFieldsValue({
    //   taxAccountingMiddle
    // });
  }

  const handleTaxMethod = async (e: RadioChangeEvent) => {
    if (e.target.value === '简易计税法') {
      form.setFieldsValue({taInTaxIrAmount: 0})
    } else {
      await handleInIdSelectModal(form.getFieldValue("invoiceRegistrationInId") || [])
    }
  }

  const handleTaxPlanChangeChange = async (value: Any | null, options: TaxPlanRow[]) => {
    const taxPlan = options.find(o => o.id === value)
    const cooperationUnit = typeof taxPlan?.cooperationUnit === 'string' ? taxPlan?.cooperationUnit?.split(",") : taxPlan?.cooperationUnit
    let responsibilityProject:ProjectRow
    if (taxPlan?.responsibilityProject) {
      [responsibilityProject] = await ProjectService.getBatchByName([taxPlan?.responsibilityProject])
    }
    setResponsibilityProject(taxPlan?.responsibilityProject)
    setCooperationUnit(taxPlan?.cooperationUnit)
    form.setFieldsValue({
      employeeFeeTaxScheduleCode: taxPlan?.efaApplyCode,
      responsibilityProject: taxPlan?.responsibilityProject,
      cooperationUnit: cooperationUnit,
      code: responsibilityProject?.code
    })
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      initialValues={{
        cooperationUnit: ['N/A']
      }}
      wrapperCol={{span: 16}}
      disabled={props.isView || FORM_DISABLED_STATE.includes(props.row.taStatus)}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="baNick1">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="baNick2">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="employeeFeeTaxScheduleCode">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="organizersUserName">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="taDate"
            rules={[{required: false, message: '请输入制单日期!'}]}>
            <DatePicker disabled placeholder={"请输入制单日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="taCode"
            rules={[{required: false, message: '请输入制单编号!'}]}>
            <Input placeholder="请输入制单编号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="核算部门"
            name="taSegment"
            rules={[{required: !props.isBatchUpdate, message: '请选择核算部门!'}]}>
            <SelectProject
              placeholder={"请选择核算部门"}
              allowClear
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="核算企业"
            name="taEnterprise"
            rules={[{required: !props.isBatchUpdate, message: '请选择核算企业!'}]}>
            <SelectCooperativeUnit
              placeholder={"请选择核算企业"}
              mode={'multiple'}
              onChange={handleTaEnterprise}
              allowClear
              cooperativeUnitCategory={"内部协作单位"}
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label=""
            name="taCategory"
            labelCol={{span: 0}}
            wrapperCol={{offset: 6, span: 16}}
            rules={[{required: !props.isBatchUpdate, message: '请选择纳税人级别!'}]}>
            <Radio.Group onChange={handleTaCategoryChange}>
              <Radio value={'一般纳税人'}>一般纳税人</Radio>
              <Radio value={'小规模纳税人'}>小规模纳税人</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="税费类别"
            name="taxCategory"
            rules={[{required: !props.isBatchUpdate, message: '请选择税费类别!'}]}>
            <SelectDict allowClear code={'tax_category'} placeholder={'请选择税费类别'} onChange={handleTaxCategory}/>
          </Form.Item>
        </Col>
        {
          taxCategory === '增值税及附加' && <Col span={24}>
            <Form.Item
              label="计税方法"
              name="taxMethod"
              rules={[{required: !props.isBatchUpdate, message: '请选择计税方法!'}]}>
              <Radio.Group onChange={handleTaxMethod}>
                <Radio value={'一般计税法'}>一般计税法</Radio>
                <Radio value={'简易计税法'}>简易计税法</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        }
        <Col span={24}>
          <Form.Item
            label="税费摘要"
            name="taDesc"
            rules={[{required: false, message: '请输入税费摘要!'}]}>
            <Input.TextArea placeholder="请输入税费摘要" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        {
          ['增值税及附加', '企业所得税', '项目管理费'].includes(taxCategory) && <>
            <Col span={24}>
              <Form.Item
                label="关联销项发票"
                name="invoiceRegistrationOutId"
                rules={[{required: false, message: '请输入关联销项发票!'}]}>
                <SelectInvoiceRegister
                  allowClear
                  mode={'multiple'}
                  className={'width-100-percentage'}
                  isHiddenOption
                  isModalSelect={!props.isView}
                  modalSelect={handleOutIdSelectModal}
                  disabled
                  irSelle={taEnterprise}
                  selectDisabled={!taEnterprise}
                  outOrInIsNull={'out'}
                  inIds={outIdNotInIds}
                  notInIds={inIdNotInIds}
                  modalTitle={"关联销项发票"}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="关联进项发票"
                name="invoiceRegistrationInId"
                rules={[{required: false, message: '请输入关联进项发票!'}]}>
                <SelectInvoiceRegister
                  allowClear
                  mode={'multiple'}
                  className={'width-100-percentage'}
                  isHiddenOption
                  isModalSelect={!props.isView}
                  modalSelect={handleInIdSelectModal}
                  disabled
                  irType={'增值税专用发票'}
                  irPurchaser={taEnterprise}
                  selectDisabled={!taEnterprise}
                  outOrInIsNull={'in'}
                  inIds={inIdNotInIds}
                  notInIds={outIdNotInIds}
                  modalTitle={"关联进项发票"}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="销项价税合计"
                name="taOutIrAmount"
                rules={[{required: false, message: '请输入销项价税合计!'}]}>
                <InputNumber precision={2} placeholder="请输入销项价税合计" className={"width-100-percentage"} disabled/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="进项价税合计"
                name="taInIrAmount"
                rules={[{required: false, message: '请输入进项价税合计!'}]}>
                <InputNumber precision={2} placeholder="请输入进项价税合计" className={"width-100-percentage"} disabled/>
              </Form.Item>
            </Col>
          </>
        }
        {
          taxCategory === '增值税及附加' && <>
            <Col span={24}>
              <Form.Item
                label="销项税额合计"
                name="taOutTaxIrAmount"
                rules={[{required: false, message: '请输入销项税额合计!'}]}>
                <InputNumber precision={2} placeholder="请输入销项税额合计" className={"width-100-percentage"} disabled/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="进项抵扣合计"
                name="taInTaxIrAmount"
                rules={[{required: false, message: '请输入进项抵扣合计!'}]}>
                <InputNumber precision={2} placeholder="请输入进项抵扣合计" className={"width-100-percentage"} disabled/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="应缴增值税额"
                name="taVatPayable"
                rules={[{required: false, message: '请输入应缴增值税额!'}]}>
                <InputNumber
                  precision={2}
                  placeholder="请输入应缴增值税额"
                  disabled
                  className={"width-100-percentage"}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={13}>
                  <Form.Item
                    label="城建税"
                    name={['taxAccountingMiddle', 'cityCardinal']}
                    labelCol={{span: 11}}
                    wrapperCol={{span: 13}}
                    rules={[{required: false, message: '请输入城建税!'}]}>
                    <Select
                      placeholder={'请选择计税基数'}
                      className={'width-100-percentage'}
                      onChange={(value) => {
                        handleCardinalChange(value, 'cityTax', 'cityDue')
                        restCardinal()
                      }}
                      options={taxBaseOptions}/>
                  </Form.Item>
                </Col>
                <Col span={1} className={"d-flex df-jcc df-aic"}>*</Col>
                <Col span={4}>
                  <Form.Item
                    label=""
                    name={['taxAccountingMiddle', 'cityTax']}
                    labelCol={{span: 0}}
                    wrapperCol={{span: 24}}
                    rules={[{required: false, message: '请输入税率!'}]}>
                    <InputNumber
                      precision={2}
                      placeholder="税率"
                      disabled={cardinals.cityCardinal === 'N/A' || props.isView}
                      className={"width-100-percentage"}
                      onChange={(value: number) => handleTaxChange(value, 'cityCardinal', 'cityDue')}
                      addonAfter={"%"}/>
                  </Form.Item>
                </Col>
                <Col span={1} className={"d-flex df-jcc df-aic"}>=</Col>
                <Col span={3}>
                  <Form.Item
                    label=""
                    name={['taxAccountingMiddle', 'cityDue']}
                    labelCol={{span: 0}}
                    wrapperCol={{span: 24}}
                    rules={[{required: false, message: '请输入城建税!'}]}>
                    <InputNumber
                      precision={2}
                      disabled={cardinals.cityCardinal !== 'N/A' || props.isView}
                      onChange={() => setAdditionalTaxAmount()}
                      placeholder="城建税"
                      className={"width-100-percentage"}/>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={13}>
                  <Form.Item
                    label="教育费附加"
                    name={['taxAccountingMiddle', 'educationCardinal']}
                    labelCol={{span: 11}}
                    wrapperCol={{span: 13}}
                    rules={[{required: false, message: '请输入教育费附加!'}]}>
                    <Select
                      placeholder={'请选择计税基数'}
                      className={'width-100-percentage'}
                      onChange={(value) => {
                        handleCardinalChange(value, 'educationTax', 'educationDue')
                        restCardinal()
                      }}
                      options={taxBaseOptions}/>
                  </Form.Item>
                </Col>
                <Col span={1} className={"d-flex df-jcc df-aic"}>*</Col>
                <Col span={4}>
                  <Form.Item
                    label=""
                    name={['taxAccountingMiddle', 'educationTax']}
                    labelCol={{span: 0}}
                    wrapperCol={{span: 24}}
                    rules={[{required: false, message: '请输入税率!'}]}>
                    <InputNumber
                      precision={2}
                      placeholder="税率"
                      className={"width-100-percentage"}
                      disabled={cardinals.educationCardinal === 'N/A' || props.isView}
                      onChange={(value: number) => handleTaxChange(value, 'educationCardinal', 'educationDue')}
                      addonAfter={"%"}/>
                  </Form.Item>
                </Col>
                <Col span={1} className={"d-flex df-jcc df-aic"}>=</Col>
                <Col span={3}>
                  <Form.Item
                    label=""
                    name={['taxAccountingMiddle', 'educationDue']}
                    labelCol={{span: 0}}
                    wrapperCol={{span: 24}}
                    rules={[{required: false, message: '请输入教育附加!'}]}>
                    <InputNumber
                      precision={2}
                      disabled={cardinals.educationCardinal !== 'N/A' || props.isView}
                      onChange={() => setAdditionalTaxAmount()}
                      placeholder="教育费"
                      className={"width-100-percentage"}/>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={13}>
                  <Form.Item
                    label="地方教育费附加"
                    name={['taxAccountingMiddle', 'spaceCardinal']}
                    labelCol={{span: 11}}
                    wrapperCol={{span: 13}}
                    rules={[{required: false, message: '请输入地方教育费附加!'}]}>
                    <Select
                      placeholder={'请选择计税基数'}
                      className={'width-100-percentage'}
                      onChange={(value) => {
                        handleCardinalChange(value, 'spaceTax', 'spaceDue')
                        restCardinal()
                      }}
                      options={taxBaseOptions}/>
                  </Form.Item>
                </Col>
                <Col span={1} className={"d-flex df-jcc df-aic"}>*</Col>
                <Col span={4}>
                  <Form.Item
                    label=""
                    name={['taxAccountingMiddle', 'spaceTax']}
                    labelCol={{span: 0}}
                    wrapperCol={{span: 24}}
                    rules={[{required: false, message: '请输入税率!'}]}>
                    <InputNumber
                      precision={2}
                      placeholder="税率"
                      className={"width-100-percentage"}
                      disabled={cardinals.spaceCardinal === 'N/A' || props.isView}
                      onChange={(value: number) => handleTaxChange(value, 'spaceCardinal', 'spaceDue')}
                      addonAfter={"%"}/>
                  </Form.Item>
                </Col>
                <Col span={1} className={"d-flex df-jcc df-aic"}>=</Col>
                <Col span={3}>
                  <Form.Item
                    label=""
                    name={['taxAccountingMiddle', 'spaceDue']}
                    labelCol={{span: 0}}
                    wrapperCol={{span: 24}}
                    rules={[{required: false, message: '请输入地方教育费附加!'}]}>
                    <InputNumber
                      precision={2}
                      disabled={cardinals.spaceCardinal !== 'N/A' || props.isView}
                      onChange={() => setAdditionalTaxAmount()}
                      placeholder="地方费"
                      className={"width-100-percentage"}/>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={13}>
                  <Form.Item
                    label="印花税"
                    name={['taxAccountingMiddle', 'printCardinal']}
                    labelCol={{span: 11}}
                    wrapperCol={{span: 13}}
                    rules={[{required: false, message: '请输入印花税!'}]}>
                    <Select
                      placeholder={'请选择计税基数'}
                      className={'width-100-percentage'}
                      onChange={(value) => {
                        handleCardinalChange(value, 'printTax', 'printDue')
                        restCardinal()
                      }}
                      options={taxBaseOptions}/>
                  </Form.Item>
                </Col>
                <Col span={1} className={"d-flex df-jcc df-aic"}>*</Col>
                <Col span={4}>
                  <Form.Item
                    label=""
                    name={['taxAccountingMiddle', 'printTax']}
                    labelCol={{span: 0}}
                    wrapperCol={{span: 24}}
                    rules={[{required: false, message: '请输入税率!'}]}>
                    <InputNumber
                      precision={2}
                      placeholder="税率"
                      className={"width-100-percentage"}
                      disabled={cardinals.printCardinal === 'N/A' || props.isView}
                      onChange={(value: number) => handleTaxChange(value, 'printCardinal', 'printDue')}
                      addonAfter={"%"}/>
                  </Form.Item>
                </Col>
                <Col span={1} className={"d-flex df-jcc df-aic"}>=</Col>
                <Col span={3}>
                  <Form.Item
                    label=""
                    name={['taxAccountingMiddle', 'printDue']}
                    labelCol={{span: 0}}
                    wrapperCol={{span: 24}}
                    rules={[{required: false, message: '请输入印花税!'}]}>
                    <InputNumber
                      precision={2}
                      disabled={cardinals.printCardinal !== 'N/A' || props.isView}
                      onChange={() => setAdditionalTaxAmount()}
                      placeholder="印花税"
                      className={"width-100-percentage"}/>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={13}>
                  <Form.Item
                    label="工会经费"
                    name={['taxAccountingMiddle', 'unionCardinal']}
                    labelCol={{span: 11}}
                    wrapperCol={{span: 13}}
                    rules={[{required: false, message: '请输入工会经费!'}]}>
                    <Select
                      placeholder={'请选择计税基数'}
                      className={'width-100-percentage'}
                      onChange={(value) => {
                        handleCardinalChange(value, 'unionTax', 'unionDue')
                        restCardinal()
                      }}
                      options={taxBaseOptions}/>
                  </Form.Item>
                </Col>
                <Col span={1} className={"d-flex df-jcc df-aic"}>*</Col>
                <Col span={4}>
                  <Form.Item
                    label=""
                    name={['taxAccountingMiddle', 'unionTax']}
                    labelCol={{span: 0}}
                    wrapperCol={{span: 24}}
                    rules={[{required: false, message: '请输入税率!'}]}>
                    <InputNumber
                      precision={2}
                      placeholder="税率"
                      className={"width-100-percentage"}
                      disabled={cardinals.unionCardinal === 'N/A' || props.isView}
                      onChange={(value: number) => handleTaxChange(value, 'unionCardinal', 'unionDue')}
                      addonAfter={"%"}/>
                  </Form.Item>
                </Col>
                <Col span={1} className={"d-flex df-jcc df-aic"}>=</Col>
                <Col span={3}>
                  <Form.Item
                    label=""
                    name={['taxAccountingMiddle', 'unionDue']}
                    labelCol={{span: 0}}
                    wrapperCol={{span: 24}}
                    rules={[{required: false, message: '请输入工会经费!'}]}>
                    <InputNumber
                      precision={2}
                      disabled={cardinals.unionCardinal !== 'N/A' || props.isView}
                      onChange={() => setAdditionalTaxAmount()}
                      placeholder="工会费"
                      className={"width-100-percentage"}/>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={13}>
                  <Form.Item
                    label="水利建设基金"
                    name={['taxAccountingMiddle', 'hydraulicCardinal']}
                    labelCol={{span: 11}}
                    wrapperCol={{span: 13}}
                    rules={[{required: false, message: '请输入水利建设基金!'}]}>
                    <Select
                      placeholder={'请选择计税基数'}
                      className={'width-100-percentage'}
                      onChange={(value) => {
                        handleCardinalChange(value, 'hydraulicTax', 'hydraulicDue')
                        restCardinal()
                      }}
                      options={taxBaseOptions}/>
                  </Form.Item>
                </Col>
                <Col span={1} className={"d-flex df-jcc df-aic"}>*</Col>
                <Col span={4}>
                  <Form.Item
                    label=""
                    name={['taxAccountingMiddle', 'hydraulicTax']}
                    labelCol={{span: 0}}
                    wrapperCol={{span: 24}}
                    rules={[{required: false, message: '请输入税率!'}]}>
                    <InputNumber
                      precision={2}
                      placeholder="税率"
                      className={"width-100-percentage"}
                      disabled={cardinals.hydraulicCardinal === 'N/A' || props.isView}
                      onChange={(value: number) => handleTaxChange(value, 'hydraulicCardinal', 'hydraulicDue')}
                      addonAfter={"%"}/>
                  </Form.Item>
                </Col>
                <Col span={1} className={"d-flex df-jcc df-aic"}>=</Col>
                <Col span={3}>
                  <Form.Item
                    label=""
                    name={['taxAccountingMiddle', 'hydraulicDue']}
                    labelCol={{span: 0}}
                    wrapperCol={{span: 24}}
                    rules={[{required: false, message: '请输入水利建设基金!'}]}>
                    <InputNumber
                      precision={2}
                      disabled={cardinals.hydraulicCardinal !== 'N/A' || props.isView}
                      onChange={() => setAdditionalTaxAmount()}
                      placeholder="基金"
                      className={"width-100-percentage"}/>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </>
        }

        <Col span={24}>
          <Form.Item
            label={'核算金额'}
            name={['taxAccountingMiddle', 'personDue']}
            hidden={['增值税及附加'].includes(taxCategory)}
            rules={[{required: false, message: `请输入核算金额!`}]}>
            <InputNumber
              precision={2}
              placeholder={`请输入核算金额!`}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="核算金额(附加)"
            name="additionalTaxAmount"
            hidden={!['增值税及附加'].includes(taxCategory)}
            rules={[{required: false, message: '请输入核算金额!'}]}>
            <InputNumber precision={2} placeholder="请输入核算金额" className={"width-100-percentage"} disabled/>
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="原付款账户"
            name="bsAccountPay"
            rules={[{required: false, message: '请输入原付款账户!'}]}>
            <Input placeholder="请输入原付款账户" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户"
            name="bsAccountPayId"
            rules={[{required: false, message: '请输入付款账户!'}]}>
            <SelectBankAccount
              onChange={handleBsAccountPayIdChange}
              allowClear
              isShowAction
              bankAccountTypeOptions={[{id: '内部协作账户', value: '内部协作账户'}, {
                id: '外部协作账户',
                value: '外部协作账户'
              }]}
              placeholder={'请输入付款账户'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户名称"
            name="baName1"
            rules={[{required: false, message: '请输入付款账户名称!'}]}>
            <Input placeholder="请输入付款账户名称" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户账号"
            name="baAccount1"
            rules={[{required: false, message: '请输入付款账户账号!'}]}>
            <Input placeholder="请输入付款账户账号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户银行"
            name="baBlank1"
            rules={[{required: false, message: '请输入付款账户银行!'}]}>
            <Input placeholder="请输入付款账户银行" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="原收款账户"
            name="bsAccountIn"
            rules={[{required: false, message: '请输入原收款账户!'}]}>
            <Input placeholder="请输入原收款账户" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户"
            name="bsAccountInId"
            rules={[{required: false, message: '请输入收款账户!'}]}>
            <SelectBankAccount
              onChange={handleBsAccountInChange}
              allowClear
              isShowAction
              bankAccountTypeOptions={[{id: '内部协作账户', value: '内部协作账户'}, {
                id: '外部协作账户',
                value: '外部协作账户'
              }]}
              placeholder={'请输入收款账户'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户名称"
            name="baName2"
            rules={[{required: false, message: '请输入收款账户名称!'}]}>
            <Input placeholder="请输入收款账户名称" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户账号"
            name="baAccount2"
            rules={[{required: false, message: '请输入收款账户账号!'}]}>
            <Input placeholder="请输入收款账户账号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户银行"
            name="baBlank2"
            rules={[{required: false, message: '请输入收款账户银行!'}]}>
            <Input placeholder="请输入收款账户银行" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联税费计划"
            name="employeeFeeTaxScheduleId"
            rules={[{required: !props.isBatchUpdate, message: '请选择关联税费计划!'}]}>
            <SelectTaxPlan
              onChange={handleTaxPlanChangeChange}
              placeholder={'请选择关联税费计划'}
              className={'width-100-percentage'}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联资金审批"
            name="fundApprovalIds"
            rules={[{required: false, message: '请选择关联资金审批!'}]}>
            <SelectFundApproval
              mode={"tags"}
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择关联资金审批"}
            ></SelectFundApproval>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: false, message: '请选择责任部门!'}]}>
            <SelectProject
              disabled
              placeholder={"请选择责任部门"}
              onChange={handleProjectChange}
              allowClear
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              disabled
              mode={'multiple'}
              allowClear
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        { props.row.id && <Col span={24}>
          <Form.Item
            label="存档批号"
            name="fileArchive"
            rules={[{required: false, message: '请选择存档批号!'}]}>
            <SelectFiling
              responsibilityProject={responsibilityProject}
              cooperationUnit={cooperationUnit}
              placeholder={"请选择存档批号"}
              inputDisabled
              isHideClose
              isHidePlus
              fieldNames={{value: "id", label: "faBatchNumber"}}
              allowClear/>
          </Form.Item>
        </Col> }
        <Col span={24}>
          <Form.Item
            label="实现日期"
            name="amountRealizedDate"
            rules={[{required: false, message: '请输入实现日期!'}]}>
            <DatePicker disabled placeholder={"请输入实现日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="实现金额"
            name="amountRealized"
            rules={[{required: false, message: '请输入实现金额!'}]}>
            <Input placeholder="请输入实现金额" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="余额"
            name="balance"
            rules={[{required: false, message: '请输入余额!'}]}>
            <Input placeholder="请输入余额" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理人员"
            name="personResponsible"
            rules={[{required: false, message: '请选择办理人员!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              placeholder={"请选择办理人员"}
              disabled
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="主办人员"
            name="organizersUserId"
            rules={[{required: false, message: '请选择主办人员!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择主办人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="taStatus"
            rules={[{required: false, message: '请输入办理进度!'}]}>
            <SelectProcessStatus
              allowClear
              placeholder={"请选择办理进度"}
              disabled/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
