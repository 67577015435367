import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Button, Drawer, FormInstance, message, Modal, Space, Table} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {accAdd, logger, showErr} from "@common/utils";
import {
  DATE_FMT,
  DEFAULT_MODAL_WIDTH,
  DEFAULT_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import {BasicInfo} from "@modules/SocialSecurityPayment/Components/BasicInfo";
import {
  SocialSecurityPaymentReq,
  SocialSecurityPaymentRow,
  SocialSecurityPaymentRowSummaryTotal
} from "@services/dto/socialSecurityPayment";
import {SocialSecurityPaymentService} from "@services/SocialSecurityPaymentService";
import {headerSearchStore} from "@/layout/Stores/HeaderSearchStore";
import {TableOptions} from "src/components/TableOptions";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import {HeaderAction} from "@components/HeaderAction";

export const SocialSecurityPayment = observer(() => {
  const {socialSecurityPaymentStore} = allStores
  const basicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<SocialSecurityPaymentRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [socialSecurityPaymentRow, setSocialSecurityPaymentRow] = useState<SocialSecurityPaymentRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [searchReq, setSearchReq] = useState<SocialSecurityPaymentReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
  })
  const [summaryTotalObj, setSummaryTotalObj] = useState<SocialSecurityPaymentRowSummaryTotal>()

  useEffect(() => {
    const req = {...searchReq}
    if(req.cooperationUnit!==headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject){
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
    }
  }, [headerSearchStore.headerSearchReq])

  const columns: ColumnsType<SocialSecurityPaymentRow> = [
    {
      title: '制单日期',
      dataIndex: 'sspDate',
      key: 'sspDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'sspCode',
      key: 'sspCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '款项类别',
      dataIndex: 'sspCategory',
      key: 'sspCategory',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
    },
    {
      title: '款项摘要',
      dataIndex: 'sspDesc',
      key: 'sspDesc',
      // align: 'center',
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '金额',
      dataIndex: 'sspMoney',
      key: 'sspMoney',
      align: 'right',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '付款账户',
      dataIndex: 'sspPaymentAccountNick',
      key: 'sspPaymentAccountNick',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '收款账户',
      dataIndex: 'sspReceiptAccountNick',
      key: 'sspReceiptAccountNick',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'sspStatus',
      key: 'sspStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableOptions
          handleDelete={()=>{handleDeleteBatch(record)}}
          handleEdit={()=>{handleEdit(record)}}
          handleView={()=>{handleView(record)}}
          onClick={() => {handleEdit(record)}}>
          修改
        </TableOptions>
      )
    }
  ];

  useEffect(() => {
    refreshData(searchReq)
  }, [searchReq])

  useEffect(() => {
    setDataSource(socialSecurityPaymentStore.socialSecurityPaymentDatasource.data?.items!)
    setTotal(socialSecurityPaymentStore.socialSecurityPaymentDatasource.data?.total!)
  }, [socialSecurityPaymentStore.socialSecurityPaymentDatasource])

  useEffect(() => {
    const objInitValue = { sspMoney: 0 }
    let obj = summaryTotalObj
    const arr = socialSecurityPaymentStore.socialSecurityPaymentDatasource.data?.items.filter(o=>selectedRowKeys.includes(o.id))
    if(arr?.length){
      obj = arr.reduce((acc, cur) => ({
        sspMoney: accAdd(acc.sspMoney, cur.sspMoney),
      }), objInitValue);
      setSummaryTotalObj(obj)
    }else{
      setSummaryTotalObj(objInitValue)
    }
  }, [socialSecurityPaymentStore.socialSecurityPaymentDatasource, selectedRowKeys])

  const handleAdd = () => {
    setSocialSecurityPaymentRow(undefined)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handleView = (record: SocialSecurityPaymentRow) => {
    setSocialSecurityPaymentRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = (record: SocialSecurityPaymentRow) => {
    setSocialSecurityPaymentRow(record)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleDeleteBatch = (record?: SocialSecurityPaymentRow) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        SocialSecurityPaymentService.deleteSocialSecurityPayment(ids).then(() => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(searchReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: SocialSecurityPaymentReq) => {
    socialSecurityPaymentStore.getSocialSecurityPayment(req)
  }

  const handleOk = () => {
    const row = {...socialSecurityPaymentRow!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(row, values)
      setConfirmLoading(true)
      if(values.sspDate){
        row.sspDate = values.sspDate.format(DATE_FMT)
      }
      if (row.id) {
        SocialSecurityPaymentService.updateSocialSecurityPayment(row).then(() => {
          actionThen();
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        SocialSecurityPaymentService.addSocialSecurityPayment(row).then(() => {
          actionThen();
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  }

  const actionThen = () => {
    message.success("操作成功！")
    setIsModalVisible(false);
    setSocialSecurityPaymentRow(undefined)
    refreshData(searchReq)
  }

  return (
    <div className={"institution"}>
      <HeaderAction
        handleKeywordSearch={(value) => setSearchReq({...searchReq, keyword: value, pageNum: 1})}
        handleKeywordClear={() => setSearchReq({...searchReq, keyword: '', pageNum: 1})}
        handleDeleteBatch={handleDeleteBatch}
        handleAdd={handleAdd}
        collapsedChildren={[]}></HeaderAction>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={dataSource}
        columns={columns}
        size={"small"}
        loading={socialSecurityPaymentStore.socialSecurityPaymentDatasource.loading || confirmLoading}
        bordered
        scroll={{ y: DEFAULT_TABLE_SCROLL_HEIGHT }}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        summary={() => (
          <Table.Summary fixed={"bottom"}>
            <Table.Summary.Row >
              <Table.Summary.Cell index={0} colSpan={2} className='text-align-center'>
                已选：{ selectedRowKeys.length }
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} colSpan={3}/>
              <Table.Summary.Cell index={5} colSpan={1} className='text-align-right'>
                合计：<TableMoneyText value={summaryTotalObj?.sspMoney}></TableMoneyText>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={5} colSpan={6}/>
            </Table.Summary.Row>
          </Table.Summary>
        )}
        rowSelection={{selectedRowKeys, onChange: setSelectedRowKeys, columnWidth: 50}}/>

      <Drawer
        title={<div className="text-align-center">社保清缴</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalVisible}
        onClose={handleCancel}>
        <BasicInfo row={{...socialSecurityPaymentRow!}} ref={basicInfo} isView={isView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button type="primary" onClick={handleOk} loading={confirmLoading}
              className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>
    </div>
  )
})
