import React, {useEffect, useState} from "react";
import {Col, Form, Row, Select} from "antd";
import {FormInstance} from "antd/es";
import {allStores} from "@/stores";
import {observer} from "mobx-react";
import {PropsType} from "@modules/Talent/Components/BasicInfo";
import {RecruitmentRow} from "@services/dto/recruitment";

export const AssignInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const {recruitmentStore, securityStore} = allStores;
  const {curRecruitment} = recruitmentStore;
  const [row, setRow] = useState<RecruitmentRow>()

  useEffect(() => {
    recruitmentStore.loadRecruitments({
      status: 'online',
      companyId: securityStore.getLoggedUser()?.companyId
    })
  }, [])

  const [form] = Form.useForm()
  useEffect(() => {
    form.resetFields()
    setRow(undefined)
    form.setFieldsValue(props.row)
  }, [props.row])

  const handleChange = (value: string) => {
    const rrow = curRecruitment.data?.items.find(item => item._id === value)
    form.resetFields(['workerTypeName'])
    setRow(rrow)
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Col span={24}>
          <Form.Item
            label="招聘公示"
            name="recruitmentId"
            labelCol={{span: 4}}
            wrapperCol={{span: 20}}
            rules={[{required: true, message: '请选择招聘公示',}]}>
            <Select 
              placeholder="请选择招聘公示"
              loading={curRecruitment.loading} 
              onChange={handleChange}
              showSearch
              filterOption={(inputValue, option) => String(option?.projectName).includes(inputValue)}
              fieldNames={{value: "_id", label: "title"}}
              options={curRecruitment.data?.items}/>
          </Form.Item>
        </Col>
        {
          row &&
                  <Col span={24}>
                    <Form.Item
                      label="工种"
                      name="workerTypeName"
                      labelCol={{span: 4}}
                      wrapperCol={{span: 20}}
                      rules={[{required: true, message: '请选择工种',}]}>
                      <Select placeholder="请选择工种">
                        { row.workerTypes.map((item, i) => (
                          <Select.Option key={item.typeName}>{ item.typeName }</Select.Option>)) }
                      </Select>
                    </Form.Item>
                  </Col>
        }
      </Row>
    </Form>
  )
}))
