
import { makeAutoObservable, runInAction } from "mobx";
import { Page, StateFullData } from "@services/dto/common";
import { OvertimeSalaryRow, SearchOvertimeSalaryRowReq } from "@/services/dto/overtimeSalary";
import { OvertimeSalaryService as Service } from "@/services/OvertimeSalaryService";

export class OvertimeSalaryStore {
  datasource: StateFullData<Page<OvertimeSalaryRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  search(req: SearchOvertimeSalaryRowReq) {
    this.datasource = { ...this.datasource, loading: true }
    Service.search(req).then(rsp => {
      runInAction(() => this.datasource = { data: rsp, loading: false })
    }).catch(err => {
      runInAction(() => this.datasource = { ...this.datasource, loading: false, err })
    })
  }
}
export const overtimeSalaryStore = new OvertimeSalaryStore();
