import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {AccountSalesReq, AccountSalesRow} from "@services/dto/accountSales";

export class AccountSalesService {
  static async getAccountSales(req: AccountSalesReq): Promise<Page<AccountSalesRow>> {
    return await apiV1.get("/salesList/search", {params: req})
  }

  static async addAccountSales(row: AccountSalesRow): Promise<ResponseEntity<AccountSalesRow>> {
    return await apiV1.post("/salesList/save", {...row})
  }

  static async updateAccountSales(row: AccountSalesRow): Promise<ResponseEntity<AccountSalesRow>> {
    return await apiV1.put("/salesList/update", {...row})
  }

  static async deleteAccountSales(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/salesList/delete", {data: ids})
  }

  static async getDetailById(id: string): Promise<ResponseEntity<AccountSalesRow>> {
    return await apiV1.get(`/salesList/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<AccountSalesRow>> {
    return await apiV1.get(`/salesList/getBatch/${ids.join(',')}`)
  }
}
