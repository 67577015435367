import {apiV1} from "@services/apiHelper";
import {PersonCascader, PersonCascaderReq, TalentReq, TalentRow} from "@services/dto/talent";
import {Page, ResponsesEntity} from "@services/dto/common";

export class TalentService {
  static async addTalent(talent: TalentRow): Promise<TalentRow> {
    return await apiV1.post('/users', {...talent});
  }

  static async deleteTalent(id: string): Promise<void> {
    return await apiV1.delete(`/users/${id}`);
  }

  static async deleteBatchTalent(ids: string[]): Promise<void> {
    return await apiV1.delete("/user/delBatch", {data: {ids}});
  }

  static async updateTalent(id: string, talent: TalentRow): Promise<TalentRow> {
    return await apiV1.put(`/users/${id}`, {...talent});
  }

  static async getTalent(req: TalentReq): Promise<Page<TalentRow>> {
    return await apiV1.get('/users', {params: req});
  }

  static async getUserVo(uids: string): Promise<TalentRow[]> {
    return await apiV1.get(`/getUserVo?uids=${uids}`);
  }

  static async getFatherAndSonStructure(): Promise<ResponsesEntity<PersonCascader>> {
    return await apiV1.get("/system/user/fatherAndSonStructure")
  }

  static async getFatherAndSonStructureUser(req: PersonCascaderReq): Promise<ResponsesEntity<PersonCascader>> {
    return await apiV1.get("/system/user/fatherAndSonStructure/user", {params: req})
  }
}
