import React, {useEffect, useState} from "react";
import {DatePicker} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import dayjs from "dayjs";
import {CustomAuthHeaderAction, PropsType as ParentPropsType} from "@components/CustomAuthHeaderAction";
import {ProjectStructureReq} from "@services/dto/projectStructure";
import {SelectDict} from "@components/SelectDict";
import {SelectContractList} from "@components/SelectContractList";
import {SelectFiling} from "@components/SelectFiling";

export interface PropsType extends ParentPropsType {
  updateCount?: number, // 更新渲染标志
  refreshData?: (req: ProjectStructureReq) => void,
  collapsed?: boolean, // 是否展开
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<ProjectStructureReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: ProjectStructureReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handlePsDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, [start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.psDate1 = start
    req.psDate2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handlePsCategoryChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.psCategory = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handlePsStatusChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.psStatus = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handlePsFileChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.psFile = value
    setSearchReq(req)
    refreshData(req)
  }

  const handlePsBudgetChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.psBudget = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleContractInIdChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.contractInId = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleContractOutIdChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.contractOutId = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined

    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <CustomAuthHeaderAction
        size={props.size}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <DatePicker.RangePicker
            placeholder={['制单开始日期', '制单结束日期']}
            onChange={handlePsDateChange}
            value={searchReq?.psDate1 ? [dayjs(searchReq?.psDate1), dayjs(searchReq?.psDate2)] : [undefined, undefined]}
            allowClear
            className={"width-100-percentage"}/>,
          <SelectDict
            value={searchReq?.psCategory}
            updateCount={props.updateCount}
            code={'common_type'}
            placeholder={'请选择项目类别'}
            allowClear
            onChange={handlePsCategoryChange}
            className={"width-100-percentage"}/>,
          <SelectDict
            value={searchReq?.psBudget}
            updateCount={props.updateCount}
            code={'common_type'}
            placeholder={'请选择关联预算'}
            allowClear
            onChange={handlePsBudgetChange}
            className={"width-100-percentage"}/>,
          <SelectContractList
            allowClear
            value={searchReq?.contractInId}
            onChange={handleContractInIdChange}
            placeholder={'请选择关联收入合同'}
            fieldNames={{value: 'id', label: 'clCode'}}/>,
          <SelectContractList
            allowClear
            value={searchReq?.contractOutId}
            onChange={handleContractOutIdChange}
            placeholder={'请选择关联支出合同'}
            fieldNames={{value: 'id', label: 'clCode'}}/>,
          <SelectDict
            value={searchReq?.psStatus}
            updateCount={props.updateCount}
            code={'common_type'}
            placeholder={'请选择办理进度'}
            allowClear
            onChange={handlePsStatusChange}
            className={"width-100-percentage"}/>,
          <SelectFiling
            placeholder={"请选择存档批号"}
            allowClear
            isFaBatchNumberGroupBy
            fieldNames={{label: 'faBatchNumber', value: 'faBatchNumber'}}
            value={searchReq?.psFile}
            onChange={handlePsFileChange}
            className={'width-100-percentage'}
            isHiddenOptions/>,
        ] : []}/>
    </>
  )
}))
