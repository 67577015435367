import React, {useEffect, useState} from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {CostReimbursementRow} from "@services/dto/costReimbursement";
import dayjs from "dayjs";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {allStores} from "@/stores";
import {ProjectRow} from "@services/dto/project";
import {SelectBankAccount} from "@components/SelectBankAccount";
import {Any} from "@common/types";
import {BankAccountRow} from "@services/dto/bankAccount";
import {SelectFiling} from "@components/SelectFiling";
import {SelectCostApplication} from "@components/SelectCostApplication";
import {SelectFundApproval} from "@components/SelectFundApproval";
import {SelectCostExpend} from "@components/SelectCostExpend";
import {CostExpendRow} from "@services/dto/costExpend";
import {isChineseStr} from "@common/utils";
import {BLACK_LIST} from "@/config";
import {SelectDict} from "@components/SelectDict";
import {SelectInvoiceRegister} from "@components/SelectInvoiceRegister";
import {SelectProcessStatus} from "@components/SelectProcessStatus";

export interface PropsType {
  row: CostReimbursementRow
  isView?: boolean
  isBatchUpdate?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const {filingStore} = allStores
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()

  useEffect(() => {
    form.resetFields()
    const costReimbursementRow = {...props.row}
    filingStore.setFilingFaCode(costReimbursementRow.erApprovalCode)
    if (typeof costReimbursementRow.erDateOfPreparation === 'string' && costReimbursementRow.erDateOfPreparation !== '') {
      costReimbursementRow.erDateOfPreparation = dayjs(costReimbursementRow.erDateOfPreparation)
    }
    if (costReimbursementRow.invoiceRegistrationIds && typeof costReimbursementRow.invoiceRegistrationIds === 'string') {
      costReimbursementRow.invoiceRegistrationIds = costReimbursementRow.invoiceRegistrationIds.split(',')
    } else {
      costReimbursementRow.invoiceRegistrationIds = ['N/A']
    }
    if (costReimbursementRow.efpEmployeeFeeApplicationIds) {
      costReimbursementRow.efpEmployeeFeeApplicationIdsTemp = costReimbursementRow.efpEmployeeFeeApplicationIds.map(o => o.id);
    }
    if (typeof costReimbursementRow.cooperationUnit === 'string') {
      costReimbursementRow.cooperationUnit = costReimbursementRow.cooperationUnit !== '' ? costReimbursementRow.cooperationUnit.split(',') : []
    }
    setResponsibilityProject(costReimbursementRow.responsibilityProject)
    setCooperationUnit(costReimbursementRow.cooperationUnit)
    form.setFieldsValue(costReimbursementRow);
  }, [props.row.id])

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    setResponsibilityProject(value)
    const row = options.find(o => value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  /**
   * @desc 账户组件修改默认填充简称字段
   * @param value
   * @param options
   */
  const handleEfpAccountingObjectChange = (value: Any | null, options: BankAccountRow[]) => {
    const row = options.find(o => value === o.id)
    form.setFieldsValue({efpAccountingObjectName: row?.baNick || ""})
    form.setFieldsValue({efpName1: row?.baName || ""})
    form.setFieldsValue({efpAccount1: row?.baAccount || ""})
    form.setFieldsValue({efpBlank1: row?.baBlank || ""})
  }

  const handleEfpPaymentAccountChange = (value: Any | null, options: BankAccountRow[]) => {
    const row = options.find(o => value === o.id)
    form.setFieldsValue({efpPaymentAccountName: row?.baNick || ""})
    form.setFieldsValue({efpName2: row?.baName || ""})
    form.setFieldsValue({efpAccount2: row?.baAccount || ""})
    form.setFieldsValue({efpBlank2: row?.baBlank || ""})
  }

  const handleCostExpendChange = (value: string[], options: CostExpendRow[]) => {
    const effectiveVal = value.filter(o => !BLACK_LIST.includes(o))
    const rows = options
      .filter((v, i, self) => self.indexOf(v) === i) // 去重
      .filter(o => effectiveVal.includes(String(o.id)))
      .filter(o => !isChineseStr(o.id)) // 去不是id的
    if (rows.length) {
      form.setFieldsValue({
        responsibilityProject: rows[0].responsibilityProject,
        code: rows[0].code,
        cooperationUnit: rows[0].cooperationUnit
      })
      setCooperationUnit(rows[0].cooperationUnit)
      setResponsibilityProject(rows[0].responsibilityProject)
    } else {
      form.setFieldsValue({
        responsibilityProject: undefined,
        code: undefined,
        cooperationUnit: undefined
      })
      setCooperationUnit(undefined)
      setResponsibilityProject(undefined)
    }
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      initialValues={{
        cooperationUnit: ['N/A']
      }}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="efpAccountingObjectName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="efpPaymentAccountName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="organizersUserName">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="erDateOfPreparation"
            rules={[{required: false, message: '请选择制单日期!'}]}>
            <DatePicker disabled placeholder={"请选择制单日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="类别"
            name="efpType"
            rules={[{required: false, message: '请选择类别!'}]}>
            <SelectDict
              className={'width-100-percentage'}
              allowClear
              code={'payment_typee'}
              placeholder={'请输入类别'}/>
          </Form.Item>
        </Col>
        {
          props.isView && <Col span={24}>
            <Form.Item
              label="制单编号"
              name="erApprovalCode"
              rules={[{required: false, message: '请输入制单编号!'}]}>
              <Input placeholder="请输入制单编号"/>
            </Form.Item>
          </Col>
        }
        <Col span={24}>
          <Form.Item
            label="费用摘要"
            name="erExpenseSummary"
            rules={[{required: false, message: '请输入费用摘要!'}]}>
            <Input.TextArea placeholder="请输入费用摘要" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="申请金额"
            name="erReimbursementAmount"
            rules={[{required: false, message: '请输入申请金额!'}]}>
            <InputNumber addonAfter={'元'} min={0} precision={2} placeholder="请输入申请金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联费用申请"
            name="efpEmployeeFeeApplicationIdsTemp"
            rules={[{required: false, message: '请选择关联费用申请!'}]}>
            <SelectCostApplication
              disabled
              mode={"multiple"}
              placeholder={'请选择关联费用申请'}
              className={'width-100-percentage'}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联费用支出"
            name="employeeFeePayIds"
            rules={[{required: !props.isBatchUpdate, message: '请选择关联费用支出!'}]}>
            <SelectCostExpend
              onChange={handleCostExpendChange}
              allowClear mode={"multiple"} placeholder={'请选择关联费用支出!'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联发票登记"
            name="invoiceRegistrationIds"
            rules={[{required: false, message: '请输入发票登记!'}]}>
            <SelectInvoiceRegister
              allowClear
              fieldNames={{label: 'irInvoiceCode2', value: 'id'}}
              disabled
              mode={'tags'}
              placeholder={'请选择发票登记'}
              className={'width-100-percentage'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联发票金额"
            name="invoiceRegistrationMoney"
            rules={[{required: false, message: '请输入发票金额!'}]}>
            <InputNumber
              precision={2}
              disabled
              addonAfter={'元'}
              placeholder="请输入发票金额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联资金审批"
            name="fundApprovalId"
            rules={[{required: false, message: '请选择关联资金审批!'}]}>
            <SelectFundApproval
              disabled
              className={'width-100-percentage'}
              placeholder={"请选择资金审批"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户"
            name="efpPaymentAccount"
            rules={[{required: false, message: '请输入付款账户!'}]}>
            <SelectBankAccount
              allowClear
              onChange={handleEfpPaymentAccountChange}
              isShowAction
              bankAccountTypeOptions={[{id: '员工公务账户', value: '员工公务账户'}, {
                id: '员工工资账户',
                value: '员工工资账户'
              }]}
              className={'width-100-percentage'}
              placeholder={'请输入付款账户'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户名称"
            name="efpName2"
            rules={[{required: false, message: '付款账户请输入户名!'}]}>
            <Input placeholder="付款账户请输入户名" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户账号"
            name="efpAccount2"
            rules={[{required: false, message: '请输入付款账户账号!'}]}>
            <Input placeholder="请输入付款账户账号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="付款账户银行"
            name="efpBlank2"
            rules={[{required: false, message: '请输入付款账户银行!'}]}>
            <Input placeholder="请输入付款账户银行" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户"
            name="efpAccountingObject"
            rules={[{required: false, message: '请输入收款账户!'}]}>
            <SelectBankAccount
              allowClear
              onChange={handleEfpAccountingObjectChange}
              isShowAction
              bankAccountTypeOptions={[{id: '员工公务账户', value: '员工公务账户'}, {
                id: '员工工资账户',
                value: '员工工资账户'
              }]}
              className={'width-100-percentage'}
              placeholder={'请输入收款账户'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户名称"
            name="efpName1"
            rules={[{required: false, message: '收款账户请输入户名!'}]}>
            <Input placeholder="收款账户请输入户名" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户账号"
            name="efpAccount1"
            rules={[{required: false, message: '请输入收款账户账号!'}]}>
            <Input placeholder="请输入收款账户账号" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收款账户银行"
            name="efpBlank1"
            rules={[{required: false, message: '请输入收款账户银行!'}]}>
            <Input placeholder="请输入收款账户银行" disabled/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{required: true, message: '请选择责任部门!'}]}>
            <SelectProject
              placeholder={"请选择责任部门"} allowClear onChange={handleProjectChange}
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{required: false, message: '请输入协作单位!'}]}>
            <SelectCooperativeUnit
              disabled
              allowClear
              mode={'multiple'}
              fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="存档批号"
            name="efaFileArchive"
            rules={[{required: false, message: '请选择存档批号!'}]}>
            <SelectFiling
              filingMode={"multiple"}
              isHidePlus
              isHideClose
              responsibilityProject={responsibilityProject}
              cooperationUnit={cooperationUnit}
              placeholder={"请选择存档批号"}
              inputDisabled
              fieldNames={{value: "id", label: "faBatchNumber"}}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="实现日期"
            name="erDate"
            rules={[{required: false, message: '请输入实现日期!'}]}>
            <DatePicker
              disabled
              placeholder={"请选择实现日期"}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="实现金额"
            name="erReimbursementPay"
            rules={[{required: false, message: '请输入实现金额!'}]}>
            <InputNumber
              addonAfter={'元'}
              disabled min={0} precision={2} placeholder="请输入实现金额"
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="余额"
            name="erReimbursementBalance"
            rules={[{required: false, message: '请输入余额!'}]}>
            <InputNumber min={0} precision={2} placeholder="请输入余额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理人员"
            name="personResponsible"
            rules={[{required: false, message: '请选择办理人员!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择办理人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="主办人员"
            name="organizersUserId"
            rules={[{required: false, message: '请选择主办人员!'}]}>
            <SelectPersonCharge
              disabled
              className={"width-100-percentage"}
              placeholder={"请选择主办人员"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="erEstatus"
            rules={[{required: false, message: '请选择办理进度!'}]}>
            <SelectProcessStatus
              allowClear
              placeholder={"请选择办理进度"}
              disabled/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
