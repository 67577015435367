import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import {Divider, Pagination, Select, Space} from "antd";
import {Any} from "@common/types";
import {allStores} from "@/stores";
import {PageParam} from "@services/dto/revenueExpenditure";
import {ContractLedgerRow} from "@services/dto/contractLedger";

export interface PropsType {
    onChange?: (value: Any, options: ContractLedgerRow | ContractLedgerRow[]) => void
    placeholder: string
    value?: Any
    allowClear?: boolean
    labelInValue?: boolean
    mode?: 'multiple' | 'tags'
    className?: string
    disabled?: boolean
}

export const SelectContractLedger = observer((props: PropsType) => {
  const {contractLedgerStore} = allStores
  const [req, setReq] = useState<PageParam>({pageNum: 1, perPage: 10})

  useEffect(() => {
    refreshData(req)
  }, [])

  const refreshData = (req: PageParam) => {
    contractLedgerStore.getContractLedger(req)
  }

  const onChange = (page: number, pageSize: number) => {
    const r = {...req}
    r.pageNum = page
    r.perPage = pageSize
    setReq(r)
    refreshData(r)
  }

  return (
    <Select
      allowClear={props.allowClear}
      placeholder={props.placeholder}
      className={`${props.className} width-100-percentage`}
      value={props.value}
      onChange={props.onChange}
      showSearch
      disabled={props.disabled}
      mode={props.mode}
      loading={contractLedgerStore.contractLedgerDatasource.loading}
      labelInValue={props.labelInValue}
      filterOption={(inputValue, option) => String(option?.name).includes(inputValue)}
      fieldNames={{value: "id", label: "name"}}
      options={contractLedgerStore.contractLedgerDatasource.data?.items}
      dropdownRender={(menu) => (
        <>
          { menu }
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Pagination size={"small"} current={req.pageNum} pageSize={req.perPage} onChange={onChange} total={contractLedgerStore.contractLedgerDatasource.data?.total} />
          </Space>
        </>
      )}/>
  )

})
