import {Page} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {
  IncomeExpenditureStatisticsReq,
  IncomeExpenditureStatisticsRow
} from "@services/dto/incomeExpenditureStatistics";

export class IncomeExpenditureStatisticsService {
  static async getIncomeExpenditureStatistics(req: IncomeExpenditureStatisticsReq): Promise<Page<IncomeExpenditureStatisticsRow>> {
    return await apiV1.get("/fundingApproval/statistics", {params: req})
  }
}
