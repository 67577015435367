import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {FinalSettlementReq} from "@services/dto/finalSettlement";
import {SelectBankAccount} from "@components/SelectBankAccount";
import {Any} from "@common/types";
import {SelectDict} from "@components/SelectDict";
import {CustomInputNumber} from "@components/CustomInputNumber";
import {SelectProject} from "@components/SelectProject";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {SelectFundApproval} from "@components/SelectFundApproval";
import {Select} from "antd";
import {SelectFiling} from "@components/SelectFiling";
import {SelectTaxPlan} from "@components/SelectTaxPlan";
import {CustomAuthHeaderAction, PropsType as ParentPropsType} from "@components/CustomAuthHeaderAction";
import {SelectProcessStatus} from "@components/SelectProcessStatus";

export interface PropsType extends ParentPropsType {
  updateCount?: number, // 更新渲染标志
  refreshData?: (req: FinalSettlementReq) => void,
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<FinalSettlementReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: FinalSettlementReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleStatusCommonChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.statusCommon = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleFsStatusChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.fsStatus = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonResponsibleChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleFsFileChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.fsFile = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleFsTypeChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.fsType = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleEmployeeFeeTaxScheduleIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.employeeFeeTaxScheduleId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleFundApprovalIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.fundApprovalId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleFsAmountChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.fsAmount1 = start
    req.fsAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleFsAccountPayChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.fsAccountPayId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleFsAccountInChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.fsAccountInId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleOrganizersUserIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.organizersUserId = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined

    req.fsFile = undefined
    req.fsAccountPay = undefined
    req.fsAccountIn = undefined
    req.fsAccountPayId = undefined
    req.fsAccountInId = undefined
    req.statusCommon = undefined
    req.fundApprovalId = undefined
    req.personResponsible = undefined
    req.personResponsibleName = undefined
    req.organizersUserId = undefined // 主办人员
    req.organizersUserName = undefined // 主办人员名
    req.fsAmount1 = undefined
    req.fsAmount2 = undefined
    req.fsType = undefined
    req.fsStatus = undefined

    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <CustomAuthHeaderAction
        size={props.size}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <SelectDict
            value={searchReq?.fsType}
            updateCount={props.updateCount}
            code={'fs_type '}
            placeholder={'请选择税费类别'}
            allowClear
            onChange={handleFsTypeChange}
            className={"width-100-percentage"}/>,
          <CustomInputNumber
            value={[searchReq?.fsAmount1, searchReq?.fsAmount2]}
            placeholder={['申请金额最小', '申请金额最大']}
            className={'width-100-percentage'}
            onSearch={handleFsAmountChange}/>,
          <SelectBankAccount
            value={searchReq?.fsAccountPayId}
            onChange={handleFsAccountPayChange}
            updateCount={props.updateCount}
            allowClear
            className={'width-100-percentage'}
            placeholder={'请输入付款账户'}/>,
          <SelectBankAccount
            value={searchReq?.fsAccountInId}
            onChange={handleFsAccountInChange}
            updateCount={props.updateCount}
            allowClear
            className={'width-100-percentage'}
            placeholder={'请输入收款账户'}/>,
          <SelectTaxPlan
            updateCount={props.updateCount}
            allowClear
            className={'width-100-percentage'}
            value={searchReq?.employeeFeeTaxScheduleId}
            onChange={handleEmployeeFeeTaxScheduleIdChange}/>,
          <SelectFundApproval
            updateCount={props.updateCount}
            allowClear
            className={'width-100-percentage'}
            value={searchReq?.fundApprovalId}
            onChange={handleFundApprovalIdChange}
            fieldNames={{value: 'id', label: 'faCode'}}/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
          <SelectCooperativeUnit
            onChange={handleCooperationUnitChange}
            value={searchReq?.cooperationUnit}
            allowClear
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectFiling
            placeholder={"请选择存档批号"}
            allowClear
            isFaBatchNumberGroupBy
            fieldNames={{label: 'faBatchNumber', value: 'faBatchNumber'}}
            onChange={handleFsFileChange}
            value={searchReq?.fsFile}
            className={'width-100-percentage'}
            isHiddenOptions/>,
          <Select
            onChange={handleStatusCommonChange}
            value={searchReq?.statusCommon}
            allowClear
            options={[
              {label: '完成', value: '完成'},
              {label: '容缺', value: '容缺'},
            ]}
            placeholder={'请选择校验办理进度'}
            className={"width-100-percentage"}/>,
          <SelectPersonCharge
            updateCount={props.updateCount}
            onChange={handlePersonResponsibleChange}
            className={"width-100-percentage"}
            value={searchReq?.personResponsible}
            placeholder={"请选择办理人员"}
            allowClear/>,
          <SelectPersonCharge
            onChange={handleOrganizersUserIdChange}
            className={"width-100-percentage"}
            placeholder={"请选择主办人员"}
            value={searchReq?.organizersUserId}
            allowClear/>,
          <SelectProcessStatus
            onChange={handleFsStatusChange}
            allowClear
            value={searchReq?.fsStatus}
            placeholder={'请选择办理进度'}
            className={"width-100-percentage"}/>,
        ] : []}/>
    </>
  )
}))
