import './index.less'
import {observer} from "mobx-react";
import {Tooltip} from "antd";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useState} from "react";
import {TooltipPlacement} from "antd/es/tooltip";

export interface PropsType {
  copyValue?: string
  value: any
  showValue?: any
  placement?: TooltipPlacement
}

export const TableEllipsisText = observer((props: PropsType) => {

  const [tip, setTip] = useState<string>('')

  return <>
    <CopyToClipboard
      onCopy={() => tip ? setTip(tip + '.') : setTip('复制成功')}
      text={props.copyValue || props.value || '/'}>
      <Tooltip
        placement={props.placement}
        title={
          <>
            <div>{ tip || '一键复制' }</div>
            <div>{ props.copyValue || props.showValue || props.value || '/' }</div>
          </>
        }>
        <div
          onMouseOut={() => setTimeout(() => setTip(''), 600)}
          className={'box c-pointer'}>
          { props.value ? (props.showValue || props.value) : '/' }
        </div>
      </Tooltip>
    </CopyToClipboard>
  </>
})
