import {observer} from "mobx-react";
import './BalanceReconciliation.less'
import {ColumnsType} from "antd/es/table";
import {BalanceReconciliationRow} from "@services/dto/bankAccount";
import {DEFAULT_TABLE_SCROLL_HEIGHT, LARGE_MODAL_WIDTH, TABLE_CELL_WIDTH_1x} from "@/config";
import type {DescriptionsProps} from 'antd';
import {FormInstance, Space, Table} from "antd";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import React, {useEffect, useState} from "react";
import {allStores} from "@/stores";
import {CustomDraggableModal} from "@components/CustomDraggableModal";

export interface PropsType {
  dataSource: BalanceReconciliationRow[]
  open: boolean
  onChange: (open: boolean) => void
  items?: DescriptionsProps['items']
}


export const BalanceReconciliation = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {
  const {bankAccountStore} = allStores
  const [dataSource, setDataSource] = useState<BalanceReconciliationRow[]>([])
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    setDataSource(props.dataSource)
  }, [props.dataSource])

  useEffect(() => {
    setIsOpen(props.open)
  }, [props.open]);

  const columns: ColumnsType<BalanceReconciliationRow> = [
    {
      title: '-',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '期初余额',
      dataIndex: 'initialBalance',
      key: 'initialBalance',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '当期收入金额',
      dataIndex: 'currentIncomeAmount',
      key: 'currentIncomeAmount',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '当期支出金额',
      dataIndex: 'currentExpenditureAmount',
      key: 'currentExpenditureAmount',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '当期收支差额',
      dataIndex: 'forTheCurrentPeriod',
      key: 'forTheCurrentPeriod',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '期末余额',
      dataIndex: 'endingBalance',
      key: 'endingBalance',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
  ];

  const handleOk = () => {
    props.onChange(false)
  }

  const handleCancel = () => {
    props.onChange(false)
  }

  return (
    <CustomDraggableModal
      title={<div className="text-align-center">余额对账</div>}
      open={isOpen}
      width={LARGE_MODAL_WIDTH}
      onOk={handleOk}
      onCancel={handleCancel}>
      { /*{
        props.items && <Descriptions
          size={'small'}
          title=""
          items={props.items}/>
      }*/ }
      {
        props.items && <>
          <div className={'desc-box'}>
            {
              props.items.map(item => <div
                key={item.key}
                className={'desc-item'}>
                <span className={'label'}>{ item.label }</span>
                <span>:</span>
                <span className={'children'}>{ item.children }</span>
              </div>)
            }
          </div>
        </>
      }
      <Table
        rowKey={"id"}
        dataSource={dataSource}
        columns={columns}
        size={"small"}
        loading={bankAccountStore.bankAccountStatisticsDatasource.loading}
        bordered
        scroll={{y: DEFAULT_TABLE_SCROLL_HEIGHT}}
        pagination={false}
      />
    </CustomDraggableModal>

  )
}))
