import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {StaffLoanReq, StaffLoanRow} from "@services/dto/staffLoan";
import {BaseService} from "@services/BaseService";
import {BaseEntity} from "@services/dto/BaseEntity";

export class StaffLoanService implements BaseService {
  async list(req: StaffLoanReq): Promise<Page<StaffLoanRow>> {
    return await apiV1.get("/employeeLoan/search", {params: req})
  }

  static async batchRefresh(): Promise<ResponseEntity<string>> {
    return await apiV1.get("/employeeLoan/batch/refresh")
  }

  async save(row: BaseEntity): Promise<ResponseEntity<StaffLoanRow>> {
    return await apiV1.post("/employeeLoan/save", {...row})
  }

  async update(row: BaseEntity): Promise<ResponseEntity<StaffLoanRow>> {
    return await apiV1.put("/employeeLoan/update", {...row})
  }

  async deleteBatch(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/employeeLoan/delete", {data: ids})
  }

  async getStaffLoan(req: StaffLoanReq): Promise<Page<StaffLoanRow>> {
    return await apiV1.get("/employeeLoan/search", {params: req})
  }

  async addStaffLoan(row: BaseEntity): Promise<ResponseEntity<StaffLoanRow>> {
    return await apiV1.post("/employeeLoan/save", {...row})
  }

  async updateStaffLoan(row: BaseEntity): Promise<ResponseEntity<StaffLoanRow>> {
    return await apiV1.put("/employeeLoan/update", {...row})
  }

  async batchUpdateStaffLoan(selectedRows: StaffLoanRow[]): Promise<ResponseEntity<string>> {
    return await apiV1.put("/employeeLoan/updateBatch", [...selectedRows])
  }

  async deleteStaffLoan(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/employeeLoan/delete", {data: ids})
  }

  async getOne(id: string): Promise<ResponseEntity<StaffLoanRow>> {
    return await apiV1.get(`/employeeLoan/getOne/${id}`)
  }

  async getBatch(ids: string[]): Promise<ResponseEntity<StaffLoanRow[]>> {
    return await apiV1.get(`/employeeLoan/getBatch/${ids.join(',')}`)
  }
}

export const staffLoanService = new StaffLoanService()
