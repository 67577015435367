import React, {useState} from "react";
import {Button, Col, DatePicker, Radio, Row} from "antd";
import IconFont from "@components/IconFont";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import {RadioChangeEvent} from "antd/es/radio/interface";
import dayjs from "dayjs";
import {SelectProject} from "@components/SelectProject";

export interface PropsType {
  handleActiveKeyChange: (value: string) => void
  handleProjectIdChange: (value: string) => void
  handleTimeChange: (dateStrings: string[]) => void
  handleDeleteBatch: () => void
  handleAdd: () => void
}

type RangeValue = [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;

export const HeaderAction = (props: PropsType) => {
  const [activeKey, setActiveKey] = useState(1)
  const [timeValue, setTimeValue] = useState<RangeValue>();
    
  const handleActiveKeyChange = (e: RadioChangeEvent) => {
    setActiveKey(e.target.value)
    props.handleActiveKeyChange(e.target.value)
  }

  const handleProjectIdChange = (value: string) => {
    props.handleProjectIdChange(value)
  }

  const handleTimeChange = (dates: RangeValue, dateStrings: [string, string]) => {
    setTimeValue(dates)
    props.handleTimeChange(dateStrings)
  }

  const handleDeleteBatch = () => {
    props.handleDeleteBatch()
  }

  const handleAdd = () => {
    props.handleAdd()
  }
    
  return (
    <>
      <Row gutter={16}>
        <Col xxl={7} xl={10} lg={12} xs={24}>
          <Radio.Group value={activeKey} onChange={handleActiveKeyChange} buttonStyle="solid" size={"middle"}>
            <Radio.Button value={1}><IconFont type={"hr-mokuai-shang"} className={"icon-fontsize icon-rotate"}/>奖励</Radio.Button>
            <Radio.Button value={2}><IconFont type={"hr-caogaoxiang1"} className={"icon-fontsize"}/>处罚</Radio.Button>
            <Radio.Button value={3}><IconFont type={"hr-mokuai-shang"} className={"icon-fontsize"}/>其他扣款</Radio.Button>
            <Radio.Button value={4}><IconFont type={"hr-caogaoxiang1"} className={"icon-fontsize"}/>其他加款</Radio.Button>
          </Radio.Group>
        </Col>
        <Col xl={4} lg={5} xs={12}>
          <SelectProject allowClear onChange={handleProjectIdChange}/>
        </Col>
        <Col xl={5} lg={7} xs={12}>
          <DatePicker.RangePicker value={timeValue} onChange={handleTimeChange} className={"width-100-percentage"}/>
        </Col>
        <Col xxl={7} xl={5} xs={24} className={"text-align-right"}>
          <Button icon={<DeleteOutlined/>} size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={() => handleDeleteBatch()}>删除</Button>

          <Button icon={<PlusOutlined/>} type={"primary"} size={"middle"} shape={"round"}
            onClick={handleAdd}>新增</Button>
        </Col>
      </Row>
    </>
  )
}