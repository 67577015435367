import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {PayrollService} from "@services/PayrollService";
import {PayrollReq, PayrollRow} from "@services/dto/Payroll";
import {BankAccountService} from "@services/BankAccountService";
import {BLACK_LIST} from "@/config";
import {FilingService} from "@services/FilingService";
import {convertCurrency, formatMoney} from "@common/utils";

export class PayrollStore {
  payrollDatasource: StateFullData<Page<PayrollRow>> = {}
  payrollSelectDatasource: StateFullData<Page<PayrollRow>> = {}
  payrollRow?: PayrollRow
  payrollOption?: string
  payrollDetail?: StateFullData<ResponseEntity<PayrollRow>> = {}
  payrollDetails?: StateFullData<ResponsesEntity<PayrollRow>> = {}
  payrollReq: PayrollReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updatePayrollRow(row: PayrollRow) {
    this.payrollRow = {...row}
  }

  updatePayrollOption(option: string) {
    this.payrollOption = option
  }

  getPayroll(req: PayrollReq) {
    this.payrollDatasource = {...this.payrollDatasource, loading: true}
    this.payrollReq = {...this.payrollReq, ...req}
    PayrollService.getPayroll(req).then(rsp => {
      runInAction(() => this.payrollDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.payrollDatasource = {...this.payrollDatasource, loading: false, err})
    })
  }

  getSelectPayroll(req: PayrollReq) {
    this.payrollSelectDatasource = {...this.payrollSelectDatasource, loading: true}
    PayrollService.getPayroll(req).then(rsp => {
      runInAction(() => this.payrollSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.payrollSelectDatasource = {...this.payrollSelectDatasource, loading: false, err})
    })
  }

  getOne(id: string) {
    this.payrollDetail = {...this.payrollDetail, loading: true}
    PayrollService.getOne(id).then(rsp => {
      runInAction(() => this.payrollDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.payrollDetail = {...this.payrollDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.payrollDetails = {...this.payrollDetails, loading: true}
    PayrollService.getBatch(ids).then(rsp => {
      runInAction(() => this.payrollDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.payrollDetails = {...this.payrollDetails, loading: false, err})
    })
  }

  async getPrintList(record: PayrollRow): Promise<LabelValue[]> {

    const row = this.payrollDatasource.data?.items.find(o => o.id === record.id)

    let paymentAccount = undefined
    let creditedAccount = undefined
    if (row.paymentAccountId !== 'N/A' && row.creditedAccountId !== 'N/A') {
      let accountRsp = await BankAccountService.getBatch([row.paymentAccountId, row.creditedAccountId])
      if (accountRsp.code !== 200 || accountRsp.data.length !== 2) {
        return
      }
      paymentAccount = accountRsp.data[0]
      creditedAccount = accountRsp.data[1]
    } else if (row.paymentAccountId !== 'N/A') {
      const {
        data: paymentAccountData,
        code: paymentAccountCode,
      } = await BankAccountService.getOne(row.paymentAccountId)
      if (paymentAccountCode === 200) {
        paymentAccount = paymentAccountData
      } else {
        return
      }
    } else if (row.creditedAccountId !== 'N/A') {
      const {
        data: creditedAccountData,
        code: creditedAccountCode,
      } = await BankAccountService.getOne(row.creditedAccountId)
      if (creditedAccountCode === 200) {
        creditedAccount = creditedAccountData
      } else {
        return
      }
    }

    // 存档批号
    let faFileRow = undefined
    if (row.payrollFile && !BLACK_LIST.includes(row.payrollFile)) {
      const payrollFileRsp = await FilingService.getBatch([row.payrollFile])
      if (payrollFileRsp && payrollFileRsp.code !== 200) {
        return
      }
      faFileRow = payrollFileRsp.data[0]
    }

    return [
      {label: '制单日期', value: row.payrollDate as string},
      {label: '制单编号', value: row.payrollNumber},
      {label: '工资类别', value: row.payrollCategory},
      {label: '工资摘要', value: row.payrollDesc},
      {label: '申请金额(元)', value: `${formatMoney(row.payrollSalary)}元（${convertCurrency(row.payrollSalary)}）`},
      {label: '付款账户简称', value: paymentAccount?.baNick},
      {label: '付款账户名称', value: paymentAccount?.baName},
      {label: '付款账户银行账号', value: paymentAccount?.baAccount},
      {label: '付款账户开户银行', value: paymentAccount?.baBlank},
      {label: '收款账户简称', value: creditedAccount?.baNick},
      {label: '收款账户名称', value: creditedAccount?.baName},
      {label: '收款账户银行账号', value: creditedAccount?.baAccount},
      {label: '收款账户开户银行', value: creditedAccount?.baBlank},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '存档批号', value: faFileRow?.faBatchNumber},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const payrollStore = new PayrollStore();
