import React, {useEffect} from "react";
import {Col, Form, Input, Radio, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {RuleRegulationRow} from "@services/dto/ruleRegulation";

export interface PropsType {
  row: RuleRegulationRow
  isView: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    const ruleRegulationRow = {...props.row}
    if (props.row.id) {
      // if (ruleRegulationRow.releaseTime) {
      //   ruleRegulationRow.releaseTime = dayjs(ruleRegulationRow.releaseTime, DATE_FMT);
      // }
    }
    form.setFieldsValue(ruleRegulationRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      initialValues={{status: 1, isVirtualUser: 0}}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="文件名称"
            name="name"
            rules={[{required: true, message: '请输入名称!'}]}>
            <Input placeholder="请输入名称"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="文件编号"
            name="code"
            rules={[{required: true, message: '请输入编号!'}]}>
            <Input placeholder="请输入编号"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="正文"
            name="theGroup"
            rules={[{required: true, message: '请输入正文!'}]}>
            <Input placeholder="请输入正文"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="版本"
            name="version"
            rules={[{required: true, message: '请输入版本!'}]}>
            <Input placeholder="请输入版本"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="status"
            rules={[{required: true, message: '请选择办理进度!'}]}>
            <Radio.Group>
              <Radio value={'0'}>有效</Radio>
              <Radio value={'1'}>失效</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
