import React, {useState} from "react";
import {Button, Col, DatePicker, Input, message, Row, Upload} from "antd";
import {DeleteOutlined, LeftOutlined, RightOutlined, SearchOutlined} from "@ant-design/icons";
import {handleDateAddSubtract, useUploadProps} from "@common/utils";
import {UploadChangeParam} from "antd/es/upload/interface";
import {BASE_API_PATH} from "@/config";
import dayjs from "dayjs";
import {SelectProject} from "@components/SelectProject";
import "./HeaderAction.less"

export interface PropsType {
    handleProjectChange: (value: string) => void
    handleSearch: (value: string) => void
    refreshData: () => void
    handleBatchDelete: () => void
    handleDateChange: (year: number, month: number) => void
}

export const HeaderAction = (props: PropsType) => {
  const [searchValue, setSearchValue] = useState<string>("")
  const [dateValue, setDateValue] = useState<dayjs.Dayjs>(dayjs())

  const handleProjectChange = (value: string) => {
    props.handleProjectChange(value)
  }

  const handleSearch = () => {
    props.handleSearch(searchValue)
  }

  const handleAddSubtract = (action: string) => {
    let now_calc = handleDateAddSubtract(action, dateValue);
    setDateValue(now_calc);
    props.handleDateChange(now_calc.year(), now_calc.month() + 1)
  }

  const handleDateChange = (date: dayjs.Dayjs | null, dateString: string) => {
    if (date) {
      setDateValue(date);
      props.handleDateChange(date.year(), date.month() + 1)
    }
  }

  const downloadTemplate = () => {
    window.open(BASE_API_PATH + "/filepath/考勤管理.xlsx")
  }

  const uploadChange = (info: UploadChangeParam) => {
    if (info.file.status === 'done') {
      if (info.file.response.data) {
        message.warning(info.file.response.data);
      } else {
        message.success(`${info.file.name} 文件导入成功`);
      }
      props.refreshData()
    }
  }

  const handleBatchDelete = () => {
    props.handleBatchDelete()
  }

  return (
    <>
      <Row gutter={16}>
        <Col xl={4} xs={12} className={"button-container"}>
          <SelectProject allowClear onChange={handleProjectChange}/>
        </Col>
        <Col xl={4} xs={12} className={"button-container"}>
          <Input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={handleSearch}
            allowClear
            placeholder="请输入姓名/身份证号"
            suffix={<SearchOutlined onClick={handleSearch}/>}
          />
        </Col>
        <Col xl={8} xs={12} className={"date-center"}>
          <Button icon={<LeftOutlined/>} onClick={() => handleAddSubtract('subtract')}></Button>
          <DatePicker
            bordered={false}
            allowClear={false}
            value={dateValue}
            disabledDate={(cur) => cur.isAfter(dayjs())}
            onChange={(date, dateString: string) => handleDateChange(date, dateString)}
            className={"date-style"}
            format={"YYYY年MM月"}
            picker={"month"}/>
          <Button disabled={dateValue.month() === dayjs().month() && dateValue.year() === dayjs().year()} icon={<RightOutlined/>} onClick={() => handleAddSubtract('add')}></Button>
        </Col>
        <Col xl={8} xs={12} className={"button-container"}>
          <Button size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={downloadTemplate}>考勤模板下载</Button>

          <Upload {...useUploadProps("/v1/workerTimes/import", uploadChange, {year: dateValue.year(), month: dateValue.month() + 1})}>
            <Button size={"middle"} shape={"round"} className={"margin-right-xs"}>导入考勤</Button>
          </Upload>

          <Button size={"middle"} shape={"round"} className={"margin-right-xs"}
            icon={<DeleteOutlined/>} onClick={handleBatchDelete}>删除</Button>
        </Col>
      </Row>
    </>
  )
}
