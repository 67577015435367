import {observer} from "mobx-react";
import {DictService} from "@services/DictService";
import {showErr} from "@common/utils";
import React, {useEffect, useState} from "react";
import {DictItem} from "@services/dto/dict";
import {Any} from "@common/types";
import {Col, Row, Select} from "antd";

export interface PropsType {
    level: string
    code: string
    value?: Any
    allowClear?: boolean
    onChange?: (value: Any, options: DictItem | DictItem[]) => void
    fieldNames?: { value: string, label: string }
    disabled?: boolean
    placeholder: string
    className?: string
}

export const ThreeSelect = observer((props: PropsType) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [dict, setDict] = useState<DictItem[]>([])
  const [value, setValue] = useState<Any>()

  useEffect(() => {
    getByKey()
  }, [props.code])

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const getByKey = () => {
    setLoading(true)
    DictService.getByKey(props.code).then(rsp => {
      setDict(rsp.value || []);
    }).catch(showErr).finally(() => setLoading(false))
  }

  const onChange = (val, option, index) => {
    let newValue
    if (!value) {
      newValue = val ? val : undefined
    } else {
      newValue = value.split("-")
      if (index === 0) {
        newValue[1] = ''
        newValue[2] = ''
      } else if (index === 1) {
        newValue[2] = ''
      }
      newValue[index] = val;
      newValue = newValue.join("-")
    }
    if (props.onChange) {
      props.onChange(newValue, option);
    }
  }

  return <Row gutter={12}>
    <Col span={8}>
      <Select
        allowClear={props.allowClear}
        value={value ? value.split("-")[0] ? value.split("-")[0] : undefined : undefined}
        onChange={(value, option) => onChange(value, option, 0)}
        placeholder={props.placeholder}
        showSearch
        filterOption={(input: string, option) => String(option?.value).toLowerCase().includes(input.toLowerCase())}
        className={props.className}
        disabled={(props.level === '一级' || props.level === '二级' || props.level === '三级') ? props.disabled : true}
        fieldNames={props.fieldNames || {value: 'value', label: 'value'}}
        options={dict}
        loading={loading}>
      </Select>
    </Col>
    <Col span={8}>
      <Select
        allowClear={props.allowClear}
        value={value ? value.split("-")[1] ? value.split("-")[1] : undefined : undefined}
        onChange={(value, option) => onChange(value, option, 1)}
        placeholder={props.placeholder}
        showSearch
        filterOption={(input: string, option) => String(option?.value).toLowerCase().includes(input.toLowerCase())}
        className={props.className}
        disabled={(props.level === '二级' || props.level === '三级') ? props.disabled : true}
        fieldNames={props.fieldNames || {value: 'value', label: 'value'}}
        options={dict.find(o => o.value === value?.split("-")[0])?.children}
        loading={loading}>
      </Select>
    </Col>
    <Col span={8}>
      <Select
        allowClear={props.allowClear}
        value={value ? value.split("-")[2] ? value.split("-")[2] : undefined : undefined}
        onChange={(value, option) => onChange(value, option, 2)}
        placeholder={props.placeholder}
        showSearch
        filterOption={(input: string, option) => String(option?.value).toLowerCase().includes(input.toLowerCase())}
        className={props.className}
        disabled={props.level === '三级' ? props.disabled : true}
        fieldNames={props.fieldNames || {value: 'value', label: 'value'}}
        options={dict.find(o => o.value === value?.split("-")[0])?.children?.find(o => o.value === value?.split("-")[1])?.children}
        loading={loading}>
      </Select>
    </Col>
  </Row>
})
