import React, {useEffect, useState} from "react";
import './index.less'
import {Button, ConfigProvider, Popover, Space} from "antd";
import {observer} from "mobx-react";
import {DashOutlined, PrinterOutlined, RedoOutlined,} from '@ant-design/icons';
import {useLocation} from "react-router-dom";
import {MenuPermissionsRow} from "@services/dto/menuPermissions";
import {RoleAndMenuType} from "@services/dto/roleManage";

export interface PropsType {
  items?: btnItemType[], // 按钮数组
  mainBtn?: string[] // 显示的主按钮
}

export type btnItemType = {
  // 标志 -> 1.用于权限判断 和 2.遍历的key
  key: string
  hidden?: boolean // 是否隐藏按钮
  disabled?: boolean // 是否禁用按钮

  // 统一按钮
  title?: React.ReactNode // 按钮的文字
  icon?: React.ReactNode
  onClick?: () => void

  // 自定义按钮
  node?: React.ReactNode
}

export interface FormAuthOptionsPropsType extends PropsType {
  onClick?: () => void
  disabled?: boolean
}

export interface HeaderOptionsPropsType extends PropsType {
  collapsed?: boolean, // 是否展开
  setCollapsed?: (v: boolean) => void, // 展开事件
  handleSearchReqReset?: () => void // 重置请求参数
}

const getMenus = (pathname: string, menuList: MenuPermissionsRow[]): MenuPermissionsRow[] => {
  let result = [];
  // 定义存储结果的数组
  if (!menuList) return result

  for (let i = 0; i < menuList.length; i++) {
    const menu = menuList[i];

    // 判断当前对象是否满足条件
    if (menu.type === 1 && menu.perms === pathname) {
      // 将满足条件的对象的子节点加入结果数组中
      result.push(...menu.child);
    }

    // 如果当前对象存在子节点，则递归查找子节点
    if (menu.child && menu.child.length > 0) {
      const children = getMenus(pathname, menu.child);
      result.push(...children);
    }
  }

  return result
}

// 表格行内右侧操作按钮组件
export const TableAuthOptions = observer((props: PropsType) => {

  const location = useLocation()
  const [mainBtn, setMainBtn] = useState<btnItemType>()
  const [permissionsList, setPermissionsList] = useState<MenuPermissionsRow[]>()

  useEffect(() => {
    const roleAndMenus: RoleAndMenuType = JSON.parse(localStorage.getItem('role-and-menus'))
    setPermissionsList(getMenus(location.pathname, roleAndMenus?.menus))
  }, []);

  /**
   * @description 主按钮变化
   */
  useEffect(() => {
    for (let i = 0; i < props.mainBtn?.length; i++) {
      const item = props.items.find(o => o.key === props.mainBtn[i])
      if (permissionsList?.some(o => o.perms === props.mainBtn[i] && !item.disabled && !item.hidden)) {
        setMainBtn(item)
        break
      }
    }
  }, [props.mainBtn, permissionsList]);

  return (
    <Space.Compact>

      {
        props.mainBtn && mainBtn && <Button
          size={"small"}
          onClick={mainBtn?.onClick}>
          { mainBtn.title ? mainBtn.title : permissionsList.find(o => o.perms === mainBtn.key).title }
        </Button>
      }

      { /*下拉按钮列表*/ }
      <ConfigProvider
        theme={{
          components: {
            Popover: {
              titleMinWidth: 130,
              algorithm: true, // 启用算法
            },
          },
        }}
      >
        <Space>
          <Popover
            title={''}
            placement="bottomRight"
            arrow={false}
            trigger={['hover']}
            content={
              <>
                { /*按钮列表*/ }
                <div className={'width-100-percentage content-box'} style={{width: 'min-content'}}>
                  {
                    props.items
                      // 1. 按钮权限 过滤要显示的按钮
                      ?.filter(o => permissionsList?.some(m => m.show && m.perms === o.key))
                      // 2. 表单办理进度 过滤要显示的按钮
                      ?.filter(o => !o.hidden)
                      // 3. 渲染按钮
                      ?.map((item) => (
                        <div
                          key={item.key}>
                          {
                            (permissionsList?.find(o => o.perms === item.key).title) &&
                            (
                              item.node ? item.node : <Button
                                className={'text-align-left'}
                                type={'text'}
                                block
                                disabled={item.disabled}
                                onClick={item.onClick}>
                                { item.icon }
                                { permissionsList?.find(o => o.perms === item.key).title }
                              </Button>
                            )
                          }
                        </div>))
                  }
                </div>
              </>
            }>
            <Button
              type={"default"}
              style={{padding: `0 5px`}}
              size={"small"}>
              <DashOutlined/>
            </Button>
          </Popover>
        </Space>
      </ConfigProvider>
    </Space.Compact>
  )
})

// 表单里面右上角操作按钮组件
export const FormAuthOptions = observer((props: FormAuthOptionsPropsType) => {
  const location = useLocation()
  const [permissionsList, setPermissionsList] = useState<MenuPermissionsRow[]>()

  useEffect(() => {
    const roleAndMenus: RoleAndMenuType = JSON.parse(localStorage.getItem('role-and-menus'))
    setPermissionsList(getMenus(location.pathname, roleAndMenus?.menus))
  }, []);

  return (
    <Space.Compact>

      <Button
        type={"link"}
        size={"small"}
        disabled={props.disabled}
        onClick={props.onClick}>
        <PrinterOutlined/>
      </Button>

      { /*下拉按钮列表*/ }
      <ConfigProvider
        theme={{
          components: {
            Popover: {
              titleMinWidth: 130,
              algorithm: true, // 启用算法
            },
          },
        }}
      >
        <Space>
          <Popover
            title={''}
            placement="bottomRight"
            arrow={false}
            trigger={['hover']}
            content={
              <>
                { /*按钮列表*/ }
                <div className={'width-100-percentage content-box'} style={{width: 'min-content'}}>
                  {
                    props.items
                      // 1. 按钮权限 过滤要显示的按钮
                      ?.filter(o => permissionsList?.some(m => m.show && m.perms === o.key))
                      // 2. 表单办理进度 过滤要显示的按钮
                      ?.filter(o => !o.hidden)
                      // 3. 渲染按钮
                      ?.map((item) => (
                        <div
                          key={item.key}>
                          {
                            (permissionsList?.find(o => o.perms === item.key).title) &&
                            (
                              item.node ? item.node : <Button
                                className={'text-align-left'}
                                type={'text'}
                                block
                                disabled={item.disabled}
                                onClick={item.onClick}>
                                { item.icon }
                                { permissionsList?.find(o => o.perms === item.key).title }
                              </Button>
                            )
                          }
                        </div>))
                  }
                </div>
              </>
            }>
            <Button
              type={"link"}
              size={"small"}>
              <DashOutlined/>
            </Button>
          </Popover>
        </Space>
      </ConfigProvider>
    </Space.Compact>
  )
})

// 菜单页头部查询尾部操作组件
export const HeaderOptions = observer((props: HeaderOptionsPropsType) => {
  const location = useLocation()
  const [permissionsList, setPermissionsList] = useState<MenuPermissionsRow[]>()

  useEffect(() => {
    const roleAndMenus: RoleAndMenuType = JSON.parse(localStorage.getItem('role-and-menus'))
    setPermissionsList(getMenus(location.pathname, roleAndMenus?.menus))
  }, []);

  const handleCollapsed = () => {
    props.setCollapsed && props.setCollapsed(!props.collapsed)
  }

  return (
    <Space.Compact>

      { /*收起和展开按钮*/ }
      <Button
        onClick={handleCollapsed}
        type={"primary"}>
        { props.collapsed ? '收起' : '展开' }
      </Button>

      { /*重置按钮*/ }
      {
        props.handleSearchReqReset && <Button
          type={"primary"}
          style={{padding: `0 10px`}}
          onClick={props.handleSearchReqReset}>
          <RedoOutlined/>
        </Button>
      }

      { /*下拉按钮列表*/ }
      <ConfigProvider
        theme={{
          components: {
            Popover: {
              titleMinWidth: 130,
              algorithm: true, // 启用算法
            },
          },
        }}
      >
        <Space>
          <Popover
            title={''}
            placement="bottomRight"
            arrow={false}
            trigger={['hover']}
            content={
              <>
                { /*按钮列表*/ }
                <div className={'width-100-percentage content-box'} style={{width: 'min-content'}}>
                  {
                    props.items
                      // 1. 按钮权限 过滤要显示的按钮
                      ?.filter(o => permissionsList?.some(m => m.show && m.perms === o.key))
                      // 2. 表单办理进度 过滤要显示的按钮
                      ?.filter(o => !o.hidden)
                      // 3. 渲染按钮
                      ?.map((item) => (
                        <div
                          key={item.key}>
                          {
                            (permissionsList?.find(o => o.perms === item.key).title) &&
                            (
                              item.node ? item.node : <Button
                                className={'text-align-left'}
                                type={'text'}
                                block
                                disabled={item.disabled}
                                onClick={item.onClick}>
                                { item.icon }
                                { permissionsList?.find(o => o.perms === item.key).title }
                              </Button>
                            )
                          }
                        </div>))
                  }
                </div>
              </>
            }>
            <Button
              type={"primary"}
              style={{padding: `0 10px`}}>
              <DashOutlined/>
            </Button>
          </Popover>
        </Space>
      </ConfigProvider>
    </Space.Compact>
  )
})
