import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {ContractListReq, ContractListRow, ContractStatisticsRow} from "@services/dto/contractList";

export class ContractListService {
  static async getContractList(req: ContractListReq): Promise<Page<ContractListRow>> {
    return await apiV1.get("/contractList/search", {params: req})
  }

  static async batchRefresh(): Promise<ResponseEntity<string>> {
    return await apiV1.get("/contractList/batch/refresh")
  }

  static async addContractList(row: ContractListRow): Promise<ResponseEntity<ContractListRow>> {
    return await apiV1.post("/contractList/save", {...row})
  }

  static async updateContractList(row: ContractListRow): Promise<ResponseEntity<ContractListRow>> {
    return await apiV1.put("/contractList/update", {...row})
  }

  static async batchUpdateContractList(selectedRows: ContractListRow[]): Promise<ResponseEntity<string>> {
    return await apiV1.put("/contractList/updateBatch", [...selectedRows])
  }

  static async deleteContractList(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/contractList/delete", {data: ids})
  }

  static async getOne(id: string): Promise<ResponseEntity<ContractListRow>> {
    return await apiV1.get(`/contractList/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<ContractListRow>> {
    return await apiV1.get(`/contractList/getBatch/${ids.join(',')}`)
  }

  static async statistics(req: ContractListReq): Promise<Page<ContractStatisticsRow>> {
    return await apiV1.get("/contractList/statistics", {params: req})
  }

  static async getByBusinessId(businessId: string): Promise<ContractStatisticsRow> {
    return await apiV1.get("/contractList/getByBusinessId", {params: {businessId}})
  }
}
