import React, { useEffect } from "react";
import { Col, DatePicker, Form, Input, message, Radio, Row } from "antd";
import { FormInstance } from "antd/es";
import { observer } from "mobx-react";
import { ContractRow } from "@services/dto/contract";
import { CascaderPerson } from "@/components/CascaderPerson";
import { PersonCascader } from "@/services/dto/talent";
import dayjs from "dayjs";
import { DATE_FMT } from "@/config";

export interface PropsType {
  row: ContractRow
}

export const ContractBasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  const handlePersonChange = (value: string[], selectedOptions: PersonCascader[]) => {
    return value
  }

  useEffect(() => {
    form.resetFields()
    const contractRow: any = { ...props.row }
    if (contractRow.userId) {
      contractRow.person = [props.row.userId, props.row.name]
    }
    if (contractRow.startTime) {
      contractRow.startTime = dayjs(contractRow.startTime)
    }
    if (contractRow.endTime) {
      contractRow.endTime = dayjs(contractRow.endTime)
    }
    form.setFieldsValue(contractRow);
  }, [props.row])

  const handleStartTimeChange = (date: dayjs.Dayjs | null, dateString: string) => {
    const endTime = form.getFieldValue("endTime")
    if (endTime && date) {
      if (date > endTime) {
        message.warning("开始时间不能大于结束时间！")
        form.resetFields(['startTime'])
      }
    }
  }

  const handleEndTimeChange = (date: dayjs.Dayjs | null, dateString: string) => {
    const startTime = form.getFieldValue("startTime")
    if (startTime && date) {
      if (date < startTime) {
        message.warning("结束时间不能少于开始时间！")
        form.resetFields(['endTime'])
      }
    }
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ status: 1, isVirtualUser: 0 }}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="员工"
            name="person"
            valuePropName={"person"}
            getValueFromEvent={handlePersonChange}
            rules={[{ required: true, message: '请选择员工!' }]}>
            <CascaderPerson disabled={!!props.row.id} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="部门"
            name="department"
            rules={[{ required: true, message: '请输入部门!' }]}>
            <Input placeholder="请输入部门" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同起始日"
            name="startTime"
            rules={[{ required: true, message: '请选择合同起始日!' }]}>
            <DatePicker placeholder={"请选择合同起始日"} disabled={!!props.row.id} className={'width-100-percentage'} format={DATE_FMT} onChange={handleStartTimeChange} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同到期日"
            name="endTime"
            rules={[{ required: true, message: '请选择合同到期日!' }]}>
            <DatePicker placeholder={"请选择合同到期日"} disabled={!!props.row.id} className={'width-100-percentage'} format={DATE_FMT} onChange={handleEndTimeChange} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            label="合同类型"
            name="type"
            rules={[{ required: true, message: '请选择合同类型!' }]}>
            <Radio.Group>
              <Radio value={'固定期限'}>固定期限</Radio>
              <Radio value={'无固定期限'}>无固定期限</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
