import React, {useEffect, useState} from "react";
import {Button, UploadFile} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {FileRow, TodoChecked} from "@services/dto/workerRecruitment";
import {CustomUpload} from "@components/CustomUpload";
import {UploadOutlined} from "@ant-design/icons";
import {UploadChangeParam} from "antd/es/upload/interface";
import {getUploadStringToArray} from "@common/utils";

export interface PropsType {
  row: TodoChecked
  handleChange: (fileList: UploadFile[]) => void
  files?: FileRow[]
}

export const UploadCustom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const getFileName = () => {
    return props.files ? props.files.map(item => item.name).join("、") : ""
  }
    
  useEffect(() => {
    const check = {...props.row}
    if (check && check.files) {
      let list:UploadFile[] = []
      check.files.forEach(file => {
        list = list.concat(getUploadStringToArray(file.fileUrl))
      })
      setFileList(list)
    }
  }, [props.row])

  const handleChange = (info: UploadChangeParam<UploadFile>) => {
    setFileList([...info.fileList])
    if (info.file.status === 'done') {
      props.handleChange([...info.fileList])
    }
  }

  return (
    <CustomUpload
      updateSize={20}
      maxCount={1}
      fileList={fileList}
      onChange={handleChange}
      className={getFileName() ? "margin-left-xs" : "custom-upload"}
      uploadType={"application"}
      accept={"application/*"}
      listType={"text"}
      multiple={false}>
      <Button icon={<UploadOutlined />}>上传{ getFileName()  }</Button>
    </CustomUpload>
  )
}))
