import React, {FC, useState, useEffect} from "react";
import {Button, Col, Form, Modal, Row, Space, Table, InputNumber, Select, Card, message, Input} from "antd";
import {PlusOutlined, DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {ColumnsType} from "antd/es/table";
import {WorkerTypes} from "@services/dto/recruitment";
import {allStores} from "@/stores";
import {observer} from "mobx-react";
import {getProjectStatusLabel, logger} from "@common/utils";

export interface PropsType {
  dataSource: WorkerTypes[],
  handleAddRecruitment: (workerType: WorkerTypes, action: boolean, index: number) => void,
  handleRemoveRecruitment: (typeIds: string[]) => void,
  className?: string
}

export const Recruitment: FC<PropsType> = observer((props) => {
  const {workerTypeStore} = allStores;
  const dataSource = props.dataSource
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false)
  const [index, setIndex] = useState<number>(-1)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  useEffect(() => {
    workerTypeStore.getWorkerTypes()
  }, [])

  const columns: ColumnsType<WorkerTypes> = [
    {
      title: '序列',
      dataIndex: 'sequence',
      key: 'sequence',
      align: 'center',
      render: (_, record: WorkerTypes, index) => (
        <div>{ index + 1 }</div>
      )
    },
    {
      title: '工种',
      dataIndex: 'typeName',
      key: 'typeName',
      align: 'center'
    },
    {
      title: '招聘人数',
      dataIndex: 'numberOfRequire',
      key: 'numberOfRequire',
      align: 'center'
    },
    {
      title: '已招聘人数',
      dataIndex: 'useNum',
      key: 'useNum',
      align: 'center'
    },
    {
      title: '招聘说明',
      dataIndex: 'desc',
      key: 'desc',
      align: 'center'
    },
    {
      title: '办理进度',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          { getProjectStatusLabel(text) }
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record, index) => (
        <Space>
          <Button icon={<EditOutlined/>} size={"middle"} shape={"round"} className={"view-btn"}
            onClick={() => handleEdit(record, index)}>修改</Button>
        </Space>
      )
    }
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[], selectedRows: WorkerTypes[]) => {
      setSelectedRowKeys(selectedRowKeys)
    },
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleEdit = (record:WorkerTypes, index: number) => {
    form.setFieldsValue(record)
    setIsEdit(true)
    setIndex(index)
    setIsModalVisible(true)
  }

  const handleOk = () => {
    form.validateFields().then(values => {
      const obj = workerTypeStore.workerTypes.items.find(item => item._id === values.typeId)
      values.typeName = obj?.name
      props.handleAddRecruitment(values, isEdit, index)
      form.resetFields();
      setIsEdit(false)
      setIsModalVisible(false);
    }).catch(info => {
      logger.debug('Validate Failed:', info);
    })
  };

  const handleCancel = () => {
    form.resetFields()
    setIsModalVisible(false);
  };
  
  const handleDeleteBatch = (record?: WorkerTypes) => {
    if (!record && selectedRowKeys.length === 0) {
      message.warning("请选择要删除的数据！")
      return
    }
    let typeIds:string[] = []
    if (record) {
      typeIds.push(record.typeId)
    } else {
      typeIds = selectedRowKeys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setSelectedRowKeys([])
        props.handleRemoveRecruitment(typeIds)
      },
    })
  }

  return (
    <Card 
      className={props.className} 
      title={<div className={"text-align-center"}>招聘信息</div>} 
      extra={<>
        <Button icon={<PlusOutlined/>} size={"middle"} shape={"round"} className={"margin-right-xs edit-btn"}
          onClick={showModal}>添加</Button>
        <Button icon={<DeleteOutlined/>} size={"middle"} shape={"round"} className={"delete-btn"}
          onClick={() => handleDeleteBatch(undefined)}>删除</Button>
      </>}>
      <Table rowSelection={{...rowSelection}} rowKey={'typeId'} dataSource={dataSource}
        columns={columns} size="middle" pagination={{
          position: ['bottomCenter'],
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`
        }} />

      <Modal title={<div className={"text-align-center"}>招聘岗位</div>} open={isModalVisible} onOk={handleOk}
        onCancel={handleCancel} maskClosable={false} closable={false}>
        <Form
          name="basic"
          form={form}
          labelCol={{span: 6}}
          wrapperCol={{span: 14}}
          autoComplete="off">
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label="工种"
                name="typeId"
                rules={[{required: true, message: '请选择工种!'}]}>
                <Select placeholder="请选择工种" className={'width-100-percentage'}>
                  {
                    workerTypeStore.workerTypes.items.map(item => {
                      return (
                        <Select.Option key={item._id} value={item._id}>{ item.name }</Select.Option>
                      )
                    })
                  }
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="招聘人数"
                name="numberOfRequire"
                rules={[{required: true, message: '请输入招聘人数!'}]}>
                <InputNumber min={1} precision={0} placeholder="请输入招聘人数" className={'width-100-percentage'}/>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="招聘说明"
                name="desc"
                rules={[{ required: false, message: '请输入招聘说明!' }]}>
                <Input.TextArea placeholder="请输入招聘说明" autoSize={{minRows: 2, maxRows: 9}}/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Card>
  )
})
