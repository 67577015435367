import React, {useEffect, useState} from "react";
import {Col, Form, Input, Row, Select, Space, Tag} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {ProcessRecordsRow} from "@services/dto/processRecords";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {OrganizationalRow} from "@services/dto/organizational";
import {
  APPROVAL_OPTIONS,
  APPROVE_FORM_DISABLED_STATE,
  FORM_AGREE_TEXT,
  FORM_ALTERNATE_TEXT,
  FORM_CLOSE_TEXT,
  FORM_COMMIT_TEXT,
  FORM_END_TEXT,
  FORM_HANDOVER_TEXT,
  FORM_PERSON_RESPONSIBLE_WHITELIST,
  FORM_RETURN_TEXT,
  FORM_REVOCATION_TEXT,
  SUPER_ROLE
} from "@/config";
import {allStores} from "@/stores";
import {RoleAndMenuType} from "@services/dto/roleManage";
import {ProcessRecordsService} from "@services/ProcessRecordsService";

export interface PropsType {
  row: ProcessRecordsRow
  businessId?: string
  status?: string
  submitterId?: string
  userId?: string
  tableName?: string
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {

  const {securityStore} = allStores

  const currentUserId = securityStore.getLoggedUser()._id
  const currentUserName = securityStore.getLoggedUser().name
  const [form] = Form.useForm()
  const [role, setRole] = useState<string[]>([])
  const [status, setStatus] = useState<string>()

  useEffect(() => {
    const roleAndMenus: RoleAndMenuType = JSON.parse(localStorage.getItem('role-and-menus'))
    setRole(roleAndMenus.roleMS?.map(o => o.name) || [])
  }, []);

  useEffect(() => {
    form.resetFields()
    const processRecordsRow = {...props.row}
    if (props.businessId) {
      ProcessRecordsService.getProcessRecords({
        businessId: props.businessId,
        pageNum: 1,
        perPage: 999,
        type: props.tableName,
      }).then(rsp => {
        const notificationScopeUserId = rsp.items
          .map(o => o.userId)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(id => id !== currentUserId) // 过滤出不是自己的数据
        const notificationScopeUserName = rsp.items
          .map(o => o.userName)
          .filter((v, i, self) => self.indexOf(v) === i) // 去重
          .filter(name => name !== currentUserName) // 过滤出不是自己的数据
          .join(',') || ''
        processRecordsRow.person = processRecordsRow.person || notificationScopeUserId
        processRecordsRow.personName = processRecordsRow.personName || notificationScopeUserName
      }).finally(() => {
        form.setFieldsValue(processRecordsRow);
      })
    } else {
      form.setFieldsValue(processRecordsRow);
    }
  }, [props.row])

  /**
   * 责任人修改 填充名字
   */
  const handlePersonResponsibleChange = (value: string, selectedOptions: OrganizationalRow[]) => {
    const row = selectedOptions.find(o => value === o.id)
    form.setFieldsValue({personResponsibleName: row.name})
  }

  /**
   * 责任人修改 填充名字
   */
  const handlePersonChange = (value: string[], selectedOptions: OrganizationalRow[]) => {
    const rows = selectedOptions.filter(o => value.includes(o.id))
    const personName = rows?.map(o => o.name).join(',') || ''
    form.setFieldsValue({personName})
  }

  const handleTagClick = (v: string) => {
    let remark = form.getFieldValue('remark') || ''
    remark += v
    form.setFieldsValue({remark})
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 4}}
      wrapperCol={{span: 18}}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personName">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="办理类型"
            name="status"
            rules={[{required: true, message: '请选择办理类型!'}]}>
            <Select
              allowClear
              onChange={v => setStatus(v)}
              placeholder={"请选择办理类型"}
              options={[
                {
                  label: FORM_COMMIT_TEXT,
                  value: FORM_COMMIT_TEXT,
                  disabled: !(
                    APPROVE_FORM_DISABLED_STATE[FORM_COMMIT_TEXT].includes(props.status)
                  )
                },
                {
                  label: FORM_REVOCATION_TEXT,
                  value: FORM_REVOCATION_TEXT,
                  disabled: !(
                    (APPROVE_FORM_DISABLED_STATE[FORM_REVOCATION_TEXT].includes(props.status) && props.submitterId === currentUserId) ||
                    (APPROVE_FORM_DISABLED_STATE[FORM_REVOCATION_TEXT].includes(props.status) && role.some(o => SUPER_ROLE.includes(o)))
                  )
                },
                {
                  label: FORM_AGREE_TEXT,
                  value: FORM_AGREE_TEXT,
                  disabled: !(
                    (APPROVE_FORM_DISABLED_STATE[FORM_AGREE_TEXT].includes(props.status) && props.userId === currentUserId) ||
                    (APPROVE_FORM_DISABLED_STATE[FORM_AGREE_TEXT].includes(props.status) && role.some(o => SUPER_ROLE.includes(o)))
                  )
                },
                {
                  label: FORM_RETURN_TEXT,
                  value: FORM_RETURN_TEXT,
                  disabled: !(
                    (APPROVE_FORM_DISABLED_STATE[FORM_RETURN_TEXT].includes(props.status) && props.userId === currentUserId) ||
                    (APPROVE_FORM_DISABLED_STATE[FORM_RETURN_TEXT].includes(props.status) && role.some(o => SUPER_ROLE.includes(o)))
                  )
                },
                {
                  label: FORM_HANDOVER_TEXT,
                  value: FORM_HANDOVER_TEXT,
                  disabled: !(
                    (APPROVE_FORM_DISABLED_STATE[FORM_HANDOVER_TEXT].includes(props.status) && props.userId !== currentUserId) ||
                    (APPROVE_FORM_DISABLED_STATE[FORM_HANDOVER_TEXT].includes(props.status) && role.some(o => SUPER_ROLE.includes(o)))
                  )
                },
                {
                  label: FORM_CLOSE_TEXT,
                  value: FORM_CLOSE_TEXT,
                  disabled: !(
                    (APPROVE_FORM_DISABLED_STATE[FORM_CLOSE_TEXT].includes(props.status) && props.userId === currentUserId) ||
                    (APPROVE_FORM_DISABLED_STATE[FORM_CLOSE_TEXT].includes(props.status) && role.some(o => SUPER_ROLE.includes(o))) ||
                    (APPROVE_FORM_DISABLED_STATE[FORM_HANDOVER_TEXT].includes(props.status) && props.userId !== currentUserId) ||
                    (APPROVE_FORM_DISABLED_STATE[FORM_HANDOVER_TEXT].includes(props.status) && role.some(o => SUPER_ROLE.includes(o)))
                  )
                },
                {
                  label: FORM_ALTERNATE_TEXT,
                  value: FORM_ALTERNATE_TEXT,
                  disabled: !(
                    (APPROVE_FORM_DISABLED_STATE[FORM_ALTERNATE_TEXT].includes(props.status) && props.userId === currentUserId) ||
                    (APPROVE_FORM_DISABLED_STATE[FORM_ALTERNATE_TEXT].includes(props.status) && role.some(o => SUPER_ROLE.includes(o)))
                  )
                },
                {
                  label: FORM_END_TEXT,
                  value: FORM_END_TEXT,
                  disabled: !(
                    (APPROVE_FORM_DISABLED_STATE[FORM_END_TEXT].includes(props.status) && props.userId === currentUserId) ||
                    (APPROVE_FORM_DISABLED_STATE[FORM_END_TEXT].includes(props.status) && role.some(o => SUPER_ROLE.includes(o)))
                  )
                },
              ]}>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理意见"
            name="remark"
            help={
              <Space className={'margin-top-xss'} size={[4, 8]} wrap>
                {
                  APPROVAL_OPTIONS[status]?.map((o: string) =>
                    <Tag
                      className={'c-pointer'}
                      onClick={() => handleTagClick(o)}
                      key={o}>
                      { o }
                    </Tag>
                  )
                }
              </Space>
            }
            rules={[{required: false, message: '请输入办理意见!'}]}>
            <Input.TextArea
              placeholder="请输入办理意见"
              allowClear
              autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
        {
          FORM_PERSON_RESPONSIBLE_WHITELIST.includes(status) && <Col span={24}>
            <Form.Item
              label="主送人员"
              name="personResponsible"
              rules={[{required: true, message: '请选择主送人员!'}]}>
              <SelectPersonCharge
                onChange={handlePersonResponsibleChange}
                isHiddenDefaultItems
                className={"width-100-percentage"}
                placeholder={"请选择下一步办理人员"}
                allowClear/>
            </Form.Item>
          </Col>
        }
        <Col span={24}>
          <Form.Item
            label="抄送人员"
            name="person"
            rules={[{required: false, message: '请选择抄送人员!'}]}>
            <SelectPersonCharge
              onChange={handlePersonChange}
              isHiddenDefaultItems
              mode={'multiple'}
              className={"width-100-percentage"}
              placeholder={"请选择下一步抄送人员"}
              allowClear/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
