import {
  DeductionRow,
  SearchDeductionRowReq,
} from "@services/dto/deduction";
import { apiV1 } from "@services/apiHelper";
import { Page, ResponseEntity } from "@services/dto/common";

export class DeductionService {
  static async search(req: SearchDeductionRowReq): Promise<Page<DeductionRow>> {
    return apiV1.get("/deductions", { params: req })
  }
  static async add(req: DeductionRow): Promise<ResponseEntity<DeductionRow>> {
    return apiV1.post("/deductions", { ...req })
  }
  static async update(req: DeductionRow): Promise<ResponseEntity<DeductionRow>> {
    return apiV1.put("/deductions", { ...req })
  }
  static async delete(ids: string[]): Promise<Page<DeductionRow>> {
    return await apiV1.delete("/deductions", { data: ids })
  }
}
