import {observer} from "mobx-react";
import {ColumnsType} from "antd/es/table";
import {Button, Drawer, FormInstance, message, Modal, Space, Table} from "antd";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {DEFAULT_MODAL_WIDTH, DEFAULT_TABLE_SCROLL_HEIGHT, TABLE_CELL_WIDTH_1x} from "@/config";
import {WorkPostReq, WorkPostRow} from "@services/dto/workPost";
import {BasicInfo} from "@modules/WorkPost/Components/BasicInfo";
import {allStores} from "@/stores";
import {WorkPostService} from "@/services/WorkPostService";
import {logger, parseURLParams, safeCompute, showErr} from "@/common/utils";
import {useLocation} from "react-router-dom";
import {LabelValue} from "@services/dto/common";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableOptions} from "src/components/TableOptions";
import {HeaderActionCom} from "@modules/WorkPost/Components/HeaderActionCom";
import {WindowPrint} from "@modules/WindowPrint";
import {PrinterOutlined} from "@ant-design/icons";

const blackColumnList = [
  'action',
]

const wideShowColumn: string[] = [
  'name',
  'code',
  'requirements2',
  'requirements',
  'action',
]

const narrowShowColumn: string[] = [
  'name',
  'code',
  'requirements2',
  'requirements',
  'action',
]

export interface PropsType {
  isModalSelect?: boolean // 拟态宽
  locationPathname?: string // 路由
  isHiddenOptions?: boolean
  dataSource?: WorkPostRow[] // 数据源
}

export const WorkPost = observer(React.forwardRef<FormInstance, PropsType>((props, _) => {
  const location = useLocation()
  const {workPostStore, headerSearchStore, tableColumnsStore, screenResolutionStore} = allStores
  const basicInfo = React.createRef<FormInstance>();
  const [dataSource, setDataSource] = useState<WorkPostRow[]>([])
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentList, setContentList] = useState<LabelValue[]>([]);
  const [isPrintTable, setIsPrintTable] = useState<boolean>(false)
  const [printTitle, setPrintTitle] = useState<string>('')
  const [renderColumns, setRenderColumns] = useState<ColumnsType<WorkPostRow>>([])
  const [printDataSource, setPrintDataSource] = useState<WorkPostRow[]>([])
  const [scrollHeight, setScrollHeight] = useState<number>(DEFAULT_TABLE_SCROLL_HEIGHT)
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [updateCount, setUpdateCount] = useState<number>(0)
  const [workPostRow, setWorkPostRow] = useState<WorkPostRow>()
  const [isView, setIsView] = useState<boolean>(false)
  const [searchReq, setSearchReq] = useState<WorkPostReq>({
    pageNum: 1,
    perPage: 10,
    cooperationUnit: headerSearchStore.headerSearchReq.cooperationUnit,
    responsibilityProject: headerSearchStore.headerSearchReq.responsibilityProject,
    ...workPostStore.workPostReq
  })

  const columns: ColumnsType<WorkPostRow> = [
    {
      title: '岗位名称',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '岗位代码',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '岗位职责',
      dataIndex: 'requirements2',
      key: 'requirements2',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '任职要求',
      dataIndex: 'requirements',
      key: 'requirements',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (_, record) => (
        <TableOptions
          handleEdit={() => {
            handleEdit(record)
          }}
          handleView={() => {
            handleView(record)
          }}
          handleDelete={() => {
            handleDeleteBatch(record)
          }}
          items={[
            <Button
              className={'text-align-left'}
              type={'text'}
              block
              onClick={() => {
                handlePrint(record).then()
              }}>
              <PrinterOutlined/>
              打印
            </Button>,
          ]}
          onClick={() => handleEdit(record)}>修改</TableOptions>
      )
    }
  ];

  /**
   * @description 初始化
   */
  useEffect(() => {

    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(props.locationPathname || location.pathname) || []
    if (showColumn.length) {
      // 检验存储中的列表是否符合规范
      showColumn = showColumn.filter(o => columns.some(column => column.key === o))
      // 无论有无存储都触发change，保存当前显示的字段
      setShowColumn(showColumn)
    } else {
      // 窄屏判断
      if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
        setShowColumn(narrowShowColumn)
      }
    }

    // 当作为组件在其他页面使用且传了数据时 不请求数据
    if (!props.dataSource) {
      refreshData(searchReq)
    }
  }, [])

  /**
   * @description 全局查询
   */
  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
      refreshData(req)
    }
  }, [headerSearchStore.headerSearchReq])

  /**
   * @description 表格数据变化
   */
  useEffect(() => {
    if (workPostStore.workPostDatasource.data) {
      setDataSource(workPostStore.workPostDatasource.data.items || []);
      setTotal(workPostStore.workPostDatasource.data.total || 0)
    }
  }, [workPostStore.workPostDatasource.data])

  /**
   * @description 表单数据变化
   */
  useEffect(() => {
    if (workPostStore.workPostRow?.workPostId) {
      setWorkPostRow(workPostStore.workPostRow)
      setIsView(false)
      setIsModalVisible(true);
      workPostStore.updateWorkPostRow({
        ...workPostStore.workPostRow,
        workPostId: undefined,
      })
    }
  }, [workPostStore.workPostRow])

  useEffect(() => {
    if (workPostStore.workPostOption) {
      const [option, paramsStr] = workPostStore.workPostOption.split("?")
      if (option === 'add') {
        handleAdd()
      } else if (paramsStr) {
        const params = parseURLParams(paramsStr)
        if (option === 'edit') {
          WorkPostService.getOne(params.id).then(rsp => {
            if (rsp.code === 200) {
              handleEdit(rsp.data)
            }
          })
        }
      }
      workPostStore.updateWorkPostOption(undefined)
    }
  }, [workPostStore.workPostOption])

  /**
   * 列配置变化
   */
  useEffect(() => {
    if (props.isModalSelect) {
      setRenderColumns(columns.filter(o => !blackColumnList.includes(o.key as string)).filter(o => showColumn.includes(String(o.key))));
    } else {
      setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))));
    }
  }, [showColumn])

  /**
   * 动态修改表格高度
   */
  useLayoutEffect(() => {
    const othersHeight = 10 + 5 + 5
    const parent = document.querySelector(".tabs-content")
    const headAction = document.querySelector(".header-action-row")
    const thead = document.querySelector(".ant-table-thead")
    const summary = document.querySelector('.ant-table-summary')
    const pagination = document.querySelector('.ant-pagination')
    const paginationHeight = pagination ? pagination.clientHeight + 16 * 2 : 0
    setScrollHeight(safeCompute(parent?.clientHeight) - safeCompute(headAction?.clientHeight) - safeCompute(thead?.clientHeight) - paginationHeight - safeCompute(summary?.clientHeight) - othersHeight)
  }, [screenResolutionStore.screenResolution.height, workPostStore.collapsed, searchReq.pageNum, searchReq.perPage])

  const handleAdd = () => {
    setWorkPostRow(undefined)
    setIsView(false)
    setIsModalVisible(true)
  }

  const handleView = (record: WorkPostRow) => {
    setWorkPostRow(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = (record: WorkPostRow) => {
    setWorkPostRow(record)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleDeleteBatch = (record?: WorkPostRow) => {
    const keys = [...selectedRowKeys]
    if (!record && keys.length === 0) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = []
    if (record) {
      ids.push(record.id)
    } else {
      ids = keys.map(item => String(item))
    }
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        WorkPostService.deleteWorkPost(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          refreshData(workPostStore.workPostReq)
        }).catch(showErr).finally(() => {
          setConfirmLoading(false)
          setSelectedRowKeys([])
        })
      },
    })
  }

  /**
   * 打印单条数据
   * @param record
   */
  const handlePrint = async (record: WorkPostRow) => {
    const list = await workPostStore.getPrintList(record)
    if (list) {
      setContentList(list)
      setPrintTitle('工作岗位单')
      setIsPrintTable(false)
      setIsModalOpen(true)
    } else {
      message.warning('请检查')
    }
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const refreshData = (req: WorkPostReq) => {
    workPostStore.getWorkPost(req)
  }

  const actionThen = () => {
    message.success("操作成功！")
    setIsModalVisible(false);
    setWorkPostRow(undefined)
    refreshData(searchReq)
  }

  const handleOk = () => {
    const row = {...workPostRow!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(row, values)
      setConfirmLoading(true)
      if (row.id) {
        WorkPostService.updateWorkPost(row).then(() => {
          actionThen()
          setUpdateCount(updateCount + 1)
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        WorkPostService.addWorkPost(row).then(() => {
          actionThen()
          setUpdateCount(updateCount + 1)
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    workPostStore.setCollapsed(collapsed)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(props.locationPathname || location.pathname, value)
  }

  /**
   * @description 打印表格
   */
  const handlePrintTable = async () => {
    // 数据处理
    const rows = workPostStore.workPostDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    for (let i = 0; i < rows.length; i++) {
    }
    setPrintDataSource(rows)
    setIsPrintTable(true)
    setPrintTitle('工作岗位表格')
    setIsModalOpen(true)
  }

  return (
    <div className={"institution"}>
      <HeaderActionCom
        size={props.isModalSelect ? 6 : undefined}
        locationPathname={props.locationPathname}
        isHiddenOptions={props.isHiddenOptions}
        updateCount={updateCount}
        collapsed={workPostStore.collapsed}
        setCollapsed={handleCollapsedChange}
        searchReq={searchReq}
        selectedRowKeys={selectedRowKeys}
        handleShowColumnReset={handleShowColumnReset}
        handleDeleteBatch={handleDeleteBatch}
        handleAdd={handleAdd}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        items={[
          <Button
            block
            className={'text-align-left'}
            type={"text"}
            onClick={() => handlePrintTable()}>
            <PrinterOutlined/>
            打印表格
          </Button>,
        ]}
        tableColumns={props.isModalSelect ? columns.filter(o => !blackColumnList.includes(o.key as string)) : columns}
        refreshData={props.dataSource ? undefined : (req) => refreshData({...searchReq, ...req})}></HeaderActionCom>

      <Table
        className={'margin-top-xss'}
        rowKey={"id"}
        dataSource={props.dataSource || dataSource}
        columns={renderColumns}
        size={"middle"}
        scroll={{y: scrollHeight, scrollToFirstRowOnChange: true}}
        loading={props.dataSource ? false : workPostStore.workPostDatasource.loading || confirmLoading}
        pagination={{
          total: total,
          position: ['bottomCenter'],
          pageSize: searchReq.perPage,
          current: searchReq.pageNum,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: total => `共${total}条`,
          onChange: handlePageChange
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys)
          },
          columnWidth: 50,
        }}/>

      <Drawer
        title={<div className="text-align-center">规章制度</div>}
        width={DEFAULT_MODAL_WIDTH}
        destroyOnClose
        open={isModalVisible}
        onClose={handleCancel}>
        <BasicInfo row={{...workPostRow!}} ref={basicInfo} isView={isView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button
              type="primary" onClick={handleOk} loading={confirmLoading}
              className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>

      <WindowPrint
        isSummary
        isHiddenTime
        title={printTitle}
        contentList={contentList}
        isModalOpen={isModalOpen}
        isPrintTable={isPrintTable}
        tableColumns={columns}
        dataSource={printDataSource}
        narrowShowColumn={narrowShowColumn}
        handleCancel={() => {
          setIsModalOpen(false)
          setSelectedRowKeys([])
        }}/>
    </div>
  )
}))
