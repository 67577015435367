import {observer} from "mobx-react";
import {ColumnsType} from "antd/es/table";
import {
  LARGER_MODAL_WIDTH,
  SMALL_TABLE_SCROLL_HEIGHT,
  TABLE_CELL_WIDTH_1_5x,
  TABLE_CELL_WIDTH_1x,
  TABLE_CELL_WIDTH_2x
} from "@/config";
import './BankStatementModal.less'
import type {DescriptionsProps} from 'antd';
import {Button, FormInstance, message, Space, Table, Tag, Typography} from "antd";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {TableMoneyText} from "@components/TableMoneyText";
import React, {useEffect, useState} from "react";
import {HeaderActionCom} from "@modules/FundApproval/Components/HeaderActionCom";
import {allStores} from "@/stores";
import {useLocation} from "react-router-dom";
import {previewFile} from "@components/CustomUpload";
import {FundApprovalRow} from "@services/dto/fundApproval";
import {CustomDraggableModal} from "@components/CustomDraggableModal";

export interface PropsType {
  dataSource?: FundApprovalRow[]
  open?: boolean
  items?: DescriptionsProps['items']
  handleOk?: (mainBody: React.Key, type: number) => void
  handleCancel?: () => void
}

const narrowShowColumn: string[] = [
  "faDate",
  "faCode",
  "faType",
  "faSummary",
  "faAmount",
  // "faPaymentAccount",
  // "faAccountsReceivable",
  // "faPayDate",
  // "faStatus",
  "statusCommon",
  "action",
]

export const MergeFundApprovalModal = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const location = useLocation()
  const {bankStatementStore, tableColumnsStore} = allStores
  const [showColumn, setShowColumn] = useState<string[]>(narrowShowColumn)
  const [dataSource, setDataSource] = useState<FundApprovalRow[]>([])
  const [renderColumns, setRenderColumns] = useState<ColumnsType<FundApprovalRow>>([])
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useEffect(() => {
    setDataSource(props.dataSource)
  }, [props.dataSource])

  useEffect(() => {
    setIsOpen(props.open)
    setSelectedRowKeys([])
  }, [props.open]);

  /**
   * 列配置变化
   */
  useEffect(() => {
    setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))))
  }, [showColumn])

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem('/fundApproval') || []
    if (!showColumn.length) return
    // 检验存储中的列表是否符合规范
    showColumn = showColumn.filter(o => columns.some(column => column.key === o))
    // 无论有无存储都触发change，保存当前显示的字段
    setShowColumn(showColumn)
  }, []);

  const columns: ColumnsType<FundApprovalRow> = [
    {
      title: '制单日期',
      dataIndex: 'faDate',
      key: 'faDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '制单编号',
      dataIndex: 'faCode',
      key: 'faCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    // {
    //   title: '款项类别',
    //   dataIndex: 'faType',
    //   key: 'faType',
    //   align: 'center',
    //   width: TABLE_CELL_WIDTH_1_5x,
    // },
    // {
    //   title: '款项摘要',
    //   dataIndex: 'faSummary',
    //   key: 'faSummary',
    //   width: TABLE_CELL_WIDTH_1_5x,
    //   render: (text) => (
    //     <Space>
    //       <TableEllipsisText value={text}></TableEllipsisText>
    //     </Space>
    //   )
    // },
    {
      title: '金额',
      dataIndex: 'faAmount',
      key: 'faAmount',
      align: 'right',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      )
    },
    {
      title: '付款账户',
      dataIndex: 'faPaymentAccount',
      key: 'faPaymentAccount',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    // {
    //   title: '付款账户简称',
    //   dataIndex: 'faPaymentAccount',
    //   key: 'faPaymentAccount',
    //   align: 'center',
    //   width: TABLE_CELL_WIDTH_2x,
    //   render: (text) => (
    //     <Space>
    //       <TableEllipsisText value={text}></TableEllipsisText>
    //     </Space>
    //   )
    // },
    {
      title: '付款账户名称',
      dataIndex: 'baName1',
      key: 'baName1',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '付款账户账号',
      dataIndex: 'baAccount1',
      key: 'baAccount1',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '付款账户银行',
      dataIndex: 'baBlank1',
      key: 'baBlank1',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '收款账户',
      dataIndex: 'faAccountsReceivable',
      key: 'faAccountsReceivable',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    // {
    //   title: '收款账户简称',
    //   dataIndex: 'faAccountsReceivable',
    //   key: 'faAccountsReceivable',
    //   align: 'center',
    //   width: TABLE_CELL_WIDTH_2x,
    //   render: (text) => (
    //     <Space>
    //       <TableEllipsisText value={text}></TableEllipsisText>
    //     </Space>
    //   )
    // },
    {
      title: '收款账户名称',
      dataIndex: 'baName2',
      key: 'baName2',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '收款账户账号',
      dataIndex: 'baAccount2',
      key: 'baAccount2',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '收款账户银行',
      dataIndex: 'baBlank2',
      key: 'baBlank2',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联申请类型',
      dataIndex: 'faApplicationFormType',
      key: 'faApplicationFormType',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联商务合同',
      dataIndex: 'faContractCode',
      key: 'faContractCode',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联申请',
      dataIndex: 'faApplicationForm',
      key: 'faApplicationForm',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联发票',
      dataIndex: 'lnvoiceRegistrationId',
      key: 'lnvoiceRegistrationId',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '关联资金出纳',
      dataIndex: 'bankStatementCodes',
      key: 'bankStatementCodes',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={
            <Typography.Text
              type="success"
              className={'c-pointer'}
              onClick={() => {
                // handleBankStatementCodeClick(record)
              }}>{ text }</Typography.Text>
          }></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '责任部门',
      dataIndex: 'responsibilityProject',
      key: 'responsibilityProject',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '协作单位',
      dataIndex: 'cooperationUnit',
      key: 'cooperationUnit',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '存档批号',
      dataIndex: 'faFileList',
      key: 'faFileList',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text, record) => (
        <Space>
          {
            text && <Typography.Text
              type="success"
              className={'c-pointer'}
              onClick={() => {
                handlePreview(record)
              }}>查看</Typography.Text>
          }
        </Space>
      )
    },
    {
      title: '实现日期',
      dataIndex: 'faPayDate',
      key: 'faPayDate',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x
    },
    {
      title: '实现金额',
      dataIndex: 'bsAmountRealized',
      key: 'bsAmountRealized',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x
    },
    {
      title: '余额',
      dataIndex: 'bsAmount',
      key: 'bsAmount',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x
    },
    {
      title: '经办记录',
      dataIndex: 'personResponsible',
      key: 'personResponsible',
      align: 'center',
      width: TABLE_CELL_WIDTH_1_5x,
      render: (text) => (
        <Space>
          <TableEllipsisText value={text}></TableEllipsisText>
        </Space>
      )
    },
    {
      title: '办理进度',
      dataIndex: 'faStatus',
      key: 'faStatus',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x
    },
    {
      title: '校验',
      dataIndex: 'statusCommon',
      key: 'statusCommon',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
      render: (text) => (
        <Space>
          <Tag color={text === '完成' ? "success" : 'error'}>{ text }</Tag>
        </Space>
      )
    },
  ];

  /**
   * 解绑按钮事件
   */
  const handleOk = (type: number) => {
    if (!selectedRowKeys.length) {
      message.warning("请选择要解绑的数据！").then()
      return
    }
    if (selectedRowKeys.length !== 1) {
      message.warning("请选择一条主体！").then()
      return
    }
    props.handleOk && props.handleOk(selectedRowKeys[0], type)
  }

  /**
   * 取消按钮事件
   */
  const handleCancel = () => {
    props.handleCancel && props.handleCancel()
  }

  /**
   * 文件预览
   * @param record
   */
  const handlePreview = (record: FundApprovalRow) => {
    if (typeof record.faFileList === 'string') {
      previewFile(record.faFileList)
    }
  }

  const handleCollapsedChange = (collapsed: boolean) => {
    bankStatementStore.setCollapsed(collapsed)
  }

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(location.pathname, value)
  }

  const handleShowColumnReset = () => {
    setShowColumn(narrowShowColumn)
  }

  return (
    <CustomDraggableModal
      title={<div className="text-align-center">合并资金审批-操作配置</div>}
      open={isOpen}
      width={LARGER_MODAL_WIDTH}
      footer={[
        <Button
          key="back"
          onClick={handleCancel}>
          取消
        </Button>,
        <Button
          key="1"
          type="primary"
          onClick={() => handleOk(1)}>
          累计合并
        </Button>,
        <Button
          key="2"
          type="primary"
          onClick={() => handleOk(2)}
        >
          不累计合并
        </Button>
      ]}
      onCancel={handleCancel}>
      { /*{
        props.items && <Descriptions
          title=""
          items={props.items}/>
      }*/ }
      {
        props.items && <>
          <div className={'desc-box'}>
            {
              props.items.map(item => <div
                key={item.key}
                className={'desc-item'}>
                <span className={'label'}>{ item.label }</span>
                <span>:</span>
                <span className={'children'}>{ item.children }</span>
              </div>)
            }
          </div>
        </>
      }

      <HeaderActionCom
        locationPathname={'/fundApproval'}
        handleShowColumnReset={handleShowColumnReset}
        collapsed={bankStatementStore.collapsed}
        setCollapsed={handleCollapsedChange}
        size={6}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={columns}></HeaderActionCom>

      <Table
        className={"margin-top-xss"}
        rowKey={"id"}
        dataSource={dataSource}
        columns={renderColumns}
        size={"small"}
        bordered
        scroll={{y: SMALL_TABLE_SCROLL_HEIGHT, scrollToFirstRowOnChange: true}}
        rowSelection={{selectedRowKeys: selectedRowKeys, onChange: setSelectedRowKeys, columnWidth: 50}}
        pagination={false}
      />
    </CustomDraggableModal>

  )
}))
