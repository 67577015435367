import {observer} from "mobx-react";
import './index.less'
import React, {useEffect, useState} from "react";
import {Button, DescriptionsProps, message, Space, Table} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {TABLE_CELL_WIDTH_1x, TABLE_CELL_WIDTH_2x, TABLE_CELL_WIDTH_3x} from "@/config";
import {
  IncomeExpenditureStatisticsReq,
  IncomeExpenditureStatisticsRow,
  IncomeExpenditureStatisticsRowSummaryTotal
} from "@services/dto/incomeExpenditureStatistics";
import {TableMoneyText} from "@components/TableMoneyText";
import {HeaderActionCom} from "@modules/IncomeExpenditureStatistics/Components/HeaderActionCom";
import {useLocation} from "react-router-dom";
import {BalanceReconciliation} from "@modules/BankAccount/Components/BalanceReconciliation";
import {BalanceReconciliationRow} from "@services/dto/bankAccount";
import {TableEllipsisText} from "@components/TableEllipsisText";
import {WindowPrint} from "@modules/IncomeExpenditureStatistics/Components/WindowPrint";
import {accAdd} from "@common/utils";
import {PrinterOutlined} from "@ant-design/icons";

const wideShowColumn: string[] = [
  "sIndex",
  "faType",
  "budgetAmountThisPeriod",
  "settlementAmountThisPeriod",
  "grandTotal",
  "status",
]

const narrowShowColumn: string[] = [
  "sIndex",
  "faType",
  "budgetAmountThisPeriod",
  "settlementAmountThisPeriod",
  "grandTotal",
  "status",
]

export const IncomeExpenditureStatistics = observer(() => {
  const {incomeExpenditureStatisticsStore, screenResolutionStore, headerSearchStore, tableColumnsStore} = allStores
  const [dataSource, setDataSource] = useState<IncomeExpenditureStatisticsRow[]>([])
  const [total, setTotal] = useState<number>(0)
  const [expanded, setExpanded] = useState<boolean>(false)
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([])
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [balanceReconciliationRow, setBalanceReconciliationRow] = useState<BalanceReconciliationRow[]>([])
  const [incomeExpenditureStatisticsRowViewTitle, setIncomeExpenditureStatisticsRowViewTitle] = useState<string>('')
  const [summaryTotalObj, setSummaryTotalObj] = useState<IncomeExpenditureStatisticsRowSummaryTotal>()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [renderColumns, setRenderColumns] = useState<ColumnsType<IncomeExpenditureStatisticsRow>>([])
  const location = useLocation()
  const [showColumn, setShowColumn] = useState<string[]>(wideShowColumn)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [items, setItems] = useState<DescriptionsProps['items']>([])
  const [searchReq, setSearchReq] = useState<IncomeExpenditureStatisticsReq>({
    pageNum: 1,
    perPage: 999,
  })

  const columns: ColumnsType<IncomeExpenditureStatisticsRow> = [
    {
      title: '序号',
      dataIndex: 'sIndex',
      key: 'sIndex',
      align: 'center',
      width: TABLE_CELL_WIDTH_1x,
    },
    {
      title: '款项类别',
      dataIndex: 'faType',
      key: 'faType',
      align: 'center',
      width: TABLE_CELL_WIDTH_3x,
      render: (text, record) => (
        <TableEllipsisText
          placement={'right'}
          showValue={<div className={'width-100-percentage'}>
            <div
              style={{transform: `translateX(${(record.sIndex.split('.').length - 1) * 2}em)`}}
              dangerouslySetInnerHTML={{__html: text}}></div>
          </div>}
          copyValue={text.replace(/&nbsp;/g, '')}
          value={text}></TableEllipsisText>
      ),
    },
    {
      title: '期初余额',
      dataIndex: 'budgetAmountThisPeriod',
      key: 'budgetAmountThisPeriod',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      ),
    },
    {
      title: '本期余额',
      dataIndex: 'settlementAmountThisPeriod',
      key: 'settlementAmountThisPeriod',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      ),
    },
    {
      title: '期末余额',
      dataIndex: 'grandTotal',
      key: 'grandTotal',
      align: 'center',
      width: TABLE_CELL_WIDTH_2x,
      render: (text) => (
        <Space>
          <TableMoneyText value={text}></TableMoneyText>
        </Space>
      ),
    },
  ]

  useEffect(() => {
    const req = {...searchReq}
    if (req.cooperationUnit !== headerSearchStore.headerSearchReq.cooperationUnit || req.responsibilityProject !== headerSearchStore.headerSearchReq.responsibilityProject) {
      req.cooperationUnit = headerSearchStore.headerSearchReq.cooperationUnit
      req.responsibilityProject = headerSearchStore.headerSearchReq.responsibilityProject
      setSearchReq(req)
    }
    refreshData(req)
  }, [headerSearchStore.headerSearchReq])

  useEffect(() => {
    setRenderColumns(columns.filter(o => showColumn.includes(String(o.key))))
  }, [showColumn])

  useEffect(() => {
    if (incomeExpenditureStatisticsStore.incomeExpenditureStatisticsDatasource.data) {
      const res = handleSIndex(incomeExpenditureStatisticsStore.incomeExpenditureStatisticsDatasource.data.items || [])
      setDataSource(res)
      setSelectedRowKeys(res.map(o => o.id))
      setTotal(0)
      const data = (incomeExpenditureStatisticsStore.incomeExpenditureStatisticsDatasource.data as any).data
      const row = data.find(o => o.status === '对账差额')
      setIncomeExpenditureStatisticsRowViewTitle(row?.forTheCurrentPeriod === 0 ? '余额相符' : '余额不符')
    }
  }, [incomeExpenditureStatisticsStore.incomeExpenditureStatisticsDatasource.data])

  useEffect(() => {
    const objInitValue = {
      budgetAmountThisPeriod: 0,
      settlementAmountThisPeriod: 0,
      grandTotal: 0,
    }
    let obj = summaryTotalObj
    const arr = incomeExpenditureStatisticsStore.incomeExpenditureStatisticsDatasource.data?.items.filter(o => selectedRowKeys.includes(o.id))
    if (arr?.length) {
      obj = arr.reduce((acc, cur) => ({
        budgetAmountThisPeriod: accAdd(acc.budgetAmountThisPeriod, cur.budgetAmountThisPeriod),
        settlementAmountThisPeriod: accAdd(acc.settlementAmountThisPeriod, cur.settlementAmountThisPeriod),
        grandTotal: accAdd(acc.grandTotal, cur.grandTotal),
      }), objInitValue);
      setSummaryTotalObj(obj)
    } else {
      setSummaryTotalObj(objInitValue)
    }
  }, [incomeExpenditureStatisticsStore.incomeExpenditureStatisticsDatasource, selectedRowKeys])

  const handleExpanded = () => {
    if (!expanded) {
      setExpandedRowKeys(dataSource.map((o) => o.id))
    } else {
      setExpandedRowKeys([])
    }
    setExpanded(!expanded)
  }

  const onExpand = (expanded: boolean, record: IncomeExpenditureStatisticsRow) => {
    let expandedRowKeysTemp: string[] = JSON.parse(JSON.stringify(expandedRowKeys))
    if (expanded) {
      expandedRowKeysTemp.push(record.id)
    } else {
      expandedRowKeysTemp = expandedRowKeysTemp.filter(o => o !== record.id)
    }
    setExpandedRowKeys(expandedRowKeysTemp)
    setExpanded(dataSource.every(o => expandedRowKeysTemp.includes(o.id)))
  }

  const columnTitle = <button
    type="button"
    onClick={() => {
      handleExpanded()
    }}
    className={`ant-table-row-expand-icon ${expanded ? '' : "ant-table-row-expand-icon-collapsed"}`}
    aria-label={expanded ? '关闭行' : "展开行"}
    aria-expanded={expanded ? 'true' : "false"}>
  </button>

  const handleSIndex = (datasource: IncomeExpenditureStatisticsRow[], prefix?: string): IncomeExpenditureStatisticsRow[] => {
    const temp: IncomeExpenditureStatisticsRow[] = JSON.parse(JSON.stringify(datasource))
    for (let i = 0; i < temp.length; i++) {
      if (prefix) {
        temp[i].sIndex = prefix + `.${String(i + 1)}`
      } else {
        temp[i].sIndex = String(i + 1)
      }
      if (temp[i].fundingApprovalStatistics.length) {
        temp[i].fundingApprovalStatistics = handleSIndex(temp[i].fundingApprovalStatistics, (prefix || '') + `${prefix ? '.' : ''}${String(i + 1)}`)
      }
    }
    return temp
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let req = {...searchReq};
    req.perPage = pageSize
    req.pageNum = page
    setSearchReq(req)
    refreshData(req)
  }

  const refreshData = (req: IncomeExpenditureStatisticsReq) => {
    setSearchReq(req)
    incomeExpenditureStatisticsStore.getIncomeExpenditureStatistics(req)
  }

  useEffect(() => {
    // 读取存储
    let showColumn: string[] = tableColumnsStore.getItem(location.pathname) || []
    if (!showColumn.length) return
    // 检验存储中的列表是否符合规范
    showColumn = showColumn.filter(o => columns.some(column => column.key === o))
    // 无论有无存储都触发change，保存当前显示的字段
    setShowColumn(showColumn)
  }, []);

  const handleTableColumnsChange = (value: string[]) => {
    setShowColumn(value)
    tableColumnsStore.setItem(location.pathname, value)
  }

  const handleIncomeExpenditureStatisticsRowView = () => {
    setItems([
      {
        key: '责任部门',
        label: '责任部门',
        children: searchReq.responsibilityProject || '-',
      },
      {
        key: '统计区间',
        label: '统计区间',
        children: (searchReq.start && searchReq.end) ? `${searchReq.start} - ${searchReq.end}` : '-',
      },
    ])
    if (incomeExpenditureStatisticsStore.incomeExpenditureStatisticsDatasource.data) {
      setBalanceReconciliationRow((incomeExpenditureStatisticsStore.incomeExpenditureStatisticsDatasource.data as any).data || {})
      setIsModalVisible(true);
    } else {
      message.warning("请等待数据加载！").then()
    }
  }

  /**
   * @description 打印表格
   */
  const handlePrintTable = () => {
    // 数据处理
    setIsModalOpen(true)
  }

  const handleShowColumnReset = () => {
    // 窄屏判断
    if (screenResolutionStore.screenResolution.height < 700 || screenResolutionStore.screenResolution.width < 1400) {
      setShowColumn(narrowShowColumn)
    } else {
      setShowColumn(wideShowColumn)
    }
  }

  const expandedRowRender = (record: IncomeExpenditureStatisticsRow) => {
    return <>
      <div></div>
      <Table
        rowKey={'id'}
        showHeader={false}
        columns={renderColumns}
        dataSource={record.fundingApprovalStatistics}
        size={"small"}
        bordered
        expandable={{
          expandedRowRender,
          expandedRowClassName: () => 'third',
          rowExpandable: (record) => Boolean(record.fundingApprovalStatistics.length),
        }}
        pagination={false}/>
    </>
  };

  const printTable = <Table
    rowKey={"id"}
    className={"margin-top-xss"}
    dataSource={dataSource}
    columns={renderColumns}
    size={"small"}
    bordered
    expandable={{
      expandedRowKeys,
      onExpand,
      expandedRowRender,
      columnTitle,
      indentSize: 0,
      expandedRowClassName: () => 'second',
      rowExpandable: (record) => Boolean(record.fundingApprovalStatistics.length),
    }}
    loading={incomeExpenditureStatisticsStore.incomeExpenditureStatisticsDatasource.loading}
    pagination={{
      showLessItems: true,
      total: total,
      position: ['bottomCenter'],
      pageSize: searchReq.perPage,
      current: searchReq.pageNum,
      showSizeChanger: true,
      showQuickJumper: true,
      showTotal: total => `共${total}条`,
      onChange: handlePageChange,
      hideOnSinglePage: true
    }}/>

  return (
    <div className={"institution"}>
      <HeaderActionCom
        selectedRowKeys={selectedRowKeys}
        handleShowColumnReset={handleShowColumnReset}
        handleIncomeExpenditureStatisticsRowView={handleIncomeExpenditureStatisticsRowView}
        items={[
          <Button
            block
            className={'text-align-left'}
            type={"text"}
            onClick={() => {
              handlePrintTable()
            }}>
            <PrinterOutlined/>
            打印表格
          </Button>,
        ]}
        incomeExpenditureStatisticsRowViewTitle={incomeExpenditureStatisticsRowViewTitle}
        handleTableColumnsChange={handleTableColumnsChange}
        showColumn={showColumn}
        tableColumns={columns}
        refreshData={(req) => refreshData({...searchReq, ...req})}></HeaderActionCom>

      { printTable }

      { /*余额不符的弹窗页面*/ }
      <BalanceReconciliation
        dataSource={balanceReconciliationRow}
        items={items}
        open={isModalVisible}
        onChange={isModalVisible => setIsModalVisible(isModalVisible)}/>

      { /*打印*/ }
      <WindowPrint
        isModalOpen={isModalOpen}
        windowPrintTableNode={printTable}
        handleCancel={() => {
          setIsModalOpen(false)
          setSelectedRowKeys([])
        }}/>

    </div>
  )
})
