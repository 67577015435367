import {makeAutoObservable, runInAction} from "mobx";
import {Page, StateFullData} from "@services/dto/common";
import {MaterialListReq, MaterialListRow} from "@services/dto/materialList";
import {MaterialListService} from "@services/MaterialListService";

export class MaterialListStore {
  materialListDatasource: StateFullData<Page<MaterialListRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getMaterialList(req: MaterialListReq) {
    this.materialListDatasource = {...this.materialListDatasource, loading: true}
    MaterialListService.getMaterialList(req).then(rsp => {
      runInAction(() => this.materialListDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.materialListDatasource = {...this.materialListDatasource, loading: false, err})
    })
  }
}

export const materialListStore = new MaterialListStore();
