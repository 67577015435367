import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {SocialSecurityPaymentReq, SocialSecurityPaymentRow} from "@services/dto/socialSecurityPayment";

export class SocialSecurityPaymentService {
  static async getSocialSecurityPayment(req: SocialSecurityPaymentReq): Promise<Page<SocialSecurityPaymentRow>> {
    return await apiV1.get("/socialSecurityPayment/search", {params: req})
  }

  static async addSocialSecurityPayment(row: SocialSecurityPaymentRow): Promise<ResponseEntity<SocialSecurityPaymentRow>> {
    return await apiV1.post("/socialSecurityPayment/save", {...row})
  }

  static async updateSocialSecurityPayment(row: SocialSecurityPaymentRow): Promise<ResponseEntity<SocialSecurityPaymentRow>> {
    return await apiV1.put("/socialSecurityPayment/update", {...row})
  }

  static async deleteSocialSecurityPayment(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/socialSecurityPayment/delete", {data: ids})
  }

  static async getOne(id: string): Promise<ResponseEntity<SocialSecurityPaymentRow>> {
    return await apiV1.get(`/socialSecurityPayment/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponseEntity<SocialSecurityPaymentRow[]>> {
    return await apiV1.get(`/socialSecurityPayment/getBatch/${ids.join(',')}`)
  }
}
