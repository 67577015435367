import {
  ArchivesWorkerRecruitmentRow,
  CreateWorkerRecruitmentReq,
  UpdateWorkerRecruitmentRow,
  UpdateWorkerRecruitmentUser,
  WorkerRecruitmentReq,
  WorkerRecruitmentRow,
  WorkerRecruitmentUser
} from "@services/dto/workerRecruitment";
import {apiV1} from "@services/apiHelper";
import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {User} from "@services/dto/security";
import {OrganizationalRow} from "@services/dto/organizational";

export class WorkerRecruitmentService {
  static async create(req: CreateWorkerRecruitmentReq): Promise<WorkerRecruitmentRow> {
    return await apiV1.post(`/workerRecruitments`, req);
  }

  static async getWorkerRecruitments(req: WorkerRecruitmentReq):Promise<Page<WorkerRecruitmentRow>> {
    return await apiV1.get('/workerRecruitments', {params: req})
  }

  static async postWorkerRecruitments(req: WorkerRecruitmentReq):Promise<Page<WorkerRecruitmentRow>> {
    return await apiV1.post('/workerRecruitments/post', {...req})
  }

  static async exportWorkerRecruitments(req: WorkerRecruitmentReq):Promise<string> {
    return await apiV1.post('/workerRecruitments/export', {...req})
  }

  static async getWorkerRecruitmentById(id: string):Promise<WorkerRecruitmentRow> {
    return await apiV1.get(`/workerRecruitments/${id}`)
  }

  static async updateWorkerRecruitment(id: string, wr: UpdateWorkerRecruitmentRow):Promise<WorkerRecruitmentRow> {
    return await apiV1.put(`/workerRecruitments/${id}`, {...wr})
  }

  static async deleteWorkerRecruitment(id: string):Promise<void> {
    return await apiV1.delete(`/workerRecruitments/${id}`)
  }

  static async deleteBatchWorkerRecruitment(ids: string[]):Promise<void> {
    return await apiV1.delete("/workerRecruitments/deleteBatch", {data: {ids}})
  }

  static async getBatch(ids: string[]): Promise<OrganizationalRow[]> {
    return await apiV1.get(`/user/getBatch/${ids.join(',')}`);
  }

  static async getUserById(id: string): Promise<User> {
    return await apiV1.get(`/users/${id}`);
  }

  static async exitWorkerRecruitment(id: string, remark: string): Promise<ResponseEntity<string>> {
    return await apiV1.put(`/workerRecruitments/exit/${id}`, {remark})
  }

  static async departureRejectedEntry(cid: string[], status: string): Promise<void> {
    return await apiV1.post("/departureRejectedEntry", {cid, status})
  }

  static async workerRecruitmentsHired(row: ArchivesWorkerRecruitmentRow): Promise<ResponseEntity<ArchivesWorkerRecruitmentRow>> {
    return await apiV1.post("/workerRecruitments/hired", {...row})
  }

  static async workerRecruitmentsUser(): Promise<ResponsesEntity<WorkerRecruitmentUser>> {
    return await apiV1.get("/workerRecruitments/user")
  }

  static async updateUser(id: string, recruitmentUser: UpdateWorkerRecruitmentUser) {
    return await apiV1.post(`/workerRecruitments/updateUser/${id}`, {...recruitmentUser})
  }
}
