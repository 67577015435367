import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {DatePicker} from "antd";
import dayjs from "dayjs";
import {StaffLoanReq} from "@services/dto/staffLoan";
import {SelectDict} from "@components/SelectDict";
import {CustomInputNumber} from "@components/CustomInputNumber";
import {SelectBankAccount} from "@components/SelectBankAccount";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {Any} from "@common/types";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {SelectFundApproval} from "@components/SelectFundApproval";
import {StaffLoanService} from "@services/StaffLoanService";
import {CustomAuthHeaderAction, PropsType as ParentPropsType} from "@components/CustomAuthHeaderAction";
import {SelectProcessStatus} from "@components/SelectProcessStatus";
import {SelectCostApplication} from "@components/SelectCostApplication";

export interface PropsType extends ParentPropsType {
  updateCount?: number, // 更新渲染标志
  refreshData?: (req: StaffLoanReq) => void,
  setConfirmLoading?: (loading: boolean) => void
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<StaffLoanReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: StaffLoanReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleElDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, [start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.elDate1 = start
    req.elDate2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleElPlannedReturnDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, [start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.elPlannedReturnDate1 = start
    req.elPlannedReturnDate2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleElActualReturnDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, [start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.elActualReturnDate1 = start
    req.elActualReturnDate2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonResponsibleChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleElStatusChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.elStatus = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleElTypeChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.elType = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleElAmountChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.elAmount1 = start
    req.elAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleElPaymentNumberChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.elPaymentNumber = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleElReceiptNumberChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.elReceiptNumber = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleEmployeeFeeApplicationIdChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.employeeFeeApplicationId = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleFundingApprovalIdChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.fundingApprovalId = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleOrganizersUserIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.organizersUserId = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    if (props.setConfirmLoading) {
      props.setConfirmLoading(true);
    }
    StaffLoanService.batchRefresh().then(() => {
      const req = {...searchReq}
      req.pageNum = 1
      req.perPage = 10
      req.keyword = undefined
      req.cooperationUnit = undefined
      req.responsibilityProject = undefined

      req.employeeFeeApplicationId = undefined
      req.fundingApprovalId = undefined
      req.elStatus = undefined
      req.elType = undefined
      req.elDate1 = undefined
      req.elDate2 = undefined
      req.elPlannedReturnDate1 = undefined
      req.elPlannedReturnDate2 = undefined
      req.elActualReturnDate1 = undefined
      req.elActualReturnDate2 = undefined
      req.elAmount1 = undefined
      req.elAmount2 = undefined
      req.personResponsible = undefined
      req.personResponsibleName = undefined
      req.organizersUserId = undefined // 主办人员
      req.organizersUserName = undefined // 主办人员名
      req.elPaymentNumber = undefined
      req.elReceiptNumber = undefined
      req.isTaApplicationForm = undefined
      req.elFile = undefined

      setSearchReq(req)
      refreshData(req)
    }).finally(() => {
      if (props.setConfirmLoading) {
        props.setConfirmLoading(false);
      }
    })
  }

  return (
    <>
      <CustomAuthHeaderAction
        size={props.size}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <DatePicker.RangePicker
            allowClear
            value={searchReq?.elDate1 ? [dayjs(searchReq?.elDate1), dayjs(searchReq?.elDate2)] : [undefined, undefined]}
            placeholder={['制单开始日期', '制单结束日期']}
            onChange={handleElDateChange}
            className={"width-100-percentage"}/>,
          <SelectDict
            updateCount={props.updateCount}
            onChange={handleElTypeChange}
            allowClear
            value={searchReq?.elType}
            className={'width-100-percentage'}
            code={'el_type'}
            placeholder={'请输入借款类别'}/>,
          <CustomInputNumber
            value={[searchReq?.elAmount1, searchReq?.elAmount2]}
            placeholder={['借款最小金额', '借款最大金额']}
            className={'width-100-percentage'}
            onSearch={handleElAmountChange}/>,
          <SelectBankAccount
            value={searchReq?.elReceiptNumber}
            onChange={handleElReceiptNumberChange}
            allowClear
            className={'width-100-percentage'}
            placeholder={'请输入付款账户'}/>,
          <SelectBankAccount
            value={searchReq?.elPaymentNumber}
            onChange={handleElPaymentNumberChange}
            allowClear
            className={'width-100-percentage'}
            placeholder={'请输入收款账户'}/>,
          <SelectCostApplication
            className={'width-100-percentage'}
            value={searchReq?.employeeFeeApplicationId}
            onChange={handleEmployeeFeeApplicationIdChange}
            placeholder={'请选择关联费用申请'}
            allowClear/>,
          <SelectFundApproval
            className={'width-100-percentage'}
            value={searchReq?.fundingApprovalId}
            onChange={handleFundingApprovalIdChange}
            placeholder={'请选择关联资金审批'}
            allowClear/>,
          <DatePicker.RangePicker
            allowClear
            value={searchReq?.elPlannedReturnDate1 ? [dayjs(searchReq?.elPlannedReturnDate1), dayjs(searchReq?.elPlannedReturnDate2)] : [undefined, undefined]}
            placeholder={['计划归还日期', '计划归还日期']}
            onChange={handleElPlannedReturnDateChange}
            className={"width-100-percentage"}/>,
          <DatePicker.RangePicker
            allowClear
            value={searchReq?.elActualReturnDate1 ? [dayjs(searchReq?.elActualReturnDate1), dayjs(searchReq?.elActualReturnDate2)] : [undefined, undefined]}
            placeholder={['实际归还日期', '实际归还日期']}
            onChange={handleElActualReturnDateChange}
            className={"width-100-percentage"}/>,
          <SelectProject
            value={searchReq?.responsibilityProject}
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            statisticsItemsReq={{
              table: 'employeeLoan',
              field: 'responsibilityProject',
            }}
            onChange={handleResponsibilityProjectChange}
            allowClear
            fieldNames={{value: 'id', label: 'name'}}/>,
          <SelectCooperativeUnit
            value={searchReq?.cooperationUnit}
            onChange={handleCooperationUnitChange}
            allowClear
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectPersonCharge
            value={searchReq?.personResponsible}
            updateCount={props.updateCount}
            onChange={handlePersonResponsibleChange}
            className={"width-100-percentage"}
            placeholder={"请选择办理人员"}
            allowClear/>,
          <SelectPersonCharge
            onChange={handleOrganizersUserIdChange}
            className={"width-100-percentage"}
            placeholder={"请选择主办人员"}
            value={searchReq?.organizersUserId}
            allowClear/>,
          <SelectProcessStatus
            onChange={handleElStatusChange}
            allowClear
            value={searchReq?.elStatus}
            placeholder={'请选择办理进度'}
            className={"width-100-percentage"}/>,
        ] : []}/>
    </>
  )
}))
