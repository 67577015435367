import {Page, StateFullData} from "@services/dto/common";
import {PageParam, ProjectRow} from "@services/dto/project";
import {makeAutoObservable, runInAction} from "mobx";
import {ProjectService} from "@services/ProjectService";

export class ProjectStore {
  projectDatasource: StateFullData<Page<ProjectRow>> = {}
  projectSelectSource: StateFullData<Page<ProjectRow>> = {}
  projectRow?: ProjectRow
  projectOption?: string
  projectDetail: StateFullData<ProjectRow> = {}
  projectDetails: StateFullData<ProjectRow[]> = {}
  projectReq: PageParam = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateProjectRow(row: ProjectRow) {
    this.projectRow = {...row}
  }

  updateProjectOption(option: string) {
    this.projectOption = option
  }

  getCompanyProject(req: PageParam) {
    this.projectDatasource = {...this.projectDatasource, loading: true}
    this.projectReq = {...this.projectReq, ...req}
    ProjectService.getCompanyProject(req).then(rsp => {
      runInAction(() => {
        this.projectDatasource = {data: rsp, loading: false}
      })
    }).catch(e => {
      runInAction(() => this.projectDatasource = {...this.projectDatasource, loading: false, err: e})
    })
  }

  getSelectCompanyProject(req: PageParam) {
    this.projectSelectSource = {...this.projectSelectSource, loading: true}
    this.projectReq = {...this.projectReq, ...req}
    ProjectService.getCompanyProject(req).then(rsp => {
      runInAction(() => {
        this.projectSelectSource = {data: rsp, loading: false}
      })
    }).catch(e => {
      runInAction(() => this.projectSelectSource = {...this.projectSelectSource, loading: false, err: e})
    })
  }

  getOne(id: string) {
    this.projectDetail = {...this.projectDetail, loading: true}
    ProjectService.getOne(id).then(rsp => {
      runInAction(() => this.projectDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.projectDetail = {...this.projectDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.projectDetails = {...this.projectDetails, loading: true}
    ProjectService.getBatch(ids).then(rsp => {
      runInAction(() => this.projectDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.projectDetails = {...this.projectDetails, loading: false, err})
    })
  }

  getBatchByName(names: string[]) {
    this.projectDetails = {...this.projectDetails, loading: true}
    ProjectService.getBatchByName(names).then(rsp => {
      runInAction(() => this.projectDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.projectDetails = {...this.projectDetails, loading: false, err})
    })
  }
}

export const projectStore = new ProjectStore();
