import React, { useEffect } from "react";
import {Col, DatePicker, Form, Input, InputNumber, Row} from "antd";
import { FormInstance } from "antd/es";
import { observer } from "mobx-react";
import {CommercialContractRow} from "@services/dto/commercialContract";
import {SelectFiling} from "@components/SelectFiling";

export interface PropsType {
  row: CommercialContractRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    const commercialContractRow = { ...props.row }
    form.setFieldsValue(commercialContractRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="核算对象"
            name="checkObject"
            rules={[{ required: true, message: '请输入核算对象!' }]}>
            <Input placeholder="请输入核算对象" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="存档批号"
            name="filing"
            rules={[{ required: true, message: '请输入存档批号!' }]}>
            <SelectFiling placeholder={"请输入存档批号"} allowClear />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同编号"
            name="contractNumber"
            rules={[{ required: true, message: '请输入合同编号!' }]}>
            <Input placeholder="请输入合同编号" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同名称"
            name="contractName"
            rules={[{ required: true, message: '请选择合同名称!' }]}>
            <Input placeholder="请选择合同名称" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同摘要"
            name="contractDigest"
            rules={[{ required: true, message: '请输入合同摘要!' }]}>
            <Input placeholder="请输入合同摘要" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同签订日期"
            name="contractSignDate"
            rules={[{ required: true, message: '请输入合同签订日期!' }]}>
            <DatePicker placeholder={"请输入合同签订日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同金额"
            name="contractAmount"
            rules={[{ required: true, message: '请输入合同金额!' }]}>
            <InputNumber min={0} precision={2} placeholder="请输入合同金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="结算申请金额"
            name="settlementApplicationAmount"
            rules={[{ required: true, message: '请输入结算申请金额!' }]}>
            <InputNumber min={0} precision={2} placeholder="请输入结算申请金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="结算实现金额"
            name="settlementRealizedAmount"
            rules={[{ required: true, message: '请输入结算实现金额!' }]}>
            <InputNumber min={0} precision={2} placeholder="请输入结算实现金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="余额"
            name="balance"
            rules={[{ required: true, message: '请输入余额!' }]}>
            <InputNumber min={0} precision={2} placeholder="请输入余额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合同关闭日期"
            name="contractCloseDate"
            rules={[{ required: true, message: '请输入合同关闭日期!' }]}>
            <DatePicker placeholder={"请输入合同关闭日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="关联项目编号"
            name="projectId"
            rules={[{ required: true, message: '请输入关联项目编号!' }]}>
            <Input placeholder="请输入关联项目编号" />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
