import React, { useEffect } from "react";
import {Col, DatePicker, Form, Input, Row} from "antd";
import { FormInstance } from "antd/es";
import { observer } from "mobx-react";
import {SelectProject} from "@components/SelectProject";
import dayjs from "dayjs";
import {ProjectLogRow} from "@services/dto/projectLog";
import {CustomUpload, normFile} from "@components/CustomUpload";
import {getUploadStringToArray} from "@common/utils";

export interface PropsType {
  row: ProjectLogRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    const projectLogRow = { ...props.row }
    if (projectLogRow.id) {
      projectLogRow.time = dayjs(projectLogRow.time)
      if (typeof projectLogRow.url === 'string') {
        projectLogRow.url = getUploadStringToArray(projectLogRow.url);
      }
    }
    form.setFieldsValue(projectLogRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ status: 1, isVirtualUser: 0 }}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="日期"
            name="time"
            rules={[{ required: true, message: '请选择日期!' }]}>
            <DatePicker placeholder={"请选择日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="项目"
            name="projectId"
            rules={[{ required: true, message: '请选择项目!' }]}>
            <SelectProject placeholder={"请选择项目"} allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="今日工作完成情况"
            name="workCompleted"
            rules={[{ required: true, message: '请输入今日工作完成情况!' }]}>
            <Input placeholder="请输入今日工作完成情况" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="明日计划完成工作"
            name="tomorrowPlan"
            rules={[{ required: false, message: '请输入明日计划完成工作!' }]}>
            <Input placeholder="请输入明日计划完成工作" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="需协调工作"
            name="needToCoordinateWork"
            rules={[{ required: false, message: '请输入需协调工作!' }]}>
            <Input placeholder="请输入需协调工作" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="待办事项"
            name="todo"
            rules={[{ required: false, message: '请输入待办事项!' }]}>
            <Input.TextArea placeholder="请输入待办事项" autoSize={{ minRows: 3, maxRows: 9 }}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="附件"
            name="url"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            extra={"支持文件(pdf,doc,docx),10M以内,最大上传1个文件"}
            rules={[{ required: false, message: '请上传附件!' }]}>
            <CustomUpload updateSize={20}
              maxCount={1}
              uploadType={"application"}
              accept={"application/*"}
              listType={"picture"}
              multiple={false} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
