import {
  BasicSalaryRow,
  SearchBasicSalaryRowReq
} from "@services/dto/basicSalary";
import { apiV1 } from "@services/apiHelper";
import { Page, ResponseEntity } from "@services/dto/common";

export class BasicSalaryService {
  static async search(req: SearchBasicSalaryRowReq): Promise<Page<BasicSalaryRow>> {
    return apiV1.get("/basicSalary", { params: req })
  }
  static async add(req: BasicSalaryRow): Promise<ResponseEntity<BasicSalaryRow>> {
    return apiV1.post("/basicSalary", { ...req })
  }
  static async update(req: BasicSalaryRow): Promise<ResponseEntity<BasicSalaryRow>> {
    return apiV1.put("/basicSalary", { ...req })
  }
  static async delete(ids: string[]): Promise<Page<BasicSalaryRow>> {
    return await apiV1.delete("/basicSalary", { data: ids })
  }
}
