import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {ProjectStructureRow, ProjectStructureReq} from "@services/dto/projectStructure";

export class ProjectStructureService {
  static async getProjectStructure(req: ProjectStructureReq): Promise<Page<ProjectStructureRow>> {
    return await apiV1.get("/projectStructure/search", {params: req})
  }

  static async addProjectStructure(row: ProjectStructureRow): Promise<ResponseEntity<ProjectStructureRow>> {
    return await apiV1.post("/projectStructure/save", {...row})
  }

  static async updateProjectStructure(row: ProjectStructureRow): Promise<ResponseEntity<ProjectStructureRow>> {
    return await apiV1.put("/projectStructure/update", {...row})
  }

  static async deleteProjectStructure(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/projectStructure/delete", {data: ids})
  }

  static async getBatch(ids: string[]): Promise<ResponseEntity<ProjectStructureRow[]>> {
    return await apiV1.get(`/projectStructure/getBatch/${ids.join(',')}`);
  }

  static async getDetailById(id: string): Promise<ResponseEntity<ProjectStructureRow>> {
    return await apiV1.get(`/projectStructure/getOne/${id}`)
  }
}
