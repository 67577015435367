import React, {useEffect, useState} from "react";
import {Button, Col, DatePicker, Divider, Form, Input, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {SelectProject} from "@components/SelectProject";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {ProjectCollaborationRow} from "@services/dto/projectCollaboration";
import {SelectDict} from "@components/SelectDict";
import {PlusOutlined} from "@ant-design/icons";
import {SelectBankAccount} from "@components/SelectBankAccount";
import {BankAccountRow} from "@services/dto/bankAccount";
import {ProjectRow} from "@services/dto/project";
import {OrganizationalRow} from "@services/dto/organizational";
import dayjs from "dayjs";
import {SelectFiling} from "@components/SelectFiling";
import {ONFILE} from "@/config";

export interface PropsType {
  row: ProjectCollaborationRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  const [responsibilityProject, setResponsibilityProject] = useState<string>()
  const [cooperationUnit, setCooperationUnit] = useState<string | string[]>()

  useEffect(() => {
    form.resetFields()
    const projectCollaborationRow = {...props.row}
    if (projectCollaborationRow.id) {
      if (typeof projectCollaborationRow.cuDate === 'string' && projectCollaborationRow.cuDate !== '') {
        projectCollaborationRow.cuDate = dayjs(projectCollaborationRow.cuDate)
      }
      setResponsibilityProject(projectCollaborationRow.responsibilityProject)
      setCooperationUnit(projectCollaborationRow.cooperationUnit)
    }
    form.setFieldsValue(projectCollaborationRow);
  }, [props.row.id])

  const handleBaNickChange = (value: string, options: BankAccountRow[], index: number) => {
    const bankAccounts = form.getFieldValue("bankAccounts")
    bankAccounts[index] = options.find(item => item.id === value)
    form.setFieldsValue({bankAccounts})
  }

  /**
   * @desc 责任部门修改 code拿到并赋值
   * @param value
   * @param options
   */
  const handleProjectChange = (value: any, options: ProjectRow[]) => {
    setResponsibilityProject(value)
    const row = options.find(o => value === o.name)
    form.setFieldsValue({code: row?.code})
  }

  const handlePersonResponsibleChange = (value: string, selectedOptions: OrganizationalRow[]) => {
    const row = selectedOptions.find(o => value === o.id)
    form.setFieldsValue({personResponsibleName: row.name})
  }

  const handleCuNameChange = (e) => {
    setCooperationUnit(e.target.value)
    form.setFieldValue("cooperationUnit", e.target.value)
  }

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      disabled={props.isView}
      initialValues={{cuStatus: '草稿'}}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
          <Input />
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="personResponsibleName">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="制单日期"
            name="cuDate"
            rules={[{required: true, message: '请输入制单日期!'}]}>
            <DatePicker
              placeholder={"请输入制单日期"}
              className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="cooperationUnitCode"
            rules={[{ required: false, message: '请输入制单编号!' }]}>
            <Input disabled placeholder="请输入制单编号" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="类别"
            name="cooperativeUnitCategory"
            rules={[{ required: false, message: '请选择类别!' }]}>
            <SelectDict code={'cooperative_unit_category'} placeholder={'请选择类别'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="名称"
            name="cuName"
            rules={[{ required: false, message: '请输入名称!' }]}>
            <Input
              onChange={handleCuNameChange}
              placeholder={"请输入名称"}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="简称"
            name="cuNick"
            rules={[{ required: false, message: '请输入简称!' }]}>
            <Input
              onChange={handleCuNameChange}
              placeholder={"请输入简称"}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="信用代码"
            name="unifiedSocialCreditIdentifier"
            rules={[{required: false, message: '请输入统一社会信用代码!'}]}>
            <Input placeholder="请输入统一社会信用代码"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="联系地址"
            name="address"
            rules={[{required: false, message: '请输入联系地址!'}]}>
            <Input placeholder="请输入联系地址"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="联系电话"
            name="phone"
            rules={[{required: false, message: '请输入联系电话!'}]}>
            <Input placeholder="请输入联系电话"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="联系人"
            name="cooperationContent"
            rules={[{required: false, message: '请输入联系人!'}]}>
            <Input placeholder="请输入联系人" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Form.List name={"bankAccounts"}>
          { (fields, {add, remove}) => (
            <>
              <Divider style={{margin: '0'}}/>
              { fields.map((field, index) => (
                <Row key={field.key}>
                  <Col span={24}>
                    <Form.Item {...field} hidden name="cooperationUnitId">
                      <Input/>
                    </Form.Item>
                  </Col>
                  <Col span={24} className={"margin-bottom-xs"}>
                    <Form.Item
                      label="账户简称"
                      name={[field.name, 'id']}
                      rules={[{required: true, message: '请选择账户简称!'}]}>
                      <SelectBankAccount
                        className={'width-100-percentage'}
                        allowClear
                        isShowAction
                        isSimpleAction
                        handleButtonClick={() => remove(index)}
                        onChange={(value, options) => handleBaNickChange(value, options, index)}
                        placeholder={'请选择账户简称'}/>
                    </Form.Item>
                  </Col>
                  <Col span={24} className={"margin-bottom-xs"}>
                    <Form.Item
                      label="账户名称"
                      name={[field.name, 'baName']}
                      rules={[{required: false, message: '请输入账户名称!'}]}>
                      <Input placeholder="请输入账户名称" disabled/>
                    </Form.Item>
                  </Col>
                  <Col span={24} className={"margin-bottom-xs"}>
                    <Form.Item
                      label="银行账号"
                      name={[field.name, 'baAccount']}
                      rules={[{required: false, message: '请输入银行账号!'}]}>
                      <Input placeholder="请输入银行账号" disabled/>
                    </Form.Item>
                  </Col>
                  <Col span={24} className={"margin-bottom-xs"}>
                    <Form.Item
                      label="开户银行"
                      name={[field.name, 'baBlank']}
                      rules={[{required: false, message: '请输入开户银行!'}]}>
                      <Input placeholder="请输入开户银行" disabled/>
                    </Form.Item>
                  </Col>
                  <Divider style={{margin: '0'}}/>
                </Row>
              )) }
              <Col span={18} offset={3}>
                <Form.Item wrapperCol={{span: 20, offset: 3}} className={"text-align-center"}>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                    新增银行账户
                  </Button>
                </Form.Item>
              </Col>
              <Divider style={{margin: '0'}}/>
            </>
          ) }
        </Form.List>
        <Col span={24}>
          <Form.Item
            label="责任人"
            name="personResponsible"
            rules={[{ required: true, message: '请选择负责人!' }]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              onChange={handlePersonResponsibleChange}
              placeholder={"请选择负责人"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{ required: true, message: '请选择责任部门!' }]}>
            <SelectProject
              placeholder={"请选择责任部门"}
              onChange={handleProjectChange}
              allowClear
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{ required: false, message: '请输入协作单位!' }]}>
            <Input disabled placeholder="请输入协作单位" />
          </Form.Item>
        </Col>
        {
          props.row.id && <Col span={24}>
            <Form.Item
              label="存档批号"
              name="cuFile"
              rules={[{required: false, message: '请选择存档批号!'}]}>
              <SelectFiling
                responsibilityProject={responsibilityProject}
                isHideClose
                isHidePlus
                cooperationUnit={cooperationUnit}
                filingMode={"multiple"}
                inputDisabled
                placeholder={"请选择存档批号"}
                allowClear/>
            </Form.Item>
          </Col>
        }
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="cuStatus"
            rules={[{required: false, message: '请输入办理进度!'}]}>
            <SelectDict
              className={'width-100-percentage'}
              allowClear
              code={'common_type3'}
              disabled={props.row.cuStatus === ONFILE}
              placeholder={'请选择办理进度'}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
