import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Button, Modal, Space, Table, UploadFile} from "antd";
import {allStores} from "@/stores";
import {ColumnsType} from "antd/es/table";
import {FileRow, TodoRow} from "@services/dto/workerRecruitment";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {CustomUpload, previewFile} from "@components/CustomUpload";
import {UploadChangeParam} from "antd/es/upload/interface";
import {getUploadArrayToString} from "@common/utils";
import dayjs from "dayjs";
import {DATE_SECONDS_FMT, DEFAULT_MODAL_WIDTH} from "@/config";
import {SelectDict} from "@components/SelectDict";

export interface PropsType {
    type: string
}

export const EnrollmentFile: FC<PropsType> = observer((props: PropsType) => {
  const {wrStore} = allStores;
  const {wr: stateWr} = wrStore
  const wr = stateWr.data!;
  const [datasource, setDatasource] = useState<TodoRow[]>([])
  const [visible, setVisible] = useState<boolean>(false)
  const [files, setFiles] = useState<FileRow[]>()
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [subscript, setSubscript] = useState<number>()
  const [typeVisible, setTypeVisible] = useState<boolean>(false)
  const [type, setType] = useState<string>("")

  useEffect(() => {
    if (wr) {
      setDatasource(wr.hireTodo);
    } else {
      setDatasource([])
    }
  }, [wr, props.type])

  const columns: ColumnsType<TodoRow> = [
    {
      title: '资料名称',
      align: 'center',
      dataIndex: ['title'],
    },
    {
      title: '附件',
      align: 'center',
      dataIndex: ['files'],
      render: (text, record, index) => (
        <Space>
          {
            record.files && record.files.length > 0 &&
              <Button disabled={getStatusText(index) !== '通过'} type={"link"} onClick={() => handleViewFile(index, record.files)}>查看</Button>
          }
        </Space>
      )
    },
    {
      title: '办理进度',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          { getStatusText(index) }
        </Space>
      )
    },
    {
      title: '存储位置',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          { getTypeText(index) }
        </Space>
      )
    },
    {
      title: '操作',
      align: "center",
      render: (_, record, index) => (
        <Space size="middle">
          {
            record.files && record.files.length > 0 && <>
              <Button disabled={getStatusText(index) !== '通过'} shape="round" className={"edit-btn"}
                size={"middle"} icon={<EditOutlined/>} onClick={() => handleViewFile(index, record.files)}>修改</Button>
              <Button disabled={getStatusText(index) !== '通过'} shape="round" className={"delete-btn"}
                size={"middle"} icon={<DeleteOutlined/>} onClick={() => handleDelete(index, record.files)}>删除</Button>
            </>
          }
          {
            record.action === 'select' && <>
              <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined/>} onClick={() => handleEditType(index)}>修改</Button>
            </>
          }
        </Space>
      ),
    }
  ];

  const getStatusText = (index: number) => {
    return wr && wr.hireTodoChecked[index].isToDo ? "通过" : "不通过"
  }

  const getTypeText = (index: number) => {
    if (wr) {
      return wr.hireTodoChecked[index]?.type
    }
    return ""
  }

  const handleViewFile = (index: number, files?: FileRow[]) => {
    let fileRows = [...wr.hireTodoChecked[index].files || []]
    files?.forEach((item, index) => {
      fileRows[index] = {
        ...fileRows[index],
        name: item.name,
        title: fileRows[index] ? fileRows[index].fileUrl.substring(fileRows[index].fileUrl.lastIndexOf("-") + 1) : ""
      }
    })
    setSubscript(index)
    setFiles(fileRows)
    setVisible(true)
  }

  const handleDelete = (index: number, files?: FileRow[]) => {
    Modal.confirm({
      title: `是否删除${files?.map(item => item.name).join(",")}文件？`,
      okText: "确定",
      cancelText: "取消",
      onOk() {
        const hireTodoChecked = [...wr.hireTodoChecked]
        let fileRows = [...hireTodoChecked[index].files || []]
        files?.forEach((item, index) => {
          fileRows[index] = {
            ...fileRows[index],
            name: item.name,
            title: "",
            fileUrl: "",
            createdAt: ""
          }
        })
        hireTodoChecked[index].files = fileRows
        setSubscript(index)
        setFiles(fileRows)
        wrStore.updateHireTodo(hireTodoChecked)
      },
    })
  }

  const handleChange = (info: UploadChangeParam, i: number, record: FileRow) => {
    setFileList([...info.fileList])
    if (info.file.status === "done") {
      let checked = [...wr.hireTodoChecked || []]
      if (subscript || subscript === 0) {
        const fileUrl = getUploadArrayToString(info.fileList)
        checked[subscript].files![i] = {
          ...record,
          createdAt: dayjs().format(DATE_SECONDS_FMT),
          title: fileUrl ? fileUrl.substring(fileUrl.lastIndexOf("-") + 1) : "",
          fileUrl: fileUrl
        }
        setFiles(checked[subscript].files)
      }
      setFileList([]);
      wrStore.updateHireTodo(checked)
    }
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const handleEditType = (index: number) => {
    setSubscript(index)
    setType(wr.hireTodoChecked[index].type || "")
    setTypeVisible(true)
  }

  const handleChangeType = (value: string) => {
    let checked = [...wr.hireTodoChecked || []]
    if (subscript || subscript === 0) {
      checked[subscript].type = value;
    }
    setType(value)
    wrStore.updateHireTodo(checked)
  }

  return (
    <>
      <Table
        rowKey={'id'}
        columns={columns}
        dataSource={datasource}
        size={"middle"}
        pagination={false}/>

      <Modal
        open={typeVisible}
        title={<div className="text-align-center">存储位置</div>}
        width={DEFAULT_MODAL_WIDTH}
        onCancel={() => {setTypeVisible(false)}}
        footer={<Button onClick={() => {setTypeVisible(false)}}>取消</Button>}>
        <SelectDict
          value={type}
          code={'dict_tripartite_agreement'}
          placeholder={'请选择存储位置'}
          className={'margin-left-xs width-100-percentage'}
          onChange={handleChangeType}/>
      </Modal>

      <Modal
        open={visible}
        title={<div className="text-align-center">附件</div>}
        width={DEFAULT_MODAL_WIDTH}
        onCancel={handleCancel}
        footer={<Button onClick={handleCancel}>取消</Button>}>
        <Table rowKey={"name"} dataSource={files} size={"middle"} pagination={false}>
          <Table.Column
            title="文件名称"
            dataIndex="title"
            key="title"
            align={"center"}
            render={(_, record: FileRow) => (
              <Space>
                { record.title || "无上传文件" }
              </Space>
            )}/>
          <Table.Column title="文件属性" dataIndex="name" key="name" align={"center"}/>
          <Table.Column title="上传时间" dataIndex="createdAt" key="createdAt" align={"center"}/>
          <Table.Column
            title="操作"
            key="action"
            align={"center"}
            render={(_, record: FileRow, index) => (
              <Space size="middle">
                {
                  record.fileUrl && <Button type={"link"} onClick={() => {previewFile(record.fileUrl)}}>查看</Button>
                }
                <CustomUpload
                  updateSize={20}
                  maxCount={1}
                  fileList={fileList}
                  onChange={(info) => handleChange(info, index, record)}
                  uploadType={"application"}
                  accept={"application/*"}
                  listType={"text"}
                  multiple={false}>
                  <Button type={"link"}>上传{ record.name }</Button>
                </CustomUpload>
              </Space>
            )}
          />
        </Table>
      </Modal>
    </>
  )
})
