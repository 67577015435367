import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {SettleApplyReq, SettleApplyRow} from "@services/dto/settleApply";

export class SettleApplyService {
  static async getSettleApply(req: SettleApplyReq): Promise<Page<SettleApplyRow>> {
    return await apiV1.get("/", {params: req})
  }

  static async addSettleApply(row: SettleApplyRow): Promise<ResponseEntity<SettleApplyRow>> {
    return await apiV1.post("/", {...row})
  }

  static async updateSettleApply(row: SettleApplyRow): Promise<ResponseEntity<SettleApplyRow>> {
    return await apiV1.put("/", {...row})
  }

  static async deleteSettleApply(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/", {data: ids})
  }
}
