import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {TaxPlanReq, TaxPlanRow} from "@services/dto/taxPlan";
import {TaxPlanService} from "@services/TaxPlanService";
import {BLACK_LIST} from "@/config";
import {OrganizationalService} from "@services/OrganizationalService";
import {FilingService} from "@services/FilingService";
import {formatMoney} from "@common/utils";

export class TaxPlanStore {
  taxPlanDatasource: StateFullData<Page<TaxPlanRow>> = {}
  taxPlanSelectDatasource: StateFullData<Page<TaxPlanRow>> = {}
  taxPlanRow?: TaxPlanRow
  taxPlanOption?: string
  taxPlanDetail: StateFullData<ResponseEntity<TaxPlanRow>> = {}
  taxPlanDetails: StateFullData<ResponsesEntity<TaxPlanRow>> = {}
  taxPlanReq: TaxPlanReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateTaxPlanRow(row: TaxPlanRow) {
    this.taxPlanRow = {...row}
  }

  updateTaxPlanOption(option: string) {
    this.taxPlanOption = option
  }

  getTaxPlan(req: TaxPlanReq) {
    this.taxPlanDatasource = {...this.taxPlanDatasource, loading: true}
    this.taxPlanReq = {...this.taxPlanReq, ...req}
    TaxPlanService.getTaxPlan(req).then(rsp => {
      runInAction(() => this.taxPlanDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.taxPlanDatasource = {...this.taxPlanDatasource, loading: false, err})
    })
  }

  getSelectTaxPlan(req: TaxPlanReq) {
    this.taxPlanSelectDatasource = {...this.taxPlanSelectDatasource, loading: true}
    TaxPlanService.getTaxPlan(req).then(rsp => {
      runInAction(() => this.taxPlanSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.taxPlanSelectDatasource = {...this.taxPlanSelectDatasource, loading: false, err})
    })
  }

  getDetailById(id: string) {
    this.taxPlanDetail = {...this.taxPlanDetail, loading: true}
    TaxPlanService.getDetailById(id).then(rsp => {
      runInAction(() => this.taxPlanDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.taxPlanDetail = {...this.taxPlanDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.taxPlanDetails = {...this.taxPlanDetails, loading: true}
    TaxPlanService.getBatch(ids).then(rsp => {
      runInAction(() => this.taxPlanDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.taxPlanDetails = {...this.taxPlanDetails, loading: false, err})
    })
  }

  async getPrintList(record: TaxPlanRow): Promise<LabelValue[]> {

    const row = this.taxPlanDatasource.data?.items.find(o => o.id === record.id)

    let personResponsible = ''
    if (!BLACK_LIST.includes(row.personResponsible)) {
      const [organizationalRow] = await OrganizationalService.getBatch([row.personResponsible])
      personResponsible = organizationalRow.name
    }

    // 存档批号
    let efaFileArchiveRow = undefined
    if (row.efaFileArchive && !BLACK_LIST.includes(row.efaFileArchive)) {
      const efaFileArchiveRsp = await FilingService.getBatch([row.efaFileArchive])
      if (efaFileArchiveRsp && efaFileArchiveRsp.code !== 200) {
        return
      }
      efaFileArchiveRow = efaFileArchiveRsp.data[0]
    }

    return [
      {label: '制单日期', value: String(row.efaAccountingObject)},
      {label: '制单编号', value: row.efaApplyCode},
      {label: '税费类别', value: row.efaPayType},
      {label: '税费说明', value: row.efaTitle},
      {label: '关联立项', value: row.efaRelatedProjectApproval},
      {label: '计划金额', value: formatMoney(row.efaApplyAmount) + '元'},
      {label: '存档批号', value: efaFileArchiveRow?.faBatchNumber},
      {label: '责任人', value: personResponsible},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '审批意见', value: '', size: 'large'}
    ]
  }
}

export const taxPlanStore = new TaxPlanStore();
