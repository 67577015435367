import {
  SalaryCalculateReq,
  SalaryRow,
  SearchSalaryReq,
} from "@services/dto/salary";
import {apiV1} from "@services/apiHelper";
import {Page, ResponseEntity} from "@services/dto/common";

export class SalaryService {
  static async search(req: SearchSalaryReq): Promise<Page<SalaryRow>> {
    return apiV1.get("/totalWagesTable", {params: req})
  }

  static async add(req: SalaryRow): Promise<ResponseEntity<SalaryRow>> {
    return apiV1.post("/totalWagesTable", {...req})
  }

  static async calculate(req: SalaryCalculateReq): Promise<ResponseEntity<SalaryRow>> {
    return apiV1.get("/totalWagesTable/calculate", {params: req})
  }

  static async update(req: SalaryRow): Promise<ResponseEntity<SalaryRow>> {
    return apiV1.put("/totalWagesTable", {...req})
  }

  static async delete(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/totalWagesTable", {data: ids})
  }
}
