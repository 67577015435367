import {makeAutoObservable, runInAction} from "mobx";
import {LabelValue, Page, ResponseEntity, ResponsesEntity, StateFullData} from "@services/dto/common";
import {ProjectStructureService} from "@services/ProjectStructureService";
import {ProjectStructureReq, ProjectStructureRow} from "@services/dto/projectStructure";

export class ProjectStructureStore {
  projectStructureDatasource: StateFullData<Page<ProjectStructureRow>> = {}
  projectStructureSelectDatasource: StateFullData<Page<ProjectStructureRow>> = {}
  projectStructureRow?: ProjectStructureRow
  projectStructureOption?: string
  projectStructureDetail: StateFullData<ResponseEntity<ProjectStructureRow>> = {}
  projectStructureDetails: StateFullData<ResponsesEntity<ProjectStructureRow>> = {}
  projectStructureSearchReq: ProjectStructureReq = {
    pageNum: 1,
    perPage: 10,
  }
  collapsed: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  setCollapsed(collapsed: boolean) {
    this.collapsed = collapsed
  }

  updateProjectStructureRow(row: ProjectStructureRow) {
    this.projectStructureRow = {...row}
  }

  updateProjectStructureOption(option: string) {
    this.projectStructureOption = option
  }

  updateProjectStructureSearchReq(req: ProjectStructureReq) {
    this.projectStructureSearchReq = {...this.projectStructureSearchReq, ...req}
  }

  getProjectStructure(req: ProjectStructureReq) {
    this.projectStructureDatasource = {...this.projectStructureDatasource, loading: true}
    this.projectStructureSearchReq = {...this.projectStructureSearchReq, ...req}
    ProjectStructureService.getProjectStructure(req).then(rsp => {
      runInAction(() => this.projectStructureDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.projectStructureDatasource = {
        ...this.projectStructureDatasource,
        loading: false,
        err
      })
    })
  }

  getSelectProjectStructure(req: ProjectStructureReq) {
    this.projectStructureSelectDatasource = {...this.projectStructureSelectDatasource, loading: true}
    ProjectStructureService.getProjectStructure(req).then(rsp => {
      runInAction(() => this.projectStructureSelectDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.projectStructureSelectDatasource = {
        ...this.projectStructureSelectDatasource,
        loading: false,
        err
      })
    })
  }

  getOne(id: string) {
    this.projectStructureDetail = {...this.projectStructureDetail, loading: true}
    ProjectStructureService.getDetailById(id).then(rsp => {
      runInAction(() => this.projectStructureDetail = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.projectStructureDetail = {...this.projectStructureDetail, loading: false, err})
    })
  }

  getBatch(ids: string[]) {
    this.projectStructureDetails = {...this.projectStructureDetails, loading: true}
    ProjectStructureService.getBatch(ids).then(rsp => {
      runInAction(() => this.projectStructureDetails = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.projectStructureDetails = {...this.projectStructureDetails, loading: false, err})
    })
  }

  async getPrintList(record: ProjectStructureRow): Promise<LabelValue[]> {

    const row = this.projectStructureDatasource.data?.items.find(o => o.id === record.id)

    return [
      {label: '制单日期', value: row.psDate as string},
      {label: '制单编号', value: row.psCode},
      {label: '项目类别', value: row.psCategory},
      {label: '项目摘要', value: row.psDesc},
      {label: '责任部门', value: row.responsibilityProject},
      {label: '编制', value: '', size: 'default'},
      {label: '审核', value: '', size: 'default'},
      {label: '批准', value: '', size: 'default'},
    ]
  }
}

export const projectStructureStore = new ProjectStructureStore();
