import {Button, Dropdown, FormInstance, MenuProps, message, Modal, Space} from "antd";
import {SettingOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {allStores} from "@/stores";
import {useNavigate} from "react-router-dom";
import {PasswordUpdateReq, User} from "@services/dto/security";
import {observer} from "mobx-react";
import {DEFAULT_MODAL_WIDTH} from "@/config";
import {UpdatePassword} from "@/layout/Components/UpdatePassword";
import {SecurityService} from "@services/SecurityService";
import {getUploadArrayToString, getUploadIds, showErr} from "@common/utils";
import './SiteLayoutRight.less'
import {PersonInfo} from "@/layout/Components/PersonInfo";
import {ToolService} from "@services/ToolService";
import {QRcodeModal} from "@components/QRcodeModal";
import {CompanyRow} from "@services/dto/company";

export interface PropsType {
  companyRowData: CompanyRow
}

export const SiteLayoutRight = observer((props: PropsType) => {
  const navigate = useNavigate()
  const {securityStore} = allStores;
  const [user, setUser] = useState<User>()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const basicInfo = React.createRef<FormInstance>();
  const personInfo = React.createRef<FormInstance>();
  const [loading, setLoading] = useState<boolean>(false)
  const [qRcodeModal, setQRcodeModal] = useState({
    title: "",
    visible: false,
    width: 500,
    value: "",
    size: 200,
  })


  useEffect(() => {
    const user = securityStore.getLoggedUser()
    setUser(user)
  }, [])

  const dropdownItems: MenuProps = {
    items: [
      {
        key: '1',
        label: (
          <Button onClick={() => enterpriseIntroduction()} type="text"
            className="site-layout-button">企业二维码</Button>
        ),
        disabled: !user?.companyId
      },
      {
        key: '2',
        label: (
          <Button onClick={() => updatePersonInfo()} type="text"
            className="site-layout-button">个人信息</Button>
        ),
      },
      {
        key: '3',
        label: (
          <Button onClick={() => updatePassword()} type="text"
            className="site-layout-button">修改密码</Button>
        ),
      },
      {
        key: '4',
        label: (
          <Button onClick={() => onLogout()} type="text" className="site-layout-button">退出登录</Button>
        ),
      },
    ]
  };

  const enterpriseIntroduction = () => {
    const code = {...qRcodeModal}
    code.visible = true
    code.title = "企业简介"
    if (props.companyRowData.url) {
      code.value = props.companyRowData.url
      setQRcodeModal(code)
    } else {
      message.warning("该企业没有输入官网地址，无法生成二维码！")
    }
  }

  const onLogout = () => {
    securityStore.logout().then(() => {
      navigate('/')
      window.location.reload()
    })
  }

  const updatePassword = () => {
    setIsModalOpen(true)
  }

  const updatePersonInfo = () => {
    setIsOpen(true)
  }

  const handleOk = () => {
    basicInfo.current?.validateFields().then(value => {
      if (value.firstPassWord !== value.secondPassWord) {
        message.warning("两次输入的新密码不一致，请确认后输入！")
        return
      }
      const passwordUpdate: PasswordUpdateReq = {
        id: user?._id!,
        oldPassWord: value.oldPassWord,
        firstPassWord: value.firstPassWord,
        secondPassWord: value.secondPassWord
      };
      setLoading(true)
      SecurityService.updatePassword(passwordUpdate).then(rsp => {
        message.success("修改密码成功！")
        setIsModalOpen(false)
      }).catch(showErr).finally(() => setLoading(false))
    })
  };

  const handleCancel = () => {
    basicInfo.current?.resetFields()
    setIsModalOpen(false);
  };

  const handlePersonCancel = () => {
    personInfo.current?.resetFields()
    setIsOpen(false)
  }

  const handlePersonOk = () => {
    personInfo.current?.validateFields().then(value => {
      const userInfo = {...user!}
      let ids = ""
      if (typeof value.avatarUrl === 'object') {
        ids = getUploadIds(value.avatarUrl)
        value.avatarUrl = getUploadArrayToString(value.avatarUrl)
      }
      Object.assign(userInfo, value);
      setLoading(true)
      SecurityService.updateUser(userInfo._id!, userInfo).then(async rsp => {
        await ToolService.submittedSuccess(ids, rsp._id)
        await securityStore.onLoginFinished({
          user: rsp,
          token: securityStore.loginRsp?.token!
        })
        setUser(rsp)
        message.success("个人信息更新成功！")
        setIsOpen(false)
      }).catch(showErr).finally(() => setLoading(false))
    })
  }

  return (
    <div className="site-layout-right">
      <div className={'user-name'}>{ (props.companyRowData ? props.companyRowData.companyName : "数字化集成管理信息系统") }</div>
      <div className="margin-left-xs margin-right-xs user-name">{ user?.name }</div>
      <Dropdown menu={dropdownItems} className="site-layout-button" placement={"bottomRight"}>
        <Space>
          <SettingOutlined/>
        </Space>
      </Dropdown>

      <Modal
        title={<div className="text-align-center">修改密码</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isModalOpen}
        onCancel={handleCancel}
        confirmLoading={loading}
        onOk={handleOk}>
        <UpdatePassword ref={basicInfo}/>
      </Modal>

      <Modal
        title={<div className="text-align-center">个人信息</div>}
        width={DEFAULT_MODAL_WIDTH}
        open={isOpen}
        onCancel={handlePersonCancel}
        confirmLoading={loading}
        onOk={handlePersonOk}>
        <PersonInfo ref={personInfo} user={{...user!}}/>
      </Modal>

      <QRcodeModal
        title={qRcodeModal.title}
        visible={qRcodeModal.visible}
        width={qRcodeModal.width}
        value={qRcodeModal.value}
        size={qRcodeModal.size}
        onCancel={() => setQRcodeModal({...qRcodeModal, visible: false})}/>
    </div>
  )
})
