import {makeAutoObservable, runInAction} from "mobx";
import {Page, ResponseEntity, StateFullData} from "@services/dto/common";
import {CompanyReq, CompanyRow} from "@services/dto/company";
import {CompanyService} from "@services/CompanyService";

export class CompanyStore {
  companyDataSource: StateFullData<Page<CompanyRow>> = {};
  companyRowData: StateFullData<ResponseEntity<CompanyRow>> = {}
    
  constructor() {
    makeAutoObservable(this)
  }

  searchCompany(req: CompanyReq) {
    this.companyDataSource = {...this.companyDataSource, loading: true};
    CompanyService.searchCompany(req).then(rsp => {
      runInAction(() => {this.companyDataSource = {data: rsp, loading: false}})
    }).catch(e => {
      runInAction(() => this.companyDataSource = {...this.companyDataSource, loading: false, err: e})
    })
  }

  getCompanyById(id: string) {
    this.companyRowData = {...this.companyRowData, loading: true}
    CompanyService.getCompanyById(id).then(rsp => {
      runInAction(() => {this.companyRowData = {data: rsp, loading: false}})
    }).catch(err => {
      runInAction(() => this.companyRowData = {...this.companyRowData, loading: false, err})
    })
  }
}

export const companyStore = new CompanyStore();