import React, {useEffect} from "react";
import {Col, DatePicker, Form, Input, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {FilingRow} from "@services/dto/filing";
import {SelectPersonCharge} from "@components/SelectPersonCharge";

export interface PropsType {
  row: FilingRow
  isView?: boolean
  filingMode?: 'multiple' | 'tags'
}

export const BasicInfoBatch = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    const filingRow = {...props.row}
    // 根据所在界面的单号字段

    form.setFieldsValue(filingRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      initialValues={{status: '未存档'}}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="code">
          <Input/>
        </Form.Item>
        { /*<Col span={24}>
          <Form.Item
            label="制单日期"
            name="faTime"
            rules={[{ required: true, message: '请选择制单日期!' }]}>
            <DatePicker disabled placeholder={"请选择制单日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="制单编号"
            name="faBatchNumber"
            rules={[{ required: false, message: '请输入制单编号!' }]}>
            <Input placeholder="请输入制单编号" disabled />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="文件名称"
            name="faName"
            rules={[{ required: true, message: '请选择文件名称!' }]}>
            <Input disabled placeholder="请选择文件名称" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="文件编号"
            name="faCode"
            rules={[{ required: false, message: '请输入文件编号!' }]}>
            <Input placeholder="请输入文件编号" disabled />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="文件类别"
            name="faType"
            rules={[{ required: true, message: '请选择文件类别!' }]}>
            <SelectDict
              disabled
              className={'width-100-percentage'}
              code={'fa_file_type'}
              placeholder={'请选择文件类别'}/>
          </Form.Item>
        </Col>*/ }
        <Col span={24}>
          <Form.Item
            label="存档日期"
            name="faSaveDate"
            rules={[{required: false, message: '请选择存档日期!'}]}>
            <DatePicker placeholder={"请选择存档日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="存档盒号"
            name="faSaveBox"
            rules={[{required: false, message: '请输入存档盒号!'}]}>
            <Input placeholder="请输入存档盒号"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="存档地点"
            name="faSaveAddress"
            rules={[{required: false, message: '请输入存档地点!'}]}>
            <Input placeholder="请输入存档地点"/>
          </Form.Item>
        </Col>
        { /*<Col span={24}>
          <Form.Item
            label="电子文件"
            name="efaAccountingObject"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            extra={"支持文件(pdf,doc,docx),20M以内"}
            rules={[{ required: false, message: '请输入电子文件!' }]}>
            <CustomUpload
              updateSize={20}
              maxCount={5}
              uploadType={"all"}
              // accept={"application/*"}
              listType={"picture"}
              multiple={true}/>
          </Form.Item>
        </Col>*/ }
        <Col span={24}>
          <Form.Item
            label="交件人"
            name="faSubmitter"
            rules={[{required: false, message: '请输入交件人!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              placeholder={"请选择交件人"}
              allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="收件人"
            name="faRecipient"
            rules={[{required: false, message: '请输入收件人!'}]}>
            <SelectPersonCharge
              className={"width-100-percentage"}
              placeholder={"请选择交件人"}
              allowClear/>
          </Form.Item>
        </Col>
        { /*<Col span={24}>
          <Form.Item
            label="责任人"
            name="personResponsible"
            rules={[{ required: false, message: '请选择负责人!' }]}>
            <SelectPersonCharge className={"width-100-percentage"} placeholder={"请选择负责人"} allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{ required: true, message: '请选择责任部门!' }]}>
            <SelectProject
              placeholder={"请选择责任部门"}
              allowClear
              disabled
              onChange={(value, options) => form.setFieldValue("code", options.find(item => item.name === value)?.code)}
              fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{ required: true, message: '请输入协作单位!' }]}>
            <SelectCooperativeUnit disabled allowClear mode={props.filingMode || mode} fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="status"
            rules={[{ required: false, message: '请选择办理进度!' }]}>
            <Select
              placeholder={'请选择办理进度'}
              disabled
              className={'width-100-percentage'}
              options={[
                {label:'已存档', value:'已存档'},
                {label:'未存档', value:'未存档'},
              ]}/>
          </Form.Item>
        </Col>*/ }
      </Row>
    </Form>
  )
}))
