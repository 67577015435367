import React, {useEffect} from "react";
import {Col, Form, Input, Row} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {RoleManageRow} from "@services/dto/roleManage";
import {SelectMenuPermissions} from "@components/SelectMenuPermissions";
import {MenuPermissionsRow} from "@services/dto/menuPermissions";

export interface PropsType {
  row: RoleManageRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()
  useEffect(() => {
    form.resetFields()
    const organizationalRow = {...props.row}
    if (organizationalRow.id) {
      if (organizationalRow.menuList) {
        organizationalRow.menuKeyList = organizationalRow.menuList.map(o => o.id)
      }
    }
    form.setFieldsValue(organizationalRow);
  }, [props.row])

  const handleMenuKeyListChange = (_: string[], option: MenuPermissionsRow[]) => {
    form.setFieldsValue({menuList: option})
  }

  return (
    <Form
      name="basic"
      ref={ref}
      disabled={props.isView}
      form={form}
      labelCol={{span: 6}}
      wrapperCol={{span: 16}}
      initialValues={{status: 1, isVirtualUser: 0}}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input/>
        </Form.Item>
        <Form.Item hidden name="menuList">
          <Input/>
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="角色名"
            name="name"
            rules={[{required: true, message: '请输入角色名!'}]}>
            <Input placeholder="请输入角色名"/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="权限"
            name="menuKeyList"
            rules={[{required: true, message: '请输入权限!'}]}>
            <SelectMenuPermissions
              multiple
              checkStrictly
              checkable
              selectable={false}
              showLine
              fieldNames={{title: 'title', key: 'id', children: 'child'}}
              onChange={handleMenuKeyListChange}
              size={'small'}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="备注"
            name="remark"
            rules={[{required: false, message: '请输入备注!'}]}>
            <Input.TextArea placeholder="请输入备注" autoSize={{minRows: 3, maxRows: 9}}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
