import React, {FC, useContext, useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import {Button, Card, Form, InputNumber, Space, Table} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {MonthSalaryRow} from "@services/dto/monthSalary";
import {FormInstance} from "antd/es";
import {logger} from "@common/utils";

export interface PropsType {
  className?: string
  loading: boolean
  total: number
  perPage: number
  pageNum: number
  selectedRowKeys: React.Key[]
  handlePageChange: (page: number, pageSize: number) => void
  handleChange: (selectedRowKeys: React.Key[]) => void
  dataSource: MonthSalaryRow[]
  handleEdit: (record: MonthSalaryRow) => void
  handleSave: (datasource: MonthSalaryRow[], row: MonthSalaryRow) => void
}

export const EditableContext = React.createContext<FormInstance | null>(null);

export type EditableTableProps = Parameters<typeof Table>[0];

export type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

export interface EditableRowProps {
  index: number;
}

export interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof MonthSalaryRow;
  record: MonthSalaryRow;
  handleSave: (record: MonthSalaryRow) => void;
}

export const EditableRow: React.FC<EditableRowProps> = ({index, ...props}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const form = useContext(EditableContext)!;
  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({[dataIndex]: record[dataIndex]});
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      if (values[dataIndex] !== record[dataIndex]) {
        handleSave({...record, ...values});
      }
    } catch (errInfo) {
      logger.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{margin: 0}}
        name={dataIndex}
        rules={[{required: true, message: `请输入${title}!`}]}>
        <InputNumber precision={String(dataIndex) === 'attendanceDaysSum' ? 4 : 2} placeholder={`请输入${title}!`} className={"width-100-percentage"}
          ref={inputRef} onPressEnter={save} onBlur={save}/>
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{paddingRight: 24}} onClick={toggleEdit}>
        { children }
      </div>
    );
  }
  return <td {...restProps}>{ childNode }</td>;
};

export const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

export const MonthPersonTable: FC<PropsType> = observer((props: PropsType) => {
  const handlePageChange = (page: number, pageSize: number) => {
    props.handlePageChange(page, pageSize)
  }

  const handleChange = (selectedRowKeys: React.Key[]) => {
    props.handleChange(selectedRowKeys);
  }

  const handleEdit = (record: MonthSalaryRow) => {
    props.handleEdit(record)
  }

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string | string[] })[] = [
    {
      title: '姓名',
      dataIndex: ["user", "name"],
      align: "center",
      width: 150,
      fixed: "left",
      render: (text, record, index) => (
        <Space>
          <Button style={{whiteSpace: 'normal'}} type={"link"} onClick={() => { handleEdit(record as MonthSalaryRow) }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '队部',
      dataIndex: "professionalLeaguesName",
      align: "center",
      width: 100
    },
    {
      title: '工种',
      dataIndex: "workerTypeName",
      align: "center",
      width: 100
    },
    {
      title: '职位',
      dataIndex: ["user", "professional"],
      align: "center",
      width: 100
    },
    {
      title: '日工资',
      dataIndex: "dailyWages",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '联系方式',
      dataIndex: ["user", "phone"],
      align: "center",
      width: 100
    },
    {
      title: '身份证号',
      dataIndex: ["user", "chinaUid"],
      align: "center",
      width: 180,
    },
    {
      title: '出勤天数',
      dataIndex: "attendanceDays",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '总工日合计',
      dataIndex: "attendanceDaysSum",
      align: "center",
      width: 100,
      editable: true
    },
    /*{
      title: '餐补',
      dataIndex: "foodSubsidies",
      align: "center",
      width: 100
    },*/
    {
      title: '奖惩',
      dataIndex: "rewardsAndPunishments",
      align: "center",
      width: 100
    },
    {
      title: '加班小时合计',
      dataIndex: "totalOvertimeHours",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '上月未发',
      dataIndex: "lastMonthWithout",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '上月多发',
      dataIndex: "lastMonthMultiple",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '报销车费',
      dataIndex: "forTheFare",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '合计应发工资(元)',
      dataIndex: "totalShouldBePay",
      align: "center",
      width: 150,
    },
    {
      title: '体检费',
      dataIndex: "physicalExamination",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '满三月培训费',
      dataIndex: "fullTrainingInMarch",
      align: "center",
      width: 150,
      editable: true
    },
    {
      title: '员工被褥',
      dataIndex: "employeesBedding",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '扣劳保',
      dataIndex: "deductionLaborInsurance",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '扣除本月社保个人承担部分',
      dataIndex: "peopleTakePart",
      align: "center",
      width: 200,
      editable: true
    },
    {
      title: '扣预支工资',
      dataIndex: "buckleAdvancePayments",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '水电代扣',
      dataIndex: "withholdingHydropower",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '扣款',
      dataIndex: "deductions",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '扣管理费',
      dataIndex: "buckleFee",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '代扣他行卡',
      dataIndex: "withholdinghislinecard",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '餐补总额',
      dataIndex: "totalamountoffoodsubsidies",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '车费补贴',
      dataIndex: "theFareSubsidies",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '其他补助',
      dataIndex: "otherBenefits",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '扣项目部代发',
      dataIndex: "projectDepartmentUndertakesToBuckle",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '扣总部代发',
      dataIndex: "undertakesToBuckleHeadquarters",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '应交税额',
      dataIndex: "sui",
      align: "center",
      width: 100,
      editable: true
    },
    {
      title: '合计实发工资(元)',
      dataIndex: "totalSalary",
      align: "center",
      width: 150,
    },
    {
      title: '银行卡姓名',
      dataIndex: ["user", "bankName"],
      align: "center",
      width: 150
    },
    {
      title: '开户行',
      dataIndex: ["user", "bankInfo"],
      align: "center",
      width: 150
    },
    {
      title: '银行卡号',
      dataIndex: ["user", "bankCardNumber"],
      align: "center",
      width: 150
    },
    {
      title: '操作',
      align: "center",
      width: 100,
      fixed: "right",
      dataIndex: 'operation',
      render: (_, record) => (
        <Space size="middle">
          <Button
            shape="round"
            className={"edit-btn"}
            size={"middle"}
            icon={<EditOutlined/>}
            onClick={() => handleEdit(record as MonthSalaryRow)}>修改</Button>
        </Space>
      ),
    },
  ];

  const handleSave = (row: MonthSalaryRow) => {
    const newData = [...props.dataSource];
    const index = newData.findIndex(item => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    props.handleSave(newData, row)
  };

  const columns = defaultColumns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: MonthSalaryRow) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <>
      <Card className={props.className}>
        <Table
          components={components}
          scroll={{x: 1500}}
          rowKey={'id'}
          dataSource={props.dataSource}
          columns={columns as ColumnTypes}
          size={"middle"}
          loading={props.loading}
          pagination={{
            total: props.total,
            position: ['bottomCenter'],
            pageSize: props.perPage,
            current: props.pageNum,
            showSizeChanger: true,
            showQuickJumper: true,
            showTotal: total => `共${total}条`, onChange: handlePageChange
          }}
          sticky
          rowSelection={{selectedRowKeys: props.selectedRowKeys, onChange: handleChange}}/>
      </Card>
    </>
  )
})