import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {InvoiceRegisterReq, InvoiceRegisterRow} from "@services/dto/invoiceRegister";

export class InvoiceRegisterService {
  static async getInvoiceRegister(req: InvoiceRegisterReq): Promise<Page<InvoiceRegisterRow>> {
    return await apiV1.get("/invoiceRegistration/search", {params: req})
  }

  static async addInvoiceRegister(row: InvoiceRegisterRow): Promise<ResponseEntity<InvoiceRegisterRow>> {
    return await apiV1.post("/invoiceRegistration/save", {...row})
  }

  static async updateInvoiceRegister(row: InvoiceRegisterRow): Promise<ResponseEntity<InvoiceRegisterRow>> {
    return await apiV1.put("/invoiceRegistration/update", {...row})
  }

  static async deleteInvoiceRegister(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/invoiceRegistration/delete", {data: ids})
  }

  static async batchUpdateInvoiceRegister(selectedRows: InvoiceRegisterRow[]): Promise<ResponseEntity<string>> {
    return await apiV1.put("/invoiceRegistration/updateBatch", [...selectedRows])
  }

  static async getDetailById(id: string): Promise<ResponseEntity<InvoiceRegisterRow>> {
    return await apiV1.get(`/invoiceRegistration/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<InvoiceRegisterRow>> {
    return await apiV1.get(`/invoiceRegistration/getBatch/${ids.join(',')}`)
  }
}
