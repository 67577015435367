import React, {useEffect, useState} from "react";
import {PageParam, ProjectRow} from "@services/dto/project";
import {Divider, Pagination, Select, Space} from "antd";
import {Any} from "@common/types";
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {StatisticsItemsReq, StatisticsItemsRow} from "@services/dto/dict";
import {DictService} from "@services/DictService";
import {OrganizationalRow} from "@services/dto/organizational";
import {showErr} from "@common/utils";
import {BLACK_LIST} from "@/config";

export interface PropsType {
  onChange?: (value: string | string[], options: ProjectRow[]) => void
  value?: string | string[]
  mode?: 'multiple' | 'tags'
  labelInValue?: boolean
  disabled?: boolean
  className?: string
  allowClear?: boolean
  placeholder?: string
  fieldNames?: { value: string, label: string }
  statisticsItemsReq?: StatisticsItemsReq
  updateCount?: number
}

export const SelectProject = observer((props: PropsType) => {
  const [value, setValue] = useState<string | string[]>()
  const {projectStore} = allStores
  const [datasource, setDatasource] = useState<ProjectRow[]>([])
  const [total, setTotal] = useState(0)
  const [items, setItems] = useState<ProjectRow[]>([])
  const [statisticsItems, setStatisticsItems] = useState<StatisticsItemsRow[] | ProjectRow[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [searchReq, setSearchReq] = useState<PageParam>({
    pageNum: 1,
    perPage: 10
  })

  /**
   *  每个组件默认写法 只需修改 'name'
   */
  const defaultItems: any[] = [
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: '',
      [props.fieldNames ? props.fieldNames.label : 'name']: '空白'
    },
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: 'N/A',
      [props.fieldNames ? props.fieldNames.label : 'name']: 'N/A'
    },
  ]

  /**
   * 初始化
   */
  useEffect(() => {
    if (props.statisticsItemsReq) {
      getStatisticsItems()
    } else {
      refreshData(searchReq)
    }
  }, [])

  /**
   * 更新统计
   */
  useEffect(() => {
    if (props.updateCount && props.statisticsItemsReq && props.value) {
      getStatisticsItems()
    }
  }, [props.updateCount]);

  /**
   * 办理进度数据变化
   */
  useEffect(() => {
    if (projectStore.projectSelectSource.data) {
      setDatasource(projectStore.projectSelectSource.data.items || [])
      setTotal(projectStore.projectSelectSource.data.total || 0)
    }
  }, [projectStore.projectSelectSource.data]);

  useEffect(() => {
    if (projectStore.projectSelectSource.data && projectStore.projectDetails.data?.length) {
      setItems(projectStore.projectDetails.data
        ?.filter(item => !(projectStore.projectSelectSource.data.items || [])
          .some(o => o.id === item.id)))
    }
  }, [projectStore.projectDetails, projectStore.projectSelectSource.data])

  /**
   * 值发生改变
   */
  useEffect(() => {
    setValue(props.value)
    if (typeof props.value === 'string' && !BLACK_LIST.includes(props.value) && !props.labelInValue) {
      projectStore.getBatchByName([props.value])
    } else if (typeof props.value === 'object' && !props.labelInValue) {
      const ids = props.value.filter((v: string) => !BLACK_LIST.includes(v))
      if (ids.length) {
        projectStore.getBatchByName(ids)
      }
    }
  }, [props.value])

  const getStatisticsItems = () => {
    setLoading(true)
    DictService.getStatisticsItems(props.statisticsItemsReq).then(rsp => {
      const row = JSON.parse(JSON.stringify(rsp.data || [])).map((item: StatisticsItemsRow | OrganizationalRow) => {
        (item as OrganizationalRow).name = `${(item as StatisticsItemsRow).value}(${(item as StatisticsItemsRow).coun})`
        return item
      })
      setStatisticsItems(row);
    }).catch(showErr).catch(showErr).finally(() => {
      setLoading(false)
    })
  }

  const refreshData = (req: PageParam) => {
    projectStore.getSelectCompanyProject(req)
  }

  const onChange = (value: Any | null) => {
    setValue(value)
    if (props.onChange) {
      props.onChange(value, [
        ...datasource,
        ...items,
      ]);
    }
  }

  const handleSearch = (inputValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = inputValue
    setSearchReq(req)
    refreshData(req)
    return true
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    const req = {...searchReq}
    req.pageNum = page
    req.perPage = pageSize
    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      {
        props.statisticsItemsReq ? (
          <Select
            allowClear={props.allowClear}
            placeholder={props.placeholder || "请选择项目"}
            className={`${props.className} width-100-percentage`}
            value={value}
            onChange={onChange}
            showSearch
            optionFilterProp="children"
            filterOption={(input: string, option) =>
              String(option?.name).toLowerCase().includes(input.toLowerCase())}
            disabled={props.disabled}
            mode={props.mode}
            loading={loading}
            labelInValue={props.labelInValue}
            fieldNames={props.fieldNames || {value: "id", label: "name"}}
            options={statisticsItems as ProjectRow[]}/>
        ) : (
          <Select
            allowClear={props.allowClear}
            placeholder={props.placeholder || "请选择项目"}
            className={`${props.className} width-100-percentage`}
            dropdownRender={(menu) => (
              <>
                { menu }
                <Divider className={"divider-margin"}/>
                <Space>
                  <Pagination
                    total={total}
                    pageSize={searchReq.perPage}
                    size={"small"}
                    onChange={handlePaginationChange}/>
                </Space>
              </>
            )}
            value={value}
            onChange={onChange}
            showSearch
            disabled={props.disabled}
            onClear={() => handleSearch('')}
            mode={props.mode}
            loading={projectStore.projectSelectSource.loading}
            filterOption={false}
            onSearch={handleSearch}
            labelInValue={props.labelInValue}
            fieldNames={props.fieldNames || {value: "id", label: "name"}}
            options={[
              ...defaultItems,
              ...datasource,
              ...items,
            ]}/>
        )
      }
    </>
  )
})
