import {Page, ResponseEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {CooperativeUnitReq, CooperativeUnitRow} from "@services/dto/cooperativeUnit";

export class CooperativeUnitService {
  static async getCooperativeUnit(req: CooperativeUnitReq): Promise<Page<CooperativeUnitRow>> {
    return await apiV1.get("/cooperationUnit/search", {params: req})
  }

  static async addCooperativeUnit(row: CooperativeUnitRow): Promise<ResponseEntity<CooperativeUnitRow>> {
    return await apiV1.post("/cooperationUnit/save", {...row})
  }

  static async updateCooperativeUnit(row: CooperativeUnitRow): Promise<ResponseEntity<CooperativeUnitRow>> {
    return await apiV1.put("/cooperationUnit/update", {...row})
  }

  static async deleteCooperativeUnit(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/cooperationUnit/delete", {data: ids})
  }

  static async getDetailById(id: string): Promise<ResponseEntity<CooperativeUnitRow>> {
    return await apiV1.get(`/cooperationUnit/getOne/${id}`)
  }
}
