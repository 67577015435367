import React from 'react';
import {AppRouters} from '@/routers'
import '@styles/App.less';
import '@styles/common.less'
import {ConfigProvider} from 'antd';
import {observer} from "mobx-react";
import dayjs from "dayjs";
import 'dayjs/locale/zh-cn';
import zhCN from 'antd/locale/zh_CN';
import '@assets/iconfont/iconfont.css'

dayjs.locale('cn');

const App = observer(() => {

  return (
    <ConfigProvider locale={zhCN} theme={{
      token: {
        colorPrimary: '#1890ff',
        colorLink: '#1890ff',
      }
    }}>
      <div className={"App"}>
        <AppRouters/>
      </div>
    </ConfigProvider>
  );
});

export default App;
