import React, {useEffect} from "react";
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import {Login} from '@modules/Security/Pages/Login'
import {BasicLayout} from "@/layout/BasicLayout";
import {PublicityAction} from '@modules/Recruitment/Pages/PublicityAction'
import {getPathByRoles} from "@common/utils";
import {allStores} from "@/stores";
import {Recruitment} from '@modules/Recruitment'
import {OnBoardingUsers} from "@modules/HR/Pages/OnBoardingUsers";
import {UserDetails} from "@modules/HR/Pages/UserDetails";
import {Talent} from "@modules/Talent"
import {Company} from "@modules/Company"
import {CompanyPages} from "@modules/Company/Pages";
import {Project} from "@modules/Project"
import {Organizational} from "@modules/Organizational"
import {MenuPermissions} from "@modules/MenuPermissions"
import {DictManagement} from "@modules/DictManagement"
import {RoleManage} from "@modules/RoleManage"
import {Supplies} from "@modules/Supplies"
import {Attendance} from "@modules/Attendance"
import {Profession} from "@modules/Profession"
import {Induction} from "@modules/Induction";
import {Archives} from "@modules/Archives";
import {ArchiveUserAction} from "@modules/Archives/Pages/ArchiveUserAction";
import {PersonnelChange} from "@modules/PersonnelChange";
import {MonthPersonSalary} from "@modules/Compensation/Pages/MonthPersonSalary";
import {MonthSalarySum} from "@modules/Compensation/Pages/MonthSalarySum";
import {MonthAdminSalary} from "@modules/Compensation/Pages/MonthAdminSalary";
import {RewardPunishment} from "@modules/RewardPunishment";
import {AttendanceSheet} from "@modules/Attendance/Pages/AttendanceSheet";
import {Quit} from "@modules/Quit";
import {OnInductionDetail} from "@modules/Induction/Pages/OnInductionDetail";
import {PunchClock} from "@modules/PunchClock";
import {Advance} from "@modules/Advance";
import {EmptyPage} from "@modules/Exception/404";
import {Institution} from "@modules/Institution";
import {RuleRegulation} from "@modules/RuleRegulation";
import {WorkPost} from "@modules/WorkPost";
import {Contract} from "@modules/HR/Pages/Contract";
import {Salary} from "@modules/Salary";
import {BasicSalary} from "@modules/Salary/Pages/BasicSalary";
import {OvertimeSalary} from "@modules/Salary/Pages/OvertimeSalary";
import {Bonus} from "@modules/Salary/Pages/Bonus";
import {Subsidy} from "@modules/Salary/Pages/Subsidy";
import {Deduction} from "@modules/Salary/Pages/Deduction";
import {Insurance} from "@modules/Salary/Pages/Insurance";
import {Payroll} from "@modules/Payroll";
import {ContractLedger} from "@modules/ContractLedger";
import {RevenueExpenditure} from "@modules/RevenueExpenditure";
import {ConstructionLog} from "@modules/ConstructionLog";
import {ProjectLog} from "@modules/ProjectLog";
import {ToDo} from "@modules/ToDo"
import {StaffLoan} from "@modules/StaffLoan"
import {CostApplication} from "@modules/CostApplication"
import {CostExpend} from "@modules/CostExpend";
import {CostReimbursement} from "@modules/CostReimbursement";
import {Filing} from "@modules/Filing";
import {InvoiceRegister} from "@modules/InvoiceRegister";
import {AccountSales} from "@modules/AccountSales";
import {TaxAccounting} from "@modules/TaxAccounting";
import {FundApproval} from "@modules/FundApproval";
import {BankStatement} from "@modules/BankStatement";
import {BankAccount} from "@modules/BankAccount";
import {BankAccountStatistics} from "@modules/BankAccount/Page/BankAccountStatistics";
import {CommercialContract} from "@modules/CommercialContract";
import {MarketingContract} from "@modules/Marketing/contract";
import {PurchaseContract} from "@modules/Purchase/contract";
import {MarketingSettleApply} from "@modules/Marketing/settleApply";
import {PurchaseSettleApply} from "@modules/Purchase/settleApply";
import {ContractList} from "@modules/ContractList";
import {ContractSettlement} from "@modules/ContractSettlement";
import {MaterialsOutBound} from "@modules/MaterialsOutBound";
import {MaterialList} from "@modules/MaterialList";
import {ProjectStructure} from "@modules/ProjectStructure";
import {BudgetManagement} from "@modules/BudgetManagement";
import {ProjectCollaboration} from "@modules/ProjectCollaboration";
import {WindowPrint} from "@modules/WindowPrint";
import {CooperativeUnit} from "@modules/CooperativeUnit";
import {Workbench} from "@modules/Workbench";
import {FinalSettlement} from "@modules/FinalSettlement";
import {InvoiceRegistration} from "@modules/InvoiceRegistration";
import {TransactionStatistics} from "@modules/TransactionStatistics";
import {IncomeExpenditureStatistics} from "@modules/IncomeExpenditureStatistics";
import {TaskSupervision} from "@modules/TaskSupervision";
import {WorkSchedule} from "@modules/WorkSchedule";
import {Process} from "@modules/Process";
import {TaxFeeStatistics} from "@modules/TaxFeeStatistics";
import {DocumentApproval} from "@modules/DocumentApproval";
import {ContractStatistics} from "@modules/ContractList/Pages/ContractStatistics";
import {SocialSecurityPayment} from "@modules/SocialSecurityPayment";
import {DemoPage} from "@modules/DemoPage";
import {EmployeeFeePay} from "@modules/EmployeeFeePay";
import {APP_TITLE} from "@/config";
import {TaxPlan} from "@modules/TaxPlan";
import {EmolumentPlan} from "@modules/EmolumentPlan";
import {FormStatus} from "@modules/FormStatus";
import {ConferenceRecord} from "@modules/ConferenceRecord";
import {JobAccount} from "@modules/JobAccount";
import {SealApproval} from "@modules/SealApproval";
import {SealApplication} from "@modules/SealApplication";
import {FileDistribution} from "@modules/FileDistribution";

export interface IRouterProps {
  path: string;
  title: string;
  name: string;
  component: any;
  disabled: boolean;
  withLayout?: boolean;
}

const routers: IRouterProps[] = [
  {
    path: '/',
    name: 'Login',
    title: '登录',
    component: Login,
    disabled: false
  },
  {
    path: '/print',
    name: 'Print',
    title: '打印',
    component: WindowPrint,
    disabled: false
  },
  {
    path: '/toDo',
    name: 'ToDo',
    title: '事项待办',
    component: ToDo,
    disabled: false,
    withLayout: true
  },
  {
    path: '/staffLoan',
    name: 'StaffLoan',
    title: '员工借款',
    component: StaffLoan,
    disabled: false,
    withLayout: true
  },
  {
    path: '/costApplication',
    name: 'CostApplication',
    title: '费用申请',
    component: CostApplication,
    disabled: false,
    withLayout: true
  },
  {
    path: '/costExpend',
    name: 'CostExpend',
    title: '费用支出',
    component: CostExpend,
    disabled: false,
    withLayout: true
  },
  {
    path: '/employeeFeePay',
    name: 'EmployeeFeePay',
    title: '费用支出',
    component: EmployeeFeePay,
    disabled: false,
    withLayout: true
  },
  {
    path: '/costReimbursement',
    name: 'CostReimbursement',
    title: '费用报销',
    component: CostReimbursement,
    disabled: false,
    withLayout: true
  },
  {
    path: '/filing',
    name: 'Filing',
    title: '文件存档',
    component: Filing,
    disabled: false,
    withLayout: true
  },
  {
    path: '/recruitments',
    name: 'Recruitment',
    title: '招聘公告',
    component: Recruitment,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/publicityAction',
    name: 'PublicityAction',
    title: '公示操作',
    component: PublicityAction,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/publicityAction/:_id',
    name: 'PublicityEdit',
    title: '编辑公示',
    component: PublicityAction,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/onBoardingUsers',
    name: 'OnBoardingUsers',
    title: '入职管理',
    component: OnBoardingUsers,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/onBoardingUsers/:onBoardingUserId/users/:userId',
    name: 'OnBoardingUserDetails',
    title: '用户详细信息',
    component: UserDetails,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/talent',
    name: 'Talent',
    title: '人才社区',
    component: Talent,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/company',
    name: 'Company',
    title: '公司录入',
    component: Company,
    disabled: false,
    withLayout: true,
  },
  // {
  //   path: '/companyPeople',
  //   name: 'CompanyPeople',
  //   title: '公司人员录入',
  //   component: CompanyPeople,
  //   disabled: false,
  //   withLayout: true,
  // },
  {
    path: '/company/pages',
    name: 'CompanyPages',
    title: '通知公告',
    component: CompanyPages,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/project',
    name: 'Project',
    title: '部门管理',
    component: Project,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/projectStructure',
    name: 'ProjectStructure',
    title: '项目审批',
    component: ProjectStructure,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/budgetManagement',
    name: 'BudgetManagement',
    title: '预算审批',
    component: BudgetManagement,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/projectCollaboration',
    name: 'ProjectCollaboration',
    title: '项目协作',
    component: ProjectCollaboration,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/institution',
    name: 'institution',
    title: '部门列表',
    component: Institution,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/organizational',
    name: 'Organizational',
    title: '系统用户',
    component: Organizational,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/menuPermissions',
    name: 'menuPermissions',
    title: '菜单管理',
    component: MenuPermissions,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/dictManagement',
    name: 'dictManagement',
    title: '菜单管理',
    component: DictManagement,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/roleManage',
    name: 'roleManage',
    title: '角色管理',
    component: RoleManage,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/supplies',
    name: 'Supplies',
    title: '物资管理',
    component: Supplies,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/attendance',
    name: 'Attendance',
    title: '考勤记录',
    component: Attendance,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/punchClock',
    name: 'PunchClock',
    title: '打卡设置',
    component: PunchClock,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/attendanceSheet/:userId/:userName',
    name: 'AttendanceSheet',
    title: '考勤管理',
    component: AttendanceSheet,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/attendance/:onBoardingUserId/users/:userId',
    name: 'OnBoardingUserDetails',
    title: '用户详细信息',
    component: UserDetails,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/profession',
    name: 'Profession',
    title: '工种人数',
    component: Profession,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/induction',
    name: 'Induction',
    title: '员工入职',
    component: Induction,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/onInductionDetail/:onBoardingUserId',
    name: 'OnInductionDetail',
    title: '入职管理',
    component: OnInductionDetail,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/quit',
    name: 'Quit',
    title: '员工离职',
    component: Quit,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/archives',
    name: 'Archives',
    title: '员工档案',
    component: Archives,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/archiveUserAction',
    name: 'NewArchiveUserAction',
    title: '新增档案库',
    component: ArchiveUserAction,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/archiveUserAction/:id/user/:userId',
    name: 'EditArchiveUserAction',
    title: '编辑档案库',
    component: ArchiveUserAction,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/rewardPunishment',
    name: 'RewardPunishment',
    title: '奖惩记录',
    component: RewardPunishment,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/personnel',
    name: 'Personnel',
    title: '员工调岗',
    component: PersonnelChange,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/advance',
    name: 'Advance',
    title: '预支工资',
    component: Advance,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/monthPersonSalary',
    name: 'MonthPersonSalary',
    title: '员工薪资明细',
    component: MonthPersonSalary,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/monthAdminSalary',
    name: 'MonthAdminSalary',
    title: '管理员薪资明细',
    component: MonthAdminSalary,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/monthSalarySum',
    name: 'MonthSalarySum',
    title: '月工资表汇总',
    component: MonthSalarySum,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/ruleRegulation',
    name: 'RuleRegulation',
    title: '流程管理',
    component: RuleRegulation,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/workPost',
    name: 'WorkPost',
    title: '工作岗位',
    component: WorkPost,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/contract',
    name: 'Contract',
    title: '人事合同',
    component: Contract,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/salary',
    name: 'Salary',
    title: '工资总表',
    component: Salary,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/basicSalary',
    name: 'BasicSalary',
    title: '基本工资',
    component: BasicSalary,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/overtimeSalary',
    name: 'OvertimeSalary',
    title: '加班工资',
    component: OvertimeSalary,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/bonus',
    name: 'Bonus',
    title: '绩效奖金',
    component: Bonus,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/subsidy',
    name: 'Subsidy',
    title: '补贴津贴',
    component: Subsidy,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/deduction',
    name: 'Deduction',
    title: '工资扣减',
    component: Deduction,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/insurance',
    name: 'Insurance',
    title: '五险一金',
    component: Insurance,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/payroll',
    name: 'Payroll',
    title: '工资发放',
    component: Payroll,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/contractLedger',
    name: 'ContractLedger',
    title: '合同台账',
    component: ContractLedger,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/revenueExpenditure',
    name: 'RevenueExpenditure',
    title: '收支台账',
    component: RevenueExpenditure,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/constructionLog',
    name: 'ConstructionLog',
    title: '施工日志',
    component: ConstructionLog,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/projectLog',
    name: 'ProjectLog',
    title: '施工日志',
    component: ProjectLog,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/invoiceRegister',
    name: 'InvoiceRegister',
    title: '发票登记',
    component: InvoiceRegister,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/accountSales',
    name: 'AccountSales',
    title: '销货清单',
    component: AccountSales,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/taxAccounting',
    name: 'TaxAccounting',
    title: '税费核算',
    component: TaxAccounting,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/finalSettlement',
    name: 'FinalSettlement',
    title: '税费清缴',
    component: FinalSettlement,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/invoiceRegistration',
    name: 'InvoiceRegistration',
    title: '增值税统计表',
    component: InvoiceRegistration,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/taxFeeStatistics',
    name: 'TaxFeeStatistics',
    title: '税费统计',
    component: TaxFeeStatistics,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/fundApproval',
    name: 'FundApproval',
    title: '资金审批',
    component: FundApproval,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/bankStatement',
    name: 'BankStatement',
    title: '资金出纳',
    component: BankStatement,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/bankAccount',
    name: 'BankAccount',
    title: '资金账户',
    component: BankAccount,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/bankAccountStatistics',
    name: 'BankAccountStatistics',
    title: '余额统计',
    component: BankAccountStatistics,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/commercialContract',
    name: 'CommercialContract',
    title: '商务合同',
    component: CommercialContract,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/marketingContract',
    name: 'MarketingContract',
    title: '合同台账',
    component: MarketingContract,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/purchaseContract',
    name: 'PurchaseContract',
    title: '合同台账',
    component: PurchaseContract,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/marketingSettleApply',
    name: 'MarketingSettleApply',
    title: '结算申请',
    component: MarketingSettleApply,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/purchaseSettleApply',
    name: 'PurchaseSettleApply',
    title: '结算申请',
    component: PurchaseSettleApply,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/contractList',
    name: 'ContractList',
    title: '合同审批',
    component: ContractList,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/contractSettlement',
    name: 'ContractSettlement',
    title: '合同结算',
    component: ContractSettlement,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/materialsOutBound',
    name: 'MaterialsOutBound',
    title: '物质出库',
    component: MaterialsOutBound,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/materialList',
    name: 'MaterialList',
    title: '物质清单',
    component: MaterialList,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/cooperativeUnit',
    name: 'CooperativeUnit',
    title: '协作单位',
    component: CooperativeUnit,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/transactionStatistics',
    name: 'TransactionStatistics',
    title: '往来统计',
    component: TransactionStatistics,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/incomeExpenditureStatistics',
    name: 'IncomeExpenditureStatistics',
    title: '收支统计',
    component: IncomeExpenditureStatistics,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/taskSupervision',
    name: 'TaskSupervision',
    title: '工作任务',
    component: TaskSupervision,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/workSchedule',
    name: 'WorkSchedule',
    title: '工作任务',
    component: WorkSchedule,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/process',
    name: 'Process',
    title: '工作流程',
    component: Process,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/workbench',
    name: 'Workbench',
    title: '工作台',
    component: Workbench,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/documentApproval',
    name: 'DocumentApproval',
    title: '文件审批',
    component: DocumentApproval,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/fileDistribution',
    name: 'FileDistribution',
    title: '文件分发',
    component: FileDistribution,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/contractStatistics',
    name: 'ContractStatistics',
    title: '合同范本',
    component: ContractStatistics,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/socialSecurityPayment',
    name: 'SocialSecurityPayment',
    title: '社保清缴',
    component: SocialSecurityPayment,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/taxPlan',
    name: 'TaxPlan',
    title: '税费计划',
    component: TaxPlan,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/emolumentPlan',
    name: 'EmolumentPlan',
    title: '工资计划',
    component: EmolumentPlan,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/formStatus',
    name: 'FormStatus',
    title: '表单状态',
    component: FormStatus,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/jobAccount',
    name: 'JobAccount',
    title: '工作统计',
    component: JobAccount,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/conferenceRecord',
    name: 'ConferenceRecord',
    title: '会议记录',
    component: ConferenceRecord,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/sealApproval',
    name: 'SealApproval',
    title: '印章审批',
    component: SealApproval,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/sealApplication',
    name: 'SealApplication',
    title: '用印申请',
    component: SealApplication,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/demoPage',
    name: 'DemoPage',
    title: '模板页面',
    component: DemoPage,
    disabled: false,
    withLayout: true,
  },
  {
    path: '/empty/*',
    name: 'EmptyPage',
    title: '找不到页面',
    component: EmptyPage,
    disabled: false,
    withLayout: true,
  }
]


export const AppRouters = () => {
  const location = useLocation()
  const navigation = useNavigate()
  const {securityStore, tabsViewStore} = allStores;

  useEffect(() => {
    if (securityStore.loginRsp) {
      const url = localStorage.getItem('dingding')
      if (url) {
        navigation(url)
      }
      if (location.pathname === '/') {
        navigation(getPathByRoles(securityStore.loginRsp.user));
      }
      const router = routers.find(item => pathMatch(item.path, location.pathname))
      if (router && router.withLayout) {
        const item = tabsViewStore.allTabsItems.find(o => o.key === location.pathname)
        const label = item?.label || router.title
        const user = securityStore.getLoggedUser()
        if (user) {
          document.title = `${label} - ${user.name || user.username} - ${APP_TITLE}`
        } else {
          document.title = `${label} - ${APP_TITLE}`
        }
        if (!tabsViewStore.tabsItems.some(item => pathMatch(item.key, location.pathname))) {
          tabsViewStore.updateTabsItems([...tabsViewStore.tabsItems, {
            key: location.pathname,
            label: String(label),
            closable: location.pathname !== '/workbench'
          }])
        }
      }
    } else {
      const query = new URLSearchParams(location.search)
      const from = query.get('from')
      if (from === 'dingding') {
        localStorage.setItem(from, location.pathname + location.search)
      }
    }
  }, [location])

  const pathMatch = (path: string, pathname: string) => {
    let flag = false;
    const paths = path.split("/")
    const pathnames = pathname.split("/")
    if (paths.length !== pathnames.length) {
      return flag;
    }
    flag = true
    for (let i = 0; i < paths.length; i++) {
      if (paths[i].includes(":")) {
        continue
      }
      if (paths[i] !== '*' && paths[i] !== pathnames[i]) {
        flag = false
        break
      }
    }
    return flag
  }

  const renderRawPage = (r: IRouterProps) => {
    return <Route key={r.name} path={r.path}
      element={<r.component/>}/>
  }

  const renderLayoutPage = (r: IRouterProps) => {
    return <Route key={r.name} path={r.path} element={<BasicLayout>
      <r.component/>
    </BasicLayout>}/>
  }

  return <Routes>
    { routers.map(i => i.withLayout ? renderLayoutPage(i) : renderRawPage(i)) }
  </Routes>
}
