import React, { useEffect } from "react";
import {Col, DatePicker, Form, Input, Row} from "antd";
import { FormInstance } from "antd/es";
import { observer } from "mobx-react";
import {SelectProject} from "@components/SelectProject";
import {ConstructionLogRow} from "@services/dto/constructionLog";
import dayjs from "dayjs";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";

export interface PropsType {
  row: ConstructionLogRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    const constructionLogRow = { ...props.row }
    if (constructionLogRow.id) {
      constructionLogRow.time = dayjs(constructionLogRow.time)
    }
    form.setFieldsValue(constructionLogRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ status: 1, isVirtualUser: 0 }}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="日期"
            name="time"
            rules={[{ required: false, message: '请选择日期!' }]}>
            <DatePicker placeholder={"请选择日期"} className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="项目"
            name="projectId"
            rules={[{ required: false, message: '请选择项目!' }]}>
            <SelectProject placeholder={"请选择项目"} allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="天气情况"
            name="weather"
            rules={[{ required: false, message: '请输入天气情况!' }]}>
            <Input placeholder="请输入天气情况" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="施工情况记录"
            name="situationRecord"
            rules={[{ required: false, message: '请输入施工情况记录!' }]}>
            <Input placeholder="请输入施工情况记录" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="安全工作记录"
            name="workRecords"
            rules={[{ required: false, message: '请输入安全工作记录!' }]}>
            <Input placeholder="请输入安全工作记录" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="备注"
            name="remark"
            rules={[{ required: false, message: '请输入备注!' }]}>
            <Input.TextArea placeholder="请输入备注" autoSize={{ minRows: 3, maxRows: 9 }}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任人"
            name="personResponsible"
            rules={[{ required: true, message: '请选择责任人!' }]}>
            <SelectPersonCharge className={"width-100-percentage"} placeholder={"请选择负责人"} allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{ required: true, message: '请选择责任部门!' }]}>
            <SelectProject placeholder={"请选择责任部门"} allowClear fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{ required: true, message: '请输入协作单位!' }]}>
            <SelectCooperativeUnit allowClear fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
