import {TabsProps} from "antd";
import {makeAutoObservable} from "mobx";

export class TabsViewStore {

  allTabsItems: TabsProps['items'] = []

  tabsItems: TabsProps['items'] = [
    {
      key: '/workbench',
      label: '工作台',
      closable: false
    }
  ]

  constructor() {
    makeAutoObservable(this)
  }

  setAllTabsItems(allTabsItems: TabsProps['items']) {
    this.allTabsItems = allTabsItems
  }

  updateTabsItems(tabsItems:TabsProps['items']) {
    this.tabsItems = [...tabsItems!]
  }
}

export const tabsViewStore = new TabsViewStore()
