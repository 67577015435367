import {Page, ResponseEntity, ResponsesEntity} from "@services/dto/common";
import {apiV1} from "@services/apiHelper";
import {FundApprovalReq, FundApprovalRow} from "@services/dto/fundApproval";

export class FundApprovalService {
  static async getFundApproval(req: FundApprovalReq): Promise<Page<FundApprovalRow>> {
    return await apiV1.get("/fundingApproval/search", {params: req})
  }

  static async getFundingApprovalByType(req: FundApprovalReq): Promise<ResponseEntity<FundApprovalRow>> {
    return await apiV1.get("/fundingApproval/getFundingApprovalByType", {params: req})
  }

  static async batchDateRefresh(): Promise<ResponseEntity<string>> {
    return await apiV1.get("/fundingApproval/batch/date/refresh")
  }

  static async addFundApproval(row: FundApprovalRow): Promise<ResponseEntity<FundApprovalRow>> {
    return await apiV1.post("/fundingApproval/save", {...row})
  }

  static async updateFundApproval(row: FundApprovalRow): Promise<ResponseEntity<FundApprovalRow>> {
    return await apiV1.put("/fundingApproval/update", {...row})
  }

  static async batchUpdateFundApproval(rows: FundApprovalRow[]): Promise<ResponseEntity<string>> {
    return await apiV1.put("/fundingApproval/batchUpdate", [...rows])
  }

  static async deleteFundApproval(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/fundingApproval/delete", {data: ids})
  }

  static async mergeFundApproval(ids: string[], mainBody: string, type: number): Promise<ResponseEntity<FundApprovalRow>> {
    return await apiV1.get(`/fundingApproval/merge/${ids.join(',')}?mainBodyId=${mainBody}&type=${type}`)
  }

  static async getOne(id: string): Promise<ResponseEntity<FundApprovalRow>> {
    return await apiV1.get(`/fundingApproval/getOne/${id}`)
  }

  static async getBatch(ids: string[]): Promise<ResponsesEntity<FundApprovalRow>> {
    return await apiV1.get(`/fundingApproval/getBatch/${ids.join(',')}`)
  }

  // /fundingApproval/unbundle?fundingId=资金审批id&businessId=关联表id&type=表名
  static async unbundle(fundingId: string, businessId: string, type: string): Promise<ResponsesEntity<FundApprovalRow>> {
    return await apiV1.get(`/fundingApproval/unbundle?fundingId=${fundingId}&businessId=${businessId}&type=${type}`)
  }

  async list(req: FundApprovalReq): Promise<Page<FundApprovalRow>> {
    return await apiV1.get("/fundingApproval/search", {params: req})
  }

  async save(row: FundApprovalRow): Promise<ResponseEntity<FundApprovalRow>> {
    return await apiV1.post("/fundingApproval/save", {...row})
  }

  async update(row: FundApprovalRow): Promise<ResponseEntity<FundApprovalRow>> {
    return await apiV1.put("/fundingApproval/update", {...row})
  }

  async deleteBatch(ids: string[]): Promise<ResponseEntity<string>> {
    return await apiV1.delete("/fundingApproval/delete", {data: ids})
  }
}
