import './index.less'
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {Button, Col, Divider, FormInstance, message, Modal, Pagination, Row, Select, Space} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {Any} from "@common/types";
import {FilingReq, FilingRow} from "@services/dto/filing";
import {CloseOutlined, EditOutlined, FolderViewOutlined, PlusOutlined} from "@ant-design/icons";
import {BLACK_LIST, DATE_FMT, DEFAULT_MODAL_WIDTH} from "@/config";
import {BasicInfo} from "@modules/Filing/Components/BasicInfo";
import {getUploadArrayToString, getUploadIds, logger, showErr} from "@common/utils";
import {FilingService} from "@services/FilingService";
import {ResponseEntity} from "@services/dto/common";
import {ToolService} from "@services/ToolService";
import {BaseSelectRef} from "rc-select";
import {StatisticsItemsReq, StatisticsItemsRow} from "@services/dto/dict";
import {DictService} from "@services/DictService";

export interface PropsType {
  onChange?: (value: Any | null, options: FilingRow[]) => void
  value?: Any | null
  mode?: 'multiple' | 'tags'
  labelInValue?: boolean
  disabled?: boolean
  inputDisabled?: boolean
  className?: string
  allowClear?: boolean
  placeholder?: string
  isHiddenOptions?: boolean
  fieldNames?: { value: string, label: string }
  statisticsItemsReq?: StatisticsItemsReq
  filingMode?: 'multiple' | 'tags'
  personResponsible?: string
  responsibilityProject?: string
  cooperationUnit?: string | string[]
  isHideEdit?: boolean
  isHidePlus?: boolean
  isHideClose?: boolean
  updateCount?: number
  isFaBatchNumberGroupBy?: boolean  // 针对faBatchNumber进行分组，要与fieldNames搭配使用
}

export const SelectFiling = observer((props: PropsType) => {
  const {filingStore} = allStores
  const [value, setValue] = useState<Any | null>()
  const [dataSource, setDataSource] = useState<FilingRow[]>([])
  const [total, setTotal] = useState<number>(0)
  const [items, setItems] = useState<FilingRow[]>([])
  const [statisticsItems, setStatisticsItems] = useState<StatisticsItemsRow[] | FilingRow[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isView, setIsView] = useState(false);
  const [filingRow, setFilingRow] = useState<FilingRow>()
  const basicInfo = React.createRef<FormInstance>();
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const selectRef = useRef<BaseSelectRef>(null)
  const [isHideField, setIsHideField] = useState<boolean>(false)
  const [searchReq, setSearchReq] = useState<FilingReq>({
    pageNum: 1,
    perPage: 10,
  })

  /**
   *  每个组件默认写法 只需修改 'faBatchNumber'
   */
  const defaultItems: any[] = [
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: 'N/A',
      [props.fieldNames ? props.fieldNames.label : 'faBatchNumber']: 'N/A'
    },
    {
      [props.fieldNames ? props.fieldNames.value : 'id']: '空白',
      [props.fieldNames ? props.fieldNames.label : 'faBatchNumber']: '空白'
    },
  ]

  /**
   * 初始化
   */
  useEffect(() => {
    if (props.statisticsItemsReq) {
      getStatisticsItems()
    } else {
      refreshData(searchReq)
    }
  }, [])

  /**
   * 更新统计
   */
  useEffect(() => {
    if (props.updateCount && props.statisticsItemsReq && props.value) {
      getStatisticsItems()
    }
  }, [props.updateCount]);

  /**
   * 办理进度数据变化
   */
  useEffect(() => {
    if (filingStore.filingSelectDatasource.data) {
      setDataSource(filingStore.filingSelectDatasource.data.items || [])
      setTotal(filingStore.filingSelectDatasource.data.total || 0)
      if (filingStore.filingDetails.data?.data.length && !props.isFaBatchNumberGroupBy) {
        setItems(filingStore.filingDetails.data?.data
          .filter(item => !(filingStore.filingSelectDatasource.data.items || [])
            .some(o => o.id === item.id)))
      }
    }
  }, [filingStore.filingDetails, filingStore.filingSelectDatasource.data])

  /**
   * 值发生改变
   */
  useEffect(() => {
    setValue(props.value)
    if (typeof props.value === 'string' && !BLACK_LIST.includes(props.value)) {
      filingStore.getBatch([props.value])
    } else if (typeof props.value === 'object') {
      const ids = props.value.filter((v: string) => !BLACK_LIST.includes(v))
      if (ids.length) {
        filingStore.getBatch(ids)
      }
    }
  }, [props.value])

  const getStatisticsItems = () => {
    setLoading(true)
    DictService.getStatisticsItems(props.statisticsItemsReq).then(rsp => {
      const row = JSON.parse(JSON.stringify(rsp.data || [])).map((item: StatisticsItemsRow | FilingRow) => {
        (item as FilingRow).faBatchNumber = `${(item as FilingRow).faBatchNumber || (item as StatisticsItemsRow).value}(${(item as StatisticsItemsRow).coun})`
        return item
      })
      setStatisticsItems(row);
    }).catch(showErr).finally(() => {
      setLoading(false)
    })
  }

  const refreshData = (req: FilingReq) => {
    if (props.isFaBatchNumberGroupBy) {
      filingStore.getGroupByFaBatchNumber(req)
    } else {
      filingStore.getSelectFiling(req)
    }
  }

  const onChange = (value: Any | null) => {
    setValue(value)
    if (props.onChange) {
      props.onChange(value, [
        ...dataSource,
        ...items,
      ]);
    }
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    const req = {...searchReq}
    req.pageNum = page
    req.perPage = pageSize
    setSearchReq(req)
    refreshData(req)
  }

  const handleSearch = (inputValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = inputValue
    setSearchReq(req)
    refreshData(req)
    return true
  }

  const handlePlus = () => {
    setFilingRow({
      ...filingRow,
      responsibilityProject: props.responsibilityProject,
      cooperationUnit: props.cooperationUnit
    })
    setIsHideField(true)
    setIsView(false)
    setIsModalOpen(true)
  }

  const handleEdit = () => {
    const record = [...dataSource, ...items].find(item => item.id === value)
    setFilingRow(record);
    setIsView(false)
    setIsHideField(true)
    setIsModalOpen(true);
  }

  const handleClose = () => {
    const record = [...dataSource, ...items].find(item => item.id === value)
    if (!record) {
      message.warning("请选择要删除的数据！").then()
      return
    }
    let ids: string[] = [record.id]
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        FilingService.deleteFiling(ids).then(() => {
          message.success("删除成功！").then()
          setConfirmLoading(false)
          handleSearch('')
          onChange(undefined)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handleView = () => {
    const record = [...dataSource, ...items].find(item => item.id === value)
    setFilingRow(record);
    setIsModalOpen(true);
    setIsView(true)
    setIsHideField(true)
  }

  const handleCancel = () => {
    setFilingRow(undefined)
    setIsView(false)
    setIsHideField(false)
    setIsModalOpen(false)
  }

  const handleOk = () => {
    const row = {...filingRow!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(row, values)
      if (values.faTime) {
        row.faTime = values.faTime.format(DATE_FMT);
      }
      if (values.faSaveDate) {
        row.faSaveDate = values.faSaveDate.format(DATE_FMT)
      }
      let ids = ""
      if (typeof row.efaAccountingObject === 'object') {
        ids = getUploadIds(row.efaAccountingObject)
        row.efaAccountingObject = getUploadArrayToString(row.efaAccountingObject)
      }
      if (typeof row.cooperationUnit === 'object') {
        row.cooperationUnit = values.cooperationUnit.join(",")
      }
      setConfirmLoading(true);
      if (row.id) {
        FilingService.updateFiling(row).then(rsp => {
          actionThen(ids, rsp).then();
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        FilingService.addFiling(row).then(rsp => {
          actionThen(ids, rsp).then();
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  }

  const actionThen = async (ids: string, rsp: ResponseEntity<FilingRow>) => {
    // 图片上传跟表单绑定
    try {
      await ToolService.submittedSuccess(ids, rsp.data.id)
      message.success("操作成功！")
      onChange(rsp.data.id)
    } catch (e) {
      showErr(e)
    } finally {
      setIsModalOpen(false);
      setFilingRow(undefined)
      setIsHideField(false)
      refreshData(searchReq)
    }
  }

  return (
    <>
      <Row>
        <Col span={props.isHiddenOptions ? 24 : 18}>
          {
            props.statisticsItemsReq ? (
              <Select
                ref={selectRef}
                allowClear={props.allowClear}
                placeholder={props.placeholder || "请选择存档批号"}
                className={`${props.className} width-100-percentage`}
                value={value}
                onChange={onChange}
                showSearch
                optionFilterProp="children"
                filterOption={(input: string, option) =>
                  String(option?.faBatchNumber).toLowerCase().includes(input.toLowerCase())}
                autoClearSearchValue
                disabled={props.inputDisabled || props.disabled}
                mode={props.mode}
                loading={loading}
                labelInValue={props.labelInValue}
                fieldNames={props.fieldNames || {value: "id", label: "faBatchNumber"}}
                options={statisticsItems as FilingRow[]}/>
            ) : (
              <Select
                ref={selectRef}
                allowClear={props.allowClear}
                placeholder={props.placeholder || "请选择存档批号"}
                className={`${props.className} width-100-percentage`}
                dropdownRender={(menu) => (
                  <>
                    { menu }
                    <Divider className={"divider-margin"}/>
                    <Space>
                      <Pagination
                        total={total}
                        pageSize={searchReq.perPage}
                        size={"small"}
                        onChange={handlePaginationChange}/>
                    </Space>
                  </>
                )}
                value={value}
                onChange={onChange}
                showSearch
                autoClearSearchValue
                disabled={props.inputDisabled || props.disabled}
                mode={props.mode}
                onClear={() => handleSearch('')}
                loading={filingStore.filingSelectDatasource.loading}
                labelInValue={props.labelInValue}
                filterOption={false}
                onSearch={handleSearch}
                fieldNames={props.fieldNames || {value: "id", label: "faBatchNumber"}}
                options={[
                  ...defaultItems,
                  ...dataSource,
                  ...items,
                ]}/>
            )
          }
        </Col>
        {
          !props.isHiddenOptions && <Col
            span={props.isHiddenOptions ? 0 : 6}
            className={'col-button'}>
            {
              !props.isHideEdit && <Button
                size={'small'}
                type={'link'}
                className={'btn-edit'}
                disabled={!value || props.disabled}
                onClick={handleEdit}>
                <EditOutlined/>
              </Button>
            }
            {
              !props.isHidePlus && <Button
                size={'small'}
                type={'link'}
                className={'btn-plus'}
                onClick={handlePlus}>
                <PlusOutlined/>
              </Button>
            }
            {
              !props.isHideClose && <Button
                size={'small'}
                type={'link'}
                className={'btn-close'}
                disabled={!value || props.disabled}
                onClick={handleClose}>
                <CloseOutlined/>
              </Button>
            }
            <Button
              size={'small'}
              type={'link'}
              className={'btn-view'}
              disabled={!value}
              onClick={handleView}>
              <FolderViewOutlined/>
            </Button>
          </Col>
        }
      </Row>

      <Modal
        title={<div className="text-align-center">文件存档</div>}
        open={isModalOpen}
        width={DEFAULT_MODAL_WIDTH}
        onOk={handleOk}
        footer={(_, {OkBtn, CancelBtn}) => (
          <>
            <CancelBtn />
            {
              !isView && <OkBtn />
            }
          </>
        )}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}>
        <BasicInfo row={{...filingRow!}} ref={basicInfo} filingMode={props.filingMode} isView={isView} isHideField={isHideField}/>
      </Modal>
    </>
  )
})
