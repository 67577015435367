import {makeAutoObservable, runInAction} from "mobx";
import {WorkerGroup} from "@services/dto/workerGroup";
import {List} from "@services/dto/common";
import {WorkerGroupService} from "@services/WorkerGroupService";

export class WorkerGroupStore {
  workerGroups: List<WorkerGroup> = {
    items: [],
    total: 0
  }

  constructor() {
    makeAutoObservable(this)
  }

  getWorkerGroups() {
    this.workerGroups = {...this.workerGroups}
    WorkerGroupService.getWorkerGroups().then((res) => {
      runInAction(() => {this.workerGroups = {...res}})
    }).catch(err => {
      runInAction(() => {this.workerGroups = {...this.workerGroups, err: err}})
    })
  }
}

export const workerGroupStore = new WorkerGroupStore();
