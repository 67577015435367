import {
  CreateRewardPunishmentReq,
  RewardPunishmentRow,
  RewardPunishmentReq,
  UpdateRewardPunishmentReq
} from "@services/dto/rewardPunishment";
import {apiV1} from "@services/apiHelper";
import {Page} from "@services/dto/common";

export class RewardPunishmentService {
  static async getRewardPunishmentById(id: string): Promise<RewardPunishmentRow> {
    return await apiV1.get(`/rewardPunishment/${id}`);
  }

  static async getRewardPunishment(req: RewardPunishmentReq): Promise<Page<RewardPunishmentRow>> {
    return await apiV1.get("/rewardPunishment/search", {params: req});
  }

  static async createRewardPunishment(createReq: CreateRewardPunishmentReq): Promise<RewardPunishmentRow> {
    return await apiV1.post("/rewardPunishment", {...createReq});
  }

  static async updateRewardPunishment(id: string, updateReq: UpdateRewardPunishmentReq): Promise<RewardPunishmentRow> {
    return await apiV1.put(`/rewardPunishment/${id}`, {...updateReq});
  }

  static async deleteRewardPunishmentById(id: string): Promise<void> {
    return await apiV1.delete(`/rewardPunishment/${id}`)
  }

  static async deleteBatchRewardPunishment(ids: string[]): Promise<void> {
    return await apiV1.delete("/rewardPunishment/deleteBatch", {data: {ids}})
  }
}