import React, {useEffect, useState} from 'react'
import {HireBaseWorkInfo, ProgressSteps} from "@modules/HR/Components/Hire";
import {FileRow, TodoRow, UpdateWorkerRecruitmentRow} from "@services/dto/workerRecruitment";
import {Button, Col, Form, Input, message, Radio, Row, Typography, UploadFile} from "antd";
import {observer} from "mobx-react";
import {WorkerRecruitmentService} from "@services/WorkerRecruitmentService";
import {allStores} from "@/stores";
import {DATE_SECONDS_FMT, WorkerRecruitmentStatus} from "@/config";
import {getUploadIds, showErr} from "@common/utils";
import {ToolService} from "@services/ToolService";
import dayjs from "dayjs";

export const Exit = observer(()=>{
  const {wrStore} = allStores;
  const {wr: stateWr} = wrStore
  const wr = stateWr.data!;
  const [form] = Form.useForm();
  const [quit, setQuit] = useState<string>("exited")
  const [disable, setDisable] = useState<boolean>(false)

  useEffect(() => {
    if (wr.remark) {
      setQuit("abnormalexited")
      form.resetFields();
      form.setFieldsValue(wr);
      setDisable(true)
    }
    if (wr.exitTodoChecked.length > 0) {
      setDisable(true)
    }
  }, [wr])

  const onNext = async (row: TodoRow, index: number, lastStep: boolean, fileList?: UploadFile[]) => {
    const checked = [...wr.exitTodoChecked || []]

    const files: FileRow[] = []
    let ids = ""
    if (fileList) {
      fileList.forEach(item => {
        let url = item.response ? item.response.url : item.url;
        files.push({
          name: "",
          fileUrl: url,
          createdAt: dayjs().format(DATE_SECONDS_FMT)
        })
      })
      ids = getUploadIds(fileList)
    }

    checked[index] = {
      typeId: row.title,
      isToDo: true,
      type: row.type,
      files
    }
    const wrObj: UpdateWorkerRecruitmentRow = {
      exitTodoChecked: checked.map(item => ({
        typeId: item.typeId,
        isToDo: item.isToDo,
        type: item.type,
        files: item.files
      }))
    }
    if (lastStep && !checked.some(i => !i.isToDo)) {
      wrObj.status = WorkerRecruitmentStatus.exited
    }
    if (ids) {
      try {
        await ToolService.submittedSuccess(ids, row.id);
      } catch (e) {
        showErr(e)
        return
      }
    }
    WorkerRecruitmentService.updateWorkerRecruitment(wr._id, wrObj).then(rsp => {
      message.success(row.title + "完成")
      wrStore.updateExitTodo(checked)
    }).catch(showErr)
  }
  
  const onFinish = (values: any) => {
    WorkerRecruitmentService.exitWorkerRecruitment(wr._id, values.remark).then(rsp => {
      message.success("离职完成")
      wrStore.updateRemark(values.remark)
    }).catch(showErr)
  }


  return <div className="exit-container">
    <HireBaseWorkInfo/>
    <Typography.Title className={"mt16"} level={4}>
      待办事项
    </Typography.Title>
    <Radio.Group value={quit} onChange={(e) => setQuit(e.target.value)} buttonStyle="solid" disabled={disable}>
      <Radio.Button value="exited">正常离职</Radio.Button>
      <Radio.Button value="abnormalexited">非正常离职</Radio.Button>
    </Radio.Group>
    {
      quit === "exited" ? 
        <ProgressSteps rows={wr.exitTodo} finalStepLabel={'完成离职'} checked={wr.exitTodoChecked} onNext={onNext}/> :
        <Form
          name="basic"
          form={form}
          labelCol={{span: 8}}
          wrapperCol={{span: 16}}
          onFinish={onFinish}
          autoComplete="off">
          <Row>
            <Form.Item hidden name="_id">
              <Input/>
            </Form.Item>
            <Col xl={6} md={10} xs={15} className={"margin-top-xs"}>
              <Col span={24}>
                <Form.Item
                  label="离职原因"
                  name="remark"
                  rules={[{required: true, message: '请输入离职原因!'}]}>
                  <Input.TextArea placeholder="请输入离职原因" autoSize={{minRows: 3, maxRows: 9}} disabled={disable}/>
                </Form.Item>
              </Col>
              {
                !disable &&
                  <Col span={24}>
                    <Form.Item className={"text-align-center"}>
                      <Button type="primary" htmlType="submit">
                        提交
                      </Button>
                    </Form.Item>
                  </Col>
              }
            </Col>
          </Row>
        </Form>
    }
  </div>
})
