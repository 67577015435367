import React, {FC, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Button, Card, Col, Row, Space, Spin, Table, Tree} from "antd";
import {ColumnsType} from "antd/es/table";
import {EditOutlined, FolderOpenOutlined} from "@ant-design/icons";
import {getOrganizationStatusLabel} from "@common/utils";
import {ProjectAndTeam, ProjectPersonnel, ProjectPersonnelReq} from "@services/dto/project";
import {allStores} from "@/stores";
import {useNavigate} from "react-router-dom";
import "./index.less"

export const Profession:FC = observer(() => {
  const {professionStore} = allStores
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>();
  const [checkedKeys, setCheckedKeys] = useState<React.Key[]>();
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [req, setReq] = useState<ProjectPersonnelReq>({pageParamsDto: {pageNum: 1, perPage: 10}})
  const [datasource, setDatasource] = useState<ProjectPersonnel[]>([])
  const [total, setTotal] = useState<number>(0)
  const [treeData, setTreeData] = useState<ProjectAndTeam[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    initProjectAndPersonnel()
    initProjectPersonnel(req)
  }, [])

  const initProjectPersonnel = (req: ProjectPersonnelReq) => {
    professionStore.getProjectPersonnel(req)
  }

  const initProjectAndPersonnel = () => {
    professionStore.getProjectAndPersonnel()
  }

  useEffect(() => {
    setDatasource(professionStore.projectPersonnel.data?.items!)
    setTotal(professionStore.projectPersonnel.data?.total!)
  }, [professionStore.projectPersonnel])

  useEffect(() => {
    setTreeData(professionStore.projectAndTeam.data?.data!)
  }, [professionStore.projectAndTeam])

  const columns: ColumnsType<ProjectPersonnel> = [
    {
      title: '岗位名称',
      dataIndex: 'professional',
      key: 'professional',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => handleView(record)}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '工种',
      dataIndex: 'typeName',
      key: 'typeName',
      align: 'center'
    },
    {
      title: '在职人数',
      dataIndex: 'useNum',
      key: 'useNum',
      align: 'center'
    },
    {
      title: '办理进度',
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          { getOrganizationStatusLabel(text) }
        </Space>
      )
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (_, record) => (
        <Space>
          <Button shape="round" className={"view-btn"} size={"middle"} icon={<EditOutlined/>} onClick={() => handleView(record)}>查看</Button>
        </Space>
      ),
    },
  ];
  
  const handleView = (record: ProjectPersonnel) => {
    let projectIds: string[] = []
    let professionalLeaguesNames: string[] = []
    let workerGroupName = record.typeName
    checkedKeys?.forEach(item => {
      const obj = String(item).split(":")
      if (!projectIds.includes(obj[0]) && obj[0]) {
        projectIds.push(obj[0])
      }
      if (!professionalLeaguesNames.includes(obj[1]) && obj[1]) {
        professionalLeaguesNames.push(obj[1])
      }
    })
    professionStore.updateProfessionSearchReq({
      projectIds,
      professionalLeaguesNames,
      workerGroupName
    })
    navigate("/archives")
  }

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue: {checked: React.Key[]; halfChecked: React.Key[];} | React.Key[]) => {
    if (Array.isArray(checkedKeysValue)) {
      let params = {...req};
      params.pageParamsDto.pageNum = 1
      params.projectPersonnels = checkedKeysValue.map(item => {
        const obj = String(item).split(":")
        return {
          pid: obj[0],
          professionalLeaguesName: obj[1]
        }
      })
      setReq(params)
      initProjectPersonnel(params)
      setCheckedKeys(checkedKeysValue);
    }
  };

  const onSelect = (selectedKeysValue: React.Key[], info: any) => {
    setSelectedKeys(selectedKeysValue);
  };

  const handlePageChange = (page: number, pageSize: number) => {
    let params = {...req}
    params.pageParamsDto.pageNum = page
    params.pageParamsDto.perPage = pageSize
    setReq(params)
    initProjectPersonnel(params)
  }

  return (
    <>
      <Card>
        <Row gutter={16}>
          <Col xl={4} md={8} xs={12} className={"left-divider"}>
            <div><FolderOpenOutlined className={"all-icon"}/>全部</div>
            <Spin spinning={professionStore.projectAndTeam.loading}>
              <Tree
                checkable
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                onCheck={onCheck}
                checkedKeys={checkedKeys}
                onSelect={onSelect}
                selectedKeys={selectedKeys}
                treeData={treeData}
                fieldNames={{
                  title: "name",
                  key: "id",
                  children: "children"
                }}
              />
            </Spin>
          </Col>
          <Col xl={20} md={16} xs={12}>
            <Table 
              rowKey={"typeName"}
              columns={columns} 
              dataSource={datasource}
              size={"middle"}
              loading={professionStore.projectPersonnel.loading}
              onRow={(record) => {
                return {
                  onClick: () => setSelectedRowKeys([record.typeName]),
                }
              }}
              pagination={{
                total: total,
                pageSize: req.pageParamsDto.perPage,
                current: req.pageParamsDto.pageNum,
                position: ['bottomCenter'],
                showSizeChanger: true, 
                showQuickJumper: true,
                showTotal: total => `共${total}条`, onChange: handlePageChange}} 
              rowSelection={{type: "radio", selectedRowKeys, onChange: setSelectedRowKeys}}/>
          </Col>
        </Row>
      </Card>
    </>
  )
})
