import {makeAutoObservable, runInAction} from "mobx";
import {Page, StateFullData} from "@services/dto/common";
import {ProjectLogService} from "@services/ProjectLogService";
import {PageParam, ProjectLogRow} from "@services/dto/projectLog";

export class ProjectLogStore {
  projectLogDatasource: StateFullData<Page<ProjectLogRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  getProjectLog(req: PageParam) {
    this.projectLogDatasource = {...this.projectLogDatasource, loading: true}
    ProjectLogService.getProjectLog(req).then(rsp => {
      runInAction(() => this.projectLogDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.projectLogDatasource = {...this.projectLogDatasource, loading: false, err})
    })
  }
}

export const projectLogStore = new ProjectLogStore();
