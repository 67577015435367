import {Page, StateFullData} from "@services/dto/common";
import {SalaryRow, SearchSalaryReq} from "@services/dto/salary";
import {makeAutoObservable, runInAction} from "mobx";
import {SalaryService} from "@services/SalaryService";

export class SalaryStore {
  salaryDatasource: StateFullData<Page<SalaryRow>> = {}

  constructor() {
    makeAutoObservable(this)
  }

  search(req: SearchSalaryReq){
    this.salaryDatasource = {...this.salaryDatasource, loading: true}
    SalaryService.search(req).then(rsp => {
      runInAction(() => this.salaryDatasource = {data: rsp, loading: false})
    }).catch(err => {
      runInAction(() => this.salaryDatasource = {...this.salaryDatasource, loading: false, err})
    })
  }
}

export const salaryStore = new SalaryStore();
