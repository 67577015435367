import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {HeaderAction} from "@components/HeaderAction";
import {ColumnsType} from "antd/es/table";
import {FilingReq} from "@services/dto/filing";
import {DatePicker, Input} from "antd";
import dayjs from "dayjs";
import {SelectDict} from "@components/SelectDict";
import {Any} from "@common/types";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {ClearOutlined, SearchOutlined} from "@ant-design/icons";
import {SelectProcessStatus} from "@components/SelectProcessStatus";

export interface PropsType {
  updateCount?: number, // 更新渲染标志
  collapsed?: boolean, // 是否展开
  handleShowColumnReset?: () => void, // 重置列操作
  setCollapsed?: (v: boolean) => void, // 展开事件
  searchReq?: FilingReq, // 请求参数
  selectedRowKeys?: React.Key[], // 所选行
  handleDeleteBatch?: () => void,
  handleAdd?: () => void,
  handleTableColumnsChange?: (value: string[]) => void,
  showColumn?: string[],
  tableColumns?: ColumnsType,
  refreshData?: (req: FilingReq) => void,
  size?: number
  isHiddenOptions?: boolean
  locationPathname?: string
  items?: React.ReactNode[]
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [faBatchNumber, setFaBatchNumber] = useState<string>()
  const [searchReq, setSearchReq] = useState<FilingReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
      setFaBatchNumber(props.searchReq.faBatchNumber)
    }
  }, [props.searchReq])

  const refreshData = (req: FilingReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleFaTimeChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.faSaveDate1 = dateStrings[0]
    req.faSaveDate2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleFaTypeChange = (value: Any) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.faType = value
    setSearchReq(req)
    refreshData(req)
  }

  /*const handleFaSubmitterChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.faSubmitter = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleFaRecipientChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.faRecipient = value
    setSearchReq(req)
    refreshData(req)
  }*/

  const handlePersonResponsibleChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleStatusChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.status = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleFaBatchNumberClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.faBatchNumber = undefined
    setSearchReq(req)
    refreshData(req)

  }

  const handleFaBatchNumberSearch = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.faBatchNumber = faBatchNumber
    setSearchReq(req)
    refreshData(req)
  }

  const handleOrganizersUserIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.organizersUserId = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined

    req.personResponsible = undefined
    req.personResponsibleName = undefined
    req.organizersUserId = undefined // 主办人员
    req.organizersUserName = undefined // 主办人员名
    req.faSaveDate1 = undefined
    req.faSaveDate2 = undefined
    req.faSubmitter = undefined
    req.faRecipient = undefined
    req.faType = undefined
    req.faBatchNumber = undefined
    req.status = undefined

    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <HeaderAction
        size={props.size}
        isHiddenOptions={props.isHiddenOptions}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        selectedRowKeys={props.selectedRowKeys}
        handleDeleteBatch={props.handleDeleteBatch}
        handleAdd={props.handleAdd}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <SelectDict
            updateCount={props.updateCount}
            value={searchReq?.faType}
            onChange={handleFaTypeChange}
            className={'width-100-percentage'}
            code={'fa_file_type'}
            allowClear
            placeholder={'请选择文件类别'}/>,
          <Input
            onChange={(e) => setFaBatchNumber(e.target.value)}
            value={faBatchNumber}
            onPressEnter={handleFaBatchNumberSearch}
            allowClear={{clearIcon: <ClearOutlined onClick={handleFaBatchNumberClear}/>}}
            placeholder="请输入存档批号"
            suffix={<SearchOutlined onClick={handleFaBatchNumberSearch}/>}
          />,
          <DatePicker.RangePicker
            value={searchReq?.faSaveDate1 ? [dayjs(searchReq?.faSaveDate1), dayjs(searchReq?.faSaveDate2)] : [undefined, undefined]}
            placeholder={['存档开始日期', '存档结束日期']}
            allowClear
            onChange={handleFaTimeChange}
            className={"width-100-percentage"}/>,
          /*<SelectPersonCharge
            updateCount={props.updateCount}
            value={searchReq?.faSubmitter}
            onChange={handleFaSubmitterChange}
            className={"width-100-percentage"}
            placeholder={"请选择交件人"}
            allowClear/>,
          <SelectPersonCharge
            updateCount={props.updateCount}
            value={searchReq?.faRecipient}
            onChange={handleFaRecipientChange}
            className={"width-100-percentage"}
            placeholder={"请选择收件人"}
            allowClear/>,*/
          <SelectPersonCharge
            updateCount={props.updateCount}
            onChange={handlePersonResponsibleChange}
            className={"width-100-percentage"}
            value={searchReq?.personResponsible}
            placeholder={"请选择办理人员"}
            allowClear/>,
          <SelectPersonCharge
            onChange={handleOrganizersUserIdChange}
            className={"width-100-percentage"}
            placeholder={"请选择主办人员"}
            value={searchReq?.organizersUserId}
            allowClear/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
          <SelectCooperativeUnit
            onChange={handleCooperationUnitChange}
            value={searchReq?.cooperationUnit}
            allowClear
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectProcessStatus
            onChange={handleStatusChange}
            allowClear
            value={searchReq?.status}
            placeholder={'请选择办理进度'}
            className={"width-100-percentage"}/>,
        ] : []}/>
    </>
  )
}))
