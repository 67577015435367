import React, {FC, useState, useEffect} from "react";
import {
  Button,
  Table,
  Space,
  message,
  Modal,
  Row,
  Col,
  Input,
  Card,
  DatePicker,
  Upload, Drawer
} from 'antd'
import {ColumnsType} from "antd/es/table";
import {DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";
import {SuppliesRow, PageParam} from "@services/dto/supplies";
import {FormInstance} from "antd/es";
import {BasicInfo} from './Components/BasicInfo'
import {allStores} from "@/stores";
import {observer} from "mobx-react";
import {getSuppliesStatusLabel, logger, showErr, useUploadProps} from "@common/utils";
import {BASE_API_PATH, DEFAULT_MODAL_WIDTH} from "@/config";
import { SuppliesService } from "@/services/SuppliesService";
import {previewFile} from "@components/CustomUpload";
import {UploadChangeParam} from "antd/es/upload/interface";
import dayjs from "dayjs";
import "./index.less"

type RangeValue = [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;

export const Supplies: FC = observer(() => {
  const basicInfo = React.createRef<FormInstance>();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isView, setIsView] = useState<boolean>(false);
  const [suppliesObj, setSuppliesObj] = useState<SuppliesRow>()
  const [dataSource, setDataSource] = useState<SuppliesRow[]>([]);
  const {suppliesStore} = allStores;
  const [total, setTotal] = useState<number>(0)
  const [suppliesReq, setSuppliesReq] = useState<PageParam>({perPage: 10, pageNum: 1})
  const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>("")
  const [timeValue, setTimeValue] = useState<RangeValue>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const columns: ColumnsType<SuppliesRow> = [
    {
      title: '物资名称',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <Button type={"link"} onClick={() => { handleView(record) }}>{ text }</Button>
        </Space>
      )
    },
    {
      title: '物资编号',
      dataIndex: 'code',
      key: 'code',
      align: 'center'
    },
    {
      title: '领用时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <div>{ new Date(text).toLocaleDateString() }</div>
        </Space>
      )
    },
    {
      title: '数量',
      dataIndex: 'num',
      key: 'num',
      align: 'center'
    },
    {
      title: '单位',
      dataIndex: 'unit',
      key: 'unit',
      align: 'center'
    },
    {
      title: '物资规格',
      dataIndex: 'specifications',
      key: 'specifications',
      align: 'center'
    },
    {
      title: '领用班',
      dataIndex: 'professionalLeagues',
      key: 'professionalLeagues',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          <div>{ record.professionalLeagues.name }</div>
        </Space>
      )
    },
    {
      title: '领用人',
      dataIndex: 'userName',
      key: 'userName',
      align: 'center'
    },
    {
      title: '办理进度',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (text, record, index) => (
        <Space>
          { getSuppliesStatusLabel(text) }
        </Space>
      )
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record, index) => (
        <Space>
          <Button shape="round" className={"view-btn"} size={"middle"} icon={<EditOutlined/>}
            onClick={() => { handleView(record) }}>查看</Button>
          <Button shape="round" className={"edit-btn"} size={"middle"} icon={<EditOutlined/>}
            onClick={() => { handleEdit(record) }}>修改</Button>
          <Button shape="round" className={"delete-btn"} size={"middle"}
            icon={<DeleteOutlined/>} onClick={() => { handleDelete(record) }}>删除</Button>
        </Space>
      )
    }
  ];

  useEffect(() => {
    refreshData(suppliesReq)
  }, [])

  const refreshData = (suppliesRequest: PageParam) => {
    suppliesStore.getSupplies(suppliesRequest)
  }

  useEffect(() => {
    setDataSource(suppliesStore.suppliesDatasource.data?.items!)
    setTotal(suppliesStore.suppliesDatasource.data?.total!)
  }, [suppliesStore.suppliesDatasource])

  const handleDelete = (record: SuppliesRow) => {
    Modal.confirm({
      title: "是否删除？",
      okText: "确定",
      cancelText: "取消",
      onOk() {
        setConfirmLoading(true)
        SuppliesService.deleteSupplies(record.id).then(() => {
          message.success("删除成功！")
          setConfirmLoading(false)
          refreshData(suppliesReq)
        }).catch(showErr).finally(() => setConfirmLoading(false))
      },
    })
  }

  const handleView = (record: SuppliesRow) => {
    setSuppliesObj(record)
    setIsView(true)
    setIsModalVisible(true);
  }

  const handleEdit = (record: SuppliesRow) => {
    setSuppliesObj(record)
    setIsView(false)
    setIsModalVisible(true);
  }

  const handleAdd = () => {
    setSuppliesObj(undefined)
    setIsView(false)
    setIsModalVisible(true);
  };

  const handleOk = () => {
    const suppliesRow = {...suppliesObj!}
    basicInfo.current!.validateFields().then(values => {
      Object.assign(suppliesRow, values)
      suppliesRow.professionalLeagues = {
        id: values.professionalLeagues.value,
        name: values.professionalLeagues.label
      }
      suppliesRow.projectId = values.project.value
      suppliesRow.projectName = values.project.label
      suppliesRow.userId = values.user.value
      suppliesRow.userName = values.user.label
      setConfirmLoading(true)
      if (suppliesRow.id) {
        SuppliesService.updateSupplies(suppliesRow.id, suppliesRow).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      } else {
        SuppliesService.addSupplies(suppliesRow).then(rsp => {
          actionThen()
        }).catch(showErr).finally(() => setConfirmLoading(false));
      }
    }).catch(logger.warn)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSuppliesObj(undefined)
  };

  const actionThen = () => {
    message.success("操作成功！")
    setIsModalVisible(false);
    setSuppliesObj(undefined)
    refreshData(suppliesReq)
  }

  const handlePageChange = (page: number, pageSize: number) => {
    let suppliesRequest = {...suppliesReq};
    suppliesRequest.perPage = pageSize
    suppliesRequest.pageNum = page
    setSuppliesReq(suppliesRequest)
    refreshData(suppliesRequest)
  }

  const onSearch = () => {
    const req = {...suppliesReq};
    req.pageNum = 1
    req.keyword = searchValue
    setSuppliesReq(req)
    refreshData(req)
  }
  
  const handleTimeChange = (dates: RangeValue, dateStrings: [string, string]) => {
    setTimeValue(dates)
    const req = {...suppliesReq};
    req.pageNum = 1
    req.startTime = dateStrings[0]
    req.endTime = dateStrings[1]
    setSuppliesReq(req)
    refreshData(req)
  }
  
  const downloadTemplate = () => {
    window.open(BASE_API_PATH + "/filepath/物资管理.xlsx")
  }
  
  const exportTemplate = () => {
    if (selectedRowKeys.length === 0) {
      message.warning("请选择导出数据！")
      return
    }
    setConfirmLoading(true)
    const ids:string[] = selectedRowKeys.map(item => String(item))
    SuppliesService.exportTemplate(ids).then(rsp => {
      window.open(rsp.data, "_blank")
    }).catch(showErr).finally(() => setConfirmLoading(false))
  }
  
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  }

  const uploadChange = (info: UploadChangeParam) => {
    if (info.file.status === 'done') {
      if (info.file.response.data) {
        message.success("文件个别导入失败, 失败列表");
        previewFile(info.file.response.data)
      } else {
        message.success(`${info.file.name} 文件导入成功`);
      }
      refreshData(suppliesReq)
    }
  }

  return (
    <div className="supplies">
      <Row gutter={16}>
        <Col xl={4} md={6} xs={12}>
          <DatePicker.RangePicker value={timeValue} onChange={handleTimeChange}/>
        </Col>
        <Col xl={4} md={6} xs={12}>
          <Input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={onSearch}
            allowClear
            placeholder="请输入物资编号/名称"
            suffix={<SearchOutlined onClick={onSearch}/>}
          />
        </Col>
        <Col xl={16} md={12} xs={24} className={"button-container"}>
          <Button size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={downloadTemplate}>导入模板下载</Button>

          <Upload {...useUploadProps("/v1/supplies/template/import", uploadChange)}>
            <Button size={"middle"} shape={"round"} className={"margin-right-xs"}>导入</Button>
          </Upload>

          <Button size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={exportTemplate}>导出</Button>
          <Button icon={<PlusOutlined/>} type={"primary"} size={"middle"} shape={"round"}
            onClick={handleAdd}>新增</Button>
        </Col>
      </Row>

      <Card className={"margin-top-sm"}>
        <Table 
          rowKey={"id"} 
          dataSource={dataSource} 
          columns={columns} 
          size={"middle"}  
          loading={suppliesStore.suppliesDatasource.loading || confirmLoading}
          pagination={{
            total: total,
            position: ['bottomCenter'],
            pageSize: suppliesReq.perPage, 
            current: suppliesReq.pageNum, 
            showSizeChanger: true, 
            showQuickJumper: true,
            showTotal: total => `共${total}条`, 
            onChange: handlePageChange
          }}
          rowSelection={{selectedRowKeys, onChange: onSelectChange}}/>
      </Card>

      <Drawer
        title={<div className="text-align-center">物资管理录入</div>} 
        width={DEFAULT_MODAL_WIDTH} 
        open={isModalVisible}
        onClose={handleCancel}>

        <BasicInfo row={{...suppliesObj!}} ref={basicInfo} isView={isView}/>

        <div className={"drawer-bottom"}>
          <Button onClick={handleCancel}>取消</Button>
          {
            !isView && <Button type="primary" onClick={handleOk} loading={confirmLoading} className={"margin-left-xs"}>确定</Button>
          }
        </div>
      </Drawer>
    </div>
  )
})
