import React, {FC, useState} from "react";
import {observer} from "mobx-react";
import {Button, Col, DatePicker, Input, Row} from "antd";
import {DeleteOutlined, LeftOutlined, RightOutlined, SearchOutlined, ExportOutlined, CheckCircleOutlined} from "@ant-design/icons";
import {handleDateAddSubtract} from "@common/utils";
import dayjs from "dayjs";
import {SelectProject} from "@components/SelectProject";
import "./MonthHeaderAction.less"

export interface PropsType {
  handleProjectChange: (value: string) => void
  handleSearch: (value: string) => void
  refreshData: () => void
  handleBatchDelete: () => void
  handleDateChange: (year: number, month: number) => void
  exportSalary: () => void
  type: number
  handleGenerate: (year: number, month: number) => void
  loading?: boolean
}

export const MonthHeaderAction:FC<PropsType> = observer((props: PropsType) => {
  const [searchValue, setSearchValue] = useState<string>("")
  const [dateValue, setDateValue] = useState<dayjs.Dayjs>(dayjs())

  const handleProjectChange = (value: string) => {
    props.handleProjectChange(value)
  }

  const handleSearch = () => {
    props.handleSearch(searchValue)
  }

  const handleAddSubtract = (action: string) => {
    let now_calc = handleDateAddSubtract(action, dateValue);
    setDateValue(now_calc);
    props.handleDateChange(now_calc.year(), now_calc.month() + 1)
  }

  const handleDateChange = (date: dayjs.Dayjs | null, dateString: string) => {
    if (date) {
      setDateValue(date);
      props.handleDateChange(date.year(), date.month() + 1)
    }
  }

  const handleBatchDelete = () => {
    props.handleBatchDelete()
  }

  const exportSalary = () => {
    props.exportSalary()
  }

  const handleGenerate = () => {
    props.handleGenerate(dateValue.year(), dateValue.month() + 1)
  }

  return (
    <>
      <Row>
        <Col offset={8} xs={8} className={"date-center"}>
          <Button icon={<LeftOutlined/>} onClick={() => handleAddSubtract('subtract')}></Button>
          <DatePicker
            bordered={false}
            allowClear={false}
            value={dateValue}
            disabledDate={(cur) => cur.isAfter(dayjs())}
            onChange={(date, dateString: string) => handleDateChange(date, dateString)}
            className={"date-style"}
            format={"YYYY年MM月"}
            picker={"month"}/>
          <Button disabled={dateValue.month() === dayjs().month() && dateValue.year() === dayjs().year()} icon={<RightOutlined/>} onClick={() => handleAddSubtract('add')}></Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xl={4} xs={12} className={"button-container"}>
          <SelectProject allowClear onChange={handleProjectChange}/>
        </Col>
        <Col xl={4} xs={12} className={"button-container"}>
          <Input
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={handleSearch}
            allowClear
            placeholder="请输入姓名/身份证号"
            suffix={<SearchOutlined onClick={handleSearch}/>}
          />
        </Col>
        <Col xl={16} xs={24} className={"button-container"}>
          <Button loading={props.loading} size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={handleGenerate} icon={<CheckCircleOutlined />}>生成</Button>

          <Button loading={props.loading} size={"middle"} shape={"round"} className={"margin-right-xs"}
            onClick={exportSalary} icon={<ExportOutlined />}>导出薪酬</Button>

          <Button loading={props.loading} size={"middle"} shape={"round"} type={"primary"}
            icon={<DeleteOutlined/>} onClick={handleBatchDelete}>删除</Button>
        </Col>
      </Row>
    </>
  )
})
