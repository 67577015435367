import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {HeaderAction, PropsType as ParentPropsType} from "@components/HeaderAction";
import {ColumnsType} from "antd/es/table";
import {ProcessReq} from "@services/dto/process";
import {DatePicker} from "antd";
import dayjs from "dayjs";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {Any} from "@common/types";
import {SelectNotificationStatus} from "@components/SelectNotificationStatus";

export interface PropsType extends ParentPropsType {
  selectedRowKeys?: React.Key[], // 所选行
  handleDeleteBatch?: () => void,
  handleAdd?: () => void,
  handleKeywordSearch?: () => void,
  handleKeywordChange?: (value: string) => void,
  handleTableColumnsChange?: (value: string[]) => void,
  showColumn?: string[],
  tableColumns?: ColumnsType,
  refreshData?: (req: ProcessReq) => void,
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<ProcessReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: ProcessReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonResponsibleChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleNotificationScopeUserIdChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.notificationScopeUserId = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleWnStatusChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.wnStatus = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleWnDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.wnDate1 = dateStrings[0]
    req.wnDate2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined

    req.personResponsible = undefined
    req.personResponsibleName = undefined
    req.notificationScopeUserId = undefined
    req.notificationScopeUserName = undefined
    req.wnDate1 = undefined
    req.wnDate2 = undefined
    req.wnStatus = undefined

    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <HeaderAction
        size={props.size}
        isHiddenOptions={props.isHiddenOptions}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        selectedRowKeys={props.selectedRowKeys}
        handleDeleteBatch={props.handleDeleteBatch}
        handleAdd={props.handleAdd}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={[
          <DatePicker.RangePicker
            allowClear
            value={searchReq?.wnDate1 ? [dayjs(searchReq?.wnDate1), dayjs(searchReq?.wnDate2)] : [undefined, undefined]}
            onChange={handleWnDateChange}
            placeholder={['制单开始日期', '制单结束日期']}
            className={"width-100-percentage"}/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
          <SelectCooperativeUnit
            onChange={handleCooperationUnitChange}
            value={searchReq?.cooperationUnit}
            allowClear
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectPersonCharge
            onChange={handlePersonResponsibleChange}
            className={"width-100-percentage"}
            placeholder={"请选择主送"}
            value={searchReq.personResponsible}
            allowClear/>,
          <SelectPersonCharge
            onChange={handleNotificationScopeUserIdChange}
            className={"width-100-percentage"}
            placeholder={"请选择抄送"}
            value={searchReq.notificationScopeUserId}
            allowClear/>,
          <SelectNotificationStatus
            onChange={handleWnStatusChange}
            allowClear
            value={searchReq?.wnStatus}
            placeholder={'请选择办理进度'}
            className={"width-100-percentage"}/>,
        ]}/>
    </>
  )
}))
