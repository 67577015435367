import React, {useEffect, useState} from "react";
import {Divider, Pagination, Select, Space} from "antd";
import {Any} from "@common/types";
import {observer} from "mobx-react";
import {allStores} from "@/stores";
import {DictItem, StatisticsItemsReq, StatisticsItemsRow} from "@services/dto/dict";
import {DictService} from "@services/DictService";
import {showErr} from "@common/utils";
import {BLACK_LIST} from "@/config";
import {
  DatasourceType,
  defaultFieldNames,
  FieldNameListType,
  FieldNameType,
  SearchReqType
} from "@services/dto/applicationForm";

export interface PropsType {
  onChange?: (value: Any, options: DictItem | DictItem[]) => void
  value?: string
  handleTableValueChange?: (value: string | string[], options: Any | Any[]) => void
  tableValue?: string[] | string
  mode?: 'multiple' | 'tags'
  labelInValue?: boolean
  disabled?: boolean
  typeDisabled?: boolean // 类型框是否禁选
  fieldNameList?: FieldNameListType // 字段名列表
  isDisallowedDistinctionType?: boolean // 禁选是否区分"表类型"和"表值"
  className?: string
  allowClear?: boolean
  placeholder?: string
  isOption?: boolean
  statisticsItemsReq?: StatisticsItemsReq
  updateCount?: number
  isOnlyUnBindFa?: string[]
  isOnlyUnBindIr?: string[]
  showKey?: string[]
  style?: React.CSSProperties
  defaultItems?: { id: string, value: string }[]
}

export const SelectApplicationFormType = observer((props: PropsType) => {
  const [datasource, setDatasource] = useState<DatasourceType[]>([])
  const [total, setTotal] = useState(0)
  const [items, setItems] = useState<DatasourceType[]>([])
  const [fieldNames, setFieldNames] = useState<FieldNameType>(defaultFieldNames)
  const [loading, setLoading] = useState<boolean>(false)
  const [dict, setDict] = useState<DictItem[]>([])
  const [statisticsItems, setStatisticsItems] = useState<StatisticsItemsRow[]>([])
  const [defaultItems, setDefaultItems] = useState<Any[]>([
    {[fieldNames.value]: 'N/A', [fieldNames.label]: 'N/A'},
    {[fieldNames.value]: '空白', [fieldNames.label]: '空白'},
  ])
  const [searchReq, setSearchReq] = useState<SearchReqType>({
    pageNum: 1,
    perPage: 10
  })
  const {
    wrStore,
    payrollStore,
    costApplicationStore,
    bankStatementStore,
    contractSettlementStore,
    staffLoanStore,
    costReimbursementStore,
    costExpendStore,
    taxAccountingStore,
    finalSettlementStore,
    projectCollaborationStore,
    taskSupervisionStore,
    workScheduleStore,
  } = allStores

  /**
   * 初始化
   */
  useEffect(() => {
    if (props.statisticsItemsReq) {
      // 统计查询
      getStatisticsItems()
    } else {
      // 查询字典
      getByKey()
    }
    refreshData(searchReq)
  }, [props.showKey])

  /**
   * 办理进度数据变化
   */
  useEffect(() => {
    switch (props.value) {
    case '员工档案':
      if (wrStore.workerRecruitmentSelectDatasource.data) {
        setDatasource(wrStore.workerRecruitmentSelectDatasource.data.items || [])
        setTotal(wrStore.workerRecruitmentSelectDatasource.data.total || 0)
        if (wrStore.workerRecruitmentDetails.data?.length) {
          setItems(wrStore.workerRecruitmentDetails.data
            ?.filter(item => !(wrStore.workerRecruitmentSelectDatasource.data.items || [])
              .some(o => o._id === item._id)))
        }
      }
      break
    case '工资发放':
      if (payrollStore.payrollSelectDatasource.data) {
        setDatasource(payrollStore.payrollSelectDatasource.data.items || [])
        setTotal(payrollStore.payrollSelectDatasource.data.total || 0)
        if (payrollStore.payrollDetails.data?.data.length) {
          setItems(payrollStore.payrollDetails.data?.data
            .filter(item => !(payrollStore.payrollSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '费用申请':
      if (costApplicationStore.costApplicationSelectDatasource.data) {
        setDatasource(costApplicationStore.costApplicationSelectDatasource.data.items || [])
        setTotal(costApplicationStore.costApplicationSelectDatasource.data.total || 0)
        if (costApplicationStore.costApplicationDetails.data?.data.length) {
          setItems(costApplicationStore.costApplicationDetails.data?.data
            .filter(item => !(costApplicationStore.costApplicationSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '社保清缴':
      break
    case '员工借款':
      if (staffLoanStore.staffLoanSelectDatasource.data) {
        setDatasource(staffLoanStore.staffLoanSelectDatasource.data.items || [])
        setTotal(staffLoanStore.staffLoanSelectDatasource.data.total || 0)
        if (staffLoanStore.staffLoanDetails.data?.data.length) {
          setItems(staffLoanStore.staffLoanDetails.data?.data
            .filter(item => !(staffLoanStore.staffLoanSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '费用报销':
      if (costReimbursementStore.costReimbursementSelectDatasource.data) {
        setDatasource(costReimbursementStore.costReimbursementSelectDatasource.data.items || [])
        setTotal(costReimbursementStore.costReimbursementSelectDatasource.data.total || 0)
        if (costReimbursementStore.costReimbursementDetails.data?.data.length) {
          setItems(costReimbursementStore.costReimbursementDetails.data?.data
            .filter(item => !(costReimbursementStore.costReimbursementSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '费用支出':
      if (costExpendStore.costExpendSelectDatasource.data) {
        setDatasource(costExpendStore.costExpendSelectDatasource.data.items || [])
        setTotal(costExpendStore.costExpendSelectDatasource.data.total || 0)
        if (costExpendStore.costExpendDetails.data?.data.length) {
          setItems(costExpendStore.costExpendDetails.data?.data
            .filter(item => !(costExpendStore.costExpendSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '合同结算':
      if (contractSettlementStore.contractSettlementSelectDatasource.data) {
        setDatasource(contractSettlementStore.contractSettlementSelectDatasource.data.items || [])
        setTotal(contractSettlementStore.contractSettlementSelectDatasource.data.total || 0)
        if (contractSettlementStore.contractSettlementDetails.data?.data.length) {
          setItems(contractSettlementStore.contractSettlementDetails.data?.data
            .filter(item => !(contractSettlementStore.contractSettlementSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '税费核算':
      if (taxAccountingStore.taxAccountingSelectDatasource.data) {
        setDatasource(taxAccountingStore.taxAccountingSelectDatasource.data.items || [])
        setTotal(taxAccountingStore.taxAccountingSelectDatasource.data.total || 0)
        if (taxAccountingStore.taxAccountingDetails.data?.data.length) {
          setItems(taxAccountingStore.taxAccountingDetails.data?.data
            .filter(item => !(taxAccountingStore.taxAccountingSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '税费缴纳':
      if (finalSettlementStore.finalSettlementSelectDatasource.data) {
        setDatasource(finalSettlementStore.finalSettlementSelectDatasource.data.items || [])
        setTotal(finalSettlementStore.finalSettlementSelectDatasource.data.total || 0)
        if (finalSettlementStore.finalSettlementDetails.data?.data.length) {
          setItems(finalSettlementStore.finalSettlementDetails.data?.data
            .filter(item => !(finalSettlementStore.finalSettlementSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '资金出纳':
      if (bankStatementStore.bankStatementSelectDatasource.data) {
        setDatasource(bankStatementStore.bankStatementSelectDatasource.data.items || [])
        setTotal(bankStatementStore.bankStatementSelectDatasource.data.total || 0)
        if (bankStatementStore.bankStatementDetails.data?.data.length) {
          setItems(bankStatementStore.bankStatementDetails.data?.data
            .filter(item => !(bankStatementStore.bankStatementSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '项目协作':
      if (projectCollaborationStore.projectCollaborationSelectDatasource.data) {
        setDatasource(projectCollaborationStore.projectCollaborationSelectDatasource.data.items || [])
        setTotal(projectCollaborationStore.projectCollaborationSelectDatasource.data.total || 0)
        if (projectCollaborationStore.projectCollaborationDetails.data?.data.length) {
          setItems(projectCollaborationStore.projectCollaborationDetails.data?.data
            .filter(item => !(projectCollaborationStore.projectCollaborationSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '工作任务':
      if (taskSupervisionStore.taskSupervisionSelectDatasource.data) {
        setDatasource(taskSupervisionStore.taskSupervisionSelectDatasource.data.items || [])
        setTotal(taskSupervisionStore.taskSupervisionSelectDatasource.data.total || 0)
        if (taskSupervisionStore.taskSupervisionDetails.data?.data.length) {
          setItems(taskSupervisionStore.taskSupervisionDetails.data?.data
            .filter(item => !(taskSupervisionStore.taskSupervisionSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break
    case '工作日程':
      if (workScheduleStore.workScheduleSelectDatasource.data) {
        setDatasource(workScheduleStore.workScheduleSelectDatasource.data.items || [])
        setTotal(workScheduleStore.workScheduleSelectDatasource.data.total || 0)
        if (workScheduleStore.workScheduleDetails.data?.data.length) {
          setItems(workScheduleStore.workScheduleDetails.data?.data
            .filter(item => !(workScheduleStore.workScheduleSelectDatasource.data.items || [])
              .some(o => o.id === item.id)))
        }
      }
      break

    default:
      break
    }
  }, [
    wrStore.workerRecruitmentDetails, wrStore.workerRecruitmentSelectDatasource.data,
    costApplicationStore.costApplicationDetails, costApplicationStore.costApplicationSelectDatasource.data,
    payrollStore.payrollDetails, payrollStore.payrollSelectDatasource.data,
    finalSettlementStore.finalSettlementDetails, finalSettlementStore.finalSettlementSelectDatasource.data,
    taxAccountingStore.taxAccountingDetails, taxAccountingStore.taxAccountingSelectDatasource.data,
    costReimbursementStore.costReimbursementDetails, costReimbursementStore.costReimbursementSelectDatasource.data,
    staffLoanStore.staffLoanDetails, staffLoanStore.staffLoanSelectDatasource.data,
    contractSettlementStore.contractSettlementDetails, contractSettlementStore.contractSettlementSelectDatasource.data,
    bankStatementStore.bankStatementDetails, bankStatementStore.bankStatementSelectDatasource.data,
    costExpendStore.costExpendSelectDatasource.data, costExpendStore.costExpendDetails,
    projectCollaborationStore.projectCollaborationDetails, projectCollaborationStore.projectCollaborationSelectDatasource.data,
    taskSupervisionStore.taskSupervisionSelectDatasource.data, taskSupervisionStore.taskSupervisionDetails,
    workScheduleStore.workScheduleSelectDatasource.data, workScheduleStore.workScheduleDetails
  ])

  /**
   * left值改变
   */
  useEffect(() => {
    const req = {...searchReq}
    req.pageNum = 1
    setSearchReq(req)
    refreshData(req)
    switch (props.value) {
    case '员工档案':
      setFieldNames((props.fieldNameList && props.fieldNameList['员工档案']) || {value: "_id", label: "name"})
      break
    case '工资发放':
      setFieldNames((props.fieldNameList && props.fieldNameList['工资发放']) || {value: "id", label: "payrollNumber"})
      break
    case '费用申请':
      setFieldNames((props.fieldNameList && props.fieldNameList['费用申请']) || {value: "id", label: "efaApplyCode"})
      break
    case '社保清缴':
      break
    case '员工借款':
      setFieldNames((props.fieldNameList && props.fieldNameList['员工借款']) || {value: "id", label: "elReqCode"})
      break
    case '费用报销':
      setFieldNames((props.fieldNameList && props.fieldNameList['费用报销']) || {value: "id", label: "erApprovalCode"})
      break
    case '费用支出':
      setFieldNames((props.fieldNameList && props.fieldNameList['费用支出']) || {value: "id", label: "efaApplyCode"})
      break
    case '合同结算':
      setFieldNames((props.fieldNameList && props.fieldNameList['合同结算']) || {value: "id", label: "csCode"})
      break
    case '税费核算':
      setFieldNames((props.fieldNameList && props.fieldNameList['税费核算']) || {value: "id", label: "taCode"})
      break
    case '税费缴纳':
      setFieldNames((props.fieldNameList && props.fieldNameList['税费缴纳']) || {value: "id", label: "fsCode"})
      break
    case '资金出纳':
      setFieldNames((props.fieldNameList && props.fieldNameList['资金出纳']) || {value: "id", label: "bsCode"})
      break
    case '项目协作':
      setFieldNames((props.fieldNameList && props.fieldNameList['项目协作']) || {value: "id", label: "cuName"})
      break
    case '工作任务':
      setFieldNames((props.fieldNameList && props.fieldNameList['工作任务']) || {value: "id", label: "tsCode"})
      break
    case '工作日程':
      setFieldNames((props.fieldNameList && props.fieldNameList['工作任务']) || {value: "id", label: "wsCode"})
      break

    default:
      break
    }
  }, [props.value]);

  /**
   * right值改变
   */
  useEffect(() => {
    switch (props.value) {
    case '员工档案':
      if (typeof props.tableValue === 'string' && !BLACK_LIST.includes(props.tableValue)) {
        wrStore.getBatch([props.tableValue])
      } else if (typeof props.tableValue === 'object') {
        const ids = props.tableValue.filter((v: string) => !BLACK_LIST.includes(v))
        if (ids.length) {
          wrStore.getBatch(ids)
        }
      }
      break
    case '工资发放':
      if (typeof props.tableValue === 'string' && !BLACK_LIST.includes(props.tableValue)) {
        payrollStore.getBatch([props.tableValue])
      } else if (typeof props.tableValue === 'object') {
        const ids = props.tableValue.filter((v: string) => !BLACK_LIST.includes(v))
        if (ids.length) {
          payrollStore.getBatch(ids)
        }
      }
      break
    case '费用申请':
      if (typeof props.tableValue === 'string' && !BLACK_LIST.includes(props.tableValue)) {
        costApplicationStore.getBatch([props.tableValue])
      } else if (typeof props.tableValue === 'object') {
        const ids = props.tableValue.filter((v: string) => !BLACK_LIST.includes(v))
        if (ids.length) {
          costApplicationStore.getBatch(ids)
        }
      }
      break
    case '社保清缴':
      break
    case '员工借款':
      if (typeof props.tableValue === 'string' && !BLACK_LIST.includes(props.tableValue)) {
        staffLoanStore.getBatch([props.tableValue])
      } else if (typeof props.tableValue === 'object') {
        const ids = props.tableValue.filter((v: string) => !BLACK_LIST.includes(v))
        if (ids.length) {
          staffLoanStore.getBatch(ids)
        }
      }
      break
    case '费用报销':
      if (typeof props.tableValue === 'string' && !BLACK_LIST.includes(props.tableValue)) {
        costReimbursementStore.getBatch([props.tableValue])
      } else if (typeof props.tableValue === 'object') {
        const ids = props.tableValue.filter((v: string) => !BLACK_LIST.includes(v))
        if (ids.length) {
          costReimbursementStore.getBatch(ids)
        }
      }
      break
    case '费用支出':
      if (typeof props.tableValue === 'string' && !BLACK_LIST.includes(props.tableValue)) {
        costExpendStore.getBatch([props.tableValue])
      } else if (typeof props.tableValue === 'object') {
        const ids = props.tableValue.filter((v: string) => !BLACK_LIST.includes(v))
        if (ids.length) {
          costExpendStore.getBatch(ids)
        }
      }
      break
    case '合同结算':
      if (typeof props.tableValue === 'string' && !BLACK_LIST.includes(props.tableValue)) {
        contractSettlementStore.getBatch([props.tableValue])
      } else if (typeof props.tableValue === 'object') {
        const ids = props.tableValue.filter((v: string) => !BLACK_LIST.includes(v))
        if (ids.length) {
          contractSettlementStore.getBatch(ids)
        }
      }
      break
    case '税费核算':
      if (typeof props.tableValue === 'string' && !BLACK_LIST.includes(props.tableValue)) {
        taxAccountingStore.getBatch([props.tableValue])
      } else if (typeof props.tableValue === 'object') {
        const ids = props.tableValue.filter((v: string) => !BLACK_LIST.includes(v))
        if (ids.length) {
          taxAccountingStore.getBatch(ids)
        }
      }
      break
    case '税费缴纳':
      if (typeof props.tableValue === 'string' && !BLACK_LIST.includes(props.tableValue)) {
        finalSettlementStore.getBatch([props.tableValue])
      } else if (typeof props.tableValue === 'object') {
        const ids = props.tableValue.filter((v: string) => !BLACK_LIST.includes(v))
        if (ids.length) {
          finalSettlementStore.getBatch(ids)
        }
      }
      break
    case '资金出纳':
      if (typeof props.tableValue === 'string' && !BLACK_LIST.includes(props.tableValue)) {
        bankStatementStore.getBatch([props.tableValue])
      } else if (typeof props.tableValue === 'object') {
        const ids = props.tableValue.filter((v: string) => !BLACK_LIST.includes(v))
        if (ids.length) {
          bankStatementStore.getBatch(ids)
        }
      }
      break
    case '项目协作':
      if (typeof props.tableValue === 'string' && !BLACK_LIST.includes(props.tableValue)) {
        projectCollaborationStore.getBatch([props.tableValue])
      } else if (typeof props.tableValue === 'object') {
        const ids = props.tableValue.filter((v: string) => !BLACK_LIST.includes(v))
        if (ids.length) {
          projectCollaborationStore.getBatch(ids)
        }
      }
      break
    case '工作任务':
      if (typeof props.tableValue === 'string' && !BLACK_LIST.includes(props.tableValue)) {
        taskSupervisionStore.getBatch([props.tableValue])
      } else if (typeof props.tableValue === 'object') {
        const ids = props.tableValue.filter((v: string) => !BLACK_LIST.includes(v))
        if (ids.length) {
          taskSupervisionStore.getBatch(ids)
        }
      }
      break
    case '工作日程':
      if (typeof props.tableValue === 'string' && !BLACK_LIST.includes(props.tableValue)) {
        workScheduleStore.getBatch([props.tableValue])
      } else if (typeof props.tableValue === 'object') {
        const ids = props.tableValue.filter((v: string) => !BLACK_LIST.includes(v))
        if (ids.length) {
          //1查询未绑定 0查询已绑定.getBatch(ids)
        }
      }
      break

    default:
      break
    }
  }, [props.tableValue])

  useEffect(() => {
    setDefaultItems([
      {[fieldNames.value]: 'N/A', [fieldNames.label]: 'N/A'},
      {[fieldNames.value]: '空白', [fieldNames.label]: '空白'},
    ])
  }, [fieldNames])

  const refreshData = (req: SearchReqType) => {
    switch (props.value) {
    case '工资发放':
      payrollStore.getSelectPayroll({
        ...req,
        isTaApplicationForm: props.isOnlyUnBindFa?.includes('工资发放') ? "1" : undefined,
        isBind: props.isOnlyUnBindIr?.includes('工资发放') ? "否" : undefined,
      })
      break
    case '员工档案':
      wrStore.getSelectWorkerRecruitments({
        ...req,
        status: "hired",
        isTaApplicationForm: props.isOnlyUnBindFa?.includes('工资发放') ? "1" : undefined,
        isBind: props.isOnlyUnBindIr?.includes('工资发放') ? "否" : undefined,
      })
      break
    case '费用申请':
      costApplicationStore.getSelectCostApplication({
        ...req,
        isTaApplicationForm: props.isOnlyUnBindFa?.includes('费用申请') ? "1" : undefined,
        isBind: props.isOnlyUnBindIr?.includes('费用申请') ? "否" : undefined,
      })
      break
    case '社保清缴':
      break
    case '员工借款':
      staffLoanStore.getSelectStaffLoanRow({
        ...req,
        isTaApplicationForm: props.isOnlyUnBindFa?.includes('员工借款') ? "1" : undefined,
        isBind: props.isOnlyUnBindIr?.includes('员工借款') ? "否" : undefined,
      })
      break
    case '费用报销':
      costReimbursementStore.getSelectCostReimbursement({
        ...req,
        isTaApplicationForm: props.isOnlyUnBindFa?.includes('费用报销') ? "1" : undefined,
        isBind: props.isOnlyUnBindIr?.includes('费用报销') ? "否" : undefined,
      })
      break
    case '费用支出':
      costExpendStore.getSelectCostExpend({
        ...req,
        isTaApplicationForm: props.isOnlyUnBindFa?.includes('费用支出') ? "1" : undefined,
        isBind: props.isOnlyUnBindIr?.includes('费用支出') ? "否" : undefined,
      })
      break
    case '合同结算':
      contractSettlementStore.getSelectContractSettlement({
        ...req,
        isTaApplicationForm: props.isOnlyUnBindFa?.includes('合同结算') ? "1" : undefined,
        isBind: props.isOnlyUnBindIr?.includes('合同结算') ? "否" : undefined,
      })
      break
    case '税费核算':
      taxAccountingStore.getSelectTaxAccounting({
        ...req,
        isTaApplicationForm: props.isOnlyUnBindFa?.includes('税费核算') ? "1" : undefined,
        isBind: props.isOnlyUnBindIr?.includes('税费核算') ? "否" : undefined,
      })
      break
    case '税费缴纳':
      finalSettlementStore.getSelectFinalSettlement({
        ...req,
        isTaApplicationForm: props.isOnlyUnBindFa?.includes('税费缴纳') ? "1" : undefined,
        isBind: props.isOnlyUnBindIr?.includes('税费缴纳') ? "否" : undefined,
      })
      break
    case '资金出纳':
      bankStatementStore.getSelectBankStatement({
        ...req,
        isTaApplicationForm: props.isOnlyUnBindFa?.includes('资金出纳') ? "1" : undefined,
        isBind: props.isOnlyUnBindIr?.includes('资金出纳') ? "否" : undefined,
      })
      break
    case '项目协作':
      projectCollaborationStore.getSelectProjectCollaboration({
        ...req,
        isTaApplicationForm: props.isOnlyUnBindFa?.includes('项目协作') ? "1" : undefined,
        isBind: props.isOnlyUnBindIr?.includes('项目协作') ? "否" : undefined,
      })
      break
    case '工作任务':
      taskSupervisionStore.getSelectTaskSupervision({
        ...req,
        isTaApplicationForm: props.isOnlyUnBindFa?.includes('工作任务') ? "1" : undefined,
        isBind: props.isOnlyUnBindIr?.includes('工作任务') ? "否" : undefined,
      })
      break
    case '工作日程':
      workScheduleStore.getSelectWorkSchedule({
        ...req,
        isTaApplicationForm: props.isOnlyUnBindFa?.includes('工作日程') ? "1" : undefined,
        isBind: props.isOnlyUnBindIr?.includes('工作日程') ? "否" : undefined,
      })
      break

    default:
      setDatasource([])
      setTotal(0)
      break
    }
  }

  const handleChange = (v: string, options: DictItem | DictItem[]) => {
    if (props.onChange) {
      props.onChange(v, options)
    }
  }

  const handleTableValueChange = (v: string, options: Any[]) => {
    if (props.handleTableValueChange) {
      props.handleTableValueChange(v, options)
    }
  }

  const handlePaginationChange = (page: number, pageSize: number) => {
    const req = {...searchReq}
    req.pageNum = page
    req.perPage = pageSize
    setSearchReq(req)
    refreshData(req)
  }

  const handleSearch = (inputValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = inputValue
    setSearchReq(req)
    refreshData(req)
    return true
  }

  const getStatisticsItems = () => {
    setLoading(true)
    DictService.getStatisticsItems(props.statisticsItemsReq).then(rsp => {
      const row = JSON.parse(JSON.stringify(rsp.data || [])).map((item: StatisticsItemsRow) => {
        item.value = `${item.value}(${item.coun})`
        return item
      })
      setStatisticsItems(row);
    }).catch(showErr).finally(() => setLoading(false))
  }

  const getByKey = () => {
    setLoading(true)
    DictService.getByKey('fund_approval_related_form_type').then(rsp => {
      setDict(rsp.value.filter(o => props.showKey ? props.showKey.includes(o.value) : o.id).map(o=>({id:o.id,value:o.value})) || []);
    }).catch(showErr).finally(() => setLoading(false))
  }

  const leftNode = <Select
    allowClear={props.allowClear}
    value={props.value}
    onChange={handleChange}
    placeholder={props.placeholder || '关联申请表单'}
    showSearch
    style={props.isOption ? {width: '40%'} : {}}
    filterOption={(input: string, option) =>
      String(option?.value).toLowerCase().includes(input.toLowerCase())}
    labelInValue={props.labelInValue}
    className={'width-100-percentage left'}
    disabled={props.isDisallowedDistinctionType ? props.typeDisabled : props.disabled}
    fieldNames={props.statisticsItemsReq ? {value: 'id', label: 'value'} : {value: 'value', label: 'value'}}
    options={props.statisticsItemsReq ? [...statisticsItems] : [...(props.defaultItems || [
      {id: 'N/A', value: 'N/A'},
      {id: '空白', value: '空白'},
    ]), ...dict]}
    loading={loading}>
  </Select>

  const rightNode = props.isOption && <Select
    style={{width: '60%'}}
    allowClear={props.allowClear}
    placeholder={props.value ? `请选择${props.value}` : "请选择它表数据"}
    className={'width-100-percentage right'}
    dropdownRender={(menu) => (
      <>
        { menu }
        <Divider className={"divider-margin"}/>
        <Space>
          <Pagination
            total={total}
            pageSize={searchReq.perPage}
            size={"small"}
            onChange={handlePaginationChange}/>
        </Space>
      </>
    )}
    value={props.tableValue}
    onChange={handleTableValueChange}
    showSearch
    onClear={() => handleSearch('')}
    disabled={props.disabled}
    mode={props.mode}
    filterOption={false}
    onSearch={handleSearch}
    labelInValue={props.labelInValue}
    fieldNames={fieldNames}
    options={[
      ...defaultItems,
      ...datasource,
      ...items,
    ]}/>

  return (
    <>
      <Space.Compact
        className={props.className || 'width-100-percentage'}>
        { leftNode }
        { rightNode }
      </Space.Compact>
    </>
  )
})
