import React, {useEffect, useState} from "react";
import {DatePicker, Select} from "antd";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {SelectDict} from "@components/SelectDict";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {HeaderAction} from "@components/HeaderAction";
import {ColumnsType} from "antd/es/table";
import dayjs from "dayjs";
import {Any} from "@common/types";
import {CustomInputNumber} from "@components/CustomInputNumber";
import {SelectProject} from "@components/SelectProject";
import {CostApplicationReq} from "@services/dto/costApplication";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";

export interface PropsType {
  updateCount?: number, // 更新渲染标志
  collapsed?: boolean, // 是否展开
  handleShowColumnReset?: () => void, // 重置列操作
  setCollapsed?: (v: boolean) => void, // 展开事件
  searchReq?: CostApplicationReq, // 请求参数
  selectedRowKeys?: React.Key[], // 所选行
  handleDeleteBatch?: () => void,
  handleAdd?: () => void,
  handleTableColumnsChange?: (value: string[]) => void,
  showColumn?: string[],
  tableColumns?: ColumnsType,
  refreshData?: (req: CostApplicationReq) => void,
  size?: number
  isHiddenOptions?: boolean
  locationPathname?: string
  items?: React.ReactNode[]
  setConfirmLoading?: (loading: boolean) => void
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [searchReq, setSearchReq] = useState<CostApplicationReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
    }
  }, [props.searchReq])

  const refreshData = (req: CostApplicationReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleCooperationUnitChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.cooperationUnit = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfaStatusChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efaStatus = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfaAccountingObjectChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efaAccountingObject1 = dateStrings[0]
    req.efaAccountingObject2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfaPayTypeChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efaPayType = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfaApplyAmountChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efaApplyAmount1 = start
    req.efaApplyAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfaAmountPayoutChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efaAmountPayout1 = start
    req.efaAmountPayout2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfaBalanceChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efaBalance1 = start
    req.efaBalance2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonResponsibleNameChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined

    req.personResponsible = undefined
    req.efaPayType = undefined
    req.efaApplyCode = undefined
    req.efaAccountingObject1 = undefined
    req.efaAccountingObject2 = undefined
    req.efaApplyAmount1 = undefined
    req.efaApplyAmount2 = undefined
    req.efaAmountPayout1 = undefined
    req.efaAmountPayout2 = undefined
    req.efaStatus = undefined
    req.efaBalance1 = undefined
    req.efaBalance2 = undefined

    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <HeaderAction
        size={props.size}
        isHiddenOptions={props.isHiddenOptions}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        selectedRowKeys={props.selectedRowKeys}
        handleDeleteBatch={props.handleDeleteBatch}
        handleAdd={props.handleAdd}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={!props.refreshData}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <DatePicker.RangePicker
            allowClear
            value={searchReq?.efaAccountingObject1 ? [dayjs(searchReq?.efaAccountingObject1), dayjs(searchReq?.efaAccountingObject2)] : [undefined, undefined]}
            placeholder={['制单开始日期', '制单结束日期']}
            onChange={handleEfaAccountingObjectChange}
            className={"width-100-percentage"}/>,
          <SelectDict
            updateCount={props.updateCount}
            code={'payment_typee'}
            onChange={handleEfaPayTypeChange}
            value={searchReq?.efaPayType}
            placeholder={'请选择费用类别'}
            allowClear
            className={"width-100-percentage"}/>,
          <CustomInputNumber
            value={[searchReq?.efaApplyAmount1, searchReq?.efaApplyAmount2]}
            placeholder={['申请金额最小', '申请金额最大']}
            className={'width-100-percentage'}
            onSearch={handleEfaApplyAmountChange}/>,
          <CustomInputNumber
            value={[searchReq?.efaAmountPayout1, searchReq?.efaAmountPayout2]}
            placeholder={['实现金额最小', '实现金额最大']}
            className={'width-100-percentage'}
            onSearch={handleEfaAmountPayoutChange}/>,
          <CustomInputNumber
            value={[searchReq?.efaBalance1, searchReq?.efaBalance2]}
            placeholder={['余额最小', '余额最大']}
            className={'width-100-percentage'}
            onSearch={handleEfaBalanceChange}/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
          <SelectCooperativeUnit
            onChange={handleCooperationUnitChange}
            value={searchReq?.cooperationUnit}
            allowClear
            fieldNames={{value: 'cuName', label: 'cuName'}}/>,
          <SelectPersonCharge
            updateCount={props.updateCount}
            onChange={handlePersonResponsibleNameChange}
            className={"width-100-percentage"}
            value={searchReq?.personResponsible}
            placeholder={"请选择经办记录"}
            allowClear/>,
          <Select
            onChange={handleEfaStatusChange}
            allowClear
            options={[
              {label:'N/A', value:'N/A'},
              {label:'空白', value:'空白'},
              {label:'已批准', value:'已批准'},
              {label:'审批中', value:'审批中'},
              {label:'已拒绝', value:'已拒绝'},
              {label:'已存档', value:'已存档'},
              {label:'已终止', value:'已终止'},
            ]}
            value={searchReq?.efaStatus}
            placeholder={'请选择办理进度'}
            className={"width-100-percentage"}/>,
        ] : []}/>
    </>
  )
}))
