import React, {useEffect, useState} from "react";
import {FormInstance} from "antd/es";
import {observer} from "mobx-react";
import {SelectDict} from "@components/SelectDict";
import {HeaderAction} from "@components/HeaderAction";
import {ColumnsType} from "antd/es/table";
import {Any} from "@common/types";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {EmployeeFeePayReq} from "@services/dto/employeeFeePay";
import {CustomInputNumber} from "@components/CustomInputNumber";
import {DatePicker, Input} from "antd";
import dayjs from "dayjs";
import {ClearOutlined, SearchOutlined} from "@ant-design/icons";

export interface PropsType {
  updateCount?: number, // 更新渲染标志
  collapsed?: boolean, // 是否展开
  handleShowColumnReset?: () => void, // 重置列操作
  setCollapsed?: (v: boolean) => void, // 展开事件
  searchReq?: EmployeeFeePayReq, // 请求参数
  size?: number
  isHiddenOptions?: boolean
  locationPathname?: string
  items?: React.ReactNode[]
  selectedRowKeys?: React.Key[], // 所选行
  handleDeleteBatch?: () => void,
  handleAdd?: () => void,
  handleTableColumnsChange?: (value: string[]) => void,
  showColumn?: string[],
  tableColumns?: ColumnsType,
  refreshData?: (req: EmployeeFeePayReq) => void,
}

export const HeaderActionCom = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, _) => {

  const [baNick1, setBaNick1] = useState<string>()
  const [searchReq, setSearchReq] = useState<EmployeeFeePayReq>({
    pageNum: 1,
    perPage: 10,
  })

  useEffect(() => {
    if (props.searchReq) {
      setSearchReq(props.searchReq);
      setBaNick1(props.searchReq.baNick1)
    }
  }, [props.searchReq])

  const refreshData = (req: EmployeeFeePayReq) => {
    if (props.refreshData) {
      props.refreshData(req)
    }
  }

  const handleEfpPayDateChange = (_: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null, dateStrings: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efpPayDate1 = dateStrings[0]
    req.efpPayDate2 = dateStrings[1]
    setSearchReq(req)
    refreshData(req)
  }

  const handleElTypeChange = (searchValue: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efpPayType = searchValue
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfpAmountChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efpAmount1 = start
    req.efpAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handleEfpApprovedAmountChange = ([start, end]: [string, string]) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.efpApprovedAmount1 = start
    req.efpApprovedAmount2 = end
    setSearchReq(req)
    refreshData(req)
  }

  const handlePersonResponsibleNameChange = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.personResponsible = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleResponsibilityProjectChange = (value: Any | null) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.responsibilityProject = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordClear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleKeywordSearch = (value: string) => {
    const req = {...searchReq}
    req.pageNum = 1
    req.keyword = value
    setSearchReq(req)
    refreshData(req)
  }

  const handleBaNick1Clear = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.baNick1 = undefined
    setSearchReq(req)
    refreshData(req)
  }

  const handleBaNick1Search = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.baNick1 = baNick1
    setSearchReq(req)
    refreshData(req)
  }

  /**
   * 重置请求参数
   */
  const handleSearchReqReset = () => {
    const req = {...searchReq}
    req.pageNum = 1
    req.perPage = 10
    req.keyword = undefined
    req.cooperationUnit = undefined
    req.responsibilityProject = undefined

    req.efpPayDate1 = undefined
    req.efpPayDate2 = undefined
    req.efpPayType = undefined
    req.efpAmount1 = undefined
    req.efpAmount2 = undefined
    req.efpApprovedAmount1 = undefined
    req.efpApprovedAmount2 = undefined
    req.baNick1 = undefined
    req.personResponsible = undefined
    req.personResponsibleName = undefined

    setSearchReq(req)
    refreshData(req)
  }

  return (
    <>
      <HeaderAction
        size={props.size}
        isHiddenOptions={props.isHiddenOptions}
        collapsed={props.collapsed}
        setCollapsed={props.setCollapsed}
        searchReq={props.searchReq}
        handleShowColumnReset={props.handleShowColumnReset}
        handleSearchReqReset={props.refreshData ? handleSearchReqReset : undefined}
        selectedRowKeys={props.selectedRowKeys}
        handleDeleteBatch={props.handleDeleteBatch}
        handleAdd={props.handleAdd}
        handleKeywordSearch={handleKeywordSearch}
        handleKeywordClear={handleKeywordClear}
        handleTableColumnsChange={props.handleTableColumnsChange}
        showColumn={props.showColumn}
        tableColumns={props.tableColumns}
        locationPathname={props.locationPathname}
        isShowKeyword={true}
        items={props.items}
        collapsedChildren={props.refreshData ? [
          <DatePicker.RangePicker
            picker={'month'}
            value={searchReq?.efpPayDate1 ? [dayjs(searchReq?.efpPayDate1), dayjs(searchReq?.efpPayDate2)] : [undefined, undefined]}
            placeholder={['付款开始月份', '付款结束月份']}
            onChange={handleEfpPayDateChange}
            allowClear
            className={"width-100-percentage"}/>,
          <SelectDict
            updateCount={props.updateCount}
            code={'pay_type'}
            value={searchReq?.efpPayType}
            allowClear
            onChange={handleElTypeChange}
            placeholder={'请输入借款类别'}
            className={"width-100-percentage"}/>,
          <CustomInputNumber
            value={[searchReq?.efpAmount1, searchReq?.efpAmount2]}
            placeholder={['最小金额', '最大金额']}
            className={'width-100-percentage'}
            onSearch={handleEfpAmountChange}/>,
          <CustomInputNumber
            value={[searchReq?.efpApprovedAmount1, searchReq?.efpApprovedAmount2]}
            placeholder={['核定最小金额', '核定最大金额']}
            className={'width-100-percentage'}
            onSearch={handleEfpApprovedAmountChange}/>,
          <Input
            onChange={(e) => setBaNick1(e.target.value)}
            value={baNick1}
            onPressEnter={handleBaNick1Search}
            allowClear={{clearIcon: <ClearOutlined onClick={handleBaNick1Clear}/>}}
            placeholder="请搜索付款账户"
            suffix={<SearchOutlined onClick={handleBaNick1Search}/>}
          />,
          <SelectPersonCharge
            updateCount={props.updateCount}
            onChange={handlePersonResponsibleNameChange}
            className={"width-100-percentage"}
            value={searchReq?.personResponsible}
            placeholder={"请选择负责人"}
            allowClear/>,
          <SelectProject
            updateCount={props.updateCount}
            placeholder={"请选择责任部门"}
            value={searchReq?.responsibilityProject}
            onChange={handleResponsibilityProjectChange}
            allowClear
            fieldNames={{value: 'name', label: 'name'}}/>,
        ] : []}/>
    </>
  )
}))
