import React, { useEffect } from "react";
import {Col, Form, Input, InputNumber, Row} from "antd";
import { FormInstance } from "antd/es";
import { observer } from "mobx-react";
import {MaterialListRow} from "@services/dto/materialList";
import {SelectPersonCharge} from "@components/SelectPersonCharge";
import {SelectProject} from "@components/SelectProject";
import {SelectCooperativeUnit} from "@components/SelectCooperativeUnit";

export interface PropsType {
  row: MaterialListRow
  isView?: boolean
}

export const BasicInfo = observer(React.forwardRef<FormInstance, PropsType>((props: PropsType, ref) => {
  const [form] = Form.useForm()

  useEffect(() => {
    form.resetFields()
    const outInBoundRow = { ...props.row }
    if (outInBoundRow.id) {
      // if (typeof outInBoundRow.mdDate === 'string') {
      //   outInBoundRow.mdDate = dayjs(outInBoundRow.mdDate)
      // }if (typeof outInBoundRow.mdOutDate === 'string') {
      //   outInBoundRow.mdOutDate = dayjs(outInBoundRow.mdOutDate)
      // }
    }
    form.setFieldsValue(outInBoundRow);
  }, [props.row])

  return (
    <Form
      name="basic"
      ref={ref}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ status: 1, isVirtualUser: 0 }}
      disabled={props.isView}
      autoComplete="off">
      <Row gutter={[0, 10]}>
        <Form.Item hidden name="id">
          <Input />
        </Form.Item>
        <Col span={24}>
          <Form.Item
            label="名称"
            name="mlName"
            rules={[{ required: false, message: '请输入名称!' }]}>
            <Input placeholder="请输入名称" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="规格型号"
            name="mlType"
            rules={[{ required: false, message: '请输入规格型号!' }]}>
            <Input placeholder="请输入规格型号" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="单位"
            name="mlUnit"
            rules={[{ required: false, message: '请输入单位!' }]}>
            <Input placeholder="请输入单位" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="数量"
            name="mlNum"
            rules={[{ required: false, message: '请输入数量!' }]}>
            <Input placeholder="请输入数量" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="单价"
            name="mlPrice"
            rules={[{ required: false, message: '请输入单价!' }]}>
            <InputNumber min={0} precision={2} placeholder="请输入金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="合价"
            name="mlSumPrice"
            rules={[{ required: false, message: '请输入合价!' }]}>
            <InputNumber min={0} precision={2} placeholder="请输入金额" className={"width-100-percentage"}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="购买方"
            name="mlPurchaser"
            rules={[{ required: false, message: '请输入购买方!' }]}>
            <Input placeholder="请输入购买方" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="销售方"
            name="mlSeller"
            rules={[{ required: false, message: '请输入销售方!' }]}>
            <Input placeholder="请输入销售方" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="办理进度"
            name="mlStatus"
            rules={[{ required: false, message: '请输入办理进度!' }]}>
            <Input placeholder="请输入办理进度" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任人"
            name="personResponsible"
            rules={[{ required: true, message: '请选择责任人!' }]}>
            <SelectPersonCharge className={"width-100-percentage"} placeholder={"请选择负责人"} allowClear/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="责任部门"
            name="responsibilityProject"
            rules={[{ required: true, message: '请选择责任部门!' }]}>
            <SelectProject placeholder={"请选择责任部门"} allowClear fieldNames={{value: 'name', label: 'name'}}/>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="协作单位"
            name="cooperationUnit"
            rules={[{ required: true, message: '请输入协作单位!' }]}>
            <SelectCooperativeUnit allowClear fieldNames={{value: 'cuName', label: 'cuName'}}/>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}))
